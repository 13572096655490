import {Request} from '../utilities/Request';

export function update_stock_note(this_obj, stock_item_id, note) {
  return Request.patch(
    this_obj,
    `/api/stock_items/${stock_item_id}/update_stock_note`,
    null,
    {note: note}
  );
}

export function stock_notes(this_obj, proposal_id) {
  return Request.post(
    this_obj,
    '/api/stock_items/stock_notes',
    null,
    {proposal_id: proposal_id}
  );
}