<template>
  <div>
    <el-card class="box-card" style="margin: 5px">
      <div slot="header" class="clearfix">
        <strong>{{ working_file_info.name.toUpperCase() }}</strong>

     
        <span
          v-show="
            working_file_info.id > 0 &&
              working_file_info.working_file.matched_quote > 0
          "
          style="float: right; padding: 3px 0"
          v-bind:class="{ crossed_text: proposal.matched_quote > 0 }"
          type="text"
          > ${{ working_file_info.working_file.matched_quote }} [Matched
          quote]</span
        >
       

      </div>

      <h3>
        STOCK ITEMS LIST
        <hr />
      </h3>
      <table width="100%" class="items_list_summary">
        <thead>
          <tr>
            <td style="width: 30%;">Name</td>
            <td>Quantity</td>
            <td>Unit cost</td>
            <td>Gross profit (%)</td>
            <td>Waste (%)</td>
            <td>Unit Sale price</td>
            <td>Total Cost</td>
            <td>Total Sale Price</td>
            <td>Total Profit</td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="stock in working_file_info.stock_items_list"
            v-bind:key="stock.id"
            :class="stock.mark_as_deleted == 1 ? 'mark_as_deleted' : ''"
          >
            <td>
              {{ stock.layer_name == "" ? stock.stock_name : stock.layer_name }}
            </td>
            <td width="100">{{ stock.quantity }}</td>
            <td width="100">${{ stock.price }}</td>
            <td width="100">%{{ stock.percent_applied }}</td>
            <td width="140">${{ stock.waste_percent }}</td>
            <td width="140">${{ stock.sale_price }}</td>
            <td width="140" style="background-color: #f7d6d6">
              ${{ round(stock.quantity * stock.price) }}
            </td>
            <td width="140" style="background-color: #dce8ec">
              ${{ stock.quantity * stock.sale_price }}
            </td>
            <td width="140" style="background-color: #e5f7e6">
              ${{
                round(
                  stock.quantity * stock.sale_price -
                    stock.quantity * stock.price
                )
              }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="5">Total</td>
            <td></td>
            <td>{{ stock_items_total_cost }}</td>
            <td>{{ stock_items_total_sale }}</td>
            <td>{{ stock_items_total_profit }}</td>
          </tr>
        </tfoot>
      </table>
      <br />

      <h3>
        TASKS LIST
        <hr />
      </h3>
      <table width="100%" class="items_list_summary">
        <thead>
          <tr>
            <td style="width: 30%;">Name</td>
            <td>Quantity</td>
            <td>Unit Cost</td>
            <td>Unit Sale price</td>
            <td>Total Cost</td>
            <td>Total Sale Price</td>
            <td>Total Profit</td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="job in working_file_info.job_proposals_list.sort(function(
              a,
              b
            ) {
              return a.position - b.position;
            })"
            v-bind:key="job.id"
            :class="job.mark_as_deleted == 1 ? 'mark_as_deleted' : ''"
          >
            <td>
              {{ job.layer_name == "" ? job.job_name : job.layer_name }}
            </td>
            <td width="140">
              <Time :is_hours="job.is_hours" :minutes="job.time_allocated_labour" />
            </td>
            <td width="140">${{ job.pay_rate }}</td>
            <td width="140">${{ job.sale_price }}</td>
            <td width="140" style="background-color: #f7d6d6">
              ${{ round(job.time_allocated_labour * job.pay_rate) }}
            </td>
            <td width="140" style="background-color: #dce8ec">
              ${{ round(job.time_allocated_labour * job.sale_price) }}
            </td>
            <td width="140" style="background-color: #e5f7e6">
              ${{
                round(
                  job.time_allocated_labour * job.sale_price -
                    job.time_allocated_labour * job.pay_rate
                )
              }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="4">Total</td>
            <td>${{ job_proposals_total_cost }}</td>
            <td>${{ job_proposals_total_sale }}</td>
            <td>${{ job_proposals_total_profit }}</td>
          </tr>
        </tfoot>
      </table>

      <h3>
        CHARGE POS LIST
        <hr />
      </h3>
      <table width="100%" class="items_list_summary">
        <thead>
          <tr>
            <td>PO ID</td>
            <td>Category</td>
            <td>Supplier</td>
            <td>Stock Category</td>
            <td>Description</td>
            <td>Status</td>
            <td>Total Cost</td>
            <td>Total Sale</td>
            <td>Total Profit</td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="po in working_file_info.charge_pos_list"
            v-bind:key="po.id"
            :class="po.mark_as_deleted == 1 ? 'mark_as_deleted' : ''"
          >
            <td>{{ po.id }}</td>
            <td>{{ po.category_task }}</td>
            <td>{{ po.supplier }}</td>
            <td>{{ po.category }}</td>
            <td>{{ po.description }}</td>
            <td>{{ po.status }}</td>
            <td>{{ po.actual_value }}</td>
            <td>{{ po.sale_price }}</td>
            <td>{{ po.sale_price - po.actual_value }}</td>
          </tr>
        </tbody>
        <!-- <tfoot>
          <tr>
            <td colspan="6">Total</td>
            <td>${{ charge_pos_total_cost }}</td>
            <td>${{ charge_pos_total_sale }}</td>
            <td>${{ charge_pos_total_profit }}</td>
          </tr>
        </tfoot> -->
      </table>
    </el-card>
    <br />
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { editor_store } from "store/modules/editor";
import Time from '../../shared/Time';
export default {
  name: "ProposalSummary",
  components: { Time },
  store: editor_store,
  props: ["working_file_info"],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    round(num) {
      return parseFloat(((num * 100) / 100).toFixed(2));
    },
  },
  computed: {
    ...mapGetters([
      "proposal",
      "job_proposals_list",
      "stock_items_list",
      "charge_pos_list",
      "files_list",
    ]),
    filtered_stocks() {
      if (this.working_file_info.id == undefined) return this.stock_items_list;
      else
        return this.stock_items_list.filter(
          (c) => c.working_file_id == this.working_file_info.id
        );
    },
    filtered_tasks() {
      if (this.working_file_info.id == undefined)
        return this.job_proposals_list;
      else
        return this.job_proposals_list.filter(
          (c) => c.working_file_id == this.working_file_info.id
        );
    },
    job_proposals_total_cost() {
      return this.round(
        this.filtered_tasks.reduce(
          (acc, item) =>
            item.mark_as_deleted == 0
              ? acc + item.time_allocated_labour * item.pay_rate
              : acc,
          0
        )
      );
    },
    job_proposals_total_sale() {
      return this.round(
        this.filtered_tasks.reduce(
          (acc, item) =>
            item.mark_as_deleted == 0
              ? acc + item.time_allocated_labour * item.sale_price
              : acc,
          0
        )
      );
    },
    job_proposals_total_profit() {
      return this.round(
        this.filtered_tasks.reduce(
          (acc, job) =>
            job.mark_as_deleted == 0
              ? acc +
                (job.time_allocated_labour * job.sale_price -
                  job.time_allocated_labour * job.pay_rate)
              : acc,
          0
        )
      );
    },
    stock_items_total_cost() {
      return this.round(
        this.filtered_stocks.reduce(
          (acc, stock) =>
            stock.mark_as_deleted == 0
              ? acc + stock.quantity * stock.price
              : acc,
          0
        )
      );
    },
    stock_items_total_sale() {
      return this.round(
        this.filtered_stocks.reduce(
          (acc, stock) =>
            stock.mark_as_deleted == 0
              ? acc + stock.quantity * stock.sale_price
              : acc,
          0
        )
      );
    },
    stock_items_total_profit() {
      return this.round(
        this.filtered_stocks.reduce(
          (acc, stock) =>
            stock.mark_as_deleted == 0
              ? acc +
                (stock.quantity * stock.sale_price -
                  stock.quantity * stock.price)
              : acc,
          0
        )
      );
    },
    total_cost_price_calculated() {
            let job_cost_price = this.job_proposals_total_sale;
            let stock_cost_price = this.stock_items_total_sale;
            // let po_cost_price = this.po_proposals_total_cost;
            return (job_cost_price + stock_cost_price ).toFixed(2);
        },
  },
 
};
</script>
<style>
.crossed_text {
  text-decoration: line-through;
}
</style>
