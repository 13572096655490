<template>
  <tr
    v-bind:style="[
      (stock.request != undefined && stock.request.is_approved == 1) ||
      stock.mark_as_deleted == 1
        ? { 'text-decoration': 'line-through', 'font-style:': 'italic' }
        : {},
    ]"
    class="stock__row"
  >
    <td>{{ stock.category }}</td>
    <td>
      <el-input
        v-model="stock.layer_name"
        :placeholder="stock.stock_name"
        size="mini"
        @change="update_stock_layer_name"
      >
      </el-input>
      <a
        target="_blank"
        :href="'../../../stock_items/' + stock.stock_item_id + '/edit'"
        >{{
          stock.stock_name +
            (stock.layer_name != "" ? ` (${stock.layer_name})` : "")
        }}</a
      >
      <br />
      <small>{{ stock.specs_details }}</small>
      <div
        v-if="
          stock.is_workspace == true &&
            proposal.approved == 0 &&
            stock.is_initiated == 1
        "
      >
        <a
          data-tooltip="Initiated stock item"
          class="material-icons dp48 tooltipped"
          >check_circle</a
        >
      </div>
    </td>
    <td>{{ stock.supplier }}</td>

    <td width="120">
      <div v-if="stock.purchase_order != undefined">
        <b>PO #:</b>
        <a :href="get_po_link(stock.purchase_order.id)">
          {{ stock.purchase_order.id }}
        </a>
        <br />
        <b>Qty:</b>
        {{ stock.purchase_order.quantity }}
        <br />
        <b>Status:</b>
        <span>{{ stock.purchase_order.status }}</span>
        <br />
        <b>From Stock:</b>
        <span>{{
          calc_qty_from_stock(stock.quantity, stock.purchase_order.quantity)
        }}</span>
      </div>
    </td>
    <td>
      <div>
        <div v-if="stock.billable_locked == true && proposal.approved == 1">
          <input
            v-on:change="update_stock_billable($event, stock.id)"
            type="checkbox"
            class="filled-in"
            :name="'n_billable_' + stock.id"
            :id="'n_billable_' + stock.id"
            :checked="stock.billable"
            :data-id="stock.id"
          />
        </div>
        <div v-else-if="proposal.approved == 1">
          <input
            v-on:change="update_stock_billable($event, stock.id)"
            type="checkbox"
            class="filled-in"
            :name="'n_billable_' + stock.id"
            :id="'n_billable_' + stock.id"
            :checked="stock.billable"
            :data-id="stock.id"
          />
          <label :for="'n_billable_' + stock.id"></label>
        </div>
      </div>
    </td>

    <td>
      <div v-if="proposal.approved == 1 || stock.is_initiated == 1">
        {{ stock.quantity }}
      </div>

      <div
        v-else-if="
          proposal.approved == 0 &&
            (stock.is_workspace == false ||
              (stock.is_workspace == true && stock.is_initiated == 0))
        "
      >
        <div
          v-if="
            stock.purchase_order == undefined ||
              stock.purchase_order == null ||
              (stock.purchase_order != undefined &&
                stock.purchase_order.status == 'APPROVED')
          "
        >
          <el-input
            size="mini"
             
            v-on:change="update_proposal_stock_qty"
            class="stock_item_qty_update"
            style="color:#999!important;"
            v-model="stock.quantity"
            :disabled="qty_disabled"
          ></el-input>
        </div>
      </div>
      <div v-else>{{ stock.quantity }}</div>
    </td>

    <td v-show="proposal.approved == true || proposal.approved == 1">
      {{ stock.used_qty }}
    </td>
    <td>${{ stock.price }}</td>
    <td>
      <el-input
        size="mini"
       
        v-on:change="update_proposal_stock_sale_price"
        type="number"
        v-model="stock.sale_price"
        style="color:#999!important;"
        :disabled="sale_price_disabled"
      ></el-input>
    </td>
    <td width="120" style="background-color: #f7d6d6">
      ${{ stock_cost_total_price }}
    </td>
    <td width="120" style="background-color: #dce8ec">
      ${{ stock_total_price }}
    </td>

    <td>
      <div v-if="stock.is_workspace == true && proposal.approved == 0">
        <div v-if="stock.is_initiated == 0 && stock.is_included === true">
          <button
            v-on:click.prevent="
              initiate_stock_item(get_stock_category(stock), stock.id)
            "
            class="btn btn-small tooltipped"
            data-position="bottom"
            data-tooltip="Initiate stock and create PO if out of stock"
          >
            Initiate
          </button>
        </div>
      </div>
      <div v-if="proposal.completed == 0 && stock.mark_as_deleted == 0">
        <div
          v-if="
            (stock.purchase_order != undefined &&
              stock.purchase_order.status == 'PENDING') ||
              stock.purchase_order == undefined
          "
        >
          <el-button
            v-on:click.prevent="delete_stock_item('default', stock.id)"
            type="danger"
            icon="el-icon-delete"
            circle
          >
          </el-button>
        </div>
        <div
          v-if="
            stock.purchase_order != undefined &&
              (stock.purchase_order.status == 'APPROVED' ||
                stock.purchase_order.status == 'RECEIVED') &&
              stock.request == undefined &&
              stock.used_qty == 0
          "
        >
          <button
            v-on:click.prevent="request_delete_stock_item('default', stock.id)"
            style="min-width: 125px;"
            class="btn btn-small"
          >
            Request Delete
          </button>
        </div>
        <div v-if="stock.request != undefined">
          <div v-if="stock.request.is_approved == 0">
            <span>Delete Request submitted</span>
          </div>
          <div v-else-if="stock.request.is_approved == 1">
            <span>Delete Request approved</span>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { ProposalStockItemMixin } from "mixins/ProposalStockItemMixin.js";
import { editor_store } from "store/modules/editor";
import { defaultInput } from '../../utilities/DefaultInput';
import {EventBus} from '../../utilities/EventBus';

export default {
  name: "StockItemEntry",
  props: ["stock"],
  mixins: [ProposalStockItemMixin],
  data() {
    return {
      qty_disabled: false,
      sale_price_disabled: false,
    };
  },
  store: editor_store,
  mounted() {
    this.$nextTick(() => { defaultInput(".stock__row"); });
  },
  methods: {
    ...mapActions([
      "load_layers_tree",
      "set_file_changed",
      "set_stock_items_list",
    ]),
    update_stock_layer_name() {
      let params = {
        layer_name: this.stock.layer_name,
      };
      this.$http
        .patch(`/update_proposal_stock_item/${this.stock.id}`, {
          proposal_stock_item: params,
        })
        .then(
          (response) => {
            this.$message({
              type: "success",
              message: "Stock name has been updated successfully",
            });
          },
          (response) => {
            this.$message({
              type: "error",
              message: "Error happened while updating, please try again.",
            });
          }
        );
    },
    get_working_file(id) {
      // migrate this function later to Store or a module library
      let working_file = this.files_list.filter((item) => {
        return item.id == id;
      })[0];

      if (working_file != undefined) return working_file.name;
      else return "";
    },
    calc_qty_from_stock(qty, po_qty) {
      var from_stock = qty - po_qty;
      return from_stock < 0 ? 0 : from_stock;
    },
    get_po_link(id) {
      return "/purchase_orders/" + id + "/edit";
    },
    save_qty_overwritten: function(event, stock_item_id) {
      let self = this;
      this.$http.post("/save_qty_overwritten", {
        stock_item_id: stock_item_id,
        stock_quantity_calculated_overwritten: event.target.checked,
      });
    },
    update_stock_billable: function(event, id) {
      let self = this;
      this.$http.post("/update_stock_billale", {
        id: id,
        stock_type: "ProposalStockItem",
        billable: event.target.checked ? 1 : 0,
      });
    },

    show_stock_item_form: function() {
      //document.getElementById('stock').style.display = 'block';
      $("#new_stock_item").fadeOut();
    },
    update_proposal_stock_sale_price: function(event) {
      let _this = this;
      _this.sale_price_disabled = true;
      this.update_stock_sale_price(_this.stock.id, _this.stock.sale_price).then(
        (response) => {
          _this.stock.total_price =
            _this.stock.sale_price * _this.stock.quantity;
          _this.sale_price_disabled = false;
          this.$message({
            message: "Updated successfully.",
            type: "success",
          });
          EventBus.$emit('reloadCategoryWorkingFiles');
        },
        (reason) => {
          _this.sale_price_disabled = false;
          console.error(reason);
          return false;
          this.$message({
            message: "Error happened while retreiving the file.",
            type: "error",
          });
        }
      );
    },
    update_proposal_stock_qty: function(event) {
      let _this = this;
      _this.qty_disabled = true;
      //Update QTY
      var js = {
        id: _this.stock.id,
        source_type: "ProposalStockItem",
        qty: _this.stock.quantity,
      };

      this.$http.post("/update_stock_item_qty", js).then(
        (response) => {
          _this.stock.total_price =
            _this.stock.sale_price * _this.stock.quantity;
          _this.qty_disabled = false;
          this.$message({
            message: "Updated successfully.",
            type: "success",
          });
          EventBus.$emit('reloadCategoryWorkingFiles');
        },
        function(response) {
          _this.qty_disabled = false;
          console.log(response);
          this.$message({
            message: "Error happened while retreiving the file.",
            type: "error",
          });
        }
      );
    },
    delete_stock_item: function(type, id) {
      let _this = this;
      this.delete_proposal_stock_item(type, id).then(
        (value) => {
          // remove stock from list
          if (_this.proposal.approved == 1) _this.stock.mark_as_deleted = 1;
          else {
            let index = _this.stock_items_list.indexOf(_this.stock);
            _this.stock_items_list.splice(index, 1);
          }

          // Update working file ONLY if editor exists
          if (typeof d3 !== "undefined") {
            d3.selectAll(`[proposal-stock-id="${id}"]`).remove();
            this.load_layers_tree();
            this.set_file_changed(true);
          }
          this.$message({
            message: "Deleted successfully.",
            type: "success",
          });
          EventBus.$emit('reloadCategoryWorkingFiles');
        },
        (reason) => {
          console.log(reason);
          this.$message({
            message: "Error happened while retreiving the file.",
            type: "error",
          });
        }
      );
    },
    get_stock_category(stock) {
      if (stock.sign_id != undefined) return "signage";
      else if (stock.print_calculation_id != undefined) return "print";
      else return "default";
    },
    initiate_stock_item: function(type, id) {
      let _this = this;
      if (
        confirm(
          "Are you sure to initiate the stock item, stock will be allocated & PO will be generated if out of stock ?"
        ) == false
      )
        return false;

      this.$http
        .post("/initiate_stock_item", {
          type: type,
          id: id,
        })
        .then(
          (response) => {
            // self.show_stock = this.stock_list();
            _this.load_stock_items_list(_this.proposal.id).then((response) => {
              _this.set_stock_items_list(response);
            });
          },
          function(response) {
            console.log(response);
          }
        );
    },
    request_delete_stock_item: function(type, id) {
      if (confirm("Are you sure to delete the stock item ?") == false)
        return false;
      this.$http.delete(`/request_delete_stock_item/${type}/${id}`).then(
        (response) => {
          self.show_stock = this.stock_list();
        },
        function(response) {
          console.log(response);
        }
      );
    },
  },
  computed: {
    ...mapGetters(["proposal", "stock_items_list", "files_list"]),
    stock_total_price() {
      return (this.stock.quantity * this.stock.sale_price).toFixed(2);
    },
    stock_cost_total_price() {
      return (this.stock.quantity * this.stock.price).toFixed(2);
    },
  },
};
</script>
