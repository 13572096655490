<template>
  <section class="sales__header">
    <el-row>
      <el-col
        :span="24"
        :lg="14"
        :xl="12"
      >
        <b>{{ sale.client_name }}</b>
        <span v-if="sale.job_name != null"> | {{ sale.job_name }}</span>
        <el-tag
          v-if="sale.quote_type != null"
        >
          {{ sale.quote_type }}
        </el-tag>
      </el-col>
      <el-col
        :span="24"
        :lg="10"
        :xl="12"
      >
        <el-link
          v-if="links.job != null"
          type="primary"
          :href="links.job"
          target="_blank"
        >
          JOB NO {{ sale.job_no }}
        </el-link>
        <el-link
          v-if="links.quote != null"
          type="primary"
          :href="links.quote"
          target="_blank"
        >
          | QUOTE NO {{ sale.proposal_id }}
        </el-link>
        <el-link
          v-if="links.po != null"
          type="primary"
          :href="links.po"
          target="_blank"
        >
          | PURCHASE ORDERS
        </el-link>
      </el-col>
    </el-row>
  </section>
</template>

<script>
export default {
  name: 'Header',
  props: {
    sale: {
      type: Object,
      required: true
    },
    links: {
      type: Object,
      required: true
    }
  }
};
</script>
