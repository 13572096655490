<template>
  <el-dialog
    title="Auto check-out"
    name="checkout_modal"
    width="30%"
    :visible.sync="dialogVisible"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :show-close="false"
  >
    It is time, do you want to checkout now ?
    <br>
    <i>System will checkout automatically after {{ seconds }} seconds</i>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        type="info"
        @click="cancel_checkout()"
      >
        Cancel
      </el-button>
      <el-button
        size="small"
        type="primary"
        @click.prevent="check_out()"
      >
        Check-out
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'CheckModal',
  data() {
    return {
      dialogVisible: false,
      seconds: 120,
      today: new Date(),
      now: new Date().getTime(),
      checkout_channel: new BroadcastChannel('checkout_channel'),
    };
  },
  computed: {
    checkout_time() {
      return new Date(
        this.today.getFullYear(),
        this.today.getMonth(),
        this.today.getDate(),
        17,
        0,
        0,
        0
      );
    },
  },
  created: function() {
    this.check_time();
    let self = this;

    this.checkout_channel.onmessage = (ev) => {
      if (ev.data == 'CLOSE') {
        self.hide_modal();
      } else if (ev.data == 'REFRESH') {
        window.location.reload();
      }
    };
  },
  methods: {
    check_time() {
      let millisTill10 = this.checkout_time - this.now;
      let self = this;
      if (millisTill10 > 0) {
        setTimeout(function() {
          self.open_modal();
        }, millisTill10);
      }
    },
    open_modal() {
      this.$nextTick(() => {
        this.dialogVisible = true;
        this.timer_on();
      });
    },
    hide_modal() {
      this.dialogVisible = false;
    },
    timer_on() {
      let self = this;
      let x = setInterval(function() {
        if (self.seconds == 0) {
          self.check_out();
          clearInterval(x);
        } else {
          self.seconds--;
        }
      }, 1000);
    },
    cancel_checkout() {
      this.hide_modal();
      this.checkout_channel.postMessage('CLOSE');
    },
    check_out() {
      let self = this;
      this.$http.get('/is_checked_out').then(
        (response) => {
          var result = response.data;
          if (result.data == false) window['check_out_now']();

          this.checkout_channel.postMessage('REFRESH');
          self.hide_modal();
        },
        function(response) {}
      );
    },
  },
};
</script>