import {Request} from '../utilities/Request';

export function acc_levy_rates(this_obj) {
  return Request.get(
    this_obj,
    '/api/acc_levy_rates',
    null,
  );
}

export function create_acc_levy_rate(this_obj, acc_levy_rate) {
  return Request.post(
    this_obj,
    '/api/acc_levy_rates',
    null,
    {
      acc_levy_rate: acc_levy_rate
    }
  );
}

export function delete_acc_levy_rate(this_obj, id) {
  return Request.delete(
    this_obj,
    `/api/acc_levy_rates/${id}`,
    null
  );
}
