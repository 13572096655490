<template>
  <Categories
    v-slot="slotProps"
    :editable="!(proposal.approved === 1)"
    category_id="editCategoryID"
  >
    <div
      v-show="proposal.approved === 0"
      style="margin: 2px;text-align: start;"
    >
      <small v-show="file_changed === true">
        <i>Unsaved changes</i>
      </small>
    </div>
    <br>
    <el-button
      v-show="show_note_icon(slotProps.category)"
      style="padding: 6px; margin: 4px; background: #ffffbf;"
      class="left"
      size="mini"
      icon="el-icon-tickets"
      @click="onEditNote"
    />
    <draggable
      id="panel_div"
      tag="div"
      class="list-group"
      group="jobslist"
      handle=".handle"
      v-bind="dragOptions"
      style="padding: 0px 6px;"
      @start="drag = true"
      @end="drag = false"
      @sort="onSortInCategory"
    >
      <WorkingFile
        v-for="(file) in slotProps.category.working_files"
        :key="file.id"
        class="file-container"
        :file="file"
        :data-id="file.id"
        :data-catetory="slotProps.category.id"
        :save_file="save_file"
        @command="onCommand"
      />
    </draggable>
  </Categories>
</template>

<script>
import { mapGetters } from 'vuex';
import { editor_store } from 'store/modules/editor';

import draggable from 'vuedraggable';
import WorkingFile from './WorkingFile';
import Categories from '../../proposal/categories/Categories';
import {EventBus} from '../../../utilities/EventBus';


export default {
  name: 'CategoryWorkingFiles',
  components: {Categories, draggable, WorkingFile },
  store: editor_store,
  props: {
    save_file: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      working_files_categories: [],
      active_working_file_category: '',
      categories: [],
      active_category: '',
      drag: false,
      updated_by_position: false,

    };
  },
  computed: {
    ...mapGetters([
      'files_list',
      'proposal',
      'client_view',
      'working_file_id',
      'working_file_categories',
      'file_changed',
    ]),
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
    show_page_menu() {
      return !(this.client_view === true || this.proposal.approved === 1);
    }
  },
  methods: {
    onSortInCategory(ev) {
      let working_files =  Array.from(ev.target.children).map((el, idx) => {
        return { id: Number(el.dataset.id), index: idx, category: el.dataset.catetory ? Number(el.dataset.catetory) : null };
      });

      let category = this.working_file_categories.find( c => c.id === working_files[0].category );
      let start_pos = category.working_files[0].position;
      let updated_list = working_files.map((file, idx)=>{
        return {id: file.id, position: start_pos + idx};
      });

      this.$http.post('/update_working_file_positions',{ working_files: updated_list }).then( ()=> {
        EventBus.$emit('reloadCategoryWorkingFiles');
      });
    },
    onCommand(command) {
      this.$emit('command', command);
    },
    onEditNote() {
      let command = {  file: { id: this.working_file_id }, action: 'edit_note' };
      this.onCommand(command);
    },
    show_note_icon(category) {
      if(this.working_file_id === '' || this.working_file_id === undefined) return false;
      if(this.client_view === true) return false;

      return category.working_files.find((w)=>Number(w.id) === Number(this.working_file_id));
    }
  }
};
</script>

<style lang="scss" scoped>
.file-container {
  text-align: center;
}
</style>