<template>
  <div>
    <div>
      <h3 class="sales__title">
        CHARGE POS LIST
      </h3>
      <hr>
    </div>
    <table class="sales_items_list">
      <thead>
        <tr>
          <td>ID</td>
          <td>Category</td>
          <td>Supplier</td>
          <td>Stock Category</td>
          <td>Status</td>
          <td>Total Sale Price</td>
          <td>Total Cost</td>
          <td>Net Profit</td>
          <th>Actual Cost</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(po,index) in items"
          :key="index"
          :class="mark_deleted(po.mark_as_deleted)"
        >
          <td> {{ po.id }}</td>
          <td> {{ po.category }}</td>
          <td> {{ po.supplier }}</td>
          <td> {{ po.stock_category }}</td>
          <td> {{ po.status }}</td>
          <td><Money :amount="po.total_sales_price" /></td>
          <td><Money :amount="po.total_sales_cost" /></td>
          <td><Money :amount="po.net_profit" /></td>
          <td><Money :amount="po.actual_cost" /></td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="5" />
          <td><Money :amount="sum_total_sales_price" /></td>
          <td><Money :amount="sum_total_sales_cost" /></td>
          <td><Money :amount="sum_net_profit" /></td>
          <td><Money :amount="sum_actual_cost" /></td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import SalesMixin from 'mixins/SalesMixin';

export default {
  name: 'POItemsList',
  mixins: [SalesMixin],
  computed: {
    sum_actual_cost() {
      return this.items.reduce((acc, item) =>
        item.mark_as_deleted == 0 ? acc + item.actual_cost : acc, 0);
    },
  }
};
</script>
