<template>
  <div class="info-container">
    <div class="name-container">
      <el-tooltip
        class="item"
        effect="dark"
        content="Category Name"
        placement="top-start"
      >
        <el-input
          v-model="category_name"
          :disabled="editable===false"
          size="mini"
          style="width:90%"
          @change="onCategoryNameChange"
        />
      </el-tooltip>
    </div>
    <div
      class="lists-container"
      style="overflow:auto"
    >
      <ul
         
        style="border:1px solid #333;padding:7px;width:100%"
      >
        <li>
          <span class="cat_totals">Total Price:<span style="color:#333"><Money :amount="category.total_price" /></span></span>
          <span class="cat_totals">Total Costs:<span style="color:#333"><Money :amount="category.total_costs" /></span></span>
          <span class="cat_totals">Total Profit:<span style="color:#333"><Money :amount="category.total_profit" /></span></span>
          <span class="cat_totals">Rate:<span style="color:#333"><Money :amount="category.rate" /></span></span>
        </li>
      </ul>
      <ul
        class="list-wrapper"
        style="width:100"
      >
        <li
          v-for="(quantity,index) in category.quantities"
          :key="index"
          style="width:220px;float:left;"
        >
          <span>{{ quantity.name }}:</span>
          <el-input-number
            v-model="quantity.qty"
            :disabled="editable===false"
            size="mini"
            :min="0"
            @change="onQtvChange(quantity)"
          />
        </li>
      </ul>
      <CategoryActions
        v-if="editable"
        class="list-wrapper"
      />
    </div>
  </div>
</template>

<script>
import Money from '../../shared/Money';
import { editCategoryWorkingFile, updateCategoryWorkingFileQty } from '../../../api/category_working_files';
import { defaultInput } from '../../../utilities/DefaultInput';
import {EventBus} from '../../../utilities/EventBus';
import CategoryActions from './CategoryActions';

export default {
  name: 'CategoryInformation',
  components: {Money, CategoryActions},
  props: {
    category: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      category_name: this.category.name
    };
  },
  watch: {
    'category.name'(newVal) {
      this.category_name = newVal;
    }
  },
  mounted() {
    this.$nextTick(() => { defaultInput('.info-container '); });
  },
  methods: {
    onCategoryNameChange(newName) {
      editCategoryWorkingFile(this, this.category.id, newName).then(({data})=>{
        this.$message({
          type: 'success',
          message: data.message
        });
        EventBus.$emit('reloadCategoryWorkingFiles');
      }).catch(() => {
        this.category_name = this.category.name;
      });
    },
    onQtvChange(quantity) {
      updateCategoryWorkingFileQty(this, this.category.id, quantity.id, quantity.qty).then(({data}) =>{
        this.$message({
          type: 'success',
          message: data.message
        });
        EventBus.$emit('reloadCategoryWorkingFiles');
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.info-container {
  background:#E5F6DF;padding:10px;

  .name-container {
    margin-left: 15px;
    margin-right: 15px;
    padding-top: 10px;
  }
.cat_totals{
  padding:0 15px; 
  text-transform: uppercase; 
  color:#a4a1a1;
  width:auto;
}
  .lists-container {
    display: flex;
    flex-wrap: wrap;

    .list-wrapper {
      margin: 10px;
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 5px;
        height: 30px;
        span {
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
