<template>
  <div>
    <el-form
      ref="organization_details"
      v-loading="loading"
      :model="organization_details"
      :label-position="labelPosition"
      label-width="100px"
    >
      <el-row :glutter="20">
        <el-row>
          <el-col :span="24">
            <el-form-item>
              <el-button
                class="right"
                type="info"
                @click="submit('organization_details')"
              >
                SAVE
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <el-row>
            <h3>
              <b>NOTICE BOARD</b>
              <hr>
            </h3>
          </el-row>
          <el-row>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="24"
              :xl="24"
            >
              <el-form-item
                label="Notice Board Message"
                prop="noticeboard"
                class="notice_editor"
              >
                <jodit-editor
                  id="pre_description_editor"
                  v-model="organization_details.noticeboard"
                  :buttons="buttons"
                  :config="jodit_config"
                />
              </el-form-item>
              <h2 style="margin-bottom:0px;">
                FLASH NOTICE
              </h2>
              <span style="color:#333; font-size:12px;margin-bottom:10px;">
                Place in messge and set dates/time the message is required to
                run
              </span>
              <el-form-item
                label="Urgent Message"
                prop="urgent_message"
              >
                <el-input
                  v-model="organization_details.urgent_message"
                  class="small"
                  autocomplete="off"
                />
              </el-form-item>
              <el-form-item label="Start Times">
                <el-col
                  :xs="24"
                  :sm="24"
                  :md="24"
                  :lg="24"
                  :xl="24"
                >
                  <el-date-picker
                    v-model="organization_details.urgent_date_start"
                    type="datetime"
                    placeholder="Pick a date"
                    style="width: 100%;"
                  />
                </el-col>
              </el-form-item>
              <el-form-item label="End Times">
                <el-col
                  :xs="24"
                  :sm="24"
                  :md="24"
                  :lg="24"
                  :xl="24"
                >
                  <el-date-picker
                    v-model="organization_details.urgent_date_end"
                    type="datetime"
                    placeholder="Pick a date"
                    style="width: 100%;"
                  />
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
      </el-row>

      <br>
      <br>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'CompanyNotices',
  components: {},
  data() {
    return {
      organization_details: { id: '', name: '' },
      labelPosition: 'right',
      loading: false,
      buttons: [
        'fullsize',
        'source',
        '|',
        'table',
        'image',
        'link',
        '|',
        'bold',
        'underline',
        'strikethrough',
        'italic',
        '|',
        'paragraph',
        'font',
        'fontsize',
        '|',
        'ul',
        'ol',
        '|',
        'undo',
        'redo',
        '|',
        'selectall',
        'cut',
        'copy',
        'paste',
        'copyformat',
        '|',
        'align',
        'outdent',
        'indent',
        '|',
        'hr',
        'brush',
        'eraser',
        'symbol',
        '|',
        'superscript',
        'subscript',
        '|',
        'print',
      ],
    };
  },
  mounted() {
    this.fill_data();
  },
  methods: {
    fill_data() {
      let _this = this;
      this.$http.get('/organization_details').then((response) => {
        _this.organization_details = response.data;
      });
    },
    submit(form_name) {
      let _this = this;

      this.$refs[form_name].validate((valid) => {
        if (valid) {
          _this.loading = true;
          this.$http
            .patch('/organizations/' + _this.organization_details.id, {
              organization: _this.organization_details,
            })
            .then(
              (response) => {
                _this.loading = false;
                if (response.data.status == 200) {
                  this.$message({
                    message: 'Record has been saved.',
                    type: 'success',
                  });
                } else {
                  _this.$message.error('Oops, this is a error message.');
                }
              },
              function(response) {
                _this.loading = false;
                _this.$message.error('Oops, this is a error message.');
              }
            );
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style>
.notice_editor .el-form-item__content {
  line-height: 19px !important;
  margin-left: auto;
  margin-right: auto;
}
.notice_editor .jodit_wysiwyg strong {
  font-weight: 900 !important;
}
</style>
