<template>
  <span>{{ percentage }}</span>
</template>

<script>
export default {
  name: "Percent",
  props: ["dividend", "divisor"],
  computed: {
    percentage() {
      if (
          isNaN(this.dividend) == true || this.dividend == null ||
          isNaN(this.divisor) == true || this.divisor == null || this.divisor == 0
      ) {
        return;
      }

      return ((this.dividend / this.divisor) * 100).toFixed(2) + "%";
    }
  }
}
</script>
