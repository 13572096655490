<template>
  <section>
    <div v-if="errors == false">
      <p v-if="payment_result==false">
        {{ master_payments.length }} master payments calculated.
      </p>
      <p v-else>
        {{ master_payments.length }} master payments calculated and saved.
      </p>
    </div>
    <div
      v-else
      class="error"
    >
      <p>{{ error_cnt }} Rows are wrong.</p>
    </div>

    <div
      v-if="!(payment_result===true && errors===false)"
      class="align__right"
    >
      <el-button
        type="primary"
        plain
        size="small"
        @click="onAddRow"
      >
        Add a row to table
      </el-button>
    </div>

    <el-table
      ref="table"
      :data="master_payments"
      style="width: 100%"
      default-expand-all
    >
      <el-table-column
        v-if="errors===false"
        type="expand"
      >
        <template slot-scope="props">
          <b> Unassigned Payments: {{ props.row.credit_note }} </b>
          <el-table
            :data="props.row.payments"
            stripe
          >
            <el-table-column
              prop="invoice_id"
              label="Invoice ID"
            >
              <template slot-scope="scope">
                <el-link
                  :href="`/invoices/${scope.row.invoice_id}/edit`"
                  target="_blank"
                >
                  {{ scope.row.invoice_id }}
                </el-link>
              </template>
            </el-table-column>
            <el-table-column
              prop="invoice_date"
              label="Invoice Date"
            />
            <el-table-column
              prop="total_price"
              label="Total Price"
            />
            <el-table-column
              prop="accumulate_amount"
              label="Accumulate Payment"
            />
            <el-table-column
              prop="paid_amounts"
              label="Paid Payment"
            />
            <el-table-column
              prop="amount"
              label="Payment"
            />
            <el-table-column
              prop="left"
              label="Left"
            />
          </el-table>
        </template>
      </el-table-column>
      <el-table-column
        v-else
        type="expand"
      >
        <template
          v-if="props.row.is_valid == false"
          slot-scope="props"
        >
          <ul class="error">
            <li
              v-for="(msg,index) in props.row.error_messages['date']"
              :key="index"
            >
              Date {{ msg }}
            </li>
          </ul>

          <ul class="error">
            <li
              v-for="(msg,index) in props.row.error_messages['payment']"
              :key="index"
            >
              Payment {{ msg }}
            </li>
          </ul>

          <ul class="error">
            <li
              v-for="(msg,index) in props.row.error_messages['account_number']"
              :key="index"
            >
              Account Number {{ msg }}
            </li>
          </ul>
        </template>
      </el-table-column>

      <el-table-column
        label="Date"
        prop="date"
      >
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.date"
            placeholder="dd/mm/yyyy"
          />
        </template>
      </el-table-column>
      <el-table-column
        label="Payment"
        prop="payment"
      >
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.payment"
            placeholder="Input number"
          />
        </template>
      </el-table-column>
      
      <el-table-column
        label="Account Number"
        prop="account_number"
      >
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.account_number"
            filterable
            allow-create
            default-first-option
            remote
            placeholder="Type and Select"
            :remote-method="get_accounts"
            @change="onAccountChange(scope.$index)"
          >
            <el-option
              v-for="account in accounts"
              :key="account.id"
              :label="account.account_no"
              :value="account.account_no"
            >
              <span style="float: left">{{ account.account_no }}</span>&nbsp;
              <span style="float: right; color: #8492a6; font-size: 13px">{{ account.account_name }}</span>
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column
        label="Account Name"
        prop="account_name"
      >
        <template slot-scope="scope">
          {{ scope.row.account_name }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="!(payment_result===true && errors===false)"
        align="right"
      >
        <template slot-scope="scope">
          <el-button
            :disabled="master_payments.length <= 1"
            size="mini"
            type="danger"
            @click="onDeleteRow(scope.$index, scope.row)"
          >
            Delete
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </section>
</template>

<script>

export default {
  name: 'MasterPaymentsResult',
  props: {
    master_payments: {
      type: Array,
      required: true
    },
    errors: {
      type: Boolean,
      required: true
    },
    payment_result: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      error_cnt: 0,
      accounts: [],
    };
  },
  mounted() {
    if (this.errors == true) {
      this.master_payments.forEach((mp) => {
        if (mp.is_valid == false) {
          this.error_cnt++;
        }
      });
      this.$nextTick(() => {
        const trs = [].slice.call(document.querySelectorAll('.el-table__row'));
        this.master_payments.forEach((mp, index) => {
          if (mp.is_valid == true) {
            trs[index].querySelector('.el-table__expand-column').style.visibility = 'hidden';
            trs[index].nextSibling.remove();
          }
        });
      });
    }
  },
  methods: {
    onDeleteRow(index) {
      if(this.master_payments.length > 1) {
        // eslint-disable-next-line vue/no-mutating-props
        this.master_payments.splice(index,1);
      }
    },
    onAddRow() {
      // eslint-disable-next-line vue/no-mutating-props
      this.master_payments.push({date:'',payment:'',account_number:''});
      let index = this.master_payments.length - 1;
      this.$nextTick(function () {
        this.set_default_input();
        const trs = [].slice.call(document.querySelectorAll('.el-table__row.expanded'));
        trs[index].querySelector('.el-table__expand-column').style.visibility = 'hidden';
        trs[index].nextSibling.remove();
      });
    },
    onAccountChange(index) {
      for(let i=0; this.accounts.length; i++) {
        if(this.accounts[i].account_no === this.master_payments[index].account_number) {
          // eslint-disable-next-line vue/no-mutating-props
          this.master_payments[index].account_name = this.accounts[i].account_name;
          break;
        }
      }
    },
    set_default_input() {
      Array.from(document.querySelectorAll('.el-input__inner')).forEach(
        function(e) {
          e.classList.add('browser-default');
          if (e.querySelector('input') != null)
            e.querySelector('input').className += ' browser-default';
        }
      );
    },
    get_accounts(term) {
      let encoded_term = encodeURIComponent(term);
      this.$http.get('/autocomplete_accounts_no?term=' + encoded_term).then(
        (response) => {
          this.accounts = response.data.data;
        },
      );
    },
  }
};
</script>

<style lang='scss'>
.error {
  color: red;
}
</style>