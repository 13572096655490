<template>
  <div class="row stocks_list_div">
    <div class="col s12 m12">
      <div class="card grey lighten-5">
        <div class="card-content">
          <span class="card-title">Stock Items list</span> 
          <hr>
          <el-collapse>
            <el-collapse-item
              v-for="stock in show_stock"
              style="border-top: 1px solid lightgray; line-height: 25px;"
              v-bind:key="stock.id"
              :name="stock.id"
              v-bind:style="[ (stock.request != undefined && stock.request.is_approved == 1 ) || stock.mark_as_deleted == 1 ?  { 'text-decoration': 'line-through', 'font-style:': 'italic' } : {  } ]"
            >
              <template slot="title">
                <span style="font-size: 14px">{{stock.stock_name}}</span>
                <div
                  v-if="stock.is_workspace == true && proposal_approved == 0 && stock.is_initiated == 1 "
                >
                  <a
                    data-tooltip="Initiated stock item"
                    class="material-icons dp48 tooltipped"
                  >check_circle</a>
                </div>
              </template>
              <div class="row">
                <div class="col m4 s12">
                  <b>Stock Id:</b>
                  <a
                    target="_blank"
                    :href="'../../../stock_items/' + stock.stock_item_id  + '/edit'"
                  >{{stock.stock_item_id}}</a>
                </div>
                <div class="col m4 s12">
                  <b>Stock Category:</b>
                  {{stock.category}}
                </div>
                <div class="col m4 s12">
                  <b>Stock Supplier:</b>
                  {{stock.supplier}}
                </div>
                <div class="col m4 s12">
                  <b>Quantity:</b>
                  <div
                    v-if="proposal_approved == true"
                    style="display: inline-block;"
                  >{{stock.quantity}}</div>
                  <div
                    style="display: inline-block;"
                    v-else-if="proposal_approved == false  && (stock.is_workspace == false || (stock.is_workspace == true  && stock.is_initiated == 0 ))"
                  >
                    <div
                      style="display: inline-block;"
                      v-if="stock.purchase_order == undefined  || stock.purchase_order == null || stock.purchase_order != undefined && stock.purchase_order.status == 'APPROVED'"
                    >
                      <input
                        v-on:change="update_proposal_stock_qty"
                        type="number"
                        class="stock_item_qty_update"
                        :data-source-type="stock.source"
                        :data-id="stock.id"
                        :data-quantity="stock.quantity"
                        :value="stock.quantity"
                      />
                    </div>
                  </div>
                  <div style="display: inline-block;" v-else>{{stock.quantity}}</div>
                </div>
                <div class="col m4 s12">
                  <b>Used Qty:</b>
                  {{stock.used_qty}}
                </div>
                <div class="col m4 s12">
                  <div v-if="stock.billable_locked== true && proposal_approved == true">
                    <b>Billable</b>
                    <input
                      v-on:change="update_stock_billable($event , stock.id )"
                      type="checkbox"
                      class="filled-in"
                      :name="'n_billable_'+stock.id"
                      :id="'n_billable_'+stock.id"
                      :checked="stock.billable"
                      :data-id="stock.id"
                      :disabled="disabled"
                    />
                  </div>
                  <div v-else-if="proposal_approved == true">
                    <b>Billable</b>
                    <input
                      v-on:change="update_stock_billable($event , stock.id )"
                      type="checkbox"
                      class="filled-in"
                      :name="'n_billable_'+stock.id"
                      :id="'n_billable_'+stock.id"
                      :checked="stock.billable"
                      :data-id="stock.id"
                    />
                    <label :for="'n_billable_'+stock.id"></label>
                  </div>
                </div>
                <div
                  class="col m4 s12"
                  v-if="proposal_approved == false  && (stock.print_calculation_id != undefined || stock.sign_id != undefined) "
                >{{stock.stock_quantity_calculated}}</div>

                <div
                  class="col m4 s12"
                  v-if="proposal_approved == false  && (stock.print_calculation_id != undefined || stock.sign_id != undefined ) "
                >
                  <div
                    v-if="stock.purchase_order != undefined && stock.purchase_order.status == 'APPROVED' || stock.purchase_order == undefined "
                  >
                    <input
                      v-on:change="save_qty_overwritten($event , stock.stock_item_id)"
                      type="checkbox"
                      class="stock_qty_calculated_overwritten filled-in"
                      :name="'n_'+stock.id"
                      :id="'n_'+stock.id"
                      :checked="stock.stock_quantity_calculated_overwritten"
                      :data-id="stock.id"
                    />
                    <label :for="'n_'+stock.id"></label>
                  </div>
                </div>
                <div
                  class="col m4 s12"
                  v-if="proposal_approved == false  && stock.print_calculation_id != undefined "
                >
                  <span v-if="stock.category == 'Paper' && booklet_checked == true ">
                    <input
                      v-on:keyup="update_print_files_per_section(stock.id)"
                      type="number"
                      class="stock_item_print_files_per_section"
                      :data-source-type="stock.source"
                      :data-id="stock.id"
                      :data-quantity="stock.print_files_per_section"
                      :value="booklets(stock.print_files_per_section, stock.id)"
                      :class="booklets(stock.print_files_per_section, -1) == 0  ? 'valid' : 'valid' "
                    />
                  </span>
                </div>
                <div
                  class="col m4 s12"
                  v-if="proposal_approved == false  && stock.print_calculation_id != undefined "
                >
                  <span v-if="stock.category == 'Paper' && booklet_checked == true">
                    <input
                      v-on:keyup="update_files_per_paper(stock.id)"
                      type="number"
                      class="stock_item_files_per_paper"
                      :data-source-type="stock.source"
                      :data-id="stock.id"
                      :data-quantity="stock.files_per_paper"
                      :value="design_files(stock.files_per_paper, stock.id)"
                      :class="design_files(stock.files_per_paper, -1) == 0  ? 'valid' : 'valid' "
                    />
                  </span>
                </div>
                <div class="col m4 s12">
                  <b>Min. packet Qty:</b>
                  {{stock.minimum_packet_quantity}}
                </div>

                <div class="col m4 s12">
                  <b>Thickness:</b>
                  {{stock.thickness}}
                </div>
                <div class="col m4 s12">
                  <b>Unit sale price</b>
                  ${{stock.sale_price}}
                </div>
                <div class="col m4 s12">
                  <b>Total price:</b>
                  ${{stock.total_price > 0 ? stock.total_price : calc_total_price(stock.price,
                  stock.percent_applied, stock.quantity).toFixed(2)}}
                </div>
                <div class="col m4 s12">
                  <b>Percent applied:</b>
                  {{stock.percent_applied}}%
                </div>
                <div class="col m4 s12" v-if="stock.working_file != undefined">
                  <el-button
                    type="text"
                    size="small"
                    v-on:click.prevent="open_working_folder"
                  >{{stock.working_file.name}} [File #{{stock.working_file.id}}]</el-button>
                </div>
                <div class="col m4 s12" v-if="stock.purchase_order != undefined">
                  <b>PO #:</b>
                  <a :href="get_po_link(stock.purchase_order.id)">
                    {{
                    stock.purchase_order.id}}
                  </a>
                  <br />
                  <b>Qty:</b>
                  {{ stock.purchase_order.quantity }}
                  <br />
                  <b>Status:</b>
                  <span>{{ stock.purchase_order.status }}</span>
                  <br />
                  <b>From Stock:</b>
                  <span>{{ calc_qty_from_stock(stock.quantity, stock.purchase_order.quantity) }}</span>
                </div>
                <div class="col m12 s12">
                  <div
                    class="right"
                    v-if="stock.is_workspace == true && proposal_approved == 0 && stock.is_initiated == 0 "
                  >
                    <button
                      v-on:click.prevent="initiate_stock_item(get_stock_category(stock), stock.id)"
                      class="btn btn-small tooltipped"
                      data-position="bottom"
                      data-tooltip="Initiate stock and create PO if out of stock"
                    >Initiate</button>
                  </div>
                  <div
                    v-if="proposal_completed == false && stock.mark_as_deleted == 0 "
                    class="right"
                  >
                    <div
                      v-if="stock.purchase_order != undefined && stock.purchase_order.status == 'PENDING' || stock.purchase_order == undefined "
                    >
                      <div v-if="stock.sign_id != undefined ">
                        <div
                          v-if="stock.is_additional == undefined || stock.is_additional == 1 || ( stock.is_additional == 0 && proposal_approved == true)"
                        >
                          <button
                            v-on:click.prevent="delete_stock_item( 'signage', stock.id)"
                            class="btn btn-small delete"
                          >Delete</button>
                        </div>
                      </div>
                      <div v-else-if="stock.print_calculation_id != undefined ">
                        <button
                          v-on:click.prevent="delete_stock_item('print', stock.id)"
                          class="btn btn-small delete"
                        >Delete</button>
                      </div>
                      <div v-else>
                        <button
                          v-on:click.prevent="delete_stock_item('default', stock.id)"
                          class="btn btn-small delete"
                        >Delete</button>
                      </div>
                    </div>
                    <div
                      v-if="stock.purchase_order != undefined && (stock.purchase_order.status == 'APPROVED' || stock.purchase_order.status == 'RECEIVED') && stock.request == undefined && stock.used_qty == 0 "
                    >
                      <div v-if="stock.sign_id != undefined ">
                        <button
                          v-on:click.prevent="request_delete_stock_item( 'signage', stock.id)"
                          class="btn btn-small"
                          style="min-width: 125px;"
                        >Request Delete</button>
                      </div>
                      <div v-else-if="stock.print_calculation_id != undefined ">
                        <button
                          v-on:click.prevent="request_delete_stock_item('print', stock.id)"
                          style="min-width: 125px;"
                          class="btn btn-small"
                        >Request Delete</button>
                      </div>
                      <div v-else>
                        <button
                          v-on:click.prevent="request_delete_stock_item('default', stock.id)"
                          style="min-width: 125px;"
                          class="btn btn-small"
                        >Request Delete</button>
                      </div>
                    </div>
                    <div v-if="stock.request != undefined ">
                      <div v-if="stock.request.is_approved == 0 ">
                        <span>Delete Request submitted</span>
                      </div>
                      <div v-else-if="stock.request.is_approved == 1 ">
                        <span>Delete Request approved</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div class="card-action">
          <div class="row">
            <div v-if="proposal_completed == false">
              <div class="col s12 m2">
                <label for="stock_quantity">Quantity:</label>
                <el-input-number
                  size="small"
                  name="stock_quantity"
                  id="stock_quantity"
                  v-model="stock_quantity"
                ></el-input-number>
              </div>
              <div class="col s12 m2">
                <label for="sale_price">Unit Sale price:</label>
                <el-input-number
                  size="small"
                  name="sale_price"
                  id="sale_price"
                  v-model="sale_price"
                ></el-input-number>
              </div>
              <div class="col s12 m8">
                <multiselect
                  v-model="selected"
                  :options="options"
                  :close-on-select="true"
                  label="name"
                  track-by="id"
                  :max="2"
                  :searchable="true"
                  :multiple="true"
                  :loading="is_loading"
                  placeholder="search or click to add stock items"
                  @select="current_selected_value"
                  @search-change="find_subcategories"
                ></multiselect>
              </div>
              <div class="col s6 m3">
                <a @click="show_new_stock_item($event)" class="btn">new</a>
              </div>
              <div class="col s6 m8">
                <button
                  v-on:click.prevent="save_stock_item"
                  id="save_stock_item"
                  class="btn right save"
                >save</button>
              </div>
              <div class="col s12 m3">
                <button
                  style="display: none"
                  v-on:click.prevent="stock_list"
                  class="stock_refresh btn"
                >Refresh</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { eventBus } from "packs/main";

export default {
  name: "StockItem",
  components: { Multiselect },
  props: ["sign_id", "print_props"],
  data() {
    return {
      selected: [],
      options: [],
      main_categories: [],
      print_files_per_section: 0,
      files_per_paper: 0,
      is_loading: false,
      print_calculation_id:
        document.getElementById("print_calculation_id") == null
          ? null
          : document.getElementById("print_calculation_id").value,
      proposal_id: document.getElementById("proposal_id").value,
      branch_id: document.getElementById("proposal_branch_id").value,
      proposal_approved:
        document.getElementById("proposal_approved").type == "checkbox"
          ? document.getElementById("proposal_approved").checked
          : document.getElementById("proposal_approved").value,
      proposal_completed:
        document.getElementById("proposal_completed").value == 1,
      stock_item_id: null,
      show_stock: [],
      stock_quantity: 1,
      sale_price: 0,
      stock_quantity_calculated_overwritten: null,
      booklet_checked: this.print_props.booklet_checked,
      booklet_pages: 0,
      amount_of_files: 0
    };
  },
  computed: {
    cravings: function() {
      return this.content["cravings"]
        ? "fa-checkbox-marked"
        : "fa-checkbox-blank-outline";
    }
  },
  created: function() {
    this.$modal.show("example");
    this.categories();
    this.stock_list();
    this.set_inputs_to_default();

    eventBus.$on("print_files_per_section", print_files_per_section => {
      this.print_files_per_section = print_files_per_section;
    });

    eventBus.$on("files_per_paper", files_per_paper => {
      this.files_per_paper = files_per_paper;
    });

    eventBus.$on("stock_quantity", stock_quantity => {
      this.stock_quantity = stock_quantity;
    });

    eventBus.$on("booklet_pages", (booklet_pages, booklet_checked) => {
      this.booklet_checked = booklet_checked;
      this.booklet_pages = booklet_pages;
    });

    eventBus.$on("booklet_checked", booklet_checked => {
      this.booklet_checked = booklet_checked;
    });

    eventBus.$on("amount_of_files", amount_of_files => {
      this.amount_of_files = amount_of_files;
    });

    document
      .querySelectorAll(".stock_container [type=submit]")
      .forEach(function(item, index) {
        item.addEventListener("click", function(e) {
          if (
            $(e.currentTarget)
              .val()
              .replace(/ /g, "") == "SaveStockItem"
          )
            return true;

          e.preventDefault();
          var container = $(this).closest(".stock_container");
          var qty = container.find("#stock_quantity").val();
          var type = container.attr("data-type");
          var id = container.attr("data-id");
          var submit_btn = this;
          var params = [
            {
              name: "selected_stock_item[quantity]",
              value: qty
            },
            {
              name: "selected_stock_item[proposal_type]",
              value: type
            },
            {
              name: "selected_stock_item[id]",
              value: id
            }
          ];

          $.each(params, function(i, param) {
            $('[name="' + param.name + '"]').remove();
            $(".edit_proposal").append(
              $.map(params, function(param) {
                return $("<input>", {
                  type: "hidden",
                  name: param.name,
                  value: param.value
                });
              })
            );
          });
          $(".edit_proposal").append(
            $("<input>", {
              type: "hidden",
              name: "commit",
              value: "create stock item"
            })
          );

          console.log("finish");
          e.stopPropagation();
          $(".edit_proposal").submit();
        });
      });
  },

  methods: {
    open_working_folder() {
      document
        .getElementById("filemanager-button")
        .setAttribute("data-search", "/job assets/working");
      document.getElementById("filemanager-button").click();
    },
    set_inputs_to_default() {
      let _this = this;
      setTimeout(() => {
        Array.from(
          document.querySelectorAll(".stocks_list_div .el-input")
        ).forEach(function(e, i) {
          e.classList.add("browser-default");
          if (e.querySelector("input") != null)
            e.querySelector("input").className += " browser-default";
        });
      }, 300);
    },
    calc_total_price: function(price, percent, quantity) {
      var tp = 0.0;
      if (quantity > 0 && price > 0) {
        tp = parseFloat(price) * quantity;
      }

      if (percent > 0 && tp > 0) {
        tp = parseFloat(tp) + (parseFloat(tp) / 100) * percent;
      }
      return tp;
    },
    get_stock_category(stock) {
      if (stock.sign_id != undefined) return "signage";
      else if (stock.print_calculation_id != undefined) return "print";
      else return "default";
    },
    calc_qty_from_stock(qty, po_qty) {
      var from_stock = qty - po_qty;
      return from_stock < 0 ? 0 : from_stock;
    },
    get_po_link(id) {
      return "/purchase_orders/" + id + "/edit";
    },
    categories: function() {
      let self = this;
      this.$http.get("/s_categories").then(
        response => {
          self.options = response.data;
          self.main_categories = response.data;
        },
        function(response) {
          console.log(response);
        }
      );
    },
    booklets: function(print_files_per_section, id) {
      if (this.booklet_checked == false) {
        this.booklet_checked = this.print_props.booklet_checked;
      }

      if (
        this.booklet_checked &&
        (print_files_per_section == 0 ||
          print_files_per_section == undefined) &&
        id != -1
      ) {
        if (this.booklet_pages == 0 && this.booklet_checked) {
          this.booklet_pages = this.print_props.booklet_pages;

          this.$http.post("/update_print_files_per_section", {
            print_calculation_stock_item_id: id,
            print_files_per_section: this.booklet_pages
          });
        }

        return this.booklet_pages;
      } else {
        return print_files_per_section;
      }
    },
    design_files: function(files_per_paper, id) {
      if (files_per_paper == undefined && id != -1) {
        if (this.amount_of_files == undefined) {
          this.amount_of_files = this.print_props.amount_of_files;
        }
        this.$http.post("/update_files_per_paper", {
          print_calculation_stock_item_id: id,
          files_per_paper: this.files_per_paper
        });

        return this.amount_of_files;
      } else {
        return files_per_paper;
      }
    },
    load_favorite_stock(category_id) {
      let _this = this;
      this.$http
        .post("/favorite_stocks_by_category", {
          category_supplier_id: category_id,
          branch_id: document.getElementById("proposal_branch_id").value
        })
        .then(response => {
          _this.options = response.data;
        });
    },
    current_selected_value(selectedValue) {
      let self = this;
      if (selectedValue.main_category != undefined) {
        //this.options = [];
        this.load_favorite_stock(selectedValue.id);
      }
      if (this.selected != null) {
        if (this.selected.length == 1) {
          this.stock_item_id = selectedValue.id;
          this.sale_price = selectedValue.sale_price;
          this.disabled = "false";
          // self.save_stock_item();
        }
      }
    },
    show_new_stock_item: function(event) {
      var ctrl = $(event.currentTarget)
        .closest(".stock_container")
        .find(".stock_partial_form");
      ctrl.slideToggle();
      $("html,body").animate({
        scrollTop: $(ctrl).offset().top
      });
    },
    stock_list: function() {
      let self = this;
      var params = {};
      if (self.sign_id != null) params = { sign_id: self.sign_id };
      else if (self.print_calculation_id != null)
        params = { print_calculation_id: self.print_calculation_id };
      else
        params = { proposal_id: self.proposal_id, branch_id: document.getElementById("proposal_branch_id").value };
      this.$http
        .post("/stock_items_list", params)
        .then(
          response => {
            if (response.data.status == 200) {
              console.log(response.data.data);

              self.show_stock = response.data.data;
            } else {
              self.show_stock = [];
            }
          },
          function(response) {
            console.log(response);
          }
        );
    },
    save_stock_item: function() {
      let self = this;

      if (self.stock_item_id != null && self.stock_quantity > 0) {
        this.$http
          .post("/save_stock_item", {
            id: self.proposal_id,
            stock_item_id: self.stock_item_id,
            print_files_per_section: self.print_files_per_section,
            files_per_paper: self.files_per_paper,
            print_calculation_id: self.print_calculation_id,
            proposal_id: self.proposal_id,
            sign_id: self.sign_id,
            stock_quantity: self.stock_quantity,
            sale_price: self.sale_price
          })
          .then(
            response => {
              if (response.status == 200) {
                console.log(response.data);
                self.show_stock = this.stock_list();
                self.stock_item_id = null;
                self.selected = null;
                this.categories();
              } else {
                alert(response.message);
              }
            },
            function(response) {
              console.log(response);
            }
          );
      } else {
        console.log("error");
      }
    },
    save_qty_overwritten: function(event, stock_item_id) {
      let self = this;

      this.$http.post("/save_qty_overwritten", {
        stock_item_id: stock_item_id,
        print_calculation_id: self.print_calculation_id,
        sign_id: self.sign_id,
        stock_quantity_calculated_overwritten: event.target.checked
      });
    },
    update_stock_billable: function(event, id, stock_type) {
      let self = this;
      stock_type = "";
      if (self.print_calculation_id != undefined)
        stock_type = "PrintCalculationStockItem";
      else if (self.sign_id != undefined) stock_type = "SignStockItem";
      else stock_type = "ProposalStockItem";

      this.$http.post("/update_stock_billale", {
        id: id,
        stock_type: stock_type,
        billable: event.target.checked ? 1 : 0
      });
    },
    update_print_files_per_section: function(id) {
      this.$http.post("/update_print_files_per_section", {
        print_calculation_stock_item_id: id,
        print_files_per_section: event.target.value
      });
    },
    update_files_per_paper: function(id) {
      this.$http.post("/update_files_per_paper", {
        print_calculation_stock_item_id: id,
        files_per_paper: event.target.value
      });
    },
    show_stock_item_form: function() {
      //document.getElementById('stock').style.display = 'block';
      $("#new_stock_item").fadeOut();
    },
    update_proposal_stock_qty: function(event) {
      if (event.target.value == $(event.target).attr("data-quantity")) {
        return false;
      }
      //Update QTY
      var js = {
        id: $(event.target).attr("data-id"),
        source_type: $(event.target).attr("data-source-type"),
        qty: event.target.value
      };
      $.ajax({
        type: "POST",
        url: "/update_stock_item_qty",
        dataType: "json",
        data: js,
        success: function(msg) {
          //alert(msg.message);
        }
      });
    },
    delete_stock_item: function(type, id) {
      //if (confirm("Are you sure to delete the stock item ?") == false)
      //return false;
      this.$http.delete(`/delete_proposal_stock_item/${type}/${id}`).then(
        response => {
          self.show_stock = this.stock_list();

          location.reload();
        },
        function(response) {
          console.log(response);
        }
      );
    },
    initiate_stock_item: function(type, id) {
      if (
        confirm(
          "Are you sure to initiate the stock item, stock will be allocated & PO will be generated if out of stock ?"
        ) == false
      )
        return false;

      this.$http
        .post("/initiate_stock_item", {
          type: type,
          id: id
        })
        .then(
          response => {
            self.show_stock = this.stock_list();
          },
          function(response) {
            console.log(response);
          }
        );
    },
    request_delete_stock_item: function(type, id) {
      if (confirm("Are you sure to delete the stock item ?") == false)
        return false;
      this.$http.delete(`/request_delete_stock_item/${type}/${id}`).then(
        response => {
          self.show_stock = this.stock_list();
        },
        function(response) {
          console.log(response);
        }
      );
    },
    internal_value(internalValue) {
      let self = this;
      if (internalValue.length === 0) {
        this.categories();
      }
      self.is_loading = true;
      if (internalValue.length === 1) {
        this.$http
          .post("/category_products", {
            category_supplier_id: internalValue[0].id,
            branch_id: document.getElementById("proposal_branch_id").value
          })
          .then(
            response => {
              self.is_loading = false;
              self.options = response.data;
            },
            function(response) {
              self.is_loading = false;
              console.log(response);
            }
          );
      }
    },

    find_subcategories(internalValue) {
      if (this.selected.length == 0) {
        this.options = this.main_categories;
      } else if (this.options.length == 0 && this.selected.length == 0) {
        this.categories();
        return;
      } else if (this.selected.length == 0 && this.options.length > 0) {
        return;
      }

      let self = this;

      self.is_loading = true;
      if (internalValue.length > 2) {
        this.$http
          .post("/category_products", {
            category_supplier_id: self.selected[0].id,
            term: internalValue,
            branch_id: document.getElementById("proposal_branch_id").value
          })
          .then(
            response => {
              self.is_loading = false;
              self.options = response.data;
            },
            function(response) {
              self.is_loading = false;
              console.log(response);
            }
          );
      } else {
        self.options = [];
      }
    }
  }
};
</script>

<style>
button.delete {
  background-color: #d9534f !important;
}
</style>
