<template>
  <div v-loading="loading">
    <el-row>
      <el-col
        v-for="list in allocated_list"
        :key="list.id"
        :xs="24"
        :sm="24"
        :md="24"
        :lg="24"
        :xl="24"
      >
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
          style="background:#999; color:#fff; font-size:20px;padding:10px;"
        >
          <div
            style="text-align:left;"
            class="left"
          >
            Branch: {{ list.branch_name }}
          </div>
          <div class="right">
            Total allocated:  {{ list.total_qty }}
          </div>
        </el-col>
      
        
        <div
          v-for="indiv in list.list"
          :key="indiv.id"
          :style="'border:1px solid #f3f3f3; padding:5px; color:' + indiv.color "
        > 
          <el-row>
            <el-col
              :xs="4"
              :sm="4"
              :md="2"
              :lg="2"
              :xl="2"
            >
              <b><a
                :style="'padding:0px;color:' + indiv.color "
                :href="'/jobs_tasks_for_production/' + indiv.account_id + '/' + indiv.proposal_id + '/0'"
                target="blank"
              >{{ indiv.proposal_id }}</a></b> 
            </el-col>
            <el-col
              :xs="20"
              :sm="20"
              :md="4"
              :lg="4"
              :xl="4"
            >
              <!-- <span class="listtext"> {{ indiv.branch_name }}</span> -->
              <span class="listtext"> {{ indiv.account_name }}</span>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="9"
              :lg="9"
              :xl="9"
            >
              <span class="listtext"> {{ indiv.job_name }} </span> <br>
              <span
                class="listtext"
                style="font-size:12px;"
              > <b>{{ indiv.task_name }} </b></span>
            </el-col>
            <el-col
              :xs="12"
              :sm="12"
              :md="3"
              :lg="3"
              :xl="3"
            >
              <span class="listtext"> QTY: {{ indiv.quantity }} </span> <br>

              <span
                v-show="indiv.po_qty"
                class="listtext"
              > ({{ indiv.po_qty }} from PO)</span>
            </el-col>
            <el-col
              v-show="indiv.po_id"
              :xs="12"
              :sm="12"
              :md="3"
              :lg="3"
              :xl="3"
            >
              <span class="listtext"> <a
                :style="'padding:0px;color:' + indiv.color "
                :href="'/purchase_orders/' + indiv.po_id + '/edit'"
                target="blank"
              > PO: {{ indiv.po_id }} </a> </span>
            </el-col>
            <el-col
              v-show="indiv.partially_allocated"
              :xs="24"
              :sm="24"
              :md="3"
              :lg="3"
              :xl="3"
            >
              <span><b>{{ indiv.partially_allocated }}</b> Part Allocated</span>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'StockAllocatedOpenJobs',
  props: ['stock_id'],
  data() {
    return {
      loading: false,
      allocated_list: [],
    };
  },
  computed: {},
  watch: {
    stock_id: function(newValue) {
      this.load_allocated_list();
    }
  },
  mounted() {    
  },
  methods: {
    load_allocated_list() {
      this.loading = true;      
      this.$http
        .get(`/stock_allocated_open_jobs/${this.stock_id}`)
        .then((response) => {
          this.allocated_list = response.data;
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
a {
  padding: 5.5px 12px;
  display: block;
}
.router-link-active {
  background: white;
  padding: 5.5px 12px;
}
.nav-menu {
  color: #fff;
}
.listtext {
  padding: 1px 3px;

}
</style>
