<template>
  <div id="pay-rates">
    <el-row>
      <el-col :span="12">
        <p>Pay Rates</p>
      </el-col>
      <el-col :span="12">
        <el-button
          size="mini"
          class="right"
          type="primary"
          plain
          @click="show_dialog = true"
        >
          Add Pay Rate
        </el-button>
      </el-col>
    </el-row>
    <el-table
      :data="pay_rates"
      stripe
    >
      <el-table-column
        prop="effective_date"
        label="Effective date"
        width="160"
      />
      <el-table-column
        prop="end_date"
        label="End date"
        width="160"
      />
      <el-table-column
        prop="rate"
        label="Rate per hour"
        width="160"
      >
        <template #default="scope">
          <money :amount="scope.row.rate" />
        </template>
      </el-table-column>
      <el-table-column width="160">
        <template #default="scope">
          <el-button
            size="mini"
            type="danger"
            plain
            @click="delete_pay_rate(scope.row.id)"
          >
            Delete
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="New Pay Rate"
      :visible.sync="show_dialog"
      width="30%"
      @opened="set_default_input()"
    >
      <el-form
        ref="pay_rate"
        :model="pay_rate"
        label-width="140px"
        :rules="rules"
      >
        <el-form-item
          label="Effective date"
          prop="effective_date"
          required
        >
          <el-date-picker
            v-model="pay_rate.effective_date"
            placeholder="Effective date"
            format="dd.MM.yyyy"
            size="small"
          />
        </el-form-item>
        <el-form-item
          label="Rate"
          prop="rate"
          required
        >
          <el-input-number
            v-model="pay_rate.rate"
            size="small"
            :precision="2"
            :step="1"
            :max="500"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            size="small"
            type="primary"
            @click="create_pay_rate"
          >
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import Money from '../shared/Money';
import {pay_rates, create_pay_rate, delete_pay_rate} from '../../api/pay_rates';
export default {
  name: 'CrmUserPayRates',
  components: {Money},
  props: {
    crm_user_id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      pay_rate: { rate: 0.0, crm_user_id: this.crm_user_id},
      pay_rates: [],
      show_dialog: false,
      rules: {
        rate: [{ required: true, message: ' ' }],
        effective_date: [{ required: true, message: ' ' }]
      }
    };
  },
  created() {
    this.user_pay_rates();
  },
  methods: {
    user_pay_rates(){
      pay_rates(this).then((response)=> {
        this.pay_rates = response.data;
      });
    },
    create_pay_rate(){
      this.$refs['pay_rate'].validate((valid) => {
        if (valid) {
          create_pay_rate(this, this.pay_rate).then((response) => {
            this.$message({
              type: 'success',
              message: response.data.message
            });
            this.user_pay_rates();
            this.show_dialog = false;
          });
        }
      });
    },
    delete_pay_rate(id){
      delete_pay_rate(this, id, this.pay_rate.crm_user_id).then((response)=> {
        this.$message({
          type: 'success',
          message: response.data.message
        });
        this.user_pay_rates();
      });
    },
    set_default_input() {
      Array.from(document.querySelectorAll('.el-select__input, .el-input__inner')).forEach(
        function (e) {
          e.classList.add('browser-default');
          if (e.querySelector('input') != null)
            e.querySelector('input').className += ' browser-default';
        }
      );
    },
  },
};
</script>

<style scoped>
#pay-rates /deep/ .el-dialog {
  width: 550px !important;
}
#pay-rates /deep/ .el-input{
  width: inherit;
}
</style>