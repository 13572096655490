export default class LinkedList {
  constructor() {
    this.head = null;
    this.tail = null;
  }

  // insert node at the end of queue
  push(data) {
    let newNode = new Node(data);
    if (!this.head) {
      this.head = newNode;
      return this.head;
    }

    let tail = this.head;
    while (tail.next !== null)
      tail = tail.next;
    tail.next = newNode;
    return this.head;
  }

  // function used to remove last node in a linkedlist
  pop() {
    if (!this.head)
      return null;
    if (!this.head.next) {
      this.head = null;
      return;
    }
		
    let previous = this.head;
    let tail = this.head.next;

    while (tail.next !== null) {
      previous = tail;
      tail = tail.next;
    }
    previous.next = null;
    return this.head;
  }
	
  // check if linkedlist is empty
  isEmpty() {
    return this.head == null;
  }

  // get last node
  last() {
    if (!this.head)
      return null;
    let last = this.head;

    while (last.next !== null)
      last = last.next;
    return last;
  }

  clear() {
    this.head = null;
    this.tail = null;
  }

}

class Node {
  constructor(data, next = null) {
    this.data = data;
    this.next = next;
  }
}