<template>
  <div>
    <el-collapse>
      <el-collapse-item
        v-for="note in page_notes"
        :key="note.id"
        v-model="page_notes"
        :title="note.name"
        :name="note.id"
      >
        <template slot="title">
          <span>
            {{ note.name }}
            <i
              v-show="note.note && note.note.length > 0"
              class="el-icon-chat-line-round"
            />
          </span>
        </template>
        <div>
          <el-row>
            <el-col>
              <div
                class="margin-left"
                v-html="note.note_truncated"
              />
              <el-button
                type="primary"
                size="mini"
                class="right"
                @click="show_page_note_dialog = true, current_note.note = note.note, current_note.id = note.id"
              >
                Edit / Read
              </el-button>
            </el-col>
          </el-row>
        </div>
        <div>
          <el-dialog
            title="Edit Page Note"
            :visible.sync="show_page_note_dialog"
            width="50%"
            append-to-body
            :close-on-click-modal="false"
          >
            <jodit-editor
              id="signature_editor"
              ref="signature_editor"
              v-model="current_note.note"
              :config="jodit_config"
            />
            <span
              slot="footer"
              class="dialog-footer"
            >
              <el-button
                type="success"
                size="mini"
                @click="edit_working_file_pages_notes(), show_page_note_dialog = false"
              >
                Save
              </el-button>
            </span>
          </el-dialog>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>

import {working_file_notes, update_note} from '../../api/working_files.js';

export default {
  name: 'PageNotes',
  props: {
    proposal_id: {
      type: [String,Number],
      required: true
    }
  },
  data() {
    return {
      page_notes: [],
      show_page_note_dialog: false,
      current_note: {id: '', note: '', note_truncated: ''},
      jodit_config : {
        enter: 'br',
      },
    };
  },
  created() {
    this.working_file_pages_notes();
  },
  methods: {
    working_file_pages_notes(){
      working_file_notes(this, this.proposal_id).then((response)=> {
        this.page_notes = response.data;
      });
    },
    edit_working_file_pages_notes(){
      update_note(this, this.current_note.id, this.current_note.note).then((response)=> {
        this.$message({
          message: response.data.message,
          type: 'success',
        });
        this.working_file_pages_notes();
      });
    }
  }
};
</script>
<style scoped>
.right{float:right;}
.margin-left{ margin-left: 10px;}
</style>