<template>
  <div>
    <el-button
      size="mini"
      class="right"
      @click="fill_data"
    >
      Refresh
    </el-button>
    <el-table
      v-loading="loading"
      :data="tasks"
    >
      <el-table-column
        prop="proposal_id"
        label="Job No"
      >
        <template slot-scope="scope">
          <el-link
            :href="`/job_view?id=${scope.row.proposal_id}`"
            target="_blank"
          >
            {{ scope.row.proposal_id }}
          </el-link>
        </template>
      </el-table-column>
      <el-table-column
        prop="task_name"
        label="Task name"
      />
      <el-table-column
        prop="job_name"
        label="Job"
      />
      <el-table-column
        prop="start_date"
        label="Start"
      />
      <el-table-column
        prop="time_allocated_labour"
        label="Time Allocated"
      >
        <template slot-scope="scope">
          <Time :is_hours="scope.row.is_hours" :minutes="scope.row.time_allocated_labour" />
        </template>
      </el-table-column>
      <el-table-column label="Action">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="assign_to_me(scope.row)"
          >
            Assign to me
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import Time from '../shared/Time'

export default {
  components: { Time },
  data() {
    return {
      loading: false,
      tasks: []
    };
  },
  mounted() {
    this.fill_data();
  },
  methods: {
    fill_data() {
      // get active future tasks 
      this.loading = true;
      this.$http.get('active_future_tasks_by_skills').then(response => {
        this.tasks = response.data;
        this.loading = false;
      }, reason => {
        this.loading = false;
      });
    },
    assign_to_me(task) {
      this.$confirm(
        'This will assign the selected task to you. Continue?',
        'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      ).then(
        (response) => {
          this.$http.patch(`/assign_task_to_me/${task.id}`).then((response) => {
            this.$message({
              type: 'success',
              message: 'Task has been assigned to you',
            });
            this.fill_data();
          });
        },
        (response) => {}
      );
    }
  }
};
</script>