var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.working_file_categories.length > 0)?_c('div',[_c('Categories',{attrs:{"editable":!(_vm.proposal.approved === 1),"category_id":"editSummaryCategoryID"},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return _vm._l((_vm.category_grouped_files(slotProps.category.id)),function(cgrp){return _c('div',{key:cgrp.id},[_c('SummaryWorkingFileEdit',{attrs:{"working_file_info":cgrp,"working_file_id":cgrp.id}})],1)})}}],null,false,1740454182)}),_vm._v(" "),(_vm.main_list)?[_c('h2',[_vm._v("Not Category List - No Working File")]),_vm._v(" "),_c('SummaryWorkingFileEdit',{attrs:{"working_file_info":_vm.main_list,"working_file_id":_vm.main_list.id}})]:_vm._e()],2):_vm._l((_vm.grouped_files.sort(function(a, b) {
      return a.position - b.position;
    })),function(grp){return _c('div',{key:grp.id,staticStyle:{"padding":"20px","border-radius":"20px","border":"3px solid #999"}},[_c('SummaryWorkingFileEdit',{attrs:{"working_file_info":grp,"working_file_id":grp.id}})],1)}),_vm._v(" "),_c('br'),_vm._v(" "),_c('table',{staticClass:"jobs_stocks_summary",staticStyle:{"width":"100%"}},[_c('tbody',[_c('tr',[_c('td',[_vm._v("STOCKS TOTAL")]),_vm._v(" "),_c('td',{attrs:{"width":"120"}},[_vm._v("$"+_vm._s(_vm.stock_items_total_sale))]),_vm._v(" "),_c('td',{attrs:{"width":"120"}})]),_vm._v(" "),_c('tr',[_c('td',[_vm._v("TASKS TOTAL")]),_vm._v(" "),_c('td',{attrs:{"width":"120"}},[_vm._v("$"+_vm._s(_vm.job_proposals_total_sale))]),_vm._v(" "),_c('td',{attrs:{"width":"120"}})]),_vm._v(" "),_c('tr',[_c('td',[_vm._v("CHARGE POs TOTAL")]),_vm._v(" "),_c('td',{attrs:{"width":"120"}},[_vm._v("$"+_vm._s(_vm.charge_pos_total_sale))]),_vm._v(" "),_c('td',{attrs:{"width":"120"}})])]),_vm._v(" "),_c('tfoot',[_c('tr',{class:{
          crossed_text: _vm.proposal_summary.is_matched_quote,
          borderd_row: 1 == 1,
        }},[_c('td',[_vm._v("TOTAL SALE")]),_vm._v(" "),_c('td',{attrs:{"width":"120"}}),_vm._v(" "),_c('td',{attrs:{"width":"120"}},[_vm._v("\n        $"+_vm._s(_vm.stock_items_total_sale +
        _vm.job_proposals_total_sale +
        _vm.charge_pos_total_sale)+"\n      ")])]),_vm._v(" "),_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.proposal_summary.is_matched_quote == true),expression:"proposal_summary.is_matched_quote == true"}],staticClass:"borderd_row"},[_c('td',[_vm._v("MATCHED QUOTE")]),_vm._v(" "),_c('td',{attrs:{"width":"120"}}),_vm._v(" "),_c('td',{attrs:{"width":"120"}},[_vm._v("$"+_vm._s(_vm.proposal_summary.total_matched_quote))])]),_vm._v(" "),_c('tr',{staticStyle:{"color":"rgb(247, 137, 137)"}},[_c('td',[_vm._v("STOCK COST")]),_vm._v(" "),_c('td',{attrs:{"width":"120"}},[_vm._v("- $"+_vm._s(_vm.stock_items_total_cost))]),_vm._v(" "),_c('td',{attrs:{"width":"120"}})]),_vm._v(" "),_c('tr',{staticStyle:{"color":"rgb(247, 137, 137)"}},[_c('td',[_vm._v("TASKS COST")]),_vm._v(" "),_c('td',{attrs:{"width":"120"}},[_vm._v("- $"+_vm._s(_vm.job_proposals_total_cost))]),_vm._v(" "),_c('td',{attrs:{"width":"120"}})]),_vm._v(" "),_c('tr',{staticStyle:{"color":"rgb(247, 137, 137)"}},[_c('td',[_vm._v("TASKS COST")]),_vm._v(" "),_c('td',{attrs:{"width":"120"}},[_vm._v("- $"+_vm._s(_vm.charge_pos_total_cost))]),_vm._v(" "),_c('td',{attrs:{"width":"120"}})]),_vm._v(" "),_c('tr',{staticClass:"borderd_row",staticStyle:{"color":"rgb(247, 137, 137)"}},[_c('td',[_vm._v("TOTAL COST")]),_vm._v(" "),_c('td',{attrs:{"width":"120"}}),_vm._v(" "),_c('td',{attrs:{"width":"120"}},[_vm._v("\n        - $"+_vm._s(_vm.round(
        _vm.job_proposals_total_cost +
        _vm.stock_items_total_cost +
        _vm.charge_pos_total_cost
        ))+"\n      ")])]),_vm._v(" "),_c('tr',{staticStyle:{"color":"rgb(114, 185, 118)"}},[_c('td',[_vm._v("GROSS PROFIT")]),_vm._v(" "),_c('td',{attrs:{"width":"120"}}),_vm._v(" "),_c('td',{attrs:{"width":"120"}},[_vm._v("$"+_vm._s(_vm.gross_profit))])])])]),_vm._v(" "),_c('br')],2)
}
var staticRenderFns = []

export { render, staticRenderFns }