<template>
  <div id="wages">
    <!--    <p>Total hours worked: {{ earnings.total_hours }}</p>-->
  </div>
</template>
<script>
import{hourly_wage_rate} from '../../../api/employment_gross_payments';
export default {
  name: 'Wages',
  components: {},
  props: {
    crm_user_id: {
      type: String,
      required: true
    },
    pay_period_start: {
      type: String,
      required: true
    },
    pay_period_end: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      earnings: []
    };
  },
  created() {
    this.hourly_wage_rate();
  },
  methods: {
    hourly_wage_rate(){
      hourly_wage_rate(this, this.crm_user_id, this.pay_period_start, this.pay_period_end).then((response)=> {
        this.earnings = response.data;
      });
    },
  }
};
</script>
<style scoped>
  #wages {margin-left: 35px;}
</style>
