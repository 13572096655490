import {Request} from '../utilities/Request';


export function createTaskTimeSpent(this_obj, crm_user_id, job_proposal_id, time_in_minutes, time_sheet_id) {
  return Request.post(
    this_obj,
    `/api/time_sheets/${time_sheet_id}/task_time_spents`,
    null,
    {
      job_proposal_id: job_proposal_id,
      time_in_minutes: time_in_minutes,
      crm_user_id: crm_user_id,
    }
  );
}

export function loadTaskTimeSpent(this_obj, time_sheet_id) {
  return Request.get(this_obj, `/api/time_sheets/${time_sheet_id}/task_time_spents`);
}

export function deleteTaskTimeSpent(this_obj, id) {
  return Request.delete(
    this_obj,
    `/api/task_time_spents/${id}`
  );
}

export function updateTaskTimeSpent(this_obj, id, time_in_minutes) {
  return Request.patch(
    this_obj,
    `/api/task_time_spents/${id}`,
    null,
    {
      time_in_minutes: time_in_minutes
    }
  );
}

export function loadTaskTimeSpentLists(this_obj, proposal_id) {
  return Request.get(this_obj, `/api/proposals/${proposal_id}/times_spents_lists`);
}


