<template>
  <el-dialog
    title="Workspace jobs to do"
    :visible.sync="naugthy_modal"
    width="50"
    height="50"
    :lock-scroll="true"
    :adaptive="true"
  >
    <ul id="list">
      <li
        v-for="item in list"
        :key="item.id"
      >
        <a :href="'/edit_proposal?id=' + item.id">{{ item.name }}</a>
      </li>
    </ul>
  </el-dialog>
</template>

<script>
export default {
  name: 'CheckWorkspace',
  data() {
    return {
      naugthy_modal: false,
      today: new Date(),
      list: [],
    };
  },
  computed: {
    alarms() {
      return [
        new Date(
          this.today.getFullYear(),
          this.today.getMonth(),
          this.today.getDate(),
          10,
          0,
          0,
          0
        ),
        new Date(
          this.today.getFullYear(),
          this.today.getMonth(),
          this.today.getDate(),
          13,
          0,
          0,
          0
        ),
      ];
    },
  },
  created: function() {
    this.check_time();
  },
  methods: {
    check_open_workspace_quotes() {
      return new Promise((resolve, reject) => {
        this.$http.get('/any_workspace_quotes').then(
          (response) => {
            resolve(response.data.list);
          },
          function(response) {
            reject(false);
          }
        );
      });
    },
    check_time() {
      let _this = this;
      //this.list = [];
      setInterval(() => {
        let check_now = false;

        this.alarms.forEach((el, idx) => {
          // check if current time is between element date (10:00:00) and (10:01:30)
          if (el < new Date() && el.getTime() + 1500 * 60 > new Date())
            check_now = true;
        });
        if (check_now == true) {
          _this.check_open_workspace_quotes().then((response) => {
            if (response.length > 0) {
              _this.list = response;
              _this.naugthy_modal = true;
            }
          });
        }
      }, 1000 * 60);
    },
  },
};
</script>
