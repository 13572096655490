<template>
  <div class="header__container">
    <h2 class="sub-container">
      <slot />
      <span class="header__right">
        TOTAL: <Money :amount="total" />
        <br>
        <span class="open-total">
          {{ open.label }}: <Money :amount="open.total" />
        </span>
      </span>
    </h2>
    <div class="sub-container">
      <div>
        page: {{ page }} / {{ total_page }}
      </div>
      <el-button-group>
        <el-button
          type="primary"
          size="small"
          @click="onPrev"
        >
          Prev Page
        </el-button>
        <el-button
          type="primary"
          size="small"
          @click="onNext"
        >
          Next Page
        </el-button>
      </el-button-group>
    </div>
  </div>
</template>

<script>
import Money from '../../../shared/Money';

export default {
  name: 'ColumnHeader',
  components: {Money},
  props: {
    total: {
      type: Number,
      required: true,
    },
    open: {
      type: Object,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    total_page: {
      type: Number,
      required: true,
    }
  },
  methods: {
    onPrev() {
      this.$emit('prev');
    },
    onNext() {
      this.$emit('next');
    }
  }
};
</script>

<style ang='scss' scoped>
.header__container {
  margin-bottom: 20px;
}
.sub-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.header__right {
  color:#999;
  font-size:16px;
  text-align:right;
}

.open-total {
  font-size:14px;color:#666
}
</style>
