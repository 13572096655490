<template>
  <div>
    <el-button
      size="mini"
      type="primary"
      @click="new_comment_dialog = true"
    >
      + <i class="el-icon-chat-line-round" />
    </el-button>
    <el-dialog
      title="New Comment (Personal Staff Note)"
      :visible.sync="new_comment_dialog"
      width="50%"
      append-to-body
      :close-on-click-modal="false"
    >
      <jodit-editor
        id="comments_editor"
        ref="comments_editor"
        v-model="entry"
        :config="jodit_config"
      />
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="success"
          size="mini"
          @click="new_comment(), new_comment_dialog = false"
        >
          Save
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import {add_comment} from '../../api/comments';

export default {
  name: 'NewComment',
  props: {
    proposal_id: {
      type: [String, Number],
      required: true
    }},
  data() {
    return {
      entry: '',
      new_comment_dialog: false,
      jodit_config : {
        enter: 'br',
      },
    };
  },
  created() {
  },
  methods: {
    new_comment() {
      add_comment(this, this.entry, this.proposal_id).then((response) => {
        this.$emit('reload_comments', true);
        this.$message({
          message: response.data.message,
          type: 'success',
        });
      });
    }
  }
};
</script>

<style scoped>

</style>
