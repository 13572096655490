export let Request = (()=>{
  let loading_cnt = 0;

  function perform_request(this_obj,request, cb, error_cb, loading_status=true) {
    if (loading_status ===  true) {
      loading_cnt++;
      this_obj.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
    }

    return request.then((response)=>{
      if (loading_status ===  true) {
        loading_cnt--;
        if (loading_cnt == 0) {
          this_obj.$loading().close();
        }
      }
      if (cb) {
        cb(response);
      }
      return response;
    },(error)=>{
      if (loading_status ===  true) {
        loading_cnt--;
      }
      console.log(error);
      this_obj.$loading().close();
      this_obj.$message({
        type: 'error',
        message: error.body.message
      });
      if (error_cb) {
        error_cb(error);
      }
      return new Promise((resolve, reject) => {
        reject(error);
      });
    });
  }

  return {
    get (this_obj, url, cb=null, params ={}, error_cb=null, res_type= 'json', loading_status=true) {
      let request = this_obj.$http.get(url, { params: params, responseType: res_type });
      return perform_request(this_obj, request, cb, error_cb, loading_status);
    },
    post (this_obj, url, cb=null, params ={},error_cb=null) {
      let request = this_obj.$http.post(url, params );
      return perform_request(this_obj, request, cb, error_cb);
    },
    patch (this_obj, url, cb=null, params ={},error_cb=null) {
      let request = this_obj.$http.patch(url, params );
      return perform_request(this_obj, request, cb, error_cb);
    },
    delete (this_obj, url, cb=null, error_cb=null) {
      let request = this_obj.$http.delete(url);
      return perform_request(this_obj, request, cb, error_cb);
    }
  };
})();
