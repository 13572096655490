<template>
  <div id="inline">
    MEETINGS
    <el-tooltip
      content="Show meetings/invitations"
      effect="dark"
      placement="bottom"
    >
      <el-button
        type="edit"
        icon="el-icon-message-solid"
        class="large"
        style="border:none; padding:0px; font-size:20px;"
        @click.native.prevent="dialogVisible = true, on_open_dialog()"
      />
    </el-tooltip>
    <span style="color: orange;">({{ count }})</span>
    <el-dialog
      title="Meetings"
      :visible.sync="dialogVisible"
      width="80%"
      :before-close="handleClose"
    >
      <el-table
        :data="meetings"
        stripe
        style="width: 100%"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <p>{{ props.row.message }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="from_date_time"
          label="from"
        />
        <el-table-column
          prop="to_date_time"
          label="to"
        />
        <el-table-column
          prop="time_difference"
          label="Duration"
        />
        <el-table-column
          prop="title"
          label="Title"
        />
        <el-table-column
          prop="to_email"
          label="Email"
        />
        <el-table-column
          prop="location"
          label="Location"
        />
        <el-table-column
          prop="created_from"
          label="Created from"
        />
        <el-table-column
          label="Job View"
        >
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.accepted == true && scope.row.remote_ip != false"
              size="mini"
              @click="link_to_job_view(scope.row.proposal_id)"
            >
              Job View
            </el-button>
          </template>
        </el-table-column>

        <el-table-column
          label="Status"
        >
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.accepted == true && scope.row.remote_ip != false"
              type="success"
            >
              Accepted
            </el-tag>
            <el-tag
              v-if="scope.row.accepted == false && scope.row.remote_ip != false"
              type="danger"
            >
              Declined
            </el-tag>
            <el-tag
              v-if="scope.row.remote_ip == false"
            >
              No response
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="Operations"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              @click="delete_meeting(scope.$index,scope.row)"
            >
              Delete
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <span
        slot="footer"
        class="dialog-footer"
      />
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'EmailMeetings',
  props: {
    mailbox_id: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      dialogVisible: false,
      meetings: [],
      count: ''
    };
  },
  watch: {
    mailbox_id: function() {
      this.count_meetings();
    }

  },
  methods: {
    on_open_dialog() {
      this.get_meetings();
    },
    get_meetings() {
      this.$http.get('/invitation_meetings/' + this.mailbox_id).then(response => {
        this.meetings = response.data.meetings;
      });
    },
    count_meetings() {
      this.$http.get('/count_meetings/' + this.mailbox_id).then(response => {
        this.count = response.data.meetings;
      });
    },
    delete_meeting(index, row) {
      this.$http.delete(`/delete_invitation_meeting/${this.mailbox_id}/${row.id}`);
      this.meetings.splice(index, 1);
    },
    link_to_job_view(proposal_id) {
      window.open('/job_view?id=' + proposal_id);
    },
    handleClose(done) {
      done();
    },
  },
};
</script>

<style scoped>
#inline {
  display: inline;
}

</style>