export function CheckPositiveInteger(input) {
  let numbers = /^[0-9]+$/;
  if (!String(input).match(numbers)) return false;

  return true;
}

export function SumFromArray(array) {
  return array.reduce((prev, curr) => {
    const value = Number(curr);
    if (!isNaN(value)) {
      return prev + curr;
    } else {
      return prev;
    }
  }, 0);
}
