<template>
  <div id="job_settings-view-options">
    <el-row :gutter="20">
      <el-col :span="7">
        <span class="el-form-item__label">Advanced Scheduler:</span>
      </el-col>
      <el-col :span="10">
        <el-select
          v-model="jobs_setting.advanced_scheduler_view"
          placeholder="Advanced Scheduler Simple"
          size="small"
          @change="update_jobs_setting"
        >
          <el-option
            v-for="option in advanced_options"
            :key="option"
            :label="option"
            :value="option"
          >
            {{ option }}
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="7">
        <span class="el-form-item__label">User Job Page View Default:</span>
      </el-col>
      <el-col :span="10">
        <el-select
          v-model="jobs_setting.user_job_view"
          placeholder="User Job Page View Default"
          size="small"
          @change="update_jobs_setting"
        >
          <el-option
            v-for="option in advanced_options"
            :key="option"
            :label="option"
            :value="option"
          >
            {{ option }}
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="7">
        <span class="el-form-item__label">Supertask Option View Default:</span>
      </el-col>
      <el-col :span="10">
        <el-select
          v-model="jobs_setting.super_task_view"
          placeholder="Supertask Option View Default"
          size="small"
          @change="update_jobs_setting"
        >
          <el-option
            v-for="option in supertask_options"
            :key="option"
            :label="option"
            :value="option"
          >
            {{ option }}
          </el-option>
        </el-select>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import {index, update} from '../../api/job_settings';

export default {
  name: 'JobSettingsViewOptions',
  data() {
    return {
      jobs_setting: {
        advanced_scheduler_view: '',
        user_job_view: '',
        super_task_view: ''},
      advanced_options: ['Simple', 'Advanced'],
      supertask_options: ['on', 'off']
    };
  },
  created() {
    this.get_jobs_setting();
  },
  methods: {
    get_jobs_setting() {
      index(this).then((response) => {
        this.jobs_setting = response.data;
      });
    },
    update_jobs_setting() {
      update(this, this.jobs_setting).then((response) => {
        this.$message({
          message: response.data.message,
          type: 'success',
        });
      });
    }
  }
};
</script>

<style scoped>
.el-select{ width: 228px;}
</style>