<template>
  <div id="leave-requests-period">
    <el-dialog
      :title="'Leave Requests ' + start + ' - ' + end"
      :visible.sync="show"
      width="80%"
      :before-close="handle_close"
      :close-on-click-modal="true"
      append-to-body
    >
      <new-leave-request
        :crm_user_id="crm_user_id"
        @reload_leave_requests="get_leave_requests('reload_leave_requests')"
      />

      <el-table
        ref="multipleTable"
        :data="leave_requests"
        style="width: 100%"
        stripe
      >
        <el-table-column
          label="Name"
          property="name"
        >
          <template #default="scope">
            {{ scope.row.user_name }}
          </template>
        </el-table-column>
        <el-table-column
          label="From"
          property="from_date"
        >
          <template #default="scope">
            {{ scope.row.from_date_formatted }}
          </template>
        </el-table-column>
        <el-table-column
          label="To"
          property="to_date"
        >
          <template #default="scope">
            {{ scope.row.to_date_formatted }}
          </template>
        </el-table-column>

        <el-table-column
          label="Type"
          property="requestable"
        >
          <template #default="scope">
            {{ scope.row.requestable }}
          </template>
        </el-table-column>
        <el-table-column
          label="Type"
          property="type"
        >
          <template #default="scope">
            {{ scope.row.type }}
          </template>
        </el-table-column>
        <el-table-column
          label="Value in hours / dollars"
        >
          <template #default="scope">
            <el-input-number
              v-if="editable[scope.$index]"
              v-model="leave_requests[scope.$index].value"
              size="small"
              :precision="2"
              :step="0.1"
            />
            <span v-else>{{ scope.row.value }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Operations"
        >
          <template slot-scope="scope">
            <el-button
              v-if="editable[scope.$index]"
              size="mini"
              @click="update(scope.$index)"
            >
              Save
            </el-button>
            <el-button
              v-else
              size="mini"
              @click="set_editable(scope.$index)"
            >
              Edit
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="delete_record(scope.$index)"
            >
              Delete
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import {leave_requests, update_record, delete_record} from '../../api/leave_requests';
import NewLeaveRequest from '../user/payroll/NewLeaveRequest.vue';
import {set_default_input} from '../../utilities/DefaultInput';

export default {
  name: 'LeaveRequestsPeriod',
  components: { NewLeaveRequest},
  props: {
    start: {
      type: [String],
      required: true
    },
    end: {
      type: [String],
      required: true
    },
    crm_user_id: {
      type: [String, Number],
      required: true
    },
    show_dialog: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      leave_requests: [],
      editable: [],
      show: this.show_dialog,
      request_dialog: false
    };
  },
  watch: {
    show_dialog(val) {
      this.show = val;
    },
  },
  created() {
    this.get_leave_requests(false);
  },
  methods: {
    get_leave_requests(event){
      leave_requests(this, this.start, this.end, this.crm_user_id ).then((response)=> {
        this.leave_requests = response.data;
        if(event == 'reload_leave_requests'){
          this.$emit('reload_pay_run');
        }
      });
    },
    set_editable(row) {
      if(this.editable.length === 0) {
        this.editable[row] = true;
        this.$set(this.editable, row, true);
      }
    },
    update(index) {
      update_record(this, this.leave_requests[index].id, this.leave_requests[index]).then(()=> {
        this.editable = [];
        this.get_leave_requests();
        this.$emit('reload_pay_run');
      });
    },
    delete_record(index) {
      delete_record(this, this.leave_requests[index].id).then(()=> {
        this.leave_requests.splice(index, 1);
        this.get_leave_requests();
        this.$emit('reload_pay_run');
      });
    },
    handle_close() {
      this.$emit('update:show_dialog', false);
    },
    set_default_input
  }
};

</script>

<style scoped>
#leave-requests-period{ width:400px;}
</style>
