<template>
  <div>
    <el-card
      v-loading="is_loading"
      class="box-card"
    >
      <div
        slot="header"
        class="clearfix"
      >
        <span>OUTWARDS GOODS</span>
      </div>
      <el-table
        :data="outwards_goods_list"
        style="width: 100%"
      >
        <el-table-column
          prop="job_name"
          label="Quote #"
          sortable
        >
          <template slot-scope="scope">
            <a :href="`edit_proposal?id=${scope.row.proposal.id}`">#{{ scope.row.proposal.id }}</a>
          </template>
        </el-table-column>
        <el-table-column
          prop="proposal.job_name"
          label="Job"
          sortable
        />
        <el-table-column
          prop="task_name"
          label="Task"
          sortable
        />
        <el-table-column
          prop="is_active"
          label="Active"
          sortable
        >
          <template slot-scope="scope">
            <el-tag
              v-show="scope.row.is_active == true"
              size="small"
              type="success"
            >
              Active
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="from_address"
          label="From"
          sortable
        />
        <el-table-column
          prop="to_address"
          label="To"
          sortable
        />
        <el-table-column
          prop="return_trip"
          label="Return trip?"
          sortable
        >
          <template slot-scope="scope">
            <i
              v-show="scope.row.return_trip == true"
              style="font-size: 20px;color: limegreen;"
              class="el-icon-circle-check"
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="distance"
          label="Distance"
          sortable
        >
          <template
            slot-scope="scope"
            prop="distance"
          >
            {{ scope.row.distance }} KM
          </template>
        </el-table-column>
        <el-table-column
          prop="duration"
          label="Duration"
          sortable
        >
          <template slot-scope="scope">
            <Time :is_hours="scope.row.is_hours" :minutes="scope.row.duration" />
          </template>
        </el-table-column>
        <el-table-column
          prop="proposal.deadline"
          label="Deadline"
          sortable
        />
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { debuglog } from 'util';
import Time from '../shared/Time';
export default {
  name: 'OutwardsGoods',
  components: { Time },
  data() {
    return {
      is_loading: false,
      outwards_goods_list: []
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.load_outwards_goods();
  },
  methods: {
    load_outwards_goods() {
      this.is_loading = true;
      this.outwards_goods_list = [];
      this.$http.get('outwards_goods_list').then(response => {
        this.outwards_goods_list = response.body;
        this.is_loading = false;
      });
    }
  }
};
</script>

<style>
</style>