<template>
  <div id="crm_user_employment_details">
    <el-form
      ref="form"
      :model="employment_details"
      label-width="220px"
    >
      <el-form-item
        label="IRD Number"
      >
        <el-input
          v-model="employment_details.ird_number"
          size="small"
          placeholder="###-###-###"
        />
        <p class="description">
          IRD numbers contain 9 digits. If your IRD number has 8 digits, add a 0 before entering the rest of your IRD number.
        </p>
      </el-form-item>
      <el-form-item
        label="Break duration"
      >
        <el-input-number
          v-model="employment_details.break_duration"
          size="small"
        />
      </el-form-item>
      <el-form-item
        label="Pay type"
      >
        <el-select
          v-model="selected_employment_pay_type_id"
          placeholder="Select"
          size="small"
          style="width: 280px;"
        >
          <el-option
            v-for="type in employment_pay_types"
            :key="type.id"
            :label="type.name"
            :value="type.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="Pay Cycle"
      >
        <el-select
          v-model="selected_pay_cycle_id"
          placeholder="Select a pay cycle"
        >
          <el-option
            v-for="item in pay_cycles"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Payslip email">
        <el-input
          v-model="employment_details.payslip_email"
          size="small"
        />
      </el-form-item>
      <el-form-item label="Tax code">
        <el-input
          v-model="employment_details.tax_code"
          size="small"
        />
      </el-form-item>
      <el-form-item label="Kiwi saver">
        <el-input
          v-model="employment_details.kiwi_saver_contributions"
          size="small"
          placeholder="contributions in percentage"
          type="number"
        />
      </el-form-item>
      <el-form-item label="Employer">
        <el-input
          v-model="employment_details.employer_contributions"
          size="small"
          placeholder="contributions in percentage"
          type="number"
        />
      </el-form-item>
      <el-form-item label="Pay as you go (holiday pay)">
        <el-checkbox
          v-model="employment_details.pay_as_you_go"
          :true-label="1"
          :false-label="0"
        />
      </el-form-item>

      <el-form-item label="Child Support Payment">
        <el-input
          v-model="employment_details.child_support_payment"
          size="small"
          type="number"
        />
      </el-form-item>
      <el-form-item label="Student Loan Extra Payment">
        <el-input
          v-model="employment_details.student_loan_extra_payment"
          size="small"
          type="number"
        />
      </el-form-item>

      <el-divider />
      <el-form-item
        label="Start date:"
      >
        <el-date-picker
          v-model="employment_details.employment_start_date"
          type="date"
          placeholder="Start date"
          format="dd.MM.yyyy"
          value-format="dd.MM.yyyy"
          size="small"
        />
      </el-form-item>

      <el-form-item
        label="End date:"
      >
        <el-date-picker
          v-model="employment_details.employment_end_date"
          type="date"
          placeholder="End date"
          format="dd.MM.yyyy"
          value-format="dd.MM.yyyy"
          size="small"
        />
      </el-form-item>
      <el-divider />
      <p style="margin-left: 120px;">
        <strong>Current time: {{ current_start }} - {{ current_end }}</strong>
      </p>
      <el-form-item
        label="Check in:"
      >
        <el-time-select
          v-model="selected_start"
          :picker-options="{
            start: '00:00',
            step: '00:15',
            end: '23:45'
          }"
          placeholder="Select start time"
          size="small"
        />
      </el-form-item>
      <el-form-item
        label="Check out:"
      >
        <el-time-select
          v-model="selected_end"
          :picker-options="{
            start: '00:00',
            step: '00:15',
            end: '23:45'
          }"
          placeholder="Select end time"
          size="small"
        />
      </el-form-item>
      <el-divider />

      <el-button
        type="primary"
        size="small"
        style="float:right;"
        @click="save_details"
      >
        Save
      </el-button>
    </el-form>
  </div>
</template>
<script>
import {pay_cycles} from '../../api/pay_cycles';

export default {
  name: 'UserEmploymentDetails',
  props: {
    crm_user_id: {
      type: [String,Number],
      required: true
    }
  },
  data() {
    return {
      employment_details: {
        break_duration: 30,
        default_check_out: '',
        default_check_in: ''
      },
      selected_end: '',
      selected_start: '',
      current_start: '',
      current_end: '',
      selected_employment_pay_type_id: '',
      selected_pay_cycle_id: '',
      employment_pay_types: {},
      pay_cycles: []

    };
  },
  watch: {
    crm_user_id(){
      this.get_employment_details();
    }
  },
  mounted() {
    this.get_employment_details();
    this.get_employment_pay_types();
    this.get_pay_cycles();
  },
  created() {
  },
  methods: {
    get_pay_cycles() {
      pay_cycles(this).then((response)=> {
        this.pay_cycles = response.data;
      });
    },
    get_employment_pay_types(){
      this.$http.get('/employment_pay_types').then((response) => {
        this.employment_pay_types = response.data;
      });
    },
    get_employment_details(){
      this.$http.get('/show_employment_details/'+ this.crm_user_id).then((response) => {
        this.employment_details = response.data;
        this.current_start = response.data.default_check_in;
        this.current_end = response.data.default_check_out;
        this.selected_employment_pay_type_id = response.data.employment_pay_type_id;
        this.selected_pay_cycle_id = response.data.pay_cycle_id;
      });
    },
    save_details(){
      if(this.selected_start){
        this.employment_details.default_check_in = this.selected_start;
      }

      if(this.selected_end){
        this.employment_details.default_check_out = this.selected_end;
      }

      if( this.selected_employment_pay_type_id ){
        this.employment_details.employment_pay_type_id = this.selected_employment_pay_type_id;
      }

      if( this.selected_pay_cycle_id ){
        this.employment_details.pay_cycle_id = this.selected_pay_cycle_id;
      }

      this.$http.patch(`/crm_users/${this.crm_user_id}`, this.employment_details).then(() => {
        this.$message({
          message: 'Employment details updated.',
          type: 'success',
        });
        this.get_employment_details();
      }, (reject) => {
        this.$message({
          message: `${reject.data.inactive.join(
            '<br>'
          )}`,
          dangerouslyUseHTMLString: true,
          type: 'error',
        });
        this.get_employment_details();
      });
    },
  }
};
</script>
<style scoped>
 #crm_user_employment_details {
   max-width: 400px;
 }
 .description{
   line-height: 15px;
   font-size: 11px;
   margin: 0px 0px 10px 0px;
 }
</style>
