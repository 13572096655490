<template>
  <div id="income-tax">
    <p class="headline">
      Net Payment: <Money :amount="net_payment" />
    </p>
    <el-collapse>
      <el-collapse-item title="Calculation Details">
        <el-table
          :data="income_tax.paye"
          stripe
        >
          <el-table-column
            label="Calculation Details"
            width="360"
          >
            <template #default="scope">
              {{ scope.row.name }}
            </template>
          </el-table-column>
          <el-table-column
            label="Deductions"
            width="180"
            props="value"
          >
            <template #default="scope">
              <span v-if="scope.row.deduction">-</span><Money :amount="scope.row.deduction" />
            </template>
          </el-table-column>
          <el-table-column
            width="180"
            props="value"
          >
            <template #default="scope">
              <Money :amount="scope.row.value" />
            </template>
          </el-table-column>
        </el-table>
      </el-collapse-item>
      <el-collapse-item title="KiwiSaver/complying fund employer contributions">
        <el-table
          :data="income_tax.kiwi_saver"
          stripe
        >
          <el-table-column
            label="Details"
            width="540"
          >
            <template #default="scope">
              {{ scope.row.name }}
            </template>
          </el-table-column>
          <el-table-column
            width="180"
            props="value"
          >
            <template #default="scope">
              <Money :amount="scope.row.value" />
            </template>
          </el-table-column>
        </el-table>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
import Money from '../../shared/Money';
import{pay_run_income_tax} from '../../../api/pay_runs';
export default {
  name: 'IncomeTax',
  components: {Money},
  props: {
    crm_user_id: {
      type: String,
      required: true
    },
    pay_period_start: {
      type: String,
      required: true
    },
    pay_period_end: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      income_tax: [],
      net_payment: 0
    };
  },
  created(){
    this.$root.$on('gross_payment_total', data => {
      this.pay_run_income_tax(data);
    });
  },
  methods: {
    pay_run_income_tax(gross_payment_total) {
      if (this.income_tax.length === 0) {
        pay_run_income_tax(this, this.crm_user_id, this.pay_period_start, this.pay_period_end, gross_payment_total).then((response) => {
          this.income_tax = response.data[0];
          this.net_payment = Object.values(this.income_tax.paye).pop().value;
          this.$emit('calculate_pay_run', this.net_payment);
        });
      }
    }
  }
};
</script>
<style scoped>
.headline {
  padding: 10px;
  color: white;
  background: #409EFF;
}
</style>
