<template>
  <div class="btn__container">
    <div class="btn__left">
      <el-button
        size="mini"
        @click="onSend"
      >
        SEND
      </el-button>
      <el-button
        v-if="is_overdue==true"
        size="mini"
        @click="onSendOverDue"
      >
        SEND OVERDUE
      </el-button>
      &nbsp;&nbsp;
      <a :href="download_link">
        <el-button size="mini">
          PDF
        </el-button>
      </a>
    </div>
    <div>
      <el-button
        size="mini"
        type="danger"
        @click="onDelete"
      >
        DELETE
      </el-button>
    </div>
  </div>
</template>

<script>
import {deleteInvoice, sendInvoice} from '../../../../api/invoice';
import {EventBus} from '../../../../utilities/EventBus';

export default {
  name: 'ExtensionButtons',
  props: {
    id: {
      type: Number,
      required: true,
    },
    is_overdue: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    download_link() {
      return `/invoices/${this.id}.pdf`;
    },
  },
  methods: {
    onSendOverDue() {
      sendInvoice(this, this.id, true).then(({data})=>{
        this.$message({
          type: 'success',
          message: data.message
        });
      });
    },
    onSend() {
      sendInvoice(this, this.id).then(({data})=>{
        this.$message({
          type: 'success',
          message: data.message
        });
      });
    },
    onDelete() {
      this.$confirm('This will permanently delete the Invoice. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(()=>{
        deleteInvoice(this, this.id).then(({data})=> {
          this.$message({
            type: 'success',
            message: data.message
          });
          EventBus.$emit('refreshAllInvoice');
        });
      });
    }
  }
};
</script>

<style lang='scss' scoped>
.btn__container {
  display: flex;
  align-items: center;
  min-height: 66px;
  width: 100%;
}

.btn__left {
  flex: 0.7;
}
</style>
