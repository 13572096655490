<template>
  <div id="crm_user_signature">
    <p>left click and hold  to create signature:</p>
    <div id="signature-div">
      <VueSignaturePad
        ref="signaturePad"
        width="400px"
        height="100px"
        :options="{onBegin: () => { $refs.signaturePad.resizeCanvas(); }}"
      />
    </div>
    <div>
      <img
        v-if="crm_user_details.e_signature"
        :src="crm_user_details.e_signature"
        style="display:block; width:400px;height:100px;"
      >
      <el-button
        size="mini"
        type="primary"
        @click="save_signature"
      >
        Save Signature
      </el-button>
      <el-button
        size="mini"
        @click="undo_signature"
      >
        Undo
      </el-button>
      <el-button
        size="mini"
        type="danger"
        @click="remove_signature"
      >
        Remove Signature
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CrmUserSignature',
  props: {
    crm_user_id: {
      type: [String,Number],
      required: true
    }
  },
  data() {
    return {
      crm_user_details: {
        e_signature: '',
        id: this.crm_user_id
      }
    };
  },
  watch:{
    crm_user_id(){
      this.get_e_signature();
    }
  },
  mounted() {
    this.get_e_signature();
  },
  created() {
  },
  methods: {
    get_e_signature(){
      this.$http.get(`/e_signature/${this.crm_user_id}`).then((response) => {
        this.$refs.signaturePad.clearSignature();
        this.crm_user_details.e_signature = response.data;
      });
    },
    undo_signature() {
      this.$refs.signaturePad.undoSignature();
    },
    remove_signature() {
      this.crm_user_details.e_signature = '';
      this.$http.post(`/remove_e_signature/${this.crm_user_id}`, {}).then(() => {
        this.$message({
          message: 'User E-Signature removed.',
          type: 'success',
        });
      });
    },
    save_signature() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      console.log( isEmpty);
      this.crm_user_details.e_signature = data;
      this.$http.patch(`/crm_users/${this.crm_user_id}`, {crm_user: this.crm_user_details }).then(() => {
        this.$message({
          message: 'User E-Signature updated.',
          type: 'success',
        });
        this.get_e_signature();
      });
    }
  }
};
</script>
<style scoped>
#signature-div {
  border: 1px dotted grey;
  width: 400px;
  height: 100px;
  padding:5px;
  margin-bottom: 5px;
}

#crm_user_signature {
}
</style>