<template>
  <div class="layers_nesting_div">
    <div class="row">
      <el-button @click="calculate_qty" size="small">Calculate</el-button>
      <ul>
        <li v-for="layer in stock_items_layers" :key="layer.label">
          <h3 style="font-weight: 500;">
            {{ layer.label.toUpperCase() }}
            <small
              ><i>{{ layer.qty }} item(s)</i></small
            >

            <span class="right">
              <el-radio-group v-model="layer.direction" size="mini">
                <el-radio-button label="Left"></el-radio-button>
                <el-radio-button label="Center"></el-radio-button>
              </el-radio-group>

              <el-input
                placeholder="Please input"
                v-model="layer.width"
                size="mini"
                style="width: 150px"
              >
                <template slot="prepend">Width</template>
              </el-input>
              <el-button
                icon="el-icon-refresh"
                size="mini"
                @click="swap_specs(layer)"
              ></el-button>
              <el-input
                placeholder="Please input"
                v-model="layer.height"
                size="mini"
                style="width: 150px"
              >
                <template slot="prepend">Height</template>
              </el-input>
            </span>

            <hr />
            <br />
            <el-input
              size="mini"
              style="width: 200px;"
              placeholder="Please input"
              v-model="layer.calculated_qty"
            >
              <template slot="prepend">Calculated Qty</template>
            </el-input>
            <br />  
          </h3>
          <el-row :gutter="20">
            <el-col :span="12">
              <LayersNestingEntry :layer="layer"></LayersNestingEntry>
            </el-col>
            <el-col :span="12">
              <div :data-nesting-id="layer.stock_id">
                <div></div>
              </div>
            </el-col>
          </el-row>
          <br />
        </li>
      </ul>
      <div class="col m6 s12"></div>
    </div>
  </div>
</template>

<script>
import LayersNestingEntry from "./LayersNestingEntry";
import SheetCalculation from "utilities/SheetsCalculation";

export default {
  name: "LayersNesting",
  props: ["stock_items_layers"],
  components: { LayersNestingEntry },
  data() {
    return {
      defaultProps: {
        children: "children",
        label: "label"
      }
    };
  },
  mounted() {
    this.set_inputs_to_default();
  },
  computed: {
    // other_layers: function() {
    //   let arr = [];
    //   d3.selectAll(".svg_layer:not([layer-type])")
    //     .nodes()
    //     .forEach((el, idx) => {
    //       arr.push({
    //         label: el.getAttribute("data-name"),
    //         el: el,
    //         nested_stocks:
    //           el.getAttribute("nested-stocks") == undefined
    //             ? []
    //             : el.getAttribute("nested-stocks").split(",")
    //       });
    //     });
    //   return arr;
    // }
  },
  methods: {
    swap_specs(layer) {
      let tmp = layer.height;
      layer.height = layer.width;
      layer.width = tmp;
    },
    calculate_qty() {
      
      // loop through stock layers
      // get the selected 'other layers' + qty
      // get stock layer specs
      // nest artwork layers against the current stock
      // and update calculated stock layer qty
      // update layers props

      this.stock_items_layers.forEach((stock_layer, idx) => {
        let checked_layers = stock_layer.nested_layers.filter(
          c => c.checked == true && c.qty > 0
        );
        let rect_arr = [];
        checked_layers.forEach((el, idx) => {
          let rect = {};
          for (let index = 0; index < el.qty; index++) {
            rect = {
              x: 0,
              y: 0,
              w: el.width,
              h: el.height,
              id: `Layer#${el.label}_Id#${index}`
            };
            rect_arr.push(rect);
          }
        });

        if (rect_arr.length == 0) {
          return;
        }
        var sheet_calc = new SheetsCalculation({
          w: stock_layer.width,
          h: stock_layer.height,
          blocks: rect_arr,
          num: 1
        });
        
        var results = sheet_calc.run();
        
        var data = JSON.stringify(results.sheets);

        stock_layer.calculated_qty = results.sheets.length;
        this.draw_nesting(stock_layer, results);
      });
    },
    clear_svgs() {
      d3.selectAll("[data-nesting-id] div").remove();
    },
    draw_nesting(stock, results) {
      let stock_id = stock.stock_id;

      let _this = this;
      d3.selectAll(`[data-nesting-id='${stock_id}'] div`).remove();

      var x;
      for (x = 0; x < results.sheets.length; x++) {
        let cutting_id = `cuttings_${x}_${stock_id}`;
        let cuttings_container = d3.select(`[data-nesting-id='${stock_id}']`);
        cuttings_container
          .append("div")
          .classed("col s4", true)
          .append("svg")
          .attr("id", cutting_id)
          .attr("preserveAspectRatio", "xMinYMin meet")
          .classed("cuttings", true);

        let svg = d3.select(`#${cutting_id}`);
        svg.attr("viewBox", "0 0 " + stock.width + " " + stock.height);
        let b;
        let sheet_blocks = results.sheets[x];
        for (b = 0; b < sheet_blocks.length; b++) {
          let block = sheet_blocks[b];
          svg
            .insert("g")
            .append("rect")
            .attr("fill", "lightsteelblue")
            .attr("stroke", "red")
            .attr("x", block.fit.x)
            .attr("y", block.fit.y)
            .attr("width", block.w)
            .attr("height", block.h);

          let font_size = Math.min(block.w, block.h) / 3;
          svg
            .insert("g")
            .append("text")
            .attr("font-size", 12)
            .attr("x", block.fit.x + 3)
            .attr("y", block.fit.y + font_size)
            .attr("fill", "black")
            .text(`${block.id}`);

          svg
            .insert("g")
            .append("text")
            .attr("font-size", font_size / 3)
            .attr("x", block.fit.x + 3)
            .attr("y", block.fit.y + font_size * 2)
            .attr("fill", "black")
            .text(`W: ${Math.round(block.w)}, H: ${Math.round(block.h)}`);
        }
      }
    },
    set_inputs_to_default() {
      let _this = this;
      Array.from(
        document.querySelectorAll(".layers_nesting_div .el-input")
      ).forEach(function(e, i) {
        e.classList.add("browser-default");
        if (e.querySelector("input") != null)
          e.querySelector("input").className += " browser-default";
      });
    }
    // handleNodeClick(data) {
    //   this.reload_stocks(data.nested_stocks);
    // },
    // reload_stocks(nested_stocks) {
    //   this.checked_stock_items = [];
    //   let arr = [];
    //   d3.selectAll('[layer-type="stock"]')
    //     .nodes()
    //     .forEach((el, idx) => {
    //       arr.push({
    //         name: el.getAttribute("data-name"),
    //         stock_id: el.getAttribute("proposal-stock-id"),
    //         qty: el.getAttribute("stock-quantity"),
    //         checked: nested_stocks.indexOf(el.getAttribute("proposal-stock-id"))
    //       });
    //     });
    //   this.checked_stock_items = arr;
    // }
  }
};
</script>

<style></style>
