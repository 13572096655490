<template>
  <div id="simple-job-view">
    <el-row
      :gutter="10"
      class="margin-bottom-0"
    >
      <el-col id="simple-top">
        <el-row>
          <el-col
            :sm="24"
            :md="10"
            style="padding-right:30px;"
          >
            <el-button
              size="mini"
              class="margin-10"
              icon="el-icon-stopwatch"
              @click="advanced_job_view()"
            >
              Advanced Job View
            </el-button>
            <span
              v-show="!proposal.completed && proposal.is_urgent"
              class="sales-badge urgent"
              style="float:none!important;padding: 5px;"
            >URGENT</span>
            <span
              v-show="!proposal.requested_deadline && proposal.deadline_approved"
              class="sales-badge deadline-fixed"
              style="float:none!important;padding: 5px;"
            >DEADLINE FIXED</span>
            <span
              class="sales-badge quote-type-name"
              style="float:none!important;padding: 5px;"
            >{{ proposal.quote_type.name }}</span>
          </el-col>
   
          <el-col
            :sm="24"
            :md="12"
            style="padding-top:5px;"
          >
            <span
              v-if="hasName"
              style="color:orange;padding-right:30px;"
            ><i class="el-icon-stopwatch" />: {{ proposal.hold_status.name }}</span>
      
            <span style="text-transform:uppercase; padding-right:30px;"><strong>{{ proposal.category_task.name }}</strong></span>
            <span style="padding-right:20px;">Branch:<strong> {{ proposal.branch.name }}</strong>  </span>
          </el-col>
       
        <!-- {{ proposal.deadline_approved}} -->
        </el-row>
      </el-col>
    </el-row>
    <el-row
      id="simple-main"
      :gutter="20"
    >
      <el-col
        class="aside"
        :sm="24"
        :md="4"
      >
        <p v-if="proposal.requested_deadline">
          <strong>Deadline: </strong>{{ proposal.requested_deadline }}
        </p>
        <div id="more-details-view">
          <el-collapse
            ref="simple-main-panel-3"
            class="details_1"
          >
            <el-collapse-item
              title="Additional Job/Quote Details"
              name="1"
            >
              <span>Quote created by: {{ proposal.crm_user.user_name }} {{ proposal.crm_user.user_surname }}  </span>
              <p v-if="proposal.related_jobs.length > 0">
                Related Jobs:
              </p>
              <span
                v-for="related in proposal.related_jobs"
                :key="related"
              >
                <el-link
                  target="_blank"
                  :href="`/simple_job_view/${ related }`"
                  type="primary"
                  class="padding-5"
                >
                  {{ related }}
                </el-link>
              </span>
              <p v-if="proposal.client_order_number">
                Order Number: {{ proposal.client_order_number }}
              </p>
              <p v-if="proposal.time_allocated_labour">
                Allocated Total Time:
                <br>
                {{ proposal.time_allocated_labour }}
              </p>
            </el-collapse-item>
          </el-collapse>
        </div>
        <add-time-spent
          :proposal_id="proposal_id"
          style="float:right;text-align:right;width:100%;padding:5px;"
        />
      </el-col>

       
      <el-col
        :sm="24"
        :md="20"
        style="float:left; padding-top:20px;"
      >
        <h2><strong>{{ proposal.account.account_name }}</strong></h2>
        <new-comment
          :proposal_id="proposal_id"
          class="right"
          @reload_comments="reload_comments()"
        />
        <br>
        <h3 style="padding-bottom:20px;">
          Job: <strong>{{ proposal.job_name }}</strong>
        </h3>

        <!-- <el-divider /> -->

        <el-collapse
          ref="simple-main-panel-1"
          v-model="activeName"
        >
          <el-collapse-item
            title="Job Notes"
            name="1"
          >
            <div>
              <span v-html="proposal.pre_description" />
              <!-- <el-divider /> -->
              <all-notes-list
                ref="all_notes_list"
                :proposal_id="proposal_id"
              />
            </div>
          </el-collapse-item>
          <div id="edit-notes">
            <el-collapse-item
              title="... Edit Notes"
              name="notes_edit"
            >
              <NotesPanel
                ref="notes_panel"
                :proposal_id="proposal_id"
              />
            </el-collapse-item>
          </div>
        </el-collapse>
        <el-collapse
          ref="simple-main-panel-1"
          v-model="activeStock"
        >
          <el-collapse-item
            name="2"
          >
            <template slot="title">
              <span>Stock Items | <el-link
                type="primary"
                @click="open_file_link(proposal_id)"
              >Assets</el-link></span>
            </template>
            <el-collapse ref="simple-stock-panel-1">
              <div id="stockheader">
                <el-collapse-item
                  v-for="(items, working_file) in proposal.working_file_stock_items"
                  :key="working_file"
                  :name="working_file"
                  :title="working_file"
                  style="padding-left: 10px;"
                >
                  <template slot="title">
                    <span>{{ working_file }} </span>
                  </template>
                  <div class="table-responsive">
                    <table class="margin">
                      <th>Stock Item ID</th>
                      <th>PO ID</th>
                      <th>Quantity</th>
                      <th>Stock name</th>
                      <th>Supplier</th>
                      <tr
                        v-for="(item, index) in items"
                        :key="index"
                      >
                        <td>
                          <el-link
                            target="_blank"
                            type="primary"
                            :href="`/stock_items/${item.id}/edit`"
                          >
                            {{ item.id }}
                          </el-link>
                        </td>
                        <td>
                          <el-link
                            v-if="item.purchase_order_id > 0"
                            target="_blank"
                            type="success"
                            :href="`/purchase_orders/${item.purchase_order_id}/edit`"
                          >
                            {{ item.purchase_order_id }}
                          </el-link>
                        </td>
                        <td>{{ item.quantity }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.supplier }}</td>
                      </tr>
                    </table>
                  </div>
                </el-collapse-item>
              </div>
            </el-collapse>
          </el-collapse-item>
        </el-collapse>
        <el-collapse v-model="activePO">
          <el-collapse-item
            name="3"
          >
            <template slot="title">
              <span>Purchase Orders</span>
            </template>
            <el-collapse ref="simple-purchase-order-panel-1">
              <div id="stockheader">
                <el-collapse-item
                  v-for="(items, working_file) in proposal.working_file_purchase_orders"
                  :key="working_file"
                  :name="working_file"
                  :title="working_file"
                  style="padding-left: 10px;"
                >
                  <template slot="title">
                    <span>{{ working_file }} </span>
                  </template>
                  <div class="table-responsive">
                    <table class="margin">
                      <th>Purchase Order ID</th>
                      <th>Quantity</th>
                      <th>Category</th>
                      <th>Supplier</th>
                      <tr
                        v-for="(item, index) in items"
                        :key="index"
                      >
                        <td>
                          <el-link
                            target="_blank"
                            type="primary"
                            :href="`/purchase_orders/${item.id}/edit`"
                          >
                            {{ item.id }}
                          </el-link>
                        </td>
                        <td>{{ item.quantity }}</td>
                        <td>{{ item.category_supplier }}</td>
                        <td>{{ item.supplier }}</td>
                      </tr>
                    </table>
                  </div>
                </el-collapse-item>
              </div>
            </el-collapse>
          </el-collapse-item>
        </el-collapse>
        <el-collapse v-model="activeTask">
          <el-collapse-item
            title="List & Tasks"
            name="4"
          >
            <el-collapse ref="simple-job-panel-1">
              <div id="stockheader">
                <el-collapse-item
                  v-for="(jobs, working_file) in proposal.working_file_jobs"
                  :key="working_file"
                  :name="working_file"
                  :title="working_file"
                  style="padding-left: 10px;"
                >
                  <template slot="title">
                    <span>{{ working_file }} </span>
                  </template>
                  <div class="table-responsive">
                    <table class="margin">
                      <th>Start</th>
                      <th>Job Name</th>
                      <th>Users</th>
                      <th>Status</th>
                      <th>Time allocated</th>
                      <th>Time spent</th>
                      <tr
                        v-for="(job, index) in jobs"
                        :key="index"
                      >
                        <td>
                          {{ job.time_frame_start }}
                        </td>
                        <td>{{ job.name }}</td>
                        <td>{{ job.crm_users }}</td>
                        <td>{{ job.status }}</td>
                        <td>{{ job.time_allocated }}</td>
                        <td>{{ job.time_spent }}</td>
                      </tr>
                    </table>
                  </div>
                </el-collapse-item>
              </div>
            </el-collapse>
          </el-collapse-item>
          <el-collapse-item
            v-if="proposal.message_references.length > 0"
            title="Emails"
            name="emails"
          >
            <FilteredEmails
              :filtered_msg_id="proposal.message_references"
              :mailbox_account="proposal.mailbox_account_id"
              :branch_id="proposal.branch.id"
              :proposal_id="proposal_id"
            />
          </el-collapse-item>
        </el-collapse>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import {simple_job_view_data} from '../../api/simple_job_view';
import NotesPanel from '../editor/panels/NotesPanel.vue';
import NewComment from '../notes/NewComment.vue';
import FilteredEmails from '../mailbox/FilteredEmails.vue';
import AllNotesList from '../notes/AllNotesList.vue';
import AddTimeSpent from '../job_proposals/AddTimeSpent.vue';

export default {
  name: 'SimpleJobView',
  components: {FilteredEmails, NotesPanel, NewComment, AllNotesList, AddTimeSpent},
  props: {
    proposal_id: {
      type: [String, Number],
      required: true
    }},
  data() {
    return {
      proposal: [],
      dated: true,
      activeName: '1',
      activeStock: '2',
      activePO: '3',
      activeTask: '4',
    
      

    };
  },
  computed: {
    hasName() {
      return this.containsKey(this.proposal, 'hold_status');
    }
  },
  created() {
    this.simple_job_view();
  },
  methods: {
    reload_comments(){
      this.$refs.notes_panel.reload_personal_staff_notes();
      this.$refs.all_notes_list.load();
    },
    simple_job_view(){
      simple_job_view_data(this, this.proposal_id).then((response) => {
        this.proposal = response.data;
      });
    },
    advanced_job_view(){
      window.location = `/job_view?id=${this.proposal_id}`;
    },
    containsKey(obj, key ) {
      return Object.keys(obj).includes(key);
    },
    open_file_link() {
      document.getElementById('filemanager-button').setAttribute('data-search', '/job assets/working');
      document.getElementById('filemanager-button').click();
    }
  },
};
</script>

<style scoped>
  #simple-job-view { margin: -20px;}
  .urgent{ background:#cc0000; }
  .deadline-fixed{ background:#ff9966; }
  .quote-type-name{ background:orange; }
  .margin{ margin: 10px 20px}
  .margin-10{ margin-right: 10px; }
  .margin-left-20{ margin-left: 20px;}
  .margin-bottom-0{ margin-bottom: 0px;}
  .padding-5{padding-right: 5px;}
  #simple-top{
    background: rgb(243, 243, 243);
    padding: 18px 20px !important;
  }

  #simple-job-view{margin-top: -20px;}
  #simple-main{margin: 0px !important; padding-left: 18px;}
  h2{ margin: 0px 0px 5px 0px;display: inline-block;}
  h3{ margin: 0px;display: inline-block;}
  .right{ float:right;}
  .color-gray{color: rgb(128, 128, 128);}
 th, td {padding: 0px;}
 .table-responsive {    min-width: auto;
    overflow: scroll;}
    table {
     min-width: 500px;
      overflow: scroll;
}
</style>

<style>
 #simple-job-view .el-collapse-item__header {line-height:13px;border:1px solid #6a6a6a;text-transform: uppercase;background:white;color:#6a6a6a; padding:20px !important;}
 #simple-job-view .el-collapse-item__content {border:1px solid #f3f3f3; padding: 5px}

 #simple-job-view .el-main{background:white;}
 #simple-job-view .aside{background:rgb(243, 243, 243);padding: 20px; float:right;height: 100vh;}

 #more-details-view .el-collapse-item__header {background:white; border:0px;}
 #more-details-view .el-collapse-item__content {border:1px solid #f3f3f3}
 #edit-notes .el-collapse-item__header {line-height:0px;border:0 !important; background:#f3f3f3 !important}
#stockheader .el-collapse-item__header {line-height:0px;border:none !important; background:white !important; color:green}

@media only screen and (max-width: 600px) {
    #simple-job-view .aside {height:auto;background:white; border-bottom:1px solid #f3f3f3; padding:10px;}

    .el-dialog {margin-left:0px !important;width:100% !important }
  }


</style>