<template>
  <div>
    <div class="poItems">
      <div class="col s12 m12">
        <el-collapse accordion>
          <el-collapse-item
            v-for="po in charge_pos_list"
            :key="po.id"
            :class="po.mark_as_deleted == 1 ? 'mark_as_deleted' : ''"
          >
            <template slot="title">
              <span class="slimfont">PO:{{ po.id }}</span>
              <span>{{ po.description }}</span>
              <span class="slimfont">{{ po.supplier }}</span>
              <span
                v-show="po.status == 'APPROVED'"
                style="color:#5cb85c;"
              >
                {{ po.status }} | PO: {{ po.id }}
              </span>
              <!-- <span style="color:#339966" v-show="stock.purchase_order.status == 'RETURNED'">
            {{ stock.purchase_order.status }}  </span> -->
              <span
                v-show="po.status == 'PENDING'"
                style="color:orange; "
              >
                {{ po.status }} APPROVAL
              </span>
              <span
                v-show="po.status == 'REJECTED'"
                style="color:red; "
              >
                {{ po.status }}
              </span>
            </template>
            <div>
              <b>Task:</b> {{ po.category_task }} | <b>Category:</b>
              {{ po.category }} | <b>Est Cost:</b> {{ po.actual_value }} |
              <b>Sale Price:</b> {{ po.sale_price }}
              <el-button
                style="margin: 3px 0px"
                size="mini"
                @click="edit_charge_po(po.id)"
              >
                View
              </el-button>

              <el-button
                v-show="po.status == 'PENDING' && po.mark_as_deleted == 0"
                class="podelete"
                @click="delete_charge_po(po.id)"
              >
                <i
                  class="el-icon-delete-solid "
                  style="font-size:16px;"
                />
              </el-button>
            </div>
          </el-collapse-item>
        </el-collapse>

        <el-button
          v-show="proposal.completed == 0"
          type="info"
          size="small"
          class="right"
          plain
          @click="new_charge_po"
        >
          + Add Charge PO
        </el-button>
      </div>
    </div>

    <el-dialog
      :visible.sync="charge_po_dialog"
      width="99%"
    >
      <EditPurchaseOrder
        :po_id="po_id"
        :proposal="proposal"
        :refresh_list="refresh_list"
        :is_charge_po="is_charge_po"
        :is_pop_up="true"
      />
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { editor_store } from 'store/modules/editor';
import EditPurchaseOrder from 'components/purchase_orders/Edit';

export default {
  name: 'ChargePOs',
  data() {
    return {
      po_id: 0,
      charge_po_dialog: false,
      is_charge_po: true,
    };
  },
  store: editor_store,
  computed: {
    ...mapGetters(['charge_pos_list', 'proposal']),
  },
  components: {
    EditPurchaseOrder,
  },
  mounted: function() {},
  methods: {
    ...mapActions(['load_charge_pos_list']),

    refresh_list() {
      this.charge_po_dialog = false;
      this.load_charge_pos_list();
    },
    new_charge_po() {
      this.charge_po_dialog = true;
      this.po_id = 0;
    },
    edit_charge_po(po_id) {
      this.charge_po_dialog = true;      
      this.po_id = po_id;
    },
    delete_charge_po(po_id) {
      let _this = this;
      this.$http.delete(`/purchase_orders/${po_id}`).then(
        (response) => {
          if (response.body) {
            _this.load_charge_pos_list();
          }
        },
        function(response) {
          console.log(response);
        }
      );
    },
  },
};
</script>

<style>
.mark_as_deleted {
  text-decoration-line: line-through;
}
.poItems span {
  padding-right: 10px;
  float: left;
}
.podelete {
  color: red !important;
  background: none !important;
  border: 0px !important;
  float: right;
}
</style>
