import {Request} from '../utilities/Request';

export function loadCreditNotes(this_obj, account_id, page) {
  return Request.get(
    this_obj,
    '/api/credit_notes',
    null,
    {
      account_id: account_id,
      page: page
    }
  );
}

export function createCreditNotes(this_obj, invoice_id, amount, date, description) {
  return Request.post(
    this_obj,
    '/api/credit_notes',
    null,
    {
      invoice_id: invoice_id,
      amount: amount,
      date: date,
      description: description
    }
  );
}

export function editCreditNotes(this_obj, credit_note_id) {
  return Request.get(
    this_obj,
    `/api/credit_notes/${credit_note_id}/edit`,
    null
  );
}

export function updateCreditNotes(this_obj, credit_note_id, amount, date, status, description) {
  return Request.patch(
    this_obj,
    `/api/credit_notes/${credit_note_id}`,
    null,
    {
      amount: amount,
      status: status,
      date: date,
      description: description
    }
  );
}
