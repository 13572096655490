<template>
    <div class="working_file_details_div" v-loading="main_loading">
        <el-form :model="file_details" :label-position="label_position" label-width="120px" size="mini">
            <el-form-item label="Name">
                <el-col :span="10">
                    <el-input v-model="file_details.name"></el-input>
                </el-col>
            </el-form-item>
            <el-form-item label="Quantity">
                <el-col :span="10" style="    grid-template-columns: auto auto; display: grid;">
                    <el-input v-model.number="file_details.qty" style="width: 100px"></el-input>
                    <el-select v-model="file_details.quantity_unit_id">
                        <el-option v-for="qty in quantity_units" :key="qty.id" :label="qty.name" :value="qty.id">
                        </el-option>
                    </el-select>
                </el-col>
            </el-form-item>
            <el-form-item>
                <template slot="label">
                            Consecutive
                            <el-tooltip placement="top-start" content="Tasks will run consecutively with no time gap.">
                              <i class="el-icon-info"></i>
                            </el-tooltip>
</template>
        <el-col :span="6">
          <el-checkbox v-model="file_details.consecutive"></el-checkbox>
        </el-col>
      </el-form-item>
      <el-form-item label="Matched quote">
        <el-col :span="6">
          <el-input v-model="file_details.matched_quote"></el-input>
        </el-col>
      </el-form-item>
      <el-row>
        <el-col :span="12">
          <el-button
            size="small"
            class="left"
            @click="hide_working_file_details_dialog()"
            >Cancel</el-button
          >
        </el-col>
        <el-col :span="12">
          <el-button
            size="small"
            class="right"
            type="primary"
            @click="save_details"
            >Save</el-button
          >
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { editor_store } from "store/modules/editor";
import {EventBus} from '../../../utilities/EventBus';
export default {
    name: "WorkingFileDetails",
    props: ["file_details", "hide_working_file_details_dialog", "quantity_units"],
    store: editor_store,
    data() {
        return {
            label_position: "left",
            main_loading: false,
        };
    },
    mounted() {
        let _this = this;
        setTimeout(() => {
            _this.set_inputs_to_default();
        }, 500);
    },
    computed: {
        ...mapGetters(["files_list"]),
    },
    methods: {
        ...mapActions(["update_file_details"]),
        save_details() {
            this.main_loading = true;
            var quantity_unit_name = "";
            var qty_unit = this.quantity_units.filter(c => c.id == this.file_details.quantity_unit_id)[0];
            if (qty_unit != undefined)
                quantity_unit_name = qty_unit.name;
            let params = {
                name: this.file_details.name,
                qty: this.file_details.qty,
                consecutive: this.file_details.consecutive,
                matched_quote: this.file_details.matched_quote,
                quantity_unit_id: this.file_details.quantity_unit_id
            };

            this.file_details.quantity_unit_name = quantity_unit_name;

            this.$http
                .patch(`/update_working_file_details/${this.file_details.id}`, {
                    file_details: params,
                })
                .then(
                    (response) => {
                        this.update_file_details(this.file_details);
                        this.hide_working_file_details_dialog();
                        this.main_loading = false;
                        EventBus.$emit('reloadCategoryWorkingFiles');

                        this.$message({
                            type: "success",
                            message: "Details have been saved successfully.",
                        });
                    },
                    (response) => {
                        this.$message({
                            type: "error",
                            message: "Error happnened while saving details.",
                        });
                        this.main_loading = false;
                    }
                );
        },
        set_inputs_to_default() {
            let _this = this;
            Array.from(
                document.querySelectorAll(".working_file_details_div .el-input")
            ).forEach(function(e, i) {
                e.classList.add("browser-default");
                if (e.querySelector("input") != null)
                    e.querySelector("input").className += " browser-default";
            });
        },
    },
};
</script>

<style scoped>
.el-input {
    /* width: 150px; */
}
</style>
