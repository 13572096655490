<template>
  <div id="client-layout">
    <el-container>
      <el-col :span="3">
        <div>
          <img
            id="logo"
            src="/images/logo.png"
          >
        </div>
        <el-aside width="300">
          <navigation />
        </el-aside>
      </el-col>
      <el-container>
        <el-header>
          <el-row>
            <el-col :span="9">
              <div class="grid-content">
                {{ details.name }}
              </div>
            </el-col>
          </el-row>
        </el-header>
        <el-main>
          <transition name="fade">
            <router-view />
          </transition>
        </el-main>
        <el-footer>{{ current_year }} ANNEXGROUP</el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Navigation from './Navigation.vue';
import {mapActions, mapGetters} from 'vuex';

export default {
  components: {navigation: Navigation},
  data() {
    return {
      logo: '/images/logo.png'
    };
  },
  computed: {
    ...mapGetters(['details']),
    current_year() {
      let d = new Date();
      return d.getFullYear();
    }
  },
  async created() {
    await this.get_details();
  },
  methods: {
    ...mapActions(['get_details']),
  }
};
</script>

<style>
#logo {
  height: 50px;
  margin-left: 20px;
  padding-top: 20px;
}

#client-container .el-container {
  width: 100%;
  height: 100%;
}

#client-layout .el-aside {
  background-color: #fff;
  color: #373737;
  text-align: left;
  height: calc(100vh - 100px);
}

#client-layout a {
  color: #373737;
}

#client-layout .el-main {
  border: 0px;
  margin: 0px;
  padding: 0px;
  background: silver;
}

#client-layout .el-header {
  background: #00538b;
  min-width: 20vh;
  padding-top: 10px !important;
  color: #fff;
}

#client-layout .el-footer {
  background: #00538b;
  min-width: 20vh;
  padding: 10px;
  font-size: 12px;
  color: #fff;
  text-align: right;
}

.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.25s;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>