<template>
  <div>
    <div
      v-loading.fullscreen.lock="loading"
      class="row"
    >
      <div class="col m12">
        <h3>
          <el-radio-group
            v-model="selected_year"
            @change="fill_data"
          >
            <el-radio-button
              v-for="year in years"
              :key="year"
              :label="year"
            />
          </el-radio-group>
          <!-- <el-button v-for='year in years' type="text" selected_year=>{{year}}</el-button> -->
          <el-row>
            <el-button
              class="right"
              button
              type="primary"
              plain
              size="small"
              style="margin-top: -10px;"
              @click="create_new_record"
            >
              New Public Holiday
            </el-button>
          </el-row>
          <hr>
        </h3>
        <table class="responsive">
          <thead>
            <tr>
              <th>Public Holiday</th>
              <th>Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="record in data"
              :key="record.id"
            >
              <td>{{ record.name }}</td>
              <td>{{ record.date_formated }}</td>
              <td>
                <el-button
                  icon="el-icon-edit"
                  circle
                  @click="edit_record(record)"
                />
                <el-button
                  icon="el-icon-delete"
                  circle
                  @click="delete_record(record.id)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <el-dialog
      title="Edit Public Holiday"
      :visible.sync="dialogFormVisible"
      width="30%"
      @opened="set_default_input"
    >
      <el-form
        ref="public_holiday_form"
        :model="selected_public_holiday"
        :rules="rules"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              label="Name"
              prop="name"
            >
              <el-input
                v-model="selected_public_holiday.name"
                autocomplete="off"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="Date"
              prop="date"
            >
              <el-date-picker
                v-model="selected_public_holiday.date"
                type="date"
                placeholder="Pick a date"
                format="dd-MM-yyyy"
                value-format="dd-MM-yyyy"
                style="width: 100%;"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogFormVisible = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="submit('public_holiday_form')"
        >Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'PublicHolidays',
  data() {
    return {
      years: [],
      selected_year: '',
      dialogFormVisible: false,
      loading: false,
      data: [],
      selected_public_holiday: { id: '', name: '', date: '' },
      rules: {
        name: [
          {
            required: true,
            message: 'Please input a public holiday name',
            trigger: 'blur',
          },
        ],
        date: [
          {
            required: true,
            message: 'Please input the date',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  computed: {},
  created() {
    this.get_years(true);
  },
  mounted() {},

  methods: {
    get_years(first = false) {
      let _this = this;
      this.$http.get('/available_years').then((response) => {
        _this.years = response.data;
        if (first == true) {
          _this.selected_year = _this.years[0];
          _this.fill_data();
        }
      });
    },
    fill_data() {
      let _this = this;
      _this.loading = true;
      this.$http
        .post('/public_holidays_by_year', {
          selected_year: _this.selected_year,
        })
        .then((response) => {
          _this.loading = false;
          _this.data = response.data;
        });
    },
    create_new_record() {
      this.edit_record({
        id: '',
        name: '',
        date:
          '01-01-' +
          (this.selected_year ? this.selected_year : new Date().getFullYear()),
      });
    },
    edit_record(data) {
      this.selected_public_holiday = data;
      this.dialogFormVisible = true;
    },
    submit(form_name) {
      let url = '';
      this.$refs[form_name].validate((valid) => {
        if (valid) this.save_record();
      });
    },
    save_record() {
      let _this = this;
      let url = '';
      if (_this.selected_public_holiday.id == '')
        url = '/create_public_holiday';
      else url = '/update_public_holiday';
      _this.loading = true;
      this.$http.post(url, _this.selected_public_holiday).then((response) => {
        _this.loading = false;
        _this.dialogFormVisible = false;
        if (response.status == 200) {
          _this.$message({
            type: 'success',
            message: 'Updated successfully.',
          });
          _this.get_years();
          _this.fill_data();
          _this.selected_public_holiday = { id: '', name: '', date: '' };
        } else {
          _this.$message({
            type: 'error',
            message: response.data.message,
          });
        }
      });
    },
    delete_record(id) {
      let _this = this;

      this.$confirm(
        'This will permanently delete the record. Continue?',
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      )
        .then(() => {
          _this.loading = true;
          this.$http.delete('/delete_public_holiday/' + id).then((response) => {
            this.$message({
              type: 'success',
              message: 'Delete completed',
            });
            _this.loading = false;
            _this.fill_data();
          });
        })
        .catch(() => {});
    },
    set_default_input() {
      Array.from(document.querySelectorAll('.el-select__input, .el-input__inner')).forEach(
          function (e) {
            e.classList.add('browser-default');
            if (e.querySelector('input') != null)
              e.querySelector('input').className += ' browser-default';
          }
      );
    },
  },
};
</script>

<style scoped></style>
