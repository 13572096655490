<template>
  <div id="proposal_details">
    <div>
      <el-form
        ref="proposal"
        label-position="top"
        :model="proposal"
      >
        <error-explanation :errors="errors" />
        <input
          v-show="false"
          v-model="proposal.id"
          type="text"
        >

        <div class="row">
          <div class="col s4">
            <el-tag
              v-show="proposal.is_template == true"
              type="warning"
              size="mini"
              effect="dark"
            >
              TEMPLATE
            </el-tag>

            <div class="field">
              <p>
                <label class="el-form-item__label">
                  Created by:
                </label>
                <br>
                {{ proposal.crm_user }}
              </p>
            </div>

            <ProposalAccount
              v-show="proposal.account_id != null"
              :account_name="proposal.account_name"
              :account_on_contract="proposal.account_on_contract"
              :account_end_contract="proposal.account_end_contract"
              :contract_end_date="proposal.contract_end_date"
              :proposal_id="proposal.id"
            />
            <div class="field">
              <el-form-item
                label="Branch"
                prop="branch_id"
                required
              >
                <el-select
                  v-model="proposal.branch_id"
                  style="width:70%"
                  filterable
                  placeholder="Select Branch"
                  size="small"
                >
                  <el-option
                    v-for="branch in branches_list"
                    :key="branch.id"
                    :label="branch.name.toUpperCase()"
                    :value="branch.id"
                  />
                </el-select>
              </el-form-item>
            </div>
            <div class="field">
              <el-form-item
                label="Category"
                prop="category_task_id"
              >
                <el-select
                  v-model="proposal.category_task_id"
                  :disabled="false"
                  style="width:70%"
                  filterable
                  placeholder="Select Category"
                  size="small"
                >
                  <el-option
                    v-for="category in categories_list"
                    :key="category.id"
                    :label="category.name.toUpperCase()"
                    :value="category.id"
                  />
                </el-select>
              </el-form-item>
            </div>
            <div class="field">
              <el-form-item
                label="Quote type"
                prop="quote_type_id"
              >
                <el-select
                  v-model="proposal.quote_type_id"
                  style="width:70%"
                  filterable
                  placeholder="Select quote type"
                  size="small"
                >
                  <el-option
                    v-for="quote_type in quote_types_list"
                    :key="quote_type.id"
                    :label="quote_type.name.toUpperCase()"
                    :value="quote_type.id"
                  />
                </el-select>
              </el-form-item>
            </div>
            <div class="field">
              <el-form-item label="Job Name">
                <el-input
                  v-model="proposal.job_name"
                  type="text"
                  placeholder="Enter job name"
                  size="small"
                />
              </el-form-item>
            </div>
            <div class="row">
              <div class="col s4">
                <div class="field">
                  <el-form-item>
                    <label>Term in month</label>
                    <el-input
                      v-model="proposal.term_in_month"
                      type="text"
                      placeholder
                      size="mini"
                    />
                  </el-form-item>
                </div>
              </div>
              <div class="col s4">
                <div class="field">
                  <el-form-item>
                    <label>Client order number</label>
                    <el-input
                      v-model="proposal.client_order_number"
                      type="text"
                      placeholder
                      size="mini"
                    />
                  </el-form-item>
                </div>
              </div>
              <div class="col s4">
                <div class="field">
                  <el-form-item>
                    <label>Matched quote</label>
                    <el-input
                      v-model="proposal.matched_quote"
                      type="number"
                      placeholder
                      size="mini"
                    />
                  </el-form-item>
                </div>
              </div>
            </div>
            <div
              v-show="files_list.length == 0"
              class="row"
            >
              <div class="col s4">
                <div class="field">
                  <el-form-item label="Qty (Packing slip)">
                    <el-input
                      v-model.number="proposal.qty"
                      type="text"
                      placeholder
                      size="mini"
                    />
                  </el-form-item>
                </div>
              </div>
            </div>
            <div
              v-show="proposal.is_template == 0"
              class="row"
            >
              <div class="col s12">
                <el-switch
                  v-model="proposal_approval"
                  :disabled="proposal.approved == 1"
                  :value="true"
                  inactive-text="Pending"
                  active-text="Approved"
                  @change="approve_proposal"
                />
              </div>
              <div class="col s12">
                <el-switch
                  v-model="proposal.create_lists"
                  :visible="proposal.approved == 0"
                  :value="true"
                  inactive-text="None"
                  active-text="Create list on approval"
                />
              </div>
            </div>
          </div>
          <div class="col s2" />
          <div class="col s6">
            <div class="row">
              <div class="col s6">
                <el-form-item prop="notify_on_completion">
                  <el-checkbox
                    v-model="proposal.notify_on_completion"
                  >
                    Notify client on job completion
                  </el-checkbox>
                </el-form-item>
              </div>
              <div class="col s6">
                <el-form-item>
                  <template slot="label">
                    <b>Preferred date
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="Warning will appear in job page when preferred date exceed deadline."
                        placement="top-start"
                      >
                        <i class="el-icon-info" /> </el-tooltip></b>
                  </template>
                  <el-col
                    :xs="24"
                    :sm="24"
                    :md="24"
                    :lg="24"
                    :xl="24"
                  >
                    <el-date-picker
                      v-model="proposal.preferred_date"
                      type="datetime"
                      placeholder="Pick a date (optional)"
                      size="small"
                      :default-time="'17:00:00'"
                      :picker-options="pickerOptions"
                      @change="check_preferred_date"
                    />
                    <br>
                    <small v-show="proposal.deadline != undefined">
                      <b>Actual deadline: </b> {{ proposal.deadline }}
                    </small>
                  </el-col>
                </el-form-item>
              </div>
              <!-- <div class="col s12" v-show="proposal.completed == 0"> -->
              <div
                v-show="false"
                class="col s6"
              >
                <el-form-item prop="is_template">
                  <el-checkbox
                    v-model="proposal.is_template"
                  >
                    Mark it as template
                  </el-checkbox>
                </el-form-item>
              </div>
            </div>
            <div class="row">
              <div class="col s12">
                <ScheduledItem />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col s12" />
        </div>
        <div class="color__red">
          {{proposal.specific_colour_noti}}
        </div>
        <div class="row">
          <div class="col s12">
            <el-collapse v-model="activeNames">
              <el-collapse-item
                title="Job description"
                name="job_desc"
              >
                <el-form-item
                  prop="pre_description"
                  class="pdf_description_editor"
                  style="float:left;"
                >
                  <label>Job description</label>
                  <jodit-editor
                    id="pre_description_editor"
                    v-model="proposal.pre_description"
                    :buttons="buttons"
                    :config="jodit_config"
                    @click="update_description"
                  />
                </el-form-item>
              </el-collapse-item>

              <el-collapse-item
                title="PDF Description"
                name="pdf_desc"
              >
                <div style="padding:20px;">
                  <el-row>
                    <el-col
                      :xs="24"
                      :sm="24"
                      :md="24"
                      :lg="24"
                      :xl="24"
                    >
                      <b>DISPLAY OPTIONS</b>
                      <el-button
                        id="proposal_save_btn"
                        ref="save_btn"
                        type="info"
                        :disabled="proposal.approved == 1"
                        size="small"
                        class="right"
                        @click.prevent="submit_form"
                      >
                        UPDATE
                      </el-button>
                      <a
                        class="right black-bttn"
                        :href="proposal_link"
                      >PDF DOWNLOAD</a>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col
                      :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="12"
                      :xl="12"
                      class="options-box"
                    >
                      <el-col
                        :xs="24"
                        :sm="24"
                        :md="24"
                        :lg="24"
                        :xl="24"
                      >
                        <h2>SUMMARY</h2>
                        <el-form-item
                          prop="proposal_section_attributes.show_file_summary"
                        >
                          <el-switch
                            v-model="
                              proposal.proposal_section_attributes
                                .show_file_summary
                            "
                            active-color="#13ce66"
                            inactive-color="#ff4949"
                            class="right"
                          />
                        </el-form-item>
                      </el-col>
                      <el-col
                        :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="12"
                        :xl="12"
                      >
                        <el-form-item
                          prop="proposal_section_attributes.show_image"
                        >
                          <el-checkbox
                            v-model="
                              proposal.proposal_section_attributes.show_image
                            "
                            class="blackcheck"
                          >
                            Images (Under construction)
                          </el-checkbox>
                        </el-form-item>
                      </el-col>

                      <el-col
                        :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="12"
                        :xl="12"
                      >
                        <el-form-item
                          prop="proposal_section_attributes.show_sizes"
                        >
                          <el-checkbox
                            v-model="
                              proposal.proposal_section_attributes.show_sizes
                            "
                            class="blackcheck"
                          >
                            Sizes (Under construction)
                          </el-checkbox>
                        </el-form-item>
                      </el-col>
                      <el-col
                        :xs="24"
                        :sm="24"
                        :md="4"
                        :lg="4"
                        :xl="4"
                      />
                    </el-col>
                    <el-col
                      :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="12"
                      :xl="12"
                      class="options-box"
                    >
                      <el-col
                        :xs="24"
                        :sm="24"
                        :md="24"
                        :lg="24"
                        :xl="24"
                      >
                        <h2>BREAKDOWN</h2>
                        <el-switch
                          v-model="
                            proposal.proposal_section_attributes
                              .show_pdf_breakdown
                          "
                          active-color="#13ce66"
                          inactive-color="#ff4949"
                          class="right"
                        />
                      </el-col>
                      <el-col
                        :xs="24"
                        :sm="24"
                        :md="6"
                        :lg="6"
                        :xl="6"
                      >
                        <el-form-item
                          prop="proposal_section_attributes.show_subtotal"
                        >
                          <el-checkbox
                            v-model="
                              proposal.proposal_section_attributes.show_subtotal
                            "
                            class="blackcheck"
                          >
                            Total Unit Price
                          </el-checkbox>
                        </el-form-item>
                      </el-col>
                      <el-col
                        :xs="24"
                        :sm="24"
                        :md="6"
                        :lg="6"
                        :xl="6"
                      >
                        <el-form-item
                          prop="proposal_section_attributes.show_unit_sale_price"
                        >
                          <el-checkbox
                            v-model="
                              proposal.proposal_section_attributes
                                .show_unit_sale_price
                            "
                            class="blackcheck"
                          >
                            Unit Price
                          </el-checkbox>
                        </el-form-item>
                      </el-col>

                      <el-col
                        :xs="24"
                        :sm="24"
                        :md="6"
                        :lg="6"
                        :xl="6"
                      >
                        <el-form-item
                          prop="proposal_section_attributes.show_qty"
                        >
                          <el-checkbox
                            v-model="
                              proposal.proposal_section_attributes.show_qty
                            "
                            class="blackcheck"
                          >
                            Stocks/tasks Qty
                          </el-checkbox>
                        </el-form-item>
                      </el-col>
                      <el-col
                        :xs="24"
                        :sm="24"
                        :md="24"
                        :lg="24"
                        :xl="24"
                      >
                        <el-form-item
                          prop="proposal_section_attributes.group_items"
                        >
                          <el-checkbox
                            v-model="
                              proposal.proposal_section_attributes.group_items
                            "
                            class="blackcheck"
                          >
                            Group stocks & tasks
                          </el-checkbox><span
                            style="font-size:10px;"
                          >(Uncheck to view individual quantities/unit
                            prices)</span>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                  <div
                    class="col s12"
                    style="border-bottom:20px;background:#f3f3f3; padding:10px; border-radius:10px;"
                  >
                    <el-col
                      :xs="24"
                      :sm="24"
                      :md="6"
                      :lg="6"
                      :xl="6"
                    >
                      <el-form-item prop="edit_pdf_description">
                        <el-checkbox
                          v-model="proposal.edit_pdf_description"
                          class="blackcheck"
                        >
                          Edit PDF description
                        </el-checkbox>
                      </el-form-item>
                    </el-col>
                    <el-col
                      :xs="24"
                      :sm="24"
                      :md="6"
                      :lg="6"
                      :xl="6"
                    >
                      <el-form-item
                        prop="proposal_section_attributes.show_total_sale"
                      >
                        <el-checkbox
                          v-model="
                            proposal.proposal_section_attributes.show_total_sale
                          "
                          class="blackcheck"
                        >
                          Total Sub Sale
                        </el-checkbox>
                      </el-form-item>
                    </el-col>
                    <el-col
                      :xs="24"
                      :sm="24"
                      :md="6"
                      :lg="6"
                      :xl="6"
                    >
                      <el-form-item
                        prop="proposal_section_attributes.show_job_total"
                      >
                        <el-checkbox
                          v-model="
                            proposal.proposal_section_attributes.show_job_total
                          "
                          class="blackcheck"
                        >
                          Job Total
                        </el-checkbox>
                      </el-form-item>
                    </el-col>
                  </div>
                </div>
                <el-form-item class="pdf_description_editor">
                  <label>PDF description</label>
                  <jodit-editor
                    id="pdf_description_editor"
                    v-model="proposal.pdf_description"
                    :buttons="buttons"
                    :config="jodit_config"
                  />
                </el-form-item>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
      </el-form>
    </div>
    <div class="row">
      <hr>
      <div class="col m12">
        <a
          v-show="proposal.account_id > 0"
          :href="`/account/proposals/${proposal.account_id}`"
          class="btn"
        >BACK</a>
        <el-button
          id="proposal_save_btn"
          ref="save_btn"
          type="primary"
          :disabled="proposal.approved == 1"
          class="right"
          @click.prevent="submit_form"
        >
          SAVE
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { editor_store, mm_to_px, xml_header } from 'store/modules/editor';
import { debuglog } from 'util';

// mixins
import { JobProposalMixin } from 'mixins/JobProposalMixin.js';
import { ProposalMixin } from 'mixins/ProposalMixin.js';
import { ProposalStockItemMixin } from 'mixins/ProposalStockItemMixin.js';

// components
import ScheduledItem from 'components/proposal/ScheduledItem';
import ProposalAccount from 'components/proposal/ProposalAccount';
import ErrorExplanation from '../shared/ErrorExplanation';

export default {
  name: 'ProposalDetails',
  components: {
    'error-explanation': ErrorExplanation,
    ScheduledItem: ScheduledItem,
    ProposalAccount,
  },
  store: editor_store,
  mixins: [ProposalMixin, JobProposalMixin, ProposalStockItemMixin],
  data() {
    return {
      errors: [],
      content: null,
      jodit_config: { enter: 'br' },
      pickerOptions: {
        // disabledDate(time) {
        //   return time.getTime() < Date.now();
        // },
      },
      // jodit_events: {
      //   change: function(event) {
      //     this.update_description;
      //   },
      // },
      config: {
        // { [module]: boolean (set true to hide) }
        hideModules: { bold: true },

        // you can override icons too, if desired
        // just keep in mind that you may need custom styles in your application to get everything to align
        iconOverrides: { bold: '<i class=\'your-custom-icon\'></i>' },

        // if the image option is not set, images are inserted as base64
        image: {
          uploadURL: '/api/myEndpoint',
          dropzoneOptions: {},
        },

        // limit content height if you wish. If not set, editor size will grow with content.
        maxHeight: '500px',

        // set to 'true' this will insert plain text without styling when you paste something into the editor.
        forcePlainTextOnPaste: true,
      },
      activeNames: 1,
      categories_list: [],
      branches_list: [],
      buttons: [
        'fullsize',
        'source',
        '|',
        'table',
        'image',
        'link',
        '|',
        'bold',
        'underline',
        'strikethrough',
        'italic',
        '|',
        'paragraph',
        'font',
        'fontsize',
        '|',
        'ul',
        'ol',
        '|',
        'undo',
        'redo',
        '|',
        'selectall',
        'cut',
        'copy',
        'paste',
        'copyformat',
        '|',
        'align',
        'outdent',
        'indent',
        '|',
        'hr',
        'brush',
        'eraser',
        'symbol',
        '|',
        'superscript',
        'subscript',
        '|',
        'print',
      ],
      defaultActionOnPaste: 'insert_clear_html',
    };
  },
  methods: {
    update_description() {
      this.proposal.edit_pdf_description = true;
    },
    check_preferred_date() {
      if (
        this.proposal.preferred_date != null &&
        new Date(this.proposal.preferred_date) > new Date(this.proposal.dated)
      )
        this.$message({
          type: 'warning',
          message: 'Preferred date exceed deadline !',
        });
    },
    onBlur(e) {
      console.log('blur', e);
    },
    ...mapActions([
      'set_proposal',
      'load_files_list',
      'set_stock_items_list',
      'set_job_proposals_list',
      'load_charge_pos_list',
      'set_jobs_list',
      'set_quote_types_list',
    ]),
    select_target_account(e) {
      this.selected_target_account = e;
    },
    remoteMethod(query) {
      let _this = this;
      if (query !== '' && query.trim().length > 4) {
        setTimeout(() => {
          let encoded_query = encodeURIComponent(query);
          this.$http
            .get(`/autocomplete_accounts?term=${encoded_query}`)
            .then((response) => {
              _this.accounts_result = response.body.data;
            });
        }, 200);
      } else {
        this.accounts_result = [];
      }
    },
    show_proposal_account() {
      this.change_account_dialog = true;
    },
    confirm_proposal_account() {
      let _this = this;
      this.$confirm(
        'Are you sure to change current proposal account, it will change everything related to this job to the newly selected account ?',
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      )
        .then(() => {
          _this
            .change_proposal_account(
              this.proposal.id,
              this.selected_target_account
            )
            .then(
              (response) => {
                location.reload();
              },
              (response) => {
                this.$message({ message: 'Error happened', type: 'error' });
              }
            );
        })
        .catch(() => {
          return false;
        });
    },
    approve_proposal(e) {
      if (this.job_proposals_list.filter(i => i.is_included === true).length == 0) {
        this.$message({
          type: 'error',
          message: 'Can\'t approve. Please create at least one task.',
        });
        return;
      }
      if (e == true) {
        this.$refs['proposal'].validate((valid) => {
          if (this.is_workspace_proposal == true) {
            this.$message({
              message:
                  'Cannot approve a proposal with quote type \'WORKSPACE\'!',
              type: 'error',
            });
            return false;
          }
          if (valid) {
            this.proposal.approved = 1;
            this.save(true);
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },

    load_branches() {
      let _this = this;
      this.$http.get('/branches_list').then(
        function(response) {
          // Success
          if (response.status == 200) {
            _this.branches_list = response.data;
          }
        },
        function(response) {
          // error
          console.log(response);
        }
      );
    },
    load_categories() {
      let _this = this;
      let query_str = '';
      this.$http.get('/category_tasks_list').then(
        function(response) {
          // Success
          if (response.status == 200) {
            _this.categories_list = response.data;
          }
        },
        function(response) {
          // error
          console.log(response);
        }
      );
    },
    load_stocks_list() {
      let _this = this;
      setTimeout(function() {
        _this.load_stock_items_list(_this.proposal.id).then((response) => {
          _this.set_stock_items_list(response);
        });
      }, 500);
    },
    load_quote_types() {
      let _this = this;
      let query_str = '';
      this.$http.get('/quote_types').then(
        function(response) {
          // Success
          _this.set_quote_types_list(response.data);
        },
        function(response) {
          // error
          console.log(response);
        }
      );
    },
    load_details() {
      let _this = this;
      let arr = window.location.search.substring(1).split('=');

      if (arr.length < 1) {
        return;
      }
      this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      this.$http
        .get('/proposals/' + arr[1])
        .then(function(response) {
          _this.set_proposal(response.body);
          _this.$loading().close();
          _this.load_files_list();
          _this.load_stocks_list();
          _this.load_jps_list();
          _this.load_charge_pos_list();
          _this.load_jobs_list();
        })
        .then(function(response) {
          _this.$loading().close();
        });
    },
    submit_form() {
      let _this = this;
      this.$refs['proposal'].validate((valid) => {
        if (valid) {
          _this.save();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    load_jps_list() {
      this.load_job_proposals(this.proposal.id).then((response) => {
        this.set_job_proposals_list(response);
      });
    },
    load_jobs_list() {
      this.load_jobs_categories(this.proposal.category_task_id).then(
        (response) => {
          this.set_jobs_list(response);
        }
      );
    },
    save(newly_approved = false) {
      this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      this.$refs['save_btn'].disabled = true;
      let _this = this;
      _this.errors = [];
      let url = '/update_proposal_details/' + this.proposal.id;
      this.$http.patch(url, this.proposal).then(
        (response) => {
          _this.$loading().close();
          if(this.client_view==true && newly_approved == true) {
            window.location = `/client/login#/thank-you/${this.proposal.id}/${false}`
          } else {
            this.$message({
              message: 'Updated successfully.',
              type: 'success',
            });
            this.load_details();
          }
        },
        function(response) {
          if (newly_approved == true) this.proposal.approved = 0;
          this.$refs['save_btn'].disabled = false;
          console.log(response.message);
          this.$message({
            message: response.body.message,
            dangerouslyUseHTMLString: true,
            type: 'warning',
            duration: 20000,
            showClose: true,
          });
          _this.$loading().close();
        }
      );
    },
    set_default_input() {
      let _this = this;
      Array.from(document.querySelectorAll('.el-input__inner')).forEach(
        function(e, i) {
          e.classList.add('browser-default');
          if (e.querySelector('input') != null)
            e.querySelector('input').className += ' browser-default';
        }
      );
    },
  },
  mounted() {
    this.load_details();
    this.load_categories();
    this.load_quote_types();
    this.set_default_input();
    this.load_branches();
  },
  computed: {
    ...mapGetters([
      'proposal',
      'proposal_id',
      'job_proposals_list',
      'files_list',
      'quote_types_list',
      'is_workspace_proposal',
      'client_view'
    ]),

    proposal_link() {
      return `/proposals/${this.proposal.id}.pdf`;
    },
    proposal_approval() {
      return this.proposal.approved == 1 ? true : false;
    },
  },
  watch: {
    'proposal.approved': function(newVal) {},
    'proposal.id': function(newVal) {},
  },
};
</script>

<style>
svg.jodit_icon {
  border: 0px !important;
}
.pdf_description_editor .el-form-item__content {
  line-height: 19px !important;
  width: 730px;
  margin-left: auto;
  margin-right: auto;
}

.blackcheck .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #909399 !important;
}
.blackcheck.el-checkbox.is-bordered.is-checked {
  border-color: #909399 !important;
}
.blackcheck .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #909399 !important;
  border-color: #909399 !important;
}
.jodit_wysiwyg table tr td {
  border-bottom: 0px;
  border-left: 0px;
  border-right: 0px;
}

a.black-bttn {
  background: #000;
  padding: 5px 10px;
  color: #fff;
  border-radius: 4px;
  margin-right: 10px;
}
a.black-bttn:hover {
  background: #999;
}
.pdf_description_editor .jodit_wysiwyg strong {
  font-weight: 900 !important;
}
</style>
