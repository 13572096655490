<template>
  <div
    id="task_time_container_id"
    class="container"
  >
    <div class="sub-title">
      Input Task Time
    </div>
    <TaskTimeSpentsList
      ref="task_time_spents_list_ref"
      :time_sheet_id="time_sheet_id"
      :is_hours="is_hours"
      @onRefreshTimeSheets="onRefreshTimeSheets"
    />
    <br>
    <el-form label-width="130px">
      <el-form-item label="Allocated Jobs">
        <JobSearchSelect
          v-model="selected_allocated_job_id"
          :crm_user_id="selected_user_info.id"
          :load_jobs_func="load_allocated_jobs"
        />
      </el-form-item>
      <el-form-item>
        <HoursInput
          v-if="is_hours"
          v-model="allocated_job_time"
        />
        <el-input-number
          v-else
          v-model="allocated_job_time"
          size="small"
          :min="1"
        />
        <el-button
          type="primary"
          class="job-time__btn"
          size="mini"
          @click="onAddJobTime"
        >
          Add
        </el-button>
      </el-form-item>
      <el-form-item label="Unallocated Jobs">
        <JobSearchSelect
          v-model="selected_unallocated_job_id"
          :crm_user_id="selected_user_info.id"
          :load_jobs_func="load_unallocated_jobs"
        />
      </el-form-item>
      <el-form-item>
        <HoursInput
          v-if="is_hours"
          v-model="unallocated_job_time"
        />
        <el-input-number
          v-else
          v-model="unallocated_job_time"
          size="small"
          :min="1"
        />
        <el-button
          type="primary"
          class="job-time__btn"
          size="mini"
          @click="onAddUnallocatedJobTime"
        >
          Add
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {defaultInput} from '../../../utilities/DefaultInput';
import {loadAllocatedJobs, loadUnallocatedJobs} from '../../../api/job_proposals';
import {createTaskTimeSpent} from '../../../api/task_time_spent';
import {loadIsHours} from  '../../../api/organization';

import { mapGetters } from 'vuex';
import { user_profile } from 'store/modules/user_profile';
import HoursInput from '../../shared/HoursInput';
import TaskTimeSpentsList from './TaskTimeSpentsList';
import JobSearchSelect from './JobSearchSelect';

export default {
  name: 'TasksTime',
  components: {HoursInput, TaskTimeSpentsList, JobSearchSelect},
  store: user_profile,
  props: {
    time_sheet_date: {
      type: String,
      required: true
    },
    time_sheet_id: {
      validator: (value) => {
        return (typeof value === 'number' || typeof value === 'undefined');
      },
      required: true
    },
  },
  data() {
    return {
      is_hours: true,
      allocated_job_time: '',
      unallocated_job_id: '',
      unallocated_job_time: '',
      selected_allocated_job_id: '',
      selected_unallocated_job_id: '',
    };
  },
  computed: {
    ...mapGetters(['selected_user_info']),
    load_allocated_jobs() {
      return loadAllocatedJobs;
    },
    load_unallocated_jobs() {
      return loadUnallocatedJobs;
    },
  },
  created() {
    loadIsHours(this).then(({data}) => {
      this.is_hours = data.is_hours;
      this.$nextTick(() => { defaultInput('#task_time_container_id'); });
    });
  },
  mounted() {
    this.$nextTick(() => { defaultInput('#task_time_container_id'); });
  },
  methods: {
    onRefreshTimeSheets() {
      this.$emit('onRefreshTimeSheets');
    },
    onAddJobTime() {
      if(this.check_validations(this.selected_allocated_job_id, this.allocated_job_time) === false)
        return;

      this.create_task_time_spent(this.selected_allocated_job_id, this.allocated_job_time);
    },
    onAddUnallocatedJobTime() {
      if(this.check_validations(this.selected_unallocated_job_id, this.unallocated_job_time) === false)
        return;

      this.create_task_time_spent(this.selected_unallocated_job_id, this.unallocated_job_time);
    },
    check_validations(selected_job_id, job_time) {
      if(!this.time_sheet_id) {
        this.warning_message('Need to make a time sheet first');
        return false;
      }
      if(!selected_job_id) {
        this.warning_message('Need to select a job');
        return false;
      }
      if(!job_time || job_time <= 0) {
        this.warning_message('Need to input time more than 0');
        return false;
      }
      return true;
    },
    create_task_time_spent(selected_job_id, job_time) {
      createTaskTimeSpent(this, this.selected_user_info.id, selected_job_id, job_time, this.time_sheet_id).then(({data})=>{
        this.$refs.task_time_spents_list_ref.load_time_spents();
        this.$message({
          type: 'success',
          message: data.message
        });
        this.onRefreshTimeSheets();
      });
    },
    warning_message(msg) {
      this.$message({
        type: 'warning',
        message: msg
      });
    }
  }
};
</script>

<style lang='scss' scoped>
@import '../../../../assets/stylesheets/components/two_columns';

.job-time__btn {
  margin-left: 1rem;
}
</style>
