<template>
  <div />
</template>

<script>
export default {
  name: 'ClientLogInSession',
  props: {
    message: {
      type: String,
      required: true
    },
    where_to: {
      type: String,
      required: true
    }
  },
  created() {
    this.$message({
      type: 'error',
      message: this.message,
    });
    this.go_to_location();
  },
  methods: {
    go_to_location() {
      setTimeout(()=>{ window.location = this.where_to; }, 2200);
    }
  }
};
</script>
