<template>
  <div class="row job_proposals_list">
    <div class="col s12 m12">
      <div class="card grey lighten-5">
        <div class="card-content">
          <span class="card-title">Tasks list</span>

          <hr>
          <NewTask :working_file_id="working_file_id" />
          <table class="responsive-table highlight job_proposals_list_table">
            <thead>
              <tr>
                <th>Working file</th>
                <th>Name</th>
                <th
                  v-show="proposal.approved == 1 || proposal.approved == true"
                >
                  Billable
                </th>
                <th>Is urgent</th>
                <th v-show="proposal.quote_type_id == 5">
                  Is initiated
                </th>
                <th>Time allocated labour</th>
                <th>Overwrite labour</th>
                <th
                  v-show="proposal.approved == 1 || proposal.approved == true"
                >
                  Time allocated adjusted
                </th>
                <th>Unit sale price</th>
                <th>Total sale price</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <JobProposalEntry
                v-for="job in grouped_job_proposals_list"
                :key="job.id"
                :job="job"
                :style="[
                  job.mark_as_deleted == 1
                    ? {
                      'text-decoration': 'line-through',
                      'font-style:': 'italic',
                    }
                    : {},
                ]"
              />
            </tbody>
          </table>
        </div>
        <div class="card-action" />
      </div>
    </div>
  </div>
</template>

<script>
import JobProposalEntry from './../job_proposals/JobProposalEntry';
import NewTask from './../job_proposals/NewTask';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { editor_store } from 'store/modules/editor';
import { JobProposalMixin } from 'mixins/JobProposalMixin.js';
import { ProposalMixin } from 'mixins/ProposalMixin.js';

export default {
  name: 'JobProposalsList',
  components: { JobProposalEntry, NewTask },
  store: editor_store,
  mixins: [JobProposalMixin, ProposalMixin],
  props: ['working_file_id'],
  data() {
    return {
      selected: [],
      options: [],
      is_loading: false,
      jobs_categories_list: [],
    };
  },
  computed: {
    ...mapGetters(['proposal', 'job_proposals_list']),
    grouped_job_proposals_list() {
      if (this.working_file_id == undefined ) return this.job_proposals_list;
      else
        return this.job_proposals_list.filter(
          (c) => c.working_file_id == this.working_file_id
        );
    },
  },
  mounted: function() {
    //this.categories();
    //this.load_jobs_list();
    // this.load_jobs_categories_list();
    this.set_inputs_to_default();
  },

  methods: {
    // load_jobs_categories_list: function() {
    //   this.load_jobs_categories(this.proposal.category_task_id).then(response => {
    //       this.jobs_categories_list = response;
    //     });
    // },
    set_inputs_to_default() {
      let _this = this;
      setTimeout(() => {
        Array.from(
          document.querySelectorAll('.job_proposals_list .el-input')
        ).forEach(function(e, i) {
          e.classList.add('browser-default');
          if (e.querySelector('input') != null)
            e.querySelector('input').className += ' browser-default';
        });
      }, 300);
    },
    current_selected_value(selectedValue) {
      let self = this;
      if (selectedValue.main_category != undefined) this.options = [];
      if (this.selected != null) {
        if (this.selected.length == 1) {
          this.stock_item_id = selectedValue.id;
          this.disabled = 'false';
          // self.save_stock_item();
        }
      }
    },
    // load_jobs_list: function() {
    //   let _this = this;
    //   let params = { id: this.proposal.id };
    //   this.$http
    //     .get("/proposal_tasks_list", {
    //       params: params
    //     })
    //     .then(
    //       response => {
    //         _this.jobs_list = response.body;
    //       },
    //       function(response) {}
    //     );
    // }
  },
};
</script>

<style>
.job_proposals_list_table td {
  padding: 5px;
}
</style>
