<template>
  <el-table
    :data="list"
    stripe
    show-summary
    :summary-method="onSummary"
    style="width: 100%"
  >
    <el-table-column
      prop="user_name"
      label="User"
      width="110"
    />
    <el-table-column
      prop="task_name"
      label="Task Name"
    />
    <el-table-column
      prop="time_in_minutes"
      label="Spent Time"
    >
      <template slot-scope="scope">
        <Time
          :is_hours="scope.row.is_hours"
          :minutes="scope.row.time_in_minutes"
        />
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import {SumFromArray} from '../../../utilities/Number';
import {display_time} from '../../../utilities/Time';
import Time from '../../shared/Time';

export default {
  name: 'TaskTimeSpentList',
  components: { Time },
  props: {
    list: {
      type: Array,
      required: true
    },
  },
  methods: {
    onSummary(param) {
      const { columns, data } = param;
      const sums = [];
      if (data.length === 0) return sums;

      sums[0] = 'Total';
      const values = data.map(item => Number(item[columns[2].property]));
      if (!values.every(value => isNaN(value))) {
        let sum_spent = SumFromArray(values);
        sums[2] = `${display_time(true, sum_spent)} | ${display_time(false, sum_spent)}`;
      }
      return sums;
    }
  }
};
</script>
