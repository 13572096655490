<template>
  <div>
    <el-col
      v-if="production.is_show == true"
      :xs="24"
      :sm="12"
      :md="12"
      :lg="12"
      :xl="8"
    >
      <KPIGauge
        :value="production.current_percent"
        :label="production.label"
        :stops="production.stops"
        :extra_infos="production.extra_infos"
      />
    </el-col>
    <el-col
      v-if="sale_min.is_show == true"
      :xs="24"
      :sm="12"
      :md="12"
      :lg="12"
      :xl="8"
    >
      <KPIGauge
        :value="sale_min.current_percent"
        :label="sale_min.label"
        :stops="sale_min.stops"
        :extra_infos="sale_min.extra_infos"
      />
    </el-col>
    <el-col
      v-if="sale_amount.is_show == true"
      :xs="24"
      :sm="12"
      :md="12"
      :lg="12"
      :xl="8"
    >
      <KPIGauge
        :value="sale_amount.current_percent"
        :label="sale_amount.label"
        :stops="sale_amount.stops"
        :extra_infos="sale_amount.extra_infos"
      />
    </el-col>
  </div>
</template>

<script>
import KPIGauge from './KPIGauge';
import {maxValue} from '../../utilities/MaxValue';
import {display_time} from '../../utilities/Time';

export default {
  name: 'PerformanceGauge',
  components: {
    KPIGauge
  },
  props: {
    kpis_gauge: {
      type: Object,
      required: true
    },
    is_manager: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      production : {
        stops: [0,50,70,90,100],
        current_percent: 0,
        label: 'Production',
        is_show: false,
        extra_infos: []
      },
      sale_min: {
        stops: [0,50,70,90,100],
        current_percent: 0,
        label: 'Performance-Sale(Min)',
        is_show: false,
        extra_infos: []
      },
      sale_amount: {
        stops: [0,50,70,90,100],
        current_percent: 0,
        label: 'Performance-Sale(Amount)',
        is_show: false,
        extra_infos: []
      }
    };
  },
  watch: {
    kpis_gauge() {
      this.production_init();
      this.sale_min_init();
      this.sale_amount_init();
    }
  },
  methods: {
    production_init() {
      this.production.is_show = this.kpis_gauge.target_section.by_production_minutes;

      if (this.kpis_gauge.target_section.performance_billable) {
        this.production_billable_init();
      } else {
        this.production_normal_init();
      }

      this.production.stops= [
        0,
        this.kpis_gauge.target_section.performance_orange,
        this.kpis_gauge.target_section.performance_yellow,
        this.kpis_gauge.target_section.performance_green,
        100,
      ];
    },
    production_billable_init() {
      this.production.current_percent = maxValue(this.kpis_gauge.performance.production.percentage_billable);
      this.production.label = `Production(Billable): ${this.kpis_gauge.performance.production.percentage_billable}%`;
      if(this.is_manager == true) {
        this.production.extra_infos = [
          {
            attr: 'BILLABLE MINUTES',
            val:  display_time(this.kpis_gauge.is_hours, this.kpis_gauge.performance.production.time_allocated_billable)
          },
          {
            attr: 'TARGET',
            val: `${this.kpis_gauge.performance.production.performance_total}%`
          },
          {
            attr: 'REACHED',
            val: `${this.kpis_gauge.performance.production.percentage_billable}%`
          },
          {
            attr: 'WEIGH-IN RATE',
            val: `${this.kpis_gauge.performance.production.minutes_billable_total}%`
          },
        ];
      }
    },
    production_normal_init() {
      this.production.current_percent = maxValue(this.kpis_gauge.performance.production.percentage_total);
      this.production.label = `Production: ${this.kpis_gauge.performance.production.percentage_total}%`;
      if(this.is_manager == true) {
        this.production.extra_infos = [
          {
            attr: 'TOTAL MINUTES',
            val:  display_time(this.kpis_gauge.is_hours, this.kpis_gauge.performance.production.total_minutes)
          },
          {
            attr: 'TARGET',
            val: `${this.kpis_gauge.performance.production.performance_total}%`
          },
          {
            attr: 'REACHED',
            val: `${this.kpis_gauge.performance.production.percentage_total}%`
          },
          {
            attr: 'WEIGH-IN RATE',
            val: `${this.kpis_gauge.performance.production.minutes_total}%`
          },
        ];
      }
    },
    sale_min_init() {
      this.sale_min.is_show = this.kpis_gauge.target_section.by_sales_minutes;

      if(this.kpis_gauge.target_section.sales_minutes_approved && this.kpis_gauge.target_section.sales_minutes_billable) {
        this.sale_min_approved_billable_init();
      }
      else if (this.kpis_gauge.target_section.sales_minutes_billable) {
        this.sales_minutes_billable_init();
      }
      else if (this.kpis_gauge.target_section.sales_minutes_approved) {
        this.sale_min_approved_init();
      }
      else {
        this.sale_min_normal_init();
      }

      this.sale_min.stops= [
        0,
        this.kpis_gauge.target_section.sales_minutes_orange,
        this.kpis_gauge.target_section.sales_minutes_yellow,
        this.kpis_gauge.target_section.sales_minutes_green,
        100,
      ];
    },
    sale_min_approved_billable_init() {
      this.sale_min.current_percent = maxValue(this.kpis_gauge.performance.sale_min.approved_percentage_billable);
      this.sale_min.label = `Sales Min(Approved Billable): ${this.kpis_gauge.performance.sale_min.approved_percentage_billable}%`;
      if(this.is_manager == true) {
        this.sale_min.extra_infos = [
          {
            attr: 'Approved Billable Total',
            val: display_time(this.kpis_gauge.is_hours, this.kpis_gauge.performance.sale_min.approved_billable_total)
          },
          {
            attr: 'TARGET',
            val: display_time(this.kpis_gauge.is_hours, this.kpis_gauge.performance.sale_min.minutes_average)
          },
          {
            attr: 'REACHED',
            val: `${this.kpis_gauge.performance.sale_min.approved_percentage_billable}%`
          },
          {
            attr: 'WEIGH-IN RATE',
            val: `${this.kpis_gauge.performance.sale_min.approved_billable_perc}%`
          },
        ];
      }
    },
    sales_minutes_billable_init() {
      this.sale_min.current_percent = maxValue(this.kpis_gauge.performance.sale_min.percentage_billable);
      this.sale_min.label = `Sales Min(Billable): ${this.kpis_gauge.performance.sale_min.percentage_billable}%`;
      if(this.is_manager == true) {
        this.sale_min.extra_infos = [
          {
            attr: 'Billable Total',
            val: display_time(this.kpis_gauge.is_hours, this.kpis_gauge.performance.sale_min.total_billable)
          },
          {
            attr: 'TARGET',
            val: display_time(this.kpis_gauge.is_hours, this.kpis_gauge.performance.sale_min.minutes_average)
          },
          {
            attr: 'REACHED',
            val: `${this.kpis_gauge.performance.sale_min.percentage_billable}%`
          },
          {
            attr: 'WEIGH-IN RATE',
            val: `${this.kpis_gauge.performance.sale_min.total_billable_perc}%`
          },
        ];
      }
    },
    sale_min_approved_init() {
      this.sale_min.current_percent = maxValue(this.kpis_gauge.performance.sale_min.approved_percentage_total);
      this.sale_min.label = `Sales Min(Approved): ${this.kpis_gauge.performance.sale_min.approved_percentage_total}%`;
      if(this.is_manager == true) {
        this.sale_min.extra_infos = [
          {
            attr: 'Approved Total',
            val: display_time(this.kpis_gauge.is_hours, this.kpis_gauge.performance.sale_min.total_approved)
          },
          {
            attr: 'TARGET',
            val: display_time(this.kpis_gauge.is_hours, this.kpis_gauge.performance.sale_min.minutes_average)
          },
          {
            attr: 'REACHED',
            val: `${this.kpis_gauge.performance.sale_min.approved_percentage_total}%`
          },
          {
            attr: 'WEIGH-IN RATE',
            val: `${this.kpis_gauge.performance.sale_min.approved_total_perc}%`
          },
        ];
      }
    },
    sale_min_normal_init() {
      this.sale_min.current_percent = maxValue(this.kpis_gauge.performance.sale_min.percentage_total);
      this.sale_min.label = `Sales Min: ${this.kpis_gauge.performance.sale_min.percentage_total}%`;
      if(this.is_manager == true) {
        this.sale_min.extra_infos = [
          {
            attr: 'MINUTES TOTAL',
            val: display_time(this.kpis_gauge.is_hours, this.kpis_gauge.performance.sale_min.total)
          },
          {
            attr: 'TARGET',
            val: display_time(this.kpis_gauge.is_hours, this.kpis_gauge.performance.sale_min.minutes_average)
          },
          {
            attr: 'REACHED',
            val: `${this.kpis_gauge.performance.sale_min.percentage_total}%`
          },
          {
            attr: 'WEIGH-IN RATE',
            val: `${this.kpis_gauge.performance.sale_min.minutes_total_perc}%`
          },
        ];
      }
    },
    sale_amount_init() {
      this.sale_amount.is_show = this.kpis_gauge.target_section.by_sales_amount;

      if(this.kpis_gauge.target_section.sales_amount_approved && this.kpis_gauge.target_section.sales_amount_billable) {
        this.sale_amount_approved_billable_init();
      }
      else if (this.kpis_gauge.target_section.sales_amount_billable) {
        this.sale_amount_billable_init();
      }
      else if (this.kpis_gauge.target_section.sales_amount_approved) {
        this.sale_amount_approved_init();
      }
      else {
        this.sale_amount_normal_init();
      }

      this.sale_amount.stops= [
        0,
        this.kpis_gauge.target_section.sales_amount_orange,
        this.kpis_gauge.target_section.sales_amount_yellow,
        this.kpis_gauge.target_section.sales_amount_green,
        100,
      ];
    },
    sale_amount_approved_billable_init() {
      this.sale_amount.current_percent = maxValue(this.kpis_gauge.performance.sale_amount.approved_percentage_billable);
      this.sale_amount.label = `Sales Amount(Approved Billable): ${this.kpis_gauge.performance.sale_amount.approved_percentage_billable}%`;
      if(this.is_manager == true) {
        this.sale_amount.extra_infos = [
          {
            attr: 'Approved Billable Amount',
            val: `${this.kpis_gauge.performance.sale_amount.amount_approved_billable}`
          },
          {
            attr: 'TARGET',
            val: `${this.kpis_gauge.performance.sale_amount.amount_average}`
          },
          {
            attr: 'REACHED',
            val: `${this.kpis_gauge.performance.sale_amount.approved_percentage_billable}%`
          },
          {
            attr: 'WEIGH-IN RATE',
            val: `${this.kpis_gauge.performance.sale_amount.amount_approved_total_perc}%`
          },
        ];
      }
    },
    sale_amount_billable_init() {
      this.sale_amount.current_percent = maxValue(this.kpis_gauge.performance.sale_amount.percentage_billable);
      this.sale_amount.label = `Sales Amount(Billable): ${this.kpis_gauge.performance.sale_amount.percentage_billable}%`;
      if(this.is_manager == true) {
        this.sale_amount.extra_infos = [
          {
            attr: 'Billable Amount',
            val: `${this.kpis_gauge.performance.sale_amount.total_billable}`
          },
          {
            attr: 'TARGET',
            val: `${this.kpis_gauge.performance.sale_amount.amount_average}`
          },
          {
            attr: 'REACHED',
            val: `${this.kpis_gauge.performance.sale_amount.percentage_billable}%`
          },
          {
            attr: 'WEIGH-IN RATE',
            val: `${this.kpis_gauge.performance.sale_amount.total_billable_perc}%`
          },
        ];
      }
    },
    sale_amount_approved_init() {
      this.sale_amount.current_percent = maxValue(this.kpis_gauge.performance.sale_amount.approved_percentage_total);
      this.sale_amount.label = `Sales Amount(Approved): ${this.kpis_gauge.performance.sale_amount.approved_percentage_total}%`;
      if(this.is_manager == true) {
        this.sale_amount.extra_infos = [
          {
            attr: 'Approved Amount',
            val: `${this.kpis_gauge.performance.sale_amount.approved_total}`
          },
          {
            attr: 'TARGET',
            val: `${this.kpis_gauge.performance.sale_amount.amount_average}`
          },
          {
            attr: 'REACHED',
            val: `${this.kpis_gauge.performance.sale_amount.approved_percentage_total}%`
          },
          {
            attr: 'WEIGH-IN RATE',
            val: `${this.kpis_gauge.performance.sale_amount.approved_total_perc}%`
          },
        ];
      }
    },
    sale_amount_normal_init() {
      this.sale_amount.current_percent = maxValue(this.kpis_gauge.performance.sale_amount.percentage_total);
      this.sale_amount.label = `Sales Amount: ${this.kpis_gauge.performance.sale_amount.percentage_total}%`;
      if(this.is_manager == true) {
        this.sale_amount.extra_infos = [
          {
            attr: 'Total Amount',
            val: `${this.kpis_gauge.performance.sale_amount.total_contract}`
          },
          {
            attr: 'TARGET',
            val: `${this.kpis_gauge.performance.sale_amount.amount_average}`
          },
          {
            attr: 'REACHED',
            val: `${this.kpis_gauge.performance.sale_amount.percentage_total}%`
          },
          {
            attr: 'WEIGH-IN RATE',
            val: `${this.kpis_gauge.performance.sale_amount.amount_total_perc}%`
          },
        ];
      }
    }
  }
};
</script>
