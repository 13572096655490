<template>
  <div>
    <el-button
      size="mini"
      class="right"
      @click="fill_data"
    >
      Refresh
    </el-button>
    <el-table
      v-loading="loading"
      :data="overdue_jobs"
    >
      <el-table-column
        prop="proposal_id"
        label="Proposal"
        width="100"
      >
        <template slot-scope="scope">
          <el-link :href="`/job_view?id=${scope.row.id}`">
            {{ scope.row.id }}
          </el-link>
        </template>
      </el-table-column>
      
      <el-table-column
        prop="quote_type"
        label="Quote Type"
      />
      <el-table-column
        prop="account_name"
        label="Category"
      />
      <el-table-column
        prop="category"
        label="Category"
      />
      <el-table-column
        prop="job_name"
        label="Job"
      />
      <el-table-column
        prop="dated"
        label="Deadline"
      />
      <el-table-column
        prop="preferred_date"
        label="Prefeered Date"
      />
    </el-table>
  </div>
</template>
<script>
export default {
  name: 'OverdueJobs',
  data() {
    return {
      loading: false,
      overdue_jobs: [],
    };
  },
  mounted() {
    this.fill_data();
  },
  methods: {
    fill_data() {
      this.$http.get('/overdue_jobs').then(
        (response) => {
          this.overdue_jobs = response.data;
        },
        (response) => {}
      );
    },
  },
};
</script>
