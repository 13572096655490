<template>
  <el-form
    ref="form"
    class="sales-form"
    :model="sale"
    :label-width="label_width"
    method="post"
  >
    <el-form-item label="Category Task">
      <el-select
        v-model="sale.category_task_id"
        placeholder="Select"
      >
        <el-option
          v-for="category_task in lists.category_task_list"
          :key="category_task.id"
          :value="category_task.id"
          :label="category_task.name"
        />
      </el-select>
    </el-form-item>

    <el-form-item label="x days due date">
      <el-input
        v-model="sale.day_due_date"
        type="number"
      />
    </el-form-item>

    <el-form-item label="First Invoice">
      <el-date-picker
        id="invoice_start_date"
        v-model="sale.first_invoice"
        class="sale-date"
        type="date"
        placeholder="Pick a day"
      />
    </el-form-item>

    <el-form-item label="Sale Term">
      <el-input
        v-model="sale.contract_term"
        type="number"
      />
    </el-form-item>

    <el-form-item label="Estimated Contract">
      <el-input
        v-model="sale.estimated_contract"
        type="number"
        :disabled="true"
      />
    </el-form-item>

    <el-form-item label="Additional sales">
      <el-input
        v-model="additional_sales"
        type="number"
        :disabled="true"
      />
    </el-form-item>

    <el-form-item label="Total Sale">
      <el-input
        v-model="sale.total_contract"
        type="number"
        step="0.01"
      />
    </el-form-item>

    <el-form-item label="Client Order No">
      <el-input
        v-model="sale.client_order_no"
        placeholder="Client Order No"
      />
    </el-form-item>

    <el-form-item label="Job No">
      <el-input v-model="sale.job_no" />
    </el-form-item>

    <el-form-item label="Payment Type">
      <el-select
        v-model="sale.payment_type"
        class="block"
        placeholder="Select Payment type"
      >
        <el-option
          v-for="(type, index) in lists.payment_type"
          :key="index"
          :label="type"
          :value="type"
        />
      </el-select>
    </el-form-item>

    <el-form-item label="Discount Amount">
      <el-input
        v-model="sale.discount_amount"
        type="number"
        step="0.01"
      />
    </el-form-item>

    <el-form-item class="sales__button">
      <el-button
        class="width--full"
        type="success"
        @click="onUpdate"
      >
        UPDATE
      </el-button>
    </el-form-item>
    <el-form-item
      v-show="is_manager && parent_sale.invoice_based_wf != true"
      class="sales__button"
    >
      <el-button
        class="width--full"
        @click="apply_partial_invoice"
      >
        Apply Partial Invoice
      </el-button>
    </el-form-item>

    <!-- <AdditionalInvoice
      v-if="is_manager && is_additional_invoice"
      :working_files="lists.working_files"
      :proposal_matched_quote="proposal_matched_quote"
    /> -->
    <br>
    <br>

    <SendInvoice v-if="is_manager" />

    <el-form-item
      v-for="(invoice) in sale.invoice_ids"
      :key="invoice.id"
      class="sales__button"
    >
      <a :href="download_link(invoice.id)">
        <el-button
          class="width--full"
          type="info"
        >
          {{ download_invoice_title(invoice.is_credit_note) }}
          <div class="button__second-line">4000{{ invoice.id }}</div>
        </el-button>
      </a>
    </el-form-item>

    <el-form-item
      v-show="sale.invoice_ids.length > 0 && is_manager"
      class="sales__button"
    >
      <el-button
        class="width--full"
        type="danger"
        @click="onRemove"
      >
        REMOVE ALL
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>


import { sale_store } from '../../../store/modules/sale_store';
import { mapGetters, mapActions } from 'vuex';
import SendInvoice from './SendInvoice';

export default {
  name: 'EditForm',
  store: sale_store,
  components: {
    SendInvoice
  },
  props: {
    lists: {
      type: Object,
      required: true
    },
    is_manager: {
      type: Boolean,
      required: true
    },
    is_additional_invoice: {
      type: Boolean,
      required: true
    },
    is_schedule: {
      type: Boolean,
      required: true
    },
    proposal_matched_quote: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      popover_visible: false,
      media_query_xl: window.matchMedia('(max-width: 1670px)'),
      label_width: '',
    };
  },
  computed: {
    ...mapGetters([
      'get_sale',
      'get_scheduled_item',
      'get_parent_sale',
    ]),
    sale: {
      get() {
        return this.get_sale;
      }
    },
    parent_sale: {
      get() {
        return this.get_parent_sale;
      },
      set(value) {
        this.set_parent_sale(value);
      }
    },    
    scheduled_item: {
      get() {
        return this.get_scheduled_item;
      },
      set(value) {
        this.set_scheduled_item(value);
      }
    },
    additional_sales() {
      return parseFloat(this.sale.total_contract) - parseFloat(this.sale.estimated_contract);
    }
  },
  created() {
    this.label_width_fc();
    this.media_query_xl.addEventListener('change', () => {
      this.label_width_fc();
    });
  },
  methods: {
    ...mapActions([
      'set_scheduled_item',
      'set_parent_sale'
    ]),
    download_invoice_title(is_credit_note) {
      if (is_credit_note == true) {
        return 'DOWNLOAD CREDIT NOTE';
      }
      return 'DOWNLOAD INVOICE';
    },
    label_width_fc() {
      if (this.media_query_xl.matches) { // If media query matches
        this.label_width = '';
      } else {
        this.label_width = '150px';
      }
    },
    download_link(invoice_id) {
      return `/invoices/${invoice_id}.pdf`;
    },

    update_params() {
      let sale = this.sale;

      if (this.is_schedule == true) {
        let scheduled_item = {
          scheduled_item_attributes: {
            is_active: this.scheduled_item.is_active,
            schedule_type_id: this.scheduled_item.schedule_type_id,
            repeat_every: this.scheduled_item.repeat_every,
            end_at: this.scheduled_item.end_at,
          }
        };
        sale = {
          ...sale,
          ...scheduled_item
        };
      }
      return sale;
    },
    onUpdate() {
      let request = this.$http.patch(`/api/sales/${this.sale.id}`,
        {
          sale: this.update_params()
        });
      request.then((response) => {
        this.sale.invoice_description = response.body.invoice_description;
        this.$message({
          message: response.body.message,
          type: 'success'
        });
      }, (error) => {
        this.$message({
          type: 'error',
          message: error.body.message
        });
      });
    },
    onRemove() {
      let request = this.$http.patch(`/api/sales/${this.sale.id}/remove_invoices`);
      request.then((response) => {
        this.sale.invoice_ids = [];
        this.$message({
          message: response.body.message,
          type: 'success'
        });
      }, (error) => {
        this.sale.invoice_ids = error.body.invoice_ids;
        this.$message({
          type: 'error',
          message: error.body.message
        });
      });
    },
    apply_partial_invoice() {

      this.$confirm('Are you sure to apply partial invoice ?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let create_request = this.$http.patch(
          `/api/sales/${this.parent_sale.id}/update_invoice_based_wf`,
          {
            sale: {
              id: this.parent_sale.id
            }
          }
        );

        create_request.then((response) => {
          
          this.set_parent_sale({...this.parent_sale, invoice_based_wf: true});

          this.$message({
            message: response.body.message,
            type: 'success'
          });

        }, (error) => {
          this.$message({
            type: 'error',
            message: error.body.message
          });
        });

      });

    },
  }
};
</script>

<style lang="scss" scoped>
.button__second-line {
  margin-top: 5px;
}
</style>
