<template>
  <div>
    <div
      v-loading.fullscreen.lock="loading"
      class="row"
    >
      <div class="col m12">
        <h3>
          <strong>CRON JOB TRANSACTIONS</strong>
          <hr>
        </h3>
        <table class="responsive">
          <thead>
            <tr>
              <th>Transaction</th>
              <th>Crm User</th>
              <th>Effective Date</th>
              <th>Completed on</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="record in data"
              :key="record.id"
            >
              <td>{{ record.transaction }}</td>
              <td>{{ record.user_name }}</td>
              <td>{{ record.effective_date }}</td>
              <td>{{ record.created_at }}</td>
              <td>{{ record.description }}</td>                     
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CronTransactions',
  data() {
    return {
      data: []
    };
  },
  mounted() {
    this.fill_data();
  },
  methods: {
    fill_data() {
      let _this = this;
      this.$http.get('/cron_job_transactions').then(response => {
        _this.data = response.data;
      });
    }
  }
};
</script>

<style scoped>
</style>