<template>
  <div>
    <el-row
      ><el-col :xs="21" :sm="21" :md="21" :lg="21" :xl="21">
        <el-input
          placeholder="Goal description"
          v-model="prop_user_goal_message"
        ></el-input> </el-col
      ><el-col :xs="3" :sm="3" :md="3" :lg="3" :xl="3">
        <el-button
          type="text"
          style="text-align:right"
          @click="add_training_request = true"
          >+ ADD</el-button
        >
      </el-col>
    </el-row>

    <div style="padding:20px; background:#f3f3f3; ">
      <p style="font-weight:bold;">User Goals</p>
      <el-row
        :gutter="0"
        v-for="user_goal in prop_user_goals"
        :key="user_goal.prop_user_goal_id"
        style="margin-top:10px; background:#fff; border:1px solid #cdb6b6; padding:10px;"
      >
        <el-col :xs="2" :sm="2" :md="1" :lg="1" :xl="1">
          <i
            v-show="!user_goal.prop_user_goal_active"
            class="el-icon-folder-opened"
            style="color:grey"
          ></i>
          <i
            v-show="user_goal.prop_user_goal_active"
            class="el-icon-check"
            style="color:green"
          ></i>
        </el-col>

        <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20">
          {{ user_goal.prop_user_goal_message }}
        </el-col>

        <el-col :xs="24" :sm="24" :md="5" :lg="3" :xl="3">
          <el-col :xs="18" :sm="18" :md="18" :lg="18" :xl="18">
            <span
              v-show="user_goal.prop_user_goal_active"
              style="background:#49c557; color:#fff; width:100%; float:left; padding:5px;font-size:12px; text-align:center"
              >SHOW
            </span>
            <span
              v-show="!user_goal.prop_user_goal_active"
              style="background:#f5012f; padding:5px; width:100%;  float:left; color:#fff;font-size:12px; text-align:center"
              >HIDE
            </span>
          </el-col>
          <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
            <!-- <el-select class="selectfield"  label="change" placeholder=" ">
                                               <el-option label="Show" value="true"></el-option>
                                                <el-option label="Hide" value="false"></el-option>
                                                <el-option label="Remove" value="false"></el-option>
                                                </el-select> -->
          </el-col>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  name: "UserGoals",
  data() {
    return {
      prop_user_goal_message: null,
      prop_user_goals: [
        {
          prop_user_goal_id: 1,
          prop_user_goal_message: "to day my goal is to reach xx",
          prop_user_goal_active: true,
        },
        {
          prop_user_goal_id: 2,
          prop_user_goal_message: "to day my goal is to reach xx",
          prop_user_goal_active: true,
        },

        {
          prop_user_goal_id: 3,
          prop_user_goal_message: "to day my goal is to reach xx",
          prop_user_goal_active: true,
        },
      ],
    };
  },
  mounted() {},
  methods: {},
  watch: {},
};
</script>
<style>
span.smallheader {
  font-size: 15px;
  font-weight: bold;
  width: 100%;
  float: left;
  margin-bottom: 13px;
}
span.semilables {
  font-size: 11px;
  font-weight: bold;
  color: #333;
  width: 100%;
  float: left;
}
.smallbox {
  border: 1px solid #f3f3f3;
  padding: 2px;
}
.smallbox input {
  margin-bottom: 0px !important;
  height: 32px !important;
}
.selectfield input {
  margin-bottom: 0px !important;
  height: 1.5em !important;
  border-bottom: 0px !important;
}
</style>
