<template>
  <div>
    <div class="card  ">
      <div class="card-content  ">
        <span class="card-title">
          Tasks by Category
        </span>
        <el-select
          v-model="selected_categories"
          multiple
          placeholder="Select"
          size="mini"
          clearable
          collapse-tags
          @change="fill_data"
        >
          <el-option
            v-for="category in categories_list"
            :key="category.id"
            :label="category.name"
            :value="category.id"
          />
        </el-select>
        <div style="height: 370px !important;overflow-y: auto !important;">
          <div
            v-for="data in grouped_data"
            :key="data.id"
            style="    margin-top: 15px;"
          >
            <span>
              <strong>{{ data.category }}</strong>
              <hr>
            </span>
            <el-table
              :summary-method="getSummaries"
              show-summary
              :data="data.items"
              style="width: 100%"
            >
              <el-table-column
                prop="name"
                label="Name"
                sortable
              >
                <template slot-scope="scope">
                  <a
                    style="cursor: pointer;"
                    @click="show_subcategory_tasks(scope.row)"
                  >{{ scope.row.name }}</a>
                </template>
              </el-table-column>
              <el-table-column
                sortable
                prop="current_time_allocated_labour"
                label="Current"
              >
                <template slot-scope="scope">
                  <Time :is_hours="scope.row.is_hours" :minutes="scope.row.current_time_allocated_labour" />
                </template>
              </el-table-column>
              <el-table-column
                sortable
                prop="future_time_allocated_labour"
                label="Future"
              >
                <template slot-scope="scope">
                  <Time :is_hours="scope.row.is_hours" :minutes="scope.row.future_time_allocated_labour" />
                </template>
              </el-table-column>
              <el-table-column
                label="Total"
              >
                <template slot-scope="scope">
                  <Time :is_hours="scope.row.is_hours" :minutes="scope.row.future_time_allocated_labour + scope.row.current_time_allocated_labour" />
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      :visible.sync="tasks_list_dialog"
      width="70%"
      :title="selected_subcategory"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <el-table
        :data="tasks_list"
        :summary-method="getSummaries"
        show-summary
        :row-class-name="tableRowClassName"
      >
        <el-table-column
          prop="job_no"
          label="Job No"
          sortable
        >
          <template slot-scope="scope">
            <a
              :href="`/job_view?id=${scope.row.job_no}`"
              target="_blank"
            >
              {{ scope.row.job_no }}</a>
          </template>
        </el-table-column>
        <el-table-column
          prop="category_name"
          label="category_name"
          sortable
        />        
        <el-table-column
          prop="account_name"
          label="Account"
          sortable
        />
        <el-table-column
          prop="job_name"
          label="Job"
          sortable
        />
        <el-table-column
          prop="billable"
          label="Billable"
          sortable
        />
        <el-table-column
          prop="start_time"
          label="Start"
          sortable
        />
        <el-table-column
          prop="end_time"
          label="End"
          sortable
        />
        <el-table-column
          prop="time_allocated_labour"
          label="Total"
          sortable
        >
          <template slot-scope="scope">
            <Time :is_hours="scope.row.is_hours" :minutes="scope.row.time_allocated_labour" />
          </template>
        </el-table-column>
      </el-table>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="tasks_list_dialog = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="tasks_list_dialog = false"
        >Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Time from '../shared/Time';
import {display_time} from "../../utilities/Time";
function get_sum(values) {
  return values.reduce((prev, curr) => {
    const value = Number(curr);
    if (!isNaN(value)) {
      return prev + curr;
    } else {
      return prev;
    }
  }, 0);
};
export default {
  name: 'TasksByCategoryDashboard',
  components: { Time },
  data() {
    return {
      selected_subcategory: '',
      categories_list: [],
      selected_categories: [],
      tasks_list: [],
      result: [],
      tasks_list_dialog: false,
    };
  },
  computed: {
    grouped_data() {
      let _this = this;
      let selected_categories = this.categories_list.filter((c) =>
        _this.selected_categories.includes(c.id)
      );
      let data = [];
      selected_categories.forEach((c) => {
        data.push({
          id: c.id,
          category: c.name.toUpperCase(),
          items: this.result.filter((r) => r.category_task_id == c.id),
        });
      });
      return data;
    },
  },
  mounted() {
    this.fill_categories();
  },
  methods: {
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      if (data.length === 0) return sums;

      let is_hours = data[0].is_hours;
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Sum';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = display_time(is_hours, get_sum(values));
        }
      });
      return sums;
    },
    show_subcategory_tasks(row) {
      this.tasks_list_dialog = true;
      this.selected_subcategory = row.name;
      let subcat_id = row.id;
      this.tasks_list = [];
      this.$http.post('/tasks_by_job_type', { job_id: row.job_id }).then(
        (response) => {
          this.tasks_list = response.data;
        },
        (response) => {}
      );
    },
    tableRowClassName({ row, rowIndex }) {
      
      if (row.is_active === 1) {
        return 'active_tasks';
      }
      return '';
    },
    fill_categories() {
      let self = this;
      this.$http.get('/category_tasks_list').then(
        function(response) {
          // Success
          if (response.status == 200) {
            self.categories_list = response.data;
          }
        },
        function(response) {
          // error
          console.log(response);
        }
      );
    },
    fill_data() {
      if (this.selected_categories.length == 0) this.result = [];
      let params = {
        categories_ids: this.selected_categories,
      };
      this.$http.post('/tasks_by_category', params).then(
        (response) => {
          this.result = response.data;
        },
        (response) => {
          this.result = [];
          this.$message({ type: 'error', message: '' });
        }
      );
    },
  },
};
</script>
<style>
.active_tasks {
  background: #dff3d5 !important;
}
</style>
