<template>
  <div>
    <div style="padding: 10px">
      <el-button style="float: right" @click="upload_font" size="small" :disabled="working_file_id == ''">Add font</el-button>
    </div>
    <div>
      <el-input placeholder="Filter keyword" v-model="filterText"></el-input>
      <el-tree
        :data="fonts_list"
        :loading="loading"
        class="filter-tree"
        :props="defaultProps"
        ref="fonts_tree"
        node-key="id"
        default-expand-all
        :expand-on-click-node="false"
        :filter-node-method="filterNode"
      >
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span style="font-size: 12px">
            {{data.name}}
            <small>[{{data.extension}}]</small>
          </span>
          <span>
            <el-button
              type="text"
              icon="el-icon-success"
              style="color: lightgrey;"
              v-bind:class="{ imported_font:  data.imported == true}"
              @click="() => toggle_font(data)"
            ></el-button>
          </span>
          <span>
            <el-button type="text" @click="() => delete_font(data)" icon="el-icon-delete"></el-button>
          </span>
        </span>
      </el-tree>
    </div>
    <div v-if="missing_fonts.length > 0">
      <b>Missing fonts</b>
      <ul>
        <li v-bind:key="font" v-for="font in missing_fonts">{{font}}</li>
      </ul>
    </div>
    <modal
      name="font_uploader_modal"
      :width="400"
      :height="150"
      :adaptive="true"
      class="font_uploader_modal"
    >
      <div style="padding: 20px">
        <el-upload
          class="upload-demo"
          :limit="1"
          ref="upload_font"
          action="/upload_font"
          :on-success="handle_success"
          :on-error="handle_error"
          :auto-upload="false"
          :file-list="fileList"
          accept=".ttf,.otf,.svg,.woff,.woff2"
        >
          <el-button slot="trigger" size="small" type="primary">select file</el-button>
          <el-button
            style="margin-left: 10px;"
            size="small"
            type="success"
            @click="submit_upload"
          >upload font</el-button>
          <div
            class="el-upload__tip"
            slot="tip"
          >Only otf,ttf,woff and woff2 font formats are accepted.</div>
        </el-upload>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { editor_store, mm_to_px } from "store/modules/editor";

export default {
  name: "FontsPanel",
  props: [],
  data() {
    return {
      filterText: "",
      defaultProps: {
        children: "children",
        label: "name"
      },
      loading: false,
      fileList: []
    };
  },
  methods: {
    ...mapActions(["add_font", "remove_font", "include_font", "exclude_font"]),
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    toggle_font(data) {
      if (data.imported == true) this.exclude_font(data);
      else this.include_font(data);
    },
    handle_success(e) {
      this.loading = false;
      this.fileList = [];
      this.$message({
        type: "success",
        message: e.message
      });
      this.add_font(e.data);
    },
    handle_error(e) {
      this.loading = false;
      this.$message({
        type: "info",
        message: e.message
      });
    },
    submit_upload() {
      this.$refs.upload_font.submit();
      this.loading = true;
    },
    upload_font() {
      this.$modal.show("font_uploader_modal");
    },
    delete_font(data) {
      this.loading = true;
      let _this = this;
      this.$http
        .post("/delete_font", {
          filename: data.name + "." + data.extension
        })
        .then(response => {
          this.loading = false;
          _this.remove_font(data.id);
          this.$message({
            type: "success",
            message: "Font has been deleted successfully."
          });
        })
        .then(response => {
          this.loading = false;
          this.$message({
            type: "info",
            message: response.message
          });
        });
    }
  },
  computed: {
    ...mapGetters(["fonts_list", "missing_fonts", "working_file_id"])
  },
  watch: {
    filterText(val) {
      this.$refs.fonts_tree.filter(val);
    }
  }
};
</script>


<style scoped>
.font_uploader_modal {
  padding: 10px !important;
}
.imported_font {
  color: orange !important
}
</style>