<template>
  <div>
    <div class="card  ">
      <div class="card-content ">
        <span class="card-title">
          Users tasks summary
        </span>
        <br>
        <div class="users_list_container">
          <table class="responsive-table striped">
            <thead>
              <tr>
                <th rowspan="2">
                  Username
                </th>
                <th colspan="2">
                  Current
                </th>
                <th colspan="2">
                  Future
                </th>
              </tr>
              <tr>
                <th>Billable</th>
                <th>Free</th>
                <th>Billable</th>
                <th>Free</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="user in users_data"
                :key="user.id"
              >
                <td>{{ user.user_name }}</td>
                <td>
                  <a @click="show_user_tasks(user.user_id,1,1)">
                    <Time :is_hours="user.is_hours" :minutes="user.current_billable" />
                  </a>
                </td>
                <td>
                  <a @click="show_user_tasks(user.user_id,1,0)">
                    <Time :is_hours="user.is_hours" :minutes="user.current_free" />
                  </a>
                </td>
                <td>
                  <a @click="show_user_tasks(user.user_id,0,1)">
                    <Time :is_hours="user.is_hours" :minutes="user.future_billable" />
                  </a>
                </td>
                <td>
                  <a @click="show_user_tasks(user.user_id,0,0)">
                    <Time :is_hours="user.is_hours" :minutes="user.future_free" />
                  </a>
                </td>
              </tr>
            </tbody>
            <tfoot
              v-if="users_data.length > 0"
              style="font-weight: bold;"
            >
              <tr style="border-top: 1px solid gray">
                <td>TOTAL</td>
                <td><Time :is_hours="users_data[0].is_hours" :minutes="total_minutes('current_billable')" /></td>
                <td><Time :is_hours="users_data[0].is_hours" :minutes="total_minutes('current_free')" /></td>
                <td><Time :is_hours="users_data[0].is_hours" :minutes="total_minutes('future_billable')" /></td>
                <td><Time :is_hours="users_data[0].is_hours" :minutes="total_minutes('future_free')" /></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
    <modal
      name="users_tasks_list"
      :height="700"
      :width="1200"
      :adaptive="true"
    >
      <div class="card ">
        <div class="card-content  ">
          <span class="card-title">{{ list_title }}<hr></span>
          <div
            v-show="is_loading"
            class="progress"
          >
            <div class="indeterminate" />
          </div>
          <el-table
            :data="rows"
            style="width: 100%"
            height="600"
          >
            <el-table-column
              prop="job_no"
              :label="'job_no' | capitalize"
              sortable
            />
            <el-table-column
              prop="account"
              :label="'account' | capitalize"
              sortable
            />
            <el-table-column
              prop="job"
              :label="'job' | capitalize"
              sortable
            />
            <el-table-column
              prop="billable"
              :label="'billable' | capitalize"
              sortable
            />
            <el-table-column
              prop="task"
              :label="'task' | capitalize"
              sortable
            />
            <el-table-column
              prop="time_allocated"
              :label="'time_allocated' | capitalize"
              sortable
            >
              <template slot-scope="scope">
                <Time :is_hours="scope.row.is_hours" :minutes="scope.row.time_allocated" />
              </template>
            </el-table-column>
            <el-table-column
              prop="time_spent"
              :label="'time_spent' | capitalize"
              sortable
            >
              <template slot-scope="scope">
                <Time :is_hours="scope.row.is_hours" :minutes="scope.row.time_spent" />
              </template>
            </el-table-column>
            <el-table-column
              prop="remaining_time"
              :label="'remaining_time' | capitalize"
              sortable
            >
              <template slot-scope="scope">
                <Time :is_hours="scope.row.is_hours" :minutes="scope.row.remaining_time" />
              </template>
            </el-table-column>
            <el-table-column
              prop="start_time"
              :label="'start_time' | capitalize"
              sortable
            />
            <el-table-column
              prop="end_time"
              :label="'end_time' | capitalize"
              sortable
            />
          </el-table>
        </div>
        <div class="card-action" />
      </div>
    </modal>
  </div>
</template>
<script>
import Time from '../shared/Time';
export default {
  name: 'UsersTasksDashboard',
  components: { Time },
  data() {
    return {
      columns: [],
      rows: [],
      users_data: [],
      list_title: '',
      is_loading: false
    };
  },
  mounted() {
    this.fill_data();
  },
  methods: {
    fill_data() {
      this.$http.post('/get_users_tasks_dashboard', {}).then(response => {
        if (response.data.status == 200) {
          this.users_data = response.data.data;
        }
      }
      );
    },
    open_modal() {
      this.$modal.show('users_tasks_list');
    },
    hide_modal() {
      this.$modal.hide('users_tasks_list');
    },
    show_user_tasks(user_id, is_active, billable) {
      this.$http.post('/get_user_tasks_list', {
        user_id: user_id,
        is_active: is_active,
        billable: billable
      }).then(response => {
        if (response.data.status == 200) {
          this.rows = response.data.data.rows;
          this.columns = response.data.data.columns;
          this.list_title = (is_active == 1 ? 'Current' : 'Future') + ' ' + (billable == 1 ? 'billable' : 'free') + ' tasks';
          this.open_modal();
        }
      }
      );
    },
    total_minutes(col) {
      return this.users_data.reduce(function (total, item) {
        return total + item[col];
      }, 0);
    }
  }
};
</script>

<style scoped>
    td, th {
        text-align: center;
        padding: 10px;
    }

    th {
        font-weight: 800;
    }

    .header {
        background-color: #ddecf9;
    }

    .subheader {
        background-color: #e5f3ff;
    }

    .rows {
        background-color: aliceblue;
    }

    a {
        cursor: pointer;
    }

    th {
        font-weight: bold;
    }

    .users_list_container {
        max-height: 400px;
        overflow: auto;
    }
</style>

