<template>
  <div class>
    <div class="row">
      <div class="col m5 s12">
        <b style="font-size: 12px;">Item specs</b>
        <el-button
          v-on:click="swap_item_specs"
          icon="el-icon-refresh"
          size="mini"
        ></el-button>
      </div>
      <div class="col m7 s12">
        <el-checkbox v-model="proposal_item_specs.include_speed_rate" size="mini" >Speed rate</el-checkbox>
        <el-input v-show="proposal_item_specs.include_speed_rate == true" v-model.number="proposal_item_specs.speed_rate" style="width: 100px" size="mini" ></el-input>
      </div>
    </div>
    <div class="row">
      <div class="col m4 s12">
        <el-radio v-model="selected_linear_spec" label="Width"></el-radio>
        <el-input-number v-model="proposal_item_specs.width" size="mini">
          <template slot="append">mm</template>
        </el-input-number>
      </div>
      <div class="col m4 s12">
        <el-radio v-model="selected_linear_spec" label="Height"></el-radio>
        <el-input-number v-model="proposal_item_specs.height" size="mini">
          <template slot="append">mm</template>
        </el-input-number>
      </div>
      <div class="col m4 s12">
        <el-radio v-model="selected_linear_spec" label="Depth"></el-radio>
        <el-input-number v-model="proposal_item_specs.depth" size="mini">
          <template slot="append">mm</template>
        </el-input-number>
      </div>
    </div>
  </div>
</template>
<script>
import { editor_store } from "store/modules/editor";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "ProposalItemSpec",
  props: ["swap_item_specs", "selected_linear_spec", "proposal_item_specs"],
  store: editor_store,
  data() {
    return {};
  },
  methods: {}
};
</script>
