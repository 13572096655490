<template>
  <span>
    {{ display_time }}
  </span>
</template>

<script>
import {display_time} from '../../utilities/Time';
export default {
  name: 'Time',
  props: {
    is_hours: {
      type: [Boolean, Number],
      required: true
    },
    is_decimal_hours: {
      type: Boolean,
      default: false
    },
    minutes: {
      validator: (value) => {
        return (typeof value === 'string' || typeof value === 'number' || typeof value === 'undefined' || value === null);
      },
      required: true
    }
  },
  computed: {
    display_time() {
      return display_time(this.is_hours, this.minutes, this.is_decimal_hours);
    }
  }
};
</script>
