<template>
  <div>
    <DetailsDialog
      v-if="showDialog == true"
      :show_dialog.sync="showDialog"
    />
    <div class="card">
      <div class="card-content ">
        <div class="card-title">
          Invoice Summary
        </div>
        <br>
        <InvoiceSummary :summary_balance="summary_balance" />
        <br>
        <OverdueSummary
          :open_invoices_count="open_invoices_count"
          :overdue_invoices_count="overdue_invoices_count"
        />
        <br>
        <el-button
          type="primary"
          size="mini"
          @click="onShowDialog"
        >
          SHOW DETAILS
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import InvoiceSummary from '../../invoice_balancing/growth_development/InvoiceSummary';
import {loadTotalOverdueInvoice} from '../../../api/invoice';
import {loadTotalInvoiceSummary} from '../../../api/invoice_summary';
import DetailsDialog from './DetailsDialog';
import OverdueSummary from './OverdueSummary';

export default {
  name: 'InvoiceSummaryDashboard',
  components: {OverdueSummary, DetailsDialog, InvoiceSummary},
  data() {
    return {
      showDialog: false,
      summary_balance: {
        current: {
          total: 0,
          invoice: 0,
          credit_note: 0
        },
        thirty: {
          total: 0,
          invoice: 0,
          credit_note: 0
        },
        sixty: {
          total: 0,
          invoice: 0,
          credit_note: 0
        },
        ninety: {
          total: 0,
          invoice: 0,
          credit_note: 0
        },
        unassigned_credits: 0,
        bad_debts: 0,
        amount_due: 0,
      },
      open_invoices_count:0,
      overdue_invoices_count: 0
    };
  },
  created() {
    loadTotalInvoiceSummary(this).then((response)=>{
      this.summary_balance = response.data;
    });
    loadTotalOverdueInvoice(this).then(({data})=>{
      this.open_invoices_count = data.open_invoices_count;
      this.overdue_invoices_count = data.overdue_invoices_count;
    });
  },
  methods: {
    onShowDialog() {
      this.showDialog = true;
    }
  }
};
</script>
