<template>
  <el-card>
    <el-row>
      <el-col
        :xs="24"
        :span="10"
      >
        <ul class="list">
          <li class="list__item space-between">
            <div class="list__attribute">
              Quoted Accepted Amount
            </div>
            <div class="list__attribute">
              <Money :amount="estimated_contract" />
            </div>
          </li>
          <li class="list__item space-between">
            <div class="list__attribute">
              Variation
            </div>
            <div class="list__attribute">
              <Money :amount="additional_sales" />
            </div>
          </li>
          <li class="list__item space-between">
            <div class="list__attribute">
              Total Sale
            </div>
            <div class="list__attribute">
              <Money :amount="total_contract" />
            </div>
          </li>
          <div v-if="parent_sale.invoice_based_wf==false">
            <li class="list__item space-between">
              <div class="list__attribute">
                Invoice
              </div>
              <div class="list__attribute">
                <Money :amount="parent_sale.total_contract" />
              </div>
            </li>
          </div>
          <div v-else>
            <li
              v-for="(wf_sale,index) in wf_sales"
              :key="index"
              class="list__item space-between"
              :class="activated(wf_sale.key)"
            >
              <div class="list__attribute">
                Invoice {{ index+1 }}
              </div>
              <div class="list__attribute">
                <Money :amount="wf_sale.sale.total_contract" />
              </div>
            </li>
          </div>
          <li class="list__item space-between">
            <div class="list__attribute">
              Sales Balance Pending
            </div>
            <div class="list__attribute">
              <Money :amount="balance_pending" />
            </div>
          </li>
        </ul>
      </el-col>
      <el-col
        :xs="{span: 24, offset: 0}"
        :offset="2"
        :span="12"
      >
        <ul class="list">
          <li class="list__item space-between">
            <div class="list__attribute">
              Total Labour Cost
            </div>
            <div class="list__attribute">
              <Money :amount="labour_cost" />
            </div>
          </li>
          <li class="list__item space-between">
            <div class="list__attribute">
              Total Stock Cost
            </div>
            <div class="list__attribute">
              <Money :amount="stock_cost" />
            </div>
          </li>
          <li class="list__item space-between">
            <div class="list__attribute">
              Total Cost
            </div>
            <div class="list__attribute">
              <Money :amount="total_cost" />
            </div>
          </li>
          <li class="list__item space-between">
            <div class="list__attribute">
              Profit
            </div>
            <div class="list__attribute">
              <Money :amount="gross_profit" />
            </div>
          </li>
          <li class="list__item space-between">
            <div class="list__attribute">
              <b>Gross profit</b>
            </div>
            <div class="list__attribute">
              <b><Percent
                :dividend="gross_profit"
                :divisor="total_sale_price"
              /></b>
            </div>
          </li>
        </ul>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import SalesCostMixin from '../../../mixins/SalesCostMixin';

import { sale_store } from '../../../store/modules/sale_store';

import Money from '../../../components/shared/Money';
import Percent from '../../../components/shared/Percent';
import {mapGetters} from 'vuex';

export default {
  name: 'Information',
  store: sale_store,
  components: {
    Money,
    Percent
  },
  mixins: [SalesCostMixin],
  computed: {
    ...mapGetters([
      'get_parent_sale',
      'get_wf_sales',
      'get_current_sale_key'
    ]),

    parent_sale: {
      get() {
        return this.get_parent_sale;
      },
    },
    wf_sales: {
      get() {
        return this.get_wf_sales;
      },
    },
    current_sale_key: {
      get() {
        return this.get_current_sale_key;
      },
    },
    estimated_contract() {
      return parseFloat(this.parent_sale.estimated_contract);
    },
    total_contract() {
      return parseFloat(this.parent_sale.total_contract);
    },
    additional_sales() {
      return parseFloat(this.total_contract) - parseFloat(this.estimated_contract);
    },
    balance_pending() {
      return parseFloat(this.total_contract) - this.total_invoice_approved;
    },
    total_invoice_approved() {
      let total_contract = 0;
      if(this.parent_sale.invoice_based_wf==false) {
        if(this.parent_sale.approved == true) {
          total_contract = parseFloat(this.parent_sale.total_contract);
        }
      }
      else {
        this.wf_sales.forEach((wf_sale) => {
          if(wf_sale.sale.approved == true) {
            total_contract += parseFloat(wf_sale.sale.total_contract);
          }
        });
      }
      return total_contract;
    },
    activated() {
      return key => {
        if (this.current_sale_key == key) {
          return 'gray__transparent';
        }
        return '';
      };
    }
  },
};

</script>


