<template>
  <div>
    <InvoicesEditDialog
      v-if="showInvoiceDialog == true"
      :show_invoice_dialog.sync="showInvoiceDialog"
      :invoice_id="invoice_id"
    />
    <ColumnHeader
      :total="invoices_total"
      :open="{
        label: 'OPEN O/S',
        total: invoices_outstanding
      }"
      :page="page"
      :total_page="total_page"
      @prev="onPrev"
      @next="onNext"
    >
      <div>
        INVOICES - <span class="additional-info">Overdue count: {{ overdue_cnt }}</span>
      </div>
    </ColumnHeader>
    <ColumnElement
      v-for="(invoice, index) in invoices"
      :key="invoice.id"
    >
      <i
        :class="info_class(invoice)"
        :style="info_style(invoice)"
      />
      <span>4000{{ invoice.id }}</span>
      <span v-if="invoice.credit_note">(4000{{ invoice.credit_note }})</span>
      <span
        class="total-price"
      >
        - TOTAL: ${{ invoice.total }}
      </span>
      <span
        v-show="invoice.outstanding != 0"
        :class="outstanding_class(invoice)"
      >
        O/S: ${{ invoice.outstanding }}
      </span>
      <span class="datediv">{{ invoice.date }}</span>
      <template #right>
        <el-button
          icon="el-icon-info"
          circle
          @click="onEditInvoice(invoice.id)"
        />
        <el-button
          v-if="extension_btns[index] == false"
          type="success"
          size="mini"
          icon="el-icon-circle-plus"
          circle
          @click="onExtension(index,true)"
        />
        <el-button
          v-else
          type="type"
          size="mini"
          icon="el-icon-circle-close"
          circle
          @click="onExtension(index,false)"
        />
      </template>
      <template #second-line>
        <el-checkbox
          v-if="show_bad_debt(invoice)"
          v-model="invoice.is_bad_debt"
          class="second-line__item invoice-col__bad-debt"
          @change="onBadDebtChange(invoice)"
        >
          Bad Debt
        </el-checkbox>
        <span
          v-if="invoice.is_overdue == true"
          class="over-due second-line__item"
        >
          OverDue: {{ invoice.due_date }}
        </span>
        <el-link
          v-if="invoice.sale_id"
          :href="`/sales/${invoice.sale_id}/edit`"
          target="_blank"
          class="link second-line__item"
          style="color:#039be5"
        >
          Sale
        </el-link>
        <el-link
          v-if="invoice.proposal_id"
          :href="`/job_view?id=${invoice.proposal_id}`"
          target="_blank"
          class="link second-line__item"
          style="color:#039be5"
        >
          Job#{{ invoice.proposal_id }}
        </el-link>
        <el-link
          v-if="invoice.proposal_id"
          :href="`/edit_proposal?id=${invoice.proposal_id}`"
          target="_blank"
          class="link second-line__item"
          style="color:#039be5"
        >
          Quote#{{ invoice.proposal_id }}
        </el-link>
        <el-link
          v-if="invoice.is_po"
          :href="`/purchase_orders?proposal_id=${invoice.proposal_id}`"
          target="_blank"
          class="link second-line__item"
          style="color:#039be5"
        >
          POs
        </el-link>
      </template>
      <template #extend>
        <ExtensionButtons
          v-if="extension_btns[index]===true"
          :id="invoice.id"
          :is_overdue="invoice.is_overdue"
        />
      </template>
    </ColumnElement>
  </div>
</template>

<script>
import InvoicesEditDialog from './InvoicesEditDialog';
import ColumnHeader from '../ColumnHeader';
import ExtensionButtons from '../ExtensionButtons';
import {BALANCE_STATUS} from '../../../status';
import {updateBadDebt} from '../../../../../api/invoice';
import {EventBus} from '../../../../../utilities/EventBus';
import ColumnElement from '../ColumnElement';

export default {
  name: 'Invoices',
  components: {ColumnElement, ColumnHeader,ExtensionButtons, InvoicesEditDialog},
  props: {
    invoices: {
      type: Array,
      required: true,
    },
    invoices_total: {
      type: Number,
      required: true,
    },
    invoices_outstanding: {
      type: Number,
      required: true,
    },
    overdue_cnt: {
      type: Number,
      required: true
    },
    total_page: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      extension_btns:[],
      showInvoiceDialog: false,
      invoice_id: null,
      page: 1
    };
  },
  computed: {
    info_class() {
      return (invoice) => {
        if(invoice.payment_assigned === BALANCE_STATUS.CLOSED) return 'el-icon-folder';

        return 'el-icon-folder-opened';
      };
    },
    info_style() {
      return (invoice) => {
        if(invoice.payment_assigned === BALANCE_STATUS.OPEN) return 'color:green';
        if(invoice.payment_assigned === BALANCE_STATUS.PARTIAL) return 'color:orange';

        return '';
      };
    },
    outstanding_class() {
      return (invoice) => {
        if(invoice.payment_assigned === BALANCE_STATUS.CLOSED) return 'outstanding';

        return 'outstanding__open';
      };
    },
    show_bad_debt() {
      return (invoice) => {
        return invoice.payment_assigned !== BALANCE_STATUS.CLOSED && invoice.is_overdue == true;
      };
    }
  },
  watch: {
    invoices() {
      this.extension_btns = [];
      this.invoices.forEach(()=>{
        this.extension_btns.push(false);
      });
    }
  },
  methods: {
    onExtension(index, val) {
      this.$set(this.extension_btns, index, val);
    },
    onBadDebtChange(invoice) {
      updateBadDebt(this, invoice.id, invoice.is_bad_debt).then(({data})=>{
        this.$message({
          type: 'success',
          message: data.message
        });
        EventBus.$emit('refreshInvoiceSummary');
      });
    },
    onEditInvoice(id) {
      this.invoice_id = id;
      this.showInvoiceDialog = true;
    },
    onPrev() {
      if(this.page === 1)
        return;

      this.page -= 1;
      this.emit_page_event();
    },
    onNext() {
      if(this.page === this.total_page)
        return;

      this.page += 1;
      this.emit_page_event();
    },
    emit_page_event(){
      this.$emit('pageChange', this.page);
    }
  }
};
</script>

<style lang='scss'>
.invoice-col__bad-debt{
  .el-checkbox__label {
    font-size:12px !important;
    font-weight: bold;
  }
}
</style>

<style lang='scss' scoped>
@import '../style/balancing';

.additional-info {
  font-size: 14px;
}

.outstanding {
  @include price__font;

  &__open {
    @include price__font;
    @include text__red;
  }
}

.over-due {
  @include text__red;
}
</style>

