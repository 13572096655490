<template>
  <span>
    <el-input
      v-model.number="hours"
      :placeholder="hours_placeholder"
      :disabled="disabled"
      size="mini"
      :class="h_class"
      :style="hours_style"
      @change="onHoursChanged"
    /> h
    <template v-if="multiline">
      <br>
    </template>
    <template v-else>
      &nbsp;
    </template>
    <el-input
      v-model.number="minutes"
      :placeholder="minutes_placeholder"
      :disabled="disabled"
      size="mini"
      :class="m_class"
      :style="hours_style"
      @change="onMinutesChanged"
    /> min
  </span>
</template>

<script>
import {MinutesToHours, HoursToMinutes} from '../../utilities/Time';
import {CheckPositiveInteger} from '../../utilities/Number';


export default {
  name: 'HoursInput',
  props: {
    disabled: {
      type: [Boolean],
      default: false
    },
    value: {
      validator: (value) => {
        return (typeof value === 'string' || typeof value === 'number' || typeof value === 'undefined' || value === null);
      },
      required: true
    },
    placeholder: {
      type: [String, Number],
      default: ''
    },
    hours_style: {
      type: [String],
      default: ''
    },
    hours_class: {
      type: [String],
      default: ''
    },
    multiline: {
      type: [Boolean],
      default: false
    }
  },
  data() {
    return {
      hours: '',
      minutes: '',
      pre_hours: '',
      pre_minutes: '',
      minutes_time: 0,
      hours_placeholder: 'hours',
      minutes_placeholder: 'minutes',
      h_class: '',
      m_class: ''
    };
  },
  watch: {
    value(val) {
      if(this.minutes_time !== val) {
        this.change_to_hours();
        this.minutes_time = this.hours_to_minutes();
      }
    }
  },
  created() {
    this.change_to_hours();
    if(this.multiline === true) {
      this.h_class = `hours__input__multiline ${this.hours_class}`;
      this.m_class = `minutes__input__multiline ${this.hours_class}`;
    } else {
      this.h_class = `hours__input ${this.hours_class}`;
      this.m_class = `minutes__input ${this.hours_class}`;
    }
  },
  methods: {
    onHoursChanged() {
      if(this.hours !== '' && CheckPositiveInteger(this.hours) === false) {
        this.display_warning_msg('Please enter time spent correctly.');
        this.hours = this.pre_hours;
        return;
      }
      this.emit_hours();
    },
    onMinutesChanged() {
      if(this.minutes !== '' && CheckPositiveInteger(this.minutes) === false) {
        this.display_warning_msg('Please enter time spent correctly.');
        this.minutes = this.pre_minutes;
        return;
      }
      if(this.minutes > 59) {
        this.display_warning_msg('Minutes should not be bigger than 59');
        this.minutes = 59;
      }
      this.emit_hours();
    },
    change_to_hours() {
      if(this.value === 0 || this.value) {
        this.hours = MinutesToHours.hours(this.value);
        this.minutes = MinutesToHours.minutes(this.value);
      } else {
        this.hours = '';
        this.minutes = '';
      }
      if(this.placeholder === 0 || this.placeholder) {
        this.hours_placeholder = MinutesToHours.hours(this.placeholder);
        this.minutes_placeholder = MinutesToHours.minutes(this.placeholder);
      }
      this.save_pre_vals();
    },
    emit_hours() {
      this.save_pre_vals();
      this.minutes_time = this.hours_to_minutes();
      this.$emit('input', this.minutes_time);
      this.$emit('change', this.minutes_time);
    },
    hours_to_minutes() {
      if(this.hours === '' && this.minutes === '') return '';

      return HoursToMinutes(this.hours, this.minutes);
    },
    save_pre_vals() {
      this.pre_hours = this.hours;
      this.pre_minutes = this.minutes;
    },
    display_warning_msg(msg) {
      this.$message({
        type: 'warning',
        message: msg,
      });
    }
  }
};
</script>

<style lang='scss' scoped>
.hours__input {
  width: 40% !important;
}
.minutes__input {
  width: 30% !important;
}

.hours__input__multiline {
  width: 70% !important;
}
.minutes__input__multiline {
  width: 70% !important;
}
</style>

