<template>
  <div>
    <ul class="error">
      <li
        v-for="(msg,index) in errors['date']"
        :key="index"
      >
        Date {{ msg }}
      </li>
    </ul>
    <ul class="error">
      <li
        v-for="(msg,index) in errors['payment']"
        :key="index"
      >
        Payment {{ msg }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Errors',
  props: {
    errors: {
      type: Object,
      required: true
    },
  }
};
</script>

<style scoped>
.error {
  color: red;
}
</style>