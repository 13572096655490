<template>
  <section>
    <div v-if="is_show_option">
      <el-row>
        <el-col :span="12">
          <b>DISPLAY OPTIONS</b>
        </el-col>
        <el-col :span="12" class="align__right">
          <el-button type="success" @click="update_invoice_description">Update</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24" :span="12" class="options-box">
          <el-checkbox v-model="sale.edit_invoice_description">Edit Invoice Description</el-checkbox>
        </el-col>
        <el-col :xs="24" :span="12" class="options-box">
          <el-col :span="24">
            <h2>BREAKDOWN</h2>
            <el-switch v-model="sale.show_pdf_breakdown" active-color="#13ce66" inactive-color="#ff4949" class="align__right"/>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="6">
            <el-checkbox v-model="sale.show_subtotal">Total Unit Price</el-checkbox>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="6">
            <el-checkbox v-model="sale.show_unit_sale_price">Unit Price</el-checkbox>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="6">
              <el-checkbox v-model="sale.show_qty">Stocks/Tasks Qty</el-checkbox>
          </el-col>
          <el-col :span="24">
              <el-checkbox v-model="sale.group_items">Group stocks & tasks</el-checkbox>
              <span style="font-size:10px;">(Uncheck to view individual quantities/unit prices)</span>
          </el-col>
        </el-col>
      </el-row>
    </div>

    <el-row>
      <el-col :span="24">
        <div class="sales__label">Invoice description</div>
        <br/>
        <div class="narrow_description_editor">
          <jodit-editor v-model="sale.invoice_description"
                        :buttons="buttons"
                        :config="jodit_config"/>
        </div>
      </el-col>
    </el-row>

    <el-row>
      <div class="sales__label">Additional description</div>
      <br/>
      <div class="narrow_description_editor">
        <jodit-editor v-model="sale.additional_desc"
                      :buttons="buttons"
                      :config="jodit_config"/>
      </div>
    </el-row>
  </section>
</template>
<script>
import CommonJoditMixin from '../../../mixins/CommonJoditMixin';
import {sale_store} from '../../../store/modules/sale_store';
import { mapGetters } from "vuex";

export default {
  name: 'DescriptionsEditors',
  mixins: [CommonJoditMixin],
  store: sale_store,
  props: {
    is_show_option: {
      type: Boolean,
      required: true
    },
    sale_key: {
      type: [String, Number],
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'get_sale_with_key',
    ]),

    sale: {
      get() {
        return this.get_sale_with_key(this.sale_key);
      }
    },
  },
  methods: {
    update_invoice_description() {
      let update_request = this.$http.patch(
          `/api/sales/${this.sale.id}/update_invoice_description`,
          {
            sale : {
              invoice_description: this.sale.invoice_description,
              edit_invoice_description: this.sale.edit_invoice_description,
              show_pdf_breakdown: this.sale.show_pdf_breakdown,
              show_subtotal: this.sale.show_subtotal,
              show_unit_sale_price: this.sale.show_unit_sale_price,
              show_qty: this.sale.show_qty,
              group_items: this.sale.group_items
            }
          });

      update_request.then((response)=>{
        this.sale.invoice_description = response.body.invoice_description;
        this.$message({
          message: response.body.message,
          type: 'success'
        });
      },(error)=>{
        this.$message({
          type: 'error',
          message: error.body.message
        });
      });
    }
  }
}
</script>
