<template>
  <div id="acc-income-caps">
    <el-row>
      <el-col :span="12">
        <p>Acc Income Caps</p>
      </el-col>
      <el-col :span="12">
        <el-button
          size="mini"
          class="right"
          type="primary"
          plain
          @click="show_dialog = true"
        >
          Add Income cap
        </el-button>
      </el-col>
    </el-row>
    <el-table
      :data="income_caps"
      stripe
    >
      <el-table-column
        prop="start"
        label="Start"
        width="160"
      />
      <el-table-column
        prop="ends"
        label="End"
        width="160"
      />
      <el-table-column
        prop="max_earning"
        label="Maximum earnings"
        width="160"
      >
        <template #default="scope">
          <money :amount="scope.row.max_earning" />
        </template>
      </el-table-column>
      <el-table-column
        prop="max_levy_payable"
        label="Maximum levy payable"
        width="180"
      >
        <template #default="scope">
          <money :amount="scope.row.max_levy_payable" />
        </template>
      </el-table-column>
      <el-table-column width="160">
        <template #default="scope">
          <el-button
            size="mini"
            type="danger"
            plain
            @click="delete_acc_income_cap(scope.row.id)"
          >
            Delete
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="New Acc Income Cap"
      :visible.sync="show_dialog"
      width="30%"
      @opened="set_default_input()"
    >
      <el-form
        ref="acc_income_cap"
        :model="income_cap"
        label-width="140px"
        :rules="rules"
      >
        <el-form-item
          label="Start period"
          prop="start"
          required
        >
          <el-date-picker
            v-model="income_cap.start"
            placeholder="Start period"
            format="dd.MM.yyyy"
            size="small"
            default-value="2025-04-01"
            type="datetime"
          />
        </el-form-item>
        <el-form-item
          label="End period"
          prop="ends"
          required
        >
          <el-date-picker
            v-model="income_cap.ends"
            placeholder="End period"
            format="dd.MM.yyyy"
            size="small"
            default-value="2026-03-31"
          />
        </el-form-item>
        <el-form-item
          label="Max earning"
          prop="max_earning"
          required
        >
          <el-input-number
            v-model="income_cap.max_earning"
            size="small"
            :precision="2"
            :step="1"
            :max="400000"
          />
        </el-form-item>
        <el-form-item
          label="Max levy payable"
          prop="max_levy_payable"
          required
        >
          <el-input-number
            v-model="income_cap.max_levy_payable"
            size="small"
            :precision="2"
            :step="1"
            :max="400000"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            size="small"
            type="primary"
            @click="create_acc_income_cap"
          >
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {acc_income_caps, create_acc_income_cap, delete_acc_income_cap} from '../../api/acc_income_caps';
import Money from '../shared/Money';

export default {
  name: 'AccLevyRates',
  components: {Money},
  data() {
    return {
      income_cap: { max_earning: 1, max_levy_payable: 1},
      income_caps: [],
      show_dialog: false,
      rules: {
        max_earning: [{ required: true, message: ' ' }],
        max_levy_payable: [{ required: true, message: ' ' }],
        start: [{ required: true, message: ' ' }],
        ends: [{ required: true, message: ' ' }],
      }
    };
  },
  created() {
    this.acc_income_caps();
  },
  methods: {
    acc_income_caps(){
      acc_income_caps(this).then((response)=> {
        this.income_caps = response.data;
      });
    },
    create_acc_income_cap(){
      this.$refs['acc_income_cap'].validate((valid) => {
        if (valid) {
          create_acc_income_cap(this, this.income_cap).then((response) => {
            this.$message({
              type: 'success',
              message: response.data.message
            });
            this.acc_income_caps();
          });
        }
      });
    },
    delete_acc_income_cap(id){
      delete_acc_income_cap(this, id).then((response)=> {
        this.$message({
          type: 'success',
          message: response.data.message
        });
        this.acc_income_caps();
      });
    },
    set_default_input() {
      Array.from(document.querySelectorAll('.el-select__input, .el-input__inner')).forEach(
        function (e) {
          e.classList.add('browser-default');
          if (e.querySelector('input') != null)
            e.querySelector('input').className += ' browser-default';
        }
      );
    },
  },
};
</script>

<style scoped>
#acc_income_caps /deep/ .el-dialog {
  width: 550px !important;
}
#acc_income_caps /deep/ .el-input{
  width: inherit;
}
</style>