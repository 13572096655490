<template>
  <div>
    <el-row>
      <el-col
        :span="24"
      >
        <h1>{{ account_name }} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{ account_no }}</h1>
      </el-col>
    </el-row>
    <el-row>
      <el-col
        :xl="{span: 22, offset: 2}"
        :span="24"
      >
        <InvoiceSummary :summary_balance="summary_balance" />
      </el-col>
    </el-row>
    <br>
    <el-row>
      <el-col
        :xl="{span: 11, offset: 2}"
        :span="24"
      >
        <SendStatement
          :init_send_to="init_send_to"
          :account_id="account_id"
        />
      </el-col>
      <el-col
        :xl="{span: 11}"
        :span="24"
      >
        <DownloadStatement
          :account_id="account_id"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import InvoiceSummary from './InvoiceSummary';
import SendStatement from './SendStatement';
import DownloadStatement from './DownloadStatement';

export default {
  name: 'GrowthDevelopment',
  components: {DownloadStatement, SendStatement, InvoiceSummary},
  props: {
    account_id: {
      type: String,
      required: true
    },
    account_no: {
      type: String,
      required: true,
    },
    account_name: {
      type: String,
      required: true,
    },
    summary_balance: {
      type: Object,
      required: true,
    },
    init_send_to: {
      type: String,
      required: true,
    }
  }
};
</script>
