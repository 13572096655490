<template>
  <div>
    <h1>WorkingFile Template List</h1>

    <el-row>
      <el-col
        :span="24"
        :lg="12"
        :xl="12"
      >
        <el-select
          v-model="selected_template"
          class="template__select"
          @change="fetch_working_files"
        >
          <el-option-group
            v-for="group in templates"
            :key="group.group_name"
            :label="group.group_name"
          >
            <el-option
              v-for="item in group.proposals"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
              <span style="float: left">{{ item.value }}</span>
              <span style="float: right">Show to Client [<span style="color: blue">{{ item.exposed_to_client_count }}</span>] of {{ item.total_count }}
              </span>
            </el-option>
          </el-option-group>
        </el-select>
      </el-col>
    </el-row>

    <br>
    <el-card class="box-card">
      <el-table
        ref="working_file_table"
        :data="working_files_list"
        style="width: 100%"
        stripe
      >
        <el-table-column
          :min-width="10"
        >
          <template slot-scope="scope">
            <img
              :src="scope.row.thumb"
              style="border: 1px solid #e4dede;width: 30px"
            >
          </template>
        </el-table-column>

        <el-table-column
          :min-width="30"
          label="Name"
        >
          <template slot-scope="scope">
            <el-input v-model="scope.row.name" />
          </template>
        </el-table-column>
        <el-table-column
          :min-width="60"
          label="Show to Client"
        >
          <template slot-scope="scope">
            <el-checkbox-group v-model="scope.row.accounts">
              <el-checkbox
                v-for="item in accounts"
                :key="item.id"
                class="checkbox-btn"
                :label="item.id"
              >
                {{ item.name }}
              </el-checkbox>
            </el-checkbox-group>
          </template>
        </el-table-column>
      </el-table>
      <br>
      <el-button
        type="primary"
        @click="onUpdate"
      >
        Update
      </el-button>
    </el-card>
  </div>
</template>

<script>
import {Request} from '../../utilities/Request';
import {defaultInput} from '../../utilities/DefaultInput';

export default {
  name: 'Templates',
  props: {
    templates: {
      type: Array,
      required: true
    },
    accounts: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selected_template: '',
      working_files_list: [],
    };
  },
  created() {
    console.log(this.accounts);
    this.selected_template = this.templates[0].proposals[0].id;
    this.fetch_working_files();
  },
  mounted() {
    defaultInput();
  },
  methods: {
    fetch_working_files() {
      Request.post(
        this,
        'proposal_working_files',
        (response)=>{
          this.working_files_list = response.body.files;
          this.$nextTick(() => {
            defaultInput();
          });
        },
        { proposal_id: this.selected_template }
      );
    },
    onUpdate() {
      let update_working_files = [];
      this.working_files_list.forEach(working_file=>{
        update_working_files.push({
          id: working_file.id,
          name: working_file.name,
          accounts: working_file.accounts
        });
      });
      Request.patch(
        this,
        '/api/working_files/update_exposed_to_clients',
        (response)=>{
          this.$message({
            type: 'success',
            message: response.body.message
          });
        },
        { working_files: update_working_files }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.template__select {
  width: 100%;
}

.checkbox-btn {
  margin: 5px;
  padding: 9px 20px 9px 10px;
  border: 1px solid #DCDFE6;
  border-radius: 4px;
}
</style>
