import {Request} from '../utilities/Request';

export function timeframe_student_loans(this_obj) {
  return Request.get(
    this_obj,
    '/api/timeframe_student_loans',
    null,
  );
}

export function create_student_loan_thresholds(this_obj, student_loan_threshold) {
  return Request.post(
    this_obj,
    '/api/student_loan_thresholds',
    null,
    {
      student_loan_threshold: student_loan_threshold
    }
  );
}

export function delete_timeframe_student_loans(this_obj) {
  return Request.delete(
    this_obj,
    '/api/delete_timeframe_student_loans'
  );
}
