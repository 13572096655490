<template>
  <div id="create-new-quote">
    <el-dialog
      title="Create New Quote"
      :visible.sync="show_new_quote_dialog"
      width="40%"
      append-to-body
      :close-on-click-modal="false"
      @opened="set_default_input()"
    >
      <el-row :gutter="20">
        <el-col :span="10">
          <span>Category:</span>
          <el-select
            v-model="proposal.category_task_id"
            filterable
            placeholder="Select a quote category"
          >
            <el-option
              v-for="category in categories"
              :key="category.id"
              :label="category.name"
              :value="category.id"
            >
              <span>{{ category.name }}</span>
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="10">
          <span>Job Name:</span>
          <el-input
            v-model="proposal.job_name"
            placeholder="Job Name"
          />
        </el-col>
        <el-col :span="10">
          <span>Client Order Number:</span>
          <el-input
            v-model="proposal.client_order_number"
            placeholder="Client Order Number"
          />
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <span>Branch:</span>
          <el-select
            v-model="proposal.branch_id"
            filterable
            placeholder="Select a branch"
          >
            <el-option
              v-for="branch in branches"
              :key="branch.id"
              :label="branch.name"
              :value="branch.id"
            >
              <span>{{ branch.name }}</span>
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <span>Quote Type:</span>
          <el-select
            v-model="proposal.quote_type_id"
            filterable
            placeholder="Select a quote type"
          >
            <el-option
              v-for="type in quote_types"
              :key="type.id"
              :label="type.name"
              :value="type.id"
            >
              <span>{{ type.name }}</span>
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <span>Term in month:</span>
          <el-select
            v-model="proposal.term_in_month"
            filterable
            placeholder="Select a term"
          >
            <el-option
              v-for="(term,index) in terms"
              :key="index"
              :label="term"
              :value="term"
              :default="1"
            >
              <span>{{ term }}</span>
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row
        v-show="proposal.category_task_id != null && templates.length > 0"
        :gutter="20"
      >
        <el-col :span="8">
          <span>Templates:</span>
          <el-select
            v-model="template_id"
            filterable
            placeholder="Select a template"
            style="width: 320px;"
          >
            <el-option
              v-for="template in templates"
              :key="template.id"
              :label="template.job_name"
              :value="template.id"
            >
              <span>{{ template.job_name }}</span>
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row
        v-show="proposal.category_task_id != null &&
          proposal.branch_id != null &&
          proposal.quote_type_id != null &&
          proposal.job_name != '' "
      >
        <el-button
          type="primary"
          class="right"
          @click="create_quote()"
        >
          Create Quote
        </el-button>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import {create_new_quote} from '../../../api/proposal';
import {set_default_input} from '../../../utilities/DefaultInput';

export default {
  name: 'CreateNewQuote',
  props: {
    account_id: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      show_new_quote_dialog: false,
      categories: [],
      branches: [],
      quote_types: [],
      templates: [],
      terms: [],
      template_id: null,
      proposal: {
        account_id: this.account_id,
        client_order_number: '',
        job_name: '',
        category_task_id: null,
        branch_id: null,
        quote_type_id: null,
        term_in_month: 1
      }
    };
  },
  watch: {
    'proposal.category_task_id': function() {
      this.get_templates();
    }
  },
  created() {
    this.get_categories();
    this.get_branches();
    this.get_quote_types();
    this.get_terms_in_month();
  },
  methods: {
    set_default_input,
    create_quote(){
      create_new_quote(this, this.proposal).then((response)=>{
        this.show_new_quote_dialog = false;
        this.proposal = {};
        this.$message({
          type: 'success',
          message: response.data.message
        });
        window.location = `/edit_proposal?id=${ response.data.id }`;
      });
    },
    get_categories() {
      this.$http.get('/category_tasks_list').then(
        (response) => {
          this.categories = response.data;
          this.make_upper_case_category_names();
        }
      );
    },
    get_branches() {
      this.$http.get('/branches').then((response) => {
        this.branches = response.data;
        this.make_upper_case_branch_names();
      });
    },
    get_quote_types() {
      this.$http.get('/quote_types').then((response) => {
        this.quote_types = response.data;
        this.make_upper_case_quote_types();
      });
    },
    get_templates() {
      this.$http.post('/api/templates/templates', {category_task_id: this.proposal.category_task_id}).then((response) => {
        this.templates = response.data;
      });
    },
    get_terms_in_month(){
      for (let i=1; i<=12; i++) {
        this.terms.push(i);
      }
    },
    make_upper_case_category_names() {
      for (let key in this.categories) {
        this.categories[key].name = this.categories[key].name.toUpperCase();
      }
    },
    make_upper_case_branch_names() {
      for (let key in this.branches) {
        this.branches[key].name = this.branches[key].name.toUpperCase();
      }
    },
    make_upper_case_quote_types() {
      for (let key in this.quote_types) {
        this.quote_types[key].name = this.quote_types[key].name.toUpperCase();
      }
    }
  }
};
</script>
<style scoped>
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.right{float: right;}
#create-new-quote{display: inline;}
</style>