<template>
    <div>
        <div class="card  ">
            <div class="card-content ">
                <span class="card-title">
                    Open Jobs statistics
                </span>
                <br>
                <br>
                <table class="responsive-table striped">
                    <thead>
                    <tr class="header">
                        <th>Category</th>
                        <th>Sales</th>
                        <th>Time allocated</th>
                        <th>Time Spent</th>
                        <th>Time Remaining</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="rows" v-for="item in categories_statistics">
                 
                        <td>
                            <span class="center">{{item.category_name}}</span>
                        </td>
                        <td>
                            <a v-on:click="show_open_sales_by_category(item.category_id, item.category_name)">{{item.total_sales
                                | currency}}</a>
                        </td>
                        <td>
                            <a v-on:click="show_open_jobs_by_category(item.category_id, item.category_name)">
                              <Time :is_hours="item.is_hours" :minutes="item.total_allocated" />
                            </a>
                        </td>
                        <td>
                            <a v-on:click="show_open_jobs_by_category(item.category_id, item.category_name)">
                              <Time :is_hours="item.is_hours" :minutes="item.total_time_spent" />
                            </a>
                        </td>
                        <td>
                            <a v-on:click="show_open_jobs_by_category(item.category_id, item.category_name)">
                              <Time :is_hours="item.is_hours" :minutes="item.total_allocated - item.total_time_spent" />
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-action">
            </div>


        </div>
        <modal name="open_sales_jobs_records" :height="700" :width="1200" :adaptive="true">
            <div class="card ">
                <div class="card-content  ">
                    <span class="card-title">{{list_title}}<hr></span>
                    <div class="progress" v-show="is_loading">
                        <div class="indeterminate"></div>
                    </div>
                    <el-table
                      v-if="is_open_jobs"
                            :data="rows"
                            style="width: 100%"
                            height="600">
                      <el-table-column
                        prop="job_no"
                        :label="'job_no' | capitalize"
                        sortable
                      />
                      <el-table-column
                        prop="account"
                        :label="'account' | capitalize"
                        sortable
                      />
                      <el-table-column
                        prop="job"
                        :label="'job' | capitalize"
                        sortable
                      />
                      <el-table-column
                        prop="task"
                        :label="'task' | capitalize"
                        sortable
                      />
                      <el-table-column
                        prop="users"
                        :label="'users' | capitalize"
                        sortable
                      />
                      <el-table-column
                        prop="time_allocated"
                        :label="'time_allocated' | capitalize"
                        sortable
                      >
                        <template slot-scope="scope">
                          <Time :is_hours="scope.row.is_hours" :minutes="scope.row.time_allocated" />
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="time_spent"
                        :label="'time_spent' | capitalize"
                        sortable
                      >
                        <template slot-scope="scope">
                          <Time :is_hours="scope.row.is_hours" :minutes="scope.row.time_spent" />
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="time_remaining"
                        :label="'time_remaining' | capitalize"
                        sortable
                      >
                        <template slot-scope="scope">
                          <Time :is_hours="scope.row.is_hours" :minutes="scope.row.time_remaining" />
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="start_time"
                        :label="'start_time' | capitalize"
                        sortable
                      />
                      <el-table-column
                        prop="end_time"
                        :label="'end_time' | capitalize"
                        sortable
                      />
                    </el-table>
                  <el-table
                      v-else
                      :data="rows"
                      style="width: 100%"
                      height="600">

                    <el-table-column
                        v-for='column in columns'
                        v-bind:key='column'
                        v-bind:prop='column'
                        v-bind:label="column | capitalize"
                        sortable
                    >
                    </el-table-column>
                  </el-table>
                </div>
                <div class="card-action">

                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import Time from '../shared/Time';
    export default {
        name: "OpenJobsStatistics",
        components: { Time },
        data() {
            return {
                categories_statistics: [],
                is_loading: false,
                columns: [],
                rows: [],
                list_title: '',
                is_open_jobs: false

            }
        },
        created() {
        },
        mounted() {
            this.fill_data()
        },
        computed: {},
        methods: {
            open_modal() {
                this.$modal.show('open_sales_jobs_records')
            },
            hide_modal() {
                this.$modal.hide('open_sales_jobs_records')
            },
            fill_data() {
                this.$http.get('/open_jobs_statistics').then(response => {
                        if (response.data.status == 200) {
                            this.categories_statistics = response.data.data
                        }
                    }
                )
            },
            show_open_sales_by_category(category_id, category_name) {

                this.open_modal()
                this.columns = []
                this.rows = []
                this.list_title = category_name + " Sales (In-production Jobs)"
                this.is_loading = true;
                this.is_open_jobs = false;
                let self = this
                this.$http.post('/open_sales_by_category',
                    {
                        category_id: category_id
                    }).then(response => {

                    self.is_loading = false
                    if (response.data.status == 200) {
                        self.columns = response.data.data.columns
                        self.rows = response.data.data.rows
                    }
                })
            },
            show_open_jobs_by_category(category_id, category_name) {

                this.open_modal()
                this.columns = []
                this.rows = []
                this.list_title = category_name + " Jobs (In-production)"
                this.is_loading = true
                this.is_open_jobs = true;
                let self = this
                this.$http.post('/open_tasks_by_category',
                    {
                        category_id: category_id
                    }).then(response => {
                    self.is_loading = false
                    if (response.data.status == 200) {
                        self.columns = response.data.data.columns
                        self.rows = response.data.data.rows
                    }
                })
            }
        }
    }
</script>

<style scoped>
    a {
        cursor: pointer;
    }
</style>