<template>
  <div>
    <el-row :gutter="20">
      <el-col
        :span="6"
        v-for="other_layer in layer.nested_layers"
        :key="other_layer.label"
      >
        <el-checkbox v-model="other_layer.checked" :label="other_layer.label">{{
          other_layer.label
        }}</el-checkbox>
        <br />
        <small>[W:{{ other_layer.width }},H:{{ other_layer.height }}]</small>
        <br />
        <el-input
          style="width: 80px"
          :disabled="!other_layer.checked"
          v-model.number="other_layer.qty"
          size="mini"
        ></el-input>
      </el-col>
      <br />
    </el-row>
  </div>
</template>

<script>
export default {
  name: "LayersNestingEntry",
  props: ["layer"],
  data() {
    return {};
  },
  mounted() {
    this.set_inputs_to_default();
  },
  computed: {},
  methods: {
    set_inputs_to_default() {
      let _this = this;
      Array.from(
        document.querySelectorAll(".layers_nesting_div .el-input")
      ).forEach(function(e, i) {
        e.classList.add("browser-default");
        if (e.querySelector("input") != null)
          e.querySelector("input").className += " browser-default";
      });
    }
  },
  watch: {}
};
</script>

<style></style>
