<template>
  <div
    class="inside-stock-info"
    :style="[
      (stock.request != undefined && stock.request.is_approved == 1) ||
        stock.mark_as_deleted == 1
        ? { 'text-decoration': 'line-through', 'font-style:': 'italic' }
        : {},
    ]"
  >
    <!-- <td> 
      <small>{{get_working_file(stock.working_file_id)}}</small>
    </td>-->

    <div>
      <div
        v-if="
          stock.is_workspace == true &&
            proposal.approved == 0 &&
            stock.is_initiated == 1
        "
        style="width:100%; text-transform:uppercase; background: orange;text-align:center;color:#fff; padding:5px; margin-bottom:10px;"
      >
        Job is in workspace - Stock is initiated
      </div>
      <i
        class="el-icon-arrow-right"
        style="color:grey"
      />

      <i
        v-show="stock.favourite"
        class="el-icon-star-on"
        style="color:orange"
      />

      <a
        target="_blank"
        :href="'../../../stock_items/' + stock.stock_item_id + '/edit'"
      >ID:{{ stock.id }}</a>
      |
      {{ stock.working_file != undefined ? stock.working_file.name : "Main" }}
      |
      <span v-if="stock.purchase_order != undefined">
        <a :href="get_po_link(stock.purchase_order.id)">
          PO:{{ stock.purchase_order.id }}
        </a>
      </span>

      <span v-show="stock.minimum_packet_quantity">
        <b>Min.PQ</b>
        {{ stock.minimum_packet_quantity }} |
      </span>

      <span v-show="stock.price">
        <b>Total:</b>
        ${{ stock_total_price }} |
      </span>
      <span
        v-show="
          stock.purchase_order == undefined ||
            (stock.purchase_order != undefined &&
              stock.purchase_order.status != 'PENDING')
        "
      >
        <b>Used:</b>
        <el-input-number
          id="usedqty"
          v-model="stock.used_qty"
          :disabled="
            has_project_plan == true ||
              used_qty_disabled == true ||
              (stock.is_workspace == true &&
                proposal.approved == 0 &&
                stock.is_initiated == 0)
          "
          :min="0"
          :controls="false"
          :max="stock.quantity"
          placeholder
          style="width:60px;"
          size="mini"
        />

        <el-button
          :disabled="
            has_project_plan == true ||
              used_qty_disabled == true ||
              (stock.is_workspace == true &&
                proposal.approved == 0 &&
                stock.is_initiated == 0)
          "
          type="plain"
          icon="el-icon-check"
          size="mini"
          circle
          @click="update_used_details(stock)"
        />
      </span>
      <div>
        <div
          v-if="stock.billable_locked == true && proposal.approved == 1"
          style="padding:0px 5px;float:left;"
        >
          <input
            :id="'n_billable_' + stock.id"
            type="checkbox"
            class="filled-in"
            :name="'n_billable_' + stock.id"
            :checked="stock.billable"
            :data-id="stock.id"
            @change="update_stock_billable($event, stock.id)"
          >
        </div>
        <div
          v-else-if="proposal.approved == 1"
          style="padding:0px 5px;float:left;"
        >
          <input
            :id="'n_billable_' + stock.id"
            type="checkbox"
            class="filled-in"
            :name="'n_billable_' + stock.id"
            :checked="stock.billable"
            :data-id="stock.id"
            @change="update_stock_billable($event, stock.id)"
          >
          <label :for="'n_billable_' + stock.id">Billable</label>
        </div>
      </div>

      <!-- <el-input-number
        size="mini"
        :step="0.1"
        v-on:change="update_proposal_stock_sale_price"
        type="number"
        v-model="stock.sale_price"
        :disabled="sale_price_disabled"
      ></el-input-number>
 
      ${{ stock_total_price }}-->

      <!-- delete request button -->
      <div class="buttonfield">
        <div v-if="stock.is_workspace == true && proposal.approved == 0">
          <div
            v-if="stock.is_initiated == 0"
            style="padding:0px 5px;float:left;"
          >
            <el-button
              class="stockbttns"
              @click.prevent="
                initiate_stock_item(get_stock_category(stock), stock.id)
              "
            >
              Initiate Stock
            </el-button>
          </div>
        </div>
        <!-- allocated button -->
        <div style="padding:0px 5px;float:left;">
          <el-button
            class="stockbttns"
            @click="load_open_jobs"
          >
            <i
              class="el-icon-info"
              style="font-size:14px;"
            />
            Jobs Same Stock
          </el-button>
        </div>

        <!-- deleted button -->

        <div v-if="proposal.completed == 0 && stock.mark_as_deleted == 0">
          <div
            v-if="
              (stock.purchase_order != undefined &&
                stock.purchase_order.status == 'PENDING') ||
                stock.purchase_order == undefined
            "
            style="padding:0px 5px;float:left;"
          >
            <span style="float:right; width:30px;" />

            <el-button
              class="stockbttns"
              @click.prevent="delete_stock_item('default', stock.id)"
            >
              <i
                class="el-icon-delete-solid"
                style="font-size:14px;"
              />
              Delete stock
            </el-button>
          </div>
          <!-- received stock button -->

          <div
            v-if="
              stock.purchase_order != undefined &&
                stock.purchase_order.status == 'APPROVED' &&
                stock.request == undefined
            "
            style="padding:0px 5px;float:left;"
          >
            <el-button
              type="warning"
              class="stockbttns"
              @click="received_stock(stock.purchase_order)"
            >
              <i
                class="el-icon-success"
                style="font-size:14px;"
              /> Received
              Stock
            </el-button>
          </div>
          <!-- delete request button -->

          <div
            v-if="
              stock.purchase_order != undefined &&
                (stock.purchase_order.status == 'APPROVED' ||
                  stock.purchase_order.status == 'RECEIVED') &&
                stock.request == undefined &&
                stock.used_qty == 0
            "
            style="padding:0px 5px;float:left;"
          >
            <el-button
              class="stockbttns"
              @click.prevent="
                request_delete_stock_item('default', stock.id)
              "
            >
              <i
                class="el-icon-delete-solid"
                style="font-size:14px;"
              />
              Request Delete
            </el-button>
          </div>

          <div v-if="stock.request != undefined">
            <div v-if="stock.request.is_approved == 0">
              <span>Delete Request submitted</span>
            </div>
            <div v-else-if="stock.request.is_approved == 1">
              <span>Delete Request approved</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { ProposalStockItemMixin } from 'mixins/ProposalStockItemMixin.js';
import { ProposalMixin } from 'mixins/ProposalMixin.js';
import { editor_store } from 'store/modules/editor';

export default {
  name: 'StockItemsEntry',
  mixins: [ProposalStockItemMixin, ProposalMixin],
  props: ['stock', 'select_open_jobs_stock'],
  data() {
    return {
      used_qty_disabled: false,
      qty_disabled: false,
      sale_price_disabled: false,
    };
  },
  store: editor_store,
  mounted() {
    if (this.stock.used_qty > 0) this.used_qty_disabled = true;
  },
  methods: {
    load_open_jobs() {
      this.$emit('select_open_jobs_stock', this.stock.stock_item_id);
    },
    open() {
      this.$alert('This is a message', 'Title', {
        confirmButtonText: 'OK',
        callback: (action) => {
          this.$message({
            type: 'info',
            message: `action: ${action}`,
          });
        },
      });
    },
    ...mapActions(['set_stock_items_list']),
    stock_list: function() {
      let self = this;
      var params = {
        proposal_id: self.proposal_id,
        branch_id: self.proposal.branch_id,
      };
      this.$http.post('/stock_items_list', params).then(
        (response) => {
          if (response.data.status == 200) {
            self.load_stocks_list();
          } else {
            self.show_stock = [];
          }
        },
        function(response) {
          console.log(response);
        }
      );
    },
    load_stocks_list() {
      let _this = this;
      setTimeout(function() {
        _this.load_stock_items_list(_this.proposal.id).then((response) => {
          _this.set_stock_items_list(response);
        });
      }, 500);
    },
    get_stock_category(stock) {
      if (stock.sign_id != undefined) return 'signage';
      else if (stock.print_calculation_id != undefined) return 'print';
      else return 'default';
    },
    received_stock(po) {
      this.receive_po_stock_items(po.id).then(
        (response) => {
          po.status = 'RECEIVED';
          this.$message({
            message: 'Stock Item Updated',
            type: 'success',
          });
        },
        (response) => {
          this.$message({
            message: 'Unable to update',
            type: 'error',
          });
        }
      );
    },
    update_used_details(stock) {
      if (stock.used_qty == 0) {
        this.$message({
          type: 'warning',
          message: 'Used quantity should be greater than 0.',
        });
        return;
      }
      let _this = this;
      this.$confirm(
        'Stock used quantity can be updated one time only, so please make sure to add the total number of used quantity now, if not sure please cancel and update later ?',
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      )
        .then(() => {
          let params = {
            id: stock.id,
            used_qty: stock.used_qty,
            qty_desc: stock.used_qty_desc,
          };
          params = `[${JSON.stringify(params)}]`;
          this.update_used_stock_details(this.proposal.id, params).then(
            (response) => {
              this.used_qty_disabled = true;
              let stock_record = this.stock_items_list.filter(
                (c) => c.id == stock.id
              )[0];
              stock_record.used_qty_original = stock.used_qty;
              this.$message({
                message: 'Stock Item Updated',
                type: 'success',
              });
            },
            (response) => {
              this.$message({
                message: 'Unable to update',
                type: 'error',
              });
            }
          );
        })
        .catch(() => {
          return false;
        });
    },
    get_working_file(id) {
      // migrate this function later to Store or a module library
      let working_file = this.files_list.filter((item) => {
        return item.id == id;
      })[0];

      if (working_file != undefined) return working_file.name;
      else return '';
    },
    calc_qty_from_stock(qty, po_qty) {
      var from_stock = qty - po_qty;
      return from_stock < 0 ? 0 : from_stock;
    },
    get_po_link(id) {
      return '/purchase_orders/' + id + '/edit';
    },
    save_qty_overwritten: function(event, stock_item_id) {
      this.$http.post('/save_qty_overwritten', {
        stock_item_id: stock_item_id,
        stock_quantity_calculated_overwritten: event.target.checked,
      });
    },
    update_stock_billable: function(event, id) {
      this.$http.post('/update_stock_billale', {
        id: id,
        stock_type: 'ProposalStockItem',
        billable: event.target.checked ? 1 : 0,
      });
    },
    show_stock_item_form: function() {
      //document.getElementById('stock').style.display = 'block';
      $('#new_stock_item').fadeOut();
    },
    update_proposal_stock_sale_price: function(event) {
      let _this = this;
      _this.sale_price_disabled = true;
      console.log(_this.stock.id);
      console.log(_this.stock.sale_price);
      this.update_stock_sale_price(_this.stock.id, _this.stock.sale_price).then(
        (response) => {
          _this.stock.total_price =
            _this.stock.sale_price * _this.stock.quantity;
          _this.sale_price_disabled = false;
        },
        (reason) => {
          _this.sale_price_disabled = false;
          console.log(reason);
          return false;
        }
      );
    },
    update_proposal_stock_qty: function(event) {
      let _this = this;
      _this.qty_disabled = true;
      //Update QTY
      var js = {
        id: _this.stock.id,
        source_type: 'ProposalStockItem',
        qty: _this.stock.quantity,
      };

      this.$http.post('/update_stock_item_qty', js).then(
        (response) => {
          _this.stock.total_price =
            _this.stock.sale_price * _this.stock.quantity;
          _this.qty_disabled = false;
        },
        function(response) {
          _this.qty_disabled = false;
          console.log(response);
        }
      );
    },
    delete_stock_item: function(type, id) {
      let _this = this;
      this.delete_proposal_stock_item(type, id);
      this.$confirm(
        'Are you sure you want to delete this stock item',
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      ).then(
        (value) => {
          // remove stock from list
          if (_this.proposal.approved == 1) _this.stock.mark_as_deleted = 1;
          else {
            let index = _this.stock_items_list.indexOf(_this.stock);
            _this.stock_items_list.splice(index, 1);
          }
        },
        (reason) => {
          console.log(reason);
        }
      );

      // return;

      // const result =   this.delete_proposal_stock_item(type,id);
      // console.log(result);
      // return

      //if (confirm("Are you sure to delete the stock item ?") == false)
      //return false;
      // let _this = this;
      // this.$http.delete(`/delete_proposal_stock_item/${type}/${id}`).then(
      //   response => {

      //     let index = _this.stock_items_list.indexOf(_this.stock);
      //     if (_this.proposal.approved == 1) _this.stock.mark_as_deleted = 1;
      //     else _this.stock_items_list.splice(index, 1);
      //   },
      //   function(response) {
      //     console.log(response);
      //   }
      // );
    },
    initiate_stock_item: function(type, id) {
      if (
        confirm(
          'Are you sure to initiate the stock item, stock will be allocated & PO will be generated if out of stock ?'
        ) == false
      )
        return false;

      this.$http
        .post('/initiate_stock_item', {
          type: type,
          id: id,
        })
        .then(
          (response) => {
            this.stock_list();
          },
          function(response) {
            console.log(response);
          }
        );
    },
    request_delete_stock_item: function(type, id) {
      if (confirm('Are you sure to delete the stock item ?') == false)
        return false;
      this.$http.delete(`/request_delete_stock_item/${type}/${id}`).then(
        (response) => {
          this.load_stocks_list();
        },
        function(response) {
          console.log(response);
        }
      );
    },
  },
  computed: {
    ...mapGetters([
      'proposal',
      'stock_items_list',
      'files_list',
      'has_project_plan',
    ]),
    stock_total_price() {
      return (this.stock.quantity * this.stock.sale_price).toFixed(2);
    },
  },
};
</script>
<style scoped>
.stockdelete {
  color: red;
  background: none;
  border: 0px;
  float: right;
}
.inside-stock-info {
  padding: 20px;
  width: 100%;
  border: 1px solid orange;
}
.buttonfield {
  /* background: orange; */
  padding: 5px;
  height: auto;
  float: left;
  width: 100%;
}
.stockbttns {
  border: 1px solid orange;
  background-color: rgba(255, 187, 0, 0.1);
  color: orange;
  border-radius: 7px;
  padding: 7px;
  float: left;
  text-transform: uppercase;
}
.stockbttns:hover {
  background: 999;
}
</style>
