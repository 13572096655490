<template>
  <div id="crm_user_benefits">
    <el-form
      ref="form"
      :model="benefit_details"
      label-width="120px"
    >
      <p>Description for PDF</p>
      <jodit-editor
        v-model="benefit_details.description"
        :buttons="buttons"
      />
      <br>
      <el-button
        type="primary"
        size="small"
        style="float:right;"
        @click="save_details"
      >
        Save
      </el-button>
    </el-form>
  </div>
</template>
<script>
export default {
  name: 'CrmUserBenefits',
  props: {
    crm_user_id: {
      type: [String,Number],
      required: true
    }
  },
  data() {
    return {
      benefit_details: {
        description: ''
      },
      buttons: [
        'source',
        'link',
        '|',
        'bold',
        'underline',
        'strikethrough',
        'italic',
        'paragraph',
        'cut',
        'copy',
        'paste',
      ]
    };
  },
  watch: {
    crm_user_id(){
      this.get_benefit_details();
    }
  },
  mounted() {
    this.get_benefit_details();
  },
  created() {
  },
  methods: {
    get_benefit_details(){
      this.$http.get('/benefit_details/'+ this.crm_user_id).then(
        (response) => {
          this.benefit_details = response.data;
        });
    },
    save_details(){
      this.$http.post(`/create_or_update_benefit/${this.crm_user_id}`, this.benefit_details).then(() => {
        this.$message({
          message: 'Benefits details updated.',
          type: 'success',
        });
        this.get_benefit_details();
      }, () => {
        this.$message({
          message: 'Error',
          type: 'error',
        });
        this.get_benefit_details();
      });
    },
  }
};
</script>
<style scoped>
#crm_user_benefits {
  max-width: 800px;
}
</style>
