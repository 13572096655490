<template>
  <div id="bank-accounts">
        <h3>
          <strong>BANK ACCOUNTS LIST</strong>
            <el-button
              class="right"
              button
              type="primary"
              plain
              size="mini"
              v-on:click="create_new_record"
              style="margin-top: -10px;"
              >New Bank Acc
            </el-button>
          <hr />
        </h3>
        <table>
          <thead>
            <tr>
              <th>Account Name</th>
              <th>Account No</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="record in data" :key="record.id">
              <td>{{ record.account_no }}</td>
              <td>{{ record.account_name }}</td>
              <td>
                <i v-show="record.is_active == true" class="el-icon-check"></i>
              </td>
              <td>
                <el-button
                  icon="el-icon-edit"
                  circle
                  v-on:click="edit_record(record)"
                ></el-button>
                <el-button
                  icon="el-icon-delete"
                  circle
                  v-on:click="delete_record(record.id)"
                ></el-button>
              </td>
            </tr>
          </tbody>
        </table>
    <el-dialog
      title="Edit Bank details"
      :visible.sync="dialogFormVisible"
      width="50%"
      @opened="set_default_input"
    >
      <el-form
        :model="selected_bank_details"
        ref="bank_details_form"
        :rules="rules"
      >
            <el-form-item prop="is_active">
              <el-checkbox v-model="selected_bank_details.is_active"
              >Active</el-checkbox>
            </el-form-item>

            <el-form-item prop="account_no" label="Account No [##-####-#######-###]">
              <el-input
                v-model="selected_bank_details.account_no"
                size="mini"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item prop="account_name" label="Account Name" required>
              <el-input
                v-model="selected_bank_details.account_name"
                size="mini"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item prop="particulars" label="Particulars">
              <el-input
                v-model="selected_bank_details.particulars"
                size="mini"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item prop="code" label="Code">
              <el-input
                v-model="selected_bank_details.code"
                size="mini"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item prop="reference" label="Reference">
              <el-input
                v-model="selected_bank_details.reference"
                size="mini"
                autocomplete="off"
              ></el-input>
            </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogFormVisible = false"
          >Cancel</el-button
        >
        <el-button
          type="primary"
          size="mini"
          @click="submit('bank_details_form')"
          >Save</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "BankAccounts",
  data() {
    return {
      loading: false,
      data: [],
      rules: {
        account_no: [
          {
            required: true,
            pattern: /\d{2}-\d{7}-\d{3}$/g,
            message: "Please input account no (##-#######-###).",
            trigger: "blur",
          },
        ],
      },
      dialogFormVisible: false,
      url_request: "/bank_accounts/",
      selected_bank_details: {
        id: "",
        bank_accountable_type: "",
        bank_accountable_id: "",
      },
    };
  },
  props: ["bank_accountable_type", "bank_accountable_id"],
  watch: {
    bank_accountable_id(){
      this.fill_data();
    }
  },
  mounted() {
    this.fill_data();
  },
  methods: {
    fill_data() {
      this.loading = true;
      this.$http
        .post("/bank_accounts_list", {
          bank_accountable_id: this.bank_accountable_id,
          bank_accountable_type: this.bank_accountable_type,
        })
        .then((response) => {
          this.loading = false;
          this.data = response.data;
        });
    },
    create_new_record() {

      this.edit_record({
        id: "",
        account_name: "",
        account_no: "",
        code: "",
        reference: "",
        particulars: "",
        active: false,
        bank_accountable_type: this.bank_accountable_type,
        bank_accountable_id: this.bank_accountable_id,
      });
    },
    edit_record(data) {
      this.selected_bank_details = Object.assign({}, data);
      this.dialogFormVisible = true;
    },
    submit(form_name) {
      let url = "";
      this.$refs[form_name].validate((valid) => {
        if (valid) this.save_record();
      });
    },
    save_record() {
      let _this = this;

      _this.loading = true;
      if (_this.selected_bank_details.id == "")
        this.$http
          .post(_this.url_request, {
            bank_account: _this.selected_bank_details,
          })
          .then(_this.successCallback, _this.errorCallback);
      else
        this.$http
          .patch(_this.url_request + _this.selected_bank_details.id, {
            bank_account: _this.selected_bank_details,
          })
          .then(_this.successCallback, _this.errorCallback);
    },
    successCallback(response) {
      let _this = this;
      _this.loading = false;
      _this.dialogFormVisible = false;
      if (response.status == 200) {
        _this.$message({
          type: "success",
          message: "Updated successfully.",
        });
        _this.fill_data();
        _this.selected_bank_details = { id: "" };
      } else {
        _this.$message({
          type: "error",
          message: response.data.message,
        });
      }
    },
    errorCallback(response) {},
    delete_record(id) {
      let _this = this;

      this.$confirm(
        "This will permanently delete the record. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          _this.loading = true;
          this.$http.delete(_this.url_request + id).then((response) => {
            this.$message({
              type: "success",
              message: "Delete completed",
            });
            _this.loading = false;
            _this.fill_data();
          });
        })
        .catch(() => {});
    },
    set_default_input() {
      Array.from(document.querySelectorAll('.el-select__input, .el-input__inner')).forEach(
          function (e) {
            e.classList.add('browser-default');
            if (e.querySelector('input') != null)
              e.querySelector('input').className += ' browser-default';
          }
      );
    },
  },
};
</script>
<style scoped>
#bank-accounts /deep/ .el-form-item__error {
  line-height: 0.5;
}
#bank-accounts /deep/ .el-form-item__content {
  line-height: 0;
}
@media only screen and (max-width: 993px) {
  #bank-accounts /deep/ .el-dialog {
    width: 100% !important;
  }
  #bank-accounts /deep/ .el-dialog__body {
    padding: 20px !important;
  }
}
</style>
