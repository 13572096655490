import {Request} from '../utilities/Request';

export function hourly_wage_rate(this_obj, crm_user_id, pay_period_start, pay_period_end) {
  return Request.post(
    this_obj,
    `/api/crm_users/${crm_user_id}/employee_gross_payments/hourly_wage_rate`,
    null,
    {
      pay_period_start: pay_period_start,
      pay_period_end: pay_period_end
    }
  );
}

export function salary_fixed_amount(this_obj, crm_user_id, pay_period_start, pay_period_end) {
  return Request.post(
    this_obj,
    `/api/crm_users/${crm_user_id}/employee_gross_payments/salary_fixed_amount`,
    null,
    {
      pay_period_start: pay_period_start,
      pay_period_end: pay_period_end
    }
  );
}

export function gross_payments(this_obj, crm_user_id, pay_period_start, pay_period_end) {
  return Request.post(
    this_obj,
    `/api/crm_users/${crm_user_id}/employee_gross_payments/gross_payments`,
    null,
    {
      pay_period_start: pay_period_start,
      pay_period_end: pay_period_end
    }
  );
}

