<template>
  <div style="margin-bottom:10px; ">
    <strong>
      <el-input
        v-model="working_file_name"
        :disabled="editable === false"
        placeholder="Please input Name"
        class="page_titleinput"
        @change="onWorkingFileNameChange"
      />
    </strong>
   
    <el-tooltip
      v-if="consecutive === true"
      class="head-info"
      effect="dark"
      content="Tasks are consecutive in this list"
      placement="top-start"
    >
      <i class="el-icon-s-operation" />
    </el-tooltip>
    <el-checkbox
      v-if="editable === true"
      ref="include_check_box"
      v-model="is_included"
      class="included_checkbox"
      @change="onChangedIncluded"
    >
      Include in quote
    </el-checkbox>
    <span
      v-if="editable === true"
    >
      &nbsp;&nbsp;
    </span>
    <el-input-number
      v-if="id !== 0"
      v-model="qty"
      :disabled="editable === false"
      size="small"
      :min="1"
      @change="onChangedQty"
    />
    <el-select
      v-if="id !== 0"
      v-model="quantity_unit_id"
      :disabled="editable === false"
      size="small"
      @change="onChangedQuantityUnit"
    >
      <el-option
        v-for="quantity in quantity_units"
        :key="quantity.id"
        :label="quantity.name"
        :value="quantity.id"
      />
    </el-select>
    <el-button
      v-if="editable === true"
      size="mini"
      type="danger"
      class="head-info"
      style="float:right"
      @click="onRemove"
    >
      <i class="el-icon-delete" />  Remove 
    </el-button>
  </div>
</template>

<script>

import { updateName, updateIsIncluded, updateQty, updateQuantityUnit } from '../../../api/working_files';
import { defaultInput } from '../../../utilities/DefaultInput';
import {EventBus} from '../../../utilities/EventBus';

export default {
  name: 'WorkingFileHeader',
  props: {
    id: {
      type: Number,
      required: true
    },
    prop_name: {
      type: String,
      required: true
    },
    prop_is_included: {
      type: Boolean,
      required: true
    },
    prop_qty: {
      type: Number,
      required: true
    },
    prop_quantity_unit_id: {
      validator: (value) => {
        return (typeof value === 'number' || value === null);
      },
      required: true
    },
    consecutive: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      working_file_name: this.prop_name,
      saved_name: this.prop_name,
      is_included: this.prop_is_included,
      qty: this.prop_qty,
      quantity_unit_id: this.prop_quantity_unit_id,
      quantity_units: []
    };
  },
  watch: {
    prop_name(newVal) {
      this.working_file_name = newVal;
      this.saved_name = newVal;
    },
    prop_is_included(newVal) {
      this.is_included = newVal;
    },
    prop_qty(newVal) {
      this.qty = newVal;
    },
    prop_quantity_unit_id(newVal) {
      this.quantity_unit_id = newVal;
    }
  },
  created() {
    this.fill_qty_units();
  },
  mounted() {
    this.$nextTick(() => { defaultInput(); });
  },
  methods: {
    onRemove() {
      this.$confirm(
        'This will permanently delete the file and the associated stocks & tasks. Continue?',
        'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      ).then(() => {
        this.$http.delete(`/working_files/${this.id}`).then(() => {
          this.$message({
            type: 'success',
            message: 'Deleted successfully',
          });
          EventBus.$emit('reloadCategoryWorkingFiles');
        });
      });

    },
    onWorkingFileNameChange(newName) {
      updateName(this, this.id, newName).then(({data})=>{
        this.saved_name = newName;
        this.$message({
          type: 'success',
          message: data.message
        });
      }).catch(() => {
        this.working_file_name = this.saved_name;
      });
    },
    onChangedIncluded() {
      updateIsIncluded(this, this.id, this.is_included).then(({data})=> {
        this.$message({
          type: 'success',
          message: data.message
        });
        EventBus.$emit('reloadCategoryWorkingFiles');
        EventBus.$emit('reloadItems');
      }).catch(()=>{
        this.is_included = !this.is_included;
      });
    },
    onChangedQty() {
      updateQty(this, this.id, this.qty).then(({data})=> {
        this.$message({
          type: 'success',
          message: data.message
        });
      });
    },
    onChangedQuantityUnit() {
      updateQuantityUnit(this, this.id, this.quantity_unit_id).then(({data})=> {
        this.$message({
          type: 'success',
          message: data.message
        });
      });
    },
    fill_qty_units() {
      this.$http.get('/quantity_units').then(response => {
        this.quantity_units = response.data;
      });
    },
  }
};
</script>

<style scoped>
.head-info {
  margin-bottom: 10px;
}
.included_checkbox {
  margin-left: 10px;
}

</style>

<style>
.included_checkbox .el-checkbox__input.is-checked+.el-checkbox__label {
}
.page_titleinput .el-input__inner{
  background:white!important;
  font-weight:300;
  color:#333;
  margin-bottom:20px;
  font-size:20px;
  border-bottom: 2px solid #f3f3f3 !important;
  border: 0;
  border-radius: 0px;
}
</style>