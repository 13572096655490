<template>
  <div class="kpi-gauge">
    <vue-speedometer
      :force-render="true"
      :max-value="max_value == null ? 100 : max_value"
      :width="250"
      :height="150"
      :value="value"
      :current-value-text="label"
      :custom-segment-stops="stops"
      :segment-colors="get_colors"
      text-color="#AAA"
      value-text-font-size="12"
      :needle-height-ratio="0.7"
    />

    <ul
      v-if="extra_infos && extra_infos.length > 0"
      class="list extra__info"
    >
      <li
        v-for="(info,index) in extra_infos"
        :key="index"
        class="list__item"
      >
        <div class="list__attribute">
          {{ info.attr }}:
        </div>
        <div class="list__attribute">
          {{ info.val }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import VueSpeedometer from 'vue-speedometer';
import Colors from '../../../assets/stylesheets/export_colors.scss';

export default {
  name: 'KPIGauge',
  components: { VueSpeedometer },
  props: {
    max_value: {
      type: Number,
      required: false,
      default: null
    },
    value: {
      type: Number,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    stops: {
      type: Array,
      required: true
    },
    colors: {
      type: Array,
      required: false,
      default: null
    },
    extra_infos: {
      type: Array,
      required: false,
      default: null
    }
  },
  computed: {
    get_colors() {
      return this.colors == null ? [Colors.redColor, Colors.orangeColor, Colors.yellowColor, Colors.greenColor] : this.colors;
    }
  }
};
</script>

<style lang="scss" scoped>
.extra__info {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 0px !important;
  padding-left: 15px;
}

.kpi-gauge {
  margin-top: 5px;
  margin-bottom: 5px;
}
.list__attribute {
  padding: 0 0 0 2px !important;;
}
</style>