<template>
  <div>
    <div class="card  ">
      <div class="card-content  ">
        <span class="card-title">
          Staff hours
        </span>

        <el-date-picker
          v-model="selected_date_range"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          style="width: auto !important;"
          :picker-options="pickerOptions2"
          @change="fill_data"
        />
        <br>
        <div class="users_list_container">
          <table class="responsive-table striped">
            <thead>
              <tr>
                <th>Username</th>
                <th>Hours</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in users_data">
                <td>{{ user.user_name }}</td>
                <td>{{ (user.total_hours/60).toFixed(2) }}</td>
              </tr>
            </tbody>
            <tfoot
              v-if=" total_hours > 0"
              style="font-weight: bold;"
            >
              <tr style="border-top: 1px solid gray">
                <td>TOTAL</td>
                <td>{{ (total_hours/60).toFixed(2) }}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StaffHoursDashboard',
  data() {
    return {
      selected_date: Date.now(),
      selected_date_range: '',
      users_data: [],
      pickerOptions2: {
        shortcuts: [{
          text: 'Current month',
          onClick(picker) {
            const end = new Date();
            const start = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last week',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        },
        {
          text: 'Last month',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last 3 months',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last 6 months',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last year',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    };
  },
  computed: {
    current_date() {
      return new Date();
    },
    start_date() {
      return new Date(this.current_date.getFullYear(), this.current_date.getMonth(), 1);
    },
    end_date() {
      return new Date(this.current_date.getFullYear(), this.current_date.getMonth(), this.current_date.getDate());
    },
    total_hours: function () {
      return this.users_data.reduce(function (total, item) {
        return total + item.total_hours;
      }, 0);
    }
  },
  mounted() {
    this.selected_date_range = [this.start_date, this.end_date];
    this.fill_data();
  },
  created() {

  },
  methods: {
    round(num) {
      return Math.round(1);
    },
    fill_data() {
      let self = this;
      this.$http.post('/get_staff_hours_dashboard', {
        start_date: this.selected_date_range[0],
        end_date: this.selected_date_range[1]
      }).then(response => {
        if (response.data.status == 200) {

          this.users_data = response.data.data;

        }
      }
      );

    }
  }
};
</script>

<style scoped>
    .users_list_container {
        max-height: 340px;
        overflow: auto;
    }
</style>