<template>
  <div style="width: 100%">
    <el-date-picker
      v-model="selected_date_range"
      type="daterange"
      align="right"
      unlink-panels
      range-separator="To"
      start-placeholder="Start date"
      end-placeholder="End date"
      style="width: auto !important;"
      :picker-options="pickerOptions2"
      v-on:change="fill_data"
    >
    </el-date-picker>

    <el-table
      :data="non_conformance_report"
      :default-sort="{ prop: 'created_at', order: 'descending' }"
      style="width: 100%"
    >
      <el-table-column
        prop="created_at"
        label="Date"
        fixed
        sortable
        width="180"
      >
      </el-table-column>
      <el-table-column
        
        prop="job_id"
        label="Job Id"
        width="140"
      >
      <template slot-scope="scope">
         <a :href="'job_view?id=' + scope.row.job_id">{{ scope.row.job_id }}</a>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="Title" width="180">
      </el-table-column>
      <el-table-column prop="description" label="Description" width="240">
      </el-table-column>
      <el-table-column
        prop="created_by_user_name"
        label="Creator"
        sortable
        width="140"
      >
      </el-table-column>
      <el-table-column
        prop="assigned_user_name"
        label="Assigned to"
        sortable
        width="140"
      >
        <template slot-scope="scope">
          <i
            v-show="scope.row.approved"
            type="warning"
            style="color:orange; font-size:18px;"
            class="el-icon-star-on"
          >
          </i>
          {{ scope.row.assigned_user_name }}
        </template>
      </el-table-column>

      <el-table-column prop="resolved" label="Resolved" width="80">
        <template slot-scope="scope">
          <i
            v-show="scope.row.resolved"
            style="font-size:18px;color:green;"
            class="el-icon-success"
          ></i>
        </template>
      </el-table-column>
      <el-table-column prop="resolved_date" label="Date" width="140">
      </el-table-column>
      <el-table-column prop="resoved_user_name" label="Resolved by" width="120">
      </el-table-column>
      <el-table-column prop="user_reason" label="Reason" width="220">
      </el-table-column>

      <el-table-column fixed="right" width="100" label="Actions">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            content="Edit NCR"
            placement="left-start"
          >
            <el-button
              size="mini"
              type="info"
              icon="el-icon-edit"
              circle
              @click="edit_record(scope.$index, scope.row)"
            ></el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="Resolve"
            placement="left-start"
          >
            <el-button
              size="mini"
              type="info"
              icon="el-icon-check"
              circle
              @click="resolve_record(scope.$index, scope.row)"
            ></el-button
          ></el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="Approve to Assigned User KPI"
            placement="left-start"
          >
            <el-button
              size="mini"
              type="info"
              v-show="!scope.row.approved"
              icon="el-icon-star-off"
              circle
              @click="update_approved(scope.$index, scope.row)"
            ></el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="UnApprove to Assigned User KPI"
            placement="left-start"
          >
            <el-button
              size="mini"
              type="info"
              v-show="scope.row.approved"
              icon="el-icon-star-off"
              circle
              @click="remove_approved(scope.$index, scope.row)"
            ></el-button>
          </el-tooltip>

          <el-tooltip
            class="item"
            effect="dark"
            content="Delete NCR"
            placement="left-start"
          >
            <el-button
              size="mini"
              type="info"
              icon=" el-icon-error"
              circle
              @click="remove_record(scope.$index, scope.row)"
            ></el-button
          ></el-tooltip>
        </template>
      </el-table-column>
    </el-table>
 
    <!-- * NCR Lisitings -->

    <el-dialog title="NCR" :visible.sync="dialogVisible" class="popup">
      <span>
        <el-form ref="form" :model="ncr_record" label-width="120px">
          <el-form-item label="Task name" required>
            <el-select
              v-model="ncr_record.job_proposal_id"
              placeholder="Select"
            >
              <el-option
                v-for="item in job_proposals_list"
                :key="item.id"
                :label="item.job_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Assigned user" required>
            <el-select
              v-model="ncr_record.user_assigned_id"
              placeholder="Select"
            >
              <el-option
                v-for="item in crm_users_list"
                :key="item.id"
                :label="item.user_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Title" required>
            <el-input v-model="ncr_record.title"></el-input>
          </el-form-item>

          <el-form-item label="Description" required>
            <el-input
              type="textarea"
              v-model="ncr_record.decription"
            ></el-input>
          </el-form-item>

          <!-- <el-form-item label="Approved set to KPI"  >
            <el-switch v-model="ncr_record.approved"></el-switch>
           </el-form-item> -->

          <el-form-item label="Notify User Urgently">
            <el-switch v-model="ncr_record.notify" ></el-switch>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="onSubmit">Confirm</el-button>
      </span>
    </el-dialog>

    <!-- resolve box -->

    <el-dialog title="NCR" :visible.sync="dialogResolve" class="popup">
      <span>
        <el-form ref="form" :model="ncr_record" label-width="120px">
          <el-form-item label="Title">
            <!-- <el-input v-model="ncr_record.title"></el-input> -->
            {{ ncr_record.title }}
          </el-form-item>
          <el-form-item label="Description">
            {{ ncr_record.decription }}
            <!-- <el-input type="textarea" v-model="ncr_record.description" ></el-input>  --> </el-form-item
          ><br /><br />
          <el-form-item label="My Explanation">
            <el-input
              type="textarea"
              v-model="ncr_record.user_reason"
            ></el-input>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogResolve = false">Cancel</el-button>
        <el-button type="primary" @click="onResolve">Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { editor_store } from "store/modules/editor";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "NonConformanceReportDashboard",
  store: editor_store,
  components: {},
  data() {
    return {
      pickerOptions2: {
        shortcuts: [
          {
            text: "Current month",
            onClick(picker) {
              const end = new Date();
              const start = new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                1
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last week",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last month",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last 3 months",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last 6 months",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last year",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      selected_date_range: "",

      dialogVisible: false,
      dialogResolve: false,

      ncr_record: {
        id: "",
        title: "",
        job_proposal_id: "",
      },
    };
  },

  mounted() {
    this.selected_date_range = [this.start_date, this.end_date];
    this.fill_data();
  },
  computed: {
    ...mapGetters([
      "proposal",
      "proposal_id",
      "job_proposals_list",
      "crm_users_list",
      "non_conformance_report",
    ]),
    current_date() {
      return new Date();
    },
    start_date() {
      return new Date(
        this.current_date.getFullYear(),
        this.current_date.getMonth(),
        1
      );
    },
    end_date() {
      return new Date(
        this.current_date.getFullYear(),
        this.current_date.getMonth(),
        this.current_date.getDate()
      );
    },
  },
  methods: {
    ...mapActions([
      "set_non_conformance_report",
      "set_job_proposals_list",
      "set_crm_users_list",
    ]),
    clear_inputs() {
      this.ncr_record = {
        id: "",
        title: "",
        job_proposal_id: "",
      };
    },
    resolve_record(index, row) {
      this.ncr_record = Object.assign({}, row);
      this.dialogResolve = true;
    },
    onResolve() {
      let params = {
        non_conformance_reports: this.ncr_record,
      };
      this.ncr_record.resolved = true;

      this.$http
        .patch(`/resolve_ncr/${this.ncr_record.id}`, params)
        .then((response) => {
          this.clear_inputs();
          this.$message({
            message: "NCR has been Resolve",
            type: "success",
          });
          this.fill_data();
          this.dialogResolve = false;
        });
    },
    onSubmit() {
      let url = `/non_conformance_reports`;
      let type = "post";

      if (this.ncr_record.id != "") {
        url += `/${this.ncr_record.id}`;
        type = "patch";
      }

      let params = {
        non_conformance_reports: this.ncr_record,
      };
      this.$http[type](url, params).then((response) => {
        this.clear_inputs();
        this.$message({
          message: "NCR has been saved",
          type: "success",
        });
        this.fill_data();
        this.dialogVisible = false;
      });
    },
    edit_record(index, row) {
      this.ncr_record = Object.assign({}, row);
      this.dialogVisible = true;
      this.fill_selections(row.job_proposal_id);
    },
    fill_selections(jp_id) {
      this.$http.get(`/tasks_list_by_jp/${jp_id}`).then((response) => {
        this.set_job_proposals_list(response.data);
      });

      this.$http.get(`/users_active_list`).then((response) => {
        this.set_crm_users_list(response.data);
      });
    },
    remove_record(index, row) {
      this.$http.delete(`/non_conformance_reports/${row.id}`);
      this.$confirm(
        "This will permanently delete this record. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then((response) => {
        this.$message({
          message: "NCR has been deleted",
          type: "success",
        });

        this.$delete(this.non_conformance_report, index);
      });
    },
    update_approved(index, row) {
      let params = {
        non_conformance_reports: row,
      };
      row.approved = true;

      this.$http
        .patch(`/non_conformance_reports/${row.id}`, params)
        .then((response) => {
          this.clear_inputs();
          this.$message({
            message: "NCR has been Updated",
            type: "success",
          });
          this.fill_data();
          this.dialogResolve = false;
        });
    },
    remove_approved(index, row) {
      let params = {
        non_conformance_reports: row,
      };
      row.approved = false;

      this.$http
        .patch(`/non_conformance_reports/${row.id}`, params)
        .then((response) => {
          this.clear_inputs();
          this.$message({
            message: "NCR has been Updated",
            type: "success",
          });
          this.fill_data();
          this.dialogResolve = false;
        });
    },

    formatter(row, column) {
      return row.address;
    },
    fill_data() {
      this.$http
        .post(`/ncr_full_list`, {
          start_date: this.selected_date_range[0],
          end_date: this.selected_date_range[1],
        })
        .then((response) => {
          this.set_non_conformance_report(
            response.data.non_conformance_reports
          );
        });
    },
  },
  watch: {},
};
</script>
<style></style>
