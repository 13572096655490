<template>
  <div>
    <ul>
      <li
          v-for="(mailbox, index) in mailboxes"
          :key="index"
          style="line-height: 44px;"
      >
        <div
            :id="mailbox"
            style="display: inline-block;width: 200px;"
            @dragover.prevent
            @drop.stop.prevent="drop"
        >
          <el-link
              :underline="false"
              style="width: 300px;display: inline;"
              :href="'/mailbox#/inbox/' + mailbox"
              type="primary"
          >
            {{ mailbox }}
          </el-link>
        </div>
        <div style="float: right;display:inline;margin-right: 5px;">
          <el-tooltip placement="top">
            <div slot="content">
              rename mailbox
            </div>
            <el-button
                size="mini"
                icon="el-icon-edit"
                circle
                @click="rename_mailbox(mailbox)"
            />
          </el-tooltip>
          <el-tooltip placement="top">
            <div slot="content">
              delete mailbox and emails
            </div>
            <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete"
                circle
                @click="set_mailbox(mailbox)"
            />
          </el-tooltip>
        </div>
      </li>
    </ul>

    <el-dialog
        title="DELETE MAILBOX FOLDER"
        :visible.sync="dialog_visible"
        width="100%"
        :before-close="handle_close"
    >
      <span>Are you sure you want delete this folder and emails?</span>
      <span
          slot="footer"
          class="dialog-footer"
      >
        <el-button @click="dialog_visible = false">Cancel</el-button>
        <el-button
            type="primary"
            @click="delete_mailbox(),dialog_visible = false"
        >Confirm</el-button>
      </span>
    </el-dialog>
    <br>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'ListMailboxes',
  data() {
    return {
      dialog_visible: false,
      mailbox: ''
    };
  },
  computed: {
    ...mapGetters(['mailboxes'])
  },
  created() {
    this.load_mailboxes();
  },
  methods: {
    ...mapActions(['load_mailboxes', 'move_email']),
    set_mailbox(mailbox) {
      this.mailbox = mailbox;
      this.dialog_visible = true;
    },
    drop(event) {
      event.preventDefault();
      let id = event.currentTarget.id;
      let message = event.dataTransfer.getData('text').split('O_u_O');
      this.move_email({
        message_id: message[0],
        folder_name: id,
        current_mailbox: message[1],
        index: message[2],
        message: 'Email moved to ' + id,
      });

      // this.$http
      //   .post("/move_email", {
      //     message_id: message[0],
      //     folder_name: id,
      //     current_mailbox: message[1],
      //     index: message[2]
      //   })
      //   .then(
      //     response => {
      //       this.$message({
      //         message: "Email moved to " + id,
      //         type: "success"
      //       });
      //     },
      //     function(response) {}
      //   );
      event.dataTransfer.clearData();
    },
    rename_mailbox(value) {
      let old_name = value;
      this.$prompt('Folder name', 'Rename Mailbox', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        inputPattern: /^[A-Z][-a-zA-Z0-9@\.+_ ]+$/,
        inputErrorMessage: 'Invalid Name'
      })
          .then(({value}) => {
            let name = value;
            this.$http
                .post('/rename_mailbox', {old_name: old_name, new_name: name})
                .then(
                    response => {
                      this.$message({
                        message: 'Folder renamed',
                        type: 'success'
                      });
                      this.load_mailboxes();
                      window.location.href = '/mailbox#/inbox/' + value;
                    },
                    function (response) {
                    }
                );
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: 'Input canceled'
            });
          });
    },

    delete_mailbox() {
      if (this.mailbox.length > 1) {
        this.$http.post('/delete_mailbox', {foldername: this.mailbox}).then(
            response => {
              this.$message({
                message: 'Folder deleted',
                type: 'success'
              });
              this.load_mailboxes();
              window.location.href = '/mailbox#/inbox';
            },
            function (response) {
            }
        );
      }
    },
    handle_close(done) {
      this.$confirm('Are you sure to close this dialog?')
          .then(_ => {
            done();
          })
          .catch(_ => {
          });
    }
  }
};
</script>

<style scoped>
</style>