<template>
  <el-card class="box-card">
    <div slot="header">
      <WorkingFile  v-on:changedTotal="changed_total" :working_file="working_file"/>
    </div>
    <StockItemsList ref="stock_items_list" v-show="stock_items.length > 0" :items="stock_items"/>
    <TaskItemsList ref="task_items_list" v-show="task_items.length > 0" :items="task_items"/>
    <POItemsList ref="po_items_list" v-show="po_items.length > 0" :items="po_items"/>
  </el-card>
</template>
<script>

import WorkingFile from "./WorkingFile";
import StockItemsList from "./StockItemsList";
import TaskItemsList from "./TaskItemsList";
import POItemsList from "./POItemsList";

export default {
  name: "VariationLists",
  props: [ "working_file", "stock_items", "task_items", "po_items"],
  components: {
    WorkingFile,
    StockItemsList,
    TaskItemsList,
    POItemsList
  },

  methods: {
    changed_total() {
      this.$emit("changedTotal");
    },

    total_sale_price() {
      if(this.working_file.matched_quote > 0) {
        return this.working_file.matched_quote;
      }
      return this.$refs.stock_items_list.sum_total_sales_price +
          this.$refs.task_items_list.sum_total_sales_price +
          this.$refs.po_items_list.sum_total_sales_price;
    },
  },

  computed: {
    total_cost() {
      return this.$refs.stock_items_list.sum_total_sales_cost +
             this.$refs.task_items_list.sum_total_sales_cost +
             this.$refs.po_items_list.sum_total_sales_cost;
    },
    stock_cost() {
      return this.$refs.stock_items_list.sum_total_sales_cost +
             this.$refs.po_items_list.sum_total_sales_cost;
    },
    labour_cost() {
      return this.$refs.task_items_list.sum_total_sales_cost;
    },
    total_quote_price() {
      return this.$refs.stock_items_list.sum_quote_price +
             this.$refs.task_items_list.sum_quote_price +
             this.$refs.po_items_list.sum_quote_price;
    },
    variation_price() {
      return this.$refs.stock_items_list.sum_variation_price +
             this.$refs.task_items_list.sum_variation_price;
    }
  }
}
</script>
