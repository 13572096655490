<template>
  <div class="btn_container">
    <div>
      <el-input
        v-model="invoice_number"
        class="input__width inputdiv"
        placeholder="Assign invoice no."
      />
      <el-input
        v-model="payment_amount"
        class="input__width inputdiv"
        placeholder="Amount"
      />

      <el-button
        type="success"
        size="mini"
        @click="onMakePayment"
      >
        +
      </el-button>
    </div>
    <div
      v-if="error"
      class="error"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
import {EventBus} from '../../../../../utilities/EventBus';
import {addPayment} from '../../../../../api/master_payment';
export default {
  name: 'AddPayment',
  props: {
    account_id: {
      type: String,
      required: true
    },
    master_payment: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      invoice_number: '',
      payment_amount: '',
      error: null
    };
  },
  methods: {
    onMakePayment() {
      this.error = null;
      let invoice_number = this.invoice_number.replace('4000','');
      addPayment(this,this.master_payment.id,invoice_number,this.payment_amount)
        .then((response)=>{
          this.$message({
            type: 'success',
            message: response.body.message
          });
          EventBus.$emit('refreshAllInvoice');
        })
        .catch((error)=>{
          this.error = error.body.details;
        });
    },
  },
};
</script>


<style lang='scss' scoped>
.input__width {
  width: 42%;
}

.btn_container {
  min-height: 66px;
}
.error {
  color: red;
}
</style>
