<template>
  <div>
    <el-form
      ref="jobs_settings"
      v-loading="loading"
      :model="jobs_settings"
      label-position="left"
      label-width="250px"
    >
      <el-row>
        <h3>
          <b>JOBS SETTINGS</b>
          <hr>
        </h3>
      </el-row>
      <el-row :glutter="20">
        <el-col :span="12">
          <el-row>
            <el-col :span="24">
              <el-form-item
                label="Max overlap"
                prop="max_overlap"
                required
              >
                <el-input
                  v-model="jobs_settings.max_overlap"
                  size="mini"
                  autocomplete="off"
                  style="max-width: 100px"
                />
                minutes
              </el-form-item>
              <el-form-item
                label="Show overdue popup"
                prop="show_overdue_popup"
              >
                <el-checkbox
                  v-model="jobs_settings.show_overdue_popup"
                  size="mini"
                />
              </el-form-item>
              <el-form-item
                label="Max scheduled search"
                prop="max_scheduled_search"
                required
              >
                <el-input
                  v-model="jobs_settings.max_scheduled_search"
                  size="mini"
                  autocomplete="off"
                  style="max-width: 100px"
                />
                months
              </el-form-item>
              <el-form-item
                label="Set proposal to urgent after"
                prop="active_urgent_days"
                required
              >
                <el-input
                  v-model="jobs_settings.active_urgent_days"
                  size="mini"
                  autocomplete="off"
                  style="max-width: 100px"
                />
                days. 
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <hr>
          <el-form-item>
            <el-button
              class="right"
              type="primary"
              @click="submit('jobs_settings')"
            >
              Submit
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-row>
      <h3><b>View Options</b><hr></h3>
    </el-row>
    <job-settings-view-options />
  </div>
</template>
<script>
import JobSettingsViewOptions from "./JobSettingsViewOptions.vue";

export default {
  name: 'JobsSettings',
  components: {JobSettingsViewOptions},
  data() {
    return {
      loading: false,
      jobs_settings: { id: '', max_overlap: 15, show_overdue_popup: true },
    };
  },
  mounted() {
    this.fill_data();
    this.set_default_input();
  },
  methods: {
    set_default_input(mills = 300) {
      let _this = this;
      setTimeout(function() {
        Array.from(document.querySelectorAll('.el-input__inner')).forEach(
          function(e, i) {
            e.classList.add('browser-default');
            if (e.querySelector('input') != null)
              e.querySelector('input').className += ' browser-default';
          }
        );
      }, mills);
    },
    fill_data() {
      let _this = this;
      this.$http.get('/jobs_settings/0').then((response) => {
        _this.jobs_settings = response.data;
      });
    },
    submit(form_name) {
      let _this = this;

      this.$refs[form_name].validate((valid) => {
        if (valid) {
          _this.loading = true;
          this.$http
            .patch('/jobs_settings/' + _this.jobs_settings.id, {
              jobs_settings: _this.jobs_settings,
            })
            .then(
              (response) => {
                _this.loading = false;
                if (response.data.status == 200) {
                  this.$message({
                    message: 'Record has been saved.',
                    type: 'success',
                  });
                } else {
                  _this.$message.error('Oops, this is a error message.');
                }
              },
              function(response) {
                _this.loading = false;
                _this.$message.error('Oops, this is a error message.');
              }
            );
        } else {
          return false;
        }
      });
    },
  },
};
</script>
