<template>
  <div id="earnings">
    <gross-payments
      :crm_user_id="crm_user_id"
      :pay_period_start="pay_period_start"
      :pay_period_end="pay_period_end"
    />
    <el-collapse>
      <el-collapse-item title="Employee Timesheet">
        <el-table
          ref="timesheet"
          :data="earnings.timesheet"
          stripe
          style="width: 100%"
          show-summary
        >
          <el-table-column
            prop="date"
            label="Date"
          />
          <el-table-column
            prop="check_in"
            label="Check in"
            width="180"
          />
          <el-table-column
            prop="check_out"
            label="Check out"
            width="180"
          />
          <el-table-column
            label="Break duration"
            width="180"
          >
            <template #default="scope">
              <span>{{ scope.row.break_duration }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="total_day_hours"
            label="Total hours"
            width="180"
          />
        </el-table>
      </el-collapse-item>
      <el-collapse-item title="Employee Requests">
        <el-table
          ref="employee_requests"
          :data="earnings.employee_requests"
          stripe
          style="width: 100%"
          :summary-method="total_cost"
          show-summary
        >
          <el-table-column
            prop="requestable_type"
            label="Category"
            width="180"
          />
          <el-table-column
            prop="type"
            label="Type"
            width="180"
          />
          <el-table-column
            prop="request.content"
            label="Description"
          />
          <el-table-column
            prop="request.value"
            label="Hours in decimal"
            width="180"
          />
          <el-table-column
            label="Pay rate"
            width="180"
          >
            <template #default="scope">
              <Money :amount="scope.row.pay_rate" />
            </template>
          </el-table-column>
          <el-table-column
            prop="total"
            label="Total"
            width="180"
          >
            <template #default="scope">
              <Money :amount="scope.row.total" />
            </template>
          </el-table-column>
        </el-table>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
import Money from '../../shared/Money';
import{pay_run_earnings} from '../../../api/pay_runs';
import GrossPayments from './GrossPayments';
export default {
  name: 'Earnings',
  components: {Money, GrossPayments},
  props: {
    crm_user_id: {
      type: String,
      required: true
    },
    pay_period_start: {
      type: String,
      required: true
    },
    pay_period_end: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      earnings: []
    };
  },
  watch: {
    crm_user_id(){
    }
  },
  mounted() {
  },
  created() {
    this.pay_run_earnings();
  },
  methods: {
    pay_run_earnings(){
      pay_run_earnings(this, this.crm_user_id, this.pay_period_start, this.pay_period_end).then((response)=> {
        this.earnings = response.data;
      });
    },
    total_cost(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Total';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = '$' + values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        } else {
          sums[index] = '';
        }
      });

      return sums;
    }
  }
};
</script>
<style scoped>

</style>
