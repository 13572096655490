<template>
  <div>    
    <el-form
      ref="form"
      label-width="100px"
    >      
      <el-form-item
        label="Show grid"
        size="mini"
      >
        <el-checkbox
          v-model="grid_settings_visible"
          name="type"
        />
      </el-form-item>

      <el-form-item
        label="Paper size"
        size="mini"
      >
        <el-select
          v-model="page_settings.size"
          style="width: 100px"
          @change="size_changed"
        >
          <el-option
            v-for="size in paper_sizes_list"
            :key="size.value"
            :label="size.label"
            :value="size.value"
          >
            <span style="float: left">{{ size.label }}</span>
            <span
              style="float: right; color: #8492a6; font-size: 13px"
            >{{ size.value == '' ? '' : '('+size.value+' mm)' }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="Width"
        size="mini"
      >
        <el-input
          v-model="page_settings_width"
          type="number"
          style="width: 100px"
          size="mini"
          class="browser-default"
          :disabled="page_settings.size != '' "
        />
      </el-form-item>
      <el-form-item
        label="Height"
        size="mini"
      >
        <el-input
          v-model="page_settings_height"
          type="number"
          style="width: 100px"
          :disabled="page_settings.size != '' "
        />
      </el-form-item>
      <el-form-item
        label="Bleed"
        size="mini"
      >
        <el-input
          v-model="page_settings_margin"
          type="number"
          style="width: 100px"
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { editor_store } from 'store/modules/editor';

export default {
  name: 'PageSettings',
  store: editor_store,
  data() {
    return {
      // size: "210x297",
      // width: "210",
      // height: "297",
      // mm_to_px: 3.7795275591,
      paper_sizes_list: [
        { label: 'A0', value: '841x1189' },
        { label: 'A1', value: '594x841' },
        { label: 'A2', value: '420x594' },
        { label: 'A3', value: '297x420' },
        { label: 'A4', value: '210x297' },
        { label: 'A5', value: '148x210' },
        { label: 'A6', value: '105x148' },
        { label: 'A7', value: '74x105' },
        { label: 'Custom', value: '' }
      ]
    };
  },
  methods: {
    ...mapActions(['set_page_size', 'set_grid_visibility', 'set_page_margin']),
    size_changed(e) {
      if (e == '') {
      } else {
        this.set_page_size(e);
      }
    }
  },
  mounted() {
    Array.from(document.querySelectorAll('input.el-input__inner')).forEach(
      function(e, i) {
        e.classList.add('browser-default');
      }
    );
  },
  computed: {
    ...mapGetters(['page_settings', 'grid_settings']),
    page_settings_width: {
      get: function() {
        return this.page_settings.width;
      },
      set: function(newWidth) {
        this.set_page_size(newWidth + 'x' + this.page_settings.height);
      }
    },
    page_settings_height: {
      get: function() {
        return this.page_settings.height;
      },
      set: function(newHeight) {
        this.set_page_size(this.page_settings.width + 'x' + newHeight);
      }
    },
    page_settings_margin: {
      get: function() {
        return this.page_settings.margin;
      },
      set: function(newMargin) {
        this.set_page_margin(newMargin);
      }
    },
    grid_settings_visible: {
      get: function() {
        return this.grid_settings.visible;
      },
      set: function(visible) {
        this.set_grid_visibility(visible);
      }
    }
  }
};
</script>