<template>
  <div style="width: 100%">
    <el-button
      type="text"
      style="color:green"
      @click="dialogVisible = true"
    >
      CREATE NEW
      <i class="el-icon-news" />
    </el-button>
    <!--- NCR Lisitings -->
    <div
      v-for="(ncr, index) in non_conformance_report"
      :key="ncr.id"
      style="padding:20px;border:1px solid #f3f3f3;margin-bottom:5px;float:left;width:100%; margin-bottom:20px;"
    >
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="24"
        :xl="24"
        style="margin-bottom:10px;"
      >
        <el-button
          v-show="proposal.complete_job_allowed"
          type="danger"
          size="small"
          icon="el-icon-delete"
          circle
          class="right"
          @click="remove_record(ncr, index)"
        />
        <el-button
          v-show="
            proposal.current_crmuser_id == ncr.created_by_id &&
              !proposal.complete_job_allowed
          "
          type="danger"
          size="small"
          icon="el-icon-delete"
          circle
          class="right"
          @click="remove_record(ncr, index)"
        />

        <i
          v-show="ncr.resolved"
          class="el-icon-success"
          style="color:green; font-size:20px;"
        />
        <el-button
          v-show="proposal.complete_job_allowed && !ncr.resolved"
          style="margin-left:0px!important;"
          icon="el-icon-edit"
          circle
          @click="edit_record(ncr)"
        />
        <el-button
          v-show="
            proposal.current_crmuser_id == ncr.created_by_id &&
              !proposal.complete_job_allowed &&
              !ncr.resolved
          "
          style="margin-left:0px!important;"
          class="editBttn"
          icon="el-icon-edit"
          circle
          @click="edit_record(ncr)"
        />

        <span
          style=" margin-right:10px; margin-left:10px;"
        ><b>Task:</b> {{ ncr.task_name }}
        </span>
        <span
          style="margin-right:10px"
        ><b>Issued at:</b> {{ ncr.created_at }}
        </span>
        <span
          style="margin-right:10px"
        ><b>Created By: </b>{{ ncr.created_by_user_name }}
        </span>
        <span
          style="margin-right:10px"
        ><b>Assigned To:</b><span style="color:#333"> {{ ncr.assigned_user_name }}</span>
        </span>
        <i
          v-show="ncr.notify && !ncr.resolved"
          class="el-icon-close-notification"
          style="color:white; background:green; border-radius:20px;"
        />
        <i
          v-show="!ncr.notify && !ncr.resolved"
          class="el-icon-close-notification"
          style="color:white; background:grey; border-radius:20px;"
        />
      </el-col>
      <hr>
      <el-col
        :xs="24"
        :sm="24"
        :md="10"
        :lg="10"
        :xl="10"
        class="notepad"
      >
        <span style=""><b>Title:</b>{{ ncr.title }} </span> <br>
        <span style=""><b>Description:</b> {{ ncr.decription }} </span>
      </el-col>
      <el-col
        v-show="ncr.resolved_date"
        :xs="24"
        :sm="24"
        :md="10"
        :lg="10"
        :xl="10"
        class="resolvedticket"
      >
        <span style=""><b>Date:</b> {{ ncr.resolved_date }} </span><br>
        <span
          style=""
        ><b>Explanation:</b> {{ ncr.user_reason }}
          <br>
          <b>Resolved By:</b> {{ ncr.resoved_user_name }}
        </span>
      </el-col>

      <el-col
        :xs="24"
        :sm="24"
        :md="2"
        :lg="2"
        :xl="2"
      >
        <el-button
          v-show="!ncr.resolved"
          style="background:#8be06e; color:#fff"
          round
          @click="resolve_record(ncr)"
        >
          RESOLVE
        </el-button>
        <el-button
          v-show="
            proposal.current_crmuser_id == ncr.user_resolved_id &&
              !proposal.complete_job_allowed
          "
          style="background:grey; color:#fff"
          round
          @click="resolve_record(ncr)"
        >
          EDIT RESPONSE
        </el-button>
      </el-col>
    </div>

    <!-- * NCR Lisitings -->

    <el-dialog
      title="NCR"
      :visible.sync="dialogVisible"
      class="popup"
    >
      <span>
        <el-form
          ref="form"
          :model="ncr_record"
          label-width="120px"
        >
          <el-form-item
            label="Task name"
            required
          >
            <el-select
              v-model="ncr_record.job_proposal_id"
              placeholder="Select"
            >
              <el-option
                v-for="item in job_proposals_list"
                :key="item.id"
                :label="item.job_name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item
            label="Assigned user"
            required
          >
            <el-select
              v-model="ncr_record.user_assigned_id"
              placeholder="Select"
            >
              <el-option
                v-for="item in crm_users_list"
                :key="item.id"
                :label="item.user_name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item
            label="Title"
            required
          >
            <el-input v-model="ncr_record.title" />
          </el-form-item>

          <el-form-item
            label="Description"
            required
          >
            <el-input
              v-model="ncr_record.decription"
              type="textarea"
            />
          </el-form-item>

          <!-- <el-form-item label="Approved set to KPI"  >
            <el-switch v-model="ncr_record.approved"></el-switch>
           </el-form-item> -->

          <el-form-item label="Notify User Urgently">
            <el-switch v-model="ncr_record.notify" />
          </el-form-item>
        </el-form>
      </span>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="onSubmit"
        >Confirm</el-button>
      </span>
    </el-dialog>

    <!-- resolve box -->

    <el-dialog
      title="NCR"
      :visible.sync="dialogResolve"
      class="popup"
    >
      <span>
        <el-form
          ref="form"
          :model="ncr_record"
          label-width="120px"
        >
          <el-form-item label="Title">
            <!-- <el-input v-model="ncr_record.title"></el-input> -->
            {{ ncr_record.title }}
          </el-form-item>
          <el-form-item label="Description">
            {{ ncr_record.decription }}
            <!-- <el-input type="textarea" v-model="ncr_record.description" ></el-input>  --> </el-form-item><br><br>
          <el-form-item label="My Explanation">
            <el-input
              v-model="ncr_record.user_reason"
              type="textarea"
            />
          </el-form-item>
        </el-form>
      </span>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogResolve = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="onResolve"
        >Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { editor_store } from 'store/modules/editor';
import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
  name: 'NonConformance',
  store: editor_store,
  components: {},
  data() {
    return {
      dialogVisible: false,
      dialogResolve: false,

      ncr_record: {
        id: '',
        title: '',
        job_proposal_id: '',
      },
    };
  },
  computed: {
    ...mapGetters([
      'proposal',
      'proposal_id',
      'job_proposals_list',
      'crm_users_list',
      'non_conformance_report',
    ]),
    resolved_time() {
      return new Date(
        this.today.getFullYear(),
        this.today.getMonth(),
        this.today.getDate(),
        17,
        0,
        0,
        0
      );
    },
  },
  mounted() {
    let _this = this;
    setTimeout(function() {
      _this.fill_data();
    }, 400);
  },
  methods: {
    ...mapActions(['set_non_conformance_report']),
    remove_record(ncr, index) {
      this.$http.delete(`/non_conformance_reports/${ncr.id}`);
      this.$confirm(
        'This will permanently delete this record. Continue?',
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      ).then((response) => {
        this.$message({
          message: 'NCR has been deleted',
          type: 'success',
        });

        this.$delete(this.non_conformance_report, index);
      });
    },

    edit_record(ncr) {
      this.ncr_record = Object.assign({}, ncr);
      this.dialogVisible = true;
    },
    resolve_record(ncr) {
      this.ncr_record = Object.assign({}, ncr);
      this.dialogResolve = true;
    },
    fill_data() {
      this.$http
        .get(`/non_conformance_reports?proposal_id=${this.proposal_id}`)
        .then((response) => {
          this.set_non_conformance_report(response.data);
        });
    },
    clear_inputs() {
      this.ncr_record = {
        id: '',
        title: '',
        job_proposal_id: '',
      };
    },
    onResolve() {
      let params = {
        non_conformance_reports: this.ncr_record,
      };
      this.ncr_record.resolved = true;

      this.$http
        .patch(`/resolve_ncr/${this.ncr_record.id}`, params)
        .then((response) => {
          this.clear_inputs();
          this.$message({
            message: 'NCR has been Resolve',
            type: 'success',
          });
          this.fill_data();
          this.dialogResolve = false;
        });
    },
    onSubmit() {
      let url = '/non_conformance_reports';
      let type = 'post';

      if (this.ncr_record.id != '') {
        url += `/${this.ncr_record.id}`;
        type = 'patch';
      }

      let params = {
        non_conformance_reports: this.ncr_record,
      };
      this.$http[type](url, params).then((response) => {
        this.clear_inputs();
        this.$message({
          message: 'NCR has been saved',
          type: 'success',
        });
        this.fill_data();
        this.dialogVisible = false;
      });
    },
  },
  watch: {},
};
</script>
<style>
.notepad {
  background-color: rgba(253, 255, 157, 0.822);
  border: 3px solid #fff;
  padding: 10px;
}
.resolvedticket {
  border: 3px solid #fff;
  border-left: 1px dotted #333;
  padding: 10px;
}
.popup .el-dialog {
  width: 50% !important;
}
@media only screen and (max-width: 600px) {
  .popup .el-dialog {
    width: 95% !important;
  }
}
</style>
