<template>
  <div style="width: 100%">
    <div
      v-for="spent in task.time_spent_history"
      :key="spent.id"
      style="padding:20px;border:1px solid #f3f3f3;margin-bottom:5px;"
    >
      <span style="margin-right:10px;"> {{ spent.updated_at }} </span>
      <span style="margin-left:10px;margin-right:10px;">
        <Time :is_hours="is_hours" :minutes="spent.time_in_minutes " />
      </span>
        
      <span style=" margin-left:10px;margin-right:10px;">  <b>{{ spent.user }}  </b>  </span>

      <span
        v-show=" spent.description"
        style=" margin-left:10px;"
      >
        Note: <b>{{ spent.description }} </b></span> 
    </div>
    <!-- <el-table :data="task.time_spent_history" style="width: 100%">
      <el-table-column prop="user" label="User" width="180"> </el-table-column>
      <el-table-column prop="time_in_minutes" label="Minutes" width="180">
      </el-table-column>
      <el-table-column prop="description" label="Description" width="180">
      </el-table-column>
      <el-table-column prop="updated_at" label="Updated at" width="180">
      </el-table-column>
    </el-table> -->
  </div>
</template>
<script>
import { editor_store } from 'store/modules/editor';
import { mapGetters } from 'vuex';
import Time from '../../../shared/Time';

export default {
  name: 'TaskHistoryLog',
  components: {Time},
  store: editor_store,
  props: ['task'],
  computed: {
    ...mapGetters([
      'is_hours'
    ]),
  }
};
</script>

