<template>
  <div id="tender_requests">
    <div>
      <el-button
        @click="request_visible = true"
        size="mini"
        icon="el-icon-folder-add"
        style="float:left;"
      >NEW REQUEST</el-button>
      <invite-supplier :branch_id="proposal.branch_id"></invite-supplier>
    </div>
    <el-dialog
      title
      :visible.sync="request_visible"
      :before-close="handle_close_request"
      @open="get_working_files"
      width="30%"
    >
      <div class="header">ADD REQUEST</div>

      <el-divider></el-divider>

      <el-form :model="tender_request" status-icon size="mini" ref="request_form">
        <el-checkbox-group v-model="working_file_list">
          <el-checkbox
            v-for="working_file in working_files"
            :key="working_file.id"
            :label="working_file.id"
          >{{ working_file.name }}</el-checkbox>
        </el-checkbox-group>
        <el-divider></el-divider>
        <el-switch
          v-model="tender_request.charge_po"
          :active-value="1"
          :inactive-value="0"
          active-text="Charge PO"
          inactive-text="Stock Item"
        ></el-switch>
        <el-divider></el-divider>

        <el-form-item label="Name:" prop="name">
          <el-input type="text" v-model="tender_request.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item v-if="tender_request.charge_po == 0" label="Width:" prop="width">
          <el-input type="text" v-model="tender_request.width" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item v-if="tender_request.charge_po == 0" label="Height:" prop="height">
          <el-input type="text" v-model="tender_request.height" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item v-if="tender_request.charge_po == 0" label="Thickness:" prop="thickness">
          <el-input type="text" v-model="tender_request.thickness" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            type="textarea"
            v-model="tender_request.description"
            placeholder="Product Description"
            :autosize="{ minRows: 4, maxRows: 4}"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="submit_form('request_item_form')">SAVE</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-table
      :data="tender_requests"
      style="width: 100%"
      stripe
      ref="requests_table"
      :default-sort="{prop: 'date', order: 'ascending'}"
      @selection-change="handleSelectionChange"
      @expand-change="get_submitted_stock_items"
    >
      <el-table-column width="20" type="expand">
        <template slot-scope="scope">
          <div class="header-open">Tender submitted items</div>

          <el-table
            :data="submitted_stock_items.filter( ({id}) => id == scope.row.id)"
            style="width: 560px"
            :show-header="show_header"
            stripe
          >
            <el-table-column width="150" label="status">
              <template slot-scope="scope">
                <el-switch
                  @change="accepted(submitted_stock_items[scope.$index])"
                  v-model="submitted_stock_items[scope.$index].accepted"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="pick"
                  inactive-text="-"
                ></el-switch>
              </template>
            </el-table-column>
            <el-table-column prop="supplier_name" label="tender"></el-table-column>
            <el-table-column prop="stock_item_name" label="name"></el-table-column>
            <el-table-column prop="stock_item_price" label="price"></el-table-column>
            <el-table-column label="item">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="primary"
                  icon="el-icon-edit"
                  @click="get_tender_response(scope.row.tender_request_supplier_id)"
                  circle
                ></el-button>
              </template>
            </el-table-column>
            <el-table-column label="message">
              <template slot-scope="scope">
                <el-button
                  @click="write_message = true, 
                  tender_request_id = scope.row.id,
                  supplier_id = scope.row.supplier_id, 
                  tender_request_supplier_id = scope.row.tender_request_supplier_id, 
                  get_messages(scope.row.tender_request_supplier_id,scope.row.supplier_id,scope.row.id )"
                  icon="el-icon-chat-round"
                  size="mini"
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column type="index" label="#" sortable width="40"></el-table-column>
      <el-table-column width="300">
        <template slot-scope="scope">
          <el-switch
            @change="active(tender_requests[scope.$index])"
            v-model="tender_requests[scope.$index].active"
            :active-value="1"
            :inactive-value="0"
            active-text="active"
            inactive-text=" "
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="name" sortable width="200"></el-table-column>
      <el-table-column prop="created_at" label="created at" sortable width="200"></el-table-column>
      <el-table-column width="50">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit"
            @click="request_visible = true, get_request(scope.row.id)"
            circle
          ></el-button>
        </template>
      </el-table-column>
      <el-table-column width="50">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="deleteRow(scope.$index,scope.row.id)"
            circle
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="Select Suppliers"
      :visible.sync="send_to_supplier_visible"
      :before-close="handle_close"
      width="30%"
    >
      <el-transfer
        style="text-align: left; display: inline-block"
        filterable
        :filter-method="supplier_filter"
        filter-placeholder="Suppliers"
        v-model="tenders"
        :data="suppliers"
        :titles="titles"
      ></el-transfer>
      <br />
      <el-button
        @click="create_tender_requests"
        size="small"
        icon="el-icon-s-promotion"
      >Send Request to Tenders</el-button>
    </el-dialog>

    <el-dialog title="Item" :visible.sync="item_dialog_visible" width="400px" left>
      <div v-for="(value,key) in item" :key="key">
        <el-row v-if="value != null">
          <el-col :span="12">
            <div class="grid-content">
              <strong>{{ humanize(key) }}:</strong>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content">{{ value }}</div>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
    <el-dialog
      title="Send Message"
      :visible.sync="write_message"
      width="40%"
      height="80%"
      :lock-scroll="true"
      :adaptive="true"
    >
      <el-form ref="message_form" :model="messageform" label-width="120px" label-position="top">
        <el-form-item>
          <el-input
            type="textarea"
            :autosize="{ minRows: 6, maxRows: 20}"
            placeholder="Please write a message"
            v-model="messageform.message"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="on_message_submit">submit</el-button>
          <el-button size="small" @click="on_message_cancel">cancel</el-button>
        </el-form-item>
      </el-form>
      <br />
      <div class="block">
        <el-timeline>
          <div v-for="message in messages" :key="message.id">
            <el-timeline-item :timestamp="message_header(message)" placement="top">
              <el-card>
                <p>{{ message.message }}</p>
              </el-card>
            </el-timeline-item>
          </div>
        </el-timeline>
      </div>
    </el-dialog>
    <br />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { editor_store } from "store/modules/editor";
import AddStockItem from "./tenders/AddStockItem.vue";
import { ProposalStockItemMixin } from "mixins/ProposalStockItemMixin.js";
import Filebrowser from "../filemanager/Filebrowser.vue";
import InviteSupplier from "../shared/InviteSupplier.vue";

export default {
  store: editor_store,
  mixins: [ProposalStockItemMixin],
  components: { AddStockItem, Filebrowser, InviteSupplier },
  data() {
    return {
      id: null,
      tender_requests: [],
      send_to_supplier_visible: false,
      accepted_visible: false,
      titles: ["choose supplier", "submit to tender"],
      suppliers: [],
      tenders: [],
      show_header: true,
      submitted_stock_items: [],
      item: [],
      stock_item: [],
      item_dialog_visible: false,
      tender_request: {
        id: null,
        name: "",
        description: "",
        width: "",
        height: "",
        thickness: "",
        charge_po: 1
      },
      request_visible: false,
      working_files: [],
      working_file_list: [],
      write_message: false,
      messageform: { message: "" },
      messages: [],
      tender_request_supplier_id: null,
      supplier_id: null
    };
  },
  created() {
    this.get_requests();
  },
  mounted() {},
  computed: {
    ...mapGetters(["proposal"])
  },
  watch: {},
  methods: {
    ...mapActions(["set_stock_items_list", "load_charge_pos_list"]),
    get_request(id) {
      return new Promise((resolve, reject) => {
        this.$http.get("/tenderiq/get_tender_request/" + id).then(
          response => {
            resolve(
              ((this.tender_request = response.data.tender_request),
              (this.working_file_list = response.data.working_file_list))
            );
          },
          function(response) {
            reject(response);
          }
        );
      });
    },
    create_tender_request() {
      return new Promise((resolve, reject) => {
        this.tender_request.active = 0;
        let params = {
          proposal_id: this.proposal.id,
          working_file_list: this.working_file_list,
          tender_request: this.tender_request
        };
        this.$http.post("/tenderiq/create_tender_request", params).then(
          response => {
            this.$message({
              message: "Tender request created",
              type: "success"
            });
            this.clear();
            this.get_requests();
            resolve(resolve);
          },
          function(response) {
            this.$message({
              message: response.data.errors,
              type: "error"
            });
            reject(response);
          }
        );
      });
    },
    update_tender_request(id) {
      return new Promise((resolve, reject) => {
        this.tender_request.proposal_id = this.proposal.id;
        this.tender_request.working_file_ids = this.working_file_list;
        let params = {
          tender_request: this.tender_request
        };
        this.$http.patch("/tenderiq/update_tender_request", params).then(
          response => {
            this.$message({
              message: "Tender Request updated",
              type: "success"
            });
            this.clear();
          },
          function(response) {
            this.$message({
              message: response.data.errors,
              type: "error"
            });
            reject(response);
          }
        );
      });
    },
    submit_form(tender_request_form) {
      if (this.tender_request.id == null) {
        this.create_tender_request();
      } else {
        this.update_tender_request();
      }
    },
    handle_close_request(done) {
      this.clear();
      done();
    },
    clear() {
      this.request_visible = false;
      this.tender_request = {};
      this.working_files = [];
      this.working_file_list = [];
    },
    get_working_files() {
      return new Promise((resolve, reject) => {
        this.$http.get("/tenderiq/working_files/" + this.proposal.id).then(
          response => {
            resolve((this.working_files = response.data.files));
          },
          function(response) {
            this.$message({
              message: response.data.errors,
              type: "error"
            });
            reject(response);
          }
        );
      });
    },
    humanize(str) {
      var i,
        frags = str.split("_");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }

      return frags.join(" ");
    },
    get_tender_response(tender_request_supplier_id) {
      return new Promise((resolve, reject) => {
        this.$http
          .get("/tenderiq/tender_response/" + tender_request_supplier_id)
          .then(
            response => {
              resolve(
                ((this.item = response.data.item),
                (this.item_dialog_visible = true))
              );
            },
            function(response) {
              this.$message({
                message: response.data.errors,
                type: "error"
              });
              reject(response);
            }
          );
      });
    },
    get_stock_item(stock_item_id) {
      return new Promise((resolve, reject) => {
        this.$http.get("/tenderiq/stock_item/" + stock_item_id).then(
          response => {
            resolve(
              ((this.stock_item = response.data.stock_item),
              (this.stock_item_dialog_visible = true))
            );
          },
          function(response) {
            this.$message({
              message: response.data.errors,
              type: "error"
            });
            reject(response);
          }
        );
      });
    },
    get_submitted_stock_items(row, expanded_rows) {
      let current_index = this.submitted_stock_items.findIndex(
        c => c.id == row.id
      );

      if (this.submitted_stock_items[current_index] == undefined) {
        return new Promise((resolve, reject) => {
          this.$http.get("/tenderiq/submitted_stock_items/" + row.id).then(
            response => {
              resolve(
                response.data.submitted_stock_items.forEach((el, idx) => {
                  this.submitted_stock_items.push(el);
                })
              );
            },
            function(response) {
              reject(response);
            }
          );
        });
      }
    },
    create_tender_requests() {
      return new Promise((resolve, reject) => {
        let params = {
          tenders: this.tenders,
          id: this.id
        };
        this.$http.post("/tenderiq/create_tender_requests", params).then(
          response => {
            if (response.data.already_sent == "no") {
              this.$message({
                message: "Tender Requests created and email sent.",
                type: "success"
              });
            } else {
              this.$message({
                message: "Tender Requests email already sent.",
                type: "success"
              });
            }
          },
          function(response) {
            reject(response);
          }
        );
      });
    },
    get_tender_request_ids() {
      return new Promise((resolve, reject) => {
        this.$http.get("/tenderiq/get_tender_requests_ids/" + this.id).then(
          response => {
            this.tenders = [];
            resolve((this.tenders = response.data.requests));
          },
          function(response) {
            reject(response);
          }
        );
      });
    },
    get_suppliers() {
      return new Promise((resolve, reject) => {
        this.$http.get("/suppliers").then(
          response => {
            resolve(
              response.data.forEach((el, index) => {
                this.suppliers.push({
                  key: el.id,
                  label: el.name,
                  initial: el.name
                });
              })
            );
          },
          function(response) {
            reject(response);
          }
        );
      });
    },
    supplier_filter(query, item) {
      return item.initial.toLowerCase().indexOf(query.toLowerCase()) > -1;
    },
    get_requests() {
      return new Promise((resolve, reject) => {
        this.$http.get("/tenderiq/get_requests/" + this.proposal.id).then(
          response => {
            resolve((this.tender_requests = response.data.tender_requests));
          },
          function(response) {
            reject(response);
          }
        );
      });
    },
    active(data) {
      return new Promise((resolve, reject) => {
        let params = {
          id: data.id,
          active: data.active
        };
        this.$http.post("/tenderiq/change_request_active", params).then(
          response => {
            this.$message({
              message: data.active == "1" ? "Approved" : "Unapproved",
              type: "success"
            });
            if (data.active == "1") {
              this.id = data.id;
              this.send_to_supplier_visible = true;
              this.set_default_input();
              this.get_suppliers();
              this.get_tender_request_ids();
            }
          },
          function(response) {
            reject(response);
          }
        );
      });
    },
    charge_po(data) {
      // return new Promise((resolve, reject) => {
      //   let params = {
      //     id: data.id,
      //     charge_po: data.charge_po
      //   };
      //   this.$http.post("/tenderiq/change_to_charge_po", params).then(
      //     response => {
      //       this.$message({
      //         message: data.charge_po == "1" ? "Charge PO" : "Stock Item",
      //         type: "success"
      //       });
      //     },
      //     function(response) {
      //       reject(response);
      //     }
      //   );
      // });
    },
    accepted(data) {
      return new Promise((resolve, reject) => {
        let params = {
          id: data.tender_request_supplier_id,
          accepted: data.accepted,
          charge_po: data.charge_po
        };
        this.$http
          .post("/tenderiq/change_request_supplier_accepted", params)
          .then(
            response => {
              let m = "";
              if (data.accepted == "1" && data.charge_po == "1") {
                m = "Added to purchase order";
                this.load_charge_pos_list();
              }
              if (data.accepted == "0" && data.charge_po == "1") {
                m = "Removed from purchase order";
              }
              if (data.accepted == "1" && data.charge_po == "0") {
                m = "Stock item added";
                this.load_stock_items_list(this.proposal.id).then(response => {
                  this.set_stock_items_list(response);
                });
              }
              if (data.accepted == "0" && data.charge_po == "0") {
                m = "Stock item deleted";
              }
              this.$message({
                message: m,
                type: "success"
              });
           

              // if (data.accepted == "1") {
              //   this.id = data.id;
              //   this.accepted_visible = true;

              //   if (data.stock_item_price != null) {
              //     if (data.charge_po == "0") {
              //       let item = {stock_item: item};
              //       this.$http
              //         .post("/tenderiq/create_stock_item", item)
              //         .then(response => {
              //           this.load_stock_items_list(this.proposal.id).then(
              //             response => {
              //               this.set_stock_items_list(response);
              //             }
              //           );
              //         });
              //     }

              //     if (data.charge_po == "1") {
              //       console.log(data);
              //       let stock = {
              //         purchase_order: {
              //           is_open_quote: true,
              //           proposal_id: this.proposal.id,
              //           estimated_value: parseFloat(data.stock_item_price),
              //           actual_value: parseFloat(data.stock_item_price),
              //           category_supplier_id: data.category_supplier_id,
              //           supplier_id: data.tender_request_supplier_id,
              //           branch_id: this.proposal.branch_id,
              //           description: data.description
              //         }
              //       };

              //       this.$http
              //         .post("/purchase_orders", stock)
              //         .then(response => {
              //           this.load_stock_items_list(this.proposal.id).then(
              //             response => {
              //               this.set_stock_items_list(response);
              //             }
              //           );
              //         });
              //     }
              //   }
              // } else {
              //   let proposal_stock_item = {
              //     stock_item_id: data.stock_item_id,
              //     proposal_id: this.proposal.id
              //   };
              //   this.$http
              //     .post(
              //       "/tenderiq/delete_proposal_stock_item",
              //       proposal_stock_item
              //     )
              //     .then(response => {
              //       this.load_stock_items_list(this.proposal.id).then(
              //         response => {
              //           this.set_stock_items_list(response);
              //         }
              //       );
              //     });
              // }
            },
            function(response) {
               this.$message({
                message: response.data.errors,
                type: "error"
              });
              reject(response);
            }
          );
      });
    },
    deleteRow(index, id) {
      this.$delete(this.tender_requests, index);
      this.delete_file(id);
    },
    delete_file(id) {
      this.$http.delete("/tenderiq/delete_request/" + id).then(response => {
        this.$message({
          message: "Tender Request deleted",
          type: "success"
        });
      });
    },
    handle_close(done) {
      this.tenders = [];
      this.suppliers = [];
      done();
    },
    handleSelectionChange(selection) {},
    set_default_input() {
      let _this = this;
      setTimeout(() => {
        Array.from(
          document.querySelectorAll("#tender_requests .el-input__inner")
        ).forEach(function(e, i) {
          e.classList.add("browser-default");
          if (e.querySelector("input") != null)
            e.querySelector("input").className += " browser-default";
        });
      }, 300);
    },
    message_header(message) {
      return message.user_name + ", " + message.created_at;
    },
    get_messages(tender_request_supplier_id, supplier_id, tender_request_id) {
      return new Promise((resolve, reject) => {
        let params = {
          tender_request_supplier_id: tender_request_supplier_id,
          supplier_id: supplier_id,
          tender_request_id: tender_request_id
        };
        this.$http.post("/tenderiq/get_messages", params).then(
          response => {
            resolve((this.messages = response.data.messages));
          },
          function(response) {
            reject(response);
          }
        );
      });
    },
    on_message_submit() {
      let params = {
        tender_request_supplier_id: this.tender_request_supplier_id,
        tender_request_id: this.tender_request_id,
        supplier_id: this.supplier_id,
        message: this.messageform.message
      };
      this.$http.post("/tenderiq/send_tender_message", params).then(
        response => {
          this.$message({
            message: "Message sent successfully",
            type: "success"
          });
          this.get_messages(
            this.tender_request_supplier_id,
            this.supplier_id,
            this.tender_request_id
          );
          (this.messageform.message = ""), (this.write_message = true);
        },
        response => {
          this.$message({
            message: "Couldn't sent message. " + response.data.errors,
            type: "error"
          });
        }
      );
    },
    on_message_cancel() {
      this.write_message = false;
    }
  }
};
</script>
<style>
#tender_requests .el-transfer-panel {
  width: 300px;
}
</style>