<template>
  <div>
    <CreditNotesEditDialog
      v-if="showCNDialog == true"
      :show_cn_dialog.sync="showCNDialog"
      :credit_note_id="credit_note_id"
    />
    <ColumnHeader
      :total="credit_notes_total"
      :open="{
        label: 'OPEN',
        total: credit_notes_open_total
      }"
      :page="page"
      :total_page="total_page"
      @prev="onPrev"
      @next="onNext"
    >
      CREDIT NOTES
    </ColumnHeader>
    <ColumnElement
      v-for="(credit_note, index) in credit_notes"
      :key="credit_note.id"
    >
      <i
        :class="info_class(credit_note)"
        :style="info_style(credit_note)"
      />
      <span>4000{{ credit_note.id }}</span>
      <span>(4000{{ credit_note.parent_invoice }})</span>
      <span
        class="total-price"
      >
        - TOTAL: <span :class="total_class(credit_note)">${{ credit_note.total }}</span>
      </span>
      <span class="datediv">{{ credit_note.date }}</span>
      <template #right>
        <el-button
          icon="el-icon-info"
          circle
          @click="onShowDialog(credit_note.id)"
        />
        <el-button
          v-if="extension_btns[index] == false"
          type="success"
          size="mini"
          icon="el-icon-circle-plus"
          circle
          @click="onExtension(index,true)"
        />
        <el-button
          v-else
          type="type"
          size="mini"
          icon="el-icon-circle-close"
          circle
          @click="onExtension(index,false)"
        />
      </template>
      <template #second-line>
        <el-link
          v-if="credit_note.sale_id"
          :href="`/sales/${credit_note.sale_id}/edit`"
          target="_blank"
          class="link second-line__item"
          style="color:#039be5"
        >
          Sale
        </el-link>
      </template>
      <template #extend>
        <ExtensionButtons
          v-if="extension_btns[index]==true"
          :id="credit_note.id"
        />
      </template>
    </ColumnElement>
  </div>
</template>

<script>

import CreditNotesEditDialog from './CreditNotesEditDialog';
import ExtensionButtons from '../ExtensionButtons';
import ColumnHeader from '../ColumnHeader';
import ColumnElement from '../ColumnElement';
import {BALANCE_STATUS} from '../../../status';

export default {
  name: 'CreditNotes',
  components: {ColumnElement, ColumnHeader, ExtensionButtons, CreditNotesEditDialog},
  props: {
    credit_notes: {
      type: Array,
      required: true,
    },
    credit_notes_total: {
      type: Number,
      required: true,
    },
    credit_notes_open_total: {
      type: Number,
      required: true,
    },
    total_page: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      invoice_number: '',
      showCNDialog: false,
      credit_note_id: '',
      extension_btns:[],
      page: 1
    };
  },
  computed: {
    info_class() {
      return (credit_note) => {
        if(credit_note.status === BALANCE_STATUS.CLOSED) return 'el-icon-notebook-1';

        return 'el-icon-notebook-2';
      };
    },
    info_style() {
      return (credit_note) => {
        if(credit_note.status === BALANCE_STATUS.OPEN) return 'color:blue';

        return '';
      };
    }
  },
  watch: {
    credit_notes() {
      this.extension_btns = [];
      this.credit_notes.forEach(()=>{
        this.extension_btns.push(false);
      });
    }
  },
  methods: {
    onShowDialog(credit_note_id) {
      this.credit_note_id = credit_note_id;
      this.showCNDialog = true;
    },
    onExtension(index, val) {
      this.$set(this.extension_btns, index, val);
    },
    total_class(credit_note) {
      return credit_note.status === BALANCE_STATUS.OPEN ? 'total__open' : null;
    },
    onPrev() {
      if(this.page === 1)
        return;

      this.page -= 1;
      this.emit_page_event();
    },
    onNext() {
      if(this.page === this.total_page)
        return;

      this.page += 1;
      this.emit_page_event();
    },
    emit_page_event(){
      this.$emit('pageChange', this.page);
    }
  }
};
</script>

<style lang='scss' scoped>
@import '../style/balancing';

.total__open {
  color: red;
}
</style>
