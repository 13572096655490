<template>
  <div id="my-time">
    <el-button
      type="success"
      size="mini"
      @click="add_time_dialog = true"
    >
      Add My Time
    </el-button>
    <el-dialog
      title="Add My Time"
      :visible.sync="add_time_dialog"
      width="50%"
      append-to-body
      :close-on-click-modal="false"
      @opened="set_default_input"
    >
      <span
        v-for="(jobs, working_file) in tasks.working_file_jobs"
        :key="working_file"
      >
        {{ working_file }}
        <el-divider />
        <div class="table-responsive">
          <table
            class="margin"
            style="overflow:scroll;min-width:500px"
          >
            <tr
              v-for="(job, index) in jobs"
              :key="index"
            >
              <td><b>{{ job.name }}</b> | {{ job.time_allocated }} minutes allocated | total added: {{ job.time_spent_sum }} minutes
                <span v-if="job.time_spents">
                  <p
                    v-for="time in job.time_spents"
                    :key="time.id"
                    class="space"
                  >
                    {{ time.updated_at }} -> {{ time.time_in_minutes }}
                  </p>
                </span>
                <br>

                <input
                  :id="`n${index}`"
                  class="el-input__inner"
                  type="number"
                >

                <el-button
                  size="mini"
                  type="primary"
                  @click="add_time_spent(job.id, `n${index}`)"
                >Add my time</el-button>

                <el-divider />
              </td>
            </tr>
          </table>
        </div>
      </span>
      <span
        slot="footer"
        class="dialog-footer"
      />
    </el-dialog>
  </div>
</template>
<script>

import {set_default_input} from '../../utilities/DefaultInput';
export default {
  name: 'AddTimeSpent',
  props: {
    proposal_id: {
      type: [String,Number],
      required: true
    }
  },
  data() {
    return {
      add_time_dialog: false,
      time_spent: '',
      tasks: []
    };
  },
  created() {
    this.get_user_tasks_for_job();
  },
  methods: {
    set_default_input,
    get_user_tasks_for_job(){
      this.$http.post('/api/user_tasks_for_job', {proposal_id: this.proposal_id}).then((response) => {
        console.log(response.data);
        this.tasks = response.data;
      }
      );
    },
    add_time_spent(job_proposal_id, index) {
      console.log(index);
      let time_spent = document.getElementById(index).value;
      console.log(time_spent);
      if(parseInt(time_spent) > -10000) {
        this.$http.post('/api/add_time_spent_user', {
          job_proposal_id: job_proposal_id,
          time_in_minutes: time_spent
        }).then(() => {
          this.get_user_tasks_for_job();
          this.$message({
            type: 'success',
            message: 'Time added.'
          });
        });
      }
    }

  }
};
</script>
<style scoped>
th, td {padding: 0px;}
.margin{ margin: 10px 20px}
.space{margin: 2px;padding-left: 10px;}
.el-input__inner { width: 100px; height: 30px;}
.table-responsive {    min-width: auto;
    overflow: scroll;}
 
</style>
