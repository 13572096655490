<template>
  <div
    v-show="visible"
    class="flashnotice"
  >
    <h3>{{ organization_details.urgent_message }}</h3>
  </div>
</template>

<script>
export default {
  name: 'FlashNotice',

  data() {
    return {
      organization_details: { id: '', name: '' },
      labelPosition: 'right',
      loading: false,
      eventTimestamp: null,
      visible: false,
    };
  },
  mounted() {
    this.fill_data();
  },
  methods: {
    fill_data() {
      let _this = this;
      this.$http.get('/organization_details').then((response) => {
        _this.organization_details = response.data;
        this.visible =
          new Date() >= new Date(response.body.urgent_date_start) &&
          new Date() <= new Date(response.body.urgent_date_end);
      });
    },
  },
};
</script>

<style>
.flashnotice {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: orange;
  
  z-index: 9999;
  left: 0;
}
.flashnotice h3{
  color:#000;
  text-align:center;
   
}
 
 
</style>
