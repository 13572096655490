<template>
  <div>
    <el-tooltip
      v-if="details.first_login == 1"
      content="requests"
      :open-delay="2000"
      placement="right"
      effect="dark"
    >
      <router-link to="/requests">
        <i class="el-icon-umbrella" />
      </router-link>
    </el-tooltip>
    <el-tooltip
      content="account details"
      :open-delay="2000"
      placement="right"
      effect="dark"
    >
      <router-link to="/details">
        <i class="el-icon-s-custom" />
      </router-link>
    </el-tooltip>
    <el-tooltip
      content="login details"
      :open-delay="2000"
      placement="right"
      effect="dark"
    >
      <router-link to="/login-details">
        <i class="el-icon-lollipop" />
      </router-link>
    </el-tooltip>
    <el-tooltip
      content="stock items"
      :open-delay="2000"
      placement="right"
      effect="dark"
    >
      <router-link to="/stock-items">
        <i class="el-icon-film" />
      </router-link>
    </el-tooltip>
    <el-tooltip
      content="add stock item"
      :open-delay="2000"
      placement="right"
      effect="dark"
    >
      <router-link to="/add-stock-item/0">
        <i class="el-icon-suitcase" />
      </router-link>
    </el-tooltip>
    <el-tooltip
      content="logout"
      :open-delay="2000"
      placement="right"
      effect="dark"
    >
      <a :href="logout">
        <i class="el-icon-close" />
      </a>
    </el-tooltip>
  </div>
</template>

<script>
import { mapGetters , mapMutations} from 'vuex';
export default {
  name: 'Navigation',
  computed: {
    ...mapGetters(['details']),
    ...mapMutations(['SET_FIRST_LOGIN']),
    logout() {
      return window.location.origin + '/tender/logout';
    }
  }
};
</script>
<style scoped>
  i {
  font-size: x-large !important;
  color: #00b4f0;
}

a {
  padding: 15.5px 20px;
  display: block;
}
.router-link-active {
  background: white;
  padding: 15.5px 20px;
}
</style>   