<template>
  <div>
    <el-tabs>
      <el-tab-pane label="User Targets">
        <UserKPITargets :crm_user_id="crm_user_id" />
      </el-tab-pane>
      <el-tab-pane label="User Upskilling/Goals">
        <UserUpskilling :crm_user_id="crm_user_id" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import UserKPITargets from './UserKPITargets.vue';
import UserUpskilling from './UserUpskilling.vue';

export default {
  name: 'CrmUserTargets',
  components: {
    UserKPITargets,
    UserUpskilling
  },
  props: {
    crm_user_id: {
      type: [String,Number],
      required: true
    }
  },
  data() {
    return {
      user_details: {}
    };
  },
  mounted() {
    this.fill_data();
  },
  methods: {
    fill_data() {
      this.$http.get(`/user_targets_data/${this.crm_user_id}`).then(response => {
        this.user_details = response.data;
      });
    }
  }
};
</script>
