<template>
  <el-dialog
    :visible.sync="show"
    width="95%"
    :close-on-click-modal="false"
    @closed="onCloseDialog"
  >
    <h2 class="text__center">
      EDIT INVOICE
    </h2>

    <el-row :gutter="30">
      <el-col
        :span="24"
        :md="{span: 12}"
      >
        <div class="container">
          <div class="sub-title">
            Update Invoice
          </div>
          <el-form
            id="invoice_edit_form"
            ref="form"
            :model="form"
            label-width="110px"
          >
            <el-form-item
              label="Number"
            >
              4000{{ invoice_id }}
            </el-form-item>
            <el-form-item label="Amount">
              <el-input
                v-model="form.amount"
                class="form__input"
              />
            </el-form-item>
            <el-form-item label="Total Price">
              <Money :amount="form.total_price" />
              <span>(GST included)</span>
            </el-form-item>
            <el-form-item label="Amount Paid">
              <Money :amount="form.amount_paid" />
            </el-form-item>
            <el-form-item label="Balance Due">
              <Money :amount="form.total_due" />
            </el-form-item>
            <el-form-item label="Status">
              <el-select
                v-model="form.status"
                class="form__input"
                placeholder="Select"
              >
                <el-option
                  v-for="item in status_options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="Date">
              <el-date-picker
                v-model="form.invoice_date"
                class="form__input"
                type="date"
                placeholder="Pick a day"
                format="dd/MM/yyyy"
              />
            </el-form-item>
            <el-form-item label="Description">
              <div class="description_editor">
                <jodit-editor
                  v-model="form.description"
                  :buttons="buttons"
                  :config="jodit_config"
                />
              </div>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                @click="onUpdate"
              >
                Update
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
      <el-col
        :span="24"
        :md="{span: 12}"
      >
        <div class="container">
          <div class="sub-title">
            Payments
          </div>
          <el-table
            :data="payments"
            style="width: 100%"
          >
            <el-table-column
              prop="payment_amount"
              label="Amount"
            />
            <el-table-column
              prop="payment_received"
              label="Payment Date"
            />
            <el-table-column
              label="Operations"
            >
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="danger"
                  @click="onPaymentDelete(scope.row.id)"
                >
                  Delete
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div>
            <el-input
              v-model="payment_amount"
              class="inputdiv payment__input"
              placeholder="INPUT AMOUNT TO BE PAID"
            />
            <el-button
              type="success"
              size="mini"
              @click="onMakePayment"
            >
              +
            </el-button>
          </div>
          <div
            v-show="errors"
            class="error"
          >
            {{ errors }}
          </div>
        </div>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import CommonJoditMixin from '../../../../../mixins/CommonJoditMixin';
import {editInvoice, updateInvoice} from '../../../../../api/invoice';
import {createMasterPaymentForInvoice} from '../../../../../api/master_payment';
import {deletePayment} from '../../../../../api/payment';
import {INVOICE_STATUS_OPTIONS} from '../../../status';
import Money from '../../../../shared/Money';
import {dateString, getToDay} from '../../../../../utilities/Date';
import {EventBus} from '../../../../../utilities/EventBus';
export default {
  name: 'InvoicesEditDialog',
  components: {Money},
  mixins: [CommonJoditMixin],
  props: {
    show_invoice_dialog: {
      type: Boolean,
      required: true
    },
    invoice_id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      show: this.show_invoice_dialog,
      form: {
        amount: '',
        total_price: '',
        total_due: '',
        amount_paid: '',
        invoice_date: '',
        status: '',
        description: '',
      },
      payments: [],
      payment_amount: '',
      status_options: INVOICE_STATUS_OPTIONS,
      errors: '',
    };
  },
  created() {
    this.load_invoice();
  },
  mounted() {
    this.$nextTick(() => {
      Array.from(document.querySelectorAll('#invoice_edit_form .el-input__inner')).forEach(
        function(e) {
          e.classList.add('browser-default');
          if (e.querySelector('input') != null)
            e.querySelector('input').className += ' browser-default';
        }
      );
    });
  },
  methods: {
    onCloseDialog() {
      this.$emit('update:show_invoice_dialog', false);
    },
    onUpdate() {
      updateInvoice(this, this.invoice_id, this.form.amount, this.form.status, dateString(this.form.invoice_date), this.form.description)
        .then(({data})=> {
          this.$message({
            type: 'success',
            message: data.message
          });
          this.load_invoice();
          EventBus.$emit('refreshAllInvoice');
        });
    },
    onMakePayment() {
      createMasterPaymentForInvoice(this, this.invoice_id, dateString(getToDay()), this.payment_amount)
        .then(({data})=>{
          this.$message({
            type: 'success',
            message: data.message
          });
          this.load_invoice();
          EventBus.$emit('refreshAllInvoice');
        })
        .catch(({data})=>{
          this.errors = data.details;
        });
    },
    onPaymentDelete(id) {
      deletePayment(this, id).then(({data})=>{
        this.$message({
          type: 'success',
          message: data.message
        });
        this.load_invoice();
        EventBus.$emit('refreshAllInvoice');
      });
    },
    close_dialog() {
      this.show = false;
    },
    load_invoice() {
      editInvoice(this,this.invoice_id).then(({data})=>{
        this.form.amount = data.amount;
        this.form.total_price = data.total_price;
        this.form.status = data.status;
        this.form.invoice_date = new Date(data.invoice_date);
        this.form.total_due = data.total_due;
        this.form.amount_paid = data.amount_paid;
        this.form.description = data.description;
        this.payments = data.payments;
      });
    }
  }
};
</script>
<style lang='scss'>
</style>
<style lang='scss' scoped>
.el-form-item {
  margin-bottom: 20px !important;
}

.container {
  margin-bottom: 15px;
  padding-left: 15px !important;
  padding-right: 15px !important;
  border: 1px dashed;
}

.form__input {
  width: 100% !important;
}
.payment__input {
  width: 80%;
}
.sub-title {
  text-align: center;
  font-weight: bold;
}

.error {
  color: red;
}
</style>
