var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding":"10px","overflow":"auto"}},[_c('el-row',[_c('el-col',{staticStyle:{"padding":"10px"},attrs:{"xs":24,"sm":24,"md":4,"lg":6,"xl":6}},[_c('span',{staticStyle:{"color":"rgb(70, 205, 52) !important","font-weight":"bold"}},[_vm._v("\n        STOCK ITEMS LIST\n      ")])])],1),_vm._v(" "),_c('table',{staticClass:"items_list_summary",staticStyle:{"border":"1px solid #333"},attrs:{"width":"100%"}},[_c('thead',[_c('tr',[_c('td',{staticStyle:{"min-width":"80px"}},[_vm._v("Category")]),_vm._v(" "),_c('td',{staticStyle:{"min-width":"100px"}},[_vm._v("Name")]),_vm._v(" "),_c('td',[_vm._v("Supplier")]),_vm._v(" "),_c('td',{staticStyle:{"min-width":"80px"}},[_vm._v("PO Details")]),_vm._v(" "),_c('td',[_vm._v("Billable")]),_vm._v(" "),_c('td',[_vm._v("Quantity")]),_vm._v(" "),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.proposal.approved == true || _vm.proposal.approved == 1),expression:"proposal.approved == true || proposal.approved == 1"}],staticStyle:{"min-width":"80px"}},[_vm._v("\n          Used Qty\n        ")]),_vm._v(" "),_c('td',{staticStyle:{"min-width":"80px"}},[_vm._v("Unit cost")]),_vm._v(" "),_c('td',{staticStyle:{"min-width":"80px"}},[_vm._v("Unit Sale price")]),_vm._v(" "),_c('td',{staticStyle:{"min-width":"80px"}},[_vm._v("Total Cost")]),_vm._v(" "),_c('td',{staticStyle:{"min-width":"80px"}},[_vm._v("Total Sale Price")]),_vm._v(" "),_c('td',[_vm._v("Action")])])]),_vm._v(" "),_c('tbody',[_vm._l((_vm.working_file_info.stock_items_list),function(stock){return _c('StockItemEntry',{key:stock.id,style:([
          (stock.request != undefined && stock.request.is_approved == 1) ||stock.mark_as_deleted == 1 ?
            {
              'text-decoration': 'line-through',
              'font-style:': 'italic',
            } : {},
        ]),attrs:{"stock":stock}})}),_vm._v(" "),_c('NewStockToSummaryEdit',{attrs:{"working_file_id":_vm.working_file_info.id}})],2),_vm._v(" "),_c('tfoot',[_c('tr',[_c('td',[_vm._v("Stock Total")]),_vm._v(" "),_c('td'),_vm._v(" "),_c('td'),_vm._v(" "),_c('td'),_vm._v(" "),_c('td'),_vm._v(" "),_c('td'),_vm._v(" "),_c('td'),_vm._v(" "),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.proposal.approved == true || _vm.proposal.approved == 1),expression:"proposal.approved == true || proposal.approved == 1"}]}),_vm._v(" "),_c('td'),_vm._v(" "),_c('td',[_vm._v("$"+_vm._s(_vm.stock_items_total_cost))]),_vm._v(" "),_c('td',[_vm._v("$"+_vm._s(_vm.stock_items_total_sale))]),_vm._v(" "),_c('td')])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }