<template>
  <div>
    <el-collapse>
      <el-collapse-item
        v-for="note in entries"
        :key="note.id"
        v-model="entries"
        :title="note.crm_user_name"
        :name="note.id"
      >
        <template slot="title">
          <span>
            {{ note.crm_user_name }}
            <i
              v-show="note.entry && note.entry.length > 0"
              class="el-icon-chat-line-round"
            />
          </span>
        </template>
        <div>
          <el-row>
            <el-col>
              <div
                class="margin-left"
                v-html="note.entry_truncated"
              />
              <el-button
                type="primary"
                size="mini"
                class="right"
                @click="show_dialog = true, current_note.entry = note.entry, current_note.id = note.id"
              >
                Edit / Read
              </el-button>
            </el-col>
          </el-row>
        </div>
        <div>
          <el-dialog
            title="Edit Personal Staff Note"
            :visible.sync="show_dialog"
            width="50%"
            append-to-body
            :close-on-click-modal="false"
          >
            <jodit-editor
              id="comments_editor"
              ref="comments_editor"
              v-model="current_note.entry"
              :config="jodit_config"
            />
            <span
              slot="footer"
              class="dialog-footer"
            >
              <el-button
                type="success"
                size="mini"
                @click="edit_entry(), show_dialog = false"
              >
                Save
              </el-button>
            </span>
          </el-dialog>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>

import {update_entry, entries} from '../../api/comments';

export default {
  name: 'PersonalStaffNotes',
  props: {
    proposal_id: {
      type: [String,Number],
      required: true
    }
  },
  data() {
    return {
      entries: [],
      show_dialog: false,
      current_note: {id: '', entry: '', note_truncated: ''},
      jodit_config : {
        enter: 'br',
      },
    };
  },
  created() {
    this.get_entries();
  },
  methods: {
    get_entries() {
      entries(this, this.proposal_id).then((response) => {
        this.entries = response.data;
      });
    },
    edit_entry() {
      update_entry(this, this.current_note.id, this.current_note.entry).then((response) => {
        this.$message({
          message: response.data.message,
          type: 'success',
        });
        this.get_entries();
      });
    }
  }
};
</script>
<style scoped>
.right{float:right;}
.margin-left{ margin-left: 10px;}
</style>