<template>
  <div>
    <el-button
      size="mini"
      icon="el-icon-folder-add"
      @click="dialog_visible = true"
    >
      CHANGE PASSWORD
    </el-button>
    <el-dialog
      title="Change Password"
      :visible.sync="dialog_visible"
      width="30%"
    >
      <el-form
        ref="email_form"
        status-icon
        :rules="rules"
        size="mini"
        :model="forgot"
        :inline-message="true"
      >
        <el-form-item
          label="Email:"
          prop="email"
        >
          <el-input
            v-model="forgot.email"
            type="text"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item
          label="Password:"
          prop="password"
        >
          <el-input
            v-model="forgot.password"
            type="text"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="mini"
            @click="submit_form('email_form')"
          >
            SEND
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'VerifyToken',
  data() {
    return {
      forgot: {email: null, password: null},
      dialog_visible: false,
      rules: {
        email: [
          {
            required: true,
            // eslint-disable-next-line no-useless-escape
            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            message: 'Please insert a email address',
            trigger: 'change'
          }
        ],
        password: [
          {
            required: true,
            // eslint-disable-next-line no-useless-escape
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{5,})/,
            message: 'Please insert a password. Min. 5 characters with at least one capital letter, a number and a special character.',
            trigger: 'change'
          }
        ]
      }
    };
  },
  created() {
  },

  methods: {
    send_password() {

      return new Promise((resolve, reject) => {
        let params = {
          email: this.forgot.email,
          password: this.forgot.password,
          token: window.location.pathname.split('/').pop()
        };
        this.$http.post('/clientiq/change_password', params).then(
          () => {
            false;
            this.$message({
              message:
                    'Form submitted. Please login.',
              type: 'success'
            });

            window.location.href = window.location.origin + '/client/login?email=' + this.forgot.email;
            resolve(resolve);
          },
          function (response) {
            this.$message({
              message: response.data.errors,
              type: 'error'
            });
            reject(response);
          }
        );
      });
    },
    submit_form(email_form) {
      this.$refs[email_form].validate(valid => {
        if (valid) {
          this.send_password();
        }
      });
    }
  }
};
</script>

<style scoped>
</style> 