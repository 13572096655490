<template>
  <div>
    <h2>Leads Report</h2>
    <el-divider />
    <p>Date Filter:</p>
    <el-date-picker
      v-model="date_selected"
      type="daterange"
      align="right"
      unlink-panels
      range-separator="To"
      start-placeholder="Start date"
      end-placeholder="End date"
      format="dd.MM.yyyy"
      value-format="dd.MM.yyyy"
      :picker-options="pickerOptions"
    />
    <el-button
      size="mini"
      type="primary"
      @click="search()"
    >
      Search
    </el-button>
    <el-button
      size="mini"
      type="primary"
      @click="lead_reports()"
    >
      List all leads
    </el-button>
    <el-row :gutter="20">
      <el-col :span="6">
        <p>User Filter:</p>
        <el-select
          v-model="selected_user"
          placeholder="All"
          size="small"
          style="width: 280px;"
          :clearable="true"
        >
          <el-option
            v-for="user in crm_users"
            :key="user.id"
            :label="user.user_name + ' ' + user.user_surname + '/' + user.title"
            :value="user.id"
          >
            <span style="float: left">{{ user.user_name }} {{ user.user_surname }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px;margin-left: 5px;">{{ user.title }}</span>
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <p>Quote Type Filter:</p>
        <el-select
          v-model="selected_status"
          placeholder="All"
          size="small"
          style="width: 280px;"
          :clearable="true"
        >
          <el-option
            v-for="status in statuses"
            :key="status"
            :label="status"
            :value="status"
          />
        </el-select>
      </el-col>
      <el-col :span="6">
        <p>Branch Filter:</p>
        <el-select
          v-model="selected_branch"
          placeholder="All"
          size="small"
          style="width: 280px;"
          :clearable="true"
        >
          <el-option
            v-for="branch in branches"
            :key="branch.id"
            :label="branch.name"
            :value="branch.id"
          >
            <span style="float: left">{{ branch.name }}</span>
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row
      justify="end"
      type="flex"
    >
      <el-button
        size="mini"
        type="primary"
        align="right"
        @click="spreadsheet_lead_reports()"
      >
        Pull Spreadsheet Report
      </el-button>
    </el-row>
    <el-table
      :data="leads"
      style="width: 100%"
      stripe
      show-summary
      :summary-method="get_summaries"
    >
      <el-table-column
        prop="updated_at"
        label="Date"
      />
      <el-table-column
        prop="status"
        label="Quote Type"
      />
      <el-table-column
        prop="quote_number"
        label="Quote Number"
      >
        <template slot-scope="scope">
          <el-link
            :href="`/edit_proposal?id=${scope.row.quote_number}`"
            target="_blank"
          >
            <span style="color: #039be5;">{{ scope.row.quote_number }}</span>
          </el-link>
        </template>
      </el-table-column>
      <el-table-column
        prop="branch"
        label="Branch"
      />
      <el-table-column
        prop="job_type"
        label="Job Type"
      />
      <el-table-column
        prop="account"
        label="Account"
      />
      <el-table-column
        prop="job_name"
        label="Job Name"
      >
        <template slot-scope="scope">
          <el-link
            :href="`/job_view?id=${scope.row.quote_number}`"
            target="_blank"
          >
            <span style="color: #039be5;">{{ scope.row.job_name }}</span>
          </el-link>
        </template>
      </el-table-column>

      <el-table-column
        prop="creator"
        label="Creator"
      />
      <el-table-column
        prop="minutes"
        label="Minutes"
      />
      <el-table-column
        prop="price"
        label="Price"
      >
        <template #default="scope">
          <Money :amount="scope.row.price" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {set_default_input} from '../../utilities/DefaultInput';
import Money from '../shared/Money.vue';
import {search_leads, download_spreadsheet_lead_reports} from '../../api/leads.js';

export default {
  name: 'LeadReport',
  components: {Money},
  data() {
    return {
      leads: [],
      crm_users: [],
      selected_user: '',
      branches: [],
      selected_branch: '',
      statuses: ['Pending/Open', 'Converted'],
      selected_status: '',
      pickerOptions: {
        shortcuts: [{
          text: 'Last week',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last month',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last 3 months',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      date_selected: ''
    };
  },
  created(){
    this.lead_reports();
    this.get_crm_users();
    this.get_branches();
  },
  mounted(){
    this.set_default_input();
  },
  methods: {
    set_default_input,
    search(){
      search_leads(this).then((response)=> {
        this.leads = response.data;
      });
    },
    spreadsheet_lead_reports(){

      download_spreadsheet_lead_reports(this, this.search.param).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        let file_name = response.headers.get('content-disposition').split('filename=')[1].split(';')[0];
        file_name = file_name.replaceAll('"','');
        link.href = url;
        link.setAttribute('download', file_name || 'Leads_Report.xls');
        document.body.appendChild(link);
        link.click();
      }).catch((e)=>{
        console.log(e);
      });
    },
    lead_reports() {
      this.$http.get('api/lead_reports').then((response) => {
        this.leads = response.data;
      });
    },
    get_crm_users() {
      this.$http.get('/crm_users').then((response) => {
        this.crm_users = response.data;
      });
    },
    get_branches() {
      this.$http.get('/branches').then((response) => {
        this.branches = response.data;
      });
    },
    get_summaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Total Cost';
          return;
        }
        // Quote Number
        if (index === 1) {return;}

        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          let sign = '$';
          // Minutes
          if (index === 7) { sign = '';}

          sums[9] =  sign + values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return (prev + curr);
            } else {
              return  prev;
            }
          }, 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        } else {
          sums[index] = ' ';
        }
      });

      return sums;
    }
  }
};
</script>

<style lang='scss' scoped>

</style>
