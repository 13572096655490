export const BALANCE_STATUS = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  PARTIAL: 'PARTIAL'
};


export const INVOICE_STATUS_OPTIONS = [{
  value: BALANCE_STATUS.OPEN,
  label: BALANCE_STATUS.OPEN
}, {
  value: BALANCE_STATUS.CLOSED,
  label: BALANCE_STATUS.CLOSED
}];
