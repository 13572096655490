<template>
  <div id="stock-item-job-view-search">
    <el-button
      size="small"
      type="info"
      plain
      @click="show_stock_search_dialog = true"
    >
      + Add Stock Item
    </el-button>
    <el-dialog
      :visible.sync="show_stock_search_dialog"
      title="Add Stock Items"
      width="56%"
      @opened="default_input()"
    >
      <el-row :gutter="20">
        <el-col :span="4">
          <label for="stock_quantity">Quantity: </label>
          <el-input-number
            id="stock_quantity"
            v-model="stock_quantity"
            type="text"
            width="100"
            name="stock_quantity"
            size="small"
            :min="0"
          />
        </el-col>
        <el-col :span="4">
          <label for="sale_price">Unit Sale price: </label>
          <el-input-number
            id="sale_price"
            v-model="sale_price"
            width="100"
            type="text"
            name="sale_price"
            size="small"
            :min="0"
          />
        </el-col>
        <el-col>
          Link:
          <el-button
            v-if="selected_stock_item_id"
            type="text"
            @click="stock_item_link()"
          >
            Stock Item {{ internal_name }}
          </el-button>
        </el-col>
      </el-row>
      <el-divider />
      <el-row>
        <el-select
          v-model="selected_category_supplier_id"
          size="mini"
          placeholder="Select category"
          :disabled="by_category == false"
          filterable
          @change="category_supplier_changed"
        >
          <el-option
            v-for="item in category_suppliers"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>

        <el-checkbox v-model="by_category">
          By category
        </el-checkbox>
      </el-row>
      <br>
      <el-row>
        <el-select
          v-model="selected_stock_item_id"
          filterable
          remote
          style="width:100%"
          reserve-keyword
          placeholder="Please enter a keyword"
          :remote-method="search_stock_items"
          :loading="loading"
          @change="selected_stock_change"
        >
          <el-option
            v-for="item in stock_items"
            :key="item.id"
            style="min-width: 300px"
            :label="item.name_with_specs"
            :value="item.id"
          />
        </el-select>
        <el-checkbox v-model="search_all_stocks">
          search all stock items
        </el-checkbox>
      </el-row>
      <el-divider />
      <el-row>
        <el-button
          size="mini"
          type="primary"
          style="float:right;"
          @click="save_stock_item()"
        >
          Add stock item
        </el-button>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'StockItemJobViewSearch',
  props: {
    proposal_id: {
      type: [String, Number],
      required: true,
      default: 0
    },
    branch_id: {
      type: [String, Number],
      required: true,
      default: 1
    }
  },
  data() {
    return {
      show_stock_search_dialog: false,
      stock_quantity: 1,
      sale_price: 0,
      by_category: false,
      category_suppliers: [],
      selected_category_supplier_id: '',
      selected_stock_item_id: '',
      stock_items: [],
      search_all_stocks: false,
      loading: false,
      internal_name: ''
    };
  },
  created(){
    this.get_category_suppliers_not_hidden();
  },
  methods: {
    category_supplier_changed(){
      this.$http.post('/favorite_stocks_by_category', {
        category_supplier_id: this.selected_category_supplier_id,
        branch_id: this.branch_id
      }).then((response) => {
        let stock_items = response.data;
        stock_items.forEach(function(obj) {
          obj.name_with_specs = obj.name;
          delete obj.name;
        });
        this.stock_items = stock_items;
        this.selected_stock_item_id = stock_items[0].id;
      });
    },
    save_stock_item() {
      if (this.selected_stock_item_id != null && this.stock_quantity > 0) {
        this.$http.post('/save_stock_item', {
          id: this.proposal_id,
          stock_item_id: this.selected_stock_item_id,
          proposal_id: this.proposal_id,
          stock_quantity: this.stock_quantity,
          sale_price: this.sale_price,
        }).then(() => {
          this.$message({
            message: 'Stock item has been added',
            type: 'success',
          });
          this.$emit('get_stock_item_list');
        });
      }
    },
    selected_stock_change(stock_item_id){
      let stock_item = this.stock_items.filter(
        (item) => item.id == stock_item_id
      )[0];
      this.sale_price = stock_item.sale_price;
      this.internal_name = stock_item.internal_name;
    },
    search_stock_items(query) {
      if (query !== '' && query.trim().length > 2) {
        let filter = { term: query };
        if (this.by_category == true && this.selected_category_supplier_id != ''){
          filter['category_supplier_id'] = this.selected_category_supplier_id;
        }
        filter['search_all_stocks'] = this.search_all_stocks;
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.$http.post('/search_stocks_by_params', filter)
            .then((response) => {
              this.stock_items = response.body.data;
            });
        }, 200);
      } else {
        this.stock_items_results = [];
        this.loading = false;
      }
    },
    get_category_suppliers_not_hidden() {
      this.$http.get('/category_suppliers_not_hidden').then(response => {
        this.category_suppliers = response.data;
      });
    },
    stock_item_link() {
      window.open(`/stock_items/${this.selected_stock_item_id}/edit`, '_blank');
    },
    default_input(){
      Array.from(document.querySelectorAll('#stock-item-job-view-search .el-input__inner')).forEach(
        function(e) {
          e.classList.add('browser-default');
          if (e.querySelector('input') != null)
            e.querySelector('input').className += ' browser-default';
        }
      );
    }
  },
};
</script>
<style scoped>
#stock-item-job-view-search{
  display: inline;
}
label {
  font-size: 1.0rem;
  color: #4c4c4c;
}
</style>