<template>
  <div id="salary">
    <p>{{ earnings.employment_pay_type }}: <Money :amount="earnings.gross_earnings_per_year" /> - {{ earnings.working_hours_per_week }} Hours / Week </p>
    <p>Gross: <Money :amount="earnings.pay_rate" /> * {{ earnings.working_hours_per_day }} Hours * {{ earnings.working_days }} Days = <Money :amount="earnings.gross_pay" /> </p>
  </div>
</template>
<script>
import Money from '../../shared/Money';
import {salary_fixed_amount} from '../../../api/employment_gross_payments';

export default {
  name: 'Salary',
  components: {Money},
  props: {
    crm_user_id: {
      type: String,
      required: true
    },
    pay_period_start: {
      type: String,
      required: true
    },
    pay_period_end: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      earnings: []
    };
  },
  created() {
    this.salary_fixed_amount();
  },
  methods: {
    salary_fixed_amount(){
      salary_fixed_amount(this, this.crm_user_id, this.pay_period_start, this.pay_period_end).then((response)=> {
        this.earnings = response.data;
      });
    }
  }
};
</script>
<style scoped>
#salary {margin-left: 35px;}
</style>