<template>
  <div>
    <el-button-group class="align__right">
      <el-button
        size="small"
        type="primary"
        :disabled="navi_disable"
        @click="preMonth"
      >
        Previous Month
      </el-button>
      <el-button
        size="small"
        type="primary"
        :disabled="navi_disable"
        @click="nextMonth"
      >
        Next Month
      </el-button>
    </el-button-group>

    <FullCalendar
      ref="fullCalendar"
      class="kpisCalendar"
      default-view="dayGridMonth"
      :header="{
        left: '',
        center: 'title',
        right: '',
      }"
      :plugins="calendarPlugins"
      :events="calendarEvents"
      @eventRender="eventRender"
    />
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import Colors from '../../../assets/stylesheets/export_colors.scss';

export default {
  name: 'KPIsCalendar',
  components: {
    FullCalendar
  },
  props: {
    overall_res: {
      type: Object,
      required: true
    }
  },
  data: function() {
    return {
      calendarPlugins: [ // plugins must be defined in the JS
        dayGridPlugin,
        timeGridPlugin,
        interactionPlugin // needed for dateClick
      ],
      calendarWeekends: true,
      calendarEvents: [],
      navi_disable: false
    };
  },
  watch: {
    overall_res() {
      this.make_weekly_per();
      this.insert_events();
      this.navi_disable = false;
    }
  },
  methods: {
    fullcalendarRefresh(){
      this.navi_disable = true;
      this.remove_all_events();
      this.$refs.fullCalendar.getApi().destroy();
      this.$refs.fullCalendar.getApi().render();
    },
    preMonth() {
      this.navi_disable = true;
      this.remove_all_events();
      this.$refs.fullCalendar.getApi().prev();
      this.$emit('on_prev');
    },
    nextMonth() {
      this.navi_disable = true;
      this.remove_all_events();
      this.$refs.fullCalendar.getApi().next();
      this.$emit('on_next');
    },

    make_weekly_per() {
      // add week column
      $('.kpisCalendar .fc-head-container')
        .find('thead tr')
        .append('<th class="fc-day-header fc-widget-header"><span>Week</span></th>');

      for(let i=1; i < 7 ;i++) {
        let $tr = $('.kpisCalendar .fc-day-grid').find(`.fc-row:nth-child(${i}) .fc-bg`).find('tr');
        if(this.overall_res.week_per_res[i-1]) {
          let bg_color = this.color_by_per(this.overall_res.week_per_res[i-1],true);
          $tr.append(`<td class="fc-day fc-widget-content ${bg_color}"><div class="td_margin">${this.overall_res.week_per_res[i-1]}%<div></td>`);
        } else {
          $tr.append('<td class="fc-day fc-widget-content"></td>');
        }

        $('.kpisCalendar .fc-day-grid')
          .find(`.fc-row:nth-child(${i}) .fc-content-skeleton`)
          .find('tr')
          .append('<td class="fc-day-top"></td>');
      }
    },
    remove_all_events() {
      let eventSources =this.$refs.fullCalendar.getApi().getEventSources();
      let len = eventSources.length;
      for (let i = 0; i < len; i++) {
        eventSources[i].remove();
      }
      this.calendarEvents =[];
    },

    insert_events() {
      this.overall_res.day_per_res.forEach(day_per=>{
        let event = {};
        if(day_per.response_type === 'Performance') {
          event = this.make_per_event(day_per);
        }
        else if (day_per.response_type === 'Holiday') {
          event= this.make_holiday_event(day_per);
        }
        else if(day_per.response_type === 'Request') {
          event = this.make_req_event(day_per);
        }
        this.calendarEvents.push({ // add new event data
          title: event.title,
          start:  event.date,
          rendering: 'background',
          backgroundColor: event.bg_color
        });
      });
    },

    color_by_per(perc, is_class=false) {
      if (this.overall_res.target_section == null) {
        return is_class ? 'bg__gold' : Colors.goldColor; //default bg
      }
      if(perc >= this.overall_res.target_section.weightin_gold) {
        return is_class ? 'bg__gold' : Colors.goldColor;
      }
      if(perc >= this.overall_res.target_section.weightin_green) {
        return is_class ? 'bg__green' : Colors.greenColor;
      }
      if(perc >= this.overall_res.target_section.weightin_yellow) {
        return is_class ? 'bg__yellow' : Colors.yellowColor;
      }
      if(perc >= this.overall_res.target_section.weightin_orange) {
        return is_class ? 'bg__orange' : Colors.orangeColor;
      }
      return  is_class ? 'bg__red' : Colors.redColor;
    },

    make_per_event(day_per) {
      let event = {};
      event.date = day_per.date;
      event.title = `${day_per.overall_perc}%`;
      event.bg_color = this.color_by_per(day_per.overall_perc);

      return event;
    },
    make_holiday_event(day_per) {
      let event = {};
      event.date = day_per.date;
      event.title = 'Public Holiday';
      event.bg_color = Colors.skyColor;
      return event;
    },
    make_req_event(day_per) {
      let event = {};
      event.date = day_per.date;
      event.title = day_per.request_type;
      if(day_per.request_type == 'Annual') {
        event.bg_color = Colors.violetColor;
      }
      else if (day_per.request_type == 'Sick') {
        event.bg_color = Colors.DeepSkyColor;
      }
      else {
        event.bg_color = Colors.tealColor;
      }
      return event;
    },
    //FullCalendar Render event
    eventRender(info) {
      $(info.el).append(`<div class="td_margin">${info.event.title}<div>`);

      setTimeout(()=>{
        for(let i =1; i < 7 ;i++) {
          let bg_event_len = $('.kpisCalendar .fc-day-grid')
            .find(`.fc-row:nth-child(${i}) .fc-bgevent-skeleton`).length;

          // add column for event
          for(let bg_event_i = 0; bg_event_i < bg_event_len; bg_event_i++) {
            let each_event_len = $('.kpisCalendar .fc-day-grid')
              .find(`.fc-row:nth-child(${i}`)
              .find('.fc-bgevent-skeleton').eq(`${bg_event_i}`)
              .find('tr').children().length;
            if (each_event_len == 7) {
              $('.kpisCalendar .fc-day-grid')
                .find(`.fc-row:nth-child(${i}`)
                .find('.fc-bgevent-skeleton')
                .eq(`${bg_event_i}`)
                .find('tr').append('<td class="pointer-events:none"></td>');
            }
          }
        }
      }, 100);
    }
  },
};
</script>

<style lang='scss'>
.kpisCalendar {
  .fc-bgevent {
    opacity: 1 !important;
  }
  .fc-row .fc-bgevent-skeleton td, .fc-row .fc-highlight-skeleton td {
    border-color: #ddd !important;
  }
  .td_margin {
    margin-top: 2rem;
  }
}
</style>
