import {Request} from '../utilities/Request';

export function pay_rates(this_obj) {
  return Request.get(
    this_obj,
    `/api/crm_users/${this_obj.crm_user_id}/pay_rates`,
    null,
  );
}

export function create_pay_rate(this_obj, pay_rate) {
  return Request.post(
    this_obj,
    `/api/crm_users/${pay_rate.crm_user_id}/pay_rates`,
    null,
    {
      pay_rate: pay_rate
    }
  );
}

export function delete_pay_rate(this_obj, id, crm_user_id) {
  return Request.delete(
    this_obj,
    `/api/crm_users/${crm_user_id}/pay_rates/${id}`,
    null
  );
}
