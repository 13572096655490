<template>
  <span
    class="custom-tree-node"
    @mouseover="highlight_content(layer_props.id)"
    @mouseout="clear_highlight"
  >
    <el-checkbox
      v-model="layer_props.checked"
      :style="{
        visibility:
          layer_props.selected_proposal_item_type == null
            ? 'hidden'
            : 'visible',
        'padding-right': '4px',
      }"
    />

    <span @click="select_layer_content(layer_props.id)">
      <el-tooltip
        content="Stock layer"
        placement="top"
      >
        <i
          v-show="layer_props.selected_proposal_item_type == 'stock'"
          class="el-icon-box"
        />
      </el-tooltip>
      <el-tooltip
        content="Task layer"
        placement="top"
      >
        <i
          v-show="layer_props.selected_proposal_item_type == 'job'"
          class="el-icon-s-order"
        />
      </el-tooltip>
      <input
        v-model="layer_props.name"
        type="text"
        :disabled="layer_props.locked == 'true'"
        class="browser-default"
        style=" border: 0px; width: 90%"
        :title="layer_props.name"
        @change="update_layer_name"
      >
    </span>
    <span>
      <el-dropdown
        v-show="renest_available"
        trigger="click"
        @command="handleCommand"
      >
        <span class="el-dropdown-link">
          <i class="el-icon-arrow-down el-icon--right" />
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="renest">Renest</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <i
        class="el-icon-success"
        :class="{ active_layer: layer_props.active }"
        @click="activate_layer(layer_props.id)"
      />
      <el-switch
        v-model="layer_props.visible"
        size="small"
      />
      <!-- <el-switch v-model="layer_props.hidden" v-on:change="change_layer_visibility"></el-switch> -->

      <i
        style="color: #F56C6C"
        :class="{ layers_locked: layer_props.locked }"
        size="small"
        class="el-icon-delete-solid"
        @click="() => delete_layer(node, layer_props)"
      />
    </span>
  </span>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { editor_store, mm_to_px } from 'store/modules/editor';
import { JobProposalMixin } from 'mixins/JobProposalMixin.js';
import { ProposalStockItemMixin } from 'mixins/ProposalStockItemMixin.js';

export default {
  name: 'LayerAttributes',
  mixins: [JobProposalMixin, ProposalStockItemMixin],
  props: ['layers_tree', 'layer_props', 'node', 'renest_layer'],
  store: editor_store,
  data() {
    return {
      layer_name: '',
      layer_visibility: true,
      selected_layer: '',
    };
  },
  watch: {
    'layer_props.visible': function(newVal, oldValue) {
      this.change_layer_visibility(newVal);
    },
    'layer_props.active': function(newVal, oldValue) {
      let layer = this.selected_layer;

      if (this.selected_layer.classList.contains('base_layer') == true)
        d3.select('.base_layer').classed('active_layer', newVal);
      else {
        if (newVal == true)
          d3.select('.base_layer').classed('active_layer', false);
        d3.select(layer).classed('active_layer', newVal);
      }
    },
    'layer_props.hover': function(newVal, oldValue) {
      let layer = this.selected_layer;

      if (this.selected_layer.classList.contains('base_layer') == true)
        d3.select('.base_layer').classed('hover_layer', newVal);
      else {
        if (newVal == true)
          d3.select('.base_layer').classed('hover_layer', false);
        d3.select(layer).classed('hover_layer', newVal);
      }
    },
    'layer_props.name': function(newVal, oldValue) {
      this.selected_layer.setAttribute('data-name', newVal);
      this.set_file_changed(true);
    },
  },
  methods: {
    ...mapActions([
      'set_selected_object',
      'clear_selection',
      'refresh_missing_fonts',
      'set_file_changed',
    ]),
    update_layer_name() {
      let layer_type = this.layer_props.selected_object.getAttribute(
        'layer-type'
      );
      if (layer_type == 'stock') {
        let id = this.layer_props.selected_object.getAttribute(
          'proposal-stock-id'
        );
        this.$http
          .patch(`/update_stock_layer_name/${id}`, {
            layer_name: this.layer_props.name,
          })
          .then(
            (response) => {
              this.$message({
                type: 'success',
                message: 'Layer name has been changed.',
              });
            },
            (response) => {
              this.$message({
                type: 'warning',
                message: response.data.message,
              });
            }
          );
      } else if (layer_type == 'job') {
        let id = this.layer_props.selected_object.getAttribute('job-id');
        this.$http
          .patch(`/update_jp_layer_name/${id}`, {
            layer_name: this.layer_props.name,
          })
          .then(
            (response) => {
              this.$message({
                type: 'success',
                message: 'Layer name has been changed.',
              });
            },
            (response) => {
              this.$message({
                type: 'warning',
                message: response.data.message,
              });
            }
          );
      }
    },
    highlight_content(id) {
      let _this = this;
      this.layers_tree.forEach(function(el, index) {
        if (el.id == id) {
          el.hover = true;
        } else {
          el.hover = false;
        }
      });
    },
    clear_highlight() {
      this.layers_tree.forEach(function(el, index) {
        el.hover = false;
      });
    },
    select_layer_content() {
      d3.selectAll('.svg_inner_container [data-selected=true]').attr(
        'data-selected',
        null
      );

      if (
        this.layer_props.selected_object.getAttribute('layer-type') == undefined
      )
        return;
      let refs = this.layer_props.selected_object.getAttribute('ref-ids');
      if (refs == undefined) {
        this.$message({ type: 'warning', message: 'Object is not attched.' });
        return;
      }
      let ref_id = JSON.parse(refs)[0];
      if (ref_id != undefined)
        this.set_selected_object(d3.select(`#${ref_id}`).node());
      else
        this.$message({ type: 'warning', message: 'Object is not attched.' });
    },
    handleCommand(command) {
      switch (command) {
      case 'renest':
        let nesting_details = eval(
          '(' + this.selected_layer.getAttribute('nesting-details') + ')'
        );
        nesting_details.layer_props = this.layer_props;
        this.$emit('renest_layer', nesting_details);
        break;

      default:
        break;
      }
    },
    // change_layer_name(e) {
    //   this.selected_layer.setAttribute("data-name", e);
    // },
    change_layer_visibility(e) {
      let layer = d3.select('#' + this.selected_layer.id).node();
      if (this.selected_layer.classList.contains('base_layer') == true)
        layer = d3.select('.base_layer').node();
      if (e == false) layer.classList.add('hide_layer');
      else layer.classList.remove('hide_layer');
    },
    activate_layer(id) {
      let _this = this;
      this.layers_tree.forEach(function(el, index) {
        if (el.id == id) {
          el.active = true;
        } else {
          el.active = false;
        }
      });
      //   this.layer_props.active = true;
    },
    delete_layer(node, data) {
      let _this = this;
      this.$confirm(
        'Are you sure to delete this layer and its children, this step cannot be undo ?',
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      )
        .then(() => {
          let layer_type = _this.layer_props.selected_object.getAttribute(
            'layer-type'
          );
          switch (layer_type) {
          case 'job':
            this.delete_job_layer();
            break;
          case 'stock':
            this.delete_stock_layer();
            break;
          default:
            this.remove_tree_layer();
            break;
          }
          this.set_file_changed(true);
        })
        .catch((e) => {});
    },
    remove_tree_layer() {
      let _this = this;
      let is_current_active = _this.layer_props.active;
      _this.selected_layer.remove();

      const parent = _this.node.parent;
      const children = parent.data.children || parent.data;

      const index = children.findIndex((d) => d.id === _this.layer_props.id);
      children.splice(index, 1);
      
      _this.refresh_missing_fonts();
      _this.clear_selection();

      if (is_current_active) _this.activate_layer(1);

      this.$message({
        type: 'success',
        message: 'Delete completed',
      });
    },
    delete_job_layer() {
      let _this = this;
      let id = _this.layer_props.selected_object.getAttribute('job-id');
      let job = _this.job_proposals_list.filter((item) => item.id == id)[0];
      if (job == undefined) _this.remove_tree_layer();
      else
        this.delete_job_proposal(id).then(
          (response) => {
            let index = _this.job_proposals_list.indexOf(job);
            if (_this.proposal.approved == 1) {
              //_this.job_proposals_list[index].mark_as_deleted = 1;
              job.mark_as_deleted = 1;
              _this.layer_props.locked = true;
            } else {
              _this.job_proposals_list.splice(index, 1);
              // delete layer from tree if proposal not approved
              _this.remove_tree_layer();
            }
          },
          (reason) => {
            console.log(reason);
          }
        );
    },
    delete_stock_layer() {
      let _this = this;
      let id = _this.layer_props.selected_object.getAttribute(
        'proposal-stock-id'
      );
      let stock = _this.stock_items_list.filter((item) => item.id == id)[0];
      if (stock == undefined) _this.remove_tree_layer();
      else
        this.delete_proposal_stock_item('default', id).then(
          (response) => {
            let index = _this.stock_items_list.indexOf(stock);
            if (_this.proposal.approved == 1) {
              stock.mark_as_deleted = 1;
              _this.layer_props.locked = true;
            } else {
              _this.stock_items_list.splice(index, 1);
              // delete layer from tree if proposal not approved
              _this.remove_tree_layer();
            }
          },
          (reason) => {
            this.$message({
              type: 'info',
              message: reason,
            });
            console.log(reason);
          }
        );
    },
  },
  created() {
    this.selected_layer = this.layer_props.selected_object;
  },
  mounted() {
    if (this.selected_layer.classList.contains('hide_layer'))
      this.layer_visibility = false;
    this.layer_name = this.layer_props.name;
  },
  computed: {
    ...mapGetters([
      'stock_items_list',
      'job_proposals_list',
      'proposal',
      'file_changed',
    ]),
    renest_available() {
      return this.selected_layer.hasAttribute('nesting-details');
    },
  },
};
</script>

<style scoped>
.active_layer {
  color: orange;
}
.hover_layer {
  color: chartreuse;
}
.layers_locked {
  visibility: hidden;
}
</style>
