<template>
  <div>
    <div class="row">
      <div class="col m6 s12">
        <p style="margin: 0px">
          <b>Working area specs:</b>
        </p>W:
        <el-input-number style="width: 130px" size="mini" v-model="nesting_item_specs.custom_width"></el-input-number>
        <p></p>H:
        <el-input-number
          style="width: 130px"
          size="mini"
          v-model="nesting_item_specs.custom_height"
        ></el-input-number>
      </div>
      <div class="col m6 s12">
        <p style="margin: 0px">
          <b>Coordinates starting:</b>
        </p>x:
        <el-input-number style="width: 130px" size="mini" v-model="nesting_item_specs.custom_x"></el-input-number>
        <p></p>y:
        <el-input-number style="width: 130px" size="mini" v-model="nesting_item_specs.custom_y"></el-input-number>
      </div>
    </div>
    <div class="row">
      <svg
        :id="'nesting_' + nesting_item_specs.id"
        style="fill: none"
        class="selected_svg svg-content-responsive"
        :viewBox="viewbox"
        preserveAspectRatio="xMinYMin meet"
      ></svg>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

export const mm_to_px = 3.779527559;
export default {
  name: "NestingProposalItem",
  props: [
    "nesting_item_specs",
    "custom_margin_horizontal",
    "custom_margin_vertical"
  ],
  data() {
    return {
      svg: ""
    };
  },
  methods: {
    set_inputs_to_default() {
      let _this = this;
      Array.from(
        document.querySelectorAll(".nesting_calculator .el-input")
      ).forEach(function(e, i) {
        e.classList.add("browser-default");
        if (e.querySelector("input") != null)
          e.querySelector("input").className += " browser-default";
      });
    },
    draw_rectangles() {
      this.svg.selectAll("g").remove();
      // getting first drawn rects only
      this.nesting_item_specs.rects.filter(c=>c.id.includes("Num#0")).forEach((rect, idx) => {
        let group = this.svg.insert("g");

        group
          .append("rect")
          .classed("item_margin_style", true)
          .attr("x", rect.x)
          .attr("y", rect.y)
          .attr("width", rect.w)
          .attr("height", rect.h);

        group
          .append("rect")
          .classed("item_style", true)
          .attr("x", rect.x + this.custom_margin_horizontal)
          .attr("y", rect.y + this.custom_margin_vertical)
          .attr("width", rect.w)
          .attr("height", rect.h)
          .attr("fill", "lightblue");

        let font_size = Math.min(rect.w, rect.h) * 0.4;
        group
          .append("text")
          .classed("item_text_style", true)
          .attr("font-size", font_size)
          .attr("x", rect.x + 3 + this.custom_margin_horizontal)
          .attr("y", rect.y + font_size)
          .text(`${idx + 1}`);
      });
    }
  },
  mounted() {
    this.svg = d3.select("#nesting_" + this.nesting_item_specs.id);
    this.set_inputs_to_default();
  },
  watch: {
    "nesting_item_specs.rects": function(newVal) {
      this.draw_rectangles();
    }
  },
  computed: {
    ...mapGetters(["page_scale"]),
    viewbox: function() {
      return (
        "0 0 " +
        this.nesting_item_specs.custom_width +
        " " +
        this.nesting_item_specs.custom_height
      );
    }
  }
};
</script>
 