<template>
  <div>
      <div slot="header" class="clearfix">
        <p>
          <strong>SKILLS</strong>
          <el-button
              @click="remove_skills_by_user"
              class="right"
              size="small"
              type="danger"
          >Delete all skills</el-button>
        </p>
          <el-select filterable v-model="selected_category_id"
                     style="width: 340px;">
            <el-option
              v-for="category in categories_list"
              :key="category.id"
              :label="category.name"
              :value="category.id"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="selected_jobs"
            multiple
            filterable
            collapse-tags
            style="width: 340px;"
          >
            <el-option
              v-for="job in filtered_jobs_list"
              :key="job.id"
              :label="job.name"
              :value="job.id"

            >
            </el-option>
          </el-select>
<br>
          <el-button
            type="primary"
            size="small"
            @click="add_user_skills"
            :disabled="this.selected_jobs.length == 0"
          >
            Add
          </el-button>
      </div>
    <el-divider></el-divider>
      <el-row v-for="group in Object.keys(grouped_skills)" :key="group">
        <strong class="skill_title">{{ group.toUpperCase() }}</strong>
        <hr />
        <el-col
          :xs="24"
          :sm="12"
          :md="10"
          :lg="8"
          :xl="6"
          v-for="skill in grouped_skills[group]"
          :key="skill.id"
        >
          <el-tag
            size="small"
            @close="remove_user_skill(skill.id)"
            closable
            style="margin: 2px;"
          >
            {{ skill.job_name }}
          </el-tag>
        </el-col>
      </el-row>
  </div>
</template>

<script>
export default {
  name: "CrmUserSkills",
  props: ["crm_user_id"],
  data() {
    return {
      selected_category_id: "",
      selected_jobs: [],
      skills: [],
      categories_list: [],
      jobs_list: [],
    };
  },
  mounted() {
    this.load_categories();
    this.load_skills();
    Object.defineProperty(Array.prototype, "unique", {
      enumerable: false,
      configurable: false,
      writable: false,
      value: function() {
        var a = this.concat();
        for (var i = 0; i < a.length; ++i) {
          for (var j = i + 1; j < a.length; ++j) {
            if (a[i] === a[j]) a.splice(j--, 1);
          }
        }

        return a;
      },
    });
  },
  methods: {
    load_categories: function() {
      this.$http.get("/category_tasks").then(
        (response) => {
          this.categories_list = response.data;
        },
        function(response) {
          console.log(response);
        }
      );
    },
    load_jobs() {
      this.$http.get(`/jobs_by_category/${this.selected_category_id}`).then(
        (response) => {
          this.jobs_list = response.data;
        },
        (response) => {
          console.log(response);
        }
      );
    },
    add_user_skills() {
      let loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      //let selected_skills = []; // selected tasks
      let job = this.jobs_list.filter((c) => c.id == this.selected_job_id)[0];
      let category = this.categories_list.filter(
        (c) => c.id == this.selected_category_id
      )[0];

      this.$http
        .post(`/crm_user_skills/`, {
          id: this.crm_user_id,
          skills: this.selected_jobs,
        })
        .then(
          (response) => {
            loading.close();
            this.selected_jobs = [];
            this.load_skills();
            this.$message({
              type: "success",
              message: "Skill has been added successfully",
            });
          },
          (response) => {
            loading.close();
            this.$message({
              type: "error",
              dangerouslyUseHTMLString: true,
              message: `Check below errors: ${response.data.errors.join(
                "<br>"
              )}`,
            });
            console.log(response);
          }
        );
    },
    remove_user_skill(id) {
      this.$http.delete(`/crm_user_skills/${id}`).then(
        (response) => {
          this.$message({
            type: "success",
            message: "Skill has been deleted.",
          });
          this.skills.filter((el, idx) => {
            if (el.id == id) {
              this.$delete(this.skills, idx);
              return;
            }
          });
        },
        (response) => {
          this.$message({ type: "error", message: response });
        }
      );
    },
    remove_skills_by_user() {
      this.$confirm(
        "This will permanently delete all skills. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          this.$http.delete(`/remove_skills_by_user/${this.crm_user_id}`).then(
            (response) => {
              this.skills = [];
              this.$message({
                type: "success",
                message: "Deleted successfully.",
              });
            },
            (response) => {
              this.$message({ type: "error", message: response });
            }
          );
        })
        .catch(() => {
          return;
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },

    load_skills: function() {
      this.$http.get(`/skills_by_user/${this.crm_user_id}`).then(
        (response) => {
          this.skills = response.data;
        },
        (response) => {}
      );
    },
  },
  computed: {
    filtered_jobs_list() {
      let skills_ids = this.skills.map((item) => item["job_id"]);
      return this.jobs_list.filter((c) => skills_ids.includes(c.id) == false);
    },
    grouped_skills() {
      let grouped = this.skills.reduce((r, a) => {
        r[a.category_name] = [...(r[a.category_name] || []), a];
        return r;
      }, {});
      return grouped;
    },
  },
  watch: {
    crm_user_id: function(newVal) {
      this.load_skills();
    },
    selected_category_id: function(newVal) {
      this.selected_jobs = [];
      this.load_jobs();
    },
  },
};
</script>
