<template>
<div>
  <el-row class="main-title row-margin">
    <el-col :span="12">Sales Variation</el-col>
    <el-col class="gross-profit" :span="12">
      <Money class="gross-profit__money" :amount="gross_profit"/>
      <Percent :dividend="gross_profit" :divisor="total_sale_price"/>
    </el-col>
  </el-row>
  <el-row class="row_margin" v-for="(wf) in working_files" :key="wf.id">
    <VariationLists ref="variation_lists"
         v-on:changedTotal="changed_total"
        :working_file="wf"
        :stock_items="get_stock_items(wf.id)"
        :task_items="get_task_items(wf.id)"
        :po_items="get_po_items(wf.id)"/>
  </el-row>
  <el-row class="row_margin">
    <Summary ref="summary"/>
  </el-row>
</div>
</template>

<script>


import SalesCostMixin from '../../mixins/SalesCostMixin';

import VariationLists from "./sales_variation_elements/VariationLists.vue";
import Summary from "./sales_variation_elements/Summary.vue";
import Money from "components/shared/Money";
import Percent from "components/shared/Percent";

import {discountValue} from '../../utilities/Discout';

export default {
  name: "SalesVariation.vue",
  components: {
    VariationLists,
    Summary,
    Money,
    Percent
  },
  mixins: [SalesCostMixin],
  props: [ "matched_quote","working_files", "stock_items", "task_items", "po_items", "discount_rate" ],
  mounted() {
    this.total_sale_price = this.variation_total_sales_price().toFixed(2);
    this.total_quote_price = this.variation_total_quote_price;
    this.total_cost = this.variation_total_cost;
    this.stock_cost = this.variation_stock_cost;
    this.labour_cost = this.variation_labour_cost;
    this.gross_profit = this.total_sale_price - this.total_cost;
  },

  methods: {
    get_stock_items(working_file_id)  {
      return this.stock_items.filter((s) => s.working_file_id === working_file_id);
    },
    get_task_items(working_file_id)  {
      return this.task_items.filter((t) => t.working_file_id === working_file_id);
    },
    get_po_items(working_file_id)  {
      return this.po_items.filter((p) => p.working_file_id === working_file_id);
    },
    variation_total_sales_price() {
      if(this.matched_quote > 0) {
        return this.matched_quote;
      }

      let total_sale_price = this.$refs.variation_lists.reduce(
          (acc, variation) => acc + variation.total_sale_price(), 0
      );

      if(this.is_working_file_matched_quote == false) {
        total_sale_price = parseFloat(discountValue(total_sale_price, this.discount_rate).toFixed(2));
      }

      let variation_price = this.$refs.variation_lists.reduce(
          (acc, variation) => acc + variation.variation_price, 0
      );
      return total_sale_price + variation_price;
    },
    changed_total() {
      this.total_sale_price = this.variation_total_sales_price();
    },
  },
  computed: {
    variation_total_cost() {
      return this.$refs.variation_lists.reduce(
          (acc, variation) => acc + variation.total_cost, 0
      );
    },
    variation_stock_cost() {
      return this.$refs.variation_lists.reduce(
          (acc, variation) => acc + variation.stock_cost, 0
      );
    },
    variation_labour_cost() {
      return this.$refs.variation_lists.reduce(
          (acc, variation) => acc + variation.labour_cost, 0
      );
    },
    variation_total_quote_price() {
      return this.$refs.variation_lists.reduce(
          (acc, variation) => acc + variation.total_quote_price, 0
      );
    },
    is_working_file_matched_quote() {
      this.$refs.variation_lists.forEach(variation=>{
        if (variation.working_file.matched_quote > 0 ) {
          return true
        }
      });
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.gross-profit {
  font-weight: bold;
  text-align: right;
  padding-right: 10px;

  &__money {
    padding-right: 10px;
  }
}

.main-title {
  font-size: 18px;
}

.row-margin {
   margin-bottom: 15px;
}
</style>