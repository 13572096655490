<template>
  <el-card class="box-card">
    <b>Upskilling/Goals List</b>
    <el-table
      :data="my_goals"
      style="width: 100%"
    >
      <el-table-column
        prop="desc"
        label="Description"
        :min-width="53"
      />
      <el-table-column
        prop="deadline_end_date"
        label="Deadline Date"
        :min-width="27"
      />
      <el-table-column
        prop="status"
        label="Status"
        :min-width="20"
      />
    </el-table>
  </el-card>
</template>

<script>
import {loadMyKPIGoals} from '../../api/my_kpi';

export default {
  name: 'UpSkillingGoals',
  props: {
    selected_user: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      my_goals: []
    };
  },
  watch: {
    selected_user() {
      this.request_my_goal();
    }
  },
  created() {
    this.request_my_goal();
  },

  methods: {
    request_my_goal() {
      loadMyKPIGoals(this, this.selected_user).then(({data})=>{ this.my_goals = data; });
    },
  }
};
</script>
