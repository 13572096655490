<template>
  <div id="deductions">
    <p class="headline">
      Total Deduction: <Money :amount="total_deduction" />
    </p>
    <el-collapse>
      <el-collapse-item title="Student Loan">
        <student-loan
          :crm_user_id="crm_user_id"
          :pay_period_start="pay_period_start"
          :pay_period_end="pay_period_end"
          @calculate_sl_pay_run="calculate"
        />
      </el-collapse-item>
      <el-collapse-item title="Child Support">
        <child-support
          :crm_user_id="crm_user_id"
          :pay_period_start="pay_period_start"
          :pay_period_end="pay_period_end"
          @calculate_cs_pay_run="calculate"
        />
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
import Money from '../../shared/Money';
import StudentLoan from './StudentLoan';
import ChildSupport from './ChildSupport';

export default {
  name: 'Deductions',
  components: {Money, StudentLoan, ChildSupport},
  props: {
    crm_user_id: {
      type: String,
      required: true
    },
    pay_period_start: {
      type: String,
      required: true
    },
    pay_period_end: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      total_deduction: 0
    };
  },
  created(){
  },
  methods: {
    calculate(value){
      this.total_deduction += Math.abs(value) ;
      this.$emit('calculate_pay_run', value);
    }
  }
};
</script>
<style scoped>
.headline {
  padding: 10px;
  color: white;
  background: #409EFF;
}
</style>
