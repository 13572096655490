<template>
  <div v-loading="main_loading" class="import_working_files">
    <el-row>
      <el-col :lg="2" :md="4" :sm="6">
        <label>
          Proposal
        </label>
      </el-col>
      <el-col :lg="8" :md="8" :sm="6">
        <el-select
          v-model="selected_proposal_template"
          @change="fetch_working_files"
          size="small"
          style="width: 200px"
        >
          <el-option-group
            v-for="group in proposal_templates"
            :key="group.group_name"
            :label="group.group_name"
          >
            <el-option
              v-for="item in group.proposals"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
          </el-option-group>
        </el-select>
        &nbsp;
        <el-checkbox :indeterminate="is_indeterminate" v-model="check_all" @change="handleCheckAllChange">Check all</el-checkbox>
      </el-col>
      <el-col :lg="12" :md="12" :sm="12">
        <el-checkbox v-model="import_to_selected" v-show="working_file_id != ''"
          >Import to selected working file</el-checkbox
        >
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-checkbox-group
          v-model="checked_working_files"
          @change="handleCheckedCitiesChange"
          class="left"
        >
          <el-checkbox
            v-for="working_file in working_files_list"
            :label="working_file.id"
            :key="working_file.id"
          >
            <img
              :src="working_file.thumb"
              style="border: 1px solid #e4dede;width: 30px"
            />
            <div class="label" :title="working_file.name">
              {{ working_file.name }}
            </div>
          </el-checkbox>
        </el-checkbox-group>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-button
          @click="import_working_files"
          class="right"
          size="mini"
          type="primary"
          >Import files</el-button
        >
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { editor_store } from "store/modules/editor";
import { createCategoryWorkingFile } from '../../../api/category_working_files';

// mixins 
import { JobProposalMixin } from "mixins/JobProposalMixin.js";
import { ProposalStockItemMixin } from "mixins/ProposalStockItemMixin.js";
import {EventBus} from '../../../utilities/EventBus';

export default {
  name: "ImportWorkingFiles",
  store: editor_store,
  mixins: [JobProposalMixin, ProposalStockItemMixin],
  props: ["hide_import_working_files_dialog"],
  data() {
    return {
      main_loading: false,
      import_to_selected: false,
      proposal_templates: [],
      working_files_list: [],
      checked_working_files: [],
      selected_proposal_template: "",
      check_all: false,
      is_indeterminate: false,
      all_working_files: []
    };
  },
  mounted() {
    this.fetch_proposal_templates();
    let _this = this;
    setTimeout(function() {
      _this.set_inputs_to_default();
    }, 500);
  },
  watch: {
    working_files_list() {
      this.all_working_files = [];
      this.working_files_list.forEach((working_file) => { this.all_working_files.push(working_file.id); });
    }
  },
  computed: {
    ...mapGetters(["proposal", "working_file_id", "get_active_working_file_category"]),
    is_import_to_working_file() {
      return this.import_to_selected === true && this.working_file_id !== '';
    }
  },
  methods: {
    ...mapActions([
      "append_files_list",
      "set_stock_items_list",
      "set_job_proposals_list",
      "set_working_file_id"
    ]),
    handleCheckAllChange(val) {
      this.checked_working_files = val ? this.all_working_files : [];
      this.is_indeterminate = false;
    },
    handleCheckedCitiesChange(val) {
      let checkedCount = val.length;
      this.check_all = checkedCount === this.working_files_list.length;
      this.is_indeterminate = checkedCount > 0 && checkedCount < this.working_files_list.length;
    },
    fetch_proposal_templates() {
      this.proposal_templates = [];
      if (this.proposal_templates.length == 0) {
        this.$http
          .get(`/proposal_templates_by_account/${this.proposal.account_id}`)
          .then(response => {
            this.proposal_templates = response.body;
          });
      }
    },
    fetch_working_files() {
      this.checked_working_files = [];
      this.is_indeterminate = false;
      this.check_all = false;

      if (this.selected_proposal_template == "") {
        this.$message({
          type: "warning",
          message: "No selected proposal."
        });
        return;
      }

      this.$http
        .post("/proposal_working_files", {
          proposal_id: this.selected_proposal_template
        })
        .then(response => {
          this.working_files_list = response.body.files;
        });
    },
    async import_working_files() {
      if (this.checked_working_files.length == 0) {
        this.$message({
          type: "warning",
          message: "No selected working files"
        });
        return;
      }

      if(!this.selected_proposal_template) {
        this.$message({
          type: "warning",
          message: "No selected proposal"
        });
        return;
      }

      let category_working_file_id = this.get_active_working_file_category ? Number(this.get_active_working_file_category) : ''
      if (this.check_all === true && this.get_active_working_file_category && this.is_import_to_working_file === false) {
        let proposal = null;
        for (let t of this.proposal_templates) {
          proposal = t.proposals.find((p) => Number(p.id) === Number(this.selected_proposal_template));
          if(proposal) break;
        }
        if(proposal) {
         let res =  await createCategoryWorkingFile(this, this.proposal.id, proposal.value);
          category_working_file_id = res.body.id
        }
      }
      this.main_loading = true;

      let params = {
        proposal_id: this.proposal.id,
        working_files_ids: this.checked_working_files,
        category_working_file_id: category_working_file_id
      };
      if (this.is_import_to_working_file) {
        params["target_working_file"] = this.working_file_id;
      }
      let working_file_id = this.working_file_id;
      this.$http.post("/import_working_files", params).then(
        response => {
          this.main_loading = false;
          let args = { active_category_id: String(category_working_file_id) }

          if (this.import_to_selected === false)
            this.append_files_list(response.body.working_files);
          else {
            this.set_working_file_id("");
            args.active_working_file_id = working_file_id;
          }

          this.$message({
            type: "success",
            message: "Working files have been imported successfully."
          });

          EventBus.$emit('reloadCategoryWorkingFiles', args);

          this.load_stocks_list();
          this.load_jps_list();
          this.selected_proposal_template = "";
          this.checked_working_files = [];
          this.hide_import_working_files_dialog();
          this.check_all = false;
          this.is_indeterminate = false;
        },
        reason => {
          this.main_loading = false;
          this.$message({
            type: "error",
            message:
              "Error happened while importing working files, please try again."
          });
        }
      );
    },
    set_inputs_to_default() {
      let _this = this;
      Array.from(
        document.querySelectorAll(".import_working_files .el-input")
      ).forEach(function(e, i) {
        e.classList.add("browser-default");
        if (e.querySelector("input") != null)
          e.querySelector("input").className += " browser-default";
      });
    },
    load_stocks_list() {
      this.load_stock_items_list(this.proposal.id).then(response => {
        this.set_stock_items_list(response);
      });
    },
    load_jps_list() {
      this.load_job_proposals(this.proposal.id).then(response => {
        this.set_job_proposals_list(response);
      });
    }
  }
};
</script>

<style></style>
