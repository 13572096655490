<template>
  <el-dialog
    title="OVERDUE TASKS"
    :visible.sync="dialog_visible"
    width="80%"
    :lock-scroll="true"
    :close-on-click-modal="true"
    :close-on-press-escape="true"
    :show-close="true"
  >
    <JobProposalsActionList
      title="Tasks list"
      :tasks_list="overdue_tasks"
    />
  </el-dialog>
</template>

<script>
import JobProposalsActionList from 'components/job_proposals/JobProposalsActionList';
export default {
  name: 'UsersRequestsNotification',
  components: { JobProposalsActionList },
  data() {
    return {
      dialog_visible: false,
      request_statuses: [],
      today: new Date(),
      overdue_tasks: [],
    };
  },
  computed: {
    alarms() {
      return [
        new Date(
          this.today.getFullYear(),
          this.today.getMonth(),
          this.today.getDate(),
          9,
          0,
          0,
          0
        ),
        new Date(
          this.today.getFullYear(),
          this.today.getMonth(),
          this.today.getDate(),
          16,
          0,
          0,
          0
        ),
      ];
    },
  },
  created: function() {},
  mounted() {
    this.check_popup_visibility();
  },
  methods: {
    check_popup_visibility() {
      let _this = this;
      this.$http.get('/jobs_settings/0').then((response) => {
        if (response.data['show_overdue_popup'] == true) _this.show_popup();
      });
    },
    show_popup() {
      let _this = this;
      if (window.location.pathname.indexOf('project_planner_view') > -1) return;
      _this.$http.get('/current_user_overdue_tasks').then((response) => {
        if (response.data.length > 0) {
          _this.overdue_tasks = response.data;
          let ids = _this.overdue_tasks.map((c) => c.proposal_id);
          if (window.location.pathname.indexOf('job_view') > -1) {
            let id = window.location.search
              .toString()
              .replace('?', '')
              .split('=')[1];
            if (ids.indexOf(parseInt(id)) == -1) _this.dialog_visible = true;
          } else if (window.location.pathname.indexOf('proposals')) {
            let id = window.location.pathname
              .replace('proposals', '')
              .replace('edit', '')
              .replaceAll('/', '')
              .replace('#', '');
            if (ids.indexOf(parseInt(id)) == -1) _this.dialog_visible = true;
          } else _this.dialog_visible = true;
        }
      });
    },
  },
};
</script>
