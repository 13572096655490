import { mapGetters } from 'vuex';
import {EventBus} from '../utilities/EventBus';

import ImportWorkingFiles from 'components/proposal/working_files/ImportWorkingFiles';
import { editor_store } from 'store/modules/editor';
import { WorkspaceMixin } from 'mixins/WorkspaceMixin.js';

const CategoryMixin = {
  store: editor_store,
  components: {
    ImportWorkingFiles
  },
  mixins: [WorkspaceMixin],
  computed: {
    ...mapGetters([
      'proposal',
      'proposal_id',
      'page_settings',
      'get_active_working_file_category',
    ]),
  },
  data() {
    return {
      import_working_files_dialog: false,
    };
  },
  methods: {
    create_new_page() {
      let params = {
        proposal_id: this.proposal.id,
        name: 'New Page',
        page_width: 10000,
        page_height: 10000,
        page_margin: this.page_settings.margin,
        category_id: this.get_active_working_file_category ? Number(this.get_active_working_file_category) : null
      };
      this.add_new_working_file(params).then(
        (response) => {
          EventBus.$emit('reloadCategoryWorkingFiles', { active_working_file_id: response.body.id});
        },
        () => {
          this.$message({
            type: 'error',
            message: 'error, contact not added',
          });
        }
      );
    },
    hide_import_working_files_dialog() {
      this.import_working_files_dialog = false;
    }
  }
};

export default CategoryMixin;