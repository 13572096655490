<template>
    <div class="project_planner_view">
        <h1>
            Project Planner dashboard
            <hr />
        </h1>
        <el-tabs v-model="selected_tab">
            <el-tab-pane label="Extensions requests" name="extensions_requests">
                <el-button size="mini" @click="fill_data" class="right">
                    Refresh
                </el-button>
                <el-table
                  :data="requests_data"
                  :row-class-name="tableRowClassName"
                >
                    <el-table-column prop="proposal_id" label="Proposal" width="100">
                        <template slot-scope="scope">
                                                  <el-link :href="`/job_view?id=${scope.row.proposal_id}`">
                                                    {{ scope.row.proposal_id }}
                                                  </el-link>
                                                  </template>
          </el-table-column>
          <el-table-column
            prop="created_by"
            label="Created by"
            width="200"
          ></el-table-column>
          <el-table-column prop="category" label="Category"></el-table-column>
          <el-table-column prop="job_name" label="Job"></el-table-column>
          <el-table-column prop="completed" label="Completed" width="100">
            <template slot-scope="scope">
              {{ scope.row.completed === true ? 'True' : 'False' }}
            </template>
          </el-table-column>
          <el-table-column prop="task_name" label="Task"></el-table-column>
          <el-table-column prop="start_time" label="Date"></el-table-column>
          <el-table-column
            prop="description"
            label="Description"
          ></el-table-column>
          <el-table-column label="Action" width="100">
            <template slot-scope="scope">
              <el-button icon="el-icon-edit" size="mini" circle @click="edit_extension_request(scope.row)" />
              <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="onDelete(scope.row)"/>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="Overdue Jobs" name="overdue_jobs" :lazy="true">
        <OverdueJobs></OverdueJobs>
      </el-tab-pane>
      <el-tab-pane label="Jobs Calendar" name="jobs_calendar" :lazy="true">
        <JobsCalendar></JobsCalendar>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      title="Extension request"
      :visible.sync="extension_request_dialog"
      width="60%"
      v-loading="request_loading"
    >
      <h3>
        <strong>Request details</strong>
        <hr />
      </h3>
      <el-row>
        <el-col :span="12">
          <b>Proposal: </b>
          <span class="request_val">
            <el-link
              target="_blank"
              :href="`/job_view?id=${selected_request.proposal_id}`"
              type="primary"
              >{{ selected_request.proposal_id }}</el-link
            >
          </span>
        </el-col>
        <el-col :span="12">
          <b>Job: </b>
          <span class="request_val">{{ selected_request.job_name }}</span>
        </el-col>
        <el-col :span="12">
          <b>Task: </b>
          <span class="request_val">{{ selected_request.task_name }}</span>
        </el-col>
        <el-col :span="12">
          <b>Created by: </b>
          <span class="request_val">{{ selected_request.created_by }}</span>
        </el-col>
        <el-col :span="12">
          <b>Suggested date:</b>
          <el-date-picker
            v-model="suggested_date"
            size="small"
            type="datetime"
            format="dd-MM-yyyy HH:mm"
            default-time="08:00:00"
            v-on:change="update_tasks_list"
            placeholder="Leave blank if not in use"
          ></el-date-picker>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="20">
          <b>Description: </b>
          <span class="request_val">{{ selected_request.description }}</span>
        </el-col>
      </el-row>
      <div v-if="selected_request.completed === true">
        <span class="color__red">Because the job was completed, this request cannot be approved.</span>
      </div>
      <h3>
        <strong>Action</strong>
        <hr />
      </h3>
      <el-row>
        <el-col :span="8">
          <strong>Status:</strong>
          <el-select
            v-model="selected_request.status.id"
            size="mini"
            filterable
          >
            <el-option
              v-for="status in request_statuses"
              :label="status.name"
              :value="status.id"
              :key="status.id"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <strong>Reason:</strong>
          <el-select
            v-model="selected_request.reason.id"
            size="mini"
            filterable
          >
            <el-option
              v-for="reason in request_reasons"
              :label="reason.name"
              :value="reason.id"
              :key="reason.id"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>

      <h3>
        New Plan
        <hr />
      </h3>
      <SuggestedPlan
        :proposal="proposal"
        :show_suggested_users="true"
        :project_plan_lists="project_plan_lists"
        :suggested_tasks="suggested_tasks"
        :suggested_deadline="suggested_deadline"
        v-on:timer_expired="timer_expired"
        v-on:update_tasks_list="update_tasks_list"
      ></SuggestedPlan>
      <el-row>
        <el-col :span="12">
          <el-button size="min" @click="extension_request_dialog = false"
            >Cancel</el-button
          >
        </el-col>
        <el-col :span="12">
          <el-button
            class="right"
            size="min"
            type="primary"
            @click="confirm_changes"
            >Update</el-button
          >
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import JobsCalendar from "components/jobs_list/JobsCalendar.vue";
import OverdueJobs from "components/proposal/OverdueJobs.vue";
import { ProposalMixin } from "mixins/ProposalMixin.js";
import { JobProposalMixin } from "mixins/JobProposalMixin.js";
import SuggestedPlan from "components/job_views/project_plan/SuggestedPlan.vue";

export default {
    name: "ProjectPlannerView",
    components: { JobsCalendar, SuggestedPlan, OverdueJobs },
    mixins: [JobProposalMixin, ProposalMixin],
    data() {
        return {
            selected_tab: "extensions_requests",
            request_loading: false,
            proposal: "",
            temp_list: [],
            suggested_date: "",
            project_plan_lists: [],
            suggested_tasks: [],
            suggested_deadline: "",
            extension_request_dialog: false,
            requests_data: [],
            request_statuses: [],
            request_reasons: [],
            extension_: [],
            selected_request: { status: {}, reason: {} },
        };
    },
    mounted() {
        this.fill_data();
        this.fill_statuses();
        this.fill_extension_reasons();
    },
    methods: {
        timer_expired() {
            this.extension_request_dialog = false;
        },
        suggested_date_changed(e) {

        },
        fill_data() {
            let loading = this.$loading({
                text: "Fetching requests...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$http.get("/extension_requests").then((response) => {
                loading.close();
                this.requests_data = response.data;
            });
        },
        fill_extension_reasons() {
            this.$http.get("/extension_reasons/").then(
                (response) => {
                    this.request_reasons = response.data;
                },
                (response) => {
                    console.log(response);
                }
            );
        },
        fill_statuses() {
            this.$http.get("/extension_statuses/").then(
                (response) => {
                    this.request_statuses = response.data;
                },
                (response) => {
                    console.log(response);
                }
            );
        },
        prepare_data(data) {
            //tasks_lists
            let lists = [];
            data.map((c) => {
                let tasks = c.tasks
                    .filter((f) => f.completed == false && f.mark_as_deleted == false)
                    .map((t) => {
                        let duration = 0;
                        let time_allocated_labour =
                            t.time_allocated_adjusted == undefined ||
                            t.time_allocated_adjusted == "" ?
                            t.time_allocated_labour :
                            t.time_allocated_adjusted;
                        if (t.is_outwork == true) duration = t.actual_duration;
                        else if (t.users_count > 1)
                            duration = time_allocated_labour / t.users_count;
                        else duration = time_allocated_labour;
                        // jp.users_count > 1 ? (jp.time_allocated_labour.to_i / jp.users_count) : jp.time_allocated_labour.to_i

                        return {
                            id: t.id,
                            name: t.job_name,
                            job_id: t.job_id,
                            position: t.position,
                            branch_specific: t.branch_specific,
                            locked: t.is_date_locked,
                            is_outwork: t.is_outwork,
                            actual_duration: t.actual_duration,
                            custom_start_date: t.custom_start_date,
                            time_frame_start: t.time_frame_start,
                            custom_start_date: t.custom_start_date,
                            start_time: t.custom_start_date == "" || t.custom_start_date == undefined ?
                                t.time_frame_start : t.custom_start_date,
                            end_time: t.time_frame_end,
                            splittable: t.splittable,
                            time_frame_end: t.time_frame_end,
                            users_count: t.users_count,
                            time_allocated_labour: time_allocated_labour,
                            suggested_users: t.suggested_users,
                            crm_users: t.crm_users,
                            duration: duration,
                            list_id: t.list_id,
                            time_allocated_adjusted: t.time_allocated_adjusted,
                            is_custom: t.is_custom,
                            resources: {},
                        };
                    });
                lists.push({
                    id: c.id,
                    list_name: c.name,
                    consecutive: c.consecutive,
                    position: c.position,
                    tasks: tasks,
                });
            });
            return lists;
        },
        update_tasks_list(updated_task = null) {
            this.request_loading = true;
            let _this = this;
            // update custom start date for the task to be extended
            this.temp_list.forEach((list) => {
                list.tasks.forEach((task) => {
                    if (updated_task != null && task["id"] == updated_task.id) {
                        task.suggested_users = updated_task.suggested_users;
                    }
                    if (task["id"] == this.selected_request.job_proposal_id) {
                        task["custom_start_date"] =
                            this.suggested_date || task["time_frame_end"];
                        return;
                    }
                });
            });
            let data = {
                tasks_lists: this.prepare_data(this.temp_list),
                proposal_id: this.selected_request.proposal_id,
            };
            this.get_suggested_plan(data).then(
                (response) => {
                    _this.suggested_deadline = response.suggested_deadline;
                    _this.suggested_tasks = _this.project_plan_lists.map((c) => {
                        return {
                            id: c.id,
                            name: c.name,
                            tasks: response.suggested_plan.filter((l) => l.list_id == c.id),
                        };
                    });
                    this.request_loading = false;
                },
                (reason) => {
                    this.request_loading = false;
                    this.$message({
                        type: "warning",
                        dangerouslyUseHTMLString: true,
                        message: `Check below errors: <br> ${reason}`,
                    });
                }
            );
        },
        edit_extension_request(extension_request) {
            let _this = this;
            this.selected_request = extension_request;
            this.extension_request_dialog = true;
            this.set_inputs_to_default();
            this.temp_list = [];
            //this.loading = true;
            this.$http
                .get(`/proposal_current_plan/${this.selected_request.proposal_id}`)
                .then((response) => {

                    this.project_plan_lists = response.data;
                    this.proposal = this.selected_request.proposal;
                    this.temp_list = response.data;
                    this.update_tasks_list();
                });

            return false;
        },
        onDelete(extension) {
          this.$confirm('This will delete the current task extension. Continue?',
            'info', {
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              type: 'warning',
            }).then(() => {
            this.$http.delete(`/delete_jp_extension/${extension.id}`).then(
                (response) => {
                  this.$message({
                    type: 'success',
                    message: 'Task extension deleted successfully',
                  });
                  this.fill_data();
                },
                (response) => {
                  this.$message({
                    type: 'error',
                    message: response.data,
                  });
                }
            );
          });
        },
        set_inputs_to_default(mills = 300) {
            setTimeout(function() {
                Array.from(
                    document.querySelectorAll(".project_planner_view .el-input")
                ).forEach(function(e, i) {
                    e.classList.add("browser-default");
                    if (e.querySelector("input") != null)
                        e.querySelector("input").className += " browser-default";
                });
            }, mills);
        },
        confirm_changes() {
            let status = this.request_statuses.filter(
                (c) => c.id == this.selected_request.status.id
            )[0];
            if (status.name == "PENDING") {
                this.$message({ type: "warning", message: "Please update status!" });
                return;
            }
            if (this.selected_request.reason.id == undefined) {
                this.$message({ type: "warning", message: "Please select reason!" });
                return;
            }
            let data = {
                id: this.selected_request.id,
                extension_status_id: this.selected_request.status.id,
                extension_reason_id: this.selected_request.reason.id,
            };
            if (status.name == "APPROVED") {
                let plan = {
                    suggested_plan: this.suggested_tasks,
                    proposal_id: this.selected_request.proposal_id,
                    suggested_deadline: this.suggested_deadline,
                };
                data = Object.assign({}, data, plan);
            }

            this.$http
                .post("/update_request_extension", { request_extension: data })
                .then((response) => {
                    this.fill_data();
                    this.extension_request_dialog = false;
                    this.selected_request = { status: {}, reason: {} };
                });
        },
        tableRowClassName({row}) {
          if (row.completed === true) {
            return 'warning-row';
          }
          return '';
        }
    },
    computed: {},
};
</script>

<style>
.request_val {
    font-weight: 300;
}

.el-table .warning-row {
  background: oldlace;
}
</style>
