<template>
  <div>
    <modal
      name="users_kpi_dashboard_list"
      :height="700"
      :width="1200"
      :adaptive="true"
    >
      <div class="card ">
        <div class="card-content  ">
          <span class="card-title"
            >{{ list_title }}
            <hr
          /></span>
          <div class="progress" v-show="is_loading">
            <div class="indeterminate"></div>
          </div>
          <el-table :data="rows" style="width: 100%" height="600">
            <el-table-column
              prop="proposal_id"
              label="Proposal #"
              sortable
              width="120"
            >
            </el-table-column>

            <el-table-column prop="account_name" label="Account" sortable>
            </el-table-column>

            <el-table-column prop="job_name" label="Job Name" sortable>
            </el-table-column>

            <el-table-column
              prop="time_allocated"
              label="Time allocated"
              width="160"
              sortable
            >
              <template slot-scope="props">
                {{ props.row.time_allocated }} min.
              </template>
            </el-table-column>

            <el-table-column
              prop="time_spent"
              label="Time spent"
              width="120"
              sortable
            >
              <template slot-scope="props">
                {{ props.row.time_spent }} min.
              </template>
            </el-table-column>
            <el-table-column
              prop="completed_date"
              label="Completed date"
              width="130"
              sortable
            >
            </el-table-column>
          </el-table>
        </div>
        <div class="card-action"></div>
      </div>
    </modal>

    <modal
      name="sales_dashboard_kpi_list"
      :height="700"
      :width="1200"
      :adaptive="true"
    >
      <div class="card " id="card_id">
        <div class="card-content  ">
          <span class="card-title"
            >{{ list_title }}
            <hr
          /></span>
          <div class="progress" v-show="is_loading">
            <div class="indeterminate"></div>
          </div>
          <el-table :data="rows" style="width: 100%" height="600">
            <el-table-column
              v-for="column in columns"
              v-bind:key="column"
              v-bind:prop="column"
              v-bind:label="column | capitalize"
              sortable
            >
            </el-table-column>
          </el-table>
        </div>
        <div class="card-action"></div>
      </div>
    </modal>

    <!-- new staff KPI start -->

    <div v-loading="main_loading" id="card_id1">
      <div>
        <el-row>
          <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
            <h2 style="font-size:20px;margin-bottom:20px">
              KPI DASHBOARD
            </h2>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
            <!--- STAR COLOR CODE START --->
            <el-row
              style="padding:15px;"
              v-for="colorcode in users_data"
              :key="colorcode.id"
              v-show="colorcode.user_id == current_user"
            >
              <p>COLOR KEY:</p>
              <el-col
                :xs="4"
                :sm="4"
                :md="4"
                :lg="4"
                :xl="4"
                style="background-color:#cfb53b;font-size:9px;padding:1px 3px;color:#fff;text-align:center"
              >
                {{ colorcode.user_target_section.weightin_gold_message }}
              </el-col>
              <el-col
                :xs="4"
                :sm="4"
                :md="4"
                :lg="4"
                :xl="4"
                style="background-color:#27d40a;font-size:9px;padding:1px 3px;color:#fff;text-align:center"
              >
                {{ colorcode.user_target_section.weightin_green_message }}
              </el-col>
              <el-col
                :xs="4"
                :sm="4"
                :md="4"
                :lg="4"
                :xl="4"
                style="background-color:#fedd01;font-size:9px;padding:1px 3px;color:#fff;text-align:center"
              >
                {{ colorcode.user_target_section.weightin_yellow_message }}
              </el-col>
              <el-col
                :xs="4"
                :sm="4"
                :md="4"
                :lg="4"
                :xl="4"
                style="background-color:orange;font-size:9px;padding:1px 3px;color:#fff;text-align:center"
              >
                {{ colorcode.user_target_section.weightin_orange_message }}
              </el-col>
              <el-col
                :xs="4"
                :sm="4"
                :md="4"
                :lg="4"
                :xl="4"
                style="background-color:red;font-size:9px;padding:1px 3px;color:#fff;text-align:center"
              >
                {{ colorcode.user_target_section.weightin_red_message }}
              </el-col>
            </el-row>
            <!--- STAR COLOR CODE END --->
          </el-col>
        </el-row>
        <hr />
        <div>
          <el-date-picker
            v-model="selected_date_range"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
            style="width: auto !important;"
            :picker-options="pickerOptions2"
            v-on:change="fill_data"
          >
          </el-date-picker>

          <el-select
            v-model="selected_roles"
            multiple
            collapse-tags
            style="margin-left: 20px;"
            placeholder="Select role(s)"
            @change="fill_data"
          >
            <el-option
              v-for="role in roles_list"
              :key="role.id"
              :label="role.name"
              :value="role.id"
            ></el-option>
          </el-select>
        </div>
        <br />

        <h3 style="font-size:20px;margin-bottom:10px;">Weekly Overview</h3>

        <el-row :gutter="0">
          <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
            <div
              style="border:1px solid #7f7f7f; padding:10px; border-radius:20px;"
            >
              <table class="responsive-table striped kpitable">
                <thead>
                  <tr>
                    <th rowspan="2">Username</th>
                    <th rowspan="2">Time Sheet</th>
                    <th rowspan="2">Performance</th>
                    <th rowspan="2">Quality (NCR)</th>
                    <th rowspan="2">Turnaround</th>
                    <th rowspan="2">Upskilling/Goals</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="user in users_data" :key="user.id" valign="top">
                    <td>
                      {{ user.user_name }}
                    </td>
                    <td>
                      <div
                          class="color-box"
                          :class="time_sheet_color(user)"
                      />

                      <div class="isadmin" v-show="is_admin == '1'">
                        <b>Timesheet:</b>{{ user.time_sheet }}mins <br />
                        <b>Target:</b>{{ user.total_target_minutes }} mins<br />
                        <b>Reached:</b> {{ user.weighin_time_sheet_total }}%
                        <br />
                        <b>Weigh-in Rate:</b> {{ user.timeframe_perc }}%
                      </div>
                    </td>
                    <td>
                      <div
                        v-show="
                          user.user_target_section.by_production_minutes == 1
                        "
                      >
                        <div
                          v-show="user.user_target_section.performance_billable"
                        >
                          <div
                            v-if="
                              user.percentage_billable >=
                                user.user_target_section.performance_green
                            "
                            class="color-box greenbox"
                          >
                            Production Minutes
                          </div>
                          <div
                            v-else-if="
                              user.percentage_billable >=
                                user.user_target_section.performance_yellow
                            "
                            class="color-box yellowbox"
                          >
                            Production Minutes
                          </div>
                          <div
                            v-else-if="
                              user.percentage_billable >=
                                user.user_target_section.performance_orange
                            "
                            class="color-box orangebox"
                          >
                            Production Minutes
                          </div>
                          <div v-else>
                            <div class="color-box redbox">
                              Production Minutes
                            </div>
                          </div>
                          <div class="isadmin" v-show="is_admin == '1'">
                            <a
                              v-on:click="
                                show_user_kpi_dashboard_tasks(
                                  user.user_id,
                                  user.user_name
                                )
                              "
                              ><b>Billable Minutes:</b
                              >{{ user.time_allocated_billable }}mins
                            </a>
                            <br />
                            <b>Target:</b>
                            {{
                              user.user_target_section.performance_total
                            }}%<br />
                            <b>Reached:</b> {{ user.percentage_billable }}%
                            <br />
                            <b>Weigh-in Rate:</b>
                            {{ user.production_mintues_billable_total }}%
                          </div>
                        </div>
                        <div
                          v-show="
                            !user.user_target_section.performance_billable
                          "
                        >
                          <div
                            v-if="
                              user.percentage_total >=
                                user.user_target_section.performance_green
                            "
                            class="color-box greenbox"
                          >
                            Production Minutes
                          </div>
                          <div
                            v-else-if="
                              user.percentage_total >=
                                user.user_target_section.performance_yellow
                            "
                            class="color-box yellowbox"
                          >
                            Production Minutes
                          </div>
                          <div
                            v-else-if="
                              user.percentage_total >=
                                user.user_target_section.performance_orange
                            "
                            class="color-box orangebox"
                          >
                            Production Minutes
                          </div>
                          <div v-else>
                            <div class="color-box redbox">
                              Production Minutes
                            </div>
                          </div>
                          <div class="isadmin" v-show="is_admin == '1'">
                            <a
                              v-on:click="
                                show_user_kpi_dashboard_tasks(
                                  user.user_id,
                                  user.user_name
                                )
                              "
                              ><b>Total Minutes:</b
                              >{{ user.total_minutes }}mins</a
                            ><br />
                            <b>Target:</b>
                            {{
                              user.user_target_section.performance_total
                            }}%<br />
                            <b>Reached:</b> {{ user.percentage_total }}%
                            <br />
                            <b>Weigh-in Rate:</b>
                            {{ user.production_mintues_total }}%
                          </div>
                        </div>
                      </div>
                      <!--- SALES MINUTES SECTION --->
                      <div
                        v-show="
                          user.user_target_section.by_sales_minutes == 1 &&
                            user.user_target_section.sales_minutes_billable ==
                              0 &&
                            user.user_target_section.sales_minutes_approved == 0
                        "
                      >
                        <div
                          v-if="
                            user.sales_minutes_total_percentage >=
                              user.user_target_section.performance_green
                          "
                          class="color-box greenbox"
                        >
                          Sales Minutes
                        </div>
                        <div
                          v-else-if="
                            user.sales_minutes_total_percentage >=
                              user.user_target_section.performance_yellow
                          "
                          class="color-box yellowbox"
                        >
                          Sales Minutes
                        </div>
                        <div
                          v-else-if="
                            user.sales_minutes_total_percentage >=
                              user.user_target_section.performance_orange
                          "
                          class="color-box orangebox"
                        >
                          Sales Minutes
                        </div>
                        <div v-else>
                          <div class="color-box redbox">Sales Minutes</div>
                        </div>
                        <div class="isadmin" v-show="is_admin == '1'">
                          <a
                            v-on:click="
                              show_sales_kpi_dashboard_tasks(
                                user.user_id,
                                user.user_name
                              )
                            "
                          >
                            <b>Sales Minutes Total:</b>{{ user.total }} mins</a
                          ><br />
                          <b>Target:</b
                          >{{ user.sales_minutes_average }} mins<br />
                          <b>Reached:</b>
                          {{ user.sales_minutes_total_percentage }}% <br />
                          <b>Weigh-in Rate:</b>
                          {{ user.sales_mintues_total_perc }}%
                        </div>
                      </div>

                      <div
                        v-show="
                          user.user_target_section.by_sales_minutes == 1 &&
                            user.user_target_section.sales_minutes_billable ==
                              1 &&
                            user.user_target_section.sales_minutes_approved == 1
                        "
                      >
                        <div
                          v-if="
                            user.sales_mintues_approved_billable_percentage >=
                              user.user_target_section.performance_green
                          "
                          class="color-box greenbox"
                        >
                          Sales Minutes
                        </div>
                        <div
                          v-else-if="
                            user.sales_mintues_approved_billable_percentage >=
                              user.user_target_section.performance_yellow
                          "
                          class="color-box yellowbox"
                        >
                          Sales Minutes
                        </div>
                        <div
                          v-else-if="
                            user.sales_mintues_approved_billable_percentage >=
                              user.user_target_section.performance_orange
                          "
                          class="color-box orangebox"
                        >
                          Sales Minutes
                        </div>
                        <div v-else>
                          <div class="color-box redbox">Sales Minutes</div>
                        </div>
                        <div class="isadmin" v-show="is_admin == '1'">
                          <a
                            v-on:click="
                              show_sales_kpi_dashboard_tasks(
                                user.user_id,
                                user.user_name
                              )
                            "
                          >
                            <b>Sales Minutes Approved Billable:</b
                            >{{ user.approved_billable }} mins</a
                          >
                          <br />
                          <b>Target:</b>{{ user.sales_minutes_average }} mins
                          <br />
                          <b>Reached:</b>
                          {{ user.sales_mintues_approved_billable_percentage }}%
                          <br />
                          <b>Weigh-in Rate:</b>
                          {{ user.sales_mintues_approved_billable_perc }}%
                        </div>
                      </div>

                      <div
                        v-show="
                          user.user_target_section.by_sales_minutes == 1 &&
                            user.user_target_section.sales_minutes_billable ==
                              0 &&
                            user.user_target_section.sales_minutes_approved == 1
                        "
                      >
                        <div
                          v-if="
                            user.sales_mintues_approved_total_percentage >=
                              user.user_target_section.performance_green
                          "
                          class="color-box greenbox"
                        >
                          Sales Minutes
                        </div>
                        <div
                          v-else-if="
                            user.sales_mintues_approved_total_percentage >=
                              user.user_target_section.performance_yellow
                          "
                          class="color-box yellowbox"
                        >
                          Sales Minutes
                        </div>
                        <div
                          v-else-if="
                            user.sales_mintues_approved_total_percentage >=
                              user.user_target_section.performance_orange
                          "
                          class="color-box orangebox"
                        >
                          Sales Minutes
                        </div>
                        <div v-else>
                          <div class="color-box redbox">Sales Minutes</div>
                        </div>
                        <div class="isadmin" v-show="is_admin == '1'">
                          <a
                            v-on:click="
                              show_sales_kpi_dashboard_tasks(
                                user.user_id,
                                user.user_name
                              )
                            "
                          >
                            <b>Sales Minutes Total Approved:</b
                            >{{ user.total_approved }} mins</a
                          >
                          <br />
                          <b>Target:</b>{{ user.sales_minutes_average }} mins
                          <br />
                          <b>Reached:</b>
                          {{
                            user.sales_mintues_approved_total_percentage
                          }}%<br />
                          <b>Weigh-in Rate:</b>
                          {{ user.sales_mintues_approved_total_perc }}%
                        </div>
                      </div>

                      <div
                        v-show="
                          user.user_target_section.by_sales_minutes == 1 &&
                            user.user_target_section.sales_minutes_billable ==
                              1 &&
                            user.user_target_section.sales_minutes_approved == 0
                        "
                      >
                        <div
                          v-if="
                            user.sales_mintues_total_billable_percentage >=
                              user.user_target_section.performance_green
                          "
                          class="color-box greenbox"
                        >
                          Sales Minutes
                        </div>
                        <div
                          v-else-if="
                            user.sales_mintues_total_billable_percentage >=
                              user.user_target_section.performance_yellow
                          "
                          class="color-box yellowbox"
                        >
                          Sales Minutes
                        </div>
                        <div
                          v-else-if="
                            user.sales_mintues_total_billable_percentage >=
                              user.user_target_section.performance_orange
                          "
                          class="color-box orangebox"
                        >
                          Sales Minutes
                        </div>
                        <div v-else>
                          <div class="color-box redbox">Sales Minutes</div>
                        </div>
                        <div class="isadmin" v-show="is_admin == '1'">
                          <a
                            v-on:click="
                              show_sales_kpi_dashboard_tasks(
                                user.user_id,
                                user.user_name
                              )
                            "
                          >
                            <b>Sales Minutes Billable Total:</b
                            >{{ user.total_billable }} mins</a
                          ><br />
                          <b>Target:</b>{{ user.sales_minutes_average }}mins
                          <br />
                          <b>Reached:</b>
                          {{
                            user.sales_mintues_total_billable_percentage
                          }}%<br />
                          <b>Weigh-in Rate:</b>
                          {{ user.sales_mintues_total_billable_perc }}%
                        </div>
                      </div>

                      <!--- END SALES MINUTES SECTION --->

                      <!--- SALES AMOUNTS SECTION --->
                      <div
                        v-show="
                          user.user_target_section.by_sales_amount == 1 &&
                            user.user_target_section.sales_amount_approved ==
                              0 &&
                            user.user_target_section.sales_amount_billable == 0
                        "
                      >
                        <div
                          v-if="
                            user.sales_amount_total_percentage >=
                              user.user_target_section.performance_green
                          "
                          class="color-box greenbox"
                        >
                          Sales Amount
                        </div>
                        <div
                          v-else-if="
                            user.sales_amount_total_percentage >=
                              user.user_target_section.performance_yellow
                          "
                          class="color-box yellowbox"
                        >
                          Sales Amount
                        </div>
                        <div
                          v-else-if="
                            user.sales_amount_total_percentage >=
                              user.user_target_section.performance_orange
                          "
                          class="color-box orangebox"
                        >
                          Sales Amount
                        </div>
                        <div v-else>
                          <div class="color-box redbox">Sales Amount</div>
                        </div>
                        <div class="isadmin" v-show="is_admin == '1'">
                          <a
                            v-on:click="
                              show_sales_kpi_dashboard_tasks(
                                user.user_id,
                                user.user_name
                              )
                            "
                          >
                            <b>Sales Total Amount:</b>${{ user.total_contract
                            }}<br />
                          </a>
                          <b>Target:</b>${{ user.sales_amount_average }}
                          <br />
                          <b>Reached:</b>
                          {{ user.sales_amount_total_percentage }}%<br />
                          <b>Weigh-in Rate:</b>
                          {{ user.sales_amount_total_perc }}%
                        </div>
                      </div>

                      <div
                        v-show="
                          user.user_target_section.by_sales_amount == 1 &&
                            user.user_target_section.sales_amount_approved ==
                              1 &&
                            user.user_target_section.sales_amount_billable == 1
                        "
                      >
                        <div
                          v-if="
                            user.sales_amount_approved_billable_percentage >=
                              user.user_target_section.performance_green
                          "
                          class="color-box greenbox"
                        >
                          Sales Amount
                        </div>
                        <div
                          v-else-if="
                            user.sales_amount_approved_billable_percentage >=
                              user.user_target_section.performance_yellow
                          "
                          class="color-box yellowbox"
                        >
                          Sales Amount
                        </div>
                        <div
                          v-else-if="
                            user.sales_amount_approved_billable_percentage >=
                              user.user_target_section.performance_orange
                          "
                          class="color-box orangebox"
                        >
                          Sales Amount
                        </div>
                        <div v-else>
                          <div class="color-box redbox">Sales Amount</div>
                        </div>
                        <div class="isadmin" v-show="is_admin == '1'">
                          <a
                            v-on:click="
                              show_sales_kpi_dashboard_tasks(
                                user.user_id,
                                user.user_name
                              )
                            "
                          >
                            <b>Sales Approved & Billable Amount:</b>${{
                              user.amount_approved_billable
                            }} </a
                          ><br />
                          <b>Target:</b>${{ user.sales_amount_average }}<br />
                          <b>Reached:</b>
                          {{
                            user.sales_amount_approved_billable_percentage
                          }}%<br />
                          <b>Weigh-in Rate:</b>
                          {{ user.sales_amount_approved_billable_perc }}%
                        </div>
                      </div>

                      <div
                        v-show="
                          user.user_target_section.by_sales_amount == 1 &&
                            user.user_target_section.sales_amount_approved ==
                              1 &&
                            user.user_target_section.sales_amount_billable == 0
                        "
                      >
                        <div
                          v-if="
                            user.sales_amount_approved_total_percentage >=
                              user.user_target_section.performance_green
                          "
                          class="color-box greenbox"
                        >
                          Sales Amount
                        </div>
                        <div
                          v-else-if="
                            user.sales_amount_approved_total_percentage >=
                              user.user_target_section.performance_yellow
                          "
                          class="color-box yellowbox"
                        >
                          Sales Amount
                        </div>
                        <div
                          v-else-if="
                            user.sales_amount_approved_total_percentage >=
                              user.user_target_section.performance_orange
                          "
                          class="color-box orangebox"
                        >
                          Sales Amount
                        </div>
                        <div v-else>
                          <div class="color-box redbox">Sales Amount</div>
                        </div>
                        <div class="isadmin" v-show="is_admin == '1'">
                          <a
                            v-on:click="
                              show_sales_kpi_dashboard_tasks(
                                user.user_id,
                                user.user_name
                              )
                            "
                          >
                            <b>Sales Approved Amount:</b>${{
                              user.amount_approved_total
                            }} </a
                          ><br />
                          <b>Target:</b>${{ user.sales_amount_average }} <br />
                          <b>Reached:</b>
                          {{
                            user.sales_amount_approved_total_percentage
                          }}%<br />
                          <b>Weigh-in Rate:</b>
                          {{ user.sales_amount_approved_total_perc }}%
                        </div>
                      </div>

                      <div
                        v-show="
                          user.user_target_section.by_sales_amount == 1 &&
                            user.user_target_section.sales_amount_approved ==
                              0 &&
                            user.user_target_section.sales_amount_billable == 1
                        "
                      >
                        <div
                          v-if="
                            user.sales_amount_total_billable_percentage >=
                              user.user_target_section.performance_green
                          "
                          class="color-box greenbox"
                        >
                          Sales Amount
                        </div>
                        <div
                          v-else-if="
                            user.sales_amount_total_billable_percentage >=
                              user.user_target_section.performance_yellow
                          "
                          class="color-box yellowbox"
                        >
                          Sales Amount
                        </div>
                        <div
                          v-else-if="
                            user.sales_amount_total_billable_percentage >=
                              user.user_target_section.performance_orange
                          "
                          class="color-box orangebox"
                        >
                          Sales Amount
                        </div>
                        <div v-else>
                          <div class="color-box redbox">Sales Amount</div>
                        </div>
                        <div class="isadmin" v-show="is_admin == '1'">
                          <a
                            v-on:click="
                              show_sales_kpi_dashboard_tasks(
                                user.user_id,
                                user.user_name
                              )
                            "
                          >
                            <b>Sales Total Billable Amount:</b>${{
                              user.amount_total_billable
                            }} </a
                          ><br />
                          <b>Target:</b>${{ user.sales_amount_average }}<br />
                          <b>Reached:</b>
                          {{
                            user.sales_amount_total_billable_percentage
                          }}%<br />
                          <b>Weigh-in Rate:</b>
                          {{ user.sales_amount_total_billable_perc }}%
                        </div>
                      </div>

                      <!-- {{ user.percentage_billable }}
                   {{ user.percentage_total }} -->
                    </td>
                    <!--NCR SECTION START --->
                    <td>
                      <div
                        v-if="
                          user.ncrs_count >= user.user_target_section.fi_red
                        "
                        class="color-box redbox"
                      ></div>
                      <div
                        v-else-if="
                          user.ncrs_count >= user.user_target_section.rfi_orange
                        "
                        class="color-box orangebox"
                      ></div>
                      <div
                        v-else-if="
                          user.ncrs_count >= user.user_target_section.rfi_yellow
                        "
                        class="color-box yellowbox"
                      ></div>
                      <div v-else>
                        <div class="color-box greenbox"></div>
                      </div>
                      <div @click="dialogVisible = true"></div>

                      <div class="isadmin" v-show="is_admin == '1'">
                        <b>Total: </b> {{ user.ncrs_count }}<br />
                        <b>Weekly Limit: </b
                        >{{ user.user_target_section.rfi_total }}<br />
                        <b>Reached: </b> {{ user.ncr_perc }}%

                        <br />
                        <b>Weigh-in Rate:</b> {{ user.ncr_weighin_perc }}%
                      </div>
                    </td>
                    <!--NCR SECTION END --->
                    <td>
                      <!-- By Email Response -->
                      <div v-show="user.user_target_section.by_email == 1">
                        <div
                          v-if="
                            user.turnaround_returned >=
                              user.user_target_section.turnaround_email_green
                          "
                          class="color-box greenbox"
                        >
                          Email Response
                        </div>
                        <div
                          v-else-if="
                            user.turnaround_returned >=
                              user.user_target_section.turnaround_email_yellow
                          "
                          class="color-box yellowbox"
                        >
                          Email Response
                        </div>
                        <div
                          v-else-if="
                            user.turnaround_returned >=
                              user.user_target_section.turnaround_email_orange
                          "
                          class="color-box orangebox"
                        >
                          Email Response
                        </div>
                        <div v-else>
                          <div class="color-box redbox">Email Response</div>
                        </div>
                        <div class="isadmin" v-show="is_admin == '1'">
                          <div>
                            <b>Reached: </b>
                            {{ user.turnaround_returned }}mins
                            <br />
                            <b>Weigh-in Rate:</b> {{ user.email_weighin_perc }}%
                          </div>
                        </div>
                      </div>
                      <!--By Task Response -->
                      <div v-show="user.user_target_section.by_task == 1">
                        <div
                          v-if="
                            user.task_response_perc >=
                              user.user_target_section.turnaround_green
                          "
                          class="color-box greenbox"
                        >
                          Task Response
                        </div>
                        <div
                          v-else-if="
                            user.task_response_perc >=
                              user.user_target_section.turnaround_yellow
                          "
                          class="color-box yellowbox"
                        >
                          Task Response
                        </div>
                        <div
                          v-else-if="
                            user.task_response_perc >=
                              user.user_target_section.turnaround_orange
                          "
                          class="color-box orangebox"
                        >
                          Task Response
                        </div>
                        <div v-else>
                          <div class="color-box redbox">Task Response</div>
                        </div>
                        <div class="isadmin" v-show="is_admin == '1'">
                          <div>
                            <b>Task Completed: </b
                            >{{ user.task_total_completion }}<br />
                            <b>Target: </b
                            >{{
                              user.user_target_section.turnaround_total
                            }}%<br />
                            <b>Response: </b>{{ user.task_response_perc }}%
                            <br />
                            <b>Weigh-in Rate:</b> {{ user.task_weighin_perc }}%
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div
                        v-if="
                          user.achieved_goal >=
                            user.user_target_section.upskilling_green
                        "
                        class="color-box greenbox"
                      ></div>
                      <div
                        v-else-if="
                          user.achieved_goal >=
                            user.user_target_section.upskilling_yellow
                        "
                        class="color-box yellowbox"
                      ></div>
                      <div
                        v-else-if="
                          user.achieved_goal >=
                            user.user_target_section.upskilling_orange
                        "
                        class="color-box orangebox"
                      ></div>
                      <div v-else>
                        <div class="color-box redbox"></div>
                      </div>
                      <div class="isadmin" v-show="is_admin == '1'">
                        <b>Total Achieved: </b> {{ user.achieved_goal }} <br />
                        <b>Goal Total: </b> {{ user.total_goal }} <br />
                        <b>Reached: </b> {{ user.goals_prec }} %
                        <br />
                        <b>Weigh-in Rate:</b>
                        {{ user.upskilling_weighin_perc }}%
                      </div>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
                <!-- <tfoot style="font-weight: bold;" v-if="users_data.length > 0">
              <tr style="border-top: 1px solid gray">
                <td>TOTAL</td>
                <td>{{ total_minutes("time_allocated_billable") }} min.</td>
                <td>{{ total_minutes("time_allocated_free") }} min.</td>
                <td>{{ total_minutes("total_minutes") }} min.</td>
                <td>{{ total_minutes("time_spent") }} min.</td>
                <td>{{ total_minutes("time_sheet") }} min.</td>
                <td>
                  {{
                    round(
                      total_minutes("percentage_billable") / users_data.length
                    )
                  }}
                  %
                </td>
                <td>
                  {{
                    round(total_minutes("percentage_total") / users_data.length)
                  }}
                  %
                </td>
              </tr>
            </tfoot> -->
              </table>
            </div>
          </el-col>
          <el-col
            :xs="24"
            :sm="24"
            :md="8"
            :lg="8"
            :xl="8"
            style="padding:15px;"
          >
            <div
              v-show="is_admin == '1'"
              style="padding:20px;background:#f3f3f3; border-radius:10px;float: left; width: 100%;"
            >
              <h2>OVERALL WEIGHIN</h2>
              <div v-for="usertarget in users_data" :key="usertarget.id">
                <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                  {{ usertarget.user_name }}
                </el-col>
                <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
                  <!-- admin weighin -->

                  <div
                    v-show="
                      usertarget.user_target_section.performance_billable ==
                        0 &&
                        usertarget.user_target_section.sales_minutes_approved ==
                          0 &&
                        usertarget.user_target_section.sales_minutes_billable ==
                          0 &&
                        usertarget.user_target_section.sales_amount_billable ==
                          0 &&
                        usertarget.user_target_section.sales_amount_approved ==
                          0
                    "
                  >
                    {{
                      usertarget.weighin_production_mintues_sm_total_sa_total
                    }}
                  </div>
                  <div
                    v-show="
                      usertarget.user_target_section.performance_billable ==
                        0 &&
                        usertarget.user_target_section.sales_minutes_approved ==
                          1 &&
                        usertarget.user_target_section.sales_minutes_billable ==
                          0 &&
                        usertarget.user_target_section.sales_amount_billable ==
                          0 &&
                        usertarget.user_target_section.sales_amount_approved ==
                          0
                    "
                  >
                    {{
                      usertarget.weighin_production_mintues_sm_approved_total_sa_total
                    }}
                  </div>
                  <div
                    v-show="
                      usertarget.user_target_section.performance_billable ==
                        0 &&
                        usertarget.user_target_section.sales_minutes_approved ==
                          0 &&
                        usertarget.user_target_section.sales_minutes_billable ==
                          1 &&
                        usertarget.user_target_section.sales_amount_billable ==
                          0 &&
                        usertarget.user_target_section.sales_amount_approved ==
                          0
                    "
                  >
                    {{
                      usertarget.weighin_production_mintues_sm_total_billable_sa_total
                    }}
                  </div>
                  <div
                    v-show="
                      usertarget.user_target_section.performance_billable ==
                        0 &&
                        usertarget.user_target_section.sales_minutes_approved ==
                          1 &&
                        usertarget.user_target_section.sales_minutes_billable ==
                          1 &&
                        usertarget.user_target_section.sales_amount_billable ==
                          0 &&
                        usertarget.user_target_section.sales_amount_approved ==
                          0
                    "
                  >
                    {{
                      usertarget.weighin_production_mintues_sm_approved_billable_sa_total
                    }}
                  </div>
                  <div
                    v-show="
                      usertarget.user_target_section.performance_billable ==
                        0 &&
                        usertarget.user_target_section.sales_minutes_approved ==
                          0 &&
                        usertarget.user_target_section.sales_minutes_billable ==
                          0 &&
                        usertarget.user_target_section.sales_amount_billable ==
                          1 &&
                        usertarget.user_target_section.sales_amount_approved ==
                          1
                    "
                  >
                    {{
                      usertarget.weighin_production_mintues_sm_total_sa_approved_billable
                    }}
                  </div>
                  <div
                    v-show="
                      usertarget.user_target_section.performance_billable ==
                        0 &&
                        usertarget.user_target_section.sales_minutes_approved ==
                          1 &&
                        usertarget.user_target_section.sales_minutes_billable ==
                          1 &&
                        usertarget.user_target_section.sales_amount_billable ==
                          1 &&
                        usertarget.user_target_section.sales_amount_approved ==
                          1
                    "
                  >
                    {{
                      usertarget.weighin_production_mintues_sm_approved_billable_sa_approved_billable
                    }}
                  </div>
                  <div
                    v-show="
                      usertarget.user_target_section.performance_billable ==
                        0 &&
                        usertarget.user_target_section.sales_minutes_approved ==
                          1 &&
                        usertarget.user_target_section.sales_minutes_billable ==
                          0 &&
                        usertarget.user_target_section.sales_amount_billable ==
                          1 &&
                        usertarget.user_target_section.sales_amount_approved ==
                          1
                    "
                  >
                    {{
                      usertarget.weighin_production_mintues_sm_approved_total_sa_approved_billable
                    }}
                  </div>
                  <div
                    v-show="
                      usertarget.user_target_section.performance_billable ==
                        0 &&
                        usertarget.user_target_section.sales_minutes_approved ==
                          0 &&
                        usertarget.user_target_section.sales_minutes_billable ==
                          1 &&
                        usertarget.user_target_section.sales_amount_billable ==
                          1 &&
                        usertarget.user_target_section.sales_amount_approved ==
                          1
                    "
                  >
                    {{
                      usertarget.weighin_production_mintues_sm_total_billable_sa_approved_billable
                    }}
                  </div>
                  <div
                    v-show="
                      usertarget.user_target_section.performance_billable ==
                        0 &&
                        usertarget.user_target_section.sales_minutes_approved ==
                          0 &&
                        usertarget.user_target_section.sales_minutes_billable ==
                          0 &&
                        usertarget.user_target_section.sales_amount_billable ==
                          0 &&
                        usertarget.user_target_section.sales_amount_approved ==
                          1
                    "
                  >
                    {{
                      usertarget.weighin_production_mintues_sm_total_sa_approved_total
                    }}
                  </div>
                  <div
                    v-show="
                      usertarget.user_target_section.performance_billable ==
                        0 &&
                        usertarget.user_target_section.sales_minutes_approved ==
                          1 &&
                        usertarget.user_target_section.sales_minutes_billable ==
                          1 &&
                        usertarget.user_target_section.sales_amount_billable ==
                          0 &&
                        usertarget.user_target_section.sales_amount_approved ==
                          1
                    "
                  >
                    {{
                      usertarget.weighin_production_mintues_sm_approved_billable_sa_approved_total
                    }}
                  </div>
                  <div
                    v-show="
                      usertarget.user_target_section.performance_billable ==
                        0 &&
                        usertarget.user_target_section.sales_minutes_approved ==
                          1 &&
                        usertarget.user_target_section.sales_minutes_billable ==
                          0 &&
                        usertarget.user_target_section.sales_amount_billable ==
                          1 &&
                        usertarget.user_target_section.sales_amount_approved ==
                          0
                    "
                  >
                    {{
                      usertarget.weighin_production_mintues_sm_approved_total_sa_approved_total
                    }}
                  </div>
                  <div
                    v-show="
                      usertarget.user_target_section.performance_billable ==
                        0 &&
                        usertarget.user_target_section.sales_minutes_approved ==
                          0 &&
                        usertarget.user_target_section.sales_minutes_billable ==
                          1 &&
                        usertarget.user_target_section.sales_amount_billable ==
                          0 &&
                        usertarget.user_target_section.sales_amount_approved ==
                          1
                    "
                  >
                    {{
                      usertarget.weighin_production_mintues_sm_total_billable_sa_approved_total
                    }}
                  </div>
                  <div
                    v-show="
                      usertarget.user_target_section.performance_billable ==
                        1 &&
                        usertarget.user_target_section.sales_minutes_approved ==
                          0 &&
                        usertarget.user_target_section.sales_minutes_billable ==
                          0 &&
                        usertarget.user_target_section.sales_amount_billable ==
                          0 &&
                        usertarget.user_target_section.sales_amount_approved ==
                          0
                    "
                  >
                    {{
                      usertarget.weighin_production_billable_total_sm_total_sa_total
                    }}
                  </div>
                  <div
                    v-show="
                      usertarget.user_target_section.performance_billable ==
                        1 &&
                        usertarget.user_target_section.sales_minutes_approved ==
                          1 &&
                        usertarget.user_target_section.sales_minutes_billable ==
                          0 &&
                        usertarget.user_target_section.sales_amount_billable ==
                          0 &&
                        usertarget.user_target_section.sales_amount_approved ==
                          0
                    "
                  >
                    {{
                      usertarget.weighin_production_billable_total_sm_approved_total_sa_total
                    }}
                  </div>
                  <div
                    v-show="
                      usertarget.user_target_section.performance_billable ==
                        1 &&
                        usertarget.user_target_section.sales_minutes_approved ==
                          0 &&
                        usertarget.user_target_section.sales_minutes_billable ==
                          1 &&
                        usertarget.user_target_section.sales_amount_billable ==
                          0 &&
                        usertarget.user_target_section.sales_amount_approved ==
                          0
                    "
                  >
                    {{
                      usertarget.weighin_production_billable_total_sm_total_billable_sa_total
                    }}
                  </div>
                  <div
                    v-show="
                      usertarget.user_target_section.performance_billable ==
                        1 &&
                        usertarget.user_target_section.sales_minutes_approved ==
                          1 &&
                        usertarget.user_target_section.sales_minutes_billable ==
                          1 &&
                        usertarget.user_target_section.sales_amount_billable ==
                          0 &&
                        usertarget.user_target_section.sales_amount_approved ==
                          0
                    "
                  >
                    {{
                      usertarget.weighin_production_billable_total_sm_approved_billable_sa_total
                    }}
                  </div>
                  <div
                    v-show="
                      usertarget.user_target_section.performance_billable ==
                        1 &&
                        usertarget.user_target_section.sales_minutes_approved ==
                          0 &&
                        usertarget.user_target_section.sales_minutes_billable ==
                          0 &&
                        usertarget.user_target_section.sales_amount_billable ==
                          1 &&
                        usertarget.user_target_section.sales_amount_approved ==
                          1
                    "
                  >
                    {{
                      usertarget.weighin_production_billable_total_sm_total_sa_approved_billable
                    }}
                  </div>
                  <div
                    v-show="
                      usertarget.user_target_section.performance_billable ==
                        1 &&
                        usertarget.user_target_section.sales_minutes_approved ==
                          1 &&
                        usertarget.user_target_section.sales_minutes_billable ==
                          1 &&
                        usertarget.user_target_section.sales_amount_billable ==
                          1 &&
                        usertarget.user_target_section.sales_amount_approved ==
                          1
                    "
                  >
                    {{
                      usertarget.weighin_production_billable_total_sm_approved_billable_sa_approved_billable
                    }}
                  </div>
                  <div
                    v-show="
                      usertarget.user_target_section.performance_billable ==
                        1 &&
                        usertarget.user_target_section.sales_minutes_approved ==
                          1 &&
                        usertarget.user_target_section.sales_minutes_billable ==
                          0 &&
                        usertarget.user_target_section.sales_amount_billable ==
                          1 &&
                        usertarget.user_target_section.sales_amount_approved ==
                          1
                    "
                  >
                    {{
                      usertarget.weighin_production_billable_total_sm_approved_total_sa_approved_billable
                    }}
                  </div>
                  <div
                    v-show="
                      usertarget.user_target_section.performance_billable ==
                        1 &&
                        usertarget.user_target_section.sales_minutes_approved ==
                          0 &&
                        usertarget.user_target_section.sales_minutes_billable ==
                          1 &&
                        usertarget.user_target_section.sales_amount_billable ==
                          1 &&
                        usertarget.user_target_section.sales_amount_approved ==
                          1
                    "
                  >
                    {{
                      usertarget.weighin_production_billable_total_sm_total_billable_sa_approved_billable
                    }}
                  </div>
                  <div
                    v-show="
                      usertarget.user_target_section.performance_billable ==
                        1 &&
                        usertarget.user_target_section.sales_minutes_approved ==
                          0 &&
                        usertarget.user_target_section.sales_minutes_billable ==
                          0 &&
                        usertarget.user_target_section.sales_amount_billable ==
                          0 &&
                        usertarget.user_target_section.sales_amount_approved ==
                          1
                    "
                  >
                    {{
                      usertarget.weighin_production_billable_total_sm_total_sa_approved_total
                    }}
                  </div>
                  <div
                    v-show="
                      usertarget.user_target_section.performance_billable ==
                        1 &&
                        usertarget.user_target_section.sales_minutes_approved ==
                          1 &&
                        usertarget.user_target_section.sales_minutes_billable ==
                          1 &&
                        usertarget.user_target_section.sales_amount_billable ==
                          0 &&
                        usertarget.user_target_section.sales_amount_approved ==
                          1
                    "
                  >
                    {{
                      usertarget.weighin_production_billable_total_sm_approved_billable_sa_approved_total
                    }}
                  </div>
                  <div
                    v-show="
                      usertarget.user_target_section.performance_billable ==
                        1 &&
                        usertarget.user_target_section.sales_minutes_approved ==
                          1 &&
                        usertarget.user_target_section.sales_minutes_billable ==
                          0 &&
                        usertarget.user_target_section.sales_amount_billable ==
                          0 &&
                        usertarget.user_target_section.sales_amount_approved ==
                          1
                    "
                  >
                    {{
                      usertarget.weighin_production_billable_total_sm_approved_total_sa_approved_total
                    }}
                  </div>
                  <div
                    v-show="
                      usertarget.user_target_section.performance_billable ==
                        1 &&
                        usertarget.user_target_section.sales_minutes_approved ==
                          0 &&
                        usertarget.user_target_section.sales_minutes_billable ==
                          1 &&
                        usertarget.user_target_section.sales_amount_billable ==
                          0 &&
                        usertarget.user_target_section.sales_amount_approved ==
                          1
                    "
                  >
                    {{
                      usertarget.weighin_production_billable_total_sm_total_billable_sa_approved_total
                    }}
                  </div>

                  <!-- admin weighin -->
                </el-col>
              </div>
            </div>

            <div
              style="padding:15px;"
              v-for="usertarget in users_data"
              :key="usertarget.id"
              v-show="usertarget.user_id == current_user"
            >
              <p style="text-align:center">
                <em><b>My Weigh In</b></em> Ranking
              </p>

              <!-- WEIGHT IN % START-->
              <!-- PRODUCTION MINTUES TOTAOTL, SM TOTAL, SA TOTAL -->
              <div
                v-show="
                  usertarget.user_target_section.performance_billable == 0 &&
                    usertarget.user_target_section.sales_minutes_approved ==
                      0 &&
                    usertarget.user_target_section.sales_minutes_billable ==
                      0 &&
                    usertarget.user_target_section.sales_amount_billable == 0 &&
                    usertarget.user_target_section.sales_amount_approved == 0
                "
              >
                <!-- star details -->
                <div
                  v-if="
                    usertarget.weighin_production_mintues_sm_total_sa_total >=
                      usertarget.user_target_section.weightin_gold
                  "
                >
                  <i class="el-icon-star-on weighinstar stargold"
                    ><br />
                    <p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_gold_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_mintues_sm_total_sa_total >=
                      usertarget.user_target_section.weightin_green
                  "
                >
                  <i class="el-icon-star-on weighinstar stargreen"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_green_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_mintues_sm_total_sa_total >=
                      usertarget.user_target_section.weightin_yellow
                  "
                >
                  <i class="el-icon-star-on weighinstar staryellow"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_yellow_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_mintues_sm_total_sa_total >=
                      usertarget.user_target_section.weightin_orange
                  "
                >
                  <i class="el-icon-star-on weighinstar starorange"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_orange_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div v-else>
                  <i class="el-icon-star-on weighinstar starred"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_red_message
                      }}</em>
                    </p></i
                  >
                </div>
                <!---star details end -->
              </div>
              <!--- end -->

              <!-- PRODUCTION MINTUES TOTAL, SM APPROVED, SA TOTAL -->
              <div
                v-show="
                  usertarget.user_target_section.performance_billable == 0 &&
                    usertarget.user_target_section.sales_minutes_approved ==
                      1 &&
                    usertarget.user_target_section.sales_minutes_billable ==
                      0 &&
                    usertarget.user_target_section.sales_amount_billable == 0 &&
                    usertarget.user_target_section.sales_amount_approved == 0
                "
              >
                <!-- star details -->
                <div
                  v-if="
                    usertarget.weighin_production_mintues_sm_approved_total_sa_total >=
                      usertarget.user_target_section.weightin_gold
                  "
                >
                  <i class="el-icon-star-on weighinstar stargold"
                    ><br />
                    <p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_gold_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_mintues_sm_approved_total_sa_total >=
                      usertarget.user_target_section.weightin_green
                  "
                >
                  <i class="el-icon-star-on weighinstar stargreen"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_green_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_mintues_sm_approved_total_sa_total >=
                      usertarget.user_target_section.weightin_yellow
                  "
                >
                  <i class="el-icon-star-on weighinstar staryellow"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_yellow_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_mintues_sm_approved_total_sa_total >=
                      usertarget.user_target_section.weightin_orange
                  "
                >
                  <i class="el-icon-star-on weighinstar starorange"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_orange_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div v-else>
                  <i class="el-icon-star-on weighinstar starred"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_red_message
                      }}</em>
                    </p></i
                  >
                </div>
                <!---star details end -->
              </div>
              <!--- end -->

              <!-- PRODUCTION MINTUES TOTAL, SM TOTAL BILLABLE, SA TOTAL -->
              <div
                v-show="
                  usertarget.user_target_section.performance_billable == 0 &&
                    usertarget.user_target_section.sales_minutes_approved ==
                      0 &&
                    usertarget.user_target_section.sales_minutes_billable ==
                      1 &&
                    usertarget.user_target_section.sales_amount_billable == 0 &&
                    usertarget.user_target_section.sales_amount_approved == 0
                "
              >
                <!-- star details -->
                <div
                  v-if="
                    usertarget.weighin_production_mintues_sm_total_billable_sa_total >=
                      usertarget.user_target_section.weightin_gold
                  "
                >
                  <i class="el-icon-star-on weighinstar stargold"
                    ><br />
                    <p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_gold_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_mintues_sm_total_billable_sa_total >=
                      usertarget.user_target_section.weightin_green
                  "
                >
                  <i class="el-icon-star-on weighinstar stargreen"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_green_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_mintues_sm_total_billable_sa_total >=
                      usertarget.user_target_section.weightin_yellow
                  "
                >
                  <i class="el-icon-star-on weighinstar staryellow"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_yellow_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_mintues_sm_total_billable_sa_total >=
                      usertarget.user_target_section.weightin_orange
                  "
                >
                  <i class="el-icon-star-on weighinstar starorange"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_orange_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div v-else>
                  <i class="el-icon-star-on weighinstar starred"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_red_message
                      }}</em>
                    </p></i
                  >
                </div>
                <!---star details end -->
              </div>
              <!--- end -->

              <!-- PRODUCTION MINTUES TOTAL, SM APPROVED BILLABLE, SA TOTAL -->
              <div
                v-show="
                  usertarget.user_target_section.performance_billable == 0 &&
                    usertarget.user_target_section.sales_minutes_approved ==
                      1 &&
                    usertarget.user_target_section.sales_minutes_billable ==
                      1 &&
                    usertarget.user_target_section.sales_amount_billable == 0 &&
                    usertarget.user_target_section.sales_amount_approved == 0
                "
              >
                <!-- star details -->
                <div
                  v-if="
                    usertarget.weighin_production_mintues_sm_approved_billable_sa_total >=
                      usertarget.user_target_section.weightin_gold
                  "
                >
                  <i class="el-icon-star-on weighinstar stargold"
                    ><br />
                    <p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_gold_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_mintues_sm_approved_billable_sa_total >=
                      usertarget.user_target_section.weightin_green
                  "
                >
                  <i class="el-icon-star-on weighinstar stargreen"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_green_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_mintues_sm_approved_billable_sa_total >=
                      usertarget.user_target_section.weightin_yellow
                  "
                >
                  <i class="el-icon-star-on weighinstar staryellow"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_yellow_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_mintues_sm_approved_billable_sa_total >=
                      usertarget.user_target_section.weightin_orange
                  "
                >
                  <i class="el-icon-star-on weighinstar starorange"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_orange_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div v-else>
                  <i class="el-icon-star-on weighinstar starred"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_red_message
                      }}</em>
                    </p></i
                  >
                </div>
                <!---star details end -->
              </div>
              <!--- end -->

              <!-- PRODUCTION MINTUES TOTAL, SM TOTAL, SA APPROVED BILLABLE -->
              <div
                v-show="
                  usertarget.user_target_section.performance_billable == 0 &&
                    usertarget.user_target_section.sales_minutes_approved ==
                      0 &&
                    usertarget.user_target_section.sales_minutes_billable ==
                      0 &&
                    usertarget.user_target_section.sales_amount_billable == 1 &&
                    usertarget.user_target_section.sales_amount_approved == 1
                "
              >
                <!-- star details -->
                <div
                  v-if="
                    usertarget.weighin_production_mintues_sm_total_sa_approved_billable >=
                      usertarget.user_target_section.weightin_gold
                  "
                >
                  <i class="el-icon-star-on weighinstar stargold"
                    ><br />
                    <p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_gold_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_mintues_sm_total_sa_approved_billable >=
                      usertarget.user_target_section.weightin_green
                  "
                >
                  <i class="el-icon-star-on weighinstar stargreen"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_green_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_mintues_sm_total_sa_approved_billable >=
                      usertarget.user_target_section.weightin_yellow
                  "
                >
                  <i class="el-icon-star-on weighinstar staryellow"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_yellow_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_mintues_sm_total_sa_approved_billable >=
                      usertarget.user_target_section.weightin_orange
                  "
                >
                  <i class="el-icon-star-on weighinstar starorange"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_orange_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div v-else>
                  <i class="el-icon-star-on weighinstar starred"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_red_message
                      }}</em>
                    </p></i
                  >
                </div>
                <!---star details end -->
              </div>
              <!--- end -->

              <!-- PRODUCTION MINTUES TOTAL, SM APPROVED BILLABLE, SA APPROVED BILLABLE -->
              <div
                v-show="
                  usertarget.user_target_section.performance_billable == 0 &&
                    usertarget.user_target_section.sales_minutes_approved ==
                      1 &&
                    usertarget.user_target_section.sales_minutes_billable ==
                      1 &&
                    usertarget.user_target_section.sales_amount_billable == 1 &&
                    usertarget.user_target_section.sales_amount_approved == 1
                "
              >
                <!-- star details -->
                <div
                  v-if="
                    usertarget.weighin_production_mintues_sm_approved_billable_sa_approved_billable >=
                      usertarget.user_target_section.weightin_gold
                  "
                >
                  <i class="el-icon-star-on weighinstar stargold"
                    ><br />
                    <p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_gold_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_mintues_sm_approved_billable_sa_approved_billable >=
                      usertarget.user_target_section.weightin_green
                  "
                >
                  <i class="el-icon-star-on weighinstar stargreen"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_green_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_mintues_sm_approved_billable_sa_approved_billable >=
                      usertarget.user_target_section.weightin_yellow
                  "
                >
                  <i class="el-icon-star-on weighinstar staryellow"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_yellow_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_mintues_sm_approved_billable_sa_approved_billable >=
                      usertarget.user_target_section.weightin_orange
                  "
                >
                  <i class="el-icon-star-on weighinstar starorange"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_orange_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div v-else>
                  <i class="el-icon-star-on weighinstar starred"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_red_message
                      }}</em>
                    </p></i
                  >
                </div>
                <!---star details end -->
              </div>
              <!--- end -->

              <!-- PRODUCTION MINTUES TOTAL, SM APPROVED, SA APPROVED BILLABLE -->
              <div
                v-show="
                  usertarget.user_target_section.performance_billable == 0 &&
                    usertarget.user_target_section.sales_minutes_approved ==
                      1 &&
                    usertarget.user_target_section.sales_minutes_billable ==
                      0 &&
                    usertarget.user_target_section.sales_amount_billable == 1 &&
                    usertarget.user_target_section.sales_amount_approved == 1
                "
              >
                <!-- star details -->
                <div
                  v-if="
                    usertarget.weighin_production_mintues_sm_approved_total_sa_approved_billable >=
                      usertarget.user_target_section.weightin_gold
                  "
                >
                  <i class="el-icon-star-on weighinstar stargold"
                    ><br />
                    <p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_gold_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_mintues_sm_approved_total_sa_approved_billable >=
                      usertarget.user_target_section.weightin_green
                  "
                >
                  <i class="el-icon-star-on weighinstar stargreen"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_green_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_mintues_sm_approved_total_sa_approved_billable >=
                      usertarget.user_target_section.weightin_yellow
                  "
                >
                  <i class="el-icon-star-on weighinstar staryellow"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_yellow_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_mintues_sm_approved_total_sa_approved_billable >=
                      usertarget.user_target_section.weightin_orange
                  "
                >
                  <i class="el-icon-star-on weighinstar starorange"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_orange_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div v-else>
                  <i class="el-icon-star-on weighinstar starred"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_red_message
                      }}</em>
                    </p></i
                  >
                </div>
                <!---star details end -->
              </div>
              <!--- end -->

              <!-- PRODUCTION MINTUES TOTAL, SM BILLABLE, SA APPROVED BILLABLE -->
              <div
                v-show="
                  usertarget.user_target_section.performance_billable == 0 &&
                    usertarget.user_target_section.sales_minutes_approved ==
                      0 &&
                    usertarget.user_target_section.sales_minutes_billable ==
                      1 &&
                    usertarget.user_target_section.sales_amount_billable == 1 &&
                    usertarget.user_target_section.sales_amount_approved == 1
                "
              >
                <!-- star details -->
                <div
                  v-if="
                    usertarget.weighin_production_mintues_sm_total_billable_sa_approved_billable >=
                      usertarget.user_target_section.weightin_gold
                  "
                >
                  <i class="el-icon-star-on weighinstar stargold"
                    ><br />
                    <p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_gold_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_mintues_sm_total_billable_sa_approved_billable >=
                      usertarget.user_target_section.weightin_green
                  "
                >
                  <i class="el-icon-star-on weighinstar stargreen"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_green_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_mintues_sm_total_billable_sa_approved_billable >=
                      usertarget.user_target_section.weightin_yellow
                  "
                >
                  <i class="el-icon-star-on weighinstar staryellow"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_yellow_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_mintues_sm_total_billable_sa_approved_billable >=
                      usertarget.user_target_section.weightin_orange
                  "
                >
                  <i class="el-icon-star-on weighinstar starorange"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_orange_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div v-else>
                  <i class="el-icon-star-on weighinstar starred"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_red_message
                      }}</em>
                    </p></i
                  >
                </div>
                <!---star details end -->
              </div>
              <!--- end -->

              <!-- PRODUCTION MINTUES TOTAL, SM TOTAL, SA APPROVED TOTAL -->
              <div
                v-show="
                  usertarget.user_target_section.performance_billable == 0 &&
                    usertarget.user_target_section.sales_minutes_approved ==
                      0 &&
                    usertarget.user_target_section.sales_minutes_billable ==
                      0 &&
                    usertarget.user_target_section.sales_amount_billable == 0 &&
                    usertarget.user_target_section.sales_amount_approved == 1
                "
              >
                {
                <!-- star details -->
                <div
                  v-if="
                    usertarget.weighin_production_mintues_sm_total_sa_approved_total >=
                      usertarget.user_target_section.weightin_gold
                  "
                >
                  <i class="el-icon-star-on weighinstar stargold"
                    ><br />
                    <p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_gold_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_mintues_sm_total_sa_approved_total >=
                      usertarget.user_target_section.weightin_green
                  "
                >
                  <i class="el-icon-star-on weighinstar stargreen"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_green_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_mintues_sm_total_sa_approved_total >=
                      usertarget.user_target_section.weightin_yellow
                  "
                >
                  <i class="el-icon-star-on weighinstar staryellow"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_yellow_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_mintues_sm_total_sa_approved_total >=
                      usertarget.user_target_section.weightin_orange
                  "
                >
                  <i class="el-icon-star-on weighinstar starorange"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_orange_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div v-else>
                  <i class="el-icon-star-on weighinstar starred"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_red_message
                      }}</em>
                    </p></i
                  >
                </div>
                <!---star details end -->
              </div>
              <!--- end -->

              <!-- PRODUCTION MINTUES TOTAL, SM APPROVED BILLABLE, SA APPROVED TOTAL -->
              <div
                v-show="
                  usertarget.user_target_section.performance_billable == 0 &&
                    usertarget.user_target_section.sales_minutes_approved ==
                      1 &&
                    usertarget.user_target_section.sales_minutes_billable ==
                      1 &&
                    usertarget.user_target_section.sales_amount_billable == 0 &&
                    usertarget.user_target_section.sales_amount_approved == 1
                "
              >
                <!-- star details -->
                <div
                  v-if="
                    usertarget.weighin_production_mintues_sm_approved_billable_sa_approved_total >=
                      usertarget.user_target_section.weightin_gold
                  "
                >
                  <i class="el-icon-star-on weighinstar stargold"
                    ><br />
                    <p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_gold_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_mintues_sm_approved_billable_sa_approved_total >=
                      usertarget.user_target_section.weightin_green
                  "
                >
                  <i class="el-icon-star-on weighinstar stargreen"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_green_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_mintues_sm_approved_billable_sa_approved_total >=
                      usertarget.user_target_section.weightin_yellow
                  "
                >
                  <i class="el-icon-star-on weighinstar staryellow"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_yellow_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_mintues_sm_approved_billable_sa_approved_total >=
                      usertarget.user_target_section.weightin_orange
                  "
                >
                  <i class="el-icon-star-on weighinstar starorange"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_orange_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div v-else>
                  <i class="el-icon-star-on weighinstar starred"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_red_message
                      }}</em>
                    </p></i
                  >
                </div>
                <!---star details end -->
              </div>
              <!--- end -->

              <!-- PRODUCTION MINTUES TOTAL, SM APPROVED TOTAL, SA APPROVED TOTAL -->
              <div
                v-show="
                  usertarget.user_target_section.performance_billable == 0 &&
                    usertarget.user_target_section.sales_minutes_approved ==
                      1 &&
                    usertarget.user_target_section.sales_minutes_billable ==
                      0 &&
                    usertarget.user_target_section.sales_amount_billable == 1 &&
                    usertarget.user_target_section.sales_amount_approved == 0
                "
              >
                <!-- star details -->
                <div
                  v-if="
                    usertarget.weighin_production_mintues_sm_approved_total_sa_approved_total >=
                      usertarget.user_target_section.weightin_gold
                  "
                >
                  <i class="el-icon-star-on weighinstar stargold"
                    ><br />
                    <p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_gold_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_mintues_sm_approved_total_sa_approved_total >=
                      usertarget.user_target_section.weightin_green
                  "
                >
                  <i class="el-icon-star-on weighinstar stargreen"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_green_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_mintues_sm_approved_total_sa_approved_total >=
                      usertarget.user_target_section.weightin_yellow
                  "
                >
                  <i class="el-icon-star-on weighinstar staryellow"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_yellow_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_mintues_sm_approved_total_sa_approved_total >=
                      usertarget.user_target_section.weightin_orange
                  "
                >
                  <i class="el-icon-star-on weighinstar starorange"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_orange_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div v-else>
                  <i class="el-icon-star-on weighinstar starred"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_red_message
                      }}</em>
                    </p></i
                  >
                </div>
                <!---star details end -->
              </div>
              <!--- end -->

              <!-- PRODUCTION MINTUES TOTAL, SM TOTAL BILLABLE, SA APPROVED TOTAL -->
              <div
                v-show="
                  usertarget.user_target_section.performance_billable == 0 &&
                    usertarget.user_target_section.sales_minutes_approved ==
                      0 &&
                    usertarget.user_target_section.sales_minutes_billable ==
                      1 &&
                    usertarget.user_target_section.sales_amount_billable == 0 &&
                    usertarget.user_target_section.sales_amount_approved == 1
                "
              >
                <!-- star details -->
                <div
                  v-if="
                    usertarget.weighin_production_mintues_sm_total_billable_sa_approved_total >=
                      usertarget.user_target_section.weightin_gold
                  "
                >
                  <i class="el-icon-star-on weighinstar stargold"
                    ><br />
                    <p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_gold_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_mintues_sm_total_billable_sa_approved_total >=
                      usertarget.user_target_section.weightin_green
                  "
                >
                  <i class="el-icon-star-on weighinstar stargreen"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_green_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_mintues_sm_total_billable_sa_approved_total >=
                      usertarget.user_target_section.weightin_yellow
                  "
                >
                  <i class="el-icon-star-on weighinstar staryellow"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_yellow_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_mintues_sm_total_billable_sa_approved_total >=
                      usertarget.user_target_section.weightin_orange
                  "
                >
                  <i class="el-icon-star-on weighinstar starorange"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_orange_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div v-else>
                  <i class="el-icon-star-on weighinstar starred"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_red_message
                      }}</em>
                    </p></i
                  >
                </div>
                <!---star details end -->
              </div>
              <!--- end -->

              <!-- PRODUCTION MINTUES BILLABLE, SM TOTAL, SA TOTAL -->
              <div
                v-show="
                  usertarget.user_target_section.performance_billable == 1 &&
                    usertarget.user_target_section.sales_minutes_approved ==
                      0 &&
                    usertarget.user_target_section.sales_minutes_billable ==
                      0 &&
                    usertarget.user_target_section.sales_amount_billable == 0 &&
                    usertarget.user_target_section.sales_amount_approved == 0
                "
              >
                <!-- star details -->
                <div
                  v-if="
                    usertarget.weighin_production_billable_total_sm_total_sa_total >=
                      usertarget.user_target_section.weightin_gold
                  "
                >
                  <i class="el-icon-star-on weighinstar stargold"
                    ><br />
                    <p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_gold_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_billable_total_sm_total_sa_total >=
                      usertarget.user_target_section.weightin_green
                  "
                >
                  <i class="el-icon-star-on weighinstar stargreen"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_green_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_billable_total_sm_total_sa_total >=
                      usertarget.user_target_section.weightin_yellow
                  "
                >
                  <i class="el-icon-star-on weighinstar staryellow"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_yellow_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_billable_total_sm_total_sa_total >=
                      usertarget.user_target_section.weightin_orange
                  "
                >
                  <i class="el-icon-star-on weighinstar starorange"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_orange_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div v-else>
                  <i class="el-icon-star-on weighinstar starred"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_red_message
                      }}</em>
                    </p></i
                  >
                </div>
                <!---star details end -->
              </div>
              <!--- end -->

              <!-- PRODUCTION MINTUES BILLABLE, SM APPPROVED, SA TOTAL -->
              <div
                v-show="
                  usertarget.user_target_section.performance_billable == 1 &&
                    usertarget.user_target_section.sales_minutes_approved ==
                      1 &&
                    usertarget.user_target_section.sales_minutes_billable ==
                      0 &&
                    usertarget.user_target_section.sales_amount_billable == 0 &&
                    usertarget.user_target_section.sales_amount_approved == 0
                "
              >
                <!-- star details -->
                <div
                  v-if="
                    usertarget.weighin_production_billable_total_sm_approved_total_sa_total >=
                      usertarget.user_target_section.weightin_gold
                  "
                >
                  <i class="el-icon-star-on weighinstar stargold"
                    ><br />
                    <p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_gold_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_billable_total_sm_approved_total_sa_total >=
                      usertarget.user_target_section.weightin_green
                  "
                >
                  <i class="el-icon-star-on weighinstar stargreen"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_green_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_billable_total_sm_approved_total_sa_total >=
                      usertarget.user_target_section.weightin_yellow
                  "
                >
                  <i class="el-icon-star-on weighinstar staryellow"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_yellow_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_billable_total_sm_approved_total_sa_total >=
                      usertarget.user_target_section.weightin_orange
                  "
                >
                  <i class="el-icon-star-on weighinstar starorange"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_orange_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div v-else>
                  <i class="el-icon-star-on weighinstar starred"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_red_message
                      }}</em>
                    </p></i
                  >
                </div>
                <!---star details end -->
              </div>
              <!--- end -->

              <!-- PRODUCTION MINTUES BILLABLE, SM TOTAL BILLABLE, SA TOTAL -->
              <div
                v-show="
                  usertarget.user_target_section.performance_billable == 1 &&
                    usertarget.user_target_section.sales_minutes_approved ==
                      0 &&
                    usertarget.user_target_section.sales_minutes_billable ==
                      1 &&
                    usertarget.user_target_section.sales_amount_billable == 0 &&
                    usertarget.user_target_section.sales_amount_approved == 0
                "
              >
                <!-- star details -->
                <div
                  v-if="
                    usertarget.weighin_production_billable_total_sm_total_billable_sa_total >=
                      usertarget.user_target_section.weightin_gold
                  "
                >
                  <i class="el-icon-star-on weighinstar stargold"
                    ><br />
                    <p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_gold_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_billable_total_sm_total_billable_sa_total >=
                      usertarget.user_target_section.weightin_green
                  "
                >
                  <i class="el-icon-star-on weighinstar stargreen"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_green_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_billable_total_sm_total_billable_sa_total >=
                      usertarget.user_target_section.weightin_yellow
                  "
                >
                  <i class="el-icon-star-on weighinstar staryellow"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_yellow_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_billable_total_sm_total_billable_sa_total >=
                      usertarget.user_target_section.weightin_orange
                  "
                >
                  <i class="el-icon-star-on weighinstar starorange"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_orange_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div v-else>
                  <i class="el-icon-star-on weighinstar starred"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_red_message
                      }}</em>
                    </p></i
                  >
                </div>
                <!---star details end -->
              </div>
              <!--- end -->

              <!-- PRODUCTION MINTUES BILLABLE, SM APPROVED BILLABLE, SA TOTAL -->
              <div
                v-show="
                  usertarget.user_target_section.performance_billable == 1 &&
                    usertarget.user_target_section.sales_minutes_approved ==
                      1 &&
                    usertarget.user_target_section.sales_minutes_billable ==
                      1 &&
                    usertarget.user_target_section.sales_amount_billable == 0 &&
                    usertarget.user_target_section.sales_amount_approved == 0
                "
              >
                <!-- star details -->
                <div
                  v-if="
                    usertarget.weighin_production_billable_total_sm_approved_billable_sa_total >=
                      usertarget.user_target_section.weightin_gold
                  "
                >
                  <i class="el-icon-star-on weighinstar stargold"
                    ><br />
                    <p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_gold_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_billable_total_sm_approved_billable_sa_total >=
                      usertarget.user_target_section.weightin_green
                  "
                >
                  <i class="el-icon-star-on weighinstar stargreen"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_green_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_billable_total_sm_approved_billable_sa_total >=
                      usertarget.user_target_section.weightin_yellow
                  "
                >
                  <i class="el-icon-star-on weighinstar staryellow"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_yellow_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_billable_total_sm_approved_billable_sa_total >=
                      usertarget.user_target_section.weightin_orange
                  "
                >
                  <i class="el-icon-star-on weighinstar starorange"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_orange_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div v-else>
                  <i class="el-icon-star-on weighinstar starred"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_red_message
                      }}</em>
                    </p></i
                  >
                </div>
                <!---star details end -->
              </div>
              <!--- end -->

              <!-- PRODUCTION MINTUES BILLABLE, SM APPROVED TOTAL, SA APPROVED BILLABLE -->
              <div
                v-show="
                  usertarget.user_target_section.performance_billable == 1 &&
                    usertarget.user_target_section.sales_minutes_approved ==
                      0 &&
                    usertarget.user_target_section.sales_minutes_billable ==
                      0 &&
                    usertarget.user_target_section.sales_amount_billable == 1 &&
                    usertarget.user_target_section.sales_amount_approved == 1
                "
              >
                <!-- star details -->
                <div
                  v-if="
                    usertarget.weighin_production_billable_total_sm_total_sa_approved_billable >=
                      usertarget.user_target_section.weightin_gold
                  "
                >
                  <i class="el-icon-star-on weighinstar stargold"
                    ><br />
                    <p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_gold_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_billable_total_sm_total_sa_approved_billable >=
                      usertarget.user_target_section.weightin_green
                  "
                >
                  <i class="el-icon-star-on weighinstar stargreen"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_green_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_billable_total_sm_total_sa_approved_billable >=
                      usertarget.user_target_section.weightin_yellow
                  "
                >
                  <i class="el-icon-star-on weighinstar staryellow"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_yellow_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_billable_total_sm_total_sa_approved_billable >=
                      usertarget.user_target_section.weightin_orange
                  "
                >
                  <i class="el-icon-star-on weighinstar starorange"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_orange_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div v-else>
                  <i class="el-icon-star-on weighinstar starred"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_red_message
                      }}</em>
                    </p></i
                  >
                </div>
                <!---star details end -->
              </div>
              <!--- end -->

              <!-- PRODUCTION MINTUES BILLABLE, SM APPROVED BILLABLE, SA APPROVED BILLABLE -->
              <div
                v-show="
                  usertarget.user_target_section.performance_billable == 1 &&
                    usertarget.user_target_section.sales_minutes_approved ==
                      1 &&
                    usertarget.user_target_section.sales_minutes_billable ==
                      1 &&
                    usertarget.user_target_section.sales_amount_billable == 1 &&
                    usertarget.user_target_section.sales_amount_approved == 1
                "
              >
                <!-- star details -->
                <div
                  v-if="
                    usertarget.weighin_production_billable_total_sm_approved_billable_sa_approved_billable >=
                      usertarget.user_target_section.weightin_gold
                  "
                >
                  <i class="el-icon-star-on weighinstar stargold"
                    ><br />
                    <p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_gold_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_billable_total_sm_approved_billable_sa_approved_billablel >=
                      usertarget.user_target_section.weightin_green
                  "
                >
                  <i class="el-icon-star-on weighinstar stargreen"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_green_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_billable_total_sm_approved_billable_sa_approved_billable >=
                      usertarget.user_target_section.weightin_yellow
                  "
                >
                  <i class="el-icon-star-on weighinstar staryellow"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_yellow_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_billable_total_sm_approved_billable_sa_approved_billable >=
                      usertarget.user_target_section.weightin_orange
                  "
                >
                  <i class="el-icon-star-on weighinstar starorange"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_orange_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div v-else>
                  <i class="el-icon-star-on weighinstar starred"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_red_message
                      }}</em>
                    </p></i
                  >
                </div>
                <!---star details end -->
              </div>
              <!--- end -->

              <!-- PRODUCTION MINTUES BILLABLE, SM APPROVED TOTAL, SA APPROVED BILLABLE -->
              <div
                v-show="
                  usertarget.user_target_section.performance_billable == 1 &&
                    usertarget.user_target_section.sales_minutes_approved ==
                      1 &&
                    usertarget.user_target_section.sales_minutes_billable ==
                      0 &&
                    usertarget.user_target_section.sales_amount_billable == 1 &&
                    usertarget.user_target_section.sales_amount_approved == 1
                "
              >
                <!-- star details -->
                <div
                  v-if="
                    usertarget.weighin_production_billable_total_sm_approved_total_sa_approved_billable >=
                      usertarget.user_target_section.weightin_gold
                  "
                >
                  <i class="el-icon-star-on weighinstar stargold"
                    ><br />
                    <p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_gold_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_billable_total_sm_approved_total_sa_approved_billable >=
                      usertarget.user_target_section.weightin_green
                  "
                >
                  <i class="el-icon-star-on weighinstar stargreen"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_green_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_billable_total_sm_approved_total_sa_approved_billable >=
                      usertarget.user_target_section.weightin_yellow
                  "
                >
                  <i class="el-icon-star-on weighinstar staryellow"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_yellow_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_billable_total_sm_approved_total_sa_approved_billable >=
                      usertarget.user_target_section.weightin_orange
                  "
                >
                  <i class="el-icon-star-on weighinstar starorange"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_orange_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div v-else>
                  <i class="el-icon-star-on weighinstar starred"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_red_message
                      }}</em>
                    </p></i
                  >
                </div>
                <!---star details end -->
              </div>
              <!--- end -->

              <!-- PRODUCTION MINTUES BILLABLE, SM TOTAL BILLABLE, SA APPROVED BILLABLE -->
              <div
                v-show="
                  usertarget.user_target_section.performance_billable == 1 &&
                    usertarget.user_target_section.sales_minutes_approved ==
                      0 &&
                    usertarget.user_target_section.sales_minutes_billable ==
                      1 &&
                    usertarget.user_target_section.sales_amount_billable == 1 &&
                    usertarget.user_target_section.sales_amount_approved == 1
                "
              >
                <!-- star details -->
                <div
                  v-if="
                    usertarget.weighin_production_billable_total_sm_total_billable_sa_approved_billable >=
                      usertarget.user_target_section.weightin_gold
                  "
                >
                  <i class="el-icon-star-on weighinstar stargold"
                    ><br />
                    <p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_gold_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_billable_total_sm_total_billable_sa_approved_billable >=
                      usertarget.user_target_section.weightin_green
                  "
                >
                  <i class="el-icon-star-on weighinstar stargreen"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_green_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_billable_total_sm_total_billable_sa_approved_billable >=
                      usertarget.user_target_section.weightin_yellow
                  "
                >
                  <i class="el-icon-star-on weighinstar staryellow"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_yellow_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_billable_total_sm_total_billable_sa_approved_billable >=
                      usertarget.user_target_section.weightin_orange
                  "
                >
                  <i class="el-icon-star-on weighinstar starorange"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_orange_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div v-else>
                  <i class="el-icon-star-on weighinstar starred"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_red_message
                      }}</em>
                    </p></i
                  >
                </div>
                <!---star details end -->
              </div>
              <!--- end -->

              <!-- PRODUCTION MINTUES BILLABLE, SM TOTAL, SA APPROVED  TOTAL -->
              <div
                v-show="
                  usertarget.user_target_section.performance_billable == 1 &&
                    usertarget.user_target_section.sales_minutes_approved ==
                      0 &&
                    usertarget.user_target_section.sales_minutes_billable ==
                      0 &&
                    usertarget.user_target_section.sales_amount_billable == 0 &&
                    usertarget.user_target_section.sales_amount_approved == 1
                "
              >
                <!-- star details -->
                <div
                  v-if="
                    usertarget.weighin_production_billable_total_sm_total_sa_approved_total >=
                      usertarget.user_target_section.weightin_gold
                  "
                >
                  <i class="el-icon-star-on weighinstar stargold"
                    ><br />
                    <p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_gold_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_billable_total_sm_total_sa_approved_total >=
                      usertarget.user_target_section.weightin_green
                  "
                >
                  <i class="el-icon-star-on weighinstar stargreen"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_green_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_billable_total_sm_total_sa_approved_total >=
                      usertarget.user_target_section.weightin_yellow
                  "
                >
                  <i class="el-icon-star-on weighinstar staryellow"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_yellow_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_billable_total_sm_total_sa_approved_total >=
                      usertarget.user_target_section.weightin_orange
                  "
                >
                  <i class="el-icon-star-on weighinstar starorange"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_orange_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div v-else>
                  <i class="el-icon-star-on weighinstar starred"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_red_message
                      }}</em>
                    </p></i
                  >
                </div>
                <!---star details end -->
              </div>
              <!--- end -->

              <!-- PRODUCTION MINTUES BILLABLE, SM APPROVED BILLABLE, SA APPROVED  TOTAL -->
              <div
                v-show="
                  usertarget.user_target_section.performance_billable == 1 &&
                    usertarget.user_target_section.sales_minutes_approved ==
                      1 &&
                    usertarget.user_target_section.sales_minutes_billable ==
                      1 &&
                    usertarget.user_target_section.sales_amount_billable == 0 &&
                    usertarget.user_target_section.sales_amount_approved == 1
                "
              >
                <!-- star details -->
                <div
                  v-if="
                    usertarget.weighin_production_billable_total_sm_approved_billable_sa_approved_total >=
                      usertarget.user_target_section.weightin_gold
                  "
                >
                  <i class="el-icon-star-on weighinstar stargold"
                    ><br />
                    <p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_gold_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_billable_total_sm_approved_billable_sa_approved_total >=
                      usertarget.user_target_section.weightin_green
                  "
                >
                  <i class="el-icon-star-on weighinstar stargreen"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_green_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_billable_total_sm_approved_billable_sa_approved_total >=
                      usertarget.user_target_section.weightin_yellow
                  "
                >
                  <i class="el-icon-star-on weighinstar staryellow"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_yellow_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_billable_total_sm_approved_billable_sa_approved_total >=
                      usertarget.user_target_section.weightin_orange
                  "
                >
                  <i class="el-icon-star-on weighinstar starorange"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_orange_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div v-else>
                  <i class="el-icon-star-on weighinstar starred"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_red_message
                      }}</em>
                    </p></i
                  >
                </div>
                <!---star details end -->
              </div>
              <!--- end -->

              <!-- PRODUCTION MINTUES BILLABLE, SM APPROVED TOTAL, SA APPROVED  TOTAL -->
              <div
                v-show="
                  usertarget.user_target_section.performance_billable == 1 &&
                    usertarget.user_target_section.sales_minutes_approved ==
                      1 &&
                    usertarget.user_target_section.sales_minutes_billable ==
                      0 &&
                    usertarget.user_target_section.sales_amount_billable == 0 &&
                    usertarget.user_target_section.sales_amount_approved == 1
                "
              >
                <!-- star details -->
                <div
                  v-if="
                    usertarget.weighin_production_billable_total_sm_approved_total_sa_approved_total >=
                      usertarget.user_target_section.weightin_gold
                  "
                >
                  <i class="el-icon-star-on weighinstar stargold"
                    ><br />
                    <p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_gold_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_billable_total_sm_approved_total_sa_approved_total >=
                      usertarget.user_target_section.weightin_green
                  "
                >
                  <i class="el-icon-star-on weighinstar stargreen"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_green_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_billable_total_sm_approved_total_sa_approved_total >=
                      usertarget.user_target_section.weightin_yellow
                  "
                >
                  <i class="el-icon-star-on weighinstar staryellow"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_yellow_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_billable_total_sm_approved_total_sa_approved_total >=
                      usertarget.user_target_section.weightin_orange
                  "
                >
                  <i class="el-icon-star-on weighinstar starorange"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_orange_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div v-else>
                  <i class="el-icon-star-on weighinstar starred"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_red_message
                      }}</em>
                    </p></i
                  >
                </div>
                <!---star details end -->
              </div>
              <!--- end -->

              <!-- PRODUCTION MINTUES BILLABLE, SM TOTAL BILLABLE, SA APPROVED  TOTAL -->
              <div
                v-show="
                  usertarget.user_target_section.performance_billable == 1 &&
                    usertarget.user_target_section.sales_minutes_approved ==
                      0 &&
                    usertarget.user_target_section.sales_minutes_billable ==
                      1 &&
                    usertarget.user_target_section.sales_amount_billable == 0 &&
                    usertarget.user_target_section.sales_amount_approved == 1
                "
              >
                <!-- star details -->
                <div
                  v-if="
                    usertarget.weighin_production_billable_total_sm_total_billable_sa_approved_total >=
                      usertarget.user_target_section.weightin_gold
                  "
                >
                  <i class="el-icon-star-on weighinstar stargold"
                    ><br />
                    <p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_gold_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_billable_total_sm_total_billable_sa_approved_total >=
                      usertarget.user_target_section.weightin_green
                  "
                >
                  <i class="el-icon-star-on weighinstar stargreen"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_green_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_billable_total_sm_total_billable_sa_approved_total >=
                      usertarget.user_target_section.weightin_yellow
                  "
                >
                  <i class="el-icon-star-on weighinstar staryellow"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_yellow_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div
                  v-else-if="
                    usertarget.weighin_production_billable_total_sm_total_billable_sa_approved_total >=
                      usertarget.user_target_section.weightin_orange
                  "
                >
                  <i class="el-icon-star-on weighinstar starorange"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_orange_message
                      }}</em>
                    </p></i
                  >
                </div>
                <div v-else>
                  <i class="el-icon-star-on weighinstar starred"
                    ><p
                      style="text-align:center; font-weight:bold; font-size:16px;color:#999"
                    >
                      <em>{{
                        usertarget.user_target_section.weightin_red_message
                      }}</em>
                    </p></i
                  >
                </div>
                <!---star details end -->
              </div>
              <!--- end -->

              <!-- weighin %  start-->

              <!-- 
   <div v-if="prop_weightin_results >= usertarget.user_target_section.weightin_gold">
   
    <i class="el-icon-star-on weighinstar stargold" ><br >
    <p style="text-align:center; font-weight:bold; font-size:16px;color:#999"><em>{{ usertarget.user_target_section.weightin_gold_message }}</em> </p>
     </i>
   </div>
   <div v-else-if="prop_weightin_results >= usertarget.user_target_section.weightin_green">
      

      <i class="el-icon-star-on weighinstar stargreen" >
          <p style="text-align:center; font-weight:bold; font-size:16px;color:#999"><em>{{ usertarget.user_target_section.weightin_green_message }}</em> </p>
     </i>
     </div>
      <div v-else-if="prop_weightin_results >= usertarget.user_target_section.weightin_yellow">
            <i class="el-icon-star-on weighinstar staryellow" >
                <p style="text-align:center; font-weight:bold; font-size:16px;color:#999"><em>{{ usertarget.user_target_section.weightin_yellow_message }}</em> </p>
          </i>
      </div>
      <div v-else-if="prop_weightin_results >= usertarget.user_target_section.weightin_orange">
            <i class="el-icon-star-on weighinstar starorange" >
                <p style="text-align:center; font-weight:bold; font-size:16px;color:#999"><em>{{ usertarget.user_target_section.weightin_orange_message }}</em> </p>
          </i>
      </div>
          <div v-else>
            <i class="el-icon-star-on weighinstar starred" >
                <p style="text-align:center; font-weight:bold; font-size:16px;color:#999"><em>{{ usertarget.user_target_section.weightin_red_message }}</em> </p>
                </i>
                </div> -->
            </div>
          </el-col>

          <!---TARGET SECTION --->

          <!-- TARGET BOXES -->

          <div
            style="padding:15px; margin-bottom:10px; float: left; width: 100%;"
            v-for="usertarget in users_data"
            :key="usertarget.id"
            v-show="usertarget.user_id == current_user"
          >
            <!-- PRODUCTION MINTUES TARGETS -->

            <el-col
              :xs="24"
              :sm="24"
              :md="8"
              :lg="8"
              :xl="8"
              style="display:none;"
            >
              <div
                style="padding:20px; background:#f3f3f3; border-radius:15px; float: left; width: 95%; margin-right:1px; margin-bottom:1px;min-height: 200px; "
                v-show="
                  usertarget.user_target_section.by_production_minutes == 1
                "
              >
                <p><b>MY PRODUCTION MINUTES</b></p>
                <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
                  <span
                    ><b><b>Target:</b></b></span
                  >
                </el-col>
                <el-col :xs="8" :sm="16" :md="16" :lg="16" :xl="16">
                  <b>
                    {{ usertarget.user_target_section.performance_total }}
                    %</b
                  >
                  <br />
                  <span
                    class="smallfont"
                    v-show="usertarget.user_target_section.performance_billable"
                    >Billable Only
                  </span>
                  <span
                    class="smallfont"
                    v-show="
                      !usertarget.user_target_section.performance_billable
                    "
                    >Billable & Free Minutes
                  </span>
                  <br />
                </el-col>
                <hr />
                <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
                  <span><b>Done:</b></span>
                </el-col>
                <el-col
                  :xs="8"
                  :sm="16"
                  :md="16"
                  :lg="16"
                  :xl="16"
                  style="margin-bottom:10px;"
                >
                  <div
                    v-show="
                      usertarget.user_target_section.performance_billable == 1
                    "
                  >
                    Allocated: {{ usertarget.time_allocated_billable }} mins -
                    {{ usertarget.percentage_billable }}%
                  </div>

                  <div
                    v-show="
                      usertarget.user_target_section.performance_billable == 0
                    "
                  >
                    Allocated:{{ usertarget.total }} mins -
                    {{ usertarget.percentage_total }}%
                  </div>
                </el-col>
              </div>
            </el-col>

            <!-- SALES MINTUES TARGETS -->

            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
              <div
                style="padding:20px; background:#f3f3f3; border-radius:15px; float: left; width: 95%; margin-right:1px; margin-bottom:1px; min-height: 200px;"
                v-show="usertarget.user_target_section.by_sales_minutes == 1"
              >
                <p><b>MY SALES MINUTES</b></p>
                <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
                  <span><b>Set Weekly:</b></span>
                </el-col>
                <el-col :xs="8" :sm="16" :md="16" :lg="16" :xl="16">
                  <b>
                    {{
                      usertarget.user_target_section.sales_minutes_total
                    }}mins</b
                  >
                  <br />
                  <span
                    class="smallfont"
                    v-show="
                      usertarget.user_target_section.sales_minutes_billable
                    "
                    >Billable Only
                  </span>
                  <span
                    class="smallfont"
                    v-show="
                      !usertarget.user_target_section.sales_minutes_billable
                    "
                    >Billable & Free Minutes
                  </span>
                  <br />
                  <span
                    class="smallfont"
                    v-show="
                      usertarget.user_target_section.sales_minutes_approved
                    "
                  >
                    Approved Only
                  </span>
                  <span
                    class="smallfont"
                    v-show="
                      !usertarget.user_target_section.sales_minutes_approved
                    "
                  >
                    Approved & Unapproved Minutes
                  </span>
                </el-col>
                <hr />
                <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
                  <span><b>Results:</b></span>
                </el-col>
                <el-col
                  :xs="8"
                  :sm="16"
                  :md="16"
                  :lg="16"
                  :xl="16"
                  style="margin-bottom:10px;"
                >
                  <div
                    v-show="
                      usertarget.user_target_section.sales_minutes_billable ==
                        0 &&
                        usertarget.user_target_section.sales_minutes_approved ==
                          0
                    "
                  >
                    {{ usertarget.total }} mins -
                    {{ usertarget.sales_minutes_total_percentage }}%
                  </div>
                  <div
                    v-show="
                      usertarget.user_target_section.sales_minutes_billable ==
                        1 &&
                        usertarget.user_target_section.sales_minutes_approved ==
                          1
                    "
                  >
                    {{ usertarget.approved_billable }} mins -
                    {{ usertarget.sales_mintues_approved_billable_percentage }}%
                  </div>
                  <div
                    v-show="
                      usertarget.user_target_section.sales_minutes_billable ==
                        0 &&
                        usertarget.user_target_section.sales_minutes_approved ==
                          1
                    "
                  >
                    {{ usertarget.total_approved }} mins -
                    {{ usertarget.sales_mintues_approved_total_percentage }}%
                  </div>
                  <div
                    v-show="
                      usertarget.user_target_section.sales_minutes_billable ==
                        1 &&
                        usertarget.user_target_section.sales_minutes_approved ==
                          0
                    "
                  >
                    {{ usertarget.total_billable }} mins -
                    {{ usertarget.sales_mintues_total_billable_percentage }}%
                  </div>
                </el-col>
              </div>
            </el-col>

            <!--- SALES MINUTES TARGETS END -->

            <!-- SALES AMOUNT TARGETS -->

            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
              <div
                style="padding:20px; background:#f3f3f3; border-radius:15px; float: left; width: 95%; margin-right:1px; margin-bottom:1px; min-height: 200px;"
                v-show="usertarget.user_target_section.by_sales_amount == 1"
              >
                <p><b>MY SALES AMOUNT </b></p>
                <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
                  <span><b>Set Weekly:</b></span>
                </el-col>
                <el-col :xs="8" :sm="16" :md="16" :lg="16" :xl="16">
                  <b>
                    {{
                      usertarget.user_target_section.sales_amount_total
                    }}mins</b
                  >
                  <br />
                  <span
                    class="smallfont"
                    v-show="
                      usertarget.user_target_section.sales_amount_billable
                    "
                    >Billable Only
                  </span>
                  <span
                    class="smallfont"
                    v-show="
                      !usertarget.user_target_section.sales_amount_billable
                    "
                    >Billable & Free Minutes
                  </span>
                  <br />
                  <span
                    class="smallfont"
                    v-show="
                      usertarget.user_target_section.sales_amount_approved
                    "
                  >
                    Approved Only
                  </span>
                  <span
                    class="smallfont"
                    v-show="
                      !usertarget.user_target_section.sales_amount_approved
                    "
                  >
                    Approved & Unapproved Minutes
                  </span>
                </el-col>
                <hr />
                <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
                  <span><b>Results:</b></span>
                </el-col>
                <el-col
                  :xs="8"
                  :sm="16"
                  :md="16"
                  :lg="16"
                  :xl="16"
                  style="margin-bottom:10px;"
                >
                  <div
                    v-show="
                      usertarget.user_target_section.by_sales_amount == 1 &&
                        usertarget.user_target_section.sales_amount_approved ==
                          0 &&
                        usertarget.user_target_section.sales_amount_billable ==
                          0
                    "
                  >
                    ${{ usertarget.total_contract }} -
                    {{ usertarget.sales_amount_total_percentage }}%
                  </div>
                  <div
                    v-show="
                      usertarget.user_target_section.by_sales_amount == 1 &&
                        usertarget.user_target_section.sales_amount_approved ==
                          1 &&
                        usertarget.user_target_section.sales_amount_billable ==
                          1
                    "
                  >
                    ${{ usertarget.amount_approved_billable }} -
                    {{ usertarget.sales_amount_approved_billable_percentage }}%
                  </div>
                  <div
                    v-show="
                      usertarget.user_target_section.by_sales_amount == 1 &&
                        usertarget.user_target_section.sales_amount_approved ==
                          1 &&
                        usertarget.user_target_section.sales_amount_billable ==
                          0
                    "
                  >
                    ${{ usertarget.amount_approved_total }} -
                    {{ usertarget.sales_amount_approved_total_percentage }}%
                  </div>
                  <div
                    v-show="
                      usertarget.user_target_section.by_sales_amount == 1 &&
                        usertarget.user_target_section.sales_amount_approved ==
                          1 &&
                        usertarget.user_target_section.sales_amount_billable ==
                          0
                    "
                  >
                    ${{ usertarget.amount_total_billable }} -
                    {{ usertarget.sales_amount_total_billable_percentage }}%
                  </div>
                </el-col>
              </div>
            </el-col>

            <!--- SALES AMOUNT TARGETS END -->
          </div>

          <!-- TARGET BOXES END -->

          <!---TARGET SECTION END --->
        </el-row>
      </div>
    </div>

    <el-dialog
      title="KFI's"
      :visible.sync="dialogVisible"
      width="80%"
      :before-close="handleClose"
    >
      **This needs to check againt the user kpi and show only the not approved
      to marketing managment

      <el-row
        :gutter="0"
        v-for="user_kfi in prop_rfi"
        :key="user_kfi.prop_rfi_id"
      >
        <el-col :xs="2" :sm="2" :md="1" :lg="1" :xl="1">
          <i
            v-show="!user_kfi.prop_rfi_approved"
            class="el-icon-folder-opened"
            style="color:grey"
          ></i>
          <i
            v-show="user_kfi.prop_rfi_approved"
            class="el-icon-check"
            style="color:green"
          ></i>
        </el-col>
        <el-col :xs="20" :sm="20" :md="1" :lg="1" :xl="1"
          ><a href="">{{ user_kfi.prop_proposal_job_number }} </a></el-col
        >
        <el-col :xs="24" :sm="24" :md="5" :lg="3" :xl="3">{{
          user_kfi.prop_rfi_date
        }}</el-col>
        <el-col :xs="24" :sm="24" :md="17" :lg="19" :xl="19"
          >{{ user_kfi.prop_rfi_reason }}
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Close</el-button>
      </span>
    </el-dialog>

    <h3 style="font-size:20px;margin-bottom:10px;margin-top:10px;display:none">
      My Total Overview
    </h3>

    <div
      style="overflow: auto; white-space: nowrap; margin-bottom:30px;display:none"
    >
      <div
        style="width:80px;display: inline-block;"
        v-for="results in tableData"
        :key="results.prop_weekly_date"
      >
        <div style="border:1px solid #f3f3f3;width:100%;text-align:center;">
          <span style="font-size:10px;line-height: 0.5;font-weight:bold;">{{
            results.prop_weekly_date
          }}</span>
        </div>
        <div style="border:1px solid #f3f3f3;">
          <i
            v-if="results.prop_weekly_weightin_results >= prop_weightin_gold"
            class="el-icon-star-on weighinstarsmall stargold"
            ><br />
          </i>
          <i
            v-else-if="
              results.prop_weekly_weightin_results >= prop_weightin_green
            "
            class="el-icon-star-on weighinstarsmall stargreen"
          ></i>
          <i
            v-else-if="
              results.prop_weekly_weightin_results >= prop_weightin_yellow
            "
            class="el-icon-star-on weighinstarsmall staryellow"
          >
          </i>
          <i
            v-else-if="
              results.prop_weekly_weightin_results >= prop_weightin_orange
            "
            class="el-icon-star-on weighinstarsmall starorange"
          >
          </i>
          <i v-else class="el-icon-star-on weighinstarsmall starred"> </i>
        </div>
      </div>
    </div>

    <h3 style="font-size:20px;margin-bottom:10px;margin-top:10px;display:none">
      <em>Last weeks</em> company rankings
    </h3>

    <div
      style="overflow: auto; white-space: nowrap;margin-bottom:30px;display:none"
    >
      <div
        style="width:80px;display: inline-block;"
        v-for="results in companytableData"
        :key="results.prop_user_id"
      >
        <div style="border:1px solid #f3f3f3;width:100%;text-align:center;">
          <span style="font-size:10px;line-height: 0.5;font-weight:bold;">{{
            results.prop_user_name
          }}</span>
        </div>
        <div style="border:1px solid #f3f3f3;">
          <i
            v-if="results.prop_weekly_weightin_results >= prop_weightin_gold"
            class="el-icon-star-on weighinstarsmall stargold"
            ><br />
          </i>
          <i
            v-else-if="
              results.prop_weekly_weightin_results >= prop_weightin_green
            "
            class="el-icon-star-on weighinstarsmall stargreen"
          ></i>
          <i
            v-else-if="
              results.prop_weekly_weightin_results >= prop_weightin_yellow
            "
            class="el-icon-star-on weighinstarsmall staryellow"
          >
          </i>
          <i
            v-else-if="
              results.prop_weekly_weightin_results >= prop_weightin_orange
            "
            class="el-icon-star-on weighinstarsmall starorange"
          >
          </i>
          <i v-else class="el-icon-star-on weighinstarsmall starred"> </i>
        </div>
      </div>
    </div>

    <!-- <div id="v-step-0">A DOM element on your page. The first step will pop on this element because its ID is 'v-step-0'.</div>
    <div class="v-step-1">A DOM element on your page. The second step will pop on this element because its ID is 'v-step-1'.</div>
    <div data-v-step="2">A DOM element on your page. The third and final step will pop on this element because its ID is 'v-step-2'.</div>
   <v-tour name="myTour" :steps="steps"></v-tour> -->

    <!-- new staff KPI end -->
  </div>
</template>
<script>
// import VueTour from "vue-tour";
// import 'vue-tour/dist/vue-tour.css';

// Vue.use(VueTour)

export default {
  name: "MyDashboard",
  // components: {VueTour},

  props: ["is_admin", "current_user"],
  data() {
    return {
      dialogVisible: false,
      prop_weekly_minutes_daily: "480",
      prop_sales_minutes_daily: "230",
      prop_sales_amount_daily: "1209",
      columns: [],
      rows: [],
      roles_list: [],
      selected_roles: [],
      users_data: [],
      list_title: "",
      is_loading: false,
      main_loading: false,
      prop_training_hours_set: 5,
      prop_training_hours_returned: 8,
      prop_training_yellow: 2,
      prop_training_orange: 4,
      prop_training_green: 6,
      prop_turnaround_set: 5,
      prop_turnaround_yellow: 2,
      prop_turnaround_orange: 4,
      prop_turnaround_green: 6,

      tableData: [
        {
          prop_weekly_date: "2016-05-02",
          prop_weekly_weightin_results: 1,
        },
        {
          prop_weekly_date: "2016-05-20",
          prop_weekly_weightin_results: 5,
        },
        {
          prop_weekly_date: "2016-05-30",
          prop_weekly_weightin_results: 3,
        },
        {
          prop_weekly_date: "2016-06-30",
          prop_weekly_weightin_results: 4,
        },
        {
          prop_weekly_date: "2016-08-20",
          prop_weekly_weightin_results: 5,
        },
      ],
      companytableData: [
        {
          prop_user_id: "1",
          prop_user_name: "Matt",
          prop_weekly_weightin_results: 3,
        },
        {
          prop_user_id: "2",
          prop_user_name: "Bob",
          prop_weekly_weightin_results: 5,
        },
        {
          prop_user_id: "3",
          prop_user_name: "Dan",
          prop_weekly_weightin_results: 4,
        },
        {
          prop_user_id: "4",
          prop_user_name: "Kyle",
          prop_weekly_weightin_results: 3,
        },
        {
          prop_user_id: "5",
          prop_user_name: "Steve",
          prop_weekly_weightin_results: 5,
        },
        {
          prop_user_id: "6",
          prop_user_name: "Mark",
          prop_weekly_weightin_results: 0,
        },
      ],
      prop_weightin_results: 1,
      prop_weightin_gold: 5,

      prop_weightin_green: 4,

      prop_weightin_yellow: 3,

      prop_weightin_orange: 2,

      prop_weightin_red: 1,

      prop_user_RFI: 1,

      prop_rfi: [
        {
          prop_rfi_id: 1,
          prop_rfi_date: "2010-03-11 7:00am",
          prop_rfi_reason: "Item was printed incorrectly, didnt check notes",
          prop_proposal_job_number: "2345",
          prop_rfi_reporting_user: "Matt",
          prop_rfi_user: "Leyita",
          prop_rfi_approved: true,
        },
        {
          prop_rfi_id: 2,
          prop_rfi_date: "2010-03-11 7:00am",
          prop_rfi_reason: "Item was printed incorrectly, didnt check notes",
          prop_proposal_job_number: "2345",
          prop_rfi_reporting_user: "Matt",
          prop_rfi_user: "Michaela",
          prop_rfi_approved: false,
        },
      ],

      pickerOptions2: {
        shortcuts: [
          {
            text: "Current month",
            onClick(picker) {
              const end = new Date();
              const start = new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                1
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last week",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last month",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last 3 months",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last 6 months",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last year",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      selected_date_range: "",
      steps: [
        {
          target: "#card_id", // We're using document.querySelector() under the hood
          content: `Discover <strong>Vue Tour</strong>!`,
        },
        {
          target: "#card_id1",
          content: "An awesome plugin made with Vue.js!",
        },
        {
          target: '[data-v-step="2"]',
          content:
            "Try it, you'll love it!<br>You can put HTML in the steps and completely customize the DOM to suit your needs.",
          params: {
            placement: "top",
          },
        },
      ],
    };
  },
  mounted() {
    //  this.$tours['myTour'].start()
    this.selected_date_range = [this.start_date, this.end_date];
    this.load_roles_list();
    let _this = this;
    setTimeout(function() {
      _this.set_default_input();
    }, 500);
  },
  computed: {
    current_date() {
      return new Date();
    },
    start_date() {
      return new Date(
        this.current_date.getFullYear(),
        this.current_date.getMonth(),
        1
      );
    },
    end_date() {
      return new Date(
        this.current_date.getFullYear(),
        this.current_date.getMonth(),
        this.current_date.getDate()
      );
    },
  },
  methods: {
    time_sheet_color(user) {
      let color = 'redbox';

      if (user.weighin_time_sheet_total >= user.user_target_section.timesheet_green) {
        color = 'greenbox';
      }
      else if(user.weighin_time_sheet_total >= user.user_target_section.timesheet_yellow) {
        color = 'yellowbox';
      }
      else if(user.weighin_time_sheet_total >= user.user_target_section.timesheet_orange) {
        color = 'orangebox';
      }
      else {
        color = 'redbox';
      }

      return color;
    },
    show_sales_kpi_dashboard_tasks(user_id, user_name) {
      this.$http
        .post("/get_sales_kpi_list_dashboard", {
          user_id: user_id,
          start_date: this.selected_date_range[0],
          end_date: this.selected_date_range[1],
        })
        .then((response) => {
          if (response.data.status == 200) {
            this.rows = response.data.data.rows;
            this.columns = response.data.data.columns;
            this.list_title = user_name + " Sales KPI";
            this.open_modal_sales();
          }
        });
    },
    handleClose(done) {
      this.$confirm("Are you sure to close this dialog?")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    load_roles_list() {
      let self = this;
      self.main_loading = true;
      this.$http.get("/roles_list_filtered").then((response) => {
        this.roles_list = response.data.roles_list;
        let tmp_roles = this.roles_list.map((c) => c.id);
        if (tmp_roles.length > 0) this.selected_roles = [tmp_roles[0]];
        self.main_loading = false;
        this.fill_data();
      });
    },
    fill_data() {
      let self = this;
      self.main_loading = true;
      this.users_data = [];
      this.$http
        .post("/get_users_kpi_overview", {
          start_date: this.selected_date_range[0],
          end_date: this.selected_date_range[1],
          roles_ids: this.selected_roles,
        })
        .then((response) => {
          if (response.data.status == 200) {
            this.users_data = response.data.data;
          }
          self.main_loading = false;
        });
    },

    set_default_input() {
      let _this = this;
      Array.from(document.querySelectorAll(".el-input__inner")).forEach(
        function(e, i) {
          e.classList.add("browser-default");
          if (e.querySelector("input") != null)
            e.querySelector("input").className += " browser-default";
        }
      );
    },
    open_modal_tasks() {
      this.$modal.show("users_kpi_dashboard_list");
    },
    open_modal_sales() {
      this.$modal.show("sales_dashboard_kpi_list");
    },
    hide_modal() {
      this.$modal.hide("users_kpi_list");
      this.$modal.hide("sales_dashboard_kpi_list");
    },
    show_user_kpi_dashboard_tasks(user_id, user_name) {
      this.$http
        .post("/get_users_kpi_list_dashboard", {
          user_id: user_id,
          start_date: this.selected_date_range[0],
          end_date: this.selected_date_range[1],
        })
        .then((response) => {
          if (response.data.status == 200) {
            this.rows = response.data.data.rows;
            this.columns = response.data.data.columns;
            this.list_title = user_name + " KPI";
            this.open_modal_tasks();
          }
        });
    },

    total_minutes(col) {
      return this.users_data.reduce(function(total, item) {
        return total + item[col];
      }, 0);
    },
    round(num) {
      return Math.round(num * 100) / 100;
    },
  },
};
</script>

<style scoped>
td,
th {
  text-align: center;
  padding: 10px;
}

th {
  font-weight: 800;
}

.header {
  background-color: #ddecf9;
}

.subheader {
  background-color: #e5f3ff;
}

.rows {
  background-color: aliceblue;
}

a {
  cursor: pointer;
}

th {
  font-weight: bold;
}

.users_list_container {
  max-height: 400px;
  overflow: auto;
}

.color-box {
  width: 100%;
  height: 20px;
  margin-bottom: 1px;
  font-size: 10px;
  text-transform: uppercase;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  padding: 2px 0 0 0;
}
.greenbox {
  background-color: #27d40a;
}
.yellowbox {
  background-color: #fedd01;
}
.orangebox {
  background-color: #fe8a01;
}
.redbox {
  background-color: #fe3701;
}
.weighinstar {
  font-size: 250px;
  text-align: center;
  width: 100%;
}
.weighinstarsmall {
  font-size: 30px;
  text-align: center;
  width: 100%;
}
.stargold {
  color: #cfb53b;
}
.stargreen {
  color: #27d40a;
}
.starorange {
  color: #fe8a01;
}
.staryellow {
  color: #fedd01;
}
.starred {
  color: #fe3701;
}
.isadmin {
  font-size: 11px;
  text-transform: uppercase;
  text-align: left;
}
.smallfont {
  font-size: 12px;
  line-height: 8px;
  color: #999;
  font-style: italic;
}
table.kpitable > tbody > tr > td {
  vertical-align: top !important;
}
</style>
