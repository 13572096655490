<template>
  <span>{{ money_format | currency }}</span>
</template>

<script>
export default {
  name: "Money",
  props: ["amount"],
  computed: {
    money_format() {
      if (isNaN(this.amount) == true || this.amount == null) {
        return;
      }
      return parseFloat(this.amount);
    }
  }
}
</script>
