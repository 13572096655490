<template>
  <div class="row">
    <el-row :gutter="0">
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="24"
        :xl="24"
      >
        <h3>
          <b>LEAVE BALANCE</b>
          <hr>
        </h3>
      </el-col>
    </el-row>
    <el-row :gutter="0">
      <el-col
        v-for="leave in user_leave_balance"
        :key="leave.type"
        :xs="24"
        :sm="24"
        :md="6"
        :lg="6"
        :xl="6"
      >
        <el-link
          type="text"
          class="underscore"
          @click="show_leave_transactions(leave)"
        >
          {{
            leave.type
          }}
        </el-link>
        <br><span>{{ leave.balance }} <small>Hours</small></span>/<span>{{ leave.balance / 8 }} <small>Days</small> </span>
      </el-col>
    </el-row>
    <Request
      :request-dialog="requestDialog"
      :reload_data="reload_data"
      :selected_request="selected_request"
    />
    <LeaveTransactions
      :leave_transaction_dialog="leave_transaction_dialog"
      :selected_leave="selected_leave"
    />

    <el-row :gutter="0">
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="24"
        :xl="24"
      >
        <h3>
          <b>LEAVE REQUESTS</b>
          <el-row>
            <el-button
              class="right"
              button
              type="primary"
              plain
              size="small"
              style="margin-top: -10px;"
              @click="create_new_request"
            >
              New Request
            </el-button>
          </el-row>
          <hr>
        </h3>
      </el-col>
    </el-row>

    <el-table
      :data="requests_data"
      style="width: 100%"
    >
      <el-table-column
        prop="from_date"
        label="Date"
        width="200"
      />

      <el-table-column
        label="Leave Type"
        width="180"
        filter-placement="bottom-end"
      >
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{
            scope.row.request_type.name
          }}</span>
        </template>
      </el-table-column>

      <el-table-column
        prop="value"
        label="Hours"
        width="180"
      />

      <el-table-column
        label="Status"
        width="180"
        column-key="status"
        :filters="[
          { text: 'PENDING', value: 'PENDING' },
          { text: 'APPROVED', value: 'APPROVED' },
          { text: 'REJECTED', value: 'REJECTED' },
        ]"
        :filter-method="filterTag"
        filter-placement="bottom-end"
      >
        <template v-slot="scope">
          <el-tag type="primary" v-if="scope.row.request_status.name == 'PENDING'">{{
            scope.row.request_status.name
          }}</el-tag>
          <el-tag type="danger" v-if="scope.row.request_status.name == 'REJECTED'">{{
              scope.row.request_status.name
            }}</el-tag>
          <el-tag type="success" v-if="scope.row.request_status.name == 'APPROVED'">{{
              scope.row.request_status.name
            }}</el-tag>
        </template>
      </el-table-column>

      <el-table-column
        prop="content"
        label="content"
      />

      <el-table-column
        prop="reply_content"
        label="Reply"
      />

      <el-table-column
        label="Actions"
        width="180"
        filter-placement="bottom-end"
      >
        <template slot-scope="scope">
          <el-button
            icon="el-icon-edit"
            circle
            @click="edit_user_request(scope.row)"
          />

          <el-button
            v-show="scope.row.request_status.name == 'PENDING'"
            icon="el-icon-delete"
            circle
            @click="delete_user_request(scope.row.id)"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import Request from './Request';
import LeaveTransactions from './LeaveTransactions';
import { mapGetters, mapActions} from 'vuex';
import { user_profile } from '../../store/modules/user_profile';

export default {
  name: 'Leave',
  store: user_profile,
  components: { Request, LeaveTransactions },
  props: {
    crm_user_id: {
      type: [String],
      required: false
    }
  },
  data() {
    return {
      requestDialog: { visible: false },
      leave_transaction_dialog: { visible: false },
      request_statuses: [],
      requests_data: [],
      selected_leave: {
        crm_user_id: '',
        leave_type: { id: '', name: '', balance: 0 },
      },
      selected_request: {
        request_type: { id: '', name: '' },
        requestable: '',
        request_status: { id: '', name: '' },
      },
    };
  },
  computed: {
    ...mapGetters(['selected_user_info', 'is_admin', 'user_leave_balance']),
  },
  watch: {
    selected_user_info: function(newVal, oldVal) {
      this.fill_requests();
      //this.load_user_balance();
    }
  },
  mounted() {
    this.crm_user_id_present();
    this.fill_requests();
    this.fill_request_statuses();

    //this.load_user_balance();
  },
  methods: {
    ...mapActions([
      "set_selected_user_info",
      "set_is_admin",
    ]),
    crm_user_id_present(){
      if(this.crm_user_id != undefined) {
        this.set_selected_user_info({id: this.crm_user_id});
        this.set_is_admin(1);
      }
    },
    edit_user_request(request) {
      this.requestDialog = { visible: true };
      this.selected_request = {
        id: request.id,
        from_date: request.from_date,
        to_date: request.to_date,
        requestable: request.requestable,
        // request_type: {id: request.request_type.id},
        // leave_type: {id: request.leave_type.id},
        request_status: { id: request.request_status.id },
        crm_user_id: request.crm_user_id,
        value: request.value,
        content: request.content,
        reply_content: request.reply_content,
      };
    },
    delete_user_request(id) {
      let _this = this;
      this.$confirm(
        'This will permanently delete the record. Continue?',
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      )
        .then(() => {
          this.$http.delete('/delete_user_request/' + id).then((response) => {
            this.$message({
              type: 'success',
              message: 'Delete completed',
            });
            _this.reload_data();
          });
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: 'Delete canceled'
          // });
        });
    },
    reload_data() {
      this.requestDialog.visible = false;
      this.fill_requests();
    },
    filterTag(value, row) {
      return row.request_status.name === value;
    },
    // load_user_balance() {
    //   let _this = this;
    //   let data = { crm_user_id: _this.selected_user_info.id };
    //   this.$http.post("/user_leave_balance", data).then(response => {
    //     _this.set_user_leave_balance(response.data.balance)
    //   });
    // },
    fill_requests() {
      let _this = this;
      let data = {
        statuses: [1, 2, 3],
        crm_user_id: _this.selected_user_info.id,
      };
      this.$http.post('/requests_filtered_list', data).then((response) => {
        _this.requests_data = response.data.data;
      });
    },
    fill_request_statuses() {
      let _this = this;
      this.$http.get('/request_statuses').then((response) => {
        _this.request_statuses = response.data;
      });
    },
    create_new_request() {
      this.selected_request = {
        id: 0,
        from_date: new Date(),
        to_date: new Date(),
        request_type: { id: '', type: '' },
        requestable: '',
        request_status: { id: '' },
        crm_user_id: this.selected_user_info.id,
        value: 0,
        content: '',
      };

      this.requestDialog.visible = true;
    },
    show_leave_transactions(leave_type) {
      this.leave_transaction_dialog = { visible: true };
      this.selected_leave = {
        crm_user_id: this.selected_user_info.id,
        leave_type: leave_type,
      };
    },
  },
};
</script>

<style scoped>
.underscore {text-decoration: underline; font-size:14px;}
</style>
