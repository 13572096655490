<template>
  <div>
    <el-collapse>
      <el-collapse-item
        v-for="note in stock_notes"
        :key="note.id"
        v-model="stock_notes"
        :title="note.name"
        :name="note.id"
      >
        <template slot="title">
          <span>
            {{ note.name }}
            <i
              v-show="note.notes && note.notes.length > 0"
              class="el-icon-chat-line-round"
            />
          </span>
        </template>
        <div>
          <el-row>
            <el-col>
              <div
                class="margin-left"
                v-html="note.note_truncated"
              />
              <el-button
                type="primary"
                size="mini"
                class="right"
                @click="show_stock_notes_dialog = true, current_note.note = note.notes, current_note.id = note.id"
              >
                Edit / Read
              </el-button>
            </el-col>
          </el-row>
        </div>
        <div>
          <el-dialog
            title="Edit Stock Note"
            :visible.sync="show_stock_notes_dialog"
            width="50%"
            append-to-body
            :close-on-click-modal="false"
          >
            <jodit-editor
              id="stock_notes_editor"
              ref="stock_notes_editor"
              v-model="current_note.note"
              :config="jodit_config"
            />
            <span
              slot="footer"
              class="dialog-footer"
            >
              <el-button
                type="success"
                size="mini"
                @click="edit_stock_note(), show_stock_notes_dialog = false"
              >
                Save
              </el-button>
            </span>
          </el-dialog>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>

import {update_stock_note, stock_notes} from '../../api/stock_items.js';

export default {
  name: 'StockNotes',
  props: {
    proposal_id: {
      type: [String,Number],
      required: true
    }
  },
  data() {
    return {
      stock_notes: [],
      show_stock_notes_dialog: false,
      current_note: {id: '', note: '', note_truncated: ''},
      jodit_config : {
        enter: 'br',
      },
    };
  },
  created() {
    this.get_stock_notes();
  },
  methods: {
    get_stock_notes() {
      stock_notes(this, this.proposal_id).then((response) => {
        this.stock_notes = response.data;
      });
    },
    edit_stock_note() {
      update_stock_note(this, this.current_note.id, this.current_note.note).then((response) => {
        this.$message({
          message: response.data.message,
          type: 'success',
        });
        this.get_stock_notes();
      });
    }
  }
};
</script>
<style scoped>
.right{float:right;}
.margin-left{ margin-left: 10px;}
</style>