<template>
  <div id="crm_user_branches">
    <span>Branches</span>
    <el-divider />
    <el-row
      v-for="branch in branches"
      :key="branch.id"
    >
      <el-checkbox
        ref="check"
        label="index"
        size="mini"
        :checked="branch.checked"
        @change="save_branch(branch.id,$event)"
      >
        {{ branch.name }}
      </el-checkbox>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'CrmUserBranches',
  props: {
    crm_user_id: {
      type: [String,Number],
      required: true
    }
  },
  data() {
    return {
      branches: []
    };
  },
  watch:{
    crm_user_id(){
      this.branches = [];
      this.get_branches();
    }
  },
  mounted() {
    this.get_branches();
  },
  methods: {
    get_branches() {
      this.$http.get(`/get_crm_user_branches/${this.crm_user_id}`).then((response) => {
        this.branches = response.data;
      });
    },
    save_branch(branch_id, checked) {
      let params = {
        crm_user_id: this.crm_user_id,
        branch_id: branch_id,
        checked: checked
      };
      this.$http.post('/update_crm_user_branches', params).then(
        () => {
          this.$message({type: 'success', message: 'Saved successfully'});
        },
        () => {
          this.$message({type: 'warning', message: 'Removed successfully'});
        }
      );
    }
  }
};
</script>
<style scoped>
#crm_user_branches /deep/ .el-divider--horizontal {
  margin: 0 0 10px 0;
}
</style>