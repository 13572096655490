<template>
  <el-collapse-item :name="name">
    <template slot="title">
      <el-row class="width--full">
        <el-col :span="12">
          {{ title }}
        </el-col>
        <el-col :span="12">
          <el-row
            class="width--full"
            :gutter="10"
          >
            <el-col :span="5">
              <div class="align__right">
                <div>
                  <el-tag :type="status_type">
                    {{ status }}
                  </el-tag>
                </div>
              </div>
            </el-col>
            <el-col :span="19">
              <span>{{ approved_date }}</span>
              &nbsp;&nbsp;
              <span>{{ invoice_email }}</span>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <DescriptionsEditors
      class="section"
      :is_show_option="is_show_option"
      :sale_key="sale_key"
    />
  </el-collapse-item>
</template>

<script>
import {mapGetters} from 'vuex';
import DescriptionsEditors from './DescriptionsEditors';
import {sale_store} from '../../../store/modules/sale_store';

export default {
  name: 'DescriptionsListItem',
  store: sale_store,
  components: {
    DescriptionsEditors
  },
  props: {
    is_show_option: {
      type: Boolean,
      required: true
    },
    name: {
      type: [String, Number],
      required: true
    },
    title: {
      type: String,
      required: true
    },
    sale_key: {
      type: [String, Number],
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'get_sale_with_key',
    ]),
    sale: {
      get() {
        return this.get_sale_with_key(this.sale_key);
      },
    },

    status() {
      let status = 'PENDING';
      if (this.sale.send_invoice == true) {
        status = 'SENT';
      }
      else if(this.sale.approved == true) {
        status = 'APPROVED';
      }
      return status;
    },

    status_type() {
      let type = 'info';
      if (this.sale.send_invoice == true) {
        type = 'success';
      }
      else if(this.sale.approved == true) {
        type = '';
      }
      return type;
    },

    invoice_email() {
      if(this.sale.send_invoice == false) {
        return;
      }
      return this.sale.invoice_email;
    },

    approved_date() {
      if (this.sale.sales_approved_at == null) {
        return;
      }
      let arrived_date = new Date(this.sale.sales_approved_at);
      let dd = arrived_date.getDate();
      let mm = arrived_date.getMonth()+1;
      let yyyy = arrived_date.getFullYear();

      return `${dd}/${mm}/${yyyy}`;
    }
  }
};
</script>
