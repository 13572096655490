<template>
  <div id="new-leave-request">
    <el-button
      size="mini"
      type="primary"
      @click="new_request_dialog = true"
    >
      New request
    </el-button>
    <el-dialog
      width="500px"
      :visible.sync="new_request_dialog"
      title="Leave/Overtime Request"
      :modal="false"
      @opened="set_default_input()"
    >
      <el-row>
        <el-select
          ref="requestable"
          v-model="selected_request.requestable"
          placeholder="Select Type"
          size="small"
          @change="update_balance"
        >
          <el-option-group
            v-for="group in request_options"
            :key="group.label"
            :label="group.label"
          >
            <el-option
              v-for="item in group.options"
              :key="item.value"
              :label="item.name"
              :group="group.label"
              :value="item.value"
            />
          </el-option-group>
        </el-select>
      </el-row>
      <el-row :gutter="10">
        <el-col :md="12">
          <el-date-picker
            v-model="selected_request.from_date"
            size="small"
            type="datetime"
            format="dd.MM.yyyy HH:mm"
            default-time="08:00:00"
            placeholder="From"
            @change="date_range_changed"
          />
        </el-col>
        <el-col :md="12">
          <el-date-picker
            v-model="selected_request.to_date"
            type="datetime"
            size="small"
            format="dd.MM.yyyy HH:mm"
            default-time="17:00:00"
            placeholder="To"
            @change="date_range_changed"
          />
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :md="12">
          <span>
            {{ selected_value_header }}
            <el-input
              v-model="selected_request.value"
              size="small"
              :precision="2"
              :step="0.5"
              :min="0.5"
            />
            <span v-show="selected_value_header == 'Hour(s)'">
              <br><small>{{ selected_request.value / 8 }} day(s)</small>
            </span>
          </span>
        </el-col>
        <el-col
          v-show="balance_visible"
          :md="12"
        >
          Balance
          <el-input
            v-model="balance"
            size="small"
          />
          <br><small>{{ balance / 8 }} day(s)</small>
        </el-col>
      </el-row>
      <el-row>
        <el-input
          v-model="selected_request.content"
          type="text"
          placeholder="Enter description... "
          style="width:100%"
        />
        <el-checkbox
          v-model="selected_request.is_content_visible"
        >
          Description visible to all users ?
        </el-checkbox>
      </el-row>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="new_request_dialog = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="save_request_record"
        >Save</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import { user_profile } from '../../../store/modules/user_profile';

export default {
  name: 'NewLeaveRequest',
  store: user_profile,
  props: {
    crm_user_id: {
      type: [String,Number],
      required: true
    }
  },
  data() {
    return {
      new_request_dialog: false,
      selected_request: {
        id: 0,
        new_leave: true,
        request_type: { id: '', name: '' },
        requestable: '',
        request_status: { id: 2, name: 'APPROVED' },
        crm_user_id: this.crm_user_id,
        value: 0
      },
      request_types: [],
      request_statuses: [],
      request_options: [],
      leave_types: [],
      has_leave: false,
      balance: 0,
      balance_visible: false,
      selected_value_header: 'Hour(s)',
      selected_value_disablity: true,

    };
  },
  watch: {
    selected_request() {
      this.date_range_changed();
    },
  },
  mounted() {
    this.set_default_input();
    this.fill_request_types();
    this.fill_request_options();
    this.fill_leave_types();
    this.fill_request_statuses();
  },
  methods: {
    selected_group() {
      let arr = this.selected_request.requestable.split('-');
      return arr > 0 ? arr[0] : '';
    },
    fill_request_options() {
      this.$http.get('/request_options').then((response) => {
        this.request_options = response.data;
      });
    },
    fill_request_types() {
      this.$http.get('/request_types').then((response) => {
        this.request_types = response.data;
      });
    },
    fill_leave_types() {
      this.$http.get('/leave_types').then((response) => {
        this.leave_types = response.data;
      });
    },
    fill_request_statuses() {
      this.$http.get('/request_statuses').then((response) => {
        this.request_statuses = response.data;
      });
    },
    calculate_hours(type) {
      if(this.selected_request.from_date != undefined && this.selected_request.to_date != undefined) {
        let data = {
          from_date: this.selected_request.from_date,
          to_date: this.selected_request.to_date,
          request_type: type,
          crm_user_id: this.crm_user_id
        };
        this.$http.post('/calculate_hours_by_request', data).then((response) => {
          this.selected_request.value = response.data.hours;
        });
      }
    },
    date_range_changed() {
      this.update_balance(this.selected_request.requestable);
    },
    update_balance(selected_value) {
      if (selected_value == undefined) {
        this.balance_visible = false;
        this.current_balance = 0;
        return;
      }
      this.selected_value_header = selected_value.startsWith('RequestType')
        ? 'Dollar(s)/Other' : 'Hour(s)';
      this.selected_value_disablity = !selected_value.startsWith(
        'RequestType'
      );
      this.balance_visible = selected_value.startsWith('LeaveType');

      if (
        selected_value != undefined &&
          selected_value.startsWith('RequestType') == false
      ) {
        // Calculate leave hours
        this.balance_visible = selected_value.startsWith('Leave');
        if (selected_value.startsWith('Leave')) {
          this.calculate_hours('leave');
        } else if (selected_value.startsWith('Overtime'))
          this.calculate_hours('overtime');

        let data = { leave_type_id: selected_value.split('-')[1],
          crm_user_id: this.crm_user_id };
        this.$http.post('/user_balance_payroll', data).then((response) => {
          this.balance = response.data.balance;
        });
      }
    },
    save_request_record() {
      if (this.selected_request == '' && this.selected_request.requestable == '')
        return;

      this.$http.post('/update_user_request/', this.selected_request).then(() => {
        this.new_request_dialog = false;
        this.$message({
          type: 'success',
          message: 'Updated successfully.',
        });
        this.$emit('reload_leave_requests');
        this.load_user_balance();
      },
      function() {
        this.$message.error('Couldn\'t save record. Perhaps text is too long?');
      }
      );
    },
    load_user_balance() {
      let data = { crm_user_id: this.crm_user_id,
        leave_type_id: this.selected_request.requestable.split('-')[1]};
      this.$http.post('/user_leave_balance', data).then(
        response => {
          this.balance = response.data.balance;
        });
    },
    set_default_input() {
      Array.from(document.querySelectorAll('.el-select__input, .el-input__inner, .el-textarea__inner')).forEach(
        function (e) {
          e.classList.add('browser-default');
          if (e.querySelector('input') != null)
            e.querySelector('input').className += ' browser-default';
        }
      );
    },
  },
};
</script>

<style scoped>
.el-row{ margin-bottom: 20px;}
.el-input--mini .el-input__inner {
  height: 28px !important;
}
</style>
