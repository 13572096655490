<template>
  <el-card
    class="box-card"
    :loading="loading"
    style="margin: 10px 0px !important;"
  >
    <div slot="header" class="clearfix">
      <span style="font-weight: 400; color: #696767;">{{
        title.toUpperCase()
      }}</span>

      <el-upload
        multiple
        class="upload  right"
        ref="upload"
        action="/upload_file_to_storage"
        :data="(upload_data = { current_path: this.path })"
        :headers="{ 'X-CSRF-TOKEN': csfr }"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :on-success="success_upload"
        :on-error="error_upload"
        :file-list="up_files"
        :show-file-list="false"
      >
        <el-button icon="el-icon-paperclip" circle></el-button>
      </el-upload>
    </div>
    <el-table :data="files_list" style="width: 100%" >
      <el-table-column prop="url" label="Thumbnail" width="100">
        <template slot-scope="scope">
          <img v-bind:src="scope.row.url" width="60" class="image" />
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Name" sortable>
        <template slot-scope="scope">
          <el-button
            type="text"
            size="mini"
            @click="handlePreview(scope.row.url)"
            >{{ scope.row.name }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column prop="size" label="Size" width="80"> </el-table-column>
      <el-table-column prop="url" label="Action" width="100">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="delete_file(scope.$index, scope.row.path)"
            circle
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>
<script>
import Vue from "vue";

export default {
  name: "AttachmentSection",
  props: {
    title: String,
    path: String,
    allow_upload: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: false,
      files_list: [],
      up_files: [],
      csfr: Vue.http.headers.common["X-CSRF-Token"],
    };
  },
  mounted() {},
  methods: {
    fill_data() {
      if (this.path == "") return;
      this.loading = true;
      this.$http.get(`/get_files_for_folder?path=${this.path}`).then(
        (response) => {
          this.files_list = response.data.files;
          this.loading = false;
        },
        (response) => {
          this.loading = false;
        }
      );
    },
    download_file() {},
    handleRemove(file, fileList) {},
    success_upload() {
      this.fill_data();
    },
    error_upload(err, file, fileList) {},
    handlePreview(file) {
      let preview_url = String(file).replace("get_file", "preview_file");
      let d = encodeURIComponent("&");
      preview_url = String(preview_url).replace(/&/g, d);
      window.location = encodeURI(preview_url);
    },
    delete_file(index, path) {
      this.loading = true;
      this.$http
        .get("/delete_file", {
          params: { path: path },
        })
        .then((response) => {
          this.loading = false;
          this.$delete(this.files_list, index);
          this.$message({
            message: "File deleted",
            type: "success",
          });
        });
    },
  },
  watch: {
    path: function(newVal) {
      if (newVal != "") this.fill_data();
    },
  },
};
</script>
