<template>
  <div>
    <el-button @click="dialog_visible = true" size="mini" icon="el-icon-folder-add">INVITE SUPPLIER</el-button>
    <el-dialog title :visible.sync="dialog_visible" width="30%">
      <div class="header">ADD SUPPLIER</div>
      <el-divider></el-divider>

      <el-form
        :model="supplier"
        status-icon
        :rules="rules"
        size="mini"
        ref="supplier_form"
        :inline-message="true"
      >
        <el-form-item label="Name:" prop="name">
          <el-input type="text" v-model="supplier.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Email:" prop="email">
          <el-input type="text" v-model="supplier.email" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            size="mini"
            @click="submit_form('supplier_form')"
          >CREATE SUPPLIER</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "InviteSupplier",
  props: ["branch_id"],
  data() {
    return {
      supplier: {
        name: null,
        email: null
      },
      dialog_visible: false,
      rules: {
        name: [
          { required: true, message: "Please insert a name", trigger: "change" }
        ],
        email: [
          {
            required: true,
            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            message: "Please insert a email",
            trigger: "change"
          }
        ]
      }
    };
  },
  created() {},

  methods: {
    create_supplier() {
      return new Promise((resolve, reject) => {
        let params = {
          name: this.supplier.name,
          email: this.supplier.email,
          branch_id: this.branch_id
        };
        this.$http.post("/tenderiq/create_supplier_tender", params).then(
          response => {
            this.$message({
              message: "Supplier created",
              type: "success"
            });

            resolve(resolve);
          },
          function(response) {
            this.$message({
              message: response.data.errors,
              type: "error"
            });
            reject(response);
          }
        );
      });
    },
    submit_form(supplier_form) {
      this.$refs[supplier_form].validate(valid => {
        if (valid) {
          this.create_supplier();
        }
      });
    }
  }
};
</script>

<style scoped>
</style> 