<template>
  <div>
    <div class="field">
      <p>
        <label class="el-form-item__label" />
     

        <br>
        {{ account_name }} 

     


        
        <el-button
          size="mini"
          @click="show_proposal_account"
        >
          Change account
        </el-button>
      </p>
      <p> 
        <span v-show="account_on_contract">
          On Contract
        </span>
        <span v-show="account_end_contract">
          |  Contact end date:   {{ contract_end_date }}
        </span>
      </p>
    </div>
    <el-dialog
      id="change_account_id"
      title="Change Account"
      :visible.sync="change_account_dialog"
      :close-on-click-modal="false"
      width="30%"
    >
      <p>
        <strong>Current Account</strong>
        <br>
        <span>{{ account_name }}</span>
      </p>

      <p>
        <strong>Target Account</strong>
        <br>
        <span>
          <el-select
            v-model="selected_target_account"
            filterable
            remote
            style="width:100%"
            reserve-keyword
            placeholder="Please enter a keyword"
            :remote-method="remoteMethod"
            size="mini"
            @change="select_target_account"
          >
            <el-option
              v-for="item in accounts_result"
              :key="item.id"
              style="min-width: 300px"
              :label="item.account_name"
              :value="item.id"
            />
          </el-select>
        </span>
      </p>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="mini"
          @click="change_account_dialog = false"
        >Cancel</el-button>
        <el-button
          :disabled="selected_target_account == ''"
          size="mini"
          type="primary"
          @click="confirm_proposal_account"
        >Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>



import { ProposalMixin } from 'mixins/ProposalMixin.js';

export default {
  name: 'ProposalAccount',
  mixins: [ProposalMixin],
  props: ['proposal_id', 'account_name', 'account_on_contract', 'account_end_contract', 'contract_end_date'],
  data() {
    return {
      accounts_result: [],
      selected_target_account: '',
      change_account_dialog: false,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    select_target_account(e) {
      this.selected_target_account = e;
    },
    remoteMethod(query) {
      let _this = this;
      if (query !== '' && query.trim().length > 4) {
        setTimeout(() => {
          let encoded_query = encodeURIComponent(query);
          this.$http
            .get(`/autocomplete_accounts?term=${encoded_query}`)
            .then((response) => {
              _this.accounts_result = response.body.data;
            });
        }, 200);
      } else {
        this.accounts_result = [];
      }
    },
    show_proposal_account() {
      this.change_account_dialog = true;
    },
    confirm_proposal_account() {
      let _this = this;
      this.$confirm(
        'Are you sure to change current proposal account, it will change everything related to this job to the newly selected account ?',
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      )
        .then(() => {
          _this
            .change_proposal_account(
              _this.proposal_id,
              _this.selected_target_account
            )
            .then(
              (response) => {                
                _this.$message({ message: response.message, type: 'success' });
                location.reload();
              },
              (response) => {
                _this.$message({ message: 'Error happened', type: 'error' });
              }
            );
        })
        .catch(() => {
          return false;
        });
    },
  },
};
</script>
