<script>
export default {
  name: 'Toast',
  data() {
    return {
    };
  },
  methods: {
    toasted_with(message, color) {
      if(!event.target.checked)
        Materialize.toast(message, 3000, color);
    }
  }
};
</script>

<style scoped>

</style>              