import {Request} from '../utilities/Request';

export function loadCategoryWorkingFiles(this_obj, proposal_id) {
  return Request.get(this_obj, `/api/proposals/${proposal_id}/category_working_files`);
}

export function createCategoryWorkingFile(this_obj, proposal_id, name) {
  return Request.post(
    this_obj,
    `/api/proposals/${proposal_id}/category_working_files`,
    null,
    { name: name }
  );
}

export function editCategoryWorkingFile(this_obj, id, name) {
  return Request.patch(
    this_obj,
    `/api/category_working_files/${id}`,
    null,
    { name: name }
  );
}

export function updateCategoryWorkingFilePositions(this_obj, proposal_id, categories) {
  return Request.patch(
    this_obj,
    `/api/proposals/${proposal_id}/category_working_files/update_positions`,
    null,
    { categories: categories }
  );
}

export function deleteCategoryWorkingFile(this_obj, id) {
  return Request.delete(
    this_obj,
    `/api/category_working_files/${id}`
  );
}

export function updateCategoryWorkingFileQty(this_obj, id, quantity_unit_id, qty) {
  return Request.post(
    this_obj,
    `/api/category_working_files/${id}/update_qty`,
    null,
    {
      quantity_unit_id: quantity_unit_id,
      qty: qty
    }
  );
}
