<template>
  <div v-loading="main_loading">
    <div
      slot="header"
      class="clearfix"
    />
    <div style="padding:10px;font-size:14px;">
      <div>
        <div
          id="locations_list"
          style="width: 100%;"
        >
          <div
            v-for="location in locations_list"
            :key="location.id"
            style="border-bottom: 1px solid #f3f3f3; border-top: 1px solid #f3f3f3; padding: 10px 3px;"
          >
            <el-button
              circle
              icon="el-icon-map-location"
              size="mini"
              style="color:darkseagreen;font-weight:bold"
              plain
              @click="
                display_map_only = true;
                edit_location(location);
              "
            />
            <i class="el-icon-link" />
            <b>{{ location.locationable_info }}</b>
            <i
              class="el-icon-place"
              style="color:green"
            />
            {{ location.from_address }}
            <i
              class="el-icon-location-information"
              style="color: darkseagreen;"
            />
            <span style="color: darkseagreen;">{{ location.to_address }}</span>

            <i
              v-show="location.return_trip == true"
              class="el-icon-refresh"
              style="font-size: 20px;color: darkseagreen;"
            />

            <i class="el-icon-share" />
            {{ location.distance }}kms
            <i class="el-icon-time" />
            <Time :is_hours="is_hours" :minutes="location.duration" />
            <span
              v-show="location.description"
              style="font-style: italic;color:orange"
            >
              <i class="font-style: italic;" />
              {{ location.description }}
            </span>

            <el-button
              circle
              icon="el-icon-edit"
              size="mini"
              round
              @click="
                display_map_only = false;
                edit_location(location);
              "
            />

            <el-button
              circle
              icon="el-icon-delete"
              size="mini"
              round
              @click="delete_location(location)"
            />

            <el-button
              type="danger"
              plain
              size="mini"
              @click="download_pack_slip(location.id)"
            >
              Packing slip
            </el-button>
          </div>
        </div>

        <el-button
          type="info"
          plain
          size="small"
          class="right"
          @click="
            display_map_only = false;
            add_new_location();
          "
        >
          +Add Location
        </el-button>
      </div>
    </div>

    <el-dialog
      title="Location Details"
      :visible.sync="edit_location_dialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-row :gutter="10">
        <el-col
          v-show="display_map_only == false"
          :md="24"
        >
          <el-form
            ref="location_form"
            v-loading="delivery_form_loading"
            :model="selected_location"
            label-position="left"
            label-width="100px"
            :rules="rules"
          >
            <el-form-item
              label="Task"
              style="width: 300px;"
              prop="locationable_id"
            >
              <el-select
                v-model="selected_location.locationable_id"
                placeholder="Select"
              >
                <el-option
                  v-for="item in job_proposals_list"
                  :key="item.id"
                  :label="item.job_name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="From">
              <el-autocomplete
                v-model="selected_location.from_address"
                style="min-width: 300px"
                placeholder="Please input"
                :fetch-suggestions="fetch_address_suggestions"
                @select="from_address_selected"
              >
                <el-button
                  slot="append"
                  icon="el-icon-map-location"
                  @click="get_coordinates('from_address')"
                />
              </el-autocomplete>
              <p style="line-height: 13px; !important; margin-top:0px;">
                <small>
                  <i>{{ selected_location.from_lon }} ,
                    {{ selected_location.from_lat }}</i>
                </small>
              </p>
            </el-form-item>
            <el-form-item label="To">
              <el-autocomplete
                v-model="selected_location.to_address"
                style="min-width: 300px"
                placeholder="Please input"
                :fetch-suggestions="fetch_address_suggestions"
                @select="to_address_selected"
              >
                <el-button
                  slot="append"
                  icon="el-icon-map-location"
                  @click="get_coordinates('to_address')"
                />
              </el-autocomplete>
              <p style="line-height: 0px; !important">
                <small>
                  <i>{{ selected_location.to_lon }} ,
                    {{ selected_location.to_lat }}</i>
                </small>
              </p>
            </el-form-item>
            <el-form-item label="Return trip?">
              <el-checkbox
                v-model="selected_location.return_trip"
                @change="update_return_trip"
              />
            </el-form-item>
            <el-form-item label="Distance">
              <el-input
                v-model="selected_location.distance"
                disabled
                style="width: 200px;"
              >
                <template slot="append">
                  KM
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="Duration">
              <el-input
                v-model="display_duration"
                disabled
                style="width: 200px;"
              />
            </el-form-item>
            <el-form-item label="Description">
              <el-input
                v-model="selected_location.description"
                type="textarea"
              />
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :md="24">
          <vl-map
            id="location_map"
            ref="location_map"
            :load-tiles-while-animating="true"
            :load-tiles-while-interacting="true"
            data-projection="EPSG:4326"
            style="height: 300px"
            @click="map_clicked"
          >
            <vl-view
              :zoom="14"
              :center="dialog_mapcenter"
              :rotation.sync="rotation"
            />
            <vl-layer-tile id="osm1">
              <vl-source-osm />
            </vl-layer-tile>

            <vl-layer-vector>
              <vl-source-vector
                ref="polyline_ref"
                :features.sync="polyline_result"
              />
              <vl-style-box>
                <vl-style-stroke
                  color="blue"
                  :width="3"
                />
              </vl-style-box>
            </vl-layer-vector>
            <vl-layer-vector>
              <vl-feature>
                <vl-geom-point :coordinates="start_coordinates" />
                <vl-style-box>
                  <vl-style-icon
                    src="/images/icons/map-marker-a.png"
                    :scale="0.4"
                    :anchor="[0.5, 1]"
                  />
                </vl-style-box>
              </vl-feature>
              <vl-feature>
                <vl-geom-point :coordinates="end_coordinates" />
                <vl-style-box>
                  <vl-style-icon
                    src="/images/icons/map-marker-b.png"
                    :scale="0.4"
                    :anchor="[0.5, 1]"
                  />
                </vl-style-box>
              </vl-feature>
            </vl-layer-vector>
          </vl-map>
        </el-col>
      </el-row>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="edit_location_dialog = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="save_location"
        >Save</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { editor_store } from 'store/modules/editor';
import { ProposalMixin } from 'mixins/ProposalMixin.js';
import { debuglog } from 'util';
import { setTimeout } from 'timers';
import {display_time} from "../../utilities/Time";
import Time from '../shared/Time';

export default {
  name: 'Delivery',
  components: { Time },
  store: editor_store,
  mixins: [ProposalMixin],
  props: ['suggested_from_address', 'suggested_to_address'],
  data() {
    return {
      display_map_only: false,
      main_loading: false,
      delivery_form_loading: false,
      polyline_result: [],
      timeout: null,
      accept_coordinates: '',
      start_coordinates: [175.24637154914788, -37.758370168496555],
      end_coordinates: [175.2462999, -37.75827],
      edit_location_dialog: false,
      selected_location: {},
      suggested_locations: [],
      zoom: 17,
      center: [175.2462999, -37.7582685],
      dialog_mapcenter: [175.2462999, -37.7582685],
      rotation: 0,
      rules: {
        locationable_id: [
          { required: true, message: 'Please select task', trigger: 'blur' },
        ],
      },
      display_duration: 0
    };
  },
  mounted() {
    this.load_locations_list();
  },
  computed: {
    ...mapGetters([
      'job_proposals_list',
      'locations_list',
      'proposal',
      'proposal_id',
      'is_hours'
    ]),
  },
  methods: {
    ...mapActions(['set_locations_list']),
    update_return_trip(e) {
      if (e == true) {
        this.selected_location.duration = this.selected_location.duration * 2;
        this.selected_location.distance = this.selected_location.distance * 2;
      } else {
        this.selected_location.duration = this.selected_location.duration / 2;
        this.selected_location.distance = this.selected_location.distance / 2;
      }
      this.display_duration = display_time(this.is_hours,  this.selected_location.duration);
    },
    load_locations_list() {
      this.set_locations_list([]);
      this.main_loading = true;
      this.$http
        .post('locations_by_proposal_tasks', { proposal_id: this.proposal.id })
        .then(
          (response) => {
            this.set_locations_list(response.body);
            this.main_loading = false;
          },
          (response) => {
            this.main_loading = false;
          }
        );
    },
    map_clicked(e, args) {
      let _this = this;
      if (this.accept_coordinates != '') {
        // get nearest address
        let url = `https://api.openrouteservice.org/geocode/reverse?api_key=5b3ce3597851110001cf6248b726cc08c83741a2a911ce99b12529c3&point.lon=${e.coordinate[0]}&point.lat=${e.coordinate[1]}&boundary.circle.radius=1&size=1&layers=address&sources=openaddresses&boundary.country=NZ`;
        var request = new XMLHttpRequest();
        if (this.accept_coordinates == 'from_address') {
          this.selected_location.from_lon = e.coordinate[0];
          this.selected_location.from_lat = e.coordinate[1];
          this.start_coordinates = e.coordinate;
        } else {
          this.selected_location.to_lon = e.coordinate[0];
          this.selected_location.to_lat = e.coordinate[1];
          this.end_coordinates = e.coordinate;
        }
        request.open('GET', url);
        request.setRequestHeader(
          'Accept',
          'application/json, application/geo+json, img/png; charset=utf-8'
        );
        this.delivery_form_loading = true;
        request.onreadystatechange = function() {
          if (this.readyState === 4) {
            let result = eval('(' + this.responseText + ')');

            if (result.features.length > 0) {
              _this['selected_location'][_this.accept_coordinates] =
                result.features[0].properties.label;
            } else
              _this['selected_location'][_this.accept_coordinates] =
                'Uknown address';
            _this.delivery_form_loading = false;
            _this.get_route_details();
            _this.accept_coordinates = '';
          }
        };
        request.send();
      }
    },
    save_location() {
      this.$refs['location_form'].validate((valid) => {
        if (valid) {
          let params = {
            location: this.selected_location,
          };
          let request_type = '';
          let url = '/locations/';

          if (this.selected_location.id == 0) request_type = 'post';
          else {
            request_type = 'patch';
            url += this.selected_location.id;
          }

          this.delivery_form_loading = true;
          this.$http[request_type](url, params).then(
            (response) => {
              this.delivery_form_loading = false;
              if (request_type == 'post')
                this.locations_list.push(response.body.location);
              else this.load_locations_list();
              this.edit_location_dialog = false;
            },
            (response) => {
              this.delivery_form_loading = false;
            }
          );
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    get_coordinates(sender) {
      this.accept_coordinates = sender;
    },
    add_new_location() {
      this.clear_inputs();
      this.fetch_suggestion();
      this.show_dialog();
    },
    show_dialog() {
      this.edit_location_dialog = true;
      let _this = this;
      setTimeout(() => {
        _this.set_inputs_to_default();
      }, 200);
    },
    fetch_suggestion() {
      console.log('fetch_suggestion');
      console.log(this.suggested_from_address);
      console.log(this.suggested_from_address);
      if (
        this.suggested_from_address == undefined ||
        this.suggested_to_address == undefined
      )
        return;

      let msg = [];
      if (this.suggested_from_address == '')
        msg.push('Branch addesss is empty');

      if (this.suggested_to_address == '')
        msg.push('Account physical addesss is empty');

      if (msg.length > 0) {
        this.$alert(msg.join(','), 'Missing addresse(s)', {
          confirmButtonText: 'OK',
        });
      }

      this.delivery_form_loading = true;
      let addresses_fetched = 0;
      this.get_coordinates_by_address(this.suggested_from_address).then(
        (response) => {
          if (response.length > 0) {
            let feature = response[0];
            addresses_fetched++;
            this.selected_location.from_address = this.suggested_from_address;
            this.selected_location.from_lon = feature.geometry.coordinates[0];
            this.selected_location.from_lat = feature.geometry.coordinates[1];
            this.start_coordinates = [
              this.selected_location.from_lon,
              this.selected_location.from_lat,
            ];
            if (addresses_fetched > 1) {
              this.delivery_form_loading = false;
              this.get_route_details();
            }
          } else {
            this.delivery_form_loading = false;
          }
        }
      );
      this.get_coordinates_by_address(this.suggested_to_address).then(
        (response) => {
          if (response.length > 0) {
            let feature = response[0];
            addresses_fetched++;
            this.selected_location.to_address = this.suggested_to_address;
            this.selected_location.to_lon = feature.geometry.coordinates[0];
            this.selected_location.to_lat = feature.geometry.coordinates[1];
            this.end_coordinates = [
              this.selected_location.to_lon,
              this.selected_location.to_lat,
            ];
            if (addresses_fetched > 1) {
              this.delivery_form_loading = false;
              this.get_route_details();
            }
          } else {
            this.delivery_form_loading = false;
          }
        }
      );
    },

    clear_inputs() {
      this.selected_location = {
        id: 0,
        from_address: '573 Te Rapa Road, New Zealand',
        to_address: '575 Te Rapa Rd, Te Rapa, Hamilton 3200',
        from_lon: 175.24637154914788,
        from_lat: -37.758370168496555,
        to_lon: 175.246313,
        to_lat: -37.758263,
        description: '',
        distance: 0,
        duration: 0,
        return_trip: false,
        locationable_type: 'JobProposal',
        locationable_id: null,
      };
      this.display_duration = display_time(this.is_hours, this.selected_location.duration);
    },
    edit_location(location) {
      this.clear_inputs();
      this.selected_location = Object.assign({}, location);
      this.display_duration = display_time(this.is_hours, this.selected_location.duration);
      this.show_dialog();
    },
    download_pack_slip(location_id) {
      this.download_location_packing_slip(location_id).then((response) => {
        this.download('', response);
      });
    },
    download(file, data) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Packing_slip.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
      return;
    },
    delete_location(location) {
      this.$confirm(
        'This will permanently delete the location. Continue?',
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      )
        .then(() => {
          this.$http.delete(`/locations/${location.id}`).then(
            (response) => {
              this.load_locations_list();
              this.$message({
                type: 'success',
                message: 'Delete completed',
              });
            },
            (response) => {}
          );
        })
        .catch(() => {});
    },
    from_address_selected(e) {
      this.selected_location.from_lon = e.link.geometry.coordinates[0];
      this.selected_location.from_lat = e.link.geometry.coordinates[1];
      this.start_coordinates = [
        this.selected_location.from_lon,
        this.selected_location.from_lat,
      ];
      this.get_route_details();
    },
    to_address_selected(e) {
      this.selected_location.to_lon = e.link.geometry.coordinates[0];
      this.selected_location.to_lat = e.link.geometry.coordinates[1];
      this.end_coordinates = [
        this.selected_location.to_lon,
        this.selected_location.to_lat,
      ];
      this.get_route_details();
    },
    get_route_details(no_refresh = false) {
      let _this = this;
      if (
        this.selected_location.from_lon == undefined ||
        this.selected_location.from_lat == undefined ||
        this.selected_location.to_lon == undefined ||
        this.selected_location.to_lat == undefined
      )
        return;
      let start_loc = `${this.selected_location.from_lon},${this.selected_location.from_lat}`;
      let end_loc = `${this.selected_location.to_lon},${this.selected_location.to_lat}`;
      var request = new XMLHttpRequest();

      request.open(
        'GET',
        `https://api.openrouteservice.org/v2/directions/driving-car?api_key=5b3ce3597851110001cf6248b726cc08c83741a2a911ce99b12529c3&start=${start_loc}&end=${end_loc}`
      );

      request.setRequestHeader(
        'Accept',
        'application/json, application/geo+json, img/png; charset=utf-8'
      );

      request.onreadystatechange = function() {
        if (this.readyState === 4) {
          let result = eval('(' + this.responseText + ')');
          _this.polyline_result = result.features;
          if (no_refresh == false) {
            _this.selected_location.duration = 0;
            _this.selected_location.distance = 0;
          }
          if (result.features.length > 0 && no_refresh == false) {
            _this.selected_location.duration = (
              parseFloat(result.features[0].properties.summary['duration']) /
              60.0
            ).toFixed(2);
            _this.selected_location.distance = (
              parseFloat(result.features[0].properties.summary['distance']) /
              1000
            ).toFixed(2);
            if (_this.selected_location.return_trip == true) {
              _this.selected_location.duration *= 2;
              _this.selected_location.distance *= 2;
            }
            _this.display_duration = display_time(_this.is_hours,  _this.selected_location.duration);
          }
          setTimeout(() => {
            _this.$refs.location_map.$map
              .getView()
              .fit(_this.$refs.polyline_ref.getFeatures()[0].getGeometry(), {
                size: _this.$refs.location_map.$map.getSize(),
                duration: 400,
              });
          }, 300);
        }
      };

      request.send();
    },

    get_coordinates_by_address(query) {
      return new Promise((resolve, reject) => {
        if (query == '') return resolve([]);

        var request = new XMLHttpRequest();
        request.open(
          'GET',
          `https://api.openrouteservice.org/geocode/search?api_key=5b3ce3597851110001cf6248b726cc08c83741a2a911ce99b12529c3&text=${query}&boundary.country=NZ`
        );

        request.setRequestHeader(
          'Accept',
          'application/json, application/geo+json, application/gpx+xml, img/png; charset=utf-8'
        );

        request.onreadystatechange = function() {
          if (this.readyState === 4) {
            let response_text = eval('(' + this.responseText + ')');

            let results = [];
            resolve(response_text.features);
          }
        };
        request.send();
      });
    },
    fetch_address_suggestions(query, cb) {
      if (query.length > 3) {
        var request = new XMLHttpRequest();
        request.open(
          'GET',
          `https://api.openrouteservice.org/geocode/search?api_key=5b3ce3597851110001cf6248b726cc08c83741a2a911ce99b12529c3&text=${query}&boundary.country=NZ`
        );

        request.setRequestHeader(
          'Accept',
          'application/json, application/geo+json, application/gpx+xml, img/png; charset=utf-8'
        );

        request.onreadystatechange = function() {
          if (this.readyState === 4) {
            console.log('Status:', this.status);
            console.log('Headers:', this.getAllResponseHeaders());
            console.log('Body:', this.responseText);
            clearTimeout(this.timeout);
            let response_text = eval('(' + this.responseText + ')');
            this.timeout = setTimeout(() => {
              let results = [];
              response_text.features.forEach((el, idx) => {
                results.push({ value: el.properties.label, link: el });
              });
              cb(results);
            }, 3000);
          }
        };

        request.send();
      }
    },
    set_inputs_to_default() {
      let _this = this;
      Array.from(document.querySelectorAll('.el-input__inner')).forEach(
        function(e, i) {
          e.classList.add('browser-default');
          if (e.querySelector('input') != null)
            e.querySelector('input').className += ' browser-default';
        }
      );
    },
  },
  watch: {
    'proposal.id': function(newVal) {
      this.load_locations_list();
    },
    edit_location_dialog: function(newVal) {
      if (newVal == false) return;
      this.start_coordinates = [
        parseFloat(this.selected_location.from_lon),
        parseFloat(this.selected_location.from_lat),
      ];
      this.end_coordinates = [
        parseFloat(this.selected_location.to_lon),
        parseFloat(this.selected_location.to_lat),
      ];

      this.get_route_details(true);
    },
  },
};
</script>

<style></style>
