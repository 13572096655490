<template>
  <div>
    <el-col
      v-if="task.is_show == true"
      :xs="24"
      :sm="12"
      :md="12"
      :lg="12"
      :xl="8"
    >
      <KPIGauge
        :value="task.current_percent"
        :label="task.label"
        :stops="task.stops"
        :extra_infos="task.extra_infos"
      />
    </el-col>
    <el-col
      v-if="email.is_show == true"
      :xs="24"
      :sm="12"
      :md="12"
      :lg="12"
      :xl="8"
    >
      <KPIGauge
        :value="email.current_percent"
        :label="email.label"
        :stops="email.stops"
        :extra_infos="email.extra_infos"
      />
    </el-col>
  </div>
</template>

<script>
import KPIGauge from './KPIGauge';
import {maxValue} from '../../utilities/MaxValue';
import {display_time} from '../../utilities/Time';

export default {
  name: 'TurnaroudGauge',
  components: {
    KPIGauge
  },
  props: {
    kpis_gauge: {
      type: Object,
      required: true
    },
    is_manager: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      task: {
        stops: [0, 50, 70, 90, 100],
        current_percent: 0,
        label: 'Task',
        is_show: false,
        extra_infos: []
      },
      email: {
        stops: [0, 50, 70, 90, 100],
        current_percent: 0,
        label: 'Email',
        is_show: false,
        extra_infos: []
      }
    };
  },
  watch: {
    kpis_gauge() {
      this.task_init();
      this.email_init();
    }
  },
  methods: {
    task_init() {
      this.task.current_percent = maxValue(this.kpis_gauge.turn_around.task.response_perc);
      this.task.is_show = this.kpis_gauge.target_section.by_task;
      this.task.label = `Task: ${this.kpis_gauge.turn_around.task.response_perc}%`;
      this.task.stops = [
        0,
        this.kpis_gauge.target_section.turnaround_orange,
        this.kpis_gauge.target_section.turnaround_yellow,
        this.kpis_gauge.target_section.turnaround_green,
        100,
      ];
      if(this.is_manager == true) {
        this.task.extra_infos = [
          {
            attr: 'TASK COMPLETED',
            val: `${this.kpis_gauge.turn_around.task.total_completion}`
          },
          {
            attr: 'TARGET',
            val: `${this.kpis_gauge.turn_around.task.target}%`
          },
          {
            attr: 'RESPONSE',
            val: `${this.kpis_gauge.turn_around.task.response_perc}%`
          },
          {
            attr: 'WEIGH-IN RATE',
            val: `${this.kpis_gauge.turn_around.task.weighin_perc}%`
          },
        ];
      }
    },
    email_init() {
      this.email.current_percent = maxValue(this.kpis_gauge.turn_around.email.response_perc);
      this.email.is_show = this.kpis_gauge.target_section.by_email;
      this.email.label = `Email: ${this.kpis_gauge.turn_around.email.response_perc}%`;
      this.email.stops = [
        0,
        this.kpis_gauge.target_section.turnaround_email_orange,
        this.kpis_gauge.target_section.turnaround_email_yellow,
        this.kpis_gauge.target_section.turnaround_email_green,
        100,
      ];
      if(this.is_manager == true) {
        this.email.extra_infos = [
          {
            attr: 'EMAIL RESPONSE AVG',
            val:  display_time(this.kpis_gauge.is_hours, this.kpis_gauge.turn_around.email.email_response_avg_min)
          },
          {
            attr: 'TARGET',
            val: `${this.kpis_gauge.turn_around.email.target}%`
          },
          {
            attr: 'RESPONSE',
            val: `${this.kpis_gauge.turn_around.email.response_perc}%`
          },
          {
            attr: 'WEIGH-IN RATE',
            val: `${this.kpis_gauge.turn_around.email.weighin_perc}%`
          },
        ];
      }
    }
  }
};
</script>
