import {Request} from '../utilities/Request';

export function acc_income_caps(this_obj) {
  return Request.get(
    this_obj,
    '/api/acc_income_caps',
    null,
  );
}

export function create_acc_income_cap(this_obj, acc_income_cap) {
  return Request.post(
    this_obj,
    '/api/acc_income_caps',
    null,
    {
      acc_income_cap: acc_income_cap
    }
  );
}

export function delete_acc_income_cap(this_obj, id) {
  return Request.delete(
    this_obj,
    `/api/acc_income_caps/${id}`,
    null
  );
}
