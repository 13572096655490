<template>
  <div id="invite-new-account">
    <el-button
      id="invite-btn"
      type="primary"
      @click="dialogVisible = true"
    >
      Invite new Account
    </el-button>
    <el-dialog
      title="Invite new Account"
      :visible.sync="dialogVisible"
      width="40%"
    >
      <el-form
        ref="invite_form"
        :model="form"
        :rules="rules"
        label-position="left"
        label-width="120px"
        status-icon
      >
        <el-form-item
          label="Account Name"
          prop="account_name"
        >
          <el-input
            v-model="form.account_name"
            size="mini"
          />
        </el-form-item>
        <el-form-item
          label="Contact Name"
          prop="contact_name"
        >
          <el-input
            v-model="form.contact_name"
            size="mini"
          />
        </el-form-item>
        <el-form-item
          label="Email"
          prop="email"
        >
          <el-input
            v-model="form.email"
            size="mini"
          />
        </el-form-item>
        <el-form-item
          label="Discount Rate"
          prop="discount"
        >
          <el-input-number
            v-model="form.discount"
            :precision="2"
            :step="0.1"
            :max="100"
            :min="0"
            size="mini"
          /> %
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="send_invite_email('invite_form')"
        >Send Email</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>


import {defaultInput} from '../../utilities/DefaultInput';

export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        account_name: '',
        contact_name: '',
        email: '',
        discount: 0
      },
      rules: {
        account_name: [{ required: true, message: 'Please Input Account Name', trigger: 'change' }],
        contact_name: [{ required: true, message: 'Please Input Contact Name', trigger: 'change' }],
        email: [{ required: true, message: 'Please Input Email', trigger: 'change' }],
        discount: [{ required: true, message: 'Please Discount Rate', trigger: 'change' }],
      }
    };
  },
  watch: {
    dialogVisible: function (newVal) {
      if(newVal == true) {
        this.$nextTick(()=>{defaultInput();});
      }
    }
  },
  methods: {
    send_invite_email(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http.post('/send_invite_email', {form: this.form}).then(
            () => {
              this.$message({
                message: 'Invite Email sent',
                type: 'success',
              });
              this.dialogVisible = false;
            },
            (response) => {
              this.$message({
                type: 'error',
                message:
                    'Couldn\'t send invite email: ' +
                    response.data.errors,
              });
            }
          );
        }
      });
    }
  }
}
;
</script>

<style scoped>
#invite-btn {
  float: right;
}

.el-form-item {
  margin-bottom: 18px !important;
}
</style>