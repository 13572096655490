<template>
  <el-form-item>
    <el-popover
      v-model="popover_visible"
      placement="bottom"
      trigger="click"
    >
      <el-button
        class="width--full"
        type="primary"
        @click="onApproved"
      >
        APPROVE
      </el-button>
      <br>
      <br>
      <el-button
        class="width--full"
        type="success"
        @click="onApprovedSend"
      >
        APPROVE & SEND INVOICE
      </el-button>
      <el-button
        slot="reference"
        type="danger"
        plain
        class="width--full"
      >
        SEND INVOICE  <i class="el-icon-caret-right el-icon-right" />
      </el-button>
    </el-popover>
  </el-form-item>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'SendInvoice',
  data() {
    return {
      popover_visible: false
    };
  },
  computed: {
    ...mapGetters([
      'get_sale',
      'get_scheduled_item'
    ]),
    sale: {
      get() {
        return this.get_sale;
      }
    }
  },
  methods: {
    onApproved() {
      this.popover_visible = false;
      this.sale.approved = true;

      this.approve_request();
    },

    onApprovedSend() {
      this.popover_visible = false;
      this.approve_request(this.send_request);
    },
    send_request() {
      this.sale.send_invoice = true;

      let send_request = this.$http.patch(`/api/sales/${this.sale.id}/send_invoice`,
        { sale : { send_invoice :this.sale.send_invoice} });

      send_request.then((response)=>{
        this.sale.sales_approved_at = response.body.sales_approved_at;
        this.$loading().close();

        this.$message({
          message: response.body.message,
          type: 'success'
        });

      },(error)=>{
        this.sale.approved = error.body.approved;
        this.sale.send_invoice = error.body.send_invoice;
        this.sale.invoiceable = error.body.invoiceable;
        this.$loading().close();

        this.$message({
          type: 'error',
          message: error.body.message
        });
      });
    },
    approve_request(keep_request_db=null) {
      this.sale.approved = true;
      this.sale.invoiceable = true;

      if(keep_request_db) {
        this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
        });
      }

      let request = this.$http.patch(`/api/sales/${this.sale.id}/create_invoice`,
        { sale : this.sale });
      request.then((response)=>{
        this.sale.sales_approved_at = response.body.sales_approved_at;
        this.sale.invoice_ids = response.body.invoice_ids;
        if(keep_request_db) {
          keep_request_db();
        } else {
          this.$message({
            message: response.body.message,
            type: 'success'
          });
        }
      },(error)=>{
        this.sale.approved = error.body.approved;
        this.sale.invoiceable = error.body.invoiceable;
        this.sale.send_invoice = error.body.send_invoice;
        if(keep_request_db) {
          this.$loading().close();
        }

        this.$message({
          type: 'error',
          message: error.body.message
        });
      });
    },
  }
};
</script>
