<template>
  <div class="working-file__detail">
    <div
      v-if="working_file_detail.pdf_path"
      class="working-file__section"
    >
      <embed
        :src="working_file_detail.pdf_path"
        type="application/pdf"
        frameBorder="0"
        scrolling="auto"
        width="100%"
        height="450px"
      >
    </div>
    <div
      v-else
      class="working-file__section"
    >
      <div>
        {{ working_file_detail.tasks.join(', ') }}
      </div>
      <div class="working-file__section">
        <div
          v-for="(stock, stock_index) in Object.entries(working_file_detail.stocks)"
          :key="stock_index"
        >
          {{ category_stocks(stock) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClientViewDetail',
  props: {
    working_file_detail: {
      type: Object,
      required: true
    }
  },
  computed: {
    category_stocks() {
      return function ([category, stocks]) {
        return `${category}: ${stocks.join(', ')}`;
      };
    }
  },
};
</script>

<style lang="scss">
.working-file__detail {
  text-align: left !important;
  margin-top: 15px;
}
.working-file__section {
  margin-top: 10px;
}
</style>