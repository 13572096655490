import Vue from 'vue';
import Vuex from 'vuex';
import { dispatch } from 'rxjs/internal/observable/pairs';

Vue.use(Vuex);

export const user_profile = new Vuex.Store({
  strict: false,
  state: {
    selected_user_info: {},
    user_leave_balance: [],
    is_admin: 0
  },
  getters: {
    selected_user_info: state => {
      return state.selected_user_info;
    },
    user_leave_balance: state => {
      return state.user_leave_balance;
    },
    is_admin: state => {
      return state.is_admin;
    }
  },
  mutations: {
    SET_USER_LEAVE_BALANCE(state,payload){
      state.user_leave_balance = payload;
    },
    SET_SELECTED_USER_INFO(state, payload) {
      state.selected_user_info = payload;
    },
    SET_IS_ADMIN(state, payload) {
      state.is_admin = payload;
    }
  },
  actions: {
    set_selected_user_info(context, selected_user_info) {
      context.commit('SET_SELECTED_USER_INFO', selected_user_info);
      context.dispatch('load_user_balance');
    },
    set_is_admin(context, is_admin) {
      context.commit('SET_IS_ADMIN', is_admin);
    },
    // set_user_leave_balance(context, user_leave_balance){
    //     context.commit('SET_USER_LEAVE_BALANCE', user_leave_balance)
    // },
    load_user_balance(context) {
      let _this = this;
      let data = { crm_user_id: _this.state.selected_user_info.id };
      Vue.http.post('/user_leave_balance', data).then(response => {
        context.commit('SET_USER_LEAVE_BALANCE', response.data.balance);
      });
    },
  }
});