<template>
  <div>
    <el-row :gutter="10">
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="24"
        :xl="24"
        style="padding:20px;"
      >
        <el-tabs
          v-model="activeName"
          class="comTabs"
          @tab-click="handleClick"
        >
          <el-tab-pane
            label="Job Notes"
            name="first"
          >
            <div v-loading="is_loading">
              <el-col
                :xs="24"
                :sm="24"
                :md="12"
                :lg="12"
                :xl="12"
                class="right"
              >
                <jodit-editor
                  :id="random_id"
                  v-model="commentData"
                  :buttons="buttons"
                  :config="jodit_config"
                />
                <div style="width:100%; text-align:right">
                  <el-button
                    type="success"
                    plain
                    @click="post_note"
                  >
                    Post comment
                  </el-button>
                </div>
              </el-col>
              <el-col
                :xs="24"
                :sm="24"
                :md="12"
                :lg="12"
                :xl="12"
              >
                <JobNotesList
                    :notes_list="notes_list"/>
              </el-col>
            </div>
          </el-tab-pane>
          <el-tab-pane
            label="Emails"
            name="second"
          >
            <FilteredEmails
              v-if="proposal.message_references.length > 0"
              :filtered_msg_id="proposal.message_references"
              :mailbox_account="proposal.mailbox_account_id"
              :branch_id="proposal.branch_id"
              :proposal_id="proposal.id"
            />
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { editor_store } from 'store/modules/editor';
import { ProposalMixin } from 'mixins/ProposalMixin.js';
import FilteredEmails from '../../mailbox/FilteredEmails.vue';
import JobNotesList from './JobNotesList';

export default {
  name: 'JobCommunication',
  store: editor_store,
  components: {
    FilteredEmails,
    JobNotesList
  },
  mixins: [ProposalMixin],
  data() {
    return {
      is_loading: false,
      random_id: `jodit_comm${parseInt(Math.random() * 100, 10)}`,
      commentData: '',
      jodit_config: { enter: 'br' },
      link: [],
      visible: false,
      activeName: 'first',
      dated: true,
      working_files_list: [],
      buttons: [
        'fullsize',
        'source',
        '|',
        'table',
        'image',
        'link',
        '|',
        'bold',
        'underline',
        'strikethrough',
        'italic',
        '|',
        'paragraph',
        'font',
        'fontsize',
        '|',
        'ul',
        'ol',
        '|',
        'undo',
        'redo',
        '|',
        'selectall',
        'cut',
        'copy',
        'paste',
        'copyformat',
        '|',
        'align',
        'outdent',
        'indent',
        '|',
        'hr',
        'brush',
        'eraser',
        'symbol',
        '|',
        'superscript',
        'subscript',
        '|',
        'print',
      ],
    };
  },
  mounted() {},
  computed: {
    ...mapGetters(['notes_list', 'proposal_id', 'proposal']),
  },
  methods: {
    ...mapActions(['add_note']),
    handleClick(tab, event) {
      console.log(tab, event);
    },
    post_note() {
      let params = {
        comment: { proposal_id: this.proposal_id, entry: this.commentData },
      };
      this.is_loading = true;

      this.add_new_note(params).then(
        (response) => {
          this.add_note(response);
          this.is_loading = false;
        },
        (reason) => {}
      );
    },
  },
  watch: {},
};
</script>
<style>
.emailsentry {
  background: rgba(223, 245, 211, 0.631);
  padding: 10px;
  margin-bottom: 10px;
  font-size: 13px;
}
.emailsentry p {
  color: #8f8f8f;
  padding-left: 10px;
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: 13px;
}
.comTabs .el-tabs__item {
  background: #f3f3f3;
  border: 2px solid #fff;
  color: #666;
  padding: 0px 20px !important;
  font-weight: 300;
  text-transform: uppercase;
}
.comTabs .el-tabs__item.is-active {
  background: rgb(118, 196, 0);
  border: 2px solid #fff;
  color: #fff;
}
.comTabs .el-tabs__active-bar {
  background-color: rgb(118, 196, 0) !important;
}
</style>
