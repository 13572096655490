<template>
  <div class="categor_tasks_div">
    <h1 class="headlinered">
      Job Categories
      <div style="display: flow-root">
        <el-button
          type="primary"
          size="mini"
          class="right"
          @click="create_category"
        >
          New Category
        </el-button>
      </div>
      <hr>
    </h1>

    <el-table :data="data">
      <el-table-column
        prop="name"
        label="Name"
      />
      <el-table-column
        prop="users"
        label="Project Planners"
      />
      <el-table-column
        prop="name"
        label="Action"
      >
        <template slot-scope="scope">
          <el-button
            circle
            icon="el-icon-edit"
            @click="edit_category(scope.row)"
          />
          <el-button
            circle
            icon="el-icon-delete"
            @click="delete_category(scope.row)"
          />
          <!-- <i class="el-icon-time"></i>
        <span style="margin-left: 10px">{{ scope.row.date }}</span> -->
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      title="Category details"
      :visible.sync="edit_category_dialog"
    >
      <el-form
        ref="selected_category_form"
        :model="selected_category"
        :rules="rules"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item
              label="Name"
              prop="name"
            >
              <el-input
                v-model="selected_category.name"
                autocomplete="off"
                size="mini"
              />
            </el-form-item>
            <el-form-item>
              <h3>
                Project Planners
                <div style="display: contents;">
                  <el-button
                    class="right"
                    icon="el-icon-plus"
                    size="mini"
                    @click="add_user"
                  />
                  <el-select
                    v-model="selected_user"
                    class="right"
                    size="mini"
                  >
                    <el-option
                      v-for="user in filtered_users_list"
                      :key="user.id"
                      :label="user.user_name"
                      :value="user.id"
                    />
                  </el-select>
                </div>
                <hr>
              </h3>
              <el-table
                :data="selected_category.category_task_planners_attributes"
              >
                <el-table-column
                  label="User"
                  prop="crm_user_name"
                />
                <el-table-column label="Branches" />
                <el-table-column label="Action">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      icon="el-icon-delete"
                      circle=""
                      @click="delete_user(scope.row)"
                    />
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="edit_category_dialog = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="save_category"
        >Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'CategoryTasks',
  data() {
    return {
      data: [],
      users_list: [],
      selected_category: {
        id: '',
        name: '',
        category_task_planners_attributes: [],
      },
      selected_user: '',
      edit_category_dialog: false,
      rules: {
        name: [
          {
            required: true,
            message: 'Please enter category name',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  computed: {
    filtered_users_list() {
      let selected_ids = this.selected_category.category_task_planners_attributes.map(
        (c) => c.crm_user_id
      );
      return this.users_list.filter(
        (c) => selected_ids.includes(c.id) == false
      );
    },
  },
  mounted() {
    this.fill_data();
  },
  methods: {
    fill_users() {
      this.$http.get('/users_list').then((response) => {
        this.users_list = response.data.list;
      });
    },
    fill_data() {
      this.$http.get('/category_tasks_list').then((response) => {
        this.data = response.data;
      });
    },
    add_user() {
      let selected_user = this.users_list.filter(
        (c) => c.id == this.selected_user
      )[0];
      if (selected_user != undefined) {
        this.selected_category.category_task_planners_attributes.push({
          id: '',
          crm_user_id: this.selected_user,
          crm_user_name: selected_user.user_name,
        });
        this.selected_user = '';
      }
    },
    delete_user(user) {
      this.selected_category.category_task_planners_attributes = this.selected_category.category_task_planners_attributes.filter(
        (c) => c.crm_user_id != user.crm_user_id
      );
    },
    create_category() {
      this.clear_category();
      this.prepare_category_dialog();
    },
    clear_category() {
      this.selected_category = {
        id: '',
        name: '',
        category_task_planners_attributes: [],
      };
    },
    prepare_category_dialog() {
      if (this.users_list.length == 0) this.fill_users();
      this.set_inputs_to_default();
      this.edit_category_dialog = true;
    },
    set_inputs_to_default(mills = 300) {
      let _this = this;
      setTimeout(() => {
        Array.from(
          document.querySelectorAll('.categor_tasks_div .el-input')
        ).forEach(function(e, i) {
          e.classList.add('browser-default');
          if (e.querySelector('input') != null)
            e.querySelector('input').className += ' browser-default';
        });
      }, mills);
    },
    edit_category(category) {
      this.prepare_category_dialog();
      this.$http.get(`/category_tasks/${category.id}`).then((response) => {
        this.selected_category = response.data;
      });
    },
    delete_category(category) {
      this.$confirm(
        'This will permanently delete the category. Continue?',
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      )
        .then(() => {
          this.$http.delete(`/category_tasks/${category.id}`).then(
            (response) => {
              this.data = response.data;
            },
            (reason) => {
              this.$message({
                type: 'error',
                dangerouslyUseHTMLString: true,
                message: reason.bodyText,
              });
            }
          );
        })
        .catch(() => {});
    },
    save_category() {
      this.$refs['selected_category_form'].validate((valid) => {
        if (valid == false) return false;
        else {
          let params = { category_task: this.selected_category };
          let type = '';
          let url = '';
          if (this.selected_category.id == '') {
            type = 'post';
            url = '/category_tasks/';
          } else {
            type = 'patch';
            url = `/category_tasks/${this.selected_category.id}`;
          }
          this.$http[type](url, params).then(
            (response) => {
              this.data = response.data;
              this.$message({ type: 'success', message: 'Saved successfully' });
              this.clear_category();
              this.edit_category_dialog = false;
            },
            (reason) => {
              this.$message({
                type: 'error',
                dangerouslyUseHTMLString: true,
                message: `Error happened: <br>${reason.data}`,
              });
            }
          );
        }
      });
    },
  },
};
</script>
