<template>
  <div>
    <el-dialog
      title="Copy Quote"
      :visible.sync="show_copy_quote_dialog"
      width="40%"
      append-to-body
      :close-on-click-modal="false"
      @opened="set_default_input()"
    >
      <el-row :gutter="20">
        <el-col :span="20">
          <span>Category:</span>
          <br>
          <el-select
            v-model="category_task_id"
            placeholder="Select a quote category"
            @change="get_quotes"
          >
            <el-option
              v-for="category in categories"
              :key="category.id"
              :label="category.name"
              :value="category.id"
            >
              <span>{{ category.name }}</span>
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="20">
          <span>Quotes:</span>
          <br>
          <el-select
            v-model="proposal_id"
            filterable
            placeholder="Select a quote"
            style="width: 500px;"
          >
            <el-option
              v-for="quote in quotes"
              :key="quote.id"
              :label="quote.job_name"
              :value="quote.id"
            >
              <span>{{ quote.id }} - {{ quote.job_name }}</span>
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-button
            type="primary"
            class="right"
            @click="clone_it()"
          >
            Copy Quote
          </el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import {set_default_input} from '../../../utilities/DefaultInput';

export default {
  name: 'CopyQuote',
  props: {
    account_id: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      show_copy_quote_dialog: false,
      categories: [],
      category_task_id: '',
      quotes: [],
      proposal_id: ''
    };
  },
  created() {
    this.get_categories();
  },
  methods: {
    set_default_input,
    clone_it(){
      this.$http.post('/api/proposals/copy_quote',
        {proposal_id: this.proposal_id , account_id: this.account_id}).then(
        (response) => {
          window.location = `/edit_proposal?id=${ response.data.id }`;
        }
      );
    },
    get_quotes(){
      this.$http.post('/api/proposals/quotes_by_category_task',
        {category_task_id: this.category_task_id, account_id: this.account_id}).then(
        (response) => {
          this.quotes = response.data;
        }
      );
    },
    get_categories() {
      this.$http.get('/category_tasks_list').then(
        (response) => {
          this.categories = response.data;
          this.make_upper_case_category_names();
        }
      );
    },
    make_upper_case_category_names() {
      for (let key in this.categories) {
        this.categories[key].name = this.categories[key].name.toUpperCase();
      }
    },
  }
};
</script>
<style scoped>
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.right{float: right;}
</style>