<template>
  <div id="inline">
    <el-link
      :underline="false"
      @click.native.prevent="dialogVisible = true,get_signature()"
    >
      Email Signature
    </el-link>
    <el-dialog
      title="Email Signature"
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose"
      append-to-body
    >
      <jodit-editor
        id="signature_editor"
        ref="signature_editor"
        v-model="signature_content"
        :buttons="buttons"
        :config="jodit_config"
      />
      <p>Click filemanager icon to upload or delete image:</p>
      <file_manager
        :current_email_account="current_email_account"
        @file_uploaded="get_signature()"
      />

      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="mini"
          @click="dialogVisible = false"
        >Cancel</el-button>
        <el-button
          size="mini"
          type="primary"
          @click="edit_signature(),dialogVisible = false"
        >Save</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import CommonJoditMixin from 'mixins/CommonJoditMixin';
import Filemanager from '../filemanager/Filemanager';

export default {
  name: 'EmailSignature',
  components: {file_manager: Filemanager},
  mixins: [CommonJoditMixin],
  props: {
    mailbox_id: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      dialogVisible: false,
      signature_content: '',
      current_email_account: '',
      jodit_config: {
        enter: 'br',
        spellcheck: true,
        cleanHTML: {
          fillEmptyParagraph: false
        }},
    };
  },
  computed: {},
  methods: {
    get_signature() {
      this.$http.get('/get_signature/' + this.mailbox_id).then(response => {
        this.signature_content = response.data.signature;
        this.current_email_account = response.data.email;
      });
    },
    edit_signature() {
      this.$http.post('/update_signature', {
        mailbox_account_id: this.mailbox_id,
        signature: this.signature_content
      })
        .then(
          response => {
            this.$message({
              message: response.data.message,
              type: 'success'
            });
          },
          response => {
            this.$message({
              message: response.data.error,
              type: 'error'
            });
          }
        );
    },
    handleClose(done) {
      done();
    }
  },
};
</script>

<style scoped>
#inline {
  display: inline;
}
</style>