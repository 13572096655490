var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"stock__row",style:([
    (_vm.stock.request != undefined && _vm.stock.request.is_approved == 1) ||
    _vm.stock.mark_as_deleted == 1
      ? { 'text-decoration': 'line-through', 'font-style:': 'italic' }
      : {},
  ])},[_c('td',[_vm._v(_vm._s(_vm.stock.category))]),_vm._v(" "),_c('td',[_c('el-input',{attrs:{"placeholder":_vm.stock.stock_name,"size":"mini"},on:{"change":_vm.update_stock_layer_name},model:{value:(_vm.stock.layer_name),callback:function ($$v) {_vm.$set(_vm.stock, "layer_name", $$v)},expression:"stock.layer_name"}}),_vm._v(" "),_c('a',{attrs:{"target":"_blank","href":'../../../stock_items/' + _vm.stock.stock_item_id + '/edit'}},[_vm._v(_vm._s(_vm.stock.stock_name +
          (_vm.stock.layer_name != "" ? ` (${_vm.stock.layer_name})` : "")))]),_vm._v(" "),_c('br'),_vm._v(" "),_c('small',[_vm._v(_vm._s(_vm.stock.specs_details))]),_vm._v(" "),(
        _vm.stock.is_workspace == true &&
          _vm.proposal.approved == 0 &&
          _vm.stock.is_initiated == 1
      )?_c('div',[_c('a',{staticClass:"material-icons dp48 tooltipped",attrs:{"data-tooltip":"Initiated stock item"}},[_vm._v("check_circle")])]):_vm._e()],1),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm.stock.supplier))]),_vm._v(" "),_c('td',{attrs:{"width":"120"}},[(_vm.stock.purchase_order != undefined)?_c('div',[_c('b',[_vm._v("PO #:")]),_vm._v(" "),_c('a',{attrs:{"href":_vm.get_po_link(_vm.stock.purchase_order.id)}},[_vm._v("\n        "+_vm._s(_vm.stock.purchase_order.id)+"\n      ")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('b',[_vm._v("Qty:")]),_vm._v("\n      "+_vm._s(_vm.stock.purchase_order.quantity)+"\n      "),_c('br'),_vm._v(" "),_c('b',[_vm._v("Status:")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.stock.purchase_order.status))]),_vm._v(" "),_c('br'),_vm._v(" "),_c('b',[_vm._v("From Stock:")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.calc_qty_from_stock(_vm.stock.quantity, _vm.stock.purchase_order.quantity)))])]):_vm._e()]),_vm._v(" "),_c('td',[_c('div',[(_vm.stock.billable_locked == true && _vm.proposal.approved == 1)?_c('div',[_c('input',{staticClass:"filled-in",attrs:{"type":"checkbox","name":'n_billable_' + _vm.stock.id,"id":'n_billable_' + _vm.stock.id,"data-id":_vm.stock.id},domProps:{"checked":_vm.stock.billable},on:{"change":function($event){return _vm.update_stock_billable($event, _vm.stock.id)}}})]):(_vm.proposal.approved == 1)?_c('div',[_c('input',{staticClass:"filled-in",attrs:{"type":"checkbox","name":'n_billable_' + _vm.stock.id,"id":'n_billable_' + _vm.stock.id,"data-id":_vm.stock.id},domProps:{"checked":_vm.stock.billable},on:{"change":function($event){return _vm.update_stock_billable($event, _vm.stock.id)}}}),_vm._v(" "),_c('label',{attrs:{"for":'n_billable_' + _vm.stock.id}})]):_vm._e()])]),_vm._v(" "),_c('td',[(_vm.proposal.approved == 1 || _vm.stock.is_initiated == 1)?_c('div',[_vm._v("\n      "+_vm._s(_vm.stock.quantity)+"\n    ")]):(
        _vm.proposal.approved == 0 &&
          (_vm.stock.is_workspace == false ||
            (_vm.stock.is_workspace == true && _vm.stock.is_initiated == 0))
      )?_c('div',[(
          _vm.stock.purchase_order == undefined ||
            _vm.stock.purchase_order == null ||
            (_vm.stock.purchase_order != undefined &&
              _vm.stock.purchase_order.status == 'APPROVED')
        )?_c('div',[_c('el-input',{staticClass:"stock_item_qty_update",staticStyle:{"color":"#999!important"},attrs:{"size":"mini","disabled":_vm.qty_disabled},on:{"change":_vm.update_proposal_stock_qty},model:{value:(_vm.stock.quantity),callback:function ($$v) {_vm.$set(_vm.stock, "quantity", $$v)},expression:"stock.quantity"}})],1):_vm._e()]):_c('div',[_vm._v(_vm._s(_vm.stock.quantity))])]),_vm._v(" "),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.proposal.approved == true || _vm.proposal.approved == 1),expression:"proposal.approved == true || proposal.approved == 1"}]},[_vm._v("\n    "+_vm._s(_vm.stock.used_qty)+"\n  ")]),_vm._v(" "),_c('td',[_vm._v("$"+_vm._s(_vm.stock.price))]),_vm._v(" "),_c('td',[_c('el-input',{staticStyle:{"color":"#999!important"},attrs:{"size":"mini","type":"number","disabled":_vm.sale_price_disabled},on:{"change":_vm.update_proposal_stock_sale_price},model:{value:(_vm.stock.sale_price),callback:function ($$v) {_vm.$set(_vm.stock, "sale_price", $$v)},expression:"stock.sale_price"}})],1),_vm._v(" "),_c('td',{staticStyle:{"background-color":"#f7d6d6"},attrs:{"width":"120"}},[_vm._v("\n    $"+_vm._s(_vm.stock_cost_total_price)+"\n  ")]),_vm._v(" "),_c('td',{staticStyle:{"background-color":"#dce8ec"},attrs:{"width":"120"}},[_vm._v("\n    $"+_vm._s(_vm.stock_total_price)+"\n  ")]),_vm._v(" "),_c('td',[(_vm.stock.is_workspace == true && _vm.proposal.approved == 0)?_c('div',[(_vm.stock.is_initiated == 0 && _vm.stock.is_included === true)?_c('div',[_c('button',{staticClass:"btn btn-small tooltipped",attrs:{"data-position":"bottom","data-tooltip":"Initiate stock and create PO if out of stock"},on:{"click":function($event){$event.preventDefault();_vm.initiate_stock_item(_vm.get_stock_category(_vm.stock), _vm.stock.id)}}},[_vm._v("\n          Initiate\n        ")])]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.proposal.completed == 0 && _vm.stock.mark_as_deleted == 0)?_c('div',[(
          (_vm.stock.purchase_order != undefined &&
            _vm.stock.purchase_order.status == 'PENDING') ||
            _vm.stock.purchase_order == undefined
        )?_c('div',[_c('el-button',{attrs:{"type":"danger","icon":"el-icon-delete","circle":""},on:{"click":function($event){$event.preventDefault();return _vm.delete_stock_item('default', _vm.stock.id)}}})],1):_vm._e(),_vm._v(" "),(
          _vm.stock.purchase_order != undefined &&
            (_vm.stock.purchase_order.status == 'APPROVED' ||
              _vm.stock.purchase_order.status == 'RECEIVED') &&
            _vm.stock.request == undefined &&
            _vm.stock.used_qty == 0
        )?_c('div',[_c('button',{staticClass:"btn btn-small",staticStyle:{"min-width":"125px"},on:{"click":function($event){$event.preventDefault();return _vm.request_delete_stock_item('default', _vm.stock.id)}}},[_vm._v("\n          Request Delete\n        ")])]):_vm._e(),_vm._v(" "),(_vm.stock.request != undefined)?_c('div',[(_vm.stock.request.is_approved == 0)?_c('div',[_c('span',[_vm._v("Delete Request submitted")])]):(_vm.stock.request.is_approved == 1)?_c('div',[_c('span',[_vm._v("Delete Request approved")])]):_vm._e()]):_vm._e()]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }