<template>
  <el-card class="box-card">
    <el-row :gutter="20">
      <el-col
        :xs="24"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="8"
      >
        <KPIGauge
          :value="time_sheet.current_percent"
          :label="time_sheet.label"
          :stops="time_sheet.stops"
          :extra_infos="time_sheet.extra_infos"
        />
      </el-col>
      <PerformanceGauge
        :kpis_gauge="kpis_gauge"
        :is_manager="is_manager"
      />
      <el-col
        :xs="24"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="8"
      >
        <KPIGauge
          :max_value="quality_ncr.max_value"
          :value="quality_ncr.current_percent"
          :label="quality_ncr.label"
          :stops="quality_ncr.stops"
          :colors="quality_ncr_colors"
          :extra_infos="quality_ncr.extra_infos"
        />
      </el-col>
      <TurnaroudGauge
        :kpis_gauge="kpis_gauge"
        :is_manager="is_manager"
      />
      <el-col
        :xs="24"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="8"
      >
        <KPIGauge
          :max_value="upskilling.max_value"
          :value="upskilling.current_percent"
          :label="upskilling.label"
          :stops="upskilling.stops"
          :extra_infos="upskilling.extra_infos"
        />
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import KPIGauge from './KPIGauge';
import PerformanceGauge from './PerformanceGauge';
import {maxValue} from '../../utilities/MaxValue';
import Colors from '../../../assets/stylesheets/export_colors.scss';
import TurnaroudGauge from './TurnaroudGauge';
import {display_time} from '../../utilities/Time';

export default {
  name: 'MyKPIsGauge',
  components: {
    TurnaroudGauge,
    PerformanceGauge,
    KPIGauge
  },
  props: {
    kpis_gauge: {
      type: Object,
      required: true
    },
    is_manager: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      time_sheet: {
        stops: [0,50,70,90,100],
        current_percent: 0,
        label: 'Time Sheet',
        extra_infos: []
      },
      quality_ncr: {
        max_value: 4,
        stops: [0,1,2,3,4],
        current_percent: 0,
        label: 'Quality(NCR)',
        extra_infos: []
      },
      upskilling: {
        max_value: 5,
        stops: [0,2,3,4,5],
        current_percent: 0,
        label: 'Upskilling/Goals',
        extra_infos: []
      }
    };
  },
  computed: {
    quality_ncr_colors() {
      return [Colors.greenColor, Colors.yellowColor, Colors.orangeColor, Colors.redColor];
    }
  },
  watch: {
    kpis_gauge() {
      this.time_sheet_init();
      this.quality_ncr_init();
      this.upskilling_init();
    }
  },
  methods: {
    time_sheet_init() {
      this.time_sheet.current_percent = maxValue(this.kpis_gauge.time_sheet.weighin_time_sheet_total);
      this.time_sheet.label = `Time Sheet : ${this.kpis_gauge.time_sheet.weighin_time_sheet_total}%`;
      this.time_sheet.stops = [
        0,
        this.kpis_gauge.target_section.timesheet_orange,
        this.kpis_gauge.target_section.timesheet_yellow,
        this.kpis_gauge.target_section.timesheet_green,
        100
      ];
      if(this.is_manager == true) {
        this.time_sheet.extra_infos = [
          {
            attr: 'TIMESHEET',
            val: display_time(this.kpis_gauge.is_hours, this.kpis_gauge.time_sheet.user_time_sheet)
          },
          {
            attr: 'TARGET',
            val: display_time(this.kpis_gauge.is_hours, this.kpis_gauge.time_sheet.total_target_minutes)
          },
          {
            attr: 'REACHED',
            val: `${this.kpis_gauge.time_sheet.weighin_time_sheet_total}%`
          },
          {
            attr: 'WEIGH-IN RATE',
            val: `${this.kpis_gauge.time_sheet.timeframe_perc}%`
          },
        ];
      }
    },
    quality_ncr_init() {
      this.quality_ncr.max_value = this.kpis_gauge.target_section.rfi_red + 1;
      if (this.kpis_gauge.quality_ncr.ncrs_count >  this.quality_ncr.max_value) {
        this.quality_ncr.max_value = this.kpis_gauge.quality_ncr.ncrs_count;
      }
      this.quality_ncr.current_percent = this.kpis_gauge.quality_ncr.ncrs_count;

      this.quality_ncr.label = `Quality(NCR): ${this.kpis_gauge.quality_ncr.ncrs_count}`;
      this.quality_ncr.stops = [
        0,
        this.kpis_gauge.target_section.rfi_yellow,
        this.kpis_gauge.target_section.rfi_orange,
        this.kpis_gauge.target_section.rfi_red,
        this.quality_ncr.max_value
      ];
      if(this.is_manager == true) {
        this.quality_ncr.extra_infos = [
          {
            attr: 'TOTAL',
            val: `${this.kpis_gauge.quality_ncr.ncrs_count}`
          },
          {
            attr: 'WEEKLY LIMIT',
            val: `${this.kpis_gauge.quality_ncr.weekly_limit}`
          },
          {
            attr: 'REACHED',
            val: `${this.kpis_gauge.quality_ncr.ncr_perc}%`
          },
          {
            attr: 'WEIGH-IN RATE',
            val: `${this.kpis_gauge.quality_ncr.weighin_perc}%`
          }
        ];
      }
    },
    upskilling_init(){
      this.upskilling.max_value =  this.kpis_gauge.target_section.upskilling_green + 1;
      if (this.kpis_gauge.upskilling.achieved_goal >  this.upskilling.max_value) {
        this.upskilling.max_value = this.kpis_gauge.upskilling.achieved_goal;
      }

      this.upskilling.current_percent = this.kpis_gauge.upskilling.achieved_goal;
      this.upskilling.label = `Upskilling/Goals: ${this.kpis_gauge.upskilling.achieved_goal}`;
      this.upskilling.stops = [
        0,
        this.kpis_gauge.target_section.upskilling_orange,
        this.kpis_gauge.target_section.upskilling_yellow,
        this.kpis_gauge.target_section.upskilling_green,
        this.upskilling.max_value
      ];
      if(this.is_manager == true) {
        this.upskilling.extra_infos = [
          {
            attr: 'TOTAL ACHIEVED',
            val: `${this.kpis_gauge.upskilling.achieved_goal}`
          },
          {
            attr: 'GOAL TOTAL',
            val: `${this.kpis_gauge.upskilling.total_goal}`
          },
          {
            attr: 'REACHED',
            val: `${this.kpis_gauge.upskilling.goals_prec}%`
          },
          {
            attr: 'WEIGH-IN RATE',
            val: `${this.kpis_gauge.upskilling.weighin_perc}%`
          }
        ];
      }
    },
  },
};
</script>
