<template>
  <el-card>
    <el-collapse>
      <el-collapse-item>
        <template slot="title">
          <el-row class="width--full">
            <el-col :span="12">
              Job Notes
            </el-col>
            <el-col :span="12">
              {{ job_notes.length }} notes
            </el-col>
          </el-row>
        </template>
        <JobNotesList
          v-if="job_notes.length > 0"
          :notes_list="job_notes"
        />
        <div v-else>
          There is no Job Notes.
        </div>
      </el-collapse-item>
    </el-collapse>
  </el-card>
</template>

<script>
import JobNotesList from '../../job_views/sections/JobNotesList';
export default {
  name: 'JobNotes',
  components: {JobNotesList},
  props: {
    proposal_id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      job_notes: []
    };
  },
  created() {
    let request = this.$http.get(`/proposal_comments/${this.proposal_id}`);
    request.then((response)=>{
      this.job_notes = response.body;
    },()=>{
      this.$message({
        type: 'error',
        message: 'Getting job notes failed'
      });
    });
  }
};
</script>
