<template>
  <div v-show="proposal.completed != 1">
    <el-col
      :span="12"
      :lg="6"
    >
      <span style="font-size:10px; color:#666">Time Allocated: </span><br>
      <HoursInput
        v-if="is_hours"
        v-model="selected_time_allocated"
      />
      <el-input-number
        v-else
        v-model="selected_time_allocated"
        size="mini"
        placeholder="Time allocated"
      />
    </el-col>
    <el-col
      :span="12"
      :lg="4"
    >
      <span style="font-size:10px; color:#666">Unit sale price:</span><br>

      <el-input-number
        v-model="selected_sale_price"
        size="mini"
        aria-placeholder="Unit sale price"
      />
    </el-col>
    <el-col
      :span="24"
      :lg="6"
    >
      <el-input
        v-model="task_description"
        class="normButtn inputdiv"
        style="margin:0;"
        placeholder="Task Notes"
        size="small"
      />
    </el-col>
    <el-col
      :span="24"
      :lg="6"
    >
      <el-select
        v-model="selected_job_id"
        size="small"
        style="width: 100%; padding:2px; border:1px solid #; background:#f3f3f3"
        class="selectfield inputdiv"
        filterable
        reserve-keyword
        placeholder="Please select a job"
      >
        <el-option
          v-for="item in jobs_list"
          :key="item.id"
          :label="item.name"
          :value="item.id"
          style="background:#fff"
          class="inputfields"
        />
      </el-select>
    </el-col>
    <el-col
      :span="24"
      :lg="2"
    >
      <el-button
        size="small"
        style="margin-top:12px;background:#4cb527; color:#fff"
        @click="add_job"
      >
        + ADD
      </el-button>
    </el-col>
  </div>
</template>

<script>
import { editor_store } from 'store/modules/editor';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { JobProposalMixin } from 'mixins/JobProposalMixin.js';
import { ProposalMixin } from 'mixins/ProposalMixin.js';
import HoursInput  from '../../shared/HoursInput';

export default {
  name: 'AddJobsToList',
  store: editor_store,
  components: {  HoursInput },
  mixins: [JobProposalMixin, ProposalMixin],
  props: ['is_initiated', 'list_id', 'load_job_proposals_list'],
  data() {
    return {
      selected_job_id: '',
      selected_sale_price: 0,
      selected_time_allocated: 0,
      task_description: '',
    };
  },
  computed: {
    ...mapGetters(['proposal', 'job_proposals_list', 'jobs_list', 'is_hours']),
  },
  watch: {
    selected_job_id: function(newVal) {
      if (newVal != undefined) {
        let selected_job = this.get_selected_job(newVal);
        this.selected_time_allocated = selected_job.allocated_time;
        this.selected_sale_price = selected_job.labour_minutes_rate;
      }
    },
  },
  methods: {
    get_selected_job(id) {
      return this.jobs_list.filter((item) => {
        return item.id == id;
      })[0];
    },
    add_job() {
      let _this = this;
      if (this.selected_job_id != '') {
        let selected_job = this.get_selected_job(this.selected_job_id);

        let data = {
          job_id: selected_job.id,
          time_allocated_labour: this.selected_time_allocated,
          sale_price: this.selected_sale_price,
          is_initiated: this.is_initiated == true ? 1 : 0,
          alternative_description: this.task_description,
        };
        if (this.list_id != undefined && this.list_id > 0)
          data['list_id'] = this.list_id;
        this.add_job_proposal(this.proposal.id, [data]).then(
          (response) => {
            response.forEach((el, idx) => {
              _this.job_proposals_list.push(el);
            });

            //
            this.$emit('tasks_added', response);

            // Need to remove udpate time frame , though it is already inactive in ruby
            _this.set_inputs_to_default();
            if (_this.proposal.approved == 1) {
              this.update_proposal_time_frame(_this.proposal.id).then(
                (response) => {
                  this.load_job_proposals_list();
                }
              );
            } else
              this.$message({
                message: 'Task added successfully.',
                type: 'success',
              });
          },
          (reason) => {
            console.log(reason);
            this.$message({
              message: 'Error occured!.',
              type: 'error',
            });
          }
        );
      }
    },
    set_inputs_to_default() {
      let _this = this;
      setTimeout(() => {
        Array.from(
          document.querySelectorAll('#job_proposals_list .el-input')
        ).forEach(function(e, i) {
          e.classList.add('browser-default');
          if (e.querySelector('input') != null)
            e.querySelector('input').className += ' browser-default';
        });
      }, 300);
    },
  },
};
</script>

<style>
.inputfields input {
  height: 1.3em !important;
  margin: 0px !important;
}
.inputfields .multiselect {
  min-height: 36px;
}

.inputfields .multiselect__tags {
  min-height: 30px;
  padding: 0px 5px;
  margin-top: 10px;
}
.selectfield {
  height: 2em !important;
  background: #fff;
  margin-top: 13px;
  margin-bottom: 2px;
  border-radius: 2px;
}
</style>
