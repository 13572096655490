<template>
	<div v-loading="is_loading" v-show="!editor_fullscreen">
		<CategoryWorkingFiles v-if="working_file_categories.length > 0"
			style="text-align: left; padding-top: 1rem; padding-bottom: 1rem" :save_file="save_file"
			@command="handleCommand" />
		<div v-else>
			<div v-show="proposal.approved == 0" style="margin: 2px;text-align: start;">
				<span v-if="client_view === false">
					<el-button size="mini" round @click="create_new_page">New page</el-button>
					<el-button size="mini" round @click="show_pdf_preview_dialog">Import PDFs</el-button>
					<el-button size="mini" round @click="import_working_files_dialog = true">Import from
						proposals</el-button>
				</span>

				<small v-show="file_changed === true">
					<i>Unsaved changes</i>
				</small>
			</div>

			<el-button style="padding: 6px; margin: 4px; background: #ffffbf;" class="left" size="mini"
				icon="el-icon-tickets"
				v-show="!(working_file_id == '' || working_file_id == undefined || client_view == true)"
				@click="edit_working_file_note({ id: working_file_id })">
			</el-button>
			<draggable id="panel_div" tag="div" class="list-group" group="jobslist" handle=".handle"
				v-bind="dragOptions" style="padding: 0 6px;" @start="drag = true" @end="drag = false" @sort="onSort">
				<WorkingFile v-for="(file) in files_list" :key="file.id" :file="file" :data-id="file.id"
					:save_file="save_file" @command="handleCommand" />
			</draggable>
		</div>

		<el-drawer :title="'Notes ' + working_file_name" :visible.sync="drawer" :direction="direction"
			:before-close="handle_close" :close-on-press-escape="false" custom-class="note-drawer"
			:append-to-body="true" ref="drawer" :modal="false">
			<div class="note-drawer__content">
				<el-form ref="form_note" :model="form_note">
					<el-form-item label="Notes" prop="content">
						<jodit-editor v-model="form_note.content" :buttons="buttons" id="working_file_note_editor"
							:config="jodit_config" />
					</el-form-item>
				</el-form>
			</div>
		</el-drawer>

		<el-dialog title="Import working files" id="import_working_file_dialog" :close-on-click-modal="false"
			:visible.sync="import_working_files_dialog">
			<ImportWorkingFiles :hide_import_working_files_dialog="hide_import_working_files_dialog">
			</ImportWorkingFiles>
		</el-dialog>

		<el-dialog title="Export working file to template" id="export_working_file_dialog" :close-on-click-modal="false"
			:visible.sync="export_working_file_dialog">
			<ExportWorkingFile :export_working_file_id="export_working_file_id"
				:hide_export_working_file_dialog="hide_export_working_file_dialog"></ExportWorkingFile>
		</el-dialog>
		<el-dialog title="Working file details" id="working_file_details_dialog" :close-on-click-modal="false"
			:visible.sync="working_file_details_dialog">
			<WorkingFileDetails :file_details="selected_working_file_details"
				:hide_working_file_details_dialog="hide_working_file_details_dialog" :quantity_units="quantity_units">
			</WorkingFileDetails>
		</el-dialog>
	</div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { editor_store, mm_to_px } from "store/modules/editor";

import attributes_template from "./../templates/attributes_template.json";
import ObjectAttribute from "./ObjectAttribute.vue";
import ImportWorkingFiles from "components/proposal/working_files/ImportWorkingFiles";
import ExportWorkingFile from "components/proposal/working_files/ExportWorkingFile";
import WorkingFileDetails from "components/proposal/working_files/WorkingFileDetails";
import draggable from "vuedraggable";
import WorkingFile from './WorkingFile';
import CategoryWorkingFiles from './CategoryWorkingFiles';
import { EventBus } from '../../../utilities/EventBus';

// mixins
import { WorkspaceMixin } from "mixins/WorkspaceMixin.js";
import { JobProposalMixin } from "mixins/JobProposalMixin.js";
import { ProposalStockItemMixin } from "mixins/ProposalStockItemMixin.js";
import CommonJoditMixin from "mixins/CommonJoditMixin";
import CategoryMixin from 'mixins/CategoryMixin';

export default {
	name: "FilesPanel",
	components: {
		ExportWorkingFile,
		WorkingFileDetails,
		CategoryWorkingFiles,
		WorkingFile,
		draggable,
	},
	store: editor_store,
	mixins: [WorkspaceMixin, JobProposalMixin, ProposalStockItemMixin, CommonJoditMixin, CategoryMixin],
	props: ["save_file", "show_pdf_preview_dialog", "add_svg_content"],
	data() {
		return {
			drag: false,
			quantity_units: [],
			selected_working_file_details: {},
			export_working_file_dialog: false,
			working_file_details_dialog: false,
			export_working_file_id: "",
			is_loading: false,
			drawer: false,
			direction: "btt",
			working_file_name: "",
			form_note: { content: "" },
			defaultActionOnPaste: "insert_clear_html",
		};
	},
	mounted() {
		this.fill_qty_units();
	},
	watch: {
		files_list() {
			if (this.files_list.length > 0 && this.client_view == true) {
				this.load_file(this.files_list[0].id);
			}
		}
	},
	methods: {
		fill_qty_units() {
			this.$http.get("/quantity_units").then(response => {
				this.quantity_units = response.data;
			})
		},
		handleCommand(command) {
			switch (command.action) {
				case "edit_details":
					this.edit_working_file_details(command.file);
					break;
				case "edit_note":
					this.edit_working_file_note(command.file);
					break;
				case "delete_file":
					this.delete_working_file(command.index, command.file);
					break;
				case "duplicate_file":
					this.duplicate_working_file(command.file);
					break;
				case "export_to_templates":
					this.export_file_to_templates(command.file);
					break;
				case "add_vector":
					this.add_vector(command.file);
					break;
			}
		},
		...mapActions([
			"set_working_file_id",
			"set_page_size",
			"set_file_changed",
			"set_job_proposals_list",
			"set_stock_items_list",
			"append_files_list",
		]),
		onSort(ev, arg) {
			let updated_list = Array.from(ev.target.children).map((el, idx) => {
				return { id: Number(el.dataset.id), position: idx };
			});
			this.$http
				.post("/update_working_file_positions", { working_files: updated_list })
				.then(
					(response) => {
						this.$message({
							type: "success",
							message: "Task has been sorted successfully.",
						});
						updated_list.forEach((el, idx) => {
							let record = this.files_list.filter((c) => c.id == el.id)[0];
							if (record != undefined) record["position"] = idx;
						});
					},
					(response) => { }
				);
		},
		hide_export_working_file_dialog() {
			this.export_working_file_dialog = false;
			this.export_working_file_id = "";
		},
		handle_close(done) {

			this.drawer = false;
			this.$http
				.patch("/working_file_note/" + this.form_note.id, {
					note: this.form_note.content,
				})
				.then(
					(response) => {
						this.$message({
							message: "Note saved successfully.",
							type: "success",
						});
						this.form_note.content = "";
						this.working_file_name = name;
					},
					function (response) {
						console.log(response);
					}
				);
			done();
		},
		edit_working_file_note(file) {
			this.drawer = true;
			return new Promise((resolve, reject) => {
				this.$http.get("/working_file_note/" + file.id).then(
					(response) => {
						resolve(
							((this.form_note.content = response.data.note),
								(this.form_note.id = file.id))
						);
					},
					function (response) {
						reject(response);
					}
				);
			});
		},
		edit_working_file_details(file) {
			this.working_file_details_dialog = true;
			this.selected_working_file_details = Object.assign({}, file);
		},
		hide_working_file_details_dialog() {
			this.working_file_details_dialog = false;
			this.selected_working_file_details = {};
		},
		export_file_to_templates(file) {
			this.export_working_file_id = file.id;
			this.export_working_file_dialog = true;
		},
		add_vector(file) {
			let _this = this;
			this.$confirm("Import original PDF as vector. Continue?", "Warning", {
				confirmButtonText: "OK",
				cancelButtonText: "Cancel",
				type: "warning",
			}).then(() => {
				let formData = new FormData();
				formData.append("working_file_id", _this.working_file_id);
				formData.append("path", file.pdf_path);
				formData.append("page_num", file.page_num);
				document.getElementById("add_pdf").value = "";

				const loading = this.$loading({
					lock: true,
					text: "Loading",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)",
				});
				this.$http
					.post("/import_pdf_part", formData, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					})
					.then(function (response) {
						// update SVG with rendered SVG part
						_this.add_svg_content(response);

						loading.close();
					})
					.then(function (response) {
						loading.close();
					});
			});
		},
		duplicate_working_file(file) {
			let params = {
				proposal_id: this.proposal.id,
				working_files_ids: [file.id],
				category_working_file_id: this.get_active_working_file_category ? Number(this.get_active_working_file_category) : null
			};

			this.is_loading = true;
			this.$http.post("/import_working_files", params).then(
				(response) => {
					this.is_loading = false;
					this.append_files_list(response.body.working_files);
					this.$message({
						type: "success",
						message: "Working file have been duplicated successfully.",
					});
					this.load_stock_items_list(this.proposal.id).then((response) => {
						this.set_stock_items_list(response);
					});
					this.load_job_proposals(this.proposal.id).then((response) => {
						this.set_job_proposals_list(response);
					});
					EventBus.$emit('reloadCategoryWorkingFiles');
				},
				(reason) => {
					this.is_loading = false;
					this.$message({
						type: "error",
						message: "Error happened while duplicating working file, please try again.",
					});
				}
			);
		},
		delete_working_file(index, file) {
			let _this = this;
			this.$confirm(
				"This will permanently delete the file and the associated stocks & tasks. Continue?",
				"Warning", {
				confirmButtonText: "OK",
				cancelButtonText: "Cancel",
				type: "warning",
			}
			).then(() => {
				this.is_loading = true;
				this.$http.delete(`/working_files/${file.id}`).then(function (response) {
					this.$message({
						type: "success",
						message: "Deleted successfully",
					});

					this.set_job_proposals_list(
						this.job_proposals_list.filter(
							(c) => c.working_file_id != parseInt(file.id)
						)
					);
					this.set_stock_items_list(
						this.stock_items_list.filter(
							(c) => c.working_file_id != parseInt(file.id)
						)
					);
					_this.files_list.splice(index, 1);
					if (_this.working_file_id == file.id) _this.set_working_file_id("");
					this.is_loading = false;

					EventBus.$emit('reloadCategoryWorkingFiles');
				});
			});
		},
		load_file(id) {
			if (this.file_changed == true) {
				this.save_file(undefined).then(
					(response) => {
						this.set_working_file_id(id);
					},
					(reject) => { }
				);
			} else this.set_working_file_id(id);
		},
	},
	computed: {
		...mapGetters([
			"selected_object",
			"proposal",
			"page_settings",
			"files_list",
			"working_file_id",
			"file_changed",
			"stock_items_list",
			"job_proposals_list",
			"client_view",
			"get_active_working_file_category",
			"working_file_categories",
			"editor_fullscreen"
		]),
		dragOptions() {
			return {
				animation: 200,
				group: "description",
				disabled: false,
				ghostClass: "ghost",
			};
		},
		show_page_menu() {
			if (this.client_view == true) {
				return false;
			}
			if (this.proposal.approved == 1) {
				return false
			}

			return true;
		}
	},
};
</script>

<style>
.note-drawer__content {
	margin: 0px 20px;
}

#files_list {
	margin: 0;
	padding: 0;
	overflow: auto;
	/* background-color: #ffffff; */
	scroll-behavior: unset;
	white-space: nowrap;
	border: 1px solid lightgray;
	height: 100px;
}

#files_list>li {
	display: inline-block;
	height: 90px;
	width: 70px;
	margin: 6px;
	border: 1px solid lightgray;
	cursor: pointer;
	float: left;
}

#files_list>li>div {
	color: #9c9c9c;
	white-space: nowrap;
	width: 60px;
	overflow: hidden;
	text-overflow: ellipsis;
}

#files_list>li.selected {
	border: 1px solid greenyellow;
}

#files_list>.active_page {
	border: 3px solid orange !important;
}

#panel_div {
	overflow-x: auto;
}

#panel_div>.active_page {
	border: 3px solid orange !important;
}

#panel_div>.el-card {
	/* width: 200px; */
	display: table-cell;
	cursor: pointer;
}

#panel_div div.label {
	color: #9c9c9c;
	white-space: nowrap;
	width: 85px;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 12px;
}

#panel_div button {
	padding: 4px 7px !important;
}

#panel_div .hide_buttons {
	visibility: hidden;
}

#panel_div .el-button-group {
	width: 120px;
}
</style>
