import {mapActions, mapGetters} from 'vuex';

const ClientTermsMixin = {
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.get_details().then(()=>{
        if (vm.details.terms_accepted == false) {
          vm.$message({
            type: 'error',
            message: 'Need to accept Terms and Conditions',
          });
          next('/account');
        } else {
          next();
        }
      });
    });
  },
  computed: {
    ...mapGetters(['details'])
  },
  methods: {
    ...mapActions(['get_details']),
  }
};

export default ClientTermsMixin;