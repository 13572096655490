<template>
  <div>
    <PaymentsDialog
      v-if="showDialog == true"
      :show_dialog.sync="showDialog"
      :account_id="account_id"
      :master_payment="sel_master_payment"
    />
    <ColumnHeader
      :total="payment_total"
      :open="{
        label: 'UNASSIGNED PAYMENTS',
        total: unassigned_total
      }"
      :page="page"
      :total_page="total_page"
      @prev="onPrev"
      @next="onNext"
    >
      PAYMENTS
    </ColumnHeader>
    <ColumnElement
      v-for="(mp, index) in master_payments"
      :key="index"
    >
      <i
        :class="info_class(mp)"
        :style="info_style(mp)"
      />
      ${{ mp.payment }}
      <span
        v-show="is_partial(mp)===true"
        class="total-price"
      >
        - UNASSIGNED: <span class="unassigned">${{ mp.credit_note }}</span>
      </span>
      <span class="datediv">{{ mp.date }}</span>
      <template #second-line>
        <span
          v-show="mp.payments.length > 0 "
        >
          <span
            v-for="(attached,attached_index) in mp.payments"
            :key="attached_index"
          >
            <span
              class="second-line__item"
              style="color:#039be5"
            >
              4000{{ attached.invoice_id }}
              <span
                style="color:#b1b17b"
              >
                (${{ attached.payment_amount }})
              </span>
            </span>
          </span>
        </span>
      </template>
      <template #right>
        <el-checkbox
          v-model="mp.is_not_included"
          class="payment-col__not_included"
          @change="onNotIncludedChange(mp)"
        >
          Not Included
        </el-checkbox>
        <el-button
          icon="el-icon-info"
          circle
          @click="onShowDialog(index)"
        />
        <el-button
          v-if="add_payments[index] == false"
          type="success"
          size="mini"
          icon="el-icon-circle-plus"
          circle
          @click="onAddPayment(index, true)"
        />
        <el-button
          v-else
          type="type"
          size="mini"
          icon="el-icon-circle-close"
          circle
          @click="onAddPayment(index, false)"
        />
      </template>
      <template #extend>
        <AddPayment
          v-if="add_payments[index]===true"
          class="payment"
          :account_id="account_id"
          :master_payment="mp"
        />
      </template>
    </ColumnElement>
  </div>
</template>

<script>
import PaymentsDialog from './PaymentsDialog';
import AddPayment from './AddPayment';
import {EventBus} from '../../../../../utilities/EventBus';
import ColumnHeader from '../ColumnHeader';
import ColumnElement from '../ColumnElement';
import {loadMasterPayments, updateIsNotIncluded} from '../../../../../api/master_payment';
import {BALANCE_STATUS} from '../../../status';

export default {
  name: 'Payments',
  components: {ColumnElement, ColumnHeader, PaymentsDialog, AddPayment},
  props: {
    account_id: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      master_payments: [],
      page: 1,
      total_page: 1,
      payment_total: 0,
      unassigned_total: 0,

      showDialog: false,
      sel_master_payment: null,
      add_payments:[]
    };
  },
  computed: {
    credit_total() {
      let total = 0;
      this.master_payments.forEach((mp)=>{
        total += mp.credit_note;
      });
      return total;
    },
    paid_payment() {
      return (mp)=> {
        return mp.payment - mp.credit_note;
      };
    },
    status() {
      return (mp) => {
        let paid = this.paid_payment(mp);
        if(paid === 0) return BALANCE_STATUS.OPEN;
        if(paid === mp.payment) return BALANCE_STATUS.CLOSED;

        return BALANCE_STATUS.PARTIAL;
      };
    },
    is_partial() {
      return (mp) => {
        return this.status(mp) === BALANCE_STATUS.PARTIAL;
      };
    },
    info_class() {
      return (mp) => {
        if(this.status(mp) === BALANCE_STATUS.CLOSED) return 'el-icon-s-claim';

        return 'el-icon-s-order';
      };
    },
    info_style() {
      return (mp) => {
        if(this.status(mp) === BALANCE_STATUS.OPEN) return 'color:green';
        if(this.status(mp) === BALANCE_STATUS.PARTIAL) return 'color:orange';
        return '';
      };
    }
  },
  created() {
    this.load_master_payments();
    EventBus.$on('refreshAllInvoice',this.onPaymentUpdated);
  },
  methods: {
    onNotIncludedChange(mp) {
      updateIsNotIncluded(this, mp.id, mp.is_not_included).then(({data})=>{
        this.$message({
          type: 'success',
          message: data.message
        });
        EventBus.$emit('refreshAllInvoice');
      });
    },
    load_master_payments() {
      return loadMasterPayments(this, this.account_id, this.page).then(({data})=>{
        this.master_payments = data.master_payments;
        this.add_payments = [];
        this.master_payments.forEach(()=>{
          this.add_payments.push(false);
        });

        this.payment_total = data.payment_total;
        this.unassigned_total = data.unassigned_total;
        this.total_page = data.total_page;

      });
    },
    async onPaymentUpdated() {
      await this.load_master_payments();
      if(this.sel_master_payment) {
        this.sel_master_payment = this.master_payments.find(mp => mp.id == this.sel_master_payment.id);
      }
    },
    onShowDialog(index) {
      this.sel_master_payment = this.master_payments[index];
      this.showDialog = true;
    },
    onAddPayment(index, val) {
      this.$set(this.add_payments, index, val);
    },
    onPrev() {
      if(this.page === 1)
        return;

      this.page -= 1;
      this.load_master_payments();

    },
    onNext() {
      if(this.page === this.total_page)
        return;

      this.page += 1;
      this.load_master_payments();
    }
  },
};
</script>

<style lang='scss'>
.payment-col__not_included{
  padding-right: 4px;

  .el-checkbox__label {
    font-size:13px !important;
  }
}

</style>
<style lang='scss' scoped>
@import '../style/balancing';

.payment {
  margin-top: 10px;
}

.unassigned {
  @include text__red;
}
</style>
