<template>
  <div id="reimbursements">
    <p class="headline">
      Reimbursement: <Money :amount="total" />
    </p>
    <el-table
      :data="reimbursements"
      style="width: 100%"
    >
      <el-table-column
        prop="created"
        label="Created at"
        width="180"
      />
      <el-table-column
        prop="actual_value"
        label="Actual value"
        width="180"
      >
        <template #default="scope">
          <Money :amount="scope.row.actual_value" />
        </template>
      </el-table-column>
      <el-table-column
        prop="description"
        label="Description"
      />
      <el-table-column
        label="Purchase order"
        width="180"
      >
        <template #default="scope">
          <el-button
            type="primary"
            size="mini"
            @click="purchase_order(scope.row.id)"
          >
            Purchase order
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        label="Remove"
        width="180"
      >
        <template #default="scope">
          <el-button
            type="danger"
            size="mini"
            @click="remove_purchase_order(scope.row.id)"
          >
            Remove
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import Money from '../../shared/Money';
import {pay_run_reimbursements, remove_purchase_order} from '../../../api/reimbursements';

export default {
  name: 'Reimbursements',
  components: {Money},
  props: {
    crm_user_id: {
      type: String,
      required: true
    },
    pay_period_start: {
      type: String,
      required: true
    },
    pay_period_end: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      reimbursements: [ { records: [] }],
      total: 0
    };
  },
  created() {
    this.pay_run_reimbursements();
  },
  methods: {
    purchase_order(id){
      window.open(`/purchase_orders/${id}/edit`, '_blank');
    },
    remove_purchase_order(id){
      remove_purchase_order(this, id).then(() => {
        this.pay_run_reimbursements();
        this.$emit('calculate_pay_run', `-${this.total}`);
      });
    },
    pay_run_reimbursements(){
      pay_run_reimbursements(this, this.crm_user_id, this.pay_period_start, this.pay_period_end).then((response)=> {
        this.reimbursements = response.data.records;
        this.total = response.data.total;
        this.$emit('calculate_pay_run', this.total);
      });
    }
  }
};
</script>
<style scoped>
  .headline {
    padding: 10px;
    color: white;
    background: #409EFF;
  }
</style>