<template>
  <div>
    <el-card
      class="box-card"
      v-for="group in grouped_files"
      :key="group.id"
      style="margin: 10px"
    >
      <div slot="header" class="clearfix">
        <strong>{{ group.name.toUpperCase() }}</strong>
        <el-button
          style="float: right;"
          icon="el-icon-edit"
          v-show="group.id > 0"
          size="small"
          @click="edit_working_file_details(group.id, group.working_file)"
        ></el-button>
      </div>
      <StockItemsList :working_file_id="group.id"></StockItemsList>
      <JobProposalsList :working_file_id="group.id"></JobProposalsList>

      <!-- </div> -->
    </el-card>
    <el-dialog
      title="Working file details"
      class="working_file_details_dialog"
      :close-on-click-modal="false"
      :visible.sync="working_file_details_dialog"
    >
      <WorkingFileDetails
        :file_details="selected_working_file_details"
        :hide_working_file_details_dialog="hide_working_file_details_dialog"
      >
      </WorkingFileDetails>
    </el-dialog>
  </div>
</template>
<script>
import { editor_store } from "store/modules/editor";
import StockItemsList from "components/stock_items/StockItemsList.vue";
import JobProposalsList from "components/job_proposals/JobProposalsList.vue";

import WorkingFileDetails from "components/proposal/working_files/WorkingFileDetails";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "WorkingFilesList",
  store: editor_store,
  data() {
    return {
      working_file_details_dialog: false,
      selected_working_file_details: {},
    };
  },
  components: {
    StockItemsList,
    WorkingFileDetails,
    JobProposalsList,
  },
  methods: {
    hide_working_file_details_dialog() {
      this.working_file_details_dialog = false;
      this.selected_working_file_details = {};
    },
    edit_working_file_details(index, file) {
      this.working_file_details_dialog = true;
      this.selected_working_file_details = Object.assign({}, file);
    },
  },
  computed: {
    ...mapGetters(["files_list", "stock_items_list", "job_proposals_list"]),
    grouped_files() {
      let grouped_working_files = [];
      let main_stock_list = this.stock_items_list.filter(
        (c) => c.working_file_id == 0
      );
      let main_jp_list = this.job_proposals_list.filter(
        (c) => c.working_file_id == 0
      );
      if (main_stock_list.length > 0 || main_jp_list.length > 0) {
        let main_list = {
          id: 0,
          name: "Main list",
          working_file: {},
          stocks: main_stock_list,
          tasks: main_jp_list,
          is_included: true,
          qty: 1,
          quantity_unit_id: null
        };
        grouped_working_files.push(main_list);
      }
      this.files_list.forEach((el, idx) => {
        let grp = {
          id: el.id,
          working_file: el,
          name: el.name,
          is_included: el.is_included,
          qty: el.qty,
          quantity_unit_id: el.quantity_unit_id,
          stocks: [],
          tasks: [],
        };
        grp.stocks = this.stock_items_list.filter(
          (c) => c.working_file_id == el.id
        );
        grp.tasks = this.job_proposals_list.filter(
          (c) => c.working_file_id == el.id
        );
        grouped_working_files.push(grp);
      });
      return grouped_working_files;
    },
  },
};
</script>
