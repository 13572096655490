import Vue from 'vue';
import Vuex from 'vuex';


import {
  Message
} from 'element-ui';
import DOMPurify from 'dompurify';

Vue.use(Vuex);


export const mailbox_store = new Vuex.Store({
  strict: false,
  state: {
    title: '',
    icon: '',
    emails: [],
    current_message_id: '',
    current_message: '',
    to: [],
    from: [],
    cc: [],
    bcc: [],
    subject: '',
    received: '',
    current_mailbox: '',
    current_index: '',
    attachments: [],
    loading: true,
    contacts: [],
    email_addresses: [],
    to_display_names: [],
    from_display_names: [],
    cc_display_names: [],
    new_mail_count: '0',
    unseen_emails: [],
    current_mailbox_account_id: '-1',
    stop_infinite_loading: false,
    infinite_id: new Date(),
    message_flag: 'ALL',
    mail_count_per_search: '',
    is_first_load: true,
    total_amount_emails: '0',
    mailboxes: [],
    level_mailboxes: [],
    email_delete: false,
    user: '',
    guid: '',
    read: true,
    search_mailbox: '',
    mime_type: ''
  },
  getters: {
    title: state => {
      return state.title;
    },
    icon: state => {
      return state.icon;
    },
    emails: state => {
      return state.emails;
    },
    current_message_id: state => {
      return state.current_message_id;
    },
    current_message: state => {
      return state.current_message;
    },
    subject: state => {
      return state.subject;
    },
    to: state => {
      return state.to;
    },
    from: state => {
      return state.from;
    },
    to_display_names: state => {
      return state.to_display_names;
    },
    from_display_names: state => {
      return state.from_display_names;
    },
    cc_display_names: state => {
      return state.cc_display_names;
    },
    received: state => {
      return state.received;
    },
    cc: state => {
      return state.cc;
    },
    bcc: state => {
      return state.bcc;
    },
    current_mailbox: state => {
      return state.current_mailbox;
    },
    current_index: state => {
      return state.current_index;
    },
    attachments: state => {
      return state.attachments;
    },
    loading: state => {
      return state.loading;
    },
    contacts: state => {
      return state.contacts;
    },
    email_addresses: state => {
      return state.email_addresses;
    },
    new_mail_count: state => {
      return state.new_mail_count;
    },
    unseen_emails: state => {
      return state.unseen_emails;
    },
    current_mailbox_id: state => {
      return state.current_mailbox_account_id;
    },
    stop_infinite_loading: state => {
      return state.stop_infinite_loading;
    },
    infinite_id: state => {
      return state.infinite_id;
    },
    message_flag: state => {
      return state.message_flag;
    },
    mail_count_per_search: state => {
      return state.mail_count_per_search;
    },
    is_first_load: state => {
      return state.is_first_load;
    },
    total_amount_emails: state => {
      return state.total_amount_emails;
    },
    mailboxes: state => {
      return state.mailboxes;
    },
    level_mailboxes: state => {
      return state.level_mailboxes;
    },
    email_delete: state => {
      return state.email_delete;
    },
    user: state => {
      return state.user;
    },
    read: state => {
      return state.read;
    },
    guid: state => {
      return state.guid;
    },
    search_mailbox: state => {
      return state.search_mailbox;
    },
    mime_type: state => {
      return state.mime_type;
    },
  },
  mutations: {

    headline(state, payload) {
      state.title = payload.title;
      state.icon = payload.icon;
    },
    SET_CURRENT_MESSAGE_ID(state, payload) {
      // important for search to get mailbox folder name from guid
      if (payload.guid != undefined && payload.user != undefined) {
        state.guid = payload.guid;
        state.user = payload.user;
      }
      state.current_message_id = payload.message_id;
    },
    GET_EMAILS(state, payload) {

      state.emails = state.emails.concat(payload);

      if (state.emails.length <= 6) {
        state.current_message_id = state.emails[0].message_id;
      }
      state.to = ''; //make sure 'state.to' is empty, to watch state changes in compose form for draft email
    },
    GET_FILTERED_EMAILS(state, payload) {
      state.emails = payload;
      //if (state.emails.length <= 5)
      state.current_message_id = state.emails[0].message_id;

      state.to = ''; //make sure 'state.to' is empty, to watch state changes in compose form for draft email
    },
    COUNT_NEW_EMAILS(state, payload) {
      state.new_mail_count = payload;
    },
    GET_NEW_EMAILS(state, payload) {
      state.emails.unshift(payload);
    },
    CURRENT_MAILBOX(state, mailbox) {
      state.current_mailbox = mailbox;
    },
    GET_EMAIL(state, payload) {
      state.current_message = payload.current_message;
      state.attachments = payload.attachments;
      state.current_message_id = payload.message_id;
      state.to = payload.to;
      state.cc = payload.cc;
      state.to_display_names = payload.to_display_names;
      state.from_display_names = payload.from_display_names;
      state.cc_display_names = payload.cc_display_names;
      state.subject = payload.subject;
      state.received = payload.received;
      state.from = payload.from;
      state.email_delete = payload.email_delete;
      state.search_mailbox = payload.search_mailbox;
      state.mime_type = payload.mime_type;

      if (payload.current_index != undefined)
        state.current_index = payload.current_index;
    },
    GET_FILTERED_EMAIL(state, payload) {
      state.current_message = payload.current_message;
      state.attachments = payload.attachments;
      state.current_message_id = payload.message_id;
      state.to = payload.to;
      state.cc = payload.cc;
      state.to_display_names = payload.to_display_names;
      state.from_display_names = payload.from_display_names;
      state.cc_display_names = payload.cc_display_names;
      state.subject = payload.subject;
      state.received = payload.received;
      state.from = payload.from;
      state.user = payload.user;

      if (payload.current_index != undefined)
        state.current_index = payload.current_index;
    },
    NO_EMAIL(state) {
      state.current_message = 'No email found';
      state.current_message_id = '';
      state.to = '';
      state.from = '';
      state.to_display_names = [];
      state.from_display_names = [];
      state.cc_display_names = [];
      state.received = '';
      state.subject = '';
      state.cc = '';
      state.bcc = '';
      state.email_delete = '';
      //state.emails = []
      state.attachments = [];
      state.search_mailbox = '';
    },
    LOADING(state, payload) {
      state.loading = payload;
    },
    CLEAR_CURRENT_MESSAGE(state) {
      state.current_message_id = '';
    },
    DELETE_EMAIL(state, index) {
      if (index !== -1)
        state.emails.splice(index, 1);
      state.current_message = '';
      state.current_index = index;
    },
    MOVE_TO(state, index) {
      if (index !== -1)
        state.emails.splice(index, 1);
      state.current_message = '';
      state.current_index = index;
    },
    GET_CONTACTS(state, payload) {
      state.contacts = payload;
    },
    NO_CONTACT(state) {
      state.current_message = 'No contact found';
    },
    ADD_CONTACT(state, payload) {
      state.contacts.unshift(payload);
    },
    DELETE_CONTACT_INDEX(state, index) {
      if (index !== -1)
        state.contacts.splice(index, 1);

    },
    EMAIL_ADDRESSES(state, payload) {
      state.email_addresses = payload;
    },
    FETCH_ALL_ACCOUNT_EMAILS_UNSEEN(state, payload) {
      state.unseen_emails = payload;
    },
    CURRENT_MAILBOX_ID(state, payload) {
      state.current_mailbox_account_id = payload;
    },
    CLEAR_MAILS(state) {
      state.emails = [];
      state.stop_infinite_loading = false;
      state.mime_type = '';
    },
    SET_INFINITE_ID(state) {
      state.infinite_id += 1;
    },
    STOP_INFINITE_LOADING(state) {
      state.stop_infinite_loading = true;
    },
    SET_MESSAGE_FLAG(state, flag) {
      state.message_flag = flag;
    },
    COUNT_MAIL(state, size) {
      state.mail_count_per_search = size;
    },
    SET_FIRST_LOAD(state, bool) {
      state.is_first_load = bool;
    },
    SET_TOTAL_AMOUNT_EMAILS(state, amount) {
      state.total_amount_emails = amount;
    },
    MAILBOXES(state, mailboxes) {
      state.mailboxes = mailboxes;
    },
    LEVEL_MAILBOXES(state, level_mailboxes) {
      state.level_mailboxes = level_mailboxes;
    },
    MARK_READ(state, payload) {
      state.emails[payload.index].read = !state.emails[payload.index].read;
      if (payload.click == true)
        state.emails[payload.index].read = false;
    },
  },
  actions: {
    no_mailbox({
      commit
    }) {
      commit('NO_EMAIL');
      commit('STOP_INFINITE_LOADING');
    },
    // for reset infinite loop loader, on change select email account
    set_first_load({
      commit
    }, bool) {
      commit('SET_FIRST_LOAD', bool);
    },
    set_message_flag({
      commit
    }, flag) {
      commit('SET_MESSAGE_FLAG', flag);
    },
    set_infinite_id({
      commit
    }) {
      commit('SET_INFINITE_ID');
    },
    clear_emails({
      commit
    }) {
      commit('CLEAR_MAILS');
    },
    get_contacts({
      state,
      commit
    }) {
      commit('LOADING', true);

      Vue.http.get('/contacts').then(response => {
        commit('LOADING', false);

        if (response.body.contacts.length > 0)
          commit('GET_CONTACTS', response.body.contacts);
        else
          commit('NO_CONTACT');
      });
    },
    set_current_message_id({
      commit
    }, payload) {
      commit('SET_CURRENT_MESSAGE_ID', payload);
    },
    get_email_addresses({
      commit
    }) {
      Vue.http.get('/email_addresses').then(response => {

        if (response.body.emails.length > 0)
          commit('EMAIL_ADDRESSES', response.body.emails);
      });
    },
    add_contact({
      commit,
      state
    }) {
      var contacts = {
        email: '',
        alias: '',
        firstname: '',
        lastname: '',
        mobile: '',
        notes: '',
        suburb: '',
        website: '',
        street: '',
        city: '',
        country: ''
      };
      commit('ADD_CONTACT', contacts);

    },
    search_contacts({
      commit
    }, query) {
      Vue.http.get('/search_contacts?query=' + query)
        .then(
          response => {
            if (response.body.contacts.length > 0)
              commit('GET_CONTACTS', response.body.contacts);
          });
    },
    get_new_emails({
      commit
    }, keys) {

      Vue.http.post('/get_all_emails', {
        keys: 'NEW',
        mailbox: 'Inbox'
      }).then(response => {
        if (response.body.emails.length > 0) {
          commit('COUNT_NEW_EMAILS', response.body.emails.length);
          for (let email of response.body.emails)
            commit('GET_NEW_EMAILS', email);

          Message({
            message: 'New Email',
            type: 'success'
          });
        }
      });
    },
    count_new_emails({
      commit
    }) {
      Vue.http.get('/count_all_emails').then(response => {
        if (response.body.size[0] > 0) {
          commit('COUNT_NEW_EMAILS', response.body.size[0]);
        }
      });
    },
    get_filtered_emails({
      state,
      commit
    }, keys) {

      commit('LOADING', true);
      commit('CLEAR_CURRENT_MESSAGE');
      // var current_path = router.currentRoute.path.split('/')
      // current_path = current_path[1]
      // current_path = current_path.charAt(0).toUpperCase() + current_path.slice(1);
      // commit('CURRENT_MAILBOX_ID', keys.mailbox_account)
      // commit('CURRENT_MAILBOX', current_path)

      Vue.http.post('/get_filtered_emails', {
        keys: 'ALL',
        mailbox: state.current_mailbox,
        message_id: keys.filtered_msg_id,
        branch_id: keys.branch_id,
        mailbox_account_id: keys.mailbox_account,
        proposal_id: keys.proposal_id,
        po_id: keys.po_id,

      }).then(response => {

        commit('LOADING', false);

        if (response.body.emails.length > 0) {
          commit('GET_EMAILS', response.body.emails);
        } else {
          commit('NO_EMAIL');
          //commit('STOP_INFINITE_LOADING')
        }
      });
    },
    get_emails({
      state,
      commit
    }, keys) {
      commit('LOADING', true);
      commit('CLEAR_CURRENT_MESSAGE');
      let search = keys.includes('TEXT');
      let sequence_number = 0;

      setTimeout(() => {

        if (state.mail_count_per_search > 0 || search) {

          if (!search) {
            sequence_number = state.mail_count_per_search - 5;
            if (sequence_number < 1) {
              sequence_number = 1;
            }
            keys = [sequence_number + ':' + state.mail_count_per_search, this.message_flag];
          }
          console.log(keys);
          Vue.http.post('/get_all_emails', {
            search: search,
            keys: keys,
            mailbox: state.current_mailbox
          }).then(response => {

            commit('LOADING', false);
            if (!search) {
              commit('COUNT_MAIL', state.mail_count_per_search - 6);
            } else {
              commit('SET_TOTAL_AMOUNT_EMAILS', response.body.emails.length);
            }

            if (response.body.emails.length > 0) {
              commit('GET_EMAILS', response.body.emails);
              state.is_first_load = false;


              if (sequence_number == 1 || search) {
                state.mail_count_per_search = '';
                commit('STOP_INFINITE_LOADING');
              }
            } else {
              commit('NO_EMAIL');
              commit('STOP_INFINITE_LOADING');
            }
          });
        } else {
          commit('LOADING', false);
          if (state.is_first_load == false) {

            commit('NO_EMAIL');
            commit('STOP_INFINITE_LOADING');
          }
        }
      }, 200);
    },
    count_mails({
      state,
      commit
    }) {
      let current_path = router.currentRoute.path;
      // path for sub folders /Level1/level2/Level3/
      if (current_path.split('/').length > 2) {
        current_path = current_path.split('/inbox/');
      } else {
        current_path = current_path.split('/');
      }
      current_path = current_path.pop();
      current_path = current_path.charAt(0).toUpperCase() + current_path.slice(1);
      current_path = decodeURI(current_path);
      commit('CURRENT_MAILBOX', current_path);
      //commit('COUNT_MAIL', 0)
      commit('SET_FIRST_LOAD', true);


      Vue.http.post('/count_mails', {
        keys: 'ALL',
        mailbox: state.current_mailbox
      }).then(response => {
        commit('SET_TOTAL_AMOUNT_EMAILS', response.body.count_result);
        if (response.body.count_result > 0) {

          commit('COUNT_MAIL', response.body.count_result);
        } else {
          commit('COUNT_MAIL', '0');
        }
      });
    },
    get_filtered_email({
      state,
      commit
    }, mail) {

      Vue.http.post('/get_email', {
        message_id: mail.message_id,
        mailbox: mail.mailbox,
        mailbox_account_id: mail.mailbox_account_id || state.current_mailbox_account_id,
        guid: mail.mailbox,
        user: mail.user,
        with_attachments: mail.with_attachments,
        reply_or_forward: mail.action

      }).then(response => {

        let mail_data = {
          current_message: DOMPurify.sanitize(response.body.email_data.email),
          to: response.body.email_data.to,
          from: response.body.email_data.from,
          cc: response.body.email_data.cc,
          from_display_names: response.body.email_data.from_display_names,
          to_display_names: response.body.email_data.to_display_names,
          cc_display_names: response.body.email_data.cc_display_names,
          subject: response.body.email_data.subject,
          received: response.body.email_data.received,
          attachments: response.body.attachments,
          message_id: mail.message_id,
          current_index: mail.index,
          user: mail.user
        };
        commit('GET_FILTERED_EMAIL', mail_data);
      });
    },
    get_email({
      state,
      commit
    }, mail) {
      //console.log(context.state.current_message_id);
      commit('LOADING', true);
      let email_data = {
        message_id: mail.message_id,
        mailbox: state.current_mailbox,
        reply_or_forward: mail.action,
        with_attachments: mail.with_attachments,
        compose: mail.draft,
        mailbox_account_id: mail.mailbox_account_id || state.current_mailbox_account_id
      };
      // for search to get mailbox folder name from guid
      if (state.message_flag[0] == 'TEXT' && state.emails.length > 0) {
        email_data.guid = state.emails[0].mailbox;
        email_data.user = state.emails[0].mailbox_account_id;
      }
      // for search to get mailbox folder name from guid (in Solr result called, for example "box":["c0513d0b36fd395ea33e00009a8b1dc4"])
      if (state.guid.length > 5 && state.user.length > 3) {
        email_data.guid = state.guid;
        email_data.user = state.user;
      }

      Vue.http.post('/get_email', email_data).then(response => {
        state.guid = '';
        state.user = '';
        let mail_data = {
          current_message: DOMPurify.sanitize(response.body.email_data.email, {
            ADD_TAGS: ['iframe', 'a'],
            ADD_ATTR: ['type', 'height', 'width', 'frameborder', 'allowfullscreen', 'href'],
            ADD_URI_SAFE_ATTR: ['src']
          }),
          to: response.body.email_data.to,
          from: response.body.email_data.from,
          cc: response.body.email_data.cc,
          from_display_names: response.body.email_data.from_display_names,
          to_display_names: response.body.email_data.to_display_names,
          cc_display_names: response.body.email_data.cc_display_names,
          subject: response.body.email_data.subject,
          received: response.body.email_data.received,
          attachments: response.body.attachments,
          message_id: mail.message_id,
          current_index: mail.index,
          email_delete: response.body.email_data.email_delete,
          search_mailbox: response.body.email_data.mailbox,
          mime_type: response.body.email_data.mime_type,
        };
        commit('GET_EMAIL', mail_data);
        commit('LOADING', false);
      });
    },
    delete_email({
      state,
      commit
    }, mail) {
      commit('LOADING', true);
      Vue.http.post('/delete_email', {
        message_id: mail.message_id,
        mailbox: state.current_mailbox,
        search_mailbox: state.search_mailbox
      }).then(response => {
        commit('DELETE_EMAIL', mail.index);
        Message({
          message: 'Email deleted',
          type: 'success'
        });
        commit('LOADING', false);
        var index = mail.index;
        if (state.emails[index] == undefined) {
          index -= 1;
        }
        if (index != -1)
          commit('GET_EMAIL', {
            message_id: state.emails[index].message_id
          });
      });
    },
    delete_emails({
      state,
      commit
    }, mail) {
      commit('', true);
      Vue.http.post('/delete_emails', {
        message_ids: mail.message_ids,
        mailbox: state.current_mailbox
      }).then(response => {
        Message({
          message: 'Emails deleted',
          type: 'success'
        });
        commit('LOADING', false);
        window.location.reload();
      });
    },
    blacklist_email({
      state,
      commit
    }, mail) {
      commit('LOADING', true);

      const regex = /[a-zA-Z0-9-_.]+@[a-zA-Z0-9-_.]+/g;
      const pattern = mail.pattern.match(regex);
      Vue.http.post('/new_blacklist_entry', {
        pattern: pattern[0]
      }).then(
        response => {
          Message({
            message: 'Email blacklisted!',
            type: 'success'
          });
          commit('LOADING', false);
        },
        response => {
          Message({
            message: response.data.error,
            type: 'error'
          });
          commit('LOADING', false);
        }
      );
    },
    load_mailboxes({
      state,
      commit
    }) {
      Vue.http.get('/list_mailboxes').then(
        response => {
          commit('MAILBOXES', response.data.list);
        }
      );
    },
    load_level_mailboxes({
      state,
      commit
    }) {
      Vue.http.get('/list_level_mailboxes').then(
        response => {
          commit('LEVEL_MAILBOXES', response.data.list);
        }
      );
    },
    move_to_spam({
      state,
      commit
    }, mail) {
      commit('LOADING', true);
      Vue.http.post('/move_to_spam', {
        message_id: mail.message_id,
        mailbox: state.current_mailbox
      }).then(response => {
        commit('MOVE_TO', mail.index);
        var m;
        if (state.current_mailbox == 'Junk')
          m = 'Email moved to inbox';
        if (state.current_mailbox == 'Inbox')
          m = 'Email moved to spam';

        Message({
          message: m,
          type: 'success'
        });
        commit('LOADING', false);
        var index = mail.index;
        if (state.emails[index] == undefined) {
          index -= 1;
        }
        if (index != -1)
          commit('GET_EMAIL', {
            message_id: state.emails[index].message_id
          });
      });
    },
    move_email({
      state,
      commit
    }, mail) {
      commit('LOADING', true);
      Vue.http.post('/move_email', {
        message_id: mail.message_id,
        folder_name: mail.folder_name,
        current_mailbox: state.current_mailbox
      }).then(response => {

        commit('MOVE_TO', mail.index);
        // var m;
        // if (state.current_mailbox == 'Trash')
        //     m = "Email moved to inbox"

        Message({
          message: mail.message,
          type: 'success'
        });
        commit('LOADING', false);
        var index = mail.index;
        if (state.emails[index] == undefined) {
          index -= 1;
        }
        if (index != -1)
          commit('GET_EMAIL', {
            message_id: state.emails[index].message_id
          });
      });
    },
    delete_contact({
      commit
    }, index) {
      commit('DELETE_CONTACT_INDEX', index);
    },
    mark_as_read({
      state,
      commit
    }, mail) {
      let payload = {index: mail.index, click: mail.click};
      commit('MARK_READ', payload);
      Vue.http.post('/mark_as_read', {
        message_id: mail.message_id,
        read: state.emails[mail.index].read
      });
    },
    fetch_all_accounts_emails_unseen({
      commit
    }) {
      Vue.http.get('/fetch_all_accounts_emails_unseen').then(response => {
        if (response.body.size[0] > 0) {
          commit('FETCH_ALL_ACCOUNT_EMAILS_UNSEEN', response.body.accounts);
        }
      });
    },
    set_default_input(mills = 300) {
      setTimeout(function () {
        Array.from(document.querySelectorAll('.el-input__inner')).forEach(
          function (e) {
            e.classList.add('browser-default');
            if (e.querySelector('input') != null)
              e.querySelector('input').className += ' browser-default';
          }
        );
      }, mills);
    },
    invitation_accepted({state}, calendar_file) {
      console.log(calendar_file);
      Vue.http.post('/invitation_accepted', {
        calendar: calendar_file
      }).then(response => {
        Message({
          message: 'Email invitation accepted sent.',
          type: 'success'
        });
      });
    },
    invitation_declined({state}, calendar_file) {
      Vue.http.post('/invitation_declined', {
        calendar: calendar_file
      }).then(response => {
        Message({
          message: 'Email invitation declined sent.',
          type: 'success'
        });
      });
    },
  }
});