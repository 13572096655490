<template>
    <el-dialog width="50%"
               :visible.sync="leave_transaction_dialog.visible"
               title="Leave Transactions"
               @opened="set_default_input()" >
        <h3>
            <strong>{{selected_leave.leave_type.type}}</strong>               
              <span class="new badge blue lighten-2 right" data-badge-caption="Days(s)">{{current_balance / 8}}</span>
              <span data-v-419e4726="" class="badge">/</span>
              <span class="new badge blue lighten-2 " data-badge-caption="Hour(s)">{{current_balance}}</span>              
            <hr>
        </h3>
        <el-form ref="leave_adjustment_form" :rules="rules" :model="new_balance"   :inline="true"  v-show="is_admin == '1'">
        <el-form-item label="New Balance (Hours)"  prop="balance" >
            <el-input-number v-model="new_balance.balance" ></el-input-number>       
        </el-form-item>
        <el-form-item label="Description" prop="description">
            <el-input v-model.number="new_balance.description"  style="width: 250px;" ></el-input>
        </el-form-item>
            <el-form-item>
            <el-button type="primary" @click="submit('leave_adjustment_form')">Submit</el-button>
            <el-button @click="leave_transaction_dialog.visible = false">Cancel</el-button>
        </el-form-item>
        </el-form>
        <el-table :data="leaves_transactions" v-loading="loading" style="width: 100%" >
            <el-table-column
                prop="created_at"
                label="Date"
                width="200">
                </el-table-column>
            <el-table-column
                prop="balance"
                label="Balance"
                width="180">
                </el-table-column>
            <el-table-column
                prop="description"
                label="Description"
                >
                </el-table-column>
        </el-table>       
    </el-dialog>
</template>

<script>

import { mapGetters, mapActions } from "vuex";

export default {
  name: "LeaveTransactions",
  props: ["leave_transaction_dialog", "selected_leave"],
  data() {
    return {
      leaves_transactions: [],
      current_balance: 0,
      loading: false,
      new_balance: {
        crm_user_id: "",
        balance: 1,
        description: "",
        leave_type_id: ""
      },
      rules: {
        balance: [
          {
            required: true,
            message: "Please input a new balance",
            trigger: "blur"
          }
        ],
        description: [
          {
            required: true,
            message: "Please input a description",
            trigger: "blur"
          }
        ]
      }
    };
  },

  watch: {
    leave_transaction_dialog: function(oldVal, newVal) {
      this.new_balance = {
        crm_user_id: this.selected_leave.crm_user_id,
        balance: "",
        description: "",
        leave_type_id: this.selected_leave.leave_type.id
      };

      this.current_balance = this.selected_leave.leave_type.balance;
      this.load_leaves_transactions();

      // debugger
      // this.current_balance = this.selected_leave.leave_type.balance;
      // if (newVal.visible == true) {
      //   this.load_leaves_transactions();
      //   this.current_balance = this.selected_leave.leave_type.balance;
      // } else {
      //   this.leaves_transactions = [];
      // }
    }
  },
  computed: {
    ...mapGetters(["selected_user_info", "is_admin"])
    // leave_dialog: {
    //   get() {
    //     return this.leave_transaction_dialog;
    //   },
    //   set(newVal) {

    //     this.$emit("leave_dialog_transaction", newVal);
    //     //this.$emit("update:leave_transaction_dialog", newVal);
    //   }
    // }
  },
  methods: {
    ...mapActions(["load_user_balance"]),
    load_leaves_transactions() {
      let _this = this;
      let data = {
        crm_user_id: _this.selected_leave.crm_user_id,
        leave_type_id: _this.selected_leave.leave_type.id
      };

      _this.loading = true;
      this.$http.post("/user_leave_transactions", data).then(response => {
        _this.leaves_transactions = response.data;
        _this.loading = false;
      });
    },
    reset_inputs() {
      this.new_balance.balance = 1;
      this.new_balance.description = "";
    },
    submit(form_name) {
      let _this = this;

      this.$refs[form_name].validate(valid => {
        if (valid) {
          _this.loading = true;
          this.$http
            .post("/adjust_leaves", { new_balance: _this.new_balance })
            .then(
              response => {
                _this.loading = false;
                if (response.data.status == 200) {
                  this.$message({
                    message: "Adjustment has been saved.",
                    type: "success"
                  });
                  _this.current_balance = response.data.balance;
                  _this.load_user_balance();
                  _this.load_leaves_transactions();
                  _this.reset_inputs();
                } else {
                  _this.$message.error("Adjustment not saved.");
                }
              },
              function(response) {
                _this.loading = false;
                _this.$message.error("Adjustment not saved.");
              }
            );
        } else {
          return false;
        }
      });
    },
    set_default_input() {
      Array.from(document.querySelectorAll('.el-select__input, .el-input__inner')).forEach(
          function (e) {
            e.classList.add('browser-default');
            if (e.querySelector('input') != null)
              e.querySelector('input').className += ' browser-default';
          }
      );
    },
  }
};
</script>

<style scoped>
</style>