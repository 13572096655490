<template>
  <el-card class="box-card" >
    <div slot="header" class="clearfix sales__title">
      Summary
    </div>

    <div class="list">
      <el-row>
        <el-col class="list__name">Total Sale Price</el-col>
        <el-col class="list__money" ><Money :amount="total_sale_price"/></el-col>
        <el-col class="list__percent"></el-col>
      </el-row>
      <el-row>
        <el-col class="list__name">Total Quote Price</el-col>
        <el-col class="list__money" ><Money :amount="total_quote_price"/></el-col>
        <el-col class="list__percent"><Percent :dividend="total_quote_price" :divisor="total_sale_price"/></el-col>
      </el-row>
      <el-row>
        <el-col class="list__name">Total Cost</el-col>
        <el-col class="list__money"><Money :amount="total_cost"/></el-col>
        <el-col class="list__percent"><Percent :dividend="total_cost" :divisor="total_sale_price"/></el-col>
      </el-row>
      <el-row>
        <el-col class="list__name">Stock Cost</el-col>
        <el-col class="list__money"><Money :amount="stock_cost"/></el-col>
        <el-col class="list__percent"><Percent :dividend="stock_cost" :divisor="total_sale_price"/></el-col>
      </el-row>
      <el-row>
        <el-col class="list__name">Labour Cost</el-col>
        <el-col class="list__money"><Money :amount="labour_cost"/></el-col>
        <el-col class="list__percent"><Percent :dividend="labour_cost" :divisor="total_sale_price"/></el-col>
      </el-row>
      <el-row>
        <el-col class="list__name">Gross Profit</el-col>
        <el-col class="list__money" ><Money :amount="gross_profit"/></el-col>
        <el-col class="list__percent"><Percent :dividend="gross_profit" :divisor="total_sale_price"/></el-col>
      </el-row>
    </div>
  </el-card>
</template>

<script>
import SalesCostMixin from '../../../mixins/SalesCostMixin';

import Money from "components/shared/Money";
import Percent from "components/shared/Percent";

export default {
  name: "Summary",
  components: {
    Money,
    Percent
  },
  mixins: [SalesCostMixin],
}
</script>

<style lang="scss" scoped>
@import "~element-ui/packages/theme-chalk/src/col";

.list {
  font-weight: bold;
  background-color: #e2edf1;
  border: 1px solid #e4dfdf;

  .el-row {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &__name {
    @extend .el-col-xs-8;

    @extend .el-col-sm-4;
    @extend .el-col-sm-offset-13;

    @extend .el-col-xl-3;
    @extend .el-col-xl-offset-16;
  }

  &__money {
    @extend .el-col-xs-8;

    @extend .el-col-sm-4;
    @extend .el-col-sm-offset-1;

    @extend .el-col-xl-2;
    @extend .el-col-xl-offset-0;
  }

  &__percent {
    @extend .el-col-xs-8;
    @extend .el-col-sm-2;
    @extend .el-col-xl-3;
  }
}
</style>
