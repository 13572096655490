<template>
  <tr
    :style="[
      job.mark_as_deleted == true
        ? { 'text-decoration': 'line-through', 'font-style:': 'italic' }
        : {},
    ]"
    class="task__row"
  >
    <td>
      <div>
        <i
          v-show="
            sortable == true && job.completed != true && proposal.approved == 0
          "
          class="el-icon-d-caret handle drag-handler"
          style="vertical-align: middle;"
        />

        <el-tooltip
          placement="top-start"
          :content="job.layer_name == '' ? job.job_name : job.layer_name"
        >
          <el-input
            v-model="job.layer_name"
            :placeholder="job.job_name"
            size="mini"
            style="min-width: 100px"
            @change="update_layer_name"
          />
        </el-tooltip>
      </div>

      <div
        v-if="job.is_outwork == true || job.is_custom === true"
        style="padding-top: 10px;"
      >
        <el-tag
          v-if="job.is_custom === true"
          effect="dark"
          type="info"
          size="mini"
        >
          CUSTOM TASK
        </el-tag>
        <el-tag
          v-if="job.is_outwork == true"
          effect="dark"
          type="warning"
          size="mini"
        >
          OUTWORK TASK
        </el-tag>
      </div>

      <br>
      <small>
        {{ job.specs_details }}
      </small>
    </td>

    <td v-show="proposal.approved == 1 || proposal.approved == true">
      <el-checkbox
        v-model="job.billable"
        :disabled="job.mark_as_deleted == 1 || job.completed == 1"
      />
    </td>
    <td
      width="190"
      :style="job.is_custom === true ? 'vertical-align: top;':''"
    >
      <el-input
        v-if="job.is_custom === false"
        v-model.number="job.users_count"
        :disabled="job.mark_as_deleted || job.completed == 1"
        size="mini"
        type="text"
        style="color:#999!important;  min-width: 50px;"
        @change="update_prop('users_count', job.users_count)"
      />

      <UsersCustomJob
        v-else
        :crm_users_list="crm_users_list"
        :already_selected_users="job.crm_users"
        :is_read_only="proposal.approved == 1 ? true : false"
        @input="onUserChangedFromCustomJob"
      />
    </td>
    <td>
      <el-checkbox
        v-model="job.branch_specific"
        :disabled="job.mark_as_deleted == 1 || job.completed == 1 || job.is_custom === true"
      />
    </td>
    <td>
      <el-checkbox
        v-model="job.splittable"
        :disabled="job.mark_as_deleted == 1 || job.completed == 1"
      />
    </td>
    <td>
      <el-checkbox
        v-model="job.is_urgent"
        :disabled="job.mark_as_deleted == 1 || job.completed == 1"
      />
    </td>
    <td v-show="proposal.quote_type_id == 5">
      <el-checkbox
        v-model="job.is_initiated"
        :disabled="job.mark_as_deleted || job.completed == 1"
      />
    </td>

    <td width="145">
      <HoursInput
          v-if="is_hours"
          v-model="job.time_allocated_labour"
          :disabled="job.mark_as_deleted || job.completed == 1"
          :multiline="true"
          :hours_style="'color:#999!important;'"
          @change="update_time_allocated_labour"
      />
      <div v-else>
        <el-col
          :xs="20"
          :sm="20"
          :md="20"
          :lg="20"
          :xl="20"
        >
          <el-input
            v-model.number="job.time_allocated_labour"
            :disabled="job.mark_as_deleted || job.completed == 1"
            size="mini"
            type="text"
            style="color:#999!important;"
            @change="update_time_allocated_labour"
          />
        </el-col>
        <el-col
            :xs="2"
            :sm="2"
            :md="2"
            :lg="2"
            :xl="2"
        >
          min
        </el-col>
      </div>
      <span v-show="job.is_outwork == true">
        <el-row>
          <el-col>
            <small>Counter limit:</small>
            <el-input
                v-model.number="job.counter_limit"
                :disabled="job.mark_as_deleted || job.completed == 1"
                size="mini"
                type="text"
                style="color:#999!important;width: 50px"
                @change="update_prop('counter_limit', job.counter_limit)"
            />
            <el-tooltip
                content="Counter limit refers to number of times the task will be recurred, then it will create follow-up task."
            >
              <i class="el-icon-info" />
            </el-tooltip>
          </el-col>
        </el-row>
      </span>
    </td>

    <td
      v-show="proposal.approved == 1 || proposal.approved == true"
      width="150"
    >
      <HoursInput
        v-if="is_hours"
        v-model="job.time_allocated_adjusted"
        :disabled="job.mark_as_deleted"
        :multiline="true"
        :hours_style="'color:#999!important;'"
        @change="update_time_allocated_adjusted"
      />
      <div v-else>
        <el-input
          v-model.number="job.time_allocated_adjusted"
          size="mini"
          type="text"
          :disabled="job.mark_as_deleted"
          @change="update_time_allocated_adjusted"
        />
      </div>
    </td>

    <td>
      <el-checkbox
        v-model="job.overwrite_labour"
        :disabled="job.mark_as_deleted || job.completed == 1"
      />
    </td>

    <td>${{ job.pay_rate }}</td>
    <td>
      <el-input-number
        v-model="job.sale_price"
        size="mini"
        :step="0.1"
        type="number"
        :disabled="job.completed == 1"
        style="color:#999!important;"
        @change="update_jp_sale_price"
      />
    </td>
    <td
      width="115"
      style="background-color: #f7d6d6"
    >
      ${{ round(job.time_allocated_labour * job.pay_rate) }}
    </td>
    <td
      width="115"
      style="background-color: #dce8ec"
    >
      ${{ round(job.sale_price * job.time_allocated_labour) }}
    </td>
    <td>
      <el-button
        v-show="job.mark_as_deleted == 0 && proposal.completed != 1"
        :disabled="job.completed == 1"
        type="danger"
        icon="el-icon-delete"
        circle
        @click.prevent="remove_job_proposal"
      />
    </td>
  </tr>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { editor_store } from 'store/modules/editor';
import { JobProposalMixin } from 'mixins/JobProposalMixin.js';
import { ProposalMixin } from 'mixins/ProposalMixin.js';
import HoursInput from '../shared/HoursInput';
import UsersCustomJob from '../proposal/summary/task/UsersCustomJob';
import { defaultInput } from '../../utilities/DefaultInput';
import {EventBus} from '../../utilities/EventBus';
import { addUserToCustomJob, removeUserFromCustomJob } from '../../api/job_proposals';

export default {
  name: 'JobProposalEntry',
  store: editor_store,
  components: { HoursInput,UsersCustomJob },
  mixins: [JobProposalMixin, ProposalMixin],
  props: ['job', 'sortable', 'crm_users_list'],
  data() {
    return {
      selected_job: '',
      select_users_ids: this.job.crm_users.map(user => user.id)
    };
  },
  mounted() {
    this.$nextTick(() => { defaultInput(".task__row"); });
  },
  methods: {
    ...mapActions(['load_layers_tree']),
    round(num) {
      return Math.round(num * 100) / 100;
    },
    get_working_file(id) {
      let working_file = this.files_list.filter((item) => {
        return item.id == id;
      })[0];

      if (working_file != undefined) return working_file.name;
      else return '';
    },
    remove_job_proposal() {
      let _this = this;

      this.delete_job_proposal(this.job.id).then(
        (response) => {
          let index = _this.job_proposals_list.indexOf(_this.job);
          if (this['d3'] != undefined) {
            d3.selectAll(`[job-id="${this.job.id}"]`).remove();
            this.load_layers_tree();
            this.set_file_changed(true);
          }
          if (_this.proposal.approved == 1) {
            _this.job.mark_as_deleted = 1;
            // Update jobs time frame once task has been marked as deleted
            this.update_proposal_time_frame(_this.proposal.id);
          } else {
            _this.job_proposals_list.splice(index, 1);
            this.$message({
              message: 'Task has been deleted successfully.',
              type: 'success',
            });
          }
          EventBus.$emit('reloadCategoryWorkingFiles');
        },
        (reason) => {
          console.log(reason);
          this.$message({
            message: 'Error occured!',
            type: 'error',
          });
        }
      );
    },
    update_layer_name(e) {
      if(this.job.is_custom === true && e === '') {
        this.$message({
          message: 'Custom task name cannot be empty',
          type: 'error',
        });
        return;
      }
      this.update_prop('layer_name', e);
    },
    update_jp_sale_price(e) {
      this.update_prop('sale_price', e);
    },
    update_time_allocated_labour(e) {
      this.update_prop('time_allocated_labour', e);
    },
    update_time_allocated_adjusted(e) {
      this.update_prop('time_allocated_adjusted', e);
    },
    update_prop(prop, value) {
      let params = {};
      params[prop] = value;
      this.$http
        .post('/update_task_attribute/' + this.job.id, {
          task_attribute: params,
        })
        .then(
          (response) => {
            this.$message({
              message: 'Updated successfully.',
              type: 'success',
            });
            EventBus.$emit('reloadCategoryWorkingFiles');
          },
          function(response) {
            this.$message({
              message: 'Error happened while retreiving the file.',
              type: 'error',
            });
          }
        );
    },
    onUserChangedFromCustomJob(new_selected_users_ids) {
      if(new_selected_users_ids.length >  this.select_users_ids.length) {
        let addedUserId = new_selected_users_ids.slice(-1)[0];
        addUserToCustomJob(this, this.job.id, addedUserId).then(({data}) => {
          this.$message({
            message: data.message,
            type: 'success',
          });
        });
      }
      else {
        let removedUserId = this.select_users_ids.find(x => new_selected_users_ids.indexOf(x) === -1);
         removeUserFromCustomJob(this, this.job.id, removedUserId).then( ({data}) => {
           this.$message({
             message: data.message,
             type: 'success',
           });
        });
      }
      this.select_users_ids = new_selected_users_ids;
    },
  },
  computed: {
    ...mapGetters(['proposal', 'files_list', 'job_proposals_list', 'is_hours']),
  },
  watch: {
    'job.billable': function(newVal) {
      this.update_prop('billable', newVal);
    },
    'job.is_urgent': function(newVal) {
      this.update_prop('is_urgent', newVal);
    },
    'job.branch_specific': function(newVal) {
      this.update_prop('branch_specific', newVal);
    },
    'job.splittable': function(newVal) {
      this.update_prop('splittable', newVal);
    },

    // "job.users_count": function(newVal) {
    //   this.update_prop("users_count", newVal);
    // },
    'job.is_initiated': function(newVal) {
      this.update_prop('is_initiated', newVal);
    },
    'job.overwrite_labour': function(newVal) {
      this.update_prop('overwrite_labour', newVal);
    },
  },
};
</script>

<style scoped>
.task_container {
  display: grid;
  grid-template-columns: auto 1fr;
}

.user_list {
  margin:2px;
}
</style>
