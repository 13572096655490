<template>
  <div>
    <el-row :glutter="20">
      <el-col :span="11">
        <h3>
          SELECTED OBJECTS
          <span class="right">
            <el-radio-group v-model="layup_split_alignment">
              <el-radio label="left_top">Left/top</el-radio>
              <el-radio label="center">Center</el-radio>
            </el-radio-group>
          </span>
          <hr />
        </h3>
        <ul>
          <li v-for="obj in layup_selected_objects" :key="obj.id">
            <ObjectLayup :data="obj"></ObjectLayup>
          </li>
        </ul>
      </el-col>
      <el-col :span="1">
        <div>&nbsp;</div>
      </el-col>
      <el-col :span="12">
        <h3>
          SELECTED STOCK
          <hr />
        </h3>
        <div>
          <el-row>
            <el-col :span="7">
              <el-radio v-model="stock_layup_type" label="new"
                >New Stock layer</el-radio
              >
            </el-col>
            <el-col :span="17">
              <el-select
                size="mini"
                v-show="stock_layup_type == 'new'"
                v-model="layup_new_stock_layer"
                filterable
                remote
                style="width: 80%"
                reserve-keyword
                placeholder="Please enter a keyword"
                :remote-method="remoteMethod"
                :loading="loading"
                @change="update_stock_specs"
              >
                <el-option
                  v-for="item in stock_items_results"
                  :key="item.id"
                  :label="item.name_with_specs"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="7">
              <el-radio v-model="stock_layup_type" label="existing"
                >Existing stock layer</el-radio
              >
            </el-col>
            <el-col :span="17">
              <div>
                <el-select
                  placeholder="Select"
                  size="mini"
                  v-model="existing_layup_stock"
                  v-show="stock_layup_type == 'existing'"
                  style="width: 80%"
                  @change="update_stock_specs"
                >
                  <el-option
                    v-for="item in layup_stocks"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              Width:
              <el-input v-model="stock_width" size="mini" style="width: 150px;">
                <template slot="append">mm </template>
              </el-input>
            </el-col>
            <el-col :span="3"
              ><el-button
                icon="el-icon-refresh"
                @click="swap_stock_specs"
                size="small"
              >
              </el-button
            ></el-col>
            <el-col :span="10">
              Height:
              <el-input
                v-model="stock_height"
                size="mini"
                style="width: 150px;"
              >
                <template slot="append">
                  mm
                </template>
              </el-input>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              Bleed:
              <el-input
                v-model.number="page_margin"
                size="mini"
                style="width: 120px;"
              >
                <template slot="append">
                  mm
                </template>
              </el-input>
            </el-col>
            <el-col :span="8">
              Spacing:
              <el-input
                v-model.number="layup_spacing"
                size="mini"
                style="width: 120px;"
              >
                <template slot="append">
                  mm
                </template>
              </el-input>
            </el-col>
            <el-col :span="4"
              ><el-button @click="calculate_qty" class="right" size="small">
                Calculate
              </el-button></el-col
            >
            <el-col :span="4"
              ><el-button @click="export_result" class="right" size="small">
                Export
              </el-button></el-col
            >
          </el-row>
        </div>
      </el-col>
    </el-row>
    <el-row :glutter="20">
      <el-col :span="24">
        <div class="area_layup_cutting"></div>
        <div class="linear_layup_cutting"></div>
        <!-- <el-tabs type="border-card">
          <el-tab-pane
            v-for="obj in objects_data"
            :label="obj.name"
            :key="obj.id"
          >
            <div class="layup_cutting" :id="`${obj.id}`"></div>
          </el-tab-pane>
        </el-tabs> -->
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ObjectLayup from "../ObjectLayup.vue";
import { WorkspaceMixin } from "mixins/WorkspaceMixin.js";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { editor_store, mm_to_px } from "store/modules/editor";
import SheetCalculation from "utilities/SheetsCalculation";

export default {
  name: "LayupPanel",
  props: ["refresh", "attach_events"],
  components: { ObjectLayup },
  mixins: [WorkspaceMixin],
  store: editor_store,
  data() {
    return {
      layup_split_alignment: "left_top",
      objects_data: [],
      layers_data: [],
      stock_width: 0,
      stock_height: 0,
      layup_spacing: 0,
      page_margin: 0,
      loading: false,
      layup_new_stock_layer: "",
      stock_layup_type: "new",
      layup_selected_objects: [],
      stock_items_results: [],
      layup_stocks: [],
      existing_layup_stock: "",
    };
  },
  mounted() {
    this.initialize();
  },
  methods: {
    ...mapActions(["load_layers_tree"]),
    update_stock_specs() {
      let stock = { wdith: 0, height: 0 };
      if (this.stock_layup_type == "new") {
        stock = this.stock_items_results.filter(
          (c) => c.id == this.layup_new_stock_layer
        )[0];
      } else {
        stock = this.layup_stocks.filter(
          (c, idx) => idx == this.existing_layup_stock
        )[0];
      }
      this.stock_width = stock.width;
      this.stock_height = stock.height;
    },
    swap_stock_specs() {
      let tmp = this.stock_height;
      this.stock_height = this.stock_width;
      this.stock_width = tmp;
    },
    draw_linear_layup(checked_linear_objects, total_length) {
      let cuttings_container = d3.select(`.linear_layup_cutting`);
      cuttings_container.attr("total_length", total_length);

      checked_linear_objects.forEach((item, idx) => {
        let cutting_id = `linear_layup_cutting_${idx}`;
        let el = item.obj.el;
        cuttings_container
          .append("div")
          .classed("col s4", true)
          .append("svg")
          .attr("id", cutting_id)
          .classed("cuttings", true);
        // .attr("preserveAspectRatio", "xMinYMin meet")
        let grp = d3
          .select(`#${cutting_id}`)
          .append("g")
          .classed("linear_layup_child", true)
          .attr("data-qty", item.obj.qty)
          .attr("data-length", item.details.length_in_mm)
          .attr("data-total-length", item.obj.qty * item.details.length_in_mm);
        let width = el.getBBox().width + 20;
        let height = (el.getBBox().height + 20) * item.obj.qty;
        // svg.attr("viewBox", "0 0 " + width + " " + height);

        // let cloned = obj.el.cloneNode(true);
        let h = el.getBBox().height + 20;
        for (var i = 0; i < item.obj.qty; i++) {
          let cloned = el.cloneNode(true);
          cloned.setAttribute("id", null);
          cloned.setAttribute(
            "transform",
            `translate(0 ${h })`
          );
          grp.node().appendChild(cloned);
          h += el.getBBox().height + 20;
          //cloned.setTranslate()
        }

        // svg
        //   .insert("g")
        //   .attr("", )
        //   .attr("", );
      });

      // cuttings_container
      //     .append("div")
      //     .classed("col s4", true)
      //     .append("svg")
      //     .attr("id", cutting_id)
      //     .attr("preserveAspectRatio", "xMinYMin meet")
      //     .classed("cuttings", true);

      //   let svg = d3.select(`#${cutting_id}`);
      //   svg.attr(
      //     "viewBox",
      //     "0 0 " + this.stock_width + " " + this.stock_height
      //   );
    },
    draw_area_layup(results) {
      let _this = this;
      //d3.selectAll(`[data-nesting-id='${stock_id}'] div`).remove();
      var x;
      let sheet_num = 1;
      for (x = 0; x < results.sheets.length; x++) {
        let cutting_id = `cuttings_${x}`;
        let cuttings_container = d3.select(`.area_layup_cutting`);

        cuttings_container
          .append("div")
          .classed("col s4", true)
          .append("svg")
          .attr("id", cutting_id)
          .attr("preserveAspectRatio", "xMinYMin meet")
          .classed("cuttings", true);

        let svg = d3.select(`#${cutting_id}`);
        svg.attr(
          "viewBox",
          "0 0 " + this.stock_width + " " + this.stock_height
        );
        let b;
        let sheet_blocks = results.sheets[x];
        for (b = 0; b < sheet_blocks.length; b++) {
          let block = sheet_blocks[b];

          svg
            .insert("g")
            .append("rect")
            .attr("layup-ref-id", block.el.ref_id)
            .attr("fill", "lightsteelblue")
            .attr("stroke", "red")
            .attr(
              "x",
              block.fit.x +
                parseFloat(this.page_margin) +
                parseFloat(this.layup_spacing)
            )
            .attr(
              "y",
              block.fit.y +
                parseFloat(this.page_margin) +
                +parseFloat(this.layup_spacing)
            )
            .attr("width", block.w - 2 * +parseFloat(this.layup_spacing))
            .attr("height", block.h - 2 * +parseFloat(this.layup_spacing));

          let font_size = Math.min(block.w, block.h) / 3;
          // svg
          //   .insert("g")
          //   .append("text")
          //   .attr("font-size", 12)
          //   .attr("x", block.fit.x + 3 + parseFloat(this.page_margin))
          //   .attr("y", block.fit.y + font_size + parseFloat(this.page_margin))
          //   .attr("fill", "black")
          //   .text(`${block.id}`);

          svg
            .insert("g")
            .classed("placeholder_label", true)
            .append("text")
            .attr("font-size", font_size / 5)
            .attr("x", block.fit.x + parseFloat(this.page_margin) + 3)
            .attr("y", block.fit.y + parseFloat(this.page_margin) + block.h / 2)
            .attr("fill", "black")
            .text(`${block.id} `);
          sheet_num++;
        }
      }
    },
    get_uid(el) {
      if (el.getAttribute("id") != undefined) return el.getAttribute("id");

      let node_type = el.nodeName;
      let i = 1;

      while (d3.select(`#${node_type}_${i}`).node() != null && i < 100) {
        i++;
      }
      let uid = `${node_type}_${i}`;
      el.setAttribute("id", uid);
      return uid;
    },
    initialize() {
      let _this = this;
      this.layup_selected_objects = [];
      this.layup_stocks = [];
      d3.selectAll(".svg_layer")
        .nodes()
        .forEach((el, idx) => {
          let width = 0;
          let height = 0;
          if (
            el.getAttribute("proposal-stock-width") == undefined ||
            el.getAttribute("proposal-stock-height") == undefined
          ) {
            let stock = this.stock_items_list.filter(
              (c) => c.id == el.getAttribute("proposal-stock-id")
            )[0];
            if (stock != undefined) {
              width = stock.width;
              height = stock.height;
            }
          } else {
            width = el.getAttribute("proposal-stock-width");
            height = el.getAttribute("proposal-stock-height");
          }

          this.layup_stocks.push({
            id: idx,
            name: el.getAttribute("data-name"),
            stock_id: el.getAttribute("proposal-stock-id"),
            qty: el.getAttribute("stock-quantity"),
            height: height,
            width: width,
          });
        });

      d3.selectAll("[data-selected=true]")
        .nodes()
        .forEach((el, idx) => {
          let relative_boundary = this.get_relative_boundaries(el);
          // assign ID to current object if no ID
          this.layup_selected_objects.push({
            id: this.get_uid(el),
            el: el,
            node: el.nodeName,
            width: Math.round(
              relative_boundary.width / (3.779527559 * this.page_scale)
            ),
            height: Math.round(
              relative_boundary.height / (3.779527559 * this.page_scale)
            ),
            qty: 0,
            checked: false,
          });
        });
    },
    remoteMethod(query) {
      let _this = this;
      if (query !== "" && query.trim().length > 4) {
        let filter = { term: query };
        setTimeout(() => {
          this.$http
            .post("/search_stocks_by_params", filter)
            .then((response) => {
              _this.stock_items_results = response.body.data;
            });
        }, 200);
      } else {
        this.stock_items_results = [];
      }
    },
    calculate_sheets(rect_arr) {
      var sheet_calc = new SheetsCalculation({
        w: this.stock_width - 2 * parseFloat(this.page_margin),
        h: this.stock_height - 2 * parseFloat(this.page_margin),
        blocks: rect_arr,
        num: 1,
      });

      let results = sheet_calc.run();
      return results;
    },
    calculate_qty() {
      this.objects_data = [];
      d3.selectAll(".area_layup_cutting").html("");
      d3.selectAll(".linear_layup_cutting").html("");

      let checked_objects = this.layup_selected_objects.filter(
        (c) => c.checked == true && c.qty > 0
      );
      let errors = [];
      if (this.stock_layup_type == "new") {
        if (this.layup_new_stock_layer == "")
          errors.push("Select a new stock!");
      } else {
        if (this.existing_layup_stock === "")
          errors.push("Select a stock from the list.");
      }
      if (checked_objects.length == 0) {
        errors.push("No selected object or no quantity inserted.");
      }
      if (this.stock_width == 0 && this.stock_height == 0) {
        errors.push("Width & Height should be greater than 0.");
      }
      if (errors.length > 0) {
        this.$message({
          message: errors.join("<br>"),
          dangerouslyUseHTMLString: true,
          type: "warning",
        });
        return;
      }
      // calculated area shapes
      this.caculate_area_shapes(checked_objects);

      // calculated linear shapes
      this.caculate_linear_shapes(checked_objects);
    },
    caculate_area_shapes(checked_objects) {
      let rect = {};
      let rect_arr = [];
      // Loop through selected object to calculate how many stock items needed
      checked_objects
        .filter((c) => c.node != "polyline")
        .forEach((el, idx) => {
          let rects = this.get_checked_obj_rects(el);
          for (let index = 0; index < el.qty; index++) {
            rects.forEach((el_child, r_idx) => {
              rect = {
                ref_id: `${el.id}_${r_idx}`,
                x: 0,
                y: 0,
                w: el_child.width,
                h: el_child.height,
                id: `${el.node}#${idx}_${r_idx}_id#${index}:${Math.round(
                  el_child.width
                )}x${Math.round(el_child.height)} ${
                  el_child.rotated == true ? "Rotated" : ""
                }`,
              };
              rect_arr.push(rect);
            });
          }
        });
      if (rect_arr.length == 0) return;
      let result = this.calculate_sheets(rect_arr);
      // this.objects_data.push({
      //   id: `layup_cutting_${idx}`,
      //   name: `Layer#${el.node}_Id#${idx}`,
      //   result: result
      // });
      this.draw_area_layup(result);
    },
    caculate_linear_shapes(checked_objects) {
      let linear_length = [];
      let total_length = 0;

      checked_objects
        .filter((c) => c.node == "polyline")
        .forEach((obj, idx) => {
          let line_details = this.get_linear_details(obj.el);
          let line_total = line_details.length_in_mm * obj.qty;
          linear_length.push({ obj: obj, details: line_details, qty: obj.qty });
          total_length += line_total;
        });
      this.draw_linear_layup(linear_length, total_length);
    },
    get_checked_obj_rects(el) {
      let full_width = el.width + 2 * parseFloat(this.layup_spacing);
      let full_height = el.height + 2 * parseFloat(this.layup_spacing);
      let stock_width = this.stock_width - 2 * parseFloat(this.page_margin);
      let stock_height = this.stock_height - 2 * parseFloat(this.page_margin);
      let rects = [];
      // Scenario #1: object can fit inside stock
      if (full_width <= stock_width && full_height <= stock_height)
        rects.push({ width: full_width, height: full_height, rotated: false });
      else if (
        // Scenario #2: object can fit inside stock but rotated
        (full_width <= stock_height && full_height <= stock_width) ||
        (full_width <= stock_height && full_height <= stock_width)
      )
        rects.push({ width: full_height, height: full_width, rotated: true });
      else {
        // Scenario #3: object cannot fit
        // Scenario #3-A, rotate the object if it fits partially, to avoid
        // creating complete working area with lots of waste
        let rotated = false;
        if (full_width <= stock_height || full_height <= stock_width) {
          let tmp_width = full_width;
          full_width = full_height;
          full_height = tmp_width;
          rotated = true;
        }
        // let qty =
        //   Math.ceil(full_width / stock_width) +
        //   Math.ceil(full_height / stock_height);
        // let start_width = 0;
        // let max_width = this.layup_split_alignment == "left_top" ? this.stock_width : full_width / Math.ceil(full_width / this.stock_width);
        // let max_height = this.layup_split_alignment == "left_top" ? this.stock_height : full_height / Math.ceil(full_height / this.stock_height);

        // Center (even distribution)
        if (this.layup_split_alignment.toLowerCase() == "center") {
          let factor = full_width / stock_width;
          factor = factor / Math.ceil(factor);
          stock_width = stock_width * factor;

          factor = full_height / stock_height;
          factor = factor / Math.ceil(factor);
          stock_height = stock_height * factor;
        }

        for (var x = 0; x < full_width; x = x + stock_width) {
          for (var y = 0; y < full_height; y = y + stock_height) {
            let width = stock_width;
            let height = stock_height;

            if (width + x > full_width) {
              width = full_width - x;
            }

            if (height + y > full_height) {
              height = full_height - y;
            }
            rects.push({ width: width, height: height, rotated: rotated });
          }
        }
        // for (var w = full_width; w  > 0; w -= max_width) {
        //   let new_w = full_width - max_width;
        //   for (var h = full_height; h  > 0; h -= max_height) {
        //     let new_h = full_height - max_height;
        //     rects.push({ width: new_w, height: new_h, rotated: rotated });
        //   }
        // }

        // for (var w = 0; w < Math.ceil(full_width / stock_width); w++) {
        // for (var w = 0; w < Math.ceil(full_width / stock_width); w++) {
        //   let start_height = 0;
        //   for (var h = 0; h < Math.ceil(full_height / stock_height); h++) {}
        //   if(this.layup_split_alignment = )
        // }
        // layup_split_alignment
      }
      return rects;
    },
    export_result() {
      let height = 0;
      let new_svg = d3.select("svg .svg_inner_container").node();

      let linear_nodes = d3.selectAll(".linear_layup_cutting svg").nodes();
      if (linear_nodes.length > 0) {
        let linear_layer = d3
          .select(new_svg)
          .select(".layers")
          .append("g")
          .attr("class", "svg_layer")
          .attr("data-name", "linear layup layer");
        let grouped_obj = linear_layer.append("g").classed("grouped", true);
        // update element id
        this.get_element_id(grouped_obj.node());
        linear_nodes.forEach((el, idx) => {
          el.setAttribute("x", el.getAttribute("x") / this.page_scale);
          el.setAttribute("y", el.getAttribute("y") / this.page_scale);
          el.setAttribute("width", el.getAttribute("width") / this.page_scale);
          el.setAttribute(
            "height",
            el.getAttribute("height") / this.page_scale
          );

          let g = grouped_obj.append("g");
          g.node().appendChild(
            d3
              .select(el)
              .select("g")
              .node()
          );
        });
        linear_layer.selectAll("*:not(g)").classed("grouped", true);
        let total_length = grouped_obj
          .selectAll(".linear_layup_child")
          .nodes()
          .reduce(function(total, el) {
            return total + parseInt(el.getAttribute("data-total-length"));
          }, 0);

        grouped_obj
          .attr("data-total-length", total_length)
          .classed("linear_layup_parent", true);
        this.attach_events(grouped_obj);
      }

      let area_nodes = d3.selectAll(".area_layup_cutting svg").nodes();
      if (area_nodes.length > 0) {
        let new_layer = d3
          .select(new_svg)
          .select(".layers")
          .append("g")
          .attr("class", "svg_layer")
          .attr("data-name", "Layup layer");
        let grouped_obj = new_layer.append("g").classed("grouped", true);
        area_nodes.forEach((el, idx) => {
          d3.select(el)
            .selectAll("rect,img")
            .nodes()
            .forEach((ch, id) => {
              let g = grouped_obj.append("g");
              if (idx > 0) {
                g.attr("transform", `translate(0,${height/this.page_scale})`);
              }
              g.node().appendChild(this.format_child_node(ch));
              // new g with translate of deltaY
            });
          height += this.stock_height * 3.779527559 * this.page_scale;
        });
        new_layer.selectAll("*:not(g)").classed("grouped", true);
        this.attach_events(grouped_obj);
      }
      d3.selectAll(".area_layup_cutting").html("");
      d3.selectAll(".linear_layup_cutting").html("");

      this.$message({ message: "Layed up successfully.", type: "success" });
      this.load_layers_tree();
    },
    format_child_node(node) {
      // convert from mm to px
      let chld_node = node.cloneNode(true);
      let val = 3.779527559 * this.page_scale;
      chld_node.setAttribute(
        "x",
        parseFloat(chld_node.getAttribute("x")) * val
      );
      chld_node.setAttribute(
        "y",
        parseFloat(chld_node.getAttribute("y")) * val
      );

      if (chld_node.hasAttribute("width")) {
        chld_node.setAttribute(
          "width",
          parseFloat(chld_node.getAttribute("width")) * val
        );
        chld_node.setAttribute(
          "height",
          parseFloat(chld_node.getAttribute("height")) * val
        );
      }

      chld_node.setAttribute(
        "x",
        chld_node.getAttribute("x") / this.page_scale
      );
      chld_node.setAttribute(
        "y",
        chld_node.getAttribute("y") / this.page_scale
      );
      chld_node.setAttribute(
        "width",
        chld_node.getAttribute("width") / this.page_scale
      );
      chld_node.setAttribute(
        "height",
        chld_node.getAttribute("height") / this.page_scale
      );

      return chld_node;
    },
  },

  computed: {
    ...mapGetters(["page_scale", "stock_items_list", "page_scale"]),
  },
  watch: {
    stock_layup_type: function(newVal) {
      this.update_stock_specs();
    },
    refresh: function(newVal) {
      if (newVal == true) {
        this.initialize();
      }
    },
  },
};
</script>

<style>
.el-row {
  margin-bottom: 5px;
}
</style>
