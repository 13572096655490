<template>
  <div>
    <el-radio-group
      v-show="false"
      v-model="labelPosition"
      size="small"
    >
      <el-radio-button label="left">
        Left
      </el-radio-button>
      <el-radio-button label="right">
        Right
      </el-radio-button>
      <el-radio-button label="top">
        Top
      </el-radio-button>
    </el-radio-group>

    <el-form
      ref="organization_details"
      v-loading="loading"
      :model="organization_details"
      :label-position="labelPosition"
      label-width="100px"
      :rules="rules"
    >
      <el-row :glutter="20">
        <el-col :span="10">
          <el-row>
            <h3>
              <b>CONTACT DETAILS</b>
              <hr>
            </h3>
          </el-row>
          <el-row>
            <el-col :span="18">
              <el-form-item
                label="Name"
                prop="name"
                required
              >
                <el-input
                  v-model="organization_details.name"
                  size="mini"
                  autocomplete="off"
                />
              </el-form-item>
              <el-form-item
                label="Email"
                prop="email"
                required
              >
                <el-input
                  v-model="organization_details.email"
                  size="mini"
                  autocomplete="off"
                />
              </el-form-item>
              <el-form-item
                label="Phone"
                prop="phone"
              >
                <el-input
                  v-model="organization_details.phone"
                  size="mini"
                  autocomplete="off"
                />
              </el-form-item>
              <el-form-item
                label="Address"
                prop="address"
              >
                <el-input
                  v-model="organization_details.address"
                  size="mini"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="2">
          <el-row>
            <h3><b /></h3>
          </el-row>
        </el-col>
        <el-col :span="10">
          <el-row>
            <h3>
              <b>EMPLOYER BUSINESS DETAILS</b>
              <hr>
            </h3>
          </el-row>
          <el-row>
            <el-col :span="16">
              <el-form-item
                label="IRD"
                prop="ird"
                label-width="70px"
                required
              >
                <el-input
                  v-model="organization_details.ird"
                  size="mini"
                  placeholder="###-####-###"
                  autocomplete="off"
                />
              </el-form-item>
              <el-form-item
                label="GST"
                prop="gst"
                placeholder="###-####-###"
                required
                label-width="70px"
              >
                <el-input
                  v-model="organization_details.gst"
                  size="mini"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <h3>
              <b>Adobe API Credentials</b>
              <hr>
            </h3>
          </el-row>
          <el-row>
            <el-col :span="16">

              <el-form-item
                  label="Key"
                  prop="adobe_api_key"
                  label-width="70px"

              >
                <el-input
                    v-model="organization_details.adobe_api_key"
                    size="mini"
                    autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>

      </el-row>
      <el-row :glutter="20">
        <el-col :span="24">
          <el-row>
            <h3>
              <b>PAYMENT DETAILS</b>
              <hr>
            </h3>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="Pay Cycle"
                prop="pay_cycle_start_date"
                required
              >
                <el-date-picker
                  v-model="organization_details.pay_cycle_start_date"
                  width="100"
                  size="mini"
                  type="date"
                  placeholder="Pick a date"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="Pay Frequency"
                label-width="120px"
                required
              >
                <el-select
                  v-model="organization_details.pay_frequency_id"
                  size="mini"
                  placeholder="please select your zone"
                >
                  <el-option
                    v-for="item in pay_frequencies"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item
                  label-width="180px"
                  label="Pay As You Go Percent"
                  prop="pay_as_you_go_percent"
                  required
              >
                <el-input
                    v-model="organization_details.pay_as_you_go_percent"
                    size="mini"
                    type="number"
                />
              </el-form-item>
            </el-col>
            </el-row>
          <el-row>
            <el-col :span="24">
              <BankAccounts
                bank_accountable_id="organization_details.id"
                bank_accountable_type="Organization"
              />
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <br>
      <br>
      <el-row>
        <el-col :span="24">
          <hr>
          <el-form-item>
            <el-button
              class="right"
              type="primary"
              @click="submit('organization_details')"
            >
              Submit
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import BankAccounts from '../shared/BankAccounts';
export default {
  name: 'OrganizationDetails',
  components: { BankAccounts },
  data() {
    return {
      organization_details: { id: '', name: '' },
      pay_frequencies: [],
      labelPosition: 'right',
      loading: false,
      rules: {
        ird: [
          {
            required: true,
            pattern: /\d{3}-\d{3}-\d{3}$/g,
            message: 'Please input ird (###-####-###).',
            trigger: 'blur',
          },
        ],
        gst: [
          {
            required: true,
            pattern: /\d{3}-\d{3}-\d{3}$/g,
            message: 'Please input gst number (###-####-###).',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  mounted() {
    this.fill_pay_frequencies();
    this.fill_data();
    this.set_default_input();
  },
  methods: {
    set_default_input(mills = 300) {
      let _this = this;
      setTimeout(function() {
        Array.from(document.querySelectorAll('.el-input__inner')).forEach(
          function(e, i) {
            e.classList.add('browser-default');
            if (e.querySelector('input') != null)
              e.querySelector('input').className += ' browser-default';
          }
        );
      }, mills);
    },
    fill_pay_frequencies() {
      let _this = this;
      this.$http.get('/pay_frequencies').then((response) => {
        _this.pay_frequencies = response.data;
      });
    },
    fill_data() {
      let _this = this;
      this.$http.get('/organization_details').then((response) => {
        _this.organization_details = response.data;
      });
    },
    submit(form_name) {
      let _this = this;

      this.$refs[form_name].validate((valid) => {
        if (valid) {
          _this.loading = true;
          this.$http
            .patch('/organizations/' + _this.organization_details.id, {
              organization: _this.organization_details,
            })
            .then(
              (response) => {
                _this.loading = false;
                if (response.data.status == 200) {
                  this.$message({
                    message: 'Record has been saved.',
                    type: 'success',
                  });
                } else {
                  _this.$message.error('Oops, this is a error message.');
                }
              },
              function(response) {
                _this.loading = false;
                _this.$message.error('Oops, this is a error message.');
              }
            );
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.el-input__inner {
  -webkit-appearance: none !important;
  background-color: #fff !important;
  background-image: none !important;
  border-radius: 4px !important;
  border: 1px solid #dcdfe6 !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  color: #606266 !important;
  display: inline-block !important;
  font-size: inherit !important;
  height: 40px !important;
  line-height: 40px !important;
  outline: 0 !important;
  padding: 0 15px !important;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  width: 100% !important;
}
</style>
