<template>
  <div>
    <div class="card  ">
      <div class="card-content  ">
        <span class="card-title">
          User(s) Timesheet
        </span>

        <el-date-picker
          v-model="selected_date_range"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          style="width: auto !important;"
          @change="fill_data"
        />
        <br>
        <div class="users_list_container">
          <table class="responsive-table striped">
            <thead>
              <tr>
                <th>Day</th>
                <th>Username</th>
                <th>Check-in</th>
                <th>Check-out</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="user in users_data"
                @click="show_time_sheet_record(user)"
              >
                <td>{{ user.created_at }}</td>
                <td>{{ user.user_name }}</td>
                <td>{{ user.check_in }}</td>
                <td>{{ user.check_out }}</td>
                <td>{{ user.reason }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <modal
      name="timesheet_user_info"
      :height="250"
      :width="800"
      :adaptive="true"
    >
      <div class="card ">
        <div class="card-content  ">
          <span class="card-title">{{ time_sheet_title }}<hr></span>
          <el-time-select
            v-model="check_in_time"
            :picker-options="time_options"
            placeholder="Check-in time"
          />
          <el-time-select
            v-model="check_out_time"
            :picker-options="time_options"
            placeholder="Check-out time"
          />
          <!-- <input v-model.number="break_duration" type="number" style="width:100px"> -->
        </div>
        <div class="card-action">
          <div style="display: flow-root;">
            <button
              class="left btn "
              @click="hide_modal"
            >
              Cancel
            </button>
            <button
              class="right btn "
              @click="save_time_record"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Toast from '../inline/Toast';
export default {
  name: 'TimesheetDashboard',
  data() {
    return {
      selected_date: Date.now(),
      selected_date_range: '',
      users_data: [],
      time_options: {
        start: '05:00',
        step: '00:01',
        end: '23:59'
      },
      time_sheet_title: '',
      time_sheet_record_id: '',
      selected_user: '',
      selected_date: '',
      check_in_time: '',
      check_out_time: ''
      // break_duration: ''

    };
  },
  computed: {
    current_date() {
      return new Date();
    }
  },
  mounted() {
    this.selected_date_range = [this.current_date, this.current_date];
    this.fill_data();
  },
  methods: {
    fill_data() {
      let self = this;
      this.$http.post('/get_users_timesheet', {
        start_date: this.selected_date_range[0],
        end_date: this.selected_date_range[1]
      }).then(response => {
        if (response.data.status == 200) {

          this.users_data = response.data.data;

        }
      }
      );

    },
    open_modal() {
      this.$modal.show('timesheet_user_info');
    },
    hide_modal() {
      this.$modal.hide('timesheet_user_info');
      this.clear_timesheet_modal();
    },
    show_time_sheet_record(user) {

      this.open_modal();
      this.time_sheet_title = user.user_name;
      this.selected_user = user.crm_user_id;
      this.time_sheet_record_id = user.id;
      this.selected_date = user.created_at;
      this.check_in_time = user.check_in;
      this.check_out_time = user.check_out;
      // this.break_duration = user.break
    },
    save_time_record() {
      let self = this;
      this.$http.post('/update_user_time_sheet', {

        id: this.time_sheet_record_id,
        created_at: this.selected_date,
        crm_user_id: this.selected_user,
        check_in: this.check_in_time,
        check_out: this.check_out_time
        // break: this.break_duration
      }).then(response => {
        if (response.data.status == 200) {
          Materialize.toast('Updated successfully', 2000, 'green');
          this.hide_modal();
          this.fill_data();
        }
      }
      );
    },
    clear_timesheet_modal() {
      this.time_sheet_title = '';
      this.selected_user = '';
      this.selected_date = '';
      this.check_in_time = '';
      this.check_out_time = '';
    }
  }
};
</script>

<style scoped>
    .users_list_container {
        max-height: 400px;
        overflow: auto;
    }

    tr {
        cursor: pointer;
    }
</style>