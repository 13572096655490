import {Request} from '../utilities/Request';

export function update_entry(this_obj, proposal_id, entry) {
  return Request.patch(
    this_obj,
    `/api/comments/${proposal_id}/update_entry`,
    null,
    {entry: entry}
  );
}

export function entries(this_obj, proposal_id) {
  return Request.post(
    this_obj,
    '/api/comments/entries',
    null,
    {proposal_id: proposal_id}
  );
}

export function add_comment(this_obj, entry, proposal_id) {
  return Request.post(
    this_obj,
    '/api/comments/add_comment',
    null,
    {entry: entry, proposal_id: proposal_id}
  );
}