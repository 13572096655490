<template>
  <div>
    <div class="card  ">
      <div class="card-content  ">
        <span class="card-title">
          Workspace summary
        </span>


        <div class="row">
          <div class="col m12 dashboard">
            <div
              ref="messageDisplay"
              style="height: 300px !important;overflow-y: auto !important; "
            >
              <ul>
                <li
                  v-for="record in summary_list"
                  class="message_container all requests notifications"
                >
                  <a :href="record.link">
                    <span class="subject">
                      <span class="from">{{ record.title }}</span>
                      <span class="time">{{ record.time }}</span>
                    </span>
                    <span class="message">
                      <span class="line_one">{{ record.message_line_1 }}</span>
                      <br>
                      <span class="line_two">{{ record.message_line_2 }}</span>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <a
              v-show="show_more"
              class="btn btn-small right"
              @click="fill_data"
            >Show more</a>
          </div>
        </div>
      </div>
      <div class="card-action">
        <button
          class="btn btn-small"
          @click="show_records_list"
        >
          Show details
        </button>
      </div>
    </div>

    <modal
      name="workspace_list"
      :height="700"
      :width="1200"
      :adaptive="true"
    >
      <div class="card ">
        <div class="card-content  ">
          <span class="card-title">Workspace list<hr></span>
          <div
            v-show="is_loading"
            class="progress"
          >
            <div class="indeterminate" />
          </div>
          <el-table
            :data="rows"
            style="width: 100%"
            height="600"
          >
            <el-table-column
              v-for="column in columns"
              :key="column"
              :prop="column"
              :label="column | capitalize"
              sortable
            />
          </el-table>
        </div>
        <div class="card-action" />
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: 'WorkspacesDashboard',
  data() {
    return {
      summary_list: [],
      start_index: 0,
      limit: 10,
      show_more: false,
      is_loading: false,
      rows: [],
      columns: []
    };
  },
  mounted() {
    this.fill_data();
  },
  created() {
  },
  methods: {
    fill_data() {
      let self = this;
      this.$http.post('/get_workspace_summary', {
        type: this.type,
        start_index: this.start_index,
        limit: this.limit
      }).then(response => {
        if (response.data.status == 200) {
          if (response.data.data.length > 0) {
            self.start_index += response.data.data.length;
            self.summary_list.push(...response.data.data);
            self.show_more = true;
          }
          else {
            this.show_more = false;
          }
        }
      }
      );

    },
    open_modal() {
      this.$modal.show('workspace_list');
    },
    hide_modal() {
      this.$modal.hide('workspace_list');
    },
    show_records_list() {
      this.open_modal();
      this.columns = [];
      this.rows = [];
      this.is_loading = true;
      this.$http.post('/get_workspace_list', {}).then(response => {
        this.is_loading = false;
        if (response.data.status == 200) {
          this.columns = response.data.data.columns;
          this.rows = response.data.data.rows;
        }
      });
    }
  }
};
</script>

<style scoped>
    td, th {
        text-align: center;
        padding: 10px;
    }

    th {
        font-weight: 800;
    }

    .col.m12.dashboard {
        height: 300px;
    }
</style>