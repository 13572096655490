<template>
  <el-card class="box-card">
    <ImportMasterPayments />
  </el-card>
</template>

<script>
import ImportMasterPayments from './ImportMasterPayments';

export default {
  name: 'AccountProfile',
  components: {
    ImportMasterPayments
  }
};
</script>
