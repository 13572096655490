<template>
  <div v-show="proposal.completed != 1">
    <el-select
      v-model="selected_job_id"
      size="small"
      style="width: 300px"
      filterable
      reserve-keyword
      placeholder="Please select a job"
    >
      <el-option
        v-for="item in jobs_list"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      />
    </el-select>

    <b>Time Allocated:</b>
    <el-input-number
      v-model="selected_time_allocated"
      size="mini"
      placeholder="Time allocated"
    />
    <b>Unit sale price:</b>
    <el-input-number
      v-model="selected_sale_price"
      size="mini"
      aria-placeholder="Unit sale price"
    />
    <el-button
      size="small"
      @click="add_job"
    >
      Add task
    </el-button>
  </div>
</template>

<script>
import { editor_store } from 'store/modules/editor';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { JobProposalMixin } from 'mixins/JobProposalMixin.js';

export default {
  name: 'JobProposalsList',
  store: editor_store,
  mixins: [JobProposalMixin],
  props: ['is_initiated', 'working_file_id'],
  data() {
    return {
      selected_job_id: '',
      selected_sale_price: 0,
      selected_time_allocated: 0,
    };
  },
  computed: {
    ...mapGetters(['proposal', 'job_proposals_list', 'jobs_list']),
  },
  watch: {
    selected_job_id: function(newVal) {
      if (newVal != undefined) {
        let selected_job = this.get_selected_job(newVal);
        this.selected_time_allocated = selected_job.allocated_time;
        this.selected_sale_price = selected_job.labour_minutes_rate;
      }
    },
  },
  methods: {
    get_selected_job(id) {
      return this.jobs_list.filter((item) => {
        return item.id == id;
      })[0];
    },
    add_job() {
      let _this = this;
      if (this.selected_job_id != '') {
        let selected_job = this.get_selected_job(this.selected_job_id);

        let data = {
          job_id: selected_job.id,
          time_allocated_labour: this.selected_time_allocated,
          sale_price: this.selected_sale_price,
          is_initiated: this.is_initiated == true ? 1 : 0,
        };
        if (this.working_file_id != undefined && this.working_file_id > 0)
          data['working_file_id'] = this.working_file_id;
        this.add_job_proposal(this.proposal.id, [data]).then(
          (response) => {
            response.forEach((el, idx) => {
              _this.job_proposals_list.push(el);
            });

            _this.set_inputs_to_default();
            if (_this.proposal.approved == 1) {
              this.update_proposal_time_frame(_this.proposal.id);
            } else
              this.$message({
                message: 'Task added successfully.',
                type: 'success',
              });
          },
          (reason) => {
            console.log(reason);
            this.$message({
              message: 'Error occured!.',
              type: 'error',
            });
          }
        );
      }
    },
    set_inputs_to_default() {
      let _this = this;
      setTimeout(() => {
        Array.from(
          document.querySelectorAll('#job_proposals_list .el-input')
        ).forEach(function(e, i) {
          e.classList.add('browser-default');
          if (e.querySelector('input') != null)
            e.querySelector('input').className += ' browser-default';
        });
      }, 300);
    },
  },
};
</script>

<style></style>
