<template>
  <div
    style="display: inline-block;"
    class="attach_mail_div"
  >
    <el-button
      type="primary"
      data-tooltip="Create Quote"
      @click="open_modal"
    >
      Create Quote
    </el-button>
    <el-dialog
      title="Create Quote"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      style="text-align:left;"
      width="500px"
      @opened="set_inputs_to_default"
    >
      <el-form>
        <h2>{{ account_name }}</h2>
        <div>
          <el-select
            v-model="category_id"
            filterable
            placeholder="Select quote category"
            style="width: 400px;"
          >
            <el-option
              v-for="category in categories_list"
              :key="category.id"
              :label="category.name"
              :value="category.id"
            >
              <span>{{ category.name }}</span>
              <span style="color: #8492a6;" />
            </el-option>
          </el-select>
        </div>
        <div>
          <el-checkbox
            v-model="include_attachments"
            label="Include email attachments"
          />
        </div>
        <br>
        <br>
      </el-form>
      <div class="row">
        <div class="col m12">
          <hr>
          <div>
            <div class="right">
              <el-button
                type="primary"
                plain
                size="small"
                @click="attach_mail_to_quote"
              >
                Create Quote
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'AttachMailToNewAccountQuote',
  props: {
    message_id: {
      type: String,
      required: true
    },
    attachments: {
      type: String,
      default: ''
    },
    current_mailbox: {
      type: String,
      default: 'Inbox'
    },
    account_name: {
      type: String,
      required: true
    },
    account_id: {
      type: [String, Number],
      required: true
    },
  },
  data() {
    return {
      select_all: false,
      selected_attachment: '',
      items_loading: false,
      select_loading: false,
      dialogVisible: false,
      is_loading: false,
      menu_loading: true,
      is_existing: 'false',
      include_attachments: true,
      categories_list: [],
      proposal_id: '',
      category_id: ''
    };
  },
  computed: {
    pdf_attachments() {
      if (this.attachments == null || this.attachments.length == 0) return [];
      return this.attachments.filter((c) => c.mime_type == 'application/pdf');
    },
  },
  watch: {
    message_id: function (oldVal, newVal) {
      if (newVal.length > 0 && oldVal != newVal) {
        this.check_message_references();
      }
    }
  },
  mounted() {
    this.fill_categories();
  },
  created() {
  },
  methods: {
    attach_mail_to_quote() {
      let self = this;
      this.is_loading = true;
      this.$http
        .post('/attach_mail_to_quote', {
          message_id: this.message_id,
          account_id: this.account_id,
          is_existing: false,
          include_attachments: this.include_attachments,
          proposal_id: this.proposal_id,
          attach_to_copied: this.attach_to_copied,
          category_id: this.category_id,
          current_mailbox: this.current_mailbox
        })
        .then((response) => {
          this.is_loading = false;
          this.$message({
            type: response.data.status == 200 ? 'success' : 'warning',
            message: response.data.message,
          });
          self.hide_modal();
          self.check_message_references();
          window.open('/edit_proposal?id=' + response.data.data, '_blank');
        });
    },
    verify_attachments() {
      this.is_loading = true;
      let params = {
        mailbox: this.current_mailbox,
        mail_box_account_id: this.account_id,
        message_id: this.message_id,
      };
      this.verified_po_list = [];
      this.$http.post('/verify_invoice_vs_po', params).then(
        (response) => {
          this.verified_po_list = response.data;
          this.is_loading = false;
        },
        (response) => {
          console.log(response);
          this.is_loading = false;
        }
      );
    },
    open_modal() {
      this.dialogVisible = true;
    },
    hide_modal() {
      this.clear_inputs();
      this.is_loading = false;
      this.dialogVisible = false;
    },
    clear_inputs() {
      this.proposal_id = '';
      this.category_id = '';
      this.attach_to_copied = false;
      this.is_existing = 'false';
    },
    check_message_references() {
      let self = this;
      if (self.message_id == undefined || self.message_id == '') return;
      this.$http
        .post('/message_references_by_msg_id', {
          message_id: self.message_id,
        })
        .then((response) => {
          self.message_references_list = response.data.data;
          self.menu_loading = false;
        });
    },
    fill_categories() {
      let self = this;
      this.$http.get('/category_tasks_list').then(
        function (response) {
          // Success
          if (response.status == 200) {
            self.categories_list = response.data;
          }
        },
        function (response) {
          // error
          console.log(response);
        }
      );
    },
    create_new_account(current_message_id){
      let encoded_msg_id = encodeURIComponent(current_message_id);
      this.show_lead_dialog = false;
      return (
        window.open('/accounts/new?message_id=' + encoded_msg_id, '_blank').focus()
      );
    },
    set_inputs_to_default() {
      setTimeout(() => {
        Array.from(document.querySelectorAll('.el-input, .el-checkbox, .el-checkbox__label')).forEach(function (e) {
          e.classList.add('browser-default');
          if (e.querySelector('input') != null)
            e.querySelector('input').className += ' browser-default';
        });
      }, 300);
    },
  },
};
</script>

<style scoped>
.el-checkbox, .el-checkbox__input{
  position: relative !important;
}
    .input-label {
      width: 130px;
    }

    .el-date-editor {
      width: 130px !important;
    }

    thead td {
      color: #909399;
      font-weight: bold;
    }
</style>

<style>
      .ValueMismatch > input {
        background-color: pink !important;
      }
</style>