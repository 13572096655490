<template>
    <div v-show="Boolean(proposal_id > 0)">
        <strong>Description</strong>
        <hr>

         <strong>Links:</strong>
         <br>
            <a :href="proposal_link">Quote #{{proposal_id}}</a>
        <br>
        <a :href="job_link">Job #{{proposal_id}}</a>
        <br>
        <br>
        <div>
            <strong>{{category_name}} specs:</strong><br>
            <div v-html="description" :class="{pre_desc: description.length > 0 }">{{description}}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ProposalSpecs",
        props: ['proposal_id'],
        data() {
            return {
                category_name: '',
                description: '',
                proposal_link: '',
                job_link: ''
            }
        },
        mounted() {
            if (this.proposal_id != undefined && this.proposal_id != '') {
                this.fill_proposal_desc()
            }
        },
        watch: { 
            proposal_id: function(newVal, oldVal) { // watch it
                this.fill_proposal_desc()
            }
        },
        methods: {
            fill_proposal_desc() {
                if( this.proposal_id == null)
                    return;
                let self = this
                this.$http.get('/proposal_desc/' + this.proposal_id).then(
                    function (response) {
                        // Success
                        if (response.status == 200) {
                            self.category_name =  response.data.data.category
                            self.description =  response.data.data.description
                            self.proposal_link = response.data.data.proposal_link
                            self.job_link = response.data.data.job_link
                        }
                    },
                    function (response) {
                        // error
                        console.log(response);
                    });
            }
        }
    }
</script>

<style scoped>
.pre_desc{
    border: 1px solid #f3eeee;
    padding: 10px;
}
</style>