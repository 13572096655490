<template>
  <div id="stock-group-to">
    <el-button
      size="mini"
      type="success"
      plain
      @click="show_group_dialog = true"
    >
      Add Stock Items
    </el-button>
    <el-dialog
      :visible.sync="show_group_dialog"
      :title="'Add Stock Item To Group ' + stock_group_name"
      :append-to-body="true"
    >
      <el-input
        v-model="search_term"
        placeholder="search stock items"
        style="width: 200px;"
      />
      <el-select
        v-model="selected_limit"
        clearable
        filterable
      >
        <el-option
          v-for="limit in limits"
          :key="limit.value"
          :label="limit.label"
          :value="limit.value"
        />
      </el-select>
      <el-button
        type="primary"
        size="mini"
        @click.prevent="submit_search"
      >
        SEARCH
      </el-button>
      <el-table
        v-loading="loading"
        :data="stock_items"
        stripe
        style="width: 100%"
      >
        <el-table-column
          prop="id"
          label="Id"
          width="80"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="stock_item_link(scope.row.id)"
            >
              {{ scope.row.id }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="internal_name"
          label="Internal Name"
          width="180"
        />
        <el-table-column
          label="Operations"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="add_stock_item(scope.$index)"
            >
              Add to Group
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'StockItemToGroup',
  props: {
    stock_group_id: {
      type: Number,
      required: true
    },
    stock_group_name: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      show_group_dialog: false,
      loading: false,
      stock_items: [],
      search_term: '',
      limits: [{
        value: '50',
        label: '50'
      }, {
        value: '100',
        label: '100'
      }, {
        value: '500',
        label: '500'
      }, {
        value: '1000',
        label: '1000'
      }, {
        value: '5000',
        label: '5000'
      }, {
        value: 'all',
        label: 'all'
      }],
      selected_limit: 50,
    };
  },
  methods: {
    submit_search() {
      this.loading = true;
      this.$http.post('/search_stock_items', {
        search_term: this.search_term,
        limit: this.selected_limit
      })
        .then(response => {
          this.loading = false;
          this.stock_items = response.data.stock_items;
          this.count = response.data.total_count;
        });
    },
    add_stock_item(index){
      this.$http.post('/add_stock_item_to_group',
        {stock_item_id: this.stock_items[index].id , stock_group_id: this.stock_group_id}
      ).then(
        () => {
          this.$message({
            type: 'success',
            message: 'Added stock item to group'
          });
        });
    },
    stock_item_link(id) {
      window.open(`/stock_items/${id}/edit`, '_blank');
    },
  },
};
</script>
<style scoped>
#stock-group-to{ display: inline;}
</style>