import {Request} from '../utilities/Request';

export function loadMasterPayments(this_obj, account_id, page) {
  return Request.get(
    this_obj,
    '/api/master_payments',
    null,
    {
      account_id: account_id,
      page: page
    }
  );
}

export function addPayment(this_obj, master_payment_id, invoice_number, payment_amount) {
  return Request.patch(
    this_obj,
    `/api/master_payments/${master_payment_id}`,
    null,
    {
      invoice_number: invoice_number,
      payment_amount: payment_amount
    }
  );
}

export function calculateMasterPayments(this_obj, master_payments) {
  return Request.post(
    this_obj,
    '/api/master_payments/calculate_mps',
    null,
    {
      master_payments: master_payments
    },
  );
}

export function importMasterPayments(this_obj, master_payments) {
  return Request.post(
    this_obj,
    '/api/master_payments/import',
    null,
    {
      master_payments: master_payments
    }
  );
}

export function calculateOneMasterPayment(this_obj, account_id, date, payment_amount) {
  return Request.post(
    this_obj,
    '/api/master_payments/calculate',
    null,
    {
      account_id: account_id,
      date: date,
      payment_amount: payment_amount
    }
  );
}

export function createMasterPayment(this_obj, account_id, date, payment_amount) {
  return Request.post(
    this_obj,
    '/api/master_payments',
    null,
    {
      account_id: account_id,
      date: date,
      payment_amount: payment_amount
    }
  );
}

export function createMasterPaymentForInvoice(this_obj, invoice_id, date, payment_amount) {
  return Request.post(
    this_obj,
    '/api/master_payments/invoice_payment',
    null,
    {
      invoice_id: invoice_id,
      date: date,
      payment_amount: payment_amount
    }
  );
}

export function createMasterPaymentPayments(this_obj, account_id, date, payment_amount) {
  return Request.post(
    this_obj,
    '/api/master_payments/master_payment_payments',
    null,
    {
      account_id: account_id,
      date: date,
      payment_amount: payment_amount
    }
  );
}

export function deleteMasterPaymentPayment(this_obj, id) {
  return Request.delete(
    this_obj,
    `/api/master_payments/${id}`
  );
}

export function updateIsNotIncluded(this_obj, id, is_not_included) {
  return Request.patch(
    this_obj,
    `/api/master_payments/${id}/update_is_not_included`,
    null,
    {
      is_not_included: is_not_included
    }
  );
}

export function updateAmount(this_obj, id, amount) {
  return Request.patch(
    this_obj,
    `/api/master_payments/${id}/update_amount`,
    null,
    {
      amount: amount
    }
  );
}
