<template>
  <div>
    <el-container id="marketing-container">
      <el-aside
        width="50px"
        style="background-color: black;"
      >
        <navigation />
      </el-aside>

      <el-container>
        <el-main>
          <transition name="fade">
            <router-view />
          </transition>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Filemanager from '../filemanager/Filemanager.vue';
import Navigation from './Navigation.vue';
export default {
  components: {
    filemanager: Filemanager,
    navigation: Navigation
  },
  computed: {}
};
</script>

<style>
#marketing-container .el-container {
    width: 100%;
}

#marketing-container {
  margin: -20px;
  min-height: 100vh;
}

@media only screen and (min-width: 601px) and (max-width: 993px){
  #marketing-container {
   margin-left: -10px;
  }
}

#marketing-container .el-main {
  border: 0px;
  margin: 0px;
  padding: 0px;
  background: #ffffff;
}
#marketing-container .header {
    background: #909399;
    min-width: 100vh;
    padding: 10px;
    color: #fff;
}
.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.25s;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
@media only screen and (max-width: 600px) {
    .jobmenu {
      font-size:30px;
    }

}
</style>