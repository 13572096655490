<template>
  <el-card>
    <el-collapse>
      <el-collapse-item>
        <template slot="title">
          <el-row class="width--full">
            <el-col :span="12">
              Tasks Time Spent
            </el-col>
            <el-col :span="12">
              <Time
                :is_hours="true"
                :minutes="total_time_spent"
              /> | <Time
                :is_hours="false"
                :minutes="total_time_spent"
              />
            </el-col>
          </el-row>
        </template>
        <h3>From Job:</h3>
        <TaskTimeSpentList :list="task_time_spents_from_job" />

        <div v-show="is_jobtime_input === true">
          <br>
          <h3>From TimeSheet:</h3>
          <TaskTimeSpentList :list="task_time_spents_from_time_sheet" />
        </div>
      </el-collapse-item>
    </el-collapse>
  </el-card>
</template>

<script>
import {loadIsJobtimeInput} from '../../../api/organization';
import {loadTaskTimeSpentLists} from '../../../api/task_time_spent';

import Time from '../../shared/Time';
import TaskTimeSpentList from './TaskTimeSpentList';

export default {
  name: 'TaskTimeSpents',
  components: { Time, TaskTimeSpentList },
  props: {
    proposal_id: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      total_time_spent: 0,
      is_jobtime_input: false,
      task_time_spents_from_job: [],
      task_time_spents_from_time_sheet: []
    };
  },
  created() {
    loadIsJobtimeInput(this).then(({data})=>{
      this.is_jobtime_input = data.is_jobtime_input;
    });
    loadTaskTimeSpentLists(this, this.proposal_id).then(({data})=>{
      this.task_time_spents_from_job = data.from_job;
      this.task_time_spents_from_time_sheet = data.from_time_sheet;
      this.total_time_spent = data.total_time_spent;
    });
  }
};
</script>
