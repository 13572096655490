<template>
  <div id="stock-group-remove">
    <el-button
      size="mini"
      type="danger"
      plain
      @click="show_group_dialog = true, stock_items_per_group()"
    >
      Remove Stock Item
    </el-button>
    <el-dialog
      :visible.sync="show_group_dialog"
      :title="'Remove Stock Item From Group ' + stock_group_name"
      :append-to-body="true"
    >
      <el-table
        v-loading="loading"
        :data="stock_items"
        stripe
        style="width: 100%"
      >
        <el-table-column
          prop="id"
          label="Id"
          width="80"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="stock_item_link(scope.row.id)"
            >
              {{ scope.row.id }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="internal_name"
          label="Internal Name"
          width="280"
        />
        <el-table-column
          label="Operations"
        >
          <template slot-scope="scope">
            <el-button
              type="danger"
              size="mini"
              @click="remove_stock_item(scope.$index)"
            >
              Remove From Group
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'StockItemRemoveFromGroup',
  props: {
    stock_group_id: {
      type: Number,
      required: true
    },
    stock_group_name: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      show_group_dialog: false,
      loading: false,
      stock_items: [],
    };
  },
  mounted(){
    this.stock_items_per_group();
  },
  methods: {
    stock_items_per_group() {
      this.loading = true;
      this.$http.post('/stock_item_per_group', {
        stock_group_id: this.stock_group_id,
      }).then(response => {
        this.loading = false;
        this.stock_items = response.data.stock_items;
      },
      () => {
        this.loading = false;
      });
    },
    remove_stock_item(index){
      let id = this.stock_items[index].id;
      this.$http.post('/remove_stock_item_from_group',
        {stock_item_id: id , stock_group_id: this.stock_group_id}
      ).then(
        () => {
          this.$message({
            type: 'success',
            message: 'Stock item from group removed'
          });
          this.stock_items.splice(index, 1);
          // used v-on="$listeners", that event fires from grand child to parent
          this.$emit('reload_groups', id);
        }
      );
    },
    stock_item_link(id) {
      window.open(`/stock_items/${id}/edit`, '_blank');
    },
  },
};
</script>
<style scoped>
#stock-group-remove{ display: inline;}
</style>