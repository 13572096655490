<template>
  <div id="reimbursement_crm_user">
    <p>Reimburse Employee</p>
    <el-select
      v-model="crm_user_id"
      clearable
      placeholder="Select Employee"
      size="small"
      style="width:100%"
      @change="add_reimbursement_to_purchase_order"
    >
      <el-option
        v-for="user in crm_users"
        :key="user.id"
        :label="user.user_name + ' ' + user.user_surname"
        :value="user.id"
      >
        <span>{{ user.user_name }} {{ user.user_surname }}</span>
      </el-option>
    </el-select>
  </div>
</template>

<script>

export default {
  name: 'ReimbursementCrmUser',
  props: {
    purchase_order_id: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      crm_users: [],
      crm_user_id: ''
    };
  },
  created() {
    this.get_crm_users();
    this.purchase_order();
  },
  methods: {
    add_reimbursement_to_purchase_order(){
      this.$http.patch(`/purchase_orders/${this.purchase_order_id}`, { reimbursement_crm_user_id: this.crm_user_id }).then(
        () => {
          let message = 'Added Employee for reimbursement on next pay run.';
          if(this.crm_user_id == '') {
            message = 'Removed Employee reimbursement for next pay run';
          }
          this.$message({
            message: message,
            type: 'success',
          });
        });
    },
    get_crm_users(){
      this.$http.get('/users_active_list').then(
        (response) => {
          this.crm_users = response.data;
        });
    },
    purchase_order(){
      this.$http.get(`/purchase_orders/${this.purchase_order_id}`).then(
        (response) => {
          this.crm_user_id = response.data.reimbursement_crm_user_id;
        });
    },
  }
};
</script>
