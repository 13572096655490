import {Request} from '../utilities/Request';

export function loadAllocatedJobs(this_obj, user_id, query) {
  return Request.get(
    this_obj,
    `/api/crm_users/${user_id}/job_proposals/allocated_jobs`,
    null,
    {
      query: query
    }
  );
}

export function loadUnallocatedJobs(this_obj, user_id, query) {
  return Request.get(
    this_obj,
    `/api/crm_users/${user_id}/job_proposals/unallocated_jobs`,
    null,
    {
      query: query
    }
  );
}

export function addUserToCustomJob(this_obj, jp_id, user_id) {
  return Request.post(
    this_obj,
    `/api/job_proposals/${jp_id}/add_user_to_custom_job`,
    null,
    {
      user_id: user_id
    }
  );
}

export function removeUserFromCustomJob(this_obj, jp_id, user_id) {
  return Request.post(
    this_obj,
    `/api/job_proposals/${jp_id}/remove_user_from_custom_job`,
    null,
    {
      user_id: user_id
    }
  );
}

export function update_alternative_descriptions(this_obj, job_proposal_id, alternative_description) {
  return Request.patch(
    this_obj,
    `/api/job_proposals/${job_proposal_id}/update_alternative_description`,
    null,
    {alternative_description: alternative_description}
  );
}

export function alternative_descriptions(this_obj, proposal_id) {
  return Request.post(
    this_obj,
    '/api/job_proposals/alternative_descriptions',
    null,
    {proposal_id: proposal_id}
  );
}

