<template>
  <div>
    <div v-if="working_file_categories.length > 0">
      <Categories
        :editable="!(proposal.approved === 1)"
        v-slot="slotProps"
        category_id="editSummaryCategoryID"
      >
        <div
          v-for="cgrp in category_grouped_files(slotProps.category.id)"
          :key="cgrp.id"
        >
          <SummaryWorkingFileEdit
            :working_file_info="cgrp"
            :working_file_id="cgrp.id"
          />
        </div>
      </Categories>
      <template v-if="main_list">
        <h2>Not Category List - No Working File</h2>
        <SummaryWorkingFileEdit
          :working_file_info="main_list"
          :working_file_id="main_list.id"
        />
      </template>
    </div>
    <div
      v-else
      v-for="grp in grouped_files.sort(function(a, b) {
        return a.position - b.position;
      })"
        :key="grp.id"
        style="padding:20px; border-radius:20px; border:3px solid #999;"
    >
      <SummaryWorkingFileEdit
          :working_file_info="grp"
          :working_file_id="grp.id"
      ></SummaryWorkingFileEdit>
    </div>

    <br/>
    <table style="width: 100%" class="jobs_stocks_summary">
      
      <tbody>
      <tr>
        <td>STOCKS TOTAL</td>
        <td width="120">${{ stock_items_total_sale }}</td>
        <td width="120"></td>
      </tr>
      <tr>
        <td>TASKS TOTAL</td>
        <td width="120">${{ job_proposals_total_sale }}</td>
        <td width="120"></td>
      </tr>
      <tr>
        <td>CHARGE POs TOTAL</td>
        <td width="120">${{ charge_pos_total_sale }}</td>
        <td width="120"></td>
      </tr>
      </tbody>
      <tfoot>
      <tr
          v-bind:class="{
            crossed_text: proposal_summary.is_matched_quote,
            borderd_row: 1 == 1,
          }"
      >
        <td>TOTAL SALE</td>
        <td width="120"></td>
        <td width="120">
          ${{
          stock_items_total_sale +
          job_proposals_total_sale +
          charge_pos_total_sale
          }}
        </td>
      </tr>
      <tr
          v-show="proposal_summary.is_matched_quote == true"
          class="borderd_row"
      >
        <td>MATCHED QUOTE</td>
        <td width="120"></td>
        <td width="120">${{ proposal_summary.total_matched_quote }}</td>
      </tr>
      <tr style="color: rgb(247, 137, 137);">
        <td>STOCK COST</td>
        <td width="120">- ${{ stock_items_total_cost }}</td>
        <td width="120"></td>
      </tr>
      <tr style="color: rgb(247, 137, 137);">
        <td>TASKS COST</td>
        <td width="120">- ${{ job_proposals_total_cost }}</td>
        <td width="120"></td>
      </tr>
      <tr style="color: rgb(247, 137, 137);">
        <td>TASKS COST</td>
        <td width="120">- ${{ charge_pos_total_cost }}</td>
        <td width="120"></td>
      </tr>
      <tr class="borderd_row" style="color: rgb(247, 137, 137);">
        <td>TOTAL COST</td>
        <td width="120"></td>
        <td width="120">
          - ${{
          round(
          job_proposals_total_cost +
          stock_items_total_cost +
          charge_pos_total_cost
          )
          }}
        </td>
      </tr>
      <tr style="color: rgb(114, 185, 118);">
        <td>GROSS PROFIT</td>
        <td width="120"></td>
        <td width="120">${{ gross_profit }}</td>
      </tr>
      </tfoot>
    </table>

    <br/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {editor_store} from '../../../store/modules/editor';
import SummaryWorkingFileEdit from './SummaryWorkingFileEdit.vue';
import EditPurchaseOrder from './../../purchase_orders/Edit';
import Categories from '../categories/Categories';
export default {
  name: 'ProposalSummaryEdit',
  store: editor_store,
  components: {
    SummaryWorkingFileEdit,
    EditPurchaseOrder,
    Categories
  },
  data() {
    return {
      po_id: 0,
      charge_po_dialog: false,
      is_charge_po: true,
    };
  },
  mounted() {
  },
  methods: {
    round(num) {
      return parseFloat(((num * 100) / 100).toFixed(2));
    },
  },
  computed: {
    ...mapGetters([
      'proposal',
      'proposal_summary',
      'job_proposals_list',
      'stock_items_list',
      'charge_pos_list',
      'files_list',
      'grouped_files',
      'working_file_categories'
    ]),
    main_list() {
      return this.grouped_files.find((grouped_file) => grouped_file.name === 'Main list' );
    },
    category_grouped_files() {
      return (category_id) => {
        return this.grouped_files.filter((grouped_file)=> grouped_file.working_file.category_working_file_id === category_id);
      };
    },
    gross_profit() {
      let total_sale = 0;
      if (this.proposal_summary.is_matched_quote == true)
        total_sale = this.proposal_summary.total_matched_quote;
      else
        total_sale =
            this.stock_items_total_sale + this.job_proposals_total_sale;
      return this.round(
          parseFloat(total_sale) +
          this.charge_pos_total_sale -
          (this.job_proposals_total_cost +
              this.stock_items_total_cost +
              this.charge_pos_total_cost)
      );
    },
    job_proposals_total_cost() {
      return this.round(
          this.job_proposals_list.filter(i => i.is_included === true).reduce(
              (acc, item) =>
                  item.mark_as_deleted == 0
                      ? acc + item.time_allocated_labour * item.pay_rate
                      : acc,
              0
          )
      );
    },
    job_proposals_total_sale() {
      return this.round(
          this.job_proposals_list.filter(i => i.is_included === true).reduce(
              (acc, item) =>
                  item.mark_as_deleted == 0
                      ? acc + item.time_allocated_labour * item.sale_price
                      : acc,
              0
          )
      );
    },
    job_proposals_total_profit() {
      return this.round(
          this.job_proposals_list.filter(i => i.is_included === true).reduce(
              (acc, job) =>
                  job.mark_as_deleted == 0
                      ? acc +
                      (job.time_allocated_labour * job.sale_price -
                          job.time_allocated_labour * job.pay_rate)
                      : acc,
              0
          )
      );
    },
    charge_pos_total_cost() {
      return this.round(
          this.charge_pos_list.filter(i => i.is_included === true).reduce(
              (acc, item) =>
                  item.mark_as_deleted == 0 ? acc + item.actual_value : acc,
              0
          )
      );
    },
    charge_pos_total_sale() {
      return this.round(
          this.charge_pos_list.filter(i => i.is_included === true).reduce(
              (acc, item) =>
                  item.mark_as_deleted == 0 ? acc + item.sale_price : acc,
              0
          )
      );
    },
    charge_pos_total_profit() {
      return this.round(
          this.charge_pos_list.filter(i => i.is_included === true).reduce(
              (acc, item) =>
                  item.mark_as_deleted == 0
                      ? acc + (item.sale_price - item.actual_value)
                      : acc,
              0
          )
      );
    },
    stock_items_total_cost() {
      return this.round(
          this.stock_items_list.filter(i => i.is_included === true).reduce(
              (acc, stock) =>
                  stock.mark_as_deleted == 0
                      ? acc + stock.quantity * stock.price
                      : acc,
              0
          )
      );
    },
    stock_items_total_sale() {
      return this.round(
          this.stock_items_list.filter(i => i.is_included === true).reduce(
              (acc, stock) =>
                  stock.mark_as_deleted == 0
                      ? acc + stock.quantity * stock.sale_price
                      : acc,
              0
          )
      );
    },
    stock_items_total_profit() {
      return this.round(
          this.stock_items_list.filter(i => i.is_included === true).reduce(
              (acc, stock) =>
                  stock.mark_as_deleted == 0
                      ? acc +
                      (stock.quantity * stock.sale_price -
                          stock.quantity * stock.price)
                      : acc,
              0
          )
      );
    },
    total_sale() {
    },
  },
};
</script>

<style>
.items_list_summary td {
  border: 1px solid #f5f0f0;
  padding: 10px 5px;
}

.items_list_summary thead {
  font-weight: 500;
  color: gray;
}

.items_list_summary tfoot {
  font-weight: 600;
  color: gray;
}

.jobs_stocks_summary {
  width: 100%;
  font-size: 13px;
  background-color: #e2edf1;
  border: 1px solid #e4dfdf;
}

.jobs_stocks_summary tr {
  font-weight: bold;
  color: #848282;
}

.jobs_stocks_summary td {
  padding: 8px 25px;
}

.jobs_stocks_summary td:last-child {
  text-align: left;
}

.jobs_stocks_summary td:first-child {
  text-align: right;
}

.borderd_row {
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}

.crossed_text {
  text-decoration: line-through;
}
</style>
