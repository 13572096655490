<template>
  <div>
    <h2>Tender Requests</h2>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {}
};
</script>

<style scoped>

</style>