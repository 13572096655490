<template>
  <div style="float:left; width:100%; padding:40px; background:#f3f3f3">
    <div style="width:100%;text-align:right;">
      <span class="spanlabel"> <i class="el-icon-timer" />Deadline: </span>
      {{ proposal.dated }}

      <span
        v-show="proposal.preferred_date != undefined"
        class="spanlabel"
      >
        <br>
        <i class="el-icon-timer" />Preferred:
      </span>
      {{ proposal.preferred_date_formatted }}
      <br>
      <span
        v-show="proposal.is_overdue == true"
        style="color: red"
      >
        <el-alert
          title="Preferred deadline exceeds deadline"
          type="warning"
          center
          show-icon
        />
      </span>
      <br>
      <el-button
        v-show="
          proposal.complete_job_allowed &&
            proposal.approved == 1 &&
            proposal.completed == 0
        "
        type="primary"
        size="mini"
        @click="complete_main_job(proposal.id)"
      >
        Complete Job
      </el-button>
      <br>
      <b>STATUS:</b>
      <el-select
        v-model="selected_hold_status"
        clearable
        @change="update_proposal_hold"
      >
        <el-option
          v-for="status in hold_statuses"
          :key="status.id"
          :label="status.name"
          :value="status.id"
        >
          <span style="float: left">{{ status.name }}</span>
          <el-tooltip :content="status.description">
            <i
              class="el-icon-info"
              style="margin-left: 5px"
            />
          </el-tooltip>
        </el-option>
      </el-select>
    </div>

    <div
      v-for="active in active_tasks"
      v-show="!proposal.completed"
      :key="active.id"
    >
      <i
        class="tiny material-icons"
        style="color:rgb(118, 196, 0); margin-top:-2px;font-size:10px; "
      >brightness_1</i>
      <b>{{ active.list_name }}</b>
      -
      {{ display_task_name(active) }}
      <span style="color:rgb(118, 196, 0)">
        <i class="el-icon-video-play" />
        {{ active.time_frame_start }}
      </span>
      <span style="color:orange">
        <i class="el-icon-takeaway-box" />
        {{ active.time_frame_end }}
      </span>
      <i class="el-icon-user" />

      <span
        v-for="assigneduser in active.crm_users"
        :key="assigneduser.id"
        class="assignedUser"
      >{{ assigneduser.user_name }}</span>
    </div>
    <div
      v-show="proposal.completed"
      class="div2"
    >
      <span style="color:red">
        <b>THIS JOB HAS BEEN COMPLETED</b>
      </span>
      <br>
      <span class="spanlabel">Completed date:</span>
      <br>
      {{ completed_date }}
    </div>

    <br>

    <el-progress
      :text-inside="true"
      :stroke-width="20"
      :percentage="time_allocated_percentage"
      status="success"
    />

    <div class="left">
      <span class="spanlabel">Job Total Allocated:</span>
      <Time :is_hours="is_hours" :minutes="job_total_time_allocated" />
      <br>
      <span class="spanlabel">Job Total Time Spent:</span>
      <Time :is_hours="is_hours" :minutes="job_total_time_spent" />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { editor_store } from 'store/modules/editor';
import { JobProposalMixin } from 'mixins/JobProposalMixin.js';
import { ProposalMixin } from 'mixins/ProposalMixin.js';
import Time from '../../shared/Time';

export default {
  name: 'JobProgress.vue',
  store: editor_store,
  components: {Time},
  mixins: [JobProposalMixin, ProposalMixin],
  props: ['active_tasks'],
  data() {
    return {
      selected_hold_status: '',
      hold_statuses: [],
      total_time_allocated: 1440,
      total_time_spent: 1709,
      completed: false,
      deadline: 'Thurs 06.09.2019 - 03:45pm',
      current_task_name: 'Compacting',
      completed_date: 'Wed 05.09.2019 - 03:45pm',
      current_task_users: [
        {
          user_id: '123',
          user_name: 'Matt',
        },
        {
          user_id: '125',
          user_name: 'Loom',
        },
        {
          user_id: '127',
          user_name: 'Dave',
        },
      ],
    };
  },
  mounted() {
    this.fill_hold_statuses();
    this.selected_hold_status = this.proposal.hold_status_id;
  },
  computed: {
    ...mapGetters(['proposal', 'proposal_id', 'job_proposals_list', 'is_hours']),
    job_proposals_list_grouped() {
      return this.groupBy(this.job_proposals_list, 'list_name');
    },
    job_total_time_allocated() {
      let total = 0;
      return this.job_proposals_list.reduce(function(total, item) {
        return total + parseInt(item.time_allocated_labour);
      }, 0);
    },
    job_total_time_spent() {
      let total = 0;
      return this.job_proposals_list.reduce(function(total, item) {
        let total_per_user = item.crm_users.reduce(function(total, item) {
          return total + parseInt(item.time_in_minutes);
        }, 0);
        return total + parseInt(total_per_user);
      }, 0);
    },
    time_allocated_percentage() {
      let completed_mintues = this.job_proposals_list
        .filter((c) => c.completed == 1 && !c.mark_as_deleted)
        .reduce(function(total, item) {
          return total + parseInt(item.time_allocated_labour);
        }, 0);
      if (completed_mintues == 0 || this.job_total_time_allocated == 0)
        return 0;
      let percentage =
        (completed_mintues / this.job_total_time_allocated) * 100;

      return Math.round(percentage);
    },
  },

  methods: {
    ...mapActions(['set_stock_items_list', 'set_job_proposals_list']),
    update_proposal_hold() {
      this.$confirm('Are you sure to update the proposal hold status ?')
        .then((_) => {
          console.log('yes');
          let params = {
            id: this.proposal_id,
            hold_status_id: this.selected_hold_status,
          };
          this.$http.post('/update_proposal_hold_status/', params).then(
            (c) => {
              this.$message({
                message: 'Proposal hold status updated.',
                type: 'success',
              });
              window.location.reload();
              // let temp_id = this.proposal.id;
              // this.proposal.id = undefined;
              // this.proposal.id = temp_id;
            },
            (response) => {
              this.$message({ type: 'warning' });
              this.selected_hold_status = this.proposal.hold_status_id;
            }
          );
        })
        .catch((_) => {
          this.selected_hold_status = this.proposal.hold_status_id;
        });
    },
    fill_hold_statuses() {
      this.$http.get('/hold_statuses').then((response) => {
        this.hold_statuses = response.data;
      });
    },
    display_task_name(task) {
      return task.layer_name ? task.layer_name : task.job_name;
    }
  },
  watch: {
    'proposal.id': function(newVal) {
      this.selected_hold_status = this.proposal.hold_status_id;
    },
  },
};
</script>
<style>
.div1 {
  padding: 10px;
  border: 2px 2px 0px 0px solid #fff;
  float: left;
  width: 33%;
  min-height: 100px;
}
.div2 {
  padding: 10px;
  border: 2px 2px 0px 0px solid #fff;
  float: left;
  width: 33%;
  min-height: 100px;
}
.div3 {
  padding: 10px;
  border: 2px 2px 0px 0px solid #fff;
  float: left;
  width: 33%;
  min-height: 100px;
}
</style>
