<template>
  <div>
    DETAILS TO GO IN HERE
  </div>
</template>

<script>
export default {
  name: 'Details',
  computed: {}
};
</script>
<style scoped>
a {
  padding: 5.5px 12px;
  display: block;
}
.router-link-active {
  background: white;
  padding: 5.5px 12px;
}
.nav-menu {
  color: #fff;
 
}
</style>

