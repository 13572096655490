export const ProposalStockItemMixin = {
  created() {},
  data() {
    return {};
  },
  methods: {
    load_stock_items_list(proposal_id) {
      return new Promise((resolve, reject) => {

        let params = {
          proposal_id: proposal_id
        };
        this.$http
          .post('stock_items_list', params)
          .then(
            response => {
              resolve(response.data.data);
            },
            response => {
              reject(response.body);
            }
          );
      });
    },
    add_proposal_stock_item(proposal_id, params) {
      return new Promise((resolve, reject) => {
        this.$http
          .patch('/add_proposal_stock_item/' + proposal_id, {
            proposal_stock_item: params
          })
          .then(response => {
            resolve(response.body.data);
          }, response => {
            reject(response.message);
          });
      });
    },
    update_proposal_stock_item(proposal_stock_item_id, params) {

      delete Object.assign(params, {
        ['quantity']: params['stock_quantity']
      })['stock_quantity'];

      return new Promise((resolve, reject) => {
        this.$http
          .patch('/update_proposal_stock_item/' + proposal_stock_item_id, {
            proposal_stock_item: params
          })
          .then(response => {
            resolve(response.body.data);
          }, response => {
            reject(response.message);
          });
      });
    },
    get_stock_attached_tasks(stock_id) {
      return new Promise((resolve, reject) => {
        var js = {
          stock_id: stock_id
        };
        this.$http.post('/get_stock_related_tasks', js).then(
          response => {
            resolve(response.body);
          },
          response => {
            reject(response.body);
          });
      });
    },
    update_stock_sale_price(stock_id, sale_price) {
      return new Promise((resolve, reject) => {
        var js = {
          id: stock_id,
          sale_price: sale_price
        };
        this.$http.post('/update_stock_sale_price', js).then(
          response => {
            resolve(response.body.data);
          },
          response => {
            reject(response.body);
          });
      });

    },
    delete_proposal_stock_item(type, id) {
      return new Promise((resolve, reject) => {
        this.$http.delete(`/delete_proposal_stock_item/${type}/${id}`).then(
          response => {
            resolve(response.body.data);
          },
          response => {
            reject('not_ok');
          }
        );
      });
    },
    receive_po_stock_items(po_id) {
      return new Promise((resolve, reject) => {
        this.$http.get(`/receive_po/${po_id}`).then(
          response => {
            if (response.data.status == 200)
              resolve(response.data);
            else
              reject(response.data);
          },
          response => {
            reject(response.body);
          });
      });
    },
    batch_prop_stock_update(data) {
      return new Promise((resolve, reject) => {
        this.$http.post('/update_prop_stocks_list/', {
          items: data
        }).then(
          response => {
            resolve(response);
          },
          reason => {
            console.log(reason);
            reject(reason);
          });
      });
    }

  }
};