<template>
  <div>
    <el-form label-width="80px">
      <el-form-item label="Category">
        <el-select
          v-model="selected_categories"
          multiple
          placeholder="Select Category"
          collapse-tags
          size="small"
          style="min-width: 210px;"
          @change="onChangeCategory"
        >
          <el-option
            v-for="category in categories_list"
            :key="category.id"
            :label="category.name.toUpperCase()"
            :value="category.id"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <br>
    <el-table
      :data="proposals"
      stripe
      style="width: 100%"
    >
      <el-table-column
        prop="id"
        label="Proposal"
        width="100"
      >
        <template slot-scope="scope">
          <el-link
            :href="`/job_view?id=${scope.row.id}`"
            target="_blank"
          >
            {{ scope.row.id }}
          </el-link>
        </template>
      </el-table-column>
      <el-table-column
        prop="quote_type"
        label="Quote Type"
      />
      <el-table-column
        prop="branch"
        label="branch"
      />
      <el-table-column
        prop="account_name"
        label="Account"
      />
      <el-table-column
        prop="category"
        label="Category"
      />
      <el-table-column
        prop="job_name"
        label="Job Name"
      />
      <el-table-column
        prop="preferred_date"
        label="Preferred date"
      />
      <el-table-column
        prop="deadline"
        label="Deadline"
      />
    </el-table>
  </div>
</template>

<script>
import {Request} from '../../utilities/Request';
import {defaultInput} from '../../utilities/DefaultInput';

export default {
  name: 'TodayDueJobs',
  data() {
    return {
      proposals: [],
      categories_list: [],
      selected_categories: []
    };
  },
  created() {
    this.init();
  },
  mounted() {
    defaultInput();
  },
  methods: {
    async init() {
      await Request.get(
        this,
        '/category_tasks_list',
        (response)=> {
          this.categories_list = response.data;
          this.selected_categories = this.categories_list.map((el) => el.id);
        }
      );
      this.fetch_today_due_jobs();
    },
    fetch_today_due_jobs() {
      Request.get(
        this,
        '/api/proposals/today_due',
        (response)=>{
          this.proposals = response.body.proposals;
        },
        {
          selected_categories: this.selected_categories,
        }
      );
    },
    onChangeCategory() {
      this.fetch_today_due_jobs();
    }
  }
};
</script>
