<template>
  <div>
    <el-button
      size="mini"
      icon="el-icon-folder-add"
      @click="dialog_visible = true"
    >
      FORGOT PASSWORD
    </el-button>
    <el-dialog
      title
      :visible.sync="dialog_visible"
      width="30%"
    >
      <el-form
        ref="email_form"
        status-icon
        :rules="rules"
        size="mini"
        :model="forgot"
        :inline-message="true"
      >
        <el-form-item
          label="Email:"
          prop="email"
        >
          <el-input
            v-model="forgot.email"
            type="text"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="mini"
            @click="submit_form('email_form')"
          >
            SEND EMAIL
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'ForgotPassword',
  data() {
    return {
      forgot: {email: null},
      dialog_visible: false,
      rules: {
        email: [
          {
            required: true,
            // eslint-disable-next-line no-useless-escape
            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            message: 'Please insert a email address',
            trigger: 'change'
          }
        ]
      }
    };
  },
  created() {
  },

  methods: {
    send_email() {
      return new Promise((resolve, reject) => {
        let params = {
          email: this.forgot.email
        };
        this.$http.post('/clientiq/send_email', params).then(
          () => {
            this.$message({
              message:
                    'Form submitted. You should receive a email with a password reset link. Please check your email.',
              type: 'success'
            });
            false;
            resolve(resolve);
          },
          function (response) {
            this.$message({
              message: response.data.errors,
              type: 'error'
            });
            reject(response);
          }
        );
      });
    },
    submit_form(email_form) {
      this.$refs[email_form].validate(valid => {
        if (valid) {
          this.send_email();
        }
      });
    }
  }
};
</script>

<style scoped>
</style>