SheetsCalculation = function (obj) {
	this.init(obj);
};

SheetsCalculation.prototype = {
	init: function (obj) {
		this.sheet_width = obj.w;
		this.sheet_height = obj.h;
		this.blocks = obj.blocks;
		this.num = obj.num;
	},
	run: function () {
		var not_fit = Blocks.deserialize(this.blocks, this.num);
		Blocks.sort.now(not_fit);
		var sheets = [],
			fit_arr = [],
			waste = [],
			i = 1;

		while (not_fit.length > 0  ) {
			var packing = new Packing(this.sheet_width, this.sheet_height);
			packing.fit(not_fit);

			var temp_fit = [];
			var fit = 0,
				nofit = [],
				block, n, len = not_fit.length;
			var not_yet_fit = [];
			for (n = 0; n < len; n++) {
				block = not_fit[n];
				if (block.fit) {
					fit = fit + block.area;
					block.sheet_num = i;
					temp_fit.push(block);
				} else
					not_yet_fit.push(block);
			}
			not_fit = not_yet_fit.slice(0);

			// add waste
			packing.waste.forEach(function (el, index) {
				el.sheet_num = i;
			});

			// add waste for

			Array.prototype.push.apply(waste, packing.waste.filter(item => item.invalid != true));
			if (temp_fit.length > 0)
				sheets.push(temp_fit);
			i++;
		}

		// Calculate waste in last sheet
		var last_sheet = sheets[sheets.length - 1];
		var last_block = last_sheet[last_sheet.length - 1];
		if (last_block != undefined) {

			var right = {
				x: last_block.fit.x + last_block.w,
				y: last_block.fit.y,
				w: (this.sheet_width - (last_block.fit.x + last_block.w)),
				h: (last_block.fit.y + last_block.h) - (last_block.fit.y)
			};

			var down = {
				x: 0,
				y: last_block.fit.y + last_block.h,
				h: this.sheet_height - (last_block.fit.y + last_block.h),
				w: this.sheet_width
			};
			var arr = [right, down];
			arr.forEach(function (element, index) {
				if (element.h >= 300 && element.w >= 300) {
					waste.push({
						x: element.x,
						y: element.y,
						h: element.h,
						w: element.w,
						sheet_num: i - 1
					});
				}
			});
		}
		return {
			sheets: sheets,
			waste: waste,
			not_fit: not_fit
		};
	},
}


// iterate through all blocks and get them
Blocks = {
	sort: {
		w: function (a, b) {
			return b.w - a.w;
		},
		h: function (a, b) {
			return b.h - a.h;
		},
		a: function (a, b) {
			return b.area - a.area;
		},
		max: function (a, b) {
			return Math.max(b.w, b.h) - Math.max(a.w, a.h);
		},
		min: function (a, b) {
			return Math.min(b.w, b.h) - Math.min(a.w, a.h);
		},
		maxside: function (a, b) {
			return Blocks.sort.msort(a, b, ['max', 'min', 'h', 'w']);
		},
		msort: function (a, b, criteria) {
			/* sort by multiple criteria */
			var diff, n;
			for (n = 0; n < criteria.length; n++) {
				diff = Blocks.sort[criteria[n]](a, b);
				if (diff != 0)
					return diff;
			}
			return 0;
		},
		now: function (blocks) {
			blocks.sort(Blocks.sort['maxside']);
		}
	},

	deserialize: function (arr, num) {
		var expanded = [];
		for (i = 0; i < arr.length; i++) {
			for (j = 0; j < num; j++)
				expanded.push({
					sign_num: (j + 1),
					section_num: (i + 1),
					w: arr[i].w,
					h: arr[i].h,
					area: arr[i].w * arr[i].h,
					id: arr[i].id,
					el: arr[i]
				});
		}
		return expanded;
	}
};


Packing = function (w, h) {
	this.init(w, h);
};

Packing.prototype = {

	init: function (w, h) {
		this.root = {
			x: 0,
			y: 0,
			w: w,
			h: h
		};
		this.waste = [];
	},

	fit: function (blocks) {
		var n, node, block;
		for (n = 0; n < blocks.length; n++) {
			block = blocks[n];
			if (node = this.findNode(this.root, block.w, block.h))
				block.fit = this.splitNode(node, block.w, block.h);
			else if (node = this.findNode(this.root, block.h, block.w)) {
				block.fit = this.splitNode(node, block.h, block.w);
				temp_w = block.h;
				temp_h = block.w;
				block.w = temp_w;
				block.h = temp_h;
				block.rotated = 1;
			}

		}
	},

	findNode: function (root, w, h) {
		if (root.used)
			return this.findNode(root.right, w, h) || this.findNode(root.down, w, h);
		else if ((w <= root.w) && (h <= root.h)) {
			this.waste.forEach(function (element, index) {
				if (root.x >= element.x && root.y >= element.y && (root.x + w) <= (element.x + element.w) && (root.y + h) <= (element.y + element.h)) {
					element.x = (root.x + w);
					element.y = (root.y + h);
					element.w = (element.w - w);
					element.h = (element.h - h);
					if (element.h < 300 || element.w < 300) {
						element.invalid = true;
					}
				}
			});
			return root;
		} else {
			// Consider waste if they are more than 300 mm width and height
			if (root.h > 300 && root.w > 300 && this.waste.some(item => item.x == root.x && item.y == root.y && item.w == root.w && item.h == root.h) == false)
				this.waste.push({
					x: root.x,
					y: root.y,
					w: root.w,
					h: root.h
				});
			return null;
		}
	},

	splitNode: function (node, w, h) {
		node.used = true;
		node.down = {
			x: node.x,
			y: node.y + h,
			w: node.w,
			h: node.h - h
		};
		node.right = {
			x: node.x + w,
			y: node.y,
			w: node.w - w,
			h: h
		};
		return node;
	}

}