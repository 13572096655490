<template>
  <el-card class="box-card">
    <b>{{ user.name }}</b>
    <ul class="user-info">
      <li class="list__item">
        <div class="list__attribute">
          Start time:
        </div>
        <div class="list__attribute">
          {{ user.start_time }}
        </div>
      </li>
      <li class="list__item">
        <div class="list__attribute">
          Hrs/week to date:
        </div>
        <div class="list__attribute">
          {{ user.week_to_date }} hrs
        </div>
      </li>
      <li
        v-if="is_manager==true"
        class="list__item"
      >
        <div class="list__attribute">
          Since anniversary:
        </div>
        <div class="list__attribute">
          {{ user.since_anniversary }} hrs
        </div>
      </li>
    </ul>
    <ul
      v-if="is_manager==true"
      class="user-info"
    >
      <li class="list__item">
        <div class="list__attribute">
          Annual leave available:
        </div>
        <div class="list__attribute">
          {{ user.annual_balance }} hrs
        </div>
      </li>
      <li class="list__item">
        <div class="list__attribute">
          Sick leave available:
        </div>
        <div class="list__attribute">
          {{ user.sick_balance }} hrs
        </div>
      </li>
      <li class="list__item">
        <div class="list__attribute">
          Bereavement available:
        </div>
        <div class="list__attribute">
          {{ user.bereavement_balance }} hrs
        </div>
      </li>
      <li class="list__item">
        <div class="list__attribute">
          Alternative holiday available:
        </div>
        <div class="list__attribute">
          {{ user.alternative_holiday_balance }} hrs
        </div>
      </li>
    </ul>
  </el-card>
</template>

<script>
import {loadMyKPIProfile} from '../../api/my_kpi';

export default {
  name: 'UserProfile',
  props: {
    selected_user: {
      type: Number,
      required: true
    },
    is_manager: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      user: {}
    };
  },
  watch: {
    selected_user() {
      this.request_user_profile();
    }
  },
  created() {
    this.request_user_profile();
  },

  methods: {
    request_user_profile() {
      loadMyKPIProfile(this, this.selected_user).then(({data})=>{ this.user = data; });
    },
  }
};
</script>

<style lang="scss" scoped>
.user-info {
  margin-top: 10px;
}

.list__attribute {
  padding: 0 0 0 2px;
}
</style>