import {Request} from '../utilities/Request';

export function loadMyKPIs(this_obj, start_date, end_date, user) {
  return Request.get(
    this_obj,
    '/api/my_kpis',
    null,
    {
      start_date: start_date,
      end_date: end_date,
      user: user
    }
  );
}

export function loadOverall(this_obj, start_date, end_date, user) {
  return Request.get(
    this_obj,
    '/api/my_overall',
    null,
    {
      start_date: start_date,
      end_date: end_date,
      user: user
    }
  );
}

export function loadMyKPIGoals(this_obj, user) {
  return Request.get(
    this_obj,
    '/api/my_kpis_goals',
    null,
    {
      user: user
    }
  );
}

export function loadMyKPIProfile(this_obj, user) {
  return Request.get(
    this_obj,
    '/api/my_kpis_profile',
    null,
    {
      user: user
    }
  );
}
