<template>
    <div v-show="show_errors" class="errors" >
        <h6>{{errors.length}} error(s) prohibited the purchase order from being saved:</h6>
        <ul>
            <li v-for="message in errors">
                {{message}}
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "ErrorExplanation",
        props: {errors: {
                    count: 0,
                    messages: []
                }},
        mounted(){
             
        },
        computed:{
            show_errors(){
                return this.errors.length > 0
            }
        },
        methods:{
            
        }
    }
</script>

<style scoped>
.errors{
    color: red;
    font-size: 12px;
    background-color: #f7ebeb;
    padding: 6px;
    margin-bottom: 10px;
}
</style>