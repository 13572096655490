var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row job_proposals_list"},[_c('div',{staticClass:"col s12 m12"},[_c('div',{staticClass:"card grey lighten-5"},[_c('div',{staticClass:"card-content"},[_c('span',{staticClass:"card-title"},[_vm._v("Tasks list")]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('NewTask',{attrs:{"working_file_id":_vm.working_file_id}}),_vm._v(" "),_c('table',{staticClass:"responsive-table highlight job_proposals_list_table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Working file")]),_vm._v(" "),_c('th',[_vm._v("Name")]),_vm._v(" "),_c('th',{directives:[{name:"show",rawName:"v-show",value:(_vm.proposal.approved == 1 || _vm.proposal.approved == true),expression:"proposal.approved == 1 || proposal.approved == true"}]},[_vm._v("\n                Billable\n              ")]),_vm._v(" "),_c('th',[_vm._v("Is urgent")]),_vm._v(" "),_c('th',{directives:[{name:"show",rawName:"v-show",value:(_vm.proposal.quote_type_id == 5),expression:"proposal.quote_type_id == 5"}]},[_vm._v("\n                Is initiated\n              ")]),_vm._v(" "),_c('th',[_vm._v("Time allocated labour")]),_vm._v(" "),_c('th',[_vm._v("Overwrite labour")]),_vm._v(" "),_c('th',{directives:[{name:"show",rawName:"v-show",value:(_vm.proposal.approved == 1 || _vm.proposal.approved == true),expression:"proposal.approved == 1 || proposal.approved == true"}]},[_vm._v("\n                Time allocated adjusted\n              ")]),_vm._v(" "),_c('th',[_vm._v("Unit sale price")]),_vm._v(" "),_c('th',[_vm._v("Total sale price")]),_vm._v(" "),_c('th',[_vm._v("Action")])])]),_vm._v(" "),_c('tbody',_vm._l((_vm.grouped_job_proposals_list),function(job){return _c('JobProposalEntry',{key:job.id,style:([
                job.mark_as_deleted == 1
                  ? {
                    'text-decoration': 'line-through',
                    'font-style:': 'italic',
                  }
                  : {},
              ]),attrs:{"job":job}})}),1)])],1),_vm._v(" "),_c('div',{staticClass:"card-action"})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }