<template>
  <div>
    <el-badge
      :value="new_mail_count"
      class="badge"
    >
      <el-button
        id="notification-button"
        type
        icon="el-icon-view"
        size="small"
        data-position="top"
        data-tooltip="Notification"
        circle
        @click="drawer = true, load()"
      />
    </el-badge>

    <el-drawer
      title="NOTIFICATIONS"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handle_close"
    >
      <div style="margin-left: 20px;">
        <el-collapse
          v-model="active_names"
          @change="handle_change"
        >
          <el-collapse-item
            title="Tender submitted items"
            name="1"
          >
            <ul>
              <li
                v-for="(tender, index) in tenders"
                id="tender_messages"
                :key="index"
                :class="{'greyl': index % 2 !== 0 }"
                style="padding: 8px;"
              >
                <span>{{ tender.supplier_name }}, {{ tender.submitted_at }} {{ tender.item_name }}</span>
                {{ tender.job_name }}
                <a
                  :href="tender.proposal_link"
                >Quote # {{ tender.proposal_id }}</a>
              </li>
            </ul>
          </el-collapse-item>
          <el-collapse-item
            title="Tender messages"
            name="2"
          >
            <ul>
              <li
                v-for="(message, index) in messages"
                id="tender_messages"
                :key="index"
                :class="{'greyl': index % 2 !== 0 }"
                style="padding: 8px;"
              >
                <span>{{ message.user_name }}, {{ message.created_at }}</span>

                <a :href="message.proposal_href">Quote # {{ message.proposal_id }}</a>
              </li>
            </ul>
          </el-collapse-item>

          <el-collapse-item
            title="Emails"
            name="3"
          >
            <ul>
              <li id="emails_notify" />
            </ul>
          </el-collapse-item>
        </el-collapse>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import Notifications from './Notifications.vue';

export default {
  name: 'Notifications',
  components: {
    notifications: Notifications
  },
  data() {
    return {
      active_names: ['1', '2'],
      model: '',
      record_id: '',
      drawer: false,
      direction: 'ltr',
      tenders: [],
      messages: []
    };
  },
  watch: {},
  mounted() {},
  created() {},
  computed: {
    ...mapGetters(['new_mail_count', 'unseen_emails'])
  },
  methods: {
    ...mapActions(['fetch_all_accounts_emails_unseen']),
    load() {
      this.get_submitted_stock_items_for_notification();
      this.get_tender_messages();
      //this.fetch_all_accounts_emails_unseen();
      console.log(this.unseen_emails);
    },
    get_submitted_stock_items_for_notification() {
      return new Promise((resolve, reject) => {
        this.$http
          .get('/tenderiq/get_submitted_stock_items_for_notification')
          .then(
            response => {
              resolve((this.tenders = response.data.submitted_stock_items));
            },
            function(response) {
              reject(response);
            }
          );
      });
    },
    get_tender_messages() {
      return new Promise((resolve, reject) => {
        this.$http.get('/tenderiq/get_all_messages').then(
          response => {
            resolve((this.messages = response.data.messages));
          },
          function(response) {
            reject(response);
          }
        );
      });
    },
    handle_close(done) {
      done();
    },
    handle_change(val) {}
  }
};
</script>

<style scoped>
.badge {
  margin-top: 5px;
  margin-left: 5px;
}

.greyl {
  background: #f7f7f7;
  width: 100%;
  border-bottom: 1px solid grey;
   border-top: 1px solid grey;
}
</style>