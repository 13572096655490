<template>
  <el-dialog
    id="invoice__master-payment-dialog"
    :visible.sync="show"
    :width="width"
    :class="dialog_class"
    @closed="onCloseDialog"
  >
    <h2 class="text__center">
      Master Payments
    </h2>
    <div class="text__center">
      Date Received: {{ master_payment.date }}
    </div>
    <div>
      Amount: $
      <el-input
        v-model="payment"
        class="input__width"
        @change="onChangeAmount"
      />
    </div>
    <b>Payments</b>
    <br>
    <div v-if="master_payment.payments.length > 0">
      <el-row
        v-for="(attached,index) in master_payment.payments"
        :key="index"
      >
        <el-col :span="8">
          4000{{ attached.invoice_id }}
        </el-col>
        <el-col :span="9">
          {{ attached.payment_received }}
        </el-col>
        <el-col
          :span="5"
          class="align__right"
        >
          <Money :amount="attached.payment_amount" />
        </el-col>
        <el-col
          :span="2"
          class="align__right"
        >
          x
        </el-col>
      </el-row>
    </div>
    <div v-else>
      No Payments
    </div>

    <div class="dash-line" />
    <el-row>
      <el-col :span="18">
        <div class="result-total">
          Total Use:
        </div>
      </el-col>
      <el-col
        :span="4"
        class="align__right"
      >
        <div class="result-total">
          <Money :amount="total_used" />
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="18">
        UN-ASSIGNED:
      </el-col>
      <el-col
        :span="4"
        class="align__right"
      >
        <Money :amount="unassigned_total" />
      </el-col>
    </el-row>
    <div class="dash-line" />

    <br>
    <b>Make a payment:</b>
    <AddPayment
      :account_id="account_id"
      :master_payment="master_payment"
    />
    <br>

    <el-button
      v-if="master_payment.payments.length == 0"
      type="danger"
      @click="onDelete"
    >
      Delete
    </el-button>

    <span
      slot="footer"
    >
      <el-button
        type="info"
        @click="show = false;"
      >
        Close
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import AddPayment from './AddPayment';
import Money from '../../../../shared/Money';
import {EventBus} from '../../../../../utilities/EventBus';
import {deleteMasterPaymentPayment, updateAmount} from '../../../../../api/master_payment';
export default {
  name: 'PaymentsDialog',
  components: {AddPayment, Money},
  props: {
    show_dialog: {
      type: Boolean,
      required: true
    },
    account_id: {
      type: String,
      required: true
    },
    master_payment: {
      type: Object,
      required: true
    },
  },
  data(){
    return {
      show: this.show_dialog,
      width: '40%',
      media_query_sm: window.matchMedia('(min-width: 1400px)'),
      invoice_no: '',
      payment: this.master_payment.payment,
      dialog_class: 'original__margin',
    };
  },
  computed: {
    total_used() {
      let total_used = 0;
      this.master_payment.payments.forEach((payment)=>{
        total_used += parseFloat(payment.payment_amount);
      });
      return total_used;
    },
    unassigned_total() {
      let payment = Math.round((Number(this.payment) + Number.EPSILON) * 100) / 100;
      if(isNaN(payment)) return '';
      let left = payment - this.total_used;
      if(left < 0) return '';

      return left;
    }
  },
  watch: {
    show_dialog(val) {
      this.show = val;
    },
    'master_payment.payment'(newVal) {
      this.payment = newVal;
    }
  },
  created() {
    this.change_width();
    this.media_query_sm.addEventListener('change', () => {
      this.change_width();
    });
  },
  methods: {
    onCloseDialog() {
      this.$emit('update:show_dialog', false );
    },
    onChangeAmount() {
      updateAmount(this, this.master_payment.id, this.payment).then(({data})=>{
        this.$message({
          type: 'success',
          message: data.message
        });
        EventBus.$emit('refreshAllInvoice');
      });
    },
    onDelete() {
      this.$confirm('This will permanently delete the Payment. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(()=> {
        deleteMasterPaymentPayment(this, this.master_payment.id)
          .then(({data}) => {
            this.$message({
              type: 'success',
              message: data.message
            });
            EventBus.$emit('refreshAllInvoice');
            this.$emit('update:show_dialog', false);
          });
      });
    },
    change_width() {
      if(this.media_query_sm.matches) {
        this.width = '40%';
        this.dialog_class = 'right__margin';
      } else {
        this.width = '70%';
        this.dialog_class = 'original__margin';
      }
    },
  },
};
</script>


<style lang='scss'>
#invoice__master-payment-dialog.original__margin {
  .el-dialog {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

#invoice__master-payment-dialog.right__margin {
  .el-dialog {
    margin-left: 50% !important;
    margin-right: 50% !important;
  }
}
</style>

<style lang='scss' scoped>
.dash-line {
  border-bottom:1px dashed #000;
  float: left;
  width:100%;
  margin-top:10px;
  margin-bottom:10px
}

.result-total {
  font-weight:bold;
  font-size:16px;
}

.input__width {
  width: 80%;
}
</style>
