<template>
  <div>
    <div class="card  ">
      <div class="card-content ">
        <span class="card-title">
          Work in Progress (WIP)
          <el-select
            v-model="selected_branches"
            size="small"
            multiple
            collapse-tags
            style="margin-left: 40px; "
            placeholder="Select branch(es)"
            @change="fill_data"
          >
            <el-option
              v-for="branch in branches_list"
              :key="branch.id"
              :label="branch.name"
              :value="branch.id"
            />
          </el-select>
        </span>

        <el-date-picker
          v-model="selected_date_range"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          style="width: auto !important;"
          :picker-options="pickerOptions2"
          @change="fill_data"
        />
        <br>
        <br>
        <table>
          <tr class="header">
            <th>COST</th>
          </tr>
          <tr class="rows">
            <td>
              <span class="center">{{ total_cost | currency }}</span>
            </td>
          </tr>
        </table>

        <pie-chart
          :chart-data="datacollection"
          :options="chartOptions"
        />
      </div>
      <div class="card-action">
        <button v-on:click="show_cost_list" class="btn btn-small">
          Cost details
        </button>
      </div>
    </div>
    <modal
      name="records_list"
      :height="700"
      :width="1200"
      :adaptive="true"
    >
      <div class="card ">
        <div class="card-content  ">
          <span
            class="card-title"
          >{{ list_title }}
            <hr></span>
          <div
            v-show="is_loading"
            class="progress"
          >
            <div class="indeterminate" />
          </div>
          <el-table
            :data="rows"
            style="width: 100%"
            height="600"
          >
            <el-table-column
              v-for="column in columns"
              :key="column"
              :prop="column"
              :label="column | capitalize"
              sortable
            />
          </el-table>
        </div>
        <div class="card-action" />
      </div>
    </modal>
  </div>
</template>
<script>
import PieChart from './PieChart';

export default {
  name: 'WorkInProgress',
  components: {
    PieChart
  },
  data() {
    return {
      branches_list: [],
      selected_branches: [],
      datacollection: null,
      total_cost: 0,
      columns: [],
      rows: [],
      list_title: '',
      is_loading: false,
      pickerOptions2: {
        shortcuts: [
          {
            text: 'Current month',
            onClick(picker) {
              const end = new Date();
              const start = new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                1
              );
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last week',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last month',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last 3 months',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last 6 months',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last year',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
      selected_date_range: ''
    };
  },
  computed: {
    current_date() {
      return new Date();
    },
    start_date() {
      return new Date(
        this.current_date.getFullYear(),
        this.current_date.getMonth(),
        1
      );
    },
    end_date() {
      return new Date(
        this.current_date.getFullYear(),
        this.current_date.getMonth(),
        this.current_date.getDate()
      );
    }
  },
  mounted() {
    this.load_branches_list();
    this.selected_date_range = [this.start_date, this.end_date];
    //this.fill_data();
  },
  created() {},
  methods: {
    load_branches_list() {
      let self = this;
      self.is_loading = true;
      this.$http.get('/branches_list').then(response => {
        this.branches_list = response.data;
        this.selected_branches = this.branches_list.map(c=>c.id);
        this.fill_data();
        self.is_loading = false;
      });
    },
    fill_data() {
      this.$http
        .post('/get_work_in_progress_info', {
          start_date: this.selected_date_range[0],
          end_date: this.selected_date_range[1],
          branches_ids: this.selected_branches
        })
        .then(response => {
          if (response.data.status == 200) {
       
            let quote_type_cost = response.data.data.quote_type_cost;
            this.total_cost = quote_type_cost.total_cost
            let bg_colors = Array(quote_type_cost.total.length)
              .fill("lightgreen")
              
            this.datacollection = {
              labels: quote_type_cost.quote_names,
              datasets: [
                {
                  label: "Proposals",
                  backgroundColor: bg_colors,
                  data: quote_type_cost.total
                }
              ]
            };
          }
        });
    },
   
    show_cost_list() {
      this.open_modal();
      this.columns = [];
      this.rows = [];
      this.list_title = "Quotes unapproved quote types";
      this.is_loading = true;
      this.$http
        .post("/get_proposal_unapproved_category_type", {
          start_date: this.selected_date_range[0],
          end_date: this.selected_date_range[1],
          branches_ids: this.selected_branches
        })
        .then(response => {
          this.is_loading = false;
          if (response.data.status == 200) {
            this.columns = response.data.data.columns;
            this.rows = response.data.data.rows;
          }
        });
    },
    open_modal() {
      this.$modal.show('records_list');
    },
    hide_modal() {
      this.$modal.hide('records_list');
    },
    chartOptions: function() {
      return { responsive: true, maintainAspectRatio: false };
    }
  }
};
</script>

<style scoped>
td,
th {
  text-align: center;
  padding: 10px;
}

th {
  font-weight: 800;
}

.header {
  background-color: #ddecf9;
}

.subheader {
  background-color: #e5f3ff;
}

.rows {
  background-color: aliceblue;
}
</style>
