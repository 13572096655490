<template>
  <div>
    <div class="summary">
      Open Invoice Count: {{ overdue_invoices_count }} / {{ open_invoices_count }} (Overdue / Total Open)
    </div>
  </div>
</template>

<script>
export default {
  name: 'OverdueSummary',
  props: {
    open_invoices_count: {
      type: Number,
      required: true
    },
    overdue_invoices_count: {
      type: Number,
      required: true
    },
  },
};
</script>

<style lang='scss' scoped>
.summary {
  font-weight: 500;
}
</style>