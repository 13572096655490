import {Request} from '../utilities/Request';

export function pay_run_transaction_monthly(this_obj, month, picker) {
  return Request.post(
    this_obj,
    '/api/pay_runs/pay_run_transaction_monthly',
    null,
    {
      month: month,
      picker: picker
    }
  );
}

export function pay_transaction_summary_sheet(this_obj, pay_period_id) {
  return Request.get(
    this_obj,
    `/api/pay_runs/${pay_period_id}/pay_transaction_summary_sheet`,
    null,
    {
    },
    null,
    'text',
    false
  );
}


