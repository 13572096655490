<template>
  <el-card
    :body-style="{ padding: '0px' }"
    :class="{ active_page: file.id === working_file_id }"
  >
    <div>
      <div class="header-container">
        <i
          v-show="show_page_menu"
          class="el-icon-rank handle drag-handler"
        />
        <el-checkbox
          v-if="proposal.approved !== 1"
          v-model="is_included"
          class="check-right"
          @change="onChangedIncluded"
        />
      </div>
      <div
        style="cursor: pointer"
        @click.prevent="load_file(file.id)"
      >
        <img
          :src="file.thumb"
          style="border: 1px solid #e4dede;width: 30px; max-height: 40px;"
        >
        <div
          class="label"
          :title="file.name"
        >
          {{ file.name }}
        </div>
        <strong>{{ file.position + 1 }}</strong>
      </div>
      <div v-if="client_view === false">
        <el-dropdown
          v-if="working_file_categories.length > 1 && proposal.approved !== 1"
          class="dropdown"
          @command="onMoveTo"
        >
          <el-button
            size="mini"
            style="width: 100%"
          >
            Move To
            <i class="el-icon-arrow-down el-icon&#45;&#45;right" />
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="category in move_to_categories_list"
              :key="category.id"
              :command="category.id"
              icon="el-icon-position"
            >
              {{ category.name }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <br v-if="working_file_categories.length > 1 && proposal.approved !== 1">
        <el-dropdown
          class="dropdown"
          @command="onCommand"
        >
          <el-button
            type="primary"
            size="mini"
            style="width: 100%"
          >
            Actions
            <i class="el-icon-arrow-down el-icon&#45;&#45;right" />
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              icon="el-icon-edit"
              :command="{ file: file, action: 'edit_details'}"
            >
              Edit details
            </el-dropdown-item>
            <el-dropdown-item
              icon="el-icon-notebook-1"
              :command="{ file: file, action: 'edit_note' }"
            >
              Update Note
            </el-dropdown-item>
            <el-dropdown-item
              icon="el-icon-delete"
              :command="{ index: file.position, file: file, action: 'delete_file' }"
            >
              Delete file
            </el-dropdown-item>
            <el-dropdown-item
              divided
              icon="el-icon-news"
              :command="{ file: file, action: 'duplicate_file' }"
            >
              Duplicate file
            </el-dropdown-item>
            <el-dropdown-item
              v-show="file.id == working_file_id && file.pdf_path != null"
              icon="el-icon-picture-outline"
              :command="{ file: file, action: 'add_vector' }"
            >
              Import original vector
            </el-dropdown-item>
            <el-dropdown-item
              icon="el-icon-share"
              :command="{	file: file, action: 'export_to_templates' }"
            >
              Export to templates
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </el-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { editor_store } from 'store/modules/editor';
import {EventBus} from '../../../utilities/EventBus';
import {moveToCategory, updateIsIncluded} from '../../../api/working_files';


export default {
  name: 'WorkingFile',
  store: editor_store,
  props: {
    file: {
      type: Object,
      required: true
    },
    save_file: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      is_included: this.file.is_included
    };
  },
  computed: {
    ...mapGetters([
      'proposal',
      'client_view',
      'working_file_id',
      'file_changed',
      'working_file_categories',
      'get_active_working_file_category'
    ]),
    show_page_menu() {
      return !(this.client_view === true || this.proposal.approved === 1);
    },
    move_to_categories_list() {
      return this.working_file_categories.filter((c)=> Number(c.id) !== Number(this.get_active_working_file_category));
    }
  },
  watch:{
    'file.is_included'(newVal) {
      this.is_included = newVal;
    }
  },
  methods: {
    ...mapActions([
      'set_working_file_id'
    ]),
    onCommand(command) {
      this.$emit('command', command);
    },
    onChangedIncluded(is_included) {
      updateIsIncluded(this, this.file.id, is_included).then(({data})=> {
        this.$message({
          type: 'success',
          message: data.message
        });
        EventBus.$emit('reloadCategoryWorkingFiles');
        EventBus.$emit('reloadItems');
      }).catch(()=>{
        this.is_included = !this.is_included;
      });
    },
    onMoveTo(category_id) {
      moveToCategory(this, this.file.id, category_id).then(({data})=>{
        this.$message({
          type: 'success',
          message: data.message
        });
        EventBus.$emit('reloadCategoryWorkingFiles');
      });
    },
    load_file(id) {
      if (this.file_changed === true) {
        this.save_file(undefined).then(() => {this.set_working_file_id(id);});
      } else {
        this.set_working_file_id(id);
      }
    },
  }
};
</script>

<style scoped>
.dropdown {
  width: 95%;
  margin-top: 5px;
}
.header-container {
  position: relative;
}
.check-right {
  position: absolute;
  top: 0;
  right: 3px;
}
</style>