<template>
  <div id="pay-cycles">
    <p>
      New Pay Cycle:
      <el-select
        v-model="new_pay_cycle.pay_frequency_id"
        placeholder="Select pay frequency"
        @change="show_card = true"
      >
        <el-option
          v-for="item in pay_frequencies"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
    </p>
    <el-card
      v-show="show_card"
    >
      <el-form
        ref="pay_cycle_form"
        :model="new_pay_cycle"
        label-width="150px"
        autocomplete="off"
      >
        <el-form-item
          label="Pay Cycle Name:"
          required
        >
          <el-input
            v-model="new_pay_cycle.name"
            size="small"
            style="width: 280px;"
          />
        </el-form-item>
        <el-form-item
          label="Pay Frequency:"
          required
          size="small"
        >
          <el-select
            v-model="new_pay_cycle.pay_frequency_id"
            style="width: 280px;"
          >
            <el-option
              v-for="item in pay_frequencies"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="Next Pay Period"
          required
        >
          <el-date-picker
            v-model="new_pay_cycle.start"
            style="width: 280px;"
            format="dd.MM.yyyy"
            size="small"
            type="datetime"
          />
        </el-form-item>

        <el-form-item
          label="Next Pay Day"
          prop="next_pay_day"
          required
        >
          <el-select
            v-model="new_pay_cycle.next_pay_day"
            style="width: 280px;"
          >
            <el-option
              v-for="(item,index) in pay_days"
              :key="index"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            size="small"
            style="float:right;"
            @click="create_pay_cycle"
          >
            Save
          </el-button>
          <el-button
            size="small"
            style="float:right;"
            @click="cancel_pay_cycle"
          >
            Cancel
          </el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card
      v-for="item in pay_cycles"
      :key="item.id"
    >
      <table id="pay-cycle-list">
        <th>Name:</th>
        <th>Pay Frequency:</th>
        <th>Next Pay Period:</th>
        <th>Next Pay Day:</th>
        <tr>
          <td><span>{{ item.name }}</span></td>
          <td><span>{{ get_pay_frequency_name(item.pay_frequency_id) }}</span> </td>
          <td><span style="color: #409EFF">{{ item.start }} - {{ item.ends }}</span> </td>
          <td><span>{{ item.next_pay_day }}</span></td>
        </tr>
      </table>
      <el-divider />
      <el-table
        ref="tablePayCycle"
        :data="employee_list.filter(user => user.inactive === false && user.pay_cycle_id === item.id)"
        style="width: 100%"
      >
        <el-table-column
          label="Employee"
          prop="name"
        >
          <template #default="scope">
            {{ scope.row.user_name }} {{ scope.row.user_surname }}
          </template>
        </el-table-column>
        <el-table-column
          label="Payslip Email"
          prop="payslip_email"
        >
          <template #default="scope">
            {{ scope.row.payslip_email }}
          </template>
        </el-table-column>
        <el-table-column
          align="right"
        >
          <template #default="scope">
            <el-button
              size="mini"
              @click="edit_employee(scope.row)"
            >
              Edit
            </el-button>
            <el-button
              size="mini"
              type="danger"
              plain
              @click="remove_employee(scope.row)"
            >
              Remove
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <br>
      <el-button
        type="primary"
        size="small"
        @click="employee_dialog = true, add_employees_to_pay_cycle(item.id)"
      >
        Add Employees
      </el-button>
      <el-divider />
      <el-tooltip placement="top">
        <div slot="content">
          Delete Pay Cycle
        </div>
        <el-button
          type="danger"
          style="float:right;"
          icon="el-icon-delete"
          circle
          @click="confirm_delete(item.id)"
        />
      </el-tooltip>
      <br>
    </el-card>
    <el-dialog
      width="50%"
      :visible.sync="employee_dialog"
      title="Add Employees"
      @opened="set_default_input"
    >
      <el-table
        ref="multipleTable"
        :data="employee_list.filter(user => user.inactive === false && user.pay_cycle_id === null)"
        style="width: 100%"
        stripe
        @selection-change="handleSelectionChange"
        @opened="get_employees()"
      >
        <el-table-column
          type="selection"
          width="55"
        />
        <el-table-column
          label="Name"
          property="name"
        >
          <template #default="scope">
            {{ scope.row.user_name }} {{ scope.row.user_surname }}
          </template>
        </el-table-column>
        <el-table-column
          label="Payslip Email"
        >
          <template #default="scope">
            {{ scope.row.payslip_email }}
          </template>
        </el-table-column>
      </el-table>
      <el-divider />
      <div>
        <el-button
          type="primary"
          size="small"
          style="float:right;margin-left:15px;"
          @click="add_selected_employees()"
        >
          Add Employee
        </el-button>
        <el-button
          style="float:right;"
          size="small"
          @click="clear_selection()"
        >
          Clear selection
        </el-button>
        <br>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {pay_cycles, create_pay_cycle, delete_pay_cycle, remove_employee_from_pay_cycle, add_selected_employees} from '../../api/pay_cycles';
import {set_default_input} from '../../utilities/DefaultInput';
export default {
  name: 'PayCycles',
  data() {
    return {
      pay_frequencies: [],
      pay_cycles: [],
      employee_list: [],
      show_card: false,
      employee_dialog: false,
      new_pay_cycle: {name: '', pay_frequency_id: null, start: new Date().toISOString().slice(0,10), next_pay_day: 'Wednesday'},
      pay_days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      selected_employees: [],
      selected_pay_cycle_id: null
    };
  },
  created() {
    this.get_pay_frequencies();
    this.get_organization_details();
    this.get_pay_cycles();
    this.get_employees();
  },
  methods: {
    set_default_input,
    get_pay_cycles() {
      pay_cycles(this).then((response)=> {
        this.pay_cycles = response.data;
      });
    },
    get_employees() {
      this.$http.get('/api/pay_cycles/employees').then((response) => {
        this.employee_list = response.data;
      });
    },
    get_pay_frequencies() {
      this.$http.get('/pay_frequencies').then((response) => {
        this.pay_frequencies = response.data;
      });
    },
    get_organization_details() {
      this.$http.get('/organization_details').then((response) => {
        this.organization_details = response.data;
      });
    },
    create_pay_cycle(){
      create_pay_cycle(this, this.new_pay_cycle).then(()=> {
        this.get_pay_cycles();
        this.show_card = false;
        this.$emit('reload_employees');
      });
    },
    cancel_pay_cycle(){
      this.new_pay_cycle = {};
      this.show_card = false;
    },
    delete_pay_cycle(id){
      delete_pay_cycle(this, id).then((response)=> {
        this.$message({
          type: 'success',
          message: response.data.message
        });
        this.get_pay_cycles();
        this.get_employees();
        this.$emit('reload_employees');
      });
    },
    get_pay_frequency_name(id){
      return this.pay_frequencies.find((u) => u.id === id).name || null;
    },
    edit_employee(row) {
      window.open(`/crm_users/${row.id}/edit`, '_blank');
    },
    remove_employee(row) {
      remove_employee_from_pay_cycle(this, row.id).then(()=> {
        this.employee_list.splice( this.employee_list.findIndex(function(i){
          return i.id === row.id;
        }), 1);
        this.get_employees();
        this.$emit('reload_employees');
      });
    },
    clear_selection() {
      this.$refs.multipleTable.clearSelection();
    },
    add_employees_to_pay_cycle(id){
      this.selected_pay_cycle_id = id;
    },
    add_selected_employees(){
      add_selected_employees(this, this.selected_pay_cycle_id, this.selected_employees).then((response)=> {
        this.$message({
          type: 'success',
          message: response.data.message
        });
        this.get_employees();
        this.employee_dialog = false;
        this.$emit('reload_employees');
      });
    },
    handleSelectionChange(val) {
      this.selected_employees = val;
    },
    confirm_delete(id){
      this.$confirm('This will permanently delete the pay cycle with all transactions. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.delete_pay_cycle(id);
        this.$message({
          type: 'success',
          message: 'Delete completed'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    }
  }
};
</script>
<style scoped>
 th {font-size: 12px;font-weight: normal;color: gray;width: 25%;}
 td, th {padding: 0px;}
 .el-card { margin-bottom: 20px;}
</style>
