<template>
  <div style="padding-top:20px; padding-bottom:10px;border-bottom:2px solid #e6e4e6" class="col l6 m6 s12">
    <i class="el-icon-message" />
    <el-select
        ref="mailbox_select"
        v-model="current_selected"
        placeholder="Select mailbox"
        style="margin-top: -16px;margin-right: 16px;width: 380px;"
        @change="connect_to_mailbox"
    >
      <el-option
          v-for="mailbox in mailboxes"
          :key="mailbox.value"
          size="mini"
          
          :label="mailbox.label"
          :value="mailbox.value"
      />
    </el-select>
    <template>
  <el-dropdown>
    <span class="el-dropdown-link">
      <i class="el-icon-s-unfold"></i>
      <el-icon class="el-icon--right">
        <arrow-down />
      </el-icon>
    </span>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item><email-invitation :mailbox_id="current_selected"></email-invitation></el-dropdown-item>
        <el-dropdown-item><email-vacation :mailbox_id="current_selected"></email-vacation></el-dropdown-item>
        <el-dropdown-item><email-signature :mailbox_id="current_selected"></email-signature></el-dropdown-item>
        <el-dropdown-item><el-link href="/mailbox#/Contact" >Contacts</el-link></el-dropdown-item>
     </el-dropdown-menu>
      <el-tooltip placement="top">
        <div slot="content">
          Compose
        </div>
          <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="compose_link()"
          />
      </el-tooltip>
    </template>
  </el-dropdown>
</template>
    <div class="col l6 m6 s12 right">
     <email-meetings :mailbox_id="current_selected"></email-meetings>
    </div>
  </div>
</template>
<script>

import {mapActions} from 'vuex';
import EmailSignature from '../user/EmailSignature';
import EmailVacation from './EmailVacation';
import EmailMeetings from './EmailMeetings';
import EmailInvitation from './EmailInvitation';

export default {
  name: 'ConnectToMailbox',
  components: {EmailInvitation, EmailSignature, EmailVacation, EmailMeetings},
  data() {
    return {
      mailboxes: [],
      current_selected: null
    };
  },
  mounted() {
    this.$refs.mailbox_select.$el.getElementsByTagName('input')[0].className +=
        ' browser-default';
  },
  created() {
    this.get_shared_mailboxes();
  },

  methods: {
    ...mapActions([
      'get_emails',
      'clear_emails',
      'set_infinite_id',
      'count_mails',
      'set_first_load',
      'no_mailbox'
    ]),

    push_id(id) {
      // push notification comes in on click
      console.log('push_id' + id);
      if (this.current_selected != id) {
        this.current_selected = id;
        this.connect_to_mailbox();
      }
    },
    compose_link() {
      return (
          window.open('/mailbox#/compose?mailbox_account_id=' + this.current_selected, '_blank' ).focus()
      );
    },
    connect_to_mailbox() {
      let action = 'reload';

      if (
          this.$route.query.action == 'forward' ||
          this.$route.query.action == 'reply'
      ) {
        action = 'noreload';
      }

      this.$http
          .post('/connect_to_mailbox', {
            mailbox_account_id: this.current_selected
          })
          .then(response => {
            if (response.body) {
              if (action == 'reload') {
                window.location.href = '/mailbox#/inbox/Inbox';
                window.location.reload();
                let bc = new BroadcastChannel('email_account');
                bc.postMessage('email_account_changed');
              }
            }
          });
    },
    get_shared_mailboxes() {
      this.$http
          .post('/get_shared_mailboxes', {crm_user_id: this.crm_user_id})
          .then(
              response => {
                if (response.body) {
                  //console.log(response.data.mailboxes)
                  this.current_selected = response.data.current_mailbox_id;
                  this.mailboxes = response.data.mailboxes;
                }
              },
              response => {
                this.no_mailbox();
              }
          );
          
    }
    
  }
  
};

</script>