<template>
  <div v-loading="loading">
    <el-dialog
      :visible.sync="branch_edit_dialog"
      title="Branch Info"
      width="50%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        label-position="left"
        :rules="rules"
        v-model="selected_branch"
        label-width="120px"
      >
        <el-form-item label="Name">
          <el-input v-model="selected_branch.name" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="Trading name">
          <el-input
            v-model="selected_branch.trading_name"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item label="Address">
          <el-input v-model="selected_branch.address" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="Phone">
          <el-input v-model="selected_branch.phone" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="Email">
          <el-input v-model="selected_branch.email" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="Domain">
          <el-input
            v-model="selected_branch.domain_name"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item label="website">
          <el-input v-model="selected_branch.website" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="Logo URL">
          <el-input
            v-model="selected_branch.logo_image_url"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item label="Footer">
          <el-input
            v-model="selected_branch.html_footer"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item label="Quote email">
          <small>
            <b><span v-html="'{{ACOUNT_NAME}}'"></span></b> represents account
            name.</small
          >
          <br />
          <jodit-editor v-model="selected_branch.quote_mail" />
        </el-form-item>
        <el-form-item label="">
          <br />
          <span>Invoice & Statement description, between address and table</span>
          <br />
          <jodit-editor v-model="selected_branch.invoice_description" />
        </el-form-item>
        <el-form-item label="">
          <br />
          <span>Quote Terms & Conditions</span>
          <br />
          <jodit-editor v-model="selected_branch.html_quote_terms" />
        </el-form-item>
        <el-form-item label="">
          <br />
          <span>Supplier Email Text</span>
          <br />
          <jodit-editor v-model="selected_branch.supplier_email_text" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="branch_edit_dialog = false">Cancel</el-button>
        <el-button type="primary" @click="save_branch">Confirm</el-button>
      </span>
    </el-dialog>

    <strong>Branches list</strong>
    <el-row>
      <el-button
        class="right"
        button
        type="primary"
        plain
        size="small"
        v-on:click="add_new_branch"
        style="margin-top: -10px;"
        >New Branch</el-button
      >
    </el-row>
    <hr />
    <el-table :data="branches_list">
      <el-table-column prop="name" label="Name"></el-table-column>
      <el-table-column
        prop="trading_name"
        label="Trading Name"
      ></el-table-column>
      <el-table-column prop="address" label="Address"></el-table-column>
      <el-table-column prop="domain_name" label="Domain"></el-table-column>
      <el-table-column prop="website" label="Website"></el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <el-button
            icon="el-icon-edit"
            size="mini"
            circle
            @click="edit_branch(scope.row)"
          ></el-button>
          <el-button
            type="danger"
            icon="el-icon-delete"
            size="mini"
            circle
            @click="delete_branch(scope)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  name: "Branches",
  data() {
    return {
      branches_list: [],
      selected_branch: {},
      loading: false,
      branch_edit_dialog: false,
      rules: {
        name: [{ required: true, message: "name is required" }],
      },
    };
  },
  mounted() {
    this.load_branches();
  },
  methods: {
    load_branches() {
      this.loading = true;
      this.$http.get("/branches").then((response) => {
        this.loading = false;
        this.branches_list = response.data;
      });
    },
    add_new_branch() {
      this.clear_branch();
      this.branch_edit_dialog = true;
    },
    edit_branch(row) {
      this.clear_branch();
      this.branch_edit_dialog = true;
      this.selected_branch = row;
    },
    delete_branch(scope) {
      let _this = this;
      this.$confirm(
        "This will permanently delete the record. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then((response) => {
        _this.loading = true;
        this.$http.delete("/branches/" + scope.row.id).then(
          (response) => {
            this.$message({
              type: "success",
              message: "Delete completed",
            });
            _this.loading = false;
            _this.branch_edit_dialog = false;
            _this.$delete(this.branches_list, scope.$index);
          },
          (response) => {
            _this.loading = false;
            this.$message({
              type: "error",
              message:
                "Error happened while deleting branch: " +
                response.data.message,
            });
          }
        );
      });
    },
    save_branch() {
      if (this.selected_branch.name.trim() == "") {
        this.$message({ type: "warning", message: "Name is required" });
        return false;
      }
      let url = "";
      let type = "";
      if (this.selected_branch.id == "") {
        url = "/branches";
        type = "post";
      } else {
        url = `/branches/${this.selected_branch.id}`;
        type = "patch";
      }
      this.loading = true;
      this.$http[type](url, { branch: this.selected_branch }).then(
        (response) => {
          this.loading = false;
          this.$message({ type: "success", message: "Saved successfully" });
          this.load_branches();
          this.branch_edit_dialog = false;
        },
        (response) => {
          this.$message({
            type: "error",
            message:
              "Error happened while saving branch information: " +
              response.data.message,
          });
          this.loading = false;
        }
      );
    },
    clear_branch() {
      this.selected_branch = {
        id: "",
        name: "",
        trading_name: "",
        logo_image_url: "",
        address: "",
        phone: "",
        invoice_description: "",
        html_footer: "",
        email: "",
        quote_mail: "",
        domain_name: "",
        website: "",
      };
    },
    set_default_input(mills = 300) {
      let _this = this;
      setTimeout(function() {
        Array.from(document.querySelectorAll(".el-input__inner")).forEach(
          function(e, i) {
            e.classList.add("browser-default");
            if (e.querySelector("input") != null)
              e.querySelector("input").className += " browser-default";
          }
        );
      }, mills);
    },
  },
  watch: {
    branch_edit_dialog: function(newVal) {
      let _this = this;
      if (newVal == false) this.selected_branch = {};
      else
        setTimeout(function() {
          _this.set_default_input();
        }, 1000);
    },
  },
};
</script>
