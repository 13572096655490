<template>
  <div id="manager">
    <el-button
        id="filemanager-button"
        ref="search_btn"
        type="primary"
        data-position="top"
        data-tooltip="File manager"
        data-search
        size="mini"
        @click="show_file_manager = true"
    >Files</el-button>
    <el-dialog
        :visible.sync="show_file_manager"
        title="File Manager"
        width="98%"
        append-to-body
    >
      <el-radio-group v-model="checkbox_selected" size="mini" id="radio-button-select">
        <el-radio-button label="edit_proposal">Quote / Job</el-radio-button>
        <el-radio-button label="purchase_orders">Purchase Order</el-radio-button>
        <el-radio-button label="any">Any</el-radio-button>
      </el-radio-group>
      <br>
      <filebrowser :model="model" :record_id="record_id" @file_uploaded="file_uploaded()" />
    </el-dialog>
  </div>
</template>

<script>
import Filebrowser from './Filebrowser.vue';

export default {
  name: 'Filemanager',
  components: {
    filebrowser: Filebrowser
  },
  props: ['current_email_account'],
  data() {
    return {
      model: '',
      record_id: '',
      show_file_manager: false,
      checkbox_selected: 'edit_proposal'
    };
  },
  watch: {
    checkbox_selected() {
       this.model = this.checkbox_selected;
    }
  },
  mounted() {
  },
  created() {
    this.set_context();
  },
  methods: {
    file_uploaded(){
      this.$emit('file_uploaded', true);
    },
    set_context() {
      let current_url = window.location.pathname;
      this.model = current_url.split('/')[1];
      this.record_id = current_url.split('/')[3];
      let is_model = false;

      if(this.model == 'purchase_orders'){
        // purchase_orders/40733/edit
        this.record_id = current_url.split('/')[2];
        this.checkbox_selected = 'purchase_orders'
        is_model = true;
      }

      if(this.model == 'simple_job_view'){
        // simple_job_view/40733
        this.record_id = current_url.split('/')[2];
        this.checkbox_selected = 'edit_proposal'
        is_model = true;
      }

      if(this.model == 'edit_proposal' || this.model == 'job_view'){
        // edit_proposal?id=10002 || job_view?id=62556
        this.record_id = window.location.href.split('id=')[1];
        this.checkbox_selected = 'edit_proposal'
        is_model = true;
      }

      if(!is_model){
        this.record_id = null
      }

      if(this.model == 'any' || this.record_id == undefined){
        this.checkbox_selected = 'any'
      }

      // if (this.record_id != undefined) {
      //   if (!this.record_id.match(/\d+/g))
      //     this.record_id = current_url.split('/')[2];
      // }

      // for email signature image upload
      if (this.current_email_account) {
        this.record_id = this.current_email_account;
      }


    }
  }
};
</script>
<style>
#manager .el-dialog__body {
  padding: 0px !important;
}

#radio-button-select{
  margin-left: 20px;
  margin-bottom: 10px;
}
</style>
