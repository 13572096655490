<template>
  <div>
    <div class="editbutton">
      <i class="el-icon-edit-outline" />
    </div>
    <el-collapse
      v-for="poitem in poitems"
      :key="poitem.id"
      @change="handleChange"
    >
      <el-collapse-item
        name="1"
        class="poItems"
      >
        <div class="inside-po-info">
          <template slot="title">
            {{ poitem.quaity }} x {{ poitem.name }}  | PO:{{ poitem.ordernumber }} | 
    
            <span
              v-show="poitem.delivery"
              style="color:orange;text-transform:uppercase;padding-left:10px;"
            > {{ poitem.status }}   </span>
            <span
              v-show="!poitem.delivery"
              style="color:red;text-transform:uppercase;padding-left:10px;"
            >  {{ poitem.status }} | {{ poitem.supplier }}  </span>
          </template>
          <div
            style="padding:20px; background:#f3f3f3;"
            class="poItems"
          >
            <el-row :gutter="2">
              <p> {{ poitem.description }}</p>
              <el-col
                :xs="24"
                :sm="24"
                :md="12"
                :lg="12"
                :xl="12"
                style="padding:20px;"
              >
                <div
                  class="row no-padding"
                  style="margin-bottom:0px;"
                >
                  <div class="divLeft">
                    <span class="spanlabel">Supplier:</span>
                  </div>
                  <div class="divRight">
                    {{ poitem.supplier }} 
                  </div>
                </div><div
                  class="row no-padding"
                  style="margin-bottom:0px;"
                >
                  <div class="divLeft">
                    <span class="spanlabel">PO:</span>
                  </div>
                  <div class="divRight">
                    {{ poitem.po }} 
                  </div>
                </div>
                <div
                  class="row no-padding"
                  style="margin-bottom:0px;"
                >
                  <div class="divLeft">
                    <span class="spanlabel">Qty Allocated:</span>
                  </div>
                  <div class="divRight">
                    {{ poitem.quanity }} 
                  </div> 
                </div> 
              </el-col>
              <el-col
                :xs="24"
                :sm="24"
                :md="12"
                :lg="12"
                :xl="12"
                style="padding:20px;"
              >
                <div
                  class="row no-padding"
                  style="margin-bottom:0px;"
                >
                  <div class="divLeft">
                    <span class="spanlabel">Min-Packaging:</span>
                  </div>
                  <div class="divRight">
                    {{ poitem.min_package }}
                  </div>
                </div>
                <div
                  class="row no-padding"
                  style="margin-bottom:0px;"
                >
                  <div class="divLeft">
                    <span class="spanlabel">Est Price:</span>
                  </div>
                  <div class="divRight">
                    {{ poitem.total_price }}
                  </div>
                </div>
              </el-col>
            </el-row>
            <div class="deletebutton"> 
              <i
                class="el-icon-delete-solid"
                style="color:red; font-size:16px;"
              />
            </div>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
 
export default {
  name: 'JobPOList.vue',
  components: {
  
  },
  props: ['proposal'],
  data() {
    return {
      activeNames: ['1'],
    
      poitems: [
        {
          id: '34',
          name: 'Purchase order test',
          category: '',
          supplier: 'Bridgeman  ',
          po: ' ',
          quaity: '1 ',
          description: 'Test text to go here, etc description text',
          total_price: ' ',
          ordernumber: '2345 ', 
          status: 'Pending',
          delivery: ''
        },
        {
          id: '345',
          name: 'Purchase order test two',
          category: '',
          supplier: 'Supplier Test',
          po: ' ',
          quaity: '23 ',
          description: 'Test text to go here, etc description text',
          total_price: ' ',
          ordernumber: '2346 ', 
          status: 'Approved',
          delivery: 'True',

        }
      ]
       
    };
  },
  watch: {
  
  },
  mounted() {
    
  },
  methods: {
    handleChange(val) {
      console.log(val);
    }
  }
};
</script>
<style>  
.divLeft {
  width:50%;
  float:left;
}
.divRight {
  width:50%;
  float:left;
}
.editbutton {
      float: right;
    height: 20px;
    background: #f3f3f3;
    width: 100%;
    text-align: right;
}
.deletebutton {
    float: right;
    width: 100%;
    text-align: right;
}
.inside-po-info {
  float:left;
}
.poItems .el-collapse-item__header {
  height: auto !important;
  line-height:17px !important;
}
 .poItems .el-collapse-item__arrow {
   margin: 0px 0px 0px 0px !important;
 }
</style>
