<template>
  <div id="draft-pay-period">
    <el-collapse accordion>
      <el-collapse-item
        v-for="pay_cycle in pay_cycle_dates"
        :key="pay_cycle.id"
        :name="pay_cycle.id"
        @change="set_default_input();"
      >
        <template slot="title">
          <table>
            <th>Pay Cycle:</th>
            <th>Pay Frequency:</th>
            <th>Next Pay Period:</th>
            <th>
              Next Pay Day:<span style="margin-left: 25px;'">
                <el-button
                  :ref="pay_cycle.id"
                  :disabled="disable_send.includes(pay_cycle.id)"
                  type="primary"
                  size="mini"
                  @click="pay_employees(pay_cycle.id)"
                >Pay employees</el-button>
              </span>
            </th>
            <tr>
              <td><span>{{ pay_cycle.name }}</span></td>
              <td><span>{{ pay_cycle.frequency }}</span> </td>
              <td><span class="color-link">{{ pay_cycle.start_calculated }} - {{ pay_cycle.end_calculated }}</span> </td>
              <td><span>{{ pay_cycle.next_pay_day }}</span></td>
            </tr>
          </table>
        </template>
        <el-table
          ref="multipleTable"
          :data="employee_list.filter(user => user.pay_cycle_id == pay_cycle.id)"
          style="width: 100%"
          stripe
          show-summary
          :summary-method="get_summaries"
          :row-key="row_key"
          :expand-row-keys="expandRowKeys"
          @expand-change="set_default_input, handleExpandChange()"
        >
          <el-table-column
            v-slot="scope"
            type="expand"
          >
            <div class="margin-left earning-details">
              <table>
                <th />
                <th>Quantity</th>
                <th>Rate</th>
                <th>Amount</th>
                <tr>
                  <td>
                    <span><b>Days paid</b></span>
                    <el-link
                      class="margin-left color-link"
                      @click="show_time_sheet_dialog = true"
                    >
                      + Add hours to Timesheet
                    </el-link>
                    <el-dialog
                      :visible.sync="show_time_sheet_dialog"
                      :close-on-click-modal="true"
                      append-to-body
                      width="70%"
                    >
                      <time-sheet
                        :crm_user_id="scope.row.id"
                        @hook:mounted="set_default_input"
                      />
                    </el-dialog>
                  </td>
                  <td>
                    <span>{{ scope.row.working_hours_in_days }} days  | {{ scope.row.working_hours }} hours</span>
                  </td>
                  <td><money :amount="scope.row.pay_rate" /></td>
                  <td><money :amount="scope.row.working_hours_pay_rate" /></td>
                </tr>
                <tr class="border-bottom-none">
                  <td><span><b>Earnings</b></span></td>
                </tr>
                <tr
                  v-for="(day, index) in scope.row.public_holiday"
                  :key="index"
                >
                  <td>
                    <span class="margin-left-10">Public holiday</span>
                    <span>
                      {{ day.date_formatted }} {{ day.name }}
                    </span>
                  </td>
                  <td>
                    <span>Hours</span>
                    <el-input
                      v-if="scope.row.public_holiday[index].public_holiday_hours.length > 0"
                      v-model="scope.row.public_holiday[index].public_holiday_hours[0].hours"
                      class="width80"
                      size="small"
                    />
                  </td>
                  <td>
                    <span>$</span>
                    <el-input
                      v-if="scope.row.public_holiday[index].public_holiday_hours.length > 0"
                      v-model="scope.row.public_holiday[index].public_holiday_hours[0].rate"
                      class="width80"
                      size="small"
                    />
                    <el-button
                      size="mini"
                      type="success"
                      icon="el-icon-check"
                      circle
                      @click="update_public_holiday_hours(day.id, scope.row.id, index, scope.$index)"
                    />
                  </td>
                  <td>
                    <span>
                      <money :amount="scope.row.public_holiday[index].public_holiday_hours[0].total" />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td />
                  <td />
                  <td />
                  <td>
                    <span>
                      <money :amount="scope.row.leave_details.public_holiday" />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td><span class="margin-left-10">Ordinary hours</span></td>
                </tr>
                <tr>
                  <td>
                    <span class="margin-bottom"><b>Expenses</b></span>
                    <br>
                    <span class="margin-left-10">Travel Food Allowance</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span><b>Holidays and leave</b></span>
                    <el-link
                      class="margin-left color-link"
                      @click="show_leave_request_dialog = true"
                    >
                      + Add Holidays or leave
                    </el-link>
                    <el-dialog
                      :visible.sync="show_leave_request_dialog"
                      :close-on-click-modal="true"
                      append-to-body
                      width="500px"
                    >
                      <new-leave-request-plain
                        :crm_user_id="scope.row.id"
                        @reload_leave_requests="get_pay_cycle_dates(),get_leave_requests('reload_leave_requests',pay_cycle.start_calculated, pay_cycle.end_calculated, scope.row.id )"
                      />
                    </el-dialog>
                  </td>
                </tr>
                <tr>
                  <td colspan="3">
                    <leave-requests-user
                      :start="pay_cycle.start"
                      :end="pay_cycle.end"
                      :crm_user_id="scope.row.id"
                      @reload_pay_run="get_pay_cycle_dates"
                    />
                  </td>
                </tr>
              </table>
            </div>
          </el-table-column>
          <el-table-column
            label="Name"
            property="name"
            width="200px"
          >
            <template #default="scope">
              <el-link
                class="color-link"
                @click="edit_employee(scope.row.id)"
              >
                {{ scope.row.name }}
              </el-link>
            </template>
          </el-table-column>
          <el-table-column
            label="Earnings"
            prop="gross_payment_total"
          >
            <template #default="scope">
              <money :amount="scope.row.gross_payment_total" />
            </template>
          </el-table-column>
          <el-table-column
            label="Leave"
            prop="leave"
          >
            <template #default="scope">
              <el-link
                class="color-link"
                @click="edit_leave(pay_cycle.start_calculated, pay_cycle.end_calculated, scope.row.id)"
              >
                <money :amount="scope.row.leave_details.leave_period_sum" />
              </el-link>
            </template>
          </el-table-column>
          <el-table-column
            label="Reimbursements"
            prop="total_reimbursements"
          >
            <template #default="scope">
              <money :amount="scope.row.total_reimbursements" />
            </template>
          </el-table-column>
          <el-table-column
            label="Deductions"
            prop="total_deduction"
          >
            <template #default="scope">
              <money :amount="scope.row.total_deduction" />
            </template>
          </el-table-column>
          <el-table-column
            label="Superannuation"
            prop="superannuation"
          >
            <template #default="scope">
              <money :amount="scope.row.superannuation" />
            </template>
          </el-table-column>
          <el-table-column
            label="Take Home Pay"
            prop="take_home_pay"
          >
            <template #default="scope">
              <money :amount="scope.row.take_home_pay" />
            </template>
          </el-table-column>
          <el-table-column
            align="right"
          >
            <template #default="scope">
              <el-dropdown @command="payslip">
                <span class="el-dropdown-link">
                  Actions<i class="el-icon-arrow-down el-icon--right" />
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="{id: scope.row.id, action: 'download_payslip'}">
                    Download Payslip
                  </el-dropdown-item>
                  <el-dropdown-item :command="{id: scope.row.id, action: 'email_payslip'}">
                    Email Payslip To Employee
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
          <el-table-column
            align="right"
          >
            <template #default="scope">
              <el-popconfirm
                title="Are you sure you want to remove the employee from this pay run?"
                @confirm="remove_employee(scope.row.id)"
              >
                <el-button
                  slot="reference"
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  size="mini"
                />
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </el-collapse-item>
    </el-collapse>
    <leave-requests-period
      v-if="showDialog == true"
      :start="start_calculated"
      :end="end_calculated"
      :crm_user_id="crm_user_id"
      :show_dialog.sync="showDialog"
      @reload_pay_run="get_pay_cycle_dates"
    />
  </div>
</template>

<script>

import {set_default_input} from '../../utilities/DefaultInput';
import {pay_cycle_dates} from '../../api/pay_cycles';
import {pay_employees, calculate_employees_pay, change_public_holiday_hours} from '../../api/pay_runs';
import {leave_requests} from '../../api/leave_requests';
import Money from '../shared/Money.vue';
import LeaveRequestsPeriod from './LeaveRequestsPeriod.vue';
import LeaveRequestsUser from './LeaveRequestsUser.vue';
import TimeSheet from '../user/TimeSheet.vue';
import NewLeaveRequestPlain from '../user/payroll/NewLeaveRequestPlain.vue';

export default {
  name: 'DraftPayPeriods',
  components: {TimeSheet, Money, LeaveRequestsUser, LeaveRequestsPeriod, NewLeaveRequestPlain},
  data() {
    return {
      pay_cycle_dates: [{start_calculated: null, end_calculated: null}],
      employee_list: [],
      employee_data: [],
      disable_send: [],
      showDialog: false,
      start_calculated: null,
      end_calculated: null,
      crm_user_id: null,
      show_time_sheet_dialog: false,
      show_leave_request_dialog: false,
      expandRowKeys: []
    };
  },
  created() {
    this.get_pay_cycle_dates();
    this.set_default_input();
  },
  methods: {
    handleExpandChange(row) {
      this.expandRowKeys.push(row.id);
    },
    row_key(row) {
      return row.id;
    },
    update_public_holiday_hours(public_holiday_id, crm_user_id, index, list_index){
      let hours = this.employee_list[list_index].public_holiday[index].public_holiday_hours[0].hours || 0;
      let rate =  this.employee_list[list_index].public_holiday[index].public_holiday_hours[0].rate || 0;
      change_public_holiday_hours(this, public_holiday_id, crm_user_id, hours, rate).then((response)=> {
        this.$message({ type: 'success', message: response.data.message });
        this.calculate_employees_pay(this.pay_cycle_dates);
      });
    },
    get_leave_requests(event, start, end, crm_user_id){
      leave_requests(this, start, end, crm_user_id ).then((response)=> {
        this.leave_requests = response.data;
        if(event == 'reload_leave_requests'){
          this.show_leave_request_dialog = false;
          this.$emit('reload_pay_run');
        }
      });
    },
    edit_leave(start_calculated, end_calculated, crm_user_id){
      this.start_calculated = start_calculated;
      this.end_calculated = end_calculated;
      this.crm_user_id = crm_user_id;
      this.showDialog = true;
    },
    payslip(command){
      if(command.action == 'download_payslip'){
        this.download_payslip(command.id);
      }
      if(command.action == 'email_payslip'){
        this.email_payslip(command.id);
      }
    },
    pay_employees(pay_cycle_id){
      this.disable_send.push(pay_cycle_id);
      let employee_list_current_pay_cycle = this.employee_list.filter(user => user.pay_cycle_id == pay_cycle_id);
      let pay_cycle = this.pay_cycle_dates.filter(pay_cycle => pay_cycle.id == pay_cycle_id);
      pay_employees(this, pay_cycle, employee_list_current_pay_cycle, this.employee_data).then(() => {
        this.$message({ type: 'success', message: 'Processing Pay Run...' });
        this.$emit('reload_draft_pay_transactions');
      });
    },
    email_payslip(id){
      this.employee_data = this.employee_list.filter(user => user.id == id);
      this.$http.post(`/api/crm_users/${id}/pay_runs/pay_run_email_payslip`,
        { employee_data:  this.employee_data }
      ).then( () => {
        this.$message({ type: 'success', message: 'Email to employee sent.' });
      });
    },
    download_payslip(id){
      this.employee_data = this.employee_list.filter(user => user.id == id);
      this.$http.post(`/api/crm_users/${id}/pay_runs/pay_run_download_payslip`,
        { employee_data:  this.employee_data },
        { responseType: 'blob' }
      ).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', response.headers.get('content-disposition').split('UTF-8\'\'')[1].trim());
        document.body.appendChild(link);
        link.click();
      });
    },
    remove_employee(id){
      this.employee_list = this.employee_list.filter(user => user.id != id);
    },
    edit_employee(id) {
      window.open(`/crm_users/${id}/edit#/employment_details`, '_blank');
    },
    calculate_employees_pay(pay_cycle_dates) {
      calculate_employees_pay(this, pay_cycle_dates).then((response) => {
        this.employee_list = response.data;
      });
    },
    get_pay_cycle_dates() {
      pay_cycle_dates(this).then((response)=> {
        this.pay_cycle_dates = response.data;
        this.calculate_employees_pay(this.pay_cycle_dates);
      });
    },
    handleSelectionChange(val) {
      this.selected_employees = val;
    },
    get_summaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 1) {
          sums[index] = 'Total Cost';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] =  '$' + values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return (prev + curr);
            } else {
              return  prev;
            }
          }, 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        } else {
          sums[index] = ' ';
        }
      });

      return sums;
    },
    set_default_input
  }
};

</script>

<style scoped>
th {font-size: 12px;font-weight: normal;color: gray;}
td, th {padding: 0px;}
.earning-details tr {
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
}
.earning-details td {
  padding: 10px 0px;
}
.earning-details td:first-child {
  padding-left: 10px;
}
.margin-left{ margin-left: 20px;}
.block{display: block;}
.margin-bottom{ margin-bottom: 8px;display: inline-block;}
.margin-left-10{ margin-left: 10px;}
.width40{ width: 40px;}
.width80{ width: 80px;}
.color-link{color: #409EFF !important;}
.center{text-align: center;}
</style>
<style>
#draft-pay-period .el-card { margin-bottom: 20px;}
.el-table__expand-icon>.el-icon {
  margin-top: -10px !important; }
#draft-pay-period .el-table__expand-icon {
  position: unset; }
.border-bottom-none{border-bottom: 1px solid white !important;}
</style>
