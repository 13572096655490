<template>
  <div>
    <div
      v-for="listuser in task.crm_users"
      :key="listuser.id"
      class="listUser"
    >
      <el-row>
        <el-col
          :span="24"
          :lg="3"
        >
          <span
            class="tableDec"
          ><b>{{ listuser.user_name }}</b></span>
        </el-col>
        <el-col
          :span="24"
          :lg="3"
        >
          Allocated: <Time :is_hours="is_hours" :minutes="listuser.time_allocated_labour" />
        </el-col>
        <el-col
          :span="24"
          :lg="3"
        >
          <span style="text-align:center">
            Spent: <Time :is_hours="is_hours" :minutes="listuser.time_in_minutes" />
          </span>
        </el-col>
        <el-col
          :span="24"
          :lg="3"
          style="margin-bottom:10px; padding-right: 10px"
        >
          <el-input
            v-show="!listuser.completed"
            v-model="listuser.description"
            size="mini"
            placeholder="+ description"
            class="timeSpentinput inputdiv"
          />
        </el-col>
        <el-col
          :span="16"
          :lg="5"
        >
          <div v-if="!listuser.completed">
            <HoursInput
              v-if="is_hours"
              v-model="listuser.time_spent_entry"
              :hours_class="'individual_hours inputdiv'"
            />
            <el-input
              v-else
              v-model.number="listuser.time_spent_entry"
              size="mini"
              placeholder="+ time "
              class="timeSpentinput inputdiv"
            />
          </div>
        </el-col>
        <el-col
          :span="8"
          :lg="2"
          style="text-align:center;height:40px"
        >
          <el-button
            v-show="!listuser.completed"
            size="small"
            type="success"
            icon="el-icon-plus"
            circle
            @click="add_time_spent(listuser)"
          />
        </el-col>

        <el-col
          :span="24"
          :lg="4"
          style="text-align:center"
        >
          <el-button
            v-show="
              listuser.completed == false &&
                task.completed == false &&
                task.has_stock == 0
            "
            :disabled="listuser.time_in_minutes == 0"
            size="small"
            type="plain"
            icon="el-icon-check"
            @click="complete_task(listuser.id)"
          >
            COMPLETE
          </el-button>

          <el-button
            v-show="
              listuser.completed == false &&
                task.completed == false &&
                task.has_stock == 1
            "
            :disabled="listuser.time_in_minutes == 0"
            size="small"
            type="plain"
            icon="el-icon-check"
            @click="
              dialogVisible = true;
              selected_user = listuser.id;
            "
          >
            COMPLETE
          </el-button>

          <JobProposalExtensions
            v-show="
              listuser.completed == false &&
                task.completed == false
            "
            :jp_id="task.id"
            :user_id="listuser.id"
          />
        </el-col>

        <el-col
          :span="24"
          :lg="1"
          style="text-align:center"
        >
          <div>
            <el-button
              v-show="false"
              type="danger"
              size="small"
              icon="el-icon-delete"
              circle
              class="right"
              @click="remove_user(listuser.jp_user_id)"
            />

            <span
              v-show="listuser.completed"
              style="color:#67C23A;"
            >COMPLETED</span>

            <el-dialog
              title="Did you use any stock?"
              :visible.sync="dialogVisible"
              width="95%"
              :before-close="handleClose"
              style="text-align:left"
              class="stockpopup"
            >
              <span style="text-align:left">
                <JobStockList />
              </span>
              <span
                slot="footer"
                class="dialog-footer"
              >
                <el-button
                  @click="
                    dialogVisible = false;
                    selected_user = '';
                  "
                >Cancel</el-button>

                <el-button
                  size="small"
                  type="plain"
                  icon="el-icon-check"
                  @click="complete_task(selected_user)"
                >COMPLETE</el-button>
              </span>
            </el-dialog>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { editor_store } from 'store/modules/editor';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { ProposalMixin } from 'mixins/ProposalMixin.js';
import { JobProposalMixin } from 'mixins/JobProposalMixin.js';
import IndividualStockItemUsed from '../taskcomponents/IndividualStockItemUsed.vue';
import JobStockList from '../JobStockList';
import JobProposalExtensions from 'components/job_proposals/JobProposalExtensions.vue';
import Time from '../../../shared/Time';
import HoursInput from '../../../shared/HoursInput';

export default {
  name: 'IndividualUser.vue',
  store: editor_store,
  components: {
    IndividualStockItemUsed,
    JobStockList,
    JobProposalExtensions,
    Time,
    HoursInput
  },
  mixins: [ProposalMixin, JobProposalMixin],
  props: ['task'],
  data() {
    return {
      input: '',
      selected_user: '',
      time_spent_entry: 0,
      dialogVisible: false,
    };
  },
  computed: {
    ...mapGetters(['proposal','is_hours']),
  },
  methods: {
    ...mapActions(['set_job_proposals_list']),
    handleClose(done) {
      this.$confirm('Are you sure to close this dialog?')
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    load_time_spent_history() {
      this.$http
        .get(`/task_time_spent_history/${this.task.id}`)
        .then((response) => {
          this.task.time_spent_history = response.data.time_spent_history;
        });
    },
    update_time_allocated(user) {
      let _this = this;
      let params = {
        id: user.jp_user_id,
        allocated_time: user.time_allocated_labour,
      };
      this.update_allocated(params).then(
        (response) => {
          _this.task.crm_users = response.data;
          this.$message({
            message: response.message,
            type: 'success',
          });
        },
        (response) => {
          this.$message({
            message: response.message,
            type: 'error',
          });
        }
      );
    },
    complete_task(user_id) {
      let params = {
        id: this.task.id,
        user_id: user_id,
      };

      this.$confirm(
        'Are you ready to complete this user on this task. Continue?',
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      ).then((response) => {
        this.$loading({
          lock: true,
          text: 'Completing task...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
        });
        this.complete_user_task(params).then(
          (response) => {
            this.$loading().close();
            let user_record = this.task.crm_users.filter(
              (c) => c.id == user_id
            )[0];
            if (user_record != undefined) {
              user_record.completed = true;

              this.$message({
                type: 'success',
                message: 'Task has been completed.',
              });
              let pending_count = this.task.crm_users.filter(
                (c) => c.completed == false
              );
              if (pending_count == 0) this.task.completed = true;
              this.dialogVisible = false;
              this.selected_user = '';

              if (response.data.completed == 1) this.proposal.completed = 1;
              else this.load_job_proposals_list();
            }
          },
          (response) => {
            this.$message({
              type: 'error',
              message: 'Cannot update the task, refresh the page & try again.',
            });
          }
        );
      });
    },
    load_job_proposals_list() {
      let _this = this;
      this.$loading({
        lock: true,
        text: 'Refreshing tasks list...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      // set second argument to true 'only_workspace_tasks'
      // to fetch is_initated tasks only if proposal is workspace
      _this.load_job_proposals(_this.proposal.id, true).then((response) => {
        _this.set_job_proposals_list(response);
        _this.$loading().close();
      });
    },
    remove_user(user_id) {
      let _this = this;

      this.remove_jp_user(user_id).then(
        (response) => {
          _this.task.crm_users = response.users;

          this.$message({
            message: response.message,
            type: 'success',
          });
        },
        (response) => {
          this.$message({
            message: response.message,
            type: 'error',
          });
        }
      );
    },
    add_time_spent: function (user) {
      let time_spent = user.time_spent_entry;
      if (
          time_spent == undefined ||
          time_spent == '' ||
          isNaN(time_spent)
          // Number(time_spent) <= 0 allow negative numbers to subtract
      ) {
        this.$message({
          type: 'warning',
          message: 'Please enter time spent correctly.',
        });
        return;
      }

      let params = {
        time_in_minutes: time_spent,
        job_proposal_id: this.task.id,
        crm_user_id: user.id,
        description: user.description,
      };
      this.update_time_spent_by_user(params).then((response) => {
        let user_record = this.task.crm_users.filter((c) => c.id == user.id)[0];
        if (user_record != undefined) {
          user_record.time_in_minutes += parseInt(time_spent);
          user.time_spent_entry = '';
          user.description = '';
          this.time_spent_entry = '';
          this.load_time_spent_history();
          this.$message({
            type: 'success',
            message: 'Time spent has been updated.',
          });
        }
      });
    },
  },
  watch: {},
};
</script>
<style lang='scss'>
.individual_hours {
  input {
    height: 2em !important;
    text-align: center;
  }
}
</style>

<style>
.tableTitle {
  text-transform: uppercase;
  font-size: 11px;
  padding-left: 3px;
}
.tableDiv {
  background: #333;
  color: rgb(182, 182, 182);
  padding-left: 3px;
}

.listUser {
  border: 2px solid #fff;
  background: #f8f6f6;
  padding: 5px;
}
.timeSpentinput {
  width: 80%;
  float: left;
}
.timeSpentinput input {
  height: 2em !important;
  text-align: center;
}
.timeSpentinput .el-collapse-item__placeholder {
  color: #fff !important;
}
.stockpopup .el-dialog__title {
  font-size: 20px;
  font-weight: bold;
}
</style>
