<template>
  <el-dialog
    title="Please fill in your Non Conformance Report/s"
    :visible.sync="naugthy_modal"
    width="50"
    height="50"
    :lock-scroll="true"
    :adaptive="true"
  >
    <ul id="list">
      <li
        v-for="item in list"
        :key="item.id"
      >
        <a :href="'/job_view?id=' + item.id">{{ item.job_name }}</a>
      </li>
    </ul>
  </el-dialog>
</template>

<script>
export default {
  name: 'NonConformanceNotification',
  data() {
    return {
      naugthy_modal: false,
      today: new Date(),
      list: [],
    };
  },
  computed: {
    alarms() {
      return [
        new Date(
          this.today.getFullYear(),
          this.today.getMonth(),
          this.today.getDate(),
          11,
          0,
          0,
          0
        ),
        new Date(
          this.today.getFullYear(),
          this.today.getMonth(),
          this.today.getDate(),
          15,
          0,
          0,
          0
        ),
      ];
    },
  },
  created: function() {
    let _this = this;
    setInterval(function() {
      _this.check_time();
    }, 600000);
  },
  methods: {
    check_urgent_ncr() {
      return new Promise((resolve, reject) => {
        this.$http.get('/urgent_non_conformance_reports').then(
          (response) => {
            resolve(response.data.list);
          },
          function(response) {
            reject(false);
          }
        );
      });
    },
    check_open_non_conformance_reports() {
      return new Promise((resolve, reject) => {
        this.$http.get('/any_non_conformance_reports').then(
          (response) => {
            resolve(response.data.list);
          },
          function(response) {
            reject(false);
          }
        );
      });
    },
    check_time() {
      let check_now = false;

      this.alarms.forEach((el, idx) => {
        // check if current time is between element date (10:00:00) and (10:01:30)
        if (el < new Date() && el.getTime() + 1500 * 60 > new Date())
          check_now = true;
      });

      let url =
        check_now == true
          ? '/any_non_conformance_reports'
          : '/urgent_non_conformance_reports';
      let _this = this;
      _this.$http.get(url).then((response) => {
        if (response.data.length > 0) {
          _this.list = response.data;
          if (
            _this.list.filter((c) =>
              location.href.endsWith(`job_view?id=${c.id}`)
            ).length == 0
          )
            _this.naugthy_modal = true;
        }
      });
    },
  },
};
</script>
