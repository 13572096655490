<template>
  <el-dialog
    id="time_sheet_dialog_id"
    :visible.sync="show"
    width="95%"
    :close-on-click-modal="false"
    :title="value.created_at"
    append-to-body
    @closed="onCloseDialog"
  >
    <el-row
      :gutter="30"
    >
      <el-col
        :span="24"
        :md="time_sheet_md_col"
      >
        <div class="container">
          <div class="sub-title">
            Time Sheet
          </div>
          <el-form label-width="125px">
            <el-row>
              <el-col
                :span="24"
                :lg="12"
              >
                <el-form-item label="Check in">
                  <el-time-select
                    placeholder="Check-in time"
                    :value="value.check_in"
                    :picker-options="time_options"
                    class="form__input"
                    @input="onUpdate('check_in', $event)"
                  />
                </el-form-item>
              </el-col>
              <el-col
                :span="24"
                :lg="12"
              >
                <el-form-item label="Check out">
                  <el-time-select
                    placeholder="Check-out time"
                    :value="value.check_out"
                    :picker-options="time_options"
                    class="form__input"
                    @input="onUpdate('check_out', $event)"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="Working Hours">
              {{ working_hours }}
            </el-form-item>
            <el-form-item label="Description">
              <el-input
                placeholder="Enter description... "
                :value="value.reason"
                type="text"
                class="form__input"
                @input="onUpdate('reason', $event)"
              />
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                class="form__input"
                @click="onUpdateTimeSheet"
              >
                Confirm
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
      <el-col
        v-if="is_jobtime_input"
        :span="24"
        :md="{span: 12}"
      >
        <TasksTime
          :time_sheet_date="value.created_at"
          :time_sheet_id="time_sheet_id"
          @onRefreshTimeSheets="onRefreshTimeSheets"
        />
      </el-col>
    </el-row>
    <span slot="footer">
      <el-button
        type="info"
        @click="onCloseDialog"
      >
        Close
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import {defaultInput} from '../../../utilities/DefaultInput';
import {display_time} from '../../../utilities/Time';
import TasksTime from './TasksTime';

function parseTimeStr(time_str) {
  let time_separated =  time_str.split(':');
  return  (+time_separated[1] + time_separated[0] * 60);
}

export default {
  name: 'TimeSheetDialog',
  components: {TasksTime},
  props: {
    show_timesheet_dialog: {
      type: Boolean,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    is_jobtime_input: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      show: this.show_timesheet_dialog,
      time_sheet_md_col: (this.is_jobtime_input === true) ? { span: 12 } : { span: 24 } ,
      time_options: {
        start: '00:00',
        step: '00:15',
        end: '23:59',
        minTime: '00:01'
      },
      time_sheet_id: this.value.id
    };
  },
  computed: {
    working_hours() {
      if(!this.value.check_in|| !this.value.check_out)
        return '';

      let s_min = parseTimeStr(this.value.check_in);
      let e_min = parseTimeStr(this.value.check_out);
      let diff = e_min - s_min;

      return display_time(true, diff);
    }
  },
  mounted() {
    this.$nextTick(() => { defaultInput('#time_sheet_dialog_id'); });
  },
  methods: {
    onCloseDialog() {
      this.$emit('update:show_timesheet_dialog', false);
    },
    onUpdate(key, value) {
      this.$emit('input', { ...this.value, [key]: value });
    },
    onUpdateTimeSheet() {
      if (!this.value || !this.value.check_in) return;

      this.$http.post('/update_user_time_sheet/', this.value).then(({data}) => {
        this.$message({
          type: 'success',
          message: 'Updated successfully.',
        });
        this.time_sheet_id = data.data;
        this.onRefreshTimeSheets();
      });
    },
    onRefreshTimeSheets() {
      this.$emit('updateTimeSheet');
    }
  }
};
</script>

<style lang='scss' scoped>
@import '../../../../assets/stylesheets/components/two_columns';
</style>
