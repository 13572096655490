import {Request} from '../utilities/Request';

export function moveToCategory(this_obj, id, category_id) {
  return Request.patch(
    this_obj,
    `/api/working_files/${id}/move_to_category`,
    null,
    { category_id: category_id }
  );
}

export function updateName(this_obj, id, name) {
  return Request.patch(
    this_obj,
    `/api/working_files/${id}/update_name`,
    null,
    { name: name }
  );
}

export function updateIsIncluded(this_obj, id, is_included) {
  return Request.patch(
    this_obj,
    `/api/working_files/${id}/update_is_included`,
    null,
    { is_included: is_included }
  );
}

export function updateQty(this_obj, id, qty) {
  return Request.patch(
    this_obj,
    `/api/working_files/${id}/update_qty`,
    null,
    { qty: qty }
  );
}

export function update_note(this_obj, id, note) {
  return Request.patch(
    this_obj,
    `/api/working_files/${id}/update_note`,
    null,
    { note: note }
  );
}

export function working_file_notes(this_obj, proposal_id) {
  return Request.post(
    this_obj,
    '/api/working_files/working_file_notes',
    null,
    {proposal_id: proposal_id }
  );
}

export function updateQuantityUnit(this_obj, id, quantity_unit_id) {
  return Request.patch(
    this_obj,
    `/api/working_files/${id}/update_quantity_unit`,
    null,
    { quantity_unit_id: quantity_unit_id }
  );
}
