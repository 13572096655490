<template>
  <tr class="list__input">
    <td />
    <td>
      <el-input
        v-model="purchase_order.description"
        size="mini"
        class="form__item"
        placeholder="Input Description"
      />
    </td>
    <td>
      <el-select
        v-model="purchase_order.category_task_id"
        filterable
        class="form__item"
        placeholder="Select a Category"
        size="small"
      >
        <el-option
          v-for="category in category_tasks_list"
          :key="category.id"
          :label="category.name"
          :value="category.id"
        />
      </el-select>
    </td>
    <td>
      <el-select
        v-model="purchase_order.supplier_id"
        filterable
        class="form__item"
        placeholder="Select a supplier"
        size="small"
        @change="onSupplierChange"
      >
        <el-option
          v-for="supplier in suppliers_list"
          :key="supplier.id"
          class="form__item"
          :label="supplier.name"
          :value="supplier.id"
        >
          <span style="float: left">{{ supplier.name }}</span>
          <span style="float: right; color: #8492a6; font-size: 13px">{{ supplier.email }}</span>
        </el-option>
      </el-select>
    </td>
    <td>
      <el-select
        v-model="purchase_order.category_supplier_id"
        class="form__item"
        filterable
        placeholder="Select a category"
        size="small"
      >
        <el-option
          v-for="category in categories_list"
          :key="category.id"
          :label="category.name"
          :value="category.id"
        />
      </el-select>
    </td>
    <td>
      <el-select
        v-model="purchase_order.po_status_id"
        class="form__item"
        filterable
        size="small"
      >
        <el-option
          v-for="status in po_status_list"
          :key="status.id"
          :label="status.name"
          :value="status.id"
        />
      </el-select>
    </td>
    <td>
      <el-select
        v-model="purchase_order.branch_id"
        class="form__item"
        filterable
        placeholder="Select a branch"
        size="small"
      >
        <el-option
          v-for="branch in branches_list"
          :key="branch.id"
          :label="branch.name"
          :value="branch.id"
        />
      </el-select>
    </td>
    <td>
      <el-input-number
        v-model.number="purchase_order.charge_percent_applied"
        class="form__item"
        :min="0"
        size="mini"
      />
    </td>
    <td>
      <el-input-number
        v-model.number="purchase_order.estimated_value"
        class="form__item"
        :min="0"
        size="mini"
        @change="onChangeEstimatedVal()"
      />
    </td>
    <td>${{ sale_price }}</td>
    <td>
      <el-button
        type="success"
        icon="el-icon-circle-plus"
        circle
        @click="onAddChargePO"
      />
    </td>
  </tr>
</template>

<script>
export default {
  name: 'NewChargePOsToSummaryEdit',
  props: {
    proposal: {
      type:Object,
      required: true
    },
    working_file_id: {
      type: Number,
      required: true
    },
    refresh_list: {
      type: Function,
      required: true,
    }
  },
  data() {
    return {
      purchase_order: {
        id: 0,
        supplier_id: '',
        proposal_id: this.proposal.id,
        branch_id: this.proposal.branch_id,
        sign_id: '',
        category_supplier_id: 0,
        po_status_id: 1,
        category_task_id: 0,
        is_open_quote: true,
        is_manual: 1,
        description: '',
        send_to: '',
        actual_value: 0,
        estimated_value: 0,
        charge_percent_applied: 0,
        mark_as_deleted: 0
      },
      branches_list: [],
      category_tasks_list: [],
      categories_list: [],
      suppliers_list: [],
      po_status_list: [],
    };
  },
  computed: {
    sale_price() {
      let cal_price = parseFloat(this.purchase_order.actual_value);

      if (this.purchase_order.charge_percent_applied) {
        cal_price += (cal_price * this.purchase_order.charge_percent_applied) / 100;
      }
      return cal_price;
    },
  },
  created() {
    this.loadBranchList();
    this.loadCategoryList();
    this.loadStockCategoryList();
    this.loadSupplierList();
    this.loadStatusList();
  },
  methods: {
    onAddChargePO() {
      if(this.purchase_order.description === '') {
        this.$message({
          message: 'All Input fields should be valid',
          type: 'error',
        });
        return;
      }

      if (this.working_file_id !== undefined) {
        this.purchase_order.working_file_id = this.working_file_id;
      }

      this.$http['post']('/purchase_orders/', JSON.stringify(this.purchase_order)).then(
        () => { this.refresh_list();},
        (res) => { console.error(res); });
    },
    onSupplierChange() {
      let selected_supplier = this.suppliers_list.filter((item) => item.id === this.purchase_order.supplier_id)[0];
      if (selected_supplier !== undefined) this.purchase_order.send_to = selected_supplier.email;
    },
    onChangeEstimatedVal() {
      this.purchase_order.actual_value = this.purchase_order.estimated_value;
    },
    loadBranchList() {
      this.$http.get('/branches_list').then(
        (response) => { this.branches_list = response.data; },
        (response) => { console.error(response); }
      );
    },
    loadCategoryList() {
      this.$http.get('/category_tasks_list').then(
        (response) => {
          this.category_tasks_list = response.data;
          this.purchase_order.category_task_id = this.category_tasks_list[0].id;
        },
        (response) => { console.error(response); }
      );
    },
    loadStockCategoryList() {
      this.$http.get('/category_suppliers_list').then(
        (response) => {
          this.categories_list = response.data;
          this.purchase_order.category_supplier_id = this.categories_list[0].id;
        },
        (response) => { console.error(response); }
      );
    },
    loadSupplierList() {
      this.$http.get('/suppliers_list').then(
        (response) => {
          this.suppliers_list = response.data;
          this.purchase_order.supplier_id = this.suppliers_list[0].id;
          this.purchase_order.send_to = this.suppliers_list[0].email;
        },
        (response) => { console.error(response); }
      );
    },
    loadStatusList() {
      this.$http.get('/po_statuses_by_id/' + (this.purchase_order.po_status_id || 1)).then(
        (response) => { this.po_status_list = response.data; },
        (response) => { console.error(response); }
      );
    },
  }
};
</script>

<style scoped>
.form__item {
  width: 100%;
}

.list__input {
  background-color: #e5f7e6;
}
</style>