<template>
  <tr class="list__input">
    <td>
      <div v-if="is_custom === false">
        <el-select
          v-model="form.job_id"
          size="mini"
          class="form__item"
          filterable
          reserve-keyword
          placeholder="SELECT a Predefine Task"
          style="color:#333"
          @change="onSelectedJob"
        >
          <el-option
            v-for="item in jobs_list"
            :key="item.id"
            :label="item.name"
            :value="item.id"
            style="background:#fff"
          />
        </el-select>
      </div>

      
      <div v-else>
        <el-input
          v-model="form.layer_name"
          size="mini"
          class="form__item"
          placeholder="Or ADD Custom Task... Type Task Name here"
        />
      </div>
    </td>
    <td v-show="proposal.approved === 1 || proposal.approved === true">
      <el-checkbox v-model="form.billable" />
    </td>
    <td>
      <el-input
        v-if="is_custom === false"
        v-model.number="form.users_count"
        size="mini"
        type="text"
        style="color:#999!important; min-width: 50px;"
      />
      <UsersCustomJob
        v-else
        v-model="form.selected_crm_user_ids"
        :crm_users_list="crm_users_list"
      />
    </td>
    <td>
      <el-checkbox
        v-model="form.branch_specific"
        :disabled="is_custom === true"
      />
    </td>
    <td><el-checkbox v-model="form.splittable" /></td>
    <td><el-checkbox v-model="form.is_urgent" /></td>
    <td v-show="proposal.quote_type_id === 5">
      <el-checkbox v-model="form.is_initiated" />
    </td>
    <td>
      <HoursInput
        v-if="is_hours"
        v-model="form.time_allocated_labour"
        :multiline="true"
        :hours_style="'color:#999!important;'"
      />
      <div v-else>
        <el-col :span="20">
          <el-input
            v-model.number="form.time_allocated_labour"
            size="mini"
            type="text"

            style="color:#999!important;"
          />
        </el-col>
        <el-col :span="2">
          min
        </el-col>
      </div>
    </td>
    <td v-show="proposal.approved === 1 || proposal.approved === true" />
    <td><el-checkbox v-model="form.overwrite_labour" /></td>
    <td />
    <td>
      <el-input
        v-model="form.sale_price"
        :min="0"
        size="mini"
       
        type="number"
        style="color:#999!important;"
      />
    </td>
    <td />
    <td><Money :amount="total_sale_price" /></td>
    <td>
      <el-button
        type="success"
        icon="el-icon-circle-plus"
        circle
        @click="onAddJob"
      />
    </td>
  </tr>
</template>

<script>
import { editor_store } from 'store/modules/editor';
import { mapGetters } from 'vuex';
import HoursInput from '../../../shared/HoursInput';
import Money from '../../../shared/Money';
import { JobProposalMixin } from 'mixins/JobProposalMixin.js';
import { ProposalMixin } from 'mixins/ProposalMixin';
import {EventBus} from '../../../../utilities/EventBus';
import {CheckPositiveInteger} from '../../../../utilities/Number';
import {loadCustomJob} from '../../../../api/jobs';
import UsersCustomJob from './UsersCustomJob';

export default {
  name: 'NewJobToSummaryEdit',
  store: editor_store,
  components: {UsersCustomJob, HoursInput, Money },
  mixins: [JobProposalMixin, ProposalMixin],
  props: {
    working_file_id: {
      validator: (value) => {
        return (typeof value === 'number' || typeof value === 'undefined');
      },
      required: true,
    },
    is_custom: {
      type: Boolean,
      default: false
    },
    crm_users_list: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      form: {
        job_id: null,
        time_allocated_labour: 0,
        sale_price: 0,
        users_count: 1,
        splittable: false,
        branch_specific: false,
        is_urgent: false,
        overwrite_labour: false,
        billable: false,
        is_initiated: false,
        is_custom: this.is_custom,
        layer_name: '', //for custom job
        selected_crm_user_ids: [] //for custom job,
        
      }
    };
  },
  computed: {
    ...mapGetters([
      'proposal',
      'jobs_list',
      'job_proposals_list',
      'is_hours'
    ]),
    total_sale_price() {
      return this.form.sale_price * this.form.time_allocated_labour;
    }
  },
  created() {
    if( this.is_custom === false ) return;

    loadCustomJob(this).then(({data})=>{
      this.form.job_id = data.id;
    });
  },
  methods: {
    get_selected_job(id) {
      return this.jobs_list.filter((item) => item.id === id)[0];
    },
    checking_fields() {
      if(this.is_custom === true) {
        this.form.users_count = this.form.selected_crm_user_ids.length;

        if (this.form.layer_name === ''){
          console.error(this.form);
          return false;
        }
      }

      if(
        this.form.job_id === null ||
        this.form.time_allocated_labour === '' ||
        this.form.sale_price === '' ||
        CheckPositiveInteger(this.form.users_count) === false ||
        Number(this.form.users_count) === 0
      ) {
        console.error(this.form);
        return false;
      }

      return true;
    },
    onSelectedJob(newVal) {
      let selected_job = this.get_selected_job(newVal);
      this.form.time_allocated_labour = selected_job.allocated_time;
      this.form.sale_price = selected_job.labour_minutes_rate;
      this.form.splittable = Boolean(selected_job.splittable);
      this.form.branch_specific = Boolean(selected_job.branch_specific);
      this.form.users_count = 1;
      this.form.is_urgent = false;
      this.form.overwrite_labour = false;
      this.form.billable = false;
      this.form.is_initiated = false;
    },
    onAddJob() {
      if( this.checking_fields() === false ) {
        this.$message({
          message: 'Input fields should be valid',
          type: 'error',
        });
        return;
      }
      let data = { ...this.form };
      if (this.working_file_id) data.working_file_id = this.working_file_id;

      this.add_job_proposal(this.proposal.id, [data]).then(
        async (response) => {
          response.forEach((el) => {
            this.job_proposals_list.push(el);
          });

          if (this.proposal.approved === 1) {
            await this.update_proposal_time_frame(this.proposal.id);
          }
          this.$message({
            message: 'Task added successfully.',
            type: 'success',
          });
          EventBus.$emit('reloadCategoryWorkingFiles');
        },
        (reason) => {
          console.error(reason);
          this.$message({
            message: 'Error occured!.',
            type: 'error',
          });
        }
      );
    }
  }
};
</script>

<style scoped>
.form__item {
  width: 100%;
}
.form__item  ::placeholder {
  color:#666 !important;
}

.list__input {
  background-color: #e5f7e6;
}
</style>