<template>
  <div>
    <h3 style="display: none;">
      <b v-if="is_admin == 1">USER TIME SHEET</b>
      <b v-else>MY TIME SHEET</b>
      <hr />
    </h3>
    <el-row style="margin-bottom:20px;">
      <el-col
        :xs="24"
        :sm="24"
        :md="16"
        :lg="16"
        :xl="16"
        style="margin-bottom:20px;"
      >
        <span><b>Filter:</b></span>
        <br />

        <el-col :xs="24" :sm="24" :md="18" :lg="18" :xl="18">
          <el-date-picker
            v-model="selected_date_range"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="-"
            start-placeholder="Start date"
            end-placeholder="End date"
            style="width: auto !important;"
            :picker-options="pickerOptions2"
            format="dd.MM.yyyy"
          >
          </el-date-picker>
          <el-button type="success" v-on:click="fill_data" size="small">Search</el-button>
        </el-col>

      </el-col>
      <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
        <el-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14">
          <b>Default Check-in: </b>
        </el-col>
        <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10">
          <span>{{ selected_user_info.default_check_in }}</span>
        </el-col>
        <el-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14">
          <b>Default Check-out: </b>
        </el-col>
        <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10">
          <span>{{ selected_user_info.default_check_out }}</span>
        </el-col>
        <el-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14">
          <b>Lunch break duration: </b>
        </el-col>
        <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10">
          <span>{{ selected_user_info.break_duration }} min.</span>
        </el-col>
      </el-col>
    </el-row>

    <el-row style="margin-bottom:20px;">
      <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10">
        <h3><b>TIME SHEET RESULTS</b></h3>
      </el-col>
      <el-col
        :xs="24" :sm="24" :md="14" :lg="14" :xl="14"
        class="flex_container"
      >
       <div class="item">
         <b>Overtime hours: </b> {{ total_overtime }} hours
       </div>
        <div class="item">
          <b>Total hours: </b> {{ total_hours }} hours
        </div>
        <div class="item">
          <b>Target hours: </b> {{ total_daily_hours }} hours
        </div>
        <div class="item"
          v-if="is_jobtime_input === true"
        >
          <b>Spent hours: </b> <Time :is_hours="true" :minutes="total_spent" />
        </div>
      </el-col>
    </el-row>
    <hr />

    <el-row style="margin-bottom:20px;">
      <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16"> </el-col>
      <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
        <span class="box-container">
          <span class="box-icon holiday-bg"> </span>
          <span>
            Public Holiday
          </span>
        </span>
        <span class="box-container">
          <span class="box-icon leave-bg"> </span>
          <span>
            Leaves
          </span>
        </span>
        <span class="box-container">
          <span class="box-icon weekend-bg"> </span>
          <span>
            Weekend
          </span>
        </span>
      </el-col>
    </el-row>

    <div v-for="week in weekly_grouped_timesheet" id="myprofiledet">
      <el-row style="margin-bottom:20px;">
        <el-col :xs="24" :sm="24" :md="5" :lg="5" :xl="5">
          <h3>
            <strong>{{ week.week_name }} </strong>
            <span style="margin-left: 10px"
              ><i>{{ week.week_range }}</i></span
            >
          </h3>
        </el-col>
        <el-col :xs="24" :sm="24" :md="5" :lg="5" :xl="5">
          <span class="right small">
            <b>Overtime hours: </b> {{ week.week_overtime }} hours
          </span>
        </el-col>
        <el-col :xs="24" :sm="24" :md="5" :lg="5" :xl="5">
          <span class="right small" style="margin: 0px 10px">
            <b>Total hours: </b> {{ week.week_total }} hours
          </span>
        </el-col>
        <el-col :xs="24" :sm="24" :md="5" :lg="5" :xl="5">
          <span class="right small" style="margin: 0px 10px">
            <b>Target hours: </b> {{ week.week_target }} hours
          </span>
        </el-col>
        <el-col
          v-if="is_jobtime_input===true"
          :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
          <span class="right small" style="margin: 0px 10px">
            <b>Spent hours: </b> <Time :is_hours="true" :minutes="week.week_total_spent" />
          </span>
        </el-col>
      </el-row>
      <hr />
      <div class="row">
        <div>
          <el-collapse accordion v-loading="loading">
            <el-collapse-item
              v-for="grouped in week.arr"
              :name="grouped.date"
              :key="grouped.date"
            >
              <template slot="title" :class="grouped.style">
                <el-row style="width:100%">
                  <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
                    <span
                      :class="grouped.style"
                      style="  display: inline-block;"
                    >
                      {{ grouped.day }} {{ grouped.date }}
                    </span>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
                    <span style=" " :class="grouped.style" class="header-style">
                      <b>SubTotal hours: </b>
                    </span>
                    <span class="attribute-style" :class="grouped.style">
                      {{ grouped.sub_total_hours }} hours
                    </span>
                  </el-col>

                  <el-col :xs="24" :sm="24" :md="5" :lg="5" :xl="5">
                    <span class="header-style" :class="grouped.style">
                      <b>Total hours: </b>
                    </span>

                    <span class="attribute-style" :class="grouped.style">
                      {{ grouped.total_hours }} hours
                    </span>
                    <span class="attribute_note" :class="grouped.style">
                      <small>(Excl. lunch Break)</small>
                    </span>
                  </el-col>

                  <el-col :xs="24" :sm="24" :md="5" :lg="5" :xl="5">
                    <span class="progressbar">
                      <el-progress
                        :text-inside="true"
                        :stroke-width="18"
                        :percentage="get_percentage(grouped).value"
                        :status="get_percentage(grouped).class"
                      ></el-progress>
                    </span>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="3" :lg="3" :xl="3">
                    <div v-if="is_jobtime_input === true">
                      <span class="header-style" :class="grouped.style">
                        <b>Spent time:</b>
                      </span>
                      <span class="attribute_note" :class="grouped.style">
                        <Time :is_hours="true" :minutes="grouped.total_time_spent" />
                      </span>
                    </div>
                    <div v-else>
                      &nbsp;
                    </div>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="3" :lg="3" :xl="3">
                    <span
                      v-show="grouped.total_overtime != 1"
                      class="header-style"
                      :class="grouped.style"
                    >
                      <b>Overtime hours: </b>
                    </span>
                    <span
                      v-show="grouped.total_overtime != 0"
                      class="attribute-style"
                      :class="grouped.style"
                    >
                      {{ grouped.total_overtime }} hours
                    </span>
                  </el-col>
                </el-row>
              </template>

              <el-tabs type="border-card">
                <el-tab-pane :label="grouped.data.length + ' entry(ies)'"  >
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <h3>
                      <strong><b>Date:</b> {{ grouped.date }}</strong>
                      <el-row>
                        <el-button
                          class="right  "
                          size="small"
                          button
                          type="primary"
                          plain
                          v-on:click="create_time_sheet_record(grouped.date)"
                          style="margin-top: -10px;"
                          >New Entry
                        </el-button>
                      </el-row>
                      <hr />
                    </h3>
                  </el-col>
                  <div v-for="data in grouped.data" :key="data.id">
                    <el-col
                      :xs="24"
                      :sm="24"
                      :md="24"
                      :lg="24"
                      :xl="24"
                      style="margin-bottom:10px;padding-bottom:10px;border-bottom:1px solid #f3f3f3;"
                    >
                      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
                        <b>Check-in:</b>{{ data.check_in }}
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
                        <b>Check-out:</b> {{ data.check_out }}
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
                        {{ data.total_hours }} hour(s)
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
                        {{ data.reason }}
                      </el-col>
                      <el-col
                        v-if="is_jobtime_input === true"
                        :xs="24" :sm="24" :md="4" :lg="4" :xl="4"
                      >
                        <b>Spent time:</b><Time :is_hours="true" :minutes="data.total_time_spent" />
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
                        <el-tooltip
                          content="Edit current time sheet entry"
                          placement="bottom"
                          effect="light"
                        >
                          <el-button
                            icon="el-icon-edit"
                            circle
                            v-on:click="edit_time_sheet_record(data)"
                          ></el-button>
                        </el-tooltip>
                        <el-button
                          icon="el-icon-delete"
                          circle
                          v-on:click="delete_time_sheet_record(data.id)"
                        ></el-button>
                      </el-col>
                    </el-col>
                  </div>
                </el-tab-pane>

                <el-tab-pane :label="grouped.requests.length + ' request(s)'" >
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                      <h3>
                        <strong><b>Date:</b> {{ grouped.date }}</strong>
                        <el-row>
                          <el-button
                            class="right  "
                            button
                            type="primary"
                            plain
                            size="small"
                            v-on:click="create_new_request(grouped.date)"
                            style="margin-top: -10px;"
                            >New Request
                          </el-button>
                        </el-row>
                        <hr />
                      </h3>
                    </el-col>
                    <div v-for="request in grouped.requests" :key="request.id">
                      <el-col
                        :xs="24"
                        :sm="24"
                        :md="24"
                        :lg="24"
                        :xl="24"
                        style="margin-bottom:10px;padding-bottom:10px;border-bottom:1px solid #f3f3f3;"
                      >
                        <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
                          {{ request.request_type.name }}
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
                          <span
                            v-show="
                              request.request_type.name != 'Break Inclusion'
                            "
                          >
                            {{ request.value }}
                            <i>{{
                              request_type_header(request.request_type.name)
                            }}</i>
                          </span>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
                          {{ request.content }}
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
                          {{ request.request_status.name }}
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
                          {{ request.reply_content }}
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
                          <el-button
                            icon="el-icon-edit"
                            circle
                            v-on:click="edit_user_request(request)"
                          ></el-button>
                          <el-button
                            icon="el-icon-delete"
                            circle
                            v-show="request.request_status.name == 'PENDING'"
                            v-on:click="delete_user_request(request.id)"
                          ></el-button>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
                        </el-col>
                      </el-col>
                    </div>
                  </el-col>
                </el-tab-pane>
              </el-tabs>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>

    <Request
      :reload_data="reload_data"
      :requestDialog="requestDialog"
      :selected_request="selected_request"
    />

    <TimeSheetDialog
      v-if="dialogFormVisible"
      :show_timesheet_dialog.sync="dialogFormVisible"
      v-model="selected_time_sheet_record"
      :is_jobtime_input="is_jobtime_input"
      @updateTimeSheet="save_time_sheet_record"
    />
  </div>
</template>

<script>
import Request from "./Request";
import TimeSheetDialog from './TimeSheetDialog/TimeSheetDialog';
import Time from '../shared/Time';
import { mapGetters, mapActions } from "vuex";
import { user_profile } from "../../store/modules/user_profile";
import {loadIsJobtimeInput} from '../../api/organization';

export default {
  name: "TimeSheet",
  components: { Request, TimeSheetDialog, Time },
  store: user_profile,
  props: {
    crm_user_id: {
      type: [String],
      required: false
    }
  },
  data() {
    return {
      dialogFormVisible: false,
      requestDialog: { visible: false },
      pickerOptions2: {
        shortcuts: [
          {
            text: "Current month",
            onClick(picker) {
              const end = new Date();
              const start = new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                1
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last week",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last month",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last 3 months",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last 6 months",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last year",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      time_options: {
        start: "00:01",
        step: "00:01",
        end: "23:59",
      },
      loading: false,
      selected_date_range: "",
      total_daily_hours: 0,
      grouped_timesheet: [],
      weekly_grouped_timesheet: [],
      time_sheet_title: "",
      selected_time_sheet_record: {},
      selected_request: {
        request_type: { id: "", name: "" },
        leave_type: { id: "", name: "" },
        request_status: { id: "", name: "" },
      },
      total_hours: 0,
      total_overtime: 0,
      total_spent: 0,
      request_statuses: "",
      request_types: "",
      is_jobtime_input: false
    };
  },
  watch: {
    selected_user_info() {
      this.fill_data();
    },
  },
  created() {
    loadIsJobtimeInput(this).then(({data})=>{
      this.is_jobtime_input = data.is_jobtime_input;
    });
  },
  mounted() {
    this.crm_user_id_present();
    this.selected_date_range = [this.start_date, this.end_date];
  },
  methods: {
    ...mapActions([
      "set_selected_user_info",
      "set_is_admin",
    ]),
    crm_user_id_present(){
      if(this.crm_user_id != undefined) {
        this.$http.get(`/user_default_check_in/${this.crm_user_id}`).then(
            (response) => {
              this.set_selected_user_info(response.data);
            })
        this.set_is_admin(1);
      }
    },
    get_date_today(){
      const today = new Date();
      const day = String(today.getDate()).padStart(2, '0');
      const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
      const year = today.getFullYear();

      return `${day}/${month}/${year}`;
      },
    reload_data() {
      this.requestDialog.visible = false;
      this.fill_data();
    },
    request_type_header(type) {
      switch (type) {
        case "Bonus":
          return "dollar";
        case "Break Inclusion":
          return "";
        default:
          return "hours";
      }
    },
    fill_data() {
      let _this = this;
      _this.loading = true;

      this.$http
        .post("/user_time_sheet", {
          start_date: _this.selected_date_range[0],
          end_date: _this.selected_date_range[1],
          user_id: _this.selected_user_info.id,
        })
        .then((response) => {
          _this.loading = false;
          _this.weekly_grouped_timesheet = [];
          _this.weekly_grouped_timesheet = response.data.grouped_weeks;
          _this.total_hours = response.data.total_hours;
          _this.total_overtime = response.data.total_overtime;
          _this.total_daily_hours = response.data.total_daily_hours;
          _this.total_spent = response.data.total_spent;
        });
    },
    edit_time_sheet_record(data) {
      //this.open_modal();
      this.clear_time_sheet_entry();
      this.dialogFormVisible = true;
      this.selected_time_sheet_record = JSON.parse(JSON.stringify(data));
    },
    edit_user_request(request) {
      this.requestDialog.visible = true;
      this.selected_request = {
        id: request.id,
        from_date: request.from_date,
        to_date: request.to_date,
        requestable: request.requestable,
        request_status: { id: request.request_status.id },
        is_content_visible:
          request.is_content_visible == 1 || request.is_content_visible == true
            ? true
            : false,
        crm_user_id: request.crm_user_id,
        value: request.value,
        content: request.content,
        reply_content: request.reply_content,
      };
    },
    delete_user_request(id) {
      let _this = this;
      this.$confirm(
        "This will permanently delete the record. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          this.$http.delete("/delete_user_request/" + id).then((response) => {
            this.$message({
              type: "success",
              message: "Delete completed",
            });
            _this.fill_data();
          });
        })
        .catch(() => {});
    },
    clear_time_sheet_entry() {
      this.selected_time_sheet_record = "";
    },
    create_new_request(date) {
      let from_date = new Date(date);
      let to_date = new Date(date);
      from_date.setHours(8,30,0,0)
      to_date.setHours(17,0,0,0)
      this.selected_request = {
        id: 0,
        from_date: from_date,
        to_date: to_date,
        request_type: { id: "" },
        request_status: { id: "" },
        leave_type: { id: "" },
        crm_user_id: this.selected_user_info.id,
        is_content_visible: false,
        value: 0,
        content: "",
      };

      this.requestDialog.visible = true;
    },
    create_time_sheet_record(date) {
      this.selected_time_sheet_record = {
        created_at: date,
        crm_user_id: this.selected_user_info.id,
        check_in: this.selected_user_info.default_check_in_24_format,
        check_out: this.selected_user_info.default_check_out_24_format
      };
      this.dialogFormVisible = true;
    },
    save_time_sheet_record() {
      this.fill_data();
    },
    delete_time_sheet_record(id) {
      let _this = this;
      let msg = "This will permanently delete the record. Continue?";
      if(this.is_jobtime_input === true) {
        msg = "This will permanently delete not only the record, but also the task time spents. Continue?"
      }
      this.$confirm(
        msg,
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          this.$http.delete("/delete_time_sheet/" + id).then((response) => {
            this.$message({
              type: "success",
              message: "Delete completed",
            });
            _this.fill_data();
          });
        })
        .catch(() => {});
    },
    get_percentage(grouped) {
      if (grouped.total_hours > grouped.daily_hours)
        return { value: 100, class: "success" };
      else if (grouped.daily_hours == 0) return { value: 0, class: "exception" };
      else {
        let val = Math.round((grouped.total_hours / grouped.daily_hours) * 100);
        let class_name = "";
        if (val >= 100) class_name = "success";
        if (val == 0) class_name = "exception";
        else if (val > 50 && val < 100) class_name = "warning";
        else if (val < 50) class_name = "exception";
        return { value: val, class: class_name };
      }
    },
  },
  computed: {
    ...mapGetters(["selected_user_info", "is_admin"]),
    current_date() {
      return new Date();
    },
    start_date() {
      return new Date(
        this.current_date.getFullYear(),
        this.current_date.getMonth(),
        1
      );
    },
    end_date() {
      return new Date(
        this.current_date.getFullYear(),
        this.current_date.getMonth(),
        this.current_date.getDate()
      );
    },
  },
};
</script>
<style lang='scss' scoped>
.flex_container {
  display: flex;
  flex-wrap: wrap;

  .item {
    flex-grow: 1;
  }
}
</style>

<style>
#myprofiledet .el-collapse-item__header {
  line-height: 25px !important;
  height: auto;
}
.header-style {
  width: 100px;
  margin-left: 5px;
  /* display: inline-grid; */
}

.attribute-style {
  /* width: 90px; */
  display: inline-table;
}

.attribute-note {
  /* width: 100px; */
  display: inline-table;
}

.weekend-style {
  color: grey;
}

.holiday-style {
  color: violet;
}

.leave-style {
  color: yellowgreen;
}

.weekend-bg {
  background-color: grey;
}

.holiday-bg {
  background-color: violet;
}

.leave-bg {
  background-color: yellowgreen;
}

.progressbar {
  width: 100%;
  display: inline-block;
}
.small {
  font-size: 12px;
}
.box-container {
  margin: 10px;
}
.box-icon {
  width: 15px;
  height: 15px;
  border-radius: 5px;
  display: inline-block;
}
</style>
