<template>
  <div id="stock_related_tasks_list">
    <div class="row">
      <div class="col s12 m12">
        <table class="responsive-table highlight" id="job_proposals_list_table">
          <thead>
            <tr>
              <th>
                <el-checkbox v-model="all_selected">All</el-checkbox>
              </th>
              <th>Name</th>
              <th>Time allocated labour</th>
              <th>Unit sale price</th>
              <th>Total sale price</th>
            </tr>
          </thead>
          <tbody>
            <StockRelatedTask
              v-for="task in stock_related_tasks"
              :stock_related_task="task"
              v-bind:key="task.id"
            ></StockRelatedTask>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col s6">
        <el-button @click="$emit('hide_stock_related_dialog')">Close</el-button>
      </div>
      <div class="col s6">
        <el-button class="right" @click="add_selected_tasks" type="primary">Add tasks</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { editor_store } from "store/modules/editor";
import { JobProposalMixin } from "mixins/JobProposalMixin.js";
import StockRelatedTask from "./StockRelateTask";
import { debuglog } from "util";

export default {
  name: "StockRelateTasksList",
  store: editor_store,
  mixins: [JobProposalMixin],
  components: { StockRelatedTask },
  data() {
    return {
      stock_related_tasks: [],
      all_selected: false
    };
  },
  props: ["jobs_list", "hide_stock_related_dialog"],
  mounted() {
    this.load_jobs();
  },
  methods: {
    set_inputs_to_default() {
      let _this = this;
      Array.from(document.querySelectorAll("#proposal_edit .el-input")).forEach(
        function(e, i) {
          e.classList.add("browser-default");
          if (e.querySelector("input") != null)
            e.querySelector("input").className += " browser-default";
        }
      );
    },
    load_jobs() {
      this.stock_related_tasks = [];
      this.jobs_list.forEach((el, idx) => {
        this.stock_related_tasks.push({
          id: idx,
          job_id: el.id,
          name: el.name,
          time_allocated_labour: el.time_allocated_labour,
          sale_price: el.labour_minutes_rate,
          selected: false
        });
      });
      setTimeout(() => {
        this.set_inputs_to_default();
      }, 200);
    },
    add_selected_tasks() {
      let data = [];
      let _this = this;
      this.stock_related_tasks.forEach((el, idx) => {
        if (el.selected == true)
          data.push({
            job_id: el.job_id,
            time_allocated_labour: el.time_allocated_labour,
            sale_price: el.sale_price,
            working_file_id: this.working_file_id
          });
      });
      if (data.length > 0) {
        this.add_job_proposal(this.proposal.id, data).then(
          response => {
            this.$message({
              message: `Tasks have been added successfully.`,
              type: "info"
            });
            //_this.job_proposals_list.push(response);
            response.forEach((el, idx) => {
              _this.job_proposals_list.push(el);
            });

            _this.$emit("hide_stock_related_dialog");
            setTimeout(() => {
              _this.set_inputs_to_default();
            }, 200);
          },
          reason => {
            console.log(reason);
            this.$message({
              message: reason,
              type: "error"
            });
          }
        );
      }
    }
  },
  computed: {
    ...mapGetters(["job_proposals_list", "working_file_id", "proposal"])
  },
  watch: {
    all_selected: function(newVal) {
      this.stock_related_tasks.forEach((el, idx) => {
        el["selected"] = newVal;
      });
    },
    jobs_list: function(newVal) {
      this.load_jobs();
    }
  }
};
</script>

