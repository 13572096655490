<template>
  <div>
    <br>
    <el-tabs
      tab-position="top"
      type="border-card"
    >
      <!-- <el-tab-pane label="Tasks lists">
        <span class="tab_header">
          TASKS LIST
          <hr />
        </span>
        <TaskList :project_plan_version="project_plan_version"></TaskList>
      </el-tab-pane> -->
      <el-tab-pane label="Tasks">
        <span class="tab_header">
          TASKS LISTS
          <span class="right">
            <b>Preferred date:</b>
            <el-date-picker
              v-model="preferred_date"
              type="datetime"
              placeholder="Pick a date (optional)"
              size="small"
              :default-time="'17:00:00'"
              @change="update_preferred_date"
            />
          </span>
          <hr>
         
        </span>

        <br>
        <el-card
          v-for="list in proposal_lists"
          v-show="false"
          :key="list.id"
          style="margin-bottom: 10px;"
        >
          <p style="font-size:18px;color:#333;text-decorate:uppercase">
            <b>
              <!--- edit list title -->
              <div
                v-show="proposal.approved == 1"
                class="right"
              >
                <i class="el-icon-edit" />
                <el-switch
                  v-model="list_title_edit"
                  active-color="#333"
                  inactive-color="#f3f3f3"
                />
                <el-button
                  v-show="!taskaddarea"
                  type="success"
                  icon="el-icon-circle-plus"
                  circle
                  @click="taskaddarea = true"
                />
                <el-button
                  v-show="taskaddarea"
                  type="info"
                  icon="el-icon-circle-close"
                  circle
                  @click="taskaddarea = false"
                />
              </div>
              <div v-show="list_title_edit">
                <el-input
                  v-show="list.id != undefined"
                  v-model="list.name"
                  class="inputButtonEdit"
                  @change="update_list(list)"
                />
                <h3 v-show="list.id == undefined">
                  {{ list.name }}
                </h3>
              </div>

              <!--- end edit list title -->

              <span v-show="!list_title_edit">
                {{ list.name }}
                <br>
              </span>
            </b>
          </p>
          <span v-show="!list_title_edit">
            {{ list.note }}
          </span>
          <div
            v-show="taskaddarea"
            style="width:100%;float:left;margin-bottom:10px;background:#f3f3f3;"
          >
            <AddJobsToList
              :list_id="list.id"
              :load_job_proposals_list="load_job_proposals_list"
            />
          </div>
        </el-card>
        <ProjectPlanListTasks
          :editor_only="editor_only"
          @load_job_proposals_list="load_job_proposals_list"
        />
      </el-tab-pane>
      <!-- <el-tab-pane label="Calendar" :lazy="true">
        <span class="tab_header">
          TASKS CALENDAR
          <hr />
        </span>
        <TasksCalendar
          :lazy="true"
          v-on:load_job_proposals_list="load_job_proposals_list"
        ></TasksCalendar>
      </el-tab-pane> -->
      <el-tab-pane
        v-if="editor_only == false"
        label="Stocks"
        :lazy="true"
      >
        <ProjectPlanStocks />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import AddJobsToList from 'components/job_views/sections/AddJobsToList.vue';
import ProjectPlanListTasks from 'components/job_views/project_plan/ProjectPlanListTasks.vue';
import ProjectPlanStocks from 'components/job_views/project_plan/ProjectPlanStocks.vue';
import TaskList from 'components/job_views/sections/TaskList.vue';

import { editor_store } from 'store/modules/editor';
import { mapGetters, mapActions, mapMutations } from 'vuex';

import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import TasksCalendar from 'components/proposal/TasksCalendar.vue';

// mixins
import { JobProposalMixin } from 'mixins/JobProposalMixin.js';
import { ProposalMixin } from 'mixins/ProposalMixin.js';

export default {
  name: 'ProjectPlan',
  store: editor_store,
  components: {
    draggable,
    AddJobsToList,
    FullCalendar,
    TasksCalendar,
    TaskList,
    ProjectPlanListTasks,
    ProjectPlanStocks,
  },
  mixins: [JobProposalMixin, ProposalMixin],
  props: {
    editor_only: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      project_plan_version: true,
      taskaddarea: false,
      list_title_edit: false,
      loading: false,
      preferred_date: '',
    };
  },
  mounted() {
    let _this = this;

    setTimeout(function() {
      _this.set_default_input();
      _this.preferred_date = _this.proposal.preferred_date;
      _this.reload_proposal_plan_list();
    }, 500);
  },
  computed: {
    ...mapGetters([
      'proposal',
      'proposal_lists',
      'job_proposals_list',
      'stock_items_list',
      'locations_list',
    ]),

    total_time_allocated() {
      return this.job_proposals_list.reduce(
        (sum, li) => sum + parseFloat(li.time_allocated_labour || 0),
        0
      );
    },
    total_adjusted_time_allocated() {
      return this.job_proposals_list.reduce(
        (sum, li) => sum + parseFloat(li.time_allocated_adjusted || 0),
        0
      );
    },
  },
  methods: {
    ...mapActions([
      'set_job_proposals_list',
      'set_proposal_lists',
      'set_project_plan_lists',
    ]),

    update_preferred_date() {
      let params = {
        preferred_date: this.preferred_date,
      };
      
      this.$http
        .patch(`/update_proposal_details/${this.proposal.id}`, params)
        .then(
          (response) => {
            this.proposal.preferred_date = response.data.preferred_date;
            this.proposal.is_overdue = response.data.is_overdue;
            this.proposal.preferred_date_formatted =
              response.data.preferred_date_formatted;
            this.$message({
              type: 'success',
              message: 'Preferred date updated successfully',
            });
          },
          (response) => {
            this.$message({
              type: 'error',
              message: 'Something went wrong.',
            });
          }
        );
    },
    reload_proposal_plan_list() {
      let _this = this;
      let list = this.proposal_lists.map((c) => c);
      list.forEach((el, idx) => {
        el.tasks = _this.job_proposals_list.filter(
          (c) => el.id == c.list_id && c.mark_as_deleted != true
        );
      });
      this.set_project_plan_lists(list);
    },

    reset_changes() {
      this.load_job_proposals_list();
    },

    list_tasks(list_id) {
      return this.job_proposals_list
        .filter((c) => c.list_id == list_id && c.mark_as_deleted == 0)
        .sort(function(a, b) {
          return a.position - b.position;
        });
    },
    selectable_row(row, rowIndex) {
      return row.completed != true;
    },
    tasks_row_class({ row, rowIndex }) {
      if (row.completed === true) {
        return 'completed_task';
      } else if (row.is_active === 1) {
        return 'active_task';
      }
      return '';
    },
    update_list(list) {
      let params = {
        id: list.id,
        list_name: list.name,
      };
      this.update_job_list(params).then(
        (response) => {
          this.load_lists();
          this.$message({
            type: 'success',
            message: 'List has been updated',
          });
        },
        (response) => {}
      );
    },
    load_lists() {
      let _this = this;
      _this.set_proposal_lists([]);
      _this.load_proposal_lists(_this.proposal.id).then(
        (response) => {
          _this.set_proposal_lists(response);
        },
        (response) => {
          console.log(response);
        }
      );
    },
    set_default_input() {
      let _this = this;
      Array.from(document.querySelectorAll('.el-input__inner')).forEach(
        function(e, i) {
          e.classList.add('browser-default');
          if (e.querySelector('input') != null)
            e.querySelector('input').className += ' browser-default';
        }
      );
    },
    load_job_proposals_list() {
      let _this = this;

      this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      // set second argument to true 'only_workspace_tasks'
      // to fetch is_initated tasks only if proposal is workspace
      _this.set_job_proposals_list([]);
      _this.load_job_proposals(_this.proposal.id, true).then((response) => {
        _this.set_job_proposals_list(response);
        _this.reload_proposal_plan_list();
        _this.$loading().close();
        setTimeout(function() {
          _this.set_default_input();
        }, 500);
      });
    },
  },
  watch: {
    proposals_list(newVal) {},
    proposal() {
      
      this.preferred_date = this.proposal.preferred_date;
    },
  },
};
</script>

<style scoped>
.tab_header {
  font-weight: 600;
  color: gray;
}

#time_allocated_table td {
  padding: 0px 4px;
}
</style>

<style>
/* .tasks_rows td {
  padding: 5px;
} */

.el-table .completed_task {
  text-decoration: line-through;
  background: rgb(196, 196, 196) !important;
}

.el-table .active_task {
  background: rgb(118, 196, 0);
  padding: 10px;
  color: #fff !important;
  /* float: left; */
}

.drag-handler {
  margin-right: 8px;
  font-size: 16px;
  cursor: move;
}

.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

/* .list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
} */

.layer_name_container {
  grid-template-columns: 1fr auto;
  display: grid;
}
</style>
