export const ProposalMixin = {

  created() {},
  data() {
    return {};
  },
  methods: {
    change_proposal_account(proposal_id, account_id) {

      return new Promise((resolve, reject) => {
        this.$http
          .post(`/change_proposal_account/${proposal_id}`, {
            account_id: account_id
          })
          .then(function (response) {
            resolve(response.body);
          })
          .then(function (response) {
            reject(response);
          });
      });
    },
    get_proposal_details(proposal_id) {
      return new Promise((resolve, reject) => {
        this.$http
          .get(`/job_view_data/${proposal_id}`)
          .then(function (response) {
            resolve(response.body.proposal);
          })
          .then(function (response) {
            reject(response);
          });
      });
    },
    get_notes_list(proposal_id) {
      return new Promise((resolve, reject) => {
        this.$http
          .get(`/proposal_comments/${proposal_id}`)
          .then(function (response) {
            resolve(response.body);
          })
          .then(function (response) {
            reject(response);
          });
      });
    },
    get_proposals_templates_by_account(account_id) {
      return new Promise((resolve, reject) => {
        this.$http.get(`/proposal_templates_by_account/${account_id}`).then(function (response) {
          resolve(response.body);
        }).then(function (response) {
          reject(response);
        });
      });
    },
    load_proposal_lists(id) {
      return new Promise((resolve, reject) => {
        this.$http
          .get(`/proposal_lists/${id}`)
          .then(function (response) {
            resolve(response.body);
          })
          .then(function (response) {
            reject(response);
          });
      });
    },
    download_packing_slip(proposal_id) {
      return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', `/packing_slip/${proposal_id}`, true);
        xhr.responseType = 'arraybuffer';
        xhr.onload = function (e) {
          resolve(this.response);
        };
        xhr.send();
      });
    },
    add_new_note(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .post('comments', params)
          .then(function (response) {
            resolve(response.body);
          })
          .then(function (response) {
            reject(response);
          });
      });
    },
    fetch_proposal_reply_template(proposal_id) {
      let params = {
        proposal_id: proposal_id
      };
      return new Promise((resolve, reject) => {
        this.$http
          .post('proposal_reply_template', params)
          .then(function (response) {
            resolve(response.body);
          })
          .then(function (response) {
            reject(response);
          });
      });
    },
    save_minutes_spent(params) {
      let data = {
        job_proposal_id: params.job_proposal_id
      };
      let jp_id = params.jp_id;
      let minutes = params.minutes;

      return new Promise((resolve, reject) => {
        this.$http
          .post(`/save_minutes_spent/${jp_id}/${minutes}`, data)
          .then(function (response) {
            resolve(response);
          })
          .then(function (response) {
            reject(response);
          });
      });
    },
    complete_job_proposal(id) {
      let params = {
        id: id
      };
      return new Promise((resolve, reject) => {
        this.$http
          .post(`/complete_job_proposal/${id}`, params)
          .then(function (response) {
            resolve(response);
          })
          .then(function (response) {
            reject(response);
          });
      });
    },
    // update_task_completion(id) {
    // 	return new Promise((resolve, reject) => {
    // 		this.$http
    // 			.patch(`/update_task_completion/${id}`)
    // 			.then(function (response) {
    // 				resolve(response)
    // 			})
    // 			.then(function (response) {
    // 				reject(response)
    // 			});
    // 	});
    // },
    assign_user_to_task(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .post('/assign_job_to_user_production_tasks/ ', params)
          .then(function (response) {
            resolve(response);
          })
          .then(function (response) {
            reject(response);
          });
      });
    },
    update_time_spent_by_user(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .post('/update_time_spent_by_user/', params)
          .then(function (response) {
            resolve(response);
          })
          .then(function (response) {
            reject(response);
          });
      });

    },
    // update_job_description(params) {
    // 	return new Promise((resolve, reject) => {
    // 		this.$http
    // 			.post(`/update_job_description/`, params)
    // 			.then(function (response) {
    // 				resolve(response)
    // 			})
    // 			.then(function (response) {
    // 				reject(response)
    // 			});
    // 	});

    // },
    update_jp_date_locked(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .post(`/update_jp_date_locked/${params.id}`, params)
          .then(function (response) {
            resolve(response);
          })
          .then(function (response) {
            reject(response);
          });
      });
    },
    add_job_list(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .post('/add_job_list/', params)
          .then(function (response) {
            resolve(response);
          })
          .then(function (response) {
            reject(response);
          });
      });
    },
    remove_job_list(id) {
      return new Promise((resolve, reject) => {
        this.$http
          .post('/remove_job_list/', {
            id: id
          })
          .then(function (response) {
            resolve(response);
          })
          .then(function (response) {
            reject(response);
          });
      });
    },

    load_jobs_categories(id) {
      return new Promise((resolve, reject) => {
        this.$http
          .get('/category_child_task_job/' + id)
          .then(response => {
            resolve(response.body);
          },
          response => {
            reject(response.body);
          });
      });
    },
    complete_main_job(id) {
      return new Promise((resolve, reject) => {
        this.$http
          .post(`/complete_main_job/${id}`)
          .then(function (response) {
            resolve(response);
            this.$message({
              type: 'success',
              message: 'Job completed successfully'
            });
          })
          .then(function (response) {
            reject(response);
          });
      });
    },
    sort_task_in_list(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .post('/sort_task_in_list/', params)
          .then(function (response) {
            resolve(response);
          })
          .then(function (response) {
            reject(response);
          });
      });
    },
    update_proposal_time_frame(id) {
      return new Promise((resolve, reject) => {
        this.$http
          .post('/update_proposal_jobs_time_frame/', {
            id: id
          })
          .then(function (response) {

            resolve(response);
          })
          .then(function (response) {
            reject(response);
          });
      });
    },
    download_location_packing_slip(location_id) {
      return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', `/location_packing_slip/${location_id}`, true);
        xhr.responseType = 'arraybuffer';
        xhr.onload = function (e) {
          resolve(this.response);
        };
        xhr.send();
      });
    },
    load_users_branch(branch_id) {
      return new Promise((resolve, reject) => {
        this.$http
          .get(`/users_by_branch/${branch_id}`)
          .then(function (response) {
            resolve(response.body);
          })
          .then(function (response) {
            reject(response);
          });
      });
    },
    add_task_to_list(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .post('/add_task_to_list/', params)
          .then(function (response) {
            resolve(response);
          })
          .then(function (response) {
            reject(response);
          });
      });
    },
    add_new_task(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .post('/add_new_task/', params)
          .then(function (response) {
            resolve(response);
          })
          .then(function (response) {
            reject(response);
          });
      });
    },
    delete_task(id) {
      return new Promise((resolve, reject) => {
        this.$http
          .delete(`/delete_job_proposal/${id}`)
          .then(function (response) {
            resolve(response);
          })
          .then(function (response) {
            reject(response);
          });
      });
    },
    add_user_to_task(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .post('/assign_user_to_jp/', params)
          .then(function (response) {
            if (response.data.status == 200)
              resolve(response.data);
            else
              reject(response.data);
          });
      });
    },
    remove_jp_user(id) {
      return new Promise((resolve, reject) => {
        this.$http
          .post('/remove_user_from_jp/', {
            id: id
          })
          .then(function (response) {
            if (response.data.status == 200)
              resolve(response.data);
            else
              reject(response.data);
          })
          .then(function (response) {
            reject(response);
          });
      });
    },
    //params => [id, allocated_time]
    update_allocated(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .post('/update_jp_user_allocated_time/', params)
          .then(function (response) {
            if (response.data.status == 200)
              resolve(response.data);
            else
              reject(response.data);
          })
          .then(function (response) {
            reject(response);
          });
      });
    },
    //params => [id, billable]
    update_billable(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .post(`/update_jp_billable/${params.id}`, params)
          .then(function (response) {
            resolve(response);
          })
          .then(function (response) {
            reject(response);
          });
      });
    },
    //params => [id, billable]
    update_order_number(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .post(`/update_client_order_number/${params.id}`, params)
          .then(function (response) {
            resolve(response);
          })
          .then(function (response) {
            reject(response);
          });
      });
    },
    //params => [id, alternative_descripion]
    update_alternative_description(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .post(`/update_jp_alternative_description/${params.id}`, params)
          .then(function (response) {
            resolve(response);
          })
          .then(function (response) {
            reject(response);
          });
      });
    },
    update_job_list(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .patch('/update_job_list/', params)
          .then(function (response) {
            resolve(response);
          })
          .then(function (response) {
            reject(response);
          });
      });
    },
    update_used_stock_details(proposal_id, stock_items) {
      return new Promise((resolve, reject) => {
        let params = {
          proposal_id: proposal_id,
          stock_items: stock_items
        };
        this.$http
          .post('/proposals/update_stock_items/', params)
          .then(function (response) {
            resolve(response);
          })
          .then(function (response) {
            reject(response);
          });
      });
    },

    //params => [id, is_on_hold]
    update_on_hold_task(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .post(`/update_jp_on_hold/${params.id}`, params)
          .then(function (response) {
            resolve(response);
          })
          .then(function (response) {
            reject(response);
          });
      });
    },
    convert_job_to_workspace(id) {
      return new Promise((resolve, reject) => {
        this.$http
          .get(`/convert_job_to_workspace/${id}`)
          .then(function (response) {
            resolve(response);
          })
          .then(function (response) {
            reject(response);
          });
      });
    },
    //params => [id, manual_date]
    update_custom_start_date(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .post('/update_job_proposal_custom_date/', params)
          .then(function (response) {
            resolve(response);
          })
          .then(function (response) {
            reject(response);
          });
      });
    },
    //params => [id, user_id]
    complete_user_task(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .post(`/complete_user_task/${params.id}`, params)
          .then(function (response) {
            if (response.data.status == 200)
              resolve(response.data);
            else
              reject(response.data);
          })
          .then(function (response) {
            reject(response);
          });
      });
    },

    get_suggested_plan(data) {
      return new Promise((resolve, reject) => {
        this.$http.post('/project_plan_scheduled_tasks', data).then(
          (response) => {
            if (response.body.status == 0) {
              reject(response.body['errors']);
            }
						
            let result = {
              suggested_deadline: response.body.result['deadline'],
              suggested_plan: response.body.result['suggested_plan'],
            };
            resolve(result);
          },
          (reason) => {
            reject(reason);
          }
        );
      });
    },

    confirm_suggested_plan(data){
      return new Promise((resolve,reject) => {
        this.$http.post('/confirm_suggested_plan', data).then(
          (response) => {
            resolve(response);					
          },
          (reason) => {
					  loading.close();
					  this.plan_dialog = false;
					  this.project_plan_loading = false;
					  this.$message({
              type: 'error',
              message: reason.data,
					  });
          }
				  );
      });
			
    }

  }
};