<template>
  <div id="notes-panel">
    <el-collapse ref="notes-panel-1">
      <el-collapse-item
        title="Personal Staff Notes"
        name="1"
      >
        <personal-staff-notes
          ref="personal_staff_notes"
          :proposal_id="proposal_id"
          @reload_comments="reload_personal_staff_notes"
        />
      </el-collapse-item>
      <el-collapse-item
        title="Job Description"
        name="2"
      >
        <proposal-pre-description :proposal_id="proposal_id" />
      </el-collapse-item>
      <el-collapse-item
        title="Page Notes"
        name="3"
      >
        <div class="padding-left-5">
          <page-notes :proposal_id="proposal_id" />
        </div>
      </el-collapse-item>
      <el-collapse-item
        title="Task Notes"
        name="4"
      >
        <task-notes :proposal_id="proposal_id" />
      </el-collapse-item>
      <el-collapse-item
        title="Stock Notes"
        name="5"
      >
        <stock-notes :proposal_id="proposal_id" />
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import {set_default_input} from '../../../utilities/DefaultInput.js';
import ProposalPreDescription from '../../notes/ProposalPreDescription.vue';
import PageNotes from '../../notes/PageNotes.vue';
import PersonalStaffNotes from '../../notes/PersonalStaffNotes.vue';
import TaskNotes from '../../notes/TaskNotes.vue';
import StockNotes from '../../notes/StockNotes.vue';

export default {
  name: 'NotesPanel',
  components: {ProposalPreDescription, PageNotes, PersonalStaffNotes, TaskNotes, StockNotes},
  props: {
    proposal_id: {
      type: [Number,String],
      required: true
    }
  },
  data() {
    return {
      jodit_config : {
        enter: 'br',
      },
    };
  },
  mounted(){
    set_default_input();
  },
  created() {
  },
  methods: {
    reload_personal_staff_notes(){
      this.$refs.personal_staff_notes.get_entries();
    }
  }
};
</script>
<style scoped>
#notes-panel{ padding: 15px 10px; }
.padding-left-5{padding-left: 5px;}
</style>

<style>
#notes-panel .el-collapse-item__header{ line-height: 14px; height: 0px;}
</style>