<template>
  <div class="column">
    <div class="first-line">
      <slot />
      <span class="right">
        <slot name="right" />
      </span>
    </div>
    <div>
      <slot name="second-line" />
    </div>
    <slot name="extend" />
  </div>
</template>

<script>
export default {
  name: 'ColumnElement'
};
</script>

<style lang='scss' scoped>
.column {
  min-height: 58px;
  width:100%;
  margin-bottom:10px;
  border-bottom:1px solid #f3f3f3;
}

.first-line {
  min-height: 29px;
}
</style>
