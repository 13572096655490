export const MinutesToHours = {
  hours(minutes) {
    let hours = 0;
    if(minutes) hours = Math.floor(Math.abs(Number(minutes)) / 60);
    return hours;
  },
  decimal_hours(minutes) {
    let hours = 0;
    if(minutes) {
      hours = Math.abs(Number(minutes)) / 60;
      hours = Math.round(hours * 100) / 100;
    }
    return hours;
  },
  minutes(minutes) {
    let return_minutes = 0;
    if(minutes) return_minutes = Math.round((Math.abs(Number(minutes)) % 60));

    return return_minutes;
  }
};

export function HoursToMinutes(hours, minutes) {
  let hours_part = 0;
  let minutes_part = 0;
  if(hours) hours_part = Number(hours) * 60;
  if(minutes) minutes_part = Number(minutes);

  return hours_part + minutes_part;
}

export function display_minutes(minutes) {
  return `${minutes} MINS`;
}

export function display_hours(minutes) {
  let sign ='';
  if(minutes && Number(minutes) < 0) {
    sign = '-';
  }
  return `${sign}${MinutesToHours.hours(minutes)} H ${ MinutesToHours.minutes(minutes)} MINS`;
}

export function display_decimal_hours(minutes) {
  let sign ='';
  if(minutes && Number(minutes) < 0) {
    sign = '-';
  }
  return `${sign}${MinutesToHours.decimal_hours(minutes)} hours`;
}

export function display_time(is_hours, minutes, is_decimal_hours=false) {
  if(is_hours) {
    if(is_decimal_hours) return display_decimal_hours(minutes);
    return display_hours(minutes);
  }

  return display_minutes(minutes);
}
