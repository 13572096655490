<template>
  <div id="variations_lists_div">
    <div class="overviewDiv">
      <el-container style="height: auto; border: 1px solid #eee">
        <el-main>
          <h2>VARIATIONS LISTS</h2>
          <el-button
            type="info"
            plain
            size="small"
            class="right"
            @click="add_variations_list"
          >
            +Add variation list
          </el-button>
          <el-table
            :data="variations_lists"
            style="width: 100%"
          >
            <el-table-column
              label="Name"
              prop="name"
            />
            <el-table-column
              label="Approved By"
              prop="approved_by"
            />
            <el-table-column
              label="Description"
              prop="list_description"
            />
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-button
                  style="margin: 10px 0px;"
                  size="small"
                  class="right"
                  @click="add_stock_items(props.row)"
                >
                  Add stock
                </el-button>
                <h3><b>STOCK ITEMS</b></h3>
                <hr>
                <el-table :data="props.row.stock_items">
                  <el-table-column
                    label="ID"
                    prop="stock_id"
                  />
                  <el-table-column
                    label="Internal Name"
                    prop="internal_name"
                  />
                  <el-table-column
                    label="Desc"
                    prop="description"
                  />
                  <el-table-column align="right">
                    <template slot-scope="scope">
                      <el-button
                        size="mini"
                        type="info"
                        icon="el-icon-edit"
                        circle
                        @click="edit_variation_item(scope.$index, scope.row)"
                      />
                      <el-button
                        size="mini"
                        icon="el-icon-delete"
                        circle=""
                        @click="delete_variation_item(scope.$index, scope.row)"
                      />
                    </template>
                  </el-table-column>
                </el-table>
                <br>
                <el-button
                  style="margin: 10px 0px;"
                  size="small"
                  class="right"
                  @click="add_tasks(props.row)"
                >
                  Add task
                </el-button>
                <h3><b>TASKS</b></h3>

                <hr>
                <el-table :data="props.row.jps">
                  <el-table-column
                    label="ID"
                    prop="jp_id"
                  />
                  <el-table-column
                    label="Name"
                    prop="name"
                  />
                  <el-table-column
                    label="Desc"
                    prop="description"
                  />
                  <el-table-column align="right">
                    <template slot-scope="scope">
                      <el-button
                        size="mini"
                        type="info"
                        icon="el-icon-edit"
                        circle
                        @click="edit_variation_item(scope.$index, scope.row)"
                      />
                      <el-button
                        size="mini"
                        icon="el-icon-delete"
                        circle=""
                        @click="delete_variation_item(scope.$index, scope.row)"
                      />
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column align="right">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="info"
                  icon="el-icon-edit"
                  circle
                  @click="edit_variations_list(scope.$index, scope.row)"
                />
                <el-button
                  size="mini"
                  icon="el-icon-delete"
                  circle=""
                  @click="delete_variations_list(scope.$index, scope.row)"
                />
              </template>
            </el-table-column>
          </el-table>
        </el-main>
      </el-container>
    </div>

    <el-dialog
      :title="`Stocks list [${selected_list.name}]`"
      :visible.sync="stocks_list_dialog"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <ul>
        <li
          v-for="stock in stocks_list"
          :key="stock.id"
        >
          <el-checkbox v-model="stock.checked">
            {{ stock.internal_name }}
          </el-checkbox>
        </li>
      </ul>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="stocks_list_dialog = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="update_stocks"
        >Save</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="`Tasks list [${selected_list.name}]`"
      :visible.sync="tasks_list_dialog"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <strong>List {{ selected_list.name }}</strong>
      <ul>
        <li
          v-for="task in tasks_list"
          :key="task.id"
        >
          <el-checkbox v-model="task.checked">
            {{ task.name }}
          </el-checkbox>
        </li>
      </ul>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="tasks_list_dialog = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="update_tasks"
        >Save</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="Variation list"
      :visible.sync="variation_list_dialog"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form
            ref="form"
            :model="selected_variation_list"
            label-width="120px"
          >
            <el-form-item
              label="Name"
              prop="name"
            >
              <el-input
                v-model="selected_variation_list.name"
                autocomplete="off"
                size="mini"
              />
            </el-form-item>
            <el-form-item
              label="Approved by"
              prop="approved_by"
            >
              <el-input
                v-model="selected_variation_list.approved_by"
                autocomplete="off"
                size="mini"
              />
            </el-form-item>
            <el-form-item
              label="Description"
              prop="list_description"
            >
              <el-input
                v-model="selected_variation_list.list_description"
                autocomplete="off"
                size="mini"
              />
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>

      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="variation_list_dialog = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="save_variation_list"
        >Save</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { editor_store } from 'store/modules/editor';

export default {
  name: 'VariationsLists',
  store: editor_store,
  props: ['proposal_id'],
  data() {
    return {
      stocks_list_dialog: false,
      tasks_list_dialog: false,
      variations_lists: [],
      selected_list: { id: undefined, name: '' },
      stocks_list: [],
      tasks_list: [],
      variation_list_dialog: false,
      selected_variation_list: {
        id: undefined,
        name: '',
        approved_by: '',
        list_description: '',
        proposal_id: undefined,
      },
    };
  },
  computed: {},
  watch: {
    proposal_id: function(newVal, oldValue) {
      //this.fill_lists();
    },
  },
  mounted() {
    let _this = this;
    setTimeout(function() {
      _this.fill_lists();
    }, 2000);
  },
  methods: {
    fill_lists() {
      this.$http
        .get(`/variations_list_by_proposal/${this.proposal_id}`)
        .then((response) => {
          this.variations_lists = response.data;
        });
    },
    add_stock_items(list) {
      var loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      this.selected_list = list;
      this.stocks_list = [];
      this.$http.get(`/variation_available_stocks/${this.proposal_id}`).then(
        (response) => {
          loading.close();
          response.data.forEach((el, idx) => {
            this.stocks_list.push({ id: el.id, internal_name: el.internal_name, checked: false });
          });
          this.stocks_list_dialog = true;
        },
        (response) => {
          loading.close();
          console.log(response.data);
        }
      );
    },
    add_tasks(list) {
      var loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      this.selected_list = list;
      this.tasks_list = [];
      this.$http.get(`/variation_available_tasks/${this.proposal_id}`).then(
        (response) => {
          loading.close();
          response.data.forEach((el, idx) => {
            this.tasks_list.push({ id: el.id, name: el.name, checked: false });
          });
          this.tasks_list_dialog = true;
        },
        (response) => {
          loading.close();
          console.log(response.data);
        }
      );
    },
    update_stocks() {
      let selected_stocks = this.stocks_list
        .filter((c) => c.checked == true)
        .map((c) => {
          return {
            variationable_id: c.id,
            variationable_type: 'ProposalStockItem',
            variations_list_id: this.selected_list.id,
          };
        });
      this.$http
        .post('/variation_stocks/', { stocks_list: selected_stocks })
        .then(
          (response) => {
            this.fill_lists();
            this.stocks_list_dialog = false;
            this.$message({
              type: 'success',
              message: 'Updated',
            });
          },
          (response) => {
            this.$message({
              type: 'error',
              message: response.data,
            });
          }
        );
    },
    update_tasks() {
      let selected_tasks = this.tasks_list
        .filter((c) => c.checked == true)
        .map((c) => {
          return {
            variationable_id: c.id,
            variationable_type: 'JobProposal',
            variations_list_id: this.selected_list.id,
          };
        });
      this.$http.post('/variation_tasks/', { tasks_list: selected_tasks }).then(
        (response) => {
          this.fill_lists();
          this.tasks_list_dialog = false;
          this.$message({
            type: 'success',
            message: 'Updated',
          });
        },
        (response) => {
          this.$message({
            type: 'error',
            message: response.data,
          });
        }
      );
    },
    add_variations_list() {
      this.selected_variation_list = {
        id: undefined,
        name: '',
        approved_by: '',
        list_description: '',
      };
      this.variation_list_dialog = true;
      this.set_inputs_to_default();
    },
    edit_variations_list(idx, row) {
      //this.$http.post("/variations_list", {});
      var selected_variation = this.variations_lists.filter(
        (c) => c.id == row.id
      )[0];
      if (selected_variation != undefined) {
        this.selected_variation_list = Object.assign({}, selected_variation);
        this.variation_list_dialog = true;
        this.set_inputs_to_default();
      } else {
        this.$message({ type: 'warning', message: 'message content' });
      }
    },
    edit_variation_item(idx, row) {
      this.$prompt('Variation item description', 'Description', {
        confirmButtonText: 'Save',
        cancelButtonText: 'Cancel',
        inputValue: row.description,
      })
        .then(({ value }) => {
          let data = {
            id: row.id,
            description: value,
          };
          this.$http.patch(`/variation_item/${row.id}`, data).then(
            (response) => {
              this.fill_lists();
              this.$message({
                type: 'success',
                message: 'Updated',
              });
            },
            (response) => {
              this.$message({
                type: 'error',
                message: response,
              });
            }
          );
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Input canceled',
          });
        });
    },
    save_variation_list() {
      let url = '';
      let type = '';
      if (this.selected_variation_list.id == undefined) {
        url = '/variations_lists/';
        type = 'post';
      } else {
        url = `/variations_lists/${this.selected_variation_list.id}`;
        type = 'patch';
      }
      let cloned = Object.assign({}, this.selected_variation_list);
      delete cloned.variation_items;
      cloned.proposal_id = this.proposal_id;
      this.$http[type](url, {
        variation_list: cloned,
      }).then(
        (response) => {
          this.fill_lists();
          this.variation_list_dialog = false;
        },
        (response) => {
          this.$message({ type: 'error', message: response.data });
          console.log(response.data);
        }
      );
    },
    save_variation_item() {
      let url = `/variations_lists/${this.selected_variation_item.id}`;
      let type = 'patch';
      let cloned = Object.assign({}, this.selected_variation_item);
      delete cloned.variation_items;
      cloned.proposal_id = this.proposal_id;
      this.$http
        .patch(url, {
          variation_list: cloned,
        })
        .then(
          (response) => {
            this.fill_lists();
            this.variation_list_dialog = false;
          },
          (response) => {
            this.$message({ type: 'error', message: response.data });
            console.log(response.data);
          }
        );
    },
    delete_variations_list(idx, list) {
      let _this = this;
      this.$confirm(
        'This will permanently delete the file. Continue?',
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      )
        .then(() => {
          _this.$http.delete(`/variations_lists/${list.id}`).then(
            (response) => {
              _this.fill_lists();
              _this.$message({
                type: 'success',
                message: 'List deleted',
              });
            },
            (response) => {
              console.log(response.data);
            }
          );
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Delete canceled',
          });
        });
    },
    delete_variation_item(idx, variation_item) {
      let _this = this;
      this.$confirm(
        'This will permanently delete the item. Continue?',
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      )
        .then(() => {
          _this.$http.delete(`/variation_item/${variation_item.id}`).then(
            (response) => {
              _this.fill_lists();
              _this.$message({
                type: 'success',
                message: 'Variation deleted',
              });
            },
            (response) => {
              console.log(response.data);
            }
          );
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Delete canceled',
          });
        });
    },
    set_inputs_to_default(mills = 300) {
      let _this = this;
      setTimeout(() => {
        Array.from(document.querySelectorAll('.el-input')).forEach(function(
          e,
          i
        ) {
          e.classList.add('browser-default');
          if (e.querySelector('input') != null)
            e.querySelector('input').className += ' browser-default';
        });
      }, mills);
    },
  },
};
</script>

<style>
.overviewDiv {
  border: 0px solid #999;
  padding: 20px;
}

.overviewDiv h2 {
  font-size: 20px;
  text-transform: uppercase;
  background: #999;
  color: #fff;
  padding: 10px;
  text-align: center;
  letter-spacing: 2px;
  margin: 1px 0 1px 0;
}

@media only screen and (max-width: 600px) {
  .overviewDiv {
    border: 0px solid #999;
    padding: 0px;
  }
}
</style>
