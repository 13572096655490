<template>
  <el-dialog
    width="500px"
    v-loading="loading"
    :visible.sync="requestDialog.visible"
    title="Leave/Overtime Request"
    @opened="set_default_input()"
  >
    <el-row>
      <el-select
        v-model="selected_request.requestable"
        :disabled="disable_inputs"
        ref="requestable"
        v-on:change="update_balance"
        placeholder="Select"
        size="mini"
      >
        <el-option-group
          v-for="group in request_options"
          :key="group.label"
          :label="group.label"
        >
          <el-option
            v-for="item in group.options"
            :key="item.value"
            :label="item.name"
            :group="group.label"
            :value="item.value"
          >
          </el-option>
        </el-option-group>
      </el-select>
    </el-row>
    <el-row :gutter="10">
      <el-col :md="12">
        <el-date-picker
          :disabled="disable_inputs"
          v-model="selected_request.from_date"
          size="mini"
          type="datetime"
          format="dd.MM.yyyy HH:mm"
          default-time="08:00:00"
          v-on:change="date_range_changed"
          placeholder="From"
        >
        </el-date-picker>
      </el-col>
      <el-col :md="12">
        <el-date-picker
          :disabled="disable_inputs"
          v-model="selected_request.to_date"
          type="datetime"
          size="mini"
          format="dd.MM.yyyy HH:mm"
          v-on:change="date_range_changed"
          default-time="17:00:00"
          placeholder="To"
        >
        </el-date-picker>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :md="12">
        <span>
          {{ selected_value_header }}
          <el-input
            v-model="selected_request.value"
            :disabled="selected_value_disablity"
            size="small"
            :precision="2"
            :step="0.5"
            :min="0.5"
          ></el-input>
          <span v-show="selected_value_header == 'Hour(s)'">
            <br /><small>{{ selected_request.value / 8 }} day(s)</small>
          </span>
        </span>
      </el-col>
      <el-col :md="12" v-show="balance_visible">
        Balance
        <el-input disabled="disabled" v-model="balance" size="small" />
        <br /><small>{{ balance / 8 }} day(s)</small>
      </el-col>
    </el-row>
    <el-row>
      <el-input
        v-model="selected_request.content"
        type="text"
        :disabled="disable_inputs"
        placeholder="Enter description... "
        style="width:100%"
      >
      </el-input>
      <el-checkbox v-model="selected_request.is_content_visible"
        >Description visible to all users ? </el-checkbox
      >
    </el-row>
    <el-row>
      <el-select
        ref="request_status"
        v-model="selected_request.request_status.id"
        v-show="is_admin == '1'"
        :disabled="disable_inputs"
        placeholder="Select status"
        filterable
        :width="100"
        size="mini"
      >
        <el-option
          v-for="item in request_statuses"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-row>
      <el-input
        v-model="selected_request.reply_content"
        type="textarea"
        :rows="4"
        v-show="is_admin == '1'"
        placeholder="Write your comment in reference to the approval/decline of the request."
        style="width:100%;height: 6rem !important;"
      >
      </el-input>

    <span slot="footer" class="dialog-footer">
      <el-button @click="requestDialog.visible = false">Cancel</el-button>
      <el-button type="primary" v-on:click="save_request_record"
        >Confirm</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters, mapActions} from "vuex";
import { user_profile } from "../../store/modules/user_profile";

export default {
  name: "Request",
  store: user_profile,
  props: ["selected_request", "requestDialog", "reload_data"],
  data() {
    return {
      disable_inputs: false,
      request_types: [],
      request_statuses: [],
      request_options: [],
      leave_types: [],
      has_leave: false,
      balance: 0,
      balance_visible: false,
      loading: false,
      selected_value_header: "Hour(s)",
      selected_value_disablity: true,
    };
  },
  computed: {
    ...mapGetters(["selected_user_info", "is_admin"]),
  },
  mounted() {
    this.set_default_input();
    this.fill_request_types();
    this.fill_request_options();
    this.fill_leave_types();
    this.fill_request_statuses();
  },
  watch: {
    selected_request(oldVal, newVal) {
      this.disable_inputs =
        this.selected_request.request_status != undefined &&
        this.selected_request.request_status.id != 1 &&
        this.selected_request.request_status.id != "";
      this.date_range_changed();
    },
  },
  methods: {
    ...mapActions(["load_user_balance"]),
    selected_group() {
      let arr = this.selected_request.requestable.split("-");
      return arr > 0 ? arr[0] : "";
    },
    fill_request_options() {
      let _this = this;
      this.$http.get("/request_options").then((response) => {
        _this.request_options = response.data;
      });
    },
    fill_request_types() {
      let _this = this;
      this.$http.get("/request_types").then((response) => {
        _this.request_types = response.data;
      });
    },
    fill_leave_types() {
      let _this = this;
      this.$http.get("/leave_types").then((response) => {
        _this.leave_types = response.data;
      });
    },
    fill_request_statuses() {
      let _this = this;
      this.$http.get("/request_statuses").then((response) => {
        _this.request_statuses = response.data;
      });
    },
    calculate_hours(type) {
      let _this = this;
      let start_date = this.selected_request.from_date;
      let end_date = this.selected_request.to_date;
      if (start_date != undefined && end_date != undefined) {
        // disable auto calculate status if approved or declined
        let data = {
          from_date: start_date,
          to_date: end_date,
          request_type: type,
        };
        this.loading = true;
        this.$http
          .post("/calculate_hours_by_request", data)
          .then((response) => {
            _this.selected_request.value = response.data.hours;
            this.loading = false;
          });
      }
    },
    date_range_changed() {
      this.update_balance(this.selected_request.requestable);
    },
    update_balance(selected_value) {
      let _this = this;
      if (selected_value == undefined) {
        _this.balance_visible = false;
        _this.current_balance = 0;
        return;
      }
      _this.selected_value_header = selected_value.startsWith("RequestType")
        ? "Dollar(s)/Other"
        : "Hour(s)";
      _this.selected_value_disablity = !selected_value.startsWith(
        "RequestType"
      );
      _this.balance_visible = selected_value.startsWith("LeaveType");

      if (
        selected_value != undefined &&
        selected_value.startsWith("RequestType") == false
      ) {
        // Calculate leave hours
        _this.balance_visible = selected_value.startsWith("Leave");
        if (selected_value.startsWith("Leave")) {
          _this.calculate_hours("leave");
        } else if (selected_value.startsWith("Overtime"))
          _this.calculate_hours("overtime");

        _this.loading = true;
        let data = { leave_type_id: selected_value.split("-")[1] };
        _this.$http.post("/user_balance", data).then((response) => {
          _this.balance = response.data.balance;
          _this.loading = false;
        });
      }
    },

    save_request_record() {
      let _this = this;
      // below needs to be changed by a form with validation
      if (
        _this.selected_request == "" &&
        _this.selected_request.requestable == ""
      )
        return;
      // selected_request.request_type.id
      //this.selected_request.requestable = {request_type: ''}
      _this.loading = true;
      _this.$http.post("/update_user_request/", _this.selected_request).then(
        (response) => {
          // _this.requestDialog = false;
          _this.loading = false;
          _this.$message({
            type: "success",
            message: "Updated successfully.",
          });
          _this.reload_data();
          _this.load_user_balance();
        },
        function(response) {
          _this.loading = false;
          _this.$message.error("Couldn't save record.Perhaps text is too long?");
        }
      );
    },
    set_default_input() {
      Array.from(document.querySelectorAll('.el-select__input, .el-input__inner, .el-textarea__inner')).forEach(
          function (e) {
            e.classList.add('browser-default');
            if (e.querySelector('input') != null)
              e.querySelector('input').className += ' browser-default';
          }
      );
    },
  },
};
</script>

<style scoped>
.el-row{ margin-bottom: 20px;}
.el-input--mini .el-input__inner {
  height: 28px !important;
}
</style>
