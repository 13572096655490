<template>
  <section class="section__schedule">
    <el-row :gutter="20">
      <el-col
        :span="8"
        :xs="24"
      >
        <b>SCHEDULE</b>&nbsp;
        <el-checkbox
          v-model="shared_scheduled_item.is_active"
          label="Is Active"
        />
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col
        :span="8"
        :xs="24"
      >
        <el-select
          v-model="shared_scheduled_item.schedule_type_id"
          placeholder="Select"
          class="sale-date"
          @change="selected_schedule_type"
        >
          <el-option
            v-for="schedule_type in schedule_types_lists.schedule_types"
            :key="schedule_type.id"
            :value="schedule_type.id"
            :label="schedule_type.type_name"
          />
        </el-select>
      </el-col>
      <el-col
        v-if="is_show_weekly"
        :span="8"
        :xs="24"
      >
        <el-select
          v-model="weekly_repeat_every"
          placeholder="Select"
          class="sale-date"
          @change="selected_repeat_every"
        >
          <el-option
            v-for="(day,index) in schedule_types_lists.weekly_list"
            :key="index"
            :value="day"
            :label="day.toUpperCase()"
          />
        </el-select>
      </el-col>
      <el-col
        v-else-if="is_show_monthly"
        :span="8"
        :xs="24"
      >
        <el-select
          v-model="monthly_repeat_every"
          placeholder="Select"
          class="sale-date"
          @change="selected_repeat_every"
        >
          <el-option
            v-for="(day,index) in schedule_types_lists.days_list"
            :key="index"
            :value="day.value"
            :label="day.label"
          />
        </el-select>
      </el-col>
      <el-col
        :span="8"
        :xs="24"
      >
        <el-date-picker
          v-model="shared_scheduled_item.end_at"
          class="sale-date"
          type="date"
          placeholder="End at(Optional)"
        />
      </el-col>
    </el-row>
  </section>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

const WEEKLY = 2;
const MONTHLY = 3;

export default {
  name: 'ScheduleElements',
  props: {
    scheduled_item: {
      type: Object,
      required: true
    },
    schedule_types_lists: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      weekly_repeat_every: this.schedule_types_lists.weekly_list[0],
      monthly_repeat_every: this.schedule_types_lists.days_list[0].value,
    };
  },
  computed: {
    ...mapGetters([
      'get_scheduled_item'
    ]),

    shared_scheduled_item: {
      get() {
        return this.get_scheduled_item;
      },
      set (value) {
        this.set_scheduled_item(value);
      }
    },
    is_show_weekly() {
      return this.shared_scheduled_item.schedule_type_id === WEEKLY;
    },
    is_show_monthly() {
      return this.shared_scheduled_item.schedule_type_id === MONTHLY;
    }
  },
  created() {
    this.shared_scheduled_item = {
      is_active: !!parseInt(this.scheduled_item.is_active),
      schedule_type_id: this.scheduled_item.schedule_type_id || this.schedule_types_lists.schedule_types[0].id,
      repeat_every: this.scheduled_item.repeat_every,
      end_at: this.scheduled_item.end_at
    };

    if(this.scheduled_item.repeat_every) {
      if(this.scheduled_item.schedule_type_id === WEEKLY) {
        this.weekly_repeat_every = this.scheduled_item.repeat_every;
      } else if(this.scheduled_item.schedule_type_id === MONTHLY) {
        this.monthly_repeat_every = this.scheduled_item.repeat_every;
      }
    }
  },
  methods: {
    ...mapActions([
      'set_scheduled_item'
    ]),

    selected_schedule_type(selected) {
      if(selected=== WEEKLY) {
        this.shared_scheduled_item.repeat_every = this.weekly_repeat_every;
      } else if(selected === MONTHLY) {
        this.shared_scheduled_item.repeat_every = this.monthly_repeat_every;
      }
    },
    selected_repeat_every(selected) {
      this.shared_scheduled_item.repeat_every = selected;
    },
  }
};
</script>

<style scoped>
.section__schedule {
  padding: 20px;
  border: 1px solid #e4dfdf;
}
</style>