<template>
  <div>
    <a :href="download_statement_link">
      <el-button
        type="success"
        size="small"
      >
        Download Statement
      </el-button>
    </a>
    <a :href="download_transaction_link">
      <el-button
        type="success"
        size="small"
      >
        Download Transaction
      </el-button>
    </a>
    &nbsp;&nbsp;&nbsp;
    <el-input
      v-model="month_ago"
      type="number"
      class="input__width"
      min="1"
      oninput="validity.valid ||(value=1);"
    />
    <span>Months</span>
  </div>
</template>

<script>
export default {
  name: 'DownloadStatement',
  props: {
    account_id: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      month_ago: 3,
    };
  },
  computed: {
    download_statement_link() {
      return `/show_statement/${this.month_ago}/${this.account_id}.pdf`;
    },
    download_transaction_link() {
      return `/show_transaction/${this.month_ago}/${this.account_id}.pdf`;
    }
  }
};
</script>

<style lang='scss' scoped>
.input__width {
  width: 60px;
}
</style>