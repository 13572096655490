<template>
  <div id="prospect-layout">
    <el-container>
      <el-header>
        <div id="header">
          <a href="/">
            <img
              style=" opacity: 0.6;height: 50px;padding-top: 20px;"
              src="/images/logo.png"
            >
          </a>
        </div>
      </el-header>
      <el-container>
        <el-aside width="100">
          <navigation :crm_user_id="crm_user_id" />
        </el-aside>

        <el-main>
          <router-view />
        </el-main>
      </el-container>
      <el-footer>&#169;{{ current_year }} ANNEX GROUP</el-footer>
    </el-container>
  </div>
</template>

<script>
import Navigation from './Navigation.vue';

export default {
  components: { Navigation },
  props: {
    crm_user_id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      logo: '/images/logo.png',
    };
  },
  computed: {
    current_year() {
      let d = new Date();
      return d.getFullYear();
    }
  },
  methods: {},
};
</script>

<style scoped>

#header{
  display: flex;
  align-items: center;

  font-size: 16px;
}
#header > span {
  padding-left: 5px;
}

#prospect-container .el-container {
  width: 100%;
  height: 100%;
}

#prospect-layout .el-aside {
  background-color: #ffffff;
  color: #333;
  text-align: center;
  height: calc(100vh - 100px);
  border-right: 1px solid lightgrey;
}

#prospect-layout .el-main {
  border: 0px;
  margin: 0px;
  padding: 0px;
  background: #ffffff;
}
#prospect-layout .el-header {
  background: #fff;
  min-width: 20vh;
  color: #000;
  border-bottom: 1px solid lightgrey;
}
#prospect-layout .el-footer {
  background:#2095FB;
  min-width: 20vh;
  padding: 10px;
  font-size: 12px;
  color: #fff;
  text-align: right;
  border-top: 1px solid lightgrey;
}
</style>