<template>
  <tr class="list__input">
    <td>
      <el-select
        v-model="category_id"
        size="small"
        class="form__item"
        filterable
        reserve-keyword
        placeholder="Select"
        @change="onCategoryChange"
      >
        <el-option
          v-for="item in category_options"
          :key="item.id"
          :label="item.name"
          :value="item.id"
          style="background:#fff"
        />
      </el-select>
    </td>
    <td>
      <el-select
        v-model="form.stock_item_id"
        size="small"
        class="form__item"
        clearable
        filterable
        remote
        reserve-keyword
        placeholder="Please select a favorite stock item, or type to search items"
        :remote-method="onSearchItem"
        @change="onStockItemChange"
        @clear="onStockItemClear"
      >
        <el-option
          v-for="item in stock_list"
          :key="item.id"
          :label="item.name"
          :value="item.id"
          style="background:#fff"
        />
      </el-select>
    </td>
    <td>{{ supplier_name }}</td>
    <td />
    <td />
    <td>
      <el-input
        v-model="form.stock_quantity"
        size="mini"
        style="color:#999!important;   "
      />
    </td>
    <td v-show="proposal.approved === true || proposal.approved === 1" />
    <td />
    <td>
      <el-input
        v-model="form.sale_price"
        :min="0"
        size="mini"
        style="color:#999!important;"
      />
    </td>
    <td />
    <td><Money :amount="total_sale_price" /></td>
    <td>
      <el-button
        type="success"
        icon="el-icon-circle-plus"
        circle
        @click="onAddTask"
      />
    </td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex';
import { editor_store } from 'store/modules/editor';
import {EventBus} from '../../../../utilities/EventBus';
import Money from '../../../shared/Money';

export default {
  name: 'NewStockToSummaryEdit',
  store: editor_store,
  components: { Money },
  props: {
    working_file_id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: {
        stock_quantity: 1,
        sale_price: 0,
        stock_item_id: null
      },
      category_options: [],
      category_id: null,
      stock_list: [],
      supplier_name: ''
    };
  },
  computed: {
    ...mapGetters([
      'proposal',
      'stock_items_list'
    ]),
    total_sale_price() {
      return this.form.sale_price * this.form.stock_quantity;
    }
  },
  mounted() {
    this.fetch_categories();
  },
  methods: {
    onAddTask() {
      if(!this.form.stock_item_id) {
        this.$message({
          message: 'Need to select Stock',
          type: 'error',
        });
        return;
      }
      let params = this.form;
      params.id = this.proposal.id;
      params.proposal_id = this.proposal.id;
      if (this.working_file_id) params.working_file_id = this.working_file_id;

      this.$http.post('/save_stock_item', params).then((res)=>{
        this.stock_items_list.push(res.body.data);
        this.$message({
          message: 'Stock added successfully.',
          type: 'success',
        });
        EventBus.$emit('reloadCategoryWorkingFiles');
      }, (res) =>{
        console.error(res);
      });
    },
    onCategoryChange() {
      this.stock_list = [];
      this.supplier_name = '';
      this.form.stock_item_id = null;
      this.form.stock_quantity = 1;
      this.form.sale_price = 0;

      this.$http.post('/favorite_stocks_by_category', {
        category_supplier_id: this.category_id,
        branch_id: this.proposal.branch_id,
      }).then((response) => { this.stock_list = response.data; });
    },
    onStockItemChange(id) {
      if(!id) return;

      let stockItem = this.stock_list.find(e => e.id === id);
      this.form.sale_price = Number(stockItem.sale_price);
      this.form.stock_quantity = 1;
      this.supplier_name = stockItem.supplier_name;
    },
    onSearchItem(term) {
      if ( !term ||term.length <= 2 || !this.category_id) return;

      this.$http.post('/category_products', {
        category_supplier_id: this.category_id,
        term: term,
        branch_id: this.proposal.branch_id,
      }).then((response) => { this.stock_list = response.data; });

    },
    onStockItemClear() {
      this.onCategoryChange();
    },
    fetch_categories() {
      this.$http.get('/s_categories').then(
        (response) => { this.category_options = response.data; },
        (response) => { console.error(response);}
      );
    }
  }
};
</script>

<style scoped>
.form__item {
  width: 100%;
}

.list__input {
  background-color: #e5f7e6;
}
</style>