<template>
  <div id="mailbox-users">
    <div v-loading.fullscreen.lock="loading">
      <div>
        <h3>
          <strong>MAILBOX LIST</strong>
          <el-row>
            <el-button
                class="right"
                button
                type="primary"
                plain
                size="small"
                v-on:click="create_new_mailbox"
                style="margin-top: -10px;"
            >New Mailbox
            </el-button>
          </el-row>
          <hr/>
        </h3>
        <el-row v-for="mailbox in mailboxes" :key="mailbox.id">
            <el-checkbox
                label="index"
                ref="check"
                :key="mailbox.id"
                size="mini"
                :checked="mailbox.shared == 1"
                @change="save_shared_mailbox(mailbox.id)"
            >{{ mailbox.email }}
            </el-checkbox>
          <br>
            <el-radio-group v-model="radio" @change="set_main_mailbox(mailbox.id)">
              <el-radio :label="mailbox.id" size="mini">main email</el-radio>
            </el-radio-group>
          <span    class="right"></span>
            <el-button
                @click.native.prevent="edit_mailbox(mailbox.id, mailbox.encrypted_mailpassword, mailbox.email)"
                size="mini"
                type="edit"
                icon="el-icon-edit"
                circle
            ></el-button>
            <email-signature :mailbox_id="mailbox.id"></email-signature>
            <email-vacation :mailbox_id="mailbox.id"></email-vacation>
            <el-button
                @click.native.prevent="delete_mailbox(mailbox.id)"
                size="mini"
                type="danger"
                icon="el-icon-delete"
                circle></el-button>
          <el-divider />
        </el-row>
      </div>
    </div>

    <el-dialog :visible.sync="dialog_form_visible" width="30%">
      <span slot="title">
        <i class="el-icon-info"></i>
        {{ dialog_title }}
      </span>
      <div v-if="errors.length" style="margin-top: -20px;">
        <p>Please correct the following error(s):</p>
        <ul>
          <li v-for="(error,index) in errors" :key="index">{{ error }}</li>
        </ul>
      </div>
      <el-form :model="form" ref="mailbox_form">
        <el-row :gutter="20">
          <el-col :span="20">
            <el-form-item>
              <el-input
                  v-model="form.mailbox_email"
                  name="mailbox_email"
                  size="small"
                  placeholder="Name (please select domain below)"
                  autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                  v-model="form.mailbox_password"
                  name="mailbox_password"
                  size="small"
                  placeholder="Mailbox password"
                  autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item v-show="edit == false">
              <el-checkbox
                  :indeterminate="is_indeterminate"
                  v-model="check_all"
                  @change="handle_check_all_domains"
              >Check all
              </el-checkbox>
              <div style="margin: 15px 0;"></div>
              <el-checkbox-group v-model="form.checked_domains" @change="handle_domain_change">
                <el-checkbox v-for="domain in domains" :label="domain" :key="domain">{{ domain }}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog_form_visible = false">Cancel</el-button>
        <el-button
            v-show="edit == false"
            type="primary"
            @click="save_new_mailbox('mailbox_form')"
        >Create</el-button>
        <el-button
            v-show="edit == true"
            type="primary"
            @click="update_mailbox('mailbox_form')"
        >Update</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import EmailSignature from './EmailSignature';
import EmailVacation from '../mailbox/EmailVacation';

export default {
  name: 'MailboxUsers',
  props: ['crm_user_id'],
  components: {
    EmailSignature, EmailVacation
  },
  data() {
    return {
      form: {
        mailbox_email: '',
        mailbox_password: '',
        checked_domains: []
      },
      mailboxes: [],
      domains: [],
      loading: false,
      edit: false,
      dialog_form_visible: false,
      radio: '',
      errors: [],
      current_id: null,
      check_all: false,
      is_indeterminate: true
    };
  },
  watch: {
    crm_user_id(){
      this.mailboxes = [];
      this.fetch_mailboxes();
      this.fetch_domains();
    }},
  mounted() {
    this.fetch_mailboxes();
    this.fetch_domains();
  },
  computed: {
    dialog_title() {
      return this.edit ? 'Update new mailbox' : 'Create new mailbox';
    }
  },
  methods: {
    handle_check_all_domains(val) {
      this.form.checked_domains = val ? this.domains : [];
      this.is_indeterminate = false;
    },
    handle_domain_change(value) {
      let checked_count = value.length;
      this.check_all = checked_count === this.domains.length;
      this.is_indeterminate =
          checked_count > 0 && checked_count < this.domains.length;
    },
    edit_mailbox(id, encrypted_mailpassword, email) {
      this.form.mailbox_email = email;
      this.edit = true;
      this.current_id = id;
      this.dialog_form_visible = true;
    },
    update_mailbox() {
      if (this.check_form()) {
        this.$http
            .post('/update_mailbox', {
              mailbox_id: this.current_id,
              email: this.form.mailbox_email,
              encrypted_mailpassword: window.btoa(this.form.mailbox_password)
            })
            .then(
                response => {
                  if (response.body) {
                    this.dialog_form_visible = false;
                    this.$message({
                      message: 'Mailbox updated',
                      type: 'success'
                    });
                  }
                },
                response => {
                  this.$message({
                    message: 'Mailbox not found',
                    type: 'error'
                  });
                }
            );
      }
    },
    delete_mailbox(id) {
      this.$confirm(
          'This will permanently delete the email account and all emails. Continue?',
          'Warning',
          {
            confirmButtonText: 'OK, delete',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
      )
          .then(() => {
            this.$http.delete('/delete_mailbox/' + id).then(
                response => {
                  if (response.body) {
                    this.mailboxes = this.mailboxes.filter(function (item) {
                      return item.id !== id;
                    });
                    this.$message({
                      message: 'Mailbox deleted',
                      type: 'success'
                    });
                  }
                },
                response => {
                  this.$message({
                    message: 'Mailbox not found',
                    type: 'error'
                  });
                }
            );
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: 'Delete canceled'
            });
          });
    },
    init_radio_group() {
      let r = this.mailboxes.filter(c => c.main_account == 1);

      if (r.length > 0) {
        this.radio = r[0].id;
      }
    },
    create_new_mailbox() {
      this.edit = false;
      this.dialog_form_visible = true;
    },
    fetch_mailboxes() {
      this.$http
          .post('/fetch_mailboxes', {crm_user_id: this.crm_user_id})
          .then(response => {
            if (response.body) {

              this.mailboxes = response.data.mailboxes;
              this.mailboxes.sort((a,b) => b.shared - a.shared)
              this.init_radio_group();
            }
          });
    },
    fetch_domains() {
      this.$http.get('/fetch_domains').then(response => {
        if (response.body) {
          this.domains = response.data.domains;
        }
      });
    },
    save_shared_mailbox(id) {
      this.$http
          .post('/save_shared_mailbox', {
            mailbox_account_id: id,
            crm_user_id: this.crm_user_id,
            checked: event.target.checked
          })
          .then(
              response => {
                this.$message({
                  message: 'shared mailbox account saved',
                  type: 'success'
                });
              },
              response => {
                this.$message({
                  message: 'Mailbox not found',
                  type: 'error'
                });
              }
          );
    },
    set_main_mailbox(id) {
      this.$http
          .post('/set_main_mailbox', {
            mailbox_account_id: id,
            crm_user_id: this.crm_user_id
          })
          .then(
              response => {
                this.$message({
                  message: 'New mailbox main email saved',
                  type: 'success'
                });
              },
              response => {
                this.$message({
                  message: 'couldn\'t set main email',
                  type: 'error'
                });
              }
          );
    },
    save_new_mailbox() {
      if (this.check_form()) {
        // window.btoa = encode string Base64
        this.$http
            .post('/create_mailbox_email', {
              email: this.form.mailbox_email,
              encrypted_mailpassword: window.btoa(this.form.mailbox_password),
              domains: this.form.checked_domains
            })
            .then(
                response => {
                  this.form.mailbox_email = '';
                  this.form.mailbox_password = '';
                  this.dialog_form_visible = false;
                  this.fetch_mailboxes();
                  this.$message({
                    message: 'New mailbox account created',
                    type: 'success'
                  });
                },
                response => {
                  this.$message({
                    message: 'Mailbox email already exists',
                    type: 'error'
                  });
                }
            );
      }
    },
    check_form() {
      this.errors = [];

      if (!this.form.mailbox_password) {
        this.errors.push('Mailbox password required.');
      }
      if (!this.form.mailbox_email) {
        this.errors.push('Mailbox email required.');
      }

      if (!this.errors.length) {
        return true;
      }
    }
  }
};
</script>

<style scoped>
@media only screen and (max-width: 993px) {
  #mailbox-users /deep/ .el-dialog { width: 100% !important;}
}
</style>