<template>
  <div style="padding:5%;">
    <Delivery
      :suggested_from_address="proposal.branch_address"
      :suggested_to_address="proposal.account_address"
    />
    <!-- <i class="el-icon-download"> Packing Slip </i> -->
  </div>
</template>
<script>
import TaskTimes from '../sections/taskcomponents/TaskTimes.vue';
import Delivery from '../../proposal/Delivery.vue';
import { editor_store } from 'store/modules/editor';
import { ProposalMixin } from 'mixins/ProposalMixin.js';
import { JobProposalMixin } from 'mixins/JobProposalMixin.js';
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: 'JobLocation.vue',
  store: editor_store,
  components: {
    Delivery
  },
  mixins: [ProposalMixin, JobProposalMixin],
  data() {
    return {};
  },
  mounted() {},
  computed: {
    ...mapGetters([
      'job_proposals_list',
      'locations_list',
      'proposal',
      'proposal_id'
    ])
  },
  methods: {},
  watch: {}
};
</script>
<style>
</style>
