<template>
  <div>
    <el-tooltip
      content="user details"
      :open-delay="2000"
      placement="right"
      effect="dark"
    >
      <router-link :to="`/details/${crm_user_id}`">
        Details
      </router-link>
    </el-tooltip>

    <el-tooltip
      content="logout"
      :open-delay="2000"
      placement="right"
      effect="dark"
    >
      <a :href="logout">
        Log out
      </a>
    </el-tooltip>
  </div>
</template>

<script>

export default {
  name: 'Navigation',
  props: {
    crm_user_id: {
      type: String,
      required: true
    }
  },
  computed: {
    logout() {
      return window.location.origin + '/prospect/logout';
    }
  }
};
</script>
<style scoped>

a {
  padding: 5px 20px;
  display: block;
  color: #000;
}
</style>   