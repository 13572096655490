<template>
  <div
    v-loading.fullscreen.lock="loading"
    class="row"
  >
    <div class="col m12">
      <h3>
        <strong>LEAVE TYPES</strong>
        <el-row />
        <hr>
      </h3>
      <table class="responsive">
        <thead>
          <tr>
            <th>Leave type</th>
            <th>Recurred Balance</th>
            <th>Initial Recurring</th>
            <th>Recurred every</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="record in data"
            :key="record.id"
          >
            <td>{{ record.name }}</td>
            <td>{{ record.accrued_value }} <small>hour(s)</small></td>
            <td>{{ record.initial_accrued_after }} <small>month(s)</small></td>
            <td>{{ record.accrued_after }} <small>month(s)</small></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

export default {
  name: 'LeaveTypes',
  data() {
    return {
      data: []
    };
  },
  mounted(){
    this.fill_data();
  },
  methods: {
    fill_data() {
      let _this = this;
      this.$http
        .get('/leave_types')
        .then(response => {
          _this.data = response.data;
        });
    }
  }
};
</script>

<style scoped>

</style>