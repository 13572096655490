<template>
  <div class="row">
    <el-row :gutter="0">
      <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20">
        <h3>
          <strong>
            USER PROFILE
          </strong>
        </h3>
      </el-col>
      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
        <div v-show="is_admin == 1">
          <el-select
            v-model="selected_user_id"
            placeholder="Select User"
            filterable
            :width="100"
            :disabled="is_admin == 0"
            v-on:change="update_selected_user"
          >
            <el-option
              v-for="item in users_list"
              :key="item.id"
              :label="item.user_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="0">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <el-tabs v-model="activeName" type="border-card">
          <el-tab-pane label="Time Sheet" name="time_sheet">
            <TimeSheet />
          </el-tab-pane>
          <el-tab-pane label="Leave" name="leave">
            <Leave />
          </el-tab-pane>
          <!-- <el-tab-pane label="Tax details" name="tax_details">Role</el-tab-pane>
                        <el-tab-pane label="Salaries" name="salaries">Task</el-tab-pane>
                        <el-tab-pane label="Payslips" name="payslips">Task</el-tab-pane> -->
        </el-tabs>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import TimeSheet from "./TimeSheet";
import Leave from "./Leave";
import { mapGetters, mapActions} from "vuex";

export default {
  name: "MyProfile",
  data() {
    return {
      activeName: "time_sheet",
      users_list: [],
      selected_user_id: "",
    };
  },
  components: {
    TimeSheet,
    Leave,
  },
  mounted() {
    this.fill_users_list();
  },
  computed: {
    ...mapGetters(["selected_user_info", "is_admin"]),
  },
  methods: {
    ...mapActions([
      "set_selected_user_info",
      "set_is_admin",
    ]),
    update_selected_user(selected_id) {
      this.grouped_timesheet = [];
      let current_user = this.users_list.filter(
        (item) => item.id == selected_id
      )[0];
      if (current_user == undefined)
        current_user = {
          id: selected_id,
          default_check_in: "",
          default_check_out: "",
          default_check_in_24_format: "",
          default_check_out_24_format: "",
          break_duration: 0,
        };
      this.set_selected_user_info(current_user);
    },
    fill_users_list() {
      let _this = this;
      this.$http.get("/users_list").then((response) => {
        _this.users_list = response.data.list;
        _this.set_is_admin(response.data.is_admin);
        _this.selected_user_id = parseInt(response.data.current_user_id);
        _this.update_selected_user(response.data.current_user_id);
      });
    },
  },
};
</script>

<style scoped>
/deep/ .el-tabs--border-card > .el-tabs__content {
  padding: 40px;
}
</style>
