<template>
  <div>
    <el-row
      v-if="stock_item_auto_po.created_at != undefined"
      id="created_at"
      :gutter="20"
    >
      <el-col :span="8">
        created at:
        <el-date-picker
          v-model="stock_item_auto_po.created_at"
          type="datetime"
          format="dd.MM.yyyy HH:mm:ss"
          :readonly="true"
          size="mini"
        />
      </el-col>
      <el-col :span="8">
        updated at:
        <el-date-picker
          v-model="stock_item_auto_po.updated_at"
          type="datetime"
          format="dd.MM.yyyy HH:mm:ss"
          :readonly="true"
          size="mini"
        />
      </el-col>
    </el-row>
    <el-divider />
    <el-row :gutter="20">
      <el-col :span="8">
        <el-row>Options</el-row>
      </el-col>
      <el-col :span="8">
        <el-row>Condition</el-row>
      </el-col>
      <el-col :span="8">
        <el-row>Purchase Order Quantity</el-row>
      </el-col>
    </el-row>
    <el-divider />

    <el-row :gutter="20">
      <el-col :span="8">
        <el-row>
          <el-checkbox v-model="stock_item_auto_po.is_recurring">
            is recurring
          </el-checkbox>
          <el-checkbox v-model="stock_item_auto_po.weekly">
            weekly
          </el-checkbox>
        </el-row>
      </el-col>
      <el-col :span="8">
        <el-row>
          <el-select
            v-model="stock_item_auto_po.recurring_day"
            placeholder="Select Day"
          >
            <el-option
              v-for="day in days"
              :key="day.value"
              :label="day.label"
              :value="day.value"
            />
          </el-select>
          <br>
          <p>Every day of the month or week <br>(if weekly checked)</p>
        </el-row>
      </el-col>
      <el-col :span="8">
        <el-row>
          <el-input-number
            v-model="stock_item_auto_po.recurring_qty"
            :min="1"
            :max="10000"
          />
        </el-row>
      </el-col>
    </el-row>

    <el-divider />
    <el-row :gutter="20">
      <el-col :span="8">
        <el-checkbox v-model="stock_item_auto_po.is_auto">
          is auto
        </el-checkbox>
      </el-col>
      <el-col :span="8">
        <el-row>
          <el-input-number
            v-model="stock_item_auto_po.min_qty"
            :min="1"
            :max="10000"
          />
          <br>
          <p>Less than.</p>
        </el-row>
      </el-col>
      <el-col :span="8">
        <el-row>
          <el-input-number
            v-model="stock_item_auto_po.auto_po_qty"
            :min="1"
            :max="10000"
          />
        </el-row>
      </el-col>
    </el-row>
    <el-divider />
    <el-row>
      <el-col :span="22">
        <el-button
          type="danger"
          @click="delete_record()"
        >
          Delete
        </el-button>
      </el-col>
      <el-col :span="2">
        <el-button
          type="primary"
          style="text-align:right"
          @click="create_or_update"
        >
          Save
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>
<script>

export default {
  name: 'StockItemRecurring',
  props: {
    branch_id: {
      type: Number,
      required: true
    },
    stock_item_id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      days: [],
      stock_item_auto_po: {
        id: null,
        is_recurring: false,
        weekly: false,
        is_auto: false,
        recurring_day: 1,
        recurring_qty: 0,
        auto_po_qty: 0,
        min_qty: 0
      }
    };
  },
  watch: {
    branch_id() {
      this.show();
    },
    stock_item_id() {
      this.show();
    }
  },
  mounted() {
    this.set_default_input();
  },
  created() {
    this.create_days();
    this.show();
  },
  methods: {
    show() {
      this.$http.get(`/recurring/${this.branch_id}/${this.stock_item_id}`).then(
        (response) => {
          this.stock_item_auto_po = response.data;
        },
        () => {
        }
      );
    },
    add_ids() {
      this.stock_item_auto_po.branch_id = this.branch_id;
      this.stock_item_auto_po.stock_item_id = this.stock_item_id;
    },
    create_or_update() {
      if (this.stock_item_auto_po.id == null)
        this.create();
      else
        this.update();
    },
    create() {
      this.add_ids();
      this.$http.post('/stock_item_auto_pos', {stock_item_auto_po: this.stock_item_auto_po}).then(
        (response) => {
          this.stock_item_auto_po = response.data;
          this.$message({
            message: 'Success, record created.',
            type: 'success',
          });

        },
        () => {
          this.$message({
            type: 'error',
            message: 'Error, couldn\'t create record'
          });
        }
      );

    },
    update() {
      this.add_ids();
      this.$http.patch(`/stock_item_auto_pos/${this.stock_item_auto_po.id}`, {stock_item_auto_po: this.stock_item_auto_po}).then(
        () => {
          this.$message({
            message: 'Success, record updated.',
            type: 'success',
          });

        },
        () => {
          this.$message({
            type: 'error',
            message: 'Error, couldn\'t update record'
          });
        }
      );
    },
    delete_record() {
      this.$http.delete(`/stock_item_auto_pos/${this.stock_item_auto_po.id}`).then(
        () => {
          this.$message({
            message: 'Record deleted',
            type: 'success'
          });
          this.$emit('close-recurring-dialog');
        },
        () => {
          this.$message({
            message: 'Record not deleted',
            type: 'error'
          });
        },
      );
    },
    create_days() {
      for (let i = 1; i <= 28; i++) {
        this.days.push({value: i, label: i});
      }
    },
    set_default_input() {
      Array.from(document.querySelectorAll('.el-input__inner')).forEach(
        function (e) {
          e.classList.add('browser-default');
          if (e.querySelector('input') != null)
            e.querySelector('input').className += ' browser-default';
        }
      );
    },
  }
};
</script>

<style>
#created_at i.el-input__icon {
  display: none;
}
</style>