<template>
  <div>
    <el-row :gutter="10">
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <div
          class="status-lines"
          style="background:#666;color:#fff;text-align:center;text-transform:uppercase;font-size:13px;"
        >
          {{ proposal.category }}
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <div class="status-lines">
          <span
            v-show="!proposal.completed && proposal.is_urgent"
            class="sales-badge"
            style="background:#cc0000; width:auto; float:left"
            >URGENT</span
          >
          <span
            v-show="!proposal.requested_deadline && proposal.deadline_approved"
            class="sales-badge"
            style="background:#ff9966; width:auto; float:left"
            >DEADLINE FIXED</span
          >
          <span
            class="sales-badge"
            style="background:orange; width:auto; float:left"
            >{{ proposal.quote_type_name }}</span
          >
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="lines">
          <span class="spanlabel">Account:</span>
          {{ proposal.account_name }}
        </div>
        <div class="lines">
          <span class="spanlabel">Branch:</span>
          {{ proposal.branch_name }}
        </div>
        <div class="lines">
          <span class="spanlabel">Proposal:</span>
          <a :href="'/edit_proposal?id=' + proposal.id">{{ proposal.id }}</a>
        </div>

        <div class="lines">
          <span class="spanlabel">Job:</span>
          <el-input v-model="proposal.job_name" />
          <el-button
            type="info"
            plain
            size="small"
            @click="onUpdateJobName"
          >
            Update
          </el-button>
        </div>

        <div class="lines">
          <span class="spanlabel">Related Jobs:</span>
          <span
            v-for="parent in proposal.related_jobs"
            :key="parent"
            style="padding-right:10px;"
          >
            <a :href="'/job_view?id=' + parent">{{ parent }}</a>
          </span>
        </div>

        <div class="lines2">
          <span class="spanlabel">Deadline:</span>
          <span
            v-if="
              proposal.requested_deadline != undefined &&
                proposal.deadline_approved == 1
            "
            >{{ proposal.requested_deadline }}</span
          >
          <span v-else>{{ proposal.dated }}</span>
        </div>
      </el-col>

      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="lines" v-show="proposal.proposal_number">
          <span class="spanlabel">Quote No:</span>
          {{ proposal.proposal_number }}
        </div>

        <div class="lines">
          <el-form ref="form" v-model="proposal" label-width="80px">
            <div style="width::30%;float:left;">
              <el-popover
                placement="bottom"
                title
                width="200"
                trigger="hover"
                content="Order Number"
              >
                <el-button slot="reference" class="job-icon-btton">
                  <i class="el-icon-s-order"></i>
                </el-button>
              </el-popover>
            </div>
            <div
              class="inputButton"
              style="width:94%;float:left;line-height:14px;"
            >
              <el-form-item label=" Order No.">
                <el-input
                  class="inputButton"
                  @change="update_CO_number"
                  v-model="proposal.client_order_number"
                ></el-input>
                <span class="mynotes">
                  #
                  <i class="el-icon-position"></i>
                </span>
              </el-form-item>
            </div>
          </el-form>
        </div>

        <div class="lines">
          <span class="spanlabel">Job creator:</span>
          {{ proposal.created_from }}
        </div>
        <div class="lines" v-show="proposal.notify_on_completion">
          <el-checkbox disabled v-model="proposal.notify_on_completion">
            <span>Notification will send to client on job completion</span>
          </el-checkbox>
        </div>
        <!-- divider right-->

        <div class="lines" v-show="proposal.proposal_notes">
          <el-popover
            placement="bottom"
            title
            width="200"
            trigger="hover"
            content="Client Notes"
          >
            <el-button slot="reference" class="job-icon-btton">
              <i class="el-icon-tickets"></i>
            </el-button>
          </el-popover>
          <span class="spanlabel">Client Notes:</span>
          {{ proposal.proposal_notes }}
        </div>

        <div class="lines" v-show="proposal.pre_description">
          <el-popover
            placement="bottom"
            title
            width="200"
            trigger="hover"
            content="Job description from quote"
          >
            <el-button slot="reference" class="job-icon-btton">
              <i class="el-icon-info"></i>
            </el-button>
          </el-popover>
          <span class="spanlabel">Quote notes:</span>
          <br />
          <br />
          <span class="mynotes">
            #
            <i class="el-icon-position"></i>
          </span>

          <div v-if="!description_edit">
            <span v-html="proposal.pre_description"></span>
          </div>

          <div v-else>
            <jodit-editor
              :id="detailsrandom_id"
              v-model="proposal.pre_description"
              @afterCopy="update_description"
              :buttons="buttons"
              :config="jodit_config"
            />
            <el-button
              type="info"
              plain
              id="btn_save"
              class="right"
              v-show="!proposal.completed"
              @click="update_description"
              >Update</el-button
            >
          </div>
          <div class="right">
            <i class="el-icon-edit"></i>
            <el-switch
              active-color="#333"
              inactive-color="#f3f3f3"
              v-model="description_edit"
            >
            </el-switch>
          </div>
        </div>
        <div>
          <i class="el-icon-info"></i>
          <span class="spanlabel">Working File Notes:</span><br />
          <div
            v-for="workingfile in working_files_list"
            :key="workingfile.id"
            style="padding-bottom:20px;width:100%; text-align: left"
          >
            <div v-show="!workingfile_description_edit">
              {{workingfile.qty == undefined || workingfile.qty == 0 ? '' : `${workingfile.qty} x `}}
              <b> {{ workingfile.name }}: </b><br />
              <span v-html="workingfile.note"></span>
            </div>
            <div v-show="workingfile_description_edit">
              <b> {{ workingfile.name }}: </b><br />
              <jodit-editor
                :id="detailsrandom_id2 + workingfile.id"
                :config="jodit_config"
                v-model="workingfile.note"
                @change="update_workingfile_note(workingfile)"
                :buttons="buttons"
              />

              <el-button
                type="info"
                plain
                id="btn_save"
                class="right"
                v-show="!proposal.completed"
                @click="update_workingfile_note(workingfile)"
                >Update</el-button
              >
            </div>
          </div>
          <div class="right">
            <i class="el-icon-edit"></i>
            <el-switch
              active-color="#333"
              inactive-color="#f3f3f3"
              v-model="workingfile_description_edit"
            >
            </el-switch>
          </div>
        </div>
        <i class="el-icon-info"></i> <span class="spanlabel">Task Notes:</span>

        <div v-for="task in job_proposals_list" :key="task.id">
          <span v-show="task.alternative_description && !task_description_edit">
            <b>{{ task.job_name }}:</b> ({{ task.list_name }})
            <span v-show="!task_description_edit">{{
              task.alternative_description
            }}</span>
          </span>

          <span v-show="task_description_edit">
            <b>{{ task.job_name }}:</b> ({{ task.list_name }})
          </span>
          <el-input
            class="inputButtonEdit"
            v-show="task_description_edit"
            @change="update_task_description(task)"
            v-model="task.alternative_description"
          ></el-input>
        </div>

        <div class="right">
          <i class="el-icon-edit"></i>
          <el-switch
            active-color="#333"
            inactive-color="#f3f3f3"
            v-model="task_description_edit"
          >
          </el-switch>
        </div>

        <!-- <div class="lines">
          <el-popover placement="bottom" title width="200" trigger="hover" content="Design files">
            <el-button slot="reference" class="job-icon-btton">
              <i class="el-icon-upload"></i>
            </el-button>
          </el-popover>
          <span class="spanlabel">Lists:</span>
        </div>
    
        <div
          :body-style="{ padding: '0px' }"
          v-bind:class="{ active_page: file.id }"
          v-for="(file, index) in working_files_list"
          v-bind:key="file.id"
        >
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">


      
            <div style="padding:10px;">
              <b>List: {{index + 1}}</b>
              {{file.name}}
              <el-button @click="open_file_link(file.id)" style="padding: 4px;">
                <i class="el-icon-download"></i>
              </el-button>

              <el-button
                type
                size="mini"
                icon="el-icon-zoom-in"
                id="thumbnaillarge-button"
                data-position="top"
                data-tooltip="View Thumbnail"
                style="padding: 4px;"
                @click="open_modal"
              ></el-button>
              <modal
                name="thumbnaillarge"
                width="90%"
                height="90%"
                :v-model="file.id"
                style="padding: 0px 20px; "
              >
                <div style="padding: 0px 20px;">
                  <div class="left" style="width:90%; max-width:800px;">
                    <img width="100%" :src="file.thumb" style="text-align:center; padding:10px;" />
                  </div>
                  <div class="right" style="padding-top: 20px;">
                    <el-button type="danger" size="mini" v-on:click="hide_modal">X</el-button>
                  </div>
                  <el-form></el-form>
                </div>
              </modal>
            </div>
          </el-col>
        </div>-->
      </el-col>
    </el-row>
  </div>
</template>
<script>
 
import { setTimeout } from "timers";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { editor_store } from "store/modules/editor";
import { JobProposalMixin } from "mixins/JobProposalMixin.js";
import { ProposalMixin } from "mixins/ProposalMixin.js";

export default {
  name: "JobDetails.vue",
  store: editor_store,
  mixins: [JobProposalMixin, ProposalMixin],
  components: {
  
  },
  data() {
    return {
      link: [],
      jodit_config: { enter: "br" },
      detailsrandom_id: `jodit_comm${parseInt(Math.random() * 100, 10)}`,
      detailsrandom_id2: `jodit_comm${parseInt(Math.random() * 100, 10)}`,
      additionalData: "",
      visible: false,
      description_edit: false,
      task_description_edit: false,
      workingfile_description_edit: false,
      activeName: "first",
      dated: true,
      working_files_list: [],
      buttons: [
        "fullsize",
        "source",
        "|",
        "table",
        "image",
        "link",
        "|",
        "bold",
        "underline",
        "strikethrough",
        "italic",
        "|",
        "paragraph",
        "font",
        "fontsize",
        "|",
        "ul",
        "ol",
        "|",
        "undo",
        "redo",
        "|",
        "selectall",
        "cut",
        "copy",
        "paste",
        "copyformat",
        "|",
        "align",
        "outdent",
        "indent",
        "|",
        "hr",
        "brush",
        "eraser",
        "symbol",
        "|",
        "superscript",
        "subscript",
        "|",
        "print"
      ],
      customButtons: [
        {
          name: "insertDate",
          iconURL: "http://xdsoft.net/jodit/logo.png",
          exec: function(editor) {
            document
              .getElementById("btn_save")
              .dispatchEvent(new Event("click"));
          }
        }
      ]
    };
  },
  mounted() {},
  computed: {
    ...mapGetters(["proposal", "proposal_id", "job_proposals_list"]),
    job_proposals_list_grouped() {
      return this.groupBy(this.job_proposals_list, "list_name");
    }
  },
  methods: {
    onUpdateJobName() {
      this.$http
          .post("/update_job_name/" + this.proposal.id, {
            job_name: this.proposal.job_name
          })
          .then(
              ({data}) => {
                this.$message({
                  message: data.message,
                  type: "success"
                });
              },
              ({data}) => {
                this.$message({
                  message: data.message,
                  type: "error"
                });
              }
          );
    },
    update_description() {
      this.$http
        .post("/update_job_description/" + this.proposal.id, {
          pre_description: this.proposal.pre_description
        })

        .then(
          response => {
            this.$message({
              message: "Updated successfully.",
              type: "success"
            });
          },
          function(response) {
            this.$message({
              message: "Error happened while retrieving the file.",
              type: "error"
            });
          }
        );
    },
    update_CO_number() {
      this.$http
        .post("/update_order_number/" + this.proposal.id, {
          client_order_number: this.proposal.client_order_number
        })

        .then(
          response => {
            this.$message({
              message: "Updated successfully.",
              type: "success"
            });
          },
          function(response) {
            this.$message({
              message: "Error happened while retrieving the file.",
              type: "error"
            });
          }
        );
    },
    update_workingfile_note(workingfile) {
      this.$http
        .patch(`/working_file_note/${workingfile.id}`, {
          note: workingfile.note
        })

        .then(
          response => {
            this.$message({
              message: "Updated successfully.",
              type: "success"
            });
          },
          function(response) {
            this.$message({
              message: "Error happened while retrieving the file.",
              type: "error"
            });
          }
        );
    },
    update_task_description(task) {
      let _this = this;
      let params = {
        id: task.id,
        alternative_description: task.alternative_description
      };
      this.update_alternative_description(params).then(
        response => {
          this.$message({
            message: "Task Description Updated",
            type: "success"
          });
        },
        response => {
          this.$message({
            message: "Unable to update",
            type: "error"
          });
        }
      );
    },

    load_working_files() {
      this.$http
        .post("/proposal_working_files", {
          proposal_id: this.proposal.id
        })
        .then(response => {
          this.working_files_list = response.body.files;
        });
    },
    open_file_link() {
      document
        .getElementById("filemanager-button")
        .setAttribute("data-search", "/job assets/working");
      document.getElementById("filemanager-button").click();
    },
    open_modal() {
      this.$modal.show("thumbnaillarge");
    },
    hide_modal() {
      this.$modal.hide("thumbnaillarge");
    }
  },
  watch: {
    "proposal.id": function(newVal) {
      this.load_working_files();
    }
  }
};
</script>
<style>
.inputButton input {
  line-height: 14px !important;
  margin: 0px !important;
  height: 14px !important;
}
.inputButton .el-form-item__content {
  line-height: 14px !important;
}
.inputButtonEdit input {
  background: lightgoldenrodyellow !important;
  border-bottom: 0px solid #9e9e9e !important;
}
</style>
