import {Request} from '../utilities/Request';

export function leave_requests(this_obj, start, end, crm_user_id) {
  return Request.post(
    this_obj,
    '/api/leave_requests/leave_requests_period',
    null,
    {
      crm_user_id: crm_user_id,
      start: start,
      end: end
    }
  );
}

export function update_record(this_obj, id, leave_requests) {
  return Request.patch(
    this_obj,
    `/update_request_status/${id}`,
    null,
    { request_details: {value: leave_requests.value}}
  );
}

export function delete_record(this_obj, id) {
  return Request.post(
    this_obj,
    '/delete_request',
    null,
    { id: id}
  );
}
