<template>
  <div>
    <div class="card  ">
      <div class="card-content ">
        <span class="card-title">
          Hold Proposals
        </span>
        <br>
        <br>
        <br>
        <pie-chart
          :chart-data="datacollection"
          :options="chartOptions"
        />
        <span>
          <el-button
            v-for="hold_status in hold_statuses"
            :key="hold_status.id"
            size="small"
            @click="show_proposals_by_status(hold_status)"
          >
            {{ hold_status.name }}
          </el-button>
        </span>
      </div>
      <div class="card-action">
        <!-- <button v-on:click="show_sales(true)" class="btn btn-small">
          Completed Jobs
        </button>
        <button v-on:click="show_sales(false)" class="btn btn-small right">
          Pending Jobs
        </button> -->
      </div>
    </div>
    <el-dialog
      title="Hold proposals"
      :visible.sync="hold_proposals_dialog"
      width="60%"
    >
      <el-table :data="hold_proposals">
        <el-table-column
          label="id"
          prop="id"
        />
        <el-table-column
          label="Job"
          prop="job_name"
        />
        <el-table-column
          label="Account"
          prop="account_name"
        />
        <el-table-column
          label=""
          prop=""
        />
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import PieChart from './PieChart';
export default {
  name: 'HoldProposals',
  components: { PieChart },
  data() {
    return {
      datacollection: '',
      hold_statuses: [],
      hold_proposals: [],
      hold_proposals_dialog: false,
    };
  },
  computed: {
    chartOptions: function() {
      return { responsive: true, maintainAspectRatio: false };
    },
  },
  mounted() {
    this.fill_data();
  },
  methods: {
    getRandomColor() {
      var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    fill_data() {
      this.$http.get('/hold_proposals_statistics').then((response) => {
        this.hold_statuses = response.data;
        let bg_colors = [];
        for (let i = 0; i <= response.data.length; i++) {
          bg_colors.push(this.getRandomColor());
        }
        this.datacollection = {
          labels: response.data.map((c) => c.name),
          datasets: [
            {
              label: 'Sales ',
              backgroundColor: bg_colors,
              data: response.data.map((c) => c.status_count),
            },
          ],
        };
      });
    },
    show_proposals_by_status(hold_status) {
      this.$http
        .post('/hold_proposals_by_status', { hold_status_id: hold_status.id })
        .then((response) => {
          this.hold_proposals = response.data;
          this.hold_proposals_dialog = true;
        });
    },
  },
};
</script>
