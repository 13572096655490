<template>
  <el-form label-position="top" :model="purchase_order" ref="purchase_order">
    <h1>
      Purchase order
      <el-tag
          effect="dark"
          type="danger"
          v-show="purchase_order.mark_as_deleted == 1"
      >
        DELETED
      </el-tag>
      <hr/>
    </h1>
    <error-explanation :errors="errors"></error-explanation>
    <input type="text" v-model="purchase_order.id" v-show="false"/>
    <input type="text" v-model="purchase_order.is_manual" v-show="false"/>
    <el-tabs type="border-card" v-model="selected_tab">
      <el-tab-pane label="Details" name="details">
        <div class="row">
          <div class="col s12 m4">
            <div class="field">
              <el-checkbox
                  label="Charge PO"
                  v-model="purchase_order.is_open_quote"
                  v-on:change="clear_product_inputs"
                  :disabled="
                  purchase_order.po_status_id > 1 || is_charge_po == true
                "
              ></el-checkbox>
            </div>
            <div class="field">
              <el-form-item
                  label="Branch"
                  class="selectMob"
                  required
                  prop="branch_id"
              >
                <el-select
                    style="width:100%"
                    class="selectMob"
                    v-model="purchase_order.branch_id"
                    filterable
                    :disabled="po_primary_disabled"
                    v-on:change="purchase_order.stock_item_branch_id = null"
                    placeholder="Select a branch"
                    size="small"
                >
                  <el-option
                      v-for="branch in branches_list"
                      :key="branch.id"
                      :label="branch.name"
                      :value="branch.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="field">
              <el-form-item
                  label="Category"
                  class="selectMob"
                  prop="category_task_id"
              >
                <el-select
                    style="width:100%"
                    class="selectMob"
                    v-model="purchase_order.category_task_id"
                    filterable
                    :disabled="po_primary_disabled"
                    placeholder="Select a Category"
                    size="small"
                >
                  <el-option
                      v-for="category in category_tasks_list"
                      :key="category.id"
                      :label="category.name"
                      :value="category.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="field">
              <el-form-item
                  label="Supplier"
                  class="selectMob"
                  required
                  prop="supplier_id"
              >
                <el-select
                    style="width:100%;left:0!important"
                    class="selectMob"
                    v-model="purchase_order.supplier_id"
                    filterable
                    :disabled="po_primary_disabled"
                    placeholder="Select a supplier"
                    v-on:change="
                    fill_email();
                    fill_stocks();
                    purchase_order.stock_item_branch_id = null;
                  "
                    size="small"
                >
                  <el-option
                      class="selectMob"
                      style="left:0!important"
                      v-for="supplier in suppliers_list"
                      :key="supplier.id"
                      :label="supplier.name"
                      :value="supplier.id"
                  >
                    <span class="mob-view" style="float: left">{{
                        supplier.name
                      }}</span>
                    <span
                        class="mob-view"
                        style="float: right; color: #8492a6; font-size: 13px"
                    >{{ supplier.email }}</span
                    >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <el-form-item label="Send to">
              <el-input
                  type="text"
                  v-model="purchase_order.send_to"
                  :disabled="po_primary_disabled"
                  placeholder="Enter email"
                  size="small"
              ></el-input>
            </el-form-item>
            <div class="field">
              <el-form-item
                  label="Stock Category"
                  required
                  prop="category_supplier_id"
              >
                <el-select
                    style="width:100%"
                    class="selectMob"
                    v-model="purchase_order.category_supplier_id"
                    filterable
                    v-on:change="
                    fill_stocks();
                    purchase_order.stock_item_branch_id = null;
                  "
                    :disabled="po_primary_disabled"
                    placeholder="Select a category"
                    size="small"
                >
                  <el-option
                      v-for="category in categories_list"
                      :key="category.id"
                      :label="category.name"
                      :value="category.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div>
              <div class="field product">
                <el-form-item label="Account" class="selectMob">
                  <el-select
                      style="width: 100%"
                      class="selectMob"
                      v-model="selected_accounts_id"
                      filterable
                      remote
                      reserve-keyword
                      placeholder="Please enter a keyword"
                      v-on:change="
                      load_quotes_by_account_id(selected_accounts_id)
                    "
                      :remote-method="accounts_list_query"
                      size="small"
                      :disabled="
                      proposal != undefined && proposal.id != undefined
                    "
                  >
                    <!-- :loading="loading" -->
                    <el-option
                        v-for="item in accounts_list"
                        :key="item.id"
                        :label="item.account_name"
                        :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>

              <div class="field product">
                <el-form-item label="Quote" class="selectMob">
                  <el-select
                      :disabled="
                      proposal != undefined && proposal.id != undefined
                    "
                      style="width:100%"
                      class="selectMob"
                      v-model="purchase_order.proposal_id"
                      filterable
                      placeholder=" - Select quote - "
                      v-on:change="
                      load_signs_by_proposal_id(purchase_order.proposal_id)
                    "
                      size="small"
                  >
                    <el-option
                        v-for="quote in quotes_list"
                        :key="quote.id"
                        :label="quote.value"
                        :value="quote.id"
                    >
                      <span>{{ quote.value }}</span>
                      <span style="color: #8492a6;">
                        <small>(#{{ quote.id }})</small>
                      </span>
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>

              <div class="field product sign">
                <el-form-item label="Sign" class="selectMob">
                  <el-select
                      style="width:100%"
                      class="selectMob"
                      v-model="purchase_order.sign_id"
                      filterable
                      :disabled="po_primary_disabled"
                      placeholder="Select"
                      size="small"
                  >
                    <el-option
                        v-for="sign in signs_list"
                        :key="sign.id"
                        :label="sign.name"
                        :value="sign.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <reimbursement-crm-user :purchase_order_id="purchase_order.id"
                                    v-if="purchase_order.id > 0"
                                    @hook:mounted="set_default_input" />
            <div class="field">
              <el-form-item label="Description [displayed in PO PDF]">
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 7, maxRows: 7 }"
                    :disabled="po_primary_disabled"
                    placeholder="Enter description here ... "
                    v-model="purchase_order.description"
                    size="small"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="col s1">
            <label></label>
          </div>
          <div
              class="product col m7 s12"
              v-show="purchase_order.is_open_quote == false"
          >
            <b>Product description</b>
            <hr/>
            <div>
              <table class="responsive-table">
                <thead>
                <tr style="color: red;">
                  <td>Product</td>
                  <td style="text-align: center;width: 70px;">Qty</td>
                  <td style="text-align: center;width: 70px">Purchase Qty</td>
                  <td style="text-align: center;width: 70px">
                    Purchase Price
                  </td>
                </tr>
                </thead>
                <tr>
                  <td>
                    <div class="field">
                      <el-select
                          style="width:100%"
                          class="selectMob"
                          v-model="purchase_order.stock_item_branch_id"
                          filterable
                          :disabled="po_primary_disabled"
                          placeholder=" - Select product - "
                          v-on:change="update_stock_info(true, true)"
                          size="small"
                      >
                        <el-option
                            style="min-width: 300px"
                            v-for="stock in stock_items_list"
                            :key="stock.stock_item_branch_id"
                            :label="stock.name_with_specs"
                            :value="stock.stock_item_branch_id"
                        >
                          <span>{{ stock.name }}</span>
                          <span style="color: #8492a6;">
                            <small>{{ stock.specs }}</small>
                          </span>
                        </el-option>
                      </el-select>
                    </div>
                  </td>
                  <td>
                    <el-input-number
                        controls-position="right"
                        v-model="purchase_order.quantity"
                        :disabled="po_primary_disabled"
                        class="field_style"
                        v-on:change="update_stock_info(false, true)"
                        size="small"
                        style="width: 110px;"
                    ></el-input-number>
                  </td>
                  <td>
                    <el-input-number
                        controls-position="right"
                        v-model="purchase_order.purchased_quantity"
                        :disabled="po_primary_disabled"
                        v-on:change="update_stock_info(false, false)"
                        class="field_style purchased_qty calculation"
                        size="small"
                        style="width: 110px;"
                    ></el-input-number>
                  </td>
                  <td>
                    <el-input-number
                        v-if="
                        purchase_order.is_open_quote &&
                          purchase_order.unit_price
                      "
                        controls-position="right"
                        :disabled="po_primary_disabled"
                        v-on:change="update_stock_info(false, false)"
                        v-model="purchase_order.unit_price"
                        size="small"
                        style="width: 110px;"
                    ></el-input-number>
                    <el-input-number
                        v-else
                        :disabled="po_primary_disabled"
                        controls-position="right"
                        v-model="purchase_order.unit_price"
                        size="small"
                        style="width: 110px;"
                    ></el-input-number>
                  </td>
                </tr>
                <tfoot>
                <tr>
                  <td colspan="4">
                    <div>
                      Link:
                      <el-button type="text" @click="stock_item_link" v-if="stock_item_id">Stock Item</el-button>
                      <p>
                        <b>Min Qty:</b>
                        <label id="lblMinQty" class="calculation">{{
                            selected_stock_item.minimum_packet_quantity
                          }}</label>
                      </p>
                      <p>
                        <b>Unit Price Calculation:</b>
                        <label id="lblStockPrice" class="calculation">{{
                            selected_stock_item.stock_price
                          }}</label>
                        <label class="calculation">{{
                            stock_price_desc
                          }}</label>
                      </p>
                      <p>
                        <b>Stock on hand:</b>

                        <label class="calculation">{{
                            stock_on_hand_desc
                          }}</label>
                      </p>
                      <p v-show="Boolean(purchase_order.proposal_id > 0)">
                        <b>Allocated:</b>
                        <label class="calculation">{{
                            allocated_price_desc
                          }}</label>
                      </p>
                    </div>
                  </td>
                </tr>
                </tfoot>
              </table>
            </div>

            <div>
              <proposal-specs
                  :proposal_id="purchase_order.proposal_id"
              ></proposal-specs>
              <!--<sign-cuttings :cuttings="purchase_order.cuttings" ></sign-cuttings>-->
              <br>
              <stock-item-list-groups :stock_item_id="stock_item_id" v-if="stock_item_id"></stock-item-list-groups>
            </div>
          </div>
        </div>

        <div class="field">
          <el-form-item label="Estimated" required prop="estimated_value">
            <el-input
                type="number"
                :disabled="po_primary_disabled"
                v-model.number="purchase_order.estimated_value"
                v-on:change="update_actual_value"
                style="width: 100px"
                size="mini"
            ></el-input>
          </el-form-item>

          <div v-show="purchase_order.is_open_quote">
            <el-form-item
                label="Percent applied (Charge PO)"
                required
                prop="charge_percent_applied"
            >
              <el-input
                  type="number"
                  v-model.number="purchase_order.charge_percent_applied"
                  style="width: 100px"
                  size="mini"
              ></el-input>
            </el-form-item>
            <el-form-item label="Sale price">{{
                charge_po_sale_price
              }}
            </el-form-item>
          </div>
        </div>

        <br/>

        <div class="divContainer row">
          <div class="col m5">
            <b>Status</b>
            <hr/>
            <div class="field">
              <el-select
                  class="selectMob"
                  style="vertical-align: top"
                  v-model="purchase_order.po_status_id"
                  ref="po_status_id"
                  filterable
                  size="small"
              >
                <el-option
                    v-for="status in po_status_list"
                    :key="status.id"
                    :label="status.name"
                    :value="status.id"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="col m6">
            <div class="field">
              <div v-show="po_transactions.length > 0">
                <b>Transactions</b>
                <hr/>
                <table class="tableclass">
                  <thead>
                  <tr>
                    <td>Status</td>
                    <td>Updated on</td>
                    <td>By</td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="trans in po_transactions" :key="trans.id">
                    <td>{{ trans.po_status }}</td>
                    <td>
                      <el-date-picker
                          size="small"
                          v-if="trans.po_status == 'APPROVED'"
                          type="date"
                          v-model="po_approved_date"
                          v-on:change="update_po_approved_date"
                          format="dd MMM yyyy"
                          class="input-with-select"
                      >
                        <el-button
                            slot="append"
                            icon="el-icon-search"
                        ></el-button>
                      </el-date-picker>
                      <el-date-picker
                          size="small"
                          v-else-if="trans.po_status == 'RECEIVED'"
                          type="date"
                          v-model="po_received_date"
                          v-on:change="update_po_received_date"
                          format="dd MMM yyyy"
                          class="input-with-select"
                      >
                        <el-button
                            slot="append"
                            icon="el-icon-search"
                        ></el-button>
                      </el-date-picker>
                      <span v-else>{{ trans.created_at }}</span>
                    </td>
                    <td>{{ trans.crm_user }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <br/>

        <div class="divContainer row" v-show="purchase_order.po_status_id > 1">
          <div class="col s5">
            <b>Attachments</b>
            <hr/>

            <AttachmentSection
                :title="'Invoices'"
                :allow-upload="true"
                :path="`PurchaseOrders/${purchase_order.id}/Invoices`"
            >
            </AttachmentSection>

            <AttachmentSection
                :title="'Packing Slips'"
                :allow-upload="true"
                :path="`PurchaseOrders/${purchase_order.id}/PackingSlips`"
            >
            </AttachmentSection>
          </div>
          <!-- <div class="col s1">
            <label></label>
          </div> -->
          <div class="col s6">
            <b>Review</b>
            <hr/>

            <div class="field">
              <p>
                <label>Actual value</label>
              </p>
              <el-input
                  type="text"
                  v-model="purchase_order.actual_value"
                  style="width:70px"
                  size="small"
              ></el-input>
            </div>
            <div class="field">
              <el-form-item label="Additional Info">
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 10, maxRows: 10 }"
                    placeholder="Enter additional informaion here ... "
                    v-model="purchase_order.additional_info"
                    size="small"
                ></el-input>
              </el-form-item>
            </div>
            <br/>
            <br/>
            <b>Supplier details</b>
            <hr/>
            <div class="field">
              <el-form-item label="Invoice #">
                <el-input
                    type="text"
                    placeholder="Enter supplier invoice here"
                    v-model="purchase_order.supplier_invoice_no"
                    size="small"
                ></el-input>
              </el-form-item>
            </div>
            <div class="field">
              <el-form-item label="Invoice date">
                <el-date-picker
                    size="small"
                    type="date"
                    v-model="purchase_order.supplier_invoice_date"
                    format="dd MMM yyyy"
                    value-format="yyyy-MM-dd"
                    class="input-with-select"
                >
                  <el-button slot="append" icon="el-icon-search"></el-button>
                </el-date-picker>
              </el-form-item>
            </div>

            <div v-show="convertable_to_stock">
              <a href="/convert_po_to_stock/">Convert to stock item</a>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane
          label="Mails"
          name="mails"
          v-if="message_references.length > 0"
      >
        <FilteredEmails
            :filtered_msg_id="message_references"
            :po_id="po_id"
        ></FilteredEmails>
      </el-tab-pane>
    </el-tabs>

    <div class="row">
      <hr/>
      <div class="col m12">
        <a href="/purchase_orders" class="btn" v-show="is_charge_po != true"
        >BACK</a
        >
        <button
            v-on:click.prevent="submit_form"
            ref="save_btn"
            v-show="purchase_order.mark_as_deleted != 1"
            class="btn save right"
        >
          SAVE
        </button>
      </div>
    </div>
  </el-form>
</template>

<script>
import ErrorExplanation from '../shared/ErrorExplanation';
import FilteredEmails from 'components/mailbox/FilteredEmails.vue';
import AttachmentSection from 'components/shared/AttachmentSection.vue';
import ProposalSpecs from './ProposalSpecs';
import StockItemListGroups from '../stock_items/StockItemListGroups.vue'
import ReimbursementCrmUser from './ReimbursementCrmUser'

export default {
  name: 'Edit',
  props: {
    po_id: [Number, String],
    proposal: Object,
    refresh_list: Function,
    is_charge_po: Boolean,
    working_file_id: Number,
    is_pop_up: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected_tab: 'details',
      purchase_order: {
        id: 0,
        supplier_id: '',
        proposal_id: '',
        branch_id: '',
        sign_id: '',
        po_status_id: 1,
        is_open_quote: false,
        is_manual: 1,
        description: '',
        actual_value: 0,
        estimated_value: 0,
        charge_percent_applied: 0,
        mark_as_deleted: 0,
      },
      message_references: [],
      po_approved_date: '',
      po_received_date: '',
      po_primary_disabled: false,
      suppliers_list: [],
      category_tasks_list: [],
      errors: [],
      po_transactions: [],
      accounts_list: [],
      categories_list: [],
      stock_items_list: [],
      quotes_list: [],
      branches_list: [],
      signs_list: [],
      is_approved: false,
      is_pending: false,
      is_open_quote: false,
      total_amount: 0,
      po_status_list: [],
      selected_stock_item: '',
      selected_supplier: '',
      selected_accounts_id: '',
      allocated_price_desc: '',
      stock_price_desc: '',
      stock_on_hand_desc: '',
      convertable_to_stock: false,
      stock_item_id: ''
    };
  },
  components: {
    'error-explanation': ErrorExplanation,
    'proposal-specs': ProposalSpecs,
    FilteredEmails,
    AttachmentSection,
    StockItemListGroups,
    ReimbursementCrmUser
  },
  mounted() {
    let _this = this;
    setTimeout(function () {
      _this.set_default_input();
      _this.preload_proposal();
    }, 200);
  },
  created() {
    this.fill_data();
  },
  computed: {
    charge_po_sale_price() {
      let sale_price = parseFloat(this.purchase_order.actual_value);
      if (this.purchase_order.charge_percent_applied != null)
        sale_price +=
            (sale_price * this.purchase_order.charge_percent_applied) / 100;
      return sale_price;
    },
  },
  watch: {
    po_id: function () {
      this.clear_inputs();
      this.fill_data();
      this.preload_proposal();
    },
  },
  methods: {
    stock_item_link() {
      window.open(`/stock_items/${this.stock_item_id}/edit`, '_blank');
    },
    set_default_input() {
      let _this = this;
      Array.from(document.querySelectorAll('.el-input__inner')).forEach(
          function (e, i) {
            e.classList.add('browser-default');
            if (e.querySelector('input') != null)
              e.querySelector('input').className += ' browser-default';
          }
      );
    },
    update_po_approved_date() {
      let approved_po = self.po_transactions.filter(
          (item) => item.po_status == 'APPROVED'
      )[0];
      this.update_po_trans_date(approved_po.id, this.po_approved_date);
    },
    update_po_received_date() {
      let received_po = self.po_transactions.filter(
          (item) => item.po_status == 'RECEIVED'
      )[0];
      this.update_po_trans_date(received_po.id, this.po_received_date);
    },
    update_po_trans_date(id, val) {
      // let approved_po = self.po_transactions.filter(
      //   item => item.po_status == "APPROVED"
      // )[0];
      let data = {
        po_trans_id: id,
        po_date: val,
      };
      this.$http.post('/update_po_trans_date', data).then(
          (response) => {
            this.$message({
              message: 'PO transaction date has been updated successfully.',
              type: 'success',
            });
          },
          (response) => {
            this.$message({
              message:
                  'Error happened while update the record, please refresh the page an try again.',
              type: 'error',
            });
          }
      );
    },
    clear_product_inputs() {
      if (this.purchase_order.is_open_quote == true) {
        this.selected_stock_item = '';
        this.purchase_order.proposal_id = '';
        this.purchase_order.sign_id = '';
        this.purchase_order.stock_item_branch_id = '';

        this.self.stock_price_desc = '';
        this.stock_on_hand_desc = '';
        this.allocated_price_desc = '';
      }
    },
    is_status(status) {
      let po_status = this.po_status_list.filter(
          (item) => item.id == this.purchase_order.po_status_id
      )[0];

      return po_status.name == status;
    },
    submit_form() {
      let self = this;
      this.$refs['purchase_order'].validate((valid) => {
        if (valid) {
          self.save();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    save() {
      this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      this.$refs['save_btn'].disabled = true;
      let self = this;
      self.errors = [];
      let url = '/purchase_orders/';
      let request_type = 'post';
      if (this.purchase_order.id > 0) {
        request_type = 'patch';
        url = url + this.purchase_order.id;
      }
      if (this.working_file_id != undefined)
        this.purchase_order.working_file_id = this.working_file_id;
      this.$http[request_type](url, JSON.stringify(this.purchase_order)).then(
          (response) => {
            self.$loading().close();
            this.$refs['save_btn'].disabled = false;

            self.$message({
              message: 'PO has been saved successfully.',
              type: 'success',
            });
            if (self.is_pop_up == true) {
              self.refresh_list();
            } else window.location = '/purchase_orders';
          },
          (response) => {
            self.$loading().close();
            this.$refs['save_btn'].disabled = false;
            if (
                response.data.errors != undefined &&
                response.data.errors.length > 0
            ) {
              self.errors = response.data.errors;
              if (self.is_charge_po != true)
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: 'smooth',
                });
            }
          }
      );
    },
    round(num) {
      return +(Math.round(num + 'e+3') + 'e-3');
    },
    update_actual_value() {
      this.purchase_order.actual_value = this.purchase_order.estimated_value;
    },
    stock_specs(stock) {
      return `Width: ${stock.width} , Height: ${stock.height}, GSM: ${stock.thickness}`;
    },
    actual_value_disabled() {
      let po_status = this.po_status_list.filter(
          (item) => item.id == this.purchase_order.po_status_id
      )[0];
      if (po_status == undefined) return;

      this.convertable_to_stock =
          ['RECEIVED', 'INVOICED', 'PAID'].includes(po_status) &&
          this.purchase_order.is_open_quote == true &&
          this.purchase_order.stock_item_branch_id == undefined;
      this.is_approved = po_status == 'APPROVED' ? true : false;
      this.is_pending = po_status == 'PENDING' ? true : false;

      return (
          po_status.name == 'APPROVED' ||
          po_status.name == 'RECEIVED' ||
          po_status.name == 'INVOICED' ||
          (po_status.name == 'REJECTED' && purchase_order.errors.length == 0)
      );
    },
    fill_stocks() {
      let self = this;
      if (
          this.purchase_order.supplier_id == undefined ||
          this.purchase_order.category_supplier_id == undefined
      )
        return;
      self.stock_items_list = [];
      let params = {
        supplier_id: this.purchase_order.supplier_id,
        category_supplier_id: this.purchase_order.category_supplier_id,
        branch_id: this.purchase_order.branch_id,
      };
      this.$http.post('/get_stock_items_by_params/', params).then(
          function (response) {
            // Success
            if (response.status == 200) {
              self.stock_items_list = response.data;
              // Clear stock item id if not in current stocks list
              if (self.stock_items_list.length > 1)
                self.update_stock_info(true, true);
            }
          },
          function (response) {
            // error
            console.log(response);
          }
      );
    },
    fill_email() {
      let selected_supplier = self.suppliers_list.filter(
          (item) => item.id == this.purchase_order.supplier_id
      )[0];
      if (selected_supplier != undefined)
        this.purchase_order.send_to = selected_supplier.email;
    },
    fill_lookup() {
      this.fill_po_account();
      this.fill_category_tasks();
      this.fill_suppliers();
      this.fill_categories();
      this.fill_po_statuses();
      this.fill_stocks();
      this.load_po_quote();
      this.load_po_sign();
      this.load_branches();
    },
    load_branches() {
      let _this = this;
      this.$http.get('/branches_list').then(
          function (response) {
            // Success
            if (response.status == 200) {
              _this.branches_list = response.data;
            }
          },
          function (response) {
            // error
            console.log(response);
          }
      );
    },
    fill_po_account() {
      if (this.purchase_order.account_id == undefined) return;
      this.$http.get('/account_by_id/' + this.purchase_order.account_id).then(
          function (response) {
            // Success
            if (response.status == 200) {
              self.accounts_list = response.data;
            }
          },
          function (response) {
            // error
            console.log(response);
          }
      );
    },
    fill_data() {
      if (this.po_id == 0) {
        this.fill_lookup();
        return;
      }
      self = this;
      this.$http.get('/purchase_orders/' + this.po_id + '/edit/').then(
          (response) => {
            // Success
            if (response.status == 200) {
              self.stock_item_id = response.data.stock_item_id;
              self.purchase_order = response.data.purchase_order;
              self.po_transactions = response.data.po_transactions;
              self.message_references = response.data.message_references;

              self.po_approved_date = '';
              self.po_received_date = '';

              let approved_po = self.po_transactions.filter(
                  (item) => item.po_status == 'APPROVED'
              )[0];

              let received_po = self.po_transactions.filter(
                  (item) => item.po_status == 'RECEIVED'
              )[0];

              if (approved_po != undefined)
                self.po_approved_date = approved_po.created_at;
              else self.po_approved_date = '';

              if (received_po != undefined)
                self.po_received_date = received_po.created_at;

              self.purchase_order.is_open_quote =
                  self.purchase_order.is_open_quote == 1 ? true : false;
              if (self.purchase_order.po_status_id > 1)
                this.po_primary_disabled = true;
              self.fill_lookup();
            }
          },
          function (response) {
            // error
            console.log(response);
          }
      );
    },
    load_attachments() {
    },
    fill_category_tasks() {
      self = this;
      let query_str = '';
      this.$http.get('/category_tasks_list').then(
          function (response) {
            // Success
            if (response.status == 200) {
              self.category_tasks_list = response.data;
            }
          },
          function (response) {
            // error
            console.log(response);
          }
      );
    },
    fill_suppliers() {
      self = this;
      let query_str = '';
      // if(this.purchase_order.supplier_id != undefined)
      //     query_str = "?id=" + this.purchase_order.supplier_id
      this.$http.get('/suppliers_list' + query_str).then(
          function (response) {
            // Success
            if (response.status == 200) {
              self.suppliers_list = response.data;
            }
          },
          function (response) {
            // error
            console.log(response);
          }
      );
    },
    fill_categories() {
      self = this;
      let query_str = '';
      this.$http.get('/category_suppliers_list').then(
          function (response) {
            // Success
            if (response.status == 200) {
              self.categories_list = response.data;
            }
          },
          function (response) {
            // error
            console.log(response);
          }
      );
    },
    accounts_list_query(term) {
      self = this;
      let encoded_term = encodeURIComponent(term);
      this.$http.get('/accounts_with_proposals?term=' + encoded_term).then(
          function (response) {
            // Success
            if (response.status == 200) {
              self.accounts_list = response.data;
            }
          },
          function (response) {
            // error
            console.log(response);
          }
      );
    },
    fill_po_statuses() {
      this.$http
          .get('/po_statuses_by_id/' + (self.purchase_order.po_status_id || 1))
          .then(
              async function (response) {
                this.po_status_list = await response.data;
              },
              function (response) {
                console.log(response);
              }
          );
    },
    load_po_quote() {
      if (this.is_charge_po == true) {
        this.purchase_order.is_open_quote = true;
      }

      if (
          this.purchase_order.proposal_id == undefined ||
          this.purchase_order.proposal_id == '' ||
          this.purchase_order.proposal_id == null
      )
        return;
      let prop_id = this.purchase_order.proposal_id;
      this.$http.get('/proposals_list/' + prop_id).then(
          function (response) {
            // Success
            if (response.status == 200) {
              self.quotes_list = response.data;
            }
          },
          function (response) {
            // error
            console.log(response);
          }
      );
    },
    load_po_sign() {
      if (this.purchase_order.sign_id == undefined) return;
      this.$http.get('/signs/' + this.purchase_order.sign_id).then(
          function (response) {
            // Success
            if (response.status == 200) {
              self.signs_list = [response.data];
            }
          },
          function (response) {
            // error
            console.log(response);
          }
      );
    },
    refetch_quotes() {
      if (this.selected_accounts_id != undefined)
        this.load_quotes_by_account_id(this.selected_accounts_id);
    },
    load_quotes_by_account_id(id) {
      this.quotes_list = [];
      this.purchase_order.proposal_id = '';
      let filter = '';

      if (this.purchase_order.category_task_id != undefined)
        filter = '&category_task_id=' + this.purchase_order.category_task_id;
      this.$http
          .get('/get_proposals_by_account?account_id=' + (id || 0) + filter)
          .then(
              function (response) {
                // Success
                if (response.status == 200) {
                  self.quotes_list = response.data;
                }
              },
              function (response) {
                // error
                console.log(response);
              }
          );
    },
    load_signs_by_proposal_id(id) {
      this.signs_list = [];
      this.purchase_order.sign_id = '';
      this.$http.get('/get_signage_by_proposal?proposal_id=' + (id || 0)).then(
          function (response) {
            // Success
            if (response.status == 200) {
              self.signs_list = response.data;
            }
          },
          function (response) {
            // error
            console.log(response);
          }
      );
    },
    update_stock_info(is_priced_locked, is_purchase_qty_locked) {
      // Do not update stock info if not pending
      // if (this.is_status('PENDING') == false) return;
      if (parseInt(this.purchase_order.po_status_id) != 1) return;

      let selected_stock_item_qry = this.stock_items_list.filter(
          (item) =>
              item.stock_item_branch_id == this.purchase_order.stock_item_branch_id
      )[0];

      if (selected_stock_item_qry == undefined) return;

      this.selected_stock_item = selected_stock_item_qry;
      this.purchase_order.quantity = this.purchase_order.quantity || 1;

      this.stock_item_id = this.selected_stock_item.id;
      this.selected_stock_item.price = this.selected_stock_item.price || 0;
      let purchase_qty = this.purchase_order.quantity;
      let unit_price = 0;

      if (this.selected_stock_item.minimum_packet_quantity != 0) {
        purchase_qty =
            Math.ceil(
                this.purchase_order.quantity /
                this.selected_stock_item.minimum_packet_quantity
            ) * this.selected_stock_item.minimum_packet_quantity;
        unit_price = this.round(
            parseFloat(this.selected_stock_item.price) /
            parseFloat(this.selected_stock_item.unit)
        );
        this.stock_price_desc = `${unit_price} NZD per item (${parseFloat(
            this.selected_stock_item.price
        )}/${this.selected_stock_item.unit})`;
      } else {
        unit_price = this.selected_stock_item.price;
        this.stock_price_desc = this.selected_stock_item.price;
      }

      if (is_purchase_qty_locked == true)
        this.purchase_order.purchased_quantity = purchase_qty;
      else {
        if (
            this.purchase_order.quantity > this.purchase_order.purchased_quantity
        )
          this.purchase_order.quantity = this.purchase_order.purchased_quantity;
      }

      if (is_priced_locked == true) this.purchase_order.unit_price = unit_price;
      else unit_price = this.purchase_order.unit_price || 0;

      if (this.purchase_order.proposal_id != undefined) {
        this.allocated_price_desc =
            parseFloat(this.selected_stock_item.allocated) +
            parseFloat(this.purchase_order.quantity);
      }
      this.stock_on_hand_desc =
          parseFloat(this.selected_stock_item.stock_on_hand) +
          parseFloat(this.purchase_order.purchased_quantity);
      this.purchase_order.estimated_value =
          this.purchase_order.purchased_quantity * unit_price;
      this.purchase_order.actual_value = this.purchase_order.estimated_value;
    },
    clear_inputs() {
      this.purchase_order = {
        id: 0,
        supplier_id: '',
        proposal_id: '',
        sign_id: '',
        po_status_id: 1,
        is_open_quote: false,
        is_manual: 1,
        description: '',
        actual_value: 0,
        estimated_value: 0,
        category_task_id: '',
      };
      this.po_transactions = [];
    },
    preload_proposal() {
      if (
          this.is_pop_up == true &&
          this.proposal &&
          this.proposal.id != undefined
      ) {
        this.purchase_order.branch_id = this.proposal.branch_id;
        this.selected_accounts_id = this.proposal.account_id;
        this.quotes_list = [
          {id: this.proposal.id, value: this.proposal.job_name},
        ];
        this.accounts_list = [
          {
            id: this.selected_accounts_id,
            account_name: this.proposal.account_name,
          },
        ];
        this.purchase_order.proposal_id = this.proposal.id;
      }
    },
  },
};
</script>

<style>
@media only screen and (max-width: 768px) {
  .el-popper {
    width: 100% !important;
    left: 0 !important;
  }

  .selectMob
  .el-select-dropdown__wrap
  .el-scrollbar__wrap
  .el-scrollbar__wrap--hidden-default {
    height: 130px !important;
    overflow: scroll;
  }

  .selectMob ul.el-scrollbar__view ul.el-select-dropdown__list {
    width: 100% !important;
    overflow: scroll !important;
    margin-left: 0px !important;
  }

  .el-select-dropdown__wrap {
    height: 150px !important;
  }

  .el-select-dropdown {
    width: 100%;
    left: 0 !important;
  }

  span.mob-view {
    float: left !important;
    position: relative;
    width: 100% !important;
  }

  .selectMob.el-select-dropdown {
    margin-left: 0px !important;
  }
}
</style>
