<template>
  <el-card class="sales__section">
    <el-collapse
      v-if="parent_sale.invoice_based_wf == false"
      accordion
    >
      <DescriptionsListItem
        :is_show_option="show_conditions.is_edit_option"
        :sale_key="parent_sale_key"
        name="1"
        title="Invoice Description"
      />
    </el-collapse>

    <el-collapse
      v-else
      accordion
      @change="handleChange"
    >
      <DescriptionsListItem
        v-for="(wf_sale,index) in wf_sales"
        :key="index"
        :is_show_option="false"
        :sale_key="wf_sale.key"
        :name="wf_sale.key"
        :title="invoiceTitle(index+1)"
      />
    </el-collapse>
  </el-card>
</template>

<script>
import { sale_store, PARENT_SALE_KEY } from '../../../store/modules/sale_store';
import {mapActions, mapGetters} from 'vuex';

import DescriptionsListItem from './DescriptionsListItem';

export default {
  name: 'DescriptionsList',
  store: sale_store,
  components: {
    DescriptionsListItem
  },
  props: {
    show_conditions: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      parent_sale_key: PARENT_SALE_KEY
    };
  },
  computed: {
    ...mapGetters([
      'get_parent_sale',
      'get_wf_sales',
    ]),
    parent_sale: {
      get() {
        return this.get_parent_sale;
      },
    },
    wf_sales: {
      get() {
        return this.get_wf_sales;
      },
    }
  },
  methods: {
    ...mapActions([
      'set_current_sale_key'
    ]),
    handleChange(val) {
      if (val) {
        this.set_current_sale_key(val);
        console.log(val);
      }
    },
    invoiceTitle(index) {
      return `Invoice ${index}`;
    }
  }
};
</script>

<style scoped>

</style>