import { sale_store } from '../store/modules/sale_store';
import { mapGetters, mapActions } from 'vuex';

const SalesCostMixin = {
  store: sale_store,
  computed: {
    ...mapGetters([
      'get_total_sale_price',
      'get_total_quote_price',
      'get_total_cost',
      'get_stock_cost',
      'get_labour_cost',
      'get_gross_profit'
    ]),
    total_sale_price: {
      get () {
        return this.get_total_sale_price;
      },
      set (value) {
        this.set_total_sale_price(value);
      }
    },
    total_quote_price: {
      get () {
        return this.get_total_quote_price;
      },
      set (value) {
        this.set_total_quote_price(value);
      }
    },
    total_cost: {
      get () {
        return this.get_total_cost;
      },
      set (value) {
        this.set_total_cost(value);
      }
    },
    stock_cost: {
      get () {
        return this.get_stock_cost;
      },
      set (value) {
        this.set_stock_cost(value);
      }
    },
    labour_cost: {
      get () {
        return this.get_labour_cost;
      },
      set (value) {
        this.set_labour_cost(value);
      }
    },
    gross_profit: {
      get () {
        return this.get_gross_profit;
      },
      set (value) {
        this.set_gross_profit(value);
      }
    },
  },
  methods: {
    ...mapActions([
      'set_total_sale_price',
      'set_total_quote_price',
      'set_total_cost',
      'set_stock_cost',
      'set_labour_cost',
      'set_gross_profit'
    ])
  }
};

export default SalesCostMixin;