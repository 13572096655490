<template>
  <div id="level-folders">
    <el-tree
      ref="search_tree"
      class="browser-default"
      :data="level_mailboxes"
      :default-expand-all="true"
      :expand-on-click-node="true"
      highlight-current
      :lock-scroll="true"
      empty-text
      draggable
      :allow-drag="allowDrag"
      @node-click="get_mails"
      @current-change="set_current_key"
      @node-drop="handle_drop"
    >
      <span
        :id="get_node(node)"
        slot-scope="{ node, data }"
        class="custom-tree-node"
        @dragover.prevent
        @drop.stop.prevent="drop"
      >
        <span>
          {{ node.label | truncate(23, '...') }}
          <el-badge
            v-show="data.count > 0"
            :value="data.count"
            class="item"
          />
        </span>
        <span v-show="node.level > 0 && current_key == node.id && !blocked_folders.includes(node.label)">
          <el-button
            size="mini"
            icon="el-icon-circle-plus"
            circle
            type="success"
            @click="create_level_mailbox_folder(node)"
          />
          <el-button
            size="mini"
            icon="el-icon-edit"
            circle
            @click="rename_mailbox(node)"
          />
          <el-button
            icon="el-icon-delete"
            circle
            size="mini"
            type="danger"
            @click="delete_dialog_visible = true"
          />
          <el-dialog
            title="DELETE FOLDER"
            :visible.sync="delete_dialog_visible"
            width="92%"
            :modal="false"
          >
            <p>Are you sure you want delete</p>
            <p>this folder and the emails inside?</p>
            <span
              slot="footer"
              class="dialog-footer"
            >
              <el-button @click="delete_dialog_visible = false">Cancel</el-button>
              <el-button
                icon="el-icon-delete"
                type="danger"
                @click="delete_mailbox(node),delete_dialog_visible = false"
              >Delete</el-button>
            </span>
          </el-dialog>

        </span>
      </span>
    </el-tree>
  </div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'ListLevelMailboxes',
  data() {
    return {
      dialog_visible: false,
      delete_dialog_visible: false,
      mailbox: '',
      current_key: 1,
      blocked_folders: ['Inbox', 'Compose', 'Sent', 'Contact', 'Drafts']
    };
  },
  computed: {
    ...mapGetters(['level_mailboxes'])
  },
  created() {
    this.load_level_mailboxes();
  },
  methods: {
    ...mapActions(['load_level_mailboxes', 'move_email']),
    get_node(node) {
      return node.data.markup;
    },
    set_current_key(node) {
      this.current_key = node.$treeNodeId;
    },
    create_level_mailbox_folder(value) {
      let path = value.data.markup;
      this.$prompt('Folder name', 'Create Mailbox', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        inputPattern: /^[A-Za-z][-a-zA-Z0-9@+_ ]+$/,
        inputErrorMessage: 'Invalid Name',
      }).then(({value}) => {
        path = path + '/' + value;
        this.$http.post('/create_level_mailbox_folder', {path: path}).then(
          () => {
            this.$message({
              message: 'Folder created',
              type: 'success',
            });
            this.load_level_mailboxes();
          },
          function () {
            this.$message({
              message: 'Mailbox already exists',
              type: 'error',
            });
          }
        );
      })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Input canceled',
          });
        });
    },
    get_mails(dada, node) {
      if (node.data.markup == 'Compose' || node.data.markup == 'Contact') {
        window.location.href = '/mailbox#/' + node.data.markup;
      } else {
        window.location.href = '/mailbox#/inbox/' + node.data.markup;
      }
    },
    drop(event) {
      event.preventDefault();
      let id = event.currentTarget.id;
      let message = event.dataTransfer.getData('text').split('O_u_O');
      this.move_email({
        message_id: message[0],
        folder_name: id,
        current_mailbox: message[1],
        index: message[2],
        message: 'Email moved to ' + id,
      });
      event.dataTransfer.clearData();
    },
    rename_mailbox(value) {
      console.log(value);

      let old_name = value.data.markup;
      this.$prompt('Folder name', 'Rename Mailbox', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        inputPattern: /^[A-Za-z][-a-zA-Z0-9@+_ ]+$/,
        inputErrorMessage: ''
      })
        .then(({value}) => {
          let name = value;
          this.$http
            .post('/rename_mailbox', {old_name: old_name, new_name: name})
            .then(
              () => {
                this.$message({
                  message: 'Folder renamed',
                  type: 'success'
                });
                this.load_level_mailboxes();
                window.location.href = '/mailbox#/inbox/' + value;
              },
              function () {
              }
            );
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Input canceled'
          });
        });
    },

    delete_mailbox(node) {
      console.log(node);
      if (node.data.markup.length > 1) {
        this.$http.post('/delete_mailbox', {path: node.data.markup}).then(
          () => {
            if (node.data.markup == 'Trash' || node.data.markup == 'Junk') {
              this.$message({
                message: 'Emails deleted. Folder can\'t be deleted',
                type: 'success'
              });
            } else {
              this.$message({
                message: 'Folder deleted',
                type: 'success'
              });
            }
            this.load_level_mailboxes();
            window.location.href = '/mailbox#/inbox/Inbox';
          },
          function () {
          }
        );
      }
    },
    handle_close(done) {
      this.$confirm('Are you sure to close this dialog?')
        .then(() => {
          done();
        });

    },
    handle_drop(draggingNode, dropNode, dropType) {
      console.log('folder name', dropType);
      console.log('folder name', draggingNode.data.label);
      console.log('old path: ', draggingNode.data.markup);
      console.log('new path: ', dropNode.data.markup, dropType);

      this.$http.post('/drag_mailbox',
        {
          folder_name: draggingNode.data.label,
          old_path: draggingNode.data.markup,
          new_path: dropNode.data.markup,
          drop_type: dropType
        }).then(
        (response) => {
          this.$message({
            message: 'Folder moved to ' + response.data.path,
            type: 'success'
          });
          this.load_level_mailboxes();
        },
        function (response) {
          this.$message({
            message: 'Can\'t move default folder ' + response.data.path,
            type: 'error'
          });
          this.load_level_mailboxes();
        }
      );
    },
    allowDrag(draggingNode) {
      return draggingNode.data.label.indexOf('Inbox') === -1;
    }
  }
};
</script>

<style scoped>
.el-tree {
  height: 100%;
  overflow: unset;
  max-height: 100%;
}

#level-folders .custom-tree-node {
  font-size: 13px;
}

#level-folders {
  line-height: 0px;
}

#level-folders .el-button.is-circle {
  padding: 2px;
}
</style>