<template>
  <div style="display: inline;">
    <el-button
      type="primary"
      class=" btn"
      @click="show_quote_dialog = true"
    >
      Create Quote
    </el-button>
    <el-dialog
      title="Create Quote"
      :visible.sync="show_quote_dialog"
      width="440px"
      append-to-body
      :close-on-click-modal="true"
    >
      <el-button @click="show_create_new_quote">
        New Quote
      </el-button>
      <el-button @click="show_create_template">
        Create Template
      </el-button>
      <el-button @click="show_copy_quote">
        Copy Quote
      </el-button>
    </el-dialog>
    <create-new-quote
      ref="create-new-quote"
      :account_id="account_id"
    />
    <copy-quote
      ref="copy-quote"
      :account_id="account_id"
    />
    <create-template
      ref="create-template"
      :account_id="account_id"
    />
  </div>
</template>
<script>

import CreateNewQuote from './CreateNewQuote.vue';
import CopyQuote from './CopyQuote.vue';
import CreateTemplate from './CreateTemplate.vue';

export default {
  name: 'CreateQuote',
  components: {CreateNewQuote, CopyQuote, CreateTemplate},
  props: {
    account_id: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      show_quote_dialog: false
    };
  },
  created() {
  },
  methods: {
    show_create_new_quote(){
      this.$refs['create-new-quote'].show_new_quote_dialog = true;
    },
    show_create_template(){
      this.$refs['create-template'].show_create_template_dialog = true;
    },
    show_copy_quote(){
      this.$refs['copy-quote'].show_copy_quote_dialog = true;
    },
  }
};
</script>
<style scoped>
</style>