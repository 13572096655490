<template>
  <tr>
    <td>
      <el-checkbox v-model="stock_related_task.selected"></el-checkbox>
    </td>
    <td>
      <span>{{stock_related_task.name}}</span>
    </td>
    <td>
      <el-input v-model.number="stock_related_task.time_allocated_labour" size="mini" style="width: 140px">
        <template slot="append">min.</template>
      </el-input>
    </td>
    <td>
      <el-input-number size="mini" style="width: 120px" :step="0.1" v-model="stock_related_task.sale_price"></el-input-number>
    </td>
    <td>{{round(stock_related_task.sale_price * stock_related_task.time_allocated_labour )}}</td>
  </tr>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["stock_related_task"],
  mounted() {},
  methods: {
    round(num) {
      return Math.round(num * 100) / 100;
    }
  }
};
</script>

