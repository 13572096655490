<template>
  <el-card class="box-card">
    <b>Overview</b>
    <KPIsCalendar
      ref="kpi_calendar"
      :overall_res="overall_res"
      @on_prev="onPrev"
      @on_next="onNext"
    />
  </el-card>
</template>

<script>

import KPIsCalendar from './KPIsCalendar';
import {loadOverall} from '../../api/my_kpi';

export default {
  name: 'Overview',
  components: {KPIsCalendar},
  props: {
    selected_user: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      selected_date_range: new Array(2),
      overall_res: {},
      date: this.first_day()
    };
  },
  watch: {
    selected_user() {
      this.request_my_overall();
      this.$refs.kpi_calendar.fullcalendarRefresh();
    }
  },
  created() {
    this.request_my_overall();
  },
  methods: {
    onPrev() {
      this.date.setMonth(this.date.getMonth()-1);
      this.request_my_overall();
    },
    onNext() {
      this.date.setMonth(this.date.getMonth()+1);
      this.request_my_overall();
    },
    first_day() {
      let date = new Date();
      return new Date(date.getFullYear(), date.getMonth(), 1);
    },
    request_my_overall() {
      this.selected_date_range[0] = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
      this.selected_date_range[1] = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);
      loadOverall(
        this,
        this.selected_date_range[0].toString(),
        this.selected_date_range[1].toString(),
        this.selected_user
      ).then(({data})=>{ this.overall_res = data;});
    }
  }
};
</script>
