<template>
  <div>
    <!-- <ul>
  
   <li v-for="stock in stock_items_list" :key="stock.id" >
     {{ stock }}

     </li>
    </ul>-->

    <el-collapse>
      <el-collapse-item
        v-for="stockitem in stockitems"
        :key="stockitem.id"
        name="test3"
      >
        <template slot="title">
          <p class="row1">
            <span
              v-if="stockitem.fixed_assets"
              style="font-weight:300"
            >
              <i
                v-show="stockitem.fixed_assets"
                class="el-icon-s-tools"
                style="color:grey"
              />
              {{ stockitem.quantity }}
            </span>

            <span
              v-else
              style="font-weight:300"
            >{{ stockitem.quantity }} {{ stockitem.unit }} x</span>
            {{ stockitem.stock_name }} | {{ stockitem.supplier_name }}
            <span
              v-show="stockitem.po_status == 'Approved - Pickup'"
              style="color:red"
            >{{ stockitem.po_status }} | PO: {{ stockitem.po_number }}</span>
            <span
              v-show="stockitem.po_status == 'Approved - Delivery'"
              style="color:#339966"
            >{{ stockitem.po_status }}</span>
            <span
              v-show="stockitem.po_status == 'Pending Approval'"
              style="color:orange"
            >{{ stockitem.po_status }}</span>
          </p>

          <p class="row2">
            <span v-show="stockitem.stock_width">W:{{ stockitem.stock_width }} x</span>
            <span v-show="stockitem.stock_height">H:{{ stockitem.stock_height }} x</span>
            <span v-show="stockitem.stock_thickness">D:{{ stockitem.stock_thickness }}</span>
            <i
              v-show="stockitem.stock_on_hand"
              class="el-icon-circle-check"
              style="color:green"
            >{{ stockitem.stock_on_hand }}</i>
            <i
              v-show="stockitem.stock_in_order"
              class="el-icon-shopping-cart-full"
              style="color:red"
            >{{ stockitem.stock_in_order }}</i>
          </p>
        </template>

        <div style="padding:0px 2px 10px 5px;">
          <p class="row3">
            <i
              class="el-icon-arrow-right"
              style="color:grey"
            />
            <i
              v-show="stockitem.favourite"
              class="el-icon-star-on"
              style="color:orange"
            />
            <a
              href="#"
              style="color:#333"
            >ID: {{ stockitem.id }}</a>

            <span v-show="stockitem.category_name">{{ stockitem.category_name }} |</span>
            <span v-show="stockitem.po_number">
              <a href="#">PO:{{ stockitem.po_number }}</a> |
            </span>
            <el-button
              v-show="stockitem.action_button"
              type="danger"
              size="mini"
              plain
            >
              <el-checkbox v-model="checked" />Received
            </el-button>
            <span v-show="stockitem.min_packet_qty">
              <b>Min.PQ</b>
              {{ stockitem.min_packet_qty }} |
            </span>
            <span v-show="stockitem.unit_price">
              <b>Unit:</b>
              ${{ stockitem.unit_price }} |
            </span>
            <span v-show="stockitem.actual_price">
              <b>Total:</b>
              ${{ stockitem.actual_price }} |
            </span>
            <b>Used:</b>
            <el-input
              v-model="stockitem.used"
              placeholder
              style="width:30px;"
            />
            <span style="float:right; width:30px;">
              <i
                class="el-icon-delete-solid stockdelete"
                style="font-size:16px;"
              />
            </span>
          </p>
        </div>
      </el-collapse-item>
    </el-collapse>
    <div style="width:100%; text-align:right; padding:10px;">
      <el-button
        size="small"
        type="info"
        plain
      >
        + Charge PO
      </el-button>
      <el-button
        size="small"
        type="info"
        plain
      >
        + Stock Item
      </el-button>
    </div>
  </div>
</template>

<script>
import { ProposalStockItemMixin } from 'mixins/ProposalStockItemMixin.js';
import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
  name: 'StockListPreviewUseOnly.vue',
  components: {},
  mixins: [ProposalStockItemMixin],
  props: ['proposal'],
  data() {
    return {
      activeNames: ['1'],
      checked: '',
      stockitems: [],
      poitems: []
    };
  },
  mounted() {
    this.load_stocks_list();
  },
  computed: {
    ...mapGetters(['stock_items_list', 'proposal'])
  },
  methods: {
    ...mapActions(['set_stock_items_list']),
    load_stocks_list() {
      let _this = this;
      setTimeout(function() {
        _this.load_stock_items_list(_this.proposal.id).then(response => {
          _this.set_stock_items_list(response);
        });
      }, 500);
    },
    handleChange(val) {
      console.log(val);
    }
  },
  watch: {}
};
</script>
<style>
.divLeft {
  width: 50%;
  float: left;
}
.divRight {
  width: 50%;
  float: left;
}
.editbutton {
  float: right;
  height: 20px;
  background: #f3f3f3;
  width: 100%;
  text-align: right;
}
.deletebutton {
  float: right;
  width: 100%;
  text-align: right;
}
.row1 {
  font-size: 13px;
  padding-right: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.row2 {
  font-size: 13px;
  color: #444;
  font-weight: 300;
  margin-top: 0px;
  margin-bottom: 0px;
}
.row3 {
  color: rgb(119, 119, 119);
  font-size: 13px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0px !important;
}
.el-collapse-item__content {
  padding-bottom: 0px !important;
}
.acoheader {
  padding: 0px;
}
.stockdelete {
  color: red;
}
</style>
