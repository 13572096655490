<template>
  <div class>
    <!-- <el-row class="tableDiv">
      <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
        <span class="tableTitle">Name</span>
      </el-col>
      <el-col :xs="2" :sm="2" :md="2" :lg="2" :xl="2">
        <span class="tableTitle">Used</span>
      </el-col>
      <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1">
        <span class="tableTitle"></span>
      </el-col>
      <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
        <span class="tableTitle">Description</span>
      </el-col>
      <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1">
        <span class="tableTitle">Save</span>
      </el-col>
      <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
        <span class="tableTitle">PO/Received</span>
      </el-col>
    </el-row> -->

    <div
      v-for="usedstock in stock_items_list"
      :key="usedstock.id"
    >
      <el-row
        v-show="
          (usedstock.request != undefined &&
            !usedstock.request.is_approved == 1) ||
            !usedstock.mark_as_deleted == 1
        "
        style="border-bottom:1px solid #f3f3f3; padding: 20px 10px;"
      >
        <el-col
          :xs="24"
          :sm="24"
          :md="8"
          :lg="8"
          :xl="8"
        >
          {{
            usedstock.stock_name
          }}
        </el-col>
        <el-col
          :xs="2"
          :sm="2"
          :md="2"
          :lg="2"
          :xl="2"
        >
          <i
            v-if="usedstock.used_qty == 0"
            class="el-icon-loading"
            style="font-weight:bold; color:red"
          />

          <i
            v-else
            class="el-icon-edit-outline"
          />
        </el-col>
        <el-col
          :xs="2"
          :sm="2"
          :md="2"
          :lg="2"
          :xl="2"
        >
          <el-input-number
            v-model="usedstock.used_qty"
            :disabled="
              (usedstock.purchase_order != undefined &&
                usedstock.purchase_order.status == 'PENDING') ||
                has_project_plan == true ||
                usedstock.used_qty_original > 0
            "
            :min="0"
            :controls="false"
            :max="usedstock.quantity"
            class="usedstockdiv"
            placeholder="Qty"
            style="width:95%;margin-right:5%;"
          />
        </el-col>
        <el-col
          :xs="16"
          :sm="16"
          :md="4"
          :lg="4"
          :xl="4"
        >
          <el-input
            v-model="usedstock.used_qty_desc"
            :disabled="
              (usedstock.purchase_order != undefined &&
                usedstock.purchase_order.status == 'PENDING') ||
                has_project_plan == true ||
                usedstock.used_qty_original > 0
            "
            class="usedstockdiv"
            placeholder="Descrption"
          />
        </el-col>
        <el-col
          :xs="4"
          :sm="4"
          :md="2"
          :lg="2"
          :xl="2"
        >
          <el-button
            :disabled="
              !(
                usedstock.purchase_order == undefined ||
                (usedstock.purchase_order != undefined &&
                  usedstock.purchase_order.status != 'PENDING')
              ) ||
                has_project_plan == true ||
                usedstock.used_qty_original > 0
            "
            type="success"
            icon="el-icon-check"
            size="mini"
            circle
            @click="update_used_details(usedstock)"
          />
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="6"
          :lg="6"
          :xl="6"
        >
          <span
            v-if="usedstock.purchase_order != undefined"
          >PO:{{ usedstock.purchase_order.id }}</span>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import StockItemEntry from '../../sections/StockItemsEntry.vue';

import { mapGetters, mapActions, mapMutations } from 'vuex';
import { editor_store } from 'store/modules/editor';
import { ProposalStockItemMixin } from 'mixins/ProposalStockItemMixin.js';
import StockRelateTasksList from 'components/stock_items/StockRelateTasksList';
import { ProposalMixin } from 'mixins/ProposalMixin.js';

export default {
  name: 'IndividualStockItemUsed',
  components: { Multiselect, StockItemEntry, StockRelateTasksList },
  mixins: [ProposalStockItemMixin, ProposalMixin],
  store: editor_store,
  data() {
    return {
      selected: [],
      options: [],
      related_stock_dialog: false,
      stock_related_tasks: [],
      main_categories: [],
      is_loading: false,
      stock_item_id: null,
      stock_quantity: 1,
      sale_price: 0,
      checked: false,
      stock_quantity_calculated_overwritten: null,
    };
  },
  computed: {
    ...mapGetters(['proposal', 'stock_items_list', 'has_project_plan']),
  },
  mounted: function() {
    this.categories();
    this.set_inputs_to_default();
    //this.stock_list();

    // eventBus.$on("stock_quantity", stock_quantity => {
    //   this.stock_quantity = stock_quantity;
    // });

    // document
    //   .querySelectorAll(".stock_container [type=submit]")
    //   .forEach(function(item, index) {
    //     item.addEventListener("click", function(e) {
    //       if (
    //         $(e.currentTarget)
    //           .val()
    //           .replace(/ /g, "") == "SaveStockItem"
    //       )
    //         return true;

    //       e.preventDefault();
    //       var container = $(this).closest(".stock_container");
    //       var qty = container.find("#stock_quantity").val();
    //       var type = container.attr("data-type");
    //       var id = container.attr("data-id");
    //       var submit_btn = this;
    //       var params = [
    //         {
    //           name: "selected_stock_item[quantity]",
    //           value: qty
    //         },
    //         {
    //           name: "selected_stock_item[proposal_type]",
    //           value: type
    //         },
    //         {
    //           name: "selected_stock_item[id]",
    //           value: id
    //         }
    //       ];

    //       $.each(params, function(i, param) {
    //         $('[name="' + param.name + '"]').remove();
    //         $(".edit_proposal").append(
    //           $.map(params, function(param) {
    //             return $("<input>", {
    //               type: "hidden",
    //               name: param.name,
    //               value: param.value
    //             });
    //           })
    //         );
    //       });
    //       $(".edit_proposal").append(
    //         $("<input>", {
    //           type: "hidden",
    //           name: "commit",
    //           value: "create stock item"
    //         })
    //       );

    //       console.log("finish");
    //       e.stopPropagation();
    //       $(".edit_proposal").submit();
    //     });
    //   });
  },

  methods: {
    ...mapActions(['set_stock_items_list']),

    update_used_details(usedstock) {
      let _this = this;

      let params = {
        id: usedstock.id,
        used_qty: usedstock.used_qty,
        qty_desc: usedstock.used_qty_desc,
      };
      params = `[${JSON.stringify(params)}]`;
      this.update_used_stock_details(this.proposal.id, params).then(
        (response) => {
          let stock = this.stock_items_list.filter(
            (c) => c.id == usedstock.id
          )[0];
          stock.used_qty_original = stock.used_qty;
          this.$message({
            message: 'Stock Item Updated',
            type: 'success',
          });
        },
        (response) => {
          this.$message({
            message: 'Unable to update',
            type: 'error',
          });
        }
      );
    },
    open_modal() {
      this.$modal.show('addStockItem');
    },
    hide_modal() {
      this.$modal.hide('addStockItem');
    },
    calc_qty_from_stock(qty, po_qty) {
      var from_stock = qty - po_qty;
      return from_stock < 0 ? 0 : from_stock;
    },

    set_inputs_to_default() {
      let _this = this;
      setTimeout(() => {
        Array.from(
          document.querySelectorAll('.stocks_list_div .el-input')
        ).forEach(function(e, i) {
          e.classList.add('browser-default');
          if (e.querySelector('input') != null)
            e.querySelector('input').className += ' browser-default';
        });
      }, 300);
    },
    // calc_total_price: function(price, percent, quantity) {
    //   var tp = 0.0;
    //   if (quantity > 0 && price > 0) {
    //     tp = parseFloat(price) * quantity;
    //   }

    //   if (percent > 0 && tp > 0) {
    //     tp = parseFloat(tp) + (parseFloat(tp) / 100) * percent;
    //   }
    //   return tp;
    // },
    get_stock_category(stock) {
      if (stock.sign_id != undefined) return 'signage';
      else if (stock.print_calculation_id != undefined) return 'print';
      else return 'default';
    },

    categories: function() {
      let self = this;
      this.$http.get('/s_categories').then(
        (response) => {
          self.options = response.data;
          self.main_categories = response.data;
        },
        function(response) {
          console.log(response);
        }
      );
    },

    current_selected_value(selectedValue) {
      let self = this;
      if (selectedValue.main_category != undefined) {
        //this.options = [];
        this.load_favorite_stock(selectedValue.id);
      }
      if (this.selected != null) {
        if (this.selected.length == 1) {
          this.stock_item_id = selectedValue.id;
          this.disabled = 'false';
          this.sale_price = selectedValue.sale_price;
          // self.save_stock_item();
        }
      }
    },
    save_stock_item: function() {
      let self = this;

      if (self.stock_item_id != null && self.stock_quantity > 0) {
        this.$http
          .post('/save_stock_item', {
            id: self.proposal.id,
            stock_item_id: self.stock_item_id,
            proposal_id: self.proposal.id,
            stock_quantity: self.stock_quantity,
            sale_price: self.sale_price,
          })
          .then(
            (response) => {
              self.stock_items_list.push(response.body.data);
              self.check_stock_attached_tasks(self.stock_item_id);
              self.stock_item_id = null;
              self.selected = null;
              this.categories();
              self.set_inputs_to_default();
            },
            function(response) {
              console.log(response);
            }
          );
      } else {
        console.log('error');
      }
    },
    check_stock_attached_tasks(stock_item_id) {
      let selected_stock = this.stock_items_list.filter((item) => {
        return item.stock_item_id == stock_item_id;
      })[0];

      this.get_stock_attached_tasks(stock_item_id).then(
        (response) => {
          if (response.length > 0) {
            this.stock_related_tasks = response;

            this.stock_related_tasks.forEach((el, idx) => {
              el['time_allocated_labour'] =
                this.stock_quantity *
                (selected_stock.speed_rate == '0'
                  ? 1
                  : selected_stock.speed_rate);
            });
            this.related_stock_dialog = true;
          }
        },
        (reason) => {
          console.log(reason);
        }
      );
    },
    internal_value(internalValue) {
      let self = this;
      if (internalValue.length === 0) {
        this.categories();
      }
      self.is_loading = true;
      if (internalValue.length === 1) {
        this.$http
          .post('/category_products', {
            category_supplier_id: internalValue[0].id,
          })
          .then(
            (response) => {
              self.is_loading = false;
              self.options = response.data;
            },
            function(response) {
              self.is_loading = false;
              console.log(response);
            }
          );
      }
    },
    load_favorite_stock(category_id) {
      let _this = this;
      this.$http
        .post('/favorite_stocks_by_category', {
          category_supplier_id: category_id,
        })
        .then((response) => {
          _this.options = response.data;
        });
    },
    find_subcategories(internalValue) {
      if (this.selected.length == 0) {
        this.options = this.main_categories;
      } else if (this.options.length == 0 && this.selected.length == 0) {
        this.categories();
        return;
      } else if (this.selected.length == 0 && this.options.length > 0) {
        return;
      }
      // else if (this.selected.length  == 1 && this.options.length == 1)
      // {
      //     this.options = []
      // }
      let self = this;

      self.is_loading = true;
      if (internalValue.length > 2) {
        this.$http
          .post('/category_products', {
            category_supplier_id: self.selected[0].id,
            term: internalValue,
          })
          .then(
            (response) => {
              self.is_loading = false;
              self.options = response.data;
            },
            function(response) {
              self.is_loading = false;
              console.log(response);
            }
          );
      } else {
        self.options = [];
      }
    },
  },
};
</script>

<style>
.usedstockdiv .el-input__inner {
  border-bottom: 1px solid #333 !important;
  border-top: 0px solid #333 !important;
  border-left: 0px solid #333 !important;
  border-right: 0px solid #333 !important;
  border-radius: 0px;
  padding: 0px !important;
  line-height: 12px !important;
}
.usedstockdiv .el-input__inner .textarea {
  height: 20px !important;
}
</style>
