<template>
  <div id="pay_runs">
    <span>Pay Runs</span>
    <el-divider />
    <el-table
      :data="pay_run_list"
      style="width: 100%"
    >
      <el-table-column
        prop="start"
        label="Start Period"
        width="180"
      />
      <el-table-column
        prop="end"
        label="End Period"
        width="180"
      />
      <el-table-column
        prop="status"
        label="Status"
        width="180"
      />
      <el-table-column
        label="Calculation"
        width="180"
      >
        <template #default="scope">
          <el-button
            size="small"
          >
            <router-link :to="{name: 'draft_pay', params: { pay_period_start: scope.row.start, pay_period_end: scope.row.end } }">
              Draft Pay
            </router-link>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import{pay_run_user_list} from '../../../api/pay_runs';
export default {
  name: 'PayRuns',
  props: {
    crm_user_id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      pay_run_list: []
    };
  },
  watch: {
    crm_user_id(){
    }
  },
  mounted() {
  },
  created() {
    this.pay_run_user_list();
  },
  methods: {
    pay_run_user_list(){
      pay_run_user_list(this, this.crm_user_id).then((response)=> {
        this.pay_run_list = response.data;
      });
    },
  }
};
</script>
<style scoped>
#pay_runs /deep/ .el-divider--horizontal {
  margin: 0 0 10px 0;
}
</style>
