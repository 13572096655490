<template>
  <div id="payroll-page">
    <el-tabs
      type="border-card"
    >
      <el-tab-pane
        label="Draft Pay Run"
        :lazy="true"
      >
        <draft-pay-periods
          ref="draftPayPeriod"
          @hook:mounted="set_default_input"
          @reload_draft_pay_transactions="reload_draft_pay_transactions"
        />
      </el-tab-pane>
      <el-tab-pane
        label="Transactions"
      >
        <pay-run-transactions
          ref="payRunTransactions"
          @hook:mounted="set_default_input"
        />
      </el-tab-pane>
      <el-tab-pane
        label="Pay Cycles"
        :lazy="true"
      >
        <pay-cycles
          @hook:mounted="set_default_input"
          @reload_employees="reload_employees"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import PayCycles from '../payroll/PayCycles.vue';
import DraftPayPeriods from './DraftPayPeriods.vue';
import PayRunTransactions from './PayRunTransactions.vue';
import {set_default_input} from '../../utilities/DefaultInput';
export default {
  name: 'PayrollPage',
  components: {DraftPayPeriods, PayCycles, PayRunTransactions },
  props: {},
  data() {
    return {};
  },
  watch: {
  },
  mounted() {
  },
  created() {
  },
  methods: {
    set_default_input,
    reload_draft_pay_transactions(){
      // @event when pay employees
      this.$refs.payRunTransactions.pay_run_transaction_monthly(false);
    },
    reload_employees(){
      // @event when on Pay cycles remove or add a new employee
      this.$refs.draftPayPeriod.get_pay_cycle_dates();
      // this.$refs.draftPayPeriod.calculate_employees_pay();
    },
  }
};
</script>
<style scoped>

</style>
