<template>
  <el-card>
    <el-collapse>
      <el-collapse-item title="Partial Invoice">
        <div v-loading="is_loading">
          <div>
            <div class="invoice-date-container">
              <label>Invoice Date</label>
              <el-date-picker
                v-model="invoice_date"
                class="wf-sale-date-picker"
                timezone="UTC+12:00"
                placeholder="Select invoice date"
                size="mini"
              />
            </div>

            <el-table
              :data="table_data"
              style="width: 100%"
              :span-method="row_span_method"
              show-summary
              :summary-method="get_summaries"
              stripe
            >
              <el-table-column
                prop="category"
                label="Category"
                fixed
                min-width="150"
                header-align="center"
              >
                <template slot-scope="scope">
                  <b>{{ scope.row.category }}</b>
                </template>
              </el-table-column>

              <el-table-column
                v-if="is_editable"
                align="center"
                label="Used Qty"
                fixed
                min-width="150"
                header-align="center"
              >
                <template slot-scope="scope">
                  <el-input-number
                    v-model="scope.row.used_qty"
                    class="wf-sale-qty-input"
                    size="mini"
                    controls-position="right"
                  />
                </template>
              </el-table-column>
              <el-table-column
                prop="name"
                label="File name"
                fit="true"
                fixed
                header-align="center"
              />
              <el-table-column
                prop="qty"
                label="Quantity"
                fit="true"
                fixed
                header-align="center"
              >
                <template slot-scope="scope">
                  {{ scope.row.qty }}
                </template>
              </el-table-column>
              <el-table-column
                prop="unit_price"
                label="Unit Price"
                fixed
                :formatter="currency_formatter"
                header-align="center"
              />
              <el-table-column
                v-for="month in formatted_months"
                :key="month.qty_key"
                :label="month.label"
                header-align="center"
                class-name="wf-sale-month"
              >
                <el-table-column
                  label="Qty"
                  fit="true"
                  align="center"
                  :prop="month.qty_key"
                  class-name="wf-sale-month"
                />
                <el-table-column
                  label="Total"
                  fit="true"
                  align="center"
                  class-name="wf-sale-month"
                  :prop="month.total_price_key"
                  :formatter="currency_formatter"
                />
              </el-table-column>
              <el-table-column
                prop="total_sale_price"
                label="Total"
                fixed="right"
                align="center"
                :formatter="currency_formatter"
              />
              <el-table-column
                prop="total_claimed"
                label="Claimed"
                :formatter="currency_formatter"
                align="center"
                fixed="right"
              />
              <el-table-column
                label="Claimed (%)"
                fixed="right"
                align="center"
              >
                <template slot-scope="scope">
                  {{ scope.row.claimed_percentage }} %
                </template>
              </el-table-column>
              <el-table-column
                prop="total_unclaimed"
                label="Remaining"
                fixed="right"
                align="center"
                :formatter="currency_formatter"
              />
            </el-table>
            <el-button
              v-if="is_editable"
              size="mini"
              type="primary"
              class="wf-sale-create-invoice-button"
              @click="create_sale"
            >
              Create Invoice
            </el-button>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </el-card>
</template>

<script>
import { sale_store } from 'store/modules/sale_store';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'SaleWorkingFilesSummary',
  store: sale_store,
  props: {
    sale_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      working_files_summary: {
        categories: [],
        months: [],
      },
      invoice_date: new Date(),
      is_loading: false,
      is_editable: true,
      table_data: [],
    };
  },
  computed: {
    ...mapGetters(['get_parent_sale']),
    parent_sale: {
      get() {
        return this.get_parent_sale;
      },
      set(value) {
        this.set_parent_sale(value);
      },
    },
    formatted_months() {
      return this.working_files_summary.months.map((month) => {
        let slug = this.format_month(month);
        return {
          qty_key: `${slug}_qty`,
          total_price_key: `${slug}_total_price`,
          label: month,
        };
      });
    }
  },
  watch: {
    'parent_sale.invoice_based_wf': function (newVal) {
      if (newVal === true) {
        this.load_working_files_summary();
      }
    },
  },
  mounted() {
    if (this.parent_sale.invoice_based_wf == true) {
      this.load_working_files_summary();
    }
  },
  methods: {
    ...mapActions(['set_current_sale_key', 'add_sale']),
    format_month(month) {
      return month.replaceAll(' ', '_').toLowerCase();
    },
    async load_working_files_summary() {

      this.is_loading = true;

      try
      {
        const response = await this.$http.get(`/api/sales/${this.sale_id}/working_files_summary`);
        this.working_files_summary = response.data;
        this.table_data = this.transform_data(response.data);
        this.set_inputs_to_default();
      }
      catch (error) {
        this.$message({ type: 'error', message: 'Failed to load working files summary.' });
        console.log(error);
      }
      finally
      {
        this.is_loading = false;
      }
    },
    set_inputs_to_default() {
      setTimeout(() => {
        Array.from(
          document.querySelectorAll('.el-input :not(.browser-default')
        ).forEach(function (e) {
          e.classList.add('browser-default');
          if (e.querySelector('input') != null)
            e.querySelector('input').className += ' browser-default';
        });
      }, 500);
    },
    async create_sale() {
      // Get working files with use_qty only greater than 0
      let sale_working_files = [];

      this.table_data.forEach((row) => {
        if (row['used_qty'] > 0 || row['used_qty'] < 0) {
          sale_working_files.push({
            working_file_id: row['id'],
            qty: row['used_qty'],
          });
        }
      });

      if (sale_working_files.length == 0) {
        this.$message({
          type: 'warning',
          message: 'No used quantity filled in any working file.',
        });
        return false;
      }

      let create_request = this.$http.post(`/api/sales/${this.sale_id}/sales`, {
        sale: {
          sale_id: this.sale_id,
          sale_working_files: sale_working_files,
          invoice_date: this.invoice_date.toLocaleDateString(),
        },
      });

      create_request.then(
        (response) => {
          let wf_sale = response.body.wf_sale;
          this.add_sale({
            key: wf_sale.id,
            sale: wf_sale,
          });
          this.set_current_sale_key(wf_sale.id);
          this.load_working_files_summary();
          this.$message({
            message: response.body.message,
            type: 'success',
          });
        },
        (error) => {
          this.$message({
            type: 'error',
            message: error.body.message,
          });
        }
      );
    },
    transform_data(data) {
      let result = [];
      data.categories.forEach((category) => {
        category.working_files.forEach((file) => {
          let months_cols = {};

          // Can be improved later
          file.working_files_summary.forEach((summary) => {
            let formatted_month = this.format_month(summary.month);
            let qty = summary.qty;
            let total_price = file.unit_price * qty;
            months_cols[`${formatted_month}_qty`] = qty;
            months_cols[`${formatted_month}_total_price`] = total_price;
          });
          result.push({
            category: category.name,
            used_qty: 0,
            ...months_cols,
            ...file,
          });
        });
      });
      return result;
    },
    row_span_method({ row, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        const category = row.category;
        const rows = this.table_data.filter(
          (item) => item.category === category
        );
        if (
          rowIndex ===
          this.table_data.findIndex((item) => item.category === category)
        ) {
          return { rowspan: rows.length, colspan: 1 };
        } else {
          return { rowspan: 0, colspan: 0 };
        }
      }
    },
    currency_formatter(row, column, cellValue) {
      if (typeof cellValue === 'number') {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(cellValue);
      }
      return cellValue;
    },
    get_summaries(param) {

      const { columns, data } = param;
      const sums = [];

      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Total';
          return;
        }

        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          // Check the custom 'sum' property
          if (column.property.toLowerCase().indexOf('total') !== -1) {
            sums[index] = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(
              values.reduce(
                (prev, curr) =>
                  (isNaN(prev) ? 0 : prev) + (isNaN(curr) ? 0 : curr),
                0
              )
            );
          } else {
            sums[index] = '';
          }
        } else {
          sums[index] = '';
        }
      });
      return sums;
    },
    header_style_class_name() {
      return 'wf-sale-month';
    },
  },
};
</script>

<style scoped>
/* Component-specific styles can be added here */
</style>

<style>
.wf-sale-qty-input {
  width: 100px !important;
}

.wf-sale-date-picker {
  width: 150px !important;
}

.invoice-date-container {
  margin: 10px;
}

.wf-sale-month {
  text-align: center;
  background-color: #2196f340 !important;
}

.wf-sale-total-cols {
  background-color: #e9faff;
}

.wf-month-start {
  border-left: 2px solid #9d9d9d;
}

.wf-month-end {
  border-right: 2px solid #9d9d9d;
}

.wf-header {
  border-bottom: 1px solid gray;
}

.wf-footer {
  border-top: 1px solid gray;
  font-weight: bold;
}

.wf-month-header {
  border-bottom: 1px solid gray;
  border-top: 1px solid gray;
}

.wf-category-header {
  background-color: #f0f0f0;
  border-bottom: 1px solid gray;
}

.wf-sale-create-invoice-button {
  margin: 10px !important;
}
</style>
