<template>
  <div style="padding:10px; border-top:2px solid #f3f3f3;">  
    <el-row>
      <el-col :xs="24" :sm="24" :md="24" :lg="3" style="padding:10px">
        <span style="color: rgb(70, 205, 52) !important; font-weight: bold;">TASKS LIST</span>
      </el-col>
    </el-row>
    <div style="overflow:auto">
    <table width="100%" class="items_list_summary">
      <thead>
        <tr>
          <td style="width: 28%;">Name</td>
          <td v-show="proposal.approved == 1 || proposal.approved == true">
            Billable
          </td>
          <td>
            Users count
            <el-tooltip class="item" effect="dark" content="Number of users to work on this task." placement="top-start">
              <i class="el-icon-info"></i>
            </el-tooltip>
          </td>
          <td>
            Branch specific?
            <el-tooltip class="item" effect="dark" content="Only users in current proposal branch will be assigned." placement="top-start">
              <i class="el-icon-info" />
            </el-tooltip>
          </td>
          <td>
            Splittable?
            <!-- # TODO: it is commented out temporary: https://james-yang053.atlassian.net/browse/AN-341, It should done in https://james-yang053.atlassian.net/browse/AN-350 -->
            (Not Available)
            <el-tooltip class="item" effect="dark" content="Task can be splitted into seperate small tasks." placement="top-start">
              <i class="el-icon-info" />
            </el-tooltip>
          </td>
          <td> Is Urgent </td>
          <td v-show="proposal.quote_type_id == 5">
            Is initiated
          </td>
          <td>Quantity</td>
          <th v-show="proposal.approved == 1 || proposal.approved == true">
            Time allocated adjusted
          </th>
          <td>Overwrite labour</td>
          <td>Unit Cost</td>
          <td>Unit Sale price</td>
          <td>Total Cost</td>
          <td>Total Sale Price</td>
          <td>Action</td>
        </tr>
      </thead>
      <draggable tag="tbody" class="list-group" :group="working_file_info.id" handle=".handle" :data-list-id="working_file_info.id" v-bind="dragOptions" @start="drag = true" @end="drag = false" @sort="onSort">
        <JobProposalEntry v-for="(job, idx) in working_file_info.job_proposals_list.sort((a, b) => a.position - b.position )"
                          :key="job.id"
                          :job-idx="idx"
                          :job-id="job.id"
                          :job="job"
                          :sortable="true"
                          :crm_users_list="crm_users_list"
                          :style="[
                            job.mark_as_deleted == 1 ?
                              {
                                'text-decoration': 'line-through',
                                'font-style:': 'italic',
                              }: {}
                          ]"
        />
      </draggable>
      <NewJobToSummaryEdit :working_file_id="working_file_info.id" />
      <NewJobToSummaryEdit
        :working_file_id="working_file_info.id"
        :is_custom="true"
        :crm_users_list="crm_users_list"
      />
      <tfoot>
        <tr>
          <td>Tasks Total</td>
          <td />
          <td />
          <td />
          <td />
          <td v-show="proposal.approved == 1 || proposal.approved == true" />
          <td v-show="proposal.approved == 1 || proposal.approved == true" />
          <td v-show="proposal.quote_type_id == 5" />
          <td />
          <td />
          <td />
          <td />
          <td> ${{ job_proposals_total_cost }}</td>
          <td>${{ job_proposals_total_sale }}</td>
          <td />
        </tr>

      </tfoot>
    </table>
  </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapGetters } from 'vuex';
import {EventBus} from '../../../../utilities/EventBus';
import {roundVal} from '../../../../utilities/Round';

import { editor_store } from 'store/modules/editor';
import JobProposalEntry from '../../../job_proposals/JobProposalEntry';
import NewJobToSummaryEdit from './NewJobToSummaryEdit';

export default {
  name: 'TaskListEdit',
  store: editor_store,
  components: {
    NewJobToSummaryEdit,
    JobProposalEntry,
    draggable
  },
  props: {
    working_file_info: {
      type: Object,
      required: true
    },
    job_proposals_total_cost:  {
      type: Number,
      required: true
    },
    job_proposals_total_sale:  {
      type: Number,
      required: true
    },
    total_mintues_caclculated: {
      type: Number,
      required: true
    },
    total_hours_caclculated: {
      type: String,
      required: true
    },
    sale_price_per_unit: {
      type: String,
      required: true
    },
    total_cost_price_calculated: {
      type: String,
      required: true
    },
    total_sales_price_calculated: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      taskaddarea: false,
      matched_quote: 0,
      crm_users_list: []
    };
  },
  computed: {
    ...mapGetters([
      'proposal',
      'job_proposals_list',
      'files_list',
    ]),
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    }
  },
  created() {
    this.$http.get('/users_active_list').then((response) => {
      this.crm_users_list = response.data;
    });
  },
  watch:{
    'working_file_info.matched_quote'(newVal){
      this.matched_quote = newVal;
    },
  },
  mounted() {
    this.matched_quote = this.working_file_info.matched_quote;
  },
  methods: {
    onSort(ev) {
      let updated_list = Array.from(ev.target.children).map((el, idx) => {
        return { id: el.getAttribute('job-id'), position: idx };
      });

      this.$http.post('/update_jp_positions_list', { jps: updated_list }).then(
        () => {
          this.$message({ type: 'success', message: 'Task has been sorted successfully.' });
          updated_list.forEach((el, idx) => {
            let record = this.working_file_info.job_proposals_list.filter((c) => c.id == el.id)[0];
            if (record != undefined) record['position'] = idx;
          });
        }
      );
    },
    update_matched_quote() {
      let matched_quote = Number(this.matched_quote)
      if(isNaN(matched_quote) === true ||matched_quote < 0 ) {
        this.$message({
          message: 'Matched Quoted is not valid',
          type: 'error',
        });
        return;
      }

      matched_quote = roundVal(matched_quote);
      this.matched_quote = matched_quote;

      let params = {
        matched_quote: matched_quote,
      };

      this.$http.patch(`/update_working_file_details/${this.working_file_info.id}`, {
        file_details: params,
      }).then((response) => {
        this.working_file_info.matched_quote = this.matched_quote;
        let file_info = this.files_list.filter((c) => c.id == this.working_file_info.id)[0];
        file_info.matched_quote = this.matched_quote;
        this.$message({
          type: 'success',
          message: 'Matched Quote have been saved successfully.',
        });
        EventBus.$emit('reloadCategoryWorkingFiles');
      },
      (response) => {
        this.$message({
          type: 'error',
          message: 'Error happnened while saving details.',
        });
      });
    }
  }
};

</script>

<style scoped>

</style>