<template>
  <div id="crm_user_roles">
    <span>Roles/Group</span>
    <el-divider />
    <span><span style="color: red;">* </span>Select a default role:</span>
    <br>
    <el-select
      v-model="default_group"
      filterable
      placeholder="Select default group"
      size="small"
      style="width: 280px;"
      @change="set_default_group($event)"
    >
      <el-option
        v-for="role in roles"
        :key="role.id"
        :label="role.name"
        :value="role.id"
      />
    </el-select>
    <br>
    <el-divider />
    <el-row
      v-for="role in roles"
      :key="role.id"
    >
      <el-checkbox
        ref="check"
        label="index"
        size="mini"
        :checked="role.checked"
        @change="save_role(role.id,$event)"
      >
        {{ role.name }}
      </el-checkbox>
    </el-row>
  </div>
</template>

<script>

export default {
  name: 'CrmUserRoles',
  props: {
    crm_user_id: {
      type: [String,Number],
      required: true
    }
  },
  data() {
    return {
      roles: [],
      default_group: ''
    };
  },
  watch:{
    crm_user_id(){
      this.roles = [];
      this.get_roles();
      this.get_current_role();
    }
  },
  mounted() {
    this.get_roles();
    this.get_current_role();
  },
  created() {
  },
  methods: {
    get_roles() {
      this.$http.get(`/get_crm_user_roles/${this.crm_user_id}`).then((response) => {
        this.roles = response.data;
      });
    },
    save_role(role_id, checked) {

      let params = {
        crm_user_id: this.crm_user_id,
        role_id: role_id,
        checked: checked
      };

      this.$http.post('/update_crm_user_roles', params).then(
        () => {
          this.$message({type: 'success', message: 'Saved successfully'});
          this.roles = [];
          this.get_roles();
        },
        () => {
          this.$message({type: 'warning', message: 'Removed successfully'});
        }
      );
    },
    get_current_role(){
      this.$http.get(`/current_crm_user_role/${this.crm_user_id}`).then((response) => {
        this.default_group = response.data;
      });
    },
    set_default_group(role_id){
      this.$http.patch(`/crm_users/${this.crm_user_id}`, {role_id: role_id}).then(() => {
        this.$message({type: 'success', message: 'Role/Group saved successfully'});
        this.save_role(role_id, true);
      });
    }

  }
};
</script>
<style scoped>
#crm_user_roles /deep/ .el-divider--horizontal {
  margin: 10px 0 10px 0;
}

</style>
