<template>
  <div>
    <h3 class="sales__title">
      TASKS LIST
    </h3>
    <hr>
    <div style="overflow:auto">
    <table class="sales_items_list">
      <thead>
        <tr>
          <th class="name">
            Name
          </th>
          <th class="quote" />
          <th>Time Allocated</th>
          <th>Time Spent</th>
          <th>Unit cost</th>
          <th>Minutes Rate(Sale)</th>
          <th>Total Sale Price</th>
          <th>Total Cost</th>
          <th>Net Profit</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(task,index) in items"
          :key="index"
          :class="mark_deleted(task.mark_as_deleted)"
        >
          <td>{{ task.name }}</td>
          <td>
            <el-tag
                class="border__zero"
                size="mini"
                :color="quote_style(task.quote_type)"
                effect="dark">
              {{ task.quote_type }}
            </el-tag>
          </td>
          <td><Time :is_hours="task.is_hours" :minutes="task.time_allocated" /></td>
          <td><Time :is_hours="task.is_hours" :minutes="task.time_spent" /></td>
          <td><Money :amount="task.unit_cost" /></td>
          <td><Money :amount="task.minutes_rate" /></td>
          <td><Money :amount="task.total_sales_price" /></td>
          <td><Money :amount="task.total_sales_cost" /></td>
          <td><Money :amount="task.net_profit" /></td>
          <td>{{ task.status }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="6" />
          <td><Money :amount="sum_total_sales_price" /></td>
          <td><Money :amount="sum_total_sales_cost" /></td>
          <td><Money :amount="sum_net_profit" /></td>
          <td />
        </tr>
      </tfoot>
    </table>
  </div>
  </div>
</template>

<script>
import SalesMixin from 'mixins/SalesMixin';
import Time from '../../shared/Time';
export default {
  name: 'TaskItemsList',
  components: { Time },
  mixins: [SalesMixin],
  computed: {
    sum_variation_price() {
      return this.items.reduce((acc, item) =>
        item.mark_as_deleted == 0 ? acc + item.variation_price : acc, 0);
    }
  }
};
</script>
