<template>
  <div>
    <div class="card  ">
      <div class="card-content ">
        <span class="card-title">
          Billable vs Free
        </span>
        <el-date-picker
          v-model="selected_date_range"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          style="width: auto !important;"
          :picker-options="pickerOptions2"
          @change="fill_data"
        />
        <br>
        <br>
        <div class="row">
          <div class="col m6">
            <table>
              <tr class="header">
                <th
                  colspan="3"
                  class="center"
                >
                  PENDING
                </th>
              </tr>
              <tr class="subheader">
                <th>FREE</th>
                <th>BILLABLE</th>
                <th>TOTAL</th>
              </tr>
              <tr class="rows">
                <td><span class="center">{{ pending_free }} {{ pending_free_percentage }}</span></td>
                <td>
                  <span class="center">{{ pending_billable }} {{ pending_billable_percentage }}</span>
                </td>
                <td><span class="center">{{ pending_free + pending_billable }}</span></td>
              </tr>
            </table>
            <div
              style="max-width: 290px"
              class="center"
            >
              <pie-chart
                :chart-data="pending_jobs_data"
                :options="chartOptions"
              />
            </div>
          </div>
          <div class="col m6">
            <table>
              <tr class="header">
                <th
                  colspan="3"
                  class="center"
                >
                  COMPLETED
                </th>
              </tr>
              <tr class="subheader">
                <th>FREE</th>
                <th>BILLABLE</th>
                <th>TOTAL</th>
              </tr>
              <tr class="rows">
                <td><span class="center">{{ completed_free }} {{ completed_free_percentage }}</span></td>
                <td>
                  <span
                    class="center"
                  >{{ completed_billable }} {{ completed_billable_percentage }}</span>
                </td>
                <td><span class="center">{{ completed_free + completed_billable }}</span></td>
              </tr>
            </table>
            <div
              style="max-width: 290px"
              class="center"
            >
              <pie-chart
                :chart-data="completed_jobs_data"
                :options="chartOptions"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="card-action">
        <button
          class="btn btn-small action-buttons"
          @click="show_records_list(0,0)"
        >
          Pending free
        </button>
        <button
          class="btn btn-small action-buttons"
          @click="show_records_list(0,1)"
        >
          Pending billable
        </button>
        <button
          class="btn btn-small action-buttons"
          @click="show_records_list(1,0)"
        >
          Completed free
        </button>
        <button
          class="btn btn-small action-buttons"
          @click="show_records_list(1,1)"
        >
          Completed billable
        </button>
      </div>
    </div>
    <modal
      name="work_records_list"
      :height="700"
      :width="1200"
      :adaptive="true"
    >
      <div class="card ">
        <div class="card-content  ">
          <span class="card-title">{{ list_title }}<hr></span>
          <div
            v-show="is_loading"
            class="progress"
          >
            <div class="indeterminate" />
          </div>
          <el-table
            :data="rows"
            style="width: 100%"
            height="600"
          >
            <el-table-column
              v-for="column in columns"
              :key="column"
              :prop="column"
              :label="column | capitalize"
              sortable
            />
          </el-table>
        </div>
        <div class="card-action" />
      </div>
    </modal>
  </div>
</template>
<script>


import PieChart from './PieChart';


export default {
  name: 'WorkDashboard',
  components: {
    PieChart
  },
  data() {
    return {
      pending_jobs_data: null,
      completed_jobs_data: null,
      completed_free: 0,
      completed_billable: 0,
      completed_total: 0,
      pending_free: 0,
      pending_billable: 0,
      pending_total: 0,
      columns: [],
      rows: [],
      list_title: '',
      is_loading: false,
      pickerOptions2: {
        shortcuts: [{
          text: 'Current month',
          onClick(picker) {
            const end = new Date();
            const start = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last week',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        },
        {
          text: 'Last month',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last 3 months',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last 6 months',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last year',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      selected_date_range: ''
    };
  },
  computed: {
    current_date() {
      return new Date();
    },
    start_date() {
      return new Date(this.current_date.getFullYear(), this.current_date.getMonth(), 1);
    },
    end_date() {
      return new Date(this.current_date.getFullYear(), this.current_date.getMonth(), this.current_date.getDate());
    },
    gross_profit() {
      return this.total_sales - this.total_cost;
    },
    completed_free_percentage() {
      return this.completed_total == 0 ? '' : ' (' + Math.round((this.completed_free / this.completed_total) * 100) + '%)';
    },
    completed_billable_percentage() {
      return this.completed_total == 0 ? '' : ' (' + Math.round((this.completed_billable / this.completed_total) * 100) + '%)';
    },
    pending_free_percentage() {
      return this.pending_total == 0 ? '' : ' (' + Math.round((this.pending_free / this.pending_total) * 100) + '%)';
    },
    pending_billable_percentage() {
      return this.pending_total == 0 ? '' : ' (' + Math.round((this.pending_billable / this.pending_total) * 100) + '%)';
    }

  },
  mounted() {
    this.selected_date_range = [this.start_date, this.end_date];
    this.fill_data();
  },
  created() {

  },
  methods: {

    fill_data() {
      this.$http.post('/get_work_info', {
        start_date: this.selected_date_range[0],
        end_date: this.selected_date_range[1]
      }).then(response => {
        if (response.data.status == 200) {
          let pending_billable_categorized = response.data.data.pending_billable_categorized;
          let pending_free_categorized = response.data.data.pending_free_categorized;
          let pending_labels = pending_billable_categorized.categories_names.concat(pending_free_categorized.categories_names);
          let pending_bg_colors = Array(pending_billable_categorized.total.length).fill('lightgreen').concat(Array(pending_free_categorized.total.length).fill('#f38686'));
          let pending_categories = pending_billable_categorized.total.concat(pending_free_categorized.total);

          let completed_billable_categorized = response.data.data.completed_billable_categorized;
          let completed_free_categorized = response.data.data.completed_free_categorized;
          let completed_labels = completed_billable_categorized.categories_names.concat(completed_free_categorized.categories_names);
          let completed_bg_colors = Array(completed_billable_categorized.total.length).fill('lightgreen').concat(Array(completed_free_categorized.total.length).fill('#f38686'));
          let completed_categories = completed_billable_categorized.total.concat(completed_free_categorized.total);

          this.pending_free = response.data.data.pending_free;
          this.pending_billable = response.data.data.pending_billable;
          this.completed_free = response.data.data.completed_free;
          this.completed_billable = response.data.data.completed_billable;

          this.pending_total = response.data.data.pending_billable + response.data.data.pending_free;
          this.completed_total = response.data.data.completed_free + response.data.data.completed_billable;

          this.pending_jobs_data = {
            labels: pending_labels,
            datasets:
                                    [
                                      {
                                        label: 'Pending',
                                        backgroundColor: pending_bg_colors,
                                        data: pending_categories
                                      }
                                    ]
          };

          this.completed_jobs_data = {
            labels: completed_labels,
            datasets:
                                    [
                                      {
                                        label: 'Completed',
                                        backgroundColor: completed_bg_colors,
                                        data: completed_categories
                                      }
                                    ]
          };
        }
      }
      );

    },
    open_modal() {

      this.$modal.show('work_records_list');
    },
    hide_modal() {
      this.$modal.hide('work_records_list');
    },
    chartOptions: function () {
      return {responsive: true, maintainAspectRatio: false};
    },
    show_records_list(completed, billable) {
      this.open_modal();
      this.columns = [];
      this.rows = [];
      this.list_title = (completed == 1 ? 'Completed' : 'Pending') + ' ' + (billable == 1 ? 'billable' : 'free') + ' tasks';
      this.is_loading = true;
      this.$http.post('/get_job_proposals_items', {
        start_date: this.selected_date_range[0],
        end_date: this.selected_date_range[1],
        completed: completed,
        billable: billable
      }).then(response => {
        this.is_loading = false;
        if (response.data.status == 200) {
          this.columns = response.data.data.columns;
          this.rows = response.data.data.rows;
        }
      });
    }

  },

};
</script>

<style scoped>
    td, th {
        text-align: center;
        padding: 10px;
    }

    th {
        font-weight: 800;
    }

    .header {
        background-color: #ddecf9;
    }

    .subheader {
        background-color: #e5f3ff;
    }

    .rows {
        background-color: aliceblue;
    }

    .action-buttons {
        font-size: 13px;
    }
</style>

