<template>
  <div>
    <el-collapse>
      <el-collapse-item
        v-for="note in task_notes"
        :key="note.id"
        v-model="task_notes"
        :title="note.name"
        :name="note.id"
      >
        <template slot="title">
          <span>
            {{ note.name }}
            <i
              v-show="note.alternative_description && note.alternative_description.length > 0"
              class="el-icon-chat-line-round"
            />
          </span>
        </template>
        <div>
          <el-row>
            <el-col>
              <div
                class="margin-left"
                v-html="note.alternative_description_truncated"
              />
              <el-button
                type="primary"
                size="mini"
                class="right"
                @click="show_task_notes_dialog = true, current_note.note = note.alternative_description, current_note.id = note.id"
              >
                Edit / Read
              </el-button>
            </el-col>
          </el-row>
        </div>
        <div>
          <el-dialog
            title="Edit Task Note"
            :visible.sync="show_task_notes_dialog"
            width="50%"
            append-to-body
            :close-on-click-modal="false"
          >
            <jodit-editor
              id="task_notes_editor"
              ref="task_notes_editor"
              v-model="current_note.note"
              :config="jodit_config"
            />
            <span
              slot="footer"
              class="dialog-footer"
            >
              <el-button
                type="success"
                size="mini"
                @click="edit_task_notes(), show_task_notes_dialog = false"
              >
                Save
              </el-button>
            </span>
          </el-dialog>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>

import {update_alternative_descriptions, alternative_descriptions} from '../../api/job_proposals.js';


export default {
  name: 'TaskNotes',
  props: {
    proposal_id: {
      type: [String,Number],
      required: true
    }
  },
  data() {
    return {
      task_notes: [],
      show_task_notes_dialog: false,
      current_note: {id: '', note: '', note_truncated: ''},
      jodit_config : {
        enter: 'br',
      },
    };
  },
  created() {
    this.get_task_notes();
  },
  methods: {
    get_task_notes() {
      alternative_descriptions(this, this.proposal_id).then((response) => {
        this.task_notes = response.data;
      });
    },
    edit_task_notes() {
      update_alternative_descriptions(this, this.current_note.id, this.current_note.note).then((response) => {
        this.$message({
          message: response.data.message,
          type: 'success',
        });
        this.get_task_notes();
      });
    }
  }
};
</script>
<style scoped>
.right{float:right;}
.margin-left{ margin-left: 10px;}
</style>