<template>
  <div>
    <div v-if="is_read_only === false">
      <el-select
        v-model="selected_user_id"
        placeholder="Select user"
        size="mini"
        class="select-opt"
      >
        <el-option
          v-for="mainuser in crm_users_list"
          :key="mainuser.id"
          :label="mainuser.user_name"
          :value="mainuser.id"
        />
      </el-select>
      <el-button
        size="mini"
        type="info"
        icon="el-icon-plus"
        class="add-btn"
        @click="onAddUser"
      />
    </div>
    <div
      v-show="selected_users_list.length > 0"
      class="wrapper-user_list"
    >
      <el-tag
        v-for="user in selected_users_list"
        :key="user.id"
        effect="dark"
        :closable="!is_read_only"
        :type="user.inactive == 0 ? 'info' : 'danger'"
        size="mini"
        class="user_list"
        @close="onRemoveUser(user)"
      >
        {{ user.user_name }}
        <span v-if="user.inactive == 1">(in-active)</span>
      </el-tag>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UsersCustomJob',
  props: {
    crm_users_list: {
      type: Array,
      required: true
    },
    already_selected_users: {
      type: Array,
      default: () => []
    },
    is_read_only: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selected_user_id: null,
      selected_users_list: [...this.already_selected_users]
    };
  },
  methods: {
    onAddUser() {
      if(this.selected_user_id === null) return;
      if(this.selected_users_list.find(user => user.id === this.selected_user_id)) return;

      let selected_users = this.crm_users_list.find(user => user.id === this.selected_user_id);
      selected_users.inactive = 0;
      this.selected_users_list.push(selected_users);
      this.$emit('input', this.selected_users_list.map(user => user.id));
    },
    onRemoveUser(user) {
      this.selected_users_list.splice(this.selected_users_list.indexOf(user), 1);
      this.$emit('input', this.selected_users_list.map(user => user.id));
    }
  }
};
</script>

<style scoped>
.wrapper-user_list {
  margin-top: 10px;
}

.select-opt {
  width: 75%;
}

.add-btn {
  width: 20%;
  padding-left:7px;padding-right:7px
}

.user_list {
  margin:2px;
}
</style>