<template>
  <div>
    <p>Import Master Payments : csv file - Date, Payment, Account No</p>
    <p>Example:</p>
    <div class="example">
      <div>09/02/2022,50,ACC36407</div>
      <div>10/02/2022,50,ACC36407</div>
    </div>
    <div>
      <p>Here is an example of a csv file which can be open in excel or text editor.</p>
      <a href="/example_import_master_payment.csv">
        <el-button size="mini">
          Download example csv file
        </el-button>
      </a>
    </div>
    <br>
    <el-upload
      ref="upload"
      action="/api/master_payments/upload"
      :headers="{ 'X-CSRF-TOKEN': csfr }"
      :on-change="onChangeUpload"
      :on-success="onSuccessUpload"
      :on-remove="onRemoveUpload"
      :on-error="onErrorUpload"
      :before-upload="onBeforeUpload"
      :file-list="fileList"
      :limit="2"
      :auto-upload="false"
    >
      <i class="el-icon-upload" />
      <div class="el-upload__text">
        <b><em>click to upload csv file</em></b>
      </div>
    </el-upload>
    <br>
    <div v-if="show_payment_btns">
      <el-button
        type="primary"
        size="small"
        @click="onCalculate"
      >
        Calculate Payments
      </el-button>
      <el-button
        style="margin-left: 10px;"
        size="small"
        type="success"
        @click="onMakePayments"
      >
        Calculate and Make Payments
      </el-button>
    </div>
    <MasterPaymentsResult
      v-if="master_payments.length > 0"
      :master_payments="master_payments"
      :errors="errors"
      :payment_result="payment_result"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import MasterPaymentsResult from './MasterPaymentsResult';
import {roundVal} from '../../utilities/Round';
import {calculateMasterPayments, importMasterPayments} from '../../api/master_payment';
import {defaultInput} from '../../utilities/DefaultInput';

export default {
  name: 'ImportMasterPaymentsWorking',
  components: {MasterPaymentsResult},
  data() {
    return {
      fileList: [],
      csfr: Vue.http.headers.common['X-CSRF-Token'],
      master_payments: [],
      errors: false,
      show_payment_btns: false,
      payment_result: false
    };
  },
  methods: {
    onChangeUpload() {
      this.fileList = this.$refs.upload.uploadFiles;
      if(this.fileList.length === 2)
        this.fileList.splice(0, 1);

      this.$refs.upload.submit();
    },
    onRemoveUpload() {
      this.master_payments = [];
      this.show_payment_btns = false;
      this.payment_result = false;
    },
    onBeforeUpload() {
      this.master_payments = [];
      this.show_payment_btns = true;
      this.payment_result = false;
      this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
    },
    onSuccessUpload(response) {
      this.success_calculate(response);
      this.$loading().close();
    },
    onErrorUpload(err) {
      this.$loading().close();
      let error_obj = JSON.parse(err.message);
      this.master_payments = error_obj.error_mp_payments;
      this.errors = true;

      this.$message({
        type: 'error',
        message: error_obj.message
      });

      this.$nextTick(function () {
        defaultInput();
      });
    },
    onMakePayments() {
      let master_payments = [];
      this.master_payments.forEach(master_payment=>{
        master_payments.push({
          date: master_payment.date,
          payment: master_payment.payment,
          account_number: master_payment.account_number
        });
      });
      this.master_payments = [];
      this.errors = [];
      this.payment_result = true;

      importMasterPayments(this, master_payments)
        .then((response)=> {
          this.fileList = [];
          this.show_payment_btns = false;
          this.success_calculate(response.body.master_payments);
          this.$message({
            message: response.body.message,
            type: 'success',
          })
        })
        .catch(error=>{
          this.error_calculate(error.body.error_mp_payments);
        });
    },
    onCalculate() {
      let master_payments = [];
      this.master_payments.forEach(master_payment=>{
        master_payments.push({
          date: master_payment.date,
          payment: master_payment.payment,
          account_number: master_payment.account_number
        });
      });
      this.master_payments = [];
      this.errors = [];

      calculateMasterPayments(this, master_payments)
        .then((response)=>{
          this.success_calculate(response.body);
        })
        .catch((error)=>{
          this.error_calculate(error.body.error_mp_payments);
        });
    },
    success_calculate(data){
      this.errors = false;
      this.master_payments = data;
      this.calculate_payments();
      this.$nextTick(function () {
        defaultInput();
      });
    },
    error_calculate(data){
      this.errors = true;
      this.master_payments = data;
      this.$nextTick(function () {
        defaultInput();
      });
    },
    calculate_payments() {
      this.master_payments.forEach((master_payment, mp_index)=> {
        master_payment.payments.forEach((payment)=>{
          let total_amount = roundVal(parseFloat(payment.amount)
              + parseFloat(payment.paid_amounts)
              + this.pre_total_amount(mp_index, payment.invoice_id));

          payment.accumulate_amount = total_amount;
          payment.left = roundVal(parseFloat(payment.total_price) - total_amount);
        });
      });
    },
    pre_total_amount(cur_mp_index, invoice_id) {
      let pre_total_amount = 0;
      for(let index = 0; index < cur_mp_index; index++) {
        this.master_payments[index].payments.forEach((payment)=>{
          if(payment.invoice_id == invoice_id)
            pre_total_amount = roundVal(pre_total_amount + payment.amount);
        });
      }
      return pre_total_amount;
    },
  }
};
</script>

<style lang='scss'>
.example {
  padding-left: 1rem;
}
</style>
