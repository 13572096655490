<template>
  <el-dialog
    title="PENDING REQUESTS"
    :visible.sync="users_requests_visible"
    width="80%"
    :lock-scroll="true"
    :show-close="true"
  >
    <el-table
      :data="pending_requests"
      :default-sort="{ prop: 'created_at', order: 'descending' }"
      style="width: 100%"
    >
      <el-table-column
        prop="user_name"
        label="User"
        fixed
        sortable
      />
      <el-table-column
        prop="type"
        label="Type"
        fixed
        sortable
      />
      <el-table-column
        prop="from_date_formatted"
        label="Date"
        fixed
      >
        <template slot-scope="scope">
          <span style="width: 200px"><b>From: </b></span>
          <span>{{ scope.row.from_date_formatted }}</span><br>
          <span style="width: 200px"><b>To: </b></span>
          <span>{{ scope.row.to_date_formatted }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="content"
        label="Description"
        fixed
      />
      <el-table-column
        prop="value"
        label="Hours"
        fixed
      />
      <el-table-column
        prop="content"
        label="Actions"
        fixed
      >
        <template slot-scope="scope">
          <el-button
            type="success"
            size="mini"
            @click="update_request(scope, 'approve')"
          >
            Approve
          </el-button>
          <el-button
            type="danger"
            size="mini"
            @click="update_request(scope, 'reject')"
          >
            Reject
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
export default {
  name: 'UsersRequestsNotification',
  data() {
    return {
      users_requests_visible: false,
      request_statuses: [],
      today: new Date(),
      pending_requests: [],
    };
  },
  computed: {
    alarms() {
      return [
        new Date(
          this.today.getFullYear(),
          this.today.getMonth(),
          this.today.getDate(),
          9,
          0,
          0,
          0
        ),
        new Date(
          this.today.getFullYear(),
          this.today.getMonth(),
          this.today.getDate(),
          11,
          0,
          0,
          0
        ),
        new Date(
          this.today.getFullYear(),
          this.today.getMonth(),
          this.today.getDate(),
          13,
          0,
          0,
          0
        ),
        new Date(
          this.today.getFullYear(),
          this.today.getMonth(),
          this.today.getDate(),
          15,
          0,
          0,
          0
        ),
      ];
    },
  },
  created: function() {
    let _this = this;

    _this.check_time();
    setInterval(function() {
      _this.check_time();
    }, 1 * 0.5 * 60000);
  },
  mounted() {},
  methods: {
    check_time() {
      let check_now = false;

      this.alarms.forEach((el, idx) => {
        // check if current time is between element date (10:00:00) and (10:01:30)
        if (el < new Date() && el.getTime() + 1.5 * 60000 > new Date())
          check_now = true;
      });

      if (check_now == false) return;
      let _this = this;

      _this.$http.get('/pending_requests').then((response) => {
        if (response.data.requests.length > 0) {
          _this.pending_requests = response.data.requests;
          _this.request_statuses = response.data.request_statuses;
          _this.users_requests_visible = true;
        }
      });
    },
    update_request(request, type) {
      let status_type = type == 'approve' ? 'approved' : 'rejected';
      let status = this.request_statuses.filter(
        (c) => c.name.toLowerCase() == status_type.toLowerCase()
      )[0];
      this.$prompt(
        `Enter ${type} reason and press 'Ok' to confirm, or press cancel.`,
        `${type.toUpperCase()} REQUEST`,
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
        }
      )
        .then(({ value }) => {
          let params = {
            request_details: {
              id: request.row.id,
              reply_content: value,
              request_status_id: status.id,
            },
          };

          this.$http
            .patch(`/update_request_status/${request.row.id}`, params)
            .then(
              (response) => {
                this.$message({
                  type: 'success',
                  message: 'Request has been updated.',
                });
                this.$delete(this.pending_requests, request.$index);
              },
              (response) => {
                this.$message({
                  type: 'error',
                  message: 'Failed to update the record.',
                });
              }
            );
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Input canceled',
          });
        });
    },
  },
};
</script>
