import { render, staticRenderFns } from "./UsersKpiDashboard.vue?vue&type=template&id=20c044aa&scoped=true&"
import script from "./UsersKpiDashboard.vue?vue&type=script&lang=js&"
export * from "./UsersKpiDashboard.vue?vue&type=script&lang=js&"
import style0 from "./UsersKpiDashboard.vue?vue&type=style&index=0&id=20c044aa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20c044aa",
  null
  
)

export default component.exports