<template>
  <div>
    <div>
      <h3 class="sales__title">
        STOCK ITEMS LIST
      </h3>
      <hr>
    </div>
    <table class="sales_items_list">
      <thead>
        <tr>
          <th class="name">
            Name
          </th>
          <th class="quote" />
          <th>Stock Qty</th>
          <th>Used Qty</th>
          <th>Unit Cost</th>
          <th>Unit Sale Price</th>
          <th>Total Sale Price</th>
          <th>Total Cost</th>
          <th>Net Profit</th>
          <th>Actual Cost</th>
          <th>Total PO Cost</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(stock,index) in items"
          :key="index"
          :class="mark_deleted(stock.mark_as_deleted)"
        >
          <td>{{ stock.name }}</td>
          <td>
            <el-tag
                class="border__zero"
                size="mini"
                :color="quote_style(stock.quote_type)"
                effect="dark">
              {{ stock.quote_type }}
            </el-tag>
          </td>
          <td>{{ stock.qty }}</td>
          <td>{{ stock.used_qty }}</td>
          <td><Money :amount="stock.unit_cost" /></td>
          <td><Money :amount="stock.unit_sales_price" /></td>
          <td><Money :amount="stock.total_sales_price" /></td>
          <td><Money :amount="stock.total_sales_cost" /></td>
          <td><Money :amount="stock.net_profit" /></td>
          <td><Money :amount="stock.actual_cost" /></td>
          <td><Money :amount="stock.total_po_cost" /></td>
          <td>{{ stock.status }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="6" />
          <td><Money :amount="sum_total_sales_price" /></td>
          <td><Money :amount="sum_total_sales_cost" /></td>
          <td><Money :amount="sum_net_profit" /></td>
          <td><Money :amount="sum_actual_cost" /></td>
          <td><Money :amount="sum_total_po_cost" /></td>
          <td />
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import SalesMixin from 'mixins/SalesMixin';

export default {
  name: 'StockItemsList',
  mixins: [SalesMixin],
  computed: {
    sum_actual_cost() {
      return this.items.reduce((acc, item) =>
        item.mark_as_deleted == 0 ? acc + item.actual_cost : acc, 0);
    },
    sum_total_po_cost() {
      return this.items.reduce((acc, item) =>
        item.mark_as_deleted == 0 ? acc + item.total_po_cost : acc, 0);
    },
    sum_variation_price() {
      return this.items.reduce((acc, item) =>
        item.mark_as_deleted == 0 ? acc + item.variation_price : acc, 0);
    }
  }
};
</script>
