<template>
  <div id="workspace_div" ref="workspace_ref" v-loading="save_loading">
    <div>
      <input ref="image_upload" id="image_file" type="file" accept="image/*" v-on:change="import_image"
        style="display: none;" />
      <input ref="pdf_upload" id="pdf_file" type="file" v-on:change="file_selected" style="display: none;" />
      <input id="add_pdf" ref="pdf_import" type="file" v-on:change="import_file_selected" style="display: none;" />
    </div>

    <div>
      <el-container style="border: 1px solid #c1bdbd !important;">
        <el-container>
          <el-aside v-if="client_view == false" style="width: 125px; padding: 5px;"
            v-bind:class="{ editing_disabled: working_file_id == '' }">
            <Toolbar @toolbar_clicked="toolbar_clicked" :data="toolbar_data"></Toolbar>
          </el-aside>
          <el-main>
            <FilesPanel :save_file="save_file" :show_pdf_preview_dialog="show_pdf_preview_dialog"
              :add_svg_content="add_svg_content"></FilesPanel>
            <div v-show="client_view == false">
              <label style="line-height: normal; text-align: left;">
                <b>Coordinates:</b>
                {{ live_coordinates }}
              </label>
              <label style="line-height: normal; text-align: left;">
                <b>Dimensions:</b>
                W: {{ selected_object_dimensions ? (selected_object_dimensions.width_mm * 0.001).toFixed(3) : "" }} m ,
                H:
                {{ selected_object_dimensions ? (selected_object_dimensions.height_mm * 0.001).toFixed(3) : "" }} m, L:
                {{ selected_object_dimensions ? (selected_object_dimensions.length_mm * 0.001).toFixed(3) : "" }} m,
                Area:
                {{ selected_object_dimensions ? selected_object_dimensions.area_formatted : "" }} m2
              </label>
              <label style="line-height: normal; text-align: left;">
                <b>Selection:</b>
                {{ brush_coordinates }}
              </label>
            </div>

            <div v-show="selected_object_specs">
              <br />
              <el-row>
                <el-col :md="8">
                  <el-input type="number" size="mini" v-model.number="selected_object_width" @input="
                    update_selected_object_specs(
                      'width',
                      selected_object_width
                    )
                    ">
                    <template slot="prepend">Width </template>

                    <template slot="append">
                      mm
                    </template>
                  </el-input>
                </el-col>
                <el-col :md="8">
                  <el-input type="number" size="mini" v-model.number="selected_object_height"
                    @input="update_selected_object_specs('height', selected_object_height)">
                    <template slot="prepend">
                      Height
                    </template>

                    <template slot="append">
                      mm
                    </template>
                  </el-input>
                </el-col>
                <el-col :md="6" v-if="client_view == false">
                  <el-checkbox v-model="selected_object_aspect_ratio">Maintain aspect ratio</el-checkbox>
                </el-col>
              </el-row>
            </div>
            <div v-if="client_view == true">
              <el-row>
                <el-col :md="8">
                  <el-input type="number" size="mini" v-model.number="working_file_qty" @change="update_qty">
                    <template slot="prepend">Quantity </template>
                  </el-input>
                </el-col>
              </el-row>
            </div>
            <ClientViewDetail v-if="client_view == true" :working_file_detail="working_file_detail" />
            <div class="selected_workspace" v-loading="loading" :style="svg_view_style">
              <el-switch class="fullscreen-toggle-btn" :value="editor_fullscreen" active-text="Fullscreen"
                @change="toggle_fullscreen"></el-switch>
              <svg id="proposal_workspace" :disabled="workspace_disabled" class="selected_svg svg-content-responsive"
                :viewBox="viewbox" preserveAspectRatio="xMinYMin meet">
                <g class="svg_outer_container">
                  <rect :x="-page_settings.screen_margin" :y="-page_settings.screen_margin" :width="page_settings.screen_width +
                    2 * page_settings.screen_margin
                    " :height="page_settings.screen_height +
                      2 * page_settings.screen_margin
                      " class="page_border" style="stroke-dasharray: 0px !important;" />
                  <rect x="-1" y="-1" :width="page_settings.screen_width + 1" :height="page_settings.screen_height + 1"
                    class="page_border inner_page_border" />
                  <g :transform="`scale(${page_scale})`">
                    <g class="svg_inner_container">
                      <g class="layers">
                        <g class="svg_layer active_layer base_layer" data-name="Base layer" data-locked="true" />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </el-main>
          <el-aside v-show="client_view == false" style="width: auto; background-color: #e9eef3;">
            <el-tooltip class="item" effect="dark" content="Show/hide side panel" placement="top-start"
              style="cursor: pointer; padding: 2px; margin-top: 8px;">
              <i class="el-icon-notebook-2 right_panel_button" @click="layers_side_panel = !layers_side_panel"></i>
            </el-tooltip>
          </el-aside>
          <el-aside width="20%" v-show="show_layers_side_panel">
            <el-collapse v-model="selected_section">
              <el-collapse-item name="1">
                <template slot="title">
                  <b>Page Settings</b>
                </template>
                <PageSettings></PageSettings>
              </el-collapse-item>
              <el-collapse-item name="2">
                <template slot="title">
                  <b>Layers</b>
                </template>
                <Layers :attach_events="attach_events" :update_nesting="update_nesting"
                  :update_nested_layers="update_nested_layers" :save_svg="save_file" ref="layers_comp"></Layers>
              </el-collapse-item>
              <el-collapse-item name="3">
                <template slot="title">
                  <b>Attributes</b>
                </template>
                <AttributesPanel :update_boundary="update_boundary" :add_editor_action="add_editor_action">
                </AttributesPanel>
              </el-collapse-item>
              <el-collapse-item name="4">
                <template slot="title">
                  <b>Fonts</b>
                </template>
                <FontsPanel></FontsPanel>
              </el-collapse-item>
              <el-collapse-item name="5">
                <template slot="title">
                  <b>Notes</b>
                </template>
                <NotesPanel :proposal_id="proposal.id"></NotesPanel>
              </el-collapse-item>
            </el-collapse>
          </el-aside>
        </el-container>
      </el-container>
    </div>
    <el-dialog title="Import settings" :visible.sync="import_settings_dialog" :close-on-click-modal="close_on_click"
      :show-close="close_on_click" :close-on-press-escape="close_on_click">
      <ImportSettings :dialog_visible="import_settings_dialog" :svg_payload="svg_payload"
        :append_to_workspace="append_to_workspace" v-on:open_file="open_file"></ImportSettings>
    </el-dialog>

    <el-dialog title="Edit text" :visible.sync="edit_text_dialog" width="30%" :close-on-click-modal="close_on_click">
      <el-input type="textarea" :rows="6" placeholder="Please input" v-model="edit_text"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="edit_text_dialog = false">Cancel</el-button>
        <el-button type="primary" @click="update_text">Confirm</el-button>
      </span>
    </el-dialog>

    <el-dialog title="Set scale (Optional)" :visible.sync="scale_dialog" width="30%" v-on:close="scale_dialog_closing">
      <div class="row">
        <div class="col s4">
          <b>Current Scale</b>
        </div>
        <div class="col s2">Width:</div>
        <div class="col s4">
          <el-input type="number" v-model="current_scale.width"></el-input>
        </div>
      </div>
      <div class="row">
        <div class="col s4"></div>
        <div class="col s2">Height:</div>
        <div class="col s4">
          <el-input type="number" v-model="current_scale.height"></el-input>
        </div>
      </div>

      <div class="row">
        <div class="col s4">Target Scale</div>
        <div class="col s2">Width:</div>
        <div class="col s4">
          <el-input type="number" v-model="target_scale.width"></el-input>
        </div>
      </div>
      <div class="row">
        <div class="col s4"></div>
        <div class="col s2">Height</div>
        <div class="col s4">
          <el-input type="number" v-model="target_scale.height"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="scale_dialog = false">Skip</el-button>
        <el-button type="primary" @click="update_scale">Update</el-button>
      </span>
    </el-dialog>

    <el-dialog title="Lay-up" :visible.sync="layup_dialog" width="60%">
      <LayupPanel :refresh="layup_dialog" :attach_events="attach_events"></LayupPanel>
    </el-dialog>
    <el-dialog title="Import PDF preview" id="import_pdf_preview_dialog" :close-on-click-modal="false"
      :visible.sync="import_pdf_preview" :fullscreen="true">
      <ImportPDFPreview :hide_pdf_preview_dialog="hide_pdf_preview_dialog"></ImportPDFPreview>
    </el-dialog>

    <el-dialog title="Scale by points" :visible.sync="scale_by_points_dialog" width="30%">
      <el-row>
        <el-col><span class="demo-input-label">Please enter distance in mm</span></el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-input v-model="scale_by_point_data.distance">
            <template slot="append">
              mm
            </template>
          </el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-checkbox v-model="scale_by_point_data.maintain_aspect_ratio">Maintain aspect ratio</el-checkbox>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="
          scale_by_points_dialog = false;
        scaling_object = null;
        ">Cancel</el-button>
        <el-button type="primary" @click="confirm_scale_by_points">Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import PageSettings from "./PageSettings.vue";
import ImportSettings from "./ImportSettings.vue";
import AttributesPanel from "./panels/AttributesPanel.vue";
import FilesPanel from "./panels/FilesPanel.vue";
import Toolbar from "./panels/Toolbar.vue";
import FontsPanel from "./panels/FontsPanel.vue";
import NotesPanel from "./panels/NotesPanel.vue";
import Layers from "./Layers.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { editor_store, mm_to_px, xml_header } from "store/modules/editor";
import { WorkspaceMixin } from "mixins/WorkspaceMixin.js";
import { ProposalStockItemMixin } from "mixins/ProposalStockItemMixin.js";
import { JobProposalMixin } from "mixins/JobProposalMixin.js";
import shapes_templates from "./templates/shapes_template.json";
import toolbar_items from "./templates/toolbar_items.json";
import { debuglog } from "util";
import LayupPanel from "./panels/LayupPanel.vue";
import ImportPDFPreview from "./ImportPDFPreview";
import LinkedList from "utilities/LinkedList";
import { defaultInput } from "../../utilities/DefaultInput";
import ClientViewDetail from "./ClientViewDetail";
import { EventBus } from '../../utilities/EventBus';

export const svg_modes = {
  NAVIGATION: "navigation",
  DRAWING: "drawing",
  SELECTING: "selecting",
  CROPPING: "cropping",
  SELECTED: "selected",
};

export const action_types = {
  ADD: "add",
  EDIT: "edit",
  DELETE: "DELETE",
};

export const move_types = {
  BACK: "back",
  FRONT: "front",
};

export default {
  name: "Workspace",
  store: editor_store,
  mixins: [WorkspaceMixin, ProposalStockItemMixin, JobProposalMixin],
  components: {
    ClientViewDetail,
    PageSettings,
    ImportSettings,
    AttributesPanel,
    Layers,
    FontsPanel,
    NotesPanel,
    FilesPanel,
    Toolbar,
    LayupPanel,
    ImportPDFPreview,
  },
  data() {
    return {
      editor_actions: "",
      layers_side_panel: true,
      import_pdf_preview: false,
      scaling_points: [],
      scaling_object: null,
      scale_by_point_data: {
        maintain_aspect_ratio: true,
        scaling_line: {},
        distance: 1,
      },
      scale_by_points_dialog: false,
      save_loading: false,
      selected_object_specs: false,
      selected_object_width: 0,
      selected_object_height: 0,
      selected_object_aspect_ratio: false,
      xml_header:
        ' xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" ',
      workspace_disabled: true,
      import_settings_dialog: false,
      layup_dialog: false,
      svg_payload: {},
      append_to_workspace: false,
      close_on_click: false,
      current_mode: svg_modes.NAVIGATION, // ['drawing','selecting','cropping']
      // svg_modes: ["navigation", "drawing", "selecting", "cropping", "selected"],
      selection_mode_active: false,
      save_disabled: false,
      brush: d3.brush(),
      slider_value: 0,
      slider_min: -100,
      slider_max: 100,
      slider_step: 0.5,
      current_zoom_level: 1,
      loading: false,
      edit_text: "",
      edit_text_dialog: false,
      shapes_templates: shapes_templates,
      live_coordinates: "X: 0 , Y: 0",
      brush_coordinates: "X: 0 , Y: 0, W: 0, H: 0",
      brush_coordinates_data: {},
      selected_section: 1,
      images_list: [],
      pdfs_list: [],
      toolbar_data: toolbar_items,
      svg: "",
      xAxis: "",
      yAxis: "",
      gX: "",
      gY: "",
      x: "",
      y: "",
      view: "",
      zoom: "",
      selected_element: "",
      grid_measurements: [
        { unit: "px", value: 1 },
        { unit: "mm", value: 0.264583333 },
        { unit: "cm", value: 0.0264583333 },
        { unit: "m", value: 0.0002645833 },
      ],
      stockDialog: false,
      scale_dialog: false,
      selection_obj: "",
      current_scale: { width: 0, height: 0 },
      target_scale: { width: 0, height: 0 },
      working_file_qty: 0,
      working_file_detail: {
        stocks: [],
        tasks: [],
        pdf_path: null,
      }
    };
  },
  methods: {
    ...mapActions([
      "set_page_size",
      "set_page_margin",
      "set_selected_object",
      "load_layers_tree",
      "clear_selection",
      "load_fonts_list",
      "refresh_missing_fonts",
      "set_files_list",
      "set_file_changed",
      "set_working_file_id",
      "set_editor_fullscreen"
    ]),
    update_qty() {
      this.current_working_file.qty = this.working_file_qty;
      this.$refs.layers_comp.update_qty_out_of_layers(this.working_file_qty);
    },
    toggle_fullscreen() {
      const element = document.documentElement;

      if (!document.fullscreenElement &&    // Standard
        !document.mozFullScreenElement && // Firefox
        !document.webkitFullscreenElement && // Chrome, Safari, Opera
        !document.msFullscreenElement) { // IE/Edge
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.mozRequestFullScreen) { // Firefox
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) { // Chrome, Safari, Opera
          element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) { // IE/Edge
          element.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) { // Firefox
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) { // Chrome, Safari, Opera
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { // IE/Edge
          document.msExitFullscreen();
        }
      }

    },
    update_path_specs(attr, value) {
      let path_coords = this.get_relative_boundaries(this.selected_object);
      let transform_exist = false;
      let xScale = value / path_coords.width;
      let yScale = value / path_coords.height;

      Array.from(this.selected_object.transform.baseVal).forEach((el, idx) => {
        if (el.type == SVGTransform.SVG_TRANSFORM_MATRIX) {
          transform_exist = true;
          if (attr == "width") {
            el.matrix.d =
              this.selected_object_aspect_ratio == true
                ? (el.matrix.a * xScale * el.matrix.d) / el.matrix.a
                : el.matrix.d;
            el.matrix.a = el.matrix.a * xScale;
          } else {
            el.matrix.a =
              this.selected_object_aspect_ratio == true
                ? (el.matrix.a * yScale * el.matrix.d) / el.matrix.d
                : el.matrix.a;
            el.matrix.d = el.matrix.d * yScale;
          }
        }
      });

      if (transform_exist == false) {
        let svg_root = d3.select("#proposal_workspace").node();
        let trans = svg_root.createSVGTransform();

        if (attr == "width") {
          trans.matrix.a = xScale;
          trans.matrix.d =
            this.selected_object_aspect_ratio == true
              ? trans.matrix.a
              : trans.matrix.d;
        } else {
          trans.matrix.d = yScale;
          trans.matrix.a =
            this.selected_object_aspect_ratio == true
              ? trans.matrix.d
              : trans.matrix.a;
        }

        if (this.selected_object.transform.baseVal.length > 0)
          // this.selected_object.transform.baseVal.insertItemBefore(trans, 0);
          this.selected_object.transform.baseVal.appendItem(trans);
        else {
          let translate = svg_root.createSVGTransform();
          translate.setTranslate(0, 0);
          this.selected_object.transform.baseVal.appendItem(translate);
          this.selected_object.transform.baseVal.appendItem(trans);
        }
      }
    },
    update_selected_object_specs(attr, value) {
      this.editor_actions.push({
        action: action_types.EDIT,
        object: this.selected_object,
        attributes: [
          {
            type: "attribute",
            key: "transform",
            value:
              this.selected_object.attributes.transform == undefined
                ? null
                : this.selected_object.attributes.transform.value,
          },
        ],
      });
      let px_value = value * mm_to_px * this.page_scale;
      this.update_path_specs(attr, px_value);
      this.update_nesting();
      this.set_selected_object(this.selected_object);
      this.set_file_changed(true);
    },
    toolbar_clicked(name) {
      this.handleSelect(name, null);
    },
    // update nesting for current selected objects
    // against the selected checked stock/task layers
    update_nesting(checked_layers = []) {
      let arr = this.dynamic_update(this.selected_object, checked_layers);
      let successful_arr = arr.filter((c) => c.status == true);
      let failed_arr = arr.filter((c) => c.status == false);
      if (successful_arr.length == 0) {
        if (failed_arr.length > 0) {
          this.$message.info({
            dangerouslyUseHTMLString: true,
            message:
              "These layers cannot be nested. <br> " +
              failed_arr.map(
                (c) =>
                  c.layer.getAttribute("data-name") + " (" + c.message + ")"
              ),
            type: "info",
          });
        }
        return false;
      }
      this.update_nested_layers(successful_arr, failed_arr);
    },
    async update_nested_layers(successful_arr, failed_arr) {
      successful_arr.forEach((el, idx) => {
        this.attach_events(el.layer);
      });


      let updated_stocks = successful_arr.filter((c) => c.type == "stock").map(c => c);
      let updated_tasks = successful_arr.filter((c) => c.type == "job");

      this.save_loading = false;
      let loaded = 0;

      if (updated_stocks.length > 0) {
        await this.batch_prop_stock_update(updated_stocks);
        loaded++;
        if (loaded == 2) this.save_loading = false;
        updated_stocks.forEach((el, idx) => {
          this.stock_items_list.filter((c) => c.id == parseInt(el.id))[0][
            "quantity"
          ] = el.qty;
          this.stock_items_list.filter((c) => c.id == parseInt(el.id))[0][
            "specs_details"
          ] = el.specs_details;
        });
      }
      else loaded++;

      if (updated_tasks.length > 0) {
        await this.batch_jps_update(updated_tasks);
        loaded++;
        if (loaded == 2) this.save_loading = false;
        updated_tasks.forEach((el, idx) => {
          this.job_proposals_list.filter((c) => c.id == parseInt(el.id))[0][
            "time_allocated_labour"
          ] = el.qty;
          this.job_proposals_list.filter((c) => c.id == parseInt(el.id))[0][
            "specs_details"
          ] = el.specs_details;
        });
      }
      else loaded++;

      if (failed_arr.length > 0) {
        let names = failed_arr.map((c) => c.layer.getAttribute("data-name"));
        this.$message.info({
          type: "info",
          dangerouslyUseHTMLString: true,
          message: `Layers have been nested, except below:<br>${names.join(
            "<br> " +
            failed_arr.map(
              (c) =>
                c.layer.getAttribute("data-name") + " (" + c.message + ")"
            )
          )}`,
        });
      } else {
        this.$message({
          type: "success",
          message: "Layers have been updated successfully.",
        });
        await this.save_file(undefined, true);
        EventBus.$emit('reloadCategoryWorkingFiles');
        EventBus.$emit('reloadItems');
      }
    },
    // crop images only from SVG editor
    crop_image() {
      let _this = this;
      let params = {
        working_file_id: this.working_file_id,
        coordinates: this.brush_coordinates_data,
      };
      this.layers_tree.forEach((el, idx) => {
        el.visible = false;
      });
      this.$http
        .post("/crop_image", { image_params: params })
        .then((response) => {
          let img = document.createElement("img");
          img.src = response.bodyText;

          img.onload = function (ev) {
            var canvas = document.createElement("canvas");
            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);

            var MAX_WIDTH = 400;
            var MAX_HEIGHT = 400;
            var width = img.width;
            var height = img.height;

            canvas.width = width;
            canvas.height = height;
            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);

            let dataurl = canvas.toDataURL("png");

            let new_layer = d3
              .select(".layers")
              .append("g")
              .classed("svg_layer", true)
              .attr("data-name", "cropped image");

            let shape = new_layer.append("g").append("svg:image");

            shape
              .attr("xlink:href", dataurl)
              .attr("x", _this.brush_coordinates_data.x1)
              .attr("y", _this.brush_coordinates_data.y1)
              .attr("width", 0)
              .attr("height", 0);

            let new_img = new Image();

            new_img.onload = function () {
              shape.attr("width", new_img.width).attr("height", new_img.height);

              _this.attach_events(shape);
            };
            new_img.src = dataurl;
            document.getElementById("image_file").value = "";
            _this.editor_actions.push({
              action: action_types.ADD,
              object: new_layer,
              attributes: [],
            });
            _this.load_layers_tree();
          };
          return;
        });
    },
    synchronize_layers() {
      let stock_ids = this.stock_items_list.map((c) => c.id);
      let tasks_ids = this.job_proposals_list.map((c) => c.id);

      let missing_stock_layers = d3
        .selectAll("[proposal-stock-id]")
        .nodes()
        .filter(
          (c) =>
            stock_ids.includes(parseInt(c.getAttribute("proposal-stock-id"))) ==
            false
        );

      let missing_task_layers = d3
        .selectAll("[job-id]")
        .nodes()
        .filter(
          (c) => tasks_ids.includes(parseInt(c.getAttribute("job-id"))) == false
        );
      if (missing_stock_layers.length > 0 || missing_task_layers.length > 0) {
        this.$confirm(
          "Stocks & Tasks were deleted from 'Stocks Items/Tasks list' but they still " +
          "exist as Layers in current working file, it is highly recommended to clear them, " +
          "Do you want to proceed ?",
          "Warning",
          {
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: "warning",
          }
        ).then(() => {
          missing_stock_layers.forEach(function (el, idx) {
            d3.select(el).remove();
          });
          missing_task_layers.forEach(function (el, idx) {
            d3.select(el).remove();
          });
          this.load_layers_tree();
          this.$message({
            type: "success",
            message: "Delete completed",
          });
        });
      }
    },
    // group selected objects into a <g> element
    group_objects() {
      let _this = this;
      let nodes = d3
        .selectAll("[data-selected=true],.selected_graphics")
        .nodes();
      if (nodes.length <= 1) {
        this.$message({
          message: "Please select more than one object to be grouped",
          type: "warning",
        });
        return;
      }
      // loop through, if any is from different layer =>
      // get all node with their layer name
      let elements_arr = [];
      let layer_name = "";
      let temp_layer_name = "";
      let same_group = true;

      nodes.forEach((el, idx) => {
        d3.select(el).on("click", null);
        el.classList.add("grouped");
        elements_arr.push({
          element: el,
          matrix_trans: _this.get_matrix_transformation(el).reverse(),
        });
        layer_name = _this.get_layer_name(el);
        if (
          same_group == true &&
          temp_layer_name != "" &&
          layer_name != temp_layer_name
        )
          same_group = false;
        temp_layer_name = layer_name;
      });

      let svg_layer;
      if (same_group == false)
        svg_layer = d3
          .selectAll(".svg_inner_container .layers")
          .append("g")
          .attr("class", "svg_layer")
          .attr("data-name", "new layer");
      else svg_layer = d3.select(this.get_parent_layer(nodes[0]));

      let grp = svg_layer.append("g").classed("grouped", true);
      elements_arr.forEach((el, idx) => {
        let g = grp;
        let i;
        for (i = 0; i < el.matrix_trans.length; i++)
          g = d3
            .select(g.node())
            .append("g")
            .attr("transform", el.matrix_trans[i]);
        g.node().appendChild(el.element);
      });

      this.set_selected_object(null);
      this.load_layers_tree();
      this.attach_events(grp.node());
      this.set_file_changed(true);
      this.end_selection();
      return grp;
    },
    // get layer name by element
    get_layer_name(el) {
      let layer_name = "";
      let elem = el.parentElement;
      while (elem.classList.contains("layers") == false) {
        if (elem.classList.contains("svg_layer") == true) {
          layer_name = elem.getAttribute("data-name");
          break;
        }
        elem = elem.parentElement;
      }
      return layer_name;
    },
    // ungroup selected group element
    ungroup_objects() {
      this.selected_object.classList.remove("grouped");
      d3.select(this.selected_object)
        .selectAll(".grouped")
        .classed("grouped", false);
      this.bind_events(this);
      this.attach_events(this.selected_object);
      this.set_file_changed(true);
    },
    draw_polyline(obj, m = 3) {
      let boundaries_annotation = this.draw_boundaries_annotation(obj, m);
      let polylines_arr = [];
      let labels_arr = [];

      polylines_arr = boundaries_annotation.polylines_arr;
      labels_arr = boundaries_annotation.labels_arr;

      let new_layer = d3
        .select(".layers")
        .append("g")
        .classed("svg_layer", true)
        .attr("data-name", "Boundaries labels");
      new_layer = new_layer.append("g").classed("grouped", true);
      labels_arr.forEach((el, idx) => {
        new_layer
          .append("text")
          .attr("x", el.x)
          .attr("y", el.y)
          .attr("transform", el.transform)
          .attr("fill", "gray")
          .style("font-size", `${3 / this.page_scale}px`)
          .classed("grouped", true)
          .text(`${Math.round(el.value / mm_to_px)}mm`);
      });
      polylines_arr.forEach((el, idx) => {
        new_layer
          .append("polyline")
          .attr("points", el)
          .attr("style", `fill:none;stroke:brown;stroke-width:10px`)
          .classed("grouped", true);
      });
      this.editor_actions.push({
        action: action_types.ADD,
        object: new_layer,
        attributes: [],
      });
      this.load_layers_tree();
    },
    show_pdf_preview_dialog() {
      this.import_pdf_preview = true;
    },
    hide_pdf_preview_dialog() {
      this.import_pdf_preview = false;
    },
    // generate labels around selected shapes
    generate_labels() {
      let nodes = d3
        .selectAll("[data-selected=true],.selected_graphics")
        .nodes();
      let grp;
      if (nodes.length <= 1) grp = this.selected_object;
      else grp = this.group_objects().node();

      let relative_coordinates = this.get_relative_boundaries(grp);

      // generate labels basd on shape type
      if (grp.nodeName == "circle" || grp.nodeName == "polygon" || grp.nodeName == "polyline" || grp.nodeName == "path") {
        this.draw_polygon_circle_annotion(grp, relative_coordinates);
        return;
      }

      this.draw_polyline({
        x: relative_coordinates.x1 / this.page_scale,
        y: relative_coordinates.y1 / this.page_scale,
        w: relative_coordinates.width / this.page_scale,
        h: relative_coordinates.height / this.page_scale,
        length: 40,
      });

      this.draw_shapes_annotion(grp, relative_coordinates);
    },
    // get boundary for [rect,circle, path] shapes
    get_shape_boundary_points(shape) {
      let shape_coord = this.get_relative_boundaries(shape);
      let points = [];
      points.push({ x: shape_coord.x1, y: shape_coord.y1 });
      points.push({
        x: shape_coord.x1 + shape_coord.width,
        y: shape_coord.y1,
      });
      points.push({
        x: shape_coord.x1 + shape_coord.width,
        y: shape_coord.y1 + shape_coord.height,
      });
      points.push({
        x: shape_coord.x1,
        y: shape_coord.y1 + shape_coord.height,
      });
      points.push({ x: shape_coord.x1, y: shape_coord.y1 });
      return points;
    },
    // extract points from shape coordinates
    extra_shape_points(shape_coord) {
      let points = [];
      points.push({ x: shape_coord.x, y: shape_coord.y });
      points.push({
        x: shape_coord.x + shape_coord.w,
        y: shape_coord.y,
      });
      points.push({
        x: shape_coord.x + shape_coord.w,
        y: shape_coord.y + shape_coord.h,
      });
      points.push({
        x: shape_coord.x,
        y: shape_coord.y + shape_coord.h,
      });
      points.push({ x: shape_coord.x, y: shape_coord.y });
      return points;
    },
    // get coordinates based on shape type
    get_shape_points(shape) {
      let shape_points = [];
      switch (shape.nodeName) {
        case "polygon":
          shape_points = Array.from(shape.points).concat(shape.points[0]);
          break;
        case "line":
          shape_points.push({ x: shape.attr("x1"), y: shape.attr("y1") });
          shape_points.push({ x: shape.attr("x2"), y: shape.attr("y2") });
          break;
        case "polyline":
          shape_points = shape.points;
          break;
        default:
          shape_points = this.extra_shape_points(shape);
          break;
      }
      return shape_points;
    },
    draw_polygon_circle_annotion(shape, shape_coord) {
      let new_layer = d3
        .select(".layers")
        .append("g")
        .classed("svg_layer", true)
        .attr("data-name", "Shapes labels");

      this.editor_actions.push({
        action: action_types.ADD,
        object: new_layer,
        attributes: [],
      });

      let shape_factor = this.get_shape_relative_coords(shape);
      let shape_middle_y =
        (shape_coord.y1 + shape_coord.height / 2) / this.page_scale;
      let shape_middle_x =
        (shape_coord.x1 + shape_coord.width / 2) / this.page_scale;

      let text = "";
      let linear = (this.selected_object_dimensions.length_mm * 0.001).toFixed(2);
      let area = this.selected_object_dimensions.area_formatted;
      let tspans = "";
      let font_size = `${(shape_coord.height * 0.1) / this.page_scale}px`;

      if (shape.nodeName == "circle") {
        tspans = `<tspan x="${shape_middle_x}">Circumference: ${linear} m</tspan><tspan x="${shape_middle_x}" dy="${shape_coord.height / 0.1}">Area: ${area} m2</tspan>`;
      }
      else if (shape.nodeName == "polyline" || shape.nodeName == "path") {
        tspans = `<tspan x="${shape_middle_x}">Length: ${linear} m</tspan>`;
        font_size = '1000px';
      }
      else {
        tspans = `<tspan x="${shape_middle_x}">Perimeter: ${linear} m</tspan><tspan x="${shape_middle_x}" dy="${shape_coord.height / 0.1}">Area: ${area} m2</tspan>`;
      }

      let txt_layer = new_layer
        .append("text")
        .attr("x", shape_middle_x)
        .attr("y", shape_middle_y)
        .style("font-size", font_size)
        .style("font-weight", "bold")
        .classed("grouped", true)
        .text(text);
      txt_layer.node().innerHTML = tspans;
      this.attach_events(txt_layer.node());
      this.load_layers_tree();
    },
    // draw shapes annotation, create boundary & shapes labels layers
    draw_shapes_annotion(grp, grp_coord) {
      let new_layer = d3
        .select(".layers")
        .append("g")
        .classed("svg_layer", true)
        .attr("data-name", "Shapes labels");

      new_layer = new_layer.append("g").classed("grouped", true);

      this.editor_actions.push({
        action: action_types.ADD,
        object: new_layer,
        attributes: [],
      });
      let shapes =
        grp.nodeName != "g"
          ? [grp]
          : d3
            .select(grp)
            .selectAll("line,polyline,polygon,circle,rect,path")
            .nodes();
      let margin = { top: 3, bottom: 3, right: 3, left: 3 };

      shapes.forEach((shape, idx) => {
        let shape_factor = this.get_shape_relative_coords(shape);
        let shape_middle_y =
          (grp_coord.y1 + grp_coord.height / 2) / this.page_scale;
        let shape_middle_x =
          (grp_coord.x1 + grp_coord.width / 2) / this.page_scale;
        let lines = [];
        let tmp;
        let shape_points = [];

        if (["rect", "circle", "path"].includes(shape.nodeName) == true)
          shape_points = this.get_shape_boundary_points(shape);
        else shape_points = this.get_shape_points(shape);
        // add the closing of polygon

        Array.from(shape_points).forEach((point, idx) => {
          let pnt = {};
          if (["rect", "circle", "path"].includes(shape.nodeName) == true)
            pnt = {
              x: point.x / this.page_scale,
              y: point.y / this.page_scale,
            };
          else
            pnt = {
              x: point.x / shape_factor.x / this.page_scale,
              y: point.y / shape_factor.y / this.page_scale,
            };

          if (idx === 0) {
            tmp = pnt;
            return;
          }

          let align_arr = [];
          if (Math.round(tmp.x) != Math.round(pnt.x)) {
            if (tmp.y > shape_middle_y && pnt.y > shape_middle_y)
              align_arr.push("bottom");
            else align_arr.push("top");
          }

          if (Math.round(tmp.y) != Math.round(pnt.y)) {
            if (tmp.x > shape_middle_x && pnt.x > shape_middle_x)
              align_arr.push("right");
            else align_arr.push("left");
          }
          lines.push({ pnt1: tmp, pnt2: pnt, align_arr: align_arr });
          tmp = pnt;
        });
        let color;

        lines.forEach((line, idx) => {
          line.align_arr.forEach((direction) => {
            switch (direction) {
              case "top":
              case "bottom":
                let y =
                  grp_coord.y1 + (direction == "bottom" ? grp_coord.height : 0);
                let spacing_y =
                  direction == "top"
                    ? -1 * margin[direction]
                    : 1 * margin[direction];
                y /= this.page_scale;
                spacing_y /= this.page_scale;
                margin[direction] += 4;
                color = this.getRandomColor();
                let line1 = new_layer
                  .append("line")
                  .attr("x1", line.pnt1.x)
                  .attr("y1", y + spacing_y)
                  .attr("x2", line.pnt2.x)
                  .attr("y2", y + spacing_y)
                  .style("stroke-width", `${0.2 / this.page_scale}`)
                  .style("stroke", "black")
                  .classed("grouped", true);

                new_layer
                  .append("line")
                  .attr("x1", line.pnt1.x)
                  .attr("y1", line.pnt1.y)
                  .attr("x2", line.pnt1.x)
                  .attr("y2", y + spacing_y)
                  .style("stroke-width", `${0.2 / this.page_scale}`)
                  .style("stroke", "black")
                  .classed("grouped", true);

                new_layer
                  .append("line")
                  .attr("x1", line.pnt2.x)
                  .attr("y1", line.pnt2.y)
                  .attr("x2", line.pnt2.x)
                  .attr("y2", y + spacing_y)
                  .style("stroke-width", `${0.2 / this.page_scale}`)
                  .style("stroke", "black")
                  .classed("grouped", true);

                // if (
                //   line1.node().getTotalLength() <
                //   shape.getTotalLength() * 0.05
                // )
                //   return;

                new_layer
                  .append("text")
                  .attr("x", line.pnt1.x + (line.pnt2.x - line.pnt1.x) / 2)
                  .attr("y", y + spacing_y)
                  .style("font-size", `${4 / this.page_scale}px`)
                  .style("font-weight", "bold")
                  .classed("grouped", true)
                  .text(
                    `${Math.round(line1.node().getTotalLength() / mm_to_px)}mm`
                  );
                break;
              case "right":
              case "left":
                color = this.getRandomColor();
                let spacing_x =
                  direction == "left"
                    ? -1 * margin[direction]
                    : 1 * margin[direction];
                margin[direction] += 4;
                let x =
                  grp_coord.x1 + (direction == "right" ? grp_coord.width : 0);
                spacing_x /= this.page_scale;
                x /= this.page_scale;
                let line2 = new_layer
                  .append("line")
                  .attr("x1", x + spacing_x)
                  .attr("y1", line.pnt1.y)
                  .attr("x2", x + spacing_x)
                  .attr("y2", line.pnt2.y)
                  .style("stroke-width", `${0.2 / this.page_scale}px`)
                  .style("stroke", "black")
                  .classed("grouped", true);
                // if (
                //   line2.node().getTotalLength() <
                //   shape.getTotalLength() * 0.05
                // )
                //   return;
                new_layer
                  .append("line")
                  .attr("x1", line.pnt1.x)
                  .attr("y1", line.pnt1.y)
                  .attr("x2", x + spacing_x)
                  .attr("y2", line.pnt1.y)
                  .style("stroke-width", `${0.2 / this.page_scale}px`)
                  .style("stroke", "black")
                  .classed("grouped", true);

                new_layer
                  .append("line")
                  .attr("x1", line.pnt2.x)
                  .attr("y1", line.pnt2.y)
                  .attr("x2", x + spacing_x)
                  .attr("y2", line.pnt2.y)
                  .style("stroke-width", `${0.2 / this.page_scale}px`)
                  .style("stroke", "black")
                  .classed("grouped", true);
                new_layer
                  .append("text")
                  .attr("x", x + spacing_x)
                  .attr("y", line.pnt1.y + (line.pnt2.y - line.pnt1.y) / 2)
                  .style("font-size", `${4 / this.page_scale}px`)
                  .style("font-weight", "bold")
                  .attr(
                    "transform",
                    `rotate(${direction == "left" ? 270 : 90} ${x +
                    spacing_x}  ${line.pnt1.y +
                    (line.pnt2.y - line.pnt1.y) / 2})`
                  )
                  .classed("grouped", true)
                  .text(
                    `${Math.round(line2.node().getTotalLength() / mm_to_px)}mm`
                  );
            }
          });
        });
      });
      this.load_layers_tree();
    },
    update_current_mode(mode) {
      // below keys are excluded from generic implemenetation
      let excluded_list = ["undo_action"];

      switch (mode) {
        case svg_modes.NAVIGATION:
          this.enable_menus(
            "nav_select",
            this.toolbar_data
              .find((c) => c.id == "nav_select")
              .buttons.filter((c) => excluded_list.indexOf(c.id) == -1)
              .map((c) => c.id),
            false
          );
          this.enable_menus("nav_select", ["select", "crop"], true);
          this.enable_menus(
            "draw",
            this.toolbar_data
              .find((c) => c.id == "draw")
              .buttons.map((c) => c.id),
            true
          );
          this.enable_menus("draw", ["end_draw"], false);
          this.enable_menus("features", ["scale_by_line"], false);
          break;
        case svg_modes.DRAWING:
          this.enable_menus(
            "nav_select",
            this.toolbar_data
              .find((c) => c.id == "nav_select")
              .buttons.filter((c) => excluded_list.indexOf(c.id) == -1)
              .map((c) => c.id),
            false
          );
          this.enable_menus(
            "draw",
            this.toolbar_data
              .find((c) => c.id == "draw")
              .buttons.map((c) => c.id),
            false
          );
          this.enable_menus("draw", ["end_draw"], true);
          break;
        case svg_modes.SELECTING:
          this.enable_menus(
            "nav_select",
            this.toolbar_data
              .find((c) => c.id == "nav_select")
              .buttons.map((c) => c.id),
            false
          );
          this.enable_menus(
            "draw",
            this.toolbar_data
              .find((c) => c.id == "draw")
              .buttons.map((c) => c.id),
            false
          );
          this.enable_menus(
            "nav_select",
            ["end_select", "group_objects", "delete_node"],
            true
          );
          break;
        case svg_modes.CROPPING:
          this.enable_menus(
            "nav_select",
            this.toolbar_data
              .find((c) => c.id == "nav_select")
              .buttons.filter((c) => excluded_list.indexOf(c.id) == -1)
              .map((c) => c.id),
            false
          );
          this.enable_menus(
            "draw",
            this.toolbar_data
              .find((c) => c.id == "draw")
              .buttons.map((c) => c.id),
            false
          );
          this.enable_menus(
            "nav_select",
            ["end_crop", "group_objects", "generate_labels", "crop_area"],
            true
          );
          break;
        case svg_modes.SELECTED:
          if (this.current_mode == svg_modes.SELECTING) return;
          this.enable_menus(
            "nav_select",
            this.toolbar_data
              .find((c) => c.id == "nav_select")
              .buttons.filter((c) => excluded_list.indexOf(c.id) == -1)
              .map((c) => c.id),
            true
          );
          this.enable_menus(
            "draw",
            this.toolbar_data
              .find((c) => c.id == "draw")
              .buttons.map((c) => c.id),
            true
          );
          this.enable_menus("nav_select", ["end_crop", "end_select"], false);
          this.enable_menus("draw", ["end_draw"], false);
          this.enable_menus("features", ["scale_by_line"], true);
          break;
      }
      this.current_mode = mode;

      // Zoom to fit & clear should be visible permanently
      this.enable_menus("nav_select", ["zoom_to_fit", "clear_all"], true);
    },
    enable_menus(toolbar, buttons, flag) {
      this.toolbar_data
        .find((c) => c.id == toolbar)
        .buttons.forEach((el, idx) => {
          if (buttons.indexOf(el.id) > -1) el.enabled = flag;
        });
    },
    initialize() {
      this.load_grid(); // load d3js grid
      this.bind_mouse(); // bind mouse events to grid
      this.bind_shortcuts(); // bind keyboard shortcuts
    },
    add_fullscreen_listener() {
      document.addEventListener('fullscreenchange', this.on_fullscreen_change);
    },
    remove_fullscreen_listener() {
      document.addEventListener('fullscreenchange', this.on_fullscreen_change);
    },
    on_fullscreen_change() {
      if (document.fullscreenElement) {

        console.log('Entering  fullscreen mode.');

        // Update store value
        this.set_editor_fullscreen(true);
        
        // Hide side panel
        document.getElementById('slide-out').style.display = "none";

        // Reset padding for editor to show the editor in full screen
        Array.from(document.getElementsByTagName('main')).map((el, idx) => {
          el.style.paddingLeft = "0";
        })

        // Hide the tab headers
        Array.from(document.getElementsByClassName('el-tabs__header')).map((el, idx) => {
          el.style.display = "none";
        });

        // Hide side panel to give editor more space
        this.layers_side_panel = false;
      } else {        
        console.log('Exited fullscreen mode.');

        // Update store value
        this.set_editor_fullscreen(false);

        // Show side panel
        document.getElementById('slide-out').style.display = "";


        // Revert back the padding value to CSS default
        Array.from(document.getElementsByTagName('main')).map((el, idx) => {
          el.style.paddingLeft = "";
        });

        // Show tab headers
        Array.from(document.getElementsByClassName('el-tabs__header')).map((el, idx) => {
          el.style.display = "";
        });
      }

      // Scroll to top
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Use 'auto' for instant scrolling
      });
    }
    ,
    set_inputs_to_default() {
      let _this = this;
      Array.from(document.querySelectorAll("#workspace_div .el-input")).forEach(
        function (e, i) {
          e.classList.add("browser-default");
          if (e.querySelector("input") != null)
            e.querySelector("input").className += " browser-default";
        }
      );
    },
    get_path_length(path) {
      let points = [];
      let i = 0;
      let tmp_pnt_a;
      let tmp_pnt_b;
      let distance = 0;

      if (path.cloneNode(true).transform.baseVal.length == 0)
        return path.getTotalLength();

      let mtrix = path.cloneNode(true).transform.baseVal.consolidate().matrix;

      while (i <= path.getTotalLength()) {
        tmp_pnt_b = path.getPointAtLength(i).matrixTransform(mtrix);
        if (i == 0) {
          tmp_pnt_a = tmp_pnt_b;
          i += 0.1 / this.page_scale;
          continue;
        } else {
          i += 0.1 / this.page_scale;
          distance += Math.sqrt(
            Math.pow(tmp_pnt_a.x - tmp_pnt_b.x, 2) +
            Math.pow(tmp_pnt_a.y - tmp_pnt_b.y, 2)
          );
          tmp_pnt_a = tmp_pnt_b;
        }
      }
      return distance;
    },
    // Update scale after cropping
    // used with crop feature only
    update_scale() {
      let _this = this;
      if (this.selection_obj != "") {
        let scaleX = _this.target_scale.width / _this.current_scale.width;
        let scaleY = _this.target_scale.height / _this.current_scale.height;

        if (scaleX == scaleY && scaleX == 1) {
        } else if (scaleX == scaleY)
          this.selection_obj.setAttribute("transform", `scale(${scaleX})`);
        else
          this.selection_obj.setAttribute(
            "transform",
            `scale(${scaleX} ${scaleY})`
          );
      }
      _this.scale_dialog = false;
    },
    getRandomColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    end_drawing() {
      let shapes = d3
        .selectAll(".scratchpad path,.scratchpad polygon,.scratchpad polyline")
        .nodes();
      let _this = this;

      if (shapes.length > 0) {
        if (
          d3.select(".svg_inner_container .svg_layer.active_layer").node() ==
          null
        ) {
          if (this.layers_tree.length > 0) this.layers_tree[0].active = true;
        }
        let group = d3
          .select(".svg_inner_container .svg_layer.active_layer")
          .append("g");

        let style = `fill: none; stroke: #000;stroke-width: ${2 /
          this.page_scale}px;stroke-linejoin: round;stroke-linecap: round;`;
        if (shapes[0].nodeName == "path" && shapes.length > 0) {
          let combined_path = group.append("path");
          let pnts = "";
          shapes.forEach((el, idx) => {
            if (el.getAttribute("d") != undefined)
              pnts += " " + el.getAttribute("d");
          });
          combined_path.attr("d", pnts);
          combined_path.attr("style", style);
          _this.attach_events(combined_path);
        } else
          shapes.forEach((el, idx) => {
            let clonedNode = el.cloneNode(true);
            clonedNode.setAttribute("style", style);
            _this.attach_events(clonedNode);
            group.node().append(clonedNode);
          });
        this.editor_actions.push({
          action: action_types.ADD,
          object: group,
          attributes: [],
        });
      }
      d3.selectAll(".scratchpad").remove();
    },
    clear_brush() {
      d3.select(
        "#proposal_workspace .svg_inner_container .brush_selection"
      ).remove();
      d3.selectAll(".selected_graphics").classed("selected_graphics", false);
    },
    // SVG content loaded
    content_loaded() {
      this.bind_events(this); // after svg content loaded
      this.load_layers_tree(true); // after svg content loaded
      this.load_fonts_list(); // after svg content loaded
    },
    // save svg file content
    save_file(external_file = undefined, hide_notification = false) {
      let loading;
      let _this = this;
      this.set_file_changed(false);
      let working_file_id =
        external_file != undefined
          ? external_file.working_file_id
          : _this.working_file_id;
      let svg_content =
        external_file != undefined
          ? external_file.svg_content
          : d3
            .select("svg .svg_inner_container g.layers")
            .node()
            .cloneNode(true);

      let defs = "";
      let defs_arr = [];
      if (
        d3
          .select("#proposal_workspace")
          .select("defs")
          .nodes().length > 0
      ) {
        d3.select("#proposal_workspace")
          .select("defs")
          .nodes()
          .forEach((el, idx) => {
            defs_arr.push(el.innerHTML);
          });
        defs = `<defs>${defs_arr.join("\r\n")}</defs>`;
      }

      d3.select(svg_content)
        .selectAll(".selected_object_frame,.brush_selection")
        .remove();
      d3.select(svg_content)
        .selectAll(".selected_graphics")
        .classed("selected_graphics", false);

      if (hide_notification == false) {
        loading = this.$loading({
          lock: true,
          text: "Saving file",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      }

      return new Promise((resolve, reject) => {
        let content = `<svg  ${_this.xml_header} viewbox="${_this.viewbox}" width="${_this.page_settings.screen_width_ns}" height="${_this.page_settings.screen_height_ns}" >${defs}${svg_content.outerHTML}</svg>`;
        let blob = new Blob([content], { type: "text/html" });
        let formData = new FormData();
        formData.append("file", blob);
        let page_dim = {
          page_width: _this.page_settings.width,
          page_height: _this.page_settings.height,
          page_margin: _this.page_settings.margin,
        };
        formData.append("page_dimensions", JSON.stringify(page_dim));
        this.$http.patch(`/working_files/${working_file_id}`, formData).then(
          (response) => {
            if (hide_notification == false)
              this.$message({
                message: "Updated successfully.",
                type: "success",
              });
            if (external_file == undefined) {
              let current_working_file = _this.files_list.filter(
                (el) => el.id == _this.working_file_id
              )[0];
              if (current_working_file)
                current_working_file.thumb = response.body.thumb;
            }
            if (hide_notification == false) loading.close();
            resolve();
          },
          function (response) {
            if (hide_notification == false) loading.close();
            this.$message({
              message: "Error happened while retreiving the file.",
              type: "error",
            });
            reject();
          }
        );
      });
    },
    // update text object content
    update_text(e) {
      let txt = "";
      let _this = this;
      let font_size =
        this.selected_object.style.fontSize ||
        this.selected_object.getAttribute("font-size");
      let dy = parseInt(font_size) || 20 / this.page_scale;
      let dx = this.selected_object.getAttribute("x") || 0;
      if (this.edit_text.indexOf("\n")) {
        let tspans = this.edit_text.split("\n");

        tspans.forEach(function (el, index) {
          if (index == 0) txt += `<tspan x="${dx}"  >${el}</tspan>`;
          else txt += `<tspan x="${dx}" dy="${dy}">${el}</tspan>`;
        });
      } else txt = this.edit_text;

      this.editor_actions.push({
        action: action_types.EDIT,
        object: this.selected_object,
        attributes: [
          {
            type: "object",
            key: "innerHTML",
            value: this.selected_object.innerHTML,
          },
        ],
      });

      this.selected_object.innerHTML = txt;
      this.set_selected_object(this.selected_object);
      this.edit_text_dialog = false;
      this.set_file_changed(true);
    },
    round(num) {
      return Math.round(num * 100) / 100;
    },
    // convert pdf to SVG & import it's content in editor
    import_file_selected(file) {
      if (file.target.files.length == 0) return;

      let _this = this;
      let formData = new FormData();
      formData.append("file", file.target.files[0]);
      _this.loading = true;
      document.getElementById("add_pdf").value = "";
      this.$http
        .post("/import_pdf_part", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          // update SVG with rendered SVG part
          _this.add_svg_content(response);

          _this.loading = false;
        })
        .then(function (response) {
          _this.loading = false;
        });

      return;
    },
    file_selected(file) {
      // /import_pdf_file
      if (file.target.files.length == 0) return;

      let _this = this;
      let formData = new FormData();
      formData.append("file", file.target.files[0]);
      formData.append("proposal_id", _this.proposal.id);
      _this.loading = true;
      document.getElementById("pdf_file").value = "";
      this.$http
        .post("/import_pdf_file", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          _this.set_files_list(_this.files_list.concat(response.body.files));
          _this.loading = false;
        })
        .then(function (response) {
          _this.loading = false;
        });

      return;
    },
    // initialize scratchpad before when drawing
    scratchpad_init() {
      let _this = this;
      _this.svg_inner_container = d3.select(
        "#proposal_workspace .svg_inner_container"
      );
      d3.selectAll(".scratch_drawing").remove();
      d3.selectAll(".scratchpad").remove();
      _this.svg_inner_container
        .append("g")
        .classed("scratchpad", true)
        .append("rect")
        .attr("width", _this.page_settings.screen_width / _this.page_scale)
        .attr("height", _this.page_settings.screen_height / _this.page_scale)
        .attr("cursor", "crosshair")
        .attr("fill", "rgba(240, 248, 255, 0.75)")
        .attr("stroke", "lightblue")
        .attr("stroke-width", `${3 / this.page_scale}px`);
    },
    poly_drawing(shape) {
      let _this = this;
      let points = [];
      this.update_current_mode(svg_modes.DRAWING);
      _this.scratchpad_init();
      _this.svg_inner_container = d3.select(
        "#proposal_workspace .svg_inner_container"
      );

      let poly = d3.select(".scratchpad").insert(shape);
      poly.attr("points", "");

      _this.svg_inner_container
        .select(".scratchpad")
        .on("mousedown", function () {
          var m = d3.mouse(this);
          poly
            .node()
            .setAttribute(
              "points",
              `${poly.node().getAttribute("points")} ${m[0]},${m[1]}`
            );
        });
      this.set_file_changed(true);
    },
    // create rectangles continously by
    // placing 2 points as a zigzag line for fast drawing
    multiple_rectangles() {
      let _this = this;
      this.update_current_mode(svg_modes.DRAWING);
      _this.scratchpad_init();
      _this.svg_inner_container = d3.select(
        "#proposal_workspace .svg_inner_container"
      );

      let points = [];
      _this.svg_inner_container
        .select(".scratchpad")
        .on("mousedown", function () {
          var m = d3.mouse(this);
          points.push(m);
          if (points.length < 2) {
            console.log("point 1 only", m);
            return;
          } else {
            console.log("point 2 only", m);
            console.log("the 2 points: ", points);
            // draw rectangle
            let rect = d3
              .select(".svg_inner_container .svg_layer.active_layer")
              .insert("rect");
            let xDelta = Math.abs(points[1][0] - points[0][0]);
            let yDelta = Math.abs(points[1][1] - points[0][1]);
            let xStart =
              points[0][0] < points[1][0] ? points[0][0] : points[1][0];
            let yStart =
              points[0][1] < points[1][1] ? points[0][1] : points[1][1];
            rect
              .attr("x", xStart)
              .attr("y", yStart)
              .attr("width", xDelta)
              .attr("height", yDelta)
              .attr("fill", "lightblue")
              .attr("stroke-width", "300px")
              .attr("stroke", "lightblue");
            _this.attach_events(rect);
            // clear points
            points = [];
          }
        });
      this.set_file_changed(true);
    },
    freehand_drawing() {
      this.update_current_mode(svg_modes.DRAWING);
      let _this = this;
      _this.svg_inner_container = d3.select(
        "#proposal_workspace .svg_inner_container"
      );
      _this.scratchpad_init();

      var line = d3.line(".scratchpad").curve(d3.curveCardinal);
      _this.svg_inner_container.select(".scratchpad").call(
        d3
          .drag()
          .container(function () {
            return this;
          })
          .subject(function () {
            var p = [Math.round(d3.event.x), Math.round(d3.event.y)];
            return [p, p];
          })
          .on("start", (d) => {
            var d = d3.event.subject,
              active = _this.svg_inner_container
                .select(".scratchpad")
                .append("path")
                .style("stroke-width", `${3 / this.page_scale}px`)
                .datum(d),
              x0 = Math.round(d3.event.x),
              y0 = Math.round(d3.event.y);

            d3.event.on("drag", function () {
              var x1 = Math.round(d3.event.x),
                y1 = Math.round(d3.event.y),
                dx = x1 - x0,
                dy = y1 - y0;
              if (dx * dx + dy * dy > 100) d.push([(x0 = x1), (y0 = y1)]);
              else d[d.length - 1] = [x1, y1];
              active.attr("d", line);
            });
          })
      );
    },
    zoom_to_selected() {
      let relative = this.get_relative_boundaries(this.selected_object);
      let scale = Math.max(
        1,
        Math.min(
          this.page_settings.screen_width / relative.width,
          this.page_settings.screen_height / relative.height
        ) * 0.9
      );
      // let translate = [
      //   this.page_settings.screen_width / 2 - scale * relative.x1 -   relative.width,
      //   this.page_settings.screen_height / 2 - scale * relative.y1 -   relative.height
      // ];
      let translate = [
        -(scale * relative.x1) * 0.95,
        -(scale * relative.y1) * 0.95,
      ];

      this.svg
        .transition()
        .duration(750)
        .call(
          this.zoom.transform,
          d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale)
        );
    },
    // move objects to front in the same layer
    move_to_front() {
      this.reorder_element(move_types.FRONT);
    },
    // send objects back in the same layer
    send_to_back() {
      this.reorder_element(move_types.BACK);
    },
    // duplicate selected features
    copy_selected() {
      let _this = this;
      let selected_graphics = d3.selectAll('[data-selected="true"]');
      var new_svg = d3
        .select("svg .svg_inner_container")
        .node()
        .cloneNode(true);

      //new_svg.selectAll(".svg_layer").remove();
      d3.select(new_svg)
        .selectAll(".svg_layer")
        .remove();
      new_svg = d3.select(new_svg);
      new_svg
        .selectAll(".svg_inner_container .layers")
        .append("g")
        .attr("class", "svg_layer active_layer base_layer")
        .attr("data-name", "Base layer");

      let new_layer = new_svg
        .selectAll(".svg_inner_container .layers")
        .append("g")
        .attr("class", "svg_layer")
        .attr("data-name", "new layer");

      new_layer.append("g");

      selected_graphics.each(function () {
        let cloned_el = this.cloneNode(true);
        cloned_el.removeAttribute("data-selected");
        let matrix_arr = _this.get_matrix_transformation(this).reverse();
        let i;
        let g = new_layer.select("g").node();
        for (i = 0; i < matrix_arr.length; i++)
          g = d3
            .select(g)
            .append("g")
            .attr("transform", matrix_arr[i])
            .node();
        g.append(cloned_el);
      });

      let params = {
        proposal_id: _this.proposal.id,
        name: "New Page",
        page_width: _this.page_settings.width,
        page_height: _this.page_settings.height,
        page_margin: _this.page_settings.margin,
      };
      this.add_new_working_file(params).then(
        (response) => {
          _this.files_list.push({
            id: response.body.id,
            name: "New Page",
            thumb: response.body.thumb,
          });
          // upload content to new working file
          this.save_file({
            svg_content: new_svg.node(),
            working_file_id: response.body.id,
          });
        },
        (response) => {
          this.$message({
            type: "error",
            message: "Error",
          });
        }
      );
    },
    // reorder selected object to front or back
    // in same layer
    reorder_element(move_type) {
      let parent = d3.select(this.get_parent_layer(this.selected_object));
      let g;

      if (move_type == move_types.FRONT) g = parent.append("g");
      else if (parent.node().childElementCount > 0) {
        g = parent.insert("g", "g:first-child");
      } else g = parent.insert("g");

      let matrix_arr = this.get_matrix_transformation(
        this.selected_object
      ).reverse();
      let i;
      for (i = 0; i < matrix_arr.length; i++)
        g = d3
          .select(g.node())
          .append("g")
          .attr("transform", matrix_arr[i]);
      g.node().appendChild(this.selected_object);
      this.set_file_changed(true);
    },
    // toolbar select event
    handleSelect(key, keyPath) {
      switch (key) {
        // Open PDF Import dialog
        case "open_file":
          this.import_pdf_preview = true;
          return;
        // Append PDF content to current working file
        case "add_pdf":
          document.getElementById("add_pdf").click();
          return;
        // Save SVG content in DB
        case "save_file":
          this.save_file();
          return;
        // Import image to current working file
        case "import_image":
          document.getElementById("image_file").click();
          return;
        // Draw rectangle shapes continuously by clicking 2 points
        case "multiple_rectangles":
          this.multiple_rectangles();
          return;
        // Delete selected element
        case "delete_node":
          this.delete_node(this.selected_object);
          return;
        // Duplicate selected element
        case "duplicate_node":
          this.duplicate_object();
          return;
        // Zoom to working area
        case "zoom_to_fit":
          this.resetted(true);
          return;
        // Zoom to selected object
        case "zoom_to_selected":
          this.zoom_to_selected();
          return;
        // move selected element to front
        case "move_to_front":
          this.move_to_front();
          return;
        // send selected element to back
        case "send_to_back":
          this.send_to_back();
          return;
        // Clear all shapes in editor
        case "clear_all":
          this.clear_all();
          return;
        // copy selected shapes to new working file
        case "copy_selected":
          this.copy_selected();
          return;
        // export to pdf
        case "to_pdf":
          this.to_pdf();
          return;
        // layup selected object against stocks
        case "layup":
          this.layup();
          return;
        // activate selection mode
        case "select_mode":
          this.update_current_mode(svg_modes.SELECTING);
          return;
        // activate cropping feature
        case "activate_cropping":
          this.activate_cropping();
          return;
        // end cropping
        case "extract_selected":
          this.extract_selected();
          return;
        // activate freehand drawing
        case "path":
          this.freehand_drawing();
          return;
        // draw polygon
        case "polygon":
          this.poly_drawing("polygon");
          return;
        // draw polyline
        case "polyline":
          this.poly_drawing("polyline");
          return;
        // end selection mode
        case "end_selection":
          this.end_selection();
          return;
        // group selected objects under <g> element
        case "group_objects":
          this.group_objects();
          return;
        // ungroup objects located in a <g> element
        case "ungroup_objects":
          this.ungroup_objects();
          return;
        // Generate annotations around selected objects
        case "generate_labels":
          this.generate_labels();
          return;
        // scale selected objects by drawing a line
        case "scale_by_line":
          this.scale_by_points();
          return;
        // Crop images that are located in the drawn boundary
        case "crop_area":
          this.crop_image();
          return;
        // undo action
        case "undo_action":
          this.undo();
          return;
      }

      let _this = this;
      let result = this.shapes_templates.filter((c) => c.name == key);

      if (result.length > 0) {
        // set first svg_layer to active if no active layer set
        if (
          d3.select(".svg_inner_container .svg_layer.active_layer").node() ==
          undefined
        )
          d3.select(".svg_inner_container .svg_layer").classed(
            "active_layer",
            true
          );

        // Add new shape to active layer
        let shape = d3
          .select(".svg_inner_container .svg_layer.active_layer")
          .insert(key);
        let _result = result[0];
        let num_fields = [
          "width",
          "height",
          "x",
          "y",
          "font-size",
          "cx",
          "cy",
          "r",
        ];
        // setting all default attribtues of newly added shape
        Object.keys(_result.attributes || []).forEach(function (el, index) {
          if (num_fields.indexOf(el) > -1)
            shape.attr(
              el,
              parseFloat(_result.attributes[el]) / _this.page_scale
            );
          else shape.attr(el, _result.attributes[el]);
        });

        // setting all default styling of newly added shape
        Object.keys(_result.styles || []).forEach(function (el, index) {
          if (num_fields.indexOf(el) > -1) {
            shape.style(el, parseFloat(_result.styles[el]) / _this.page_scale);
          } else {
            shape.style(el, _result.styles[el]);
          }
        });

        if (_result.functions)
          Object.keys(_result.functions).forEach(function (el, index) {
            shape[el](_result.functions["text"]);
          });
        this.attach_events(shape);

        this.editor_actions.push({
          action: action_types.ADD,
          object: shape,
          attributes: [],
        });
      } else if (key == "image") {
      }
    },
    add_editor_action(data) {
      this.editor_actions.push(data);
    },
    undo() {
      if (this.editor_actions.isEmpty() == false) {
        this.set_selected_object(null);

        let data = this.editor_actions.last().data;
        if (data.object == null) {
          this.editor_actions.pop();
          return;
        }
        switch (data.action) {
          case action_types.ADD:
            data.object.remove();
            this.load_layers_tree();
            break;
          case action_types.DELETE:
            d3.select(data.parent)
              .node()
              .appendChild(data.object);
            break;
          case action_types.EDIT:
            data.attributes.forEach((el, idx) => {
              switch (el.type) {
                case "attribute":
                  d3.select(data.object).attr(el.key, el.value);
                  break;
                case "style":
                  data.object["style"][el.key] = el.value;
                  break;
                case "object":
                  data.object[el.key] = el.value;
                  break;
              }
            });
            break;
        }
        this.editor_actions.pop();
        this.set_file_changed(true);
      }
    },
    // move elements using keyboard keys
    move_selected(key) {
      let factor = d3.event.ctrlKey || d3.event.metaKey == true ? 5 : 1;
      factor /= this.page_scale;
      if (d3.event.altKey == true) {
        let font_d = d3.event.key == "ArrowUp" ? 1 : -1;
        font_d /= this.page_scale;
        let font_size = d3.select(this.selected_object).style("font-size");
        if (font_size != undefined)
          d3.select(this.selected_object).style(
            "font-size",
            parseInt(font_size) + font_d + "px"
          );
        else d3.select(this.selected_object).style("font-size", "20px");
      }

      this.editor_actions.push({
        action: action_types.EDIT,
        object: this.selected_object,
        attributes: [
          {
            type: "attribute",
            key: "transform",
            value:
              this.selected_object.attributes.transform == undefined
                ? null
                : this.selected_object.attributes.transform.value,
          },
        ],
      });
      switch (d3.event.key) {
        case "ArrowLeft":
          this.update_coordinates_by(-1 * factor, 0);
          break;
        case "ArrowRight":
          this.update_coordinates_by(1 * factor, 0);
          break;
        case "ArrowUp":
          this.update_coordinates_by(0, -1 * factor);
          break;
        case "ArrowDown":
          this.update_coordinates_by(0, 1 * factor);
          break;
      }

      this.create_boundary(this.selected_object);
      this.set_file_changed(true);
    },
    scale_by_points() {
      let _this = this;

      if (_this.selected_object == null) return;
      this.scaling_object = this.selected_object;
      this.scaling_points = [];

      this.scratchpad_init();

      d3.select(".scratchpad")
        .append("line")
        .attr("id", "scaling_line")
        .attr("stroke", "red")
        .attr("stroke-wdith", "10%");
      d3.select(".scratchpad").on("click", function () {
        var coords = d3.mouse(this);
        if (_this.scaling_points.length == 0)
          d3.select("#scaling_line")
            .attr("x1", coords[0])
            .attr("y1", coords[1]);
        else if (_this.scaling_points.length == 1) {
          _this.coordinates_correction(coords);
        }
        _this.scaling_points.push(coords);
        if (_this.scaling_points.length == 2) {
          _this.show_scaling_points_dialog();
        }
      });

      d3.select(".scratchpad").on("mousemove", function () {
        if (_this.scaling_points.length == 1) {
          var coords = d3.mouse(this);
          _this.coordinates_correction(coords);
        }
      });
    },
    coordinates_correction(coords) {
      let x1 = parseFloat(d3.select("#scaling_line").attr("x1"));
      let y1 = parseFloat(d3.select("#scaling_line").attr("y1"));

      let x2 = coords[0];
      let y2 = coords[1];

      let deltaX = Math.abs(coords[0] - x1);
      let deltaY = Math.abs(coords[1] - y1);

      if (deltaX > deltaY) {
        x2 = coords[0];
        y2 = y1;
      } else {
        x2 = x1;
        y2 = coords[1];
      }

      d3.select("#scaling_line")
        .attr("x2", x2)
        .attr("y2", y2);
    },
    get_line_factor(line, new_distance) {
      // Method used to calculate x or y factor
      // against new mm distance defined by the user
      let factor = { scaleX: 1, scaleY: 1 };
      if (Math.round(line.x1) == Math.round(line.x2)) {
        let deltaY = line.y2 - line.y1;
        let detaYmm = Math.round(Math.abs(deltaY) / mm_to_px);
        factor.scaleY = new_distance / detaYmm;
      } else {
        let deltaX = line.x2 - line.x1;
        let detaXmm = Math.round(Math.abs(deltaX) / mm_to_px);
        factor.scaleX = new_distance / detaXmm;
      }
      return factor;
    },
    confirm_scale_by_points() {
      if (
        this.scale_by_point_data.distance == undefined ||
        this.scale_by_point_data.distance == 0
      ) {
        this.$message({
          type: "warning",
          message: "Please enter the distance.",
        });
        return false;
      }
      let factor = this.get_line_factor(
        this.scale_by_point_data.scaling_line,
        parseFloat(this.scale_by_point_data.distance)
      );
      this.update_object_scale(
        this.scaling_object,
        factor.scaleX,
        factor.scaleY,
        true,
        this.scale_by_point_data.maintain_aspect_ratio
      );
      this.scaling_object = null;
      this.$message({
        type: "success",
        message: "Selected object has been scaled successfully.",
      });
      this.scale_by_points_dialog = false;
    },
    show_scaling_points_dialog() {
      this.scaling_points = [];
      d3.select(".scratchpad").on("click", null);
      d3.select(".scratchpad").on("mousemove", null);

      let scale_obj = d3.select("#scaling_line");
      let scaling_line = {
        x1: scale_obj.attr("x1"),
        y1: scale_obj.attr("y1"),
        x2: scale_obj.attr("x2"),
        y2: scale_obj.attr("y2"),
      };

      let current_length = scale_obj.node().getTotalLength();
      current_length = Math.round(current_length / mm_to_px);
      d3.select(".scratchpad").remove();
      this.scale_by_points_dialog = true;
      let _this = this;
      setTimeout(() => {
        _this.set_inputs_to_default();
      }, 500);
      this.scale_by_point_data.scaling_line = scaling_line;
      this.scale_by_point_data.distance = current_length;
    },
    end_selection() {
      this.clear_brush();
      this.clear_selection();
      this.end_drawing();
      this.update_current_mode(svg_modes.NAVIGATION);
    },
    delete_selected_node() {
      this.delete_node(this.selected_object);
    },
    // bind keyboard shortcuts
    bind_shortcuts() {
      let _this = this;
      d3.select("#proposal_workspace")
        .on("keydown", function () {
          // Editing a text
          if (
            _this.selected_object != undefined &&
            _this.selected_object.nodeName == "text" &&
            document.activeElement == document.getElementById("foreign_input")
          ) {
            return true;
          }

          // zoom out
          switch (d3.event.key) {
            case "Escape":
              _this.end_selection();
              break;
            case "Delete":
            case "Backspace":
              _this.delete_node(_this.selected_object);
              break;
            case "ArrowLeft":
            case "ArrowRight":
            case "ArrowUp":
            case "ArrowDown":
              _this.move_selected(d3.event.key);
              break;
          }

          d3.event.preventDefault();
          return;
        })
        .on("focus", function () {
          d3.event.preventDefault();
        })
        .on("click", () => {
          if (_this.current_mode != svg_modes.SELECTING) {
            _this.set_selected_object(null);
            _this.update_current_mode(svg_modes.NAVIGATION);
          }
        });
    },
    bind_mouse() {
      let _this = this;
      let screen_width = this.page_settings.screen_width;
      let screen_height = this.page_settings.screen_height;

      let width = this.page_settings.width;
      let height = this.page_settings.height;

      d3.select(".svg_outer_container").on("mousemove", function () {
        var point = d3.mouse(this);
        let xLinear = d3
          .scaleLinear()
          .domain([0, screen_width])
          .range([0, width]);
        let yLinear = d3
          .scaleLinear()
          .domain([0, screen_height])
          .range([0, height]);
        _this.live_coordinates =
          "X: " +
          Math.round(xLinear(point[0]) * 100) / 100 +
          " , Y: " +
          Math.round(yLinear(point[1]) * 100) / 100;
      });
    },
    get_selected_object_coordinates() {
      let x = 0;
      let y = 0;
      if (
        this.selected_object != null &&
        this.selected_object.transform.animVal.length > 0
      ) {
        x = this.selected_object.transform.animVal[0].matrix.e;
        y = this.selected_object.transform.animVal[0].matrix.f;
      }
      return { x: x, y: y };
    },
    update_selected_obj_coordinates(x, y) {
      let translated = false;
      let temp_idx = -1;

      Array.from(this.selected_object.transform.baseVal).forEach((el, idx) => {
        if (el.type == 2) {
          el.setTranslate(x, y);
          translated = true;
        } else if (el.type == 4 || el.type == 3) {
          temp_idx = temp_idx != -1 && idx < temp_idx ? idx : temp_idx;
          if (temp_idx == -1 || (temp_idx != -1 && idx < temp_idx))
            temp_idx = idx;
        }
      });

      if (translated == false) {
        let trans = this.selected_object.ownerSVGElement.createSVGTransform();
        trans.setTranslate(x, y);
        if (temp_idx != -1)
          this.selected_object.transform.baseVal.insertItemBefore(
            trans,
            temp_idx
          );
        else this.selected_object.transform.baseVal.appendItem(trans);
      }
      return;

      let selected_object = this.selected_object;
      if (selected_object.transform.baseVal.length > 0) {
        // loop
        if (selected_object.transform.baseVal[0].type == 1) {
          // MATRIX
          selected_object.transform.baseVal[0].matrix.e = x;
          selected_object.transform.baseVal[0].matrix.f = y;
        } else if (selected_object.transform.baseVal[0].type == 2) {
          // translate
          selected_object.transform.baseVal[0].setTranslate(x, y);
        }
      } else {
        selected_object.setAttribute(
          "transform",
          " translate(" + x + "," + y + ") "
        );
      }
    },
    clientToGlobalFactor(obj) {
      let userToClient = 1;
      let clientToGlobal =
        d3
          .select(".svg_inner_container")
          .node()
          .getBBox().width /
        d3
          .select(".svg_inner_container")
          .node()
          .getBoundingClientRect().width;

      if (obj.getBBox().width > 0) {
        userToClient = obj.getBoundingClientRect().width / obj.getBBox().width;
      } else {
        userToClient =
          obj.getBoundingClientRect().height / obj.getBBox().height;
      }
      let factor = this.page_scale * clientToGlobal * userToClient;
      return factor;
    },
    // create frame around the selected object
    create_frame_selection(frame) {
      let _this = this;
      d3.selectAll(".frame_selection").remove();
      let child_items = d3.selectAll(".svg_inner_container [data-selected]")
        ._groups[0];
      let x1 = 0;
      let y1 = 0;
      let x2 = 0;
      let y2 = 0;
      Array.from(child_items).forEach(function (el, index) {
        let abs = _this.get_relative_boundaries(el);

        if (index == 0) {
          x1 = abs.x1;
          y1 = abs.y1;
          x2 = abs.x2;
          y2 = abs.y2;
        } else {
          if (abs.x1 < x1) x1 = abs.x1;
          if (abs.y1 < y1) y1 = abs.y1;
          if (abs.x2 > x2) x2 = abs.x2;
          if (abs.y2 > y2) y2 = abs.y2;
        }
      });

      if (child_items.length > 0)
        d3.selectAll(".svg_outer_container")
          .insert("g")
          .classed("frame_selection", true)
          .attr("fill", "none")
          .attr("stroke", "#eaea02")
          .append("rect")
          .attr("stroke-width", "0.1%")
          .attr("x", x1)
          .attr("y", y1)
          .attr("width", x2 - x1)
          .attr("height", y2 - y1);
    },
    // scale elmenet according to new value
    scale_object(elem, scale) {
      let updated = false;
      if (elem.transform.baseVal.length > 0) {
        Array.from(elem.transform.baseVal).forEach(function (el, index) {
          if (el.type == 3) {
            el.matrix.a = el.matrix.a + scale.x;
            el.matrix.d = el.matrix.d + scale.y;
            updated = true;
          }
        });
      }

      if (updated == false && elem.transform.baseVal.length == 0)
        elem.setAttribute("transform", ` scale(${scale.x})`);
      else
        elem.setAttribute(
          "transform",
          `${elem.getAttribute("transform")} scale(${scale.x})`
        );
      this.set_selected_object(this.selected_object);
    },
    // update selected object coordinates by deltaX & deltaY
    update_coordinates_by(x, y) {
      let coordinates = this.get_selected_object_coordinates();
      // Current position:
      this.xCoord = coordinates.x + x;
      this.yCoord = coordinates.y + y;
      this.update_selected_obj_coordinates(this.xCoord, this.yCoord);
    },
    // Update selected object boundary
    update_boundary(e) {
      let group = d3.select(".selected_object_frame");
      let relative_boundary = this.get_relative_boundaries(e);

      let rect = group.select("rect");
      if (rect.node() == null) rect = group.insert("rect", ":first-child");
      rect
        .attr("x", relative_boundary.x1 / this.page_scale)
        .attr("y", relative_boundary.y1 / this.page_scale)
        .attr("width", relative_boundary.width / this.page_scale)
        .attr("height", relative_boundary.height / this.page_scale);

      let handle = group.select(".handle");
      if (handle.node() == null)
        handle = group
          .insert("circle", ":first-child")
          .attr("r", 3)
          .attr("fill", "#cdd7ef")
          .attr("stroke", "#a7bef8")
          .attr("stroke-dasharray", "1.5%")
          .attr("stroke-width", `10px`)
          .classed("handle", true);
      handle
        .attr("cx", relative_boundary.x1 / this.page_scale)
        .attr("cy", relative_boundary.y1 / this.page_scale);

      if (this.selected_object.nodeName != "path") {
        let resize = group.select(".resize");
        if (resize.node() == null)
          resize = group
            .insert("circle", ":first-child")
            .attr("r", 3)
            .attr("fill", "cornflowerblue")
            .attr("stroke", "#a7bef8")
            .attr("stroke-width", this.stroke_width(8))
            .classed("resize", true);
        resize
          .attr("cx", relative_boundary.x2 / this.page_scale)
          .attr("cy", relative_boundary.y2 / this.page_scale);
      }
      //this.set_file_changed(true);
      return;
    },
    // Create boundary around selected object
    create_boundary(e) {
      let _this = this;
      d3.selectAll(".selected_object_frame").remove();
      if (e == null) return;

      let client_to_global_factor = this.clientToGlobalFactor(e);
      let attributes = [];
      if (e != null)
        attributes = [
          {
            type: "attribute",
            key: "transform",
            value:
              e.attributes.transform != undefined
                ? e.attributes.transform.value
                : null,
          },
        ];

      if (e.nodeName == "image") {
        d3.select(e).call(
          d3
            .drag()
            .on("start", function (d, i) {
              // d3.select(e).style("cursor", "grabbing");
            })
            .on("drag", function (d, i) {
              _this.update_coordinates_by(d3.event.dx, d3.event.dy);
            })
            .on("end", function (d, i) {
              _this.refresh_page_size();
              d3.select(e).style("cursor", "pointer");
              if (e != null && attributes.length > 0)
                _this.editor_actions.push({
                  action: action_types.EDIT,
                  object: e,
                  attributes: attributes,
                });
            })
        );
      }

      let group = d3
        .select(".svg_inner_container")
        .append("g", ":first-child")
        .classed("selected_object_frame", true)
        .style("outline-width", "10px");
      _this.update_boundary(e);

      d3.select(".selected_object_frame rect").call(
        d3
          .drag()
          .on("start", function (d, i) {
            d3.select(e)
              .raise()
              .classed("active", true);
            // d3.select(e).style("cursor", "grabbing");
          })
          .on("drag", function (d, i) {
            _this.update_coordinates_by(d3.event.dx, d3.event.dy);
            if (this.getAttribute("transform") != null) {
              _this.update_boundary(_this.selected_object);
              return;
            }
            _this.update_boundary(_this.selected_object);
            return;
          })
          .on("end", function (d, i) {
            _this.refresh_page_size();
            d3.select(e).classed("active", false);
            d3.select(e).style("cursor", "pointer");
            if (e != null && attributes.length > 0)
              _this.editor_actions.push({
                action: action_types.EDIT,
                object: e,
                attributes: attributes,
              });
            _this.set_file_changed(true);
          })
      );

      attributes.push({
        type: "attribute",
        key: "width",
        value: e.getAttribute("width"),
      });
      attributes.push({
        type: "attribute",
        key: "height",
        value: e.getAttribute("height"),
      });

      switch (e.nodeName) {
        case "circle":
          attributes.push({
            type: "attribute",
            key: "r",
            value: e.getAttribute("r"),
          });
          break;
        case "ellipse":
          attributes.push({
            type: "attribute",
            key: "rx",
            value: e.getAttribute("rx"),
          });
          attributes.push({
            type: "attribute",
            key: "ry",
            value: e.getAttribute("ry"),
          });
          break;
      }

      // resize drag event
      d3.select(".selected_object_frame .resize").call(
        d3
          .drag()
          .on("start", function (d, i) {
            d3.select(e)
              .raise()
              .classed("active", true);
          })
          .on("drag", function (d, i) {
            // rectangles or circles

            if (_this.selected_object.nodeName == "circle") {
              let updated_r = 0;
              if (d3.event.dx != 0) updated_r = d3.event.dx > 0 ? 1 : -1;
              else if (d3.event.dx != 0) updated_r = d3.event.dy > 0 ? 1 : -1;

              let r = parseInt(_this.selected_object.getAttribute("r"));
              _this.selected_object.setAttribute(
                "r",
                r + updated_r / _this.page_scale
              );
            } else if (_this.selected_object.nodeName == "ellipse") {
              let rx =
                parseFloat(_this.selected_object.getAttribute("rx")) +
                parseInt(d3.event.dx);
              let ry =
                parseFloat(_this.selected_object.getAttribute("ry")) +
                parseInt(d3.event.dy);
              _this.selected_object.setAttribute("rx", rx);
              _this.selected_object.setAttribute("ry", ry);
            } else if (
              ["line", "path", "polyline", "polygon", "ellipse"].indexOf(
                _this.selected_object.nodeName
              ) > -1
            ) {
              let scaleX = 1;
              let scaleY = 1;
              if (d3.event.dx != 0) {
                scaleX =
                  (_this.selected_object.getBBox().width + d3.event.dx) /
                  _this.selected_object.getBBox().width;
              }
              if (d3.event.dy != 0) {
                scaleY =
                  (_this.selected_object.getBBox().height + d3.event.dy) /
                  _this.selected_object.getBBox().height;
              }
              _this.update_object_scale(_this.selected_object, scaleX, scaleY);
            } else if (_this.selected_object.nodeName == "text") {
            }
            let strokeWidth = _this.selected_object.style.strokeWidth;
            let width = _this.selected_object.getBBox().width + d3.event.dx;
            let height = _this.selected_object.getBBox().height + d3.event.dy;

            _this.selected_object.setAttribute("width", width);
            _this.selected_object.setAttribute("height", height);

            _this.update_boundary(_this.selected_object);
            return;
          })
          .on("end", function (d, i) {
            _this.refresh_page_size();
            if (e != null && attributes.length > 0)
              _this.editor_actions.push({
                action: action_types.EDIT,
                object: e,
                attributes: attributes,
              });

            d3.select(e).classed("active", false);
            _this.set_selected_object(_this.selected_object);
            _this.update_nesting();
            _this.set_file_changed(true);
          })
      );
    },
    // Attach click/dblClick event for an elment
    attach_events(vue_element) {
      let _this = this;
      let elem =
        vue_element._groups == undefined ? vue_element : vue_element.node();
      d3.select(elem).on("click", function () {
        // if current selected object is working area of a group
        if (elem.classList.contains("working_area_frame") == true)
          elem = this.parentElement;

        if (d3.event.shiftKey || _this.current_mode == "selecting") {
          if (_this.selected_object != null) _this.set_selected_object(null);

          if (elem.hasAttribute("data-selected"))
            elem.removeAttribute("data-selected");
          else elem.setAttribute("data-selected", true);
          _this.create_frame_selection(elem);
        } else {
          _this.set_selected_object(elem);
          elem.setAttribute("data-selected", true);
        }
        d3.event.stopPropagation();
      });

      if (elem.nodeName == "text") {
        d3.select(elem).on("dblclick", function (e) {
          _this.edit_text = "";
          if (elem.childElementCount > 0)
            Array.from(elem.children).forEach(function (el, index) {
              _this.edit_text += el.textContent + "\n";
            });
          else _this.edit_text = elem.innerHTML;
          _this.edit_text_dialog = true;
          d3.event.preventDefault();
          d3.event.stopPropagation();
        });
      }
    },
    make_click_event() {
      d3.select(".svg_inner_container .base_layer").selectAll(
        " text:not(.grouped), path:not(.grouped), circle:not(.grouped), " +
        " rect:not(.grouped), image:not(.grouped), line:not(.grouped), " +
        " line:not(.grouped), polyline:not(.grouped), polygon:not(.grouped)," +
        " ellipse:not(.grouped), g.working_area_frame, .svg_layer g.grouped:not(.svg_layer)"
      ).dispatch('click');
    },
    bind_events(vue_comp) {
      let _this = this;
      d3.select(".svg_inner_container")
        .selectAll(
          " text:not(.grouped), path:not(.grouped), circle:not(.grouped), " +
          " rect:not(.grouped), image:not(.grouped), line:not(.grouped), " +
          " line:not(.grouped), polyline:not(.grouped), polygon:not(.grouped)," +
          " ellipse:not(.grouped), g.working_area_frame, .svg_layer g.grouped:not(.svg_layer)"
        )
        .on("click", function () {
          let elem = this;
          if (elem.classList.contains("working_area_frame") == true)
            elem = elem.parentElement;

          if (d3.event.shiftKey || _this.current_mode == "selecting") {
            if (_this.selected_object != null) _this.set_selected_object(null);

            if (elem.hasAttribute("data-selected"))
              elem.removeAttribute("data-selected");
            else elem.setAttribute("data-selected", true);

            _this.create_frame_selection(elem);
          } else {
            vue_comp.set_selected_object(elem);
            elem.setAttribute("data-selected", true);
          }
          d3.event.stopPropagation();
        });

      d3.selectAll(".svg_inner_container text").on("dblclick", function () {
        vue_comp.select_text(this);
        d3.event.preventDefault();
        d3.event.stopPropagation();
      });
    },
    select_text(elem) {
      let _this = this;
      _this.edit_text = "";
      if (elem.childElementCount > 0)
        Array.from(elem.children).forEach(function (el, index) {
          _this.edit_text += el.textContent + "\n";
        });
      else _this.edit_text = elem.innerHTML;
      _this.edit_text_dialog = true;
    },
    load_grid() {
      if (this.svg == "") {
        this.svg = d3.select("#proposal_workspace");
      }
      // get screen specs from page settings
      let screen_width = this.page_settings.screen_width;
      let screen_height = this.page_settings.screen_height;

      let width = this.page_settings.width;
      let height = this.page_settings.height;

      this.zoom = d3
        .zoom()
        .scaleExtent([0.1, 40])
        .on("zoom", this.zoomed);

      let selected_unit = this.grid_measurements.filter(
        (c) => c.unit == this.grid_settings.unit
      );

      // define x axis
      this.x = d3
        .scaleLinear()
        .domain([0, width])
        .range([0, screen_width]);

      // define y axis
      this.y = d3
        .scaleLinear()
        .domain([0, height])
        .range([0, screen_height]);

      let tick_size = ((width + 2) / (height + 2)) * 15;
      let font_size = Math.min(Math.max(parseInt(screen_width / 100), 5), 40);

      this.xAxis = d3
        .axisBottom(this.x)
        .ticks(tick_size)
        .tickSize(screen_height)
        .tickPadding(3 - screen_height);

      this.yAxis = d3
        .axisRight(this.y)
        .ticks(tick_size)
        .tickSize(screen_width)
        .tickPadding(3 - screen_width);

      d3.select(".axis--x").attr("font-size", font_size);
      d3.select(".axis--y").attr("font-size", font_size);

      if (this.gX == "") {
        this.gX = this.svg
          .append("g")
          .attr("class", "axis axis--x")
          .call(this.xAxis);
      } else this.gX.call(this.xAxis);

      if (this.gY == "") {
        this.gY = this.svg
          .append("g")
          .attr("class", "axis axis--y")
          .call(this.yAxis);
      } else this.gY.call(this.yAxis);

      this.svg.call(this.zoom);
      return;
    },
    import_image(file) {
      if (file.target.files.length == 0) return;
      let _this = this;
      let file_content = file.target.files[0];

      let formData = new FormData();
      if (_this.proposal.is_template == true)
        formData.append(
          "current_path",
          `Templates/Quotes/${_this.proposal.id}/job assets/assets/`
        );
      else
        formData.append(
          "current_path",
          `Accounts/${_this.proposal.account_name} ${_this.proposal.account_no}/Jobs/${_this.proposal.id}/job assets/assets/`
        );
      formData.append("file", file.target.files[0]);
      _this.$http.post("/upload_file_to_storage", formData).then(
        (response) => {
          _this.$message({
            message: "File has been uploaded to 'Assets' folder",
            type: "success",
          });
        },
        (response) => {

        }
      );

      const reader = new FileReader();

      // Set the image once loaded into file reader
      reader.onload = function (e) {
        let img = document.createElement("img");
        img.src = reader.result;

        img.onload = function (ev) {
          var canvas = document.createElement("canvas");
          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);

          var MAX_WIDTH = 400;
          var MAX_HEIGHT = 400;
          var width = img.width;
          var height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);

          let dataurl = canvas.toDataURL(file.type);
          //document.getElementById("output").src = dataurl;

          let shape = d3
            .select(".svg_inner_container .svg_layer.active_layer")
            .append("g")
            .append("svg:image");

          shape
            .attr("xlink:href", dataurl)
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", 0)
            .attr("height", 0);

          let new_img = new Image();
          new_img.onload = function () {
            shape.attr("width", new_img.width).attr("height", new_img.height);

            _this.attach_events(shape);
          };
          new_img.src = dataurl;
          document.getElementById("image_file").value = "";
          _this.editor_actions.push({
            action: action_types.ADD,
            object: shape,
            attributes: [],
          });
        };
      };
      reader.readAsDataURL(file_content);
      return;
    },
    update_object_scale(
      elem,
      newScaleX,
      newScaleY,
      set_tranlsate = false,
      maintain_aspect_ratio = false
    ) {
      let scaleX = 1;
      let scaleY = 1;
      let scale;
      let scaled = false;
      let rotate_trans_idx = -1;

      if (maintain_aspect_ratio == true) {
        if (newScaleX != 1) newScaleY = newScaleX;
        else newScaleX = newScaleY;
      }

      if (set_tranlsate == true) {
        let trans = elem.ownerSVGElement.createSVGTransform();
        let trans_coords = this.get_relative_boundaries(elem);

        if (newScaleX == newScaleY)
          trans.setTranslate(
            -(elem.getBBox().x / newScaleX) / this.page_scale,
            -(elem.getBBox().y / newScaleY) / this.page_scale
          );
        else if (newScaleX != 1)
          trans.setTranslate(
            -(elem.getBBox().x / newScaleX) / this.page_scale,
            1
          );
        else if (newScaleY != 1)
          trans.setTranslate(
            1,
            -(elem.getBBox().y / newScaleY) / this.page_scale
          );

        if (rotate_trans_idx > -1)
          elem.transform.baseVal.insertItemBefore(trans, rotate_trans_idx);
        else elem.transform.baseVal.appendItem(trans);
      }

      if (elem.transform.baseVal.length > 0) {
        Array.from(elem.transform.baseVal).forEach(function (el, index) {
          if (el.type == 3) {
            scaleX = el.matrix.a;
            scaleY = el.matrix.d;
            scaled = true;
            scaleX *= newScaleX;
            scaleY *= newScaleY;
            if (scaleX < 0 || scaleY < 0) return;
            el.setScale(scaleX, scaleY);
          } else if (el.type == 4) {
            rotate_trans_idx = idx;
          }
        });
      }

      if (scaled == false) {
        let trans = elem.ownerSVGElement.createSVGTransform();
        trans.setScale(newScaleX, newScaleY);
        if (rotate_trans_idx > -1)
          elem.transform.baseVal.insertItemBefore(trans, rotate_trans_idx);
        else elem.transform.baseVal.appendItem(trans);
      }
    },
    add_svg_content(response) {
      this.append_to_workspace = true;
      this.svg_payload = this.get_svg_specs(response.body.content);
      this.import_settings_dialog = true;
    },
    get_svg_specs(content) {
      let parser = new DOMParser();
      let xmlDoc = parser.parseFromString(content, "text/xml");
      let svg_width = 0;
      let svg_height = 0;
      svg_width = parseFloat(
        xmlDoc.getElementsByTagName("svg")[0].getAttribute("width")
      );
      svg_height = parseFloat(
        xmlDoc.getElementsByTagName("svg")[0].getAttribute("height")
      );
      return { svg_width: svg_width, svg_height: svg_height, content: xmlDoc };
    },
    load_content(content, page_dimensions, page_margin) {
      let _this = this;
      let parser = new DOMParser();
      _this.clear(true);

      if (content.indexOf("<svg") == -1)
        content = `<svg ${this.xml_header}> ${content}</svg>`;

      let xmlDoc = parser.parseFromString(content, "text/xml");

      let svg_width = this.page_settings.width;
      let svg_height = this.page_settings.height;

      if (
        xmlDoc.getElementsByTagName("svg")[0].getAttribute("width") != undefined
      ) {
        svg_width =
          parseFloat(
            xmlDoc.getElementsByTagName("svg")[0].getAttribute("width")
          ) / mm_to_px;
        svg_height =
          parseFloat(
            xmlDoc.getElementsByTagName("svg")[0].getAttribute("height")
          ) / mm_to_px;
      }

      let r = false;
      // If page is loaded first time
      if (page_dimensions == "x") r = true;
      this.set_page_size(page_dimensions);
      this.set_page_margin(page_margin);

      this.svg_payload = {
        content: xmlDoc,
        svg_width: svg_width,
        svg_height: svg_height,
      };

      if (r == true) this.import_settings_dialog = true;
      else this.open_file({ xmlDoc: xmlDoc, scale: undefined });
      //show dialog
    },
    // Open PDF Import dialog
    open_file(xml_file) {
      this.import_settings_dialog = false;
      if (this.append_to_workspace == true) this.append_svg_content(xml_file);
      else this.load_svg_content(xml_file);

      this.bind_events(this);

      this.refresh_missing_fonts();
      this.load_layers_tree(true); // after svg content loaded
      this.load_fonts_list(); // after svg content loaded
    },
    zoomed() {
      d3.selectAll(".svg_outer_container").attr(
        "transform",
        d3.event.transform
      );
      this.current_zoom_level = d3.event.transform.k;
      this.gX.call(this.xAxis.scale(d3.event.transform.rescaleX(this.x)));
      this.gY.call(this.yAxis.scale(d3.event.transform.rescaleY(this.y)));
    },
    resetted(animation = true) {
      this.svg
        .transition()
        .duration(animation == false ? 0 : 750)
        .call(this.zoom.transform, d3.zoomIdentity);
    },
    clear_all() {
      this.$confirm(
        "This will permanently delete all objects. Continue? \n [Undo is not possible]",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          this.clear();
        })
        .catch(() => { });
    },
    clear(deep = false) {
      if (deep == true) d3.selectAll(".svg_inner_container ").html("");
      else d3.selectAll(".svg_inner_container .svg_layer").html("");
      //d3.select("defs").html("");
      this.editor_actions.clear();

      this.clear_selection();
      this.refresh_missing_fonts();
    },
    delete_node() {
      if (this.selected_object == null) {
        d3.selectAll(".selected_graphics , [data-selected=true]")
          .nodes()
          .forEach((el, idx) => {
            this.editor_actions.push({
              action: action_types.DELETE,
              object: Object.assign(el),
              parent: el.parentElement,
              attributes: [],
            });
          });

        // d3.selectAll(".selected_graphics").remove();
        d3.selectAll(".selected_graphics , [data-selected=true]").remove();

        this.set_file_changed(true);
        return;
      } else {
        this.editor_actions.push({
          action: action_types.DELETE,
          object: Object.assign(this.selected_object),
          parent: this.selected_object.parentElement,
          attributes: [],
        });
      }
      let parent = this.selected_object.parentElement;
      if (this.selected_object.nodeName != undefined)
        this.selected_object.remove();
      this.clear_selection();

      // Select last child in parent elem
      if (parent.childElementCount > 0) {
        let last_item = parent.children[parent.children.length - 1];
        this.set_selected_object(last_item);
      }

      this.set_file_changed(true);
    },
    duplicate_object() {
      let cloned = this.selected_object.cloneNode(true);
      cloned.setAttribute("id", "");
      this.selected_object.parentElement.insertBefore(cloned, null);
      this.set_selected_object(cloned);
      this.update_coordinates_by(10, 10);

      this.bind_events(this);
      this.editor_actions.push({
        action: action_types.ADD,
        object: this.selected_object,
        attributes: [],
      });
      this.set_file_changed(true);
    },
    fit_to_content() {
      let width =
        d3
          .select(".svg_container")
          .node()
          .getBBox().width + 32;
      let height =
        d3
          .select(".svg_container")
          .node()
          .getBBox().height + 40;
      if (width == 0 || height == 0) return;
      this.page_settings.width = d3
        .select(".svg_container")
        .node()
        .getBBox().width;
      this.page_settings.height = d3
        .select(".svg_container")
        .node()
        .getBBox().height;
      this.load_grid();
      //this.set_file_changed(true);
    },
    // Export content to PDF
    to_pdf() {
      //inkscape t.svg --export-pdf=t.pdf
      let _this = this;
      let svg_content = d3
        .select("#proposal_workspace")
        .node()
        .cloneNode(true);
      d3.select(svg_content)
        .select(".svg_outer_container")
        .attr("transform", "scale(1)");
      d3.select(svg_content)
        .selectAll(".axis")
        .remove();
      d3.select(svg_content)
        .selectAll(".page_border")
        .remove();
      d3.select(svg_content)
        .selectAll(".selected_object_frame")
        .remove();

      let current_working_file = _this.files_list.filter(
        (el) => el.id == _this.working_file_id
      )[0];
      this.$http
        .post("/svg_to_pdf", {
          svg: svg_content.outerHTML,
          filename: current_working_file.name,
        })
        .then((response) => {
          window.location = `get_svg_pdf?filename=${response.bodyText}`;
        });
    },
    // Show layup dialog
    layup() {
      // get selected objects
      // show dialog with selected objects
      // user can choose stock item
      this.layup_dialog = true;
    },
    get_el_attribute(element, attribute) {
      let str = attribute;
      let start_idx = element.indexOf(str + "(");
      let end_idx = element.indexOf(")", start_idx);
      if (start_idx < 0 || end_idx < 0) return "";
      let parsed = element.substring(start_idx + str.length + 1, end_idx);
      let result = { str: parsed, arr: parsed.split(",") };
      return result;
    },
    get_matrix(transform_str) {
      let result = { a: 1, b: 0, c: 0, d: 1 };
      if (transform_str.indexOf("matrix") >= 0) {
        let attr_val = this.get_el_attribute(transform_str, "matrix");
        if (attr_val.str != "") {
          result = {
            a: parseFloat(attr_val.arr[0]),
            b: parseFloat(attr_val.arr[1]),
            c: parseFloat(attr_val.arr[2]),
            d: parseFloat(attr_val.arr[3]),
          };
        }
      } else if (transform_str.indexOf("scale") >= 0) {
        let attr_val = this.get_el_attribute(transform_str, "scale");
        if (attr_val.str != "") {
          result = {
            a: parseFloat(attr_val.arr[0]),
            b: 0,
            c: 0,
            d:
              attr_val.arr.length > 1
                ? parseFloat(attr_val.arr[1])
                : parseFloat(attr_val.arr[0]),
          };
        }
      }
      return result;
    },
    matrix_to_string(matrix) {
      return `matrix(${matrix.a},${matrix.b},${matrix.c},${matrix.d},${matrix.e},${matrix.f})`;
    },
    get_matrix_transformation(el) {
      let elem = el.parentElement;
      let _this = this;
      let matrix = [];
      let matrix_str = "";
      let matrix_arr = [];
      while (elem.classList.contains("layers") == false) {
        if (elem.transform.baseVal.length > 0)
          matrix_arr.push(
            _this.matrix_to_string(elem.transform.baseVal.consolidate().matrix)
          );
        elem = elem.parentElement;
      }
      return matrix_arr;
    },
    extract_selected() {
      let selection = d3.select(".selection").node();
      let _this = this;

      if (selection == null) {
        _this.$message({
          message: "No selected area",
          type: "info",
        });
        return;
      }

      let xScale =
        selection.getBBox().width / selection.getBoundingClientRect().width;
      let yScale =
        selection.getBBox().height / selection.getBoundingClientRect().height;

      let x = selection.getBoundingClientRect().x * xScale;
      let y = selection.getBoundingClientRect().y * yScale;

      let layer_name = "New Selection";
      let new_layer = d3
        .selectAll(".svg_inner_container .layers")
        .append("g")
        .attr("class", "svg_layer")
        .attr("data-name", layer_name);
      let id = Math.max.apply(
        Math,
        this.layers_tree.map(function (o) {
          return o.id;
        })
      );
      id++;
      const newChild = {
        id: id,
        name: layer_name,
        selected_object: new_layer._groups[0][0],
        active: false,
        locked: false,
        children: [],
      };
      this.layers_tree.push(newChild);

      let transformVal = "";

      let selected_graphics = d3.selectAll(".selected_graphics");

      if (
        selected_graphics == null ||
        selected_graphics._groups[0].length == 0
      ) {
        _this.$message({
          message: "No graphics located in the selected area.",
          type: "info",
        });
        _this.end_drawing();
        return;
      }

      new_layer.append("g");
      selected_graphics.each(function () {
        let cloned_el = this.cloneNode(true);
        cloned_el.classList.remove("selected_graphics");
        let matrix_arr = _this.get_matrix_transformation(this).reverse();

        let i;

        let g = new_layer.select("g").node();
        for (i = 0; i < matrix_arr.length; i++)
          g = d3
            .select(g)
            .append("g")
            .attr("transform", matrix_arr[i])
            .node();
        g.append(cloned_el);
      });

      _this.current_scale = {
        width:
          new_layer.node().getBBox().width / (3.779527559 * _this.page_scale),
        height:
          new_layer.node().getBBox().height / (3.779527559 * _this.page_scale),
      };

      _this.target_scale = {
        width:
          new_layer.node().getBBox().width / (3.779527559 * _this.page_scale),
        height:
          new_layer.node().getBBox().height / (3.779527559 * _this.page_scale),
      };
      _this.selection_obj = new_layer.select("g").node();
      this.attach_events(new_layer);
      _this.scale_dialog = true;
      setTimeout(() => {
        _this.set_inputs_to_default();
      }, 200);

      // this.save_file();
    },
    scale_dialog_closing() {
      this.save_file();
      this.selection_obj = "";
      this.current_scale = { width: 0, height: 0 };
      this.target_scale = { width: 0, height: 0 };
    },
    activate_cropping() {
      this.update_current_mode(svg_modes.CROPPING);
      if (
        this.selected_object != undefined &&
        this.selected_object.nodeName != "image"
      )
        this.set_selected_object(null);
      let _this = this;
      d3.select("#proposal_workspace .svg_inner_container")
        .append("g")
        .classed("brush_selection", true)
        .call(
          _this.brush
            .extent([
              [0, 0],
              [
                this.page_settings.screen_width / this.page_scale,
                this.page_settings.screen_height / this.page_scale,
              ],
            ])
            .on("start brush", this.brushed)
            .on("end", this.brushended)
        );
    },
    brushended() {
      if (!d3.event.selection) {
        console.log("There is no selection");
        this.brush_coordinates = "";
        this.brush_coordinates_data = {};
      }
    },
    brushed() {
      let _this = this;
      if (d3.event.selection == null) {
        this.brush_coordinates = "";
        this.brush_coordinates_data = {};
        return;
      }
      let rect = d3
        .select(".selection")
        .node()
        .getBoundingClientRect();
      let coordX0 = rect.x,
        coordY0 = rect.y,
        coordX1 = rect.x + rect.width,
        coordY1 = rect.y + rect.height;
      var s = d3.event.selection,
        x0 = s[0][0] / (3.779527559 * this.page_scale),
        y0 = s[0][1] / (3.779527559 * this.page_scale),
        x1 = s[1][0] / (3.779527559 * this.page_scale),
        y1 = s[1][1] / (3.779527559 * this.page_scale);

      let width = this.round(x1 - x0);
      let height = this.round(y1 - y0);
      this.brush_coordinates_data = {
        x1: s[0][0],
        y1: s[0][1],
        x2: s[1][0],
        y2: s[1][1],
      };

      this.brush_coordinates =
        "X: " +
        this.round(x0) +
        ", Y: " +
        this.round(y0) +
        ", W: " +
        width +
        ", H: " +
        height +
        ")";

      let highlighted_shapes = d3
        .selectAll(".svg_inner_container .svg_layer:not(.hide_layer)")
        .selectAll("text,path,circle,rect,image,line,polyline,polygon,ellipse");
      if (highlighted_shapes.nodes().length > 0)
        this.enable_menus("nav_select", ["delete_node"], true);
      else this.enable_menus("nav_select", ["delete_node"], false);

      highlighted_shapes.classed(
        "selected_graphics",

        function (d) {
          let shape = {
            x0: this.getBoundingClientRect().x,
            y0: this.getBoundingClientRect().y,
            x1:
              this.getBoundingClientRect().x +
              this.getBoundingClientRect().width,
            y1:
              this.getBoundingClientRect().y +
              this.getBoundingClientRect().height,
          };
          let fit =
            coordX0 <= shape.x0 &&
            coordY0 <= shape.y0 &&
            coordX1 >= shape.x1 &&
            coordY1 >= shape.y1;
          return fit;
        }
      );
    },
    // load selected working file into editor
    load_working_file(id) {
      let _this = this;
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$http.get("/working_files/" + id).then(
        function (response) {
          _this.load_content(
            response.body.content,
            response.body.page_dimensions,
            response.body.page_margin
          );
          loading.close();

          if (this.client_view == true) {
            this.layers_tree.forEach((el) => {
              if (el.selected_proposal_item_type != null) el.checked = true;
            });
            this.working_file_qty = this.current_working_file.qty;
            this.working_file_detail.stocks = response.body.stocks;
            this.working_file_detail.tasks = response.body.tasks;
            this.working_file_detail.pdf_path = response.body.pdf_path;
            this.$nextTick(() => {
              this.make_click_event();
            });
          } else {
            this.synchronize_layers();
          }
        },
        function (response) {
          loading.close();
          this.$message({
            message: "Error happened.",
            type: "error",
          });
        }
      );
    },
    // auto save svg content every 10 seconds
    // if file has been changed
    autosave() {
      let _this = this;
      setInterval(() => {
        if (_this.file_changed == true) {
          _this.set_file_changed(false);
          _this.save_file(undefined, true);
        }
      }, 10000);
    },
  },
  watch: {
    layup_dialog: function (newVal) {
      if (newVal == true) {
        let _this = this;
        setTimeout(function () {
          _this.set_inputs_to_default();
        }, 200);
      }
    },
    working_file_id: function (newVal) {
      this.append_to_workspace = false;
      if (newVal != "") {
        this.load_working_file(newVal);
      } else {
        this.load_layers_tree();
        this.clear();
      }
      this.update_current_mode(svg_modes.NAVIGATION);
      setTimeout(() => {
        this.load_grid();
        this.resetted();
      }, 100);
    },
    "proposal.id": function (newVal) { },
    "page_settings.size": function (newVal, oldVal) {
      this.load_grid();
      this.resetted();
    },
    "page_settings.width": function (newVal, oldVal) {
      this.load_grid();
      this.bind_mouse();
      // this.resetted();
    },
    "page_settings.height": function (newVal, oldVal) {
      this.load_grid();
      this.bind_mouse();
      // this.resetted();
    },
    "grid_settings.visible": function (newVal, oldVal) {
      // Grid Visible
      d3.select(".axis--x").style(
        "visibility",
        this.grid_settings.visible ? "visible" : "hidden"
      );
      d3.select(".axis--y").style(
        "visibility",
        this.grid_settings.visible ? "visible" : "hidden"
      );
    },
    selected_object: function (newVal, oldVal) {
      this.create_boundary(this.selected_object);

      if (newVal != undefined) {
        this.update_current_mode(svg_modes.SELECTED);
      }
    },
    editor_actions: {
      handler: function () {
        if (this.editor_actions.isEmpty() == false)
          this.enable_menus("nav_select", ["undo_action"], true);
        else this.enable_menus("nav_select", ["undo_action"], false);
      },
      deep: true,
    },
  },
  mounted() {
    this.editor_actions = new LinkedList();
    this.add_fullscreen_listener();
    if (this.proposal.id != "" || this.client_view == true) {
      this.initialize();
      this.autosave();
    }
    d3.selection.prototype.appendHTML = function (HTMLString) {
      return this.select(function () {
        return this.appendChild(
          document.importNode(
            new DOMParser().parseFromString(HTMLString, "text/html").body
              .childNodes[0],
            true
          )
        );
      });
    };
    d3.selection.prototype.appendSVG = function (SVGString) {
      return this.select(function () {
        return this.appendChild(
          document.importNode(
            new DOMParser().parseFromString(
              '<svg xmlns="http://www.w3.org/2000/svg">' + SVGString + "</svg>",
              "application/xml"
            ).documentElement.firstChild,
            true
          )
        );
      });
    };
  },
  beforeDestroy() {
    this.remove_fullscreen_listener();
  },
  computed: {
    ...mapGetters([
      "page_settings",
      "grid_settings",
      "selected_object",
      "fonts_list",
      "proposal",
      "working_file_id",
      "files_list",
      "page_scale",
      "layers_tree",
      "file_changed",
      "job_proposals_list",
      "stock_items_list",
      "client_view",
      "editor_fullscreen"
    ]),
    svg_view_style() {
      if (this.client_view == true) {
        return 'width:1px;height:1px;visibility: hidden;'
      }
      return '';
    },

    show_layers_side_panel() {
      if (this.client_view == true) {
        return false;
      }
      return this.layers_side_panel;
    },

    current_working_file() {
      return this.files_list.filter((el) => el.id == this.working_file_id)[0];
    },
    selected_object_dimensions: function () {
      if (
        this.selected_object == null ||
        this.selected_object == "" ||
        this.selected_object.nodeName == undefined ||
        (this.selected_object.nodeName == "g" &&
          this.selected_object.classList.contains("grouped") == false) ||
        this.selected_object.nodeName == "text"
      ) {
        this.selected_object_specs = false;
        this.selected_object_width = 0;
        this.selected_object_height = 0;
        return { x: 0, y: 0, height: 0, width: 0 };
      }

      let dim = {};
      dim = this.get_relative_boundaries(this.selected_object);
      let height_mm = this.round(dim.height / (3.779527559 * this.page_scale));
      let width_mm = this.round(
        parseFloat(dim.width / (3.779527559 * this.page_scale))
      );
      let length_mm = 0;
      let area = 0;
      let radius = 0;
      this.selected_object_width = width_mm;
      this.selected_object_height = height_mm;
      this.selected_object_specs = true;

      if (dim.length != undefined)
        length_mm = this.round(parseFloat(dim.length / 3.779527559));
      else {
        length_mm = this.get_path_length(this.selected_object) / 3.779527559;
      }
      // calculate area
      switch (this.selected_object.nodeName) {
        case "circle":
          area = Math.PI * (this.selected_object_width / 2) * (this.selected_object_height / 2)
          break;
        case "rect":
          area = this.selected_object_width * this.selected_object_height;
          break;
        case "polygon":
          area = this.calculatePolygonArea(this.selected_object);
          break;
      }
      return {
        height_mm: height_mm,
        width_mm: width_mm,
        length_mm: length_mm,
        area: (area * 0.001 * 0.001),
        area_formatted: this.formatNumberWithCommas((area * 0.001 * 0.001).toFixed(3))
      };
    },
    grid: function () { },
    viewbox: function () {
      return (
        "0 0 " +
        this.page_settings.screen_width +
        " " +
        this.page_settings.screen_height
      );
    },
  },
};
</script>

<style>
#proposal_edit .selected_workspace svg {
  background: #d9e1e8;
  /* background: #ff0000; */
}

.fullscreen-toggle-btn {
  right: 10px;
  z-index: 1000;
  float: right;
  top: 45px;
}

[data-selected="true"] {
  /* stroke: magenta !important; */
  /* stroke-width: 3mm !important; */
  fill: orange !important;
  fill-opacity: 0.5 !important;
}

.scratchpad path {
  fill: none;
  stroke: #000;
  /* stroke-width: 3%; */
  stroke-linejoin: round;
  stroke-linecap: round;
}

.scratchpad polyline,
.scratchpad polygon {
  fill: none;
  stroke: gray;
  stroke-width: 5%;
}

rect.selected_graphics,
circle.selected_graphics {
  stroke: #e2e204 !important;
  fill: orange !important;
  fill-opacity: 0.4;
}

path.selected_graphics,
polyline.selected_graphics,
polygon.selected_graphics {
  stroke: #e2e204 !important;
  fill: orange !important;
  fill-opacity: 0.4;
  /* fill: #d6d600 !important; */
}

text.selected_graphics {
  fill: #d6d600 !important;
}

.editing_disabled {
  display: none;
}

/* body {
  width: 100%;
  height: 100%;
  font-family: monospace;
} */

/* .node {
  opacity: 1;
}

.node circle {
  fill: #999;
  cursor: pointer;
}

.node text {
  font: 10px sans-serif;
  cursor: pointer;
}

.node--internal circle {
  fill: #555;
}

.node--internal text {
  text-shadow: 0 1px 0 #fff, 0 -1px 0 #fff, 1px 0 0 #fff, -1px 0 0 #fff;
}

.link {
  fill: none;
  stroke: #555;
  stroke-opacity: 0.4;
  stroke-width: 1.5px;
  stroke-dasharray: 1000;
} */

/* #proposal_edit .active:hover {
  cursor: grab;
} */

#proposal_edit .node:hover {
  pointer-events: all;
  stroke: #ff0000;
}

#proposal_edit .node.highlight {
  fill: red;
}

#proposal_edit .controls {
  position: fixed;
  top: 16px;
  left: 16px;
  background: #f8f8f8;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
}

#proposal_edit .controls *+* {
  margin-top: 1rem;
}

/* 
label {
  display: block;
}

.list-enter-active,
.list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to   {
  opacity: 0;
  transform: translateY(30px);
}

.line-enter-active,
.line-leave-active {
  transition: all 2s;
  stroke-dashoffset: 0;
}
.line-enter, .line-leave-to   {
  stroke-dashoffset: 1000;
} */

.svg-main-container {
  width: 80%;
}

.svg-container {
  display: inline-block;
  position: relative;
  width: 80%;
  padding-bottom: 3%;
  /* aspect ratio */
  vertical-align: top;
  overflow: hidden;
}

.svg-content-responsive {
  display: inline-block;
  position: relative;
  top: 10px;
  left: 0;
}

/* #selected_object_frame {
  fill: none;
  fill: #fbfbb1;
  stroke: #b1afaf;
  stroke-width: 1px;
  cursor: grab;
} */

.selected_object_frame {
  /* fill: #fbfbb1;
  stroke: #b1afaf;
  stroke-width: 1px;
  cursor: grab; */
  outline-color: #9eb6f7;
  outline-offset: 2px;
  outline-style: dashed;
  cursor: grab;
  /* outline-width: 50px; */
  fill: orange;
  fill-opacity: 0.3;
}

/* .selected_object_frame > circle {
  stroke-width: 20%;
}
.frame_selection > rect {
  stroke-width: 10%;
} */

.axis line {
  stroke: #bdbdbd;
  stroke-width: 0.05%;
}

.axis text {
  fill: #9e9e9e;
}

#proposal_edit svg {
  border: 1px solid gray;
}

/* .selected_object {
  outline: 1px solid #d6cece;
  outline-offset: 2px;
} */

rect.selected {
  outline: 1px dotted #d6cece;
  outline-offset: -1px;
}

.svg_inner_container text:hover {
  outline: 1px dotted #d6cece;
  outline-offset: -1px;
}

.layers path:hover,
.layers polygon:hover,
.layers polyline:hover {
  /* fill: yellow !important; */
  stroke: yellow !important;
  cursor: pointer !important;
}

#proposal_edit .attribute_style {
  display: inline-block;
  min-width: 19%;
  position: relative;
  background-color: lightblue;
  vertical-align: top;
}

#proposal_edit .page_border {
  outline: red;
  /* fill: none; */
  fill: white;
  stroke: #cccbcb;
  stroke-dasharray: 5px;
}

/* .el-header,
.el-footer {
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
  line-height: 60px;
} */

/* #proposal_edit .el-aside {  
  color: #333;
  text-align: center;
  line-height: 200px;
} */

#proposal_edit .el-main {
  background-color: #e9eef3;
  color: #333;
  text-align: center;
  padding: 10px;
  /* line-height: 160px; */
}

#proposal_edit>.el-container {
  margin-bottom: 40px;
}

#proposal_edit>.el-container:nth-child(5) .el-aside,
#proposal_edit>.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

#proposal_edit>.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

#proposal_edit g.svg_layer.active_layer {
  outline: #ffd07b;
  outline-offset: 1px;
  outline-style: auto;
  outline-width: 1px;
}

#proposal_edit g.svg_layer.hover_layer {
  outline: #8f71ff;
  outline-offset: 3px;
  outline-style: auto;
  outline-width: 2px;
}

.svg_.svg_inner_container [data-selected] {
  outline-width: 3px !important;
  outline-style: solid !important;
  outline-color: orange !important;
}

.right_panel_button {
  cursor: pointer;
  padding: 3px;
  margin-top: 8px;
  background: white;
  border-radius: 5px 0px 0px 5px;
}
</style>
