<template>
  <div style="display: contents;">
    <el-tooltip
      content="Apply for extension"
      placement="top"
    >
      <el-button
        type="warning"
        circle
        icon="el-icon-time"
        size="mini"
        style="margin: 0px 7px;"
        @click="show_extensions(jp_id, user_id)"
      />
    </el-tooltip>
    
    <el-dialog
      title="Apply for extension"
      :visible.sync="extension_dialog"
      width="30%"
      :append-to-body="true"
    >
      <el-table :data="jp_extensions">
        <el-table-column
          prop="user.user_name"
          label="User"
        />
        <el-table-column
          prop="description"
          label="Description"
        />
        <el-table-column
          prop="status.name"
          label="Status"
        />
        <el-table-column label="Action">
          <template slot-scope="scope">
            <el-button
              v-show="
                scope.row.user.id == jp_extension.user.id &&
                  (scope.row.status.name == '' ||
                    scope.row.status.name == 'PENDING')
              "
              type="danger"
              icon="el-icon-delete"
              circle
              size="mini"
              @click="delete_extension(scope.row.id, scope.$index)"
            />
          </template>
        </el-table-column>
      </el-table>
      <el-row>
        <el-col>
          <el-input
            v-model="jp_extension.description"
            placeholder="Enter reason to apply for extension"
          />
          <el-button
            type="info"
            size="small"
            @click="extension_dialog = false"
          >
            Cancel
          </el-button>

          <el-button
            type="primary"
            size="small"
            class="right"
            @click="add_extension"
          >
            Apply
          </el-button>
        </el-col>
        <!-- jp_extension: {jp_id: '', user_id: '', description: ''} -->
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'JobProposalExtensions',
  props: ['jp_id', 'user_id'],
  data() {
    return {
      loading: false,
      jp_extension: {
        jp_id: '',
        description: '',
        user_id: '',
        user: { id: '', user_name: '' },
        status: { id: '', name: '' },
      },
      jp_extensions: [],
      extension_dialog: false,
    };
  },
  methods: {
    show_extensions() {
      this.loading = true;
      this.jp_extension = {
        jp_id: this.jp_id,
        description: '',
        user: { id: this.user_id, name: '' },
      };
      this.jp_extensions = [];
      this.$http.get(`/job_proposal_extensions/${this.jp_id}`).then(
        (response) => {
          this.extension_dialog = true;
          this.jp_extensions = response.data;
          this.loading = false;
        },
        (response) => {
          this.loading = false;
        }
      );
    },
    add_extension() {
      if (this.jp_extension.description == '') {
        this.$message({
          message: 'Please enter the reason for extending task timing.',
          type: 'warning',
        });
        return;
      }
      let params = {
        jp_id: this.jp_extension.jp_id,
        user_id: this.jp_extension.user.id,
        description: this.jp_extension.description,
      };
      let _this = this;
      _this.loading = true;
      this.$http
        .post(`/add_jp_extension/${this.jp_extension.jp_id}`, params)
        .then(
          (response) => {
            _this.loading = false;
            _this.$message({
              types: 'success',
              message: 'Request has been saved & sent to department leader.',
            });
            _this.extension_dialog = false;
          },
          (response) => {
            _this.loading = false;
            this.$message({ types: 'error', message: response.data.message });
            console.log(response);
          }
        );
    },
    delete_extension(id, idx) {
      this.$confirm(
        'This will delete the current task extension. Continue?',
        'info', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      )
        .then(() => {
          this.$http.delete(`/delete_jp_extension/${id}`).then(
            (response) => {
              this.jp_extensions.splice(idx, 1);
              this.$message({
                type: 'success',
                message: 'Task extension deleted successfully',
              });
            },
            (response) => {
              this.$message({
                type: 'error',
                message: response.data,
              });
            }
          );
        })
        .catch(() => {});
    },
  },
};
</script>
