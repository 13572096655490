<template>
  <div id="crm_user_page">
    <el-tabs
      type="border-card"
      tab-position="left"
      @tab-click="pay_runs_default_route"
    >
      <el-tab-pane
        label="Employee"
        :lazy="true"
      >
        <crm-user-details
          :crm_user_id="crm_user_id"
          @hook:mounted="set_default_input"
        />
      </el-tab-pane>
      <el-tab-pane
        label="Employment Details"
        :lazy="true"
      >
        <user-employment-details
          :crm_user_id="crm_user_id"
          @hook:mounted="set_default_input"
        />
      </el-tab-pane>
      <el-tab-pane
        label="Pay Rates"
        :lazy="true"
      >
        <crm-user-pay-rates
          :crm_user_id="crm_user_id"
          @hook:mounted="set_default_input"
        />
      </el-tab-pane>
      <el-tab-pane
        label="Leave"
        :lazy="true"
      >
        <leave
          :crm_user_id="crm_user_id"
          @hook:mounted="set_default_input"
        />
      </el-tab-pane>
      <el-tab-pane
        label="Time Sheet"
        :lazy="true"
      >
        <time-sheet
          :crm_user_id="crm_user_id"
          @hook:mounted="set_default_input"
        />
      </el-tab-pane>
      <el-tab-pane
        label="Pay Runs"
        :lazy="true"
      >
        <router-view
          :crm_user_id="crm_user_id"
          @hook:mounted="set_default_input"
        />
      </el-tab-pane>
      <el-tab-pane
        label="Bank Account"
        :lazy="true"
      >
        <bank-account
          :bank_accountable_id="crm_user_id"
          bank_accountable_type="CrmUser"
          @hook:mounted="set_default_input"
        />
      </el-tab-pane>
      <el-tab-pane
        label="Roles/Group"
        :lazy="true"
      >
        <crm-user-roles
          :crm_user_id="crm_user_id"
          @hook:mounted="set_default_input"
        />
      </el-tab-pane>
      <el-tab-pane
        label="Branches"
        :lazy="true"
      >
        <crm-user-branches
          :crm_user_id="crm_user_id"
          @hook:mounted="set_default_input"
        />
      </el-tab-pane>
      <el-tab-pane
        label="Skills"
        :lazy="true"
      >
        <crm-user-skills
          :crm_user_id="crm_user_id"
          @hook:mounted="set_default_input"
        />
      </el-tab-pane>
      <el-tab-pane
        label="Email Accounts"
        :lazy="true"
      >
        <mailbox-users
          :crm_user_id="crm_user_id"
          @hook:mounted="set_default_input"
        />
      </el-tab-pane>
      <el-tab-pane
        label="User Targets"
        :lazy="true"
      >
        <crm-user-targets
          :crm_user_id="crm_user_id"
          @hook:mounted="set_default_input"
        />
      </el-tab-pane>
      <el-tab-pane
        label="Welcome Email"
        :lazy="true"
      >
        <crm-user-welcome
          :crm_user_id="crm_user_id"
          @hook:mounted="set_default_input"
        />
      </el-tab-pane>
      <el-tab-pane
        label="Benefits PDF"
        :lazy="true"
      >
        <crm-user-benefits
          :crm_user_id="crm_user_id"
          @hook:mounted="set_default_input"
        />
      </el-tab-pane>
      <el-tab-pane
        label="Company Clothe PDF"
        :lazy="true"
      >
        <crm-user-company-clothe
          :crm_user_id="crm_user_id"
          @hook:mounted="set_default_input"
        />
      </el-tab-pane>
      <el-tab-pane
        label="E-Signature"
        :lazy="true"
      >
        <crm-user-signature
          :crm_user_id="crm_user_id"
          @hook:mounted="set_default_input"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import CrmUserDetails from '../crm_users/CrmUserDetails';
import CrmUserSkills from '../user/CrmUserSkills';
import BankAccount from '../shared/BankAccounts';
import MailboxUsers from '../user/MailboxUsers';
import CrmUserTargets from '../crm_users/CrmUserTargets';
import UserEmploymentDetails from '../crm_users/UserEmploymentDetails';
import CrmUserBranches from './CrmUserBranches';
import CrmUserRoles from './CrmUserRoles';
import CrmUserWelcome from './CrmUserWelcome';
import CrmUserBenefits from './CrmUserBenefits';
import CrmUserCompanyClothe from './CrmUserCompanyClothe';
import CrmUserSignature from './CrmUserSignature';
import Leave from '../user/Leave';
import TimeSheet from '../user/TimeSheet';
import CrmUserPayRates from './CrmUserPayRates';

export default {
  name: 'CrmUserPage',
  components: { CrmUserSkills, BankAccount, MailboxUsers,
    CrmUserTargets, UserEmploymentDetails,
    CrmUserDetails, CrmUserBranches, CrmUserRoles,
    CrmUserWelcome, CrmUserBenefits, CrmUserCompanyClothe,
    CrmUserSignature, Leave, TimeSheet, CrmUserPayRates,
  },
  props: {
    crm_user_id: {
      type: [String,Number],
      required: true
    }
  },
  data() {
    return {};
  },
  watch: {
  },
  mounted() {
  },
  created() {
  },
  methods: {
    pay_runs_default_route(tab){
      if(tab.label == 'Pay Runs'){
        this.$router.push('/pay_runs').catch(() => {});
      }
    },
    is_permitted(){
      this.$http.get('/permitted').then((response) => {
        this.permitted = response.data;
      });
    },
    set_default_input() {
      Array.from(document.querySelectorAll('.el-select__input, .el-input__inner')).forEach(
        function (e) {
          e.classList.add('browser-default');
          if (e.querySelector('input') != null)
            e.querySelector('input').className += ' browser-default';
        }
      );
    },
  }
};
</script>
<style scoped>

</style>
