export function getToDay() {
  let current_date = new Date();
  return new Date(
    current_date.getFullYear(),
    current_date.getMonth(),
    current_date.getDate()
  );
}

export function dateString(date) {
  if (!date) return '';

  let month = (date.getMonth() + 1).toString().padStart(2, '0');
  let day   = date.getDate().toString().padStart(2, '0');
  let year = date.getFullYear();

  return `${day}/${month}/${year}`;
}
