<template>
    <div>
        <div class="card  " v-loading="main_loading">
            <div class="card-content  ">
                <span class="card-title">
                   {{ title }}
                </span>

                <el-date-picker
                        v-model="selected_date_range"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="To"
                        start-placeholder="Start date"
                        end-placeholder="End date"
                        style="width: auto !important;"
                        :picker-options="pickerOptions2"
                        v-on:change="fill_data"
                        v-show="show_date_picker">
                </el-date-picker>
                <div class="row">
                    <div class="col m12 dashboard">
                        <div ref='messageDisplay' style="height: 300px !important;overflow-y: auto !important; ">
                            <ul>
                                <li v-for='record in summary_list' class="message_container all requests notifications">
                                    <a :href="record.link">
                               <span class="subject">
                                 <span class="from">{{record.title}}</span>
                                 <span class="time">{{record.time}}</span>
                               </span>
                                        <span class="message">
                                   <span class="line_one">{{record.message_line_1}}</span>
                                   <br>
                                   <span class="line_two">{{record.message_line_2}}</span>
                               </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <a v-on:click="fill_data" v-show="show_more" class="btn btn-small right">Show more</a>
                    </div>
                </div>
            </div>
            <div class="card-action">
                <button v-on:click="show_records_list" class="btn btn-small">Show details</button>
            </div>
        </div>
        <modal :name="type" :height="700" :width="1200" :adaptive="true">
            <div class="card ">
                <div class="card-content  ">
                    <span class="card-title">{{title}}<hr></span>
                    <div class="progress" v-show="is_loading">
                        <div class="indeterminate"></div>
                    </div>
                    <el-table
                      :data="rows"
                      style="width: 100%"
                      height="600"
                      v-if="type === 'OVERDUE' || type === 'INPRODUCTION' || type==='ONHOLD'"
                    >
                      <el-table-column
                        prop="job_no"
                        :label="'job_no' | capitalize"
                        sortable
                      />
                      <el-table-column
                        prop="account"
                        :label="'account' | capitalize"
                        sortable
                      />
                      <el-table-column
                        prop="job"
                        :label="'job' | capitalize"
                        sortable
                      />
                      <el-table-column
                        prop="task"
                        :label="'task' | capitalize"
                        sortable
                      />
                      <el-table-column
                        prop="users"
                        :label="'users' | capitalize"
                        sortable
                      />
                      <el-table-column
                        prop="time_allocated"
                        :label="'time_allocated' | capitalize"
                        sortable
                      >
                        <template slot-scope="scope">
                          <Time :is_hours="scope.row.is_hours" :minutes="scope.row.time_allocated" />
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="start_time"
                        :label="'start_time' | capitalize"
                        sortable
                      />
                      <el-table-column
                        prop="end_time"
                        :label="'end_time' | capitalize"
                        sortable
                      />
                    </el-table>
                  <el-table
                    :data="rows"
                    style="width: 100%"
                    height="600"
                    v-else
                  >
                    <el-table-column
                        v-for='column in columns'
                        v-bind:key='column'
                        v-bind:prop="column"
                        v-bind:label="column | capitalize"
                        sortable
                    >
                    </el-table-column>
                  </el-table>

                </div>
                <div class="card-action">

                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import Time from '../shared/Time';
    export default {
        name: "JobsTemplateDashboard",
        components: { Time },
        props: ["title", "type"],
        data() {
            return {
                columns: [],
                rows: [],
                start_index: 0,
                limit: 10,
                list_title: '',
                is_loading: false,
                main_loading: true,
                show_date_picker: false,
                show_more: false,
                summary_list: [],
                pickerOptions2: {
                    shortcuts: [{
                        text: 'Current month',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: 'Last week',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                        {
                            text: 'Last month',
                            onClick(picker) {
                                const end = new Date();
                                const start = new Date();
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                                picker.$emit('pick', [start, end]);
                            }
                        }, {
                            text: 'Last 3 months',
                            onClick(picker) {
                                const end = new Date();
                                const start = new Date();
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                                picker.$emit('pick', [start, end]);
                            }
                        }, {
                            text: 'Last 6 months',
                            onClick(picker) {
                                const end = new Date();
                                const start = new Date();
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
                                picker.$emit('pick', [start, end]);
                            }
                        }, {
                            text: 'Last year',
                            onClick(picker) {
                                const end = new Date();
                                const start = new Date();
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                                picker.$emit('pick', [start, end]);
                            }
                        }]
                },
                selected_date_range: ''
            }
        },
        mounted() {
            this.selected_date_range = [this.start_date, this.end_date]
            this.fill_data()
        },
        created() {

        },
        computed: {
            current_date() {
                return new Date()
            },
            start_date() {
                return new Date(this.current_date.getFullYear(), this.current_date.getMonth(), 1)
            },
            end_date() {
                return new Date(this.current_date.getFullYear(), this.current_date.getMonth(), this.current_date.getDate())
            }
        },
        methods: {

            fill_data() {
                let self = this
                this.$http.post('/get_jobs_data_summary', {
                    type: this.type,
                    start_date: this.selected_date_range[0],
                    end_date: this.selected_date_range[1],
                    start_index: this.start_index,
                    limit: this.limit
                }).then(response => {
                        self.main_loading = false
                        if (response.data.status == 200) {
                            if (response.data.data.length > 0) {
                                this.start_index += response.data.data.length
                                this.summary_list.push(...response.data.data)
                                this.show_more = true
                                var messageDisplay = self.$refs.messageDisplay;
                                messageDisplay.scrollTop = messageDisplay.scrollHeight;
                            }
                            else {
                                this.show_more = false
                            }
                        }
                    }
                )

            },
            open_modal() {
                this.$modal.show(this.type)
            },
            hide_modal() {
                this.$modal.hide(this.type)
            },
            chartOptions: function () {
                return {responsive: true, maintainAspectRatio: false}
            },
            show_records_list() {
                this.open_modal()
                this.columns = []
                this.rows = []
                this.is_loading = true;
                this.$http.post('/get_jobs_data_details', {
                    type: this.type,
                    start_date: this.selected_date_range[0],
                    end_date: this.selected_date_range[1]
                }).then(response => {
                    this.is_loading = false;
                    if (response.data.status == 200) {
                        this.columns = response.data.data.columns
                        this.rows = response.data.data.rows
                    }
                })
            }

        },

    }
</script>

<style scoped>
    td, th {
        text-align: center;
        padding: 10px;
    }

    th {
        font-weight: 800;
    }

    .col.m12.dashboard {
        height: 300px;
    }
</style>