<template>
  <div id="stock-item-price-changed">
    <el-button
      v-if="stock_items.length > 0"
      size="small"
      type="danger"
      @click="show_stock_item_dialog = true"
    >
      Stock item price changed!
    </el-button>
    <el-dialog
      :visible.sync="show_stock_item_dialog"
      title="Stock Items"
      width="50%"
    >
      <el-table
        :data="stock_items"
        stripe
        style="width: 100%"
        @selection-change="handle_selection_change"
      >
        <el-table-column
          type="selection"
          width="55"
        />
        <el-table-column
          prop="stock_item_id"
          label="Stock item ID"
          width="120"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="stock_item_link(scope.row.stock_item_id)"
            >
              {{ scope.row.stock_item_id }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="stock_item_name"
          label="Name"
        />
        <el-table-column
          prop="working_file_name"
          label="Working file name"
        />
        <el-table-column
          prop="old_sale_price"
          label="Old Sale Price"
          width="120"
        >
          <template slot-scope="scope">
            <Money :amount="scope.row.old_sale_price" />
          </template>
        </el-table-column>
        <el-table-column
          prop="new_sale_price"
          label="New Sale Price"
          width="120"
        >
          <template slot-scope="scope">
            <Money :amount="scope.row.new_sale_price" />
          </template>
        </el-table-column>
      </el-table>
      <el-row>
        <br>
        <el-button
          type="primary"
          style="float:right;"
          @click="update_quote_stock_item_prices()"
        >
          Update quote stock item prices
        </el-button>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import Money from '../../../components/shared/Money';
export default {
  name: 'StockItemPrice',
  components: { Money },
  props: {
    proposal_id: {
      type: [String,Number],
      required: false,
      default: 0
    }
  },
  data() {
    return {
      stock_items: [],
      show_stock_item_dialog: false,
      selected_stock_items: []
    };
  },
  watch: {
    proposal_id() {
      this.stock_item_price_changed();
    }
  },
  methods: {
    update_quote_stock_item_prices(){
      let params = {proposal_id: this.proposal_id, selected_stock_items: this.selected_stock_items};
      this.$http.post('update_quote_stock_item_prices', params).then(
        () => {
          this.stock_item_price_changed();
        }
      );
    },
    stock_item_price_changed(){
      this.$http.get(`/stock_item_price_changed/${this.proposal_id}`).then(
        (response) => {
          this.stock_items = response.data;
        }
      );
    },
    handle_selection_change(val){
      this.selected_stock_items = val;
    },
    stock_item_link(id) {
      window.open(`/stock_items/${id}/edit`, '_blank');
    },
  }
};
</script>
<style scoped>
#stock-item-price-changed{ display:inline;}
</style>