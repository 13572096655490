<template>
  <div>
    <router-link to="/rules">
      <i class="material-icons">build</i>
    </router-link>
    <router-link to="/create_rule">
      <i class="material-icons">code</i>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'Navigation',
  computed: {}
};
</script>
<style scoped>
a {
  padding: 5.5px 12px;
  display: block;
}
.router-link-active {
  background: white;
  padding: 5.5px 12px;
}
</style>   

