<template>
  <div
      style="display: inline-block;"
      class="attach_mail_div"
  >
    <el-tag
        v-for="msg in message_references_list"
        :key="msg.id"
        size="small"
        closable
        :disable-transitions="false"
        @close="unlink_mail(msg)"
    >
      <a :href="`${msg.link}`">{{ msg.link_title }}</a>
    </el-tag>

    <el-button
        type
        icon="el-icon-document-add"
        style="color:#599ef8;border:none; padding:0px 5px; font-size:20px;background:none;font-size:23px;"
        data-position="top"
        data-tooltip="Attach mail to Existing/New proposal"
        @click="open_modal"
    />
    <el-dialog
        title="Attach mail"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        style="text-align:left;"
        width="75%"
    >
      <el-tabs
          v-loading="is_loading"
          type="border-card"
      >
        <el-tab-pane label="Quote">
          <div style="padding: 0px 20px;">
            <el-form>
              <div class="row">
                <div class="col m6">
                  <el-select
                      v-model="account_id"
                      style="width: 100%"
                      size="small"
                      filterable
                      remote
                      reserve-keyword
                      placeholder="Please enter an account name"
                      :remote-method="accounts_list_query"
                  >
                    <!-- :loading="loading" -->
                    <el-option
                        v-for="item in accounts_list"
                        :key="item.id"
                        :label="item.account_name"
                        :value="item.id"
                    />
                  </el-select>
                </div>
                <div class="col m3">
                  <div class="field">
                    <el-checkbox
                        v-model="include_attachments"
                        label="Include attachments"
                    />
                  </div>
                </div>
                  <div slot="content">

                  </div>
                  <el-button type="primary" size="mini" @click="show_lead_dialog = true">Create a new account/lead</el-button>
              </div>
              <div class="row">
                <div class="col m3">
                  <el-radio
                      v-model="is_existing"
                      label="true"
                  >
                    Existing Quote
                  </el-radio>
                </div>

                <div class="col m5">
                  <el-select
                      v-model="proposal_id"
                      size="small"
                      style="width:100%"
                      filterable
                      :disabled="is_existing == 'false'"
                      placeholder=" - Select quote - "
                  >
                    <el-option
                        v-for="quote in quotes_list"
                        :key="quote.id"
                        :label="quote.value"
                        :value="quote.id"
                    >
                      <span>{{ quote.value }}</span>
                      <span style="color: #8492a6;">
                        <small>(#{{ quote.id }})</small>
                      </span>
                    </el-option>
                  </el-select>
                </div>
                <div class="col m4">
                  <div class="field">
                    <el-checkbox
                        v-model="attach_to_copied"
                        :disabled="is_existing == 'false'"
                        label="Attach to copied version"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col m3">
                  <el-radio
                      v-model="is_existing"
                      label="false"
                  >
                    New Quote
                  </el-radio>
                </div>
                <div class="col m5">
                  <el-select
                      v-model="category_id"
                      style="width:100%"
                      size="small"
                      filterable
                      :disabled="is_existing == 'true'"
                      placeholder=" - Select category - "
                  >
                    <el-option
                        v-for="category in categories_list"
                        :key="category.id"
                        :label="category.name"
                        :value="category.id"
                    >
                      <span>{{ category.name }}</span>
                      <span style="color: #8492a6;"/>
                    </el-option>
                  </el-select>
                </div>
              </div>
            </el-form>
            <div class="row">
              <div class="col m12">
                <hr>
                <div>
                  <div class="left">
                    <el-button
                        type="danger"
                        size="small"
                        plain
                        @click="hide_modal"
                    >
                      Close
                    </el-button>
                  </div>
                  <div class="right">
                    <el-button
                        type="primary"
                        plain
                        size="small"
                        @click="attach_mail_to_quote"
                    >
                      Attach
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Purchase Order">
          <el-radio
              v-model="selected_po_import"
              label="manual"
          >
            Manual
          </el-radio>

          <el-select
              v-model="selected_po"
              size="mini"
              filterable
              remote
              reserve-keyword
              style="width: 300px"
              placeholder="Please enter a keyword"
              :remote-method="search_pos"
              :loading="select_loading"
          >
            <el-option
                v-for="item in pos_list"
                :key="item.id"
                :label="'PO#' + item.id + ' [' + item.supplier_name + ']'"
                :value="item.id"
            >
              <span
                  style="float: left; ont-weight: 500;"
              >PO# {{ item.id }}</span>
              <span
                  style="float: left; margin-left: 5px"
              >[{{ item.po_status_name }}]</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{
                  item.supplier_name
                }}</span>
            </el-option>
          </el-select>
          <div v-show="1 == 2">
            <el-select
                v-model="selected_attachment"
                size="small"
                style="width: 300px"
            >
              <el-option
                  v-for="file in pdf_attachments"
                  :key="file.content_id"
                  :label="file.original_filename"
              />
            </el-select>
            <el-tooltip
                class="item"
                effect="dark"
                content="Validate mail attached invoice against selected PO"
                placement="top-start"
            >
              <!-- :disabled="selected_po == '' || pdf_attachments.length == 0" -->
              <el-button
                  size="mini"
                  icon="el-icon-finished"
                  @click="verify_email_pdf"
              />
            </el-tooltip>
            <!-- <ul>
                  <li v-for="item in po_checks" :key="item.id">
                    <i :class="item.icon"></i> {{ item.name }}
                  </li>
                </ul>
                <el-checkbox v-model="attach_pdf_invoice"
                  >Save PDF attachment in PO</el-checkbox> -->
          </div>
          <br>
          <el-radio
              v-model="selected_po_import"
              label="auto"
          >
            Auto
          </el-radio>

          <el-button
              v-show="selected_po_import == 'auto'"
              size="mini"
              type="info"
              @click="verify_attachments"
          >
            Verify
          </el-button>

          <table
              v-show="selected_po_import == 'auto'"
              width="100%"
              class="el-table__header"
          >
            <thead>
            <tr>
              <td width="30">
                <el-checkbox
                    v-model="select_all"
                    @change="select_all_rows"
                />
              </td>
              <td>Filename</td>
              <td width="120">
                PO Num
              </td>
              <td>Invoice number</td>
              <td width="130">
                Invoice date
              </td>
              <td width="90">
                Estimated
              </td>
              <td width="100">
                Original value
              </td>
              <td width="100">
                Supplier value
              </td>
              <td>Status</td>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="row in verified_po_list"
                :key="row.po_num"
            >
              <td>
                <el-checkbox v-model="row.selected"/>
              </td>
              <td>
                <small> {{ row.file_name }}</small>
              </td>
              <td width="60">
                  <span>
                    <el-select
                        v-model="row.po_num"
                        size="small"
                        filterable
                        remote
                        reserve-keyword
                        placeholder="Please enter a keyword"
                        :remote-method="search_pos"
                        :loading="select_loading"
                        @change="update_po_row(row)"
                    >
                      <el-option
                          v-for="item in pos_list"
                          :key="item.id"
                          :label="item.id"
                          :value="item.id"
                      >
                        <span
                            style="float: left; ont-weight: 500;"
                        >PO# {{ item.id }}</span>
                        <span
                            style="float: left; margin-left: 5px"
                        >[{{ item.po_status_name }}]</span>
                        <span
                            style="float: right; color: #8492a6; font-size: 13px"
                        >{{ item.supplier_name }}</span>
                      </el-option>
                    </el-select>
                  </span>
              </td>
              <td>
                <el-tooltip
                    class="item"
                    effect="dark"
                    :content="row.invoice_num"
                    placement="top-start"
                >
                  <el-input
                      v-model.number="row.invoice_num"
                      size="mini"
                      :class="{
                        ValueMismatch: invoice_num_exist,
                      }"
                  >
                    <el-button
                        slot="append"
                        icon="el-icon-finished"
                        @click="verify_invoice(row)"
                    />
                  </el-input>
                </el-tooltip>
              </td>
              <td>
                <el-date-picker
                    v-model="row.invoice_date"
                    size="mini"
                    type="date"
                />
              </td>
              <td>
                {{ row.estimated_value }}
              </td>
              <td>
                {{ row.original_actual }}
              </td>
              <td>
                <el-input-number
                    v-model="row.actual_value"
                    size="mini"
                    style="width: 100px"
                    :min="0"
                    :controls="false"
                    :class="{
                      ValueMismatch: row.actual_value != row.original_actual,
                    }"
                />
              </td>
              <td>
                <el-select
                    v-model="row.po_status_id"
                    size="mini"
                >
                  <el-option
                      v-for="item in row.po_statuses"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  />
                </el-select>
              </td>
            </tr>
            </tbody>
          </table>

          <hr>
          <div>
            <div class="left">
              <el-button
                  type="danger"
                  size="small"
                  plain
                  @click="hide_modal"
              >
                Close
              </el-button>
            </div>
            <div class="right">
              <el-button
                  type="primary"
                  size="small"
                  plain
                  @click="attach_mail_to_pos"
              >
                Attach
              </el-button>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <!-- <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">Cancel</el-button>
                    <el-button type="primary" @click="dialogVisible = false"
                      >Confirm</el-button
                    >
                  </span> -->
    </el-dialog>
    <el-dialog
        width="500px"
        :visible.sync="show_lead_dialog"
        append-to-body
        title="Create a new lead"
        :before-close="onBeforeClose"
        @click="show_lead_dialog = false"
    >
      <div style="text-align: center;">
        <a class="el-button" @click="create_new_account_lead(message_id)">Create New</a>
        <a class="el-button" @click="invite_dialog = true">Invite Client to create</a>
      </div>
      <el-dialog
          width="60%"
          :visible.sync="invite_dialog"
          append-to-body
          @opened="set_inputs_to_default()">
        <invite-lead-account  />
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import InviteLeadAccount from "../accounts/InviteLeadAccount.vue";

export default {
  name: 'MailToProposal',
  components: {InviteLeadAccount},
  props: ['message_id', 'attachments', 'current_mailbox'],
  data() {
    return {
      select_all: false,
      selected_po_import: 'manual',
      attach_pdf_invoice: true,
      selected_attachment: '',
      selected_po: '',
      items_loading: false,
      select_loading: false,
      dialogVisible: false,
      selected_supplier: '',
      pos_list: [],
      verified_po_list: [],
      supplier_checked: false,
      is_loading: false,
      menu_loading: true,
      attach_to_copied: false,
      is_existing: 'true',
      include_attachments: true,
      message_references_list: [],
      accounts_list: [],
      categories_list: [],
      account_id: '',
      proposal_id: '',
      category_id: '',
      quotes_list: [],
      suppliers_list: [],
      show_lead_dialog: false,
      invite_dialog:  false,
      po_checks: [
        {
          id: 'po_number',
          name: 'PO Number',
          icon: 'el-icon-minus',
          valid: false,
        },
        {
          id: 'invoice_amount',
          name: 'Invoice amount',
          icon: 'el-icon-minus',
          valid: false,
        },
        {
          id: 'invoice_number',
          name: 'Invoice number',
          icon: 'el-icon-minus',
          valid: false,
        },
      ],
    };
  },
  computed: {
    pdf_attachments() {
      if (this.attachments == null || this.attachments.length == 0) return [];
      return this.attachments.filter((c) => c.mime_type == 'application/pdf');
    },
  },
  watch: {
    message_id: function (oldVal, newVal) {
      if (newVal.length > 0 && oldVal != newVal) {
        console.log('oldVal', oldVal);
        console.log('newVal', newVal);
        this.check_message_references();
      }
    },
    dialogVisible: function (newVal) {
      this.set_inputs_to_default();
      this.selected_po = '';
      this.verified_po_list = [];
      this.select_all = false;
      this.is_loading = false;
    },
    account_id: function (val) {
      this.load_quotes_by_account_id(val);
    },
    selected_po_import: function (val) {
      if (val == 'manual') this.selected_po = '';
      else {
        this.verified_po_list = [];
        this.select_all = false;
      }
    },
  },
  mounted() {
    this.fill_categories();
    this.fill_default_account_id();
  },
  created() {
  },
  methods: {
    update_po_row(row) {
      this.$http.get(`/purchase_orders/${row.po_num}`).then((response) => {
        let record = response.data;
        row.po_status_id = record.po_status_id;
        row.estimated_value = record.estimated_value;
        row.original_actual = record.original_actual;
        row.po_statuses = record.po_statuses;
        this.set_inputs_to_default();
      });
    },
    verify_invoice(row) {
      if (
          row.po_num == '' ||
          row.invoice_num == '' ||
          row.po_num == null ||
          row.invoice_num == null
      ) {
        this.$message({
          message: 'PO number & Invoice number should be entered !',
          type: 'warning',
        });
        return;
      }
      let params = {po_num: row.po_num, invoice_num: row.invoice_num};
      this.is_loading = true;
      this.$http.post('/verify_invoice_number', params).then(
          (response) => {
            this.is_loading = false;
            if (response.data.length > 0)
              this.$message({
                type: 'warning',
                message: `Invoice number #${
                    row.po_num
                } is already attached to POs [${response.data
                    .map((c) => c.id)
                    .join(',')}].`,
              });
            else
              this.$message({
                type: 'success',
                message: `Invoice number #${row.invoice_num} is not attached to other POs.`,
              });
          },
          (response) => {
            this.is_loading = false;
          }
      );
    },
    select_all_rows(e) {
      for (var i = 0; i <= this.verified_po_list.length; i++)
        this.verified_po_list[i]['selected'] = e;
    },
    verify_attachments() {
      this.is_loading = true;
      let params = {
        mailbox: this.current_mailbox,
        mail_box_account_id: this.account_id,
        message_id: this.message_id,
      };
      this.verified_po_list = [];
      this.$http.post('/verify_invoice_vs_po', params).then(
          (response) => {
            this.verified_po_list = response.data;
            this.set_inputs_to_default();
            this.is_loading = false;
          },
          (response) => {
            console.log(response);
            this.is_loading = false;
          }
      );
    },
    open_modal() {
      //this.$modal.show("attach_to_proposal");
      this.dialogVisible = true;
    },
    hide_modal() {
      //this.$modal.hide("attach_to_proposal");
      this.clear_inputs();
      this.is_loading = false;
      this.dialogVisible = false;
    },
    verify_email_pdf() {
      let formData = new FormData();
      let attachment_content = this.attachments.filter(
          (c) => c.content_id == this.selected_attachment
      );
      formData.append('attachment_content', attachment_content);
      formData.append('po_id', this.selected_po);
      this.$http.post('/verify_mail_pdf_vs_invoice', formData).then(
          (response) => {
          },
          (response) => {
          }
      );
    },
    clear_inputs() {
      this.proposal_id = '';
      this.account_id = '';
      this.category_id = '';
      this.attach_to_copied = false;
      this.is_existing = 'true';
    },
    search_pos(query) {
      let encoded_query = encodeURIComponent(query);
      if (query !== '' && query.length > 2) {
        this.select_loading = true;
        this.$http.get(`/search_pos?term=${encoded_query}`).then(
            (response) => {
              this.select_loading = false;
              this.pos_list = response.data;
            },
            (response) => {
              this.pos_list = [];
            }
        );
      }
    },
    remoteMethod(query) {
      let encoded_query = encodeURIComponent(query);
      if (query !== '' && query.length > 3) {
        this.select_loading = true;
        this.$http.get(`/search_suppliers?term=${encoded_query}`).then(
            (response) => {
              this.select_loading = false;
              this.suppliers_list = response.data;
            },
            (response) => {
            }
        );
        // setTimeout(() => {
        //   this.loading = false;
        //   this.options = this.list.filter((item) => {
        //     return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
        //   });
        // }, 200);

        //this.suppliers_list = [];
      } else {
        this.suppliers_list = [];
      }
    },
    load_pos_by_supplier() {
      this.pos_list = [];
      this.$http.get(`/pos_by_supplier?id=${this.selected_supplier}`).then(
          (response) => {
          },
          (response) => {
          }
      );
    },
    fill_default_account_id() {
      this.$http.get('/get_current_account').then((response) => {
        if (response.status == 200 && response.data.data != null) {
          self.account_id = response.data.data.id;
          self.accounts_list = [response.data.data];
        }
      });
    },
    check_message_references() {
      let self = this;
      if (self.message_id == undefined || self.message_id == '') return;
      this.$http
          .post('/message_references_by_msg_id', {
            message_id: self.message_id,
          })
          .then((response) => {
            self.message_references_list = response.data.data;
            self.menu_loading = false;
          });
    },
    accounts_list_query(term) {
      let self = this;
      let url = 'list';
      let encoded_term = encodeURIComponent(term);
      if (this.is_existing == 'false') url = 'accounts_list';
      this.$http.get(`/${url}?term=${encoded_term}`).then(
          function (response) {
            // Success
            if (response.status == 200) {
              self.accounts_list = response.data;
            }
          },
          function (response) {
            // error
            console.log(response);
          }
      );
    },
    load_quotes_by_account_id(id) {
      this.quotes_list = [];
      let self = this;
      this.$http.get('/get_proposals_by_account?account_id=' + (id || 0)).then(
          function (response) {
            // Success
            if (response.status == 200) {
              self.quotes_list = response.data;
              self.proposal_id = '';
            }
          },
          function (response) {
            // error
            console.log(response);
          }
      );
    },
    attach_mail_to_pos() {
      let self = this;
      this.is_loading = true;
      let params = {
        import_type: this.selected_po_import,
        message_id: this.message_id,
        mailbox_account_id: this.account_id,
        mailbox: this.current_mailbox,
        pos: [],
      };
      if (this.selected_po_import == 'manual') {
        if (this.selected_po == '') {
          this.$message({
            type: 'warning',
            message: 'No selected PO to be attached.',
          });
          this.is_loading = false;
          return;
        }
        params.pos = [{po_num: this.selected_po}];
      } else {
        let selected_pos = this.verified_po_list.filter(
            (c) => c.selected == true
        );
        if (selected_pos.length == 0) {
          this.$message({
            type: 'warning',
            message: 'No selected PO to be attached.',
          });
          this.is_loading = false;
          return;
        }
        params.pos = selected_pos;
      }
      this.$http.post('/attach_mail_to_pos', params).then((response) => {
        self.$loading().close();
        self.$message({message: 'Success', type: 'success'});
        self.hide_modal();
        self.check_message_references();
      });
    },
    attach_mail_to_quote() {
      if (this.validate_inputs() == false) return;
      let self = this;
      this.is_loading = true;
      this.$http
          .post('/attach_mail_to_quote', {
            message_id: this.message_id,
            account_id: this.account_id,
            is_existing: this.is_existing,
            include_attachments: this.include_attachments,
            proposal_id: this.proposal_id,
            attach_to_copied: this.attach_to_copied,
            category_id: this.category_id,
            current_mailbox: this.current_mailbox
          })
          .then((response) => {
            this.is_loading = false;
            this.$message({
              type: response.data.status == 200 ? 'success' : 'warning',
              message: response.data.message,
            });
            self.hide_modal();
            self.check_message_references();
          });
    },
    fill_categories() {
      let self = this;
      this.$http.get('/category_tasks_list').then(
          function (response) {
            // Success
            if (response.status == 200) {
              self.categories_list = response.data;
            }
          },
          function (response) {
            // error
            console.log(response);
          }
      );
    },
    validate_inputs() {
      let errors = [];
      if (this.account_id == '') errors.push('Account');
      if (this.is_existing == 'true') {
        if (this.proposal_id == '') errors.push('Quote');
      } else {
        if (this.category_id == '') errors.push('Category');
      }

      if (errors.length >= 1) {
        this.$message({
          type: 'warning',
          message: 'Enter missing data: [' + errors.join(',') + ']',
        });
        return false;
      } else return true;
    },
    unlink_mail(msg) {
      let self = this;
      this.$confirm(
          'This will unlink the current mail from clicked ' +
          msg.messageable_type +
          '. Continue?',
          'Warning',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning',
          }
      )
          .then(() => {
            this.$http.delete('/unlink_mail/' + msg.id).then(
                //success
                function (response) {
                  if (response.status == 200) {
                    self.check_message_references();
                    self.$message({
                      type: 'success',
                      message: 'Mail has been unlinked.',
                    });
                  }
                },
                function (response) {
                }
            );
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: 'Unlink canceled',
            });
          });
    }, onBeforeClose() {
      this.invite_dialog = false;
      this.show_lead_dialog = false;
    },
    create_new_account(current_message_id){
      let encoded_msg_id = encodeURIComponent(current_message_id)
      this.show_lead_dialog = false;
      return (
          window.open('/accounts/new?message_id=' + encoded_msg_id, '_blank').focus()
      );
    },
    create_new_account_lead(current_message_id) {
      let encoded_msg_id = encodeURIComponent(current_message_id)
      this.show_lead_dialog = false;
      return (
          window.open('/accounts/new?message_id=' + encoded_msg_id + '&current_mailbox=' + this.current_mailbox, '_blank').focus()
      );
    },
    set_inputs_to_default() {
      let _this = this;
      setTimeout(() => {
        Array.from(document.querySelectorAll('.el-input')).forEach(function (
            e,
            i
        ) {
          e.classList.add('browser-default');
          if (e.querySelector('input') != null)
            e.querySelector('input').className += ' browser-default';
        });
      }, 300);
    },
  },
};
</script>

<style scoped>
.input-label {
  width: 130px;
}

.el-date-editor {
  width: 130px !important;
}

thead td {
  color: #909399;
  font-weight: bold;
}
</style>

<style>
.ValueMismatch > input {
  background-color: pink !important;
}
</style>
