import {Request} from '../utilities/Request';

export function index(this_obj) {
  return Request.get(
    this_obj,
    '/api/jobs_settings',
    null,
  );
}

export function update(this_obj, jobs_setting) {
  return Request.patch(
    this_obj,
    `/api/jobs_settings/${jobs_setting.id}`,
    null,
    { jobs_setting: jobs_setting }
  );
}

