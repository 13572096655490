<template>
  <div>
    <b>Add a Payment:</b>
    <div>
      <el-date-picker
        v-model="payment_date"
        type="date"
        placeholder="Pick a day"
        format="dd/MM/yyyy"
      />
      <el-input
        ref="payment_amount"
        v-model="payment_amount"
        class="input__width inputdiv"
        placeholder="Amount"
      />
    </div>
    <div>
      <el-button
        type="success"
        size="mini"
        @click="onCalculate"
      >
        Calculate Payments
      </el-button>
      <el-button
        v-show="show_credit_btn"
        type="primary"
        size="mini"
        @click="onMakeCredits"
      >
        Make Unassigned Payments
      </el-button>
    </div>
  </div>
</template>

<script>
import {calculateOneMasterPayment, createMasterPayment} from '../../../../api/master_payment';
import {dateString, getToDay} from '../../../../utilities/Date';
import {EventBus} from '../../../../utilities/EventBus';

export default {
  name: 'AddMasterPayment',
  props: {
    account_id: {
      type: String,
      required: true
    },
    show_credit_btn: {
      type: Boolean,
      default: true,
    }
  },
  data(){
    return {
      payment_amount: '',
      payment_date: getToDay(),
    };
  },
  mounted() {
    this.$refs.payment_amount.focus();
  },
  methods: {
    onCalculate() {
      calculateOneMasterPayment(this, this.account_id, dateString(this.payment_date), this.payment_amount)
        .then(({data})=>{
          this.$emit('cal_res', data);
        })
        .catch(({data})=>{
          this.$emit('errors', data.details);
        });
    },
    onMakeCredits() {
      createMasterPayment(this, this.account_id, dateString(this.payment_date), this.payment_amount)
        .then(({data})=>{
          this.$message({
            type: 'success',
            message: data.message
          });
          EventBus.$emit('refreshAllInvoice');
        })
        .catch(({data})=>{
          this.$emit('errors', data.details);
        });
    }
  },
};
</script>

<style lang='scss' scoped>
.input__width {
  width: 50%;
}
</style>