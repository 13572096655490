import { render, staticRenderFns } from "./SalesVariation.vue?vue&type=template&id=3b31ff1d&scoped=true&"
import script from "./SalesVariation.vue?vue&type=script&lang=js&"
export * from "./SalesVariation.vue?vue&type=script&lang=js&"
import style0 from "./SalesVariation.vue?vue&type=style&index=0&id=3b31ff1d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b31ff1d",
  null
  
)

export default component.exports