import {Request} from '../utilities/Request';

export function loadIsHours(this_obj) {
  return Request.get(
    this_obj,
    '/api/organizations/is_hours'
  );
}

export function updateIsHours(this_obj, is_hours) {
  return Request.patch(
    this_obj,
    '/api/organizations/update_is_hours',
    null,
    {
      is_hours: is_hours
    }
  );
}

export function loadIsJobtimeInput(this_obj) {
  return Request.get(
    this_obj,
    '/api/organizations/is_jobtime_input'
  );
}

export function updateIsJobtimeInput(this_obj, is_jobtime_input) {
  return Request.patch(
    this_obj,
    '/api/organizations/update_is_jobtime_input',
    null,
    {
      is_jobtime_input: is_jobtime_input
    }
  );
}

export function loadInvoiceFooter(this_obj) {
  return Request.get(
    this_obj,
    '/api/organizations/invoice_footer'
  );
}

export function updateInvoiceFooter(this_obj, invoice_footer) {
  return Request.patch(
    this_obj,
    '/api/organizations/update_invoice_footer',
    null,
    {
      invoice_footer: invoice_footer
    }
  );
}


export function loadPurchaseOrderHeader(this_obj) {
  return Request.get(
    this_obj,
    '/api/organizations/po_header'
  );
}

export function updatePurchaseOrderHeader(this_obj, po_header) {
  return Request.patch(
    this_obj,
    '/api/organizations/update_po_header',
    null,
    {
      po_header: po_header
    }
  );
}

export function loadPurchaseOrderFooter(this_obj) {
  return Request.get(
    this_obj,
    '/api/organizations/po_footer'
  );
}

export function updatePurchaseOrderFooter(this_obj, po_footer) {
  return Request.patch(
    this_obj,
    '/api/organizations/update_po_footer',
    null,
    {
      po_footer: po_footer
    }
  );
}