<template>
  <div>
    <el-form
      ref="form"
      label-width="70px"
    >
      <el-form-item
        :key="attribute.name"
        :label="attribute.name"
        size="mini"
      >
        <div
          v-if="
            attribute.name == 'font-size' ||
              attribute.name == 'width' ||
              attribute.name == 'height'
          "
        >
          <!-- <el-input
            v-if="attribute.name == 'width' || attribute.name == 'height'"
            :data-prop="attribute.name"
            :value="px_to_mm"
            v-on:change="update_attribute_mm"
            class="browser-default"
          >
            <template slot="append">mm</template>
          </el-input> -->

          <el-input
            v-model="attribute.value"
            :data-prop="attribute.name"
            :data-group="attribute.group"
            class="browser-default"
            @change="update_attribute"
          >
            <el-button
              v-if="attribute.name == 'font-size'"
              slot="append"
              icon="el-icon-delete"
              @click="delete_attribtue"
            />
            <template
              v-else
              slot="append"
            >
              px
            </template>
          </el-input>
        </div>

        <el-input
          v-else-if="
            attribute.prop.type == undefined || attribute.prop.type == ''
          "
          v-model="attribute.value"
          :data-prop="attribute.name"
          :data-group="attribute.group"
          class="browser-default"
          @change="update_attribute"
        >
          <el-button
            slot="append"
            icon="el-icon-delete"
            @click="delete_attribtue"
          />
        </el-input>

        <div v-else-if="attribute.name == 'font-family'">
          <el-select
            v-model="attribute.value"
            placeholder="Select"
            :data-prop="attribute.name"
            :data-group="attribute.group"
            class="default-browser"
            allow-create
            filterable
            @change="update_attribute"
          >
            <el-option
              v-for="item in fonts_list"
              :key="item.id"
              :label="item.name + '.' + item.extension"
              :value="item.name"
            >
              <span style="float: left; font-size: 12px;">{{ item.name }}</span>
              <span
                style="float: right; color: #8492a6; font-size: 12px"
              >[{{ item.extension }}]</span>
            </el-option>
          </el-select>
          <el-button
            slot="append"
            icon="el-icon-delete"
            @click="delete_attribtue"
          />
        </div>

        <!-- <el-autocomplete
          v-else-if="attribute.name == 'font-family' "
          :fetch-suggestions="fetch_fonts_list"
          :data-prop="attribute.name"
          :data-group="attribute.group"
          v-model="attribute.value"
          class="browser-default"
          :trigger-on-focus="false"
        >
          <el-button slot="append" @click="delete_attribtue" icon="el-icon-delete"></el-button>
          <template slot-scope="{ item }">
            <small>
              <div class="value">{{ item.value }}</div>
            </small>
          </template>
        </el-autocomplete>-->
        <el-select
          v-else-if="
            attribute.prop.type != undefined &&
              attribute.prop.type == 'autocomplete'
          "
          v-model="attribute.value"
          :data-prop="attribute.name"
          :data-group="attribute.group"
          class="browser-default"          
          allow-create
          filterable
          @change="update_attribute"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.value"
            :value="item.value"
          />
          <el-button
            slot="append"
            icon="el-icon-delete"
            @click="delete_attribtue"
          />
        </el-select>

        <el-input
          v-else-if="
            attribute.prop.type != undefined &&
              attribute.prop.type == 'colorpicker'
          "
          v-model="attribute.value"
          type="color"
          :data-prop="attribute.name"
          :data-group="attribute.group"
          @change="update_attribute"
        >
          <el-button
            slot="append"
            icon="el-icon-delete"
            @click="delete_attribtue"
          />
        </el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { editor_store, mm_to_px } from 'store/modules/editor';
import attributes_template from './../templates/attributes_template.json';

export default {
  name: 'ObjectAttribute',
  store: editor_store,
  props: ['attribute', 'load_attributes', 'aspect_ratio', 'add_editor_action'],
  data() {
    return {
      attributes_template: attributes_template,
      options: [],
    };
  },
  mounted() {
    let _this = this;
    this.options = [];
    if (
      this.attribute.prop.type != undefined &&
      this.attribute.prop.type == 'autocomplete'
    )
      Array.from(this.attribute.prop.options.split('|')).forEach(function(
        el,
        idx
      ) {
        _this.options.push({ value: el });
      });
  },
  methods: {
    ...mapActions([
      'include_font',
      'refresh_missing_fonts',
      'set_selected_object',
      'set_file_changed',
    ]),
    update_attribute_mm(e) {
      let px_value = e * mm_to_px * this.page_scale;
      this.attribute.value = px_value;
      this.update_attribute();
    },
    update_autocomplete(e) {
      this.update_attribute();
    },
    delete_attribtue() {
      if (this.attribute.group == 'style') {
        this.selected_object.style[this.attribute.name] = '';
        this.add_editor_action({
          action: 'edit',
          object: this.selected_object,
          attributes: [
            {
              type: 'style',
              key: this.attribute.name,
              value: this.attribute.value || null,
            },
          ],
        });
      } else {
        this.selected_object.removeAttribute(this.attribute.name);
        this.add_editor_action({
          action: 'edit',
          object: this.selected_object,
          attributes: [
            {
              type: 'attribute',
              key: this.attribute.name,
              value: this.attribute.value || null,
            },
          ],
        });
      }
      this.load_attributes();

      this.set_selected_object(this.selected_object);
    },
    querySearch(queryString, cb) {
      var links = this.attributes_template;
      var results = queryString
        ? links.filter(this.createFilter(queryString))
        : links;
      // call callback function to return suggestions
      cb(results);
    },
    createFilter(queryString) {
      return (link) => {
        return link.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
    handleSelect(item) {
      console.log(item);
    },
    // fetch_fonts_list(qry, cb) {
    //   if (qry.length < 2) return;
    //   let options = [];
    //   this.$http.get("/fonts?query=" + qry).then(
    //     response => {
    //       cb(response.data);
    //     },
    //     function(response) {
    //       console.log(response);
    //     }
    //   );
    // },
    // fetch_suggestions(qry, cb) {
    //   let options = [];
    //   let data = [];
    //   if (this.attribute.prop.options != undefined) {
    //     // for (let option in attribute.prop.options) {
    //     //     options.push({value: option})
    //     // }
    //     Array.from(this.attribute.prop.options.split("|")).forEach(function(
    //       el,
    //       idx
    //     ) {
    //       options.push({ value: el });
    //     });
    //   }
    //   cb(options);
    // },
    get_attribute_prop(attribute_name) {
      let props = {};

      let result = this.attributes_template.filter(
        (c) => c.name == attribute_name
      );
      if (result.length > 0) props = result[0];
      return props;
      //   let props = {};
      //   switch (attribute_name) {
      //     case "font-size":
      //       props = {
      //         type: "autocomplete",
      //         options:
      //           "medium|xx-small|x-small|small|large|x-large|xx-large|smaller|larger|length|initial|inherit"
      //       };
      //   }
    },
    // set_inputs_to_default() {
    //   let _this = this;
    //   Array.from(document.querySelectorAll("[data-prop]")).forEach(function(
    //     e,
    //     i
    //   ) {
    //     e.classList.add("browser-default");
    //     e.addEventListener("change", _this.update_attribute);
    //     e.style.width = "90% ";
    //   });
    // },
    update_attribute() {
      
      let _this = this;
      let attr = this.attribute.name;
      let group = this.attribute.group;
      let value = this.attribute.value;

      switch (group) {
      case 'style':
        this.add_editor_action({
          action: 'edit',
          object: this.selected_object,
          attributes: [
            {
              type: 'style',
              key: attr,
              value: this.selected_object.style[attr] || null,
            },
          ],
        });

        d3.select(this.selected_object).style(attr, value);
        break;
      case 'text':
        this.add_editor_action({
          action: 'edit',
          object: this.selected_object,
          attributes: [
            {
              type: 'object',
              key: 'innerHTML',
              value: this.selected_object.innerHTML,
            },
          ],
        });
        d3.select(this.selected_object).html(value);
        break;
        //   case 'attributes':
        //   this.selected_object.attr(attr, value);
      default:
        this.add_editor_action({
          action: 'edit',
          object: this.selected_object,
          attributes: [
            {
              type: 'attribute',
              key: attr,
              value: value,
            },
          ],
        });

        if (
          (attr == 'width' || attr == 'height') &&
            this.aspect_ratio == true
        ) {
          _this.update_dimensions(attr, value);
        } else d3.select(this.selected_object).attr(attr, value);
      }

      d3.selectAll('.selected_object_frame rect').attr(
        'width',
        this.selected_object.getBBox().width
      );
      d3.selectAll('.selected_object_frame rect').attr(
        'height',
        this.selected_object.getBBox().height
      );

      if (this.attribute.name == 'font-family') {
        // import font if doesn't exist in def
        let font = this.fonts_list.filter(
          (c) => c.name.toLowerCase() == this.attribute.value.toLowerCase()
        )[0];
        if (font != null) {
          if (font.imported == false) {
            this.include_font(font);
          }
        }
        this.refresh_missing_fonts();
      } else if (this.attribute.name == 'font-size') {
        let size = parseInt(this.attribute.value);
        if (size != undefined)
          d3.select(this.selected_object)
            .selectAll('tspan[dy]')
            .attr('dy', size);
      }

      this.set_selected_object(this.selected_object);
      this.set_file_changed(true);
    },
    update_dimensions(attr, value) {
      let height = 0;
      let width = 0;
      if (attr == 'width') {
        width = parseFloat(value);
        height =
          width *
          (parseFloat(this.selected_object.getAttribute('height')) /
            parseFloat(this.selected_object.getAttribute('width')));
      } else {
        height = parseFloat(value);
        width =
          height *
          (parseFloat(this.selected_object.getAttribute('width')) /
            parseFloat(this.selected_object.getAttribute('height')));
      }
      d3.select(this.selected_object).attr('width', width);
      d3.select(this.selected_object).attr('height', height);
    },
  },
  computed: {
    ...mapGetters([
      'selected_object',
      'fonts_list',
      'page_scale',
      'page_settings',
      'file_changed',
    ]),
    px_to_mm() {
      return this.attribute.value / (mm_to_px * this.page_scale);
    },
    // value_in_mm: {
    //   get: function() {
    //     return this.attribute.value / mm_to_px;
    //   },
    //   set: function(newVal) {
    //     let px_value = newVal * mm_to_px;
    //     this.attribute.value = px_value;
    //     this.update_attribute();
    //   }
    // }
  },
  watch: {
    // "attribute.value": function(newVal, oldVal) {
    //   this.update_attribute();
    // }
    // object_attributes: {
    //   handler: function(val, oldVal) {
    //     let _this = this;
    //     setTimeout(function() {
    //       _this.set_inputs_to_default();
    //     }, 200);
    //   }
    // },
    // selected_object: function(newVal, oldVal) {
    //   this.load_attributes();
    // }
  },
};
</script>

<style scoped>
.el-form-item {
  margin-bottom: 5px !important;
}
</style>
<style>
.el-autocomplete-suggestion {
  width: auto !important;
}
</style>
