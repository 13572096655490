<template>
  <div id="stock-group-list">
    Stock Groups
    <stock-group
      :stock_item_id="stock_item_id"
      style="float:right"
      @reload_groups="stock_item_list_groups"
    />
    <hr>
    <el-row>
      <el-tag
        v-for="group in groups"
        ref="tags"
        :key="group.id"
        type="success"
        plain
        closable
        @click="selected_group = group.name, show_stock_item_dialog = true, stock_items_per_group(group.id)"
        @close="remove_stock_item_from_group(tag, group.id)"
      >
        {{ group.name }}
      </el-tag>
    </el-row>
    <el-dialog
      :visible.sync="show_stock_item_dialog"
      :title="'Stock Items for ' + selected_group"
      :append-to-body="true"
    >
      <el-table
        :data="stock_items"
        stripe
        style="width: 100%"
      >
        <el-table-column
          prop="id"
          label="Id"
          width="80"
        />
        <el-table-column
          prop="internal_name"
          label="Internal Name"
          width="280"
        />
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import StockGroup from './StockGroup';
export default {
  name: 'StockItemListGroups',
  components: {StockGroup},
  props: {
    stock_item_id: {
      type: [String,Number],
      required: true
    }
  },
  data() {
    return {
      groups: [],
      show_stock_item_dialog: false,
      stock_items: [],
      selected_group: '',
      tag: ''
    };
  },
  watch:{
    stock_item_id(){
      this.stock_item_list_groups();
    }
  },
  mounted(){
    if(this.stock_item_id) {
      this.stock_item_list_groups();
    }
  },
  methods: {
    stock_item_list_groups() {
      this.$http.get(`/stock_item_list_groups/${this.stock_item_id}`).then(response => {
        this.groups = response.data;
      });
    },
    stock_items_per_group(stock_group_id) {
      this.$http.post('/stock_item_per_group', {
        stock_group_id: stock_group_id,
      }).then(response => {
        this.stock_items = response.data.stock_items;
      });
    },
    remove_stock_item_from_group(tag, stock_group_id){
      this.$http.post('/remove_stock_item_from_group',
        {stock_item_id: this.stock_item_id , stock_group_id: stock_group_id}
      ).then(
        () => {
          this.$message({
            type: 'success',
            message: 'Stock item from group removed'
          });
          this.groups.splice(this.groups.indexOf(tag), 1);
        }
      );
    }
  },
};
</script>
<style scoped>
  #stock-group-list{
    display: inline;
  }
  .el-tag + .el-tag {
    margin-left: 10px;
  }
</style>