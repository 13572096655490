<template>
  <div id="draft_pay">
    <el-row>
      <el-col :span="22">
        <span>Draft Pay - {{ pay_period_start }} to {{ pay_period_end }}</span>
      </el-col>
      <el-col :span="2">
        <router-link
          to="/pay_runs"
        >
          Pay runs
        </router-link>
      </el-col>
    </el-row>
    <el-divider />
    <p class="headline">
      Take home payment: <Money :amount="total" />
    </p>
    <p>Earnings</p>
    <el-card
      class="box-card"
      shadow="never"
    >
      <pay-run-earnings
        :crm_user_id="crm_user_id"
        :pay_period_start="pay_period_start"
        :pay_period_end="pay_period_end"
      />
    </el-card>
    <p>Income Tax</p>
    <el-card
      class="box-card"
      shadow="never"
    >
      <pay-run-income-tax
        :crm_user_id="crm_user_id"
        :pay_period_start="pay_period_start"
        :pay_period_end="pay_period_end"
        @calculate_pay_run="calculate"
      />
    </el-card>
    <p>Deductions</p>
    <el-card
      class="box-card"
      shadow="never"
    >
      <pay-run-deductions
        :crm_user_id="crm_user_id"
        :pay_period_start="pay_period_start"
        :pay_period_end="pay_period_end"
        @calculate_pay_run="calculate"
      />
    </el-card>
    <p>Reimbursements</p>
    <el-card
      class="box-card"
      shadow="never"
    >
      <pay-run-reimbursements
        :crm_user_id="crm_user_id"
        :pay_period_start="pay_period_start"
        :pay_period_end="pay_period_end"
        @calculate_pay_run="calculate"
      />
    </el-card>
  </div>
</template>
<script>
import Money from '../../shared/Money';
import{pay_run_draft_pay} from '../../../api/pay_runs';

import PayRunEarnings from './Earnings';
import PayRunIncomeTax from  './IncomeTax';
import PayRunDeductions from  './Deductions';
import PayRunReimbursements from  './Reimbursements';

export default {
  name: 'DraftPay',
  components: { Money, PayRunEarnings, PayRunIncomeTax, PayRunReimbursements, PayRunDeductions },
  props: {
    crm_user_id: {
      type: String,
      required: true
    },
    pay_period_start: {
      type: String,
      required: true
    },
    pay_period_end: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      draft_pay: [],
      total: 0
    };
  },
  watch: {
    crm_user_id(){
    }
  },
  mounted() {
  },
  created() {
    this.pay_run_draft_pay();
  },
  methods: {
    calculate(value){
      this.total += parseFloat(value);
    },
    pay_run_draft_pay(){
      pay_run_draft_pay(this, this.crm_user_id, this.pay_period_start, this.pay_period_end).then((response)=> {
        this.draft_pay = response.data;
      });
    }

  }
};
</script>
<style scoped>
#draft_pay /deep/ .el-divider--horizontal {
  margin: 0 0 10px 0;
}
.headline {
  padding: 10px;
  color: white;
  background: #67C23A;
}
</style>
