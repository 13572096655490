<template>
  <div>
    <Header :sale="sale" :links="links" />
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18">
        <Information class="sales__section" />
        <DescriptionsList :show_conditions="show_conditions" />
        <ScheduleElements class="sales__section" v-if="show_conditions.is_schedule"
          :scheduled_item="sale.scheduled_item" :schedule_types_lists="lists.schedule_types_lists" />
        <JobDesc class="sales__section" v-if="show_conditions.is_job_desc" :job_desc="sale.job_desc" />
        <JobNotes class="sales__section" v-if="show_conditions.is_job_notes" :proposal_id="sale.proposal_id" />
        <TaskTimeSpents class="sales__section" v-if="sale.proposal_id" :proposal_id="sale.proposal_id" />
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
        <EditForm :lists="lists" :is_manager="show_conditions.is_manager"
          :is_additional_invoice="show_conditions.is_additional_invoice" :is_schedule="show_conditions.is_schedule"
          :proposal_matched_quote="sale.proposal_matched_quote" />
      </el-col>
    </el-row>
    <el-row>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <SaleWorkingFilesSummary class="sales__section" :sale_id="sale.id" v-if="parent_sale.invoice_based_wf" />
      </el-col>
    </el-row>
  </div>
</template>

<script>

import { sale_store } from "store/modules/sale_store";
import { mapGetters, mapActions } from "vuex";

import Header from "./sales_form_elements/Header";
import Information from "./sales_form_elements/Information"
import EditForm from "./sales_form_elements/EditForm";
import DescriptionsList from "./sales_form_elements/DescriptionsList"
import ScheduleElements from "./sales_form_elements/ScheduleElements";
import JobDesc from "./sales_form_elements/JobDesc";
import JobNotes from "./sales_form_elements/JobNotes";
import TaskTimeSpents from "./task_time_spents/TaskTimeSpents";
import SaleWorkingFilesSummary from "./sales_form_elements/SaleWorkingFilesSummary";

export default {
  name: "SalesEditForm",
  store: sale_store,
  components: {
    TaskTimeSpents,
    Header,
    Information,
    EditForm,
    DescriptionsList,
    ScheduleElements,
    JobDesc,
    JobNotes,
    SaleWorkingFilesSummary
  },
  props: ["sale", "links", "lists", "show_conditions"],
  created() {
    const {
      scheduled_item, client_name, job_name, quote_type, proposal_matched_quote, job_desc,
      ...parent_sale
    } = {
      ...this.sale,
      ...{
        send_invoice: this.sale.send_invoice || false,
        job_no: this.sale.job_no ? this.sale.job_no : this.sale.proposal_id
      }
    }
    this.parent_sale = parent_sale;

    this.parent_sale.wf_sales.forEach(wf_sale => {
      this.add_sale({
        key: wf_sale.id,
        sale: wf_sale
      });
    });

    if (this.parent_sale.invoice_based_wf == true) {
      this.set_current_sale_key(this.parent_sale.wf_sales[0].id);
    }
  },
  computed: {
    ...mapGetters([
      "get_parent_sale",
    ]),
    parent_sale: {
      get() {
        return this.get_parent_sale;
      },
      set(value) {
        this.set_parent_sale(value);
      }
    }
  },
  methods: {
    ...mapActions([
      'set_parent_sale',
      'add_sale',
      'set_current_sale_key'
    ])
  }
}
</script>
