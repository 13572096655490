<template>
  <div>
    <el-card class="box-card">
      <div
        slot="header"
        class="clearfix"
      >
        <span>
          <strong>Assigned users</strong>
        </span>
        <span
          class="right"
          style="margin-top: -10px;"
        >
          <el-select v-model="selected_user">
            <el-option-group
              v-for="group in users_grouped"
              :key="group[0]"
              :label="group[0]"
            >
              <el-option
                v-for="item in group[1]"
                :key="item.crm_user_id"
                :label="item.user_name"
                :value="item.crm_user_id"
              />
            </el-option-group>
          </el-select>
          <el-button
            size="mini"
            type="primary"
            :disabled="selected_user == '' || selected_user == null"
            @click="add_user_skill"
          >
            Add User
          </el-button>
        </span>
      </div>
      <el-row>
        <draggable
          v-model="crm_users_lists"
          draggable=".item"
          @change="change_user_order"
        >
          <div
            v-for="(user, index) in crm_users_lists"
            :key="user.id"
            class="item"
            style="margin: 2px;"
          >
            <el-row>
              <el-col :span="4">
                {{ index + 1 }} - {{ user.name }}
              </el-col>
              <el-col :span="6">
                <span style="font-weight: 300;font-size: 14px;">
                  {{ user.branches_list }}
                </span>
              </el-col>
              <el-col :span="6">
                <el-button
                  type="danger"
                  size="mini"
                  @click="remove_user_skill(user.id)"
                >
                  Remove
                </el-button>
              </el-col>
            </el-row>
          </div>
        </draggable>
      </el-row>
    </el-card>
  </div>
</template>
<script>
import draggable from 'vuedraggable';

export default {
  name: 'SkillUsers',
  components: {
    draggable,
  },
  props: ['job_id'],
  data() {
    return {
      selected_user: '',
      crm_users_lists: [],
      users_grouped: [],
    };
  },
  computed: {},
  watch: {
    job_id: function(newVal) {
      this.load_crm_users();
    },
  },
  mounted() {
    this.load_crm_users();
    this.load_users_grouped();
  },
  methods: {
    change_user_order: function(e) {
      let params = {
        job_id: this.job_id,
        users_list: this.crm_users_lists.map((c) => c.crm_user_id),
      };

      this.$http.post('/reorder_skill_crm_users/', params).then(
        (response) => {
          this.$message({
            type: 'success',
            message: 'Reordered successfully.',
          });
        },
        (response) => {
          this.$message({ type: 'error', message: response });
        }
      );
    },
    load_users_grouped() {
      this.$http.get('/users_grouped_by_branch').then(
        (response) => {
          this.users_grouped = response.data.grouped;
          this.users_list = response.data.non_grouped;
        },
        (response) => {
          
          console.log(response.data);
        }
      );
    },
    load_crm_users() {
      this.$http.get(`/skill_crm_users/${this.job_id}`).then(
        (response) => {
          this.crm_users_lists = response.data;
        },
        (response) => {}
      );
    },
    remove_user_skill(id) {
      this.$http.delete(`/crm_user_skills/${id}`).then(
        (response) => {
          this.load_crm_users();
          this.$message({
            type: 'success',
            message: 'Skill has been deleted.',
          });
          // this.skills.filter((el, idx) => {
          //   if (el.id == id) {
          //     this.$delete(this.skills, idx);
          //     return;
          //   }
          // });
        },
        (response) => {
          this.$message({ type: 'error', message: response });
        }
      );
    },
    add_user_skill() {
      let user_exists =
        this.crm_users_lists.filter((c) => c.crm_user_id == this.selected_user)
          .length > 0;
      if (user_exists == true) {
        this.$message({ type: 'info', message: 'User exists already!' });
        return;
      }
      let loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      this.$http
        .post('/crm_user_skills/', {
          id: this.selected_user,
          skills: [this.job_id],
        })
        .then(
          (response) => {
            this.load_crm_users();
            loading.close();
            this.$message({
              type: 'success',
              message: 'User has been added successfully.',
            });
          },
          (response) => {}
        );
    },
  },
};
</script>
