<template>
  <el-table
    id="task_time_spents_table_id"
    :data="task_time_spents"
    stripe
    show-summary
    :summary-method="onSummaries"
    style="width: 100%"
  >
    <el-table-column
      prop="proposal.id"
      label="Quote#"
      width="110"
    />
    <el-table-column
      prop="proposal.job_name"
      label="Job Name"
    />
    <el-table-column
      prop="task_name"
      label="Task Name"
    />
    <el-table-column
      prop="time_in_minutes"
      label="Spent Time"
    >
      <template slot-scope="scope">
        <HoursInput
          v-if="is_hours"
          v-model="scope.row.time_in_minutes"
          @change="onUpdate(scope.row.id, scope.row.time_in_minutes)"
        />
        <el-input-number
          v-else
          v-model="scope.row.time_in_minutes"
          size="mini"
          :min="1"
          @change="onUpdate(scope.row.id, scope.row.time_in_minutes)"
        />
      </template>
    </el-table-column>
    <el-table-column
      label="Operations"
      width="110"
    >
      <template slot-scope="scope">
        <el-button
          size="mini"
          type="danger"
          @click="onDelete(scope.row.id)"
        >
          Delete
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import {defaultInput} from '../../../utilities/DefaultInput';
import {display_time} from '../../../utilities/Time';
import {SumFromArray} from '../../../utilities/Number';

import { loadTaskTimeSpent, deleteTaskTimeSpent, updateTaskTimeSpent } from '../../../api/task_time_spent';
import HoursInput from '../../shared/HoursInput';

export default {
  name: 'TaskTimeSpentsList',
  components: { HoursInput },
  props: {
    time_sheet_id: {
      validator: (value) => {
        return (typeof value === 'number' || typeof value === 'undefined');
      },
      required: true
    },
    is_hours: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      task_time_spents: []
    };
  },
  created() {
    this.load_time_spents();
  },
  methods: {
    onDelete(id) {
      deleteTaskTimeSpent(this, id).then(({data})=>{
        this.load_time_spents();
        this.$message({
          type: 'success',
          message: data.message
        });
      });
    },
    onUpdate(id, time_in_minutes) {
      if(!time_in_minutes || time_in_minutes <= 0) {
        this.$message({
          type: 'warning',
          message: 'Need to input time more than 0'
        });
        return;
      }

      updateTaskTimeSpent(this, id, time_in_minutes).then(({data})=>{
        this.load_time_spents();
        this.$emit('onRefreshTimeSheets');
        this.$message({
          type: 'success',
          message: data.message
        });
      });
    },
    onSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      if (data.length === 0) return sums;

      sums[0] = 'Total';
      const values = data.map(item => Number(item[columns[3].property]));
      if (!values.every(value => isNaN(value))) {
        let sum_spent = SumFromArray(values);
        sums[3] = `${display_time(true, sum_spent)} | ${display_time(false, sum_spent)}`;
      }
      return sums;
    },
    load_time_spents() {
      if(!this.time_sheet_id) return;

      loadTaskTimeSpent(this, this.time_sheet_id).then(({data})=>{
        this.task_time_spents = data;
        this.$nextTick(() => { defaultInput('#task_time_spents_table_id'); });
      });
    }
  }
};
</script>
