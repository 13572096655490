import { render, staticRenderFns } from "./LayerAttributes.vue?vue&type=template&id=64f58136&scoped=true&"
import script from "./LayerAttributes.vue?vue&type=script&lang=js&"
export * from "./LayerAttributes.vue?vue&type=script&lang=js&"
import style0 from "./LayerAttributes.vue?vue&type=style&index=0&id=64f58136&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64f58136",
  null
  
)

export default component.exports