<template>
    <div>

        <table>
            <thead>
            <tr>
                <td><strong>Quantity</strong></td>
                <td><strong>Price</strong></td>
                <td></td>
            </tr>                         
            </thead>
            <tbody>
            <tr v-for="(row,index) in qp">
                <td>
                    <input type="hidden" :value="row.id"
                           :name="'stock_item[stock_item_quantity_prices_attributes][' + index +'][id]'"
                           :id="'stock_item_stock_item_quantity_prices_attributes_' + index +'_id'"/>
                    <input type="text" v-model="row.quantity"
                           :name="'stock_item[stock_item_quantity_prices_attributes][' + index +'][quantity]'"
                           :id="'stock_item_stock_item_quantity_prices_attributes_' + index +'_quantity'"></td>
                <td>
                    <input type="text" v-model="row.price"
                           :name="'stock_item[stock_item_quantity_prices_attributes][' + index +'][price]'"
                           :id="'stock_item_stock_item_quantity_prices_attributes_' + index +'_price'"></td>
                <td>
                    <button class="trash" v-on:click.prevent="removeRow(row, index)">Remove</button>
                </td>
            </tr>
            </tbody>
        </table>
        <div>
            <button class="button btn-primary" v-on:click.prevent="addRow">Add row</button>
        </div>
        <br>
    </div>

</template>


<script>
    export default { 

        props: ['quantity_prices'], 
        data() {
            return {
                qp: this.quantity_prices
            }
        },
        methods: {

            addRow: function () {
                this.quantity_prices.push({quantity: '', price: ''});
            },
            removeRow: function (row, index) {
                this.qp.splice(index, 1);
                this.$http.delete(`/stock_item_quantity_prices/${row.id}`)
            }
        }
    };

</script>