<template>
  <div :id="category_id">
    <div
      style="margin-bottom: 20px;"
    >
      <el-button
        v-if="editable"
        size="mini"
        round
        @click="onAddCategory"
      >
        Add a Category
      </el-button>
      &nbsp;
      <a :href="download_link(proposal_id)">
        <el-button
          size="mini"
          round
        >
          Export a SpreadSheet
        </el-button>
      </a>
      &nbsp;
      <el-upload
        v-if="editable"
        class="btn-upload"
        :before-upload="onBeforeUpload"
        :on-success="onSuccessUpload"
        :on-error="onErrorUpload"
        :show-file-list="false"
        :action="import_url"
        :headers="{ 'X-CSRF-TOKEN': csfr }"
      >
        <el-button
          size="mini"
          round
        >
          Import a SpreadSheet
        </el-button>
      </el-upload>
    </div>
    <el-tabs
      v-model="active_working_file_category"
      type="border-card"
    >
      <el-tab-pane
        v-for="(category) in working_file_categories"
        :key="category.id"
        :label="category.name"
        :name="String(category.id)"
      >
        <div
          v-if="editable"
          class="component-container"
        >
          <el-button
            size="mini"
            type="danger"
            icon="delete"
            style="float:right"
            @click="onDeleteCategory(category)"
          >
            <i class="el-icon-delete" /> Category
          </el-button>
        </div>
        <CategoryInformation
          class="component-container"
          :category="category"
          :editable="editable"
        />
        <slot :category="category" />
      </el-tab-pane>
    </el-tabs>

    <el-dialog
      :title="form.title"
      :visible.sync="category_dialog"
      width="90%"
    >
      <el-form label-width="125px">
        <el-form-item label="Name">
          <el-input v-model="form.name" />
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="category_dialog = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="form.submit_fn"
        >Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { createCategoryWorkingFile, editCategoryWorkingFile, deleteCategoryWorkingFile, updateCategoryWorkingFilePositions} from '../../../api/category_working_files';
import Sortable from 'sortablejs';
import {EventBus} from '../../../utilities/EventBus';
import {getFileExtension} from '../../../utilities/File';
import CategoryInformation from './CategoryInformation';

import { editor_store } from 'store/modules/editor';

export default {
  name: 'Categories',
  components: {CategoryInformation},
  store: editor_store,
  props: {
    editable: {
      type: Boolean,
      default: true
    },
    category_id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      category_dialog: false,
      form: {
        title: 'New Category',
        id: null,
        name: '',
        submit_fn: this.onCreateCategory
      },
      sortable_obj: null,
      csfr: Vue.http.headers.common['X-CSRF-Token'],
      import_url: ''
    };
  },
  computed: {
    ...mapGetters([
      'working_file_categories',
      'proposal_id',
      'proposal',
      'get_active_working_file_category'
    ]),
    active_working_file_category: {
      get() {
        return this.get_active_working_file_category;
      },
      set (value) {
        this.set_active_working_file_category(value);
      }
    }
  },
  watch: {
    working_file_categories() {
      this.active_focus();
    },
    editable(newVal) {
      if(newVal === false && this.sortable_obj) {
        this.sortable_obj.option('disabled', true);
      }
    }
  },
  mounted() {
    this.active_focus();
    if(this.editable === true) {
      this.make_draggable();
      this.import_url = `/api/spread_sheets/proposals/${this.proposal_id}/import`;
    }
  },
  methods: {
    ...mapActions(['set_active_working_file_category']),
    onBeforeUpload(file) {
      if(getFileExtension(file.name) !== 'xlsx') {
        this.$message({
          type: 'error',
          message: 'The extension of the file should be xlsx'
        });
        return false;
      }
      this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      return true;
    },
    onSuccessUpload(response) {
      this.$loading().close();

      this.$message({
        type: 'success',
        message: response.message
      });

      EventBus.$emit('reloadCategoryWorkingFiles');
      EventBus.$emit('reloadItems');
    },
    onErrorUpload(err) {
      this.$loading().close();
      let error_obj = JSON.parse(err.message);

      this.$message({
        type: 'error',
        message: error_obj.message
      });
    },

    onAddCategory() {
      this.form.title = 'New Category';
      this.form.name = '';
      this.form.id = null;
      this.form.submit_fn = this.onCreateCategory;
      this.category_dialog = true;
    },
    onCreateCategory() {
      createCategoryWorkingFile(this, this.proposal_id, this.form.name).then(({data})=>{
        this.$message({
          type: 'success',
          message: data.message
        });
        EventBus.$emit('reloadCategoryWorkingFiles', { active_category_id: String(data.id)});
      });
      this.category_dialog = false;
    },
    onEditCategory() {
      editCategoryWorkingFile(this, this.form.id, this.form.name).then(({data})=>{
        this.$message({
          type: 'success',
          message: data.message
        });
        EventBus.$emit('reloadCategoryWorkingFiles');
      });
      this.category_dialog = false;
    },
    onDeleteCategory(category) {
      deleteCategoryWorkingFile(this, category.id).then(({data})=>{
        this.$message({
          type: 'success',
          message: data.message
        });
        EventBus.$emit('reloadCategoryWorkingFiles');
      });
    },
    onTabEnd(ev) {
      let oldIndex = ev.oldIndex;
      let newIndex = ev.newIndex;
      let c_ids = [];

      this.working_file_categories.forEach((category)=> { c_ids.push(category.id); });
      let changed_tab = c_ids.splice(oldIndex, 1)[0];
      c_ids.splice(newIndex, 0, changed_tab);
      let updated_list = c_ids.map((c, idx)=>{
        return {id: c, position: idx};
      });

      updateCategoryWorkingFilePositions(this, this.proposal.id, updated_list).then(()=>{
        EventBus.$emit('reloadCategoryWorkingFiles');
      });
    },
    active_focus() {
      if( this.working_file_categories.length === 0 ||
          this.working_file_categories.find((category) => String(category.id) === this.active_working_file_category) ) {
        return;
      }
      this.active_working_file_category = String(this.working_file_categories[0].id);
    },
    make_draggable() {
      this.$nextTick(() => {
        let el = document.querySelector(`#${this.category_id} .el-tabs__nav`);
        this.sortable_obj = Sortable.create(el, {
          animation: 200,
          filter: '.el-icon-close',
          onEnd: this.onTabEnd
        });
      });
    },
    download_link(proposal_id) {
      return `/spread_sheets/proposals/${proposal_id}/download`;
    }
  }
};
</script>

<style lang="scss" scoped>
.component-container {
  margin-top: 10px;
  margin-bottom: 10px;
}

.btn-upload {
  display: inline-block;
}
</style>
