import {Request} from '../utilities/Request';

export function simple_job_view_data(this_obj, proposal_id) {
  return Request.post(
    this_obj,
    '/api/simple_job_view_data',
    null,
    {
      proposal_id: proposal_id
    }
  );
}

