export function defaultInput(parent='') {
  Array.from(document.querySelectorAll(`${parent} .el-input__inner`)).forEach(
    function(e) {
      e.classList.add('browser-default');
      if (e.querySelector('input') != null)
        e.querySelector('input').className += ' browser-default';
    }
  );
}

export function set_default_input() {
  Array.from(document.querySelectorAll('.el-select__input, .el-input__inner')).forEach(
    function (e) {
      e.classList.add('browser-default');
      if (e.querySelector('input') != null)
        e.querySelector('input').className += ' browser-default';
    }
  );
}
