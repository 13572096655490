<template>
  <div class="stocks_list_div">
    <div style="padding:10px;">
      <div class=" white">
        <div>
          <div id="stocks_list_table"  class="responsive-table highlight" >
            <el-collapse accordion>
              <el-collapse-item
                  v-for="stock in filtered_stock_items"
                  :key="stock.id"
                  class="stockItems"
                  :name="stock.id"
              >
                <template slot="title">
                  <div class="inside-stock-info"
                      :style="[ (stock.request != undefined && stock.request.is_approved == 1) || stock.mark_as_deleted == 1
                        ? {'text-decoration': 'line-through','font-style:': 'italic',} : {},]" >
                    <span v-show="stock.is_fixed_asset" style="font-weight:300" >
                      <i class="el-icon-s-tools" style="color:grey" />
                    </span>
                    <span v-if="proposal.approved == 1" class="slimfont" >{{ stock.quantity }} x</span>
                    <span v-else-if="proposal.approved == 0 && (stock.is_workspace == false || (stock.is_workspace == true && stock.is_initiated == 0))"
                        class="slimfont" >
                      <span v-if="stock.purchase_order == undefined || stock.purchase_order == null || (stock.purchase_order != undefined && stock.purchase_order.status == 'APPROVED')"
                          class="slimfont" >
                      </span>
                    </span>
                    <span v-else class="slimfont" >{{ stock.quantity }} x</span>
                    {{ stock.stock_name }}
                    <span style="color:#666;" class="slimfont">{{ stock.supplier }}</span>

                    <span v-if="stock.purchase_order != undefined">
                      <span v-show="stock.purchase_order.status == 'RETURNED'" style="color:red;" >{{ stock.purchase_order.status }} | PO: {{ stock.purchase_order.id }}</span>
                      <span v-show="stock.purchase_order.status == 'PENDING'" style="color:orange; " >{{ stock.purchase_order.status }} APPROVAL</span>
                      <span v-show="stock.purchase_order.status == 'REJECTED'" style="color:red;" >{{ stock.purchase_order.status }}</span>
                      <span v-show="stock.purchase_order.status == 'RECEIVED'" style="color:green;" >{{ stock.purchase_order.status }}</span>
                    </span>

                    <span class="slimfont">{{ stock.specs }}</span>
                    <span v-show="!stock.purchase_order">
                      <i class="el-icon-circle-check" style="color:green" >{{ stock.stock_on_hand }}</i>
                    </span>
                    <span v-if="stock.purchase_order != undefined">
                      <i class="el-icon-circle-check" style="color:green" >{{ calc_qty_from_stock(stock.quantity, stock.purchase_order.quantity) }}</i>
                      <i class="el-icon-shopping-cart-full" style="color:red" >{{ stock.purchase_order.quantity }}</i>
                    </span>
                  </div>
                </template>
                <StockItemEntry
                    :stock="stock"
                    :style="[(stock.request != undefined && stock.request.is_approved == 1) || stock.mark_as_deleted == 1 ? { 'text-decoration': 'line-through','font-style:': 'italic',color: 'red',} : {},]"
                    @select_open_jobs_stock="select_open_jobs_stock"
                />
              </el-collapse-item>
            </el-collapse>
            <ChargePOs/>
            <div v-show="proposal.completed == 0" class="col s12 m12 popupboxMain" >
              <stock-item-job-view-search :proposal_id="proposal.id" :branch_id="proposal.branch_id" @get_stock_item_list="load_stocks_list()" v-show="proposal.completed != 1" class="right" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="Stock attached tasks" :visible.sync="related_stock_dialog" >
      <StockRelateTasksList
          :jobs_list="stock_related_tasks"
          @hide_stock_related_dialog="related_stock_dialog = false"
      />
    </el-dialog>
    <el-dialog
        title="Stock Open Allocated Jobs"
        class="allocatedstock"
        :visible.sync="open_allocated_dialog"
    >
      <StockAllocatedOpenJobs
          :stock_id="open_job_stock_id"
      />
    </el-dialog>
  </div>
</template>

<script>
import StockItemEntry from '../sections/StockItemsEntry.vue';
import ChargePOs from '../sections/ChargePOs.vue';
import {mapGetters, mapActions} from 'vuex';
import {editor_store} from 'store/modules/editor';
import {ProposalStockItemMixin} from 'mixins/ProposalStockItemMixin.js';
import StockRelateTasksList from 'components/stock_items/StockRelateTasksList';
import StockAllocatedOpenJobs from './StockAllocatedOpenJobs';
import StockItemJobViewSearch from '../../stock_items/StockItemJobViewSearch.vue'

export default {
  name: 'JobStockList',
  components: {
    StockItemEntry,
    StockRelateTasksList,
    ChargePOs,
    StockAllocatedOpenJobs,
    StockItemJobViewSearch
  },
  mixins: [ProposalStockItemMixin],
  store: editor_store,
  props: ['list_name'],
  data() {
    return {
      selected: [],
      open_job_stock_id: '',
      open_allocated_dialog: false,
      options: [],
      related_stock_dialog: false,
      stock_related_tasks: [],
      is_loading: false,
      stock_item_id: null,
      stock_quantity: 1,
      sale_price: 0,
      stock_quantity_calculated_overwritten: null,
      last_used_stock_items: false
    };
  },
  computed: {
    ...mapGetters(['proposal', 'stock_items_list', 'categories_list']),
    filtered_stock_items() {
      let included_list = this.stock_items_list.filter(i => i.is_included === true);

      if (this.list_name == undefined) {
        return included_list;
      } else if (this.list_name.toLowerCase() == 'main list') {
        return included_list.filter((c) => c.working_file_id == 0);
      } else {
        let _this = this;
        let result = included_list.filter(
            (c) => c.working_file != null && c.working_file.name.toLowerCase() == _this.list_name.toLowerCase()
        );

        return result;
      }
    },
  },
  watch: {
    'proposal.id': function (val) {
      if (val != undefined) this.load_stocks_list();
    },
    categories_list: function (val) {
      this.options = this.categories_list;
    },
  },
  mounted: function () {
    if (this.categories_list.length == 0) this.load_categories_list();
    this.set_inputs_to_default();
  },

  methods: {
    ...mapActions(['set_stock_items_list', 'load_categories_list']),
    select_open_jobs_stock(stock_id) {
      this.open_allocated_dialog = true;
      let _this = this;
      setTimeout(function () {
        _this.open_job_stock_id = stock_id;
      }, 300);
    },
    open_modal() {
      this.$modal.show('addStockItem');
    },
    hide_modal() {
      this.$modal.hide('addStockItem');
    },
    calc_qty_from_stock(qty, po_qty) {
      var from_stock = qty - po_qty;
      return from_stock < 0 ? 0 : from_stock;
    },
    load_stocks_list() {
      let _this = this;
      _this.load_stock_items_list(_this.proposal.id).then((response) => {
        _this.set_stock_items_list(response);
      });
    },
    set_inputs_to_default() {
      let _this = this;
      setTimeout(() => {
        Array.from(
            document.querySelectorAll('.stocks_list_div .el-input')
        ).forEach(function (e, i) {
          e.classList.add('browser-default');
          if (e.querySelector('input') != null)
            e.querySelector('input').className += ' browser-default';
        });
      }, 500);
    },
    get_stock_category(stock) {
      if (stock.sign_id != undefined) return 'signage';
      else if (stock.print_calculation_id != undefined) return 'print';
      else return 'default';
    },
    current_selected_value(selectedValue) {
      let self = this;
      if (selectedValue.main_category != undefined) {
        if (this.last_used_stock_items) {
          this.load_last_used_stock_items(selectedValue.id);
        } else {
          this.load_favorite_stock(selectedValue.id);
        }
      }
      if (this.selected != null) {
        if (this.selected.length == 1) {
          this.stock_item_id = selectedValue.id;
          this.disabled = 'false';
          this.sale_price = selectedValue.sale_price;
        }
      }
    },
    save_stock_item: function () {
      let self = this;

      if (self.stock_item_id != null && self.stock_quantity > 0) {
        this.$http
            .post('/save_stock_item', {
              id: self.proposal.id,
              stock_item_id: self.stock_item_id,
              proposal_id: self.proposal.id,
              stock_quantity: self.stock_quantity,
              sale_price: self.sale_price,
            })
            .then(
                (response) => {
                  self.stock_items_list.push(response.body.data);
                  self.check_stock_attached_tasks(self.stock_item_id);
                  self.stock_item_id = null;
                  self.selected = null;
                  this.categories();
                  self.set_inputs_to_default();
                  this.$message({
                    message: 'Stock has been added',
                    type: 'success',
                  });
                },
                function (response) {
                  console.log(response);
                }
            );
      } else {
        console.log('error');
      }
    },
    check_stock_attached_tasks(stock_item_id) {
      let selected_stock = this.stock_items_list.filter((item) => {
        return item.stock_item_id == stock_item_id;
      })[0];

      this.get_stock_attached_tasks(stock_item_id).then(
          (response) => {
            if (response.length > 0) {
              this.stock_related_tasks = response;

              this.stock_related_tasks.forEach((el, idx) => {
                el['time_allocated_labour'] =
                    this.stock_quantity *
                    (selected_stock.speed_rate == '0'
                        ? 1
                        : selected_stock.speed_rate);
              });
              this.related_stock_dialog = true;
            }
          },
          (reason) => {
            console.log(reason);
          }
      );
    },
    internal_value(internalValue) {
      let self = this;
      if (internalValue.length === 0) {
        this.categories();
      }
      self.is_loading = true;
      if (internalValue.length === 1) {
        this.$http
            .post('/category_products', {
              category_supplier_id: internalValue[0].id,
            })
            .then(
                (response) => {
                  self.is_loading = false;
                  self.options = response.data;
                },
                function (response) {
                  self.is_loading = false;
                  console.log(response);
                }
            );
      }
    },
    load_favorite_stock(category_id) {
      let _this = this;
      this.$http
          .post('/favorite_stocks_by_category', {
            category_supplier_id: category_id
          })
          .then((response) => {
            _this.options = response.data;
          });
    },
    load_last_used_stock_items(category_id) {
      let _this = this;
      this.$http
          .post('/last_used_stock_items_by_category', {
            category_supplier_id: category_id
          })
          .then((response) => {
            _this.options = response.data;
          });
    },
    find_subcategories(internalValue) {
      if (this.selected.length == 0) {
        this.options = this.categories_list;
      } else if (this.options.length == 0 && this.selected.length == 0) {
        this.categories();
        return;
      } else if (this.selected.length == 0 && this.options.length > 0) {
        return;
      }
      let self = this;

      self.is_loading = true;
      if (internalValue.length > 2) {
        this.$http
            .post('/category_products', {
              category_supplier_id: self.selected[0].id,
              term: internalValue,
            })
            .then(
                (response) => {
                  self.is_loading = false;
                  self.options = response.data;
                },
                function (response) {
                  self.is_loading = false;
                  console.log(response);
                }
            );
      } else {
        self.options = [];
      }
    },
  },
};
</script>

<style>
#stocks_list_table td {
  padding: 5px;
}

.slimfont {
  font-weight: 300;
}

.stockItems .el-collapse-item__header {
  height: auto !important;
  line-height: 17px !important;
}

.popupbox {
  padding: 20px;
  height: auto !important;
  left: 0 !important;
}

.addStockItemDiv {
  padding: 0px 20px;
  width: 400px !important;
}

.inside-stock-info {
  float: left;
}

.stockItems .el-collapse-item__arrow {
  margin: 0px 0px 0px 0px !important;
}

.allocatedstock .el-dialog {
  width: 90%;
  margin: 0 auto 0px;
}
</style>
