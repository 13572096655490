<template>
    <div>
        <autocomplete
                ref="autocomplete"
                placeholder="Accounts"
                :source="accounts"
                input-class="form-control"
                inputClass="select_submit"
                results-property="data"
                :results-display="formattedDisplay"
                name="search_account_id"
                initialValue=""
                @selected="reload"
        >
        </autocomplete>
    </div>
</template>

<script>
    import Autocomplete from 'vuejs-auto-complete'
                                             
    export default {
        components: {Autocomplete},
        data() {
            return {
             
            }
        },

        methods: {
            accounts(term) {
              let encoded_term = encodeURIComponent(term);
              return '/autocomplete_accounts?term=' + encoded_term
            },

            formattedDisplay(result) {
                return result.account_name
            },

            reload(){
             
            }


        }


    }


</script>