<template>
  <el-card class="box-card">
    <el-row :gutter="20">
      <el-col
        :span="24"
      >
        <ul class="list">
          <li class="list__item">
            <div class="list__attribute circle__red" />
            <div class="list__attribute">
              Oh dear!
            </div>
          </li>
          <li class="list__item">
            <div class="list__attribute circle__orange" />
            <div class="list__attribute">
              Keep Going!
            </div>
          </li>
          <li class="list__item">
            <div class="list__attribute circle__yellow" />
            <div class="list__attribute">
              Good Work!
            </div>
          </li>
          <li class="list__item">
            <div class="list__attribute circle__green" />
            <div class="list__attribute">
              Great Work!
            </div>
          </li>
          <li class="list__item">
            <div class="list__attribute circle__gold" />
            <div class="list__attribute">
              Fantastic!
            </div>
          </li>
        </ul>
      </el-col>
      <el-col
        :span="24"
      >
        <div
          class="overall-weighin"
          :class="bg_color"
        >
          {{ kpis_gauge.overall_perc }}%
        </div>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
export default {
  name: 'OverallWeighin',
  props: {
    kpis_gauge: {
      type: Object,
      required: true
    }
  },
  computed: {
    bg_color() {
      if (this.kpis_gauge.target_section == null) {
        return 'bg__gold'; //default bg
      }
      if(this.kpis_gauge.overall_perc >= this.kpis_gauge.target_section.weightin_gold) {
        return 'bg__gold';
      }
      else if(this.kpis_gauge.overall_perc >= this.kpis_gauge.target_section.weightin_green) {
        return 'bg__green';
      }
      else if(this.kpis_gauge.overall_perc >= this.kpis_gauge.target_section.weightin_yellow) {
        return 'bg__yellow';
      }
      else if(this.kpis_gauge.overall_perc >= this.kpis_gauge.target_section.weightin_orange) {
        return 'bg__orange';
      }
      else {
        return 'bg__red';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../assets/stylesheets/components/colors';

@mixin circle($bgcolor) {
  height: 23px;
  width: 23px;
  background-color:$bgcolor;
  border-radius: 50%;
  display: inline-block;
}
.circle__red {
  @include circle($red-color);
}
.circle__orange {
  @include circle($orange-color);
}
.circle__yellow {
  @include circle($yellow-color);
}
.circle__green {
  @include circle($green-color);
}
.circle__gold {
  @include circle($gold-color);
}

.overall-weighin{
  margin: auto;
  font-weight: bold;
  font-size: 30px;
  color: white;
  height: 135px;
  line-height: 135px;
  text-align: center;
  border-radius: 2%;
}
</style>
