<template>
  <div>
    <el-row :gutter="10">
      <el-col :sm="20" :md="11">
        <h3>
          <strong>Stock On Hand transactions</strong>
          <hr/>
        </h3>
        <el-table :data="stock_on_hand_trans" style="width: 100%">
          <el-table-column label="In/Out">
            <template slot-scope="scope">
              <span>
                <i
                    v-if="scope.row.quantity > 0"
                    style="color: #23ab23; font-size:16px"
                    class="fa fa-arrow-up"
                ></i>
                <i v-else style="color: #e00101; font-size:16px" class="fa fa-arrow-down"></i>
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="referenceable_type" label="Source"></el-table-column>
          <el-table-column prop="quantity" label="allocated/released"></el-table-column>
          <el-table-column prop="created_at" label="time"></el-table-column>
          <el-table-column prop="created_by" label="Created by"></el-table-column>
          <el-table-column prop="desc" label="Description"></el-table-column>
        </el-table>
      </el-col>
      <el-col :sm="20" :md="1"></el-col>
      <el-col :sm="20" :md="11">
        <h3>
          <strong>Stock Allocation transactions</strong>
          <hr/>
        </h3>
        <el-table :data="allocation_trans" style="width: 100%">
          <el-table-column label="In\Out">
            <template slot-scope="scope">
              <span>
                <i
                    v-if="scope.row.quantity > 0"
                    style="color: #23ab23; font-size:16px"
                    class="fa fa-arrow-up"
                ></i>
                <i v-else style="color: #e00101; font-size:16px" class="fa fa-arrow-down"></i>
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="referenceable_type" label="Source"></el-table-column>
          <el-table-column prop="quantity" label="quantity"></el-table-column>
          <el-table-column prop="created_at" label="time"></el-table-column>
          <el-table-column prop="created_by" label="Created by"></el-table-column>
          <el-table-column prop="desc" label="Description"></el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'StockItemTransactions',
  props: ['stock_item_branch_id'],
  data() {
    return {
      allocation_trans: [],
      stock_on_hand_trans: []
    };
  },
  mounted() {
    this.load_stock_transactions();
  },
  watch: {
    stock_item_branch_id(oldVal, newVal) {
      this.load_stock_transactions();
    }
  },
  methods: {
    load_stock_transactions() {
      let _this = this;

      this.allocation_trans = [];
      this.stock_on_hand_trans = [];
      this.$http
          .get(`/stock_item_branch_transactions/${this.stock_item_branch_id}`)
          .then(response => {
            _this.allocation_trans = response.body.filter(
                c => c.is_allocated == 1
            );
            _this.stock_on_hand_trans = response.body.filter(
                c => c.is_allocated == 0
            );
          });
    }
  }

};
</script>

<style>
</style>