var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inside-stock-info",style:([
    (_vm.stock.request != undefined && _vm.stock.request.is_approved == 1) ||
      _vm.stock.mark_as_deleted == 1
      ? { 'text-decoration': 'line-through', 'font-style:': 'italic' }
      : {},
  ])},[_c('div',[(
        _vm.stock.is_workspace == true &&
          _vm.proposal.approved == 0 &&
          _vm.stock.is_initiated == 1
      )?_c('div',{staticStyle:{"width":"100%","text-transform":"uppercase","background":"orange","text-align":"center","color":"#fff","padding":"5px","margin-bottom":"10px"}},[_vm._v("\n      Job is in workspace - Stock is initiated\n    ")]):_vm._e(),_vm._v(" "),_c('i',{staticClass:"el-icon-arrow-right",staticStyle:{"color":"grey"}}),_vm._v(" "),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.stock.favourite),expression:"stock.favourite"}],staticClass:"el-icon-star-on",staticStyle:{"color":"orange"}}),_vm._v(" "),_c('a',{attrs:{"target":"_blank","href":'../../../stock_items/' + _vm.stock.stock_item_id + '/edit'}},[_vm._v("ID:"+_vm._s(_vm.stock.id))]),_vm._v("\n    |\n    "+_vm._s(_vm.stock.working_file != undefined ? _vm.stock.working_file.name : "Main")+"\n    |\n    "),(_vm.stock.purchase_order != undefined)?_c('span',[_c('a',{attrs:{"href":_vm.get_po_link(_vm.stock.purchase_order.id)}},[_vm._v("\n        PO:"+_vm._s(_vm.stock.purchase_order.id)+"\n      ")])]):_vm._e(),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.stock.minimum_packet_quantity),expression:"stock.minimum_packet_quantity"}]},[_c('b',[_vm._v("Min.PQ")]),_vm._v("\n      "+_vm._s(_vm.stock.minimum_packet_quantity)+" |\n    ")]),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.stock.price),expression:"stock.price"}]},[_c('b',[_vm._v("Total:")]),_vm._v("\n      $"+_vm._s(_vm.stock_total_price)+" |\n    ")]),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.stock.purchase_order == undefined ||
          (_vm.stock.purchase_order != undefined &&
            _vm.stock.purchase_order.status != 'PENDING')
      ),expression:"\n        stock.purchase_order == undefined ||\n          (stock.purchase_order != undefined &&\n            stock.purchase_order.status != 'PENDING')\n      "}]},[_c('b',[_vm._v("Used:")]),_vm._v(" "),_c('el-input-number',{staticStyle:{"width":"60px"},attrs:{"id":"usedqty","disabled":_vm.has_project_plan == true ||
            _vm.used_qty_disabled == true ||
            (_vm.stock.is_workspace == true &&
              _vm.proposal.approved == 0 &&
              _vm.stock.is_initiated == 0),"min":0,"controls":false,"max":_vm.stock.quantity,"placeholder":"","size":"mini"},model:{value:(_vm.stock.used_qty),callback:function ($$v) {_vm.$set(_vm.stock, "used_qty", $$v)},expression:"stock.used_qty"}}),_vm._v(" "),_c('el-button',{attrs:{"disabled":_vm.has_project_plan == true ||
            _vm.used_qty_disabled == true ||
            (_vm.stock.is_workspace == true &&
              _vm.proposal.approved == 0 &&
              _vm.stock.is_initiated == 0),"type":"plain","icon":"el-icon-check","size":"mini","circle":""},on:{"click":function($event){return _vm.update_used_details(_vm.stock)}}})],1),_vm._v(" "),_c('div',[(_vm.stock.billable_locked == true && _vm.proposal.approved == 1)?_c('div',{staticStyle:{"padding":"0px 5px","float":"left"}},[_c('input',{staticClass:"filled-in",attrs:{"id":'n_billable_' + _vm.stock.id,"type":"checkbox","name":'n_billable_' + _vm.stock.id,"data-id":_vm.stock.id},domProps:{"checked":_vm.stock.billable},on:{"change":function($event){return _vm.update_stock_billable($event, _vm.stock.id)}}})]):(_vm.proposal.approved == 1)?_c('div',{staticStyle:{"padding":"0px 5px","float":"left"}},[_c('input',{staticClass:"filled-in",attrs:{"id":'n_billable_' + _vm.stock.id,"type":"checkbox","name":'n_billable_' + _vm.stock.id,"data-id":_vm.stock.id},domProps:{"checked":_vm.stock.billable},on:{"change":function($event){return _vm.update_stock_billable($event, _vm.stock.id)}}}),_vm._v(" "),_c('label',{attrs:{"for":'n_billable_' + _vm.stock.id}},[_vm._v("Billable")])]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"buttonfield"},[(_vm.stock.is_workspace == true && _vm.proposal.approved == 0)?_c('div',[(_vm.stock.is_initiated == 0)?_c('div',{staticStyle:{"padding":"0px 5px","float":"left"}},[_c('el-button',{staticClass:"stockbttns",on:{"click":function($event){$event.preventDefault();_vm.initiate_stock_item(_vm.get_stock_category(_vm.stock), _vm.stock.id)}}},[_vm._v("\n            Initiate Stock\n          ")])],1):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticStyle:{"padding":"0px 5px","float":"left"}},[_c('el-button',{staticClass:"stockbttns",on:{"click":_vm.load_open_jobs}},[_c('i',{staticClass:"el-icon-info",staticStyle:{"font-size":"14px"}}),_vm._v("\n          Jobs Same Stock\n        ")])],1),_vm._v(" "),(_vm.proposal.completed == 0 && _vm.stock.mark_as_deleted == 0)?_c('div',[(
            (_vm.stock.purchase_order != undefined &&
              _vm.stock.purchase_order.status == 'PENDING') ||
              _vm.stock.purchase_order == undefined
          )?_c('div',{staticStyle:{"padding":"0px 5px","float":"left"}},[_c('span',{staticStyle:{"float":"right","width":"30px"}}),_vm._v(" "),_c('el-button',{staticClass:"stockbttns",on:{"click":function($event){$event.preventDefault();return _vm.delete_stock_item('default', _vm.stock.id)}}},[_c('i',{staticClass:"el-icon-delete-solid",staticStyle:{"font-size":"14px"}}),_vm._v("\n            Delete stock\n          ")])],1):_vm._e(),_vm._v(" "),(
            _vm.stock.purchase_order != undefined &&
              _vm.stock.purchase_order.status == 'APPROVED' &&
              _vm.stock.request == undefined
          )?_c('div',{staticStyle:{"padding":"0px 5px","float":"left"}},[_c('el-button',{staticClass:"stockbttns",attrs:{"type":"warning"},on:{"click":function($event){return _vm.received_stock(_vm.stock.purchase_order)}}},[_c('i',{staticClass:"el-icon-success",staticStyle:{"font-size":"14px"}}),_vm._v(" Received\n            Stock\n          ")])],1):_vm._e(),_vm._v(" "),(
            _vm.stock.purchase_order != undefined &&
              (_vm.stock.purchase_order.status == 'APPROVED' ||
                _vm.stock.purchase_order.status == 'RECEIVED') &&
              _vm.stock.request == undefined &&
              _vm.stock.used_qty == 0
          )?_c('div',{staticStyle:{"padding":"0px 5px","float":"left"}},[_c('el-button',{staticClass:"stockbttns",on:{"click":function($event){$event.preventDefault();return _vm.request_delete_stock_item('default', _vm.stock.id)}}},[_c('i',{staticClass:"el-icon-delete-solid",staticStyle:{"font-size":"14px"}}),_vm._v("\n            Request Delete\n          ")])],1):_vm._e(),_vm._v(" "),(_vm.stock.request != undefined)?_c('div',[(_vm.stock.request.is_approved == 0)?_c('div',[_c('span',[_vm._v("Delete Request submitted")])]):(_vm.stock.request.is_approved == 1)?_c('div',[_c('span',[_vm._v("Delete Request approved")])]):_vm._e()]):_vm._e()]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }