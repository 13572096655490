import {Request} from '../utilities/Request';

export function loadInvoiceSummary(this_obj, account_id) {
  return Request.get(
    this_obj,
    `/api/accounts/${account_id}/invoice_summaries`
  );
}

export function loadTotalInvoiceSummary(this_obj) {
  return Request.get(
    this_obj,
    '/api/invoice_summaries/total'
  );
}

export function loadInvoiceSummaryIndex(this_obj, search) {
  return Request.get(
    this_obj,
    '/api/invoice_summaries',
    null,
    {
      search: search
    },
    null,
    'json',
    false
  );
}

export function downloadInvoiceSummariesSheet(this_obj, search) {
  return Request.get(
    this_obj,
    '/api/invoice_summaries/download_summaries',
    null,
    {
      search: search
    },
    null,
    'blob',
    false
  );
}
