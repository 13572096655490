<template>
  <div>
    <GrowthDevelopment
      :account_no="account_no"
      :account_name="account_name"
      :account_id="account_id"
      :summary_balance="summary_balance"
      :init_send_to="init_send_to"
    />
    <StatementLogs
      :statement_logs="statement_logs"
    />
    <h1>INVOICE BALANCING</h1>
    <MasterPaymentDialog
      v-if="showMPDialog == true"
      :account_id="account_id"
      :show_mp_dialog.sync="showMPDialog"
    />
    <CreditNotesDialog
      v-if="showCNDialog == true"
      :show_cn_dialog.sync="showCNDialog"
      :account_id="account_id"
    />
    <el-row>
      <el-col
        :span="24"
        class="align__right"
      >
        <el-button
          size="mini"
          @click="onShowCreditNoteDialog"
        >
          Create a Credit Note
        </el-button>

        <el-button
          size="mini"
          @click="onShowMPDialog"
        >
          Create a Manual Payment
        </el-button>
      </el-col>
    </el-row>

    <el-row>
      <el-col
        :span="8"
        class="balance__col"
      >
        <Invoices
          :invoices="invoices"
          :invoices_total="invoices_total"
          :invoices_outstanding="invoices_outstanding"
          :overdue_cnt="overdue_cnt"
          :total_page="invoice_total_page"
          @pageChange="onInvoicePageChange"
        />
        </el-col>
      <el-col
        :span="8"
        class="balance__col"
      >
        <CreditNotes
          :credit_notes="credit_notes"
          :credit_notes_total="credit_notes_total"
          :credit_notes_open_total="credit_notes_open_total"
          :total_page="credit_notes_total_page"
          @pageChange="onCreditNotePageChange"
        />
      </el-col>
      <el-col
        :span="8"
        class="balance__col"
      >
        <Payments :account_id="account_id"/>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import CreditNotes from './balancings/columns/credit_notes/CreditNotes';
import Invoices from './balancings/columns/invoices/Invoices';
import Payments from './balancings/columns/payments/Payments';
import GrowthDevelopment from './growth_development/GrowthDevelopment';

import {EventBus} from '../../utilities/EventBus';
import {loadInvoiceBalance} from '../../api/invoice';
import {loadInvoiceSummary} from '../../api/invoice_summary'
import {loadStatementLogs} from '../../api/statement';
import StatementLogs from "./statement_logs/StatementLogs";
import MasterPaymentDialog from "./balancings/create_master_payment/MasterPaymentDialog";
import CreditNotesDialog from "./balancings/create_credit_note/CreditNotesDialog";
import {loadCreditNotes} from '../../api/credit_notes';

export default {
  name: "InvoiceBalancing",
  props: ["account_id", "account_no", "account_name","init_send_to"],
  components: {CreditNotesDialog, MasterPaymentDialog, StatementLogs, GrowthDevelopment, Payments, Invoices, CreditNotes},
  data() {
    return {
      invoices: [],
      invoice_page: 1,
      invoice_total_page: 1,
      invoices_total: 0,
      invoices_outstanding: 0,
      overdue_cnt: 0,
      credit_notes: [],
      credit_notes_page: 1,
      credit_notes_total_page: 1,
      credit_notes_total: 0,
      credit_notes_open_total: 0,
      summary_balance: {
        current: {
          total: 0,
          invoice: 0,
          credit_note: 0
        },
        thirty: {
          total: 0,
          invoice: 0,
          credit_note: 0
        },
        sixty: {
          total: 0,
          invoice: 0,
          credit_note: 0
        },
        ninety: {
          total: 0,
          invoice: 0,
          credit_note: 0
        },
        unassigned_credits: 0,
        bad_debts: 0,
        amount_due: 0
      },
      statement_logs: [],
      showMPDialog: false,
      showCNDialog: false
    };
  },
  created() {
    this.load_statement_logs();
    this.load_invoices_all();
    this.load_credit_notes();

    EventBus.$on('statementSent',()=>{
      this.load_statement_logs();
    });

    EventBus.$on('refreshAllInvoice',()=>{
      this.load_invoices_all();
      this.load_credit_notes();
    });

    EventBus.$on('refreshInvoiceSummary',()=>{
      this.load_invoice_summary();
    });
  },
  methods: {
    load_invoices_all() {
      this.load_invoice_summary();
      this.load_invoice_balance();
    },
    load_statement_logs() {
      loadStatementLogs(this, this.account_id).then((response)=> {
        this.statement_logs = response.data
      });
    },
    load_invoice_summary() {
      loadInvoiceSummary(this, this.account_id).then((response)=>{
        this.summary_balance = response.data
      });
    },
    load_invoice_balance() {
      loadInvoiceBalance(this, this.account_id, this.invoice_page).then(({data})=>{
        this.invoices = data.invoices;
        this.invoices_total = data.invoices_total;
        this.invoices_outstanding = data.invoices_outstanding;
        this.overdue_cnt = data.overdue_cnt;
        this.invoice_total_page = data.total_page;
      });
    },
    load_credit_notes() {
      loadCreditNotes(this, this.account_id, this.credit_notes_page).then(({data})=>{
        this.credit_notes = data.credit_notes;
        this.credit_notes_total = data.total_price;
        this.credit_notes_open_total = data.open_total;
        this.credit_notes_total_page = data.total_page;
      });
    },
    onShowMPDialog() {
      this.showMPDialog = true;
    },
    onShowCreditNoteDialog() {
      this.showCNDialog = true;
    },
    onInvoicePageChange(page){
      this.invoice_page = page;
      this.load_invoice_balance();
    },
    onCreditNotePageChange(page){
      this.credit_notes_page = page;
      this.load_credit_notes();
    }
  }
};
</script>
<style lang='scss'>
.inputdiv {
  color: #999 !important;
  text-transform: uppercase !important;
}

@media only screen and (max-width: 600px) {
  .receipt {
    width: 99%;
  }
}
</style>

<style lang='scss' scoped>
 .balance__col {
   border:1px solid #f3f3f3;
   padding:10px;
   min-height:100vh
 }
</style>
