<template>
  <div id="crm_user_new">
    <el-button
      size="small"
      type="success"
      @click="show_dialog = true"
    >
      New User
    </el-button>
    <el-dialog
      :visible.sync="show_dialog"
      title="Create User"
      width="50%"
      @opened="set_default_input()"
    >
      <el-form
        ref="form"
        :model="crm_user_new"
        label-width="150px"
        autocomplete="off"
      >
        <el-form-item
          label="Default group"
          required
        >
          <el-select
            v-model="crm_user_new.role_id"
            filterable
            placeholder="Select default group"
            size="small"
            style="width: 280px;"
          >
            <el-option
              v-for="role in roles"
              :key="role.id"
              :label="role.name"
              :value="role.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="Job title"
          required
        >
          <el-input
            v-model="crm_user_new.title"
            size="small"
          />
        </el-form-item>
        <el-form-item
          label="First name"
          required
        >
          <el-input
            v-model="crm_user_new.user_name"
            size="small"
          />
        </el-form-item>
        <el-form-item
          label="Last name"
          required
        >
          <el-input
            v-model="crm_user_new.user_surname"
            size="small"
          />
        </el-form-item>
        <el-form-item
          label="Email"
          required
        >
          <el-input
            v-model="crm_user_new.user_email"
            size="small"
            placeholder="for IQ login"
          />
        </el-form-item>
        <el-form-item label="Phone">
          <el-input
            v-model="crm_user_new.phone_number"
            size="small"
          />
        </el-form-item>
        <el-form-item label="Mobile">
          <el-input
            v-model="crm_user_new.mobile_number"
            size="small"
          />
        </el-form-item>
        <el-form-item
          label="Password"
          required
        >
          <el-input
            v-model="crm_user_new.password"
            size="small"
            type="password"
            autocomplete="new-password"
            placeholder="must be 6 to 14 characters"
          />
        </el-form-item>
        <el-form-item
          label="Confirmation"
          required
        >
          <el-input
            v-model="crm_user_new.password_confirmation"
            size="small"
            type="password"
            placeholder="Password confirmation"
            autocomplete="new-password"
          />
        </el-form-item>
        <el-divider />
        <el-button
          type="primary"
          size="small"
          style="float:right;"
          @click="create_user"
        >
          Save
        </el-button>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: 'CrmUserNew',
  data() {
    return {
      crm_user_new: {
        role_id: ''
      },
      show_dialog: false,
      roles: [],

    };
  },
  mounted() {
    this.get_roles();
  },
  created() {

  },
  methods: {
    create_user(){
      let params = {
        crm_user: this.crm_user_new
      };
      this.$http.post('/crm_users', params).then(
        (response) => {
          this.$message({type: 'success', message: 'User created.'});
          window.location = `/crm_users/${response.data.id}/edit`;
        },
        (reject) => {
          this.$message({
            type: 'error',
            message: `${reject.data.errors.join(
              '<br>'
            )}`,
            dangerouslyUseHTMLString: true});
        }
      );
    },
    get_roles() {
      this.$http.get('/roles').then((response) => {
        this.roles = response.data;
      });
    },
    set_default_input() {
      Array.from(document.querySelectorAll('.el-select__input, .el-input__inner')).forEach(
        function (e) {
          e.classList.add('browser-default');
          if (e.querySelector('input') != null)
            e.querySelector('input').className += ' browser-default';
        }
      );
    },
  }
};
</script>
<style scoped>
#crm_user_new {
  max-width: 400px;
}

#crm_user_new .el-button-group button {
  margin-right: 5px;
}

#crm_user_new /deep/ .el-dialog__body {
  overflow: hidden;
}

@media only screen and (max-width: 993px) {
  #crm_user_new /deep/ .el-dialog {
    width: 100% !important;
  }
}
</style>
