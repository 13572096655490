<template>
  <div>
    <div
      v-show="!get_ncrs == ''"
      style=" padding: 10px;
    background:red;
    float: left;
    width: 100%;
    color: #fff;
    text-align: center;
    margin-top: 10px;"
    >
      <B>PLEASE RESOLVE THE NON CONFORMANCE REPORT FOR</B><br>
      <span style="text-transform: uppercase;"> {{ get_ncrs }}</span>
    </div>
    <el-col
      class="right"
      :xs="24"
      :sm="24"
      :md="12"
      :lg="12"
      :xl="12"
    >
      <el-select      
        v-show="false"
        v-model="selected_crm_user"
        class="right inputdiv"
        placeholder="Add users"
        @change="user_selected"
      >
        <el-option
          v-for="mainuser in crm_users_list"
          :key="mainuser.id"
          :label="mainuser.user_name"
          :value="mainuser.id"
        />
      </el-select>
    </el-col>
    <el-col
      :xs="2"
      :sm="2"
      :md="2"
      :lg="2"
      :xl="2"
      class="rightText"
      :disabled="task.completed"
      :class="{ removecol: task.completed }"
    />

    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="24"
      :xl="24"
      style="border-top:1px solid #333; margin-top:20px;"
    >
      <IndividualUser :task="task" />
    </el-col>

    <div
      style="border-top:1px solid #333; margin-top:10px; padding:20px; float:left;width:100%"
    >
      <span class="spanlabel">
        <span style="font-size:17px; color:#333;">Total:</span> Time allocated:
      </span>
      <Time :is_hours="is_hours" :minutes="task.time_allocated_labour" />
      <span class="spanlabel">Spent:</span>
      <Time :is_hours="is_hours" :minutes="total_time_spent" />
    </div>
  </div>
</template>
<script>
import IndividualUser from '../taskcomponents/IndividualUser.vue';
import { CrmUsersMixin } from 'mixins/CrmUsersMixin.js';
import { editor_store } from 'store/modules/editor';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { ProposalMixin } from 'mixins/ProposalMixin.js';
import Time from '../../../shared/Time';

export default {
  name: 'TaskUserDetails',
  store: editor_store,

  components: {
    IndividualUser,
    Time
  },
  mixins: [CrmUsersMixin, ProposalMixin],
  props: ['task'],
  data() {
    return {
      selected_crm_user: '',
    };
  },
  mounted() {},
  computed: {
    ...mapGetters([
      'proposal',
      'job_proposals_list',
      'crm_users_list',
      'non_conformance_report',
      'is_hours'
    ]),
    total_time_allocated() {
      return this.task.crm_users.reduce(function(total, item) {
        return total + parseInt(item.time_allocated_labour);
      }, 0);
    },
    total_time_spent() {
      return this.task.crm_users.reduce(function(total, item) {
        return total + parseInt(item.time_in_minutes);
      }, 0);
    },

    get_ncrs() {
      let filtered = this.non_conformance_report.filter(
        (c) => c.job_proposal_id == this.task.id
      );
      return filtered
        .filter((c) => c.resolved == false)
        .map((c) => c.title)
        .join(', ');

      // if(filtered.length == 0)
      // return {};
      // let approved_count = 0;
      // let resolved_count = 0;
      // filtered.forEach((el, idx) => {
      //   if (el["approved"] == 1) approved_count++;
      //   if (el["resolved"] == 1) resolved_count++;
      // });

      // let info = {
      //   approved: approved_count,
      //   resolved: resolved_count,
      // };
      // return info;
    },
  },
  methods: {
    user_selected() {
      if (this.selected_crm_user != undefined) {
        let params = {
          crm_user_id: this.selected_crm_user,
          job_proposal_id: this.task.id,
          time_allocated: 0,
        };
        this.add_user_to_task(params).then(
          (response) => {
            let temp_task = this.job_proposals_list.filter(
              (item) => item.id == this.task.id
            )[0];
            temp_task.crm_users = response.data;
            this.$message({
              message: response.message,
              type: 'success',
            });
          },
          (response) => {
            this.$message({
              message: response.message,
              type: 'error',
            });
          }
        );
      }
    },
  },
  watch: {},
};
</script>
<style>
.select-wrapper input.select-dropdown {
  height: 3em !important;
  margin: 0 0 0px 0 !important;
}
</style>
