<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-button
          size="small"
          type="info"
          @click.prevent="new_stock_item()"
        >
          New stock item
        </el-button>
        <stock-group />
        <el-upload
          ref="upload"
          action="/import_stock"
          :auto-upload="false"
          size="small"
          style="display: inline;"
          :headers="{ 'X-CSRF-TOKEN': csfr }"
          :on-success="upload_success"
          :on-error="upload_error"
        >
          <el-button
            slot="trigger"
            size="small"
            type="primary"
            style="margin-left: 10px;"
          >
            Select a csv file
          </el-button>
          <el-button
            size="small"
            type="success"
            @click="submit_upload(), loading = true"
          >
            Import
          </el-button>

          <a href="/example_import_stock_items.csv">
            <el-button size="small">
              Download example csv file
            </el-button>
          </a>
        </el-upload>
      </el-col>
    </el-row>
    <p style="margin-top: 32px;">
      Search Stock Items
      <el-badge
        :value="count"
        class="item"
        type="success"
      />
      <br>
      <el-checkbox v-model="all_stock_on_hand">
        Stock on hand
      </el-checkbox>
      <el-checkbox v-model="recurring_po">
        Recurring PO
      </el-checkbox>
      <el-checkbox v-model="auto_po">
        Auto PO
      </el-checkbox>
    </p>
    <el-input
      v-model="search_term"
      placeholder="search stock items"
      style="width: 200px;"
    />
    <el-select
      v-model="selected_category"
      placeholder="Select category"
      clearable
      filterable
    >
      <el-option
        v-for="category in category_suppliers"
        :key="category.id"
        :label="category.name"
        :value="category.id"
      />
    </el-select>
    <el-select
      v-model="selected_supplier"
      placeholder="Select supplier"
      clearable
      filterable
    >
      <el-option
        v-for="supplier in suppliers"
        :key="supplier.id"
        :label="supplier.name"
        :value="supplier.id"
      />
    </el-select>
    <el-select
      v-model="selected_limit"
      clearable
      filterable
    >
      <el-option
        v-for="limit in limits"
        :key="limit.value"
        :label="limit.label"
        :value="limit.value"
      />
    </el-select>
    <el-button
      type="primary"
      @click.prevent="submit_search"
    >
      SEARCH
    </el-button>
    <el-button
      type="success"
      @click.prevent="save_editable_stock_items()"
    >
      Save
    </el-button>
    <el-table
      v-loading="loading"
      :data="stock_items"
      style="width: 100%"
      stripe
      row-key="id"
      @sort-change="sortChange"
    >
      <el-table-column
        prop="id"
        label="Edit"
        sortable="custom"
        width="100%"
      >
        <template slot-scope="scope">
          <el-button
            type="success"
            size="mini"
            @click="edit_stock_items(scope.row.id)"
          >
            {{ scope.row.id }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        sortable="custom"
        label="On hand"
        width="100%"
        prop="total_stock_on_hand"
      >
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.total_stock_on_hand > 0"
            type="info"
            size="mini"
            @click="show_branch_dialog(scope.row.id)"
          >
            {{ scope.row.total_stock_on_hand }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        sortable="custom"
        label="Allocated"
        width="110%"
        prop="total_allocated"
      >
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.total_allocated > 0"
            type="info"
            size="mini"
            @click="show_branch_dialog(scope.row.id)"
          >
            {{ scope.row.total_allocated }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        prop="stock_code"
        label="Stock code"
        sortable="custom"
      >
        <template slot-scope="scope">
          <span @dblclick="set_editable(scope.$index,scope.row.id)">
            <el-input
              v-if="editable[scope.$index]"
              v-model="stock_items[scope.$index].stock_code"
            />
            <span v-else>{{ scope.row.stock_code }}</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="Name"
        sortable="custom"
      >
        <template slot-scope="scope">
          <span @dblclick="set_editable(scope.$index,scope.row.id)">
            <el-input
              v-if="editable[scope.$index]"
              v-model="stock_items[scope.$index].name"
            />
            <span v-else>{{ scope.row.name }}</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="internal_name"
        label="Internal Name"
        sortable="custom"
      >
        <template slot-scope="scope">
          <span @dblclick="set_editable(scope.$index,scope.row.id)">
            <el-input
              v-if="editable[scope.$index]"
              v-model="stock_items[scope.$index].internal_name"
            />
            <span v-else>{{ scope.row.internal_name }}</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="thickness"
        label="Thickness"
        sortable="custom"
      >
        <template slot-scope="scope">
          <span @dblclick="set_editable(scope.$index,scope.row.id)">
            <el-input
              v-if="editable[scope.$index]"
              v-model="stock_items[scope.$index].thickness"
            />
            <span v-else>{{ scope.row.thickness }}</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="width"
        label="Width"
        sortable="custom"
      >
        <template slot-scope="scope">
          <span @dblclick="set_editable(scope.$index,scope.row.id)">
            <el-input
              v-if="editable[scope.$index]"
              v-model="stock_items[scope.$index].width"
            />
            <span v-else>{{ scope.row.width }}</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="height"
        label="Height"
        sortable="custom"
      >
        <template slot-scope="scope">
          <span @dblclick="set_editable(scope.$index,scope.row.id)">
            <el-input
              v-if="editable[scope.$index]"
              v-model="stock_items[scope.$index].height"
            />
            <span v-else>{{ scope.row.height }}</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="price"
        label="Price"
        sortable="custom"
      >
        <template slot-scope="scope">
          <Money :amount="scope.row.price" />
        </template>
        <template slot-scope="scope">
          <span @dblclick="set_editable(scope.$index,scope.row.id)">
            <el-input
              v-if="editable[scope.$index]"
              v-model="stock_items[scope.$index].price"
            />
            <span v-else>  <Money :amount="scope.row.price" /></span>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="updated_at"
        label="Updated"
        sortable="custom"
      >
        <template slot-scope="scope">
          {{ new Date(scope.row.updated_at).toLocaleString() }}
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :visible.sync="show_stock_item_branches"
      title="Stock Item Branches"
    >
      <stock-item-branches :stock_item_id="stock_item_id" />
    </el-dialog>
  </div>
</template>
<script>
import Money from '../shared/Money';
import StockItemBranches from './StockItemBranches';
import StockGroup from './StockGroup';
import Vue from 'vue';

export default {
  components: {Money, StockItemBranches, StockGroup},
  data() {
    return {
      auto_po: false,
      recurring_po: false,
      all_stock_on_hand: false,
      loading: false,
      search_term: '',
      stock_items: [],
      category_suppliers: [],
      selected_category: '',
      suppliers: [],
      selected_supplier: '',
      limits: [{
        value: '50',
        label: '50'
      }, {
        value: '100',
        label: '100'
      }, {
        value: '500',
        label: '500'
      }, {
        value: '1000',
        label: '1000'
      }, {
        value: '5000',
        label: '5000'
      }, {
        value: 'all',
        label: 'all'
      }],
      selected_limit: 50,
      count: 0,
      editable: [],
      editable_ids: [],
      filtered_stock_items: [],
      csfr: Vue.http.headers.common['X-CSRF-Token'],
      show_stock_item_branches: false,
      stock_item_id: '',
      fileList: []
    };
  },
  mounted() {
    this.set_default_input();
  },
  created() {
    this.get_category_suppliers_list();
    this.get_suppliers();
    // this.get_search_stock_items();

  },
  methods: {
    show_branch_dialog(stock_item_id) {
      this.stock_item_id = stock_item_id;
      this.show_stock_item_branches = true;
    },
    sort_float_desc(key) {
      this.stock_items.sort((a, b) => parseFloat(a[key]) - parseFloat(b[key]));
    },
    sort_float_asc(key) {
      this.stock_items.sort((a, b) => parseFloat(b[key]) - parseFloat(a[key]));
    },
    sort_name_desc(key) {
      this.stock_items.sort((a, b) => a[key].localeCompare(b[key]));
    },
    sort_name_asc(key) {
      this.stock_items.sort((a, b) => b[key].localeCompare(a[key]));
    },
    sort_date_desc(key) {
      this.stock_items.sort((a, b) => new Date(b[key]).getTime() - new Date(a[key]).getTime());
    },
    sort_date_asc(key) {
      this.stock_items.sort((a, b) => new Date(a[key]).getTime() - new Date(b[key]).getTime());
    },
    sortChange(props) {
      console.log(props);
      this.editable = [];
      this.editable_ids = [];
      switch (props.prop) {
      case 'id':
      case 'price':
      case 'thickness':
      case 'width':
      case 'height':
      case 'total_stock_on_hand':
      case 'total_allocated':
        props.order === 'descending' ? this.sort_float_desc(props.prop) : this.sort_float_asc(props.prop);
        break;
      case 'name':
      case 'internal_name':
      case 'stock_code':
        props.order === 'descending' ? this.sort_name_desc(props.prop) : this.sort_name_asc(props.prop);
        break;
      case 'updated_at':
        props.order === 'descending' ? this.sort_date_desc(props.prop) : this.sort_date_asc(props.prop);
        break;
      default:
        break;
      }
    },
    submit_search() {
      this.loading = true;
      this.$http.post('/search_stock_items', {
        search_term: this.search_term,
        supplier_id: this.selected_supplier,
        category_supplier_id: this.selected_category,
        limit: this.selected_limit,
        all_stock_on_hand: this.all_stock_on_hand,
        recurring_po: this.recurring_po,
        auto_po: this.auto_po
      })
        .then(response => {
          this.loading = false;
          this.stock_items = response.data.stock_items;
          this.count = response.data.total_count;
          this.editable_ids = [];
          this.editable = [];
        });
    },
    submit_upload() {
      this.file_list = this.$refs.upload.uploadFiles;
      if (this.file_list.length === 0) {
        this.$message({
          type: 'error',
          message: 'Please select a file first.',
        });
        this.loading = false;
      } else {
        this.$refs.upload.submit();
      }
    },
    upload_error(e) {
      this.$message({
        type: 'error',
        message: JSON.parse(e.message).data,
      });
      this.loading = false;
    },
    upload_success() {
      this.$message({
        type: 'success',
        message: 'Stock items imported.',
      });
      this.$refs.upload.clearFiles();
      this.loading = false;
    },
    get_category_suppliers_list() {
      this.$http.get('/category_suppliers_not_hidden').then(response => {
        this.category_suppliers = response.data;
      });
    },
    get_suppliers() {
      this.$http.get('/suppliers').then(response => {
        this.suppliers = response.data;
      });
    },
    edit_stock_items(id) {
      window.open(`/stock_items/${id}/edit`, '_blank');
    },
    new_stock_item() {
      window.open('/stock_items/new', '_blank');
    },
    set_editable(row, id) {
      this.editable[row] = true;
      this.$set(this.editable, row, true);
      this.editable_ids.push(id);
    },
    save_editable_stock_items() {
      let _ = this;

      let unique_ids = [...new Set(this.editable_ids)];
      unique_ids.forEach(function (el) {
        let stock = _.stock_items.filter((s) => s.id === el);
        _.filtered_stock_items.push(stock[0]);
      });
      this.editable_ids = [];
      this.editable = [];

      this.$http.patch('/update_editable_stock_items', {stock_items: this.filtered_stock_items}).then(() => {
        this.filtered_stock_items = [];
      });
    },
    set_default_input() {
      Array.from(document.querySelectorAll('.el-input__inner')).forEach(
        function (e) {
          e.classList.add('browser-default');
          if (e.querySelector('input') != null)
            e.querySelector('input').className += ' browser-default';
        }
      );
    },
  }
};
</script>

<style scoped>

</style>