<template>
  <div>
    <!-- <div class="taskinsidetitles">
 
       Used Stock Items
   
 
    
     </div> -->

    <IndividualStockItemUsed :element="element" />
  </div>
</template>
<script>
import IndividualStockItemUsed from '../taskcomponents/IndividualStockItemUsed.vue';

export default {
  name: 'StockItemsUsedDeatils',
  components: {
    IndividualStockItemUsed,
  },
  props: ['element'],
  data() {
    return {};
  },
  watch: {},
  mounted() {},
  methods: {},
};
</script>
<style></style>
