<template>
  <section>
    <el-row :gutter="20">
      <el-col :span="24">
        <Header
          v-model="kpis_gauge"
          :user_id="user_id"
          :user_list="user_list"
          :is_manager="is_manager"
          @changeUser="changeUser($event)"
        />
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="14"
        :xl="14"
      >
        <MyKPIsGauges
          :kpis_gauge="kpis_gauge"
          :is_manager="is_manager"
        />
      </el-col>
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="10"
        :xl="10"
      >
        <el-row :gutter="20">
          <el-col
            :xs="24"
            :sm="24"
            :md="24"
            :lg="12"
            :xl="12"
          >
            <OverallWeighin :kpis_gauge="kpis_gauge" />
          </el-col>
          <el-col
            :xs="24"
            :sm="24"
            :md="24"
            :lg="12"
            :xl="12"
          >
            <UserProfile
              :selected_user="selected_user"
              :is_manager="is_manager"
            />
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col
        :span="24"
      >
        <UpSkillingGoals :selected_user="selected_user" />
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="18"
        :xl="14"
      >
        <Overview :selected_user="selected_user" />
      </el-col>
    </el-row>
  </section>
</template>

<script>
import Header from './Header';
import MyKPIsGauges from './MyKPIsGauges';
import OverallWeighin from './OverallWeighin';
import Overview from './Overview';
import UserProfile from './UserProfile';
import UpSkillingGoals from './UpSkillingGoals';

export default {
  name: 'MyKPIs',
  components: {
    UpSkillingGoals,
    UserProfile,
    Header,
    MyKPIsGauges,
    OverallWeighin,
    Overview
  },
  props: {
    user_id: {
      type: Number,
      required: true
    },
    user_list: {
      type: Array,
      required: true
    },
    is_manager: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      kpis_gauge: {},
      selected_user: this.user_id
    };
  },
  methods: {
    changeUser(changed_user) {
      this.selected_user = changed_user;
    }
  }
};
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
</style>
