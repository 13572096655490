import {
	editor_store,
	mm_to_px
} from "store/modules/editor";
import {
	mapGetters,
	mapActions
} from "vuex";
export const WorkspaceMixin = {
	store: editor_store,
	created() { },
	data() {
		return {
			nesting_specs: {}
		}
	},
	computed: {
		...mapGetters(["job_proposals_list", "stock_items_list", "layers_tree", "page_scale", "jobs_list"])
	},
	mounted() { },
	methods: {
		...mapActions(["load_layers_tree", "set_page_size"]),
		stroke_width(option = 4) {
			return `${option / this.page_scale}px`;
		},
		get_element_id(el) {
			let id = el.getAttribute("id");
			if (id == undefined || id == "null" || id == "") {
				let i = 1;
				let node_name = el.nodeName;

				while (d3.select(`#${node_name}_${i}`).node() != undefined && i < 1000)
					i++;
				id = `${node_name}_${i}`
				el.setAttribute('id', id);
			}
			return id;
		},
		get_parent_layer(elem) {
			if (elem.classList.contains("svg_layer")) {
				return elem;
			} else
				return this.get_parent_layer(elem.parentNode);
		},
		get_shape_relative_coords(obj) {
			let transform = d3.select(".svg_outer_container").attr("transform");
			d3.select(".svg_outer_container")
				.node()
				.removeAttribute("transform");
			var svg = d3.select("#proposal_workspace").node();
			let x1, y1, x2, y2, width, height, svgP, pt;

			pt = svg.createSVGPoint();
			pt.x = obj.getBoundingClientRect().x;
			pt.y = obj.getBoundingClientRect().y;

			svgP = pt.matrixTransform(svg.getScreenCTM().inverse());
			let x = svgP.x;
			let y = svgP.y;
			let factor_x = obj.getBBox().x / svgP.x;
			let factor_y = obj.getBBox().y / svgP.y;

			// Array.from(obj.points).forEach((point) => {
			// 	point.x = point.x * factor_x
			// 	point.y = point.y * factor_y
			// })

			return {
				x: factor_x,
				y: factor_y
			};

		},
		parseTransform(transform) {
			const matrix = [1, 0, 0, 1, 0, 0];  // Identity matrix as default
			const matrixRegex = /matrix\(([^)]+)\)/;
			const translateRegex = /translate\(([^)]+)\)/;
			const scaleRegex = /scale\(([^)]+)\)/;


			// Extract and apply matrix transform
			const matrixMatch = transform.match(matrixRegex);
			if (matrixMatch) {
				const matrixValues = matrixMatch[1].split(' ').map(Number);
				for (let i = 0; i < 6; i++) {
					matrix[i] = matrixValues[i];
				}
			}

			// Extract and apply translate transform
			const translateMatch = transform.match(translateRegex);
			if (translateMatch) {
				const [tx, ty] = translateMatch[1].split(' ').map(Number);
				matrix[4] += tx;
				matrix[5] += ty;
			}

			// Extract and apply scale transform
			const scaleMatch = transform.match(scaleRegex);
			if (scaleMatch) {
				const scaleValues = scaleMatch[1].split(' ').map(Number);
				const sx = scaleValues[0];
				const sy = scaleValues[1] !== undefined ? scaleValues[1] : sx;

				matrix[0] *= sx;  // scaleX
				matrix[1] *= sy;  // skewY (normally 0, but including for completeness)
				matrix[2] *= sx;  // skewX (normally 0, but including for completeness)
				matrix[3] *= sy;  // scaleY
			}

			return matrix;
		},
		calculateArea(polygon) {
			const data = polygon.getAttribute("points")
				.trim()
				.split(/\s+/)
				.map(point => point.split(",").map(Number));
			let area = 0;
			for (let i = 0; i < data.length; i++) {
				const [x1, y1] = data[i];
				const [x2, y2] = data[(i + 1) % data.length];
				area += (x1 * y2 - x2 * y1);
			}
			return Math.abs(area / 2);
		},
		calculateRectangleArea(rect) {
			const rectTransform = rect.getAttribute('transform');
			let width = parseFloat(rect.getAttribute('width'));
			let height = parseFloat(rect.getAttribute('height'));

			// Create rectangle points
			let points = [
				{ x: 0, y: 0 },
				{ x: width, y: 0 },
				{ x: width, y: height },
				{ x: 0, y: height }
			];

			if (rectTransform != null) {
				const rectMatrix = this.parseTransform(rectTransform);
				points = this.applyMatrixToRectPoints(points, rectMatrix);

				// Calculate transformed width and height
				width = Math.sqrt(Math.pow(points[1].x - points[0].x, 2) + Math.pow(points[1].y - points[0].y, 2));
				height = Math.sqrt(Math.pow(points[3].x - points[0].x, 2) + Math.pow(points[3].y - points[0].y, 2));
			}

			return width * height;
		},
		applyMatrixToPoints(points, matrix) {
			const [a, b, c, d, e, f] = matrix;
			return points.map(point => {
				return {
					x: (point.x * a + point.y * c + e) / 3.779527559,
					y: (point.x * b + point.y * d + f) / 3.779527559
				};
			});
		},
		applyMatrixToRectPoints(points, matrix) {
			const [a, b, c, d, e, f] = matrix;
			return points.map(point => {
				return {
					x: point.x * a + point.y * c + e,
					y: point.x * b + point.y * d + f
				};
			});
		},
		formatNumberWithCommas(number) {
			return number.toLocaleString();
		},
		calculatePolygonArea(polygon) {
			// Extract the points attribute from the polygon element
			let points = polygon.getAttribute("points")
				.trim()
				.split(/\s+/)
				.map(point => {
					let pnts = point.split(",");
					return {
						'x': pnts[0],
						'y': pnts[1]
					}
				});
			const polygonTransform = polygon.getAttribute('transform');
			if (polygonTransform != null) {
				const polygonMatrix = this.parseTransform(polygonTransform);
				points = this.applyMatrixToPoints(points, polygonMatrix);
			}
			else {
				points = points.map(point => {
					return {
						x: point.x / 3.779527559,
						y: point.y / 3.779527559
					};
				});
			}

			let area = 0;
			for (let i = 0; i < points.length; i++) {
				const j = (i + 1) % points.length;
				area += points[i].x * points[j].y;
				area -= points[j].x * points[i].y;
			}
			return Math.abs(area / 2);

		},
		get_polygon_area(polygon) {
			// Extract the points attribute from the polygon element
			const points = polygon.getAttribute("points")
				.trim()
				.split(/\s+/)
				.map(point => point.split(",").map(Number));

			// Use d3.polygonArea to calculate the area
			const area = d3.polygonArea(points);

			return Math.abs(area); // Return the absolute value of the area
		},
		get_relative_boundaries(obj) {
			let transform = d3.select(".svg_outer_container").attr("transform");
			d3.select(".svg_outer_container")
				.node()
				.removeAttribute("transform");
			var svg = d3.select("#proposal_workspace").node();
			let x1, y1, x2, y2, width, height, svgP, pt;

			pt = svg.createSVGPoint();
			pt.x = obj.getBoundingClientRect().x;
			pt.y = obj.getBoundingClientRect().y;

			svgP = pt.matrixTransform(svg.getScreenCTM().inverse());
			x1 = svgP.x;
			y1 = svgP.y;

			pt.x = obj.getBoundingClientRect().right;
			pt.y = obj.getBoundingClientRect().bottom;
			svgP = pt.matrixTransform(svg.getScreenCTM().inverse());
			x2 = svgP.x;
			y2 = svgP.y;
			width = x2 - x1;
			height = y2 - y1;
			let outer_width = d3.select('.svg_outer_container').node().getBBox().width
			let outer_height = d3.select('.svg_outer_container').node().getBBox().height
			if (transform != undefined)
				d3.select(".svg_outer_container").attr("transform", transform);

			return {
				x1: x1,
				y1: y1,
				x2: x2,
				y2: y2,
				width: width,
				height: height,
				outer_width: outer_width,
				outer_height: outer_height
			};
		},
		add_new_working_file(params) {
			return new Promise((resolve, reject) => {
				this.$http.post("/working_files", params).then(
					response => {
						resolve(response);
					},
					reason => {
						console.log(reason);
						reject(response);
					}
				);
			})
		},
		get_svg_content(svg) {
			return Array.from(svg.children).filter(c => c.nodeName == "g")[0].outerHTML;
		},
		append_svg_content(xml_file) {

			let svg = xml_file.xmlDoc.children[0];
			//let content = this.get_svg_content(svg);
			let content = svg.outerHTML;

			//xmlDoc
			let layer_g = d3.selectAll(".svg_inner_container .layers").append("g");
			layer_g.attr("data-name", "new layer");
			layer_g.attr("class", "svg_layer");

			// create new empty group
			layer_g = layer_g.append("g")
			layer_g.attr("class", "grouped");

			// scale group container if 'fit to selected'
			if (xml_file.scale != undefined)
				layer_g.attr("transform", xml_file.scale);

			// add SVG content inside
			layer_g.appendHTML(content);
			layer_g.selectAll('path,text,circle,polygon,polyline,line,rect').classed('grouped', true);


		},
		load_svg_content(xml_file) {

			// let graphics =
			// 	xml_file.xmlDoc.children[0].children[xml_file.xmlDoc.children[0].children.length - 1];

			let svg = xml_file.xmlDoc.children[0];
			// let graphics =
			// 	xml_file.xmlDoc.children[0].getElementsByTagName('g')[0];


			let imported_g = d3.select(".svg_inner_container");
			// if no layers class defined
			if (d3.select(svg).selectAll('.layers').nodes().length == 0) {
				imported_g = imported_g.append("g").classed("layers", true);
			}

			// if base layer is not defined
			if (
				d3.select(svg).selectAll('.base_layer').nodes().length == 0
			) {
				// get layers container
				imported_g = imported_g.node().classList.contains("layers") ?
					imported_g :
					imported_g.select(".layers");
				imported_g = imported_g
					.append("g")
					.classed("svg_layer active_layer base_layer", true);
				imported_g.attr("data-name", "Base layer");
				imported_g.attr("data-locked", true);
				d3.select(svg).selectAll('path,text,circle,polygon,polyline,line,rect,image').classed('grouped', true)
			} else if (d3.select(svg).selectAll('.svg_layer .grouped').nodes().length > 0) {
				// this line is fired for imported PDFs that has grouped g element and need to add
				// class grouped to specific elements
				d3.select(svg).selectAll('.svg_layer .grouped').selectAll('path,text,circle,polygon,polyline,line,rect,image').classed('grouped', true)
			}

			// // Add defs to layers tag
			let defs = d3.select("#proposal_workspace > defs");
			if (defs.node() == undefined)
				defs = d3.select("#proposal_workspace").append('defs')

			d3.select(svg).selectAll('defs').nodes().forEach((el, idx) => {
				if (el.innerHTML != "")
					defs.appendHTML(el.innerHTML);
			})


			if (xml_file.scale != undefined)
				imported_g = imported_g.append('g').classed('grouped', true).html(svg.innerHTML);
			else
				imported_g = imported_g.html(svg.innerHTML);

			if (xml_file.scale != undefined)
				imported_g.node().setAttribute("transform", xml_file.scale);

			// handle imported layers
			// d3.selectAll('.svg_layer.imported_layer > g').classed('imported_layer_el', true);
			// d3.selectAll('.svg_layer.imported_layer').classed('imported_layer', false);
			// d3.selectAll('.imported_layer_el').attr('transform', `scale(${this.page_scale})`);
			//

			let imported_layers_elements = d3.select('.imported_layers_content').nodes();
			let layers_el = d3.selectAll('.layers').node();
			if (imported_layers_elements.length > 0) {
				imported_layers_elements.forEach((import_elem, idx) => {
					let spec = import_elem.getAttribute('data-spec');
					let scale = import_elem.getAttribute('data-original-scale');
					Array.from(d3.select(import_elem).node().children).forEach((svg_layer_elem, child_id) => {
						let g;
						Array.from(d3.select(svg_layer_elem).node().children).forEach((svg_layer_child, idx) => {
							if (idx == 0) {
								g = d3.select(svg_layer_elem).append('g');
								g.attr('data-spec', spec);
								g.attr('data-original-scale', scale);
							}
							g.node().appendChild(svg_layer_child);
						})
						layers_el.appendChild(svg_layer_elem);

						// d3.select(child)
						// 	.classed("custom_size_specs", true)
						// 	.attr('data-spec', child.getAttribute('data-spec'))
						// 	.attr('data-original-scale', child.getAttribute('data-original-scale'))
						// layers_el.append("g").appendChild(child);
					})
				});
				d3.selectAll('.imported_layers_content').remove();
			}
			let scale = this.page_scale;
			let page_width = parseInt(this.page_settings.width);
			d3.selectAll('[data-original-scale]').attr('transform', function () {

				let area_width = parseInt(this.getAttribute('data-spec').split('x')[0]);

				if (parseFloat(this.getAttribute('data-original-scale')) == parseFloat(scale))
					return `scale(1)`;
				else {
					let diff = `${page_width}`.length - `${area_width}`.length;
					let final_scale = 1 / Math.pow(10, diff) || 1;
					return `scale(${final_scale})`;
				}
			})

			// d3.selectAll('.svg_layer[data-spec]').nodes().forEach((el, idx) => {
			// 	let group;
			// 	Array.from(el.children).forEach((child, child_id) => {
			// 		if (child_id == 0)
			// 			group = d3.select(el).append('g')
			// 			.attr('data-spec', el.getAttribute('data-spec'))
			// 			.attr('data-original-scale', el.getAttribute('data-original-scale'))
			// 		group.node().appendChild(child);
			// 	})
			// })
			// d3.selectAll('.svg_layer[data-spec]')
			// 	.attr('data-spec', null)
			// 	.attr('data-original-scale', null);

			// d3.select(node).append('g').node().appendChild(node.children[0])
			// <g class=​"grouped" transform=​"translate(-23.3593 -13.7408)​ scale(1 1)​" data-selected=​"true">​…​</g>​
			// enlarge page size if objects 
			let _this = this;
			setTimeout(function () {
				_this.refresh_page_size();
			}, 500);

		},
		refresh_page_size() {

			let actual_width = (d3.select('#proposal_workspace .svg_inner_container').node().getBBox().x + d3.select('#proposal_workspace .svg_inner_container').node().getBBox().width) * this.page_scale;
			let actual_height = (d3.select('#proposal_workspace .svg_inner_container').node().getBBox().y + d3.select('#proposal_workspace .svg_inner_container').node().getBBox().height) * this.page_scale;

			if (actual_width > this.page_settings.screen_width || actual_height > this.page_settings.screen_height) {
				let updated_width = this.page_settings.width;
				let updated_height = this.page_settings.height;
				if (actual_width > this.page_settings.screen_width)
					updated_width = Math.round(actual_width / this.page_scale / mm_to_px);
				if (actual_height > this.page_settings.screen_height)
					updated_height = Math.round(actual_height / this.page_scale / mm_to_px);
				let specs = '';
				if (updated_height > updated_width)
					specs = `${updated_height}x${updated_height}`;
				else
					specs = `${updated_width}x${updated_width}`;
				this.set_page_size(specs);
			}
		},
		draw_boundaries_annotation(obj, m = 3) {
			let polylines_arr = [];
			// line 1
			polylines_arr.push(
				`${obj.x},${obj.y - m}  ${obj.x},${obj.y - obj.length}`
			);
			polylines_arr.push(
				`${obj.x},${obj.y + obj.h + m}  ${obj.x},${obj.y + obj.h + obj.length}`
			);
			polylines_arr.push(
				`${obj.x - m},${obj.y} ${obj.x - obj.length},${obj.y}`
			);
			polylines_arr.push(
				`${obj.x + obj.w + m},${obj.y}  ${obj.x + obj.w + obj.length},${obj.y}`
			);

			// line 2
			polylines_arr.push(
				`${obj.x},${obj.y - obj.length + m} ${obj.x + obj.w / 2 - 10},${obj.y -
				obj.length +
				m}`
			);
			polylines_arr.push(
				`${obj.x},${obj.y + obj.h + obj.length - m} ${obj.x +
				obj.w / 2 -
				10},${obj.y + obj.h + obj.length - m}`
			);
			polylines_arr.push(
				`${obj.x - obj.length + m},${obj.y} ${obj.x - obj.length + m},${obj.y +
				obj.h / 2 -
				10}`
			);
			polylines_arr.push(
				`${obj.x + obj.w + obj.length - m},${obj.y} ${obj.x +
				obj.w +
				obj.length -
				m},${obj.y + obj.h / 2 - 10}`
			);

			// line 3
			polylines_arr.push(
				`${obj.x + obj.w},${obj.y - m} ${obj.x + obj.w}, ${obj.y - obj.length}`
			);
			polylines_arr.push(
				`${obj.x + obj.w},${obj.y + obj.h + m} ${obj.x + obj.w}, ${obj.y +
				obj.h +
				obj.length}`
			);
			polylines_arr.push(
				`${obj.x - m},${obj.y + obj.h} ${obj.x - obj.length}, ${obj.y + obj.h}`
			);
			polylines_arr.push(
				`${obj.x + obj.w + m},${obj.y + obj.h}  ${obj.x +
				obj.w +
				obj.length},${obj.y + obj.h}`
			);

			// line 4
			polylines_arr.push(
				`${obj.x + obj.w}, ${obj.y - obj.length + m} ${obj.x +
				obj.w -
				obj.w / 2 +
				10},${obj.y - obj.length + m}`
			);
			polylines_arr.push(
				`${obj.x + obj.w}, ${obj.y + obj.h + obj.length - m} ${obj.x +
				obj.w -
				obj.w / 2 +
				10},${obj.y + obj.h + obj.length - m}`
			);
			polylines_arr.push(
				`${obj.x - obj.length + m},${obj.y + obj.h} ${obj.x -
				obj.length +
				m},${obj.y + obj.h - obj.h / 2 + 10}`
			);
			polylines_arr.push(
				`${obj.x + obj.w + obj.length - m},${obj.y + obj.h} ${obj.x +
				obj.w +
				obj.length -
				m},${obj.y + obj.h - obj.h / 2 + 10}`
			);

			let labels_arr = [];
			// top
			labels_arr.push({
				x: obj.x + obj.w / 2 - 10,
				y: obj.y - obj.length + m,
				transform: null,
				value: obj.w
			});

			// bottom
			labels_arr.push({
				x: obj.x + obj.w / 2 - 10,
				y: obj.y + obj.h + obj.length - m,
				transform: null,
				value: obj.w
			});

			// left
			labels_arr.push({
				x: obj.x - obj.length + m,
				y: obj.y + obj.h / 2,
				transform: `rotate(270 ${obj.x - obj.length + m} ${obj.y + obj.h / 2})`,
				value: obj.h
			});

			// right
			labels_arr.push({
				x: obj.x + obj.w + obj.length - m,
				y: obj.y + obj.h / 2 - 10,
				transform: `rotate(90 ${obj.x +
					obj.w +
					obj.length -
					m +
					" " +
					(obj.y + obj.h / 2 - 10)} )`,
				value: obj.h
			});
			return {
				labels_arr: labels_arr,
				polylines_arr: polylines_arr
			};
		},
		dynamic_update(el, checked_layers = []) {

			let id = el.getAttribute("id");
			let attached_layers = Object.assign([], checked_layers);
			if (id == undefined && checked_layers.length == 0) return [];
			if (attached_layers.length == 0)
				d3.selectAll(".svg_layer[ref-ids]")
					.nodes()
					.forEach((el, idx) => {
						let data = JSON.parse(el.getAttribute("ref-ids"));
						if (data.includes(id)) attached_layers.push(el);
					});
			if (attached_layers.length == 0) return [];
			let arr = [];
			attached_layers.forEach((child, idx) => {
				arr.push(Object.assign(this.update_attached_layer(el, child), {
					layer: child
				}));

			});
			this.load_layers_tree();
			return arr;
		},
		// dynamic update for multiple elements
		dynamic_update_multiple(layer, elements) {
			// nest all received 'elements' against current layer
			// get layer specs
			let layer_specs = this.get_el_details(layer.selected_object);
			let rect_arr = [];
			let inner_shapes = [];
			let nesting_proposal_items = [];
			// generate rects
			elements.forEach((el, idx) => {
				let el_specs = this.get_relative_boundaries(el);
				let ref_id = this.get_element_id(el);
				let rects = this.generate_rectangles_by_specs(
					idx,
					el_specs,
					layer_specs.nesting_details.stock_specs,
					layer_specs.nesting_details.nesting_options.nesting_alignment || "center",
					layer_specs.nesting_details.nesting_options.margin_v,
					layer_specs.nesting_details.nesting_options.margin_h
				);
				nesting_proposal_items.push({
					id: idx.toString(),
					ref_id: ref_id,
					custom_x: el_specs.x1 / (mm_to_px * this.page_scale),
					custom_y: el_specs.y1 / (mm_to_px * this.page_scale),
					custom_width: el_specs.width / (mm_to_px * this.page_scale),
					custom_height: el_specs.height / (mm_to_px * this.page_scale),
					rects: rects
				});
				rect_arr = rect_arr.concat(rects);
				let custom_x = el_specs.x1 / (mm_to_px * this.page_scale);
				let custom_y = el_specs.y1 / (mm_to_px * this.page_scale);
				let custom_width = el_specs.width / (mm_to_px * this.page_scale);
				let custom_height = el_specs.height / (mm_to_px * this.page_scale);
				inner_shapes.push({
					x: custom_x * (mm_to_px * this.page_scale),
					y: custom_y * (mm_to_px * this.page_scale),
					w: custom_width * (mm_to_px * this.page_scale),
					h: custom_height * (mm_to_px * this.page_scale),
					shapes: rects,
				});
			});

			// run sheet calculation against rects
			var results = new SheetsCalculation({
				w: layer_specs.nesting_details.stock_specs.width,
				h: layer_specs.nesting_details.stock_specs.height,
				blocks: rect_arr,
				num: 1,
			}).run();

			let working_file_details = {
				horizontal_margin: layer_specs.nesting_details.nesting_options.margin_h,
				vertical_margin: layer_specs.nesting_details.nesting_options.margin_v,
				qty: results.sheets.length,
				inner_shapes: inner_shapes,
				data: JSON.stringify(results.sheets),
				nesting_proposal_items: nesting_proposal_items,
			};
			layer_specs.working_file_details = working_file_details;
			this.update_layer_data(layer_specs);

			let qty_txt = `Qty: ${layer_specs.nesting_details.stock_specs.shape_qty || 1} , `;

			let specs_details = [];
			layer_specs.nesting_details.working_areas_details = nesting_proposal_items.map((nest, idx) => {
				specs_details.push(`${(nest.custom_width / 1000).toFixed(2)}x${(nest.custom_height / 1000).toFixed(2)}`);
				return {
					id: nest.id,
					ref_id: nest.ref_id,
					cust_x: nest.custom_x,
					cust_y: nest.custom_y,
					cust_w: nest.custom_width,
					cust_h: nest.custom_height
				};
			});
			layer_specs.layer_props.selected_object.setAttribute('nesting-details', JSON.stringify(layer_specs.nesting_details));
			layer_specs.specs_details = `square: ${specs_details.join(" , ")} m`;
			layer_specs.specs_details = qty_txt + layer_specs.specs_details;
			layer_specs.quantity = results.sheets.length;
			return layer_specs;
		},
		get_el_details(el) {

			let nesting_details = JSON.parse(el.getAttribute("nesting-details"));

			if (el.getAttribute('shape-quantity') != undefined && el.getAttribute('shape-quantity') != nesting_details.stock_specs.shape_qty) {
				nesting_details.stock_specs.shape_qty = el.getAttribute('shape-quantity');
				el.setAttribute('nesting-details', JSON.stringify(nesting_details));
			}
			let type = el.getAttribute("layer-type");
			let id = type == "stock" ? el.getAttribute("proposal-stock-id") : el.getAttribute("job-id");

			let layer_props = this.layers_tree.filter(
				c => c.selected_object == el
			)[0];

			let el_item = {};
			if (type == "stock") {
				el_item = this.stock_items_list.filter(
					c => c.id == parseInt(id)
				)[0];
			} else {
				el_item = this.job_proposals_list.filter(
					c => c.id == parseInt(id)
				)[0];
			}

			return {
				type: type,
				id: id,
				// el: el,
				el_item: el_item,
				nesting_details: nesting_details,
				layer_props: layer_props
			};
		},
		update_layer_data(obj) {
			let _this = this;
			//layer
			let selected_layer = d3.select(obj.layer_props.selected_object);
			let shape_qty = parseInt(selected_layer.node().getAttribute('shape-quantity')) || 1;

			// clear layer content
			selected_layer.html("");

			// update layer UI
			switch (obj.nesting_details.nesting_options.type) {
				case "linear":
					let g = selected_layer.append("g");


					obj.linear_items_arr.forEach((el) => {
						let color = _this.getRandomColor();
						g.append("polyline")
							.attr("points", el.points)
							.attr("stroke", color)
							.attr("stroke-width", "10%")
							.attr("fill", "red")
							.attr("transform", el.transform || null);
					});

					let working_area_specs = g.node().getBBox();

					let text = g.append("text").text(`Quantity: ${shape_qty}`);
					let size = (text.node().getBBox().height * working_area_specs.width) / text.node().getBBox().width;
					text.attr("font-size", size / 2)
						.attr("x", working_area_specs.x)
						.attr("y", working_area_specs.y);
					break;
				case "cubic_square":
					let shadow_link = _this.get_shadow_link();
					let relative_coords = this.get_relative_boundaries(obj.el);
					let working_area = selected_layer
						.append("g")
						.classed("working_area_details", true);
					working_area.append("rect")
						.classed("working_area_frame", true)
						.classed("grouped", true)
						.attr("x", relative_coords.x1 / _this.page_scale)
						.attr("y", relative_coords.y1 / _this.page_scale)
						.attr("width", relative_coords.width / _this.page_scale)
						.attr("height", relative_coords.height / _this.page_scale)
						.attr("fill", "#fbfbbd")
						.attr("stroke", "orange")
						.attr("stroke-width", "1px")
						.attr("filter", `url(#${shadow_link})`);
					text = working_area.append("text")
						.attr("x", relative_coords.x1 / _this.page_scale)
						.attr("y", relative_coords.y1 / _this.page_scale)
						.text(`Quantity: ${shape_qty}`);
					size =
						(text.node().getBBox().height * (relative_coords.width / _this.page_scale)) / text.node().getBBox().width;
					text.attr("font-size", size / 2);
					break;
				case "nesting":
					this.add_layer_details(selected_layer, obj.working_file_details);
					break;
			}
		},
		add_layer_details(selected_layer, working_file_details) {
			let shape_qty = parseInt(selected_layer.node().getAttribute('shape-quantity')) || 1;
			let group = selected_layer;
			let _this = this;
			group = group.append("g").classed("grouped", true);

			working_file_details.inner_shapes.forEach((inner_shape_group) => {
				let working_area = group
					.append("g")
					.classed("working_area_details", true);
				working_area
					.append("rect")
					.classed("working_area_frame", true)
					.attr("x", inner_shape_group.x)
					.attr("y", inner_shape_group.y)
					.attr("width", inner_shape_group.w)
					.attr("height", inner_shape_group.h)
					.attr("fill", "none")
					.attr("stroke", "orange")
					.attr("stroke-width", "1px");
				let text = working_area
					.append("text")
					.attr("x", inner_shape_group.x)
					.attr("y", inner_shape_group.y)
					.text(`Quantity: ${shape_qty}`);
				let size =
					(text.node().getBBox().height * working_area.node().getBBox().width) / text.node().getBBox().width;
				text.attr("font-size", size / 2);

				inner_shape_group.shapes
					.filter((c) => c.id.includes("Num#0")).forEach((el, id) => {
						let shape = el;
						group
							.append("rect")
							.classed("item_style", true)
							.attr(
								"x",
								inner_shape_group.x +
								(shape.x + working_file_details.horizontal_margin) *
								mm_to_px
							)
							.attr(
								"y",
								inner_shape_group.y +
								(shape.y + working_file_details.vertical_margin) *
								mm_to_px
							)
							.attr(
								"width",
								(shape.w - 2 * working_file_details.horizontal_margin) *
								mm_to_px
							)
							.attr(
								"height",
								(shape.h - 2 * working_file_details.vertical_margin) *
								mm_to_px
							)
							.attr("fill", "none")
							.attr("stroke", "gray")
							.attr("stroke-width", "1px")

						let font_size =
							Math.min(
								shape.w * mm_to_px - 2,
								shape.h * mm_to_px - 2
							) * 0.4;
						group
							.append("text")
							.attr("font-size", font_size)
							.attr(
								"x",
								inner_shape_group.x + (shape.x + 3) * mm_to_px
							)
							.attr(
								"y",
								inner_shape_group.y +
								shape.y * mm_to_px +
								font_size
							)
							.text(`${id + 1}`);
					});


			});
			d3.selectAll(group).selectAll("*").classed("grouped", true);
		},
		get_shadow_link() {
			let shadow_filter = d3
				.selectAll("#shadow_filter")
				.node();
			let id = "shadow_filter";
			if (shadow_filter == undefined) {
				shadow_filter = d3
					.selectAll("#proposal_workspace .layers")
					.append("defs")
					.append("filter")
					.attr("id", id)
					.attr("x", 0)
					.attr("y", 0)
					.attr("width", "200%")
					.attr("height", "200%");

				shadow_filter
					.append("feOffset")
					.attr("result", "offOut")
					.attr("in", "SourceAlpha")
					.attr("dx", "5")
					.attr("dy", "5");
				shadow_filter
					.append("feGaussianBlur")
					.attr("result", "blurOut")
					.attr("in", "offOut")
					.attr("stdDeviation", "5");
				shadow_filter
					.append("feBlend")
					.attr("in", "SourceGraphic")
					.attr("in2", "blurOut")
					.attr("mode", "normal");
			}
			return id;
		},
		update_attached_layer(el, target) {
			let specs = this.get_el_details(target);
			specs.el = el;
			try {

				if (specs.nesting_details.stock_specs.width == 0 && specs.nesting_details.stock_specs.height == 0) {
					return {
						id: specs.id,
						type: specs.type,
						status: false,
						message: "Stock/task width & height are not defined."
					};
				}
				switch (specs.nesting_details.nesting_options.type) {
					case "linear":
						let linear_data = this.calculate_linear(specs);
						specs.quantity = linear_data.quantity;
						specs.linear_items_arr = linear_data.linear_items_arr;
						specs.specs_details = linear_data.specs_details;
						break;
					case "cubic_square":
						let cubic_data = this.calculate_cubic_specs(specs);
						specs.quantity = cubic_data.quantity;
						specs.specs_details = `cubic: ${(cubic_data.total_calculated_area / 1000).toFixed(2)}m`;
						break;
					case "nesting":
						specs.working_file_details = this.calculate_working_details(specs);
						specs.quantity = specs.working_file_details.qty;
						// may need update later
						let nest = specs.working_file_details['nesting_proposal_items'];
						let specs_details = [];
						specs.nesting_details.working_areas_details = nest.map((nest, idx) => {
							specs_details.push(`${(nest.custom_width / 1000).toFixed(2)}x${(nest.custom_height / 1000).toFixed(2)}`);
							return {
								id: nest.id,
								ref_id: nest.ref_id,
								cust_x: nest.custom_x,
								cust_y: nest.custom_y,
								cust_w: nest.custom_width,
								cust_h: nest.custom_height
							};
						});
						specs.layer_props.selected_object.setAttribute('nesting-details', JSON.stringify(specs.nesting_details));
						specs.specs_details = `square: ${specs_details.join(" , ")} m`;
						break;
					default:
						specs.quantity = target.getAttribute('stock-quantity');
						specs.specs_details = "";
						break;
				}

				let qty_txt = `Qty: ${specs.nesting_details.stock_specs.shape_qty || 1} , `;
				specs.specs_details = qty_txt + specs.specs_details;
				if (specs.el_item == undefined) {
					return {
						id: specs.id,
						type: specs.type,
						qty: specs.quantity,
						specs_details: specs.specs_details,
						status: false,
						message: "Stock/Task item doesn't exist or not defined"
					};
				}
				// update layer UI
				this.update_layer_data(specs);

				if (specs.type == "job") {

					let job = this.jobs_list.filter(c => c.id == parseInt(specs.el_item.job_id))[0];
					if (job != undefined) {
						let speed_rate = specs.nesting_details.stock_specs.speed_rate;
						specs.quantity *= parseFloat(speed_rate || job.speed_rate);
					}
				} else {
					// Calculate speed rate if it was included for below proposal stock item
					if (specs.nesting_details.stock_specs.include_speed_rate == true) {
						let speed_rate = specs.nesting_details.stock_specs.speed_rate;
						specs.quantity *= parseFloat(speed_rate || 1);
					}
					specs.quantity = specs.quantity + (specs.quantity * specs.el_item.waste_percent || 0) / 100;
				}
				return {
					id: specs.id,
					type: specs.type,
					qty: specs.quantity,
					specs_details: specs.specs_details,
					status: true,
					message: ""
				};
			} catch (err) {
				return {
					id: specs.id,
					type: specs.type,
					qty: specs.quantity,
					specs_details: specs.specs_details,
					status: true,
					message: err
				};
			}
		},
		clientToGlobal() {
			return (
				d3
					.select(".svg_inner_container")
					.node()
					.getBBox().width /
				d3
					.select(".svg_inner_container")
					.node()
					.getBoundingClientRect().width
			);
		},
		globalToClient() {
			return (
				d3
					.select(".svg_inner_container")
					.node()
					.getBoundingClientRect().width /
				d3
					.select(".svg_inner_container")
					.node()
					.getBBox().width
			);
		},
		calculate_linear(specs) {
			let _this = this;
			let outer_transform = d3.select(".svg_outer_container").attr("transform");
			d3.select(".svg_outer_container")
				.node()
				.removeAttribute("transform");

			let linear_items_details = [];
			let linear_items = [];
			if (specs.el.classList.contains("linear_layup_parent") == true) {
				linear_items = d3.select(specs.el).selectAll("polyline").nodes();
			} else
				linear_items = [specs.el];
			linear_items.forEach((el, idx) => {
				linear_items_details.push(this.get_linear_details(el));
			});

			//let el = this.get_linear_details(specs.el);
			let cut_off_global_px = 0;
			let quantity_needed = 0;
			let linear_items_arr = [];
			linear_items_details.forEach((el, idx) => {

				let start = 0;
				// width in user coordinates
				let width_px =
					specs.nesting_details.stock_specs['width'] * mm_to_px * this.globalToClient() * el.clientToUser;

				// increment in user coordinates
				let increment_px =
					specs.nesting_details.nesting_options.linear_spacing * mm_to_px * this.globalToClient() * el.clientToUser;

				let counter = 0;
				while (start < el.obj.getTotalLength()) {
					// in case there was a cuttoff from last stock item
					let end;
					if (cut_off_global_px > 0) {
						end =
							start +
							increment_px +
							width_px -
							cut_off_global_px * _this.globalToClient() * el.clientToUser;
					} else {
						end = start + increment_px + width_px;
						quantity_needed++;
					}

					linear_items_arr.push({
						points: _this.get_obj_path(el, start + increment_px, end),
						transform: _this.get_obj_translate(el)
					});

					start = end;
					counter++;
					// on last iteration, add the cutt-off if exists
					if (start + width_px + increment_px > el.obj.getTotalLength()) {
						// get last point
						if (start + increment_px < el.obj.getTotalLength()) {
							quantity_needed++;
							linear_items_arr.push({
								points: _this.get_obj_path(
									el,
									start + increment_px,
									el.obj.getTotalLength()
								),
								transform: _this.get_obj_translate(el)
							});

							cut_off_global_px = 0;
							// cut_off_global_px =

							// 	(el.obj.getTotalLength() - (start + increment_px)) *
							// 	el.userToClient *
							// 	_this.clientToGlobal();
							start = el.obj.getTotalLength();
						}
					}
				}
			});
			if (outer_transform != undefined)
				d3.select(".svg_outer_container").attr("transform", outer_transform);
			return {
				linear_items_arr: linear_items_arr,
				specs_details: `linear: ${linear_items_details.map(c => (c.length_in_mm / 1000).toFixed(2)).join(" , ")} m`,
				quantity: quantity_needed * specs.nesting_details.stock_specs.shape_qty
			};
		},
		// get translate value of an element
		get_obj_translate(el) {
			let translate_val = Array.from(el.obj.transform.baseVal).filter(
				(c) => c.type == 2
			)[0];
			if (translate_val != undefined)
				return `translate(${translate_val.matrix.e} ${translate_val.matrix.f})`;
			return "";
		},
		get_linear_details(elem) {

			let userToClient, clientToUser;
			let _this = this;
			if (elem.getBBox().width > 0) {
				userToClient =
					elem.getBoundingClientRect().width / elem.getBBox().width;
				clientToUser =
					elem.getBBox().width / elem.getBoundingClientRect().width;
			} else {
				userToClient =
					elem.getBoundingClientRect().height / elem.getBBox().height;
				clientToUser =
					elem.getBBox().height / elem.getBoundingClientRect().height;
			}
			let length =
				elem.getTotalLength() * userToClient * _this.clientToGlobal();

			return {
				length: length,
				length_in_mm: length / (mm_to_px * _this.page_scale),
				userToClient: userToClient,
				clientToUser: clientToUser,
				obj: elem
			};
		},
		get_obj_path(el, start, end) {
			let increment = 1;
			let _this = this;

			let points = [],
				coordInitial,
				previous_point,
				x = 0,
				y = 0;
			do {
				coordInitial = el.obj.getPointAtLength(start);
				x = (coordInitial.x * el.userToClient * _this.clientToGlobal()).toFixed(
					3
				);
				y = (coordInitial.y * el.userToClient * _this.clientToGlobal()).toFixed(
					3
				);

				if (points.length == 0) {
					points.push({
						x: x,
						y: y
					});
					start += increment;
					continue;
				}
				previous_point = points[points.length - 1];
				if (previous_point.x == x && points.length > 2) {
					previous_point.y = y;
				} else if (previous_point.y == y && points.length > 2) {
					previous_point.x = x;
				} else {
					points.push({
						x: x,
						y: y
					});
				}
				start += increment;
			} while (start < end);
			let points_str = "";
			points.forEach((el, idx) => {
				points_str += ` ${el.x},${el.y}`;
			});
			return points_str;
		},
		getRandomColor() {
			var letters = "0123456789ABCDEF";
			var color = "#";
			for (var i = 0; i < 6; i++) {
				color += letters[Math.floor(Math.random() * 16)];
			}
			return color;
		},
		fetch_nesting_details(specs) {
			// get selected items viewport boundaries
			//let specs = this.get_relative_boundaries(el);
			let working_area_specs = this.get_relative_boundaries(specs.el);
			let ref_id = this.get_element_id(specs.el);

			// generate rectangles by specs
			let rects = this.generate_rectangles_by_specs(
				1,
				working_area_specs,
				specs.nesting_details.stock_specs,
				specs.nesting_details.nesting_options.nesting_alignment || "center",
				specs.nesting_details.nesting_options.margin_v,
				specs.nesting_details.nesting_options.margin_h
			);

			return {
				id: 1,
				ref_id: ref_id,
				custom_x: working_area_specs.x1 / (mm_to_px * this.page_scale),
				custom_y: working_area_specs.y1 / (mm_to_px * this.page_scale),
				custom_width: working_area_specs.width / (mm_to_px * this.page_scale),
				custom_height: working_area_specs.height / (mm_to_px * this.page_scale),
				rects: rects
			};

		},
		generate_rectangles_by_specs(id, working_area_specs, item_specs, nesting_alignment, margin_v, margin_h) {


			let rect_arr = [];
			let work_area_mm_width =
				working_area_specs.width / (mm_to_px * this.page_scale);
			let work_area_mm_height =
				working_area_specs.height / (mm_to_px * this.page_scale);

			// stock / task specs in mm
			let spec_width = item_specs.width; //- 2 * this.custom_margin_horizontal;
			let spec_height = item_specs.height; //- 2 * this.custom_margin_vertical;

			// Center (even distribution)
			if (nesting_alignment == "center") {
				let factor = work_area_mm_width / spec_width;
				factor = factor / Math.ceil(factor);
				spec_width = spec_width * factor;

				factor = work_area_mm_height / spec_height;
				factor = factor / Math.ceil(factor);
				spec_height = spec_height * factor;
			}

			let counter = 0;
			for (var x = 0; x < work_area_mm_width; x = x + spec_width) {
				for (var y = 0; y < work_area_mm_height; y = y + spec_height) {
					for (var c = 0; c < (item_specs.shape_qty || 1); c++) {
						let width = spec_width;
						let height = spec_height;

						if (width + x > work_area_mm_width) {
							width = work_area_mm_width - x;
						}

						if (height + y > work_area_mm_height) {
							height = work_area_mm_height - y;
						}
						counter++;
						let rect = {
							x: x,
							y: y,
							w: width - 2 * margin_v,
							h: height - 2 * margin_h,
							id: `File#${id}_Sec#${counter}_Num#${c}`
						};
						rect_arr.push(rect);
					}
				}
			}
			return rect_arr;
		},
		calculate_working_details(specs) {
			let el = this.fetch_nesting_details(specs);
			let inner_shapes = [];
			// rect_arr = rect_arr.concat(el.rects);
			inner_shapes.push({
				x: el.custom_x * (mm_to_px),
				y: el.custom_y * (mm_to_px),
				w: el.custom_width * (mm_to_px),
				h: el.custom_height * (mm_to_px),
				shapes: el.rects
			});
			var sheet_calc = new SheetsCalculation({
				w: specs.nesting_details.stock_specs.width, //- 2 * this.custom_margin_horizontal,
				h: specs.nesting_details.stock_specs.height, // - 2 * this.custom_margin_vertical,
				blocks: el.rects,
				num: 1
			});

			var results = sheet_calc.run();
			//this.fetch_nesting_details(el, idx + 1)

			return {
				horizontal_margin: specs.nesting_details.nesting_options.margin_h,
				vertical_margin: specs.nesting_details.nesting_options.margin_v,
				qty: results.sheets.length,
				inner_shapes: inner_shapes,
				data: "", //JSON.stringify(results.sheets), // commented as it is slowing down the performance
				nesting_proposal_items: [el]
			};
		},
		calculate_cubic_specs(specs) {
			this.cubic_summary = "";
			let stock_cubic_area =
				specs.nesting_details.stock_specs.width *
				specs.nesting_details.stock_specs.height *
				(specs.nesting_details.stock_specs.depth == 0 ?
					1 :
					specs.nesting_details.stock_specs.depth);

			let total_calculated_area = 0;
			let cubic_summary_width, cubic_summary_height;
			let depth = specs.nesting_details.nesting_options.obj_depth == 0 ? 1 : specs.nesting_details.nesting_options.obj_depth;
			let relative_boundaries = this.get_relative_boundaries(specs.el);

			cubic_summary_width = relative_boundaries.width;
			cubic_summary_height = relative_boundaries.height;
			total_calculated_area +=
				((((cubic_summary_width / mm_to_px) *
					(cubic_summary_height / mm_to_px)) /
					this.page_scale) *
					depth) /
				this.page_scale;

			let area = parseFloat(
				(total_calculated_area / stock_cubic_area).toFixed(4)
			);
			return {
				quantity: area * specs.nesting_details.stock_specs.shape_qty,
				total_calculated_area: total_calculated_area
			};
		},
	}
}