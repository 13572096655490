<template>
  <div class="custom-tree-container">
    <div class="block">
      <el-row >
        <el-col id="col-tree"   >
          <el-container style="margin-bottom: 10px; ">
            <el-header>
              <el-input size="mini"
                v-model="search_folder_input"
                class="search_fields"
                :placeholder="model == 'any' ? 'search anything' : 'search quote id'"
                @keyup.enter.native="search_folders"
                style="width: 204px !important;"
                clearable
              />
              <el-button size="mini" type="primary" @click="search_folders()">Search</el-button>
              <el-input
                  v-model="filter_folders"
                  placeholder="filter folders"
                  class="search_fields"
                  size="mini"
                  clearable
                  style="width: 204px !important;"
              />
            </el-header>
            <el-main style="padding: 0px;background:#fff;">
              <el-tree
                ref="search_tree"
                :data="search_results"
                node-key="id"
                :default-expand-all="true"
                :expand-on-click-node="true"
                :filter-node-method="filter_node_search"
                highlight-current
                :lock-scroll="false"
                empty-text
                @node-click="get_files"
                v-loading="search_loading"
              >
                <span
                  slot-scope="{ node, data }"
                  class="custom-tree-node"
                >
                  <span>
                    {{ node.label | truncate(60, '...')  }}
                         <el-badge
                             v-show="data.count > 0"
                             :value="data.count"
                             class="item"
                         />
                  </span>
                  <span v-show="node.level > 1">
                    <el-button
                      type="text"
                      size="mini"
                      @click="append(node,data)"
                    >Create</el-button>
                    <el-button
                      type="text"
                      size="mini"
                      @click="edit_folder_name(node.label, data, node.id)"
                    >Edit</el-button>

                    <el-button
                      type="text"
                      size="mini"
                      @click="delete_folder(node, data)"
                    >Delete</el-button>
                  </span>
                </span>
              </el-tree>

              <el-tree
                  ref="tree"
                  :data="data"
                  node-key="id"
                  :expand-on-click-node="true"
                  :filter-node-method="filter_node"
                  highlight-current
                  :lock-scroll="false"
                  :load="load_nodes"
                  lazy
                  empty-text
                  @node-click="get_files"
                  style="max-height: 400px;"
              >
                <span
                    slot-scope="{ node, data }"
                    class="custom-tree-node"
                >
                  <span>
                    {{ node.label }}
                    <el-badge
                        v-show="data.count > 0"
                        :value="data.count"
                        class="item"
                    />
                  </span>
                  <span v-show="node.level > 1">
                    <el-button
                        type="text"
                        size="mini"
                        @click="append(node,data)"
                    >Create</el-button>
                    <el-button
                        type="text"
                        size="mini"
                        @click="edit_folder_name(node.label, data, node.id)"
                    >Edit</el-button>

                    <el-button
                        type="text"
                        size="mini"
                        @click="delete_folder(node, data)"
                    >Delete</el-button>
                  </span>
                </span>
              </el-tree>
            </el-main>
          </el-container>
        </el-col>

        <el-col id="col-files"  >
          <el-row>
          <el-input
            v-model="search_field"
            placeholder="search files"
            class="search_fields"
            @keyup.enter.native="search_files"
            size="mini"
            clearable
            style="width:200px !important;"
          />
          <el-button size="mini" type="primary" @click="search_files()">Search</el-button>
          <el-input
            v-model="filter_file_input"
            class="search_fields"
            placeholder="filter results"
            @keyup.native="filter_files"
            size="mini"
            clearable
            style="width:200px !important;"
          />

          <el-upload
            ref="upload"
            v-loading="loading"
            multiple
            class="upload"
            action="/upload_file_to_storage"
            :data="upload_data = {current_path: current_path}"
            :headers="{ 'X-CSRF-TOKEN': csfr}"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-success="success_upload"
            :on-error="error_upload"
            :file-list="up_files"
            list-type="picture"
            style="display: inline;"
          >
            <el-button
              size="mini"
              type="primary"
            >
              Click to upload
            </el-button>
          </el-upload>
          </el-row>
          <span>
            <hr>
            <div>{{ current_path }}</div>
            <hr>
          </span>

          <transition name="el-fade-in">
            <el-button
              v-show="showMultiDeleteButton"
              type="danger"
              size="small"
              icon="el-icon-delete"
              @click="deleteRows"
            >
              Delete selected
            </el-button>
          </transition>

          <el-table
            ref="filesTable"
            :data="files"
            stripe
            :default-sort="{prop: 'date', order: 'ascending'}"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="index"
              label="#"
              sortable
              width="50"
            />
            <el-table-column
              type="selection"
              width="55"
            />
            <el-table-column
              prop="url"
              label="Thumbnail"
              width="100"
            >
              <template slot-scope="scope">
                <el-tooltip :content="scope.row.path" placement="bottom" effect="light">
                  <img
                    :src="scope.row.url"
                    width="70"
                    class="image"
                  >
                </el-tooltip>
              </template>
            </el-table-column>

            <el-table-column
              prop="name"
              label="Name"
              sortable
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="mini"
                  @click="handlePreview(scope.row.url)"
                >
                  {{ scope.row.name }}
                </el-button>
              </template>
            </el-table-column>

            <el-table-column
              prop="size"
              label="Size"
              sortable
            />

            <el-table-column
              label="Date"
              sortable
            >
              <template slot-scope="scope">
                <span>{{ scope.row.date }}</span>
              </template>
            </el-table-column>

            <el-table-column label>
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="success"
                  icon="el-icon-edit"
                  circle
                  @click="edit_file_name(scope.row.name,scope.$index)"
                />
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  @click="deleteRow(scope.$index,scope.row.path)"
                />
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
let id = 1000;
import Vue from 'vue';

export default {
  name: 'Filebrowser',
  props: ['model', 'record_id'],
  data() {
    const data = [];
    const search_results = [];
    return {
      data: JSON.parse(JSON.stringify(data)),
      search_results: JSON.parse(JSON.stringify(search_results)),
      filter_folders: '',
      search_folder_input: '',
      filter_file_input: null,
      files: [],
      up_files: [],
      showMultiDeleteButton: false,
      multipleSelection: [],
      new_node: false,
      current_path: '',
      current_id: '',
      in_context: true,
      csfr: Vue.http.headers.common['X-CSRF-Token'],
      loading: false,
      search_field: '',
      search_loading: false
    };
  },
  watch: {
    filter_folders(val) {
      this.$refs.tree.filter(val);
      this.$refs.search_tree.filter(val);
    },
    model(){
      this.search_folders();
    }
  },
  mounted(){
    this.set_default_input()
  },
  created() {
    this.search_folder_input = this.record_id;
    this.search_folders();
  },
  methods: {
    load_nodes(node, resolve) {
      this.set_current_path(node);

      this.$http
          .get('/browse_dir', {
            params: { path: this.current_path + '/' }
          })
          .then(response => {
            resolve(response.data.dirs);
          });
    },
    success_upload() {
      this.up_files = [];
      this.get_files('upload_files');
      this.$emit('file_uploaded', true);
      console.log(this.current_path);
    },
    error_upload(err, file, fileList) {
      let msg = JSON.parse(err.message);
      this.$message({
        message: msg.notice,
        type: 'error'
      });
    },
    edit_file_name(filename, index) {
      this.$prompt('Please edit file name', 'EDIT', {
        confirmButtonText: 'Save',
        cancelButtonText: 'Cancel',
        inputPattern: /^[\w,\s-]+\.[\w,\s-]/,
        inputErrorMessage: 'Invalid file name',
        inputValue: filename
      })
        .then(({ value }) => {
          this.$http
            .post('/rename_file', {
              new_filename: this.current_path + '/' + value,
              filename: this.current_path + '/' + filename
            })
            .then(
              response => {
                this.files[index].name = value;
                this.$message({
                  type: 'success',
                  message: 'Your new file name is ' + value
                });
              },
              response => {
                this.$message({
                  message: 'Sorry, couldn\'t rename file ' + filename,
                  type: 'error'
                });
              }
            );
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Edit canceled'
          });
        });
    },
    edit_folder_name(foldername, data, id) {
      this.$prompt('Please edit folder name', 'EDIT', {
        confirmButtonText: 'Save',
        cancelButtonText: 'Cancel',
        inputPattern: /[\w,\s-]/,
        inputErrorMessage: 'Invalid folder name',
        inputValue: foldername
      })
        .then(({ value }) => {
          let new_foldername = value;
          let current_path = this.current_path;
          let new_name_path = '';
          current_path = current_path.substr(0, current_path.lastIndexOf('/'));
          if (current_path.length == 0) new_name_path = '/' + new_foldername;
          else new_name_path = current_path + '/' + new_foldername;

          this.$http
            .post('/rename_folder', {
              path: this.current_path,
              rename_dir_folder: new_name_path
            })
            .then(
              response => {
                this.current_path = new_name_path;
                data.label = new_foldername;
                this.$message({
                  message: 'Folder renamed to ' + new_foldername,
                  type: 'success'
                });
              },
              response => {
                this.$message({
                  message: 'couldn\'t rename folder ' + foldername,
                  type: 'error'
                });
              }
            );
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Edit canceled'
          });
        });
    },
    handleSelectionChange(selection) {
      if (selection.length >= 1) {
        this.showMultiDeleteButton = true;
      } else {
        this.showMultiDeleteButton = false;
      }
      this.multipleSelection = selection;
    },
    deleteRow(index, path) {
      this.$delete(this.files, index);
      this.delete_file(path);
    },
    deleteRows() {
      this.multipleSelection.forEach(file => {
        this.files.splice(file['path'], 1);
        this.delete_file(file['path']);
      });
    },
    delete_file(path) {
      this.$http
        .get('/delete_file', {
          params: { path: path }
        })
        .then(response => {
          this.$message({
            message: 'File deleted!',
            type: 'success'
          });
        });
    },
    get_parent(node, arr) {
      if (node == undefined || node == null) return;

      arr.unshift(node.label);
      this.get_parent(node.parent, arr);
    },
    set_current_path(node) {
      const labels = [];
      this.get_parent(node, labels);
      this.current_path = labels.filter(item => item !== undefined).join('/');
    },
    get_files(data, node) {
      if (data != 'upload_files') this.set_current_path(node);
        this.current_id = data.id;
        this.loading = true;
        this.$http
          .get('/get_files_for_folder', {
            params: { path: this.current_path }
          })
          .then(response => {
            this.files = response.data.files;
            this.loading = false;
          });
    },
    search_files() {
      if (this.search_field.length > 1) {
        this.loading = true;
        this.$http
          .post('/search_files', {
            name: this.search_field
          })
          .then(response => {
            this.files = response.data.files;
            this.loading = false;
          }, reject => {
            this.$message({
              message: 'No file found',
              type: 'error'
            });
            this.loading = false;
          });
      }
    },
    search_folders() {
      if ((this.search_folder_input != undefined && this.search_folder_input.length > 2) ) {
        this.search_loading = true;
        this.$http
          .post('/search_folders', {
            name: this.search_folder_input,
            mode: this.model
          })
          .then(response => {
            this.search_loading = false;
            this.search_results = response.data.dirs;
            if(response.data.dirs.length == 0){
              this.$message({
                message: 'No folder with files found.',
                type: 'error'
              });
            }
          }, reject => {
            this.$message({
              message: 'No folder with files found',
              type: 'error'
            });
            this.search_loading = false;
          });
      } else {
        this.search_results = [];
        if (this.model == 'any'){
          // this.load_nodes();
        }
      }
    },
    filter_node_search(value, search_results) {
      if (!value) return true;
      if (search_results.label == null) return true;
      if (
        search_results.label.toLowerCase().indexOf(value.toLowerCase()) !== -1
      ) {
        return true;
      }
    },
    filter_node(value, data) {
      if (!value) return true;
      if (data.label == null) return true;
      if (data.label.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
        return true;
      }
    },
    handleCheckChange(data, checked, indeterminate) {
      //console.log(data, checked, indeterminate);
    },
    handleNodeClick(data) {
      //console.log("node clicked");
    },

    append(node, data) {
      this.set_current_path(node);
      const newChild = { id: id++, label: 'new_folder' + id, children: [] };
      if (!data.children) {
        this.$set(data, 'children', []);
      }
      data.children.push(newChild);

      this.$http
        .get('/new_folder', {
          params: { path: this.current_path + '/' + newChild.label }
        })
        .then(response => {
          this.$message({
            message: 'Folder created ' + this.current_path,
            type: 'success'
          });
        });
    },
    delete_folder(node, data) {
      this.set_current_path(node);
      let folder = this.current_path.split('/');

      this.$confirm(
        'This will permanently delete the folder ' +
          folder.pop() +
          ', subfolders and files inside. Continue?',
        'Warning',
        {
          confirmButtonText: 'OK, DELETE',
          cancelButtonText: 'CANCEL',
          type: 'warning'
        }
      )
        .then(() => {
          this.$http
            .get('/delete_folder', {
              params: { path: this.current_path }
            })
            .then(
              response => {
                this.up_files = [];
                this.$refs.upload.clearFiles();
                node.parent.childNodes.splice(
                  node.parent.childNodes.findIndex(d => d.id == node.id),
                  1
                );
                this.$message({
                  message: 'Folder deleted',
                  type: 'success'
                });
              },
              response => {
                this.$message({
                  message: 'Folder not deleted',
                  type: 'error'
                });
              }
            );
        })
        .catch(() => {
          this.$message({
            type: 'success',
            message: 'Delete canceled'
          });
        });
    },
    filter_files() {
      const rows = this.$refs.filesTable.$refs.bodyWrapper.getElementsByClassName(
        'el-table__row'
      );
      for (let row of rows) {
        let cells = row.getElementsByTagName('td');
        for (let cell of cells) {
          let innerText = cell.innerText.toLowerCase();
          let filter_file_input = this.filter_file_input.toLowerCase();
          if (innerText.indexOf(filter_file_input) > -1) {
            row.style.display = '';
            break;
          } else {
            row.style.display = 'none';
          }
        }
      }
    },
    handleRemove(file, fileList) {
      //console.log(file, fileList);
    },
    handlePreview(file) {
      let preview_url = String(file).replace('get_file', 'preview_file');
      let d = encodeURIComponent('&');
      let h = encodeURIComponent('#');
      preview_url  = String(preview_url).replace(/&/g, d).replace(/#/g, h);
      window.location = encodeURI(preview_url);
    },
    set_default_input() {
      Array.from(document.querySelectorAll('.el-input__inner')).forEach(
          function (e) {
            e.classList.add('browser-default');
            if (e.querySelector('input') != null)
              e.querySelector('input').className += ' browser-default';
          }
      );
    },
  }
};
</script>

<style scoped>
.custom-tree-container .el-tree {
  max-height: none;
}
.custom-tree-node {
  /*flex: 1;*/
  /*display: flex;*/
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.custom-tree-container > .el-input__inner {
  height: 20px !important;
  line-height: 20px !important;
  padding-top: 12px !important;
  font-weight: normal !important;
  color: #4c4c4cde !important;
}

.el-input__inner::-webkit-input-placeholder {
  color: orange !important;
  font-weight: normal !important;
  font-size: 12px;
  opacity: 0.8;
  font-family: "Roboto", sans-serif !important;
}
.el-table th > .cell {
  font-size: 14px;
  font-weight: normal;
  color: #606266;
}
.el-table .cell a {
  color: #409eff;
  font-size: 12px;
}

.el-table .cell {
  font-size: 12px;
}
.custom-tree-container /deep/ .el-tree__empty-block {
  min-height: 0px !important;
  margin-bottom: 10px;
}

.custom-tree-container /deep/ .el-header{
  height: auto !important;
}

.custom-tree-node .el-badge {
  padding-top: 8px;
}

.el-tree {
  width: 100%;
  overflow-x: scroll;
}

.el-tree > .el-tree-node {
  min-width: 100%;
  display: inline-block !important;
}

.search_fields {
  width: 49% !important;
}

.custom-tree-container /deep/ .el-table .cell {
  padding-right: 0px !important;
}
.custom-tree-container /deep/ td {
  display: table-cell !important;
}
#col-files{
  padding-left: 15px;
}


@media only screen and (min-width: 993px) {
 #col-tree{ width: 40%;}
  #col-files{ width: 58%;}
}
</style>