import {Request} from '../utilities/Request';

export function timeframe_esct_rates(this_obj) {
  return Request.get(
    this_obj,
    '/api/timeframe_esct_rates',
    null,
  );
}

export function create_esct_rates(this_obj, esct_rate) {
  return Request.post(
    this_obj,
    '/api/esct_rates',
    null,
    {
      esct_rate: esct_rate
    }
  );
}

export function delete_timeframe_esct_rates(this_obj) {
  return Request.delete(
    this_obj,
    '/api/delete_timeframe_esct_rates'
  );
}
