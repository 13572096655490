import {
  Promise
} from 'q';

export const JobProposalMixin = {
  created() {},
  data() {
    return {};
  },
  methods: {
    load_job_proposals(proposal_id, only_workspace_tasks = false) {
      return new Promise((resolve, reject) => {
        let params = {
          id: proposal_id
        };
        if (only_workspace_tasks == true)
          params['only_workspace_tasks'] = true;

        this.$http
          .get('/proposal_tasks_list', {
            params: params
          })
          .then(
            response => {
              resolve(response.body);
            },
            reason => {
              console.log(reason);
              reject(reason);
            }
          );
      });
    },
    add_job_proposal(proposal_id, params) {
      return new Promise((resolve, reject) => {
        this.$http
          .patch('/add_job_proposal/' + proposal_id, {
            job_proposals: params
          })
          .then(response => {
            resolve(response.body.data);
          }, response => {
            reject(response.message);
          });
      });
    },
    delete_job_proposal(id) {
      return new Promise((resolve, reject) => {
        this.$http.delete(`/delete_job_proposal/${id}`).then(
          response => {
            resolve(response.body.data);
          },
          response => {
            reject(response.message);
          }
        );
      });
    },
    // below method is used to update the entire record
    update_jp(id, params) {
      return new Promise((resolve, reject) => {
        this.$http.patch('/update_job_proposal/' + id, params).then(
          response => {
            resolve(response.data);
          },
          reason => {
            console.log(reason);
            reject(reason);
          }
        );
      });
    },
    // below method is used to update below fields only
    // :custom_start_date, :time_frame_start, :time_frame_end, :is_date_locked, :crm_user_id
    update_job_proposal_params(id, params) {
      return new Promise((resolve, reject) => {
        this.$http.patch('/update_job_proposal_attributes/' + id, params).then(
          response => {
            resolve(response);
          },
          reason => {
            console.log(reason);
            reject(reason);
          }
        );
      });
    },
    get_suggested_schedule(proposal_id) {
      return new Promise((resolve, reject) => {
        let params = {
          proposal_id: proposal_id
        };
        this.$http.post('/get_suggested_schedule/', params).then(
          response => {
            resolve(response);
          },
          reason => {
            console.log(reason);
            reject(reason);
          });
      });
    },
    complete_update_task(id, time_spent) {
      return new Promise((resolve, reject) => {
        this.$http.post(`/complete_update_task/${id}`, {
          time_spent: time_spent
        }).then(
          response => {
            resolve(response);
          },
          reason => {
            reject(reason);
          }
        );
      });
    },
    batch_jps_update(data) {
      return new Promise((resolve, reject) => {
        this.$http.post('/update_jps_list/', {
          items: data
        }).then(
          response => {
            resolve(response);
          },
          reason => {
            console.log(reason);
            reject(reason);
          });
      });
    }
  }
};