const CommonJoditMixin = {
  data() {
    return {
      jodit_config: { enter: 'br' },
      buttons: [
        'fullsize',
        'source',
        '|',
        'table',
        'image',
        'link',
        '|',
        'bold',
        'underline',
        'strikethrough',
        'italic',
        '|',
        'paragraph',
        'font',
        'fontsize',
        '|',
        'ul',
        'ol',
        '|',
        'undo',
        'redo',
        '|',
        'selectall',
        'cut',
        'copy',
        'paste',
        'copyformat',
        '|',
        'align',
        'outdent',
        'indent',
        '|',
        'hr',
        'brush',
        'eraser',
        'symbol',
        '|',
        'superscript',
        'subscript',
        '|',
        'print',
      ],
    };
  },
};

export default CommonJoditMixin;