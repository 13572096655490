import Vue from 'vue';
import Vuex from 'vuex';
import {
  Message
} from 'element-ui';

Vue.use(Vuex);

export const client_store = new Vuex.Store({
  strict: false,
  state: {
    details: {
      id: '',
      email: '',
      account_email: '',
      invoice_email: '',
      postal_code: '',
      physical_address: '',
      payment_options: null,
      terms_accepted: false
    }
  },
  getters: {
    details: state => {
      return state.details;
    }
  },
  mutations: {
    SET_DETAILS(state, payload) {
      state.details = payload;
    }
  },
  actions: {
    get_details({
      commit
    }) {
      return new Promise((resolve, reject) => {
        Vue.http.get('/client/details').then(
          response => {
            resolve(response);
            commit('SET_DETAILS', response.body.details);
          },
          function (response) {
            Message({
              message: response.data.errors,
              type: 'error'
            });
            reject(response);
          }
        );
      });
    },
    update({state}) {
      return new Promise((resolve, reject) => {

        let params = {
          client: state.details
        };
        Vue.http.patch('/update_client', params).then(
          () => {
            Message({
              message: 'Client updated.',
              type: 'success'
            });
            resolve(resolve);
          },
          function (response) {
            Message({
              message: response.data.errors,
              type: 'error'
            });
            reject(response);
          }
        );
      });
    }
  }
});