<template>
  <div id="stock-group">
    <el-button
      size="small"
      type="info"
      @click="show_group_dialog = true"
    >
      New Group
    </el-button>
    <el-dialog
      :visible.sync="show_group_dialog"
      title="Stock Item Groups"
      width="56%"
    >
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input
            v-model="stock_group.name"
            placeholder="New Group"
          />
        </el-col>
        <el-col :span="8">
          <el-button
            type="primary"
            @click="create()"
          >
            Save Group
          </el-button>
        </el-col>
      </el-row>
      <el-table
        :data="stock_groups"
        stripe
        style="width: 100%"
      >
        <el-table-column
          prop="id"
          label="Id"
          width="180"
        />
        <el-table-column
          prop="name"
          label="Name"
          width="180"
        >
          <template slot-scope="scope">
            <el-input
              v-if="editable[scope.$index]"
              v-model="stock_groups[scope.$index].name"
            />
            <span v-else>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Operations"
        >
          <template slot-scope="scope">
            <el-button
              v-if="editable[scope.$index]"
              size="mini"
              @click="update(scope.$index)"
            >
              Save
            </el-button>
            <el-button
              v-else
              size="mini"
              @click="set_editable(scope.$index)"
            >
              Edit
            </el-button>
            <el-button
              v-if="stock_item_id > 0"
              size="mini"
              @click="add_current_stock_item(scope.$index,stock_item_id)"
            >
              Add current stock item
            </el-button>
            <stock-item-to-group
              :stock_group_id="stock_groups[scope.$index].id"
              :stock_group_name="stock_groups[scope.$index].name"
            />
            <stock-item-remove-from-group
              :stock_group_id="stock_groups[scope.$index].id"
              :stock_group_name="stock_groups[scope.$index].name"
              v-on="$listeners"
            />
            <el-button
              size="mini"
              type="danger"
              @click="delete_record(scope.$index)"
            >
              Delete Group
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import StockItemToGroup from './StockItemToGroup.vue';
import StockItemRemoveFromGroup from './StockItemRemoveFromGroup.vue';
export default {
  name: 'StockGroup',
  components: {StockItemToGroup,StockItemRemoveFromGroup},
  props: {
    stock_item_id: {
      type: [String,Number],
      required: false,
      default: 0
    }
  },
  data() {
    return {
      show_group_dialog: false,
      stock_group: {
        name: ''
      },
      stock_groups: [],
      editable: []
    };
  },
  created(){
    this.index();
  },
  methods: {
    add_current_stock_item(index, stock_item_id){
      this.$http.post('/add_stock_item_to_group',
        {stock_item_id: stock_item_id , stock_group_id: this.stock_groups[index].id}
      ).then(
        () => {
          this.$message({
            type: 'success',
            message: 'Added stock item to group'
          });
          this.$emit('reload_groups', this.stock_item_id);
        });
    },
    set_editable(row) {
      if(this.editable.length === 0) {
        this.editable[row] = true;
        this.$set(this.editable, row, true);
      }
    },
    index(){
      this.$http.get('/stock_groups').then(
        (response) => {
          this.stock_groups = response.data;
        }
      );
    },
    create() {
      this.$http.post('/stock_groups', {stock_group: this.stock_group}).then(
        () => {
          this.$message({
            message: 'Success, record created.',
            type: 'success',
          });
          this.index();
        },
        (response) => {
          this.$message({
            type: 'error',
            message: response.data.error
          });
        }
      );
    },
    update(index) {
      this.$http.patch(`/stock_groups/${this.stock_groups[index].id}`, {stock_group: this.stock_groups[index]}).then(
        () => {
          this.$message({
            message: 'Success, record updated.',
            type: 'success',
          });
          this.editable = [];
        },
        (response) => {
          this.$message({
            type: 'error',
            message: response.data.error
          });
          this.$emit('reload_groups', this.stock_item_id);
        }
      );
    },
    delete_record(index) {
      this.$http.delete(`/stock_groups/${this.stock_groups[index].id}`).then(
        () => {
          this.$message({
            message: 'Record deleted',
            type: 'success'
          });
          this.stock_groups.splice(index, 1);
          this.$emit('reload_groups', this.stock_item_id);
        },
        () => {
          this.$message({
            message: 'Record not deleted',
            type: 'error'
          });
        },
      );
    },
  },
};
</script>
<style scoped>
#stock-group{ display: inline;}
</style>