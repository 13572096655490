import {Request} from '../utilities/Request';

export function pre_description(this_obj, proposal_id) {
  return Request.get(
    this_obj,
    `/api/proposals/${proposal_id}/proposal_description_note`,
    null
  );
}

export function update_proposal(this_obj, id, pre_description) {
  return Request.patch(
    this_obj,
    `/api/proposals/${id}/update_pre_description`,
    null,
    { pre_description: pre_description }
  );
}

export function create_new_quote(this_obj, proposal) {
  return Request.post(
    this_obj,
    '/api/proposals/create_new_quote',
    null,
    { proposal: proposal }
  );
}

export function create_template(this_obj, proposal) {
  return Request.post(
    this_obj,
    '/api/proposals/create_new_template',
    null,
    { proposal: proposal }
  );
}
