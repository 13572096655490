<script>
export default {
  name: 'WriteAttribute',
  methods: {
    write_attributa(id,column,table){
      this.$http.post('/write_attribute_to_db', {
        id: id,
        column: column,
        table: table,
        text: event.target.value
      });
    }

  }
};
</script>
