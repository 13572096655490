<template>
  <div v-loading="loading">
    <el-dialog
      :visible.sync="hold_status_edit_dialog"
      title="Job Status Info"
      width="50%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        label-position="left"
        :rules="rules"
        v-model="selected_hold_status"
        label-width="120px"
      >
        <el-form-item label="Name">
          <el-input v-model="selected_hold_status.name" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="Out of Schedule">
          <el-checkbox
            v-model="selected_hold_status.out_of_schedule"
            size="mini"
          ></el-checkbox>
        </el-form-item>
        <el-form-item
          label="Duration"
          v-show="selected_hold_status.out_of_schedule == false"
        >
          <el-input
            v-model="selected_hold_status.duration"
            size="mini"
            style="width: 100px"
          >
          </el-input>
          mins
        </el-form-item>
        <el-form-item
          label="Counter"
          v-show="selected_hold_status.out_of_schedule == false"
        >
          <el-input
            v-model.number="selected_hold_status.counter"
            size="mini"
            style="width: 100px"
          ></el-input>
        </el-form-item>
        <el-form-item label="Description">
          <el-input
            type="textarea"
            :rows="2"
            v-model="selected_hold_status.description"
            size="mini"
          ></el-input>
        </el-form-item>
        <h3 v-show="selected_hold_status.out_of_schedule == false">
          <b>Follow-up task per category</b>
          <hr />
        </h3>
        <el-table
          :data="selected_hold_status.hold_status_task_categories_attributes"
          v-show="selected_hold_status.out_of_schedule == false"
        >
          <el-table-column label="Category" prop="category_task_name">
          </el-table-column>
          <el-table-column label="Task" prop="job_id">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.job_id"
                size="mini"
                style="width: 200px"
              >
                <el-option
                  v-for="job in jobs_list.filter(
                    (c) => c.category_task_id == scope.row.category_task_id
                  )"
                  :key="job.id"
                  :label="job.name"
                  :value="job.id"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="hold_status_edit_dialog = false" size="small"
          >Cancel</el-button
        >
        <el-button type="primary" @click="save_hold_status" size="small"
          >Confirm</el-button
        >
      </span>
    </el-dialog>

    <strong>Job Statuses list</strong>
    <el-row>
      <el-button
        class="right"
        button
        type="primary"
        plain
        size="small"
        v-on:click="add_new_hold_status"
        style="margin-top: -10px;"
        >New Job status</el-button
      >
    </el-row>
    <hr />
    <el-table :data="hold_statuses_list">
      <el-table-column prop="name" label="Name"></el-table-column>
      <el-table-column prop="out_of_schedule" label="Out of Schedule">
        <template slot-scope="scope">
          <i
            v-show="scope.row.out_of_schedule == true"
            class="el-icon-check"
          ></i>
        </template>
      </el-table-column>
      <el-table-column prop="duration" label="Duration">
        <template slot-scope="scope">
          <span v-show="scope.row.out_of_schedule == false">
            {{ scope.row.duration }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="description" label="Description">
      </el-table-column>
      <el-table-column label="Action">
        <template slot-scope="scope">
          <el-button
            icon="el-icon-edit"
            size="mini"
            circle
            @click="edit_hold_status(scope.row)"
          ></el-button>
          <el-button
            type="danger"
            icon="el-icon-delete"
            size="mini"
            circle
            @click="delete_hold_status(scope)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  name: "Hold Statuses",
  data() {
    return {
      hold_statuses_list: [],
      jobs_list: [],
      selected_hold_status: {},
      loading: false,
      hold_status_edit_dialog: false,
      rules: {
        name: [{ required: true, message: "name is required" }],
      },
    };
  },
  mounted() {
    this.load_hold_statuses();
    this.load_jobs_list();
  },
  methods: {
    load_jobs_list() {
      this.$http.get("/jobs").then((response) => {
        this.jobs_list = response.data;
      });
    },
    load_hold_statuses() {
      this.loading = true;
      this.$http.get("/hold_statuses").then((response) => {
        this.loading = false;
        this.hold_statuses_list = response.data;
      });
    },
    add_new_hold_status() {
      this.clear_hold_status();
      this.loading = true;
      this.$http.get(`/hold_statuses/new`).then((response) => {
        this.loading = false;
        this.selected_hold_status = response.data;
      });
      this.hold_status_edit_dialog = true;
    },

    edit_hold_status(row) {
      this.clear_hold_status();
      this.loading = true;
      this.$http.get(`/hold_statuses/${row.id}`).then((response) => {
        this.loading = false;
        this.selected_hold_status = response.data;
      });
      this.hold_status_edit_dialog = true;
    },
    delete_hold_status(scope) {
      let _this = this;
      this.$confirm(
        "This will permanently delete the record. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then((response) => {
        _this.loading = true;
        this.$http.delete("/hold_statuses/" + scope.row.id).then(
          (response) => {
            this.$message({
              type: "success",
              message: "Delete completed",
            });
            _this.loading = false;
            _this.hold_status_edit_dialog = false;
            _this.$delete(this.hold_statuses_list, scope.$index);
          },
          (response) => {
            _this.loading = false;
            this.$message({
              type: "error",
              message:
                "Error happened while deleting hold_status: " +
                response.data.message,
            });
          }
        );
      });
    },
    save_hold_status() {
      if ((this.selected_hold_status.name || "").trim() == "") {
        this.$message({ type: "warning", message: "Name is required" });
        return false;
      }

      if (
        this.selected_hold_status.out_of_schedule == false &&
        this.selected_hold_status.hold_status_task_categories_attributes.filter(
          (c) => c.job_id == null
        ).length > 0
      ) {
        this.$message({
          type: "warning",
          message: "Select follow-up task for all categories",
        });
        return false;
      }

      if (this.selected_hold_status.out_of_schedule == true)
        this.selected_hold_status.hold_status_task_categories_attributes = [];
      let url = "";
      let type = "";
      if (
        this.selected_hold_status.id == "" ||
        this.selected_hold_status.id == null
      ) {
        url = "/hold_statuses";
        type = "post";
      } else {
        url = `/hold_statuses/${this.selected_hold_status.id}`;
        type = "patch";
      }
      this.loading = true;
      this.$http[type](url, { hold_status: this.selected_hold_status }).then(
        (response) => {
          this.loading = false;
          this.$message({ type: "success", message: "Saved successfully" });
          this.load_hold_statuses();
          this.hold_status_edit_dialog = false;
        },
        (response) => {
          this.$message({
            type: "error",
            message:
              "Error happened while saving hold_status information: " +
              response.data.message,
          });
          this.loading = false;
        }
      );
    },
    clear_hold_status() {
      this.selected_hold_status = {
        id: "",
        name: "",
        out_of_schedule: false,
        duration: "",
        description: "",
        hold_status_task_categories_attributes: [],
      };
    },
    set_default_input(mills = 300) {
      let _this = this;
      setTimeout(function() {
        Array.from(document.querySelectorAll(".el-input__inner")).forEach(
          function(e, i) {
            e.classList.add("browser-default");
            if (e.querySelector("input") != null)
              e.querySelector("input").className += " browser-default";
          }
        );
      }, mills);
    },
  },
  watch: {
    hold_status_edit_dialog: function(newVal) {
      let _this = this;
      if (newVal == false) this.selected_hold_status = {};
      else
        setTimeout(function() {
          _this.set_default_input();
        }, 1000);
    },
  },
};
</script>
