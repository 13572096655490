<template>
  <div>
    <div class="card  ">
      <div class="card-content ">
        <span class="card-title">
          Unapproved Sales
        </span>
        <br>
        <br>
        <br>
        <table>
          <tr class="header">
            <th>COMPLETED</th>
            <th>PENDING</th>
            <th>TOTAL</th>
          </tr>
          <tr class="rows">
            <td><span class="center">{{ sales_completed_jobs | currency }}</span></td>
            <td><span class="center">{{ sales_pending_jobs | currency }}</span></td>
            <td><span class="center">{{ (sales_completed_jobs + sales_pending_jobs) | currency }}</span></td>
          </tr>
        </table>
        <pie-chart
          :chart-data="datacollection"
          :options="chartOptions"
        />
      </div>
      <div class="card-action">
        <button
          class="btn btn-small"
          @click="show_sales(true)"
        >
          Completed Jobs
        </button>
        <button
          class="btn btn-small right"
          @click="show_sales(false)"
        >
          Pending Jobs
        </button>
      </div>
    </div>
    <modal
      name="sales_records_list"
      :height="700"
      :width="1200"
      :adaptive="true"
    >
      <div class="card ">
        <div class="card-content  ">
          <span class="card-title">{{ list_title }}<hr></span>
          <div
            v-show="is_loading"
            class="progress"
          >
            <div class="indeterminate" />
          </div>
          <el-table
            :data="rows"
            style="width: 100%"
            height="600"
          >
            <el-table-column
              v-for="column in columns"
              :key="column"
              :prop="column"
              :label="column | capitalize"
              sortable
            />
          </el-table>
        </div>
        <div class="card-action" />
      </div>
    </modal>
  </div>
</template>

<script>
import PieChart from './PieChart';

export default {
  name: 'SalesByJobCompletion',
  components: {PieChart},
  data() {
    return {
      datacollection: '',
      sales_completed_jobs: 0,
      sales_pending_jobs: 0,
      columns: [],
      rows: [],
      list_title: '',
      is_loading: false
    };
  },
  computed: {
    current_date() {
      return new Date();
    }
  },
  created() {
  },
  mounted() {
    this.fill_data();
  },
  methods: {
    fill_data() {
      let self = this;
      this.$http.post('/get_sales_by_job_completion', {

      }).then(response => {
        if (response.data.status == 200) {
          self.sales_completed_jobs = response.data.data.sales_completed_jobs;
          self.sales_pending_jobs = response.data.data.sales_pending_jobs;

          let categorized_pending = response.data.data.categorized_pending;
          let categorized_completed = response.data.data.categorized_completed;
          let bg_colors = Array(categorized_pending.total.length).fill('lightgreen').concat(Array(categorized_completed.total.length).fill('#efefb2'));
          let catgories_data = categorized_pending.total.concat(categorized_completed.total);
          let catgories_labels =  categorized_pending.categories_names.concat(categorized_completed.categories_names);

          this.datacollection = {
            labels: catgories_labels,
            datasets:
                                    [
                                      {
                                        label: 'Sales ',
                                        backgroundColor: bg_colors,
                                        data: catgories_data
                                      }
                                    ]
          };
        }
      }
      );
    },
    show_sales(approved) {
      this.open_modal();
      this.columns = [];
      this.rows = [];
      let self = this;
      this.list_title = (approved == true ? 'Completed Jobs ' : 'Pending Jobs') + ' Sales';
      this.is_loading = true;
      this.$http.post('/get_sales_records_by_completion', {
        approved: approved == true ? 1 : 0
      }).then(response => {
        self.is_loading = false;
        if (response.data.status == 200) {
          self.columns = response.data.data.columns;
          self.rows = response.data.data.rows;
        }
      });
    },
    open_modal() {
      this.$modal.show('sales_records_list');
    },
    hide_modal() {
      this.$modal.hide('sales_records_list');
    },
    chartOptions: function () {
      return {responsive: true, maintainAspectRatio: false};
    }
  }
};
</script>

<style scoped>
    td, th {
        text-align: center;
        padding: 10px;
    }

    th {
        font-weight: 800;
    }

    .header {
        background-color: #ddecf9;
    }

    .subheader {
        background-color: #e5f3ff;
    }

    .rows {
        background-color: aliceblue;
    }
    .header {
        font-size: 13px;
    }
</style>