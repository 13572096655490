<template>
  <el-dialog
    :visible.sync="show"
    :width="width"
    :close-on-click-modal="false"
    @closed="onCloseDialog"
  >
    <h2 class="text__center">
      Update a Credit Note
    </h2>

    <el-form
      id="credit_note_form"
      ref="form"
      :model="form"
      label-width="120px"
    >
      <el-form-item label="Number">
        4000{{ credit_note_id }}
      </el-form-item>
      <el-form-item label="Invoice Number">
        4000{{ parent_invoice_number }}
      </el-form-item>
      <el-form-item label="Job Name">
        {{ job_name }}
      </el-form-item>
      <el-form-item label="Amount">
        <el-input
          v-model="form.amount"
          class="form__input"
        />
      </el-form-item>
      <el-form-item label="Total Price">
        <Money :amount="-total_price" />
        <span>(GST included)</span>
      </el-form-item>
      <el-form-item label="Status">
        <el-select
          v-model="form.status"
          class="form__input"
          placeholder="Select"
        >
          <el-option
            v-for="item in status_options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Date">
        <el-date-picker
          v-model="form.invoice_date"
          class="form__input"
          type="date"
          placeholder="Pick a day"
          format="dd/MM/yyyy"
        />
      </el-form-item>
      <el-form-item label="Description">
        <div class="description_editor">
          <jodit-editor
            v-model="form.description"
            :buttons="buttons"
            :config="jodit_config"
          />
        </div>
      </el-form-item>
    </el-form>
    <div
      v-if="error_message"
      class="error"
    >
      {{ error_message }}
    </div>
    <span
      slot="footer"
    >
      <el-button
        type="info"
        @click="close_dialog"
      >
        Close
      </el-button>
      <el-button
        type="primary"
        @click="onUpdate"
      >
        Update
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import CommonJoditMixin from '../../../../../mixins/CommonJoditMixin';
import {EventBus} from '../../../../../utilities/EventBus';
import Money from '../../../../shared/Money';
import {editCreditNotes, updateCreditNotes} from '../../../../../api/credit_notes';
import {INVOICE_STATUS_OPTIONS} from '../../../status';
import {dateString, getToDay} from '../../../../../utilities/Date';

export default {
  name: 'CreditNotesEditDialog',
  components: {Money},
  mixins: [CommonJoditMixin],
  props: {
    show_cn_dialog: {
      type: Boolean,
      required: true
    },
    credit_note_id: {
      type: Number,
      required: true
    }
  },
  data(){
    return {
      show: this.show_cn_dialog,
      width: '70%',
      media_query_sm: window.matchMedia('(min-width: 1000px)'),

      form: {
        description: '',
        amount: '',
        invoice_date: getToDay(),
        status: ''
      },
      status_options: INVOICE_STATUS_OPTIONS,
      error_message: '',
      job_name: '',
      parent_invoice_number: ''
    };
  },
  computed: {
    total_price() {
      let amount = this.form.amount ? parseFloat(this.form.amount) : 0;
      return (amount + amount * 0.15).toFixed(3);
    }
  },
  created() {
    this.change_width();
    this.media_query_sm.addEventListener('change', () => {
      this.change_width();
    });
    editCreditNotes(this,this.credit_note_id).then(({data})=>{
      this.form.amount = data.amount;
      this.form.description = data.description;
      this.form.status = data.status;
      this.job_name = data.job_name;
      this.form.invoice_date = new Date(data.invoice_date);
      this.parent_invoice_number = data.parent_invoice_number;
    });
  },
  mounted() {
    this.$nextTick(() => {
      Array.from(document.querySelectorAll('#credit_note_form .el-input__inner')).forEach(
        function(e) {
          e.classList.add('browser-default');
          if (e.querySelector('input') != null)
            e.querySelector('input').className += ' browser-default';
        }
      );
    });
  },
  methods: {
    onCloseDialog() {
      this.$emit('update:show_cn_dialog', false);
    },
    onUpdate() {
      this.error_message = '';
      updateCreditNotes(
        this,
        this.credit_note_id,
        this.form.amount,
        dateString(this.form.invoice_date),
        this.form.status,
        this.form.description
      ).then(({data})=>{
        this.$message({
          type: 'success',
          message: data.message
        });
        EventBus.$emit('refreshAllInvoice');
        this.close_dialog();
      }).catch(({data})=>{ this.error_message = data.details; });
    },
    close_dialog() {
      this.show = false;
    },
    change_width() {
      if(this.media_query_sm.matches) {
        this.width = '70%';
      } else {
        this.width = '90%';
      }
    },
  }
};
</script>

<style lang='scss' scoped>
.el-form-item {
  margin-bottom: 22px !important;
}
.form__input {
  width: 100%
}
.error {
  color: red;
}
</style>
