<template>
  <el-select
    :value="value"
    filterable
    remote
    class="form__input"
    placeholder="Type a Job Name or Quote Number - at least 4 characters"
    :remote-method="onSearchJobs"
    :loading="loading"
    @input="onSelect"
  >
    <el-option-group
      v-for="group in jobs_lists.groups"
      :key="group.label"
      :label="group.label"
    >
      <el-option
        v-for="(job, index) in group.options"
        :key="index"
        :label="`${job.proposal.job_name} (${job.proposal.id}) - ${job.task_name}`"
        :value="job.id"
      >
        <div class="option-container">
          <div class="left-container">
            <div class="name-space">
              {{ job.proposal.job_name }}
            </div>
            <div class="subinfo-space">
              ({{ job.proposal.id }})
            </div>
          </div>
          <div class="right-container">
            <div class="name-space">
              {{ job.task_name }}
            </div>
            <div class="subinfo-space">
              &nbsp;-&nbsp;{{ job.time_frame_start }}
            </div>
          </div>
        </div>
      </el-option>
    </el-option-group>
  </el-select>
</template>

<script>
const OPEN_JOBS = 'Open Jobs';
const COMPLETED_JOBS = 'Completed Jobs(Within 6 months)';

export default {
  name: 'JobSearchSelect',
  props: {
    value: {
      type: [Number, String],
      required: true
    },
    crm_user_id: {
      type: Number,
      required: true
    },
    load_jobs_func: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      selected: '',
      jobs_lists: {
        groups: [
          {
            label: OPEN_JOBS,
            options: []
          },
          {
            label: COMPLETED_JOBS,
            options: []
          }
        ]
      }
    };
  },
  methods: {
    onSearchJobs(query) {
      if(!query || query.length <= 3) return;

      this.loading = true;
      this.load_jobs_func(this, this.crm_user_id, query).then(({data})=>{
        this.loading = false;
        this.jobs_lists.groups[0].options = data.open_jobs;
        this.jobs_lists.groups[0].label = `${OPEN_JOBS} - ${data.open_jobs.length}`;
        this.jobs_lists.groups[1].options = data.recently_completed_jobs;
        this.jobs_lists.groups[1].label = `${COMPLETED_JOBS} - ${data.recently_completed_jobs.length}`;
      }).catch(()=>{
        this.loading = false;
      });
    },
    onSelect(e) {
      this.$emit('input', e);
    }
  }
};
</script>
<style lang='scss' scoped>
.option-container {
  display: flex;
  justify-content: space-between;
}
.left-container {
  display: flex;
}

.right-container {
  display: flex;
  justify-content: flex-end;
}

.subinfo-space {
  font-size: 12px;
}
.name-space {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 14rem;
}
</style>