<template>
  <div>
    <el-row>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <span class="smallheader">Upskilling/Goals</span>
        <p>Calculated base on deadline status having been achieved over number of goals set over the selected time/date.  </p>
        <el-col
          :xs="24"
          :sm="24"
          :md="4"
          :lg="4"
          :xl="4"
          class="smallbox"
          style="background:#f3f3f3; display:none"
        >
          <span class="semilables"> Total (Month)</span>
          <el-input
            v-model="user_details.data.target_section.upskilling_total"
            @change="update_targets"
          >
          </el-input>
        </el-col>
        <el-col class="smallbox" :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
          <span class="semilables">Green Star (Min)</span>
          <el-input
            v-model="user_details.data.target_section.upskilling_green"
            @change="update_targets"
          >
          </el-input>
        </el-col>

        <el-col class="smallbox" :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
          <span class="semilables">Yellow Star (Min)</span>
          <el-input
            v-model="user_details.data.target_section.upskilling_yellow"
            @change="update_targets"
          >
          </el-input>
        </el-col>

        <el-col class="smallbox" :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
          <span class="semilables">Orange Star (Min)</span>
          <el-input
            v-model="user_details.data.target_section.upskilling_orange"
            @change="update_targets"
          >
          </el-input>
        </el-col>
        <el-col class="smallbox" :xs="24" :sm="24" :md="4" :lg="4" :xl="4" style="background:#cfffe5">
          <span class="semilables">Counts for overall weigh-in %</span>
          <el-input
            v-model="user_details.data.target_section.upskilling_overall_perc"
            @change="update_targets"
          >
          </el-input>
        </el-col>
      </el-col>
    </el-row>
    <div style="padding:20px; background:#f3f3f3; ">
      <el-row>
        <el-button type="text" style="float:right; " @click="dialogVisible = true">+ ADD NEW</el-button>
      </el-row>
      <el-row
        :gutter="0"
          v-for="(upskill, index) in user_details.data.upskillings" :key="upskill.id"
        style="margin-top:10px; background:#fff; border:1px solid #cdb6b6; padding:10px;"
      >
        <el-col :xs="2" :sm="2" :md="1" :lg="1" :xl="1">
          <i v-show="upskill.upskilling_status_id == 1"
            class="el-icon-folder-opened"
            style="color:grey"
          ></i>
          <i v-show="upskill.upskilling_status_id == 2"
            class="el-icon-check"
            style="color:green"
          ></i>
            <i v-show="upskill.upskilling_status_id == 3"
            class="el-icon-close"
            style="color:red"
          ></i>
        </el-col>
        <el-col :xs="24" :sm="24" :md="25" :lg="16" :xl="16" >
          <b>Description:</b> {{ upskill.upskilling_decription }}
        </el-col>
        <el-col :xs="24" :sm="24" :md="6" :lg="4" :xl="4">
            <b> Deadline date:</b> <br />
            {{ upskill.upskilling_end_time }}
        </el-col>
        <el-col :xs="24" :sm="24" :md="5" :lg="3" :xl="3">
          <el-col :xs="18" :sm="18" :md="18" :lg="18" :xl="18">
                  <el-select @change="update_goal(upskill)" v-model="upskill.upskilling_status_id" placeholder="Select">
                  <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                    >
                  </el-option>
                </el-select>
          </el-col>
          <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
             <el-button type="danger" @click="delete_goal(index, upskill)" icon="el-icon-delete" circle></el-button>
          </el-col>
        </el-col>
      </el-row>
    </div>
      <el-dialog
        title="New Upskilling/Goal"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
        <form @submit.prevent="onSubmit">
          <el-input type="textarea" placeholder="Description" v-model="upskilling_description"></el-input>
           <div class="block">
             <span class="demonstration">Deadline Date</span>
              <el-date-picker v-model="deadline_end_date" type="datetime" placeholder=" "></el-date-picker>
           </div>
         </form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="save_goal">Confirm</el-button>
        </span>
      </el-dialog>
  </div>
</template>
<script>
export default {
  name: "UserUpskilling",
  props: ['crm_user_id'],
  components: {},
  data() {
    return {
       dialogVisible: false,
       options: [],
       deadline_end_date: "",
       upskilling_description: "",
       user_details: {
         data: {
           target_section: {}
         }
       },
       pickerOptions: {
        shortcuts: [
          {
            text: "Last week",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last month",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last 3 months",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value1: "",
      value2: "",
    };
  },
  watch: {
    crm_user_id(){
      this.options = [];
      this.fill_status();
      this.fill_data();
    }
  },
  mounted() {
    this.fill_status();
    this.fill_data();
  },
  methods: {
    save_goal(){
      let params = {
        crm_user_id: this.crm_user_id,
        upskilling_end_time: this.deadline_end_date,
        upskilling_decription: this.upskilling_description,
        upskilling_status_id: this.upskilling_status_id = 1,
      }
      this.$http.post("/upskillings", { upskilling: params}
      ).then((response) => {
         this.$message({
            message: "Saved successfully.",
            type: "success",
          });
          this.dialogVisible = false;
          this.upskilling_description = "";
          this.deadline_end_date = "";
          this.user_details.data.upskillings.push(response.data)
        }, (response) => {
             this.$message({
              message: "Something went wrong, your change was not saved.",
              type: "error",
            });
          })
    },
    delete_goal(index, item){
      let params = {
        id: item.id, 
      }
      this.$http.delete("/upskillings/"+item.id, { upskilling: params}
      ).then((response) => {
         this.$message({
            message: "Deleted successfully.",
            type: "success",
          });
          this.user_details.data.upskillings.splice(index, 1)
      },(response) => {
           this.$message({
            message: "Something went wrong, your change was not saved.",
            type: "error",
          });
         })
    },
     update_goal(item){
       
      let params = {
        id: item.id, 
        upskilling_status_id: item.upskilling_status_id
      }
      this.$http.patch("/upskillings/"+item.id, { upskilling: params}
      ).then((response) => {
         this.$message({
            message: "Updated successfully.",
            type: "success",
          });
      }, (response) => {
           this.$message({
            message: "Something went wrong, your change was not saved.",
            type: "error",
          });
      })
    },
    fill_status(){
        this.$http.get("/upskilling_statuses").then((response) => {
          this.options = response.data
        })
    },
    fill_data() {
      this.$http.get(`/user_targets_data/${this.crm_user_id}`).then((response) => {
        this.user_details = response.data;
      });
    },
    update_targets() {
      let params = { target_section: this.user_details.data.target_section };
      this.$http.patch("/update_target_section/" + this.crm_user_id, params).then(
        (response) => {
          this.$message({
            message: "Updated successfully.",
            type: "success",
          });
        }, function(response) {
          this.$message({
            message: "Something went wrong, your change was not saved.",
            type: "error",
          });
        }
      );
    },
    onSubmit() {},
    handleClose(done) {
      this.$confirm("Are you sure to close this dialog?")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
  },
};
</script>
<style>
  span.smallheader {
    font-size: 15px;
    font-weight: bold;
    width: 100%;
    float: left;
    margin-bottom: 13px;
  }
  span.semilables {
    font-size: 11px;
    font-weight: bold;
    color: #333;
    width: 100%;
    float: left;
  }
  .smallbox {
    border: 1px solid #f3f3f3;
    padding: 2px;
  }
  .smallbox input {
    margin-bottom: 0px !important;
    height: 32px !important;
  }
  .selectfield input {
    margin-bottom: 0px !important;
    height: 1.5em !important;
    border-bottom: 0px !important;
  }
  .upskillingdate span.el-range-separator {
    width: 10% !important;
  }
</style>
