<template>
  <div style="padding:10px;overflow:auto;">
    <el-row >
      <el-col :xs="24" :sm="24" :md="4" :lg="6" :xl="6" style="padding:10px;">
        <span style="color:rgb(70, 205, 52) !important; font-weight:bold; ">
          STOCK ITEMS LIST
        </span>
      </el-col>
    </el-row>
    <table width="100%" class="items_list_summary" style="border: 1px solid #333">
      <thead>
        <tr>
          <td style="min-width:80px;">Category</td>
          <td style="min-width:100px;">Name</td>
          <td >Supplier</td>
          <td style="min-width:80px;">PO Details</td>
          <td>Billable</td>
          <td>Quantity</td>
          <td v-show="proposal.approved == true || proposal.approved == 1" style="min-width:80px;">
            Used Qty
          </td>
          <td style="min-width:80px;">Unit cost</td>

          <td style="min-width:80px;">Unit Sale price</td>
          <td style="min-width:80px;">Total Cost</td>
          <td style="min-width:80px;">Total Sale Price</td>
          <td>Action</td>
        </tr>
      </thead>
      <tbody>
        <StockItemEntry
          :stock="stock" v-for="stock in working_file_info.stock_items_list" v-bind:key="stock.id"
          :style="[
            (stock.request != undefined && stock.request.is_approved == 1) ||stock.mark_as_deleted == 1 ?
              {
                'text-decoration': 'line-through',
                'font-style:': 'italic',
              } : {},
          ]"
        />
        <NewStockToSummaryEdit :working_file_id="working_file_info.id" />
      </tbody>
      <tfoot>
        <tr>
          <td>Stock Total</td>
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
          <td v-show="proposal.approved == true || proposal.approved == 1"></td>
          <td />
          <td>${{ stock_items_total_cost }}</td>
          <td>${{ stock_items_total_sale }}</td>
          <td />
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { editor_store } from 'store/modules/editor';
import StockItemEntry from '../../../stock_items/StockItemEntry';
import NewStockToSummaryEdit from './NewStockToSummaryEdit';

export default {
  name: 'StockListEdit',
  store: editor_store,
  props: {
    working_file_info: {
      type: Object,
      required: true
    },
    stock_items_total_cost: {
      type: Number,
      required: true
    },
    stock_items_total_sale: {
      type: Number,
      required: true
    }

  },
  components: {
    NewStockToSummaryEdit,
    StockItemEntry
  },
  data() {
    return {
      stockaddarea: false,
    };
  },
  computed: {
    ...mapGetters([
      'proposal'
    ])
  }
};
</script>
<style scoped>
.items_list_summary {
  overflow:scroll;
}
</style>
