<template>
  <div>
    <div style="margin: 2px;text-align: start;">
      <el-button
        size="mini"
        round
        @click="create_new_page"
      >
        New page
      </el-button>
      <el-button
        size="mini"
        round
        @click="import_pdf_preview = true"
      >
        Import PDFs
      </el-button>
      <el-button
        size="mini"
        round
        @click="import_working_files_dialog = true"
      >
        Import from proposals
      </el-button>
    </div>
    <el-dialog
      title="Import working files"
      :close-on-click-modal="false"
      :visible.sync="import_working_files_dialog"
    >
      <ImportWorkingFiles :hide_import_working_files_dialog="hide_import_working_files_dialog" />
    </el-dialog>
    <el-dialog
      v-if="import_pdf_preview"
      id="import_pdf_preview_dialog"
      title="Import PDF preview"
      :close-on-click-modal="false"
      :visible.sync="import_pdf_preview"
      :fullscreen="true"
    >
      <ImportPDFPreview
        :hide_pdf_preview_dialog="hide_pdf_preview_dialog"
      />
    </el-dialog>
  </div>
</template>

<script>
import CategoryMixin from 'mixins/CategoryMixin';
import ImportPDFPreview from '../../editor/ImportPDFPreview';

export default {
  name: 'CategoryActions',
  components: {
    ImportPDFPreview
  },
  mixins: [CategoryMixin],
  data() {
    return {
      import_pdf_preview: false,
    };
  },
  methods: {
    show_pdf_preview_dialog() {
      this.import_pdf_preview = true;
    },
    hide_pdf_preview_dialog() {
      this.import_pdf_preview = false;
    }
  }
};
</script>
