<template>
  <div>
    <div class="divider"/>
    <el-container style="height: 800px;border: 1px solid #eee;overflow: hidden !important;">
      <el-aside width="415px" style="border-right: 1px solid #eee;overflow:unset;" >
        <el-table
            ref="singleTable"
            v-loading="loading"
            :data="emails"
            style="width: 100%"
            height="800"
            highlight-current-row
            @current-change="handleCurrentChange"
        >
          <el-table-column
              label="Attached Emails"
              property="received"
          >
            <template slot-scope="scope">
              <div style="cursor:pointer;"
                  @click="get_filtered_email({
                    message_id: scope.row.message_id,
                    mailbox_account_id: scope.row.mail_box_account_id,
                    mailbox: scope.row.mailbox_folder,
                    index: scope.$index,
                    guid: scope.row.mailbox_folder,
                    user: scope.row.user
                  })"
              >
                <p style="font-size:11px;text-align:right">
                  <el-tag
                      size="mini"
                      effect="dark"
                      type="info">
                    {{ scope.row.received }}
                  </el-tag>
                </p>
                <p style="font-size:11px;">
                  {{ scope.row.from }}
                  <i v-if="scope.row.has_attachments" class="el-icon-document" />
                </p>
                <p/>
                <p style="font-weight: bold;line-height: 1.1em;padding-bottom: 4px;" >
                  {{ scope.row.subject }}
                </p>
                <p style="font-size:11px;line-height: 1.1em;word-break: normal;" >
                  {{ scope.row.message }}
                </p>
              </div>
              <p style="text-align:right;margin-top: 10px;" @dblclick="get_filtered_email({
                    message_id: scope.row.message_id,
                    mailbox_account_id: scope.row.mail_box_account_id,
                    mailbox: scope.row.mailbox_folder,
                    index: scope.$index
                  }),(dialog_email = true)" >
                <el-row>
                  <el-tag
                      v-for="msg in scope.row.message_reference"
                      :key="msg.id"
                      closable
                      :disable-transitions="false"
                      @close="unlink_mail(msg)" >
                    <a :href="`${msg.link}`">{{ msg.link_title }}</a>
                  </el-tag>
                  <a target="_blank" :href="reply_external_link(scope.row)" >
                    <el-button
                        size="mini"
                        type="success"
                        icon="el-icon-edit"
                        circle />
                  </a>
                </el-row>
              </p>
            </template>
          </el-table-column>
        </el-table>
      </el-aside>

      <el-container id="email_container">
        <el-header style="text-align:right;margin-top: 10px;height: auto;">
          <el-button
              icon="el-icon-refresh"
              circle
              @click="refresh_filtered_mails"
          />
          <el-tooltip placement="top">
            <div slot="content">
              delete email
            </div>
          </el-tooltip>
          <el-row style="text-align:left;margin-top: 10px;color:#3c7ebf">
            Sent: {{ received }}
          </el-row>
          <el-row v-if="from_display_names" style="text-align:left;margin-top: 10px;" >
            <span :title="from_email">From: {{ from_display }}</span>
          </el-row>
          <el-row v-if="to_display_names" style="text-align:left;margin-top: 10px;" >
            <span :title="to_email">To: {{ to_display }}</span>
          </el-row>
          <el-row style="text-align:left;">
            <el-badge
                v-for="(file, index) in attachments"
                v-if="file.content_disposition == 'attachment'"
                :key="index"
                :value="file.size + 'kb'"
                class="item" >
              <el-button
                  size="mini"
                  round
                  @click=" download_attachment(
                    file.content_id,
                    file.original_filename,
                    file.mime_type)" >
                {{ file.original_filename }}
              </el-button>
            </el-badge>
          </el-row>
          <el-row
              class="divider"
              style="margin-top: 5px;"
          />
          <el-row style="text-align:left;margin-top: 15px;">
            <strong>Subject: {{ subject }}</strong>
          </el-row>
          <el-row class="divider" style="margin-top: 5px;margin-bottom: 15px;" />
        </el-header>
        <el-main style="background: #fff; all: initial;overflow:auto;margin-left: 20px;" >
          <div v-html="current_message"/>
        </el-main>
      </el-container>
    </el-container>
    <el-dialog
        title="Email"
        :visible.sync="dialog_email"
        width="100%"
        left
    >
      <el-container id="email_container">
        <el-header style="text-align:right;margin-top: 10px;height: auto;">
          <el-button
              v-show="is_embeded"
              icon="el-icon-refresh"
              circle
              @click="refresh_filtered_mails"
          />
          <el-row style="text-align:left;margin-top: 10px;color:#3c7ebf" >
            Sent: {{ received }}
          </el-row>
          <el-row v-if="from_display_names" style="text-align:left;margin-top: 10px;" >
            <span :title="from_email">From: {{ from_display }}</span>
          </el-row>
          <el-row v-if="to_display_names" style="text-align:left;margin-top: 10px;" >
            <span :title="to_email">To: {{ to_display }}</span>
          </el-row>

          <el-row style="text-align:left;">
            <el-badge
                v-for="(file, index) in attachments"
                v-if="file.content_disposition == 'attachment'"
                :key="index"
                :value="file.size + 'kb'"
                class="item"
            >
              <el-button
                  size="mini"
                  round
                  @click="download_attachment(
                    file.content_id,
                    file.original_filename,
                    file.mime_type )" >
                {{ file.original_filename }}
              </el-button>
            </el-badge>
          </el-row>
          <el-row class="divider" style="margin-top: 5px;" />
          <el-row style="text-align:left;margin-top: 15px;">
            <strong>Subject: {{ subject }}</strong>
          </el-row>
          <el-row class="divider" style="margin-top: 5px;margin-bottom: 15px;" />
        </el-header>
        <el-main style="background: #fff; all: initial;overflow:auto;margin-left: 20px;" >
          <div v-html="current_message"/>
        </el-main>
      </el-container>
    </el-dialog>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import MailToProposal from 'components/proposal/MailToProposal';
import ConnectMailbox from './ConnectMailbox';
import Filemanager from '../filemanager/Filemanager.vue';
import {mailbox_store} from 'store/modules/mailbox_store';

export default {
  components: {
    'mail-to-proposal': MailToProposal,
    filemanager: Filemanager,
    'connect-to-mailbox': ConnectMailbox
  },
  props: ['filtered_msg_id', 'mailbox_account', 'branch_id', 'proposal_id', 'po_id'],
  data() {
    return {
      currentRow: null,
      infinite_state: null,
      search_term: '',
      dialog_email: false,
      dialog_email_message: ''
    };
  },
  store: mailbox_store,
  created() {
    this.refresh_filtered_mails();
  },
  computed: {
    ...mapGetters([
      'title',
      'icon',
      'emails',
      'current_message',
      'current_message_id',
      'current_mailbox',
      'current_index',
      'attachments',
      'loading',
      'subject',
      'to',
      'from',
      'received',
      'to_display_names',
      'from_display_names',
      'infinite_id',
      'message_flag',
      'mail_count_per_search',
      'stop_infinite_loading',
      'is_first_load',
      'total_amount_emails',
      'user'
    ]),
    ...mapState([
      'emails',
      'stop_infinite_loading',
      'infinite_id',
      'mail_count_per_search',
      'is_first_load',
      'message_flag'
    ]),
    message_id_changed() {
      return this.current_message_id;
    },
    to_email() {
      return Array.isArray(this.to) ? this.to.join(', ') : this.to;
    },
    from_email() {
      return Array.isArray(this.from) ? this.from.join(', ') : this.from;
    },
    to_display() {
      let to = Array.isArray(this.to) ? this.to.join(', ') : this.to;
      return this.to_display_names[0] == null
          ? to
          : this.to_display_names.join(', ');
    },
    from_display() {
      let from = Array.isArray(this.from) ? this.from.join(', ') : this.from;
      return this.from_display_names[0] == null
          ? from
          : this.from_display_names.join(', ');
    },
    is_embeded() {
      return !(this.filtered_msg_id == undefined || this.filtered_msg_id == '');
    }
  },
  watch: {
    // filtered_msg_id(value) {
    //   // wait for props to arrive from job comunications,editor store
    //   // message_references, mailbox_account
    //   this.refresh_filtered_mails();
    // },
    mail_count_per_search(value) {
      // if (this.is_first_load == true) {
      //   this.set_first_load(false);
      //   this.infinite_loader(this.infinite_state);
      // }
    },
    message_id_changed(value) {
      // get current email when email list is loaded
      // if (value != "") {
      //   this.get_email({
      //     message_id: value,
      //     mailbox_account_id: this.mailbox_account
      //   });
      //   //console.log(`current email changed to ${value}`);
      // }
      if (this.currentRow == null) {
        this.$refs.singleTable.setCurrentRow(this.emails[0]);
      }
    },
    emails(value) {
      //console.log('emails loaded');
      if (this.infinite_state != null || this.infinite_state != undefined) {
        this.infinite_state.loaded();
      }
    }
  },
  methods: {
    ...mapMutations(['headline']),
    ...mapActions([
      'get_filtered_email',
      'get_filtered_emails',
      'delete_email',
      'get_emails',
      'clear_emails',
      'set_infinite_id',
      'set_message_flag',
      'count_mails',
      'move_to_spam',
      'set_first_load',
      'load_mailboxes'
    ]),
    drag(msgid, ev) {
      ev.currentTarget.style.border = 'dashed';
      ev.dataTransfer.setData(
          'text/plain',
          msgid + '__' + this.current_mailbox
      );
    },
    create_folder_mailbox() {
      this.$prompt('Folder name', 'Create Mailbox', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        inputPattern: /^[-a-zA-Z0-9@\.+_]+$/,
        inputErrorMessage: 'Invalid Name'
      })
          .then(({value}) => {
            let name = value;
            this.$http.post('/create_mailbox_folder', {foldername: name}).then(
                response => {
                  this.$message({
                    message: 'Folder created',
                    type: 'success'
                  });
                  this.load_mailboxes();
                  //this.$router.push(response.data.name);
                },
                function (response) {
                }
            );
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: 'Input canceled'
            });
          });
    },
    search_mails() {
      // TEXT searches header and body from email
      // https://tools.ietf.org/html/rfc3501#section-6.4.4
      if (this.search_term.length > 1) {
        this.clear_emails();

        this.set_message_flag(['TEXT', this.search_term]);
      }
    },
    clear_search_mails() {
      location.reload();
    },
    connect_to_mailbox(value) {
      console.log(value);
    },
    unlink_mail(msg) {
      let self = this;
      this.$confirm(
          'This will unlink the current mail from clicked ' +
          msg.messageable_type +
          '. Continue?',
          'Warning',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
      )
          .then(() => {
            this.$http.delete('/unlink_mail/' + msg.id).then(
                //success
                function (response) {
                  if (response.status == 200) {
                    console.log(self);
                    self.$message({
                      type: 'success',
                      message: 'Mail has been unlinked.'
                    });
                  }
                },
                function (response) {
                }
            );
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: 'Unlink canceled'
            });
          });
    },
    renderButtonHeader(h, {column, $index}) {
      if ($index === 1) {
        return h('el-row', {}, [
          h('span', column.label),
          h('el-button', {
            props: {
              type: 'primary',
              icon: 'el-icon-edit',
              circle: 'circle',
              size: 'mini'
            },
            style: 'font-weight: normal',
            on: {
              click(e) {
                alert('ll');
              }
            }
          }),
          h('el-button', {
            props: {
              icon: 'el-icon-search',
              circle: 'circle'
            },
            style: 'font-weight: normal',
            on: {
              click(e) {
                alert('ll');
              }
            }
          })
        ]);
      } else {
        return column.label;
      }
    },
    download_attachment(content_id, filename, mime_type) {
      this.$http
          .post(
              '/download_attachment',
              {
                message_id: this.current_message_id,
                content_id: content_id,
                mailbox: this.currentRow.mailbox_folder,
                user: this.user,
                filename: filename
              },
              {responseType: 'blob'}
          )
          .then(response => {
            console.log(mime_type);
            let blob = new Blob(
                [this.base64toBlob(response.data.attachment, mime_type)],
                {type: mime_type}
            );
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.click();
          })
          .catch(err => {
            console.log(err);
          });
    },
    view_attachments(row) {
      console.log('todo: download all attachments');
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },
    base64toBlob(base64Data, contentType) {
      contentType = contentType || '';
      var sliceSize = 1024;
      var byteCharacters = atob(base64Data);
      var bytesLength = byteCharacters.length;
      var slicesCount = Math.ceil(bytesLength / sliceSize);
      var byteArrays = new Array(slicesCount);

      for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        var begin = sliceIndex * sliceSize;
        var end = Math.min(begin + sliceSize, bytesLength);

        var bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
      }
      return new Blob(byteArrays, {type: contentType});
    },

    reply_external_link(row) {
      // scope.row.message_id,
      //                   scope.row.mail_box_account_id,
      //                   scope.row.mailbox_folder,
      //                   scope.row.mailbox_folder,
      //                   scope.row.user
      let encoded_msg_id = encodeURIComponent(row.message_id);
      return `${window.location.origin}/mailbox#/compose/draft?message_id=${encoded_msg_id}&action=reply&email_account=${row.user}&mailbox=${row.mailbox_folder}&user=${row.user}`;
    },
    refresh_filtered_mails() {
      this.clear_emails();
      this.get_filtered_emails({
        branch_id: this.branch_id,
        filtered_msg_id: this.filtered_msg_id,
        mailbox_account: this.mailbox_account,
        proposal_id: this.proposal_id,
        po_id: this.po_id
      });
    }
  }
};
</script>
<style>
.el-loading-spinner .circular {
  border: none !important;
}
</style>
<style scoped>


p {
  padding: 0px;
  margin: 0px;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 12px;
}

a,
a:link,
a:visited {
  color: #039be5 !important;
  text-decoration: none !important;
}

.item {
  margin-top: 10px;
  margin-right: 40px;
}

.el-button + .el-button {
  margin-left: 0px !important;
}

.search_emails {
  width: 282px;
}

.el-table--border::after,
.el-table--group::after,
.el-table::before {
  position: relative;
}
</style>
