<template>
  <div>
    <el-tabs
      type="border-card"
      tab-position="left"
    >
      <el-tab-pane
        :lazy="true"
        label="Organization details"
      >
        <OrganizationDetails />
      </el-tab-pane>
      <el-tab-pane
        :lazy="true"
        label="Jobs settings"
      >
        <JobsSettings />
      </el-tab-pane>
      <el-tab-pane
        :lazy="true"
        label="Branches"
      >
        <Branches />
      </el-tab-pane>
      <el-tab-pane
        :lazy="true"
        label="Job Statuses"
      >
        <HoldStatus />
      </el-tab-pane>
      <el-tab-pane
        :lazy="true"
        label="Public Holidays"
      >
        <PublicHolidays />
      </el-tab-pane>
      <el-tab-pane
        :lazy="true"
        label="Leave types"
      >
        <LeaveTypes />
      </el-tab-pane>
      <el-tab-pane
        :lazy="true"
        label="Cron Transactions"
      >
        <CronTransactions />
      </el-tab-pane>
      <el-tab-pane label="Task">
        Task
      </el-tab-pane>
      <el-tab-pane
        :lazy="true"
        label="Company Notices"
      >
        <CompanyNotices />
      </el-tab-pane>
      <el-tab-pane
        :lazy="true"
        label="Company Settings"
      >
        <CompanySettings />
      </el-tab-pane>
      <el-tab-pane
          :lazy="true"
          label="Tax Rates"
      >
        <tax-rates />
      </el-tab-pane>
      <el-tab-pane
          :lazy="true"
          label="Acc Levy Rates"
      >
        <acc-levy-rates />
      </el-tab-pane>
      <el-tab-pane
        :lazy="true"
        label="Acc Income Cap"
    >
      <acc-income-cap />
    </el-tab-pane>
      <el-tab-pane
          :lazy="true"
          label="ESCT Rates"
      >
        <esct-rates />
      </el-tab-pane>
      <el-tab-pane
          :lazy="true"
          label="Student Loan Thresholds"
      >
        <student-loan-thresholds />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import PublicHolidays from './PublicHolidays';
import LeaveTypes from './LeaveTypes';
import CronTransactions from './CronTransactions';
import OrganizationDetails from './OrganizationDetails';
import HoldStatus from './HoldStatus';
import Branches from './Branches';
import CompanyNotices from './CompanyNotices';
import JobsSettings from './JobsSettings';
import CompanySettings from './CompanySettings'
import TaxRates from './TaxRates'
import AccLevyRates from './AccLevyRates'
import EsctRates from './EsctRates'
import AccIncomeCap from './AccIncomeCap'
import StudentLoanThresholds from './StudentLoanThresholds'

export default {
  name: 'CompanyProfile',
  components: {
    PublicHolidays,
    LeaveTypes,
    CronTransactions,
    OrganizationDetails,
    CompanyNotices,
    Branches,
    HoldStatus,
    JobsSettings,
    CompanySettings,
    TaxRates,
    AccLevyRates,
    EsctRates,
    AccIncomeCap,
    StudentLoanThresholds
  },
};
</script>
