<template>
  <div>
    <h3><b>COMPANY SETTINGS</b></h3>
    <hr>
    <el-form
      ref="order_form"
      :model="form"
      size="small"
    >
      <el-row :gutter="100">
        <el-col :span="12">
          <el-form-item
            label="Display Time Format"
            prop="is_hours"
          >
            <el-switch
              v-model="form.is_hours"
              :active-color="sw_active_color"
              :inactive-color="sw_inactive_color"
              active-text="Hours"
              inactive-text="Minutes"
              class="sw"
              @change="onIsHoursChange"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="Input Job Working Time to TimeSheet"
            prop="is_jobtime_input"
          >
            <el-switch
              v-model="form.is_jobtime_input"
              :active-color="sw_active_color"
              :inactive-color="sw_inactive_color"
              active-text="YES"
              inactive-text="NO"
              class="sw"
              @change="onIsJobtimeInputChange"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="100">
        <el-col :span="24">
          <h3><b>Invoice PDF</b></h3>
          <hr>
        </el-col>
        <el-col :span="24">
          <el-form-item label="Footer">
            <el-input
              v-model="form.invoice_footer"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 30 }"
              placeholder="Input Invoice Footer html"
              @change="onInvoiceFooterChange"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="100">
        <el-col :span="24">
          <h3><b>Purchase Order PDF</b></h3>
          <hr>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Header">
            <el-input
              v-model="form.po_header"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 20}"
              placeholder="Input Purchase Order Header html"
              @change="onPurchaseOrderHeaderChange"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Footer">
            <el-input
              v-model="form.po_footer"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 20}"
              placeholder="Input Purchase Order Footer html"
              @change="onPurchaseOrderFooterChange"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {
  loadIsHours, updateIsHours,
  loadIsJobtimeInput, updateIsJobtimeInput,
  loadInvoiceFooter, updateInvoiceFooter,
  loadPurchaseOrderHeader, updatePurchaseOrderHeader,
  loadPurchaseOrderFooter, updatePurchaseOrderFooter
} from '../../api/organization';

import Colors from '../../../assets/stylesheets/export_colors.scss';

export default {
  name: 'CompanySettings',
  data() {
    return {
      form: {
        is_hours: false,
        is_jobtime_input: false,
        invoice_footer: '',
        po_header: '',
        po_footer: ''
      }
    };
  },
  computed: {
    sw_active_color() {
      return Colors.malachiteColor;
    },
    sw_inactive_color() {
      return Colors.tartOrangeColor;
    }
  },
  created() {
    loadIsHours(this).then(({data}) => { this.form.is_hours = data.is_hours; });
    loadIsJobtimeInput(this).then(({data}) => { this.form.is_jobtime_input = data.is_jobtime_input; });
    loadInvoiceFooter(this).then(({data}) => { this.form.invoice_footer = data.invoice_footer; });
    loadPurchaseOrderHeader(this).then(({data}) => { this.form.po_header = data.po_header; });
    loadPurchaseOrderFooter(this).then(({data}) => { this.form.po_footer = data.po_footer; });
  },
  methods: {
    onIsHoursChange() {
      updateIsHours(this, this.form.is_hours).then(({data}) => {
        this.$message({
          type: 'success',
          message: data.message
        });
      });
    },
    onIsJobtimeInputChange() {
      updateIsJobtimeInput(this, this.form.is_jobtime_input).then(({data}) => {
        this.$message({
          type: 'success',
          message: data.message
        });
      });
    },
    onInvoiceFooterChange() {
      updateInvoiceFooter(this, this.form.invoice_footer).then(({data}) => {
        this.$message({
          type: 'success',
          message: data.message
        });
      });
    },
    onPurchaseOrderHeaderChange() {
      updatePurchaseOrderHeader(this, this.form.po_header).then(({data}) => {
        this.$message({
          type: 'success',
          message: data.message
        });
      });
    },
    onPurchaseOrderFooterChange() {
      updatePurchaseOrderFooter(this, this.form.po_footer).then(({data}) => {
        this.$message({
          type: 'success',
          message: data.message
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.sw {
  float: right;
  margin-top: 5px;
}
</style>
