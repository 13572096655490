<template>
  <div class="row stocks_list_div">
    <div class="col s12 m12">
      <div class="card grey lighten-5">
        <div class="card-content">
          <span class="card-title">Stock Items list</span>
          <hr />
          <table
            id="stocks_list_table"
            class="responsive-table highlight"
            v-if="
              grouped_stock_items_list && grouped_stock_items_list.length != 0
            "
          > 
            <thead>
              <tr>
                <th>Working file</th>
                <th>Name</th>
                <th>Category</th>
                <th>Supplier</th>
                <th>Qty</th>
                <th
                  v-show="proposal.approved == true || proposal.approved == 1"
                >
                  Used qty
                </th>
                <th>Billable</th>
                <th>Sale Price</th>
                <th>Total Price</th>
                <th>Stock/PO details</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <StockItemEntry
                :stock="stock"
                v-for="stock in grouped_stock_items_list"
                v-bind:key="stock.id"
                v-bind:style="[
                  (stock.request != undefined &&
                    stock.request.is_approved == 1) ||
                  stock.mark_as_deleted == 1
                    ? {
                        'text-decoration': 'line-through',
                        'font-style:': 'italic',
                      }
                    : {},
                ]"
              ></StockItemEntry>
            </tbody>
          </table>
        </div>
        <div class="card-action">
          <div class="row">
            <div v-show="proposal.completed != 1">
              <div class="col s2">
                <label for="stock_quantity">Quantity:</label>
                <el-input-number
                  type="text"
                  width="100"
                  name="stock_quantity"
                  id="stock_quantity"
                  v-model="stock_quantity"
                  size="small"
                ></el-input-number>
              </div>
              <div class="col s2">
                <label for="sale_price">Unit Sale price</label>
                <el-input-number
                  width="100"
                  type="text"
                  name="sale_price"
                  id="sale_price"
                  v-model="sale_price"
                  size="small"
                ></el-input-number>
              </div>
              <div class="col s8">
                <multiselect
                  v-model="selected"
                  :options="options"
                  :close-on-select="true"
                  label="name"
                  track-by="id"
                  :max="2"
                  :searchable="true"
                  :multiple="true"
                  :loading="is_loading"
                  placeholder="search or click to add stock items"
                  @select="current_selected_value"
                  @search-change="find_subcategories"
                ></multiselect>
              </div>
              <div class="col s3"></div>
              <div class="col s8">
                <button
                  v-on:click.prevent="save_stock_item"
                  id="save_stock_item"
                  class="btn right save"
                >
                  save stock item
                </button>
              </div>
              <div class="col s3">
                <button
                  style="display: none"
                  v-on:click.prevent="stock_list"
                  class="stock_refresh btn"
                >
                  Refresh
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="Stock attached tasks"
      :visible.sync="related_stock_dialog"
    >
      <StockRelateTasksList
        :jobs_list="stock_related_tasks"
        v-on:hide_stock_related_dialog="related_stock_dialog = false"
      ></StockRelateTasksList>
    </el-dialog>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import StockItemEntry from "./../stock_items/StockItemEntry";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { editor_store } from "store/modules/editor";
import { ProposalStockItemMixin } from "mixins/ProposalStockItemMixin.js";
import StockRelateTasksList from "components/stock_items/StockRelateTasksList";

export default {
  name: "StockItemsList",
  components: { Multiselect, StockItemEntry, StockRelateTasksList },
  mixins: [ProposalStockItemMixin],
  props: ["working_file_id"],
  store: editor_store,
  data() {
    return {
      selected: [],
      options: [],
      related_stock_dialog: false,
      stock_related_tasks: [],
      main_categories: [],
      is_loading: false,
      stock_item_id: null,
      stock_quantity: 1,
      sale_price: 0,
      stock_quantity_calculated_overwritten: null,
    };
  },
  computed: {
    ...mapGetters(["proposal", "stock_items_list"]),
    grouped_stock_items_list() {
      if (this.working_file_id == undefined) return this.stock_items_list;
      else
        return this.stock_items_list.filter(
          (c) => c.working_file_id == this.working_file_id
        );
    },
  },
  mounted: function() {
    this.categories();
    this.set_inputs_to_default();
    //this.stock_list();

    // eventBus.$on("stock_quantity", stock_quantity => {
    //   this.stock_quantity = stock_quantity;
    // });

    // document
    //   .querySelectorAll(".stock_container [type=submit]")
    //   .forEach(function(item, index) {
    //     item.addEventListener("click", function(e) {
    //       if (
    //         $(e.currentTarget)
    //           .val()
    //           .replace(/ /g, "") == "SaveStockItem"
    //       )
    //         return true;

    //       e.preventDefault();
    //       var container = $(this).closest(".stock_container");
    //       var qty = container.find("#stock_quantity").val();
    //       var type = container.attr("data-type");
    //       var id = container.attr("data-id");
    //       var submit_btn = this;
    //       var params = [
    //         {
    //           name: "selected_stock_item[quantity]",
    //           value: qty
    //         },
    //         {
    //           name: "selected_stock_item[proposal_type]",
    //           value: type
    //         },
    //         {
    //           name: "selected_stock_item[id]",
    //           value: id
    //         }
    //       ];

    //       $.each(params, function(i, param) {
    //         $('[name="' + param.name + '"]').remove();
    //         $(".edit_proposal").append(
    //           $.map(params, function(param) {
    //             return $("<input>", {
    //               type: "hidden",
    //               name: param.name,
    //               value: param.value
    //             });
    //           })
    //         );
    //       });
    //       $(".edit_proposal").append(
    //         $("<input>", {
    //           type: "hidden",
    //           name: "commit",
    //           value: "create stock item"
    //         })
    //       );

    //       console.log("finish");
    //       e.stopPropagation();
    //       $(".edit_proposal").submit();
    //     });
    //   });
  },

  methods: {
    set_inputs_to_default() {
      let _this = this;
      setTimeout(() => {
        Array.from(
          document.querySelectorAll(".stocks_list_div .el-input")
        ).forEach(function(e, i) {
          e.classList.add("browser-default");
          if (e.querySelector("input") != null)
            e.querySelector("input").className += " browser-default";
        });
      }, 300);
    },
    // calc_total_price: function(price, percent, quantity) {
    //   var tp = 0.0;
    //   if (quantity > 0 && price > 0) {
    //     tp = parseFloat(price) * quantity;
    //   }

    //   if (percent > 0 && tp > 0) {
    //     tp = parseFloat(tp) + (parseFloat(tp) / 100) * percent;
    //   }
    //   return tp;
    // },
    get_stock_category(stock) {
      if (stock.sign_id != undefined) return "signage";
      else if (stock.print_calculation_id != undefined) return "print";
      else return "default";
    },

    categories: function() {
      let self = this;
      this.$http.get("/s_categories").then(
        (response) => {
          self.options = response.data;
          self.main_categories = response.data;
        },
        function(response) {
          console.log(response);
        }
      );
    },

    current_selected_value(selectedValue) {
      let self = this;
      if (selectedValue.main_category != undefined) {
        //this.options = [];
        this.load_favorite_stock(selectedValue.id);
      }
      if (this.selected != null) {
        if (this.selected.length == 1) {
          this.stock_item_id = selectedValue.id;
          this.disabled = "false";
          this.sale_price = selectedValue.sale_price;
          // self.save_stock_item();
        }
      }
    },
    save_stock_item: function() {
      let self = this;

      if (self.stock_item_id != null && self.stock_quantity > 0) {
        let params = {
          id: self.proposal.id,
          stock_item_id: self.stock_item_id,
          proposal_id: self.proposal.id,
          stock_quantity: self.stock_quantity,
          sale_price: self.sale_price,
        };
        if (this.working_file_id != undefined && this.working_file_id > 0)
          params["working_file_id"] = this.working_file_id;
        this.$http.post("/save_stock_item", params).then(
          (response) => {
            self.stock_items_list.push(response.body.data);
            self.check_stock_attached_tasks(self.stock_item_id);
            self.stock_item_id = null;
            self.selected = null;
            this.categories();
            self.set_inputs_to_default();
          },
          function(response) {
            console.log(response);
          }
        );
      } else {
        console.log("error");
      }
    },
    check_stock_attached_tasks(stock_item_id) {
      let selected_stock = this.stock_items_list.filter((item) => {
        return item.stock_item_id == stock_item_id;
      })[0];

      this.get_stock_attached_tasks(stock_item_id).then(
        (response) => {
          if (response.length > 0) {
            this.stock_related_tasks = response;

            this.stock_related_tasks.forEach((el, idx) => {
              el["time_allocated_labour"] =
                this.stock_quantity *
                (selected_stock.speed_rate == "0"
                  ? 1
                  : selected_stock.speed_rate);
            });
            this.related_stock_dialog = true;
          }
        },
        (reason) => {
          console.log(reason);
        }
      );
    },
    internal_value(internalValue) {
      let self = this;
      if (internalValue.length === 0) {
        this.categories();
      }
      self.is_loading = true;
      if (internalValue.length === 1) {
        this.$http
          .post("/category_products", {
            category_supplier_id: internalValue[0].id,
            branch_id: this.proposal.branch_id,
          })
          .then(
            (response) => {
              self.is_loading = false;
              self.options = response.data;
            },
            function(response) {
              self.is_loading = false;
              console.log(response);
            }
          );
      }
    },
    load_favorite_stock(category_id) {
      let _this = this;
      this.$http
        .post("/favorite_stocks_by_category", {
          category_supplier_id: category_id,
          branch_id: this.proposal.branch_id,
        })
        .then((response) => {
          _this.options = response.data;
        });
    },
    find_subcategories(internalValue) {
      if (this.selected.length == 0) {
        this.options = this.main_categories;
      } else if (this.options.length == 0 && this.selected.length == 0) {
        this.categories();
        return;
      } else if (this.selected.length == 0 && this.options.length > 0) {
        return;
      }
      // else if (this.selected.length  == 1 && this.options.length == 1)
      // {
      //     this.options = []
      // }
      let self = this;

      self.is_loading = true;
      if (internalValue.length > 2) {
        this.$http
          .post("/category_products", {
            category_supplier_id: self.selected[0].id,
            term: internalValue,
            branch_id: this.proposal.branch_id,
          })
          .then(
            (response) => {
              self.is_loading = false;
              self.options = response.data;
            },
            function(response) {
              self.is_loading = false;
              console.log(response);
            }
          );
      } else {
        self.options = [];
      }
    },
  },
};
</script>

<style>
#stocks_list_table td {
  padding: 5px;
}
</style>
