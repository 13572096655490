<template>
  <div
    class="whiteBackground"
    style="min-height:125px;"
  >
    <!-- <div class="taskinsidetitles">
      <el-row>
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">Task Dates/Times</el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" class="rightText"></el-col>
      </el-row>
    </div> -->
    <el-row :gutter="10">
      <el-col
        :xs="24"
        :sm="24"
        :md="12"
        :lg="12"
        :xl="12"
        styl="padding:20px;"
      >
        <span v-show="!task.completed && !task.custom_start_date">
          <b>EST START TIME:</b>
          <br>
          {{ task.time_frame_start }}
        </span>
        <span
          v-show="
            !task.completed && task.custom_start_date && !task.is_date_locked
          "
          style="color:orange"
        >
          <b>CUSTOM START TIME:</b>
          <br>
          {{ task.custom_start_date }}
        </span>
        <span
          v-show="
            !task.completed && task.custom_start_date && task.is_date_locked
          "
          style="color:red"
        >
          <i
            v-show="task.is_date_locked"
            class="el-icon-lock"
          />
          <b>LOCKED DATE:</b>
          <br>
          {{ task.custom_start_date }}
        </span>

        <span
          v-show="task.completed"
          style="color:green"
        >
          <b style="color:green">COMPLETED DATE:</b>
          <br>
          {{ task.completed_date }}
        </span>
      </el-col>
      <el-col
        :xs="24"
        :sm="24"
        :md="12"
        :lg="12"
        :xl="12"
      >
        <span v-show="!task.completed">
          <b>EST FINISH TIME:</b>
          <br>
          {{ task.time_frame_end }}
        </span>
      </el-col>
      <el-col
        v-show="!task.completed"
        :xs="24"
        :sm="24"
        :md="24"
        :lg="24"
        :xl="24"
      >
        <el-form
          v-show="false"
          class="taskTime"
        >
          <b> Custom Date:</b>
          <el-date-picker
            v-show="!task.is_date_locked"
            v-model="custom_start_date"
            :value="task.custom_start_date"
            class="datepicker"
            type="datetime"
            placeholder
            editable
            clearable
            clear-icon="el-icon-circle-close"
            default-time="12:00:00"
            @change="update_custom_time"
          />

          <el-button
            v-show="!task.is_date_locked"
            type="danger"
            icon="el-icon-close"
            circle
            size="mini"
            @click="clear_custom_date"
          />

          <span style="margin-left: 10px">
            <b>Lock date:</b>
            <el-switch
              v-model="task.is_date_locked"
              active-color="red"
              inactive-color="#f3f3f3"
              @change="lock_in_date(task)"
            />
          </span>

          <br>
        </el-form>

        <br>
        <span
          style="color:#333; background:#f3f3f3; width:100%;padding:10px;font-size:15px;float:left;"
        >OVERALL JOB DEADLINE: {{ proposal.dated }}</span>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { editor_store } from 'store/modules/editor';
import { ProposalMixin } from 'mixins/ProposalMixin.js';

export default {
  name: 'TaskTimes',
  components: {},
  mixins: [ProposalMixin],
  props: ['task'],
  store: editor_store,
  data() {
    return {
      custom_start_date: '',
      month_names: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    };
  },
  mounted() {},
  computed: {
    ...mapGetters(['proposal']),
  },
  methods: {
    lock_in_date(task) {
      let _this = this;
      let params = {
        id: task.id,
        is_date_locked: task.is_date_locked,
      };
      this.update_jp_date_locked(params).then(
        (response) => {
          this.$message({
            message: 'Date lock Updated',
            type: 'success',
          });
        },
        (response) => {
          this.$message({
            message: 'Unable to update',
            type: 'error',
          });
        }
      );
    },
    formatted_date(date) {
      if (date == '' || date == undefined) return '';
      let dt = new Date(date);
      return (
        dt.getDate() +
        ' ' +
        this.month_names[dt.getMonth()] +
        ' ' +
        dt.getFullYear() +
        ' ' +
        dt.getHours() +
        ':' +
        dt.getMinutes()
      );
    },
    update_custom_time(e) {
      let _this = this;
      let params = {
        id: this.task.id,
        manual_date: this.custom_start_date,
      };
      this.update_custom_start_date(params).then(
        (response) => {
          this.task.custom_start_date = this.formatted_date(
            this.custom_start_date
          );

          this.$message({
            message: 'Custom Date Updated',
            type: 'success',
          });
        },
        (response) => {
          this.$message({
            message: 'Unable to update',
            type: 'error',
          });
        }
      );
    },
    clear_custom_date() {
      this.custom_start_date = '';
      this.update_custom_time();
    },
  },
  watch: {},
};
</script>
<style>
.rightText {
  text-align: right;
}
.taskTime i.el-input__icon {
  display: none;
}
</style>
