<template>
  <table style="width:100%">
    <tr>
      <th width="14%">
        <div>CURRENT</div>
      </th>
      <th width="14%">
        <div>30 DAYS</div>
      </th>
      <th width="14%">
        <div>60 DAYS</div>
      </th>
      <th width="14%">
        <div>90 DAYS +</div>
      </th>
      <th width="14%">
        <div>UNASSIGNED PAYMENTS</div>
      </th>
      <th width="14%">
        <div>BAD DEBTS</div>
      </th>
      <th>
        <div class="total_amount">
          TOTAL AMOUNT DUE
        </div>
      </th>
    </tr>
    <tr>
      <td>
        <Money :amount="summary_balance.current.total" />
      </td>
      <td>
        <Money :amount="summary_balance.thirty.total" />
      </td>
      <td>
        <Money :amount="summary_balance.sixty.total" />
      </td>
      <td>
        <Money :amount="summary_balance.ninety.total" />
      </td>
      <td>
        <Money :amount="summary_balance.unassigned_credits" />
      </td>
      <td>
        <Money :amount="summary_balance.bad_debts" />
      </td>
      <td class="total_amount">
        <Money :amount="summary_balance.amount_due" />
      </td>
    </tr>
    <tr>
      <td>
        <div class="additional-info">
          <div>Invoice: <Money :amount="summary_balance.current.invoice" /></div>
          <div>Credit Note: <Money :amount="summary_balance.current.credit_note" /></div>
        </div>
      </td>
      <td>
        <div class="additional-info">
          <div>Invoice: <Money :amount="summary_balance.thirty.invoice" /></div>
          <div>Credit Note: <Money :amount="summary_balance.thirty.credit_note" /></div>
        </div>
      </td>
      <td>
        <div class="additional-info">
          <div>Invoice: <Money :amount="summary_balance.sixty.invoice" /></div>
          <div>Credit Note: <Money :amount="summary_balance.sixty.credit_note" /></div>
        </div>
      </td>
      <td>
        <div class="additional-info">
          <div>Invoice: <Money :amount="summary_balance.ninety.invoice" /></div>
          <div>Credit Note: <Money :amount="summary_balance.ninety.credit_note" /></div>
        </div>
      </td>
      <td />
      <td />
      <td />
    </tr>
  </table>
</template>

<script>

import Money from '../../shared/Money';

export default {
  name: 'InvoiceSummary',
  components: {Money},
  props: {
    summary_balance: {
      type: Object,
      required: true,
    }
  }
};
</script>

<style lang='scss' scoped>
table {
  border:1px solid #f3f3f3;
  border-collapse: collapse;

  th, td{
    border-left: 1px solid #f3f3f3;
    border-right: 1px solid #f3f3f3;
    text-align: center;
    font-weight: bold;
  }

  td {
    padding: 0px 5px 10px;

    &:first-child {
      border-left: none;
    }
    &:last-child {
      border-right: none;
    }

    .additional-info {
      font-size:12px;
      color: #409eff;
    }
  }

  th {
    padding: 10px 5px;
    height: 1rem; // To make div height stretched to th
    div {
      background-color: #f3f3f3;
      padding-top: 5px;
      padding-bottom: 5px;
      height: 100%;
    }

    .total_amount {
      background-color: #e2feee;
    }
  }

  .total_amount {
    color: green;
  }
}
</style>
