<template>
  <div>
    <el-row>
      <el-col>
        <div
          class="margin-left"
          v-html="proposal.pre_description_truncated"
        />
        <el-button
          type="primary"
          size="mini"
          class="right"
          @click="show_dialog = true"
        >
          Edit / Read
        </el-button>
      </el-col>
    </el-row>
    <el-dialog
      title="Edit Job Description"
      :visible.sync="show_dialog"
      width="50%"
      append-to-body
      :close-on-click-modal="false"
    >
      <jodit-editor
        id="description_editor"
        ref="description_editor"
        v-model="proposal.pre_description"
        :config="jodit_config"
      />
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="success"
          size="mini"
          @click="update(), show_dialog = false"
        >
          Save
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {pre_description, update_proposal} from '../../api/proposal.js';
export default {
  name: 'ProposalPreDescription',
  props: {
    proposal_id: {
      type: [String,Number],
      required: true
    }
  },
  data() {
    return {
      show_dialog: false,
      proposal: {id: '', pre_description: '', pre_description_truncated: ''},
      jodit_config : {
        enter: 'br',
      },
    };
  },
  created() {
    this.proposal_pre_description();
  },
  methods: {
    proposal_pre_description(){
      pre_description(this, this.proposal_id).then((response) => {
        this.proposal = response.data;
      });
    },
    update(){
      update_proposal(this, this.proposal_id, this.proposal.pre_description).then((response) => {
        this.$message({
          message: response.data.message,
          type: 'success',
        });
        this.proposal_pre_description();
      });
    }
  }
};
</script>
<style scoped>
.right{float:right;}
.margin-left{ margin-left: 10px;}
</style>
