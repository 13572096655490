<template>
  <div class="row">
    <div class="col m12">
      <div class="row" v-loading="loading">
        <el-radio-group
          v-model="calendar_selected_group"
          v-on:change="update_calendar_view"
          size="mini"
        >
          <el-radio-button label="Lists"></el-radio-button>
          <el-radio-button label="Jobs"></el-radio-button>
          <!-- <el-radio-button label="Tasks"></el-radio-button>
          <el-radio-button label="Assets"></el-radio-button> -->
          <el-radio-button label="Users"></el-radio-button>
        </el-radio-group>
        <el-button @click="fetch_data" type="success" class="right" size="mini"
          >Refresh</el-button
        >
        <el-button
          @click="reassign_tasks"
          type="success"
          class="right"
          size="mini"
          style="margin: 0px 5px;"
          >Reassign tasks</el-button
        >
      </div>
      <div class="row">
        <FullCalendar
          :config="calendar_config"
          editable="false"
          :defaultView="calendar_selected_view"
          :views="calendar_views"
          :plugins="calendar_plugins"
          nowIndicator="true"
          :calendarWeekends="calendarWeekends"
          weekNumbers="true"
          minTime="07:00:00"
          maxTime="18:00:00"
          :weekends="calendarWeekends"
          :allDaySlot="allDaySlot"
          resourceLabelText="Lists"
          @dateClick="handleDateClick"
          @eventDrop="event_drop"
          @eventRender="event_render"
          :resources="calendar_resources"
          :events="calendar_events"
          :businessHours="business_hours"
          :header="header"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { editor_store } from "store/modules/editor";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";

import { JobProposalMixin } from "mixins/JobProposalMixin.js";
import interactionPlugin from "@fullcalendar/interaction";

import { debuglog } from "util";

export default {
  name: "TasksCalendar",
  components: {
    FullCalendar, // make the <FullCalendar> tag available
  },
  store: editor_store,
  mixins: [JobProposalMixin],
  data() {
    return {
      loading: false,
      time_line_custom: {},
      allDaySlot: true,
      visible_range: {
        start: "2019-07-29",
        end: "2019-09-01",
      },
      calendarWeekends: false,
      suggested_results: [],
      calendar_resources: [],
      calendar_selected_group: "Lists",
      calendar_selected_view: "resourceTimelineWeek",
      calendar_plugins: [
        dayGridPlugin,
        resourceTimelinePlugin,
        resourceTimeGridPlugin,
        interactionPlugin,
      ],
      business_hours: {
        daysOfWeek: [1, 2, 3, 4, 5], // Monday - Friday
        startTime: "08:00:00", // a start time (10am in this example)
        endTime: "17:00:00", // an end time (6pm in this example)
      },
      header: {
        center: "title",
        left: "prev,today,next",
        right:
          "dayGridMonth,resourceTimelineWeek,resourceTimelineMonth,resourceTimeGridDay,resourceTimeGridWeek",
      },
      calendar_events: [],
      calendar_views: {
        timeGridFourDay: {
          type: "timeGrid",
          duration: { days: 4 },
          buttonText: "4 day",
        },
      },
      calendar_config: {
        eventDragStop: function(event, jsEvent, ui, view) {},
      },
    };
  },
  mounted() {
    this.fetch_data();
  },
  computed: {
    ...mapGetters(["proposal"]),
  },
  methods: {
    fetch_data() {
      this.loading = true;
      this.$http
        .get(`/proposal_tasks_calendar_view/${this.proposal.id}`)
        .then((response) => {
          this.loading = false;
          this.suggested_results = response.data.result;
          this.update_view();
        });
    },
    event_drop(event, delta, revertFunc) {
      // is_date_locked
      //update custom start date
      let item = this.calendar_events.filter(
        (item) => item.id == event.event.id
      )[0];
      if (item != undefined) {
        item.start = event.event.start;
        item.end = event.event.end;
      }
    },
    update_jp_params(id, params) {
      this.loading = true;
      let results = this.update_job_proposal_params(id, params).then(
        (response) => {
          // get suggested plan after a task was locked or unlocked
          this.get_suggested_plan();
        },
        (reason) => {
          console.log(reason);
        }
      );
    },
    event_render(event, element) {
      event.el.setAttribute("title", event.event.title);

      if (event.event.extendedProps.eventType == "NewTask") {
        event.el.classList.add("event_new_task");
        let _event = event;
        let btn = document.createElement("el-button");
        let icon = document.createElement("i");
        btn.setAttribute("style", "font-size: 18px");
        if (event.event.startEditable == true)
          icon.setAttribute("class", "el-icon-unlock");
        else icon.setAttribute("class", "el-icon-lock");

        icon.setAttribute("task-id", event.event.id);

        btn.append(icon);
        btn.addEventListener("click", (ev) => {
          let task_id = ev.target.getAttribute("task-id");
          let locked = ev.target.classList.contains("el-icon-lock");
          this.toggle_task_lock(task_id, !locked);
          if (locked) {
            ev.target.classList.add("el-icon-unlock");
            ev.target.classList.remove("el-icon-lock");
          } else {
            ev.target.classList.add("el-icon-lock");
            ev.target.classList.remove("el-icon-unlock");
          }
        });
        event.el.prepend(btn);
      }
    },

    toggle_task_lock(id, value) {
      let item = this.calendar_events.filter((item) => item.id == id)[0];
      if (item != undefined) {
        item.locked = value;
        item.startEditable = !value;

        if (value == true)
          this.update_jp_params(id, {
            custom_start_date: item.start,
            time_frame_start: item.start,
            time_frame_end: item.end,
            is_date_locked: value,
            crm_user_id: item.user_id,
          });
        else
          this.update_jp_params(id, {
            custom_start_date: "",
            time_frame_start: "",
            time_frame_end: "",
            is_date_locked: false,
            crm_user_id: "",
          });
      }
    },
    update_calendar_view() {
      //resourceTimelineMonth,dayGridMonth
      //     switch (this.calendar_selected_group) {
      //   case "Lists":
      //     this.calendar_selected_view = "resourceTimelineWeek";
      //     break;
      //   case "Tasks":
      //     this.calendar_selected_view = "dayGridMonth";
      //     break;
      // }
      this.update_view();
    },
    reassign_tasks() {
      this.get_suggested_plan();
    },
    get_suggested_plan() {
      this.loading = true;
      let results = this.get_suggested_schedule(this.proposal.id).then(
        (response) => {
          this.loading = false;
          if (response.data.status == 0) {
            this.$message({
              type: "warning",
              dangerouslyUseHTMLString: true,
              message: `Cannot schedule task because of below error(s): <br> ${response.data.errors}`,
            });
          }
          this.suggested_results = response.data.result;
          this.update_view();
          this.$emit("load_job_proposals_list");
          this.check_preferred_date();
        },
        (reason) => {
          console.log(reason);
          this.loading = false;
        }
      );
    },
    check_preferred_date() {
      if (
        this.proposal.preferred_date != null &&
        new Date(this.proposal.preferred_date) > new Date(this.proposal.dated)
      )
        this.$message({
          type: "warning",
          message: "Preferred date exceed deadline !",
        });
    },
    update_view() {
      let _this = this;
      this.calendar_resources = [];
      this.calendar_events = [];
      let resource_id_col = "list_id";

      this.suggested_results.lists.forEach((element, id) => {
        if (_this.calendar_selected_group == "Lists") {
          _this.calendar_events.push({
            title:
              element.name +
              (element.tasks != undefined
                ? "\n" +
                  element.tasks.map((task) => {
                    return task.name;
                  })
                : ""),
            start: element.start_date,
            end: element.end_date,
            resourceId: element.resources.list_id,
            startEditable: false,
            durationEditable: false,
            eventType: "List",
            classNames: element.class_names,
          });
        } else {
          if (this.calendar_selected_group == "Users") {
            resource_id_col = "user_id";
          } else if (this.calendar_selected_group == "Assets") {
            resource_id_col = "asset_id";
          }

          element.tasks.forEach((el, idx) => {
            // el.resources["user_id"].forEach((user, id) => {
            let show_slot_label =
              el.assigned_slots.length > 1 &&
              el.resources["user_id"].length == 1;
            el.assigned_slots.forEach((slot, slot_idx) => {
              this.calendar_events.push({
                id: el.id,
                title:
                  el.name +
                  (show_slot_label == true ? ` [Slot #${slot_idx + 1}]` : ""),
                start: slot.start_date, // el.start_time,
                end: slot.end_date,
                resourceId: slot.user_id, // user,
                eventType: "NewTask",
                user_id: slot.user_id, // user,
                startEditable: !el.locked,
                durationEditable: false,
                locked: el.locked,
                classNames: el.class_names,
              });
              // });
            });
          });
        }
      });

      if (this.calendar_selected_group == "Jobs") {
        resource_id_col = "proposal_id";
        this.suggested_results.jobs.forEach((element, id) => {
          _this.calendar_events.push({
            title: element.name,
            start: element.start_time,
            end: element.end_time,
            resourceId: element.resources.proposal_id,
            startEditable: false,
            durationEditable: false,
            eventType: "Job",
            classNames: element.class_names,
          });
        });
      } else
        this.suggested_results.tasks.forEach((el, id) => {
          if (this.calendar_selected_group == "Users") {
            resource_id_col = "user_id";
          } else if (this.calendar_selected_group == "Assets") {
            resource_id_col = "asset_id";
          }
          this.calendar_events.push({
            id: el.id,
            title: el.name,
            start: el.start_time,
            end: el.end_time,
            resourceId: el.resources[resource_id_col],
            startEditable: false,
            durationEditable: false,
            eventType: "CurrentTask",
            classNames: el.class_names,
          });
        });

      // // loop through users
      // let temp_resource_id =
      // this.suggested_results.users_slots.forEach((el, idx) => {
      //   el.assigned_tasks.forEach((element, id) => {
      //     this.calendar_events.push({
      //       title: element.name,
      //       start: element.start_date,
      //       end: element.end_date,
      //       resourceId: 0,
      //       eventType: "List",
      //       classNames: element.class_names
      //     });
      //   });
      // });
      //switch
      if (
        _this.calendar_selected_group == "Lists" ||
        _this.calendar_selected_group == "Tasks"
      )
        this.calendar_resources = this.suggested_results.resources.lists;
      else if (_this.calendar_selected_group == "Assets")
        this.calendar_resources = this.suggested_results.resources.lists;
      else if (_this.calendar_selected_group == "Users")
        this.calendar_resources = this.suggested_results.resources.users;
      else if (_this.calendar_selected_group == "Jobs")
        this.calendar_resources = this.suggested_results.resources.jobs;
    },
    handleDateClick(arg) {
      alert(arg.date);
    },
  },
  watch: {},
};
</script>

<style>
.fc-event:hover {
  min-height: 40px !important;
  z-index: 1000 !important;
  box-shadow: 2px 1px 1px grey !important;
}

.event_new_task {
  background-color: cornflowerblue !important;
  color: #dac179 !important;
}

.event_list {
  background-color: mediumpurple !important;
}

.existing_task {
  background-color: indianred;
}

.jobs_lists {
  background-color: mediumpurple;
}
</style>
