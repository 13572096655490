import Money from 'components/shared/Money';

const SalesMixin = {
  components: {
    Money
  },
  props: ['items'],
  methods: {
    quote_style(quote_type) {
      if (quote_type == 'fixed quote'.toUpperCase())
        return 'orange';
      else if(quote_type == 'billable'.toUpperCase())
        return 'purple';
      else if(quote_type == 'free of charge'.toUpperCase())
        return '#E57373';
      else
        return 'grey';
    },
    mark_deleted(mark_as_deleted) {
      if (mark_as_deleted == 1)
        return 'mark_as_deleted';
    },
  },
  computed: {
    sum_total_sales_price() {
      return this.items.reduce((acc, item) =>
        item.mark_as_deleted == 0 ? acc + item.total_sales_price : acc, 0);
    },
    sum_total_sales_cost() {
      return this.items.reduce((acc, item) =>
        item.mark_as_deleted == 0 ? acc + item.total_sales_cost : acc, 0);
    },
    sum_net_profit() {
      return this.items.reduce((acc, item) =>
        item.mark_as_deleted == 0 ? acc + item.net_profit : acc, 0);
    },
    sum_quote_price() {
      return this.items.reduce((acc, item) =>
        item.mark_as_deleted == 0 ? acc + item.quote_price : acc, 0);
    }
  }
};

export default SalesMixin;
