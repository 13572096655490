import Vue from 'vue';
import Vuex from 'vuex';
import {
  Message
} from 'element-ui';

Vue.use(Vuex);

export const tender_store = new Vuex.Store({
  strict: false,
  state: {
    details: {
      id: '',
      name: '',
      street: '',
      postcode: null,
      city: '',
      country: '',
      phone: null,
      email: '',
      contact_name: '',
      gst_number: '',
      bank_acc_no: '',
      payment_code: '',
      payment_reference: '',
      first_login: null
    }       
  },
  getters: {
    details: state => {
      return state.details;
    }
  },
  mutations: {
    SET_DETAILS(state, payload) {
      state.details = payload;
    },
    SET_FIRST_LOGIN(state){
      state.details.first_login = 1;
    }
  },
  actions: {
    get_details({
      commit
    }) {
      return new Promise((resolve, reject) => {
        Vue.http.get('/tender/details').then(
          response => {
            resolve((this.details = response.data.details));
            commit('SET_DETAILS', response.body.details);
          },
          function (response) {
            Message({
              message: response.data.errors,
              type: 'error'
            });
            reject(response);
          }
        );
      });
    },
    update({commit}) {
      return new Promise((resolve, reject) => {
               
        let params = {
          tender: this.details
        };
        Vue.http.patch('/update_tender', params).then(
          response => {
            Message({
              message: 'Tender updated.',
              type: 'success'
            });
            commit('SET_FIRST_LOGIN');
            resolve(resolve);
          },
          function(response) {
            Message({
              message: response.data.errors,
              type: 'error'
            });
            reject(response);
          }
        );
      });
    },
  }
});