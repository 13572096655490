<template>
  <div
    v-if="student_loan.tax_type"
    id="student-loan"
  >
    <p class="headline">
      Deduction: <strong><Money :amount="student_loan.deduction" /></strong><br>
      Calculated: <Money :amount="student_loan.calculated" /><br>
      Extra Payment: <Money :amount="student_loan.extra_payment" />
    </p>
  </div>
</template>
<script>
import Money from '../../shared/Money';
import {pay_run_student_loan} from '../../../api/pay_runs';

export default {
  name: 'StudentLoan',
  components: {Money},
  props: {
    crm_user_id: {
      type: String,
      required: true
    },
    pay_period_start: {
      type: String,
      required: true
    },
    pay_period_end: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      student_loan:  {deduction: 0, tax_type: true, calculated: 0, extra_payment: 0}
    };
  },
  created(){
    this.$root.$on('gross_payment_total', data => {
      this.pay_run_student_loan(data);
    });
  },
  methods: {
    pay_run_student_loan(gross_payment_total) {
      if (this.student_loan.deduction === 0) {
        pay_run_student_loan(this, this.crm_user_id, this.pay_period_start, this.pay_period_end, gross_payment_total).then((response) => {
          this.student_loan = response.data;
          this.$emit('calculate_sl_pay_run', `-${this.student_loan.deduction}`);
        });
      }
    }
  }
};
</script>
<style scoped>
.headline {
  padding: 10px;
  color: white;
  background: #409EFF;
}
</style>
