<template>
  <div id="gross_payments">
    <p class="headline">
      Gross Payment:   <Money :amount="payments.total" />
    </p>
    <el-collapse>
      <el-collapse-item title="Gross Payment Details">
        <salary
          v-if="$parent.earnings.employment_pay_type === 'Salary fixed amount'"
          :crm_user_id="crm_user_id"
          :pay_period_start="pay_period_start"
          :pay_period_end="pay_period_end"
        />
        <wages
          v-if="$parent.earnings.employment_pay_type === 'Hourly wage rate'"
          :crm_user_id="crm_user_id"
          :pay_period_start="pay_period_start"
          :pay_period_end="pay_period_end"
        />
        <ul>
          <li
            v-for="(payment,index) in payments_list"
            :key="index"
            class="payment-list"
          >
            <ul>
              <strong>{{ index.split('_').join(' ').toUpperCase() }}</strong>
              <li
                v-for="(value, key) in payment"
                :key="key"
              >
                <span class="payment-span">{{ key.split('_').join(' ') }}:</span>
                <span><Money :amount="value" /></span>
              </li>
            </ul>
          </li>
        </ul>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
import{gross_payments} from '../../../api/employment_gross_payments';
import Money from '../../shared/Money';
import Salary from './Salary';
import Wages from './Wages';
export default {
  name: 'GrossPayments',
  components: {Money, Salary, Wages },
  props: {
    crm_user_id: {
      type: String,
      required: true
    },
    pay_period_start: {
      type: String,
      required: true
    },
    pay_period_end: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      payments: []
    };
  },
  computed: {
    payments_list() {
      delete this.payments.total;
      return this.payments;
    }
  },
  created() {
    this.gross_payments();
  },
  methods: {
    gross_payments(){
      gross_payments(this, this.crm_user_id, this.pay_period_start, this.pay_period_end).then((response)=> {
        this.payments = response.data;
        this.$root.$emit('gross_payment_total', this.payments.total);
      });
    },
  }
};
</script>
<style scoped>
#gross_payments /deep/ .el-divider--horizontal {
  margin: 0 0 10px 0;
  clear:both;
}
.payment-list{
  float:left;
  padding: 35px;
}
.payment-span{
  width: 130px;
  float:left
}
.headline {
  padding: 10px;
  color: white;
  background: #409EFF;
}

</style>
