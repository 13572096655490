<template>
  <el-row>
    <el-col
      :xs="24"
      :span="5"
    >
      <b>My KPIs</b>
    </el-col>
    <el-col
      :xs="24"
      :span="19"
    >
      <el-date-picker
        v-model="selected_date_range"
        type="daterange"
        align="right"
        class="width--auto"
        unlink-panels
        range-separator="To"
        start-placeholder="Start date"
        end-placeholder="End date"
        :picker-options="picker_options"
        @change="onChangeDateRange"
      />

      <el-select
        v-if="is_manager"
        v-model="selected_user"
        class="user_list"
        placeholder="Select"
        @change="onChangeUser"
      >
        <el-option
          v-for="user in user_list"
          :key="user.id"
          :label="user.name"
          :value="user.id"
        />
      </el-select>
    </el-col>
  </el-row>
</template>

<script>
import {loadMyKPIs} from '../../api/my_kpi';

export default {
  name: 'Header',
  props: {
    user_id: {
      type: Number,
      required: true
    },
    user_list: {
      type: Array,
      required: true
    },
    is_manager: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      selected_user: null,
      selected_date_range: '',
      picker_options: {
        shortcuts: [
          {
            text: 'Current month',
            onClick(picker) {
              const end = new Date();
              const start = new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                1
              );
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last week',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last month',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last 3 months',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last 6 months',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last year',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
    };
  },
  computed: {
    start_date() {
      let current_date = new Date();
      return new Date(
        current_date.getFullYear(),
        current_date.getMonth(),
        1
      );
    },
    end_date() {
      let current_date = new Date();
      return new Date(
        current_date.getFullYear(),
        current_date.getMonth(),
        current_date.getDate()
      );
    },
  },
  created() {
    this.set_default_input();
    this.selected_user = this.user_id;
    this.selected_date_range = [this.start_date, this.end_date];
    this.request_my_kpis();
  },
  mounted() {
    this.set_default_input();
  },
  methods: {
    onChangeDateRange() {
      this.request_my_kpis();
    },
    onChangeUser() {
      this.request_my_kpis();
      this.$emit('changeUser', this.selected_user);
    },
    request_my_kpis() {
      loadMyKPIs(
        this,this.selected_date_range[0].toString(),
        this.selected_date_range[1].toString(),
        this.selected_user
      ).then(({data})=>{ this.$emit('input', data); });
    },
    set_default_input() {
      Array.from(document.querySelectorAll('.el-input__inner')).forEach(
        function(e) {
          e.classList.add('browser-default');
          if (e.querySelector('input') != null)
            e.querySelector('input').className += ' browser-default';
        }
      );
    }
  }
};
</script>


<style lang="scss" scoped>
.user_list {
   margin-left: 5px;
}
</style>
