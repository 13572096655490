<template>
  <div id="layers_panel">
    <div style="text-align: center;">
      <span v-show="working_file_id > 0" :disabled="working_file_id == '' || working_file_id == undefined">
        <el-tooltip content="Select all stock/task layers" placement="top">
          <el-checkbox v-model="check_all_layers" />
        </el-tooltip>
      </span>
      <span>
        <el-dropdown v-show="working_file_id > 0" :disabled="working_file_id == '' || working_file_id == undefined"
          @command="handleCommand">
          <span class="el-dropdown-link">
            Add Layer
            <i class="el-icon-arrow-down el-icon--right" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="stock"><i class="el-icon-box" />Stock Layer</el-dropdown-item>
            <el-dropdown-item command="job"><i class="el-icon-s-order" />Task layer</el-dropdown-item>
            <el-dropdown-item command="other"><i class="el-icon-document-copy" />Other
              layer</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown v-show="working_file_id > 0" :disabled="working_file_id == '' || working_file_id == undefined"
          @command="handle_tools_clicked">
          <span class="el-dropdown-link">
            Tools
            <i class="el-icon-arrow-down el-icon--right" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="draw_stock_layer"><i class="el-icon-box" />Draw a Stock Layer</el-dropdown-item>
            <el-dropdown-item command="draw_job_layer"><i class="el-icon-s-order" />Draw a Task
              layer</el-dropdown-item>
            <el-dropdown-item command="custom_area_nesting" divided><i class="el-icon-s-grid" />Custom area
              Nesting</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <el-input v-show="working_file_id > 0" v-model="working_file_qty"
          :value="working_file != undefined ? working_file.qty : 1" size="mini" style="width: 120px"
          :disabled="working_file_id == '' || working_file_id == undefined">
          <el-button slot="append" icon="el-icon-finished" @click="update_working_file_qty" />
        </el-input>
        <!-- <el-button @click="layers_nesting_dialog = true" size="mini">
          Nesting
        </el-button> -->
      </span>
    </div>
    <div>
      <el-tree :data="layers_tree" node-key="id" default-expand-all :expand-on-click-node="false"
        :allow-drag="allowDrag">
        <LayerAttributes slot-scope="{ node, data }" :layers_tree="layers_tree" :layer_props="data" :node="node"
          @renest_layer="renest_layer" />
      </el-tree>
    </div>

    <el-dialog title="Stock/Task Layer" :visible.sync="proposal_item_layer_dialog" :close-on-click-modal="false"
      class="proposal_item_layer_dialog">
      <a v-show="selected_proposal_item_type == 'stock'" href="/stock_items/new"
        class="right el-button el-button--default el-button--mini" target="_blank" icon="el-icon-search">
        <i class="el-icon-box" />
        New Stock Item</a>
      <el-input v-model.number="proposal_item_specs.shape_qty" class="right" style="width: 160px; margin: 0 10px;"
        size="mini">
        <template slot="prepend">
          Quantity
        </template>
      </el-input>
      <br>
      <br>
      <el-button v-show="selected_proposal_item_type == 'stock'" class="right" type="primary" size="mini"
        @click="add_stock_item">
        {{
          existing_nesting == true ? "Update Stock layer" : "Add Stock layer"
        }}
      </el-button>
      <el-button v-show="selected_proposal_item_type == 'job'" class="right" type="primary" size="mini"
        @click="save_job">
        {{
          existing_nesting == true ? "Update Job layer" : "Add Job layer"
        }}
      </el-button>
      <div class="row">
        <div class="col m4 s6">
          <label>
            <b>Layer name:</b>
          </label>
          <el-input v-model="proposal_item_layer_name" placeholder="Enter layer name" size="mini"
            style="max-width: 200px" />
        </div>
        <div class="col m4 s6">
          <b>Type:</b>
          <el-radio v-model="selected_proposal_item_type" label="stock" :disabled="existing_nesting"
            @change="set_inputs_to_default">
            Stock
          </el-radio>
          <el-radio v-model="selected_proposal_item_type" label="job" :disabled="existing_nesting"
            @change="set_inputs_to_default">
            Task
          </el-radio>
        </div>
        <div class="col m4 s6">
          <b>Working area(s) count:</b>
          {{ selected_objects_count }}
        </div>
      </div>
      <el-form v-show="selected_proposal_item_type == 'stock'" ref="new_stock_form" label-position="top">
        <el-form ref="proposal_item_form" label-position="top" :model="proposal_stock_item">
          <div class="row">
            <div class="col m2 s6">
              <el-checkbox v-model="by_category">
                By category
              </el-checkbox>
            </div>
            <div class="col m3 s6">
              <el-select v-model="selected_category_id" size="mini" placeholder="Select"
                :disabled="by_category == false" filterable>
                <el-option v-for="item in stock_categories_list" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
            </div>
            <div class="col m7 s12">
              <ProposalItemSpec :swap_item_specs="swap_item_specs" :selected_linear_spec="selected_linear_spec"
                :proposal_item_specs="proposal_item_specs" />
            </div>
          </div>
          <div class="row">
            <div class="col s2">
              <el-form-item prop="quantity" required>
                <label>
                  <b>Quantity</b>
                  <!-- <small v-show="proposal_item_specs.include_speed_rate == true"
                    >(minutes)</small
                  > -->
                </label>
                <el-input v-model.number="proposal_stock_item.quantity" size="mini" :min="0.001" />
              </el-form-item>
            </div>
            <div class="col s4">
              <el-form-item prop="stock_item_id" required>
                <label>
                  <b>Stock item</b>
                </label>

                <el-select v-model="proposal_stock_item.stock_item_id" filterable remote style="width:100%"
                  reserve-keyword placeholder="Please enter a keyword" :remote-method="remoteMethod" :loading="loading"
                  size="mini" @change="selected_stock_change">
                  <el-option v-for="item in stock_items_results" :key="item.id" style="min-width: 300px"
                    :label="item.name_with_specs" :value="item.id" />
                </el-select>
              </el-form-item>
              <el-tooltip placement="bottom"
                content="By default, only stocks purchased in last 3 years are filtered, to filter all stocks, tick this checkbox.">
                <el-checkbox v-model="show_all_stocks">
                  Search all stocks
                </el-checkbox>
              </el-tooltip>
              <br>
              <a v-show="!(
                proposal_stock_item.stock_item_id == undefined ||
                proposal_stock_item.stock_item_id == ''
              )
                " :href="`stock_items/${proposal_stock_item.stock_item_id}/edit`" target="_blank">
                <i class="el-icon-edit" />
                {{ proposal_stock_item.internal_name }}
                <small>{{ proposal_stock_item.specs }}</small>
              </a>
            </div>
            <div class="col s2">
              <el-form-item prop="sale_price" required>
                <label>
                  <b>Sale price</b>
                </label>
                <el-input-number v-model="proposal_stock_item.sale_price" style="width: 100px" size="mini"
                  :min="min_sale_price" />
              </el-form-item>
            </div>
            <div class="col s2">
              <el-form-item prop="waste_percent" required>
                <label>
                  <b>waste (%)</b>
                </label>
                <el-input-number v-model="proposal_stock_item.waste_percent" style="width: 100px" size="mini"
                  :min="0" />
              </el-form-item>
            </div>
            <div class="col s2">
              <el-form-item prop="total_price">
                <label>
                  <b>Total price</b>
                </label>
                <el-input style="width: 100px" size="mini" disabled :value="total_price_calculated" />
              </el-form-item>
            </div>
          </div>
          <div class="row">
            <div class="col s4">
              <el-form-item prop="quantity" required>
                <b>
                  Quantity
                  <small>(</small>
                  <small v-show="proposal_item_specs.include_speed_rate == true">speed rate +
                  </small>
                  <small>incl. waste %)</small>
                </b>
                {{ quantity_incl_waste() }}
              </el-form-item>
            </div>
          </div>
        </el-form>
      </el-form>
      <el-form v-show="selected_proposal_item_type == 'job'" ref="new_job_form" label-position="top"
        :model="job_proposal">
        <div>
          <div class="row">
            <div class="col s5">
              <div class="col s12">
                <el-form-item>
                  <label>
                    <b>Job</b>
                  </label>
                  <el-select v-model="job_proposal.job_id" style="width: 220px" filterable reserve-keyword
                    placeholder="Please select a job" :loading="loading" size="mini" @change="selected_job_change">
                    <el-option v-for="item in jobs_list" :key="item.id" :label="item.name" :value="item.id" />
                  </el-select>
                </el-form-item>
              </div>
              <div class="col s6">
                <el-form-item style="line-height: unset;">
                  <label> <b>Time allocated</b> <small v-if="!is_hours">(min.)</small> </label>
                  <HoursInput v-if="is_hours" v-model="job_proposal.time_allocated_labour" />
                  <el-input v-else v-model.number="job_proposal.time_allocated_labour" size="mini" />
                </el-form-item>
              </div>
              <div class="col s6">
                <el-form-item style="line-height: unset;">
                  <label> <b>Sale price</b><small>($ per min.)</small> </label>
                  <el-input v-model="sale_price" size="mini" />
                </el-form-item>
              </div>
            </div>
            <div class="col s7">
              <ProposalItemSpec :swap_item_specs="swap_item_specs" :selected_linear_spec="selected_linear_spec"
                :proposal_item_specs="proposal_item_specs" />
            </div>
          </div>
        </div>
      </el-form>
      <div class="row">
        <div>
          <el-radio v-model="drawing_settings" label="none">
            None
          </el-radio>
        </div>
        <div>
          <el-radio v-model="drawing_settings" label="linear">
            Linear
          </el-radio>
          <div v-show="drawing_settings == 'linear'">
            <div class="row">
              <div class="col m4">
                <label>
                  <b>Spacing:</b>
                </label>
                <el-input v-model="linear_spacing" style="width: 130px" size="mini">
                  <el-button slot="append" icon="el-icon-refresh" @click="update_linear_spacing" />
                </el-input>
              </div>
              <div class="col m4">
                <label>
                  <b>Length (Working area):</b>
                  {{ (linear_length/1000).toFixed(3) }} <small>m</small><br>                 
                  <b> Quantity: </b>
                  {{ proposal_item_specs.shape_qty }}<br>

                  <b> Total: </b>
                  {{
                    ((linear_length * proposal_item_specs.shape_qty) / 1000).toFixed(3)
                  }}
                  <small>m</small>
                </label>
              </div>
              <div class="col m4">
                <label>
                  <b>Quantity needed</b><br>
                  <div v-html="quantity_needed_m"></div>
                </label>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col m12">
                <ul>
                  <li v-for="item in calculated_linear_items" v-bind:key="item.id">
                    {{item.title}}
                    <small></small>
                  </li>
                </ul>
              </div>
            </div>-->
          </div>
        </div>
        <div>
          <el-radio v-model="drawing_settings" label="cubic_square">
            Area M2/M3
          </el-radio>
          <div v-show="drawing_settings == 'cubic_square'" class="row">
            <div class="col m3 s6">
              <label>
                <b>Depth:</b>
              </label>
              <el-input v-model="selected_object_depth" style="width: 130px" size="mini">
                <el-button slot="append" icon="el-icon-refresh" @click="calculate_quantity_by_area" />
              </el-input>
            </div>
            <div class="col m7 s12">
              <label>
                <b>Summary:</b>
                <div v-html="cubic_summary"></div>
                <small />
              </label>
            </div>
            <div class="col m2 s6">
              <label>
                <b>Quantity needed</b><br>
                <div v-html="quantity_needed_m"></div>
              </label>
            </div>
          </div>
        </div>
        <div>
          <el-radio v-model="drawing_settings" label="nesting">
            Nesting
          </el-radio>
          <NestingCalculator v-show="drawing_settings == 'nesting'" ref="nesting_calculator_cmp"
            :item_specs="proposal_item_specs" :nesting_options="nesting_options"
            @update_nesting_details="update_nesting_details" @update_nesting_options="update_nesting_options" />
        </div>
      </div>
      <div class="row">
        <div class="col s6">
          <el-button class="left" @click="proposal_item_layer_dialog = false">
            Cancel
          </el-button>
        </div>
        <div class="col s6">
          <el-button v-show="selected_proposal_item_type == 'stock'" class="right" type="primary" size="small"
            @click="add_stock_item">
            {{
              existing_nesting == true
                ? "Update Stock layer"
                : "Add Stock layer"
            }}
          </el-button>
          <el-button v-show="selected_proposal_item_type == 'job'" class="right" type="primary" size="small"
            @click="save_job">
            {{
              existing_nesting == true ? "Update Job layer" : "Add Job layer"
            }}
          </el-button>
        </div>
      </div>
      <el-dialog title="Stock attached tasks" :visible.sync="related_stock_dialog" append-to-body>
        <StockRelateTasksList :jobs_list="stock_related_tasks"
          @hide_stock_related_dialog="related_stock_dialog = false" />
      </el-dialog>
    </el-dialog>
    <el-dialog title="Layers nesting" :visible.sync="layers_nesting_dialog">
      <span>
        <LayersNesting :stock_items_layers="stock_items_layers" />
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="layers_nesting_dialog = false">Cancel</el-button>
        <el-button type="primary" @click="layers_nesting_dialog = false">Confirm</el-button>
      </span>
    </el-dialog>
    <!-- <el-dialog
      title="Custom area Nesting"
      :visible.sync="custom_area_nesting_dialog"
    >
      <el-checkbox v-model="chk_all">Check all</el-checkbox>
      <div style="margin: 15px 0;"></div>

      <el-checkbox-group v-model="checked_item_layers_ids">
        <el-checkbox
          v-for="layer in stocks_tasks_layers"
          :label="layer.id"
          :key="layer.id"
        >
          <i v-if="layer.type == 'stock'" class="el-icon-box"></i>
          <i v-else class="el-icon-s-order"></i>
          {{ layer.type }} {{ layer.name }}</el-checkbox
        >
      </el-checkbox-group>
      <el-button type="primary" @click="custom_area_nesting">Renest</el-button>
    </el-dialog> -->
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { editor_store } from 'store/modules/editor';
import LayerAttributes from './LayerAttributes.vue';
import LayersNesting from './calculator/LayersNesting.vue';
import NestingCalculator from './calculator/NestingCalculator.vue';
import ProposalItemSpec from './calculator/ProposalItemSpec.vue';
import StockRelateTasksList from 'components/stock_items/StockRelateTasksList';
import { JobProposalMixin } from 'mixins/JobProposalMixin.js';
import { WorkspaceMixin } from 'mixins/WorkspaceMixin.js';
import { ProposalStockItemMixin } from 'mixins/ProposalStockItemMixin.js';
import HoursInput from '../shared/HoursInput';

export const mm_to_px = 3.779527559;
export const mm_to_m2 = Math.pow(10, -6);
export const mm_to_m3 = Math.pow(10, -9);

export default {
  name: 'Layers',
  store: editor_store,
  components: {
    HoursInput,
    LayerAttributes,
    NestingCalculator,
    ProposalItemSpec,
    StockRelateTasksList,
    LayersNesting,
  },
  mixins: [JobProposalMixin, ProposalStockItemMixin, WorkspaceMixin],
  props: [
    'attach_events',
    'save_svg',
    'update_nesting',
    'update_nested_layers',
  ],
  data() {
    return {
      show_all_stocks: false,
      chk_all: false,
      check_all_layers: false,
      working_file_qty: 0,
      checked_item_layers_ids: [],
      //stocks_tasks_layers: [],
      custom_area_nesting_dialog: false,
      selected_layer: '',
      sale_price: 0,
      existing_nesting: false,
      other_layers: [],
      stock_items_layers: [],
      selected_objects_count: 0,
      stock_related_tasks: [],
      related_stock_dialog: false,
      layers_nesting_dialog: false,
      selected_proposal_item_type: 'stock',
      proposal_item_layer_name: '',
      svg_inner_container: '',
      loading: false,
      layer_mode: '',
      selected_linear_spec: 'Width',
      linear_spacing: 0,
      linear_length: 0,
      quantity_needed: 0,
      quantity_needed_m: 0,
      cubic_summary: '',
      cubic_specs_details: '',
      selected_object_depth: 0,
      calculated_linear_items: [],
      by_category: false,
      layer_counter: 1,
      min_sale_price: 1,
      drawing_settings: 'none',
      custom_x: 0,
      custom_y: 0,
      nesting_details: '',
      linear_items_arr: [],
      proposal_stock_item: {
        stock_item_id: '',
        quantity: 1,
        unit_price: 0,
        total_price: 0,
        percent_applied: 0,
        working_file_id: 0,
        width: 0,
        height: 0,
      },
      proposal_item_specs: {
        shape_qty: 1,
        width: 0,
        height: 0,
        depth: 0,
        include_speed_rate: false,
        speed_rate: 0,
      },
      job_proposal: {
        id: '',
        job_id: '',
        time_allocated_labour: 0,
        sale_price: 0,
      },
      // sale_price: "",

      selected_category_id: '',
      stock_categories_list: [],
      stock_items_results: [],
      proposal_item_layer_dialog: false,
      filterText: '',
      defaultProps: {
        children: 'guys',
        label: 'name',
      },
      nesting_options: {
        nesting_alignment: 'center',
        custom_margin_horizontal: 0,
        custom_margin_vertical: 0,
      },
    };
  },
  watch: {
    check_all_layers: function (newVal) {
      this.layers_tree.forEach((el, idx) => {
        if (el.selected_proposal_item_type != null) el.checked = newVal;
      });
    },
    selected_proposal_item_type: function (newVal) {
      if (newVal == 'task') this.proposal_item_specs.include_speed_rate = true;
    },
    proposal_item_layer_dialog: function (newVal) {
      let _this = this;
      if (newVal == true) {
        if (this.selected_proposal_item_type == 'stock') {
          if (this.stock_categories_list.length == 0)
            this.load_stock_categories_list();
        }

        let linear_items = d3.selectAll('.svg_inner_container [data-selected]')
          ._groups[0];
        linear_items.forEach((el, idx) => {
          _this.get_element_id(el);
        });
        if (linear_items.length > 0)
          this.selected_objects_count = linear_items.length;
        else if (this.selected_object != null) this.selected_objects_count = 1;
        else this.selected_objects_count = 0;
      } else {
        this.selected_layer = '';
        this.clear_nesting_inputs();
      }
      setTimeout(() => {
        _this.set_inputs_to_default();
      }, 300);
    },
    drawing_settings: function (newVal) {
      let _this = this;
      if (newVal == 'nesting') {
        setTimeout(function () {
          _this.$refs.nesting_calculator_cmp.fill_nesting_items();
        }, 700);
      }
    },
    working_file_id: function (newVal) {
      if (newVal != '') {
        let _this = this;
        this.working_file_qty = this.working_file.qty;
        setTimeout(function () {
          _this.set_inputs_to_default();
        }, 300);
      }
    },
    layers_nesting_dialog: function (newVal) {
      this.other_layers = [];
      if (newVal == false) return;

      d3.selectAll('.svg_layer:not([layer-type])')
        .nodes()
        .forEach((el, idx) => {
          let relative_boundaries = this.get_relative_boundaries(el);
          this.other_layers.push({
            label: el.getAttribute('data-name'),
            el: el,
            width: relative_boundaries.width / (mm_to_px * this.page_scale),
            height: relative_boundaries.height / (mm_to_px * this.page_scale),
            qty:
              el.getAttribute('data-qty') == undefined
                ? 0
                : el.getAttribute('data-qty'),
            nested_stocks:
              el.getAttribute('nested-stocks') == undefined
                ? []
                : el.getAttribute('nested-stocks').split(','),
          });
        });
      this.stock_items_layers = [];

      d3.selectAll('[layer-type="stock"]')
        .nodes()
        .forEach((el, idx) => {
          let stock_id = el.getAttribute('proposal-stock-id');
          let stock = this.stock_items_list.filter(
            (item) => item.id == stock_id
          )[0];

          let nested_layers = [];
          this.other_layers.forEach((el, idx) => {
            let item = el.nested_stocks.filter(
              (c) => c.split(':')[0] == String(stock_id)
            )[0];

            let nested_layer = {
              label: el.label,
              checked: false,
              qty: 0,
              el: el.el,
              width: Math.round(el.width),
              height: Math.round(el.height),
            };

            if (item != undefined) {
              nested_layer.checked = true;
              nested_layer.qty = item.split(':')[1];
            }
            nested_layers.push(nested_layer);
          });

          this.stock_items_layers.push({
            label: el.getAttribute('data-name'),
            stock_id: stock_id,
            qty: el.getAttribute('stock-quantity'),
            shape_qty: el.getAttribute('shape-quantity') || 1,
            width: stock.width,
            height: stock.height,
            calculated_qty: el.getAttribute('stock-quantity'),
            direction: 'Left',
            specs: {},
            nested_layers: nested_layers,
          });
        });
    },
  },
  methods: {
    update_nesting_options(details) {
      this.nesting_options.custom_margin_horizontal =
        details.custom_margin_horizontal;
      this.nesting_options.custom_margin_vertical =
        details.custom_margin_vertical;
      this.nesting_options.nesting_alignment = details.nesting_alignment;
    },
    quantity_incl_waste() {
      let qty = this.proposal_stock_item.quantity;
      if (this.proposal_item_specs.include_speed_rate == true) {
        let speed_rate = 1;
        speed_rate = this.proposal_item_specs.speed_rate || 1;
        qty *= speed_rate;
      }
      return qty + (qty * this.proposal_stock_item.waste_percent || 0) / 100;
    },
    custom_area_nesting() {
      // Calculate checked stocks & tasks layers based on selected object
      let checked_layers = this.stocks_tasks_layers
        .filter((c) => c.checked == true)
        .map((c) => c);

      // let qty_txt = `Qty: ${specs.nesting_details.stock_specs.shape_qty || 1} , `;
      // specs.specs_details = qty_txt + specs.specs_details;
      if (checked_layers.length == 0) {
        this.$message({ type: 'warning', message: 'No checked layer(s)!' });
        return;
      }

      let selected_objects =
        this.selected_object == null || this.selected_object == undefined
          ? d3.selectAll('[data-selected=true]').nodes()
          : [this.selected_object];

      if (selected_objects.length == 0) {
        this.$message({ type: 'warning', message: 'No selected object !' });
        return;
      }
      let layers_arr = [];
      checked_layers.forEach((layer) => {
        let result = this.dynamic_update_multiple(layer, selected_objects);
        layers_arr.push(result);
        let working_file_details = result.working_file_details;

        this.add_layer_details(
          d3.select(layer.selected_object),
          working_file_details
        );
        this.attach_events(layer.selected_object);
        // layer['type'] = layer.selected_proposal_item_type;
        // layer['id'] =
      });

      let updated_stocks = layers_arr.filter((c) => c.type == 'stock').map((c) => {
        return {
          id: c['id'],
          type: c['type'],
          qty: c['quantity'],
          specs_details: c['specs_details'],
          layer: c.layer_props.selected_object
        };
      });
      let updated_tasks = layers_arr.filter((c) => c.type == 'job').map((c) => {
        return {
          id: c['id'],
          type: c['type'],
          qty: c['quantity'],
          specs_details: c['specs_details'],
          layer: c.layer_props.selected_object
        };
      });
      this.update_nested_layers(updated_stocks, []);
      this.update_nested_layers(updated_tasks, []);

      this.load_layers_tree();
      return;

      // Update nesting for checked layers
      //     this.update_nesting(checked_layers.map((c) => c.selected_object));
      checked_layers.forEach((el, idx) => {
        let objs = [];
        selected_objects.forEach((selected_object, sel_idx) => {
          objs.push(this.get_element_id(selected_object));
        });
        el.selected_object.setAttribute('ref-ids', JSON.stringify(objs));
      });
      //this.$message({ type: "success", message: "Nested successfully." });
    },
    // custom_area_nesting() {
    //   // Calculate checked stocks & tasks layers based on selected object
    //   let checked_layers = this.stocks_tasks_layers.filter(
    //     (c) => c.checked == true
    //   );
    //   if (checked_layers.length == 0) {
    //     this.$message({ type: "warning", message: "No checked layer(s)!" });
    //     return;
    //   }

    //   if (this.selected_object == null || this.selected_object == undefined) {
    //     this.$message({ type: "warning", message: "No selected object !" });
    //     return;
    //   }

    //   let obj_id = this.get_element_id(this.selected_object);
    //   let _this = this;
    //   // Update nesting for checked layers
    //   this.update_nesting(checked_layers.map((c) => c.selected_object));
    //   checked_layers.forEach((el, idx) => {
    //     el.selected_object.setAttribute("ref-ids", JSON.stringify([obj_id]));
    //   });
    //   //this.$message({ type: "success", message: "Nested successfully." });
    // },
    clear_nesting_inputs() {
      this.drawing_settings = 'none';
      this.linear_spacing = 0;
      this.linear_length = 0;
      this.quantity_needed = 1;
      this.selected_object_depth = 0;
      this.proposal_item_layer_name = '';
      this.selected_proposal_item_type = 'stock';

      this.nesting_options = {
        nesting_alignment: 'left',
        custom_margin_horizontal: 0,
        custom_margin_vertical: 0,
        nesting_proposal_items: [],
      };

      this.proposal_stock_item = {
        stock_item_id: '',
        quantity: 1,
        unit_price: 0,
        total_price: 0,
        percent_applied: 0,
        working_file_id: 0,
        width: 0,
        height: 0,
      };

      this.proposal_item_specs = {
        shape_qty: 1,
        width: 0,
        height: 0,
        depth: 0,
        include_speed_rate: false,
        speed_rate: 0,
      };
      this.job_proposal = {
        id: '',
        job_id: '',
        time_allocated_labour: 0,
      };

      this.$refs['proposal_item_form'].resetFields();
      //this.$refs["new_stock_form"].resetFields();
      //this.$refs["new_job_form"].resetFields();
    },
    // main SVG:
    renest_layer(nesting_details, show_dialog = true) {
      this.proposal_item_layer_dialog = show_dialog;

      let _this = this;

      _this.existing_nesting = true;
      _this.selected_layer = nesting_details.layer_props.selected_object;
      this.set_selected_object(null);

      // Set selected objects based on layer work_area_details array

      // if nothing selected then get ref-ids and mark them as selected
      // else anything selected, then generate IDs and then send IDs as ref-ids

      if (
        d3.selectAll('[data-selected=true]').nodes().length == 0 &&
        this.selected_object == null
      ) {
        let refs_ids = eval(
          '(' +
          nesting_details.layer_props.selected_object.getAttribute(
            'ref-ids'
          ) +
          ')'
        );
        let selected_objects = refs_ids.map((el, idx) => {
          return `#${el}`;
        });

        _this.set_selected_object(null);
        d3.selectAll('[data-selected]')
          .attr('data-selected', null)
          .style('stroke-width', null);

        if (selected_objects.length > 0) {
          d3.selectAll(selected_objects.join(','))
            .attr('data-selected', true)
            .style('stroke-width', _this.stroke_width());
        }
      }

      // setTimeout(function() {
      _this.selected_proposal_item_type =
        nesting_details.layer_props.selected_proposal_item_type;
      _this.proposal_item_layer_name = nesting_details.layer_props.name;

      if (_this.selected_proposal_item_type == 'stock') {
        let id = nesting_details.layer_props.selected_object.getAttribute(
          'proposal-stock-id'
        );

        let prop_stock_item = _this.stock_items_list.filter(
          (c) => c.id == parseInt(id)
        )[0];

        if (prop_stock_item != undefined) {
          //this.proposal_stock_item = prop_stock_item;
          //Object.assign(this.proposal_stock_item,prop_stock_item);
          _this.remoteMethod(prop_stock_item.stock_name);

          Object.assign(_this.proposal_stock_item, prop_stock_item);
          _this.min_sale_price = parseFloat(prop_stock_item.sale_price || 0);
        }
      } else {
        let id = nesting_details.layer_props.selected_object.getAttribute(
          'job-id'
        );

        let prop_task = _this.job_proposals_list.filter(
          (c) => c.id == parseInt(id)
        )[0];

        if (prop_task != undefined) {
          //prop_task
          _this.job_proposal['job_id'] = prop_task['job_id'];
          let cloned_task = Object.assign({}, prop_task);
          delete cloned_task.jobs_id;
          Object.assign(_this.job_proposal, cloned_task);
          _this.sale_price = this.job_proposal.sale_price;
        }
      }

      _this.proposal_item_specs = nesting_details.stock_specs;
      // _this.proposal_item_specs.shape_qty = this.shape_qty;

      _this.drawing_settings = nesting_details.nesting_options.type;
      _this.linear_spacing = nesting_details.nesting_options.linear_spacing;

      _this.selected_object_depth = nesting_details.nesting_options.obj_depth;

      _this.nesting_options = {
        nesting_alignment: nesting_details.nesting_options.nesting_alignment,
        custom_margin_horizontal: nesting_details.nesting_options.margin_h,
        custom_margin_vertical: nesting_details.nesting_options.margin_v,
      };

      switch (_this.drawing_settings) {
        case 'linear':
          _this.update_linear_spacing();
          break;
        case 'cubic_square':
          _this.calculate_quantity_by_area();
          break;
        case 'nesting':
          setTimeout((el) => {
            _this.$refs.nesting_calculator_cmp.calculate_nesting_items();
          }, 900);
          break;
        default:
          break;
      }
      // }, 400);
    },
    calculate_quantity_by_area() {

      this.cubic_summary = '';
      this.cubic_specs_details = '';
      let depth = ((this.proposal_item_specs.depth == 0 || this.selected_object_depth == 0)
        ? 1
        : this.selected_object_depth);
      let stock_cubic_area =
        this.proposal_item_specs.width *
        this.proposal_item_specs.height *
        ((this.proposal_item_specs.depth == 0 || this.selected_object_depth == 0)
          ? 1
          : this.proposal_item_specs.depth);

      let calculated_area = 0;
      let cubic_summary_width, cubic_summary_height;

      d3.selectAll('.svg_inner_container [data-selected]')
        .nodes()
        .forEach((el, idx) => {
          let relative_boundaries = this.get_relative_boundaries(el);
          let height_mm = Math.round(relative_boundaries.height / (mm_to_px * this.page_scale));
          let width_mm = Math.round(parseFloat(relative_boundaries.width / (mm_to_px * this.page_scale)));
          let area = 0;
          if (el.nodeName == "polygon") {
            // 
            area = this.calculatePolygonArea(el);
          }
          else if (el.nodeName == "circle") {
            area = Math.PI * (height_mm / 2) * (width_mm / 2);
          }
          else {
            cubic_summary_width = relative_boundaries.width;
            cubic_summary_height = relative_boundaries.height;
            area = (cubic_summary_width / (mm_to_px * this.page_scale)) * (cubic_summary_height / (mm_to_px * this.page_scale));
          }
          calculated_area += area;
        });
      calculated_area = calculated_area * depth;

      if (depth > 1) {
        let stock_square_area_m3 = stock_cubic_area * mm_to_m3;
        let working_area_m3 = calculated_area * mm_to_m3;
        let total_working_area_m3 = working_area_m3 * this.proposal_item_specs.shape_qty;
        let area_m3 = total_working_area_m3 / stock_square_area_m3;
        this.quantity_needed_m = `${area_m3.toFixed(4)}m<sup>3</sup>`;
        this.quantity_needed = area_m3.toFixed(4);
        this.cubic_summary = `
                          <strong>Quantity:</strong> ${this.proposal_item_specs.shape_qty}<br>
                          <strong>Stock cubic area:</strong> ${stock_square_area_m3.toFixed(4)}m<sup>3</sup><br>
                          <strong>Working area:</strong> ${working_area_m3.toFixed(4)}m<sup>3</sup><br>
                          <strong>Total working area (incl. Qty):</strong> ${total_working_area_m3.toFixed(4)}m<sup>3</sup><br>
                          <strong>Calculation:</strong> ${total_working_area_m3.toFixed(4)}m<sup>2</sup>/${stock_square_area_m3.toFixed(4)}m<sup>3</sup> = ${area_m3.toFixed(4)}m<sup>3</sup>
                        `;
        this.cubic_specs_details = `cubic:${(area_m3).toFixed(4)}m3`;
      }
      else {
        let stock_square_area_m2 = stock_cubic_area * mm_to_m2;
        let working_area_m2 = calculated_area * mm_to_m2;
        let total_working_area_m2 = working_area_m2 * this.proposal_item_specs.shape_qty;
        let area_m2 = total_working_area_m2 / stock_square_area_m2;
        this.quantity_needed_m = `${area_m2.toFixed(4)}m<sup>2</sup>`;
        this.quantity_needed = area_m2.toFixed(4);
        this.cubic_summary = `
                          <strong>Quantity:</strong> ${this.proposal_item_specs.shape_qty}<br>
                          <strong>Stock square area:</strong> ${stock_square_area_m2.toFixed(4)}m<sup>2</sup><br>
                          <strong>Working area:</strong> ${working_area_m2.toFixed(4)}m<sup>2</sup><br>
                          <strong>Total working area (incl. Qty):</strong> ${total_working_area_m2.toFixed(4)}m<sup>2</sup><br>
                          <strong>Calculation:</strong> ${total_working_area_m2.toFixed(4)}m<sup>2</sup>/${stock_square_area_m2.toFixed(4)}m<sup>2</sup> = ${area_m2.toFixed(4)}m<sup>2</sup>
                        `;
        this.cubic_specs_details = `square:${(area_m2).toFixed(4)}m2`;

      }
      this.update_nesting_details({ qty: this.quantity_needed });
    },
    clientToGlobal() {
      return (
        d3
          .select('.svg_inner_container')
          .node()
          .getBBox().width /
        d3
          .select('.svg_inner_container')
          .node()
          .getBoundingClientRect().width
      );
    },
    globalToClient() {
      return (
        d3
          .select('.svg_inner_container')
          .node()
          .getBoundingClientRect().width /
        d3
          .select('.svg_inner_container')
          .node()
          .getBBox().width
      );
    },
    get_obj_path(el, start, end) {
      let increment = 1 / this.page_scale;
      let _this = this;

      let points = [],
        coordInitial,
        previous_point,
        x = 0,
        y = 0;
      do {
        coordInitial = el.obj.getPointAtLength(start);
        x = (coordInitial.x * el.userToClient * _this.clientToGlobal()).toFixed(
          3
        );
        y = (coordInitial.y * el.userToClient * _this.clientToGlobal()).toFixed(
          3
        );

        if (points.length == 0) {
          points.push({ x: x, y: y });
          start += increment;
          continue;
        }
        previous_point = points[points.length - 1];
        if (previous_point.x == x && points.length > 2) {
          previous_point.y = y;
        } else if (previous_point.y == y && points.length > 2) {
          previous_point.x = x;
        } else {
          points.push({ x: x, y: y });
        }
        start += increment;
      } while (start < end);
      let points_str = '';
      points.forEach((el, idx) => {
        points_str += ` ${el.x},${el.y}`;
      });
      return points_str;
    },
    // get translate value of an element
    get_obj_translate(el) {
      let translate_val = Array.from(el.obj.transform.baseVal).filter(
        (c) => c.type == 2
      )[0];
      if (translate_val != undefined)
        return `translate(${translate_val.matrix.e} ${translate_val.matrix.f})`;
      return '';
    },
    update_linear_spacing() {
      // method used to calculate selected linear shapes and check how many quantity
      // needed of stock items or task labour minutes
      let _this = this;
      let outer_transform = d3.select('.svg_outer_container').attr('transform');
      d3.select('.svg_outer_container')
        .node()
        .removeAttribute('transform');
      this.calculated_linear_items = [];
      this.linear_items_arr = [];
      this.quantity_needed = 0;
      if (
        this.proposal_item_specs[this.selected_linear_spec.toLowerCase()] ==
        0 &&
        this.linear_spacing == 0
      ) {
        _this.$message({
          message: `Choosen Item spec '${this.selected_linear_spec}' is 0 !`,
          type: 'info',
        });
        return;
      }

      // get all linear items
      // get sum their length
      let selected_layers = d3
        .selectAll('.svg_inner_container [data-selected]')
        .nodes();
      let linear_items = [];

      // if current selection is a layup element
      // then we fetch all polylines
      if (
        selected_layers.length == 1 &&
        this.selected_object != undefined &&
        this.selected_object.classList.contains('linear_layup_parent') == true
      ) {
        linear_items = d3.select(this.selected_object).selectAll('polyline');
        this.set_selected_object(null);
        d3.selectAll('[data-selected]')
          .attr('data-selected', null)
          .style('stroke-width', null);
        linear_items
          .attr('data-selected', 'true')
          .style('stroke-width', _this.stroke_width());
        linear_items = linear_items.nodes();
      } else linear_items = selected_layers;

      let linear_items_details = [];
      if (linear_items.length > 0) {
        linear_items.forEach((el, idx) => {
          linear_items_details.push(_this.get_linear_details(el));
        });
      } else if (this.selected_object != null)
        linear_items_details.push(
          _this.get_linear_details(this.selected_object)
        );
      _this.linear_length = 0;
      let cut_off_global_px = 0;
      linear_items_details.forEach((el, idx) => {
        let el_length = el.obj.getTotalLength() / this.page_scale;
        _this.linear_length += el.length_in_mm;
        let temp_start_length = 0;
        let start = 0;

        // width in user coordinates
        let width_px =
          this.proposal_item_specs[this.selected_linear_spec.toLowerCase()] *
          mm_to_px *
          _this.globalToClient() *
          el.clientToUser;
        // increment in user coordinates
        let increment_px =
          this.linear_spacing *
          mm_to_px *
          _this.globalToClient() *
          el.clientToUser;
        let counter = 0;

        while (start < el.obj.getTotalLength()) {
          // in case there was a cuttoff from last stock item
          let end;
          if (cut_off_global_px > 0) {
            end =
              start +
              increment_px +
              width_px -
              cut_off_global_px * _this.globalToClient() * el.clientToUser;
          } else {
            end = start + increment_px + width_px;
            this.quantity_needed++;
          }

          this.linear_items_arr.push({
            points: _this.get_obj_path(el, start + increment_px, end),
            transform: _this.get_obj_translate(el),
          });

          start = end;
          counter++;
          // on last iteration, add the cutt off if exists
          if (start + width_px + increment_px > el.obj.getTotalLength()) {
            // get last point
            if (start + increment_px < el.obj.getTotalLength()) {
              this.quantity_needed++;
              this.linear_items_arr.push({
                points: _this.get_obj_path(
                  el,
                  start + increment_px,
                  el.obj.getTotalLength()
                ),
                transform: _this.get_obj_translate(el),
              });
              cut_off_global_px = 0;
              // cut_off_global_px =
              //   (el.obj.getTotalLength() - (start + increment_px)) *
              //   el.userToClient *
              //   _this.clientToGlobal();
              start = el.obj.getTotalLength();
            }
          }
        }
      });

      if (outer_transform != undefined)
        d3.select('.svg_outer_container').attr('transform', outer_transform);
      // add waster percenatge to calculated qty
      let qty =
        this.quantity_needed * (this.proposal_item_specs.shape_qty || 1);
      this.quantity_needed_m = qty;
      this.update_nesting_details({ qty: qty });
    },
    get_linear_details(elem) {
      let userToClient, clientToUser;
      let _this = this;
      if (elem.getBBox().width > 0) {
        userToClient =
          elem.getBoundingClientRect().width / elem.getBBox().width;
        clientToUser =
          elem.getBBox().width / elem.getBoundingClientRect().width;
      } else {
        userToClient =
          elem.getBoundingClientRect().height / elem.getBBox().height;
        clientToUser =
          elem.getBBox().height / elem.getBoundingClientRect().height;
      }
      let length =
        elem.getTotalLength() * userToClient * _this.clientToGlobal();

      return {
        length: length,
        length_in_mm: length / mm_to_px,
        userToClient: userToClient,
        clientToUser: clientToUser,
        obj: elem,
      };
    },
    swap_item_specs() {
      let temp_width = this.proposal_item_specs.width;
      let temp_height = this.proposal_item_specs.height;
      this.proposal_item_specs.width = temp_height;
      this.proposal_item_specs.height = temp_width;
    },
    update_nesting_details(value) {
      this.quantity_needed = value.qty;
      this.proposal_stock_item.quantity = value.qty;

      if (this.job_proposal.job_id != '') {
        let selected_job = this.get_selected_job(this.job_proposal.job_id);
        this.job_proposal.time_allocated_labour =
          value.qty * this.proposal_item_specs.speed_rate; // selected_job.speed_rate;
      } else {
      }
      this.nesting_details = value;
    },
    load_stock_categories_list() {
      let _this = this;
      this.$http.get('/category_suppliers_list/').then((response) => {
        _this.stock_categories_list = response.body;
      });
    },
    selected_stock_change(e) {
      if (e != '') {
        let selected_stock = this.get_selected_stock(e);

        // this.proposal_stock_item = selected_stock;

        Object.keys(selected_stock).forEach((el, idx) => {
          if (el != 'id') {
            this.proposal_stock_item[el] = selected_stock[el];
          }
        });
        this.proposal_item_layer_name = selected_stock.internal_name;
        this.proposal_stock_item.quantity = 1;
        this.proposal_stock_item.stock_item_id = e;
        this.proposal_item_specs = {};
        this.proposal_item_specs = {
          shape_qty: 1,
          width: selected_stock.width,
          height: selected_stock.height,
          depth: selected_stock.thickness,
          include_speed_rate:
            isNaN(selected_stock.speed_rate) || selected_stock.speed_rate == 0
              ? false
              : true,
          speed_rate: selected_stock.speed_rate || 0,
        };
        this.min_sale_price = parseFloat(selected_stock.sale_price);
        this.selected_linear_spec = 'Width';
        // this.proposal_stock_item.total_price =
        //   selected_stock.sale_price * this.proposal_stock_item.quantity;
      }
    },
    selected_job_change(e) {
      if (e != '') {
        let selected_job = this.get_selected_job(e);

        this.proposal_item_specs = {
          shape_qty: 1,
          width: selected_job.width,
          height: selected_job.height,
          speed_rate: selected_job.speed_rate,
          include_speed_rate: true,
          depth: 0,
        };
        this.selected_linear_spec = 'Width';

        this.job_proposal.time_allocated_labour = parseFloat(
          selected_job.allocated_time || 0
        );
        this.sale_price = parseFloat(selected_job.labour_minutes_rate || 0);
      } else
        this.proposal_item_specs = {
          shape_qty: 1,
          width: 0,
          height: 0,
          include_speed_rate: true,
          speed_rate: 0,
          depth: 0,
        };
    },
    get_selected_stock(id) {
      return this.stock_items_results.filter((item) => {
        return item.id == id;
      })[0];
    },
    get_selected_job(id) {
      return this.jobs_list.filter((item) => {
        return item.id == id;
      })[0];
    },
    task_update_callback(id, response) {
      let _this = this;
      let selected_job = _this.get_selected_job(_this.job_proposal.job_id);
      let nesting_data = this.get_nesting_details();
      let params = {
        'layer-type': 'job',
        'job-id': id,
        'nesting-details': JSON.stringify(nesting_data),
        'ref-ids': JSON.stringify(
          nesting_data.working_areas_details.map((el) => el.ref_id)
        ),
        'time-allocated': _this.job_proposal.time_allocated_labour,
        'data-name':
          _this.proposal_item_layer_name == '' ||
            _this.proposal_item_layer_name == undefined
            ? selected_job.name
            : _this.proposal_item_layer_name,
      };
      if (_this.drawing_settings == 'cubic_square') {
        params['depth'] = _this.selected_object_depth;
      }

      // Update store
      if (this.existing_nesting == true) {
        _this.update_job_proposal(response);
      } else _this.job_proposals_list.push(response);

      // Update layer tree
      let specs;
      if (
        _this.drawing_settings == 'nesting' &&
        _this.nesting_details != '' &&
        _this.nesting_details.inner_shapes != undefined
      ) {
        _this.add_layer({
          params: params,
          nesting_details: _this.nesting_details,
          working_file_details: _this.nesting_details.inner_shapes,
        });
      } else if (
        _this.drawing_settings == 'linear' &&
        _this.linear_items_arr.length > 0
      ) {
        _this.add_layer({
          params: params,
          linear_items: _this.linear_items_arr,
        });
      } else {
        specs = {
          width: selected_job.width,
          height: selected_job.height,
          x: 0,
          y: 0,
        };
        _this.add_layer({
          params: params,
          specs: specs,
        });
      }
    },
    save_job() {
      let _this = this;
      let selected_job = this.get_selected_job(this.job_proposal.job_id);
      if (_this.job_proposal.job_id != '') {
        let data = {
          job_id: selected_job.id,
          time_allocated_labour: _this.job_proposal.time_allocated_labour,
          working_file_id: _this.working_file_id,
          sale_price: _this.sale_price,
          specs_details: _this.get_specs_details(),
          layer_name: this.proposal_item_layer_name,
        };

        if (_this.existing_nesting == false) {
          _this.add_job_proposal(_this.proposal.id, [data]).then(
            (result) => {
              _this.task_update_callback(result[0].id, result[0]);
            },
            (reason) => {
              console.log(reason);
            }
          );
        } else {
          _this.update_jp(_this.job_proposal.id, data).then(
            (result) => {
              _this.task_update_callback(_this.job_proposal.id, result);
            },
            (reason) => {
              console.log(reason);
            }
          );
        }
      }
    },
    ...mapActions([
      'set_selected_object',
      'load_layers_tree',
      'clear_selection',
      'update_stock_item',
      'update_job_proposal',
      'update_file_details',
    ]),
    set_inputs_to_default() {
      let _this = this;
      Array.from(document.querySelectorAll('#layers_panel .el-input')).forEach(
        function (e, i) {
          e.classList.add('browser-default');
          if (e.querySelector('input') != null)
            e.querySelector('input').className += ' browser-default';
        }
      );
    },
    remoteMethod(query) {
      let _this = this;
      if (query !== '' && query.trim().length > 2) {
        let filter = { term: query };
        if (this.by_category == true && this.selected_category_id != '')
          filter['category_supplier_id'] = this.selected_category_id;
        filter['search_all_stocks'] = this.show_all_stocks;
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.$http
            .post('/search_stocks_by_params', filter)
            .then((response) => {
              _this.stock_items_results = response.body.data;
            });
        }, 200);
      } else {
        this.stock_items_results = [];
      }
    },
    get_nesting_details() {
      let working_areas_details = [];
      let _this = this;
      switch (this.drawing_settings) {
        case 'none':
        case 'linear':
        case 'cubic_square':
          d3.selectAll('[data-selected]')
            .nodes()
            .forEach((el, idx) => {
              working_areas_details.push({
                // id: idx,
                ref_id: this.get_element_id(el),
              });
            });
          break;
        case 'nesting':
          this.nesting_details.nesting_proposal_items.forEach((el, idx) => {
            working_areas_details.push({
              id: el.id,
              ref_id: el.ref_id,
              cust_x: el.custom_x,
              cust_y: el.custom_y,
              cust_w: el.custom_width,
              cust_h: el.custom_height,
            });
          });
        default:
          break;
      }

      let nesting_data = {
        stock_specs: this.proposal_item_specs,
        nesting_options: {
          type: this.drawing_settings || 'none',
          nesting_alignment: this.nesting_options.nesting_alignment,
          //this.$refs.nesting_calculator_cmp.nesting_alignment || "left",
          linear_spacing: this.linear_spacing || 0,
          obj_depth: this.selected_object_depth || 0,
          margin_h: this.nesting_options.custom_margin_horizontal || 0,
          margin_v: this.nesting_options.custom_margin_vertical || 0,
        },
        working_areas_details: working_areas_details,
      };
      return nesting_data;
    },
    save_stock() {
      let _this = this;
      let selected_stock = this.get_selected_stock(
        this.proposal_stock_item.stock_item_id
      );

      let data = {
        proposal_id: this.proposal.id,
        unit_price: selected_stock.price,
        sale_price: this.proposal_stock_item.sale_price,
        total_price: this.total_price_calculated,
        working_file_id: this.working_file_id,
        stock_item_id: this.proposal_stock_item.stock_item_id,
        percent_applied: selected_stock.price_percentage,
        stock_quantity: this.quantity_incl_waste(),
        waste_percent: this.proposal_stock_item.waste_percent,
        specs_details: this.get_specs_details(),
        layer_name: this.proposal_item_layer_name,
      };

      if (this.existing_nesting == true) {
        this.update_proposal_stock_item(this.proposal_stock_item.id, data).then(
          (result) => {
            _this.prop_stock_callback(this.proposal_stock_item.id, result);
          },
          (reason) => {
            console.log(reason);
          }
        );
      } else
        this.add_proposal_stock_item(this.proposal.id, data).then(
          (result) => {
            _this.prop_stock_callback(result.id, result);
          },
          (reason) => {
            console.log(reason);
          }
        );
    },
    prop_stock_callback(id, result) {
      let _this = this;
      let nesting_data = this.get_nesting_details();
      let selected_stock = this.get_selected_stock(
        this.proposal_stock_item.stock_item_id
      );

      let params = {
        'layer-type': 'stock',
        'nesting-details': JSON.stringify(nesting_data),
        'proposal-stock-id': id,
        'ref-ids': JSON.stringify(
          nesting_data.working_areas_details.map((el) => el.ref_id)
        ),
        'shape-quantity': _this.proposal_item_specs.shape_qty,
        'stock-quantity': _this.proposal_stock_item.quantity,
        'data-name':
          _this.proposal_item_layer_name == '' ||
            _this.proposal_item_layer_name == undefined
            ? selected_stock.name
            : _this.proposal_item_layer_name,
      };
      if (_this.drawing_settings == 'cubic_square') {
        params['depth'] = _this.selected_object_depth;
      }
      if (this.existing_nesting == true) {
        _this.update_stock_item(result);
      } else _this.stock_items_list.push(result);

      let specs;
      if (
        _this.drawing_settings == 'nesting' &&
        _this.nesting_details != undefined
      ) {
        specs = {
          width: _this.nesting_details.width,
          height: _this.nesting_details.height,
          x: _this.nesting_details.x,
          y: _this.nesting_details.y,
        };
        _this.add_layer({
          params: params,
          specs: specs,
          nesting_details: _this.nesting_details,
        });
      } else if (
        _this.drawing_settings == 'linear' &&
        _this.linear_items_arr.length > 0
      ) {
        _this.add_layer({
          params: params,
          linear_items: _this.linear_items_arr,
        });
      } else {
        specs = {
          width: selected_stock.width,
          height: selected_stock.height,
          x: 0,
          y: 0,
        };
        _this.add_layer({
          params: params,
          specs: specs,
        });
      }
      if (_this.existing_nesting == false)
        _this.check_stock_attached_tasks(
          _this.proposal_stock_item.stock_item_id
        );
      // check if stock has attached tasks and show them (speed rate and specs are needed)
    },
    check_stock_attached_tasks(stock_item_id) {
      let _this = this;
      let selected_stock = this.get_selected_stock(stock_item_id);
      this.get_stock_attached_tasks(stock_item_id).then(
        (response) => {
          if (response.length > 0) {
            this.stock_related_tasks = response;

            this.stock_related_tasks.forEach((el, idx) => {
              el['time_allocated_labour'] =
                _this.proposal_stock_item.quantity *
                (selected_stock.speed_rate == '0'
                  ? 1
                  : selected_stock.speed_rate);
            });
            this.related_stock_dialog = true;
          }
        },
        (reason) => { }
      );
    },
    get_specs_details() {
      let specs = `Qty: ${this.proposal_item_specs.shape_qty} , `;
      switch (this.drawing_settings) {
        case 'linear':
          specs = specs + `linear: ${(this.linear_length / 1000).toFixed(2)}m`;
          break;
        case 'cubic_square':
          specs = specs + this.cubic_specs_details;
          break;
        case 'nesting':
          specs =
            specs +
            'square: ' +
            this.nesting_details.nesting_proposal_items.map(
              (c) =>
                `${(c.custom_width / 1000).toFixed(2)}x${(
                  c.custom_height / 1000
                ).toFixed(2)}` + ' m'
            );
          break;
      }
      return specs;
    },
    add_stock_item() {
      this.$refs['proposal_item_form'].validate((valid) => {
        if (valid) {
          this.save_stock();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleCommand(command) {
      this.nesting_details = {
        width: this.page_settings.width,
        height: this.page_settings.height,
        x: 0,
        y: 0,
      };
      switch (command) {
        case 'stock':
        case 'job':
          this.selected_proposal_item_type = command;
          this.proposal_item_layer_dialog = true;
          this.existing_nesting = false;
          this.proposal_item_specs.shape_qty = this.working_file_qty;
          break;
        case 'other':
          this.add_layer({});
          break;
      }
    },
    draw_layer(layer_type) {
      //draw_stock_layer
      let _this = this;
      this.layer_mode = layer_type;

      this.svg_inner_container = d3.select(
        '#proposal_workspace .svg_inner_container'
      );
      d3.selectAll('.scratch_drawing').remove();
      if (_this.svg_inner_container.select('.scratchpad').node() == null)
        _this.svg_inner_container
          .append('g')
          .classed('scratchpad', true)
          .append('rect')
          .attr('width', _this.page_settings.screen_width)
          .attr('height', _this.page_settings.screen_height)
          .attr('cursor', 'crosshair')
          .attr('fill', '#f0f8ff5c')
          .attr('stroke', 'lightblue')
          .attr('stroke-width', _this.stroke_width(1));

      this.svg_inner_container
        .select('.scratchpad')
        .on('mousedown', function () {
          var m = d3.mouse(this);
          let rect = _this.svg_inner_container
            .select('.scratchpad')
            .append('rect')
            .classed('scratch_drawing', true)
            .attr('x', m[0])
            .attr('y', m[1])
            .attr('height', 0)
            .attr('width', 0)
            .attr('fill', 'none')
            .attr('stroke-width', _this.stroke_width(2))
            .attr('stroke', '#585757')
            .attr('stroke-dasharray', '5px');

          d3.event.stopPropagation();
          _this.svg_inner_container
            .select('.scratchpad')
            .on('mousemove', function (d) {
              var m = d3.mouse(this);
              let rect = _this.svg_inner_container.select(
                'rect.scratch_drawing'
              );
              rect
                .attr('width', Math.max(0, m[0] - +rect.attr('x')))
                .attr('height', Math.max(0, m[1] - +rect.attr('y')));
            });
          d3.event.stopPropagation();
        })
        .on('mouseup', this.mouseup);
    },
    handle_tools_clicked(command) {
      switch (command) {
        case 'draw_stock_layer':
          this.draw_layer('stock');
          break;
        case 'draw_job_layer':
          this.draw_layer('job');
          break;
        case 'custom_area_nesting':
          this.custom_area_nesting();
        default:
          break;
      }
    },
    mouseup() {
      let rect = this.svg_inner_container.select('rect.scratch_drawing');
      this.nesting_details = {
        width: parseFloat(rect.attr('width')) / (mm_to_px * this.page_scale),
        height: parseFloat(rect.attr('height')) / (mm_to_px * this.page_scale),
        x: parseFloat(rect.attr('x')) / (mm_to_px * this.page_scale),
        y: parseFloat(rect.attr('y')) / (mm_to_px * this.page_scale),
      };
      this.svg_inner_container.select('.scratchpad').remove();

      this.proposal_item_layer_dialog = true;
      this.existing_nesting = false;
      this.selected_proposal_item_type = this.layer_mode;

      this.svg_inner_container.on('mousedown', null);
      this.svg_inner_container.on('mousemove', null);
      this.svg_inner_container.on('mouseup', null);
    },
    allowDrag(draggingNode) {
      return draggingNode.data.locked != 'true';
    },
    add_layer(obj) {
      let _this = this;
      let id = Math.max.apply(
        Math,
        this.layers_tree.map(function (o) {
          return o.id;
        })
      );

      let layer_counter = this.layer_counter++;
      let layer_name =
        obj.params != undefined
          ? obj.params['data-name']
          : 'LAYER ' + layer_counter;
      let new_layer;
      if (_this.existing_nesting == true) {
        new_layer = d3.select(_this.selected_layer);
        new_layer.html('');
      } else {
        new_layer = d3
          .selectAll('.svg_inner_container .layers')
          .append('g')
          .attr('class', 'svg_layer')
          .attr('data-name', layer_name);
      }

      let shape_qty = this.proposal_item_specs.shape_qty || 1;

      // Set html attributes of layer
      if (obj.params != null)
        Object.keys(obj.params).forEach(function (key, index) {
          new_layer.attr(key, obj.params[key]);
        });

      // Below is used to create a frame
      // draw nested elements
      if (
        obj.nesting_details != undefined &&
        obj.nesting_details.inner_shapes != undefined
      ) {
        this.add_layer_details(new_layer, obj.nesting_details);
      } else if (obj.linear_items != undefined) {
        // draw linear items (stock/task) along lines
        let g = new_layer.append('g').classed('grouped', true);

        obj.linear_items.forEach((el, idx) => {
          let color = _this.getRandomColor();
          g.append('polyline')
            .attr('points', el.points)
            .attr('stroke', color)
            .attr('stroke-width', _this.stroke_width(1))
            .attr('fill', 'red')
            .attr('transform', el.transform || null);
        });
        let working_area_specs = g.node().getBBox();

        let text = g.append('text').text(`Quantity: ${shape_qty}`);
        let size =
          (text.node().getBBox().height * working_area_specs.width) /
          text.node().getBBox().width;
        text
          .attr('font-size', size / 2)
          .attr('x', working_area_specs.x)
          .attr('y', working_area_specs.y);
        d3.selectAll(g)
          .selectAll('*')
          .classed('grouped', true);
      } else if (this.drawing_settings == 'cubic_square') {
        // Update below to fit for square metre
        let shadow_link = _this.get_shadow_link();
        d3.selectAll('.svg_inner_container [data-selected]')
          .nodes()
          .forEach((el, idx) => {
            let relative_coords = this.get_relative_boundaries(el);
            let working_area = new_layer
              .append('g')
              .classed('working_area_details', true)
              .classed('grouped', true);
            working_area
              .append('rect')
              .classed('working_area_frame', true)
              .classed('grouped', true)
              .attr('x', relative_coords.x1 / _this.page_scale)
              .attr('y', relative_coords.y1 / _this.page_scale)
              .attr('width', relative_coords.width / _this.page_scale)
              .attr('height', relative_coords.height / _this.page_scale)
              .attr('fill', '#fbfbbd')
              .attr('stroke', 'orange')
              .attr('stroke-width', '1px')
              .attr('filter', `url(#${shadow_link})`);

            let text = working_area
              .append('text')
              .attr('x', relative_coords.x1 / _this.page_scale)
              .attr('y', relative_coords.y1 / _this.page_scale)
              .text(`Quantity: ${shape_qty}`);
            let size =
              (text.node().getBBox().height *
                (relative_coords.width / _this.page_scale)) /
              text.node().getBBox().width;
            text.attr('font-size', size / 2);
          });
      }
      this.attach_events(new_layer);
      id++;
      const newChild = {
        id: id,
        name: layer_name,
        selected_object: new_layer.node(),
        selected_proposal_item_type:
          obj.params != undefined ? obj.params['layer-type'] : '',
        active: false,
        locked: false,
        visible: true,
        children: [],
      };
      //if (this.existing_nesting == false) this.layers_tree.push(newChild);

      this.load_layers_tree();

      // Save SVG after adding a layer
      //if (obj.params != undefined) this.save_svg();
      this.save_svg();
    },
    add_layer_details(new_layer, working_file_details) {
      let group = new_layer;
      let _this = this;
      group = group.append('g').classed('grouped', true);
      //group.classed('grouped_objects')
      //new_layer.insert("g").classed("working_area_details", true);

      // below code was commented as it is not in use
      // group
      //   .append("foreignObject")
      //   .classed("data", true)
      //   .attr("x", 0)
      //   .attr("y", 0)
      //   .attr("visibility", "hidden")
      //   .text(working_file_details.data);

      working_file_details.inner_shapes.forEach((inner_shape_group, idx) => {
        let working_area = group
          .append('g')
          .classed('working_area_details', true);
        working_area
          .append('rect')
          .classed('working_area_frame', true)
          .attr('x', inner_shape_group.x)
          .attr('y', inner_shape_group.y)
          .attr('width', inner_shape_group.w / _this.page_scale)
          .attr('height', inner_shape_group.h / _this.page_scale)
          .attr('fill', 'none')
          .attr('stroke', 'orange')
          .attr('stroke-width', '1px');
        let text = working_area
          .append('text')
          .attr('x', inner_shape_group.x)
          .attr('y', inner_shape_group.y)
          .text(`Quantity: ${_this.proposal_item_specs.shape_qty}`);

        let size =
          (text.node().getBBox().height * working_area.node().getBBox().width) /
          text.node().getBBox().width;
        text.attr('font-size', size / 2);
        inner_shape_group.shapes
          .filter((c) => c.id.includes('Num#0'))
          .forEach((el, id) => {
            let shape = el;
            group
              .append('rect')
              .classed('item_style', true)
              .attr(
                'x',
                inner_shape_group.x +
                (shape.x + working_file_details.horizontal_margin) * mm_to_px
              )
              .attr(
                'y',
                inner_shape_group.y +
                (shape.y + working_file_details.vertical_margin) * mm_to_px
              )
              .attr(
                'width',
                (shape.w - 2 * working_file_details.horizontal_margin) *
                mm_to_px
              )
              .attr(
                'height',
                (shape.h - 2 * working_file_details.vertical_margin) * mm_to_px
              )
              .attr('fill', 'none')
              .attr('stroke', 'gray')
              .attr('stroke-width', '10px');

            let font_size =
              Math.min(shape.w * mm_to_px - 2, shape.h * mm_to_px - 2) * 0.4;
            group
              .append('text')
              .attr('font-size', font_size)
              .attr('x', inner_shape_group.x + (shape.x + 3) * mm_to_px)
              .attr('y', inner_shape_group.y + shape.y * mm_to_px + font_size)
              .text(`${id + 1}`);
          });
      });
      group;
    },
    update_file_qty() {
      let file_details = {
        id: this.working_file.id,
        name: this.working_file.name,
        qty: this.working_file_qty,
        matched_quote: this.working_file.matched_quote,

      };
      this.$http
        .patch(`/update_working_file_details/${this.working_file_id}`, {
          file_details: { qty: this.working_file_qty },
        })
        .then((response) => {
          this.update_file_details(file_details);
          this.$loading().close();
        });
    },
    update_working_file_qty() {
      if (this.working_file_qty == undefined || this.working_file_qty <= 0) {
        this.$message({ type: 'warning', message: 'Please enter quantity!' });
        return;
      }

      let arr = [];
      this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      let _this = this;
      setTimeout(function () {
        let checked_layers = _this.stocks_tasks_layers.filter(
          (c) => c.checked == true
        );
        checked_layers.forEach((el, idx) => {
          let ref_ids = el.selected_object.getAttribute('ref-ids');
          if (ref_ids == undefined) return;
          arr = arr.concat(JSON.parse(ref_ids));
          el.selected_object.setAttribute(
            'shape-quantity',
            _this.working_file_qty
          );
        });
        arr.unique().forEach((el, idx) => {
          let elem = d3.select(`#${el}`).node();
          if (elem != undefined) {
            _this.set_selected_object(elem);
            _this.update_nesting(checked_layers.map((c) => c.selected_object));
          }
        });
        _this.update_file_qty();
      }, 200);
    },
    update_qty_out_of_layers(qty) {
      this.working_file_qty = qty;
      this.update_working_file_qty();
    },
  },
  mounted() {
    this.layers_nesting_dialog = true;
    this.drawing_settings = 'nesting';
    this.layers_nesting_dialog = false;
    let _this = this;

    // add uniqe to array
    Object.defineProperty(Array.prototype, 'unique', {
      enumerable: false,
      configurable: false,
      writable: false,
      value: function () {
        var a = this.concat();
        for (var i = 0; i < a.length; ++i) {
          for (var j = i + 1; j < a.length; ++j) {
            if (a[i] === a[j]) a.splice(j--, 1);
          }
        }

        return a;
      },
    });
  },
  computed: {
    ...mapGetters([
      'layers_tree',
      'working_file_id',
      'proposal',
      'page_settings',
      'job_proposals_list',
      'stock_items_list',
      'page_scale',
      'selected_object',
      'jobs_list',
      'files_list',
      'is_hours'
    ]),
    working_file() {
      return this.files_list.filter((c) => c.id == this.working_file_id)[0];
    },

    total_price_calculated: function () {
      let qty = this.proposal_stock_item.quantity || 0;
      let sale_price = this.proposal_stock_item.sale_price || 0;

      return Math.round(qty * sale_price * 100) / 100;
    },
    // checked_item_layers(){
    //   this.stocks_tasks_layers = d3
    //     .selectAll("[ref-ids]")
    //     .nodes()
    //     .map((c) => {
    //       let elem = d3.select(c);
    //       let js = {
    //         id: elem.attr("id"),
    //         name: elem.attr("data-name"),
    //         el: elem,
    //         type: elem.attr("proposal-stock-id") != undefined ? "stock" : "job",
    //       };
    //       return js;
    // },
    stocks_tasks_layers() {
      return this.layers_tree.filter(
        (c) => c.selected_proposal_item_type != null
      );
    },
  },
};
</script>

<style>
.proposal_item_layer_dialog .row {
  margin-bottom: 5px;
}

polyline {
  fill: none;
}

.el-collapse-item__header {
  padding: 10px !important;
  /* height: 48px !important; */
}
</style>

<style>
.el-tree {
  max-height: 300px;
  overflow-y: auto;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.hide_layer {
  /* visibility: hidden; */
  display: none;
}
</style>
