<template>
  <div>
    <div class="card  ">
      <div class="card-content ">
        <span class="card-title">
          Sales <small>(Invoices vs without Invoices)</small>
        </span>

        <el-date-picker
          v-model="selected_date_range"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          style="width: auto !important;"
          :picker-options="pickerOptions2"
          @change="fill_data"
        />
        <br>
        <br>
        <table>
          <tr class="header">
            <th>With invoices</th>
            <th>Without invoices</th>
            <th>Total</th>
          </tr>
          <tr class="rows">
            <td><span class="center">{{ sales_without_invoices | currency }}</span></td>
            <td><span class="center">{{ sales_with_invoices | currency }}</span></td>
            <td><span class="center">{{ (sales_without_invoices + sales_with_invoices) | currency }}</span></td>
          </tr>
        </table>
        <pie-chart
          :chart-data="datacollection"
          :options="chartOptions"
        />
      </div>
      <div class="card-action">
        <button
          class="btn btn-small"
          @click="show_sales(true)"
        >
          Sales (with invoices)
        </button>
        <button
          class="btn btn-small right"
          @click="show_sales(false)"
        >
          Sales (without invoices)
        </button>
      </div>
    </div>
    <modal
      name="sales_invoices_records_list"
      :height="700"
      :width="1200"
      :adaptive="true"
    >
      <div class="card">   
        <div class="card-content  ">
          <span class="card-title">{{ list_title }}<hr></span>
          <div
            v-show="is_loading"
            class="progress"
          >
            <div class="indeterminate" />
          </div>
          <el-table
            :data="rows"
            style="width: 100%"
            height="600"
          >
            <el-table-column
              v-for="column in columns"
              :key="column"
              :prop="column"
              :label="column | capitalize"
              sortable
            />
          </el-table>
        </div>
        <div class="card-action" />
      </div>
    </modal>
  </div>
</template>

<script>
import PieChart from './PieChart';
export default {
  name: 'SalesWithoutInvoices',
  components: { PieChart},
  data(){
    return {
      datacollection: '',
      sales_without_invoices: 0,
      sales_with_invoices: 0,
      columns: [],
      rows: [],
      list_title: '',
      is_loading: false,
      pickerOptions2: {
        shortcuts: [{
          text: 'Current month',
          onClick(picker) {
            const end = new Date();
            const start = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last week',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        },
        {
          text: 'Last month',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last 3 months',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last 6 months',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last year',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      selected_date_range: ''
    };
  },
  computed: {
    current_date() {
      return new Date();
    },
    start_date() {
      return new Date(this.current_date.getFullYear(), this.current_date.getMonth(), 1);
    },
    end_date() {
      return new Date(this.current_date.getFullYear(), this.current_date.getMonth(), this.current_date.getDate());
    }
  },
  created(){},
  mounted(){
    this.selected_date_range = [this.start_date, this.end_date];
    this.fill_data();
  },
  methods:{
    fill_data() {
      let self = this;
      this.$http.post('/get_sales_invoices', {
        start_date: this.selected_date_range[0],
        end_date: this.selected_date_range[1]
      }).then(response => {
        if (response.data.status == 200) {
          self.sales_without_invoices = response.data.data.sales_without_invoices;
          self.sales_with_invoices = response.data.data.sales_with_invoices;
          this.datacollection = {
            labels: ['With Invoices', 'Without Invoices'],
            datasets:
                                            [
                                              {
                                                label: 'Sales ',
                                                backgroundColor: ['lightgreen', '#efefb2'],
                                                data: [self.sales_with_invoices, self.sales_without_invoices]
                                              }
                                            ]
          };
        }
      }
      );
    },
    show_sales(with_invoices){
      this.open_modal();
      this.columns = [];
      this.rows = [];
      let self = this;
      this.list_title = 'Sales ' + (with_invoices == true ? '(with invoices)' : '(without invoices)');
      this.is_loading = true;
      this.$http.post('/get_sales_invoices_records', {
        start_date: this.selected_date_range[0],
        end_date: this.selected_date_range[1],
        with_invoices: with_invoices
      }).then(response => {
        self.is_loading = false;
        if (response.data.status == 200) {
          self.columns = response.data.data.columns;
          self.rows = response.data.data.rows;
        }
      });
    },
    open_modal() {
      this.$modal.show('sales_invoices_records_list');
    },
    hide_modal() {
      this.$modal.hide('sales_invoices_records_list');
    },
    chartOptions: function () {
      return {responsive: true, maintainAspectRatio: false};
    }
  }
};
</script>

<style scoped>
 td, th {
        text-align: center;
        padding: 10px;
    }

    th {
        font-weight: 800;
    }
    .header{
        background-color: #ddecf9;
    }
    .subheader{
        background-color: #e5f3ff;
    }
    .rows{
        background-color: aliceblue;
     }
</style>