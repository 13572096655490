<template>
  <el-row>
    <el-col
      :lg="24"
      :xl="22"
    >
      <span class="tab_header">
        STOCK ITEMS LIST
        <hr>
      </span>
      <el-table :data="stock_items_list">
        <el-table-column
          type="selection"
          width="55"
        />
        <el-table-column label="Stock description">
          <template slot-scope="scope">
            <div class="layer_name_container">
              <div style="max-width: 600px;">
                <el-input
                  v-model="scope.row.layer_name"
                  :placeholder="scope.row.stock_name"
                  size="mini"
                  @change="update_stock_layer_name(scope.row)"
                >
                  <template
                    slot="prepend"
                  >
                    {{ scope.row.quantity }} x
                  </template>
                  <template slot="append">
                    <el-link
                      icon="el-icon-link"
                      :href="`stock_items/${scope.row.stock_item_id}/edit`"
                      :underline="false"
                    />
                  </template>
                </el-input>
              </div>
              <div>
                <span
                  v-show="
                    scope.row.purchase_order != undefined &&
                      scope.row.purchase_order.status == 'PENDING'
                  "
                  style="color: orange;"
                >PENDING APPROVAL</span>
              </div>
            </div>
            <!-- <a
                    :href="`stock_items/${scope.row.stock_item_id}/edit`"
                    target="_blank"
                  >
                    {{ scope.row.quantity }} x {{ scope.row.stock_name }}</a
                  > -->
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          prop="unit_cost"
          label="Unit Cost"
        />
        <el-table-column
          width="100"
          label="Total Cost"
        >
          <template slot-scope="scope">
            {{ scope.row.total_cost }}
          </template>
        </el-table-column>
      </el-table>

      <div
        v-show="false"
        style="padding: 10px;"
        class="right"
      >
        <el-button
          type="danger"
          plain
          size="mini"
        >
          Delete selected
        </el-button>
        <el-button
          type="primary"
          size="mini"
        >
          Approve POs
        </el-button>
      </div>
    </el-col>
  </el-row>
</template>
<script>
import { editor_store } from 'store/modules/editor';
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: 'ProjectPlanStocks',
  store: editor_store,
  data() {
    return {};
  },
  mounted() {
    this.set_default_input();
  },
  methods: {
    approve_selected_pos() {},
    delete_selected_pos() {},
    update_stock_layer_name(stock) {
      let params = {
        layer_name: stock.layer_name,
      };
      this.$http
        .patch(`/update_proposal_stock_item/${stock.id}`, {
          proposal_stock_item: params,
        })
        .then(
          (response) => {
            this.$message({
              type: 'success',
              message: 'Stock name has been updated successfully',
            });
          },
          (response) => {
            this.$message({
              type: 'error',
              message: 'Error happened while updating, please try again.',
            });
          }
        );
    },
    set_default_input(mills = 300) {
      let _this = this;
      setTimeout(function() {
        Array.from(document.querySelectorAll('.el-input__inner')).forEach(
          function(e, i) {
            e.classList.add('browser-default');
            if (e.querySelector('input') != null)
              e.querySelector('input').className += ' browser-default';
          }
        );
      }, mills);
    },
  },
  computed: {
    ...mapGetters(['stock_items_list']),
  },
};
</script>
