<template>
  <div class="client__page">
    <h3 class="headline">
      QUOTES
    </h3>
    <div class="content">
      <el-table
        :data="quotes"
        stripe
        style="width: 100%"
      >
        <el-table-column
          prop="id"
          label="Job No"
        />
        <el-table-column
          prop="category_task"
          label="Category"
        />
        <el-table-column
          prop="job_name"
          label="Job Name"
        />
        <el-table-column
          label="Approved"
        >
          <template slot-scope="scope">
            <div :class="unapproved_text_class(scope.row.approved)">
              {{ approved_text(scope.row.approved) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="custom"
          label="Custom"
        />
        <el-table-column
          label="Created At"
        >
          <template slot-scope="scope">
            <div>
              {{ scope.row.created_at }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Operations"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              :type="operation_btn_type(scope.row.approved)"
              class="op__btn__width"
              @click="onEdit(scope.row)"
            >
              {{ operation_btn_text(scope.row.approved) }}
            </el-button>

            <el-button
              v-if="scope.row.approved === 0"
              size="mini"
              type="danger"
              class="op__btn__width"
              @click="onDelete(scope.row)"
            >
              Delete
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {Request} from '../../utilities/Request';
import ClientTermsMixin from '../../mixins/ClientTermsMixin';

export default {
  name: 'Quotes',
  mixins: [ClientTermsMixin],
  data() {
    return {
      quotes: [],
    };
  },
  computed: {
    approved_text() {
      return (approved)=> {
        return approved == 1 ? 'Yes' : 'No';
      };
    },
    unapproved_text_class() {
      return (approved)=> {
        return approved == 0 ? 'unapproved-text__color' : '';
      };
    },
    operation_btn_text() {
      return (approved)=> {
        return approved == 1 ? 'Show' : 'Edit';
      };
    },
    operation_btn_type() {
      return (approved) => {
        return approved == 1 ? '' : 'primary';
      };
    }
  },
  created() {
    this.fetch_proposals();
  },
  methods: {
    fetch_proposals() {
      Request.get(
        this,
        '/api/proposals',
        (response) => {
          this.quotes = response.body.proposals;
        }
      );
    },
    onEdit(quote) {
      window.location = `/edit_proposal?id=${ quote.id }`;
    },
    onDelete(quote) {
      Request.delete(
        this,
        `/api/proposals/${parseInt(quote.id)}`,
        (response) => {
          this.$message({
            message: response.body.message,
            type: 'success'
          });
          this.fetch_templates();
        }
      );
    }
  }
};

</script>

<style lang="scss" scoped>
.op__btn__width {
  width: 68px !important;
}

.unapproved-text__color {
  color: red;
}
</style>