<template>
  <div id="pay-run-transactions">
    <el-date-picker
      v-model="month_picked"
      type="month"
      format="MM.yyyy"
      size="small"
      :clearable="false"
      placeholder="Please pick a month."
      @change="pay_run_transaction_monthly(true)"
    />
    <p />

    <el-collapse accordion>
      <el-collapse-item
        v-for="pay_run in pay_runs_monthly"
        :key="pay_run.id"
        :name="pay_run.id"
      >
        <template slot="title">
          <table>
            <th>Pay Date:</th>
            <th>Pay Period ({{ pay_run.pay_frequency }}):</th>
            <th>Pay Cycle:</th>
            <th>IRD Sheet</th>
            <tr>
              <td><span>{{ pay_run.pay_date }}</span></td>
              <td><span style="color: #409EFF">{{ pay_run.pay_period.start_calculated }} - {{ pay_run.pay_period.end_calculated }}</span> </td>
              <td><span>{{ pay_run.pay_cycle_name }}</span> </td>
              <td>
                <el-link
                  style="color: #409EFF"
                  @click="pay_transaction_summary_sheet(pay_run.pay_period_id)"
                >
                  IR Payday Filling File Upload {{ pay_run.pay_period_id }}
                </el-link>
              </td>
            </tr>
          </table>
        </template>
        <el-tabs type="border-card">
          <el-tab-pane label="Employees">
            <el-table
              ref="employee_table"
              :data="pay_run.transactions"
              style="width: 100%"
              stripe
            >
              <el-table-column
                label="Name"
                property="name"
                width="200px"
              >
                <template #default="scope">
                  <el-link
                    style="color: #409EFF"
                    @click="edit_employee(scope.row.employee_data.id)"
                  >
                    {{ scope.row.employee_data.name }}
                  </el-link>
                </template>
              </el-table-column>
              <el-table-column
                label="Earnings"
                prop="gross_payment_total"
              >
                <template #default="scope">
                  <money :amount="scope.row.gross_payment" />
                </template>
              </el-table-column>
              <el-table-column
                label="Leave"
                prop="leave"
              >
                <template #default="scope">
                  <money :amount="scope.row.leave" />
                </template>
              </el-table-column>
              <el-table-column
                label="Reimbursements"
                prop="total_reimbursements"
              >
                <template #default="scope">
                  <money :amount="scope.row.employee_data.reimbursements.total" />
                </template>
              </el-table-column>
              <el-table-column
                label="Deductions"
                prop="total_deduction"
              >
                <template #default="scope">
                  <money :amount="scope.row.deduction" />
                </template>
              </el-table-column>
              <el-table-column
                label="Superannuation"
                prop="superannuation"
              >
                <template #default="scope">
                  <money :amount="scope.row.employee_data.superannuation_employee" />
                </template>
              </el-table-column>
              <el-table-column
                label="Take Home Pay"
                prop="take_home_pay"
              >
                <template #default="scope">
                  <money :amount="scope.row.total_home_payment" />
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="Payments">
            <el-table
              ref="payments_table"
              :data="pay_run.transactions"
              style="width: 100%"
              stripe
            >
              <el-table-column
                label="Recipient"
                property="name"
                width="200px"
              >
                <template #default="scope">
                  <el-link
                    style="color: #409EFF"
                    @click="edit_employee(scope.row.employee_data.id)"
                  >
                    {{ scope.row.employee_data.name }}
                  </el-link>
                </template>
              </el-table-column>
              <el-table-column
                label="Bank Account"
                prop="bank_account"
              >
                <template #default="scope">
                  {{ scope.row.employee_data.bank_account }}
                </template>
              </el-table-column>
              <el-table-column
                label="Inland Revenue"
                prop="bank_account_inland_revenue"
              />
              <el-table-column
                label="PAYE"
                prop="total_deduction"
              >
                <template #default="scope">
                  {{ scope.row.employee_data.total_deduction }}
                </template>
              </el-table-column>
              <el-table-column
                label="Payment"
                prop="take_home_pay"
              >
                <template #default="scope">
                  <money :amount="scope.row.total_home_payment" />
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>

import {set_default_input} from '../../utilities/DefaultInput';
import {pay_run_transaction_monthly, pay_transaction_summary_sheet} from '../../api/pay_run_transactions';
import Money from '../shared/Money.vue';

export default {
  name: 'PayRunTransactions',
  components: {Money},
  data() {
    return {
      pay_runs_monthly: [],
      month_picked: new Date(new Date().getFullYear(), new Date().getMonth()).toLocaleDateString('en-us', { year:'numeric', month:'short'})
    };
  },
  created() {
    this.set_default_input();
    this.pay_run_transaction_monthly(false);
  },
  methods: {
    set_default_input,
    pay_run_transaction_monthly(picker) {
      pay_run_transaction_monthly(this, this.month_picked, picker).then((response)=> {
        this.pay_runs_monthly = response.data;
        if(response.data[0]  != undefined) {
          this.month_picked = response.data[0].month_picked;
        }
      });
    },
    pay_transaction_summary_sheet(pay_period_id){
      this.loading = true;
      pay_transaction_summary_sheet(this, pay_period_id).then((response) => {
        this.loading = false;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        let file_name = response.headers.get('content-disposition').split('filename=')[1].split(';')[0];
        file_name = file_name.replaceAll('"','');
        link.href = url;
        link.setAttribute('download', file_name || 'inland_revenue_summaries.csv');
        document.body.appendChild(link);
        link.click();
      }).catch(()=>{
        this.loading = false;
      });
    },
    edit_employee(id) {
      window.open(`/crm_users/${id}/edit#/employment_details`, '_blank');
    },

  }
};

</script>

<style scoped>
#pay-run-transactions{ min-height: 200px;}
th {font-size: 12px;font-weight: normal;color: gray;width: 25%;}
td, th {padding: 0px;}
.el-card { margin-bottom: 20px;}
</style>
