<template>
  <el-dialog
    :visible.sync="show"
    width="97%"
    title="Details of Invoice Summary"
    :close-on-click-modal="false"
    @closed="onCloseDialog"
  >
    <div>
      <el-input
        v-model="search.model_val"
        class="search__input"
        placeholder="INPUT NO OR NAME FOR ACCOUNTS HAVING AMOUNTS"
        @keyup.enter.native="onSearch"
      />
      &nbsp;
      <el-button
        type="success"
        size="small"
        @click="onSearch"
      >
        Search
      </el-button>
      &nbsp;
      <el-button
        v-show="search.param"
        type="danger"
        size="small"
        @click="onResetSearch"
      >
        Reset Search Result
      </el-button>
    </div>
    <div>
      Account #: {{ current_accounts_num }} / {{ total_accounts_num }} (Having Amount Due / Total Active)
    </div>
    <br>
    <div>
      <el-button
        v-show="invoice_summaries.length > 0"
        type="primary"
        size="small"
        @click="onDownloadSpreadSheet"
      >
        Export Spreadsheet
      </el-button>
    </div>
    <br>
    <el-table
      id="invoice-summary__details"
      ref="summaryTable"
      v-loading="loading"
      class="details__table"
      height="550"
      :data="invoice_summaries"
      :default-sort="{prop: 'amount_due', order: 'descending'}"
    >
      <el-table-column
        type="index"
      />
      <el-table-column
        prop="account_no"
        label="ACCOUNT NO"
        :min-width="7"
      >
        <template slot-scope="scope">
          <el-link
            :href="`/invoice_balancing/${scope.row.id}`"
            target="_blank"
          >
            {{ scope.row.account_no }}
          </el-link>
        </template>
      </el-table-column>
      <el-table-column
        prop="account_name"
        label="ACCOUNT NAME"
        :min-width="14"
      />
      <el-table-column
        prop="current"
        label="CURRENT"
        :sort-orders="sort_order"
        sortable
        :min-width="10"
      />
      <el-table-column
        prop="thirty"
        label="30 DAYS"
        :sort-orders="sort_order"
        sortable
        :min-width="10"
      />
      <el-table-column
        prop="sixty"
        label="60 DAYS"
        :sort-orders="sort_order"
        sortable
        :min-width="10"
      />
      <el-table-column
        prop="ninety"
        label="90 DAYS+"
        :sort-orders="sort_order"
        sortable
        :min-width="10"
      />
      <el-table-column
        prop="unassigned_credits"
        label="UNASSIGNED PAYMENTS"
        :sort-orders="sort_order"
        sortable
        :min-width="12"
      />
      <el-table-column
        prop="bad_debts"
        label="BAD DEBTS"
        :sort-orders="sort_order"
        sortable
        :min-width="10"
      />
      <el-table-column
        prop="amount_due"
        label="TOTAL AMOUNT"
        :sort-orders="sort_order"
        sortable
        :min-width="10"
      />
      <el-table-column
        prop="overdue_cnt"
        label="OVERDUE #"
        :sort-orders="sort_order"
        sortable
        :min-width="7"
      />
      <infinite-loading
        ref="infiniteLoading"
        slot="append"
        direction="bottom"
        force-use-infinite-wrapper=".el-table__body-wrapper"
        @infinite="OnInfinite"
      />
    </el-table>
  </el-dialog>
</template>

<script>
import {loadInvoiceSummaryIndex, downloadInvoiceSummariesSheet} from '../../../api/invoice_summary';

export default {
  name: 'DetailsDialog',
  props: {
    show_dialog: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      show: this.show_dialog,
      invoice_summaries: [],
      loading: false,
      sort_order: ['descending','ascending','descending'],
      search: {
        model_val : null,
        param: null
      },
      total_accounts_num: 0
    };
  },
  computed: {
    current_accounts_num() {
      return this.invoice_summaries.length;
    }
  },
  methods: {
    onCloseDialog() {
      this.$emit('update:show_dialog', false);
    },
    onSearch() {
      if(this.search.model_val == null || this.search.model_val.length <= 2) {
        this.$message({
          type: 'error',
          message: 'Must at least 3 characters'
        });
      } else {
        this.search.param = this.search.model_val;
        this.reset_scroll();
      }
    },
    onResetSearch() {
      this.search.model_val = null;
      this.search.param = null;
      this.reset_scroll();
    },
    onDownloadSpreadSheet() {
      this.loading = true;
      downloadInvoiceSummariesSheet(this, this.search.param).then((response) => {
        this.loading = false;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        let file_name = response.headers.get('content-disposition').split('filename=')[1].split(';')[0];
        file_name = file_name.replaceAll('"','');
        link.href = url;
        link.setAttribute('download', file_name || 'Invoice_Summaries.xls');
        document.body.appendChild(link);
        link.click();
      }).catch(()=>{
        this.loading = false;
      });
    },
    OnInfinite($state){
      this.loading = true;
      loadInvoiceSummaryIndex(this, this.search.param).then(({data})=>{
        this.invoice_summaries = data.accounts;
        this.total_accounts_num = data.number_accounts;
        $state.loaded();
        $state.complete();
        this.loading = false;
      }).catch(()=>{
        $state.complete();
        this.loading = false;
      });
    },
    reset_scroll() {
      this.invoice_summaries = [];
      this.total_accounts_num = 0;
      this.$refs.infiniteLoading.stateChanger.reset();
    }
  }
};
</script>

<style lang='scss'>
#invoice-summary__details .cell {
  word-break: break-word !important;
}
</style>

<style lang='scss' scoped>
.search__input {
  width: 60% !important;
}
.details__table {
  width: 100%;
}
</style>
