<template>
  <div class="object_layup">
    <el-row :gutter="10">
      <el-col :span="12">
        <el-checkbox
          v-model="data.checked"
          :label="data.node"
        >
          {{ data.node }}
          <small>(Width: {{ data.width }}, Height: {{ data.height }})</small>
        </el-checkbox>
      </el-col>
      <el-col :span="12">
        <el-input
          v-model.number="data.qty"
          style="width: 150px"
          size="mini"
          :disabled="!data.checked"
        >
          <template slot="prepend">
            Quantity:
          </template>
        </el-input>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'ObjectLayup',
  props: ['data'],
  data() {
    return {};
  },
  mounted() {
    let _this = this;
    setTimeout(function() {
      _this.set_inputs_to_default();
    }, 2000);
  },
  methods: {
    set_inputs_to_default() {
      let _this = this;
      Array.from(document.querySelectorAll('.object_layup .el-input')).forEach(
        function(e, i) {
          e.classList.add('browser-default');
          if (e.querySelector('input') != null)
            e.querySelector('input').className += ' browser-default';
        }
      );
    }
  }
};
</script>

<style></style>
