<template>
  <div>
    <b>Send Statement:</b>
    &nbsp;&nbsp;&nbsp;&nbsp;
    <el-input
      v-model="email_addr"
      class="input__width inputdiv"
      placeholder="Input Email Address"
    />
    <el-button
      type="success"
      size="mini"
      icon="el-icon-s-promotion"
      circle
      @click="onSubmit"
    />
  </div>
</template>

<script>
import {EventBus} from '../../../utilities/EventBus';
import {SendStatement} from '../../../api/statement';

export default {
  name: 'SendStatement',
  props: {
    init_send_to: {
      type: String,
      required: true,
    },
    account_id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      email_addr: this.init_send_to,
    };
  },
  methods: {
    onSubmit() {
      SendStatement(this,this.account_id,this.email_addr).then((response)=>{
        this.$message({
          type: 'success',
          message: response.data.message
        });
        EventBus.$emit('statementSent');
      });
    }
  }
};
</script>

<style lang='scss' scoped>
.input__width {
  width: 400px;
}
</style>
