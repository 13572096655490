<template>
  <div>
    <div
      v-loading="main_loading"
      class="card  "
    >
      <div class="card-content ">
        <span class="card-title">
          Sales KPI
        </span>
        <el-date-picker
          v-model="selected_date_range"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          style="width: auto !important;"
          :picker-options="pickerOptions2"
          @change="fill_data"
        />
        <el-select
          v-model="selected_roles"
          multiple
          collapse-tags
          style="margin-left: 20px;"
          placeholder="Select role(s)"
          @change="load_data = true"
          @visible-change="load_data == true ? fill_data() : '' "
        >
          <el-option
            v-for="role in roles_list"
            :key="role.id"
            :label="role.name"
            :value="role.id"
          />
        </el-select>
        <br>
        <div class="users_list_container">
          <table class="responsive-table striped">
            <thead>
              <tr>
                <th rowspan="2">
                  Username
                </th>
                <th colspan="2">
                  Approved
                </th>
                <th rowspan="2">
                  Approved (total)
                </th>
                <th rowspan="2">
                  Unapproved
                </th>
                <th rowspan="2">
                  Total
                </th>
                <th rowspan="2">
                  Percentage
                </th>
                <th rowspan="2">
                  Amount ($)
                </th>
              </tr>
              <tr>
                <th>Billable</th>
                <th>Free</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in users_data">
                <td>
                  <a
                    @click="
                      show_sales_kpi_tasks(user.user_id, user.user_name)
                    "
                  >{{ user.user_name }}</a>
                </td>
                <td><Time :is_hours="user.is_hours" :minutes="user.approved_billable" /></td>
                <td><Time :is_hours="user.is_hours" :minutes="user.approved_free" /></td>
                <td><Time :is_hours="user.is_hours" :minutes="user.total_approved" /></td>
                <td><Time :is_hours="user.is_hours" :minutes="user.total_unapproved" /></td>
                <td><Time :is_hours="user.is_hours" :minutes="user.total" /></td>
                <td>{{ user.percentage }}</td>
                <td>{{ user.total_contract }}</td>
              </tr>
            </tbody>
            <tfoot
              v-if="users_data.length > 0"
              style="font-weight: bold;"
            >
              <tr style="border-top: 1px solid gray">
                <td>TOTAL</td>
                <td><Time :is_hours="users_data[0].is_hours" :minutes="total_minutes('approved_billable')" /></td>
                <td><Time :is_hours="users_data[0].is_hours" :minutes="total_minutes('approved_free')" /></td>
                <td><Time :is_hours="users_data[0].is_hours" :minutes="total_minutes('total_approved')" /></td>
                <td><Time :is_hours="users_data[0].is_hours" :minutes="total_minutes('total_unapproved')" /></td>
                <td><Time :is_hours="users_data[0].is_hours" :minutes="total_minutes('total')" /></td>
                <td>
                  {{ round(total_minutes("percentage") / users_data.length) }} %
                </td>
                <td>${{ total_minutes("total_contract") }}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
    <modal
      name="sales_kpi_list"
      :height="700"
      :width="1200"
      :adaptive="true"
    >
      <div class="card ">
        <div class="card-content  ">
          <span
            class="card-title"
          >{{ list_title }}
            <hr></span>
          <div
            v-show="is_loading"
            class="progress"
          >
            <div class="indeterminate" />
          </div>
          <el-table
            :data="rows"
            style="width: 100%"
            height="600"
          >
            <el-table-column
              prop="id"
              :label="'id' | capitalize"
              sortable
            />
            <el-table-column
              prop="quote_type"
              :label="'quote_type' | capitalize"
              sortable
            />
            <el-table-column
              prop="account_name"
              :label="'account_name' | capitalize"
              sortable
            />
            <el-table-column
              prop="job_name"
              :label="'job_name' | capitalize"
              sortable
            />
            <el-table-column
              prop="total_allocated_billable"
              :label="'total_allocated_billable' | capitalize"
              sortable
            >
              <template slot-scope="scope">
                <Time :is_hours="scope.row.is_hours" :minutes="scope.row.total_allocated_billable" />
              </template>
            </el-table-column>
            <el-table-column
              prop="total_allocated_free"
              :label="'total_allocated_free' | capitalize"
              sortable
            >
              <template slot-scope="scope">
                <Time :is_hours="scope.row.is_hours" :minutes="scope.row.total_allocated_free" />
              </template>
            </el-table-column>
            <el-table-column
              prop="approval"
              :label="'approval' | capitalize"
              sortable
            />
            <el-table-column
              prop="total_contract"
              :label="'total_contract' | capitalize"
              sortable
            />
          </el-table>
        </div>
        <div class="card-action" />
      </div>
    </modal>
  </div>
</template>

<script>
import Time from '../shared/Time';
export default {
  name: 'SalesKpiDashboard',
  components: { Time },
  data() {
    return {
      load_data: false,
      columns: [],
      rows: [],
      users_data: [],
      roles_list: [],
      main_loading: false,
      selected_roles: [],
      list_title: '',
      is_loading: false,
      pickerOptions2: {
        shortcuts: [
          {
            text: 'Current month',
            onClick(picker) {
              const end = new Date();
              const start = new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                1
              );
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last week',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last month',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last 3 months',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last 6 months',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last year',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
      selected_date_range: ''
    };
  },
  computed: {
    current_date() {
      return new Date();
    },
    start_date() {
      return new Date(
        this.current_date.getFullYear(),
        this.current_date.getMonth(),
        1
      );
    },
    end_date() {
      return new Date(
        this.current_date.getFullYear(),
        this.current_date.getMonth(),
        this.current_date.getDate()
      );
    }
  },
  mounted() {
    this.selected_date_range = [this.start_date, this.end_date];
    //this.fill_data();
    this.load_roles_list();
  },
  methods: {
    load_roles_list() {
      let self = this;
      self.main_loading = true;
      this.$http.get('/roles_list_filtered').then(response => {
        this.roles_list = response.data.roles_list;
        let tmp_roles = this.roles_list.map(c => c.id);
        if (tmp_roles.length > 0) this.selected_roles = [tmp_roles[0]];
        self.main_loading = false;
        this.fill_data();
      });
    },
    fill_data() {
      let self = this;
      self.main_loading = true;
      self.load_data = false;
      this.users_data = [];
      this.$http
        .post('/get_sales_kpi_dashboard', {
          start_date: this.selected_date_range[0],
          end_date: this.selected_date_range[1],
          roles_ids: this.selected_roles
        })
        .then(response => {
          if (response.data.status == 200) {
            this.users_data = response.data.data;
            self.main_loading = false;
          }
        });
    },
    open_modal() {
      this.$modal.show('sales_kpi_list');
    },
    hide_modal() {
      this.$modal.hide('sales_kpi_list');
    },
    show_sales_kpi_tasks(user_id, user_name) {
      this.$http
        .post('/get_sales_kpi_list_dashboard', {
          user_id: user_id,
          start_date: this.selected_date_range[0],
          end_date: this.selected_date_range[1]
        })
        .then(response => {
          if (response.data.status == 200) {
            this.rows = response.data.data.rows;
            this.columns = response.data.data.columns;
            this.list_title = user_name + ' Sales KPI';
            this.open_modal();
          }
        });
    },
    total_minutes(col) {
      let total_val = this.users_data.reduce(function(total, item) {
        return total + parseFloat(item[col]);
      }, 0);
      return Math.round(total_val);
    },
    round(num) {
      return Math.round(num * 100) / 100;
    }
  }
};
</script>

<style scoped>
td,
th {
  text-align: center;
  padding: 10px;
}

th {
  font-weight: 800;
}

.header {
  background-color: #ddecf9;
}

.subheader {
  background-color: #e5f3ff;
}

.rows {
  background-color: aliceblue;
}

a {
  cursor: pointer;
}

th {
  font-weight: bold;
}

.users_list_container {
  max-height: 400px;
  overflow: auto;
}
</style>
