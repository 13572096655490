<template>
  <div id="leave-requests-user">
    <span
      v-if="leave_requests.length == 0"
      class="margin-left"
    >No Holidays and leave.</span>
    <el-table
      v-if="leave_requests.length > 0"
      ref="multipleTable"
      :data="leave_requests"
      style="width: 100%"
      stripe
    >
      <el-table-column
        label="From"
        property="from_date"
      >
        <template #default="scope">
          {{ scope.row.from_date_formatted }}
        </template>
      </el-table-column>
      <el-table-column
        label="To"
        property="to_date"
      >
        <template #default="scope">
          {{ scope.row.to_date_formatted }}
        </template>
      </el-table-column>

      <el-table-column
        label="Type"
        property="requestable"
      >
        <template #default="scope">
          {{ scope.row.requestable }}
        </template>
      </el-table-column>
      <el-table-column
        label="Type"
        property="type"
      >
        <template #default="scope">
          {{ scope.row.type }}
        </template>
      </el-table-column>
      <el-table-column
        label="hours/dollars"
      >
        <template #default="scope">
          <el-input-number
            v-if="editable[scope.$index]"
            v-model="leave_requests[scope.$index].value"
            size="small"
            :precision="2"
            :step="0.1"
          />
          <span v-else>{{ scope.row.value }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="Operations"
      >
        <template slot-scope="scope">
          <el-button
            v-if="editable[scope.$index]"
            size="mini"
            @click="update(scope.$index)"
          >
            Save
          </el-button>
          <el-button
            v-else
            size="mini"
            @click="set_editable(scope.$index)"
          >
            Edit
          </el-button>
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            circle
            @click="delete_record(scope.$index)"
          />
        </template>
      </el-table-column>
      <el-table-column
        label="Status"
      >
        <template slot-scope="scope">
          <el-select
            v-model="leave_requests[scope.$index].request_status_id"
            size="small"
            @change="save_request_status(leave_requests[scope.$index].id, leave_requests[scope.$index].request_status_id )"
          >
            <el-option
              v-for="item in request_statuses"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {leave_requests, update_record, delete_record} from '../../api/leave_requests';
import {set_default_input} from '../../utilities/DefaultInput';

export default {
  name: 'LeaveRequestsUser',
  props: {
    start: {
      type: [String],
      required: true
    },
    end: {
      type: [String],
      required: true
    },
    crm_user_id: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      leave_requests: [],
      editable: [],
      request_dialog: false,
      request_statuses: []
    };
  },
  watch: {
    show_dialog(val) {
      this.show = val;
    },
  },
  created() {
    this.get_leave_requests(false);
    this.get_request_statuses();
  },
  methods: {
    save_request_status(request_id, request_status_id){
      this.$http.patch(`/update_request_status/${request_id}`, {request_details: {request_status_id: request_status_id}}).then(() => {
        this.$message({
          type: 'success',
          message: 'Updated request status.',
        });
        this.$emit('reload_pay_run');
      });
    },
    get_request_statuses(){
      this.$http.get('/request_statuses')
        .then(response => {
          this.request_statuses = response.data;
        });
    },
    get_leave_requests(event){
      leave_requests(this, this.start, this.end, this.crm_user_id ).then((response)=> {
        this.leave_requests = response.data;
        this.next_tick_set_default();
        if(event == 'reload_leave_requests'){
          this.$emit('reload_pay_run');
        }
      });
    },
    set_editable(row) {
      if(this.editable.length === 0) {
        this.editable[row] = true;
        this.$set(this.editable, row, true);
        this.next_tick_set_default();
      }
    },
    update(index) {
      update_record(this, this.leave_requests[index].id, this.leave_requests[index]).then(()=> {
        this.editable = [];
        this.get_leave_requests();
        this.$emit('reload_pay_run');
      });
    },
    delete_record(index) {
      delete_record(this, this.leave_requests[index].id).then(()=> {
        this.leave_requests.splice(index, 1);
        this.get_leave_requests();
        this.$emit('reload_pay_run');
      });
    },
    next_tick_set_default(){
      this.$nextTick(() => {
        this.set_default_input();
      });
    },
    handle_close() {
      this.$emit('update:show_dialog', false);
    },
    set_default_input
  }
};

</script>

<style scoped>
  #leave-requests-user{
    width: 100%;
  }
  .margin-left {margin-left: 10px; }
</style>
