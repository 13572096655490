<template>
  <div class="sideMenu">
    <a href="#details"><i class="material-icons">view_headline</i></a>
    <a href="#stockitems"><i class="material-icons">assessment</i></a>
    <a href="#pos"><i class="material-icons">assignment_return</i></a>
    <a href="#joblist"><i class="material-icons">assignment</i></a>
    <a href="#communications"> <i class="material-icons">message</i></a>
    <a href="#locations"> <i class="material-icons">location_on</i></a>
  </div>
</template>

<script>
export default {
  name: 'Navigation',
  computed: {}
};
</script>
<style scoped>
a {
  padding: 5.5px 12px;
  display: block;
}
.router-link-active {
  background: white;
  padding: 5.5px 12px;
}
.nav-menu {
  color: #fff;
}
.sideMenu {
  position:fixed;
  top:40px;
}
.sideMenu a {
  color:#fff;
}
.sideMenu li {
  color:#fff;
}
.sideMenu li:hover {
  color:rgb(104, 102, 102);
}
.sideMenu a:hover {
  color:rgb(104, 102, 102);
}

</style>

