export const CrmUsersMixin = {
  created() {},
  data() {
    return {};
  },
  methods: {
    users_by_branch(branch_id) {
      return new Promise((resolve, reject) => {
        this.$http
          .get(`/users_by_branch/${branch_id}`)
          .then(function (response) {
            resolve(response);
          })
          .then(function (response) {
            reject(response);
          });
      });
    },
    check_user_availability(data) {
      return new Promise((resolve, reject) => {
        // data 
        // users_ids , start_time, time allocated , jp_ids to be excluded
        this.$http.post('/check_user_availability', {
          data: data
        }).then(response => {
          resolve(response);
        }, reason => {
          reject(reason);
        });
      });
    }
  }
};