<template>
  <div id="crm_user_details">
    <el-form
      ref="form"
      :model="crm_user_details"
      label-width="150px"
      autocomplete="off"
    >
      <el-form-item label="Leader">
        <el-select
          v-model="selected_leader_id"
          placeholder="Select"
          size="small"
          style="width: 280px;"
        >
          <el-option
            v-for="user in crm_users"
            :key="user.id"
            :label="user.user_name + ' ' + user.user_surname + '/' + user.title"
            :value="user.id"
          >
            <span style="float: left">{{ user.user_name }} {{ user.user_surname }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px;margin-left: 5px;">{{ user.title }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Employment type">
        <el-select
          v-model="selected_employment_type_id"
          placeholder="Select"
          size="small"
          style="width: 280px;"
        >
          <el-option
            v-for="type in employment_types"
            :key="type.id"
            :label="type.name"
            :value="type.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Job title">
        <el-input
          v-model="crm_user_details.title"
          size="small"
        />
      </el-form-item>
      <el-form-item label="First name">
        <el-input
          v-model="crm_user_details.user_name"
          size="small"
        />
      </el-form-item>
      <el-form-item label="Last name">
        <el-input
          v-model="crm_user_details.user_surname"
          size="small"
        />
      </el-form-item>
      <el-form-item label="Email">
        <el-input
          v-model="crm_user_details.user_email"
          size="small"
        />
      </el-form-item>
      <el-form-item label="Phone">
        <el-input
          v-model="crm_user_details.phone_number"
          size="small"
        />
      </el-form-item>
      <el-form-item label="Mobile">
        <el-input
          v-model="crm_user_details.mobile_number"
          size="small"
        />
      </el-form-item>
      <el-form-item label="Colour">
        <el-input
          v-model="crm_user_details.colour"
          size="small"
          autocomplete="new-password"
        />
      </el-form-item>
      <el-form-item label="Password">
        <el-input
          v-model="crm_user_details.password"
          size="small"
          type="password"
          autocomplete="new-password"
          placeholder="must be 6 to 14 characters"
        />
      </el-form-item>
      <el-form-item label="Confirmation">
        <el-input
          v-model="crm_user_details.password_confirmation"
          size="small"
          type="password"
          placeholder="Password confirmation"
          autocomplete="new-password"
        />
      </el-form-item>
      <el-form-item label="Personal email">
        <el-input
          v-model="crm_user_details.personal_email"
          size="small"
        />
      </el-form-item>
      <el-form-item label="Date of birth">
        <el-input
          v-model="crm_user_details.date_of_birth"
          size="small"
        />
      </el-form-item>
      <el-form-item label="Address">
        <el-input
          v-model="crm_user_details.address"
          size="small"
        />
      </el-form-item>
      <el-form-item label="Next of kin name">
        <el-input
          v-model="crm_user_details.next_of_kin_name"
          size="small"
        />
      </el-form-item>
      <el-form-item label="Next of kin phone">
        <el-input
          v-model="crm_user_details.next_of_kin_phone"
          size="small"
        />
      </el-form-item>
      <el-form-item label="Next of kin address">
        <el-input
          v-model="crm_user_details.next_of_kin_address"
          size="small"
        />
      </el-form-item>
      <el-form-item label="Kin relationship">
        <el-input
          v-model="crm_user_details.next_of_kin_relationship"
          size="small"
        />
      </el-form-item>
      <el-divider />
      <el-button
        type="primary"
        size="small"
        style="float:right;"
        @click="save_details"
      >
        Save
      </el-button>
    </el-form>
  </div>
</template>

<script>

export default {
  name: 'CrmUserDetails',
  props: {
    crm_user_id: {
      type: [String,Number],
      required: true
    }
  },
  data() {
    return {
      crm_user_details: {},
      crm_users: {},
      employment_types: {},
      selected_leader_id: '',
      selected_employment_type_id: ''
    };
  },
  watch:{
    crm_user_id(){
      this.get_crm_user_details();
    }
  },
  mounted() {
    this.get_crm_user_details();
    this.get_crm_users();
    this.get_employment_types();
  },
  created() {
  },
  methods: {
    get_employment_types(){
      this.$http.get('/employment_types').then((response) => {
        this.employment_types = response.data;
      });
    },
    get_crm_user_details(){
      this.$http.get('/show_crm_user_details/'+ this.crm_user_id).then((response) => {
        this.crm_user_details = response.data;
        this.selected_leader_id = response.data.leader_id;
        this.selected_employment_type_id = response.data.employment_type_id;
      });
    },
    get_crm_users(){
      this.$http.get('/show_crm_user_leaders').then((response) => {
        this.crm_users = response.data;
      });
    },
    save_details(){
      if( this.selected_leader_id ){
        this.crm_user_details.leader_id = this.selected_leader_id;
      }
      if( this.selected_employment_type_id ){
        this.crm_user_details.employment_type_id = this.selected_employment_type_id;
      }
      this.$http.patch(`/crm_users/${this.crm_user_id}`, {crm_user: this.crm_user_details }).then(() => {
        this.$message({
          message: 'User details updated.',
          type: 'success',
        });
        this.get_crm_user_details();
      }, (reject) => {
        this.$message({
          message: `${reject.data.errors.join(
            '<br>'
          )}`,
          dangerouslyUseHTMLString: true,
          type: 'error'
        });
        this.get_crm_user_details();
      });
    },
  }
};
</script>
<style scoped>
  #crm_user_details {
    max-width: 430px;
  }
</style>
