import { render, staticRenderFns } from "./MyKPIs.vue?vue&type=template&id=5aedbda8&scoped=true&"
import script from "./MyKPIs.vue?vue&type=script&lang=js&"
export * from "./MyKPIs.vue?vue&type=script&lang=js&"
import style0 from "./MyKPIs.vue?vue&type=style&index=0&id=5aedbda8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aedbda8",
  null
  
)

export default component.exports