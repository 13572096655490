<template>
  <el-card>
    <el-collapse v-model="activeNames">
      <el-collapse-item
        title="Job Description"
        name="1"
      >
        <div
          v-if="job_description"
          class="editor"
        >
          <jodit-editor
            v-model="job_description"
            :buttons="[]"
            :config="jodit_config"
          />
        </div>
        <div v-else>
          There is no Job Description.
        </div>
      </el-collapse-item>
    </el-collapse>
  </el-card>
</template>

<script>
export default {
  name: 'JobDesc',
  props: {
    job_desc: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      activeNames: ['1'],
      jodit_config : {
        enter: 'br',
        readonly: true
      },
      job_description: this.job_desc
    };
  }
};
</script>

<style lang="scss" scoped>
.editor {
  padding: 20px;
}
</style>