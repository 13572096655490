import {Request} from '../utilities/Request';

export function loadInvoiceBalance(this_obj, account_id, page) {
  return Request.get(
    this_obj,
    '/api/invoices',
    null,
    {
      account_id: account_id,
      page: page
    }
  );
}

export function loadTotalOverdueInvoice(this_obj) {
  return Request.get(
    this_obj,
    '/api/invoices/overdue'
  );
}

export function loadInvoiceOpen(this_obj, account_id) {
  return Request.get(
    this_obj,
    '/api/invoices/open',
    null,
    {
      account_id: account_id,
    }
  );
}
export function loadInvoiceDetails(this_obj, invoice_id) {
  return Request.get(
    this_obj,
    `/api/invoices/${invoice_id}`
  );
}

export function sendInvoice(this_obj, invoice_id, is_overdue=false) {
  return Request.post(
    this_obj,
    `/api/invoices/${invoice_id}/send_invoice`,
    null,
    {
      is_overdue: is_overdue
    }
  );
}

export function editInvoice(this_obj, id) {
  return Request.get(
    this_obj,
    `/api/invoices/${id}/edit`
  );
}

export function updateInvoice(this_obj, id, amount, status, date, description) {
  return Request.patch(
    this_obj,
    `/api/invoices/${id}`,
    null,
    {
      amount: amount,
      status: status,
      date: date,
      description: description
    }
  );
}

export function deleteInvoice(this_obj, id) {
  return Request.delete(
    this_obj,
    `/api/invoices/${id}`
  );
}

export function updateBadDebt(this_obj, id, is_bad_debt) {
  return Request.patch(
    this_obj,
    `/api/invoices/${id}/update_bad_debt`,
    null,
    {
      is_bad_debt: is_bad_debt
    }
  );
}
