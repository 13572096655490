<template>
  <div>
    <span>
      <!-- <p class="color-gray"><b>Page Notes:</b></p> -->
      <ul>
        <li
          v-for="(note,index) in page_notes"
          :key="index"
        >
          <span v-if="note.note">
            <span class="padding-left-5"><strong>{{ note.name }}:</strong></span>
            <span class="padding-left-10">{{ note.note }}</span>
          </span>
        </li>
      </ul>
      <!-- <el-divider /> -->
    </span>
 
    <span>
      <!-- <p class="color-gray"><b>Task Notes:</b></p> -->
      <ul>
        <li
          v-for="(note,index) in task_notes"
          :key="index"
        >
          <span v-if="note.alternative_description">
            <span class="padding-left-5"><strong>{{ note.name }}:</strong></span>
            <span class="padding-left-10">{{ note.alternative_description }}</span>
          </span>
        </li>
      </ul>
      <!-- <el-divider /> -->
    </span>
    <span>
      <!-- <p class="color-gray"><b>Stock Notes:</b></p> -->
      <ul>
        <li
          v-for="(note,index) in stock_notes"
          :key="index"
        >
          <span v-if="note.notes">
            <span><strong>{{ note.name }}:</strong></span>
            <span>{{ note.notes }}</span>
          </span>
        </li>
      </ul>
    </span>


    <span>
      <!-- <p class="color-gray"><b>Staff Comments:</b></p> -->
      <div id="staff-comments">
        <el-collapse ref="staff_comments">
          <el-collapse-item
            title="View Staff Comments"
          >
            <ul>
              <li
                v-for="(note,index) in entries"
                :key="index"
              >
                <span><i>{{ note.crm_user_name }} wrote on {{ note.updated }} :</i></span>
                <br>
                <span
                  class="padding-left-5"
                  v-html="note.entry"
                />
              </li>
            </ul>
          </el-collapse-item>
        </el-collapse>
      </div>

      <!-- <el-divider /> -->
    </span>
  </div>
</template>
<script>

import {working_file_notes} from '../../api/working_files.js';
import {entries} from '../../api/comments';
import {stock_notes} from '../../api/stock_items';
import {alternative_descriptions} from '../../api/job_proposals';

export default {
  name: 'AllNotesList',
  props: {
    proposal_id: {
      type: [String,Number],
      required: true
    }
  },
  data() {
    return {
      page_notes: [],
      entries: [],
      task_notes: [],
      stock_notes: []
    };
  },
  created() {
    this.load();
  },
  methods: {
    load(){
      this.working_file_pages_notes();
      this.get_entries();
      this.get_task_notes();
      this.get_stock_notes();
    },
    working_file_pages_notes(){
      working_file_notes(this, this.proposal_id).then((response)=> {
        this.page_notes = response.data;
      });
    },
    get_entries() {
      entries(this, this.proposal_id).then((response) => {
        this.entries = response.data;
      });
    },
    get_task_notes() {
      alternative_descriptions(this, this.proposal_id).then((response) => {
        this.task_notes = response.data;
      });
    },
    get_stock_notes() {
      stock_notes(this, this.proposal_id).then((response) => {
        this.stock_notes = response.data;
      });
    },
  }
};
</script>
<style scoped>
ul{}
.padding-left-5{ padding-left: 5px; }
.padding-left-10{ padding-left: 10px; }
.color-gray{color: gray;}
</style>
<style>
#staff-comments .el-collapse-item__header {line-height:0px;border:0 !important; background:#fff !important; border: 1px solid #f3f3f3}

</style>