<template>
  <div>
    <router-link to="/account">
      Account
    </router-link>

    <router-link to="/dashboard">
      Dashboard
    </router-link>

    <router-link to="/quotes">
      Quotes
    </router-link>

    <router-link to="/login-details">
      Login Details
    </router-link>

    <a :href="logout">
      Logout
    </a>
  </div>
</template>

<script>
export default {
  name: 'Navigation',
  computed: {
    logout() {
      return window.location.origin + '/client/logout';
    }
  }
};
</script>
<style scoped>
i {
  font-size: x-large !important;
  color: #00b4f0;
}

a {
  padding: 15.5px 20px;
  display: block;
}

.router-link-active {
  background: white;
  padding: 15.5px 20px;
}
</style>   