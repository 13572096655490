import Vue from 'vue';
import Vuex from 'vuex';
import {
  dispatch
} from 'rxjs/internal/observable/pairs';
import {
  stat
} from 'fs';

import {discountValue} from '../../utilities/Discout';

Vue.use(Vuex);
export const mm_to_px = 3.779527559;
export const xml_header =
  ' xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" version="1.1"';
Vue.mixin({
  methods: {
    truncate(str) {
      return str.replace(/-/g, '') + ' ...was truncaaaated!'; // example implementation
    },
  },
});
export const editor_store = new Vuex.Store({
  strict: false,
  state: {
    file_changed: false,
    editor_fullscreen: false,
    has_project_plan: false,
    selected_object: {},
    selected_objects: [],
    jobs_list: [],
    proposal_lists: [],
    project_plan_lists: [],
    non_conformance_report: [],
    page_settings: {
      size: 'Custom',
      width: '1000',
      height: '1000',
      margin: '3',
      screen_width: 1000 * mm_to_px,
      screen_height: 1000 * mm_to_px,
      screen_margin: 3 * mm_to_px,
      screen_height_ns: 1000 * mm_to_px,
      screen_width_ns: 1000 * mm_to_px,
      screen_margin_ns: 3 * mm_to_px,
      unit: 'mm',
    },
    is_workspace_proposal: false,
    grouped_files: [],
    grid_settings: {
      visible: true,
      unit: 'mm',
    },
    layers_tree: [],
    fonts_list: [],
    quote_types_list: [],
    locations_list: [],
    missing_fonts: [],
    proposal_id: '',
    proposal_summary: {
      is_matched_quote: false,
      total_matched_quote: 0,
    },
    proposal: {
      id: '',
      proposal_section_attributes: {},
      pdf_description: '',
      pre_description: '',
      message_references: '',
      mailbox_account_id: ''
    },
    working_file_id: '',
    files_list: [],
    stock_items_list: [],
    job_proposals_list: [],
    current_user_info: {},
    crm_users_list: [],
    notes_list: [],
    charge_pos_list: [],
    categories_list: [],
    total_sale: 0,
    scale: 1,
    client_view: false,
    discount: 0,
    is_hours: false,
    working_file_categories: [],
    active_working_file_category: ''
  },
  getters: {
    has_project_plan: (state) => {
      if (state.proposal.approved == 1) {
        if (
          state.job_proposals_list.filter(
            (c) =>
              c.job_name.trim().toLocaleLowerCase() == 'project plan' &&
            c.completed == false &&
            c.created_at < state.proposal.approved_at
          ).length > 0
        )
          return true;
      } else {
        if (
          state.job_proposals_list.filter(
            (c) =>
              c.job_name.trim().toLocaleLowerCase() == 'project plan' &&
            c.completed == false
          ).length > 0
        )
          return true;
      }
      return false;
    },
    jobs_list: (state) => {
      return state.jobs_list;
    },
    current_user_info: (state) => {
      return state.current_user_info;
    },
    proposal_lists: (state) => {
      return state.proposal_lists;
    },
    project_plan_lists: (state) => {
      return state.project_plan_lists;
    },
    non_conformance_report: (state) => {
      return state.non_conformance_report;
    },
    notes_list: (state) => {
      return state.notes_list;
    },
    stock_items_list: (state) => {
      return state.stock_items_list;
    },
    job_proposals_list: (state) => {
      return state.job_proposals_list;
    },
    crm_users_list: (state) => {
      return state.crm_users_list;
    },
    charge_pos_list: (state) => {
      return state.charge_pos_list;
    },
    categories_list: (state) => {
      return state.categories_list;
    },
    page_scale: (state) => {
      return state.scale;
    },
    total_sale: (state) => {
      let jp_total = state.job_proposals_list.filter(i => i.is_included === true).reduce(
        (acc, item) =>
          item.mark_as_deleted == 0 ?
            acc + item.time_allocated_labour * item.sale_price :
            acc,
        0
      );
      let stock_total = state.stock_items_list.filter(i => i.is_included === true).reduce(
        (acc, stock) =>
          stock.mark_as_deleted == 0 ?
            acc + parseFloat(stock.sale_price * stock.quantity) :
            acc,
        0
      );

      let charge_pos_total = state.charge_pos_list.filter(i => i.is_included === true).reduce(
        (acc, po) =>
          po.mark_as_deleted == 0 ? acc + parseFloat(po.sale_price) : acc,
        0
      );

      let total = (((jp_total + stock_total + charge_pos_total) * 100) / 100);
      state.total_sale = discountValue(total, state.discount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
      return state.total_sale;
    },
    page_settings: (state) => {
      return state.page_settings;
    },
    grid_settings: (state) => {
      return state.grid_settings;
    },
    selected_object: (state) => {
      return state.selected_object;
    },
    file_changed: (state) => {
      return state.file_changed;
    },    
    layers_tree: (state) => {
      return state.layers_tree;
    },
    fonts_list: (state) => {
      return state.fonts_list;
    },
    quote_types_list: (state) => {
      return state.quote_types_list;
    },
    locations_list: (state) => {
      return state.locations_list;
    },
    missing_fonts: (state) => {
      return state.missing_fonts;
    },
    proposal_id: (state) => {
      return state.proposal_id;
    },
    working_file_id: (state) => {
      return state.working_file_id;
    },
    files_list: (state) => {
      return state.files_list;
    },
    proposal: (state) => {
      return state.proposal;
    },
    scale: (state) => {
      return state.scale;
    },
    is_workspace_proposal: (state) => {
      let workspace = state.quote_types_list.filter(
        (item) => item.name == 'workspace'
      )[0];
      if (workspace == undefined) return false;
      if (workspace.id == state.proposal.quote_type_id) return true;
      else return false;
    },
    grouped_files: (state) => {
      let grouped_working_files = [];
      let main_stock_list = state.stock_items_list.filter(
        (c) => c.working_file_id == 0 || c.working_file_id == null
      );
      let main_jp_list = state.job_proposals_list.filter(
        (c) => c.working_file_id == 0 || c.working_file_id == null
      );

      let main_charge_pos_list = state.charge_pos_list.filter(
        (c) => c.working_file_id == 0 || c.working_file_id == null
      );

      if (main_stock_list.length > 0 || main_jp_list.length > 0 || main_charge_pos_list.length > 0) {
        let main_list = {
          id: 0,
          name: 'Main list',
          is_main_list: true,
          working_file: { template: { id: null, name: null }},
          position: state.files_list.length,
          stock_items_list: main_stock_list,
          job_proposals_list: main_jp_list,
          charge_pos_list: main_charge_pos_list,
          is_included: true,
          qty: 1,
          quantity_unit_id: null
        };
        grouped_working_files.push(main_list);
      }

      state.files_list.forEach((el, idx) => {
        let grp = {
          id: el.id,
          working_file: el,
          name: el.name,
          stock_items_list: [],
          job_proposals_list: [],
          matched_quote: el.matched_quote,
          is_main_list: false,
          consecutive: el.consecutive,
          position: el.position,
          is_included: el.is_included,
          qty: el.qty,
          quantity_unit_id: el.quantity_unit_id
        };
        grp.stock_items_list = state.stock_items_list.filter(
          (c) => c.working_file_id == el.id
        );
        grp.job_proposals_list = state.job_proposals_list.filter(
          (c) => c.working_file_id == el.id
        );
        grp.charge_pos_list = state.charge_pos_list.filter(
          (c) => c.working_file_id == el.id
        );
        grouped_working_files.push(grp);
      });


      grouped_working_files.sort((file_a, file_b) => {
        return file_a.position - file_b.position;
      });

      return grouped_working_files;
    },
    proposal_summary: (state) => {
      let total_matched_quote = 0;
      if (state.proposal.matched_quote > 0)
        total_matched_quote = state.proposal.matched_quote;
      else if (state.files_list.filter((c) => c.matched_quote > 0 && c.is_included === true).length > 0) {
        state.files_list.filter(c => c.is_included === true).forEach((el, idx) => {
          if (el.matched_quote > 0)
            total_matched_quote += parseFloat(el.matched_quote);
          else {
            total_matched_quote += state.stock_items_list
              .filter((c) => c.working_file_id == el.id)
              .reduce(
                (acc, stock) =>
                  stock.mark_as_deleted == 0 ?
                    acc + stock.quantity * stock.sale_price :
                    acc,
                0
              );
            total_matched_quote += state.job_proposals_list
              .filter((c) => c.working_file_id == el.id)
              .reduce(
                (acc, item) =>
                  item.mark_as_deleted == 0 ?
                    acc + item.time_allocated_labour * item.sale_price :
                    acc,
                0
              );
          }
        });
        total_matched_quote += state.stock_items_list
          .filter((c) => c.working_file_id == 0)
          .reduce(
            (acc, stock) =>
              stock.mark_as_deleted == 0 ?
                acc + stock.quantity * stock.sale_price :
                acc,
            0
          );
        total_matched_quote += state.job_proposals_list
          .filter((c) => c.working_file_id == 0)
          .reduce(
            (acc, item) =>
              item.mark_as_deleted == 0 ?
                acc + item.time_allocated_labour * item.sale_price :
                acc,
            0
          );
      }
      return {
        is_matched_quote: state.proposal.matched_quote > 0 ||
          state.files_list.filter((c) => c.matched_quote > 0 && c.is_included === true).length > 0,
        total_matched_quote: total_matched_quote.toFixed(2),
      };
    },
    client_view: (state) => {
      return state.client_view;
    },
    discount: (state) => {
      return state.discount;
    },
    is_hours: (state) => {
      return state.is_hours;
    },
    working_file_categories: (state) => {
      return state.working_file_categories;
    },
    get_active_working_file_category: (state) => {
      return state.active_working_file_category;
    },
    editor_fullscreen: (state) => {
      return state.editor_fullscreen;
    }
  },
  mutations: {
    ADD_NOTE(state, payload) {
      state.notes_list.unshift(payload);
    },
    SET_NOTES_LIST(state, payload) {
      state.notes_list = payload;
    },
    SET_STOCK_ITEMS_LIST(state, payload) {
      state.stock_items_list = payload;
    },
    UPDATE_STOCK_ITEM(state, payload) {
      const item = state.stock_items_list.find((x) => x.id == payload.id);
      Object.assign(item, payload);
    },
    UPDATE_JOB_PROPOSAL(state, payload) {
      const item = state.job_proposals_list.find((x) => x.id == payload.id);
      Object.assign(item, payload);
    },
    UPDATE_FILE_DETAILS(state, payload) {
      const item = state.files_list.find((x) => x.id == payload.id);
      Object.assign(item, payload);
    },
    SET_JOB_PROPOSALS_LIST(state, payload) {
      state.job_proposals_list = payload;
    },
    SET_CURRENT_USER_INFO(state, payload) {
      state.current_user_info = payload;
    },
    SET_CRM_USERS_LIST(state, payload) {
      state.crm_users_list = payload;
    },
    SET_JOBS_LIST(state, payload) {
      state.jobs_list = payload;
    },
    SET_PROPOSAL_LISTS(state, payload) {
      state.proposal_lists = payload;
    },
    SET_PROJECT_PLAN_LISTS(state, payload) {
      state.project_plan_lists = payload;
    },
    SET_NON_CONFORMANCE_REPORT(state, payload) {
      state.non_conformance_report = payload;
    },
    LOAD_CATEGORIES_LIST(state) {
      state.categories_list = [];
      Vue.http.get('/s_categories').then((response) => {
        state.categories_list = response.data;
      });
    },
    LOAD_CHARGE_POS_LIST(state) {
      state.charge_pos_list = [];
      Vue.http
        .post('/charge_pos_list', {
          proposal_id: state.proposal.id,
        })
        .then((response) => {
          state.charge_pos_list = response.body;
        });
    },
    SET_TOTAL_SALE(state, payload) {
      state.total_sale = payload;
    },
    SET_GRID_VISIBILITY(state, payload) {
      state.grid_settings.visible = payload;
    },
    SET_GRID_UNIT(state, payload) {
      state.grid_settings.unit = payload;
    },
    SET_PAGE_MARGIN(state, payload) {
      let margin = (state.page_settings.margin = payload || 0);
      state.page_settings.margin = margin;
      state.page_settings.screen_margin = margin * mm_to_px * state.scale;
    },
    SET_WORKING_FILE_ID(state, payload) {
      state.working_file_id = payload;
    },
    SET_PAGE_SIZE(state, payload) {
      let width = parseFloat(payload.split('x')[0]);
      let height = parseFloat(payload.split('x')[1]);
      // if(height > width)
      //     width = height;
      if (
        (width == '' || height == '') &&
        (state.page_settings.screen_width == 0 ||
          state.page_settings.screen_height == 0)
      ) {
        width = 210;
        height = 297;
      } else if (width == '' || height == '') {
        return true;
      } else {
        width = parseFloat(width);
        height = parseFloat(height);
      }

      if (payload == '' || payload == 'x') state.page_settings.size = 'Custom';
      let width_length = parseInt(width).toString().length;
      if (width_length >= 3) {
        let count = width_length > 3 ? width_length - 3 : 3 - width_length;
        state.scale =
          width_length > 3 ?
            1 / parseInt(1 + '0'.repeat(count)) :
            parseInt('1' + '0'.repeat(count));
      } else state.scale = 1;
      state.page_settings.screen_width = width * mm_to_px * state.scale;
      state.page_settings.screen_height = width * mm_to_px * state.scale;

      state.page_settings.screen_width_ns = width * mm_to_px;
      state.page_settings.screen_height_ns = width * mm_to_px;

      state.page_settings.width = width;
      state.page_settings.height = width;

      let scale = state.page_scale;
      let page_width = parseInt(state.page_settings.width);
      d3.selectAll('[data-original-scale]').attr('transform', function () {
        let area_width = parseInt(this.getAttribute('data-spec').split('x')[0]);

        if (
          parseFloat(this.getAttribute('data-original-scale')) ==
          parseFloat(scale)
        )
          return 'scale(1)';
        else {
          let diff = `${page_width}`.length - `${area_width}`.length;
          let final_scale = 1 / Math.pow(10, diff) || 1;
          return `scale(${final_scale})`;
        }
      });
    },
    // check if objects inside inner SVG container exceeds current viewBox specs
    SET_SELECTED_OBJECT(state, payload) {
      // clear first
      d3.selectAll('.selected_object_frame').remove();
      d3.selectAll('[data-selected]').attr('data-selected', null);
      d3.selectAll('.frame_selection').remove();

      state.selected_object = null;
      state.selected_object = payload;
    },
    SET_FILE_CHANGED(state, payload) {
      state.file_changed = payload;
    },
    SET_EDITOR_FULLSCREEN(state, payload) {
      state.editor_fullscreen = payload;
    },
    SET_QUOTE_TYPES_LIST(state, payload) {
      state.quote_types_list = payload;
    },
    SET_PROPOSAL_ID(state, payload) {
      state.proposal_id = payload;
    },
    SET_PROPOSAL(state, payload) {
      state.proposal = payload;
    },

    LOAD_FONTS_LIST(state) {
      state.fonts_list = [];
      Vue.http.get('/fonts').then(
        (response) => {
          if (response.data != null) {
            Array.from(response.data).forEach(function (el, index) {
              el['imported'] =
                d3
                  .selectAll(
                    '.svg_inner_container text[font-family=\'' + el.name + '\']'
                  )
                  .node() != null ?
                  true :
                  false;
              state.fonts_list.push(el);
            });
          }
        },
        (error) => {
          // no fonts
        }
      );
    },
    APPEND_FILES_LIST(state, payload) {
      state.files_list = state.files_list.concat(payload);
    },
    LOAD_FILES_LIST(state, payload) {
      Vue.http
        .post('/proposal_working_files', {
          proposal_id: state.proposal.id,
        })
        .then((response) => {
          state.files_list = response.body.files;
          if(payload) {
            this.commit('SET_WORKING_FILE_ID', payload);
          }
        });
    },
    REFRESH_MISSING_FONTS(state) {
      state.missing_fonts = [];

      let embedded_fonts = Array.from(
        d3.selectAll('.svg_inner_container .svg_layer text')._groups[0]
      )
        .map((c) => c.style['font-family'])
        .filter((value, index, self) => self.indexOf(value) === index);

      embedded_fonts.forEach(function (e, i) {
        let font = state.fonts_list.filter((c) => c.name == e)[0];
        if (font == null) state.missing_fonts.push(e);
        else {
          if (font.imported == false) this.include_font(font);
        }
      });
    },
    ADD_FONT(state, payload) {
      let id = Math.max.apply(
        Math,
        state.fonts_list.map(function (o) {
          return o.id;
        })
      );
      id++;
      let obj = payload;
      obj['id'] = id;
      obj['imported'] =
        d3
          .selectAll(
            '.svg_inner_container text[font-family=\'' + obj.name + '\']'
          )
          .node() != null ?
          true :
          false;
      state.fonts_list.push(obj);

      // Check if newly added font was listed in missing fonts and remove it
      let missing_font_idx = state.missing_fonts.indexOf(obj.name);
      if (missing_font_idx > -1) {
        state.missing_fonts.slice(missing_font_idx, 1);
      }
    },
    INCLUDE_FONT(state, payload) {
      d3.select('#proposal_workspace')
        .append('defs')
        .append('style')
        .attr('font_name', payload.name + '.' + payload.extension)
        .html(
          '@font-face { font-family: "' +
          payload.name +
          '"; src: url("font_file?name=' +
          payload.name +
          '.' +
          payload.extension +
          '") ;}'
        );
      let font = state.fonts_list.filter(
        (c) => c.name == payload.name && c.extension == payload.extension
      )[0];
      if (font != null) font.imported = true;
    },
    EXCLUDE_FONT(state, payload) {
      d3.selectAll(
        'style[font_name=\'' + payload.name + '.' + payload.extension + '\']'
      ).remove();
      let font = state.fonts_list.filter(
        (c) => c.name == payload.name && c.extension == payload.extension
      )[0];
      if (font != null) font.imported = false;
    },
    REMOVE_FONT(state, payload) {
      state.fonts_list = state.fonts_list.filter(function (value, index, arr) {
        return value.id != payload;
      });
    },
    LOAD_LAYERS_TREE(state, payload = false) {
      let layers = Array.from(d3.selectAll('.svg_inner_container .svg_layer')._groups[0]);
      let checked_layers = state.layers_tree.filter(c => c.checked == true).map(c => c.id);
      state.layers_tree = [];
      Array.from(layers).forEach(function (el, index) {
        let id = el.getAttribute('id');

        if (id == undefined || d3.selectAll(`#${id}`).nodes().length > 1) {
          let i = 1;
          let node_name = el.nodeName;
          while (
            d3.select(`#${node_name}_${i}`).node() != undefined &&
            i < 1000
          )
            i++;
          id = `${node_name}_${i}`;
          el.setAttribute('id', id);
        }

        let is_visible;
        if (payload == true) {
          is_visible = (el.getAttribute('data-name').toLowerCase() == 'base layer') ? true : false;
        } else
          is_visible = !el.classList.contains('hide_layer');

        if (is_visible == false && el.classList.contains('hide_layer') == false)
          el.classList.add('hide_layer');


        state.layers_tree.push({
          id: id, //index + 1,
          name: el.getAttribute('data-name'),
          selected_object: d3.select(`#${id}`).node(),
          checked: checked_layers.includes(id) ? true : false,
          active: el.classList.contains('active_layer'),
          hover: false,
          visible: is_visible,
          locked: el.getAttribute('data-locked'),
          selected_proposal_item_type: el.getAttribute('layer-type'),
          children: [],
        });
      });
    },
    SET_FILES_LIST(state, payload) {
      state.files_list = payload;
    },
    SET_LOCATIONS_LIST(state, payload) {
      state.locations_list = payload;
    },
    SET_CLIENT_VIEW(state, payload) {
      state.client_view = payload;
    },
    SET_DISCOUNT(state, payload) {
      state.discount = payload;
    },
    SET_IS_HOURS(state, payload) {
      state.is_hours = payload;
    },
    SET_WORKING_FILE_CATEGORIES(state, payload) {
      state.working_file_categories = payload;
    },
    SET_ACTIVE_WORKING_FILE_CATEGORY(state, payload) {
      state.active_working_file_category = payload;
    }
  },
  actions: {
    add_note(context, note) {
      context.commit('ADD_NOTE', note);
    },
    set_notes_list(context, notes_list) {
      context.commit('SET_NOTES_LIST', notes_list);
    },
    set_grid_visibility(context, visible) {
      context.commit('SET_GRID_VISIBILITY', visible);
    },
    set_grid_unit(context, unit) {
      context.commit('SET_GRID_UNIT', unit);
    },
    set_page_size(context, size) {
      context.commit('SET_PAGE_SIZE', size);
    },
    set_page_margin(context, margin) {
      context.commit('SET_PAGE_MARGIN', margin);
    },
    set_selected_object(context, selected_object) {
      context.commit('SET_SELECTED_OBJECT', selected_object);
    },
    set_file_changed(context, file_changed) {
      context.commit('SET_FILE_CHANGED', file_changed);
    },
    set_editor_fullscreen(context, editor_fullscreen) {
      context.commit('SET_EDITOR_FULLSCREEN', editor_fullscreen);
    },
    set_quote_types_list(context, quote_types_list) {
      context.commit('SET_QUOTE_TYPES_LIST', quote_types_list);
    },
    set_proposal_id(context, proposal_id) {
      context.commit('SET_PROPOSAL_ID', proposal_id);
    },
    set_proposal(context, proposal) {
      context.commit('SET_PROPOSAL', proposal);
    },
    load_layers_tree(context, payload) {
      context.commit(
        'LOAD_LAYERS_TREE', payload
      );
    },
    add_font(context, payload) {
      context.commit('ADD_FONT', payload);
    },
    remove_font(context, payload) {
      context.commit('REMOVE_FONT', payload);
    },
    include_font(context, payload) {
      context.commit('INCLUDE_FONT', payload);
    },
    exclude_font(context, payload) {
      context.commit('EXCLUDE_FONT', payload);
    },
    load_fonts_list(context) {
      context.commit('LOAD_FONTS_LIST');
    },
    load_files_list(context, payload=null) {
      context.commit('LOAD_FILES_LIST', payload);
    },
    set_working_file_categories(context, payload) {
      context.commit('SET_WORKING_FILE_CATEGORIES', payload);
    },
    append_files_list(context, payload) {
      context.commit('APPEND_FILES_LIST', payload);
    },
    refresh_missing_fonts(context) {
      context.commit('REFRESH_MISSING_FONTS');
    },
    clear_selection(context) {
      context.commit('SET_SELECTED_OBJECT', null);
    },
    set_working_file_id(context, payload) {
      context.commit('SET_WORKING_FILE_ID', payload);
    },
    set_files_list(context, payload) {
      context.commit('SET_FILES_LIST', payload);
    },
    set_locations_list(context, payload) {
      context.commit('SET_LOCATIONS_LIST', payload);
    },
    set_stock_items_list(context, payload) {
      context.commit('SET_STOCK_ITEMS_LIST', payload);
    },
    update_stock_item(context, payload) {
      context.commit('UPDATE_STOCK_ITEM', payload);
    },
    update_file_details(context, payload) {
      context.commit('UPDATE_FILE_DETAILS', payload);
    },
    update_job_proposal(context, payload) {
      context.commit('UPDATE_JOB_PROPOSAL', payload);
    },
    set_job_proposals_list(context, payload) {
      context.commit('SET_JOB_PROPOSALS_LIST', payload);
    },
    set_current_user_info(context, payload) {
      context.commit('SET_CURRENT_USER_INFO', payload);
    },
    set_crm_users_list(context, payload) {
      context.commit('SET_CRM_USERS_LIST', payload);
    },
    set_jobs_list(context, payload) {
      context.commit('SET_JOBS_LIST', payload);
    },
    set_proposal_lists(context, payload) {
      context.commit('SET_PROPOSAL_LISTS', payload);
    },
    set_project_plan_lists(context, payload) {
      context.commit('SET_PROJECT_PLAN_LISTS', payload);
    },
    set_non_conformance_report(context, payload) {
      context.commit('SET_NON_CONFORMANCE_REPORT', payload);
    },
    load_charge_pos_list(context) {
      context.commit('LOAD_CHARGE_POS_LIST');
    },
    load_categories_list(context) {
      context.commit('LOAD_CATEGORIES_LIST');
    },
    set_total_sale(context, payload) {
      context.commit('SET_TOTAL_SALE', payload);
    },
    set_client_view(context, payload) {
      context.commit('SET_CLIENT_VIEW', payload);
    },
    set_discount(context, payload) {
      context.commit('SET_DISCOUNT', payload);
    },
    set_is_hours(context, payload) {
      context.commit('SET_IS_HOURS', payload);
    },
    set_active_working_file_category(context, payload) {
      context.commit('SET_ACTIVE_WORKING_FILE_CATEGORY', payload);
    }
  },
});