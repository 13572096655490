<template>
  <div>
    <div class="toolbar">
      <div
        v-for="toolbar in data"
        :key="toolbar.id"
        class="toolbar_panel"
      >
        <div class="toolbar_title">
          <small>
            <b>{{ toolbar.title }}</b>
          </small>
        </div>
        <div
          v-for="btn in toolbar.buttons"
          :key="btn.id"
          class="toolbar_button"
        >
          <el-button
            :icon="btn.icon"
            :disabled="btn.enabled == false"
            size="mini"
            @click="button_click(btn.event)"
          />
          <el-tooltip
            :content="btn.tooltip"
            placement="left"
            effect="light"
          >
            <small>{{ btn.title }}</small>
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Toolbar',
  props: ['data'],
  data() {
    return {};
  },
  methods: {
    button_click(name) {
      this.$emit('toolbar_clicked', name);
    },
  },
};
</script>
<style scoped>
.toolbar_panel {
  border: 1px solid #bfbebe;
  display: block;
  margin-top: 3px;
}
.toolbar_title {
  background: #c1dde6;
  padding: 0px 4px;
}
.toolbar_button {
  display: inline-grid;
  margin: 3px;
  text-align: center;
  color: lightgrey;
}
.toolbar_button > small {
  width: 47px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #afaeae;
}
</style>
