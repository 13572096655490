import {Request} from '../utilities/Request';

export function SendStatement(this_obj, account_id, email_addr) {
  return Request.post(
    this_obj,
    '/api/statement_logs/send_statement',
    null,
    {
      account_id: account_id,
      email_addr: email_addr
    }
  );
}

export function loadStatementLogs(this_obj, account_id) {
  return Request.get(
    this_obj,
    '/api/statement_logs/',
    null,
    {
      account_id: account_id,
    }
  );
}

export function downloadStatementPDF(this_obj, id) {
  return Request.get(
    this_obj,
    `/api/statement_logs/${id}/download_statement`,
    null,
    null,
    null,
    'blob'
  );
}
