<template>
  <div id="scheduled_item">
    <h3 style="font-weight: 500;">SCHEDULE
      <hr>
    </h3>
    <div>
      <el-form label-position="top" :model="scheduled_item" ref="scheduled_item">
        <el-form-item size="mini">
          <el-col :span="10">
            <el-switch
              v-model="scheduled_item.is_active"
              active-text="Active"
              inactive-text="Inactive"
            ></el-switch>
          </el-col>
          <el-col :span="2"></el-col>
          <el-col :span="8">
            <el-date-picker
              type="date"
              placeholder="End date (optional)"
              v-model="scheduled_item.end_at"
              style="width: 100%;"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-col :span="10">
            <el-select
              v-model="scheduled_item.schedule_type_id"
              @change="update_days_list"
              size="small"
              placeholder="Select frequency"
            >
              <el-option
                v-for="type in schedule_types"
                :key="type.id"
                :label="type.type_name"
                :value="type.id"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="2"></el-col>
          <el-col :span="10">
            <el-select
              v-model="scheduled_item.repeat_every"
              v-show="scheduled_item.schedule_type_id > 1"
              size="small"
              placeholder="Select day"
            >
              <el-option v-for="item in days_list" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-col>
        </el-form-item>
      </el-form>
    </div>
    <div class="row">
      <hr>
      <div class="col m12">
        <el-button v-on:click.prevent="submit_form" ref="save_btn" size="small" class="right">SAVE</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorExplanation from "../shared/ErrorExplanation";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { editor_store, mm_to_px, xml_header } from "store/modules/editor";

export default {
  name: "ScheduledItem",
  store: editor_store,
  store: editor_store,
  data() {
    return {
      scheduled_item: {
        id: "",
        schedule_type_id: "",
        repeat_every: "",
        end_at: "",
        scheduleable_type: "Proposal",
        scheduleable_id: ""
      },
      schedule_types: [],
      days_list: []
    };
  },
  methods: {
    ...mapActions([]),
    update_days_list(e) {
      this.scheduled_item.repeat_every = "";
      switch (e) {
        case 1:
          this.days_list = [];
          break;
        case 2:
          this.days_list = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ];
          break;
        case 3:
          this.days_list = d3.range(1, 29);
          break;
      }
    },
    submit_form() {
      let self = this;
      this.$refs["scheduled_item"].validate(valid => {
        if (valid) {
          self.save();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    save() {
      let _this = this;
      this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$refs["save_btn"].disabled = true;
      let url = "/scheduled_items/";
      let request_type = "post";
      if (this.scheduled_item.id != "") {
        request_type = "patch";
        url = url + this.scheduled_item.id;
      }
      this.$http[request_type](url, {
        scheduled_item: this.scheduled_item
      }).then(
        response => {
          _this.$loading().close();
          this.$message({
            message: "Schedule has been updated successfully.",
            type: "success"
          });
          if (request_type == "post")
            _this.scheduled_item = response.body.scheduled_item;
        },
        function(response) {
          console.log(response);
          _this.$loading().close();
                    this.$message({
            message: "Error happened while updating the schedule.",
            type: "success"
          });
        }
      );
    },
    load_details() {
      let _this = this;
      this.scheduled_item.scheduleable_id = this.proposal.id;
      if (this.proposal.id != "")
        this.$http
          .get("/scheduled_item_by_proposal/" + this.proposal.id)
          .then(function(response) {

            _this.scheduled_item = response.body.scheduled_item;
          })
          .then(function(response) {});
    },
    load_schedule_types() {
      let _this = this;
      this.$http.get("/schedule_types").then(
        function(response) {
          _this.schedule_types = response.body;
        },
        function(response) {
          console.log(response);
        }
      );
    },
    set_default_input() {
      let _this = this;
      Array.from(document.querySelectorAll(".el-input__inner")).forEach(
        function(e, i) {
          e.classList.add("browser-default");
          if (e.querySelector("input") != null)
            e.querySelector("input").className += " browser-default";
        }
      );
    }
  },
  mounted() {
    this.load_schedule_types();
    //this.set_default_input();
  },
  computed: {
    ...mapGetters(["proposal"])
  },
  watch: {
    "proposal.id": function(newVal) {
      if (newVal != "") this.load_details();
    }
  }
};
</script>