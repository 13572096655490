<template>
  <div
    style="float: left;
    margin-left: 10px;
    margin-top: 4px;"
  >
    <el-button
      type
      icon="el-icon-notebook-2"
      size="small"
      circle
      @click="popup = true"
    />

    <div
      v-show="popup"
      class="pinboardpopup"
    >
      PIN BOARD
      <el-button
        type
        icon="el-icon-close"
        class="right"
        size="small"
        circle
        @click="popup = false"
      />

      <el-form
        ref="form"
        :model="form"
      >
        <el-input
          v-model="user_details.pinboard"
          type="textarea"
          style="min-height:350px"
          @change="save_data"
        />
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PinBoard',
  data() {
    return {
      popup: false,
      form: {
        desc: '',
      },

      user_details: {},
      user_id: '',
    };
  },
  watch: {},
  mounted() {
    this.fill_data();
  },
  methods: {
    save_data() {      
      this.$http.patch('/user_pinboard_data', { pinboard: this.user_details.pinboard }).then(
        (response) => {
          this.$message({
            message: 'Pinboard Updated',
            type: 'success',
          });
        },
        (response) => {
          this.$message({
            message: 'Unable to update',
            type: 'error',
          });
        }
      );
    },
    fill_data() {
      this.$http.get('/user_pinboard_data').then((response) => {
        this.user_details = response.data;
      });
    },
  },
};
</script>
<style>
.pinboardpopup {
  position: fixed;
  z-index: 9999;
  background: #ffffe0;
  left: 0;
  top: 10;
  border: 2px #f3f3f3;
  padding: 10px;
  width: 300px;
  resize: both;
  overflow: auto;
  box-shadow: 1px 1px 7px #888888;
}
.pinboardpopup .el-textarea__inner {
  min-height: 350px !important;
}
</style>
