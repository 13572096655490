<template>
  <div>
    Branches
    <hr/>
    <el-table :data="stock_item_branches" style="width: 100%">
      <el-table-column prop="id" label="id"></el-table-column>
      <el-table-column prop="name" label="Branch"></el-table-column>
      <el-table-column prop="stock_on_hand" label="Stock on hand"></el-table-column>
      <el-table-column prop="allocated" label="Allocated"></el-table-column>
      <el-table-column prop="available" label="Available"></el-table-column>

      <el-table-column label="Actions">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="text"
              @click="show_stock_adjust(scope.row)"
          >Adjust
          </el-button>
          <br>
          <el-button
              size="mini"
              type="text"
              v-show="scope.row.id != null "
              @click="show_stock_transaction(scope.row.id)"
          >Transactions
          </el-button>
          <br>
          <el-badge :is-dot="scope.row.recurring > 0">
            <el-button
                size="mini"
                type="text"
                v-show="scope.row.branch_id != null"
                @click="show_stock_recurring(scope.row.branch_id, scope.row.name)"
            >Recurring
            </el-button>
          </el-badge>
        </template>
      </el-table-column>
    </el-table>
    <br/>
    <el-dialog
        :visible.sync="stock_adjust_dialog"
        title="Stock Item adjustment"
        width="30%"
        :close-on-press-escape="false"
        :append-to-body=true
    >
      <el-form
          ref="stock_adjustment_form"
          :model="selected_stock_adjustment"
          :rules="rules"
          label-width="100px"
          size="mini"
      >
        <el-form-item label="Branch">{{ selected_stock_adjustment.branch_name }}</el-form-item>
        <el-form-item label="From branch" prop="from_branch_id">
          <el-checkbox
              v-model="selected_stock_adjustment.is_from_branch"
              @change="from_branch_available_changed"
          ></el-checkbox>
          <el-select
              v-show="selected_stock_adjustment.is_from_branch == true"
              v-model="selected_stock_adjustment.from_branch_id"
              placeholder="please select a branch"
              @change="from_branch_available_changed"
          >
            <el-option
                v-for="branch in filtered_branches"
                :key="branch.branch_id"
                :label="branch.name"
                :value="branch.branch_id"
            >
              <span style="float: left">{{ branch.name }}</span>
              <span
                  style="float: right; color: #8492a6; font-size: 13px"
              >{{ branch.stock_on_hand - branch.allocated }}</span>
            </el-option>
          </el-select>
          <label
              style="margin-left: 10px"
              v-show="selected_stock_adjustment.is_from_branch == true"
          >Available: {{ from_branch_available_stock }}</label>
        </el-form-item>

        <el-form-item
            label="Create task ?"
            v-show="selected_stock_adjustment.is_from_branch == true"
        >
          <el-checkbox v-model="selected_stock_adjustment.create_job"></el-checkbox>
        </el-form-item>
        <el-form-item
            label="Proposal #"
            v-show="selected_stock_adjustment.create_job == true && selected_stock_adjustment.is_from_branch == true"
            prop="proposal.id"
        >
          <el-autocomplete
              class="inline-input"
              v-model.number="selected_stock_adjustment.proposal.id"
              :fetch-suggestions="query_search"
              placeholder="Enter proposal number here"
              :trigger-on-focus="false"
              @select="handle_select"
          >
            <template slot-scope="{ item }">
              <span style="color: #8492a6;">#{{ item.id }} -</span>
              <span>{{ item.value }}</span>
            </template>
          </el-autocomplete>
        </el-form-item>
        <el-form-item
            label="Task"
            v-show="selected_stock_adjustment.create_job == true && selected_stock_adjustment.is_from_branch == true"
            prop="proposal.task_id"
        >
          <el-select
              v-model="selected_stock_adjustment.proposal.task_id"
              placeholder="please select a task"
          >
            <el-option
                v-for="item in tasks_list"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Quantity" prop="quantity">
          <el-input-number
              v-model="selected_stock_adjustment.quantity"
              :max="from_branch_available_stock"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="Description" prop="description">
          <el-input type="textarea" v-model="selected_stock_adjustment.description"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="stock_adjust_dialog = false">Cancel</el-button>
        <el-button size="small" type="primary" @click="update_stock_adjustment">Save</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="stock_transactions_dialog" title="Stock Item Transaction" width="70%"
               :append-to-body=true>
      <StockItemTransactions :stock_item_branch_id="selected_stock_branch_id"></StockItemTransactions>
    </el-dialog>
    <el-dialog :visible.sync="stock_recurring_dialog"
               :title="'Recurring / Auto Purchase Order for Branch ' + selected_branch_name"
               width="40%"
               :append-to-body=true
    >
      <StockItemRecurring :branch_id="selected_branch_id" :stock_item_id="parseInt(stock_item_id)"
                          v-on:close-recurring-dialog="close_stock_recurring"></StockItemRecurring>
    </el-dialog>
  </div>
</template>

<script>
import StockItemTransactions from '../stock_items/StockItemTransactions';
import StockItemRecurring from '../stock_items/StockItemRecurring';

export default {
  name: 'StockItemBranches',
  props: ['stock_item_id'],
  components: {StockItemTransactions, StockItemRecurring},
  data() {
    var check_from_branch = (rule, value, callback) => {
      if (
          value == undefined &&
          this.selected_stock_adjustment.is_from_branch == true
      ) {
        callback(new Error('Please select the branch'));
      } else {
        callback();
      }
    };

    var check_proposal = (rule, value, callback) => {
      if (
          this.selected_stock_adjustment.create_job == true &&
          value == undefined
      ) {
        callback(new Error('Please select a proposal'));
      } else {
        callback();
      }
    };
    var check_task = (rule, value, callback) => {
      if (
          this.selected_stock_adjustment.create_job == true &&
          value == undefined
      ) {
        callback(new Error('Please select a task'));
      } else {
        callback();
      }
    };
    return {
      selected_stock_branch_id: null,
      selected_branch_id: null,
      stock_transactions_dialog: false,
      stock_recurring_dialog: false,
      selected_branch_name: '',
      from_branch_available_stock: 100000000,
      tasks_list: [],
      stock_item_branches: [],
      filtered_branches: [],
      stock_adjust_dialog: false,
      selected_stock_adjustment: {
        id: null,
        is_from_branch: false,
        branch_id: null,
        branch_name: '',
        from_branch_id: null,
        quantity: 1,
        description: '',
        create_job: false,
        proposal: {id: null, task_id: null}
      },
      rules: {
        quantity: [
          {
            required: true,
            message: 'Please input quantity',
            trigger: 'blur'
          }
        ],
        description: [
          {
            required: true,
            message: 'Please input description',
            trigger: 'blur'
          }
        ],
        from_branch_id: [
          {
            validator: check_from_branch,
            trigger: 'blur'
          }
        ],
        'proposal.id': [
          {
            validator: check_proposal,
            trigger: 'blur'
          }
        ],
        'proposal.task_id': [
          {
            validator: check_task,
            trigger: 'blur'
          }
        ]
      }
    };
  },
  watch: {
    stock_item_id(old_id, new_id) {
      this.load_stock_branches();
    }
  },
  mounted() {
    this.load_stock_branches();
  },
  methods: {
    update_stock_adjustment() {
      let _this = this;
      this.$refs['stock_adjustment_form'].validate(valid => {
        if (valid) {
          let params = _this.selected_stock_adjustment;
          params.stock_item_id = _this.stock_item_id;
          _this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          _this.$http.post('/stock_item_branch_adjust', params).then(
              response => {
                _this.$loading().close();
                _this.load_stock_branches();
                _this.stock_adjust_dialog = false;
              },
              reason => {
                _this.$loading().close();
              }
          );
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    query_search(query_string, cb) {
      if (query_string.length >= 5) {
        // search proposals >= than write number
        this.$http
            .post('/search_proposals', {query: query_string})
            .then(response => {
              cb(response.body);
            });
      }
    },
    handle_select(e) {
      //fetch tasks related to current proposal category
      this.tasks_list = [];
      this.selected_stock_adjustment.proposal.id = e.id;
      this.selected_stock_adjustment.proposal.task_id = null;
      this.$http.get(`/tasks_by_proposal/${e.id}`).then(response => {
        this.tasks_list = response.body;
      });
    },
    clear_stock_form() {
      this.selected_stock_adjustment = {
        id: null,
        is_from_branch: false,
        branch_name: '',
        branch_id: null,
        from_branch_id: null,
        quantity: 1,
        description: '',
        create_job: false,
        proposal: {id: null, task_id: null}
      };
    },
    show_stock_transaction(id) {
      this.selected_stock_branch_id = id;
      this.stock_transactions_dialog = true;
    },
    show_stock_recurring(id, branch_name) {
      this.selected_branch_id = id;
      this.selected_branch_name = branch_name;
      this.stock_recurring_dialog = true;
    },
    close_stock_recurring() {
      this.stock_recurring_dialog = false;
    },
    show_stock_adjust(scope) {
      let _this = this;

      this.clear_stock_form();
      this.stock_adjust_dialog = true;
      this.stock_transactions_dialog = false;
      setTimeout(function () {
        _this.set_default_input();
      });
      this.selected_stock_adjustment.branch_name = scope.name;
      this.selected_stock_adjustment.id = scope.id;
      this.selected_stock_adjustment.branch_id = scope.branch_id;
      this.filtered_branches = [];

      this.filtered_branches = this.stock_item_branches.filter(
          item => item.branch_id != scope.branch_id && item.available > 0
      );
    },
    load_stock_branches() {
      this.$http.get(`/stock_item_branches_list/${this.stock_item_id}`).then(
          response => {
            this.stock_item_branches = response.data;
          },
          function (response) {
            console.log(response);
          }
      );
    },
    set_default_input() {
      let _this = this;
      Array.from(document.querySelectorAll('.el-input__inner')).forEach(
          function (e, i) {
            e.classList.add('browser-default');
            if (e.querySelector('input') != null)
              e.querySelector('input').className += ' browser-default';
          }
      );
    },
    from_branch_available_changed() {
      if (this.selected_stock_adjustment.is_from_branch == false) {
        this.from_branch_available_stock = 100000000;
        return;
      }

      let branch_id = this.selected_stock_adjustment.from_branch_id;
      if (branch_id != undefined) {
        let branch = this.stock_item_branches.filter(
            c => c.branch_id == branch_id
        )[0];
        if (branch != undefined)
          this.from_branch_available_stock = branch.available;
        else this.from_branch_available_stock = 0;
      } else this.from_branch_available_stock = 0;
    }
  },
  computed: {}
};
</script>

<style>
</style>