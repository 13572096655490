<template>
  <div
    v-if="child_support.tax_type"
    id="child-support"
  >
    <p class="headline">
      Deduction: <Money :amount="child_support.deduction" />
    </p>
  </div>
</template>
<script>
import Money from '../../shared/Money';
import {pay_run_child_support} from '../../../api/pay_runs';

export default {
  name: 'ChildSupport',
  components: {Money},
  props: {
    crm_user_id: {
      type: String,
      required: true
    },
    pay_period_start: {
      type: String,
      required: true
    },
    pay_period_end: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      child_support: {deduction: 0, tax_type: true}
    };
  },
  created(){
    this.$root.$on('gross_payment_total', data => {
      this.pay_run_child_support(data);
    });
  },
  methods: {
    pay_run_child_support(gross_payment_total) {
      if (this.child_support.deduction === 0) {
        pay_run_child_support(this, this.crm_user_id, this.pay_period_start, this.pay_period_end, gross_payment_total).then((response) => {
          this.child_support = response.data;
          this.$emit('calculate_cs_pay_run', `-${this.child_support.deduction}`);
        });
      }
    }
  }
};
</script>
<style scoped>
.headline {
  padding: 10px;
  color: white;
  background: #409EFF;
}
</style>
