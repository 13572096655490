<template>
  <div id="tender-layout">
    <el-container>
      <el-header>
        <el-row>
          <el-col :span="12">
            <div class="grid-content">{{ details.name }}</div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content" style="text-align: right; font-size:18px;">annexgroup</div>
          </el-col>
        </el-row>
      </el-header>
      <el-container>
        <el-aside width="100">
          <navigation></navigation>
        </el-aside>

        <el-main>
          <transition name="fade">
            <router-view></router-view>
          </transition>
        </el-main>
      </el-container>
      <el-footer>{{ current_year }} ANNEX GROUP</el-footer>
    </el-container>
  </div>
</template>

<script>
import Navigation from "./Navigation.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      logo: "/images/logo.png"
    };
  },
  created() {
    if (this.details.first_login == 0) {
      this.$router.push("/details");
    } else {
      this.$router.push("/requests");
    }
  },
  components: { navigation: Navigation },

  watch: {
    details(new_val) {
      if (new_val.first_login == 0) {
        this.$router.push("/details");
      } else {
        this.$router.push("/requests");
      }
    }
  },

  computed: {
    ...mapGetters(["details"]),
    current_year() {
      let d = new Date();
      return d.getFullYear();
    }
  }
};
</script>

<style>
#tender-container .el-container {
  width: 100%;
  height: 100%;
}

#tender-layout .el-aside {
  background-color: #000000;
  color: #333;
  text-align: center;

  height: calc(100vh - 100px);
}

#tender-layout .el-main {
  border: 0px;
  margin: 0px;
  padding: 0px;
  background: #ffffff;
}
#tender-layout .el-header {
  background: #000;
  min-width: 20vh;
  padding-top: 10px !important;
  color: #fff;
}
#tender-layout .el-footer {
  background: #000;
  min-width: 20vh;
  padding: 10px;
  font-size: 12px;
  color: #fff;
  text-align: right;
}
.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.25s;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>