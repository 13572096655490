<template>
  <div>
    <h3>
      <span style="font-weight: 500;color: #949292;">STAFF CALENDAR</span>

      <hr>
    </h3>

    <br>
    <el-date-picker
      v-model="selected_date_range"
      size="small"
      type="daterange"
      align="right"
      unlink-panels
      range-separator="To"
      start-placeholder="Start date"
      end-placeholder="End date"
      style="width: auto !important;"
      :picker-options="pickerOptions2"
    >
      <h1>Staff absentees statuess</h1>
    </el-date-picker>
    <el-button
      type="success"
      size="small"
      @click="fill_data"
    >
      Search
    </el-button>
    <ul>
      <li
        v-for="req in requests_data"
        :key="req.id"
      >
        <h2 class="datescal">
          {{ req.date }}
        </h2>
       
        <ul>
          <li
            v-for="record in req.data"
            :key="record.id"
            style="margin-bottom:15px;margin-left:10px; border-bottom: 1px dotted #f3f3f3; padding:10px"
          >
            <span style="margin-right:10px;font-weight:bold;">
              {{ record.user_name }}
            </span>
          
            <span style="margin-right:10px">(  {{ record.type }} ) </span>
            <span style="margin-right:10px"> {{ record.content }}</span>  
            <span
              style="margin-right:0px;font-size:12px;color:#999;  "
            >From
              {{ record.from_date }}
            </span>
            -
            <span
              style="margin-right:10px;font-size:12px;color:#999;  "
            > To:
              {{ record.to_date }}
            </span>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'UserRequests',
  data() {
    return {
      requests_data: [],
      selected_date_range: '',
      pickerOptions2: {
        shortcuts: [
          {
            text: 'Last 6 months',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last 3 months',
            onClick(picker) {
              const end = new Date();
              const start = new Date();

              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last month',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Current month',
            onClick(picker) {
              const end = new Date();
              const start = new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                1
              );
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Next week',
            onClick(picker) {
              const start = new Date();
              const end = new Date();
              end.setTime(end.getTime() + 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Next month',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Next 3 months',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(end.getTime() + 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Next 6 months',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(end.getTime() + 3600 * 1000 * 24 * 180);
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
    };
  },
  computed: {
    current_date() {
      return new Date();
    },
    start_date() {
      return new Date(
        this.current_date.getFullYear(),
        this.current_date.getMonth(),
        1
      );
    },
    end_date() {
      return new Date(
        this.current_date.getFullYear(),
        this.current_date.getMonth(),
        this.current_date.getDate()
      );
    },
  },
  mounted() {
    this.selected_date_range = [this.start_date, this.end_date];
  },
  methods: {
    fill_data() {
      let params = {
        start_date: this.selected_date_range[0],
        end_date: this.selected_date_range[1],
      };
      this.$http.post('/requests_by_range', params).then(
        (response) => {
          this.requests_data = response.data;
        },
        (response) => {
          console.log('Error !');
        }
      );
    },
  },
};
</script>

<style scoped>
.datescal {
  margin: 0px;
  padding:20px;
  background:#f3f3f3;
  color: #999 !important;
}

</style>