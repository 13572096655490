<template>
  <div id="statement_log">
    <h1>
      STATEMENTS LOG
      <el-tag type="info">
        {{ statement_logs.length }} logs
      </el-tag>
    </h1>
    <div v-if="statement_logs.length ==0">
      No Data
    </div>
    <div
      v-else
      class="scroll__list"
    >
      <el-row>
        <el-col
          :span="24"
          :xl="16"
        >
          <table style="width:100%">
            <tr
              v-for="(log, index) in statement_logs"
              :key="index"
            >
              <td>
                {{ log.sent_at }}
              </td>
              <td>
                <el-tag
                  v-if="log.monthly"
                  type="success"
                >
                  SENT MONTHLY
                </el-tag>
                <el-tag
                  v-else
                  type="success"
                >
                  SENT
                </el-tag>
              </td>
              <td>
                {{ log.email }}
              </td>
              <td>
                <el-button
                  type="success"
                  size="mini"
                  @click="onDownloadPDF(log)"
                >
                  DOWNLOAD PDF
                </el-button>
              </td>
            </tr>
          </table>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {downloadStatementPDF} from '../../../api/statement';

export default {
  name: 'StatementLogs',
  props: {
    statement_logs: {
      type: Array,
      required: true
    }
  },
  methods: {
    onDownloadPDF(log) {
      downloadStatementPDF(this,log.id).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', log.attachment_name);
        document.body.appendChild(link);
        link.click();
      });
    }
  }
};
</script>

<style lang='scss' scoped>
#statement_log {
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
}
.scroll__list {
  overflow:hidden;
  overflow-y: scroll;
  height: 130px;
}
/* if scrollbar need to shows always
!* width *!
::-webkit-scrollbar {
  width: 10px;
}

!* Track *!
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

!* Handle *!
::-webkit-scrollbar-thumb {
  background: #888;
}

!* Handle on hover *!
::-webkit-scrollbar-thumb:hover {
  background: #555;
}*/
</style>
