<template>
  <div>
    <div style="text-align: end;margin: 10px 0px;">
      <el-button
        v-show="show_mark_urgent"
        type="primary"
        size="mini"
        @click="mark_all_as_urgent"
      >
        Mark all tasks as urgent
      </el-button>
    </div>
    
    <el-row v-show="show_save == true">
      <el-col>
        <el-button
          class="right"
          type="primary"
          size="mini"
          @click="confirm_changes"
        >
          Confirm changes
        </el-button>
        <el-button
          class="right"
          type="info"
          size="mini"
          @click="reset_changes"
        >
          Reset changes
        </el-button>
      </el-col>
    </el-row>
    <el-row
      :gutter="10"
      style="padding: 10px; background:#f3f3f3"
    >
      <div v-show="proposal.completed != 1">
        <el-col
          :xs="20"
          :sm="20"
          :md="10"
          :lg="5"
          :xl="5"
          no-padding
        >
          <el-input
            v-model="list_name"
            class="normButtn inputdiv"
            placeholder="New List Name"
          />
        </el-col>
        <el-col
          :xs="4"
          :sm="4"
          :md="2"
          :lg="2"
          :xl="2"
          no-padding
        >
          <el-button
            type="info"
            size="small"
            plain
            @click="create_list"
          >
            + List
          </el-button>
        </el-col>
      </div>
      <el-col
        :xs="24"
        :sm="24"
        :md="12"
        :lg="12"
        :xl="12"
        style="margin-top:10px;"
      >
        <el-col
          :xs="4"
          :sm="4"
          :md="4"
          :lg="4"
          :xl="4"
        >
          <b><small> LIST ORDER: </small></b>
        </el-col>
        <el-col
          :xs="18"
          :sm="18"
          :md="18"
          :lg="18"
          :xl="18"
        >
          <draggable
            v-model="temp_proposal_lists"
            @change="change_list_order"
          >
            <div
              v-for="list in temp_proposal_lists.filter((c) => c.id != undefined)"
              :key="list.id"
              class="listDrag"
            >
              {{ list.name }}
            </div>
          </draggable>
        </el-col>
      </el-col>
    </el-row>
    <el-card
      v-for="(list, idx) in temp_list"
      :key="list.id"
      style="margin-bottom: 10px;"
    >
      <div
        slot="header"
        class="clearfix"
      >
        <h2>
          <el-button
            v-show="job_proposals_list.filter((c) => c.list_id == list.id).length == 0"
            round
            style="color:red "
            icon="el-icon-delete"
            circle
            @click="remove_list(list.id, idx)"
          />
    
          <p style="font-size:18px;color:#333;text-decorate:uppercase">
            <b>
              <!--- edit list title -->
              <div
                v-show="proposal.approved == 1"
                class="right"
              >
                <i class="el-icon-edit" />
                <el-switch
                  v-model="list_title_edit"
                  active-color="#333"
                  inactive-color="#f3f3f3"
                />
                <el-button
                  v-show="!taskaddarea"
                  type="success"
                  icon="el-icon-circle-plus"
                  circle
                  @click="taskaddarea = true"
                />
                <el-button
                  v-show="taskaddarea"
                  type="info"
                  icon="el-icon-circle-close"
                  circle
                  @click="taskaddarea = false"
                />
              </div>
              <div v-show="list_title_edit">
                <el-input
                  v-model="list.name"
                  class="inputButtonEdit"
                  @change="update_list_name(list)"
                />
              </div>
            
              <!--- end edit list title -->
            
              <span v-show="!list_title_edit">
                {{ list.name }}
                <el-tooltip
                  content="Only users in current job branch will be assigned."
                  placement="top-start"
                >
                  <el-button
                    v-show="list.id != undefined && list.id != ''"
                    :type="list.consecutive == true ? 'warning' : 'default'"
                    icon="el-icon-s-operation"
                    size="mini"
                    circle
                    @click="
                      list.consecutive = !list.consecutive;
                      show_save = true;
                    "
                  />
                </el-tooltip>
                <br>
              </span>
            </b>
          </p>
          <span v-show="!list_title_edit">
            {{ list.note }}
          </span>
        </h2>
        <AddJobsToList
          :list_id="list.id"
          @tasks_added="tasks_added"
        />
      </div>
    
      <table class="table table-striped el-table">
        <thead class="thead-dark">
          <tr>
            <th scope="col">
            </th>
            <th scope="col">
              Name
            </th>
            <th scope="col">
              Start
            </th>
            <th scope="col">
              End
            </th>
            <th scope="col">
              Allocated
            </th>
            <th scope="col">
              Adjusted
            </th>
            <th scope="col">
              Users
            </th>
            <th scope="col">
              Action
            </th>
          </tr>
        </thead>
    
        <draggable
          v-model="list.tasks"
          tag="tbody"
          class="list-group"
          group="jobslist"
          handle=".handle"
          :data-list-id="list.id"
          v-bind="dragOptions"
          @start="drag = true"
          @end="drag = false"
@sort="onSort" @add="onAdd"
        >
          <tr
            v-for="(item, idx) in list.tasks"
            :key="item.id"
            :class="{
              active_task: item.is_active,
              completed_task: item.completed,
              'list-group-item': 1 == 1,
              'success-row': item.completed,
            }"
            :data-task-id="item.id"
          >
            <td class="cell">
              <i
                v-show="item.completed != true"
                class="el-icon-d-caret handle drag-handler"
              />
            </td>
            <td class="cell">
              <div class="layer_name_container">
                <div>
                  <el-tooltip
                    placement="top-start"
                    :content="
                      item.layer_name == '' ? item.job_name : item.layer_name
                    "
                  >
                    <el-input
                      v-model="item.layer_name"
                      :placeholder="item.job_name"
                      size="mini"
                      style="min-width: 120px"
                      @change="update_layer_name(item)"
                    />
                  </el-tooltip>
                </div>
    
                <div>
                  <el-tooltip
                    v-show="item.time_frame_start == undefined"
                    placement="top"
                    content="Task timing is not defined, run 'reassign tasks' in calendar."
                  >
                    <i
                      class="el-icon-warning"
                      style="color: red; font-size: large;"
                    />
                  </el-tooltip>
                  <el-button
                    v-show="
                      item.completed == false &&
                        item.time_frame_start != undefined &&
                        editor_only == false
                    "
                    :type="item.is_date_locked == true ? 'warning' : 'default'"
                    icon="el-icon-lock"
                    size="mini"
                    circle
                    @click="update_task_lock_date(item)"
                  />
    
                  <el-tooltip
                    content="Follow previous task timing"
                    placement="top"
                  >
                    <el-button
                      v-show="
                        item.completed == false &&
                          item.time_frame_start != undefined &&
                          editor_only == false &&
                          item.is_date_locked == false
                      "
                      :type="item.follow_previous == true ? 'warning' : 'default'"
                      icon="el-icon-s-fold"
                      size="mini"
                      circle
                      @click="update_follow_previous(item)"
                    />
                  </el-tooltip>
                  <el-tooltip
                    content="Lock users"
                    placement="top"
                  >
                    <el-button
                      v-show="
                        item.completed == false &&
                          item.time_frame_start != undefined &&
                          editor_only == false &&
                          item.is_date_locked == false
                      "
                      :type="item.locked_users == true ? 'warning' : 'default'"
                      icon="el-icon-user"
                      size="mini"
                      circle
                      @click="update_locked_users(item)"
                    />
                  </el-tooltip>
                  <el-tag
                    v-show="item.is_urgent"
                    type="danger"
                    size="mini"
                    effect="dark"
                  >
                    URGENT
                  </el-tag>
                  <el-tag
                    v-show="item.is_initiated && proposal.approved == 0"
                    effect="dark"
                    size="mini"
                  >
                    INITITATED
                  </el-tag>
                </div>
              </div>
              <el-tag
                v-if="item.is_custom === true"
                effect="dark"
                type="info"
                size="mini"
              >
                CUSTOM TASK
              </el-tag>
              <span>
                {{
                  (item.suggested_users.length > 0
                    ? item.suggested_users
                    : item.crm_users
                  )
                    .map((c) => c.user_name)
                    .join(" , ")
                }}
              </span>
            </td>
            <td class="cell">
              <span v-if="editor_only == true">
                {{
                  item.custom_start_date != undefined
                    ? item.custom_start_date
                    : item.time_frame_start
                }}
              </span>
              <span v-else>
                <span
                  v-if="item.completed == true || item.is_date_locked == true"
                >
                  {{
                    item.custom_start_date != undefined
                      ? item.custom_start_date
                      : item.time_frame_start
                  }}
                </span>
                <el-date-picker
                  v-else
                  v-model="item.custom_start_date"
                  type="datetime"
                  :placeholder="item.time_frame_start"
                  format="d MMM yyyy hh:mma"
                  size="mini"
                  @change="custom_start_date_changed(item)"
                />
              </span>
            </td>
            <td class="cell">
              {{ item.time_frame_end }}
            </td>
            <td class="cell">
              <Time :is_hours="is_hours" :minutes="item.time_allocated_labour" />
              <br>
              <span v-show="item.is_outwork == true">
                <small>
                  <b>Outwork duration:</b>
                  <Time :is_hours="is_hours" :minutes="item.actual_duration" />
                </small>
              </span>
            </td>
            <td class="cell">
              <div v-if="editor_only == true">
                <Time
                  v-if="item.time_allocated_adjusted == undefined"
                  :is_hours="is_hours"
                  :minutes="item.time_allocated_labour"
                />
                <Time
                  v-else
                  :is_hours="is_hours"
                  :minutes="item.time_allocated_adjusted"
                />
              </div>
              <div v-else>
                <HoursInput
                  v-if="is_hours"
                  v-model="item.time_allocated_adjusted"
                  :disabled="item.completed ||item.is_date_locked == true || item.is_outwork == true"
                  :placeholder="item.time_allocated_labour"
                  style="width: 160px"
                  @change="activate_save_btn"
                />
                <el-input
                  v-else
                  v-model.number="item.time_allocated_adjusted"
                  :disabled="item.completed || item.is_date_locked == true || item.is_outwork == true"
                  :placeholder="item.time_allocated_labour"
                  size="mini"
                  style="width: 80px"
                  @change="activate_save_btn"
                />
              </div>
            </td>
            <td>
              <span v-if="editor_only == true">
                {{ item.users_count }}
              </span>
              <el-input-number
                v-else
                v-model="item.users_count"
                :disabled="
                  item.completed ||
                    item.is_date_locked == true ||
                    item.is_outwork == true
                "
                controls-position="right"
                size="mini"
                style="width: 90px"
                :min="1"
                :max="5"
                @change="show_save = true"
              />
              <el-tag
                v-show="item.is_outwork == true"
                type="warning"
                size="mini"
                effect="dark"
              >
                OUTWORK TASK
              </el-tag>
              <el-tooltip
                v-show="
                  item.is_date_locked == false &&
                    item.completed == false &&
                    item.is_outwork == false &&
                    editor_only == false
                "
                effect="dark"
                :content="
                  item.suggested_users.length > 0
                    ? item.suggested_users.map((c) => c.user_name).join(',')
                    : 'Select suggested user(s)'
                "
                placement="top-start"
              >
                <el-button
                  v-show="
                    item.completed == false && item.mark_as_deleted == false
                  "
                  :type="
                    item.suggested_users.length > 0 ? 'warning' : 'primary'
                  "
                  icon="el-icon-user-solid"
                  size="mini"
                  circle
                  @click="show_suggested_users(item)"
                />
              </el-tooltip>
              <el-tooltip
                v-show="
                  !(
                    item.completed == true ||
                    item.is_date_locked == true ||
                    item.is_outwork == true ||
                    item.is_custom === true ||
                    editor_only == true
                  )
                "
                content="Restrict users to current job's branch only"
              >
                <el-button
                  v-show="
                    item.completed == false &&
                      item.time_frame_start != undefined &&
                      item.suggested_users.length == 0
                  "
                  :type="item.branch_specific == true ? 'warning' : 'default'"
                  icon="el-icon-office-building"
                  size="mini"
                  circle
                  @click="
                    item.branch_specific = !item.branch_specific;
                    update_tasks_branch_specific(item);
                    show_save = true;
                  "
                />
              </el-tooltip>
            </td>
            <td class="cell">
              <el-button
                v-show="item.completed == false"
                type="danger"
                icon="el-icon-delete"
                plain
                size="mini"
                @click="remove_task(item, list, idx)"
              />
            </td>
          </tr>
        </draggable>
    
        <tfoot>
          <tr>
            <td colspan="4" />
            <td>
              <Time :is_hours="false" :minutes="time_allocated_labour(list)" /> |
              <Time :is_hours="true" :minutes="time_allocated_labour(list)" />
            </td>
            <td>
              <Time :is_hours="false" :minutes="time_allocated_adjusted(list)" /> |
              <Time :is_hours="true" :minutes="time_allocated_adjusted(list)" />
            </td>
            <td>
              <span v-html="time_allocated_difference(list)" />
            </td>
          </tr>
        </tfoot>
      </table>
    </el-card>
    <el-dialog
      v-loading="project_plan_loading"
      title="SUGGESTED PLAN"
      :visible.sync="plan_dialog"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="80%"
    >
      <SuggestedPlan
        :proposal="proposal"
        :project_plan_lists="project_plan_lists"
        :suggested_tasks="suggested_tasks"
        :suggested_deadline="suggested_deadline"
        :show_suggested_users="false"
        @timer_expired="timer_expired"
      />
      <el-row>
        <el-col>
          <hr>
          <el-button
            type="info"
            size="mini"
            @click="plan_dialog = false"
          >
            Cancel
          </el-button>
          <el-button
            type="primary"
            class="right"
            size="mini"
            @click="confirm_plan"
          >
            Confirm
          </el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <el-dialog
      title="Users"
      :visible.sync="users_dialog"
      width="30%"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <b>CURRENT USERS</b>
      <hr>
      <el-tag
        v-for="user in current_item.crm_users"
        :key="user.id"
        size="mini"
      >
        {{ user.user_name }}
      </el-tag>
      <br>
      <br>
      <br>
      <b>SUGGESTED USERS</b>
      <el-button
        type="primary"
        class="right"
        size="mini"
        @click="add_user"
      >
        Add user
      </el-button>
      <el-select
        v-model="selected_crm_user"
        class="right inputdiv right"
        placeholder="Add users"
        size="mini"
      >
        <el-option
          v-for="mainuser in crm_users_list"
          :key="mainuser.id"
          :label="mainuser.user_name"
          :value="mainuser.id"
        />
      </el-select>
    
      <br>
      <draggable
        v-model="current_item.suggested_users"
        tag="tbody"
        class="list-group"
        group="userslist"
        handle=".user_tag"
        v-bind="dragOptions"
      >
        <el-tag
          v-for="user in current_item.suggested_users"
          :key="user.id"
          size="mini"
          class="user_tag"
          closable
          style="margin: 3px; display: table;"
          @close="handleClose(user)"
        >
          {{ user.user_name }}
        </el-tag>
      </draggable>
    
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="users_dialog = false">Ok</el-button>
        <!-- <el-button type="primary" @click="users_dialog = false"
                                                                                              >Confirm</el-button
                                                                                            > -->
      </span>
    </el-dialog>
    <el-dialog
      title="Conflicts"
      :visible.sync="conflicts_dialog"
      width="60%"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    />
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { editor_store } from 'store/modules/editor';
import AddJobsToList from 'components/job_views/sections/AddJobsToList.vue';
import SuggestedPlan from 'components/job_views/project_plan/SuggestedPlan.vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { ProposalMixin } from 'mixins/ProposalMixin.js';
import { CrmUsersMixin } from 'mixins/CrmUsersMixin.js';
import Time from '../../shared/Time';
import HoursInput from '../../shared/HoursInput';

import { display_time } from '../../../utilities/Time';

export default {
  name: 'ProjectPlanList',
  components: { draggable, AddJobsToList, SuggestedPlan, Time, HoursInput },
  mixins: [ProposalMixin, CrmUsersMixin],
  props: ['editor_only'],
  store: editor_store,
  data() {
    return {
      drag: false,
      taskaddarea: false,
      list_title_edit: false,
      list_name: '',
      conflicts_dialog: false,
      timer_id: '',
      new_plan_list: [],
      selected_crm_user: '',
      users_dialog: false,
      plan_dialog: false,
      suggested_tasks: [],
      suggested_deadline: '',
      current_item: {},
      // current_users: [],
      // suggested_users: [],
      show_save: false,
      select_all: false,
      temp_list: [],
      temp_proposal_lists: [],
      project_plan_loading: false,
    };
  },
  mounted() {
    this.reload_list();
  },
  computed: {
    ...mapGetters([
      'proposal',
      'job_proposals_list',
      'proposal_lists',
      'project_plan_lists',
      'crm_users_list',
      'is_hours'
    ]),
    pending_tasks() {
      return this.job_proposals_list.filter(
        (c) =>
          c.completed == false &&
                c.is_urgent == false &&
                c.mark_as_deleted == false
      );
    },
    show_mark_urgent() {
      return this.pending_tasks.length > 0;
    },
    // current_deadline() {

    //   if (this.job_proposals_list.length == 0) return "";

    //   var sorted = this.job_proposals_list.sort(function(a, b) {
    //     // Turn your strings into dates, and then subtract them
    //     // to get a value that is either negative, positive, or zero.
    //     return new Date(b.time_frame_end) - new Date(a.time_frame_end);
    //   });
    //   return sorted[sorted.length - 1].time_frame_start;
    // },
    users_list() {
      let _this = this;
      return this.crm_users_list.filter(
        (c) =>
          (_this.current_item.suggested_users || [])
            .map((c) => c.id)
            .indexOf(_this.selected_crm_user) == -1
      );
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
    total_allocated() {
      return 0;
    },
    total_adjusted() {
      return 0;
    },
  },
  methods: {
    ...mapActions(['set_project_plan_lists', 'set_proposal_lists']),
    mark_all_as_urgent() {
      this.$http
        .patch(`/mark_all_tasks_as_urgent/${this.proposal.id}`)
        .then((response) => {
          this.$message({
            type: 'success',
            message: 'Task have been updated successfully.',
          });
          this.pending_tasks.forEach((el, idx) => {
            el.is_urgent = true;
          });
          this.temp_proposal_lists.forEach((el, idx) => {
            el.tasks
              .filter((c) => c.completed == false)
              .forEach((task) => {
                task.is_urgent = true;
              });
          });

          this.temp_list.forEach((el, idx) => {
            el.tasks
              .filter((c) => c.completed == false)
              .forEach((task) => {
                task.is_urgent = true;
              });
          });
        });
    },
    create_list() {
      if (this.list_name.trim() == '') {
        this.$message({
          type: 'warning',
          message: 'No list name',
        });
        return;
      }
      let params = {
        list_name: this.list_name,
        id: this.proposal.id,
      };
      this.add_job_list(params).then(
        (response) => {
          this.$message({
            type: 'success',
            message: 'List has been added',
          });

          // update lists
          let list = Object.assign([], this.proposal_lists);
          let idx =
                        this.proposal_lists.length == 0 ?
                          0 :
                          this.proposal_lists.length - 1;

          if (list.filter((c) => c.id == null) != undefined)
            list.splice(idx, 0, response.data);
          else list.pop(response.data);

          // Update main list
          this.set_proposal_lists(list);
          // Update temp prop list
          this.temp_proposal_lists.splice(idx, 0, response.data);
          // Update temp list
          this.temp_list.splice(idx, 0, response.data);
          this.list_name = '';
        },
        (response) => {
          this.$message({ type: 'error', message: response.data });
        }
      );
    },
    update_job_list_name(list, id, name) {
      list.forEach((el, idx) => {
        if (el.id == id) {
          el.name = name;
          return;
        }
      });
    },
    update_list_name(list) {
      let params = {
        id: list.id,
        list_name: list.name,
      };
      this.update_job_list(params).then(
        (response) => {
          //this.load_lists();
          this.update_job_list_name(this.proposal_lists, list.id, list.name);
          this.update_job_list_name(
            this.temp_proposal_lists,
            list.id,
            list.name
          );
          this.update_job_list_name(this.temp_list, list.id, list.name);
          this.$message({
            type: 'success',
            message: 'List name has been updated',
          });
        },
        (response) => {}
      );
    },
    update_list(list, ordered_list) {
      list.forEach((el, idx) => {
        let elem = ordered_list.filter((c) => c.id == el.id)[0];
        if (elem != undefined) el.position = elem.position;
      });
      list = list.sort(this.compare);
    },
    compare(a, b) {
      if (a.position < b.position) {
        return -1;
      }
      if (a.position > b.position) {
        return 1;
      }
      return 0;
    },
    change_list_order() {
      let _this = this;
      let ordered_list = this.temp_proposal_lists
        .filter((c) => c.id != undefined)
        .map((el, idx) => {
          return { id: el.id, position: idx };
        });

      let params = {
        ordered_list: ordered_list,
        proposal_id: this.proposal_id,
      };

      this.$http.post('/update_lists_position', params).then((response) => {

        this.show_save = true;
        this.update_list(this.temp_proposal_lists, ordered_list);
        this.update_list(this.proposal_lists, ordered_list);
        this.update_list(this.temp_list, ordered_list);
      });
    },
    update_tasks_branch_specific(item) {
      this.temp_list.forEach((el, idx) => {
        el.tasks
          .filter((c) => c.job_id == item.job_id && c.completed == false)
          .forEach((task, idx) => {
            task.branch_specific = item.branch_specific;
          });
      });
    },
    timer_expired() {
      this.plan_dialog = false;
    },
    update_layer_name(item) {
      // item
      let params = {};
      params['layer_name'] = item.layer_name;
      this.$http
        .post('/update_task_attribute/' + item.id, {
          task_attribute: params,
        })
        .then(
          (response) => {
            this.$message({
              message: 'Updated successfully.',
              type: 'success',
            });
          },
          function(response) {
            this.$message({
              message: 'Error happened while retreiving the file.',
              type: 'error',
            });
          }
        );
    },
    custom_start_date_changed(task) {
      this.show_save = true;
      if (task.custom_start_date == '')
        task.time_frame_end = task.time_frame_end_original;
      this.set_default_input();
    },
    update_locked_users(task) {
      this.$http
        .post('/update_task_attribute/' + task.id, {
          task_attribute: { locked_users: !task.locked_users },
        }).then(response => {
          task.locked_users = !task.locked_users;
          this.$message({
            message: 'Updated successfully',
            type: 'success'
          });
          this.show_save = true;
        },
        reason => {
          this.$message({
            message: 'Error happened',
            type: 'error'
          });

        });
    },
    update_follow_previous(task) {

      this.$http
        .post('/update_task_attribute/' + task.id, {
          task_attribute: { follow_previous: !task.follow_previous },
        }).then(response => {
          task.follow_previous = !task.follow_previous;
          this.$message({
            message: 'Updated successfully',
            type: 'success'
          });
          this.show_save = true;
        },
        reason => {
          this.$message({
            message: 'Error happened',
            type: 'error'
          });

        });

    },
    update_task_lock_date(task) {
      this.show_save = true;
      let locked = !task.is_date_locked;
      // skip if unlocking
      if (locked == false) {
        task.is_date_locked = !task.is_date_locked;
        this.set_default_input();
        return;
      }

      // Assure users count is equal to suggested/assigned users
      if (
        (task.suggested_users.length > 0 &&
                    task.suggested_users.length != task.users_count) ||
                (task.suggested_users.length == 0 &&
                    task.crm_users.length != task.users_count)
      ) {
        this.$message({
          type: 'warning',
          message: 'Current/suggested users should be equal to users count to lock a task.',
        });
        return;
      }

      let users_ids =
                task.suggested_users.length > 0 ?
                  task.suggested_users.map((c) => c.id) :
                  task.crm_users.map((c) => c.id);
      let start_date =
                task.custom_start_date != undefined ?
                  task.custom_start_date :
                  task.time_frame_start;
      let time_allocated =
                task.time_allocated_adjusted == undefined ||
                task.time_allocated_adjusted == '' ?
                  task.time_allocated_labour :
                  task.time_allocated_adjusted;
      //user_id , start_time, time allocated , jp_ids
      if (start_date == '' || start_date == undefined) {
        this.$message({
          type: 'warning',
          message: 'Cannot lock task as start date is not defined.',
        });
        return;
      } else if (start_date < new Date()) {
        this.$message({
          type: 'warning',
          message: 'Cannot lock task as start date is in the past, please update the date.',
        });
        return;
      }
      let params = {
        users_ids: users_ids,
        jp_ids: [task.id],
        start_date: start_date,
        time_allocated: time_allocated,
      };
      let loading = this.$loading({
        lock: true,
        text: 'Checking conflicts...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      this.check_user_availability(params).then(
        (response) => {
          let selected_users =
                        task.suggested_users.length > 0 ?
                          task.suggested_users :
                          task.crm_users;
          if (response.data.available == true) {
            task.is_date_locked = !task.is_date_locked;
            task.crm_users = selected_users.slice(0, task.users_count);
            this.set_default_input();
            task.time_frame_end = response.data.end_date;
            this.$message({
              type: 'success',
              message: 'Task has been locked successfully.',
            });
          } else {
            {
              let msg =
                                'Assigned/suggested user(s) already have other task(s). To proceed with conflict press \'Yes\' or \'No\' to cancel ?';
              let occupied_slots = response.data.occupied_slots.map(
                (c) =>
                  `<small>- <b>${c.user_name}:</b> ${
                    c.proposal_job_name
                  } [<a target="_blank" href="/job_view?id=${
                    c.proposal_id
                  }" >#${c.proposal_id}</a>] between ${new Date(
                    c.start_date
                  ).toLocaleString()} & ${new Date(
                    c.end_date
                  ).toLocaleString()}</small>`
              );
              msg += '<br>' + occupied_slots.join('<br>');
              this.$confirm(msg, 'Conflicts', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'warning',
                dangerouslyUseHTMLString: true,
              }).then(() => {
                task.is_date_locked = !task.is_date_locked;
                task.time_frame_end = response.data.end_date;
                this.set_default_input();
              });
            }
          }
          loading.close();
        },
        (reason) => {
          this.$message({ type: 'error', message: reason });
          loading.close();
        }
      );
    },
    confirm_changes() {
      let data = {
        tasks_lists: this.prepare_data(),
        proposal_id: this.proposal.id,
      };

      let _this = this;
      this.suggested_tasks = [];
      this.suggested_deadline = '';
      let loading = this.$loading({
        lock: true,
        text: 'Preparing suggested plan...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });

      this.get_suggested_plan(data).then(
        (response) => {
          this.plan_dialog = true;
          _this.suggested_deadline = response.suggested_deadline;
          _this.suggested_tasks = _this.temp_list.map((c) => {
            return {
              id: c.id,
              name: c.name,
              tasks: _this.format_suggested_plan_tasks(response.suggested_plan.filter((l) => l.list_id == c.id)),
            };
          });
          loading.close();
        },
        (reason) => {
          loading.close();
          this.$message({
            type: 'warning',
            dangerouslyUseHTMLString: true,
            message: `Check below errors: <br> ${reason}`,
          });
        }
      );
    },
    format_suggested_plan_tasks(tasks) {
      var tmp_start, tmp_end;
      tasks.forEach((el, idx) => {
        if (idx > 0 && el['follow_previous'] == true) {
          if (el['start_time'] >= tmp_start && el['start_time'] < tmp_end) {
            el['follow_status'] = true;
          } else {
            el['follow_status'] = false;
          }
        } else
          el['follow_status'] = undefined;
        tmp_start = el['start_time'];
        tmp_end = el['end_time'];
      });
      return tasks;
    },
    confirm_plan() {
      let data = {
        suggested_plan: this.suggested_tasks,
        proposal_id: this.proposal.id,
        suggested_deadline: this.suggested_deadline,
      };
      let loading = this.$loading({
        lock: true,
        text: 'Saving...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      this.project_plan_loading = true;

      this.confirm_suggested_plan(data).then(
        (response) => {
          this.$emit('load_job_proposals_list');
          this.proposal.dated = ` ${this.suggested_deadline}`;
          this.$message({
            type: 'success',
            message: response.data.message,
          });
          loading.close();
          this.plan_dialog = false;
          this.project_plan_loading = false;
        },
        (reason) => {
          loading.close();
          this.plan_dialog = false;
          this.project_plan_loading = false;
          this.$message({
            type: 'error',
            message: reason.data,
          });
        }
      );
    },
    prepare_data() {
      //tasks_lists
      let lists = [];
      this.temp_list.map((c) => {
        let tasks = c.tasks
          .filter((f) => f.completed == false && f.mark_as_deleted == false)
          .map((t) => {
            let duration = 0;
            let time_allocated_labour =
                            t.time_allocated_adjusted == undefined ||
                            t.time_allocated_adjusted == '' ?
                              t.time_allocated_labour :
                              t.time_allocated_adjusted;
            if (t.is_outwork == true) duration = t.actual_duration;
            else if (t.users_count > 1)
              duration = time_allocated_labour / t.users_count;
            else duration = time_allocated_labour;
            // jp.users_count > 1 ? (jp.time_allocated_labour.to_i / jp.users_count) : jp.time_allocated_labour.to_i

            return {
              id: t.id,
              name:  t.layer_name ? t.layer_name : t.job_name,
              job_id: t.job_id,
              position: t.position,
              branch_specific: t.branch_specific,
              locked: t.is_date_locked,
              is_outwork: t.is_outwork,
              follow_previous: t.follow_previous,
              actual_duration: t.actual_duration,
              custom_start_date: t.custom_start_date,
              time_frame_start: t.time_frame_start,
              custom_start_date: t.custom_start_date,
              start_time: t.custom_start_date == '' || t.custom_start_date == undefined ?
                t.time_frame_start : t.custom_start_date,
              end_time: t.time_frame_end,
              splittable: t.splittable,
              time_frame_end: t.time_frame_end,
              users_count: t.users_count,
              time_allocated_labour: time_allocated_labour,
              suggested_users: t.suggested_users,
              crm_users: t.crm_users,
              duration: duration,
              list_id: t.list_id,
              time_allocated_adjusted: t.time_allocated_adjusted,
              is_custom: t.is_custom,
              resources: {},
            };
          });
        lists.push({
          id: c.id,
          list_name: c.name,
          consecutive: c.consecutive,
          position: c.position,
          tasks: tasks,
        });
      });
      return lists;
    },
    handleClose(item) {
      this.current_item.suggested_users.splice(
        this.current_item.suggested_users.indexOf(item),
        1
      );
    },
    add_user() {
      if (
        this.current_item.suggested_users
          .map((c) => c.id)
          .indexOf(this.selected_crm_user) >= 0
      ) {
        return;
      }
      this.show_save = true;
      let user = this.crm_users_list.filter(
        (c) => c.id == this.selected_crm_user
      )[0];

      if (user != undefined) {
        if (this.current_item.suggested_users == undefined)
          this.current_item.suggested_users = [];
        this.current_item.suggested_users.push(user);
        this.set_default_input();
      }
    },
    show_suggested_users(item) {
      this.users_dialog = true;
      this.current_item = item;
      this.set_default_input();
      // this.current_users = item.crm_users;
      // this.suggested_users = item.suggested_users || [];
    },
    remove_task(item, list, idx) {
      this.$confirm(
        'This will permanently delete the task. Continue?',
        'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      ).then(
        (response) => {
          this.delete_task(item.id).then((response) => {
            list.tasks.splice(idx, 1);

            // let idx = _this.project_plan_lists.forEach
            // .indexOf
            // this.update_proposal_time_frame(this.proposal.id).then(
            //   (response) => {
            //     this.$emit('load_job_proposals_list')
            //   }
            // );
            this.$emit('load_job_proposals_list');

            this.$message({
              type: 'success',
              message: 'Task has been deleted',
            });
          });
          //this.set_project_plan_lists(this.temp_list);
          this.project_plan_lists.forEach((list) => {
            list.tasks.forEach((task, idx) => {
              if (task.id == item.id) {
                list.tasks.splice(idx, 1);
                return;
              }
            });
          });
        },
        (response) => {}
      );
    },
    tasks_added(tasks) {
      tasks.forEach((task, idx) => {
        let list = this.temp_list.filter((c) => c.id == task.list_id)[0];
        if (list != undefined) {
          list.tasks.push(task);
          let pp_list = this.project_plan_lists.filter(
            (c) => c.id == task.list_id
          )[0];
          if (pp_list != undefined)
            pp_list.tasks.push(JSON.parse(JSON.stringify(task)));
        }
      });
      this.update_tasks_order();
      //this.set_project_plan_lists(this.temp_list);

      this.show_save = true;
    },
    set_default_input(milliseconds = 500) {
      let _this = this;
      setTimeout(function() {
        Array.from(document.querySelectorAll('.el-input__inner')).forEach(
          function(e, i) {
            e.classList.add('browser-default');
            if (e.querySelector('input') != null) {
              e.querySelector('input').className += ' browser-default';
            }
          }
        );
      }, milliseconds);
    },
    time_allocated_labour(list) {
      return list.tasks.reduce(
        (sum, li) => sum + parseFloat(li.time_allocated_labour || 0),
        0
      );
    },
    time_allocated_adjusted(list) {
      return list.tasks.reduce(
        (sum, li) =>
          sum +
                parseFloat(li.time_allocated_adjusted || li.time_allocated_labour),
        0
      );
    },
    time_allocated_difference(list) {
      let total_allocated = this.time_allocated_labour(list);
      let total_adjusted = this.time_allocated_adjusted(list);
      let total = total_allocated - total_adjusted;
      let style = total > 0 ? 'blue' : 'red';
      if (total == 0) return '';
      else return `<span style="color: ${style}" >  ${display_time(this.is_hours, total)}</span>`;
    },
    check_list(list, event) {
      list.tasks.forEach((c) => (c.selected = event));
    },
    reset_changes() {
      this.reload_list();
      this.show_save = false;
    },
    remove_list(id, idx) {
      this.remove_job_list(id).then(
        (response) => {
          // this.load_lists();
          this.$message({
            type: 'success',
            message: 'Delete list',
          });
          this.temp_list.splice(idx, 1);
          let list = Object.assign([], this.proposal_lists);
          list.splice(idx, 1);
          this.set_proposal_lists(list);
          this.temp_proposal_lists.splice(idx, 1);
        },
        (response) => {
          this.$message({
            type: 'error',
            message: 'Delete list',
          });
        }
      );
    },
    reload_list() {
      let loading = this.$loading({
        lock: true,
        text: 'Loading...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });

      let _this = this;
      this.temp_list = [];
      this.temp_proposal_lists = [];
      setTimeout(function(sender) {
        _this.temp_list = JSON.parse(JSON.stringify(_this.project_plan_lists));
        _this.temp_proposal_lists = JSON.parse(
          JSON.stringify(_this.project_plan_lists)
        );
        loading.close();
        _this.set_default_input();
      }, 500);
    },
    update_tasks_order() {
      this.temp_list.forEach((list) => {
        list.tasks.forEach((task, idx) => {
          // need to ignore completed tasks
          task.is_active = idx == 0 ? 1 : 0;
          task.position = idx;
        });
      });
    },
    onAdd(ev, arg) {
      this.activate_save_btn();
      this.temp_list.forEach((c) =>
        c.tasks.forEach((t) => {
          console.log(t);
          if (t.id == parseInt(ev.item.getAttribute('data-task-id'))) {
            t.list_id = ev.target.getAttribute('data-list-id');
            return;
          }
        })
      );
    },
    onSort(ev, arg) {
      this.activate_save_btn();
      this.update_tasks_order();
    },
    activate_save_btn() {
      this.show_save = true;
      //this.$emit("activate_save_btn");
    },
    delete_selected_tasks() {
      // batch deletion
      // confirm deletion
      this.activate_save_btn();
    },
  },
  watch: {
    project_plan_lists: function(newVal) {
      this.reload_list();
    },
    // plan_dialog: function(newVal) {
    //   if (newVal == false) this.clear_timer();
    // },
  },
};
</script>

<style scoped>
.plan_list {
    display: list-item;
    margin-left: 20px;
}

.plan_list>li {
    list-style-type: decimal !important;
    font-weight: 300;
}
</style>

<style>
.timer_style {
    background: rgb(69 170 245);
    padding: 5px 14px;
    border: 2px solid rgb(243, 243, 243);
    border-radius: 8.2px;
    color: rgb(255, 255, 255);
}

.layer_name_container {
    grid-template-columns: 1fr auto;
    display: grid;
}
</style>
