var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-form',{ref:"form",attrs:{"label-width":"70px"}},[_c('el-form-item',{key:_vm.attribute.name,attrs:{"label":_vm.attribute.name,"size":"mini"}},[(
          _vm.attribute.name == 'font-size' ||
            _vm.attribute.name == 'width' ||
            _vm.attribute.name == 'height'
        )?_c('div',[_c('el-input',{staticClass:"browser-default",attrs:{"data-prop":_vm.attribute.name,"data-group":_vm.attribute.group},on:{"change":_vm.update_attribute},model:{value:(_vm.attribute.value),callback:function ($$v) {_vm.$set(_vm.attribute, "value", $$v)},expression:"attribute.value"}},[(_vm.attribute.name == 'font-size')?_c('el-button',{attrs:{"slot":"append","icon":"el-icon-delete"},on:{"click":_vm.delete_attribtue},slot:"append"}):_c('template',{slot:"append"},[_vm._v("\n            px\n          ")])],2)],1):(
          _vm.attribute.prop.type == undefined || _vm.attribute.prop.type == ''
        )?_c('el-input',{staticClass:"browser-default",attrs:{"data-prop":_vm.attribute.name,"data-group":_vm.attribute.group},on:{"change":_vm.update_attribute},model:{value:(_vm.attribute.value),callback:function ($$v) {_vm.$set(_vm.attribute, "value", $$v)},expression:"attribute.value"}},[_c('el-button',{attrs:{"slot":"append","icon":"el-icon-delete"},on:{"click":_vm.delete_attribtue},slot:"append"})],1):(_vm.attribute.name == 'font-family')?_c('div',[_c('el-select',{staticClass:"default-browser",attrs:{"placeholder":"Select","data-prop":_vm.attribute.name,"data-group":_vm.attribute.group,"allow-create":"","filterable":""},on:{"change":_vm.update_attribute},model:{value:(_vm.attribute.value),callback:function ($$v) {_vm.$set(_vm.attribute, "value", $$v)},expression:"attribute.value"}},_vm._l((_vm.fonts_list),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name + '.' + item.extension,"value":item.name}},[_c('span',{staticStyle:{"float":"left","font-size":"12px"}},[_vm._v(_vm._s(item.name))]),_vm._v(" "),_c('span',{staticStyle:{"float":"right","color":"#8492a6","font-size":"12px"}},[_vm._v("["+_vm._s(item.extension)+"]")])])}),1),_vm._v(" "),_c('el-button',{attrs:{"slot":"append","icon":"el-icon-delete"},on:{"click":_vm.delete_attribtue},slot:"append"})],1):(
          _vm.attribute.prop.type != undefined &&
            _vm.attribute.prop.type == 'autocomplete'
        )?_c('el-select',{staticClass:"browser-default",attrs:{"data-prop":_vm.attribute.name,"data-group":_vm.attribute.group,"allow-create":"","filterable":""},on:{"change":_vm.update_attribute},model:{value:(_vm.attribute.value),callback:function ($$v) {_vm.$set(_vm.attribute, "value", $$v)},expression:"attribute.value"}},[_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.value,"value":item.value}})}),_vm._v(" "),_c('el-button',{attrs:{"slot":"append","icon":"el-icon-delete"},on:{"click":_vm.delete_attribtue},slot:"append"})],2):(
          _vm.attribute.prop.type != undefined &&
            _vm.attribute.prop.type == 'colorpicker'
        )?_c('el-input',{attrs:{"type":"color","data-prop":_vm.attribute.name,"data-group":_vm.attribute.group},on:{"change":_vm.update_attribute},model:{value:(_vm.attribute.value),callback:function ($$v) {_vm.$set(_vm.attribute, "value", $$v)},expression:"attribute.value"}},[_c('el-button',{attrs:{"slot":"append","icon":"el-icon-delete"},on:{"click":_vm.delete_attribtue},slot:"append"})],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }