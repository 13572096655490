<template>
  <div class="stock-item">
    <el-button @click="stock_item_visible = true" size="mini" icon="el-icon-folder-add">NEW REQUEST</el-button>
    <el-dialog
      title
      :visible.sync="stock_item_visible"
      :before-close="handle_close"
      @open="get_working_files"
      width="30%"
    >
      <div class="header">ADD REQUEST</div>
      <el-divider></el-divider>

      <el-form :model="stock_item" status-icon :rules="rules" size="mini" ref="stock_item_form"  :inline-message=true>
        <el-checkbox-group v-model="working_file_list">
          <el-checkbox
            v-for="working_file in working_files"
            :key="working_file.id"
            :label="working_file.id"
          >{{ working_file.name }}</el-checkbox>
        </el-checkbox-group>
        <el-divider></el-divider>
        <el-form-item label="Name:" prop="name">
          <el-input type="text" v-model="stock_item.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="category_supplier_id">
          <el-select
            v-model="stock_item.category_supplier_id"
            placeholder="Please select a category"
            style="width: 258px;"
          >
            <el-option
              v-for="item in categories_supplier"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="stock_unit_size_id">
          <el-select
            v-model="stock_item.stock_unit_size_id"
            placeholder="Please select a unit size"
            style="width: 258px;"
          >
            <el-option
              v-for="item in stock_unit_sizes"
              :key="item.id"
              :label="item.name"
              :value="String(item.id)"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="Stock Code:" prop="stock_code">
          <el-input type="text" v-model="stock_item.stock_code" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Price:" prop="price">
          <el-input type="text" v-model="stock_item.price" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Width:" prop="width">
          <el-input type="text" v-model="stock_item.width" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Height:" prop="height">
          <el-input type="text" v-model="stock_item.height" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Thickness:" prop="thickness">
          <el-input type="text" v-model="stock_item.thickness" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Minimum packet Quantity:" prop="minimum_packet_quantity">
          <el-input type="text" v-model="stock_item.minimum_packet_quantity" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            type="textarea"
            v-model="stock_item.description"
            placeholder="Product Description"
            :autosize="{ minRows: 4, maxRows: 4}"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="submit_form('stock_item_form')">SAVE</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>


<script>
export default {
  props: ["proposal_id"],
  watch: {
    stock_item_id: function(newVal, oldVal) {
      this.stock_item = {};
      this.fetch_stock_item();
    }
  },
  data() {
    return {
      working_files: [],
      working_file_list: [],
      stock_item_visible: false,
      stock_item: {
        id: null,
        supplier_id: null,
        name: "",
        stock_code: "",
        price: "",
        width: null,
        height: null,
        thickness: null,
        stock_unit_size_id: null,
        minimum_packet_quantity: "",
        description: "",
        category_supplier_id: null
      },
      categories_supplier: [],
      stock_unit_sizes: [],
      rules: {
       name: [{ required: true, message: 'Please insert a name', trigger: 'change' }],
        category_supplier_id: [ {  required: true, message: 'Please select a category', trigger: 'change'  } ],
        stock_unit_size_id: [ {  required: true, message: 'Please select a unit size', trigger: 'change'  } ],
        stock_code: [{ required: true, message: 'Please insert a unique stock code', trigger: 'change' }],
        price: [{ required: true, message: 'Please insert a price', trigger: 'change' }],
        description: [{ required: true, message: 'Please insert a description', trigger: 'change'  }]
      }
    };
  },
  created() {
    // this.stock_item.supplier_id = 0;
    this.get_supplier_categories();
    this.get_stock_unit_sizes();
  },

  methods: {
    get_working_files() {
      return new Promise((resolve, reject) => {
        this.$http.get("/tenderiq/working_files/" + this.proposal_id).then(
          response => {
            resolve((this.working_files = response.data.files));
          },
          function(response) {
            this.$message({
              message: response.data.errors,
              type: "error"
            });
            reject(response);
          }
        );
      });
    },
    get_stock_item() {
      return new Promise((resolve, reject) => {
        this.$http.get("/tenderiq/stock_item/" + this.stock_item.id).then(
          response => {
            resolve((
              this.stock_item = response.data.stock_item,
              this.working_file_list = response.data.working_file_list
            ));
          },
          function(response) {
            this.$message({
              message: response.data.errors,
              type: "error"
            });
            reject(response);
          }
        );
      });
    },
    create_stock_item() {
      return new Promise((resolve, reject) => {
        let params = {
          proposal_id: this.proposal_id,
          stock_item: this.stock_item,
          working_file_list: this.working_file_list
        };
        this.$http.post("/tenderiq/create_stock_item", params).then(
          response => {
            this.$message({
              message: "Stock Item created",
              type: "success"
            });
            this.clear()
            this.$parent.get_requests();
            resolve(resolve);
          },
          function(response) {
            this.$message({
              message: response.data.errors,
              type: "error"
            });
            reject(response);
          }
        );
      });
    },
    update_stock_item() {
      return new Promise((resolve, reject) => {
        let params = {
          proposal_id: this.proposal_id,
          stock_item: this.stock_item,
          working_file_list: this.working_file_list
        };
        this.$http.patch("/tenderiq/update_stock_item", params).then(
          response => {
            this.$message({
              message: "Stock Item updated",
              type: "success"
            });
            this.clear()
          },
          function(response) {
            this.$message({
              message: response.data.errors,
              type: "error"
            });
            reject(response);
          }
        );
      });
    },
    clear(){
      this.stock_item_visible = false;
            this.stock_item = {};
            this.working_files = [];
            this.working_file_list = [];
    },
    get_supplier_categories() {
      return new Promise((resolve, reject) => {
        this.$http.get("/tenderiq/get_category_suppliers").then(
          response => {
            resolve(
              (this.categories_supplier = response.data.category_suppliers)
            );
          },
          function(response) {
            reject(response);
          }
        );
      });
    },
    get_stock_unit_sizes() {
      return new Promise((resolve, reject) => {
        this.$http.get("/tenderiq/get_stock_unit_sizes").then(
          response => {
            resolve((this.stock_unit_sizes = response.data.stock_unit_sizes));
          },
          function(response) {
            reject(response);
          }
        );
      });
    },
    submit_form(stock_item_form) {
      this.$refs[stock_item_form].validate(valid => {
        if (valid && this.stock_item.id == null) {
          this.create_stock_item();
        } else {
          this.update_stock_item();
        }
      });
    },
    handle_close(done) {
      this.clear();
      done();
    }
  }
};
</script>

<style scoped>
.stock-item {
  margin: 20px;
  width: 258px;
}
</style> 