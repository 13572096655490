import {Request} from '../utilities/Request';

export function timeframe_tax_rates(this_obj) {
  return Request.get(
    this_obj,
    '/api/timeframe_tax_rates',
    null,
  );
}

export function create_tax_rates(this_obj, tax_rate) {
  return Request.post(
    this_obj,
    '/api/tax_rates',
    null,
    {
      tax_rate: tax_rate
    }
  );
}

export function delete_timeframe_tax_rates(this_obj) {
  return Request.delete(
    this_obj,
    '/api/delete_timeframe_tax_rates'
  );
}
