<template>
  <div style="padding:10px;">
    <ul>
      <h3 class="smallerHeader" />
      <li
        v-for="entry in notes_list"
        :key="entry.id"
      >
        <div
          v-show="entry.entry"
          class="notesentry"
        >
          <span v-html="entry.entry" />
          <div class="notesentryUser">
            <i class="el-icon-user" />
            {{ entry.crm_user_name }}
            <i class="el-icon-time" />
            {{ entry.created_at }}
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'JobNotesList',
  props: {
    notes_list: {
      type: Array,
      required: true
    }
  },
};

</script>

<style scoped>
.smallerHeader {
  font-weight: bold;
  font-size: 20px;
}

.notesentry {
  padding: 20px;
  background: rgba(252, 250, 226, 0.51);
  border-radius: 10px;
  margin-bottom: 20px;
  font-size: 13px;
}

.notesentryUser {
  border-top: 1px solid #f3f3f3;
  color: #333;
  margin-top: 2px;
  padding: 10px;
  font-size: 13px;
  width: 100%;
}
</style>