<template>
  <div>
    <div style="background:white; padding:20px; margin-bottom:40px; border: 2px solid #c1c1c1">
      <div style="text-align:right;padding-bottom:10px;float:right;width:20%; padding-top: 7px; padding-right: 5px;">
       SUMMARY
      <el-switch v-model="quotevalue1">
      </el-switch>
      </div>
    <div style="width:80%">
    <WorkingFileHeader
      :id="working_file_id"
      :prop_name="working_file_info.name"
      :prop_is_included="working_file_info.is_main_list === true ? true : working_file_info.is_included"
      :prop_qty="working_file_info.qty"
      :prop_quantity_unit_id="working_file_info.quantity_unit_id"
      :consecutive="working_file_info.consecutive"
      :editable="working_file_info.is_main_list === false && proposal.approved !== 1"
    />
  </div>
    <el-collapse v-model="activeName" class="summarydiv" accordion style="border:2px solid #46cd34" v-show="quotevalue1 == 0 || quotevalue1 == false" >
     <el-collapse-item title="EDIT DETAILS" name="1">

      <StockListEdit
        :working_file_info="working_file_info"
        :stock_items_total_cost="stock_items_total_cost"
        :stock_items_total_sale="stock_items_total_sale"
      />
    <div style="padding:10px; border-top:2px solid #f3f3f3; margin-top:20px;">
      <el-row>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="padding:10px">
              <el-button type="info" icon="el-icon-circle-plus" @click="new_charge_po" circle class="right"></el-button>
              
              <el-dialog :visible.sync="charge_po_dialog" width="70%" :close-on-press-escape="false" :close-on-click-modal="false">
                  <EditPurchaseOrder :po_id="po_id" :proposal="proposal" :refresh_list="refresh_list" :is_charge_po="is_charge_po" :working_file_id="working_file_info.id" :is_pop_up="true"></EditPurchaseOrder>
              </el-dialog>
  
              <span style="color: rgb(70, 205, 52) !important; font-weight: bold;">CHARGE POS LIST </span>
          </el-col>
      </el-row>
      <div style="overflow:auto">
      <table width="100%" class="items_list_summary">
          <thead>
              <tr>
                  <td style="min-width:90px">PO ID</td>
                  <td width="300">Description</td>
                  <td>Category</td>
                  <td>Supplier</td>
                  <td>Stock Category</td>
                  <td>Status</td>
                  <td>Branch</td>
                  <td width="120">Percent</td>
                  <td width="120">Cost</td>
                  <td width="120">Sale Price</td>
                  <td>Action</td>
              </tr>
          </thead>
          <tbody>
              <ChargePOsList v-for="po in working_file_info.charge_pos_list" :po="po" v-bind:key="po.id" v-bind:style="[
              po.mark_as_deleted == 1
                ? 'mark_as_deleted'
                : ''
                ? {
                    'text-decoration': 'line-through',
                    'font-style:': 'italic',
                  }
                : {},
            ]"></ChargePOsList>
              <NewChargePOsToSummaryEdit
                :proposal="proposal"
                :working_file_id="working_file_info.id"
                :refresh_list="refresh_list"
              />
          </tbody>
          <tfoot>
              <td>POs Total</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>${{ po_proposals_total_cost }}</td>
              <td>${{ po_proposals_total_sale }}</td>
              <td></td>
          </tfoot>
      </table>
    </div>
      <br />
    </div>
      <TaskListEdit
        :working_file_info="working_file_info"
        :job_proposals_total_cost="job_proposals_total_cost"
        :job_proposals_total_sale="job_proposals_total_sale"
        :total_mintues_caclculated="total_mintues_caclculated"
        :total_hours_caclculated="total_hours_caclculated"
        :sale_price_per_unit="sale_price_per_unit"
        :total_cost_price_calculated="total_cost_price_calculated"
        :total_sales_price_calculated="total_sales_price_calculated"
      />
  </el-collapse-item>
</el-collapse>


<h3 style="color:#46cd34" v-show="quotevalue1 == 1 || quotevalue1 == true ">STOCK ITEMS LIST</h3>
    <div style="overflow:auto">
    <table width="100%" class="items_list_summary" v-show="quotevalue1 == 1 || quotevalue1 == true">
      <thead style="color: #d2d0d0 !important;">
        <tr>
          <td style="width: 30%;">Name</td>
          <td>Quantity</td>
          <td>Unit cost</td>
          <td>Gross profit (%)</td>
          <td>Waste (%)</td>
          <td>Unit Sale price</td>
          <td>Total Cost</td>
          <td>Total Sale Price</td>
          <td>Total Profit</td>
        </tr>
      </thead>
      <tbody >
        <tr
          v-for="stock in working_file_info.stock_items_list"
          v-bind:key="stock.id"
          :class="stock.mark_as_deleted == 1 ? 'mark_as_deleted' : ''"
        >
          <td>
            {{ stock.layer_name == "" ? stock.stock_name : stock.layer_name }}
          </td>
          <td width="100">{{ stock.quantity }}</td>
          <td width="100">${{ stock.price }}</td>
          <td width="100">%{{ stock.percent_applied }}</td>
          <td width="140">${{ stock.waste_percent }}</td>
          <td width="140">${{ stock.sale_price }}</td>
          <td width="140" style="background-color: #f7d6d6">
            ${{ round(stock.quantity * stock.price) }}
          </td>
          <td width="140" style="background-color: #dce8ec">
            ${{ stock.quantity * stock.sale_price }}
          </td>
          <td width="140" style="background-color: #e5f7e6">
            ${{
              round(
                stock.quantity * stock.sale_price -
                  stock.quantity * stock.price
              )
            }}
          </td>
        </tr>
      </tbody>
      <tfoot style="color: #d2d0d0 !important;">
        <tr>
          <td colspan="5">Total</td>
          <td></td>
          <td>{{ stock_items_total_cost }}</td>
          <td>{{ stock_items_total_sale }}</td>
          <td>{{ stock_items_total_profit }}</td>
        </tr>
      </tfoot>
 
    </table>
  </div>
    <br />

    <h3 style="color:#46cd34" v-show="quotevalue1 == 1 || quotevalue1 == true">
      
      TASKS LIST
    </h3>
    <div style="overflow:auto"> 
    <table width="100%" class="items_list_summary" v-show="quotevalue1 == 1 || quotevalue1 == true">
      <thead style="color: #d2d0d0 !important;">
        <tr>
          <td style="width: 30%;">Name</td>
          <td>Quantity</td>
          <td>Unit Cost</td>
          <td>Unit Sale price</td>
          <td>Total Cost</td>
          <td>Total Sale Price</td>
          <td>Total Profit</td>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="job in working_file_info.job_proposals_list.sort(function(
            a,
            b
          ) {
            return a.position - b.position;
          })"
          v-bind:key="job.id"
          :class="job.mark_as_deleted == 1 ? 'mark_as_deleted' : ''"
        >
          <td>
            {{ job.layer_name == "" ? job.job_name : job.layer_name }}
          </td>
          <td width="140">
            <Time :is_hours="job.is_hours" :minutes="job.time_allocated_labour" />
          </td>
          <td width="140">${{ job.pay_rate }}</td>
          <td width="140">${{ job.sale_price }}</td>
          <td width="140" style="background-color: #f7d6d6">
            ${{ round(job.time_allocated_labour * job.pay_rate) }}
          </td>
          <td width="140" style="background-color: #dce8ec">
            ${{ round(job.time_allocated_labour * job.sale_price) }}
          </td>
          <td width="140" style="background-color: #e5f7e6">
            ${{
              round(
                job.time_allocated_labour * job.sale_price -
                  job.time_allocated_labour * job.pay_rate
              )
            }}
          </td>
        </tr>
      </tbody>
      <tfoot style="color: #d2d0d0 !important;">
        <tr>
          <td colspan="4">Total</td>
          <td>${{ job_proposals_total_cost }}</td>
          <td>${{ job_proposals_total_sale }}</td>
          <td>${{ job_proposals_total_profit }}</td>
        </tr>
      </tfoot>
    </table>
</div>
    <h3 style="color:#46cd34" v-show="quotevalue1 == 1 || quotevalue1 == true">CHARGE POS LIST</h3>
    <div style="overflow:auto">
    <table width="100%" class="items_list_summary" v-show="quotevalue1 == 1 || quotevalue1 == true">
      <thead style="color: #d2d0d0 !important;">
        <tr>
          <td>PO ID</td>
          <td>Category</td>
          <td>Supplier</td>
          <td>Stock Category</td>
          <td>Description</td>
          <td>Status</td>
          <td>Total Cost</td>
          <td>Total Sale</td>
          <td>Total Profit</td>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="po in working_file_info.charge_pos_list"
          v-bind:key="po.id"
          :class="po.mark_as_deleted == 1 ? 'mark_as_deleted' : ''"
        >
          <td>{{ po.id }}</td>
          <td>{{ po.category_task }}</td>
          <td>{{ po.supplier }}</td>
          <td>{{ po.category }}</td>
          <td>{{ po.description }}</td>
          <td>{{ po.status }}</td>
          <td>{{ po.actual_value }}</td>
          <td>{{ po.sale_price }}</td>
          <td>{{ po.sale_price - po.actual_value }}</td>
        </tr>
      </tbody>
      <tfoot  style="color: #d2d0d0 !important;">
        <tr>
          <td colspan="6">Total</td>
          <td>${{ po_proposals_total_cost }}</td>
          <td>${{ po_proposals_total_sale }}</td>
          <td>${{ po_proposals_total_sale - po_proposals_total_cost }}</td>
       
        </tr>
      </tfoot>
 
    </table>
 
  </div>
<table>
      <tr>
        <td style="vertical-align: bottom">
          <div style="min-width:60%;padding:5px;border:#f3f3f3 0px solid; color:#6f6f6f; margin-top:20px;">
          <template v-if="working_file_info.is_main_list === false">
              <td>
            Match Quote Price: </td><td>
             <el-input
              v-model="matched_quote"
              @change="update_matched_quote"
            /></td>
          </template>
          </div>
        </td>
        <td v-show="proposal.approved == 1 || proposal.approved == true" />
        <td v-show="proposal.quote_type_id == 5" style="text-align:right;" />
        <div style="text-align:right; padding:10px; ">
          <span style="font-size:11px;color:#6f6f6f">TOTAL TIME:</span>
          <span style="font-size:15px">
            {{ total_hours_caclculated }}
          </span>
          <br>
        <td v-show="proposal.approved == 1 || proposal.approved == true" />
      <span style="font-size:11px;color:#6f6f6f">
         UNIT PRICE:</span>
          {{ sale_price_per_unit }}  <br>
          <span style="font-size:11px;color:#6f6f6f">TOTAL COSTS:</span>
          <span>
            ${{ total_cost_price_calculated }}
          </span>
          <br />
        <span style="border-top:2px solid #f3f3f3;padding:5px;margin-top:5px;  text-transform: uppercase;">
         <span style="font-size:15px;color:#6f6f6f"> Total Sale Price:</span>
          <span style="font-size:20px">
            ${{ total_sales_price_calculated }}
          </span>
      </span>
        </div>
          <div
            style=" text-align:right;"
            v-show="working_file_info.id > 0 && working_file_info.matched_quote > 0"
            v-bind:class="{ crossed_text: proposal.matched_quote > 0 }"
            type="text"
          >
          <span style="background:#46cd34;padding: 10px;">
            Matched quote:
            <span style="font-size:18px; " >${{ working_file_info.matched_quote }}</span></span>
          </div>
      </tr>
  </table>
  </div></div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { editor_store } from "store/modules/editor";
import { ProposalStockItemMixin } from "mixins/ProposalStockItemMixin.js";
import JobProposalEntry from "./../../job_proposals/JobProposalEntry";
import StockItemEntry from "./../../stock_items/StockItemEntry";
import ChargePOsList from "./../../purchase_orders/ChargePOsList";
import NewChargePOsToSummaryEdit from './../../purchase_orders/NewChargePOsToSummaryEdit';
import EditPurchaseOrder from "./../../purchase_orders/Edit";
import WorkingFileHeader from "./WorkingFileHeader";
import StockListEdit from "./stock/StockListEdit";
import TaskListEdit from "./task/TaskListEdit";

import draggable from "vuedraggable";
import {display_hours} from "../../../utilities/Time";
import {EventBus} from '../../../utilities/EventBus';

export default {
  name: "SummaryWorkingFileEdit",
  store: editor_store,
  props: ["working_file_info", "working_file_id"],
  mixins: [ProposalStockItemMixin],
  components: {
      JobProposalEntry,
      StockItemEntry,
      ChargePOsList,
      NewChargePOsToSummaryEdit,
      EditPurchaseOrder,
      WorkingFileHeader,
      StockListEdit,
      TaskListEdit,
      draggable,
  },
  data() {
      return {
          edit_name_dialog: false,
          selected_name_data: "",
          qty_disabled: false,
          stockaddarea: false,
          taskaddarea: false,
          sale_price_disabled: false,
          matched_quote: 0,
          po_id: 0,
          charge_po_dialog: false,
          is_charge_po: true,
          quotevalue1: false,
         
      };
  },
  mounted() {
      this.matched_quote = this.working_file_info.matched_quote;
  },
  methods: {
      ...mapActions(["load_charge_pos_list"]),
      onSort(ev, arg) {

          let updated_list = Array.from(ev.target.children).map((el, idx) => {
              return { id: el.getAttribute("job-id"), position: idx };
          });

          this.$http.post("/update_jp_positions_list", { jps: updated_list }).then(
              (response) => {
                  this.$message({ type: "success", message: "Task has been sorted successfully." })
                  updated_list.forEach((el, idx) => {
                      let record = this.working_file_info.job_proposals_list.filter(
                          (c) => c.id == el.id
                      )[0];
                      if (record != undefined) record["position"] = idx;
                  });
              },
              (response) => {}
          );
          //let list_id = parseInt(ev.target.getAttribute("data-list-id"));
      },
      update_tasks_sort(list_id, tasks) {},
      refresh_list() {
        this.charge_po_dialog = false;
        this.load_charge_pos_list();
        EventBus.$emit('reloadCategoryWorkingFiles');
      },
      new_charge_po() {
          if(this.working_file_info.is_included === false) {
            this.$message({
              type: 'error',
              message: 'Can\'t make new PO because this working file is not included',
            });
            return;
          }
          this.charge_po_dialog = true;
          this.po_id = 0;
      },
      edit_charge_po(po_id) {
          this.charge_po_dialog = true;
          this.po_id = po_id;
      },
      delete_charge_po(po_id) {
          let _this = this;
          this.$http.delete(`/purchase_orders/${po_id}`).then(
              (response) => {
                  if (response.body) {
                      _this.load_charge_pos_list();
                    EventBus.$emit('reloadCategoryWorkingFiles');
                  }
              },
              function(response) {
                  console.log(response);
              }
          );
      },
      
      update_matched_quote() {
          let params = {
              matched_quote: this.matched_quote,
          };

          this.$http
              .patch(`/update_working_file_details/${this.working_file_info.id}`, {
                  file_details: params,
              })
              .then(
                  (response) => {
                      this.working_file_info.matched_quote = this.matched_quote;
                      // this.update_file_details(this.file_details);
                      // this.hide_working_file_details_dialog();

                      let file_info = this.files_list.filter(
                          (c) => c.id == this.working_file_info.id
                      )[0];
                      file_info.matched_quote = this.matched_quote;
                      this.$message({
                          type: "success",
                          message: "Details have been saved successfully.",
                      });
                  },
                  (response) => {
                      this.$message({
                          type: "error",
                          message: "Error happnened while saving details.",
                      });
                  }
              );
      },
      round(num) {
          return parseFloat(((num * 100) / 100).toFixed(2));
      },
  },
  computed: {
      ...mapGetters([
          "proposal",
          "job_proposals_list",
          "stock_items_list",
          "charge_pos_list",
          "files_list",
      ]),
      dragOptions() {
          return {
              animation: 200,
              group: "description",
              disabled: false,
              ghostClass: "ghost",
          };
      },
      filtered_stocks() {
          if (this.working_file_info.id == undefined) return this.stock_items_list;
          else
              return this.stock_items_list.filter(
                  (c) => c.working_file_id == this.working_file_info.id
              );
      },
      filtered_tasks() {
          if (this.working_file_info.id == undefined)
              return this.job_proposals_list;
          else
              return this.job_proposals_list.filter(
                  (c) => c.working_file_id == this.working_file_info.id
              );
      },
      filtered_po() {
          if (this.working_file_info.id == undefined) return this.charge_pos_list;
          else
              return this.charge_pos_list.filter(
                  (c) => c.working_file_id == this.working_file_info.id
              );
      },
      total_cost_price_calculated() {
          let job_cost_price = this.job_proposals_total_cost;
          let stock_cost_price = this.stock_items_total_cost;
          let po_cost_price = this.po_proposals_total_cost;
          return (job_cost_price + stock_cost_price + po_cost_price).toFixed(2);
      },
      total_sales_price_calculated() {
          let job_sale_price = this.job_proposals_total_sale;
          let stock_sale_price = this.stock_items_total_sale;
          let po_sale_price = this.po_proposals_total_sale;
          return (job_sale_price + stock_sale_price + po_sale_price).toFixed(2);
      },
      sale_price_per_unit() {
          var qty = this.working_file_info.working_file.qty;
          if (qty == 0 || qty == undefined)
              qty = 1;
          return `$${(parseFloat(this.total_sales_price_calculated)/parseFloat(qty)).toFixed(2)} ${this.working_file_info.working_file.quantity_unit_name}`
      },
      job_proposals_total_cost() {
          return this.round(
              this.filtered_tasks.reduce(
                  (acc, item) =>
                  item.mark_as_deleted == 0 ?
                  acc + item.time_allocated_labour * item.pay_rate :
                  acc,
                  0
              )
          );
      },
      po_proposals_total_cost() {
          return this.round(
              this.filtered_po.reduce(
                  (acc, item) =>
                  item.mark_as_deleted == 0 ? acc + item.actual_value : acc,
                  0
              )
          );
      },
      po_proposals_total_sale() {
          return this.round(
              this.filtered_po.reduce(
                  (acc, item) =>
                  item.mark_as_deleted == 0 ? acc + item.sale_price : acc,
                  0
              )
          );
      },
      total_mintues_caclculated() {
          return this.round(
              this.filtered_tasks.reduce(
                  (acc, item) =>
                  item.mark_as_deleted == 0 ? acc + item.time_allocated_labour : acc,
                  0
              )
          );
      },
      total_hours_caclculated() {
          return display_hours(this.total_mintues_caclculated);
      },
      job_proposals_total_sale() {
          return this.round(
              this.filtered_tasks.reduce(
                  (acc, item) =>
                  item.mark_as_deleted == 0 ?
                  acc + item.time_allocated_labour * item.sale_price :
                  acc,
                  0
              )
          );
      },
      job_proposals_total_profit() {
          return this.round(
              this.filtered_tasks.reduce(
                  (acc, job) =>
                  job.mark_as_deleted == 0 ?
                  acc +
                  (job.time_allocated_labour * job.sale_price -
                      job.time_allocated_labour * job.pay_rate) :
                  acc,
                  0
              )
          );
      },
      stock_items_total_cost() {
          return this.round(
              this.filtered_stocks.reduce(
                  (acc, stock) =>
                  stock.mark_as_deleted == 0 ?
                  acc + stock.quantity * stock.price :
                  acc,
                  0
              )
          );
      },
      stock_items_total_sale() {
          return this.round(
              this.filtered_stocks.reduce(
                  (acc, stock) =>
                  stock.mark_as_deleted == 0 ?
                  acc + stock.quantity * stock.sale_price :
                  acc,
                  0
              )
          );
      },
      stock_items_total_profit() {
          return this.round(
              this.filtered_stocks.reduce(
                  (acc, stock) =>
                  stock.mark_as_deleted == 0 ?
                  acc +
                  (stock.quantity * stock.sale_price -
                      stock.quantity * stock.price) :
                  acc,
                  0
              )
          );
      },
  },
};
</script>

<style>


.summarydiv .el-collapse-item__header{
  line-height:20px!important;
  background:rgb(229, 247, 230) !important;
}
.crossed_text {
  text-decoration: line-through;
}

.minslot .el-input-group__append {
  background: none !important;
}

.inputfields input {
  height: 1.3em !important;
  margin: 0px !important;
  color: #333;
  border-top: 0px;
  border-left: 0px;
  line-height: 19px !important;
  border-right: 0px;
}

.inputfields .multiselect {
  min-height: 36px;
}

.inputfields .multiselect__tags {
  min-height: 30px;
  padding: 0px 5px;
  margin-top: 10px;
}

.inputfields .multiselect__tag {
  white-space: normal;
  z-index: 9999;
}

.inputfields .multiselect__option {
  white-space: normal;
}

.inputfields .multiselect__select {
  height: 35px !important;
}

.inputfields .multiselect__spinner {
  height: 32px;
}
</style>
