import Vue from 'vue';
import {
  mailbox_store
} from 'store/modules/mailbox_store';
import {
  tender_store
} from 'store/modules/tender_store';
import {
  client_store
} from 'store/modules/client_store';
import {
  user_profile
} from 'store/modules/user_profile';
import StockItems from 'components/StockItems.vue';
import StockItemListGroups from 'components/stock_items/StockItemListGroups.vue';
import ProposalAccount from 'components/proposal/ProposalAccount.vue';
import JobsCalendar from 'components/jobs_list/JobsCalendar.vue';
import SearchStockItems from 'components/stock_items/SearchStockItems.vue';
import AutocompleteAccounts from 'components/AutocompleteAccounts.vue';
import QuantityPrice from 'components/QuantityPrice.vue';
import JobDefaultStockItems from 'components/JobDefaultStockItems.vue';
import Toast from 'components/inline/Toast.vue';
import WriteAttribute from 'components/inline/WriteAttribute.vue';
import VueResource from 'vue-resource';
import VModal from 'vue-js-modal';
import AutoCheckout from 'components/AutoCheckout';
import WorkspaceNotification from 'components/notifications/WorkspaceNotification';
import NonConformanceNotification from 'components/notifications/NonConformanceNotification';
import UsersRequestsNotification from 'components/notifications/UsersRequestsNotification';
import OverdueTasksNotification from 'components/notifications/OverdueTasksNotification';
import InvoiceSummaryDashboard from '../components/dashboard/invoice_summary/InvoiceSummaryDashboard';
import SalesDashboard from 'components/dashboard/SalesDashboard';
import WorkInProgress from 'components/dashboard/WorkInProgress';
import UsersRequests from 'components/reports/UsersRequests';
import CrmUserSkills from 'components/user/CrmUserSkills';
import CrmUserPage from 'components/crm_users/CrmUserPage';
import CrmUserNew from 'components/crm_users/CrmUserNew';
import CrmUserList from 'components/crm_users/CrmUserList';
import PayrollPage from 'components/payroll/PayrollPage';
import SkillUsers from 'components/job/SkillUsers';
import VariationsLists from 'components/job_views/sections/variations/VariationsLists';
import SalesVariation from 'components/sales/SalesVariation';
import SalesEditForm from 'components/sales/SalesEditForm';
import WorkDashboard from 'components/dashboard/WorkDashboard';
import JobView from 'components/job_views/JobView';
import SimpleJobView from 'components/job_views/SimpleJobView';
import JobsTemplateDashboard from 'components/dashboard/JobsTemplateDashboard';
import UsersTasksDashboard from 'components/dashboard/UsersTasksDashboard';
import UsersKpiDashboard from 'components/dashboard/UsersKpiDashboard';
import SalesKpiDashboard from 'components/dashboard/SalesKpiDashboard';
import UserKPITargets from 'components/crm_users/UserKPITargets';
import UserUpskilling from 'components/crm_users/UserUpskilling';
import UserGoals from 'components/crm_users/UserGoals';
import TimesheetDashboard from 'components/dashboard/TimesheetDashboard';
import StaffHoursDashboard from 'components/dashboard/StaffHoursDashboard';
import InvoicesDashboard from 'components/dashboard/InvoicesDashboard';
import SpendDashboard from 'components/dashboard/SpendDashboard';
import SalesPriceMatchedQuote from 'components/dashboard/SalesPriceMatchedQuote';
import PurchaseOrdersDashboard from 'components/dashboard/PurchaseOrdersDashboard';
import TasksByCategoryDashboard from 'components/dashboard/TasksByCategoryDashboard';
import ClientsDashboard from 'components/dashboard/ClientsDashboard';
import SalesWithInvoices from 'components/dashboard/SalesWithoutInvoices';
import OpenJobsStatistics from 'components/dashboard/OpenJobsStatistics';
import HoldProposals from 'components/dashboard/HoldProposals';
import WorkspaceSummary from 'components/dashboard/WorkspacesDashboard';
import SalesByJobCompletion from 'components/dashboard/SalesByJobCompletion';
import NewMailCount from 'components/mailbox/Newmail';
import CreateQuote from 'components/proposal/quote/CreateQuote'

import UserTimeSheet from 'components/user/TimeSheet';
import MyProfile from 'components/user/MyProfile';
import MyDashboard from 'components/dashboard/MyDashboard';
import FlashNotice from 'components/FlashNotice';
import NonConformanceReportDashboard from 'components/dashboard/NonConformanceReportDashboard';
import InvoiceBalancing from 'components/invoice_balancing/InvoiceBalancing';
import OutwardsGoods from 'components/locations/OutwardsGoods';
import CompanyProfile from 'components/company_settings/CompanyProfile';
import CompanyNotices from 'components/company_settings/CompanyNotices';
import AccountingProfile from 'components/accounting/AccountingProfile';
import BankAccounts from 'components/shared/BankAccounts';
import MailboxUsers from 'components/user/MailboxUsers';

import ProposalEdit from 'components/proposal/ProposalEdit';

import LeadReport from 'components/leads/LeadReport.vue';
import AttachMailToNewAccountQuote from 'components/leads/AttachMailToNewAccountQuote.vue'
import PurchaseOrdersEdit from 'components/purchase_orders/Edit';
import CategoryTasks from 'components/company_settings/CategoryTasks.vue';
import ProjectPlannerView from 'components/user/ProjectPlannerView.vue';
import locale from 'element-ui/lib/locale/lang/en';
import Mailbox from 'components/mailbox/Mailbox';
import FilteredEmails from 'components/mailbox/FilteredEmails';
import Marketing from 'components/marketing/Marketing';
import TenderLayout from 'components/tender/Layout';
import ClientLayout from 'components/client/Layout';
import ProspectLayout from 'components/prospect/Layout';
import Navigation from 'components/client/Navigation';
import TenderRequests from 'components/tender/TenderRequests';
import ListMailboxes from 'components/mailbox/ListMailboxes';
import ListLevelMailboxes from 'components/mailbox/ListLevelMailboxes';

import StockItemBranches from 'components/stock_items/StockItemBranches';
import ForgotPassword from 'components/password/ForgotPassword';
import ForgotClientPassword from 'components/password/ForgotClientPassword';
import VerifyToken from 'components/password/VerifyToken';
import VerifyClientToken from 'components/password/VerifyClientToken';

import VueRouter from 'vue-router';
import Vue2Filters from 'vue2-filters';
import VueSignaturePad from 'vue-signature-pad';

import Filemanager from 'components/filemanager/Filemanager';
import Notifications from 'components/notifications/Notifications';
import PinBoard from 'components/pinboard/PinBoard';

import MyKPIs from 'components/kpis/MyKPIs';
import Templates from '../components/templates/Templates';
import ClientLogInSession from '../components/client/ClientLogInSession';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import 'jodit/build/jodit.min.css';
import JoditEditor from 'jodit-vue';

// import VueTour from "vue-tour";
// import 'vue-tour/dist/vue-tour.css';

import '@fullcalendar/core/main';
import '@fullcalendar/daygrid/main';
import '@fullcalendar/resource-timeline/main.css';


import InfiniteLoading from 'vue-infinite-loading';

import VueLayers from 'vuelayers';
import 'vuelayers/lib/style.css'; // needs css-loader
import {
  SelectInteraction
} from 'vuelayers';
import InviteNewAccount from '../components/client/InviteNewAccount';
import Quotes from '../components/client/Quotes';
import PayRuns from '../components/user/payroll/PayRuns';
import DraftPay from '../components/user/payroll/DraftPay';

Vue.use(VueLayers);
Vue.use(SelectInteraction);
Vue.use(JoditEditor);
Vue.use(VueSignaturePad);
// Vue.use(VueTour)


Vue.use(InfiniteLoading, {
  props: {
    spinner: 'waveDots',
    distance: 10,
    direction: 'bottom'
  },
  system: {
    throttleLimit: 50
  }
},);
Vue.use(VueRouter);

Vue.use(Vue2Filters);

Vue.use(VueResource);

Vue.use(ElementUI, {
  locale
});


Vue.component('Toast', Toast);
Vue.component('WriteAttribute', WriteAttribute);
Vue.component('Filemanager', Filemanager);
Vue.component('Notifications', Notifications);
Vue.component('Pinboard', PinBoard);


Vue.use(VModal, {
  dialog: true
});

export const eventBus = new Vue();


document.addEventListener('DOMContentLoaded', () => {
  // var TinyEditor = require('@tinymce/tinymce-vue').default;

  Vue.http.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  var print_calculation_vue = document.getElementById('print_calculation_vue');
  var quantity_price_vue = document.getElementById('quantity_price_vue');
  var stock_items_vue = document.querySelectorAll('.stock_items_vue');
  var job_default_stock_items_vue = document.getElementById('job_default_stock_items_vue');
  var search_stock_items_vue = document.getElementById('search_stock_items_vue');
  var autocomplete_account_vue = document.getElementById('autocomplete_account_vue');
  var auto_checkout_vue = document.getElementById('auto_checkout_vue');
  var workspace_notification_vue = document.getElementById('workspace_notification_vue');
  var non_conformance_notification_vue = document.getElementById('non_conformance_notification_vue');
  var users_requests_notification_vue = document.getElementById('users_requests_notification_vue');
  var overdue_tasks_notification_vue = document.getElementById('overdue_tasks_notification_vue');
  var mailbox = document.getElementById('mailbox');
  var marketing = document.getElementById('marketing');
  let invoice_summary_dashboard_vue = document.getElementById('invoice_summary_dashboard_vue');
  var sales_dashboard_vue = document.getElementById('sales_dashboard_vue');
  var work_dashboard_vue = document.getElementById('work_dashboard_vue');
  var work_in_progress_vue = document.getElementById('work_in_progress_vue');
  var job_view_vue = document.getElementById('job_view_vue');
  let simple_job_view_vue = document.getElementById('simple_job_view_vue');
  var jobs_template_dashboard_vue = document.querySelectorAll('.jobs_template_dashboard');
  var users_tasks_dashboard_vue = document.getElementById('users_tasks_dashboard_vue');
  var users_kpi_dashboard_vue = document.getElementById('users_kpi_dashboard_vue');
  var users_kpi_targets_vue = document.getElementById('users_kpi_targets_vue');
  var users_upskilling_vue = document.getElementById('users_upskilling_vue');
  var users_goals_vue = document.getElementById('users_goals_vue');
  var sales_kpi_dashboard_vue = document.getElementById('sales_kpi_dashboard_vue');
  var sales_price_matched_quote_vue = document.getElementById('sales_price_matched_quote_vue');
  var users_timesheet_dashboard_vue = document.getElementById('users_timesheet_dashboard_vue');
  var staff_hours_dashboard_vue = document.getElementById('staff_hours_dashboard_vue');
  var invoices_dashboard_vue = document.getElementById('invoices_dashboard_vue');
  var spend_dashboard_vue = document.getElementById('spend_dashboard_vue');
  var purchase_orders_dashboard_vue = document.getElementById('purchase_orders_dashboard_vue');
  var tasks_by_category_dashboard_vue = document.getElementById('tasks_by_category_dashboard_vue');
  var purchase_orders_edit_vue = document.getElementById('purchase_orders_edit_vue');
  var category_tasks_vue = document.getElementById('category_tasks_vue');
  var project_planner_view_vue = document.getElementById('project_planner_view_vue');
  var clients_dashboard_vue = document.getElementById('clients_dashboard_vue');
  var sales_without_invoices_vue = document.getElementById('sales_without_invoices_vue');
  var open_jobs_statistics_vue = document.getElementById('open_jobs_statistics_vue');
  var hold_proposals_vue = document.getElementById('hold_proposals_vue');
  var vue_toast = document.getElementsByClassName('vue-toast');
  var vue_write_attribute = document.getElementsByClassName('vue-write-attribute');
  var workspace_dashboard_vue = document.getElementById('workspace_dashboard_vue');
  var sales_by_job_completion_vue = document.getElementById('sales_by_job_completion_vue');
  var filtered_mailbox = document.getElementById('filtered_mailbox');
  var filemanager_tag_name = document.getElementsByTagName('filemanager')[0];
  var notifications_tag_name = document.getElementsByTagName('notifications')[0];
  var pinboard_tag_name = document.getElementsByTagName('pinboard')[0];
  var new_mail_count = document.getElementById('new_mail_count');
  let create_quote_vue = document.getElementById('create_quote_vue');
  var user_time_sheet_vue = document.getElementById('user_time_sheet_vue');
  var my_profile_vue = document.getElementById('my_profile_vue');
  var my_dashboard_vue = document.getElementById('my_dashboard_vue');
  var ncr_dashboard_vue = document.getElementById('ncr_dashboard_vue');
  var invoice_balancing_vue = document.getElementById('invoice_balancing_vue');
  var outwards_goods_vue = document.getElementById('outwards_goods_vue');
  var company_profile_vue = document.getElementsByTagName('company_profile')[0];
  var company_notices_vue = document.getElementsByTagName('company_notices_vue')[0];
  var accounting_profile_vue = document.getElementsByTagName('accounting_profile')[0];
  var bank_accounts_vue = document.getElementsByTagName('bank_accounts')[0];
  var mailbox_users_vue = document.getElementsByTagName('mailbox_users')[0];
  let crm_user_page = document.getElementsByTagName('crm_user_page')[0];
  let crm_user_new = document.getElementsByTagName('crm_user_new')[0];
  let payroll = document.getElementsByTagName('payroll')[0];
  let crm_user_list = document.getElementsByTagName('crm_user_list')[0];
  var proposal_edit_vue = document.getElementsByTagName('proposal')[0];
  var tender_layout = document.getElementById('tender_layout_vue');
  var tender_requests = document.getElementById('tender_requests_vue');
  var client_layout = document.getElementById('client_layout_vue');
  let client_nav = document.getElementById('client_nav_vue');
  let prospect_layout = document.getElementById('prospect_layout_vue');
  var invite_new_account = document.getElementById('invite_new_account_vue');
  var flash_notice_vue = document.getElementById('flash_notice_vue');

  var stock_item_branches = document.getElementById('stock_item_branches_vue');

  var list_mailboxes = document.getElementById('list_mailboxes_vue');
  var list_level_mailboxes = document.getElementById('list_level_mailboxes_vue');
  var forgot_password = document.getElementById('forgot_password_vue');
  var forgot_client_password = document.getElementById('forgot_client_password_vue');
  let client_login_session_vue = document.getElementById('client_login_session_vue');
  var verify_token_form = document.getElementById('verify_token_form_vue');
  var verify_client_token_form = document.getElementById('verify_client_token_form_vue');
  var proposal_account = document.getElementById('proposal_account_vue');
  var jobs_calendar_vue = document.getElementById('jobs_calendar_vue');
  var users_requests_vue = document.getElementById('users_requests_vue');

  var crm_user_skills_vue = document.getElementsByTagName('crm_user_skills')[0];
  var variations_lists_vue = document.getElementsByTagName('variations_lists')[0];
  let sales_variation_vue = document.getElementById('sales_variation');
  let sales_edit_form_vue = document.getElementById('sales_edit_form');
  let stock_item_list_groups = document.getElementById('stock_item_list_groups_vue');
  let vue_lead_report = document.getElementById('vue_lead_report');
  let attach_mail_to_new_account_quote_vue = document.getElementById('attach_mail_to_new_account_quote_vue');
  //VariationsLists
  var skill_users_vue = document.getElementsByTagName('skill_users')[0];

  let my_kpis_vue = document.getElementById('my_kpis');

  let templates_vue = document.getElementById('templates_index');


  if (jobs_calendar_vue != null) {
    new Vue({
      el: jobs_calendar_vue,
      render: h => h(JobsCalendar, {
        props: {
          user_id: jobs_calendar_vue.getAttribute('data-user-id'),
          show_filter: jobs_calendar_vue.getAttribute('data-show-filter'),
        }
      })
    });
  }


  if (proposal_account != null) {
    new Vue({
      el: proposal_account,
      render: h => h(ProposalAccount, {
        props: {
          proposal_id: proposal_account.getAttribute('proposal-id'),
          account_name: proposal_account.getAttribute('account-name')
        }
      })
    });
  }

  if (crm_user_page != null) {
    const routes = [
      {
        path: '/pay_runs',
        name: 'pay_runs',
        component: () => import('../components/user/payroll/PayRuns')
      },
      {
        path: '/draft_pay/:pay_period_start/:pay_period_end',
        name: 'draft_pay',
        component: () => import('../components/user/payroll/DraftPay'),
        props: true
      }
    ];

    global.router = new VueRouter({
      routes
    });

    new Vue({
      el: crm_user_page,
      components: { VueSignaturePad },
      router,
      render: h => h(CrmUserPage, { props: {
          crm_user_id: crm_user_page.getAttribute('crm-user-id')
        }
      })
    });
  }

  if (payroll != null) {
    new Vue({
      el: payroll,
      render: h => h(PayrollPage)
    });
  }

  if (vue_lead_report != null) {
    new Vue({
      el: vue_lead_report,
      render: h => h(LeadReport)
    });
  }

  if ( attach_mail_to_new_account_quote_vue != null) {
    new Vue({
      el: attach_mail_to_new_account_quote_vue,
      render: h => h(AttachMailToNewAccountQuote, {
      props: {
        account_name: attach_mail_to_new_account_quote_vue.getAttribute('data-account-name'),
        message_id: attach_mail_to_new_account_quote_vue.getAttribute('data-message-id'),
        account_id: attach_mail_to_new_account_quote_vue.getAttribute('data-account-id'),
        current_mailbox: attach_mail_to_new_account_quote_vue.getAttribute('data-current-mailbox'),
      }
    })
    });
  }

  if (crm_user_new != null) {
    new Vue({
      el: crm_user_new,
      render: h => h(CrmUserNew)
    });
  }

  if (crm_user_list != null) {
    new Vue({
      el: crm_user_list,
      render: h => h(CrmUserList)
    });
  }

  if (users_requests_vue != null) {
    new Vue({
      el: users_requests_vue,
      render: h => h(UsersRequests, {})
    });
  }

  if (stock_item_branches != null) {
    new Vue({
      el: stock_item_branches,
      render: h => h(StockItemBranches, {
        props: {
          stock_item_id: stock_item_branches.getAttribute('stock-item-id')
        }
      })
    });
  }

  if (verify_token_form != null) {
    new Vue({
      el: verify_token_form,
      render: h => h(VerifyToken)
    });
  }

  if (stock_item_list_groups != null) {
    new Vue({
      el: stock_item_list_groups,
      render: h => h(StockItemListGroups,{ props: {
          stock_item_id: stock_item_branches.getAttribute('stock-item-id')
        }})
    });
  }

  if (verify_client_token_form != null) {
    new Vue({
      el: verify_client_token_form,
      render: h => h(VerifyClientToken)
    });
  }

  if (forgot_password != null) {
    new Vue({
      el: forgot_password,
      render: h => h(ForgotPassword)
    });
  }

  if (forgot_client_password != null) {
    new Vue({
      el: forgot_client_password,
      render: h => h(ForgotClientPassword)
    });
  }

  if (list_mailboxes != null) {
    const tender_requests = new Vue({
      el: list_mailboxes,
      store: mailbox_store,
      render: h => h(ListMailboxes)
    });

  }

  if (list_level_mailboxes != null) {
    const tender_requests = new Vue({
      el: list_level_mailboxes,
      store: mailbox_store,
      render: h => h(ListLevelMailboxes)
    });

  }

  if (invite_new_account != null) {
    new Vue({
      el: invite_new_account_vue,
      render: h => h(InviteNewAccount)
    });

  }

  if (tender_requests != null) {
    new Vue({
      el: tender_requests,
      render: h => h(TenderRequests)
    });

  }

  if (tender_layout != null) {

    const Details = () => import('components/tender/Details');
    const StockItems = () => import('components/tender/StockItems');
    const AddStockItem = () => import('components/tender/AddStockItem');
    const Requests = () => import('components/tender/Requests');
    const Response = () => import('components/tender/Response');
    const StockItem = () => import('components/tender/StockItem');
    const LoginDetails = () => import('components/tender/Login');

    const routes = [
      {
        name: 'details',
        path: '/details',
        component: Details
      },
      {
        name: 'login_details',
        path: '/login-details',
        component: LoginDetails
      },
      {
        name: 'stock_items',
        path: '/stock-items',
        component: StockItems
      },
      {
        name: 'add_stock_item',
        path: '/add-stock-item/:id',
        component: AddStockItem
      },
      {
        name: 'requests',
        path: '/requests',
        component: Requests
      },
      {
        name: 'response',
        path: '/response',
        component: Response
      },
      {
        name: 'stock_item',
        path: '/stock-item/:id',
        component: StockItem
      }
    ];
    global.router = new VueRouter({
      routes
    });

    new Vue({
      el: tender_layout,
      components: {},
      router,
      store: tender_store,
      render: h => h(TenderLayout)
    });
  }

  if (client_layout != null) {

    const LoginDetails = () => import('components/client/Login');
    const Account = () => import('components/client/Account');
    const Dashboard = () => import('components/client/Dashboard');
    const Quotes = () => import('components/client/Quotes');
    const NewOrder = () => import('components/client/NewOrder');
    const ThankYou = () => import('components/client/ThankYou');

    const routes = [
      {
        name: 'login_details',
        path: '/login-details',
        component: LoginDetails
      },
      {
        name: 'account',
        path: '/account',
        component: Account
      },
      {
        name: 'dashboard',
        path: '/dashboard',
        component: Dashboard
      },
      {
        name: 'quotes',
        path: '/quotes',
        component: Quotes
      },
      {
        name: 'new_order',
        path: '/new-order/:category_task',
        props: true,
        component: NewOrder
      },
      {
        name: 'thank_you',
        path: '/thank-you/:quote_number/:workspace',
        props: true,
        component: ThankYou
      },
    ];
    global.router = new VueRouter({
      base: '/client/login',
      routes
    });

    new Vue({
      el: client_layout,
      components: {},
      router,
      store: client_store,
      render: h => h(ClientLayout)
    });
  }

  if (prospect_layout != null) {

    const Details = () => import('components/prospect/Details');
    const routes = [
      {
        name: 'details',
        path: `/details/:crm_user_id`,
        component: Details
      }
    ];
    global.router = new VueRouter({
      routes
    });

    new Vue({
      el: prospect_layout,
      components: { VueSignaturePad },
      router,
      render: h => h(ProspectLayout,{
        props:{
          crm_user_id: prospect_layout.getAttribute('data-crm-user-id')}
      })
    });
  }

  if (proposal_edit_vue != null) {
    new Vue({
      el: proposal_edit_vue,
      components: {
        JoditEditor
      },
      render: h => h(ProposalEdit, {
        props: {
          client_view: JSON.parse(proposal_edit_vue.dataset.client_view),
          discount: JSON.parse(proposal_edit_vue.dataset.discount),
          proposal_id: JSON.parse(proposal_edit_vue.dataset.proposal_id)
        }
      })
    });
  }

  if (marketing != null) {

    const Rules = () => import('components/marketing/Rules');
    const EditRule = () => import('components/marketing/EditRule');

    const routes = [{
      name: 'rules',
      path: '/rules',
      component: Rules
    },
      {
        name: 'create_rule',
        path: '/create_rule',
        component: EditRule
      },
      {
        name: 'edit_rule',
        path: '/edit_rule/:id',
        component: EditRule
      }

    ];
    global.router = new VueRouter({
      routes
    });

    new Vue({
      el: marketing,
      components: {
        JoditEditor
      },
      router,
      render: h => h(Marketing)
    });
  }

  if (mailbox != null || filtered_mailbox != null) {
    //opens mailbox menu on sidebar every time
    $('.collapsible').collapsible('open', 1);
    // lazy loading routes
    const Inbox = () => import('components/mailbox/Inbox');
    const Sent = () => import('components/mailbox/Sent');
    const Junk = () => import('components/mailbox/Junk');
    const Draft = () => import('components/mailbox/Draft');
    const Trash = () => import('components/mailbox/Trash');
    const Compose = () => import('components/mailbox/Compose');
    const Contact = () => import('components/mailbox/Contact');
    const Blacklist = () => import('components/mailbox/Blacklist');
    const FilteredEmails = () => import('components/mailbox/FilteredEmails');
    const routes = [{
      path: '/inbox',
      component: Inbox
    },
      {
        path: '/sent',
        component: Sent
      },
      {
        path: '/junk',
        component: Junk
      },
      {
        path: '/drafts',
        component: Draft
      },
      {
        path: '/trash',
        component: Trash
      },
      {
        name: 'compose',
        path: '/compose',
        component: Compose
      },
      {
        name: 'edit',
        path: '/compose/draft',
        component: Compose
      },
      {
        path: '/contact',
        component: Contact
      },
      {
        path: '/blacklist',
        component: Blacklist
      },
      {
        path: '/inbox/*',
        component: Inbox
      }
    ];

    global.router = new VueRouter({
      routes
    });

    if (mailbox != null) {
      new Vue({
        el: mailbox,
        components: {
          InfiniteLoading,
          JoditEditor

        },
        router,
        store: mailbox_store,
        render: h => h(Mailbox)
      });

    }

    if (filtered_mailbox != null) {

      new Vue({
        el: filtered_mailbox,
        components: {
          InfiniteLoading

        },
        router,
        store: mailbox_store,
        render: h => h(FilteredEmails, {
          props: {
            filtered_msg_id: filtered_mailbox.getAttribute('data-msg-id'),
            branch_id: filtered_mailbox.getAttribute('data-branch-id'),
            mailbox_account: filtered_mailbox.getAttribute('data-mailbox-account-id'),
            proposal_id: filtered_mailbox.getAttribute('data-proposal-id')
          }
        })
      });
    }
  }

  if (vue_toast.length != 0) {
    new Vue({
      el: '[data-behavior="vue-toast"]'
    }); //creates a toast
  }

  if (vue_write_attribute.length != 0) {
    new Vue({
      el: '[data-behavior="vue-write-attribute"]'
    }); //update column in database for input field
  }

  if (filemanager_tag_name != null) {
    const filemanager_inst = new Vue({
      el: filemanager_tag_name,
      render: h => h(Filemanager)
    });
  }

  if (notifications_tag_name != null) {
    const notifications_inst = new Vue({
      el: notifications_tag_name,
      store: mailbox_store,
      render: h => h(Notifications)
    });
  }
  if (pinboard_tag_name != null) {
    const pinboard_inst = new Vue({
      el: pinboard_tag_name,
      components: {
        VModal
      },
      render: h => h(PinBoard)
    });
  }

  if (new_mail_count != null) {
    const new_mail = new Vue({
      el: new_mail_count,
      store: mailbox_store,
      render: h => h(NewMailCount)
    });
  }

  if (create_quote_vue != null) {
    const create_quote = new Vue({
      el: create_quote_vue,
      render: h =>  h(CreateQuote, {
        props: {
          account_id: create_quote_vue.getAttribute('data-account-id-quote')
        }
      })
    });
  }

  if (auto_checkout_vue != null) {
    const checkout = new Vue({
      el: auto_checkout_vue,
      components: {
        VModal
      },
      render: h => h(AutoCheckout)
    });
  }


  if (workspace_notification_vue != null) {
    const workspacepop = new Vue({
      el: workspace_notification_vue,
      components: {
        VModal
      },
      render: h => h(WorkspaceNotification)
    });
  }

  if (non_conformance_notification_vue != null) {
    const non_conformance_pop = new Vue({
      el: non_conformance_notification_vue,
      components: {
        VModal
      },
      render: h => h(NonConformanceNotification)
    });
  }

  if (users_requests_notification_vue != null) {
    new Vue({
      el: users_requests_notification_vue,
      components: {
        VModal
      },
      render: h => h(UsersRequestsNotification)
    });
  }

  if (overdue_tasks_notification_vue != null) {
    new Vue({
      el: overdue_tasks_notification_vue,
      render: h => h(OverdueTasksNotification)
    });
  }


  if (work_dashboard_vue != null) {
    const work_dashboard = new Vue({
      el: work_dashboard_vue,
      components: {
        VModal,
        JoditEditor
      },
      render: h => h(WorkDashboard)
    });
  }

  if (job_view_vue != null) {
    var url = window.location.pathname.split('/');
    var ids = {
      account_id: url[2],
      proposal_id: url[3]
    };
    const job_view = new Vue({
      el: job_view_vue,
      components: {
        VModal,
        JoditEditor
      },
      render: h => h(JobView, {
        props: ids
      })


    });
  }

  if (simple_job_view_vue != null) {
    let url = window.location.pathname.split('/');
    const simple_job_view = new Vue({
      el: simple_job_view_vue,
      render: h => h(SimpleJobView, {
        props: {
          proposal_id: url[2]}
      })
    });
  }

  if (invoice_summary_dashboard_vue != null) {
    new Vue({
      el: invoice_summary_dashboard_vue,
      render: h => h(InvoiceSummaryDashboard)
    });
  }


  if (sales_dashboard_vue != null) {
    const sales_dashboard = new Vue({
      el: sales_dashboard_vue,
      components: {
        VModal
      },
      render: h => h(SalesDashboard)
    });
  }

  if (work_in_progress_vue != null) {
    const sales_dashboard = new Vue({
      el: work_in_progress_vue,
      components: {
        VModal
      },
      render: h => h(WorkInProgress)
    });
  }

  if (users_tasks_dashboard_vue != null) {
    const users_tasks_dashboard = new Vue({
      el: users_tasks_dashboard_vue,
      components: {
        VModal
      },
      render: h => h(UsersTasksDashboard)
    });
  }

  if (users_kpi_dashboard_vue != null) {
    new Vue({
      el: users_kpi_dashboard_vue,
      components: {
        VModal
      },
      render: h => h(UsersKpiDashboard, {
        props: {
          is_admin: users_kpi_dashboard_vue.getAttribute('is_admin'),
          current_user: users_kpi_dashboard_vue.getAttribute('current_user')
        }
      })
    });
  }
  if (users_kpi_targets_vue != null) {
    new Vue({
      el: users_kpi_targets_vue,
      components: {
        VModal
      },
      render: h => h(UserKPITargets)
    });
  }
  if (users_upskilling_vue != null) {
    new Vue({
      el: users_upskilling_vue,
      components: {
        VModal
      },
      render: h => h(UserUpskilling)
    });
  }
  if (users_goals_vue != null) {
    new Vue({
      el: users_goals_vue,
      components: {
        VModal
      },
      render: h => h(UserGoals)
    });
  }
  if (sales_kpi_dashboard_vue != null) {
    new Vue({
      el: sales_kpi_dashboard_vue,
      components: {
        VModal
      },
      render: h => h(SalesKpiDashboard)
    });
  }

  if (sales_price_matched_quote_vue != null) {
    new Vue({
      el: sales_price_matched_quote_vue,
      components: {
        VModal
      },
      render: h => h(SalesPriceMatchedQuote)
    });
  }

  if (users_timesheet_dashboard_vue != null) {
    new Vue({
      el: users_timesheet_dashboard_vue,
      components: {
        VModal
      },
      render: h => h(TimesheetDashboard)
    });
  }

  if (staff_hours_dashboard_vue != null) {
    new Vue({
      el: staff_hours_dashboard_vue,
      components: {
        VModal
      },
      render: h => h(StaffHoursDashboard)
    });
  }

  if (invoices_dashboard_vue != null) {
    new Vue({
      el: invoices_dashboard_vue,
      components: {
        VModal
      },
      render: h => h(InvoicesDashboard)
    });
  }


  if (spend_dashboard_vue != null) {
    new Vue({
      el: spend_dashboard_vue,
      components: {
        VModal
      },
      render: h => h(SpendDashboard, {
        props: {
          is_admin: spend_dashboard_vue.getAttribute('is_admin')

        }
      })
    });
  }

  if (purchase_orders_dashboard_vue != null) {
    new Vue({
      el: purchase_orders_dashboard_vue,
      components: {
        VModal
      },
      render: h => h(PurchaseOrdersDashboard)
    });
  }

  if (tasks_by_category_dashboard_vue != null) {
    new Vue({
      el: tasks_by_category_dashboard_vue,
      components: {
        VModal
      },
      render: h => h(TasksByCategoryDashboard)
    });
  }

  if (purchase_orders_edit_vue != null) {
    new Vue({
      el: purchase_orders_edit_vue,
      render: h => h(PurchaseOrdersEdit, {
        props: {
          po_id: purchase_orders_edit_vue.getAttribute('po_id')
        }
      })
    });
  }

  if (category_tasks_vue != null) {
    new Vue({
      el: category_tasks_vue,
      render: h => h(CategoryTasks)
    });
  }

  if (project_planner_view_vue != null) {
    new Vue({
      el: project_planner_view_vue,
      render: h => h(ProjectPlannerView)
    });
  }

  if (clients_dashboard_vue != null) {
    new Vue({
      el: clients_dashboard_vue,
      components: {
        VModal
      },
      render: h => h(ClientsDashboard)
    });
  }

  if (sales_without_invoices_vue != null) {
    new Vue({
      el: sales_without_invoices_vue,
      components: {
        VModal
      },
      render: h => h(SalesWithInvoices)
    });
  }

  if (sales_by_job_completion_vue != null) {
    new Vue({
      el: sales_by_job_completion_vue,
      components: {
        VModal
      },
      render: h => h(SalesByJobCompletion)
    });
  }

  if (user_time_sheet_vue != null) {
    new Vue({
      el: user_time_sheet_vue,
      components: {
        VModal
      },
      render: h => h(UserTimeSheet, {
        props: {
          is_admin: user_time_sheet_vue.getAttribute('is_admin'),
          current_user: user_time_sheet_vue.getAttribute('current_user')
        }
      })
    });
  }


  if (my_profile_vue != null) {
    new Vue({
      el: my_profile_vue,
      store: user_profile,
      render: h => h(MyProfile)
    });
  }
  if (my_dashboard_vue != null) {
    new Vue({
      el: my_dashboard_vue,
      components: {
        VModal
      },
      render: h => h(MyDashboard, {
        props: {
          is_admin: my_dashboard_vue.getAttribute('is_admin'),
          current_user: my_dashboard_vue.getAttribute('current_user')
        }
      })
    });
  }
  if (flash_notice_vue != null) {
    new Vue({
      el: flash_notice_vue,
      components: {
        VModal
      },
      render: h => h(FlashNotice, {})
    });
  }
  if (ncr_dashboard_vue != null) {
    new Vue({
      el: ncr_dashboard_vue,
      components: {
        VModal
      },
      render: h => h(NonConformanceReportDashboard, {
        props: {
          is_admin: ncr_dashboard_vue.getAttribute('is_admin'),
          current_user: ncr_dashboard_vue.getAttribute('current_user')
        }
      })
    });
  }
  if (invoice_balancing_vue != null) {
    new Vue({
      el: invoice_balancing_vue,
      components: {
        VModal
      },
      render: h => h(InvoiceBalancing, {
        props: {
          account_id: invoice_balancing_vue.getAttribute('data-account-id'),
          account_no: invoice_balancing_vue.getAttribute('data-account-no'),
          account_name: invoice_balancing_vue.getAttribute('data-account-name'),
          init_send_to: invoice_balancing_vue.getAttribute('data-init-send-to'),
        }

      })
    });
  }

  if (outwards_goods_vue != null) {
    new Vue({
      el: outwards_goods_vue,
      render: h => h(OutwardsGoods)
    });
  }

  if (accounting_profile_vue != null) {
    new Vue({
      el: accounting_profile_vue,
      render: h => h(AccountingProfile)
    });
  }

  if (company_profile_vue != null) {
    new Vue({
      el: company_profile_vue,
      components: {
        JoditEditor
      },
      render: h => h(CompanyProfile)
    });
  }

  if (company_notices_vue != null) {
    new Vue({
      el: company_notices_vue,
      components: {
        JoditEditor
      },
      render: h => h(CompanyNotices)
    });
  }

  if (variations_lists_vue != null) {
    new Vue({
      el: variations_lists_vue,
      render: h => h(VariationsLists, {
        props: {
          proposal_id: variations_lists_vue.getAttribute('proposal_id')
        }
      })
    });
  }

  if (sales_variation_vue != null) {
    new Vue({
      el: sales_variation_vue,
      render: h => h(SalesVariation, {
        props: {
          matched_quote: JSON.parse(sales_variation_vue.dataset.matched_quote),
          working_files: JSON.parse(sales_variation_vue.dataset.working_files),
          stock_items: JSON.parse(sales_variation_vue.dataset.stock_items),
          task_items: JSON.parse(sales_variation_vue.dataset.task_items),
          po_items: JSON.parse(sales_variation_vue.dataset.po_items),
          discount_rate: JSON.parse(sales_variation_vue.dataset.discount_rate)
        }
      })
    });
  }
  if (sales_edit_form_vue != null) {
    new Vue({
      el: sales_edit_form_vue,
      render: h => h(SalesEditForm, {
        props: {
          sale: JSON.parse(sales_edit_form_vue.dataset.sale),
          lists: JSON.parse(sales_edit_form_vue.dataset.lists),
          links: JSON.parse(sales_edit_form_vue.dataset.links),
          show_conditions: JSON.parse(sales_edit_form_vue.dataset.show_conditions),
        }
      })
    });
  }

  if (crm_user_skills_vue != null) {

    new Vue({
      el: crm_user_skills_vue,
      render: h => h(CrmUserSkills, {
        props: {
          crm_user_id: crm_user_skills_vue.getAttribute('crm_user_id')
        }
      })
    });
  }

  if (skill_users_vue != null) {
    new Vue({
      el: skill_users_vue,
      render: h => h(SkillUsers, {
        props: {
          job_id: skill_users_vue.getAttribute('job_id')
        }
      })
    });
  }

  if (mailbox_users_vue != null) {
    new Vue({
      el: mailbox_users_vue,
      render: h => h(MailboxUsers, {
        props: {
          crm_user_id: mailbox_users_vue.getAttribute('crm_user_id')
        }
      })
    });
  }

  if (bank_accounts_vue != null) {
    new Vue({
      el: bank_accounts_vue,
      render: h => h(BankAccounts, {
        props: {
          bank_accountable_type: bank_accounts_vue.getAttribute('bank_accountable_type'),
          bank_accountable_id: bank_accounts_vue.getAttribute('bank_accountable_id')
        }
      })
    });
  }


  if (open_jobs_statistics_vue != null) {
    new Vue({
      el: open_jobs_statistics_vue,
      components: {
        VModal
      },
      render: h => h(OpenJobsStatistics)
    });
  }

  if (hold_proposals_vue != null) {
    new Vue({
      el: hold_proposals_vue,
      render: h => h(HoldProposals)
    });
  }

  if (workspace_dashboard_vue != null) {
    new Vue({
      el: workspace_dashboard_vue,
      components: {},
      render: h => h(WorkspaceSummary)
    });
  }


  if (autocomplete_account_vue != null) {

    new Vue({
      el: autocomplete_account_vue,
      render: h => h(AutocompleteAccounts)
    });

  }
  if (search_stock_items_vue != null) {

    new Vue({
      el: search_stock_items_vue,
      render: h => h(SearchStockItems)
    });

  }

  if (print_calculation_vue != null) {
    var print_instance = new Vue({
      el: '#print_calculation_data',
      data: function () {
        return {
          print_data: {
            print_files_per_section: 0, // document.getElementById('print_files_per_section').value,
            files_per_paper: 0, //document.getElementById('files_per_paper').value
            stock_quantity: 0,
            stock_quantity_overwritten: 0
          },
          booklet_checked: document.getElementById('booklet_hidden').value == '1' ? true : false,
          booklet_pages: document.getElementById('proposal_print_calculation_attributes_booklet_pages').value,
          amount_of_files: document.getElementById('proposal_print_calculation_attributes_amount_of_files').value,
          finish_size: document.getElementById('finish_size').options[0].value,
          use_format_width: document.getElementById('proposal_print_calculation_attributes_use_format_width').value,
          use_format_height: document.getElementById('proposal_print_calculation_attributes_use_format_height').value
        };
      },

      watch: {
        'print_data.print_files_per_section': function () {
          eventBus.$emit('print_files_per_section', this.print_data.print_files_per_section);
        },
        'print_data.files_per_paper': function () {
          eventBus.$emit('files_per_paper', this.print_data.files_per_paper);
        },
        'print_data.stock_quantity': function () {
          eventBus.$emit('stock_quantity', this.print_data.stock_quantity);
        },
        'booklet_pages': function () {
          eventBus.$emit('booklet_pages', this.booklet_pages, this.booklet_checked);
        },

        'booklet_checked': function () {
          eventBus.$emit('booklet_checked', this.booklet_checked);
        },

        'amount_of_files': function () {
          eventBus.$emit('amount_of_files', this.amount_of_files);
        },


        'finish_size': function () {
          var width_height = this.finish_size.split('_');
          this.use_format_width = width_height[0];
          this.use_format_height = width_height[1];

        }
      }
    });
  }

  if (quantity_price_vue != null) {

    var quantity_prices_attributes = JSON.parse(quantity_price_vue.dataset.quantityPricesAttributes);

    var l = new Vue({
      el: quantity_price_vue,
      render: h => h(QuantityPrice, {
        props: {
          quantity_prices: quantity_prices_attributes
        }
      })
    });
  }


  if (job_default_stock_items_vue != null) {
    var l = new Vue({
      el: job_default_stock_items_vue,
      render: h => h(JobDefaultStockItems, {
        props: {
          job_id: $(job_default_stock_items_vue).attr('data-id')
        }
      })
    });
  }

  stock_items_vue.forEach(function (element, index) {
    var params = {};

    if ($(element).attr('data-type') == 'signage') {
      var booklet_checked = false;
      params = {
        sign_id: $(element).attr('data-id'),
        print_props: booklet_checked
      };
    } else if ($(element).attr('data-type') == 'print') {
      params = {
        print_props: print_instance._data
      };
    } else {
      params = {
        print_props: {}
      };
    }

    new Vue({
      el: element,
      render: h => h(StockItems, {
        props: params
      }),
    });

  });

  jobs_template_dashboard_vue.forEach(function (element, index) {
    var params = {
      title: $(element).attr('data-title'),
      type: $(element).attr('data-type')
    };

    new Vue({
      el: element,
      components: {
        VModal
      },
      render: h => h(JobsTemplateDashboard, {
        props: params
      }),
    });

  });

  if (my_kpis_vue != null) {
    new Vue({
      el: my_kpis_vue,
      render: h => h(MyKPIs, {
        props: {
          user_id: JSON.parse(my_kpis_vue.dataset.user_id),
          user_list: JSON.parse(my_kpis_vue.dataset.user_list),
          is_manager: JSON.parse(my_kpis_vue.dataset.is_manager)
        }
      })
    });
  }

  if (templates_vue != null) {
    new Vue({
      el: templates_vue,
      render: h => h(Templates, {
        props: {
          templates: JSON.parse(templates_vue.dataset.templates_list),
          accounts: JSON.parse(templates_vue.dataset.accounts_list),
        }
      })
    });
  }

  if (client_login_session_vue != null) {
    new Vue({
      el: client_login_session_vue,
      render: h => h(ClientLogInSession, {
        props: {
          message: client_login_session_vue.dataset.message,
          where_to: client_login_session_vue.dataset.where_to,
        }
      })
    });
  }
});
