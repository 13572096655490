<template>
  <div>
    <el-divider content-position="left">
      {{ title }}
    </el-divider>
    <el-table
      :data="tasks_list"
      style="width: 100%"
      :row-class-name="tableRowClassName"
    >
      <el-table-column
        label=""
        width="50"
      >
        <template slot-scope="scope">
          {{ parseInt(scope.$index) + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="proposal_id"
        label="Job Id"
        width="100"
      >
        <template slot-scope="scope">
          <el-link
            :href="`/job_view?id=${scope.row.proposal_id}`"
            target="_blank"
          >
            {{ scope.row.proposal_id }}
          </el-link>
        </template>
      </el-table-column>
      <el-table-column
        prop="account_name"
        label="Account"
        sortable
      />
      <el-table-column
        prop="job_name"
        label="Task"
      >
        <template slot-scope="scope">
          {{ scope.row.job_name }}<br>
          <el-tag
            v-show="scope.row.extension_status != undefined"
            :type="request_status(scope.row.extension_status)"
            size="mini"
            effect="dark"
          >
            REQUEST {{ scope.row.extension_status }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="task_name"
        label="Name"
        sortable
      >
        <template slot-scope="scope">
          {{ scope.row.task_name }}
          <span v-show="scope.row.is_urgent == 1">
            <br>
            <el-tag
              type="danger"
              effect="dark"
              size="mini"
            >URGENT</el-tag>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="slot_duration"
        label="Duration"
        sortable
      >
        <template slot-scope="scope">
          <Time :is_hours="scope.row.is_hours" :minutes="scope.row.slot_duration" />
        </template>
      </el-table-column>
      <el-table-column
        prop="in_production"
        label="In Production"
        sortable
      >
        <template slot-scope="scope">
          {{ scope.row.in_production }}
        </template>
      </el-table-column>
      <el-table-column
        prop="start_time"
        label="Start"
        sortable
      >
        <template slot-scope="scope">
          {{ scope.row.start_date }}
        </template>
      </el-table-column>
      <el-table-column
        prop="end_time"
        label="End"
        sortable
      >
        <template slot-scope="scope">
          {{ scope.row.end_date }}
        </template>
      </el-table-column>

      <el-table-column
        prop="proposal_id"
        label="Action"
      >
        <template slot-scope="scope">
          <el-tooltip
            content="Update time spent"
            placement="top"
          >
            <el-button
              type="primary"
              icon="el-icon-plus"
              circle
              size="mini"
              @click="show_time_spent(scope.row.user_id, scope.row.jp_id)"
            />
          </el-tooltip>
          <el-tooltip
            content="Complete task"
            placement="top"
          >
            <el-button
              type="success"
              circle
              icon="el-icon-check"
              size="mini"
              @click="complete_task(scope.row, scope.$index, tasks_list)"
            />
          </el-tooltip>
          <JobProposalExtensions
            :jp_id="scope.row.jp_id"
            :user_id="scope.row.user_id"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import JobProposalExtensions from './JobProposalExtensions.vue';
import Time from '../shared/Time'
export default {
  name: 'JobProposalsActionList',
  components: { JobProposalExtensions, Time },
  props: ['tasks_list', 'title'],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    request_status(extension_status) {
      switch (extension_status) {
      case 'PENDING':
        return '';
      case 'APPROVED':
        return 'success';
      case 'DENIED':
        return 'danger';
      default:
        return 'warning';
      }
    },
    show_time_spent(user_id, jp_id) {
      let params = { id: jp_id, user_id: user_id };
      let _this = this;
      _this.$http.post(`/task_time_spent_by_user/${jp_id}`, params).then(
        (response) => {
          _this
            .$prompt(
              `Current time spent is ${response.data}min(s). Please add time spent and press 'Ok' ?`,
              'Add time spent',
              {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning',
              }
            )
            .then(({ value }) => {
              let params = {
                time_in_minutes: value,
                job_proposal_id: jp_id,
                crm_user_id: user_id,
              };
              _this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
              });
              _this.$http.post('/update_time_spent_by_user', params).then(
                (response) => {
                  _this.$loading().close();
                  _this.$message({
                    type: 'success',
                    message: 'Time spent has been updated successfully',
                  });
                },
                (response) => {
                  _this.$loading().close();
                  _this.$message({
                    type: 'error',
                    message: response,
                  });
                  return false;
                }
              );
            })
            .catch(() => {});
        },
        (response) => {}
      );
    },
    complete_task(data, index, list) {
      let _this = this;
      this.$confirm('This will complete the current task. Continue?', 'info', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      })
        .then(() => {
          let params = { id: data.jp_id, user_id: data.user_id };
          _this.$http.post(`/complete_user_task/${data.jp_id}`, params).then(
            (response) => {
              if (response.data.status == 200) {
                list.splice(index, 1);
                _this.$message({
                  type: 'success',
                  message: response.data.message,
                });
              } else {
                _this.$message({
                  type: 'error',
                  message: response.data.message,
                });
              }
            },
            (response) => {}
          );
        })
        .catch(() => {
          _this.$message({
            type: 'info',
            message: 'Delete canceled',
          });
        });
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.is_active === true) {
        return 'active-row';
      }
      return '';
    },
  },
};
</script>
<style>
.el-table .active-row {
  background: #deffce;
}
</style>
