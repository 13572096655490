<template>
    <div style="margin-right:20px;">

        <br/>
        <b>Stock Items</b>
        <hr>

        <br>
        <table id="papers_div" class="responsive-table highlight" v-if="show_stock != 0">
            <th>stock id</th>
            <th>Stock Name</th>
            <th>Category</th>
            <th>Supplier</th>
            <th>Qty</th>
            <th>Delete</th>
            <tr v-for='(stock,index) in show_stock' :key="index">
                <td>
                    <a target="_blank"
                       :href="'../../../stock_items/' + stock.stock_item_id  + '/edit'" >{{stock.stock_item_id}}</a></td>
                <td>
                    <a target="_blank"
                       :href="'../../../stock_items/' + stock.stock_item_id  + '/edit'">{{stock.stock_name}}</a>
                </td>
                <td>{{stock.category}}</td>
                <td>{{stock.supplier}}</td>

                <input v-on:change="update_job_stock_qty" type="number" class="job_stock_item_qty_update"
                       :data-id="stock.id" :data-quantity="stock.quantity"
                       style="width: 50px" :value="stock.quantity" step="any"/>
                <td>
                    <div>
                        <div>
                            <div>
                                <button v-on:click.prevent="delete_job_stock_item(stock.id)" class="btn delete">
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </table>
        <br>


        <div>
            <br>
            <div class="field">
                <label for="quantity">Quantity:</label>
                <input type="text" name="quantity" id="quantity" style="width: 50px;"
                       v-model='quantity'>
            </div>
            <multiselect
                    v-model="selected"
                    :options="options"
                    :close-on-select="true"
                    label="name"
                    track-by="id"
                    :max="2"
                    :searchable="true"
                    :multiple="true"
                    :loading="false"
                    placeholder="search or click to add stock items"
                    @select="current_selected_value"
                    @input="internal_value">
            </multiselect>


            <button v-on:click.prevent="save_job_stock_item" id="save_job_stock_item" class=" btn">Save Default Stock item</button>
            <br>
        </div>

    </div>


</template>

<script>
    import Multiselect from 'vue-multiselect';
 import { eventBus } from "packs/main";

    export default {

        components: {Multiselect},
        props: ['job_id'],
        data() {
            return {
                selected: null,
                options: [],
                stock_item_id: null,
                show_stock: [],
                quantity: 1
            }
        },
        computed: {
            cravings: function () {
                return this.content['cravings'] ? 'fa-checkbox-marked' : 'fa-checkbox-blank-outline';
            }

        },
        created: function () {
            this.categories();
            this.stock_list();
            eventBus.$on('quantity', (quantity) => {
                this.quantity = quantity;
            });
        },

        methods: {

            categories: function () {
                let self = this;
                
                this.$http.get('/s_categories').then(response => {
                    self.options = response.data
                }, function (response) {
                    console.log(response)
                });
            },

            current_selected_value(selectedValue) {
                if (this.selected != null) {
                    if (this.selected.length == 1) {
                        this.stock_item_id = selectedValue.id
                        this.disabled = "false"
                    }
                }
            },
            stock_list: function () {
                let self = this;
                this.$http.get('/job_default_stock_items_list/' + self.job_id, {

                }).then(response => {
                    if (response.data.status == 200) {
                        self.show_stock = response.data.data;
                    }
                    else {
                        self.show_stock = []
                    }
                }, function (response) {
                    console.log(response)
                });
            },

            save_job_stock_item: function () {
                let self = this;
                if (self.stock_item_id != null && self.quantity > 0) {
                    this.$http.post('/save_job_stock_item/', {
                        job_id: self.job_id,
                        stock_item_id: self.stock_item_id,
                        quantity: self.quantity
                    }).then(response => {
                        if (response.status == 200) {
                            console.log(response.data)
                            self.show_stock = this.stock_list()
                            self.stock_item_id = null
                            self.selected = null
                            this.categories()
                        }
                        else {
                            alert(response.message);
                        }
                    }, function (response) {
                        console.log(response)
                    });
                }
                else {
                    console.log('error')
                }
            },
            update_job_stock_qty: function (event) {
                if (event.target.value == $(event.target).attr('data-quantity')) {
                    return false;
                }
                //Update QTY
                var js = {
                    id: $(event.target).attr('data-id'),
                    quantity: event.target.value
                };
                $.ajax({
                    type: "POST",
                    url: "/update_job_stock_qty/",
                    dataType: 'json',
                    data: js,
                    success: function (msg) {

                        //alert(msg.message);
                    }
                });

            },
            delete_job_stock_item: function (id) {

                //if (confirm("Are you sure to delete the stock item ?") == false)
                //return false;
                this.$http.delete(`/delete_job_stock_item/${id}`).then(response => {
                    self.show_stock = this.stock_list()
                }, function (response) {
                    console.log(response)
                });
            },
            internal_value(internalValue) {
                let self = this;
                if (internalValue.length === 0) {
                    this.categories()
                }

                if (internalValue.length === 1) {
                    this.$http.post('/category_products', {category_supplier_id: internalValue[0].id}).then(response => {
                        self.options = response.data;
                    }, function (response) {
                        console.log(response)
                    });
                }


            }

        }
    }


</script>


