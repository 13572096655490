<template>
  <div>
    <div class="card">
      <div class="card-content">
        <span class="card-title">
          Spend Statistics
        </span>
        <br>
        <br>
        <el-date-picker
          v-model="selected_date_range"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          style="width: auto !important;"
          :picker-options="pickerOptions2"
          @change="fill_data"
        />
        <table class="responsive-table striped">
          <thead>
            <tr class="header">
              <th>Category</th>
              <th>Completed</th>
              <th>Pending</th>
              <th>Total</th>
              <th>Cost/PO's</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in categories_statistics"
              :key="item.id"
              class="rows"
            >
              <td>
                <span class="center">{{ item.category_name }}</span>
              </td>
              <td>
                <a
                  @click="
                    show_spend_sales_by_category(item.category_id, true)
                  "
                >{{ item.closed_total_sales | currency }}</a>
              </td>
              <td>
                <a
                  @click="
                    show_pending_sales_by_category(item.category_id, false)
                  "
                >{{ item.pending_total_sales | currency }}</a>
              </td>
              <td>
                {{ total_sales(item) | currency }}
              </td>
              <td>
                <a @click="show_pos_by_category(item.category_id)">{{
                  item.total_cost | currency
                }}</a>
              </td>
            </tr>
          </tbody>

          <tfoot
            v-if="categories_statistics.length > 0"
            style="font-weight: bold;"
          >
            <tr style="border-top: 1px solid gray">
              <td>TOTAL</td>
              <td>{{ total_minutes("closed_total_sales") | currency }}</td>
              <td>{{ total_minutes("pending_total_sales") | currency }}</td>
              <td>{{ all_total_sales() | currency }}</td>
              <td>{{ total_minutes("total_cost") | currency }}</td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div class="card-action" />
    </div>
    <modal
      name="spend_sales_jobs_records"
      :height="700"
      :width="1400"
      :adaptive="true"
    >
      <div class="card ">
        <div class="card-content  ">
          <span
            class="card-title"
          >{{ list_title }}
            <hr></span>
          <div
            v-show="is_loading"
            class="progress"
          >
            <div class="indeterminate" />
          </div>
          <el-table
            :data="rows"
            style="width: 100%"
            height="600"
          >
            <el-table-column
              v-for="column in columns"
              :key="column"
              :prop="column"
              :label="column | capitalize"
              sortable
            />
          </el-table>
        </div>
        <div class="card-action" />
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: 'SpendDashboard',
  props: ['is_admin'],
  data() {
    return {
      categories_statistics: [],
      is_loading: false,
      columns: [],
      rows: [],
      list_title: '',
      pickerOptions2: {
        shortcuts: [
          {
            text: 'Current month',
            onClick(picker) {
              const end = new Date();
              const start = new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                1
              );
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last week',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last month',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last 3 months',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last 6 months',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last year',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
      selected_date_range: '',
    };
  },
  computed: {
    current_date() {
      return new Date();
    },
    start_date() {
      return new Date(
        this.current_date.getFullYear(),
        this.current_date.getMonth(),
        1
      );
    },
    end_date() {
      return new Date(
        this.current_date.getFullYear(),
        this.current_date.getMonth(),
        this.current_date.getDate()
      );
    },
  },
  created() {},
  mounted() {
    this.fill_data();
    this.selected_date_range = [this.start_date, this.end_date];
  },
  methods: {
    open_modal() {
      this.$modal.show('spend_sales_jobs_records');
    },
    hide_modal() {
      this.$modal.hide('spend_sales_jobs_records');
    },
    fill_data() {
      let params = {
        start_date: this.selected_date_range[0],
        end_date: this.selected_date_range[1],
      };
      this.$http.post('spend_jobs_statistics', params).then((response) => {
        this.categories_statistics = response.data;
      });
    },
    total_sales(item) {
      return (
        parseFloat(item.pending_total_sales) +
        parseFloat(item.closed_total_sales)
      );
    },
    show_spend_sales_by_category(category_id, completed) {
      this.open_modal();
      this.columns = [];
      this.rows = [];
      let self = this;
      this.list_title =
        (completed == true ? 'Completed Jobs ' : 'Pending Jobs') + ' Sales';
      this.is_loading = true;
      let params = {
        start_date: this.selected_date_range[0],
        end_date: this.selected_date_range[1],
        completed: completed == true ? 1 : 0,
        category_id: category_id,
      };
      this.$http.post('/spend_sales_by_category', params).then((response) => {
        self.is_loading = false;
        if (response.data.status == 200) {
          self.columns = response.data.data.columns;
          self.rows = response.data.data.rows;
        }
      });
    },
    show_pending_sales_by_category(category_id, completed) {
      this.open_modal();
      this.columns = [];
      this.rows = [];
      let self = this;
      this.list_title =
        (completed == true ? 'Completed Jobs ' : 'Pending Jobs') + ' Sales';
      this.is_loading = true;
      let params = {
        completed: completed == true ? 1 : 0,
        category_id: category_id,
      };
      this.$http
        .post('/spend_pending_sales_by_category', params)
        .then((response) => {
          self.is_loading = false;
          if (response.data.status == 200) {
            self.columns = response.data.data.columns;
            self.rows = response.data.data.rows;
          }
        });
    },
    show_pos_by_category(category_id) {
      this.open_modal();
      this.columns = [];
      this.rows = [];
      let self = this;
      this.is_loading = true;
      let params = {
        start_date: this.selected_date_range[0],
        end_date: this.selected_date_range[1],
        category_ids: [category_id],
      };
      
      this.$http.post('/get_po_records', params).then((response) => {
        self.is_loading = false;
        if (response.data.status == 200) {
          self.columns = response.data.data.columns;
          self.rows = response.data.data.rows;
        }
      });
    },
    all_total_sales() {
      let total_val = this.categories_statistics.reduce(function(total, item) {
        return (
          total +
          parseFloat(item['pending_total_sales']) +
          parseFloat(item['closed_total_sales'])
        );
      }, 0);

      return Math.round(total_val);
    },
    total_minutes(col) {
      let total_val = this.categories_statistics.reduce(function(total, item) {
        return total + parseFloat(item[col]);
      }, 0);
      return Math.round(total_val);
    },

    show_open_jobs_by_category(category_id, category_name) {
      this.open_modal();
      this.columns = [];
      this.rows = [];
      this.list_title = category_name + ' Jobs (In-production)';
      this.is_loading = true;
      let self = this;
      this.$http
        .post('/open_tasks_by_category', {
          category_id: category_id,
        })
        .then((response) => {
          self.is_loading = false;
          if (response.data.status == 200) {
            self.columns = response.data.data.columns;
            self.rows = response.data.data.rows;
          }
        });
    },
  },
};
</script>

<style scoped>
a {
  cursor: pointer;
}
</style>
