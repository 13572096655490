<template>
  <div id="inline">
    <el-link
      :underline="false"
      @click.native.prevent="dialogVisible = true"
    >
      Send Meeting Invitation
    </el-link>
    <el-dialog
      title="Meeting invitation"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
      append-to-body
      @opened="set_default_input()"
    >
      <el-input
        v-model="title"
        type="text"
        placeholder="Title"
        class="space"
      />
      <el-date-picker
        v-model="date_range"
        class="browser-default space"
        type="datetimerange"
        start-placeholder="Start Date"
        end-placeholder="End Date"
        format="dd.MM.yyyy HH:mm:ss"
        range-separator="/"
        :default-time="['13:00:00', '14:00:00']"
      />

      <el-input
        v-model="location"
        type="text"
        placeholder="Location"
        class="space"
      />
      <el-input
        v-model="to_email"
        type="text"
        placeholder="To Email"
        class="space"
      />
      <el-input
        v-model="message"
        type="textarea"
        :autosize="{ minRows: 7, maxRows: 7 }"
        placeholder="Message"
        class="space"
      />

      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="plain"
          size="mini"
          @click="clear_meeting()"
        >Clear</el-button>
        <el-button
          size="mini"
          @click="dialogVisible = false"
        >Cancel</el-button>
        <el-button
          size="mini"
          type="primary"
          @click="set_meeting_dates(),dialogVisible = false"
        >Send Email</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'EmailInvitation',
  props: {
    mailbox_id: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      dialogVisible: false,
      date_range: [],
      message: '',
      title: '',
      location: '',
      to_email: '',
      selected_category: '',
      meetings: []
    };
  },
  methods: {
    get_meeting_dates() {
      this.$http.get('/get_meeting_dates/' + this.mailbox_id).then(response => {
        if (response.data.data) {
          this.date_range = response.data.data.date_range;
          this.message = response.data.data.message;
          this.title = response.data.data.title;
          this.location = response.data.data.location;
          this.to_email = response.data.data.to_email;
        }
      });
    },
    set_meeting_dates() {
      this.$http.post('/set_meeting_dates', {
        mailbox_account_id: this.mailbox_id,
        date_range: this.date_range,
        message: this.message,
        title: this.title,
        location: this.location,
        to_email: this.to_email,
        category_task_id: this.selected_category
      })
        .then(
          response => {
            this.$message({
              message: response.data.message,
              type: 'success'
            });
          },
          response => {
            this.$message({
              message: response.data.errors,
              type: 'error'
            });
          }
        );
    },
    clear_meeting() {
      this.message = '';
      this.date_range = [];
      this.title = '',
      this.location = '',
      this.to_email = '';
      this.selected_category = '';
    },
    handleClose(done) {
      done();
    },
    set_default_input() {
      Array.from(document.querySelectorAll('.el-select__input, .el-input__inner')).forEach(
        function (e) {
          e.classList.add('browser-default');
          if (e.querySelector('input') != null)
            e.querySelector('input').className += ' browser-default';
        }
      );
    },
  },
};
</script>

<style scoped>
#inline {
  display: inline;
}
.space { margin-bottom: 10px;}
</style>