import {Request} from '../utilities/Request';

export function search_leads(this_obj) {
  return Request.post(
    this_obj,
    '/api/lead_reports/search_leads',
    null,
    {
      date: this_obj.date_selected,
      user_id: this_obj.selected_user,
      branch_id: this_obj.selected_branch,
      status: this_obj.selected_status
    }
  );
}

export function download_spreadsheet_lead_reports(this_obj) {
  return Request.get(
    this_obj,
    '/api/lead_reports/download_spreadsheet_lead_reports',
    null,
    {
    },
    null,
    'blob',
    false
  );
}
