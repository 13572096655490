<template>
  <el-dialog
    :visible.sync="show"
    :width="width"
    :close-on-click-modal="false"
    @closed="onCloseDialog"
  >
    <h2 class="text__center">
      Manual Payment
    </h2>

    <AddMasterPayment
      ref="add_master_payment_comp"
      :account_id="account_id"
      :show_credit_btn="master_payment==null"
      @errors="onErrors"
      @cal_res="onCalculationResponse"
    />

    <br>
    <Errors
      v-if="errors"
      :errors="errors"
    />
    <div v-if="master_payment">
      <Results
        :account_id="account_id"
        :master_payment="master_payment"
        @paymentUpdated="onPaymentUpdated"
        @paymentCancel="onPaymentCancel"
      />
    </div>

    <span slot="footer">
      <el-button
        type="info"
        @click="show = false;"
      >
        Close
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import {getToDay} from '../../../../utilities/Date';
import Errors from './Errors';
import AddMasterPayment from './AddMasterPayment';
import Results from './Results';
import {roundVal} from '../../../../utilities/Round';
import {EventBus} from '../../../../utilities/EventBus';

export default {
  name: 'MasterPaymentDialog',
  components: {Results, AddMasterPayment, Errors},
  props: {
    account_id: {
      type: String,
      required: true
    },
    show_mp_dialog: {
      type: Boolean,
      required: true
    },
  },
  data(){
    return {
      show: this.show_mp_dialog,
      width: '33%',
      media_query_sm: window.matchMedia('(min-width: 1000px)'),
      payment_amount: '',
      payment_date: getToDay(),
      errors: null,
      master_payment: null
    };
  },
  watch: {
    show_dialog(val) {
      this.show = val;
    },
  },
  created() {
    this.change_width();
    this.media_query_sm.addEventListener('change', () => {
      this.change_width();
    });
  },
  methods: {
    onCloseDialog() {
      this.$emit('update:show_mp_dialog', false );
    },
    onPaymentUpdated() {
      this.master_payment = null;
      this.errors = null;
      EventBus.$emit('refreshAllInvoice');
    },
    onPaymentCancel() {
      this.master_payment = null;
      this.errors = null;
    },
    onErrors(errors) {
      this.master_payment = null;
      this.errors = errors;
    },
    onCalculationResponse(result) {
      this.errors = null;
      this.master_payment = result;
      this.calculate_payments();
    },
    calculate_payments() {
      this.master_payment.payments.forEach((payment)=>{
        let total_amount = roundVal(parseFloat(payment.amount)
            + parseFloat(payment.paid_amounts));

        payment.accumulate_amount = total_amount;
        payment.left = roundVal(parseFloat(payment.total_price) - total_amount);
      });
    },
    change_width() {
      if(this.media_query_sm.matches) {
        this.width = '60%';
      } else {
        this.width = '90%';
      }
    }
  },
};
</script>

<style lang='scss' scoped>
.el-dialog__body {
  padding-left: 50px !important;
  padding-right: 50px !important;
}
</style>