<template>
  <div>
    <div class="row">
      <div class="col m2">
        <b>Current specs:</b>
      </div>
      <div class="col m3">
        <span>Width:</span>
        <el-input
          v-model="svg_payload.svg_width"
          :disabled="true"
          style="max-width: 200px;"
        >
          <template slot="append">
            mm
          </template>
        </el-input>
      </div>
      <div class="col m3">
        <span>height:</span>
        <el-input
          v-model="svg_payload.svg_height"
          :disabled="true"
          style="max-width: 200px;"
        >
          <template slot="append">
            mm
          </template>
        </el-input>
      </div>
    </div>
    <div class="row">
      <div>
        <el-radio
          v-model="import_settings"
          label="actual"
        >
          Actual value
        </el-radio>
      </div>
      
      <div v-show="append_to_workspace == false">
        <el-radio
          v-model="import_settings"
          label="fit"
        >
          Fit to content
        </el-radio>
      </div>
      <div v-show="append_to_workspace == true && selected_object != null">
        <el-radio
          v-model="import_settings"
          label="fit_to_selected"
        >
          Fit to selected
        </el-radio>
      </div>
      <div v-show="append_to_workspace == false ">
        <el-radio
          v-model="import_settings"
          label="custom"
        >
          Custom
        </el-radio>
        <div
          v-show="import_settings == 'custom'"
          class="row"
        >
          <div class="col m4">
            <span>Width:</span>
            <el-input
              v-model="target_width"
              placeholder="Please input"
              @change="update_dimensions('width', target_width)"
            >
              <template slot="append">
                mm
              </template>
            </el-input>
          </div>
          <div class="col m4">
            <span>Height:</span>
            <el-input
              v-model="target_height"
              placeholder="Please input"
              @change="update_dimensions('height', target_height)"
            >
              <template slot="append">
                mm
              </template>
            </el-input>
          </div>
          <div class="col m4">
            <el-checkbox
              v-model="aspect_ratio"
              label="Maintain aspect radio"
            />
          </div>
        </div>
      </div>
    </div>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        type="primary"
        @click="confirm_specs"
      >Confirm</el-button>
    </span>
  </div>
</template>
<script>
import { editor_store } from 'store/modules/editor';
import { WorkspaceMixin } from 'mixins/WorkspaceMixin.js';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { debuglog } from 'util';
import { setTimeout } from 'timers';
export const mm_to_px = 3.779527559;

export default {
  name: 'ImportSettings',
  mixins: [WorkspaceMixin],
  store: editor_store,
  props: ['svg_payload', 'dialog_visible', 'append_to_workspace'],
  data() {
    return {
      import_settings: 'actual',
      target_height: 0,
      target_width: 0,
      aspect_ratio: true
    };
  },
  mounted() {
    setTimeout(function() {
      Array.from(document.querySelectorAll('#workspace_div .el-input')).forEach(
        function(e, i) {
          e.classList.add('browser-default');
          if (e.querySelector('input') != null)
            e.querySelector('input').className += ' browser-default';
        }
      );
    }, 300);
  },
  computed: {
    ...mapGetters(['page_settings', 'page_scale', 'selected_object'])
  },
  methods: {
    ...mapActions(['set_page_size']),
    confirm_specs() {
      let scale = 'scale(1)';
      let scaleX = 0;
      let scaleY = 0;
      switch (this.import_settings) {
      case 'actual':
        // may enlarge the page dimensions if page dimension is less than actual value
        // if (
        //   parseFloat(this.svg_payload.svg_width)  >
        //     this.page_settings.width ||
        //   parseFloat(this.svg_payload.svg_height) >
        //     this.page_settings.height
        // )
        if (this.append_to_workspace == false)
          this.set_page_size(
            `${this.svg_payload.svg_width}x${this.svg_payload.svg_height}`
          );
        break;
      case 'fit':
        // scale the svg content to fit in specified page dim.
        scaleX =
            (this.page_settings.width /
              parseFloat(this.svg_payload.svg_width)) *
            this.page_scale;
        scaleY =
            (this.page_settings.height /
              parseFloat(this.svg_payload.svg_height)) *
            this.page_scale;
        scale = `scale(${scaleX},${scaleY})`;
        break;
      case 'fit_to_selected':
        let relative_boundaries = this.get_relative_boundaries(this.selected_object);
        scaleX = relative_boundaries.width /  parseFloat(this.svg_payload.svg_width);
        scaleY = relative_boundaries.height /  parseFloat(this.svg_payload.svg_height);          
        let transform = `translate(${relative_boundaries.x1} ${relative_boundaries.y1})`;

        scale = `${transform} scale(${scaleX},${scaleY})`;
        break;
      case 'custom':
        // calculate scale
        this.set_page_size(`${this.target_width}x${this.target_height}`);
        scaleX =
            (this.page_settings.width /
              parseFloat(this.svg_payload.svg_width)) *
            this.page_scale;
        scaleY =
            (this.page_settings.height /
              parseFloat(this.svg_payload.svg_height)) *
            this.page_scale;
        scale = `scale(${scaleX},${scaleY})`;
        break;
      }
      this.$emit('open_file', {
        xmlDoc: this.svg_payload.content,
        scale: scale
      });
    },
    update_dimensions(attr, value) {
      if (this.aspect_ratio == false) return;
      let height = 0;
      let width = 0;
      if (attr == 'width') {
        this.target_height = parseFloat(value) * (this.svg_payload.svg_height / this.svg_payload.svg_width);
      } else {
        this.target_width = parseFloat(value) * (this.svg_payload.svg_width / this.svg_payload.svg_height);
      }
    }
  },
  watch: {
    import_settings: function(newVal) {
      if (newVal == 'custom') {
        this.target_width = this.svg_payload.svg_width;
        this.target_height = this.svg_payload.svg_height;
      }
    },
    dialog_visible: function(newVal) {
      if (newVal == true) {
        this.target_width = this.svg_payload.svg_width;
        this.target_height = this.svg_payload.svg_height;
      }
    }
  }
};
</script>
