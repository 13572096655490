<template>
 <tr
  v-bind:style="[
    po.mark_as_deleted == 1 ? 'mark_as_deleted' : ''
      ? { 'text-decoration': 'line-through', 'font-style:': 'italic' }
      : {}
  ]"
>
  <td>{{ po.id }}</td>
  <td>
    {{ po.description }}
    <el-button
      icon="el-icon-edit" circle
      size="mini"
      @click="edit_charge_po(po.id)"
    />
  </td>
  <td>{{ po.category_task }}</td>
  <td>{{ po.supplier }}</td>
  <td>{{ po.category }}</td>

  <td>{{ po.status }}</td>
  <td>{{ po.branch }}</td>
  <td>{{po.charge_percent_applied}}</td>
  <td width="120" style="background-color: #f7d6d6">${{ po.actual_value }}</td>
  <td width="120" style="background-color: #dce8ec">${{ po.sale_price }}</td>
  <td width="50">
    <el-button type="danger"
      v-show="po.status == 'PENDING' && po.mark_as_deleted == 0"
      icon="el-icon-delete"
      @click="delete_charge_po(po.id)"
      circle
    />
  </td>
  <el-dialog
    :visible.sync="charge_po_dialog"
    width="70%"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <EditPurchaseOrder
      :po_id="po_id"
      :proposal="proposal"
      :refresh_list="refresh_list"
      :is_charge_po="is_charge_po"
      :working_file_id="po.working_file_id"
      :is_pop_up="true"
    />
  </el-dialog>
</tr>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { editor_store } from "store/modules/editor";
import EditPurchaseOrder from "../purchase_orders/Edit";
import {EventBus} from '../../utilities/EventBus';

export default {
  name: "ChargePOsList",
   props: ["po"],
  data() {
    return {
      po_id: 0,
      charge_po_dialog: false,
      is_charge_po: true,
    };
  },
  store: editor_store,
  computed: {
    ...mapGetters(["charge_pos_list", "proposal"]),
  },
  components: {
    EditPurchaseOrder,
  },
  mounted: function() {},
  methods: {
    ...mapActions(["load_charge_pos_list"]),
    refresh_list() {
      this.charge_po_dialog = false;
      this.load_charge_pos_list();
      EventBus.$emit('reloadCategoryWorkingFiles');
    },
    new_charge_po() {
      this.charge_po_dialog = true;
      this.po_id = 0;
    },
    edit_charge_po(po_id) {
      this.charge_po_dialog = true;
      this.po_id = po_id;
    },
    delete_charge_po(po_id) {
      let _this = this;
      this.$http.delete(`/purchase_orders/${po_id}`).then(
        (response) => {
          if (response.body) {
            _this.load_charge_pos_list();
          }
          EventBus.$emit('reloadCategoryWorkingFiles');
        },
        function(response) {
          console.log(response);
        }
      );
    },
  },
};
</script>

<style>
.mark_as_deleted {
  text-decoration-line: line-through;
}
</style>
