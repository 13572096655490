<template>
  <div
    v-show="new_mail_count > 0"
    style="display: inline; position: relative; top: 4px;"
  >
    <el-badge
      :value="new_mail_count + ' new'"
      class="item"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Newmail',
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['new_mail_count']),
  },
  created() {
    this.size();
  },
  methods: {
    ...mapActions(['count_new_emails']),
    size() {
      // setInterval(() => {
      //     this.count_new_emails();
      //     console.log(this.new_mail_count)
      // }, 100000);
    }

  }
};
</script>

<style scoped>
</style>
