<template>
  <div>
    <el-row>
      <el-col
        :xs="24"
        :sm="24"
        :md="12"
        :lg="12"
        :xl="12"
      >
        <el-container style="height: auto; border: 0px solid #eee">
          <el-main>
            <div class="overviewDiv">
              <a name="details" />
              <h2>JOB DETAILS</h2>
              <JobDetails :proposal="proposal" />
            </div>
          </el-main>
        </el-container>
      </el-col>

      <el-col
        :xs="24"
        :sm="24"
        :md="12"
        :lg="12"
        :xl="12"
      >
        <div class="overviewDiv">
          <a name="stockitems" />
          <el-container style="height: auto; border: 1px solid #eee">
            <el-main>
              <h2>HOLD POINTS</h2>
              <JobHoldPoints :holdpoint_tasks="holdpoint_tasks" />
            </el-main>
          </el-container>
        </div>
      </el-col>
      <el-col
        :xs="24"
        :sm="24"
        :md="12"
        :lg="12"
        :xl="12"
      >
        <div class="overviewDiv">
          <a name="stockitems" />
          <el-container style="height: auto; border: 1px solid #eee">
            <el-main>
              <h2>STOCK ITEMS/PO's/ASSETS</h2>
              <JobStockList :proposal="proposal" />
            </el-main>
          </el-container>
        </div>
      </el-col>
      <el-col
        :xs="24"
        :sm="24"
        :md="12"
        :lg="12"
        :xl="12"
      >
        <div class="overviewDiv">
          <a name="locations" />
          <el-container style="height: auto; border: 1px solid #eee">
            <el-main>
              <h2>DELIVERY/LOCATIONS</h2>
              <Delivery
                :suggested_from_address="proposal.branch_address"
                :suggested_to_address="proposal.account_address"
              />
            </el-main>
          </el-container>
        </div>
      </el-col>
    </el-row>

    <el-row>
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="24"
        :xl="24"
      >
        <div class="overviewDiv">
          <a name="joblist" />
          <el-container style="height: auto; border: 1px solid #eee">
            <el-main>
              <h2>LISTS AND TASKS</h2>
              <TaskList
                :proposal="proposal"
                :element="element"
              />
            </el-main>
          </el-container>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="24"
        :xl="24"
      >
        <VariationsLists :proposal_id="proposal_id" />
      </el-col>
    </el-row>
    <el-row>
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="24"
        :xl="24"
      >
        <div class="overviewDiv">
          <a name="communications" />
          <el-container style="height: auto; border: 1px solid #eee">
            <el-main>
              <h2>COMMUNCIATIONS</h2>

              <div id="1">
                <JobCommunication
                  ref="1"
                  :proposal="proposal"
                />
              </div>
            </el-main>
          </el-container>
        </div>
      </el-col>

      <el-col
        :xs="24"
        :sm="24"
        :md="12"
        :lg="12"
        :xl="21"
      />
    </el-row>
  </div>
</template>

<script>
import TaskList from '../sections/TaskList.vue';
import JobDetails from '../sections/JobDetails.vue';
import JobStockList from '../sections/JobStockList.vue';
import JobPOList from '../sections/JobPOList.vue';
import JobCommunication from '../sections/JobCommunication.vue';
import JobLocations from '../sections/JobLocations.vue';
import JobHoldPoints from '../sections/JobHoldPoints.vue';
import JobProgress from '../sections/JobProgress.vue';
import Delivery from '../../proposal/Delivery.vue';
import VariationsLists from '../sections/variations/VariationsLists';
import { ProposalMixin } from 'mixins/ProposalMixin.js';
import { editor_store } from 'store/modules/editor';
import { JobProposalMixin } from 'mixins/JobProposalMixin.js';
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: 'JobOverview.vue',
  components: {
    JobDetails,
    TaskList,
    JobStockList,
    JobPOList,
    JobCommunication,
    JobLocations,
    JobHoldPoints,
    JobProgress,
    Delivery,
    VariationsLists,
  },
  mixins: [ProposalMixin, JobProposalMixin],
  props: ['proposal', 'element', 'holdpoint_tasks'],
  store: editor_store,
  data() {
    return {};
  },

  mounted() {},
  computed: {
    ...mapGetters(['proposal_id']),
  },
  methods: {
    download_file() {
      this.download_packing_slip(this.proposal.id).then((response) => {
        this.download('', response);
      });
    },
    download(file, data) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
      return;
    },
  },
  watch: {},
};
</script>

<style>
.overviewDiv {
  border: 0px solid #999;
  padding: 20px;
}

.overviewDiv h2 {
  font-size: 20px;
  text-transform: uppercase;
  background: #999;
  color: #fff;
  padding: 10px;
  text-align: center;
  letter-spacing: 2px;
  margin: 1px 0 1px 0;
}

@media only screen and (max-width: 600px) {
  .overviewDiv {
    border: 0px solid #999;
    padding: 0px;
  }
}
</style>
