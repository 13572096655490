<template>
  <div v-loading="main_loading" class="export_working_file">
    <el-row>
      <el-col :md="10">
        <el-radio-group v-model="export_to_template">
          <el-radio label="new_template">New proposal</el-radio>
          <el-radio label="existing_template">Existing proposal</el-radio>
        </el-radio-group>
        <el-checkbox
          v-show="export_to_template == 'new_template'"
          v-model="is_global"
          >Global</el-checkbox
        >
      </el-col>
      <el-col :span="14">
        <el-select
          v-show="export_to_template == 'existing_template'"
          v-model="selected_proposal_template"
          size="small"
          style="width: 200px"
        >
          <el-option-group
            v-for="group in proposal_templates"
            :key="group.group_name"
            :label="group.group_name"
          >
            <el-option
              v-for="item in group.proposals"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
          </el-option-group>
        </el-select>
      </el-col>
    </el-row>

    <!-- <el-row>
      <el-col :span="24">
        <el-checkbox-group v-model="checked_working_files" class="left">
          <el-checkbox
            v-for="working_file in working_files_list"
            :label="working_file.id"
            :key="working_file.id"
          >
            <img
              :src="working_file.thumb"
              style="border: 1px solid #e4dede;width: 30px"
            />
            <div class="label" :title="working_file.name">
              {{ working_file.name }}
            </div>
          </el-checkbox>
        </el-checkbox-group>
      </el-col>
    </el-row> -->
    <el-row>
      <el-col :span="24">
        <el-button
          @click="export_working_file"
          class="right"
          size="mini"
          type="primary"
          >Export</el-button
        >
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { editor_store } from "store/modules/editor";

export default {
  name: "ExportWorkingFile",
  store: editor_store,
  props: ["hide_export_working_file_dialog", "export_working_file_id"],
  data() {
    return {
      main_loading: false,
      selected_proposal_template: "",
      export_to_template: "new_template",
      is_global: false,
      proposal_templates: [],
    };
  },
  mounted() {
    this.fetch_proposal_templates();
    let _this = this;
    setTimeout(function() {
      _this.set_inputs_to_default();
    }, 500);
  },
  computed: {
    ...mapGetters(["proposal"]),
  },
  methods: {
    fetch_proposal_templates() {
      this.proposal_templates = [];
      if (this.proposal_templates.length == 0) {
        this.$http
          .get(`/proposal_templates_by_account/${this.proposal.account_id}`)
          .then((response) => {
            this.proposal_templates = response.body;
          });
      }
    },
    export_working_file() {
      if (
        this.export_to_template == "existing_template" &&
        this.selected_proposal_template == ""
      ) {
        this.$message({
          type: "warning",
          message:
            "Please make sure to select a proposal template for 'existing proposal' option.",
        });
        return;
      }
      let params = {
        id: this.export_working_file_id,
        is_existing_template:
          this.export_to_template == "existing_template" ? true : false,
        proposal_id: this.selected_proposal_template,
        is_global: this.is_global
      };
      this.main_loading = true;
      this.$http
        .post("/export_working_file", params)
        .then((response) => {
          let proposal_str = `Working file exported succesfully ${proposal_str} to new proposal template <a href="/edit_proposal?id=${response.data.proposal_id}">#${response.data.proposal_id}</a>`;
          this.$message({
            type: "success",
            duration: 5000,
            dangerouslyUseHTMLString: true,
            message: proposal_str,
          });
          this.hide_export_working_file_dialog();
        })
        .catch((error) => {
          console.log(error);
          this.$message({
            type: "error",
            message:
              "error happened while export working file, please try again.",
          });
        })
        .finally(() => (this.main_loading = false));
      // .then(response => {
      //   this.main_loading = false;
      //   this.$message({
      //     type: "error",
      //     message:
      //       "error happened while export working file, please try again."
      //   });
      // });
    },
    set_inputs_to_default() {
      let _this = this;
      Array.from(
        document.querySelectorAll(".export_working_file .el-input")
      ).forEach(function(e, i) {
        e.classList.add("browser-default");
        if (e.querySelector("input") != null)
          e.querySelector("input").className += " browser-default";
      });
    },
  },
};
</script>
