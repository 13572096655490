<template>
  <div id="crm_user_list">
    <el-table
      ref="list-table"
      :data="crm_users"
      strip
    >
      <el-table-column
        prop="user_name"
        label="First name"
      />
      <el-table-column
        prop="user_surname"
        label="Last name"
      />
      <el-table-column>
        <template slot-scope="scope">
          <el-button
            size="mini"
            style="width:85px;"
            @click="edit_user(scope.$index, scope.row)"
          >
            Edit
          </el-button>
          <el-button
            size="mini"
            type="danger"
            @click="inactivate_user(scope.$index)"
          >
            Inactivate
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>

export default {
  name: 'CrmUserList',
  data() {
    return {
      crm_users: [{
        inactive: 0
      }]
    };
  },
  mounted() {
    this.get_crm_users();
  },
  created() {
  },
  methods: {
    edit_user(index,row){
      window.location = `/crm_users/${row.id}/edit`;
    },
    inactivate_user(index){
      this.$http.patch(`/crm_users/${this.crm_users[index].id}`, { crm_user: {inactive: '1' }}).then(() => {
        this.$message({
          message: 'User inactivated.',
          type: 'success',
        });
        this.get_crm_users();
      },(reject) => {
        this.$message({
          message: `${reject.data.inactive.join(
            '<br>'
          )}`,
          dangerouslyUseHTMLString: true,
          type: 'error'
        });
        this.get_crm_users();
      });
    },
    get_crm_users(){
      this.$http.get('/users_active_list').then(
        (response) => {
          this.crm_users = response.data;
        });
    }
  }
};
</script>
<style scoped>
@media only screen and (max-width: 993px) {
  #crm_user_list /deep/ .el-dialog__body {
    padding: 4px;
  }
  #crm_user_list /deep/ .el-button+.el-button {
    margin-left: 0px;
    margin-bottom: 5px;
  }
}
</style>
