<template>
    <div>
        <div class="card  ">
            <div class="card-content " v-loading="dashboard_loading">
                <span class="card-title">
                    Accounts (<span v-show="show_sales">Sales,</span> Billable and Free minutes)
                </span>
                <el-date-picker
                        v-model="selected_date_range"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="To"
                        start-placeholder="Start date"
                        end-placeholder="End date"
                        style="width: auto !important;"
                        :picker-options="pickerOptions2"
                        v-on:change="fill_data"
                >
                </el-date-picker>
                <br>
                <div class="users_list_container">
                    <table class="responsive-table striped">
                        <thead>
                        <tr>
                            <th rowspan="2">Account</th>
                            <th colspan="2">Time Allocated</th>
                            <th rowspan="2">Total</th>
                            <th rowspan="2" v-show="show_sales">Sales</th>
                        </tr>
                        <tr>
                            <th>Billable</th>
                            <th>Free</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for='client in clients_data'>
                            <td>
                                {{client.account_name}} 
                                <i v-show="client.is_key_account == 1" class="material-icons dp48">done</i>
                            </td>
                            <td>
                              <a v-on:click="show_account_tasks(client.id,1,0)">
                                <Time :is_hours="client.is_hours" :minutes="client.total_billable" />
                              </a>
                            </td>
                            <td>
                              <a v-on:click="show_account_tasks(client.id,0,1)">
                                <Time :is_hours="client.is_hours" :minutes="client.total_free" />
                              </a>
                            </td>
                            <td>
                              <a v-on:click="show_account_tasks(client.id,1,1)">
                                <Time :is_hours="client.is_hours" :minutes="client.total_allocated" />
                              </a>
                            </td>
                            <td v-show="show_sales" ><a v-on:click="show_sales_records(client.id)">{{client.sales | currency}}</a></td>
                            
                        </tr>
                        </tbody>
                        <tfoot style="font-weight: bold;" v-if="clients_data.length > 0">
                        <tr style="border-top: 1px solid gray">
                            <td></td>
                            <td><Time :is_hours="clients_data[0].is_hours" :minutes="total_minutes('total_billable')" /></td>
                            <td><Time :is_hours="clients_data[0].is_hours" :minutes="total_minutes('total_free')" /></td>
                            <td><Time :is_hours="clients_data[0].is_hours" :minutes="total_minutes('total_allocated')" /></td>
                            <td>{{total_minutes('sales') | currency}}</td>
                            <td></td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
        <modal name="clients_list" :height="700" :width="1200" :adaptive="true">
            <div class="card ">
                <div class="card-content  ">
                    <span class="card-title">{{list_title}}<hr></span>
                    <div class="progress" v-show="is_loading">
                        <div class="indeterminate"></div>
                    </div>
                    <el-table
                      v-if="is_job_list"
                      :data="rows"
                      style="width: 100%"
                      height="600"
                    >
                      <el-table-column
                        prop="job_no"
                        :label="'job_no' | capitalize"
                        sortable
                      />
                      <el-table-column
                        prop="job"
                        :label="'job' | capitalize"
                        sortable
                      />
                      <el-table-column
                        prop="task"
                        :label="'task' | capitalize"
                        sortable
                      />
                      <el-table-column
                        prop="billable"
                        :label="'billable' | capitalize"
                        sortable
                      />
                      <el-table-column
                        prop="time_allocated"
                        :label="'time_allocated' | capitalize"
                        sortable
                      >
                        <template slot-scope="scope">
                          <Time :is_hours="scope.row.is_hours" :minutes="scope.row.time_allocated" />
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="start_time"
                        :label="'start_time' | capitalize"
                        sortable
                      />
                      <el-table-column
                        prop="end_time"
                        :label="'end_time' | capitalize"
                        sortable
                      />
                    </el-table>
                    <el-table
                        v-else
                        :data="rows"
                        style="width: 100%"
                        height="600">

                      <el-table-column
                          v-for='column in columns'
                          v-bind:key='column'
                          v-bind:prop="column"
                          v-bind:label="column | capitalize"
                          sortable
                      >
                      </el-table-column>
                    </el-table>

                </div>
                <div class="card-action">

                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import Time from '../shared/Time';
    export default {
        name: "ClientsDashboard",
        components: { Time },
        data() {
            return {
                columns: [],
                dashboard_loading: false,
                show_sales: false,
                rows: [],
                clients_data: [],
                list_title: '',
                is_loading: false,
                pickerOptions2: {
                    shortcuts: [{
                        text: 'Current month',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: 'Last week',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                        {
                            text: 'Last month',
                            onClick(picker) {
                                const end = new Date();
                                const start = new Date();
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                                picker.$emit('pick', [start, end]);
                            }
                        }, {
                            text: 'Last 3 months',
                            onClick(picker) {
                                const end = new Date();
                                const start = new Date();
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                                picker.$emit('pick', [start, end]);
                            }
                        }, {
                            text: 'Last 6 months',
                            onClick(picker) {
                                const end = new Date();
                                const start = new Date();
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
                                picker.$emit('pick', [start, end]);
                            }
                        }, {
                            text: 'Last year',
                            onClick(picker) {
                                const end = new Date();
                                const start = new Date();
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                                picker.$emit('pick', [start, end]);
                            }
                        }]
                },
                selected_date_range: '',
                is_job_list: false
            }
        },
        mounted() {
            this.selected_date_range = [this.start_date, this.end_date]
            this.fill_data()
        },
        computed: {
            current_date() {
                return new Date()
            },
            start_date() {
                return new Date(this.current_date.getFullYear(), this.current_date.getMonth(), 1)
            },
            end_date() {
                return new Date(this.current_date.getFullYear(), this.current_date.getMonth(), this.current_date.getDate())
            }

        },
        methods: {
            fill_data() {
                let self = this
                self.dashboard_loading = true
                this.$http.post('/get_clients_dashboard', {
                    start_date: this.selected_date_range[0],
                    end_date: this.selected_date_range[1]
                }).then(response => {
                        self.dashboard_loading = false
                        if (response.data.status == 200) {
                            self.clients_data = response.data.data
                            self.show_sales = response.data.show_sales
                        }
                    }
                )
            },
            show_sales_records(id)
            {
                this.is_job_list = false;
                let self = this
                this.$http.post('/get_sales_records_by_account', {                
                        account_id: id,
                        start_date: this.selected_date_range[0],
                        end_date: this.selected_date_range[1]                     
                }).then(response => {
                        if (response.data.status == 200) {
                            self.rows = response.data.data.rows
                            self.columns = response.data.data.columns
                            self.list_title = "Sales list"
                            self.open_modal()
                        }
                    }
                )
            },
            show_account_tasks(id, billable, free) {
              this.is_job_list = true;
              let self = this
                this.$http.post('/client_tasks_list', {
                    filter: {
                        account_id: id,
                        start_date: this.selected_date_range[0],
                        end_date: this.selected_date_range[1],
                        billable: billable,
                        free: free
                    }
                }).then(response => {
                        if (response.data.status == 200) {
                            self.rows = response.data.data.rows
                            self.columns = response.data.data.columns
                            self.list_title = "Jobs list"
                            self.open_modal()
                        }
                    }
                )
            },
            open_modal() {
                this.$modal.show('clients_list')
            },
            hide_modal() {
                this.$modal.hide('clients_list')
            },
            show_user_kpi_tasks(user_id, user_name) {
              this.is_job_list = false;
                this.$http.post('/get_clients_list_dashboard', {
                    user_id: user_id,
                    start_date: this.selected_date_range[0],
                    end_date: this.selected_date_range[1]
                }).then(response => {
                        if (response.data.status == 200) {
                            this.rows = response.data.data.rows
                            this.columns = response.data.data.columns
                            this.list_title = user_name + " KPI"
                            this.open_modal()
                        }
                    }
                )
            },
            total_minutes(col) {
                return this.clients_data.reduce(function (total, item) {
                    return total + item[col];
                }, 0);
            }
        }
    }
</script>

<style scoped>
    td, th {
        text-align: center;
        padding: 10px;
    }

    th {
        font-weight: 800;
    }

    .header {
        background-color: #ddecf9;
    }

    .subheader {
        background-color: #e5f3ff;
    }

    .rows {
        background-color: aliceblue;
    }

    a {
        cursor: pointer;
    }

    th {
        font-weight: bold;
    }

    .users_list_container {
        max-height: 470px;
        overflow: auto;
    }
    table{
        font-size: 14px;
    }
</style>

