<template>
  <div id="crm-user-welcome">
    <el-form
      ref="crm_user_details"
      :model="crm_user_details"
      label-width="150px"
      autocomplete="off"
      :rules="rules"
    >
      <el-form-item
        label="Leader"
        required
      >
        <el-select
          v-model="selected_leader_id"
          placeholder="Select"
          size="small"
          style="width: 280px;"
        >
          <el-option
            v-for="user in crm_users"
            :key="user.id"
            :label="user.user_name + ' ' + user.user_surname + '/' + user.title"
            :value="user.id"
          >
            <span style="float: left">{{ user.user_name }} {{ user.user_surname }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px;margin-left: 5px;">{{ user.title }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="Job title"
        required
      >
        <el-input
          v-model="crm_user_details.title"
          size="small"
        />
      </el-form-item>
      <el-form-item
        label="First name"
        required
      >
        <el-input
          v-model="crm_user_details.user_name"
          size="small"
        />
      </el-form-item>
      <el-form-item
        label="Last name"
        required
      >
        <el-input
          v-model="crm_user_details.user_surname"
          size="small"
        />
      </el-form-item>
      <el-form-item
        label="Personal email"
        required
      >
        <el-input
          v-model="crm_user_details.personal_email"
          size="small"
        />
      </el-form-item>
      <el-form-item
        label="Pay rate"
        required
      >
        <el-input-number
          v-model="crm_user_details.rate"
          size="small"
          :precision="2"
          :step="0.1"
        />
      </el-form-item>
      <el-form-item
        label="Start date"
        required
      >
        <el-date-picker
          v-model="crm_user_details.employment_start_date"
          type="date"
          placeholder="Start date"
          format="dd.MM.yyyy"
          value-format="dd.MM.yyyy"
          size="small"
        />
      </el-form-item>
      <el-form-item
        label="Acceptance"
        required
      >
        <el-date-picker
          v-model="crm_user_details.acceptance_date"
          type="datetime"
          placeholder="Select date and time"
          size="small"
          format="dd.MM.yyyy HH:mm:ss"
        />
      </el-form-item>
      <el-form-item
        label="Password"
        required
        prop="password"
      >
        <el-input
          v-model="crm_user_details.password"
          size="small"
          type="password"
          autocomplete="new-password"
          placeholder="must be 6 to 14 characters"
        />
      </el-form-item>
      <el-form-item
        label="Confirmation"
        required
        prop="password_confirmation"
      >
        <el-input
          v-model="crm_user_details.password_confirmation"
          size="small"
          type="password"
          placeholder="Password confirmation"
          autocomplete="new-password"
        />
      </el-form-item>
      <el-form-item
        label="E-Signature"
        required
      >
        <el-select
          v-model="selected_e_signature_id"
          placeholder="Select"
          size="small"
          style="width: 280px;"
        >
          <el-option
            v-for="user in crm_user_signatures"
            :key="user.id"
            :label="user.user_name + ' ' + user.user_surname + '/' + user.title"
            :value="user.id"
          >
            <span style="float: left">{{ user.user_name }} {{ user.user_surname }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px;margin-left: 5px;">{{ user.title }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-tooltip
          placement="bottom"
          effect="light"
          type="primary"
        >
          <div slot="content">
            Note: + Benefits PDF Description Tab, + Company Clothe PDF Tab if required. <br>All input fields are required to create a login(/prospect/login),
            letter of offer and other employee pdfs.<br>If you want to create new Files, please delete the Employee/'USER' folder/files in File manager first or press delete files button<br>
            The email will be send to his personal email address.
          </div>
          <el-button size="small">
            Info
          </el-button>
        </el-tooltip>
      </el-form-item>
      <el-form-item>
        <el-button
          type="danger"
          size="small"
          style="float:left;"
          @click="delete_employee_files"
        >
          Delete files
        </el-button>
        <el-button
          type="primary"
          size="small"
          style="float:right;"
          :disabled="disable_send"
          @click="send_welcome_email('crm_user_details')"
        >
          Send welcome email
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>

export default {
  name: 'CrmUserWelcome',
  props: {
    crm_user_id: {
      type: [String,Number],
      required: true
    }
  },
  data() {
    return {
      crm_user_details: {},
      crm_users: {},
      crm_user_signatures: {},
      selected_leader_id: '',
      selected_e_signature_id: '',
      disable_send: false,
      rules: {
        password: [{ required: true, message: 'Please provide a password', trigger: 'blur' },
          { min: 6, max: 14, message: 'Length should be 6 to 14', trigger: 'blur' }],
        password_confirmation: [{ required: true, message: 'Please provide a password confirmation', trigger: 'blur' },
          { min: 6, max: 14, message: 'Length should be 6 to 14', trigger: 'blur' }],
      }
    };
  },
  watch:{
    crm_user_id(){
      this.get_crm_user_details();
    }
  },
  mounted() {
    this.get_crm_user_details();
    this.get_crm_users();
    this.get_e_signatures();
  },
  methods: {
    delete_employee_files() {
      this.$alert('Are you sure you want to delete all employee files for this user?', 'Delete', {
        confirmButtonText: 'DELETE',
        callback: () => {
          this.delete_files();
        }
      });
    },
    delete_files(){
      this.$http.post('/delete_employee_folder', {crm_user_id: this.crm_user_id }).then(() => {
        this.$message({
          message: 'Files deleted',
          type: 'success',
        });
      },() => {
        this.$message({
          message: 'Files not deleted',
          type: 'error'
        });
      });
    },
    get_crm_user_details(){
      this.$http.get('/show_crm_user_details/'+ this.crm_user_id).then((response) => {
        this.crm_user_details = response.data;
        if( this.crm_user_details.e_signature_id ){
          this.selected_e_signature_id = this.crm_user_details.e_signature_id;
        }
        if( this.crm_user_details.leader_id ){
          this.selected_leader_id = this.crm_user_details.leader_id;
        }

      });
    },
    get_crm_users(){
      this.$http.get('/show_crm_user_leaders').then((response) => {
        this.crm_users = response.data;
      });
    },
    get_e_signatures(){
      this.$http.get('/show_crm_user_e_signatures').then((response) => {
        this.crm_user_signatures = response.data;
      });
    },
    send_welcome_email(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if( this.selected_leader_id ){
            this.crm_user_details.leader_id = this.selected_leader_id;
          }
          if( this.selected_e_signature_id ){
            this.crm_user_details.e_signature_id = this.selected_e_signature_id;
          }
          this.disable_send = true;
          this.$http.post(`/crm_user_welcome_email/${this.crm_user_id}`, {crm_user: this.crm_user_details }).then(() => {
            this.$message({
              message: 'Email sent. PDF Employee Files created.',
              type: 'success',
            });
            this.disable_send = false;
          }, (reject) => {
            this.$message({
              message: `${reject.data.errors.join(
                '<br>'
              )}`,
              dangerouslyUseHTMLString: true,
              type: 'error'
            });
            this.disable_send = false;
          });
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style scoped>
#crm-user-welcome{
  max-width: 430px;
}

@media only screen and (max-width: 993px) {

}
</style>
