<template>
  <div class="nesting_calculator">
    <div>
      <div class>
        <div class="col m3 s12">
          <p style="margin: 0px">
            <b>Horizontal Margin</b>
          </p>
          <el-input-number
            style="width: 90px"
            :min="0"
            size="mini"
            v-model="custom_margin_horizontal"
          ></el-input-number>
          <p></p>
        </div>
        <div class="col m3 s12">
          <p style="margin: 0px">
            <b>Vertical margin</b>
          </p>
          <el-input-number
            style="width: 90px"
            :min="0"
            size="mini"
            v-model="custom_margin_vertical"
          ></el-input-number>
        </div>
        <div class="col m3 s12">
          <p>
            <el-radio-group v-model="nesting_alignment" size="mini">
              <el-radio-button label="left">left</el-radio-button>
              <el-radio-button label="center">Center</el-radio-button>
            </el-radio-group>
          </p>
        </div>
        <div class="col m3 s12">
          <p>
            <el-button size="mini" @click="calculate_nesting_items"
              >Calculate</el-button
            >
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col s3"></div>
      </div>
      <div class="row">
        <div class="col s6">
          <b>
            Working file
            <hr />
          </b>
          <el-tabs tab-position="left">
            <el-tab-pane
              v-for="item in nesting_proposal_items"
              :key="item.id"
              :label="item.id"
            >
              <NestingProposalItem
                :nesting_item_specs="item"
                :custom_margin_horizontal="custom_margin_horizontal"
                :custom_margin_vertical="custom_margin_vertical"
              ></NestingProposalItem>
            </el-tab-pane>
          </el-tabs>
          <!-- <svg
            :id="svg_id"
            style="fill: none"
            class="selected_svg svg-content-responsive"
            :viewBox="viewbox"
            preserveAspectRatio="xMinYMin meet"
          ></svg>-->
        </div>
        <div class="col s6">
          <b>
            Sheets &amp; cuttings
            <hr />
          </b>
          <div class="row cuttings_container"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { editor_store } from "store/modules/editor";
import { mapGetters, mapActions, mapMutations } from "vuex";
import SheetCalculation from "utilities/SheetsCalculation";
import { WorkspaceMixin } from "mixins/WorkspaceMixin.js";
import NestingProposalItem from "./NestingProposalItem";

export const mm_to_px = 3.779527559;
export default {
  name: "NestingCalculator",
  props: ["item_specs", "nesting_options"],
  store: editor_store,
  mixins: [WorkspaceMixin],
  components: { NestingProposalItem },
  data() {
    return {
      svg: "",
      nesting_proposal_items: [],
      nesting_alignment: "center",
      custom_margin_vertical: 0,
      custom_margin_horizontal: 0,
    };
  },
  methods: {
    calculate_nesting() {
      //this.calculate_nesting_qty();
      // var sheet_calc = new SheetsCalculation({
      //   w: this.item_specs.width, //+ 2 * this.custom_margin,
      //   h: this.item_specs.height, // + 2 * this.custom_margin,
      //   blocks: rect_arr,
      //   num: 1
      // });
    },
    get_nesting_details(el, idx) {
      // get selected items viewport boundaries
      let specs = this.get_relative_boundaries(el);
      let ref_id = this.get_element_id(el);

      // generate rectangles by specs
      let rects = this.generate_rectangles_by_specs(
        idx,
        specs,
        this.item_specs,
        this.nesting_alignment,
        this.custom_margin_vertical,
        this.custom_margin_horizontal
      );
      
      return {
        id: idx.toString(),
        ref_id: ref_id,
        custom_x: specs.x1 / (mm_to_px * this.page_scale),
        custom_y: specs.y1 / (mm_to_px * this.page_scale),
        custom_width: specs.width / (mm_to_px * this.page_scale),
        custom_height: specs.height / (mm_to_px * this.page_scale),
        rects: rects,
      };
    },
    fill_nesting_items() {
      if (this.item_specs.width == 0 || this.item_specs.height == 0) {
        this.$message({
          message: "Items specs need to be defined.",
          type: "warning",
        });
        return;
      }
      let linear_items = d3
        .selectAll(".svg_inner_container [data-selected]")
        .nodes();
      this.nesting_proposal_items = [];
      if (linear_items.length > 0) {
        linear_items.forEach((el, idx) => {
          this.nesting_proposal_items.push(
            this.get_nesting_details(el, idx + 1)
          );
        });
      } else if (this.selected_object != null) {
        this.nesting_proposal_items.push(
          this.get_nesting_details(this.selected_object, 1)
        );
      } else {
        this.$message({
          message: "No selected working area.",
          type: "warning",
        });
      }
    },
    draw_cuttings(results) {
      let _this = this;
      d3.selectAll(".cuttings_container div").remove();
      var x;
      for (x = 0; x < results.sheets.length; x++) {
        let cutting_id = "cuttings_" + x;
        let cuttings_container = d3.select(`.cuttings_container`);
        cuttings_container
          .append("div")
          .classed("col s4", true)
          .append("svg")
          .attr("id", cutting_id)
          .attr("preserveAspectRatio", "xMinYMin meet")
          .classed("cuttings", true);

        let svg = d3.select(`#${cutting_id}`);
        svg.attr(
          "viewBox",
          "0 0 " + this.item_specs.width + " " + this.item_specs.height
        );
        let b;
        let sheet_blocks = results.sheets[x];
        for (b = 0; b < sheet_blocks.length; b++) {
          let block = sheet_blocks[b];
          svg
            .insert("g")
            .append("rect")
            .attr("fill", "lightsteelblue")
            .attr("stroke", "red")
            .attr("x", block.fit.x)
            .attr("y", block.fit.y)
            .attr("width", block.w)
            .attr("height", block.h);

          let font_size = Math.min(block.w, block.h) / 2;
          svg
            .insert("g")
            .append("text")
            .attr("font-size", font_size / 4)
            .attr("x", block.fit.x + 3)
            .attr("y", block.fit.y + font_size)
            .attr("fill", "black")
            .text(`${block.id}`);

          svg
            .insert("g")
            .append("text")
            .attr("font-size", font_size / 4)
            .attr("x", block.fit.x + 3)
            .attr("y", block.fit.y + font_size * 2)
            .attr("fill", "black")
            .text(`W: ${Math.round(block.w)}, H: ${Math.round(block.h)}`);
        }
      }
    },
    calculate_nesting_items() {
       
      if(this.item_specs.width == 0 || this.item_specs.height == 0)
      {       
        return ;
      }
      // [].concat(['s','d','d'], [1,2,3],['s'])
      let rect_arr = [];
      let inner_shapes = [];
      this.fill_nesting_items();
      this.nesting_proposal_items.forEach((el, idx) => {
        rect_arr = rect_arr.concat(el.rects);
        inner_shapes.push({
          x: el.custom_x * (mm_to_px * this.page_scale),
          y: el.custom_y * (mm_to_px * this.page_scale),
          w: el.custom_width * (mm_to_px * this.page_scale),
          h: el.custom_height * (mm_to_px * this.page_scale),
          shapes: el.rects,
        });
      });

      var sheet_calc = new SheetsCalculation({
        w: this.item_specs.width, //- 2 * this.custom_margin_horizontal,
        h: this.item_specs.height, // - 2 * this.custom_margin_vertical,
        blocks: rect_arr,
        num: 1,
      });
      var results = sheet_calc.run();
      this.draw_cuttings(results);
      let working_file_details = {
        horizontal_margin: this.custom_margin_horizontal,
        vertical_margin: this.custom_margin_vertical,
        qty: results.sheets.length,
        inner_shapes: inner_shapes,
        data: JSON.stringify(results.sheets),
        nesting_proposal_items: this.nesting_proposal_items,
      };
      this.$emit("update_nesting_details", working_file_details);
    },
    wait(ms) {
      var start = new Date().getTime();
      var end = start;
      while (end < start + ms) {
        end = new Date().getTime();
      }
    },
    clear_svgs() {
      this.svg.selectAll("g").remove();
      d3.selectAll(".cuttings_container div").remove();
    },
    update_nesting_options() {
      let nesting_options = {
        custom_margin_vertical: this.custom_margin_vertical,
        custom_margin_horizontal: this.custom_margin_horizontal,
        nesting_alignment: this.nesting_alignment,
      };
      this.$emit("update_nesting_options", nesting_options);
    },
    set_inputs_to_default() {
      let _this = this;
      Array.from(
        document.querySelectorAll(".nesting_calculator .el-input")
      ).forEach(function(e, i) {
        e.classList.add("browser-default");
        if (e.querySelector("input") != null)
          e.querySelector("input").className += " browser-default";
      });
    },
  },
  watch: {
    "page_settings.width": function(newVal) {
      this.custom_width = this.page_settings.width;
      this.custom_height = this.page_settings.height;
    },
    "item_specs.width": function(newVal) {
      if (newVal != undefined && newVal > 0) this.fill_nesting_items();
    },
    "item_specs.height": function(newVal) {
      if (newVal != undefined && newVal > 0) this.fill_nesting_items();
    },
    "item_specs.shape_qty": function(newVal) {
      if (newVal != undefined && newVal > 0) this.fill_nesting_items();
    },
    nesting_alignment: function(newVal) {
      this.update_nesting_options();
      this.fill_nesting_items();
    },
    custom_margin_vertical: function(newVal) {
      this.update_nesting_options();
    },
    custom_margin_horizontal: function(newVal) {
      this.update_nesting_options();
    },
    nesting_options: function(newVal) {
      this.nesting_alignment = this.nesting_options.nesting_alignment;
      this.custom_margin_horizontal = this.nesting_options.custom_margin_horizontal;
      this.custom_margin_vertical = this.nesting_options.custom_margin_vertical;
    },
  },
  created() {},
  mounted() {
    let _this = this;
    setTimeout(() => {
      _this.set_inputs_to_default();
    }, 200);
    // this.nesting_alignment = this.nesting_options.nesting_alignment;
    // this.custom_margin_horizontal = this.nesting_options.custom_margin_horizontal;
    // this.custom_margin_vertical = this.nesting_options.custom_margin_vertical;
  },
  computed: {
    ...mapGetters(["page_settings", "selected_object", "page_scale"]),
    viewbox: function() {
      return "0 0 " + this.custom_width + " " + this.custom_height;
    },
  },
};
</script>

<style scope>
.item_style {
  fill: #c3e5f169;
  stroke-dasharray: 3px;
  stroke-width: 1px;
  stroke: black;
}

.item_margin_style {
  fill: none;
}

.item_text_style {
  fill: black;
}
</style>
