<template>
  <div>
    <b>  Unassigned Payments: {{ master_payment.credit_note }} </b>
    <el-table
      :data="master_payment.payments"
      style="width: 100%"
      stripe
    >
      <el-table-column
        prop="invoice_id"
        label="Invoice ID"
      >
        <template slot-scope="scope">
          <el-link
            :href="`/invoices/${scope.row.invoice_id}/edit`"
            target="_blank"
          >
            {{ scope.row.invoice_id }}
          </el-link>
        </template>
      </el-table-column>
      <el-table-column
        prop="invoice_date"
        label="Invoice Date"
      />
      <el-table-column
        prop="total_price"
        label="Total Price"
      />
      <el-table-column
        prop="accumulate_amount"
        label="Accumulate Payment"
      />
      <el-table-column
        prop="paid_amounts"
        label="Paid Payment"
      />
      <el-table-column
        prop="amount"
        label="Payment"
      />
      <el-table-column
        prop="left"
        label="Left"
      />
    </el-table>
    <br>
    <el-button
      type="success"
      size="mini"
      @click="onPayments"
    >
      Make Payments
    </el-button>

    <el-button
      type="danger"
      size="mini"
      @click="onCancel"
    >
      Cancel
    </el-button>
  </div>
</template>

<script>

import {createMasterPaymentPayments} from '../../../../api/master_payment';

export default {
  name: 'Results',
  props: {
    account_id: {
      type: String,
      required: true
    },
    master_payment: {
      type: Object,
      required: true
    }
  },
  methods: {
    onPayments() {
      createMasterPaymentPayments(this,this.account_id,this.master_payment.date,this.master_payment.payment)
        .then(({data})=>{
          this.$message({
            type: 'success',
            message: data.message
          });
          this.$emit('paymentUpdated');
        });
    },
    onCancel() {
      this.$emit('paymentCancel');
    }
  }
};
</script>

<style scoped>

</style>