<template>
  <div>
    <div>
      <el-row>
        <h2></h2>
        <div style="width:100%;background:orange;color:#fff; padding:5px; margin-bottom:10px;">
          NOTE: Make sure the "Counts for overall weigh-in %" all total to 100%,
          over all tabs available
        </div>
        <el-row style="margin-bottom:40px;">
          <span class="smallheader">Timeframe </span>
          <P>Timeframe details calculated from company information </P>
          <el-col
            class="smallbox"
            :xs="24"
            :sm="24"
            :md="4"
            :lg="4"
            :xl="4"
            style="background:#cfffe5"
          >
            <span class="semilables">Counts for overall weigh-in %</span>
            <el-input
              v-model="user_details.data.target_section.timesheet_overall_perc"
              @change="update_targets"
            >
            </el-input>
          </el-col>
        </el-row>

        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8"> </el-col>
        <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
          <span class="smallheader">Overall Weekly Weigh In Rating </span>

          <el-col class="smallbox" :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
            <span class="semilables">Gold Star </span>
            <el-input
              v-model="user_details.data.target_section.weightin_gold"
              @change="update_targets"
            >
            </el-input>
            <el-input
              v-model="user_details.data.target_section.weightin_gold_message"
              @change="update_targets"
              style="font-size:12px;font-style: italic; "
            ></el-input>
          </el-col>

          <el-col class="smallbox" :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
            <span class="semilables">Green Star </span>
            <el-input
              v-model="user_details.data.target_section.weightin_green"
              @change="update_targets"
            >
            </el-input>
            <el-input
              v-model="user_details.data.target_section.weightin_green_message"
              @change="update_targets"
              style="font-size:12px;font-style: italic; "
            ></el-input>
          </el-col>

          <el-col class="smallbox" :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
            <span class="semilables">Yellow Star </span>
            <el-input
              v-model="user_details.data.target_section.weightin_yellow"
              @change="update_targets"
            >
            </el-input>
            <el-input
              v-model="user_details.data.target_section.weightin_yellow_message"
              @change="update_targets"
              style="font-size:12px;font-style: italic; "
            ></el-input>
          </el-col>

          <el-col class="smallbox" :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
            <span class="semilables">Orange Star </span>
            <el-input
              v-model="user_details.data.target_section.weightin_orange"
              @change="update_targets"
            >
            </el-input>
            <el-input
              v-model="user_details.data.target_section.weightin_orange_message"
              @change="update_targets"
              style="font-size:12px;font-style: italic; "
            ></el-input>
          </el-col>
          <el-col class="smallbox" :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
            <span class="semilables">Red Star </span>
            <el-input
              v-model="user_details.data.target_section.weightin_red"
              @change="update_targets"
            >
            </el-input>
            <el-input
              v-model="user_details.data.target_section.weightin_red_message"
              @change="update_targets"
              style="font-size:12px;font-style: italic; "
            ></el-input>
          </el-col>
        </el-col>
      </el-row>

      <el-row style="margin-top:30px;">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <span class="smallheader">Time Sheet </span>
          <P>Calculated based on completion of time spent over time sheet</P>

          <el-col class="smallbox" :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
            <span class="semilables">Green (%)</span>
            <el-input
                v-model="user_details.data.target_section.timesheet_green"
                @change="update_targets"
            >
            </el-input>
          </el-col>

          <el-col class="smallbox" :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
            <span class="semilables">Yellow (%)</span>
            <el-input
                v-model="user_details.data.target_section.timesheet_yellow"
                @change="update_targets"
            >
            </el-input>
          </el-col>

          <el-col class="smallbox" :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
            <span class="semilables">Orange (%)</span>
            <el-input
                v-model="user_details.data.target_section.timesheet_orange"
                @change="update_targets"
            >
            </el-input>
          </el-col>

        </el-col>
      </el-row>

      <el-row style="margin-top:30px;">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <span class="smallheader">Performance (% Achievements) </span>
          <p>Calculated based on completion of allocated time over time sheet,
            check billable to billable only, un-check equals to free & billable total
          </p>
          <span class="smallheader">Measure by </span>
          <el-col
            :xs="24"
            :sm="24"
            :md="24"
            :lg="24"
            :xl="24"
            style="margin-bottom:20px;"
          >
            <el-checkbox
              v-model="user_details.data.target_section.by_production_minutes"
              @change="update_targets"
              label="Production minutes"
              style="background:white"
              >Production minutes</el-checkbox
            >
            <el-checkbox
              v-model="user_details.data.target_section.by_sales_minutes"
              @change="update_targets"
              label="Sales minutes"
              style="background:white"
              >Sales minutes</el-checkbox
            >
            <el-checkbox
              v-model="user_details.data.target_section.by_sales_amount"
              @change="update_targets"
              label="Sales amount"
              style="background:white"
              >Sales amount</el-checkbox
            >
          </el-col>

          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-col
              :xs="24"
              :sm="24"
              :md="4"
              :lg="4"
              :xl="4"
              class="smallbox"
              style="background:#f3f3f3"
            >
              <span class="semilables">Performance minutes total (% weekly)</span>
              <el-input
                v-model="user_details.data.target_section.performance_total"
                @change="update_targets"
              >
              </el-input>
            </el-col>

            <el-col class="smallbox" :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <span class="semilables">Green (Min)</span>
              <el-input
                v-model="user_details.data.target_section.performance_green"
                @change="update_targets"
              >
              </el-input>
            </el-col>

            <el-col class="smallbox" :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <span class="semilables">Yellow (Min)</span>
              <el-input
                v-model="user_details.data.target_section.performance_yellow"
                @change="update_targets"
              >
              </el-input>
            </el-col>

            <el-col class="smallbox" :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <span class="semilables">Orange (Min)</span>
              <el-input
                v-model="user_details.data.target_section.performance_orange"
                @change="update_targets"
              >
              </el-input>
            </el-col>
            <el-col class="smallbox" :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <el-checkbox
                v-model="user_details.data.target_section.performance_billable"
                @change="update_targets"
                label="1"
                style="background:white"
                >Billable only</el-checkbox>
            </el-col>
            <el-col
              class="smallbox"
              :xs="24"
              :sm="24"
              :md="4"
              :lg="4"
              :xl="4"
              style="background:#cfffe5"
            >
              <span class="semilables">Counts for overall weigh-in %</span>
              <el-input
                v-model="user_details.data.target_section.production_mintues_overall_perc"
                @change="update_targets"
              >
              </el-input>
            </el-col>
          </el-col>

          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-col
              :xs="24"
              :sm="24"
              :md="4"
              :lg="4"
              :xl="4"
              class="smallbox"
              style="background:#f3f3f3"
            >
              <span class="semilables">Sales minutes total (Weekly)</span>
              <el-input
                v-model="user_details.data.target_section.sales_minutes_total"
                @change="update_targets"
              >
              </el-input>
            </el-col>

            <el-col class="smallbox" :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <span class="semilables">Green (Min)</span>
              <el-input
                v-model="user_details.data.target_section.sales_minutes_green"
                @change="update_targets"
              >
              </el-input>
            </el-col>

            <el-col class="smallbox" :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <span class="semilables">Yellow (Min)</span>
              <el-input
                v-model="user_details.data.target_section.sales_minutes_yellow"
                @change="update_targets"
              >
              </el-input>
            </el-col>

            <el-col class="smallbox" :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <span class="semilables">Orange (Min)</span>
              <el-input
                v-model="user_details.data.target_section.sales_minutes_orange"
                @change="update_targets"
              >
              </el-input>
            </el-col>

            <el-col class="smallbox" :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <el-checkbox
                v-model="
                  user_details.data.target_section.sales_minutes_approved
                "
                @change="update_targets"
                label="1"
                style="background:white"
                >Approved Only</el-checkbox>
              <el-checkbox
                v-model="
                  user_details.data.target_section.sales_minutes_billable
                "
                @change="update_targets"
                label="1"
                style="background:white"
                >Billable Only</el-checkbox>
            </el-col>
            <el-col
              class="smallbox"
              :xs="24"
              :sm="24"
              :md="4"
              :lg="4"
              :xl="4"
              style="background:#cfffe5"
            >
              <span class="semilables">Counts for overall weigh-in %</span>
              <el-input
                v-model="
                  user_details.data.target_section.sales_mintues_overall_perc
                "
                @change="update_targets"
              >
              </el-input>
            </el-col>
          </el-col>

          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-col
              :xs="24"
              :sm="24"
              :md="4"
              :lg="4"
              :xl="4"
              class="smallbox"
              style="background:#f3f3f3"
            >
              <span class="semilables">Sales amount total (Weekly)</span>
              <el-input
                v-model="user_details.data.target_section.sales_amount_total"
                @change="update_targets"
              >
              </el-input>
            </el-col>

            <el-col class="smallbox" :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <span class="semilables">Green (%)</span>
              <el-input
                v-model="user_details.data.target_section.sales_amount_green"
                @change="update_targets"
                >$
              </el-input>
            </el-col>

            <el-col class="smallbox" :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <span class="semilables">Yellow (%)</span>
              <el-input
                v-model="user_details.data.target_section.sales_amount_yellow"
                @change="update_targets"
                >$
              </el-input>
            </el-col>

            <el-col class="smallbox" :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <span class="semilables">Orange (%)</span>
              <el-input
                v-model="user_details.data.target_section.sales_amount_orange"
                @change="update_targets"
                >$
              </el-input>
            </el-col>
            <el-col class="smallbox" :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <el-checkbox
                v-model="user_details.data.target_section.sales_amount_approved"
                @change="update_targets"
                label="1"
                style="background:white"
                >Approved Only</el-checkbox>
              <el-checkbox
                v-model="user_details.data.target_section.sales_amount_billable"
                @change="update_targets"
                label="1"
                style="background:white"
                >Billable Only</el-checkbox>
            </el-col>
            <el-col
              class="smallbox"
              :xs="24"
              :sm="24"
              :md="4"
              :lg="4"
              :xl="4"
              style="background:#cfffe5"
            >
              <span class="semilables">Counts for overall weigh-in %</span>
              <el-input
                v-model="user_details.data.target_section.sales_amount_overall_perc"
                @change="update_targets"
              >
              </el-input>
            </el-col>
          </el-col>
        </el-col>
      </el-row>

      <el-row style="margin-top:30px;">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <span class="smallheader">Turnaround </span>
          <p>Task calculated based on completing all tasks on their daily list on time.</p>
          <el-col
            :xs="24"
            :sm="24"
            :md="24"
            :lg="24"
            :xl="24"
            style="margin-bottom:20px;"
          >
            <el-checkbox
              v-model="user_details.data.target_section.by_email"
              @change="update_targets"
              label="Email"
              style="background:white"
              >Email response
            </el-checkbox>
            <el-checkbox
              v-model="user_details.data.target_section.by_task"
              @change="update_targets"
              label="Task Completed"
              style="background:white"
              >Task response</el-checkbox
            >
          </el-col>

          <!--- TURNAROUND FIELDS FOR TASK START ---->
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
         
            <el-col
              :xs="24"
              :sm="24"
              :md="4"
              :lg="4"
              :xl="4"
              class="smallbox"
              style="background:#f3f3f3"
            >
              <span class="semilables">Task Response Total Target (%)</span>
              <el-input
                v-model="user_details.data.target_section.turnaround_total"
                @change="update_targets"
              >
              </el-input>
            </el-col>

            <el-col class="smallbox" :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <span class="semilables">Green (%)</span>
              <el-input
                v-model="user_details.data.target_section.turnaround_green"
                @change="update_targets"
              >
              </el-input>
            </el-col>

            <el-col class="smallbox" :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <span class="semilables">Yellow (%)</span>
              <el-input
                v-model="user_details.data.target_section.turnaround_yellow"
                @change="update_targets"
              >
              </el-input>
            </el-col>

            <el-col class="smallbox" :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <span class="semilables">Orange (%)</span>
              <el-input
                v-model="user_details.data.target_section.turnaround_orange"
                @change="update_targets"
              >
              </el-input>
            </el-col>
            <el-col
              class="smallbox"
              :xs="24"
              :sm="24"
              :md="4"
              :lg="4"
              :xl="4"
              style="background:#cfffe5"
            >
              <span class="semilables">Counts for overall weigh-in %</span>
              <el-input
                v-model="
                  user_details.data.target_section.task_response_overall_perc
                "
                @change="update_targets"
              >
              </el-input>
            </el-col>
          </el-col>
          <!--- TURNAROUND FIELDS FOR TASK END --->

          <!--- TURNAROUND FIELDS FOR EMAIL START ---->
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
           
            <el-col
              :xs="24"
              :sm="24"
              :md="4"
              :lg="4"
              :xl="4"
              class="smallbox"
              style="background:#f3f3f3"
            >
              <span class="semilables">Emails Response Total Target (%)</span>
              <el-input
                v-model="user_details.data.target_section.turnaround_email_total"
                @change="update_targets"
              >
              </el-input>
            </el-col>

            <el-col class="smallbox" :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <span class="semilables">Green (%)</span>
              <el-input
                v-model="user_details.data.target_section.turnaround_email_green"
                @change="update_targets"
              >
              </el-input>
            </el-col>

            <el-col class="smallbox" :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <span class="semilables">Yellow (%)</span>
              <el-input
                v-model="user_details.data.target_section.turnaround_email_yellow"
                @change="update_targets"
              >
              </el-input>
            </el-col>

            <el-col class="smallbox" :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <span class="semilables">Orange (%)</span>
              <el-input
                v-model="user_details.data.target_section.turnaround_email_orange"
                @change="update_targets"
              >
              </el-input>
            </el-col>
            <el-col
              class="smallbox"
              :xs="24"
              :sm="24"
              :md="4"
              :lg="4"
              :xl="4"
              style="background:#cfffe5"
            >
              <span class="semilables">Counts for overall weigh-in %</span>
              <el-input
                v-model="user_details.data.target_section.email_response_overall_perc"
                @change="update_targets"
              >
              </el-input>
            </el-col>
          </el-col>
          <!--- TURNAROUND FIELDS FOR EMAIL END --->
        </el-col>
      </el-row>

      <el-row style="margin-top:30px;">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <span class="smallheader">Quailty Report (NCR) </span>
          <p>Calculated base on approved NCR received over the average of the
            total per week over selected time/date
          </p>
          <el-col
            :xs="24"
            :sm="24"
            :md="4"
            :lg="4"
            :xl="4"
            class="smallbox"
            style="background:#f3f3f3"
          >
            <span class="semilables"> Total (Weekly)</span>
            <el-input
              v-model="user_details.data.target_section.rfi_total"
              @change="update_targets"
            >
            </el-input>
          </el-col>

          <el-col class="smallbox" :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
            <span class="semilables">Red (%)</span>
            <el-input
              v-model="user_details.data.target_section.rfi_red"
              @change="update_targets"
            >
            </el-input>
          </el-col>

          <el-col class="smallbox" :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
            <span class="semilables">Orange (%)</span>
            <el-input
              v-model="user_details.data.target_section.rfi_orange"
              @change="update_targets"
            >
            </el-input>
          </el-col>

          <el-col class="smallbox" :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
            <span class="semilables">Yellow (%)</span>
            <el-input
              v-model="user_details.data.target_section.rfi_yellow"
              @change="update_targets"
            >
            </el-input>
          </el-col>
          <el-col
            class="smallbox"
            :xs="24"
            :sm="24"
            :md="4"
            :lg="4"
            :xl="4"
            style="background:#cfffe5"
          >
            <span class="semilables">Counts for overall weigh-in %</span>
            <el-input
              v-model="user_details.data.target_section.ncr_overall_perc"
              @change="update_targets"
            >
            </el-input>
          </el-col>
        </el-col>
      </el-row>
      <p style="font-weight:bold;">Current NCR/s assigned user</p>
      <div style="padding:20px; background:#f3f3f3;  ">
       
<!--- NCR TABLE NEW --->
  <el-table
    :data="ncrs"
    :default-sort = "{prop: 'created_at', order: 'descending'}"
    style="width: 100%">
     <el-table-column
      prop="job_id"
      label="Job Id"
      sortable
      width="100">
         <template slot-scope="scope">
         <a :href="'job_view?id=' + scope.row.job_id">{{ scope.row.job_id }}</a>
        </template>
    </el-table-column>
    <el-table-column
      prop="created_at"
      label="Date"
      sortable
      width="180">
    </el-table-column>
    <el-table-column
      prop="title"
      label="Tile"
      width="180">
    </el-table-column>
    <el-table-column
      prop="decription"
      label="Decription"
      width="300">
    </el-table-column>
     <el-table-column
      prop="approved"
      label="Approved"
       sortable
      width="110">
      <template slot-scope="scope">
          <i
            v-show="scope.row.approved"
            type="warning"
            style="color:orange; font-size:18px;"
            class="el-icon-star-on"
          >
          </i>
              <i
            v-show="!scope.row.approved"
            type="warning"
            style="color:grey; font-size:18px;"
            class="el-icon-star-on"
          >
          </i>
           
        </template>

    </el-table-column>
      <el-table-column
      prop="resolved_date"
      label="Resolved Date"
      width="140">
    </el-table-column>
       <el-table-column
      prop="user_reason"
      label="Reason"
      width="200">
    </el-table-column>
   <el-table-column fixed="right" width="100" label="Actions">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            content="Approve to Assigned User KPI"
            placement="left-start"
          >
            <el-button
              size="mini"
              type="info"
              v-show="!scope.row.approved"
              icon="el-icon-star-off"
              circle
              @click="update_approved(scope.$index, scope.row)"
            ></el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="UnApprove to Assigned User KPI"
            placement="left-start"
          >
            <el-button
              size="mini"
              type="info"
              v-show="scope.row.approved"
              icon="el-icon-star-off"
              circle
              @click="remove_approved(scope.$index, scope.row)"
            ></el-button>
          </el-tooltip>

          <el-tooltip
            class="item"
            effect="dark"
            content="Delete NCR"
            placement="left-start"
          >
            <el-button
              size="mini"
              type="info"
              icon=" el-icon-error"
              circle
              @click="remove_record(scope.$index, scope.row)"
            ></el-button
          ></el-tooltip>
        </template>
      </el-table-column>
  </el-table>
  <!--- NCR TABLE END --->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "UserKPITargets",
  props: {
    crm_user_id: {
      type: [String,Number],
      required: true
    }
  },
  data() {
    return {
      user_details: {
        data: {
          target_section: {}
        }
      },
      ncrs: [],
      value: "",
      prop_rfi: [
        {
          prop_rfi_id: 1,
          prop_rfi_date: "2010-03-11 7:00am",
          prop_rfi_reason:
            "Item was printed incorrectly, didnt check notes, Item was printed incorrectly, didnt check notes,Item was printed incorrectly, didnt check notes,Item was printed incorrectly, didnt check notes,",
          prop_proposal_job_number: "2345",
          prop_rfi_reporting_user: "Matt",
          prop_rfi_user: "Leyita",
          prop_rfi_approved: true,
        },
        {
          prop_rfi_id: 2,
          prop_rfi_date: "2010-03-11 7:00am",
          prop_rfi_reason: "Item was printed incorrectly, didnt check notes",
          prop_proposal_job_number: "2345",
          prop_rfi_reporting_user: "Matt",
          prop_rfi_user: "Michaela",
          prop_rfi_approved: false,
        },
      ],
    };
  },
  watch: {
    crm_user_id(){
      this.fill_data();
    }
  },
  mounted() {
    this.fill_data();
  },
  methods: {
    fill_data() {
      this.$http.get(`/user_targets_data/${this.crm_user_id}`).then((response) => {
        this.user_details = response.data;
        this.load_user_ncrs();
      });
    },
    load_user_ncrs() {
      this.$http.post("/ncr_by_user", { user_id: this.crm_user_id }).then((response) => {
        this.ncrs = response.data;
      });
    },
     update_approved(index, row) {
      let params = {
        non_conformance_reports: row,
      };
      row.approved = true;

      this.$http
        .patch(`/non_conformance_reports/${row.id}`, params)
        .then((response) => {
          this.clear_inputs();
          this.$message({
            message: "NCR has been Updated",
            type: "success",
          });
          this.fill_data();
          this.dialogResolve = false;
        });
    },
    remove_approved(index, row) {
      let params = {
        non_conformance_reports: row,
      };
      row.approved = false;

      this.$http
        .patch(`/non_conformance_reports/${row.id}`, params)
        .then((response) => {
          this.clear_inputs();
          this.$message({
            message: "NCR has been Updated",
            type: "success",
          });
          this.fill_data();
          this.dialogResolve = false;
        });
    },
    remove_record(index, row) {
      this.$http.delete(`/non_conformance_reports/${row.id}`);
      this.$confirm(
        "This will permanently delete this record.Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then((response) => {
        this.$message({
          message: "NCR has been deleted",
          type: "success",
        });
        debugger
        this.$delete(this.ncrs, index);
      });
    },
    update_targets() {
      let params = { target_section: this.user_details.data.target_section };
      this.$http.patch("/update_target_section/" + this.crm_user_id, params).then(
        (response) => {
          this.$message({
            message: "Updated successfully.",
            type: "success",
          });
        },
        function(response) {
          this.$message({
            message: "Something went wrong, your change was not saved.",
            type: "error",
          });
        }
      );
    },
  },
};
</script>
<style>
span.smallheader {
  font-size: 15px;
  font-weight: bold;
  width: 100%;
  float: left;
  margin-bottom: 13px;
}
span.semilables {
  font-size: 11px;
  font-weight: bold;
  color: #333;
  width: 100%;
  float: left;
}
.smallbox {
  border: 1px solid #f3f3f3;
  padding: 2px;
}
.smallbox input {
  margin-bottom: 0px !important;
  height: 32px !important;
}
.selectfield input {
  margin-bottom: 0px !important;
  height: 1.5em !important;
  border-bottom: 0px !important;
}
</style>
