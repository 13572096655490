import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

function today() {
  let today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1;
  let yyyy = today.getFullYear();

  return `${yyyy}-${mm}-${dd}`;
}

function sale_init(sale) {
  sale.first_invoice = today();
  sale.invoice_description = sale.invoice_description  || '';
  sale.additional_desc =  sale.additional_desc || '';
}

export const PARENT_SALE_KEY = 'ParentSale';

export const sale_store = new Vuex.Store({
  state: {
    current_sale: PARENT_SALE_KEY,
    sales: [{
      key: PARENT_SALE_KEY,
      sale: {
        id: null,
        send_invoice: false,
        approved: false,
        sales_approved_at: null,

        category_task_id: 0,
        day_due_date: 0,
        first_invoice: null,
        contract_term: 0,
        estimated_contract: 0,
        client_order_no: '',
        payment_type: '',
        job_no: '',
        total_contract: 0,
        discount_amount: 0,
        invoiceable: true,

        invoice_description: '',
        additional_desc: '',
        edit_invoice_description: false,
        show_pdf_breakdown: false,
        show_subtotal: false,
        show_unit_sale_price: false,
        show_qty: false,
        group_items: false,

        invoice_email: '',
        invoice_based_wf: false,
        invoice_ids:[]
      }
    }],
    scheduled_item: {
      is_active: false,
      schedule_type_id: null,
      repeat_every: null,
      end_at: null
    },

    total_sale_price: 0,
    total_quote_price: 0,
    total_cost: 0,
    stock_cost: 0,
    labour_cost: 0,
    gross_profit: 0,
  },
  getters: {
    get_sales: (state) => {
      return state.sales;
    },
    get_parent_sale: (state) => {
      return state.sales.find(o => o.key === PARENT_SALE_KEY).sale;
    },
    get_wf_sales: (state) => {
      return state.sales.filter(o => o.key != PARENT_SALE_KEY);
    },
    get_sale: (state) => {
      return state.sales.find(o => o.key === state.current_sale).sale;
    },
    get_sale_with_key: (state) => (key) => {
      return state.sales.find(o => o.key === key).sale;
    },
    get_current_sale_key: (state) => {
      return state.current_sale;
    },
    get_scheduled_item: (state) => {
      return state.scheduled_item;
    },
    get_total_sale_price: (state) => {
      return state.total_sale_price;
    },
    get_total_quote_price: (state) => {
      return state.total_quote_price;
    },
    get_total_cost: (state) => {
      return state.total_cost;
    },
    get_stock_cost: (state) => {
      return state.stock_cost;
    },
    get_labour_cost: (state) => {
      return state.labour_cost;
    },
    get_gross_profit: (state) => {
      return state.gross_profit;
    }
  },
  mutations: {
    SET_PARENT_SALE(state, payload) {
      sale_init(payload);
      state.sales.find(o => o.key === PARENT_SALE_KEY).sale = payload;
    },
    SET_CURRENT_SALE_KEY(state, payload) {
      state.current_sale = payload;
    },
    ADD_SALE(state, payload) {
      sale_init(payload.sale);
      state.sales.push(payload);
    },
    SET_SCHEDULED_ITEM(state, payload) {
      state.scheduled_item = payload;
    },
    SET_TOTAL_SALE_PRICE(state, payload) {
      state.total_sale_price = payload;
    },
    SET_TOTAL_QUOTE_PRICE(state, payload) {
      state.total_quote_price = payload;
    },
    SET_TOTAL_COST(state, payload) {
      state.total_cost = payload;
    },
    SET_STOCK_COST(state, payload) {
      state.stock_cost = payload;
    },
    SET_LABOUR_COST(state, payload) {
      state.labour_cost = payload;
    },
    SET_GROSS_PROFIT(state, payload) {
      state.gross_profit = payload;
    }
  },
  actions: {
    set_parent_sale(context, payload) {
      context.commit('SET_PARENT_SALE', payload);
    },
    set_current_sale_key(context, payload) {
      context.commit('SET_CURRENT_SALE_KEY', payload);
    },
    add_sale(context, payload) {
      context.commit('ADD_SALE', payload);
    },
    set_scheduled_item(context, payload) {
      context.commit('SET_SCHEDULED_ITEM', payload);
    },
    set_total_sale_price(context, payload) {
      context.commit('SET_TOTAL_SALE_PRICE', payload);
    },
    set_total_quote_price(context, payload) {
      context.commit('SET_TOTAL_QUOTE_PRICE', payload);
    },
    set_total_cost(context, payload) {
      context.commit('SET_TOTAL_COST', payload);
    },
    set_stock_cost(context, payload) {
      context.commit('SET_STOCK_COST', payload);
    },
    set_labour_cost(context, payload) {
      context.commit('SET_LABOUR_COST', payload);
    },
    set_gross_profit(context, payload) {
      context.commit('SET_GROSS_PROFIT', payload);
    }
  }
});