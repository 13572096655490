<template>
  <div style="float:left; width:100%; padding:20px; background:#f3f3f3">
    <ul
      v-for="holdpoint in holdpoint_tasks"
      v-show="!proposal.completed"
      :key="holdpoint.id"
    >
      <li class="fontStyleP">
        <i
          class="el-icon-remove"
          style="color:red"
        /> <b>  
          {{ holdpoint.job_name }} ( {{ holdpoint.list_name }}) </b> <br><i> {{ holdpoint.alternative_description }}</i>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { editor_store } from 'store/modules/editor';
import { JobProposalMixin } from 'mixins/JobProposalMixin.js';
import { ProposalMixin } from 'mixins/ProposalMixin.js';

export default {
  name: 'JobProgress.vue',
  store: editor_store,
  components: {},
  mixins: [JobProposalMixin, ProposalMixin],
  props: ['holdpoint_tasks'],
  data() {
    return {
       
  
    };
  },
  mounted() {},
  computed: {
    ...mapGetters(['proposal', 'proposal_id', 'job_proposals_list']),
    job_proposals_list_grouped() {
      return this.groupBy(this.job_proposals_list, 'list_name');
    }
  },

  methods: {
    ...mapActions(['set_stock_items_list', 'set_job_proposals_list'])
  },
  watch: {}
};
</script>
<style>
 
 
</style>
