<template>
  <div id="tax-rates">
    <el-row>
      <el-col :span="12">
        <p>Tax Rates</p>
      </el-col>
      <el-col :span="12">
        <el-button
          size="mini"
          class="right"
          type="danger"
          plain
          style="margin-left: 10px;"
          @click="delete_last_time_frame()"
        >
          Delete latest time frame
        </el-button>
        <el-button
          size="mini"
          class="right"
          type="primary"
          plain
          @click="show_dialog = true"
        >
          New Tax Rates
        </el-button>
      </el-col>
    </el-row>
    <el-collapse v-model="active_names">
      <el-collapse-item
        v-for="(time,index) in tax_rates"
        :key="time.id"
        :title="time.start + ' - ' + (time.ends || 'open')"
        :name="index"
      >
        <el-table
          :data="time.tax_rates"
        >
          <el-table-column
            prop="from_amount"
            label="From amount"
          >
            <template #default="scope">
              <money :amount="scope.row.from_amount" />
            </template>
          </el-table-column>
          <el-table-column
            prop="to_amount"
            label="To amount"
          >
            <template #default="scope">
              <money :amount="scope.row.to_amount" />
            </template>
          </el-table-column>
          <el-table-column
            prop="rate"
            label="Rate %"
          />
        </el-table>
      </el-collapse-item>
    </el-collapse>
    <el-dialog
      title="New Tax Rates"
      :visible.sync="show_dialog"
      width="30%"
      @opened="set_default_input()"
    >
      <el-form
        ref="tax_rate"
        :model="tax_rate"
        label-width="140px"
        :rules="rules"
      >
        <el-form-item
          label="Start tax period"
          prop="start"
          required
        >
          <el-date-picker
            v-model="tax_rate.start"
            placeholder="Start tax period"
            format="dd.MM.yyyy"
            size="small"
            type="datetime"
          />
        </el-form-item>
        <el-form-item
          label="From amount"
          required
          prop="from_amount"
        >
          <el-input
            v-model="tax_rate.from_amount"
            placeholder="From amount"
            size="small"
          />
        </el-form-item>
        <el-form-item
          label="To amount"
          prop="to_amount"
          required
        >
          <el-input
            v-model="tax_rate.to_amount"
            placeholder="To amount"
            size="small"
          />
        </el-form-item>
        <el-form-item
          label="Rate"
          prop="rate"
          required
        >
          <el-input-number
            v-model="tax_rate.rate"
            size="small"
            :precision="2"
            :step="0.5"
            :max="100"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            size="small"
            type="primary"
            @click="create_tax_rates"
          >
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {timeframe_tax_rates, create_tax_rates, delete_timeframe_tax_rates} from '../../api/tax_rates';
import Money from '../shared/Money';
export default {
  name: 'TaxRates',
  components: {Money},
  data() {
    return {
      tax_rates: [{id: '', start: '', ends: ''}],
      tax_rate: { start: '', from_amount: '', to_amount: '', rate: 10 },
      show_dialog: false,
      timeframe: [],
      rules: {
        start: [{ required: true, message: ' ' }],
        from_amount: [{ required: true, message: ' ', trigger: 'blur' }],
        to_amount: [{ required: true, message: ' ', trigger: 'blur' }],
        rate: [{ required: true, message: ' ', trigger: 'blur' }]
      },
      active_names: [0]
    };
  },
  created() {
    this.timeframe_tax_rates();
  },
  methods: {
    timeframe_tax_rates(){
      timeframe_tax_rates(this).then((response)=> {
        this.tax_rates = response.data;
      });
    },
    create_tax_rates(){
      this.$refs['tax_rate'].validate((valid) => {
        if (valid) {
          create_tax_rates(this, this.tax_rate).then((response) => {
            this.$message({
              type: 'success',
              message: response.data.message
            });
            this.timeframe_tax_rates();
          });
        }
      });
    },
    delete_last_time_frame(){
      delete_timeframe_tax_rates(this).then((response)=> {
        this.$message({
          type: 'success',
          message: response.data.message
        });
        this.timeframe_tax_rates();
      });
    },
    set_default_input() {
      Array.from(document.querySelectorAll('.el-select__input, .el-input__inner')).forEach(
        function (e) {
          e.classList.add('browser-default');
          if (e.querySelector('input') != null)
            e.querySelector('input').className += ' browser-default';
        }
      );
    },
  },
};
</script>

<style scoped>
#tax-rates /deep/ .el-dialog {
  width: 550px !important;
}
#tax-rates /deep/ .el-input{
  width: inherit;
}
</style>