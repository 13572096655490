<template>
  <div>
    <el-row>
      <el-col>
        <span class="timer_style">Plan will expire in {{ suggested_plan_timer }}</span>
      </el-col>
    </el-row>
    <el-row>
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="11"
      >
        <b>CURRENT PLAN</b>
    
        <el-tag
          type="warning"
          effect="dark"
          class="right"
          size="mini"
        >
          {{ proposal.dated }}
        </el-tag>

        <hr>
        <ul>
          <li
            v-for="list in project_plan_lists"
            :key="list.id"
          >
            <h3>
              <strong>{{ list.name }}</strong>
            </h3>
            <ul class="plan_list">
              <li
                v-for="task in list.tasks.filter(
                  (c) => c.completed == false && c.mark_as_deleted == false
                )"
                :key="task.id"
              >
                <span style="font-weight: 400;">{{ display_task_name(task) }}: </span>
                <i>{{ task.time_frame_start }} to {{ task.time_frame_end }}</i>
                <el-tag
                  v-for="user in task.crm_users"
                  :key="user.id"
                  size="mini"
                >
                  {{ user.user_name }}
                </el-tag>
              </li>
            </ul>
          </li>
        </ul>
      </el-col>
      <el-col
        :xs="1"
        :sm="1"
        :md="1"
        :lg="1"
      >
        <p>
          <br>
        </p>
      </el-col>
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="11"
      >
        <b>NEW PLAN</b>
        <!-- <b>Deadline:</b> <i class="right">{{ this.suggested_deadline }}</i> -->
    
        <el-tag
          type="warning"
          effect="dark"
          class="right"
          size="mini"
        >
          {{ suggested_deadline }}
        </el-tag>
        <hr>
    
        <ul>
          <li
            v-for="list in suggested_tasks"
            :key="list.id"
          >
            <h3>
              <strong>{{ list.name }}</strong>
            </h3>
            <ul class="plan_list">
              <li
                v-for="task in list.tasks"
                :key="task.id"
              >
                <el-tooltip
                  v-show="task.follow_status == false"
                  content="Current task timing cannot follow previous task timing."
                >
                  <i
                    class="el-icon-warning"
                    style="color: orange; font-size: large;"
                  />
                </el-tooltip>
                <el-tooltip
                  v-show="task.follow_previous == true"
                  content="Follow previous task timing"
                >
                  <i
                    class="el-icon-s-unfold"
                    circle
                  />
                </el-tooltip>
                <span style="font-weight: 400;">{{ task.name }}: </span>
                <i>{{ task.start_time }} to {{ task.end_time }}</i>
                <el-tag
                  v-for="user in task.jp_users"
                  :key="user.id"
                  size="mini"
                >
                  {{ user.user_name }}
                </el-tag>
    
    
                <el-button
                  v-show="show_suggested_users == true"
                  type="primary"
                  icon="el-icon-user-solid"
                  size="mini"
                  circle
                  @click="show_suggested_users_dialog(task)"
                />
              </li>
            </ul>
          </li>
        </ul>
      </el-col>
    </el-row>
    
    <el-dialog
      title="Users"
      :visible.sync="users_dialog"
      width="30%"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      append-to-body
    >
      <b>CURRENT USERS</b>
      <hr>
      <el-tag
        v-for="user in current_item.jp_users"
        :key="user.id"
        size="mini"
      >
        {{ user.user_name }}
      </el-tag>
      <br>
      <br>
      <br>
      <b>SUGGESTED USERS</b>
      <el-button
        type="primary"
        class="right"
        size="mini"
        @click="add_user"
      >
        Add user
      </el-button>
      <el-select
        v-model="selected_crm_user"
        class="right inputdiv right"
        placeholder="Add users"
        size="mini"
      >
        <el-option
          v-for="mainuser in crm_users_list"
          :key="mainuser.id"
          :label="mainuser.user_name"
          :value="mainuser.id"
        />
      </el-select>
    
      <br>
      <draggable
        v-model="current_item.suggested_users"
        tag="tbody"
        class="list-group"
        group="userslist"
        handle=".user_tag"
        v-bind="dragOptions"
      >
        <el-tag
          v-for="user in current_item.suggested_users"
          :key="user.id"
          size="mini"
          class="user_tag"
          closable
          style="margin: 3px; display: table;"
          @close="handleClose(user)"
        >
          {{ user.user_name }}
        </el-tag>
      </draggable>
    
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="users_dialog = false; update_task_user(current_item)">Ok</el-button>
        <!-- <el-button type="primary" @click="users_dialog = false"
                            >Confirm</el-button
                        > -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { readFileSync } from 'fs';
import draggable from 'vuedraggable';
export default {
  components: { draggable },
  props: [
    'proposal',
    'project_plan_lists',
    'suggested_tasks',
    'suggested_deadline',
    'show_suggested_users'
  ],
  data() {
    return {
      suggested_plan_timer_ms: '',
      current_item: {},
      users_dialog: false,
      selected_crm_user: '',
      crm_users_list: []
    };
  },
  computed: {
    suggested_plan_timer() {
      let dt = new Date(this.suggested_plan_timer_ms);
      return (
        dt
          .getMinutes()
          .toString()
          .padStart(2, '0') +
                ':' +
                dt
                  .getSeconds()
                  .toString()
                  .padStart(2, '0')
      );
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },
  watch: {
    suggested_deadline: function(newVal) {

      if (newVal == '' || newVal == undefined) this.clear_timer();
      else this.initiate_timer();
    },
  },
  mounted() {
    this.fill_users();
  },
  methods: {
    initiate_timer() {
      if (this.timer_id != undefined) this.clear_timer();
      this.suggested_plan_timer_ms = new Date().setMinutes(5, 0, 0);
      this.timer_id = setInterval(this.update_timer, 1000);
    },
    clear_timer() {
      clearInterval(this.timer_id);
    },
    set_inputs_to_default(mills = 300) {
      setTimeout(function() {
        Array.from(
          document.querySelectorAll('.project_planner_view .el-input')
        ).forEach(function(e, i) {
          e.classList.add('browser-default');
          if (e.querySelector('input') != null)
            e.querySelector('input').className += ' browser-default';
        });
      }, mills);
    },
    update_task_user() {
      this.$emit('update_tasks_list', this.current_item);
    },
    add_user() {
      if (
        (this.current_item.suggested_users || []).map((c) => c.id).indexOf(this.selected_crm_user) >= 0
      ) {
        return;
      }

      let user = this.crm_users_list.filter(
        (c) => c.id == this.selected_crm_user
      )[0];

      if (user != undefined) {
        if (this.current_item.suggested_users == undefined)
          this.current_item.suggested_users = [];
        this.current_item.suggested_users.push(user);
        this.set_default_input();
      }
    },
    fill_users() {
      this.$http.get('/users_active_list').then((response) => {
        this.crm_users_list = response.data;
      });
    },
    set_default_input(mills = 300) {
      setTimeout(function() {
        Array.from(
          document.querySelectorAll('.project_planner_view .el-input')
        ).forEach(function(e, i) {
          e.classList.add('browser-default');
          if (e.querySelector('input') != null)
            e.querySelector('input').className += ' browser-default';
        });
      }, mills);
    },
    show_suggested_users_dialog(item) {
      this.users_dialog = true;
      this.current_item = item;
      this.set_default_input();
    },
    update_timer() {
      this.suggested_plan_timer_ms -= 1000;
      let dt = new Date(this.suggested_plan_timer_ms);
      if (dt.getMinutes() == 0 && dt.getSeconds() <= 2) {
        this.$emit('timer_expired');
        this.clear_timer();
      }
    },
    display_task_name(task) {
      return task.layer_name ? task.layer_name : task.job_name;
    }
  },
};
</script>
