<template>
  <div id="proposal_edit">
    <h2 style="color: gray" v-show="!editor_fullscreen">
      <i
        v-show="proposal.is_template == 1"
        class="el-icon-news"
      />
      <b>PROPOSAL #{{ proposal.id }}</b>
      <span
        v-show="working_file_id != '' && working_file_info != {}"
        style="margin-left: 20px;"
      >
        [
        <b>{{ working_file_info.name }}</b>
        {{ working_file_info.total_sale }}
        <small
          v-show="working_file_info.matched_quote > 0"
          :class="{ crossed_text: proposal.matched_quote > 0 }"
        ><i>( NZD${{ working_file_info.matched_quote }} Matched quote )</i></small>]
      </span>
      <small
        v-show="proposal_summary.is_matched_quote == true"
        style=" float: right; "
      >&nbsp;&nbsp;[ Matched quote: ${{
        proposal_summary.total_matched_quote
      }}]
      </small>
      <strong style="font-size: 20px; float: right">${{ total_sale }}</strong>

      <el-button
          v-show="proposal.approved == 0 && client_view == true"
          plain
          type="danger"
          class="right"
          size="mini"
          style="margin: 0px 4px; padding-bottom: 5px"
          @click="discard_proposal"
      >
        Discard
      </el-button>

      <stock-item-price-changed :proposal_id="proposal.id" v-if="!proposal.approved"></stock-item-price-changed>

      <el-button
        v-show="proposal.approved == 0 && proposal.is_template != 1"
        plain
        type="primary"
        class="right"
        size="mini"
        style="margin: 0px 4px; padding-bottom: 5px"
        @click="approve_proposal"
      >
        Approve
      </el-button>
      <span
        v-show="proposal.approved == 1 || is_workspace_proposal"
        style="float: right;text-shadow: 2px 2px 0px lightblue; margin: 0px  10px"
      >
        <a
          :href="
            `/jobs_tasks_for_production/${proposal.account_id}/${proposal.id}/0`
          "
        >Job #{{ proposal.id }}</a>
      </span>
      <span v-if="client_view==false">
        <el-button
          v-show="proposal.approved == 1 && proposal.completed == 0"
          style="margin: 0px 10px"
          size="mini"
          class="right"
          type="primary"
          @click="convert_to_workspace"
        >
          Convert Job to workspace
        </el-button>
      </span>
      <span
        v-show="proposal.approved == 0"
        style="float: right;text-shadow: 2px 2px 0px lightblue;"
      />
      <hr>
    </h2>

    <el-tabs
      v-if="client_view==true"
      v-model="activeName"
      type="border-card"
      @tab-click="set_inputs_to_default"
    >
      <div v-show="false">
        <ProposalDetails ref="details_section" />
        <TasksCalendar
          v-if="proposal.approved == 0"
          :lazy="true" />
      </div>

      <el-tab-pane
          label="Editor"
          name="editor"
          :lazy="true"
      >
        <Workspace :client_view="client_view"/>
      </el-tab-pane>
    </el-tabs>
    <el-tabs
      v-else
      v-model="activeName"
      type="border-card"
      @tab-click="onTabClick"
    >
      <el-tab-pane
        label="Details"
        name="details"
      >
        <ProposalDetails ref="details_section" />
      </el-tab-pane>

      <el-tab-pane
        label="Mails"
        name="mail"
        :lazy="true"
      >
        <el-row>
          <el-col :span="24">
            <el-button
              class="right "
              size="small"
              type="primary"
              @click="send_to_client"
            >
              Send proposal to client
            </el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <FilteredEmails
              :filtered_msg_id="proposal.message_references"
              :mailbox_account="proposal.mailbox_account_id"
              :branch_id="proposal.branch_id"
              :proposal_id="proposal.id"
            />
          </el-col>
        </el-row>
      </el-tab-pane>

      <el-tab-pane
        label="Editor"
        name="editor"
        :lazy="true"
      >
        <Workspace/>
      </el-tab-pane>

      <el-tab-pane
        label="Delivery"
        name="Delivery"
        :lazy="true"
      >
        <Delivery />
      </el-tab-pane>
      <el-tab-pane
        label="Tender Requests"
        name="tender_requests"
        :lazy="true"
      >
        <TenderRequests />
      </el-tab-pane>
      <el-tab-pane
        label="Edit Summary"
        name="edit summary"
        :lazy="true"
      >
        <ProposalSummaryEdit
          name="proposal_items_edit"
          :lazy="true"
        />
      </el-tab-pane>
      <el-tab-pane
        label="Summary"
        name="summary"
        :lazy="true"
      >
        <ProposalSummary />
      </el-tab-pane>
      <el-tab-pane
        v-if="proposal.approved == 0"
        label="Calendar"
        name="Calendar"
        :lazy="true"
      >
        <TasksCalendar :lazy="true" />
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      title="Task completion"
      :visible.sync="workspace_task_dialog"
      :close-on-click-modal="false"
      :destroy-on-close="true"
    >
      <span>Please select task to be completed or add a new task:</span>
      <el-row>
        <el-col :span="18">
          <el-radio-group v-model="selected_workspace_task">
            <el-radio
              v-for="job in pending_initiated_tasks"
              :key="job.id"
              style="display: block;"
              :label="job.id"
            >
              {{ job.job_name }}
            </el-radio>
          </el-radio-group>
        </el-col>
        <el-col :span="6">
          <el-input
            v-model="workspace_task_time_spent"
            type="numbers"
            size="small"
          >
            <template slot="append">
              min.
            </template>
          </el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <NewTask :is_initiated="true" />
        </el-col>
      </el-row>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="small"
          @click="workspace_task_dialog = false"
        >Cancel</el-button>
        <el-button
          type="primary"
          size="small"
          @click="complete_workspace_task"
        >Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { editor_store } from 'store/modules/editor';
import { request } from 'http';
import { parse } from 'path';
import {EventBus} from '../../utilities/EventBus';
import { loadIsHours } from '../../api/organization';
import { loadCategoryWorkingFiles } from '../../api/category_working_files';

// Components
import Workspace from './../editor/Workspace';
import NewTask from './../job_proposals/NewTask';
import ProposalDetails from 'components/proposal/ProposalDetails';
import WorkingFilesList from 'components/proposal/working_files/WorkingFilesList.vue';
import StockItemsList from './../stock_items/StockItemsList';
import JobProposalsList from './../job_proposals/JobProposalsList';
import ChargePOsList from './../purchase_orders/ChargePOsList';
import FilteredEmails from 'components/mailbox/FilteredEmails.vue';
import TenderRequests from './TenderRequests';
import ProposalSummary from './summary/ProposalSummary';
import ProposalSummaryEdit from './summary/ProposalSummaryEdit';
import Delivery from './Delivery';
import TasksCalendar from './TasksCalendar';
import StockItemPriceChanged from  '../proposal/proposal_stock_items/StockItemPrice'

// Mixins
import { JobProposalMixin } from 'mixins/JobProposalMixin.js';
import { ProposalMixin } from 'mixins/ProposalMixin.js';
import {Request} from "../../utilities/Request";
import {defaultInput} from "../../utilities/DefaultInput";
import {ProposalStockItemMixin} from "../../mixins/ProposalStockItemMixin";

export default {
  name: 'Edit',
  store: editor_store,
  components: {
    Workspace,
    ProposalDetails,
    StockItemsList,
    WorkingFilesList,
    JobProposalsList,
    TenderRequests,
    ProposalSummary,
    ProposalSummaryEdit,
    ChargePOsList,
    TasksCalendar,
    Delivery,
    FilteredEmails,
    NewTask,
    StockItemPriceChanged
  },
  mixins: [ProposalMixin, ProposalStockItemMixin,JobProposalMixin],
  props: {
    client_view: {
      type: Boolean,
      required: true
    },
    discount: {
      type: Number,
      required: true
    },
    proposal_id: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      workspace_task_dialog: false,
      selected_workspace_task: '',
      workspace_task_time_spent: 0,
      activeName: this.client_view == false ?'details' : 'editor',
      categories: []
    };
  },

  methods: {
    ...mapActions([
      'set_job_proposals_list',
      'set_client_view',
      'set_discount',
      'set_is_hours',
      'set_working_file_categories',
      'set_proposal',
      'load_files_list',
      'load_charge_pos_list',
      "set_stock_items_list",
      "set_job_proposals_list",
      'set_working_file_id',
      'set_proposal_id',
      'set_active_working_file_category'
    ]),
    onTabClick() {
      if(this.activeName === 'editor' || this.activeName === 'summary' || this.activeName === 'edit summary') {
        EventBus.$emit('reloadCategoryWorkingFiles');
      } else if (this.activeName === 'details') {
        this.$http.get('/proposals/' + this.proposal_id).then((response)=> { this.set_proposal(response.body); });
      }

      this.set_inputs_to_default();
    },
     load_category_working_file() {
      return loadCategoryWorkingFiles(this, this.proposal_id).then(({data})=>{
        this.set_working_file_categories(data);
      });
    },
    convert_to_workspace() {
      let _this = this;
      this.$confirm(
        'Are you sure you want to convert current job to Workspace proposal ?',
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      )
        .then(() => {
          this.convert_job_to_workspace(this.proposal.id).then(
            (response) => {
              _this.$message({
                type: 'success',
                message: response.data.message,
              });
              window.location.reload();
            },
            (reason) => {
              _this.$message({
                type: 'warning',
                message: reason.data.message,
              });
            }
          );
        })
        .catch(() => {});
    },

    discard_proposal() {
      Request.delete(
          this,
          `/api/proposals/${this.proposal.id}`,
          (response) => {
            this.$message({
              message: response.body.message,
              type: 'success'
            });
            setTimeout(() => {
              window.location = `/client/login#/dashboard`;
            }, 500);
          }
      );
    },
    approve_proposal() {
      this.$refs['details_section'].approve_proposal(true);
    },
    complete_workspace_task() {
      if (
        this.selected_workspace_task == '' ||
        this.workspace_task_time_spent == 0
      ) {
        this.$message({
          type: 'warning',
          message:
            'Make sure you have selected a task from the list and enter the time spent!',
        });
        return;
      }

      this.complete_update_task(
        this.selected_workspace_task,
        this.workspace_task_time_spent
      ).then(
        (response) => {
          this.$message({
            type: 'success',
            message: 'Saved successfully.',
          });
          this.workspace_task_dialog = false;

          // reload job proposals list after update
          this.load_job_proposals(this.proposal.id).then((response) => {
            this.set_job_proposals_list(response);

            // update quote to fixed quote
            // if all is_initiated tasks have been completed
            if (
              this.job_proposals_list.filter(
                (c) => c.is_initiated == true && c.completed == 0
              ).length == 0
            ) {
              let fixed_quote = this.quote_types_list.filter(
                (c) => c.name == 'fixed quote'
              )[0];
              if (fixed_quote != undefined) {
                this.proposal.quote_type_id = fixed_quote.id;
                // trigger saving proposal
                document.getElementById('proposal_save_btn').click();
              }
            }
          });
        },
        (reason) => {
          this.$message({
            type: 'error',
            message: 'Error happened while updating the task.',
          });
          console.log(reason);
        }
      );
    },
    set_inputs_to_default() {
      let _this = this;
      setTimeout(() => {
        Array.from(
          document.querySelectorAll(
            '#proposal_edit .el-input :not(.browser-default'
          )
        ).forEach(function(e, i) {
          e.classList.add('browser-default');
          if (e.querySelector('input') != null)
            e.querySelector('input').className += ' browser-default';
        });
      }, 500);
    },
    send_to_client() {
      let message_references = this.proposal.message_references.split(',');
      if (message_references.length > 0) {
        let message_id = encodeURI(message_references[0]);
        let url = `/mailbox#/compose/draft?message_id=${message_id}&action=reply&attach_proposal=${this.proposal.id}`;
        window.open(url, 'Reply to client', 'width=700,height=700');
        window.addEventListener('message', this.handleMessageReceived);
      } else {
        let url = `/mailbox#/compose?attach_proposal=${this.proposal.id}`;
        window.open(url, 'Send proposal to client', 'width=700,height=700');
        window.addEventListener('message', this.handleMessageReceived);
      }
    },
    handleMessageReceived(e) {
      this.workspace_task_dialog = true;
      window.removeEventListener('message', this.handleMessageReceived);
    },
    load_stocks_list() {
      this.load_stock_items_list(this.proposal_id).then(response => {
        this.set_stock_items_list(response);
      });
    },
    load_jps_list() {
      this.load_job_proposals(this.proposal_id).then(response => {
        this.set_job_proposals_list(response);
      });
    }
  },
  created() {
    loadIsHours(this).then(({data}) => { this.set_is_hours(data.is_hours);});
    this.set_proposal_id(this.proposal_id);
    this.set_client_view(this.client_view);
    this.set_discount(this.discount);
    this.load_category_working_file();

    EventBus.$on('reloadCategoryWorkingFiles',async (active_object)=>{
     let active_working_file_id = null;
     if(active_object && active_object.active_working_file_id) active_working_file_id = active_object.active_working_file_id;
     this.load_files_list(active_working_file_id);

     await this.load_category_working_file();
     if(active_object) {
       if(active_object.active_category_id) this.set_active_working_file_category(active_object.active_category_id);
     }
    });

    EventBus.$on('reloadItems',async (active_object)=>{
      this.$http.get('/proposals/' + this.proposal_id).then((response)=> { this.set_proposal(response.body); });
      this.load_stocks_list();
      this.load_jps_list();
      this.load_charge_pos_list();
    });
  },
  computed: {
    ...mapGetters([
      'proposal',
      'proposal_summary',
      'total_sale',
      'working_file_id',
      'files_list',
      'stock_items_list',
      'job_proposals_list',
      'quote_types_list',
      'is_workspace_proposal',
      'editor_fullscreen'
    ]),
    pending_initiated_tasks() {
      return this.job_proposals_list.filter(
        (c) => c.is_initiated == true && c.completed == false
      );
    },
    is_workspace() {},
    working_file_info: function() {
      if (this.working_file_id != '' && this.files_list.length > 0) {
        let file_info = this.files_list.filter(
          (c) => c.id == this.working_file_id
        )[0];
        let matched_quote = 0;
        let total = 0;
        let is_matched_quote = false;

        let tasks_sale = this.job_proposals_list
          .filter((c) => c.working_file_id == this.working_file_id)
          .reduce(
            (sum, c) =>
              sum + parseFloat(c.sale_price * c.time_allocated_labour),
            0
          );

        let stocks_sale = this.stock_items_list
          .filter((c) => c.working_file_id == this.working_file_id)
          .reduce(
            (sum, stock) => sum + parseFloat(stock.quantity * stock.sale_price),
            0
          );
        total = tasks_sale + stocks_sale;
        let dec = (total.toString().split('.')[1] || '0').length;
        if (dec >= 3)
          total = total
            .toFixed(3)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return {
          name: file_info.name,
          total_sale: `$${total}`,
          matched_quote: file_info.matched_quote,
        };
      } else return {};
    },
  },
  watch: {
    workspace_task_dialog: function(newVal) {
      if (newVal == true) this.set_inputs_to_default();
    },
  },
};
</script>

<style>
.crossed_text {
  text-decoration: line-through;
}
</style>
