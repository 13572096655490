<template>
  <div class="task-list-div">
    <div
      v-for="list in proposal_lists"
      :key="list.id"
      style="border:2px solid #f3f3f3; padding:20px; float:left;width:100%;"
    >
      <p style="font-size:18px;color:#333;text-decorate:uppercase">
        <b> {{ list.name }} </b>
        <br>
      </p>
      <span>
        {{ list.note }}
      </span>
      <div style="width:100%;float:left">
        <draggable
          group="joblist"
          :data-list-id="list.id"
          draggable=".js-draggable"
          handle=".handle"
          @start="drag = true"
          @end="drag = false"
          @add="onAdd"
          @sort="onSort"
        >
          <div
            v-for="task in job_proposals_list
              .filter((c) => c.list_id == list.id && c.mark_as_deleted == 0)
              .sort(function(a, b) {
                return a.position - b.position;
              })"
            :key="task.id"
            :data-task-id="task.id"
            :class="{ 'js-draggable': !task.completed || task.completed }"
          >
            <div class="dragBody">
              <el-collapse
                :name="'collapse_' + task.id"
                accordion
              >
                <el-collapse-item name="job_id">
                  <template slot="title">
                    <div
                      class="listingshow"
                      style="width:100%;"
                      :active="task.is_active"
                      :disabled="task.completed"
                      :class="{
                        disabledlisting: task.completed,
                        activelisting: task.is_active && !task.completed,
                      }"
                    >
                      <el-row style="width:100%;">
                        <el-col
                          :xs="4"
                          :sm="4"
                          :md="1"
                          :lg="1"
                          :xl="1"
                        >
                          <span v-show="!task.completed && task.is_on_hold">
                            <i
                              class="el-icon-remove"
                              style="color:red"
                            />
                          </span>
            
                          <!-- <i class="el-icon-d-caret handle dragicon"></i> -->
                        </el-col>
                        <el-col
                          :xs="20"
                          :sm="20"
                          :md="23"
                          :lg="23"
                          :xl="23"
                        >
                          <span
                            v-show="
                              !task.completed_date && !task.custom_start_date
                            "
                            class="slimfont"
                          >{{ task.time_frame_start }}</span>
            
                          <span
                            v-show="
                              !task.completed_date && task.custom_start_date
                            "
                            class="slimfont"
                          >{{ task.custom_start_date }}</span>
            
                          <span
                            v-show="task.completed_date"
                            class="slimfont"
                            :disabled="task.completed"
                            :class="{ disabledtaskinside: task.completed }"
                          >{{ task.completed_date }}</span>
                          |
                          <i
                            v-show="task.is_date_locked"
                            class="el-icon-lock"
                          /><span
                            :disabled="task.completed"
                            :class="{ disabledtaskinside: task.completed }"
                          >
                            {{ task.job_display_name }}
                          </span>
                          <i class="el-icon-user">
                            <span
                              v-for="assigneduser in task.crm_users"
                              :key="assigneduser.id"
                              class="assignedUser"
                              :disabled="task.completed"
                              :class="{ disabledtaskinside: task.completed }"
                            >{{ assigneduser.user_name }}</span>
                          </i>
            
                          <span
                            v-show="!task.completed && task.is_urgent"
                            class="badgeDiv divRed"
                          >URGENT</span>
                          <span
                            v-show="task.billable"
                            class="badgeDiv divPurple"
                          >BILLABLE</span>
            
                          <span
                            class="fontstyle"
                            :disabled="task.completed"
                            :class="{ disabledtaskinside: task.completed }"
                          >
                            <span
                              v-show="!task.completed"
                              style="color:green"
                            >
                              <b>Pending</b>
                            </span>
                            <span v-show="task.completed">
                              <b>Completed</b>
                            </span>
                          </span>
            
                          <span
                            v-if="task.completed"
                            class="fontstyle"
                            :disabled="task.completed"
                          >
                            <i
                              class="el-icon-time"
                            >
                              A:<Time :is_hours="is_hours" :minutes="task.time_allocated_labour" />
                            </i>
                            <i
                              class="el-icon-time"
                            >
                              S:<Time :is_hours="is_hours" :minutes="total_time_spent(task)" />
                            </i>
            
                            <b>
                              <i
                                v-show="
                                  task.time_allocated_labour <
                                    total_time_spent(task)
                                "
                                class="el-icon-top"
                                style="color:red"
                              />
                            </b>
                            <b>
                              <i
                                v-show="
                                  task.time_allocated_labour >
                                    total_time_spent(task)
                                "
                                class="el-icon-bottom"
                                style="color:green"
                              />
                            </b>
                          </span>
                          <span
                            v-else
                            class="fontstyle"
                            :disabled="task.completed"
                          >
                            <i
                              class="el-icon-time"
                            >
                              A:<Time :is_hours="is_hours" :minutes="task.time_allocated_labour" />
                            </i>
                            <i
                              v-show="total_time_spent(task)"
                              class="el-icon-time"
                            >
                              S:<Time :is_hours="is_hours" :minutes="total_time_spent(task)" />
                            </i>
                          </span>
                          <el-button
                            v-show="list.id"
                            style="padding: 4px;"
                            @click="open_file_link(list.id)"
                          >
                            <i class="el-icon-picture" />
                          </el-button>
                                      
                          <el-button
                            v-show="show_assign_to_me(task)"
                            type="primary"
                            size="mini"
                            @click="assign_to_me(task)"
                          >
                            Assign to me
                          </el-button>
                          <el-tag
                            v-show="task.extension_status != undefined"
                            size="mini"
                            class="right"
                            :type="request_status(task.extension_status)"
                          >
                            {{ task.extension_status }} EXTENSION REQUEST
                          </el-tag>
                          <span
                            v-show="project_plan_version == true"
                            class="right"
                          >
                            <span
                              style="font-weight: 300"
                            >Time Allocated adjusted:</span>
                            <el-input
                              v-model="task.time_allocated_adjusted"
                              :disabled="task.completed"
                              style="width: 100px"
                              size="mini"
                            />
                          </span>
                          <el-tag
                            v-show="task.is_outwork == true"
                            type="warning"
                            size="mini"
                            effect="dark"
                          >
                            OUTWORK TASK
                          </el-tag>
                        </el-col>
                      </el-row>
                    </div>
                  </template>
                  <!---- NEW AREA --->
                  <div
                    v-show="project_plan_version == false"
                    class="insideDiv greenbackground"
                  >
                    <el-row :gutter="10">
                      <el-col
                        class="insideCol"
                        :xs="24"
                        :sm="24"
                        :md="24"
                        :lg="24"
                        :xl="24"
                      >
                        <el-tabs
                          :key="task.id"
                          class="taskTabs"
                          :value="task.id + 'task_details'"
                          @tab-click="handleClick"
                        >
                          <el-tab-pane
                            label="User/Task Details"
                            :name="task.id + 'task_details'"
                            :lazy="true"
                          >
                            <el-col
                              v-show="task.alternative_description"
                              :xs="24"
                              :sm="24"
                              :md="24"
                              :lg="24"
                              :xl="24"
                            >
                              <span class="spanlabel">Notes:</span>
                              <div
                                v-show="task.alternative_description"
                                style="float:left;width:100%;padding:10px;background:#FFFF99;border-radius:5px;margin-bottom:10px;"
                              >
                                {{ task.alternative_description }}
                              </div>
                            </el-col>
                            <el-col
                              :xs="24"
                              :sm="24"
                              :md="12"
                              :lg="12"
                              :xl="12"
                            >
                              <el-checkbox
                                v-show="proposal.quote_type_billable"
                                v-model="task.billable"
                                class="billableBttn"
                                label="Billable"
                                border
                                @change="update_task_billable(task)"
                              />
                              <el-checkbox
                                v-model="task.is_on_hold"
                                class="onholdBttn"
                                label="Hold Point"
                                border
                                @change="update_on_hold(task)"
                              />
                            </el-col>

                            <TaskUserDetails :task="task" />
                          </el-tab-pane>
                          <el-tab-pane
                            label="Dates"
                            :name="task.id + 'dates'"
                            :lazy="true"
                          >
                            <TaskTimes :task="task" />
                          </el-tab-pane>
                          <el-tab-pane
                            label="Used Stock"
                            :name="task.id + 'used_stocks'"
                            :lazy="true"
                          >
                            <JobStockList
                              :task="task"
                              :list_name="list.name"
                            />
                          </el-tab-pane>
                          <el-tab-pane
                            label="Task History Log"
                            :name="task.id + 'history'"
                            :lazy="true"
                          >
                            <TaskHistoryLog :task="task" />
                          </el-tab-pane>
                        </el-tabs>

                        <el-col
                          class="lastCol"
                          :disabled="task.completed"
                          :class="{ removecol: task.completed }"
                        >
                          <el-button
                            class="right"
                            type="danger"
                            plain
                            @click="remove_task(task.id)"
                          >
                            Delete Task
                          </el-button>
                        </el-col>
                      </el-col>

                      <!-- POPUP ON LEAVE IF JOB TRUE -->
                      <!-- <StockItemsUsedDetails :task="task"></StockItemsUsedDetails> -->
                      <!-- POPUP END -->
                    </el-row>
                  </div>

                  <!--- NEW AREA END --->
                  <!-- <div class="insideDiv greenbackground">
                  <el-row :gutter="10">
                    <el-col class="insideCol" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                      <div>
                        <p>
                          <span class="spanlabel">Notes:</span>
                        </p>
                      </div>
                  
                      <div
                        v-show="task.alternative_description"
                        style="float:left;width:100%;padding:10px;background:#FFFF99;border-radius:5px;margin-bottom:10px;"
                      >{{ task.alternative_description }}</div>

                      <el-checkbox
                        class="billableBttn"
                        v-model="task.billable"
                        v-show="proposal.quote_type_name == 'fixed quote' "
                        label="Billable"
                        @change="update_task_billable(task)"
                        border
                      ></el-checkbox>
                      <el-checkbox
                        class="billableBttn"
                        v-model="task.billable"
                        v-show="proposal.quote_type_name == 'charge up' "
                        label="Billable"
                        @change="update_task_billable(task)"
                        border
                      ></el-checkbox>
                      <el-checkbox
                        class="onholdBttn"
                        v-model="task.is_on_hold"
                        @change="update_on_hold(task)"
                        label="Hold Point"
                        border
                      ></el-checkbox>
                    </el-col>

                    <el-col class="insideCol" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                      <TaskTimes :task="task"></TaskTimes>
                    </el-col>
                  </el-row>
                  <el-row :gutter="10">
                    <el-col class="insideCol" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                      <StockItemsUsedDetails :task="task"></StockItemsUsedDetails>
                    </el-col>

                    <el-col class="insideCol" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                      <TaskUserDetails :task="task" :crm_users_list="crm_users_list"></TaskUserDetails>
                    </el-col>

                    <el-col
                      class="lastCol"
                      :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="12"
                      :xl="12"
                      :disabled="task.completed"
                      :class="{ 'removecol': task.completed }"
                    >
                      <el-button type="danger" @click="remove_task(task.id)" plain>Delete Task</el-button>
                    </el-col>
                  </el-row>
                </div> -->
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
        </draggable>
      </div>
      <div class="right" />
    </div>
    <!-- ORGAINAL DESIGN-->
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import TaskTimes from '../sections/taskcomponents/TaskTimes.vue';
import TaskUserDetails from '../sections/taskcomponents/TaskUserDetails.vue';
import TaskHistoryLog from '../sections/taskcomponents/TaskHistoryLog.vue';
import StockItemsUsedDetails from '../sections/taskcomponents/StockItemsUsedDetails.vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import AddJobsToList from './AddJobsToList.vue';
import { editor_store } from 'store/modules/editor';
import { JobProposalMixin } from 'mixins/JobProposalMixin.js';
import { ProposalMixin } from 'mixins/ProposalMixin.js';
import { CrmUsersMixin } from 'mixins/CrmUsersMixin.js';
import JobStockList from './JobStockList';
import Time from '../../shared/Time';
export default {
  name: 'TaskList',
  store: editor_store,
  components: {
    draggable,
    TaskUserDetails,
    AddJobsToList,
    TaskTimes,
    StockItemsUsedDetails,
    TaskHistoryLog,
    JobStockList,
    Time
  },
  mixins: [CrmUsersMixin, JobProposalMixin, ProposalMixin],
  props: {
    project_plan_version: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      total_allocated: 0,
      total_spent: 0,
      activeName: 'first',
      active_names: ['1'],
      selected_job_id: '',
      selected_sale_price: 0,
      selected_time_allocated: 0,
      task_description: '',
      is_loading: false,
    };
  },
  mounted() {
    // let _this = this;
    // setTimeout(function() {
    //   _this.load_job_proposals_list();
    //   _this.load_lists();
    //   _this.load_users_list();
    // }, 500);
  },

  computed: {
    ...mapGetters([
      'proposal',
      'proposal_id',
      'job_proposals_list',
      'crm_users_list',
      'jobs_list',
      'proposal_lists',
      'current_user_info',
      'is_hours'
    ]),
    job_proposals_list_grouped() {
      return this.groupBy(this.job_proposals_list, 'list_name');
    },
  },

  methods: {
    ...mapActions([
      'set_stock_items_list',
      'set_job_proposals_list',
      'set_crm_users_list',
      'set_jobs_list',
      'set_proposal_lists',
    ]),
    handleClick(tab, event) {
      console.log(tab, event);
    },
    show_assign_to_me(task) {
      return (task.completed == false &&
                task.crm_users.length == 1 &&
                task.crm_users[0].user_name != this.current_user_info.user_name);

    },
    assign_to_me(task) {
      this.$confirm(
        'This will assign the selected task to you. Continue?',
        'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      ).then(
        (response) => {
          this.$http.patch(`/assign_task_to_me/${task.id}`).then((response) => {
            task.crm_users.forEach((el, idx) => {
              el.user_name = response.data.user_name;
            });
            this.$message({
              type: 'success',
              message: 'Task has been assigned to you',
            });
          });
        },
        (response) => {}
      );
    },
    request_status(extension_status) {
      switch (extension_status) {
      case 'PENDING':
        return '';
      case 'APPROVED':
        return 'success';
      case 'DENIED':
        return 'danger';
      default:
        return 'warning';
      }
    },

    total_time_spent(task) {
      let total = 0;
      let total_per_user = task.crm_users.reduce(function(total, item) {
        return total + parseInt(item.time_in_minutes);
      }, 0);
      return total + parseInt(total_per_user);
    },
    update_task_billable(task) {
      let _this = this;
      let params = {
        id: task.id,
        billable: task.billable,
      };
      this.update_billable(params).then(
        (response) => {
          this.$message({
            message: 'Billable Updated',
            type: 'success',
          });
        },
        (response) => {
          this.$message({
            message: 'Unable to update',
            type: 'error',
          });
        }
      );
    },
    update_on_hold(task) {
      let _this = this;
      let params = {
        id: task.id,
        is_on_hold: task.is_on_hold,
      };
      this.update_on_hold_task(params).then(
        (response) => {
          this.$message({
            message: 'Hold Point Updated',
            type: 'success',
          });
        },
        (response) => {
          this.$message({
            message: 'Unable to update',
            type: 'error',
          });
        }
      );
    },
    load_stock_items() {
      let _this = this;
      setTimeout(function() {
        _this.load_stock_items_list(_this.proposal.id).then((response) => {
          _this.set_stock_items_list(response);
        });
      }, 500);
    },

    load_users_list() {
      // this.load_users_branch(this.proposal.branch_id).then((response) => {
      //   this.set_crm_users_list(response);
      // });
      this.$http.get('/users_active_list').then((response) => {
        this.set_crm_users_list(response.data);
      });
      //       this.load_users_branch(this.proposal.branch_id).then((response) => {
      //   this.set_crm_users_list(response);
      // });
    },
    get_selected_job(id) {
      return this.jobs_list.filter((item) => {
        return item.id == id;
      })[0];
    },
    onSort(ev, arg) {
      let _this = this;
      let list_id = ev.target.getAttribute('data-list-id');
      let task_id = parseInt(ev.item.getAttribute('data-task-id'));
      let list = this.job_proposals_list
        .filter((c) => c.list_id == list_id && c.id != task_id)
        .sort(function(a, b) {
          return a.position - b.position;
        })
        .map((a) => a.id);
      list.splice(ev.newDraggableIndex, 0, task_id);

      let params = {
        list_id: list_id,
        job_proposal_ids: list,
        proposal_id: this.proposal_id,
      };
      this.sort_task_in_list(params).then(
        (response) => {
          // below is commented so workspace tasks can be updated using update_time_frame func
          // if (_this.proposal.approved == 1) {
          this.update_proposal_time_frame(_this.proposal.id).then(
            (response) => {
              this.load_job_proposals_list();
            }
          );
          // }
          this.$message({
            message: 'Task updated successfully.',
            type: 'success',
          });
        },
        (response) => {}
      );
    },
    onAdd(ev, arg) {
      let _this = this;
      let params = {
        list_id: ev.target.getAttribute('data-list-id'),
        job_proposal_id: ev.item.getAttribute('data-task-id'),
        position: ev.newDraggableIndex,
      };
      this.add_task_to_list(params).then(
        (response) => {
          if (_this.proposal.approved == 1) {
            this.update_proposal_time_frame(_this.proposal.id).then(
              (response) => {
                this.load_job_proposals_list();
              }
            );
          }
          this.$message({
            message: 'Task updated successfully.',
            type: 'success',
          });
        },
        (response) => {}
      );
    },
    add_job() {
      let _this = this;
      if (this.selected_job_id != '') {
        let selected_job = this.get_selected_job(this.selected_job_id);

        let data = [{
          job_id: selected_job.id,
          time_allocated_labour: this.selected_time_allocated,
          sale_price: this.selected_sale_price,
          alternative_description: this.task_description,
        }, ];
        this.add_job_proposal(this.proposal.id, data).then(
          (response) => {
            response.forEach((el, idx) => {
              _this.job_proposals_list.push(el);
              _this.task_description = '';
            });

            // _this.set_inputs_to_default();
            if (_this.proposal.approved == 1) {
              this.update_proposal_time_frame(_this.proposal.id).then(
                (response) => {
                  this.load_job_proposals_list();
                }
              );
            }
            this.$message({
              message: 'Task added successfully.',
              type: 'success',
            });
          },
          (reason) => {
            console.log(reason);
            this.$message({
              message: 'Error occured!.',
              type: 'error',
            });
          }
        );
      }
    },
    remmove_list(id) {
      this.remove_job_list(id).then(
        (response) => {
          this.load_lists();
          this.$message({
            type: 'success',
            message: 'Delete list',
          });
        },
        (response) => {}
      );
    },

    //  update_list(id) {
    //     let _this = this;
    //   let params = {
    //     id: list.id,
    //    name: list.name
    //   };
    //   this.update_job_list(params).then(
    //     (response) => {
    //       this.load_lists();
    //       this.$message({
    //         type: "success",
    //         message: "List Updated",
    //       });
    //     },
    //     (response) => {}
    //   );
    // },
    remove_task(id) {
      this.$confirm(
        'This will permanently delete the task. Continue?',
        'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      ).then(
        (response) => {
          this.delete_task(id).then((response) => {
            this.update_proposal_time_frame(this.proposal.id).then(
              (response) => {
                this.load_job_proposals_list();
              }
            );

            this.$message({
              type: 'success',
              message: 'Task has been deleted',
            });
          });
        },
        (response) => {}
      );
    },
    groupBy(array, key) {
      const result = {};
      array.forEach((item) => {
        if (!result[item[key]]) {
          result[item[key]] = [];
        }
        result[item[key]].push(item);
      });
      return result;
    },
    load_lists() {
      let _this = this;
      _this.set_proposal_lists([]);
      _this.load_proposal_lists(_this.proposal_id).then(
        (response) => {
          _this.set_proposal_lists(response);
        },
        (response) => {
          console.log(response);
        }
      );
    },
    load_jobs_list: function() {
      this.load_jobs_categories(this.proposal.category_task_id).then(
        (response) => {
          this.set_jobs_list(response);
        }
      );
    },
    load_job_proposals_list() {
      let _this = this;
      this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      // set second argument to true 'only_workspace_tasks'
      // to fetch is_initated tasks only if proposal is workspace
      _this.load_job_proposals(_this.proposal_id, true).then((response) => {
        _this.set_job_proposals_list(response);
        _this.$loading().close();
      });
    },
    handleChange(val) {
      console.log(val);
    },
    // load_working_files() {
    //   this.$http
    //     .post("/proposal_working_files", {
    //       proposal_id: this.proposal.id,
    //     })
    //     .then((response) => {
    //       this.working_files_list = response.body.files;
    //     });
    // },
    open_file_link() {
      document
        .getElementById('filemanager-button')
        .setAttribute('data-search', '/job assets/working');
      document.getElementById('filemanager-button').click();
    },
    open_modal() {
      this.$modal.show('listthumbnaillarge');
    },
    hide_modal() {
      this.$modal.hide('listthumbnaillarge');
    },
  },
  watch: {
    'proposal.id': function(newVal) {
      // this.load_working_files();
      this.load_job_proposals_list();
      this.load_lists();
      this.load_users_list();
      this.load_jobs_list();
    },
    selected_job_id: function(newVal) {
      if (newVal != undefined) {
        let selected_job = this.get_selected_job(newVal);
        this.selected_time_allocated = selected_job.allocated_time;
        this.selected_sale_price = selected_job.labour_minutes_rate;
        this.task_description = selected_job.alternative_description;
      }
    },
  },
};
</script>

<style>
.lastCol {
    text-align: right;
    padding-top: 20px;
}

.removecol {
    display: none;
}

.dragDiv {
    border: 1px solid #f3f3f3;
    padding: 10px;
    background: #999;
    color: #fff;
}

.disabledtask {
    background: #d2d2d2;
    text-decoration: line-through;
    color: #333;
}

.disabledtaskinside {
    text-decoration: line-through;
}

.dragBody {
    background: #f3f3f3;
    padding: 2px;
    font-family: "Roboto", sans-serif !important;
}

.listingshow {
    padding: 10px;
}

.dragBody .el-collapse-item__header {
    line-height: 20px !important;
    height: auto !important;
    font-size: 14px;
    padding: 0px !important;
}

.assignedUser {
    border-right: 1px solid #f3f3f3;
    padding: 3px;
    font-family: "Roboto", sans-serif !important;
    font-size: 14px;
}

.assignedUser:last-child {
    border-right: 0px;
}

.fontstyle {
    padding: 2px 6px;
    font-family: "Roboto", sans-serif !important;
    text-transform: uppercase;
}

.insideDiv {
    padding: 10px;
}

.insideCol {
    border: 2px solid rgb(227, 249, 226);
    padding: 10px;
    background: #fff;
    min-height: 118px;
}

.el-collapse-item__content {
    padding-bottom: 0px;
}

.taskinsidetitles {
    border: 1px solid #b6b6b6;
    font-size: 16px;
    color: #333;
    padding: 6px;
    text-transform: uppercase;
}

.greenbackground {
    background: rgb(190, 207, 189);
}

.removebttn {
    display: none;
}

.el-collapse-item__header.is-active {
    background: #f7f7f7;
}

.badgeDiv {
    padding: 2px 6px 2px 6px;
    text-transform: uppercase;
    font-size: 11px;
}

.divRed {
    background: red;
    color: #fff;
}

.divOrange {
    background: orange;
    color: #fff;
}

.divPurple {
    background: purple;
    color: #fff;
}

.normButtn .el-input__inner {
    background: #fff;
    color: #999 !important;
}

.normButtn .el-input {
    width: 250px !important;
}

.task-list-div input {
    margin: 0px !important;
    border-bottom: 1px dotted #999 !important;
}

.billableBttn .el-checkbox__input.is-checked+.el-checkbox__label {
    color: purple !important;
}

.billableBttn.el-checkbox.is-bordered.is-checked {
    border-color: purple !important;
}

.billableBttn .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: purple !important;
    border-color: purple !important;
}

.onholdBttn .el-checkbox__input.is-checked+.el-checkbox__label {
    color: orange !important;
}

.onholdBttn.el-checkbox.is-bordered.is-checked {
    border-color: orange !important;
}

.onholdBttn .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: orange !important;
    border-color: orange !important;
}

.dragicon {
    font-size: 16px;
    border: 1px solid #dddedf;
    padding: 5px;
}

.listDrag {
    border: 1px solid rgb(255, 255, 255);
    float: left;
    padding: 5px 10px;
    text-transform: uppercase;
}

.listDrag:hover {
    background: #999;
    color: #fff;
}

.taskTabs .el-tabs__item {
    background: #f3f3f3;
    border: 2px solid #fff;
    color: #666;
    padding: 0px 20px !important;
    font-weight: 300;
    text-transform: uppercase;
}

.taskTabs .el-tabs__item.is-active {
    background: rgb(118, 196, 0);
    border: 2px solid #fff;
    color: #fff;
}

.taskTabs .el-tabs__active-bar {
    background-color: rgb(118, 196, 0) !important;
}
</style>
