import {Request} from '../utilities/Request';

export function pay_run_reimbursements(this_obj, crm_user_id, pay_period_start, pay_period_end) {
  return Request.post(
    this_obj,
    `/api/crm_users/${crm_user_id}/pay_runs/pay_run_reimbursements`,
    null,
    {
      pay_period_start: pay_period_start,
      pay_period_end: pay_period_end
    }
  );
}

export function remove_purchase_order(this_obj, id) {
  return Request.patch(
    this_obj,
    `/purchase_orders/${id}`,
    null,
    {
      reimbursement_crm_user_id: null
    }
  );
}

